import { range } from 'lodash'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { brokerage, single, team } from 'design/images/onboarding'

import LoadingIndicator from 'shared/LoadingIndicator'
import Toast, { formToast, serverToast } from 'shared/Toast/Toast'
import { activateAccount, inviteNewUser, onboardTenantDetails } from './Mutations'
import { getGroups } from './Queries'
import TeamForm from './TeamForm'
import TenantForm from './TenantForm'
import { validateCompany, validateTeamMember } from './Validation'

import { getLoggedInUser, Routes } from 'utils'

import { AddressType } from 'shared/AddressInput/Types'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { MlsType } from 'shared/Mls/Types'

import {
  CompanyFormErrorType,
  CompanyFormType,
  CurrentUserType,
  PhoneType,
  TeamFormErrorType,
  TeamFormType
} from './Types'

import {
  ActiveToggle,
  Container,
  Footer,
  FormContainer,
  Header,
  HeaderBold,
  MobileTabImage,
  Row,
  Submit,
  TabContainer,
  TabImage,
  TabLabel,
  TabsContainer,
  Toggle,
  ToggleContainer
} from './Styled'

interface State {
  activeTab: number
  currentUser: CurrentUserType
  form: CompanyFormType
  groupId: string
  loading: string
  mobileImage: string
  tabs: any
  teamForm: TeamFormType[]
}

interface Props extends RouteComponentProps<{}> {}

class TeamSelection extends React.Component<Props, State> {
  public state = {
    activeTab: 0,
    currentUser: {} as CurrentUserType,
    form: {
      city: '',
      domain: '',
      errors: {} as CompanyFormErrorType,
      mls: '',
      name: '',
      state: '',
      streetAddress: '',
      streetName: '',
      streetNumber: '',
      zipCode: ''
    } as CompanyFormType,
    groupId: '',
    loading: '',
    mobileImage: '',
    tabs: [
      {
        companyName: '',
        errorName: 'Name',
        id: 'INDIVIDUAL',
        image: single,
        label: 'Just Me'
      },
      {
        companyName: '',
        errorName: 'Team name',
        id: 'TEAM',
        image: team,
        label: 'My Team'
      },
      {
        companyName: '',
        errorName: 'Brokerage name',
        id: 'BROKERAGE',
        image: brokerage,
        label: 'Brokerage'
      }
    ],
    teamForm: [] as TeamFormType[]
  }

  public componentDidMount = () => {
    this.initializeForm()
    this.getGroups()
  }

  public render = () => {
    const { activeTab, form, loading, mobileImage, tabs, teamForm } = this.state

    return (
      <Container>
        {loading && <LoadingIndicator type={IndicatorTypeEnum.Spinner} message={loading} />}
        <Header>
          Choose How to <HeaderBold>Use Your Pass!</HeaderBold>
        </Header>

        <Row justify="center">
          <MobileTabImage type={1} src={mobileImage} />
          <TabsContainer>
            {tabs.map((tab: any, index: number) => (
              <Tab
                key={tab.id}
                id={index}
                label={tab.label}
                active={activeTab === index}
                image={tab.image}
                toggleActive={this.toggleActive}
              />
            ))}
          </TabsContainer>
        </Row>

        <FormContainer justify="center">
          <TenantForm
            tenantType={tabs[activeTab].id}
            form={form}
            handleAddressChange={this.handleAddressChange}
            handleInputChange={this.handleInputChange}
            handleDropdownChange={this.handleDropdownChange}
            handleMlsChange={this.handleMlsChange}
          />
          {tabs[activeTab].id !== 'INDIVIDUAL' && (
            <TeamForm form={teamForm} handleInputChange={this.handleMemberInputChange} />
          )}
        </FormContainer>

        <Footer justify="center">
          <Submit content="Continue" onClick={this.submitTeamSize} size="large" />
        </Footer>
      </Container>
    )
  }

  private initializeForm = async () => {
    const { form, tabs } = this.state
    this.setState({ loading: 'gathering data...' })

    const user = await getLoggedInUser()
    if (!user) {
      this.props.history.push({ pathname: Routes.onboarding.login.path })
      return
    }

    if (user.office && user.office.length > 0) {
      const isActive = await activateAccount()
      if (isActive) {
        this.props.history.push({ pathname: Routes.primary.dashboard.path })
        return
      }
    }

    tabs[0].companyName = user.firstName + ' ' + user.lastName

    const defaultTeam: TeamFormType[] = range(5).map((key: number) => ({
      email: '',
      errors: {} as TeamFormErrorType,
      firstName: '',
      key,
      lastName: ''
    }))

    this.toggleActive(0)

    this.setState({
      currentUser: user as CurrentUserType,
      form,
      loading: '',
      tabs,
      teamForm: defaultTeam
    })
  }

  private toggleActive = (id: number) => {
    const { form, tabs } = this.state
    form.name = tabs[id].companyName
    form.domain = this.parseSubdomain(tabs[id].companyName)
    this.setState({
      activeTab: id,
      form,
      mobileImage: tabs[id].image
    })
  }

  private getGroups = async () => {
    this.setState({ loading: 'getting user groups...' })
    const groups = await getGroups()
    const agentGroup = groups.data.getGroups.find((group: any) => group.name === 'AGENT')
    this.setState({
      groupId: agentGroup._id,
      loading: ''
    })
  }

  private setName = (name: string) => {
    const { activeTab, tabs } = this.state
    tabs[activeTab].companyName = name
    this.setState({ tabs })
  }

  private parseSubdomain = (name: string) => {
    const spaces = /\s+/gi
    const invalidChars = /[^a-zA-Z0-9]/gi
    return name
      .split('.')[0]
      .toLowerCase()
      .replace(invalidChars, ' ')
      .replace(spaces, '-')
  }

  private handleMemberInputChange = (item: string, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { teamForm } = this.state
    teamForm[index][item] = event.target.value
    teamForm[index].errors = {}
    this.setState({ teamForm })
  }

  private handleAddressChange = (address: AddressType) => {
    const { form } = this.state
    const updatedForm = {
      ...form,
      ...address
    }
    this.setState({ form: updatedForm })
  }

  private handleInputChange = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const { form } = this.state
    form[name] = event.target.value
    form.errors[name] = ''
    if (name === 'name') {
      this.setName(event.target.value)
    }
    if (name === 'name' || name === 'domain') {
      form.domain = this.parseSubdomain(event.target.value)
    }
    this.setState({ form })
  }

  private handleDropdownChange = (event: React.SyntheticEvent, data: any) => {
    const { form } = this.state
    form.state = data.value
    this.setState({ form })
  }

  private handleMlsChange = (id: string, mlsData: MlsType) => {
    const { form } = this.state
    form.mls = id
    form.state = mlsData.state
    this.setState({ form })
  }

  private isValidCompany = () => {
    const { activeTab, form, tabs } = this.state
    const result = validateCompany(form, tabs[activeTab].errorName)

    if (!result.formIsValid) {
      form.errors = result.errors
      this.setState({ form })
      formToast(result.errors)
      return false
    }
    return true
  }

  private isValidTeam = (isIndividual: boolean) => {
    const { teamForm } = this.state
    let members: any = null
    let valid: boolean = true

    if (isIndividual) {
      return {
        members,
        valid
      }
    }

    const trimForm = teamForm.map((member: TeamFormType) => {
      return {
        ...member,
        email: member.email.trim(),
        firstName: member.firstName.trim(),
        lastName: member.lastName.trim()
      }
    })

    members = trimForm.filter((member: TeamFormType, index: number) => {
      if (!member.firstName && !member.lastName && !member.email) {
        return false
      }
      const validation = validateTeamMember(member, index + 1)
      if (!validation.formIsValid) {
        valid = false
        teamForm[index].errors = validation.errors
        Object.keys(validation.errors).forEach((key: string) => {
          Toast({
            message: validation.errors[key],
            type: 'error'
          })
        })
      }
      return validation.formIsValid
    })

    this.setState({ teamForm })
    return {
      members,
      valid
    }
  }

  private submitTeamSize = async () => {
    const {
      activeTab,
      currentUser,
      form: { city, domain, mls, name, state, streetName, streetNumber, zipCode },
      groupId,
      tabs
    } = this.state

    this.setState({ loading: 'validating form...' })
    const isIndividual = tabs[activeTab].id === 'INDIVIDUAL'
    const validCompany = this.isValidCompany()
    const validTeam = this.isValidTeam(isIndividual)

    if (!validCompany || !validTeam.valid) {
      this.setState({ loading: '' })
      return
    }

    const emailName = (currentUser.firstName + currentUser.lastName)
      .toLowerCase()
      .trim()
      .replace(/[^a-z]/gi, '')

    let officePhone = ''
    if (currentUser.phones.length > 0) {
      const phone =
        currentUser.phones.find((item: PhoneType) => item.type.toLowerCase() === 'work') || currentUser.phones[0]
      officePhone = phone.value
    }

    try {
      this.setState({ loading: 'submitting data...' })
      const response = await onboardTenantDetails({
        adminEmail: {
          type: 'Admin',
          value: `admin@${domain}.realtypass.com`
        },
        companyName: name,
        companyType: tabs[activeTab].id,
        domainName: domain,
        homeAddress: {
          city,
          country: 'USA',
          state,
          streetName,
          streetNumber,
          type: 'Home',
          zipCode
        },
        mls,
        officeAddress: {
          city,
          country: 'USA',
          state,
          streetName,
          streetNumber,
          type: 'Office',
          zipCode
        },
        officeEmail: currentUser.userName,
        officePhone,
        realtyPassEmail: {
          type: 'RealtyPass',
          value: `${emailName}@${domain}.realtypass.com`
        },
        userId: currentUser._id
      })

      if (!isIndividual && validTeam.valid && validTeam.members) {
        this.setState({ loading: 'inviting team members...' })
        validTeam.members.forEach((member: any) => {
          inviteNewUser({
            capSetting: response.capSetting._id,
            firstName: member.firstName,
            group: groupId,
            lastName: member.lastName,
            office: response.office[0]._id,
            transactionFeeSetting: response.transactionFeeSetting._id,
            userName: member.email
          })
        })
      }
    } catch (error) {
      this.setState({ loading: '' })
      return serverToast(error)
    }

    this.setState({ loading: '' })
    this.props.history.push(Routes.onboarding.root + Routes.onboarding.welcome.path)
  }
}

const Tab = ({
  label,
  id,
  image,
  toggleActive,
  active
}: {
  label: string
  image: string
  id: number
  toggleActive: (id: number) => void
  active: boolean
}) => (
  <TabContainer>
    <TabImage type={id + 1} src={image} />
    <ToggleContainer onClick={toggleActive.bind({}, id)}>
      <Toggle>{active && <ActiveToggle />}</Toggle>
      <TabLabel>{label}</TabLabel>
    </ToggleContainer>
  </TabContainer>
)
export default withRouter(TeamSelection)
