import * as React from 'react'
import { connect } from 'react-redux'

import DocumentZone from 'shared/DocumentZone'
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import { serverToast } from 'shared/Toast/Toast'
import Checklist from './Checklist'

import {
  createTransactionDocuments,
  deleteTransactionDocument,
  updateTransactionDocument
} from 'app/Transactions/Dashboard/TransactionMutations'
import { getTransactionDocument } from 'app/Transactions/Dashboard/TransactionQueries'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'

import { getLoggedInUser } from 'utils'

import { Container, Section } from './Styled'

import { ActionEnum } from 'shared/DocumentZone/Documents/ActionButtons/Types'
import { PDFDocumentType } from 'shared/PDFViewer/Types'
import { TransactionType, UserType } from 'app/Transactions/Details/Types'

interface StoreProps {
  documents: any
  documentUnreadCount: number
  setDocumentUnreadCount: (count: number) => void
  setTransaction: (transaction: TransactionType) => void
  setTransactionDocuments: (data: any) => void
}

interface OwnProps {
  onAction: (action: ActionEnum, data: any) => void
  resizeTrigger?: boolean
  transaction: TransactionType
}

type Props = OwnProps & StoreProps

interface State {
  loading: string
  modal: any
  user: UserType
}

class Documents extends React.Component<Props, State> {
  public state = {
    modal: null,
    user: {} as UserType,
    loading: '',
  }

  public componentDidMount = async () => {
    const { setTransactionDocuments, setDocumentUnreadCount, transaction } = this.props

    const user: any = await getLoggedInUser({ fromCache: true })
    if (user) {
      this.setState({ user })
    }

    const documents = await getTransactionDocument(transaction._id)
    setTransactionDocuments(documents)

    const count: PDFDocumentType[] = documents.filter((item: PDFDocumentType) => !item.isViewed)
    setDocumentUnreadCount(count.length)
  }

  public render() {
    const { documents, resizeTrigger, transaction } = this.props

    const { modal, loading } = this.state

    return (
      <React.Fragment>
        {modal}
        <Container>
          <Checklist transactionId={transaction._id} />
          <Section primary={true}>
            <DocumentZone
              disableClick={false}
              disabled={transaction.status === 'Closed'}
              docs={documents}
              gridActions={['download', 'lock', 'mail', 'trash']}
              listActions={['rename', 'download', 'lock', 'mail', 'trash']}
              onAction={this.handleActions}
              onUploadEach={this.handleFileUpload}
              resizeTrigger={resizeTrigger}
              viewActions={['markup', 'download', 'lock', 'mail', 'trash', 'rotate', 'zoom']}
              loadingTrigger={loading}
            />
          </Section>
        </Container>
      </React.Fragment>
    )
  }

  private closeModal = () => {
    this.setState({ modal: null })
  }

  private handleFileUpload = async (file: File) => {
    const {
      documents,
      documentUnreadCount,
      setDocumentUnreadCount,
      setTransaction,
      setTransactionDocuments,
      transaction
    } = this.props

    try {
      const doc = await createTransactionDocuments([file], transaction._id)
      const updated = [doc[0], ...documents]
      setTransactionDocuments(updated)
      setDocumentUnreadCount(documentUnreadCount + 1)
    } catch {
      return
    }

    if (!transaction.tags) {
      const transactionUpdate = JSON.parse(JSON.stringify(transaction))
      transactionUpdate.tags = 'Updated'
      setTransaction(transactionUpdate)
    }
  }

  private handleActions = async (action: ActionEnum, data: any) => {
    const { onAction, setTransactionDocuments } = this.props
    switch (action) {
      case ActionEnum.Lock:
        this.handleLockAction(data._id, true)
        break

      case ActionEnum.Unlock:
        this.handleLockAction(data._id, false)
        break

      case ActionEnum.Trash:
        this.handleTrashAction(data._id)
        break

      case ActionEnum.Mail:
        this.handleEmailAction(data)
        break

      case ActionEnum.Rotate:
        this.handleRotateAction(data._id, data.rotation + 90)
        break

      case ActionEnum.Rename:
        this.handleRenameAction(data._id, data.fileName)
        break

      case ActionEnum.View:
        this.handleViewAction(data._id, data.isViewed)
        break

      case ActionEnum.UpdateAll:
        setTransactionDocuments(data)
        break

      case ActionEnum.SaveOne:
        this.handleSaveOneAction(data)
        break
    }

    onAction(action, data)
  }

  private handleViewAction = async (id: string, isViewed: boolean) => {
    const {
      documents,
      documentUnreadCount,
      setDocumentUnreadCount,
      setTransaction,
      setTransactionDocuments,
      transaction
    } = this.props
    const { user } = this.state
    if (documentUnreadCount > 0 && !isViewed && (user.role === 'ADMIN' || user.role === 'MANAGER')) {
      const updated: any = JSON.parse(JSON.stringify(documents))
      const index: number = updated.findIndex((doc: any) => doc._id === id)
      if (index < 0) {
        return
      }

      const unread = documentUnreadCount - 1
      updated[index].isViewed = true
      setDocumentUnreadCount(unread)
      setTransactionDocuments(updated)

      try {
        await this.handleSaveOneAction(updated[index])
      } catch {
        setDocumentUnreadCount(documentUnreadCount)
        setTransactionDocuments(documents)
        return
      }

      if (unread === 0) {
        const transactionUpdate = JSON.parse(JSON.stringify(transaction))
        transactionUpdate.tags = ''
        setTransaction(transactionUpdate)
      }
    }
  }

  private handleEmailAction = async (doc: PDFDocumentType) => {
    const modal = <ComposeModal onClose={this.closeModal} sendDocument={doc} />
    this.setState({ modal })
  }

  private handleLockAction = async (id: string, lockStatus: boolean) => {
    const { documents, setTransactionDocuments } = this.props

    const updated: any = JSON.parse(JSON.stringify(documents))
    const index: number = updated.findIndex((doc: any) => doc._id === id)
    if (index < 0) {
      return
    }

    updated[index].isLocked = lockStatus
    setTransactionDocuments(updated)
    try {
      await this.handleSaveOneAction(updated[index])
    } catch {
      setTransactionDocuments(documents)
    }
  }

  private handleTrashAction = async (id: string) => {
    const { documents, setTransactionDocuments } = this.props

    const updated: any = JSON.parse(JSON.stringify(documents))
    const index: number = updated.findIndex((doc: any) => doc._id === id)
    if (index < 0) {
      return
    }

    updated.splice(index, 1)
    setTransactionDocuments(updated)
    try {
      await deleteTransactionDocument(id)
    } catch {
      setTransactionDocuments(documents)
    }
  }

  private handleRotateAction = async (id: string, rotation: number) => {
    const { documents, setTransactionDocuments } = this.props

    const updated: any = JSON.parse(JSON.stringify(documents))
    const index: number = updated.findIndex((doc: any) => doc._id === id)
    if (index < 0) {
      return
    }

    rotation = rotation > 270 ? 0 : rotation
    rotation = Math.floor(rotation / 90) * 90

    updated[index].rotation = rotation
    setTransactionDocuments(updated)
    try {
      await this.handleSaveOneAction(updated[index])
    } catch {
      setTransactionDocuments(documents)
    }
  }

  private handleRenameAction = async (id: string, fileName: string) => {
    const { documents, setTransactionDocuments } = this.props

    const updated: any = JSON.parse(JSON.stringify(documents))
    const index: number = updated.findIndex((doc: any) => doc._id === id)
    if (index < 0) {
      return
    }
    updated[index].fileName = fileName
    setTransactionDocuments(updated)
    try {
      await this.handleSaveOneAction(updated[index])
    } catch {
      setTransactionDocuments(documents)
    }
  }

  private handleSaveOneAction = async (doc: PDFDocumentType) => {
    const { documents, setTransactionDocuments } = this.props
    const {
      fileName,
      isLocked,
      isViewed,
      rotation,
      viewerMarkup,
      pages,
      password,
    } = doc

    const input:any = {
      fileName,
      isLocked,
      isViewed,
      rotation,
      viewerMarkup,
      pages,
      password,
    }

    const getIdentifier = (url: string) => {
      const identifier = /.com\/.*\.pdf?/gi
      return (url.match(identifier) || [''])[0]
    }

    this.setState({ loading: 'Saving document changes...' })
    try {
      const result: PDFDocumentType = await updateTransactionDocument(doc._id, input) || {} as PDFDocumentType
      const previous: string = getIdentifier(doc.url)
      const current: string = getIdentifier(result.url)
      if (previous !== current) {
        const index = documents.findIndex((item:PDFDocumentType) => item._id === doc._id)
        if (result && index > -1) {
          const update = [ ...documents ]
          update[index] = {
            ...doc,
            ...result
          }
          setTransactionDocuments(update)
        }
      }
    } catch (error) {
      serverToast(error)
      setTransactionDocuments(documents)
    }
    this.setState({ loading: '' })
  }
}

const mapStateToProps = (state: AppState) => ({
  documentUnreadCount: state.transactions.documentCount,
  documents: state.transactions.documents
})

export default connect(
  mapStateToProps,
  {
    setDocumentUnreadCount: Actions.documentCount,
    setTransaction: Actions.getTransactionDetail,
    setTransactionDocuments: Actions.getTransactionDocuments
  }
)(Documents)
