import * as React from 'react'
import {
  Description,
  Text,
} from './Styled'

interface Props {
  content: any
}

class Contents extends React.Component<Props> {
  public state = {}

  public render() {
    const { content } = this.props
    return (
      <div style={{ marginBottom: 20 }}>
        <Description>
          <Text>{content.description}</Text>
        </Description>
      </div>
    )
  }
}

export default Contents
