import { MyReportModule, ReportState, TransactionModule } from './Types'

// My Report Module
export const addNewMyReport = (
  state: ReportState,
  module: string,
  newData: MyReportModule
): ReportState['reportData'] => {
  const { reportData } = state
  let feeData = reportData.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === module) {
      const listLength = list.reportName.length
      const newList = list.reportName.slice()
      newList.splice(listLength, 0, newData)
      return {
        ...list,
        reportName: newList
      }
    } else {
      return list
    }
  })
  return feeData
}

export const deleteMyReport = (
  state: ReportState,
  moduleId: string,
  reportId: string
): MyReportModule[] => {
  const { reportData } = state
  let feeData = reportData.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === moduleId) {
      const newList = list.reportName.slice()
      const listIndex = newList.findIndex((items: any) => {
        return items._id === reportId
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        reportName: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

// Transaction Module
export const addNewTransaction = (
  state: ReportState,
  module: string,
  newData: TransactionModule
): ReportState['transactionData'] => {
  const { transactionData } = state
  let feeData = transactionData.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === module) {
      const listLength = list.reportName.length
      const newList = list.reportName.slice()
      newList.splice(listLength, 0, newData)
      return {
        ...list,
        reportName: newList
      }
    } else {
      return list
    }
  })
  return feeData
}

export const deleteTransaction = (
  state: ReportState,
  moduleId: string,
  reportId: string
): TransactionModule[] => {
  const { transactionData } = state
  let feeData = transactionData.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === moduleId) {
      const newList = list.reportName.slice()
      const listIndex = newList.findIndex((items: any) => {
        return items._id === reportId
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        reportName: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}
