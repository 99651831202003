// Import Packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const StyledIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
    margin-right: 10px;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 14px;
    }
    @media ( min-width: 1200px ) {
      font-size: 16px;
    }
`

const StyledSegment = styled(Segment)`
  &.ui.segment {
    border: none;
    box-shadow: none;
    display: flex;
    padding: 0px;
    font-size: 14px;
    margin: 0.5rem 0rem;
    background-color: ${Colors.transparent};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: all;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 14px;
      margin: 0.2rem 0rem;
    }
    @media ( min-width: 1200px ) {
      font-size: 14px;
      margin: 0.5rem 0rem;
    }
  }
`

export const Text = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: all;
`

export {
  StyledIcon,
  StyledSegment
}
