import { ActionCreator } from 'redux'
import Actions from 'store/Notifications/ActionsTypes'
import {
  DiscardFeedMessage,
  DiscardUpdateMessage,
  GetFeedMessage,
  GetUpdateMessage,
  NotificationCount,
  NotificationCountItems,
  NotificationsItems,
  ReadFeedMessage,
  ReadUpdateMessage
} from './Types'

export const getFeedNotification: ActionCreator<GetFeedMessage> = (
  data: NotificationsItems[]
) => ({
  payload: {
    data
  },
  type: Actions.getFeedNotification
})

export const getUpdateNotification: ActionCreator<GetUpdateMessage> = (
  data: NotificationsItems[]
) => ({
  payload: {
    data
  },
  type: Actions.getUpdateMessage
})

export const readFeedMessage: ActionCreator<ReadFeedMessage> = (id: string) => {
  return {
    payload: {
      id
    },
    type: Actions.readFeedMessage
  }
}

export const readUpdateMessage: ActionCreator<ReadUpdateMessage> = (
  id: string
) => {
  return {
    payload: {
      id
    },
    type: Actions.readUpdateMessage
  }
}

export const discardFeedMessage: ActionCreator<DiscardFeedMessage> = () => {
  return {
    type: Actions.discardFeedMessage
  }
}

export const discardUpdateMessage: ActionCreator<DiscardUpdateMessage> = () => {
  return {
    type: Actions.discardUpdateMessage
  }
}

export const showNotificationCount: ActionCreator<NotificationCount> = (
  data: NotificationCountItems
) => {
  return {
    payload: {
      data
    },
    type: Actions.showNotificationCount
  }
}
