import { debounce, escapeRegExp, filter } from 'lodash'
import * as React from 'react'
import { Form, Select } from 'semantic-ui-react'

import { StyledSearch } from '../Styled'

import { FormType, OptionType, SearchOptionType } from '../Types'

interface Props {
  form: FormType
  onChange: (e:any, { name: string, value: any }:any) => void
  userOptions: SearchOptionType[]
  officeOptions: OptionType[]
}

interface State {
  isSearching: boolean
  searchResults: SearchOptionType[]
}

class InternalForm extends React.Component<Props, State> {
  public state = {
    isSearching: false,
    searchResults: [] as SearchOptionType[]
  }

  public render() {
    const {
      form,
      form: { errors },
      officeOptions,
      onChange,
    } = this.props

    const {
      isSearching,
      searchResults,
    } = this.state

    return (
      <Form.Group widths='equal'>
        <Form.Field
          control={Select}
          name='office'
          value={form.office}
          label='Select Office'
          options={officeOptions}
          placeholder='Select Office'
          onChange={onChange}
          error={!!errors.office}
        />
        <Form.Field
          error={!!errors.user}
          label='Referral Name'
          control={StyledSearch}
          loading={isSearching}
          onResultSelect={this.handleResultSelect}
          onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
          name='user'
          placeholder='Referral Name'
          results={searchResults}
          value={form.name}
        />
      </Form.Group>
    )
  }

  private clearSearch = () => {
    this.setState({
      isSearching: false,
      searchResults: []
    })
  }

  private handleSearchChange = (e: any, { value }: any) => {
    const { onChange, userOptions } = this.props
    onChange(e, { name:'name', value })
    this.setState({ isSearching: true })

    setTimeout(() => {
      if (value.length < 1) {
        return this.clearSearch()
      }
      const re = new RegExp(escapeRegExp(value), 'i')
      const isMatch = (result: SearchOptionType) => re.test(result.title)
      this.setState({
        isSearching: false,
        searchResults: filter(userOptions, isMatch)
      })
    }, 300)
  }

  private handleResultSelect = (e: React.SyntheticEvent<HTMLDivElement>, { result }: any) => {
    const { onChange } = this.props
    onChange(e, { name: 'user', value: result._id })
    onChange(e, { name: 'name', value: result.title })
  }
}

export default InternalForm
