import { MarkupSourceEnum } from './Types'

export const defaultLabels = {
  [MarkupSourceEnum.Textract]: [
    { text: 'Title', value: 'title' },
    { text: 'MLS', value: 'mls' },
    { text: 'MLS ID', value: 'mlsId' },
    { text: 'Street Name', value: 'streetName' },
    { text: 'Street Number', value: 'streetNumber' },
    { text: 'Street Address', value: 'streetAddress' },
    { text: 'Full Address', value: 'fullAddress' },
    { text: 'City', value: 'city' },
    { text: 'State', value: 'state' },
    { text: 'Zip Code', value: 'zipCode' },
    { text: 'Price', value: 'price' }
  ],
  [MarkupSourceEnum.User]: [
    { text: 'Text', value: 'text' },
    { text: 'Signature', value: 'signature' },
    { text: 'Note', value: 'note' },
  ],
  [MarkupSourceEnum.None]: []
}
