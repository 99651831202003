// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const AddLeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40%;
  height: auto;
  background: ${Colors.White510};

  .fa-images {
    width: 80px;
    height: 80px;
    color: ${Colors.Grey520};
  }
`

const StyledIcon = styled.div`
  background: ${Colors.Black700};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50px;
  width: 70px;
`

const Title = styled.div`
  width: auto;
  margin-top: 6px;
  font-weight: 600;
  color: ${Colors.Black500};
`

const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 50%;
`

const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  &.ui.form .field.disabled>label, .ui.form .fields.disabled>label {
    opacity: 1;
  }

  &.ui.form .field.disabled>.ui.dropdown:not(.button)>.default.text  {
    color: ${Colors.Black50};
  }

  &.ui.form .field.disabled>.selection.dropdown {
    background-color: ${Colors.Black950};
  }

  &.ui.form .field>.selection.dropdown {
    width: 70%;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200} !important;
    color: ${Colors.White1000} !important;
    margin-bottom: 30px;
  }
`

export {
  AddLeftPanel,
  AddForm,
  Container,
  StyledForm,
  StyledIcon,
  Title,
}
