// Import Packages
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'

// Import Components
import Transactions from './Transactions'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Styled Components
import {
  ContentContainer,
  FullWrapper,
  ViewAllBtn,
  ViewAllInner
} from './Styled'

const SidePanel = ({ history }: RouteComponentProps<{}>) => (
  <div>
    <FullWrapper>
      <ContentContainer>
        <Transactions />
        <Link to={Routes.primary.transactions.path}>
          <ViewAllBtn>
            <ViewAllInner>{Strings.sidePanel.viewAll}</ViewAllInner>
          </ViewAllBtn>
        </Link>
      </ContentContainer>
    </FullWrapper>
  </div>
)

export default withRouter(SidePanel)
