// Import Packages
import { Label, Tab } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
`

const RightPanel = styled.div`
  padding-top: 0;
  width: 100%;
`

const StyledTab = styled(Tab)`
  .ui.tabular.menu .active.item {
    color: ${Colors.Black200};
    border-top: none;
    border-radius: 0px !important;
  }

  .ui.tabular.menu .active.item.feed {
    border-left: none;
  }

  /* .ui.tabular.menu .active.item:hover {
    color: ${Colors.Black500};
  } */

  .ui.tabular.menu .item {
    color: ${Colors.DarkBlue205};
    font-weight: 600;
  }

  .ui.tabular.menu {
    background-color: ${Colors.Grey110};
  }
`

const StyledLabel = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px !important;
  }

  &.ui.label {
    background: ${Colors.Red810} !important;
    color: ${Colors.White1000};
  }
`

export {
  Container,
  RightPanel,
  StyledTab,
  StyledLabel,
}
