// Import Packages
import * as React from 'react'
import { Grid } from 'semantic-ui-react'

// Import Components
import { getTenantDetails } from 'app/Settings/Queries'
import Company from './Company'
import Contact from './Contact'
import { ProfileType } from './Types'

import { getLoggedInUser } from 'utils'

interface State {
  profile: ProfileType
}

class Profile extends React.Component<{}, State> {
  public state = {
    profile: {} as ProfileType
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    const response = await getTenantDetails(user.tenant)
    this.setState({ profile: response })
  }

  public render() {
    const { profile } = this.state
    return (
      <Grid>
        {profile !== null && profile !== undefined && (Object.keys(profile).length > 0) &&
          <Grid.Row style={{ paddingBottom: '0' }}>
            <Grid.Column width={8}>
              <Company profile={profile} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Contact profile={profile} />
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    )
  }

}

export default Profile
