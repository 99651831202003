import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import {
  children,
  x,
  y,
} from 'design/Styled'

export const Container = styled.div`
  ${x};
  ${children};
  justify-content: space-between;
`

export const Address = styled.address`
  ${y};
  font: ${fonts.xxSmall.regular};
  color: ${Colors.TextMedium};
  line-height: 1.4em;

`

export const Price = styled.strong`
  ${x};
  width: auto;
  font: ${fonts.medium.bold};
  color: ${Colors.TextHighlight};
`

export const Icon = styled(FontAwesomeIcon)`
  margin-left: 0.8em;
  font-size: 14px;
  color: ${Colors.TextMedium};
  cursor: pointer;
`
