// Import Packages
import { Form, Search } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const AddLeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40%;
  height: auto;
  background: ${Colors.Grey110};

  .ui.small.image {
    width: 250px;
  }
`

const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 15px;
`

const Text = styled.div`
  width: 220px;
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
`

const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 50%;
`

const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

const StyledSearch = styled(Search)`

  .ui.icon.input>i.icon {
    display: none;
  }
`

export {
  AddForm,
  AddLeftPanel,
  Container,
  StyledForm,
  StyledSearch,
  Text,
  Title,
}
