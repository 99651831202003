// Import Packages
import { Icon, Input, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background?: string
  width?: number
}

const Header = styled.header`
  display: flex;
  padding: 8px 0px 8px 15px;
  font-weight: 600;
  align-items: center;
  box-shadow: 0 0 10px -6px ${Colors.KanbanShadow};
  z-index: 2;
`

const Title = styled.div`
  font-size: 14px;
  display: flex;
  flex-grow: 1;
  color: ${props => props.color || Colors.Purple50};
  width: 10px;
  line-height: 1.2;
  align-items: center;
`

const StyledIconContainer = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 3px solid ${Colors.White480};
  padding: 4px;
  margin-right: 10px;

  .fa-lock-alt {
    width: 15px;
    height: 15px;
    color: gray;
  }
`

const StyledIcon = styled.div`
  cursor: pointer;
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 15px;
  align-self: center;
`

const RecordCount = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.color || Colors.Purple50};
`

const DownButton = styled.div`
  font-size: 14px;
  color: ${Colors.Black760};
  margin-left: 5px;
  cursor: pointer;
`

const PopupWrapper = styled(Popup)`
  height: auto;
  width: 30%;
  margin-left: -19px !important;

  &.ui.bottom.left.popup:before {
    background: ${Colors.White470} !important;
    font-size: 25px;
  }

  &.ui.popup {
    max-width: 350px;
    background: ${Colors.White470};
    border-radius: 20px;
  }
`

const EditBoardName = styled.div`
  margin-top: -5px;

  i.icon {
    font-size: 20px;
    margin-left: 10px;
  }
`

const StyledInput = styled(Input)`
  height: 30px;
  width: ${(props: Props) => props.width || props.width}px;
`

const StyledPopup = styled(Popup)`
  &.ui.top.left.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    margin-left: -8px;
  }
`

const CounterItems = styled.span`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-top: 2px;
`

const CounterIcon = styled(Icon)`
  line-height: 1;
  color: ${Colors.ButtonDanger};
  &.icon {
    font-size: 1.1em;
  }
`

const Count = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 1.5em;
  height: 1.5em;
  padding: 0 0.4em;
  left: -0.6em;
  font-size: 0.7em;
  line-height: 1;
  font-weight: normal;
  color: ${Colors.White1000};
  background: ${Colors.ButtonDanger};
  border-radius: 0.75em;
`

export {
  DownButton,
  EditBoardName,
  Header,
  PopupWrapper,
  RecordCount,
  StyledIcon,
  StyledIconContainer,
  StyledInput,
  Title,
  StyledPopup,
  Count,
  CounterIcon,
  CounterItems
}
