import React from 'react'

import {
  Container,
  Image,
  Template,
  Caption,
  Label,
} from './Styled'

import {
  UserPassType,
} from 'store/Pass/Types'
import { User, UserRoleEnum } from 'store/Users/Types'

interface Props {
  pass: UserPassType.Base
  user: User.LoggedInType
  onSelect: (template: string) => void
}

const ADMIN_TEMPLATES = [
  { id: 't1', name: 'template 1', src: 'https://onechimp.s3-us-west-2.amazonaws.com/app/pass/admin.jpg' },
]

const AGENT_TEMPLATES = [
  { id: 't1', name: 'template 1', src: 'https://onechimp.s3-us-west-2.amazonaws.com/app/pass/agent1.jpg' },
  { id: 't2', name: 'template 2', src: 'https://onechimp.s3-us-west-2.amazonaws.com/app/pass/agent2.jpg' },
  { id: 't3', name: 'template 3', src: 'https://onechimp.s3-us-west-2.amazonaws.com/app/pass/agent3.jpg' },
]

class TemplateSelector extends React.Component<Props, {}> {
  public state = {
    roleWiseTemplates: []
  }

  public componentDidMount() {
    const { user } = this.props
    if (user.role === UserRoleEnum.Admin) {
      this.setState({ roleWiseTemplates: ADMIN_TEMPLATES })
    } else {
      this.setState({ roleWiseTemplates: AGENT_TEMPLATES })
    }
  }

  public render() {
    const { pass: { settings } } = this.props
    const { roleWiseTemplates } = this.state
    return (
      <React.Fragment>
        <Label>Select a template below to use for this website:</Label>
        <Container>
          {roleWiseTemplates.map((item: any) => (
            <Template
              key={item.id}
              onClick={() => this.handleClick(item.id)}
              isActive={settings.template === item.id}
            >
              <Image
                key={item.id}
                src={item.src}
              />
              <Caption>{item.name}</Caption>
            </Template>
          ))}
        </Container>
      </React.Fragment>
    )
  }

  private handleClick = (id: string) => {
    const { onSelect } = this.props
    onSelect(id)
  }
}
export default TemplateSelector
