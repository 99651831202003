import Colors from 'design/Colors'
import styled from 'styled-components'

import {
  scrollYContainer,
  y,
} from 'design/Styled'

interface Props {
  background: string
}

const Container = styled.div`
  ${y};
  flex: 1;
  justify-content: center;
  overflow: hidden;
`

export const ScrollY = styled.div`
  ${scrollYContainer};
  padding-top: 2em;
  padding-bottom: 2em;
`

const StyledGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const StyledCard = styled.div`
  position: relative;
  display: flex;
  width: 290px;
  border: 2px solid ${Colors.Black771};
  padding: 30px;
  border-radius: 10px;
  padding-bottom: 15px;
  margin-right: 20px;
  margin-bottom: 20px;
`

const Image = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 50px;
  min-height: 50px;
  align-self: flex-start;
  margin-right: 15px;
  border-radius: 50%;
`

const Info = styled.div`
  .ui.button {
    font-size: 0.9rem;
    margin-top: 10px;
    border-radius: 0px;
    /* background: ${Colors.Green215};
    color: ${Colors.White1000}; */
    height: 26px;
    padding-top: 7px;
  }

  .ui.button.key-button {
    font-size: 0.8rem;
    margin-top: 13px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    height: 20px;
    padding-top: 3px;
    margin-left: 5px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .key {
    display: flex;
  }
`

const PassName = styled.div`
  font-size: 18px;
  color: ${Colors.Black500};
  font-weight: 600;
`

const Description = styled.div`
  font-size: 12px;
  color: ${Colors.DarkBlue205};
  text-align: left;
  line-height: 1.2;
  margin-top: 4px;
`

const ActivatedBy = styled.div`
  font-size: 12px;
  text-align: right;
  margin-top: 12px;
  margin-right: -45px;
  margin-bottom: 4px;
  font-style: italic;
  font-weight: 500;
  color: ${Colors.Black550};
`

const Free = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 50px;
  min-height: 50px;
  position: absolute;
  top: 0;
  right: -8px;
`

export {
  ActivatedBy,
  StyledCard,
  Container,
  StyledGrid,
  Image,
  Info,
  PassName,
  Description,
  Free
}
