import * as React from 'react'

import DocumentZone from 'shared/DocumentZone'
import Scrollable from 'shared/Scrollable'
import { serverToast } from 'shared/Toast/Toast'
import Transition from 'shared/Transition'

import { createTransactionViaConcierge } from '../../Mutations'

import { Strings } from 'utils'

import { Bold, Col, Description, List, ListItem, Title } from '../../Styled'

import { ActionEnum, DocTemplateType, OfficeType, TransitEnum } from '../../Types'

interface Props {
  isAdditional: boolean
  office: OfficeType
  onAction: (action: ActionEnum, data: any) => void
  templates: DocTemplateType[]
  token: string
  transit: TransitEnum
}

const {
  transactionModal: {
    CONCIERGE: { TITLE }
  }
} = Strings

class DocumentStep extends React.Component<Props, {}> {
  public render() {
    const { isAdditional, office, templates, transit } = this.props

    const transition = transit === TransitEnum.Enter
    const isMultiple = templates.length > 1

    return (
      <Col height={100}>
        <Transition on={transition}>
          {!isAdditional && <Title>{TITLE.document1}</Title>}
          {templates && (
            <React.Fragment>
              <Transition on={transition} delay={30}>
                <Description>
                  Please upload{' '}
                  {isMultiple && (
                    <>
                      <Bold>one</Bold> of
                    </>
                  )}{' '}
                  the following document
                  {isMultiple && 's'} to create a new transaction for <Bold>{office.branchName}</Bold>:
                </Description>
              </Transition>
              <List>
                <Scrollable>
                  {templates.map((item: DocTemplateType, index: number) => (
                    <Transition key={item._id} on={transition} delay={60 + 30 * index}>
                      <ListItem>{item.name}</ListItem>
                    </Transition>
                  ))}
                </Scrollable>
              </List>
            </React.Fragment>
          )}
        </Transition>
        <Transition on={transition} type="fadeIn">
          <Col primary={true}>
            <DocumentZone allowMultiple={false} disableClick={false} onUploadEach={this.handleUpload} />
          </Col>
        </Transition>
      </Col>
    )
  }

  private handleUpload = async (file: File) => {
    const { office, onAction, token } = this.props

    try {
      const result = await createTransactionViaConcierge({ file, office: office._id, token })
      if (result) {
        onAction(ActionEnum.Step, null)
      }
    } catch (error) {
      serverToast(error)
    }
  }
}

export default DocumentStep
