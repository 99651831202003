import * as React from 'react'
import { Button, Form, Input } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

import BrowseModal from './BrowseModal'
import PassSection from './PassSection'

import { createPassSection, deletePassSection, updatePassSection } from './Mutations'
import { getPassesSection } from './Queries'

import {
  ModalType,
  PassSectionType,
} from './Types'

import { Strings } from 'utils'

import {
  AddPass,
  AddSection,
  Container,
  PassName,
  Row,
  Section,
  Settings,
  StyledForm,
  StyledPopup,
} from './Styled'

import { faChevronDown, faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronDown, faPencilAlt, faPlus, faTrashAlt])

interface State {
  id: string
  loader: boolean
  modal: ModalType
  passesSection: PassSectionType[]
  renameSection: boolean
  section: string
  sectionId: string
  showAddNewSection: boolean
}

class Passes extends React.Component<{}, State> {
  public state = {
    id: '',
    loader: false,
    modal: {} as ModalType,
    passesSection: [] as PassSectionType[],
    renameSection: false,
    section: '',
    sectionId: '',
    showAddNewSection: false
  }

  public async componentDidMount() {
    const response = await getPassesSection()
    this.setState({ passesSection: response, loader: false })
  }

  public render() {
    const {
      id,
      loader,
      modal,
      passesSection,
      renameSection,
      section,
      sectionId,
      showAddNewSection,
    } = this.state

    const passesSections = passesSection.map((item: any, index: any) => (
      <Section key={index}>
        {renameSection && id === item._id ? (
          <StyledForm size="mini">
            <Form.Field
              control={Input}
              value={section}
              name="section"
              placeholder="Rename Your Section"
              onChange={this.handleChange}
            />
            {loader ? (
              <Form.Field
                content="LOADING"
                control={Button}
                loading={true}
              />
            ) : (
              <Form.Field
                content={'SAVE'}
                control={Button}
                onClick={this.updateSection}
              />
            )}
            <Form.Field
              content={'CANCEL'}
              control={Button}
              onClick={this.cancelRenameSection}
            />
          </StyledForm>
        ) : (
          <PassName>
            <div>{item.name}</div>
            <StyledPopup
              trigger={<FontAwesomeIcon icon={faChevronDown} />}
              content={
                <Settings>
                  <div onClick={() => this.renameSection(item._id, item.name)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                    {Strings.dashboard.rename}
                  </div>
                  <div onClick={() => this.deleteSection(item._id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                    {Strings.dashboard.delete}
                  </div>
                </Settings>
              }
              position="bottom left"
              hoverable={true}
            />
          </PassName>
        )}
        <Row>
          <PassSection
            content={item.userPasses}
            getPassesSection={this.getPassesSection}
            section={item._id}
          />
          <AddPass onClick={() => this.showPassBrowserModal(item._id)} />
        </Row>
      </Section>
    ))
    return (
      <React.Fragment>
        {modal.search && (
          <BrowseModal
            passesSection={passesSection}
            getPassesSection={this.getPassesSection}
            closeModal={this.closeModal}
            sectionId={sectionId}
          />
        )}
        <Container id="columnScroll">{passesSections}</Container>
        {showAddNewSection ? (
          <StyledForm size="mini">
            <Form.Field
              autoFocus={showAddNewSection}
              control={Input}
              name="section"
              onChange={this.handleChange}
              placeholder="Name Your Section"
            />
            {loader ? (
              <Form.Field
                content="LOADING"
                control={Button}
                loading={true}
              />
            ) : (
              <Form.Field
                content={'SAVE'}
                control={Button}
                onClick={this.addNewSection}
              />
            )}
            <Form.Field
              content={'CANCEL'}
              control={Button}
              onClick={this.showAddNewSection}
            />
          </StyledForm>
        ) : (
          <AddSection onClick={this.showAddNewSection}>
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
            <span>{Strings.dashboard.addSection}</span>
          </AddSection>
        )}
      </React.Fragment>
    )
  }

  private showPassBrowserModal = (sectionId: string) => {
    const { modal } = this.state
    modal.search = true
    this.setState({ modal, sectionId })
  }

  private closeModal = () => {
    const modal = {} as ModalType
    this.setState({ modal })
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    this.setState({ section: value })
  }

  private getPassesSection = async (data: PassSectionType[]) => {
    this.setState({ passesSection: data })
  }

  private addNewSection = async () => {
    const { section, showAddNewSection } = this.state
    this.setState({ loader: true })
    const response = await createPassSection(section ? section : 'New Section')
    if (response) {
      this.setState({ showAddNewSection: !showAddNewSection, section: '', passesSection: response, loader: false })
    }

    const element = document.getElementById('columnScroll')
    if (element) {
      // const config = element.getBoundingClientRect()
      window.setTimeout(() => {
        element.scrollTo(0, element.scrollHeight)
      }, 100)
    }
  }

  private renameSection = (id: string, name: string) => {
    this.setState({ renameSection: true, id, section: name })
  }

  private cancelRenameSection = () => {
    this.setState({ renameSection: false })
  }

  private updateSection = async () => {
    const { id, section } = this.state
    this.setState({ loader: true })
    const response = await updatePassSection(id, section)
    if (response) {
      this.setState({ passesSection: response, renameSection: false, section: '', loader: false })
    }
  }

  private deleteSection = async (id: string) => {
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this pass section!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        const response = await deletePassSection(id)
        this.setState({ passesSection: response })
        ConfirmAlert('Deleted!', 'Your pass section has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your pass section is safe', 'error')
      }
    })
  }

  private showAddNewSection = () => {
    const { showAddNewSection } = this.state
    this.setState({ showAddNewSection: !showAddNewSection })
  }
}

export default Passes
