import moment from 'moment'
import * as React from 'react'
import { DragDropContext, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import ConfirmAlert from 'sweetalert2'

import DeleteIcon from 'design/icons/styledBoard/delete-button.png'
import DogIcon from 'design/icons/styledBoard/dog_icon.png'

import KanbanLane from './KanbanLane'

import { generateID, getLoggedInUser, Strings } from 'utils'

import Colors from 'design/Colors'

import {
  AddBoard,
  AddBoardContainer,
  AddLink,
  Aside,
  Delete,
  DeleteText,
  DropAside,
  Image,
  KanbanBoardContainer,
  KanbanContainer,
  KanbanHoverContainer,
  KanbanLaneContainer,
  KanbanScroller,
  LeftArrow,
  RightArrow,
  Row,
  StyledButton,
  StyledForm,
  StyledInput
} from './Styled'

import { faChevronDoubleLeft, faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronDoubleLeft, faChevronDoubleRight])

interface Props {
  addNewBoard?: (index: number, name: string) => void
  addNewCard: (id: string, index: string) => void
  alterData?: (data: any) => void
  reorderCard?: (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => void
  reorderColumn?: (boardId: string, sourceIndex: number, destinationIndex: number) => void
  moveCard?: (cardId: string, destinationIndex: number, destinationBoardId: string, sourceBoardId: string) => void
  resetCardData: () => void
  restoreCard: (boardId: string, cardId: string) => void
  setNewIcon?: (id: string, icon: string) => void
  setNewDescription?: (id: string, description: string) => void
  setNewBoardName?: (id: string, boardName: string) => void
  deleteCard: (boardId: string, cardId: string) => void
  deleteRecord?: (boardId: string, cardId: string) => void
  updateBoard?: (boardId: string, skip: number) => void
  CardComponent: React.ReactType
  ActionComponent: React.ReactType
  data: any
  icons: any[]
  price?: number
  appearance?: boolean
  deleteTransfer?: boolean
}

interface State {
  showInputBox: boolean
  boardName: string
  deleteBox: boolean
  deleteBoardId: string
  isAsideActive: boolean
  showArrows: boolean
  source: string
  draggableId: string
  user: any
  medium: any
}

// disable all react-beautiful-dnd development warnings
window['__react-beautiful-dnd-disable-dev-warnings'] = true

const BoardBorderColors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Grey800}`,
  `${Colors.Green560}`
]

class KanbanBoard extends React.Component<Props, State> {
  public state = {
    boardName: '',
    deleteBoardId: '',
    deleteBox: false,
    draggableId: '',
    isAsideActive: false,
    medium: [],
    showArrows: false,
    showInputBox: false,
    source: '',
    user: {}
  }

  private isScrolling: boolean = false
  private scrollRef: any = React.createRef<HTMLDivElement>()
  private scrollTimer: any = null

  public async componentDidMount() {
    const { data, alterData } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    const deleteBoardId = generateID()
    this.setState({ deleteBoardId, user })
    const newData = data.map((dataItem: any) => {
      return {
        ...dataItem,
        color: this.getRandomColors(),
        items: dataItem.items.map((items: any) => {
          return {
            ...items,
            color: this.getRandomColors()
          }
        })
      }
    })
    if (alterData) {
      alterData(newData)
    }
  }

  public componentWillUnmount = () => {
    this.scrollStop()
  }

  public render() {
    const { CardComponent, data, ActionComponent, icons, appearance, price, addNewBoard } = this.props
    const { isAsideActive, showInputBox, deleteBoardId } = this.state
    const getColumnListIndex = () => {
      this.addNewBoard(data.length + 1)
      this.toggleAddBoard()
    }

    const getColumnListIndexFunction = (index: number) => {
      this.addNewBoard(index + 2)
    }

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <KanbanBoardContainer>
          <KanbanLaneContainer isActive={!isAsideActive}>
            <LeftArrow
              onMouseEnter={() => this.scrollStart(-1)}
              onMouseLeave={this.scrollStop}
              isActive={!isAsideActive}
            >
              <FontAwesomeIcon icon={['fas', 'chevron-double-left']} />
            </LeftArrow>
            <Droppable direction="horizontal" droppableId="COLUMN" type="COLUMN">
              {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
                <KanbanContainer innerRef={dropProvided.innerRef} {...dropProvided.droppableProps}>
                  <KanbanScroller innerRef={this.scrollRef}>
                    {data.map((element: any, index: number) => (
                      <KanbanHoverContainer key={index}>
                        <KanbanLane
                          item={element}
                          index={index}
                          CardComponent={CardComponent}
                          ActionComponent={ActionComponent}
                          addNewCard={this.addNewCard}
                          icons={icons}
                          setNewIcon={this.setNewIcon}
                          setNewDescription={this.setNewDescription}
                          setNewBoardName={this.setNewBoardName}
                          appearance={appearance}
                          navigatePage={this.navigateBoardPages}
                          price={price}
                          color={element.color}
                          counterIcon="clock outline"
                          counterItems={
                            element.items.filter(
                              (item: any) => item.dueDate && moment(item.dueDate).isBefore(new Date())
                            ).length
                          }
                        />
                        <AddBoardContainer>
                          <AddBoard
                            onClick={() => getColumnListIndexFunction(index)}
                            style={{ display: addNewBoard ? 'block' : 'none' }}
                          />
                        </AddBoardContainer>
                      </KanbanHoverContainer>
                    ))}
                    {dropProvided.placeholder}
                  </KanbanScroller>
                </KanbanContainer>
              )}
            </Droppable>
            <RightArrow
              onMouseEnter={() => this.scrollStart(1)}
              onMouseLeave={this.scrollStop}
              isActive={!isAsideActive}
            >
              <FontAwesomeIcon icon={['fas', 'chevron-double-right']} />
            </RightArrow>
          </KanbanLaneContainer>

          <Aside isActive={isAsideActive || showInputBox}>
            {!isAsideActive && <Image src={DogIcon} width={80} />}
            {!isAsideActive &&
              addNewBoard && <AddLink onClick={this.toggleAddBoard}>{Strings.kanbanView.addNewBoard}</AddLink>}
            {!isAsideActive &&
              showInputBox && (
                <StyledForm size={'mini'}>
                  <StyledInput
                    name={Strings.kanbanView.boardName}
                    onChange={this.onChangeHandle}
                    placeholder={Strings.kanbanView.nameYourList}
                  />
                  <Row justify={'space-between'}>
                    <StyledButton content={Strings.kanbanView.save} onClick={getColumnListIndex} />
                    <StyledButton content={Strings.kanbanView.cancel} onClick={this.toggleAddBoard} />
                  </Row>
                </StyledForm>
              )}
            <DropAside isActive={isAsideActive}>
              <Droppable droppableId={deleteBoardId ? deleteBoardId : 'DELETE'}>
                {provided => (
                  <Delete primary={true} justify={'center'} innerRef={provided.innerRef}>
                    <Image src={DeleteIcon} width={53} />
                    <DeleteText>{Strings.kanbanView.dropToDelete}</DeleteText>
                  </Delete>
                )}
              </Droppable>
            </DropAside>
          </Aside>
        </KanbanBoardContainer>
      </DragDropContext>
    )
  }

  private scrollX = (direction: number) => {
    const container = this.scrollRef.current
    if (!container || !this.isScrolling) {
      return
    }

    this.scrollTimer = window.setTimeout(() => {
      container.scrollLeft += direction * 30
      this.scrollX(direction)
    }, 100)
  }

  private scrollStart = (direction: number) => {
    this.isScrolling = true
    this.scrollX(direction)
  }

  private scrollStop = () => {
    this.isScrolling = false
    window.clearTimeout(this.scrollTimer)
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    if (setNewBoardName) {
      setNewBoardName(id, boardName)
    }
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    if (setNewDescription) {
      setNewDescription(id, description)
    }
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    if (setNewIcon) {
      setNewIcon(id, icon)
    }
  }

  private getList = (id: any) => {
    const list = this.props.data.find((laneItem: any) => {
      return laneItem._id === id
    })
    return list
  }

  private getListIndex = (id: any) => {
    const listIndex = this.props.data.findIndex((laneItem: any) => {
      return laneItem._id === id
    })
    return listIndex
  }

  private reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = list.items.slice()
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return {
      ...list,
      items: result
    }
  }

  private reorderColumn = (list: any, startIndex: any, endIndex: any) => {
    const result = list.slice()
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return [...result]
  }

  private move = (
    source: any,
    sourceIndex: any,
    destination: any,
    destinationIndex: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = source.items.slice()
    const destClone = destination.items.slice()
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const resultState = this.props.data.slice()

    resultState[sourceIndex] = {
      ...resultState[sourceIndex],
      items: sourceClone
    }

    resultState[destinationIndex] = {
      ...resultState[destinationIndex],
      items: destClone
    }

    return resultState
  }

  private onDragStart = (res: any) => {
    this.setState({ isAsideActive: true })
  }

  private onDragEnd = (res: any) => {
    this.setState({ isAsideActive: false })
    const { source, draggableId, destination, type } = res
    const { deleteTransfer, alterData, moveCard, reorderCard, reorderColumn } = this.props

    const { deleteBoardId } = this.state

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    if (type === 'COLUMN') {
      const items = this.reorderColumn(this.props.data, source.index, destination.index)
      if (alterData) {
        alterData(items)
      }
      if (reorderColumn) {
        reorderColumn(draggableId, source.index + 1, destination.index + 1)
      }
    }

    if (destination.droppableId === deleteBoardId && type !== 'COLUMN') {
      const { deleteCard, resetCardData, restoreCard, deleteRecord } = this.props
      const { user }: any = this.state
      if (!deleteTransfer) {
        if (user._id === draggableId) {
          ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotDelete, 'error')
        } else {
          ConfirmAlert({
            cancelButtonText: Strings.kanbanView.noKeepIt,
            confirmButtonText: Strings.kanbanView.yesDeleteIt,
            showCancelButton: true,
            text: Strings.kanbanView.recoverCard,
            title: Strings.kanbanView.sure,
            type: 'warning'
          }).then(result => {
            if (result.value) {
              deleteCard(source.droppableId, draggableId)
              if (deleteRecord) {
                deleteRecord(source.droppableId, draggableId)
              } else {
                resetCardData()
              }
            } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
              restoreCard(source.droppableId, draggableId)
              resetCardData()
              ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
            } else {
              restoreCard(source.droppableId, draggableId)
              resetCardData()
            }
          })
        }
      } else {
        deleteCard(source.droppableId, draggableId)
      }
    }

    if (source.droppableId === destination.droppableId && type !== 'COLUMN') {
      const listIndex = this.getListIndex(source.droppableId)
      const ordering = this.reorder(this.props.data[listIndex], source.index, destination.index)

      const resultColumns: any = this.props.data.slice()
      resultColumns.splice(listIndex, 1, ordering)
      if (alterData) {
        alterData([...resultColumns])
      }
      if (reorderCard) {
        reorderCard(source.droppableId, draggableId, source.index + 1, destination.index + 1)
      }
    }

    if (
      source.droppableId !== destination.droppableId &&
      destination.droppableId !== deleteBoardId &&
      type !== 'COLUMN'
    ) {
      const result: any = this.move(
        this.getList(source.droppableId),
        this.getListIndex(source.droppableId),
        this.getList(destination.droppableId),
        this.getListIndex(destination.droppableId),
        source,
        destination
      )
      if (alterData) {
        alterData(result)
      }
      if (moveCard) {
        moveCard(draggableId, destination.index + 1, destination.droppableId, source.droppableId)
      }
    }
  }

  private addNewBoard = (index: number) => {
    const { data, addNewBoard } = this.props

    const { boardName } = this.state
    const LastIndex = data.length - 1 === index - 1
    if (addNewBoard) {
      addNewBoard(index, boardName)
    }

    if (!LastIndex) {
      return
    }

    const element = document.getElementById('columnScroll')
    if (element) {
      const config = element.getBoundingClientRect()
      window.setTimeout(() => {
        element.scrollLeft = config.width
      }, 100)
    }
  }

  private addNewCard = (id: string, index: string) => {
    const { addNewCard } = this.props
    addNewCard(id, index)
  }

  private getRandomColors = () => {
    return BoardBorderColors[Math.floor(Math.random() * BoardBorderColors.length)]
  }

  private onChangeHandle = (e: any) => {
    this.setState({ boardName: e.target.value })
  }

  private toggleAddBoard = () => {
    this.setState((prev: State) => {
      return {
        isAsideActive: false,
        showInputBox: !prev.showInputBox
      }
    })
  }

  private navigateBoardPages = (boardId: string, singleSkip: number) => {
    const { updateBoard } = this.props
    if (updateBoard) {
      updateBoard(boardId, singleSkip)
    }
  }
}

export default KanbanBoard
