// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Import Images and Icons
import ExportIcon from 'design/icons/contacts/contact_icon_1.png'
import ImportIcon from 'design/icons/contacts/contact_icon_2.png'
import ReportIcon from 'design/icons/contacts/contact_icon_3.png'
import SortIcon from 'design/icons/contacts/contact_icon_4.png'
import DeleteIcon from 'design/icons/contacts/contact_icon_5.png'

// Import Components
import { exportTransaction, loggedUser, ShowImportFileModal, sortTransactions } from '../Dashboard'
import ActionItem from './ActionItem'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Action from 'store/Reports/Actions'

// Import Utils
import { Routes, Strings } from 'utils'

interface Props {
  viewReport: (report: string) => void
  closeActionPopup: () => void
  boardId: string
  showAddButton?: boolean
  showAppearance?: boolean
}

interface State {
  sortOrder: number
}

class Actions extends React.Component<Props, State>{
  public state = {
    sortOrder: 1
  }

  public render() {
    const { showAddButton, showAppearance } = this.props
    return (
      <div>
        <span onClick={this.showExportFileModal}><ActionItem title={Strings.kanbanView.export} text={Strings.kanbanView.actionText} icon={ExportIcon} /></span>
        {(showAddButton || loggedUser.role === 'AGENT') && <span onClick={this.showImportFileModal}><ActionItem title={Strings.kanbanView.import} text={Strings.kanbanView.actionText} icon={ImportIcon} /></span>}
        <Link
          to={Routes.primary.reports.path + '?previous-page=transactions'}
          onClick={() => this.props.viewReport('transactions')}
        >
          <ActionItem title={Strings.kanbanView.viewReport} text={Strings.kanbanView.actionText} icon={ReportIcon} />
        </Link>
        <span onClick={this.handleSortChange}><ActionItem title={Strings.kanbanView.sort} text={Strings.kanbanView.actionText} icon={SortIcon} /></span>
        {showAppearance && <span onClick={this.deleteBoard}><ActionItem title={Strings.kanbanView.delete} text={Strings.kanbanView.actionText} icon={DeleteIcon} /></span>}
      </div>
    )
  }

  private showImportFileModal = () => {
    const { closeActionPopup, boardId } = this.props
    ShowImportFileModal(boardId)
    closeActionPopup()
  }

  private showExportFileModal = () => {
    const { closeActionPopup, boardId } = this.props
    exportTransaction(boardId)
    closeActionPopup()
  }

  private deleteBoard = () => {
    const { closeActionPopup } = this.props
    closeActionPopup()
  }

  private handleSortChange = () => {
    const { sortOrder } = this.state
    const { boardId } = this.props
    if (sortOrder === 1) {
      this.setState({ sortOrder: -1 })
      sortTransactions(boardId, this.state.sortOrder)
    } else {
      this.setState({ sortOrder: 1 })
      sortTransactions(boardId, this.state.sortOrder)
    }
  }
}

const mapStateToProps = (state: AppState) => ({})

export default connect(
  mapStateToProps,
  {
    viewReport: Action.viewReport,
  }
)(Actions)

