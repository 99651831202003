// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Image, Table } from 'semantic-ui-react'

// Import Images and Icons
import SummaryPass3 from 'design/icons/dashboard/leadSummaryPass(3).jpg'

// Import Components
import Modal from 'shared/Modal'
import { serverToast } from 'shared/Toast/Toast'

// Import Store Types, Actions and Reducers
import { UserPassType } from 'app/Dashboard/MainContent/Passes/Types'
import * as ContactActions from 'store/Contacts/Actions'
import { ContactPassFilter } from 'store/Contacts/Types'
import * as LeadActions from 'store/Leads/Actions'
import { LeadPassFilter } from 'store/Leads/Types'
import * as TaskActions from 'store/Tasks/Actions'
import { TaskPassFilter } from 'store/Tasks/Types'
import * as TransactionActions from 'store/Transactions/Actions'
import { TransactionPassFilter } from 'store/Transactions/Types'
import * as UserProfileActions from 'store/UserProfile/Actions'
import { ProfilePassFilter } from 'store/UserProfile/Types'

import { updateUserPass } from '../../Mutations'
import { hotSheetPass } from '../../Queries'

// Import Images and Icons
import Colors from 'design/Colors'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Styled Components
import {
  BottomPanel,
  Contact,
  ContactLeftPanel,
  Container,
  DescriptionWrapper,
  Label,
  PastDue,
  Row,
  StyledCard,
  StyledDropdown,
  StyledForm,
  Task,
  TaskLeftPanel,
  TaskRightPanel,
  Title,
  TopPanel,
  Transaction,
  Upcoming
} from './Styled'

// Font Awesome Icons
import { faCalendarAlt, faMoneyBill, faUser, faUserPlus, faUsers } from '@fortawesome/pro-light-svg-icons'
import { faTasks } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faTasks, faCalendarAlt, faUserPlus, faUsers, faMoneyBill, faUser])

interface StoreProps {
  setShowUserPassTransactionFilterData: (filter: TransactionPassFilter) => void
  setShowUserPassTaskFilterData: (filter: TaskPassFilter) => void
  setShowUserPassContactFilterData: (filter: ContactPassFilter) => void
  setShowUserPassLeadFilterData: (filter: LeadPassFilter) => void
  setShowUserPassProfileFilterData: (filter: ProfilePassFilter) => void
}

interface OwnProps {
  closeModal: () => void
  onUpdate: (data: any) => void
  pass: UserPassType
}

type Props = StoreProps & OwnProps & RouteComponentProps<{}>

interface State {
  animate: boolean
  timePeriod: number
  payableTransaction: any[]
  newTransaction: any[]
  receivedTransaction: any[]
  updatedTransaction: any[]
  loader: boolean
  newTransactions: number
  payableTransactions: number
  receivedTransactions: number
  referrals: number
  taskDue: number
  taskUpcoming: number
  totalContacts: number
  totalLeads: number
  updatedTransactions: number
  userID: string
}

const OPTIONS = [{ key: 1, text: 30, value: 30 }, { key: 2, text: 60, value: 60 }, { key: 3, text: 90, value: 90 }]

class ActivityHotSheetModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    loader: true,
    newTransaction: [],
    payableTransaction: [],
    receivedTransaction: [],
    timePeriod: 30,
    updatedTransaction: [],

    newTransactions: 0,
    payableTransactions: 0,
    receivedTransactions: 0,
    referrals: 0,
    taskDue: 0,
    taskUpcoming: 0,
    totalContacts: 0,
    totalLeads: 0,
    updatedTransactions: 0,
    userID: ''
  }

  public async componentDidMount() {
    const {
      pass: { timePeriod }
    } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    if (timePeriod !== null) {
      this.setState({ timePeriod, userID: user._id })
    } else {
      this.setState({ timePeriod: 30, userID: user._id })
    }
    this.getTimePeriodData(timePeriod)
  }

  public render() {
    const {
      animate,
      timePeriod,
      newTransactions,
      payableTransactions,
      receivedTransactions,
      referrals,
      taskDue,
      taskUpcoming,
      totalContacts,
      totalLeads,
      updatedTransactions
    } = this.state
    return (
      <Modal
        content={
          <Container>
            <Row justify="space-between" padding={'1.5em 2em 1em'}>
              <Title>Your Activity Snapshot</Title>
              <StyledForm width={-1}>
                <Label>{Strings.dashboard.passes.time}</Label>
                <StyledDropdown
                  name="timePeriod"
                  onChange={this.changeTimePeriod}
                  selection={true}
                  value={timePeriod}
                  options={OPTIONS}
                />
              </StyledForm>
            </Row>
            <TopPanel>
              <StyledCard background={'red'}>
                <Task>
                  <TaskLeftPanel>
                    <div className="task">
                      <div>
                        <FontAwesomeIcon icon={['far', 'tasks']} />
                      </div>
                      <div>{Strings.dashboard.passes.tasks}</div>
                    </div>
                    <DescriptionWrapper>
                      <Image src={SummaryPass3} size="small" />
                      <div className="trend">{Strings.dashboard.passes.trend}</div>
                      <div className="month">{`(Last ${timePeriod} Days)`}</div>
                    </DescriptionWrapper>
                  </TaskLeftPanel>
                  <TaskRightPanel>
                    <PastDue onClick={() => this.getTasks('Due')}>
                      <h2>{taskDue}</h2>
                      <div>
                        <FontAwesomeIcon icon={['fal', 'calendar-alt']} /> ({Strings.dashboard.passes.pastDue})
                      </div>
                    </PastDue>
                    <Upcoming onClick={() => this.getTasks('Upcoming')}>
                      <h2>{taskUpcoming}</h2>
                      <div>({Strings.dashboard.passes.upcoming})</div>
                    </Upcoming>
                  </TaskRightPanel>
                </Task>
              </StyledCard>
              <StyledCard background={'red'} style={{ marginLeft: 30, width: 400 }}>
                <Transaction>
                  <TaskLeftPanel>
                    <div className="task">
                      <div>
                        <FontAwesomeIcon icon={['fal', 'money-bill']} />
                      </div>
                      <div>{Strings.dashboard.passes.transaction}</div>
                    </div>
                    <DescriptionWrapper>
                      <Image src={SummaryPass3} size="small" />
                      <div className="trend">{Strings.dashboard.passes.trend}</div>
                      <div className="month">{`(Last ${timePeriod} Days)`}</div>
                    </DescriptionWrapper>
                  </TaskLeftPanel>
                  <TaskRightPanel>
                    <Table basic="very" celled={true} collapsing={true}>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Upcoming onClick={() => this.getTransactions('$$ Received')}>
                              <h2 style={{ color: Colors.Blue111 }}>{receivedTransactions}</h2>
                              <div>({Strings.dashboard.passes.received})</div>
                            </Upcoming>
                          </Table.Cell>
                          <Table.Cell>
                            <Upcoming onClick={() => this.getTransactions('Updated')}>
                              <h2 style={{ color: Colors.Magenta60 }}>{updatedTransactions}</h2>
                              <div>({Strings.dashboard.passes.updated})</div>
                            </Upcoming>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <Upcoming onClick={() => this.getTransactions('Payable')}>
                              <h2 style={{ color: Colors.Magenta50 }}>{payableTransactions}</h2>
                              <div>({Strings.dashboard.passes.payable})</div>
                            </Upcoming>
                          </Table.Cell>
                          <Table.Cell>
                            <Upcoming onClick={() => this.getTransactions('New')}>
                              <h2 style={{ color: Colors.Orange250 }}>{newTransactions}</h2>
                              <div>({Strings.dashboard.passes.new})</div>
                            </Upcoming>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </TaskRightPanel>
                </Transaction>
              </StyledCard>
            </TopPanel>
            <BottomPanel>
              <StyledCard background={'red'} style={{ width: 205 }}>
                <Contact onClick={this.getContacts}>
                  <ContactLeftPanel>
                    <div className="contact">
                      <div>
                        <FontAwesomeIcon icon={['fal', 'user-plus']} />
                      </div>
                      <div style={{ flex: 1 }}>{Strings.dashboard.passes.contactsAdded}</div>
                      <div style={{ marginRight: 15, fontSize: 30 }}>{totalContacts}</div>
                    </div>
                    <DescriptionWrapper>
                      <Image src={SummaryPass3} size="small" />
                      <div className="trend">{Strings.dashboard.passes.trend}</div>
                      <div className="month">{`(Last ${timePeriod} Days)`}</div>
                    </DescriptionWrapper>
                  </ContactLeftPanel>
                </Contact>
              </StyledCard>
              <StyledCard background={'red'} style={{ marginLeft: 30, width: 205, marginTop: 0, marginBottom: 0 }}>
                <Contact onClick={this.getUserProfile}>
                  <ContactLeftPanel>
                    <div className="referral">
                      <div style={{ width: '100%' }}>
                        <div>
                          <FontAwesomeIcon icon={['fal', 'user']} />
                        </div>
                        <div style={{ width: 10, marginTop: 5 }}>{Strings.dashboard.passes.referralsAccepted}</div>
                      </div>
                      <div className="referral1" style={{ fontSize: 30, marginRight: 15 }}>
                        {referrals}
                      </div>
                    </div>
                  </ContactLeftPanel>
                </Contact>
              </StyledCard>
              <StyledCard background={'red'} style={{ marginLeft: 30, width: 205, marginTop: 0, marginBottom: 0 }}>
                <Contact onClick={this.getLeads}>
                  <ContactLeftPanel>
                    <div className="contact">
                      <div>
                        <FontAwesomeIcon icon={['fal', 'users']} />
                      </div>
                      <div style={{ flex: 1 }}>{Strings.dashboard.passes.leadsGenerated}</div>
                      <div style={{ marginRight: 15, fontSize: 30 }}>{totalLeads}</div>
                    </div>
                    <DescriptionWrapper>
                      <Image src={SummaryPass3} size="small" />
                      <div className="trend">{Strings.dashboard.passes.trend}</div>
                      <div className="month">{`(Last ${timePeriod} Days)`}</div>
                    </DescriptionWrapper>
                  </ContactLeftPanel>
                </Contact>
              </StyledCard>
            </BottomPanel>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={755}
      />
    )
  }

  private getTimePeriodData = async (timePeriod: number = 30) => {
    const { pass } = this.props
    try {
      const response = await hotSheetPass(pass._id, timePeriod)
      this.setState({
        newTransactions: response[`newTransactions`],
        payableTransactions: response[`payableTransactions`],
        receivedTransactions: response[`receivedTransactions`],
        referrals: response[`referrals`],
        taskDue: response[`taskDue`],
        taskUpcoming: response[`taskUpcoming`],
        totalContacts: response[`totalContacts`],
        totalLeads: response[`totalLeads`],
        updatedTransactions: response[`updatedTransactions`]
      })
    } catch (error) {
      serverToast(error)
    }
  }

  private getTransactions = (status: string) => {
    const { setShowUserPassTransactionFilterData } = this.props
    const { userID, timePeriod } = this.state
    const newData = {
      status: status,
      timePeriod: timePeriod,
      userId: userID
    }
    setShowUserPassTransactionFilterData(newData)
    this.props.history.push(Routes.primary.transactions.path + '?previous-page=passFilter')
  }

  private getTasks = (type: string) => {
    const { setShowUserPassTaskFilterData } = this.props
    const { userID, timePeriod } = this.state
    const newData = {
      timePeriod: timePeriod,
      type: type,
      userId: userID
    }
    setShowUserPassTaskFilterData(newData)
    this.props.history.push(Routes.primary.tasks.path + '?previous-page=passFilter')
  }

  private getContacts = () => {
    const { setShowUserPassContactFilterData } = this.props
    const { userID, timePeriod } = this.state
    const newData = {
      timePeriod: timePeriod,
      userId: userID
    }
    setShowUserPassContactFilterData(newData)
    this.props.history.push(Routes.primary.contacts.path + '?previous-page=passFilter')
  }

  private getLeads = () => {
    const { setShowUserPassLeadFilterData } = this.props
    const { userID, timePeriod } = this.state
    const newData = {
      timePeriod: timePeriod,
      userId: userID
    }
    setShowUserPassLeadFilterData(newData)
    this.props.history.push(Routes.primary.leads.path + '?previous-page=passFilter')
  }

  private getUserProfile = () => {
    const { setShowUserPassProfileFilterData } = this.props
    const { userID, timePeriod } = this.state
    const newData = {
      timePeriod: timePeriod,
      userId: userID
    }
    setShowUserPassProfileFilterData(newData)
    this.props.history.push(Routes.primary.profile.path + `/${userID}?previous-page=passFilter`)
  }

  private changeTimePeriod = async (e: any, { value }: any) => {
    const { onUpdate, pass } = this.props
    this.setState({ timePeriod: value })
    this.getTimePeriodData(value)

    const input = { timePeriod: value }
    try {
      const response = await updateUserPass(pass._id, input)
      onUpdate(response)
    } catch {
      // userPass not updated
    }
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default withRouter(
  connect(
    null,
    {
      setShowUserPassContactFilterData: ContactActions.showUserPassContactFilterData,
      setShowUserPassLeadFilterData: LeadActions.showUserPassLeadFilterData,
      setShowUserPassProfileFilterData: UserProfileActions.showUserPassProfileFilterData,
      setShowUserPassTaskFilterData: TaskActions.showUserPassTaskFilterData,
      setShowUserPassTransactionFilterData: TransactionActions.showUserPassTransactionFilterData
    }
  )(ActivityHotSheetModal)
)
