// Import Packages
import moment from 'moment'
import * as React from 'react'
import DatePickers from 'react-datepicker'
import { Button, Dropdown, Form, Image, Input, Label, TextArea } from 'semantic-ui-react'

import 'react-datepicker/dist/react-datepicker.css'

// Import Components
import DatePicker from 'shared/DatePicker'
import Modal from 'shared/Modal'
import Toast from 'shared/Toast'
import { handleEventValidation } from './EventValidation'
import { handleValidation } from './validation'

import { LeadShareWith, OptionType } from 'store/Leads/Types'
import { FormErrorType, FormType, UserType } from './Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { AddForm, AddLeftPanel, Container, SelectTask, StyledForm, StyledLabel } from './Styled'

// Font Awesome Icons
import { faEnvelope, faHandshake, faPhone, faSms, faTicket } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone, faSms, faHandshake, faTicket])

interface Props {
  addWorkflowTask?: (task: FormType) => void
  addEvent: (event: FormType) => void
  closeModal: () => void
  edit?: boolean
  editDetailObj?: any
  transactionSharedUser: any
  user: UserType
}

interface State {
  form: FormType
  selectTask: number
  openDatePicker: boolean
  userMultiOptions: OptionType[]
  sharedWith: LeadShareWith[]
  animate: boolean
  openStartDatePicker: boolean
  openEndDatePicker: boolean
  startTime: Date
  endTime: Date
  taskTime: Date
}

const taskIcons = [
  { name: 'phone', value: 'Call' },
  { name: 'envelope', value: 'Email' },
  { name: 'sms', value: 'Sms' },
  { name: 'handshake', value: 'Meeting' },
  { name: 'ticket', value: 'Pass' }
]

class AddWorkflowTasksModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    endTime: new Date(),
    form: {
      errors: {} as FormErrorType
    } as FormType,
    openDatePicker: false,
    openEndDatePicker: false,
    openStartDatePicker: false,
    selectTask: 0,
    sharedWith: [],
    startTime: new Date(),
    taskTime: new Date(),
    userMultiOptions: []
  }

  public async componentDidMount() {
    const { edit, editDetailObj, transactionSharedUser } = this.props
    const usersOpt: any = []
    transactionSharedUser.forEach((element: any) => {
      const multiObj: any = {
        content: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Image src={element.profileImage} avatar={true} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {element.firstName} {element.lastName}
              </div>
              <div>{element.emails && element.emails[0] ? element.emails[0].value : ''}</div>
            </div>
          </div>
        ),
        key: element._id,
        text: (
          <Label
            content={element.firstName}
            onRemove={(e: any, lp: any) => {
              e.stopPropagation()
              this.removeCard(lp.value)
            }}
          />
        ),
        value: element._id
      }
      usersOpt.push(multiObj)
    })
    if (!edit) {
      this.setState({
        form: {
          ...editDetailObj,
          endDate: moment().format('L'),
          endTime: moment().format('LT'),
          errors: {} as FormErrorType,
          startDate: moment().format('L'),
          startTime: moment().format('LT'),
          taskTime: editDetailObj.taskTime
        },
        sharedWith: []
      })
    }
    this.setState({ userMultiOptions: usersOpt })
    if (edit && editDetailObj) {
      const date: any = moment(editDetailObj.taskTime)
      const editSharedWith: any = []
      if (editDetailObj.sharedWith) {
        editDetailObj.sharedWith.forEach((obj: any) => {
          editSharedWith.push(obj._id)
        })
      }
      const index = taskIcons.findIndex((task: any) => {
        return task.value === editDetailObj.type
      })
      if (editDetailObj.taskTime !== null) {
        this.setState({
          endTime: editDetailObj.end,
          form: {
            ...editDetailObj,
            dueDate: editDetailObj.dueDate,
            endDate: editDetailObj.end,
            endTime: moment(editDetailObj.end).format('LT'),
            errors: {} as FormErrorType,
            sharedWith: editSharedWith,
            startDate: editDetailObj.start,
            startTime: moment(editDetailObj.start).format('LT'),
            taskTime: editDetailObj.taskTime
          },
          selectTask: index,
          sharedWith: editSharedWith,
          startTime: editDetailObj.start,
          taskTime: date._d
        })
      } else {
        this.setState({
          endTime: editDetailObj.end,
          form: {
            ...editDetailObj,
            dueDate: editDetailObj.dueDate,
            endDate: editDetailObj.end,
            endTime: moment(editDetailObj.end).format('LT'),
            errors: {} as FormErrorType,
            sharedWith: editSharedWith,
            startDate: editDetailObj.start,
            startTime: moment(editDetailObj.start).format('LT')
          },
          selectTask: index,
          sharedWith: editSharedWith,
          startTime: editDetailObj.start
        })
      }
    }
  }

  public render() {
    const {
      form,
      form: { errors },
      selectTask,
      openDatePicker,
      userMultiOptions,
      sharedWith,
      animate,
      openStartDatePicker,
      openEndDatePicker,
      startTime,
      endTime,
      taskTime
    } = this.state
    const taskIcon = taskIcons.map((items: any, index: number) => {
      const selectTaskFucntion = () => {
        this.selectTask(index)
      }
      return (
        <div key={index}>
          {selectTask === index ? (
            <div className="selectTask">
              <FontAwesomeIcon icon={['far', items.name]} />
            </div>
          ) : (
            <div className="task" onClick={selectTaskFucntion}>
              <FontAwesomeIcon icon={['far', items.name]} />
            </div>
          )}
        </div>
      )
    })
    return (
      <Modal
        content={
          <Container>
            <AddLeftPanel>
              {selectTask === 4 ? (
                <Image src={Strings.eventsPopup.src} size="small" />
              ) : (
                <Image src={Strings.tasks.src} size="small" />
              )}
            </AddLeftPanel>
            <AddForm>
              <span>{Strings.tasks.selectTask}</span>
              <SelectTask>{taskIcon}</SelectTask>
              {selectTask === 4 ? (
                <StyledForm size={'mini'}>
                  <Form.Field
                    error={!!errors.name}
                    control={Input}
                    label="Event Name"
                    name="name"
                    value={form.name}
                    placeholder="Event Name"
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    error={!!errors.description}
                    control={TextArea}
                    label="Event Description"
                    name="description"
                    value={form.description}
                    placeholder="Description"
                    onChange={this.handleChange}
                  />
                  <Form.Group widths="equal">
                    <Form.Field
                      error={!!errors.startDate}
                      control={Input}
                      className="duedate"
                      name="startDate"
                      value={form.startDate ? moment(form.startDate).format('L') : ''}
                      autoComplete="off"
                      label="Start Date"
                      onClick={this.handleStartDateOpen}
                      action={{
                        icon: (
                          <DatePicker
                            minDate={true}
                            date={new Date()}
                            onChangeDate={this.onChangeStartDate}
                            open={openStartDatePicker}
                            handleOpen={this.handleStartDateOpen}
                            handleClose={this.handleClose}
                          />
                        ),
                        onClick: this.handleStartDateOpen
                      }}
                      placeholder="Select Start Date"
                    />
                    <Form.Field>
                      <label>Start Time</label>
                      <DatePickers
                        selected={startTime}
                        onChange={this.startTime}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      error={!!errors.endDate}
                      control={Input}
                      className="duedate"
                      name="endDate"
                      value={form.endDate ? moment(form.endDate).format('L') : ''}
                      autoComplete="off"
                      label="End Date"
                      onClick={this.handleEndDateOpen}
                      action={{
                        icon: (
                          <DatePicker
                            minDate={true}
                            date={new Date()}
                            onChangeDate={this.onChangeEndDate}
                            open={openEndDatePicker}
                            handleOpen={this.handleEndDateOpen}
                            handleClose={this.handleClose}
                          />
                        ),
                        onClick: this.handleEndDateOpen
                      }}
                      placeholder="Select End Date"
                    />
                    <Form.Field>
                      <label>End Time</label>
                      <DatePickers
                        selected={endTime}
                        onChange={this.endTime}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <StyledLabel>{Strings.tasks.sharedWith}</StyledLabel>
                    <Dropdown
                      error={errors[`sharedWith`] ? true : false}
                      name="sharedWith"
                      placeholder="Shared With"
                      multiple={true}
                      value={sharedWith}
                      fluid={true}
                      selection={true}
                      clearable={true}
                      options={userMultiOptions}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Button className="save-button" onClick={this.addNewEvent} content="SAVE EVENT" />
                </StyledForm>
              ) : (
                <StyledForm size={'mini'}>
                  <Form.Field
                    error={!!errors.name}
                    control={Input}
                    label="Task Name"
                    name="name"
                    value={form.name}
                    placeholder="Task Name"
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    error={!!errors.description}
                    control={TextArea}
                    label="Task Description"
                    name="description"
                    value={form.description}
                    placeholder="Description"
                    onChange={this.handleChange}
                  />
                  <Form.Group widths="equal">
                    <Form.Field
                      error={!!errors.dueDate}
                      control={Input}
                      className="duedate"
                      name="dueDate"
                      value={form.dueDate ? moment(form.dueDate).format('L') : ''}
                      autoComplete="off"
                      label="Due Date"
                      onClick={this.handleOpen}
                      action={{
                        icon: (
                          <DatePicker
                            minDate={true}
                            date={new Date()}
                            onChangeDate={this.onChangeDueDate}
                            open={openDatePicker}
                            handleOpen={this.handleOpen}
                            handleClose={this.handleClose}
                          />
                        ),
                        onClick: this.handleOpen
                      }}
                      placeholder="Select Date"
                    />
                    <Form.Field>
                      <label>Task Time</label>
                      <DatePickers
                        selected={taskTime}
                        onChange={this.taskTime}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <StyledLabel>{Strings.tasks.sharedWith}</StyledLabel>
                    <Dropdown
                      error={!!errors.sharedWith}
                      name="sharedWith"
                      placeholder="Shared With"
                      multiple={true}
                      value={sharedWith}
                      fluid={true}
                      selection={true}
                      clearable={true}
                      options={userMultiOptions}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Button className="save-button" onClick={this.addNewCard} content="SAVE TASK" />
                </StyledForm>
              )}
            </AddForm>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={700}
      />
    )
  }

  private startTime = (time: Date) => {
    const { form, endTime }: any = this.state
    if (moment(endTime).isSameOrAfter(time)) {
      form[`startTime`] = moment(time).format('LT')
      this.setState({ form, startTime: time })
    } else {
      Toast({ message: 'Invalid Start Time', type: 'error' })
    }
  }

  private endTime = (time: Date) => {
    const { form, startTime }: any = this.state
    if (moment(time).isSameOrAfter(startTime)) {
      form[`endTime`] = moment(time).format('LT')
      this.setState({ form, endTime: time })
    } else {
      Toast({ message: 'Invalid End Time', type: 'error' })
    }
  }

  private taskTime = (time: Date) => {
    const { form }: any = this.state
    form[`taskTime`] = time
    this.setState({ form, taskTime: time })
  }

  private onChangeDueDate = (date: string) => {
    const { form } = this.state
    form[`dueDate`] = date
    this.setState({ form })
    this.handleClose()
  }

  private onChangeStartDate = (date: string) => {
    const { form } = this.state
    if (moment(moment(date).format('YYYY-MM-DD')).isSameOrBefore(moment(form[`endDate`]).format('YYYY-MM-DD'))) {
      form[`startDate`] = date
      this.setState({ form })
      this.handleClose()
    } else {
      Toast({ message: 'Invalid Start date', type: 'error' })
      this.handleClose()
    }
  }

  private onChangeEndDate = (date: string) => {
    const { form } = this.state
    if (moment(moment(form[`startDate`]).format('YYYY-MM-DD')).isSameOrBefore(moment(date).format('YYYY-MM-DD'))) {
      form[`endDate`] = date
      this.setState({ form })
      this.handleClose()
    } else {
      Toast({ message: 'Invalid end date', type: 'error' })
      this.handleClose()
    }
  }

  private handleOpen = () => {
    this.setState({ openDatePicker: true })
  }

  private handleStartDateOpen = () => {
    this.setState({ openStartDatePicker: true })
  }

  private handleEndDateOpen = () => {
    this.setState({ openEndDatePicker: true })
  }

  private handleClose = () => {
    this.setState({ openDatePicker: false, openStartDatePicker: false, openEndDatePicker: false })
  }

  private removeCard = (data: any) => {
    const { sharedWith, form }: any = this.state
    const newSharedWith = sharedWith.filter((lang: string) => lang !== data)
    form[`sharedWith`] = newSharedWith
    this.setState({ sharedWith: newSharedWith, form })
  }

  private selectTask = (index: number) => {
    const { form } = this.state
    form[`type`] = taskIcons[index].value
    this.setState({ selectTask: index, form })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    if (name === 'sharedWith') {
      let { sharedWith }: any = this.state
      sharedWith = value
      form[name] = value
      this.setState({ form, sharedWith })
    } else {
      form[name] = value
      this.setState({ form })
    }
  }

  private addNewCard = () => {
    const { form, selectTask } = this.state
    const { addWorkflowTask } = this.props
    form[`type`] = taskIcons[selectTask].value
    if (form[`taskTime`] === undefined) {
      form[`taskTime`] = moment().format()
    }

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    if (addWorkflowTask) {
      addWorkflowTask(form)
      this.closeModal()
    }
    this.closeModal()
  }

  private addNewEvent = () => {
    const { form, selectTask } = this.state
    const { addEvent } = this.props
    form[`type`] = taskIcons[selectTask].value

    const validation = handleEventValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    if (addEvent) {
      addEvent(form)
      this.closeModal()
    }
    this.closeModal()
  }
}

export default AddWorkflowTasksModal
