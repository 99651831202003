// Import Packages
import * as React from 'react'

// Import Styled Components
import { Icon, IconContainer, ItemContainer, Text, Title } from './Styled'

interface Props {
  action: () => void
  icon?: string
  icons?: any
  title: string
  text?: string
}

const ActionItem = ({ action, title, text, icon, icons }: Props) => (
  <ItemContainer onClick={action}>
    <IconContainer>{icons ? icons : <Icon backgroundImage={icon} />}</IconContainer>
    <div>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </div>
  </ItemContainer>
)

export default ActionItem
