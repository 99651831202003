// Import Packages
import * as React from 'react'
import { Button, Form, Image, Select } from 'semantic-ui-react'
import { VictoryAnimation, VictoryLabel, VictoryPie } from 'victory'

// Import Images and Icons
import CheckDog from 'design/icons/teams/check_dog.png'
import Dog from 'design/icons/teams/dog.png'

import Modal from 'shared/Modal'

// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import { OptionsType, Percents } from 'store/Offices/Types'

// Import Utils
import { Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  AddContactLeftPanel,
  AddForm,
  Container,
  Percent,
  PercentContainer,
  ProgressContainer,
  StyledForm,
  Text
} from './Styled'

interface Props {
  closeModal: () => void
  deleteOffice: (newOfficeId: string) => void
  deactivateOffice: (officeId: string) => void
  officeId: string
  boardId: string
  isList: boolean
}

interface State {
  offices: OptionsType[]
  newOffice: string
  data: Percents[]
  percent: number
  startTimer: boolean
  animate: boolean
}

let setStateInterval = 0

const getData = (percent: number) => {
  return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }]
}

class DeleteOfficeModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    data: getData(0) as [],
    newOffice: '',
    offices: [],
    percent: 0,
    startTimer: false
  }

  public startCount = () => {
    let percent = 0
    const { newOffice } = this.state
    if (newOffice) {
      this.setState({ startTimer: true })
      setStateInterval = window.setInterval(() => {
        percent += Math.random() * 60
        percent = percent >= 100 ? 100 : percent
        if (percent === 100) {
          window.clearInterval(setStateInterval)
          this.deleteOffice()
        }
        this.setState({
          data: getData(percent) as [],
          percent
        })
      }, 2000)
    }
  }

  public componentWillUnmount() {
    window.clearInterval(setStateInterval)
  }

  public async componentWillMount() {
    const { officeId } = this.props
    const offices = await client.query({
      query: GET_OFFICES
    })
    const { data }: any = offices
    const officeList: OptionsType[] = []
    if (data && data.getOffices) {
      data.getOffices.forEach((office: any) => {
        if (office._id !== officeId) {
          officeList.push({
            key: office._id,
            text: office.branchName,
            value: `${office.branchName}, ${office._id}`
          })
        }
      })
    }
    this.setState({ offices: officeList })
  }

  public render() {
    const { animate } = this.state
    return (
      <Modal
        content={
          <Container>
            <AddForm>
              <StyledForm>
                <Text>{Strings.office.migrate}</Text>
                <Form.Field
                  control={Select}
                  label="Migrate Users To"
                  name="office"
                  placeholder="Select Office"
                  options={this.state.offices}
                  onChange={this.handleChange}
                />
                <Button type="button" onClick={this.startCount} content="MIGRATE" />
                <Button type="button" onClick={this.deactivateOffice} content="DEACTIVATE" />
              </StyledForm>
            </AddForm>
            <AddContactLeftPanel>
              <ProgressContainer>
                <div>
                  <svg viewBox="0 0 400 400" width="100%" height="100%">
                    <VictoryPie
                      standalone={false}
                      animate={{ duration: 1000 }}
                      width={400}
                      height={400}
                      data={this.state.data}
                      innerRadius={135}
                      cornerRadius={25}
                      colorScale={[Colors.DarkBlue200, Colors.Grey335]}
                      style={{
                        labels: {
                          fill: Colors.Grey110
                        }
                      }}
                      startAngle={0}
                      endAngle={360}
                    />
                    <VictoryAnimation duration={1000} data={this.state.data}>
                      {(newProps: any) => {
                        return (
                          <VictoryLabel
                            textAnchor="end"
                            verticalAnchor="end"
                            x={200}
                            y={200}
                            style={{ fontSize: 30 }}
                          />
                        )
                      }}
                    </VictoryAnimation>
                  </svg>
                  <PercentContainer>
                    <Image src={this.state.percent === 100 ? CheckDog : Dog} size="small" />
                    {this.state.startTimer && <Percent>{`Transfer - ${Math.round(this.state.percent)}%`}</Percent>}
                  </PercentContainer>
                </div>
              </ProgressContainer>
            </AddContactLeftPanel>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        minWidth={700}
        width={700}
      />
    )
  }

  private handleChange = async (event: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const res = value.split(', ')
    this.setState({ newOffice: res[1] })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private deleteOffice = () => {
    const { deleteOffice } = this.props
    const { newOffice } = this.state
    deleteOffice(newOffice)
    this.closeModal()
  }

  private deactivateOffice = () => {
    const { officeId, deactivateOffice } = this.props
    deactivateOffice(officeId)
    this.closeModal()
  }
}

export default DeleteOfficeModal
