// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Table } from 'semantic-ui-react'

import { StyledTable, TableContainer } from './Styled'

interface Props {
  task: any
  workflow: any
}

export default class Tables extends React.Component<Props> {
  public state = {}

  public render() {
    const { task, workflow } = this.props
    return (
      <TableContainer>
        <StyledTable singleLine={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Task</Table.HeaderCell>
              <Table.HeaderCell>Task Description</Table.HeaderCell>
              <Table.HeaderCell>Due</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="body">
            <Table.Row>
              <Table.Cell
                colspan="3"
                style={{
                  background: 'rgba(34,36,38,.1)',
                  fontSize: 14,
                  fontWeight: 500,
                  textAlign: 'center'
                }}
              >
                Selected Workflow Task
              </Table.Cell>
            </Table.Row>
            {task.map((items: any, index: number) => (
              <Table.Row key={index}>
                <Table.Cell>{items.name}</Table.Cell>
                <Table.Cell>{items.description}</Table.Cell>
                <Table.Cell>in {items.dueDate} days</Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell
                colspan="3"
                style={{
                  background: 'rgba(34,36,38,.1)',
                  fontSize: 14,
                  fontWeight: 500,
                  textAlign: 'center'
                }}
              >
                Existing Workflow Task
              </Table.Cell>
            </Table.Row>
            {workflow.map((items: any, index: number) => (
              <Table.Row key={index}>
                <Table.Cell>{items.name}</Table.Cell>
                <Table.Cell>{items.description}</Table.Cell>
                <Table.Cell>in {moment().to(items.dueDate, true)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </StyledTable>
      </TableContainer>
    )
  }
}
