// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

// Import Components
import Toast from 'shared/Toast/Toast'
import { addMessage, deleteMessages, readMessages, updateMessage } from '../../../Dashboard/TransactionMutations'
import { getTransactionMessage, getUnreadMessageCount } from '../../../Dashboard/TransactionQueries'
import NotesList from './NotesList'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'
import { Messages } from 'store/Transactions/Types'

// Import Styled Components
import { MessagesWrapper } from './Styled'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

interface StoreProps {
  setNewMessage: (newData: Messages) => void
  setUpdateMessage: (id: string, newData: Messages) => void
  setMessagesData: (messages: Messages[]) => void
  setDeleteMessage: (id: string) => void
  setMessageCount: (count: number) => void
  messages: Messages[]
}

interface OwnProps {
  transactionId: string
}

type Props = OwnProps & StoreProps

interface State {
  owner: string
  loader: boolean
}

class Notes extends React.Component<Props, State> {
  public state = {
    loader: false,
    owner: ''
  }

  public async componentDidMount() {
    const { setMessagesData, setMessageCount, transactionId } = this.props

    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({ owner: user._id })
    const messages = await getTransactionMessage(transactionId)
    setMessagesData(messages)
    await readMessages(transactionId, user._id)
    const count = await getUnreadMessageCount(transactionId, user._id)
    setMessageCount(count)
  }

  public render() {
    const { messages } = this.props
    const { owner, loader } = this.state
    return (
      <MessagesWrapper>
        <NotesList
          loader={loader}
          owner={owner}
          messageData={messages}
          addNewMessage={this.addNewMessage}
          editMessage={this.editMessage}
          deleteMessage={this.deleteMessage}
        />
      </MessagesWrapper>
    )
  }

  private deleteMessage = async (id: string) => {
    const { setDeleteMessage } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.leads.alert.noKeepIt,
      confirmButtonText: Strings.leads.alert.yesDeleteIt,
      showCancelButton: true,
      text: 'You will not be able to recover this note',
      title: Strings.leads.alert.sure,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        try {
          await deleteMessages(id)
          setDeleteMessage(id)
          ConfirmAlert('Deleted!', 'Your note has been deleted.', 'success')
          this.setState({ loader: false })
        } catch (error) {
          this.setState({ loader: false })
          Toast({ message: error.message, type: 'error' })
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.leads.alert.cancelled, 'Your note is safe.', 'success')
      }
    })
  }

  private editMessage = async (id: string, note: string) => {
    const { setUpdateMessage } = this.props
    this.setState({ loader: true })
    try {
      const data = await updateMessage(id, note)
      setUpdateMessage(id, data)
      if (data) {
        this.setState({ loader: false })
      }
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }

  private addNewMessage = async (message: string) => {
    const { setNewMessage, transactionId } = this.props
    this.setState({ loader: true })
    try {
      const data = await addMessage(transactionId, message)
      setNewMessage(data)
      if (data) {
        this.setState({ loader: false })
      }
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  messages: state.transactions.messages
})

export default connect(
  mapStateToProps,
  {
    setDeleteMessage: Actions.deleteMessage,
    setMessageCount: Actions.messageCount,
    setMessagesData: Actions.getMessagesData,
    setNewMessage: Actions.addNewMessage,
    setUpdateMessage: Actions.editMessage
  }
)(Notes)
