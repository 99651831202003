import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, icon, styledToolTip, text, x, y } from 'design/Styled'

export const Container = styled.div`
  ${y};
  ${text};
  height: 100%;
  overflow: hidden;
  background: ${Colors.Container};

  .rp-dropzone-container {
    overflow: hidden;
  }
`

export const Header = styled.header`
  ${x};
`

export const Text = styled.strong`
  ${text};
`

export const Col = styled.section`
  ${y};
  height: 100%;
`

export const Row = styled.div`
  ${x};
  ${children};
  width: auto;
`

export const Title = styled.h2`
  ${text};
  font: ${fonts.large.regular};
  color: ${Colors.TextLink};
  text-align: center;
`

export const Nav = styled.nav`
  ${icon};
  padding: 4px;
  color: ${Colors.TextLight};
  background: ${Colors.ButtonPrimary};
  border-radius: 50%;
  width: 22px;
  height: 22px;
  justify-content: center;

  &.previous {
    svg {
      position: relative;
      left: -1px;
    }
  }

  &.next {
    svg {
      position: relative;
      left: 1px;
    }
  }
`

export const Close = styled.nav`
  ${icon};
  padding: 0;
  color: ${Colors.Text};
`

export const Image = styled.img`
  display: block;
  height: 100%;
  margin: 0 auto;
`

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`
