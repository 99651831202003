export interface AddressType {
  city: string
  country?: string
  state: string
  streetAddress: string
  streetName: string
  streetNumber?: string
  zipCode: string
}

export interface AddressErrorType {
  city?: string
  country?: string
  state?: string
  streetAddress?: string
  streetName?: string
  streetNumber?: string
  zipCode?: string
}

export interface AddressValidationType {
  city: string
  state: string
  streetName: string
  streetNumber: string
  zipCode: string
}

export interface AlgoliaSuggestionType {
  administrative: string[]
  city: string[]
  locale_names: string[]
  objectID: string
  postcode: string[]
}

export interface AlgoliaErrorType {
  message: string
}

export interface ResultType {
  data: AddressType
  title: string
}

export interface SearchParamsType {
  aroundLatLng?: string
  aroundRadius?: number
  countries: string
  hitsPerPage?: number
  language: string
  query: string
  type: AddressModeEnum
}

export interface CityStateParamType {
  city: string
  state: string
}

export enum AddressModeEnum {
  City = 'city',
  Address = 'address'
}

export enum AddressValueEnum {
  city = 'city',
  address = 'streetAddress'
}

export enum AddressResultEnum {
  city = 'is_city',
  address = 'is_highway'
}
