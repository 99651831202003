import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import { children, primaryButton, x, y } from 'design/Styled'

export const Container = styled.section`
  ${x};
  ${children};
  flex: 1;
  align-items: stretch;
  padding: 1em;
  overflow: hidden;
`

export const Section = styled.section`
  ${y};
`

export const Row = styled.div`
  ${x};
  ${children};
  margin-bottom: 0.8em;
`

export const Return = styled(Button)`
  &.ui.button {
    ${primaryButton};
    margin-left: 1em;
  }
`
