// Import Packages
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

// Import Images and Icons
import { gopass, propass } from 'design/images/onboarding'

// Import Utils
import { Routes } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  BGGradient,
  ColorDivider,
  ColoredTitle,
  Divider,
  Feature,
  FlexWrapper,
  Header,
  HeaderBold,
  Pass,
  PassContainer,
  PassContent,
  PassTitle,
  PassTitleBold,
  SkipBtn,
  SubTitle
} from './Styled'

interface Props extends RouteComponentProps<{}> { }

class PassSelection extends React.Component<Props> {
  public render() {
    return (
      <PassContainer>
        <Header>
          Get Your Plan <HeaderBold>in Motion</HeaderBold>
        </Header>
        <BGGradient />
        <FlexWrapper>
          <Pass>
            <PassContent img={gopass}>
              <PassTitle>
                <PassTitleBold>Go</PassTitleBold>
                Pass
              </PassTitle>
              <SubTitle>includes all these key features:</SubTitle>
              <ColorDivider color={Colors.Green500} />
              <Feature>10 Digital Signatures</Feature>
              <Feature>Realty Pass Branding</Feature>
              <Feature>Unlimited Users</Feature>
              <Feature>Unlimited Transactions</Feature>
              <Feature>Unlimited Workflows</Feature>
              <Feature>Paperless Office Solution</Feature>
              <Feature>Lead Distribution</Feature>
              <Feature>Automated Tax Reporting</Feature>
              <Feature>Team & Brokerage Support</Feature>
              <Feature>Dozens of Integrations</Feature>
              <ColoredTitle color={Colors.Green500}>FREE</ColoredTitle>
            </PassContent>
            <Button style={{ zIndex: 10 }} onClick={this.submitPass}>
              Select
            </Button>
          </Pass>
          <Divider />
          <Pass>
            <PassContent img={propass}>
              <PassTitle>
                <PassTitleBold>Pro</PassTitleBold>
                Pass
              </PassTitle>
              <SubTitle>includes all gopass features, plus:</SubTitle>
              <ColorDivider color={Colors.Orange100} />
              <Feature>Unlimited Digital Signatures</Feature>
              <Feature>Custom Branding</Feature>
              <Feature>Customized Dashboard</Feature>
              <Feature>Enhanced Tax Reporting</Feature>
              <Feature>Unlimited Storage</Feature>
              <Feature>National Leads Program</Feature>
              <Feature>Marketing Suite</Feature>
              <Feature>Premium Feature Discounts</Feature>
              <Feature>Other widgets TBD</Feature>
              <Feature>Other integrations TBD</Feature>
              <ColoredTitle color={Colors.Orange100}>$199</ColoredTitle>
            </PassContent>
            <Button style={{ zIndex: 10 }} onClick={this.submitPass}>
              Select
            </Button>
          </Pass>
        </FlexWrapper>
        <SkipBtn onClick={this.skipToEnd}>skip</SkipBtn>
      </PassContainer>
    )
  }

  private submitPass = () => {
    this.props.history.push(
      Routes.onboarding.root + Routes.onboarding.welcome.path
    )
  }

  private skipToEnd = () => {
    this.props.history.push(
      Routes.onboarding.root + Routes.onboarding.welcome.path
    )
  }
}

export default withRouter(PassSelection)
