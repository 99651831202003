// import { debounce } from 'lodash'
import * as React from 'react'

import { Strings } from 'utils'
import Toast from 'shared/Toast/Toast'
import SensitiveInput from 'shared/SensitiveInput'

import { getUserProfile } from './Queries'
import { FormType, UserType, PaymentMethodEnum, FormErrorType } from './Types'
import { updateProfile } from 'app/UserProfile/UserProfileMutations'

import {
  Container,
  CustomButtonDisplay,
  CustomErrorDisplay,
  Item,
  Label,
  Row,
  StyledDropdown,
  StyledInput,
} from './Styled'
import { Button } from 'semantic-ui-react'

const PaymentInfoStrings = Strings.userProfile.paymentInfo

interface Props {
  userID: string
  prevComp?:string
  closeModal?:any
}

interface State {
  form: FormType
  user: UserType
  buttonFlag: boolean
  loading: boolean
}

const PAYMENT_METHOD_OPTIONS = [
  { key: PaymentMethodEnum.Direct, value: PaymentMethodEnum.Direct, text: PaymentMethodEnum.Direct },
  { key: PaymentMethodEnum.Ach, value: PaymentMethodEnum.Ach, text: PaymentMethodEnum.Ach },
  { key: PaymentMethodEnum.Check, value: PaymentMethodEnum.Check, text: PaymentMethodEnum.Check },
  { key: PaymentMethodEnum.PayPal, value: PaymentMethodEnum.PayPal, text: PaymentMethodEnum.PayPal }
]

export default class PaymentInfo extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    user: {} as UserType,
    form: {} as FormType,
    buttonFlag: false,
    loading: false
  }

  public async componentDidMount() {
    const { userID } = this.props

    const user: UserType = await getUserProfile(userID) || {} as UserType
    const form: FormType = {
      accountNumber: user.accountNumber || '',
      bankName: user.bankName || '',
      routingNumber: user.routingNumber || '',
      ein: user.ein || '',
      paymentMethod: user.paymentMethod || '',
      taxID: user.taxID || '',
      taxReportName: user.taxReportName || '',
      errors: {} as FormErrorType
    }

    let buttonFlag = false

    for (let key in form) {
      
      if (!form[key]) { 
        buttonFlag = true
      }
    }

    this.setState({
      form,
      user,
      buttonFlag
    })
  }

  public render() {
    const {
      form,
      user,
    } = this.state
    const {prevComp} = this.props

    return (
      <Container>
        {prevComp==='dashboard' && <p>Please fill the Tax ID and Payment Method</p>}
       <div className='dashboard'>
        <Row className='dashboard__row'>
          <Label>{PaymentInfoStrings.taxReportingName} :</Label>
          <Item className='dashboard__col'>
            <CustomErrorDisplay>
            <StyledInput
              placeholder={PaymentInfoStrings.taxReportingName}
              name='taxReportName'
              value={form.taxReportName}
              onChange={this.handleChange}
              error={!!form?.errors?.taxReportName}
              disabled = {this.state.loading}
            />
            <div className='custom-error'>{form?.errors?.taxReportName}</div>
            </CustomErrorDisplay>
          </Item>
        </Row>
        <Row className='dashboard__row'>
          <Label>{PaymentInfoStrings.einNumber} :</Label>
          <Item className='dashboard__col'>
            <CustomErrorDisplay>
            <SensitiveInput
              mask='99-9999999'
              maxLength={9}
              name='ein'
              onChange={this.handleChange}
              placeholder={PaymentInfoStrings.einNumber}
              value={form.ein}
              error={!!form?.errors?.ein}
              disabled = {this.state.loading}
            />
            <div className='custom-error'>{form?.errors?.ein}</div>
            </CustomErrorDisplay>
          </Item>
        </Row>
        <Row className='dashboard__row'>
          <Label>{PaymentInfoStrings.taxId} :</Label>
          <Item className='dashboard__col'>
          <CustomErrorDisplay>
            <SensitiveInput
              mask='999-99-9999'
              maxLength={9}
              name='taxID'
              onChange={this.handleChange}
              placeholder={PaymentInfoStrings.taxId}
              value={form.taxID}
              error={!!form?.errors?.taxID}
              disabled = {this.state.loading}
            />
            <div className='custom-error'>{form?.errors?.taxID}</div>
            </CustomErrorDisplay>
          </Item>
        
        </Row>
        <Row className='dashboard__row'>
          <Label>{PaymentInfoStrings.paymentMethod} :</Label>
          <Item className='dashboard__col'>
            <CustomErrorDisplay>
            <StyledDropdown
              disabled={user.role === 'GUEST' || this.state.loading}
              name='paymentMethod'
              placeholder='Payment Method'
              selection={true}
              value={form.paymentMethod}
              options={PAYMENT_METHOD_OPTIONS}
              onChange={this.handlePaymentMethodChange}
              error={!!form?.errors?.paymentMethod}
            />
              <div className='custom-error'>{form?.errors?.paymentMethod}</div>
            </CustomErrorDisplay>
          </Item>
        
        </Row>
        {(form.paymentMethod === PaymentMethodEnum.Ach || form.paymentMethod === PaymentMethodEnum.Direct) && (
          <React.Fragment>
            <Row className='dashboard__row'>
              <Label>{PaymentInfoStrings.bankName} :</Label>
              <Item className='dashboard__col'>
                <CustomErrorDisplay>
                <StyledInput
                  placeholder={PaymentInfoStrings.bankName}
                  name='bankName'
                  value={form.bankName}
                  onChange={this.handleChange}
                  error={!!form?.errors?.bankName}
                  disabled = {this.state.loading}
                />
                  <div className='custom-error'>{form?.errors?.bankName}</div>
               </CustomErrorDisplay>
              </Item>
            
            </Row>
            <Row className='dashboard__row'>
              <Label>{PaymentInfoStrings.routingNumber} :</Label>
              <Item className='dashboard__col'>
              <CustomErrorDisplay>
                <SensitiveInput
                  placeholder={PaymentInfoStrings.routingNumber}
                  name='routingNumber'
                  value={form.routingNumber}
                  onChange={this.handleChange}
                  error={!!form?.errors?.routingNumber}
                  disabled = {this.state.loading}
                />
                <div className='custom-error'>{form?.errors?.routingNumber}</div>
                </CustomErrorDisplay>
              </Item>
              
            </Row>
            <Row className='dashboard__row'>
              <Label>{PaymentInfoStrings.accountNumber} :</Label>
              <Item className='dashboard__col'>
                <CustomErrorDisplay>
                <SensitiveInput
                  placeholder={PaymentInfoStrings.accountNumber}
                  name='accountNumber'
                  value={form.accountNumber}
                  onChange={this.handleChange}
                  error={!!form?.errors?.accountNumber}
                  disabled = {this.state.loading}
                />
                  <div className='custom-error'>{form?.errors?.accountNumber}</div>
                </CustomErrorDisplay>
              </Item>
            
            </Row>
          </React.Fragment>
        )}
        </div>
        <CustomButtonDisplay>
        <Button type="button" onClick={this.handleSubmit} content={'SAVE'} disabled={this.state.buttonFlag || this.state.loading}  loading={this.state.loading} />
        </CustomButtonDisplay>
      </Container>
    )
  }

  private handleSubmit=async()=>{
    const {form, user: { _id }} = this.state
    for (let item in form){
      let itemValue = form[item]
      if(typeof itemValue === 'string'){
        itemValue=itemValue.trim()
        form[item] = itemValue
      }
    }
    try{
      this.setState({loading: true})
      await updateProfile(form, { _id })
      Toast({ message: 'Account information saved successfully', type: 'success' })
      this.props.closeModal()
    }catch(error){
    }finally{
      this.setState({loading: false})
    }
  }

  // private handleUpdate = async () => {
  //   const { user: { _id }, form } = this.state

  //   if (this.debounceJob) {
  //     this.debounceJob.cancel()
  //   }

  //   this.debounceJob = debounce(async () => {
  //     await updateProfile(form, { _id })
  //   }, 1000)

  //   this.debounceJob()
  // }

  private handleChange = async (e: React.SyntheticEvent<HTMLDivElement> | any, { name, value }: any) => {
    const { form } = this.state // const {form, user} = this.state
    form[name] = value

    if(!form[name]){
      form.errors = { ...form.errors, [name]: 'Field cannot be empty' }
    } else{
      form.errors = { ...form.errors, [name]: '' }
    }

    const isFieldEmpty = (() => {
       
      if (form.paymentMethod === PaymentMethodEnum.Ach || form.paymentMethod === PaymentMethodEnum.Direct) {
        return [form.routingNumber, form.bankName, form.accountNumber, form.taxReportName].some(val => {
          return val?.trim() === ''})
      } else {
        return [form.ein,form.paymentMethod, form.taxID, form.taxReportName].some(val => val?.trim() === '')
      }
    })()
    const hasError = Object.values(form.errors).some((val: any) => val !== '')

    this.setState({ form: { ...form, [name]: value }, buttonFlag: isFieldEmpty|| hasError})
    // this.setState({ form }, () => {
    //   if(user?._id){
    //     this.handleUpdate()
    //   }
    
    // })

  }

  private handlePaymentMethodChange = (e: React.SyntheticEvent<HTMLDivElement> | any,{name,value} : any) =>{
    const { form }= this.state

    if(value !== form.paymentMethod){
      const isFieldEmpty = (() => {
        if (value === PaymentMethodEnum.Ach || value === PaymentMethodEnum.Direct) {
          return [form.routingNumber, form.bankName, form.accountNumber, form.taxReportName].some(val => val?.trim()  === '')
        } else {
          return [form.ein, value, form.taxID, form.taxReportName].some(val => val?.trim() === '')
        }
      })()
      const hasError = value === PaymentMethodEnum.Ach || value === PaymentMethodEnum.Direct ? Object.values(form.errors).some((val: any) => val !== ''): [form.ein, value, form.taxID, form.taxReportName].some(val => val === '')
      this.setState({form: {...form, paymentMethod: value, bankName:'',routingNumber:'',accountNumber:''}, buttonFlag: value === PaymentMethodEnum.Ach || value === PaymentMethodEnum.Direct ? true :   isFieldEmpty || hasError})
    }
    
  }
}
