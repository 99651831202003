import Colors from 'design/Colors'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

interface ItemContainerProps {
  width?: number,
  height?: number
}

const FeeGroup = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
`

const FeeGroupRow = styled(Segment.Group)`
  &&.ui.segments {
    justify-content: center;
    height: 40px;
    border: none;
    box-shadow: none;

    @media (max-width: 1350px){
      height: 80px;
    }
  }
`

const LargeFeeGroupRow = styled(FeeGroupRow)`
  &&.ui.segments {
    height: 75px;
    border: none;
    box-shadow: none;
    margin: 0px;
  }
`

const ItemContainer = styled(Segment)`
  .ui.horizontal.segments && {
    width: ${(props: ItemContainerProps) => props.width ? `${props.width}%` : '100%'};
    height: ${(props: ItemContainerProps) => props.height ? `${props.height}%` : '100%'};
    align-items: center;
    margin: 0 auto;
    align-content: center;
    display: inline-flex;
    justify-content: end;
    border: none;
    box-shadow: none;
    padding-top: 6px;
    padding-bottom: 14px;
    padding: 0;
    margin-right: 10px;

    @media (max-width: 1350px){
      width: 100%;
    }

  }
`

const SliderDetailContainer = styled(Segment.Group)`
  &&.ui.horizontal.segments {
    width: 90%;
    background-color: ${Colors.Grey00};
    margin: 0 auto;
    border: none;
    box-shadow: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;

    @media (max-width: 1350px){
      justify-content: space-between;
      flex-direction: column;
    }
  }

`

const Label = styled.div`
  white-space: nowrap;
  margin-right: 8px;

  @media (max-width: 1350px){
    width: 50%;
    text-align: center;
  }
`

const StyledInput = styled.div`
  .ui[class*="left icon"].input>input {
    padding-left: 2em !important;
  }

  .ui.input>input {
    font-size: 10px;
    max-width: 40%;
    height: 20px;
    border-radius: 0;
  }

  i.icon, i.icons {
    font-size: 10px;
    color: ${Colors.Black550};
  }

  @media (max-width: 1350px){
    width: 30%;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export {
  FeeGroup,
  FeeGroupRow,
  ItemContainer,
  LargeFeeGroupRow,
  SliderDetailContainer,
  Label,
  StyledInput,
}
