import { ActionCreator } from 'redux'
import Actions from 'store/Offices/ActionsTypes'
import {
  Filter,
  OfficeAddNewBoard,
  OfficeAddNewCard,
  OfficeChangeStatus,
  OfficeDeleteCard,
  OfficeDetails,
  OfficeFilter,
  OfficeGetBoardData,
  OfficeItemsDetails,
  OfficeResetCardData,
  OfficeRestoreCard,
  OfficeSearchData,
  OfficeSerachLoader,
  OfficeSetNewBoardName,
  OfficeSetNewDescription,
  OfficeSetNewIcon
} from './Types'

export const addNewBoard: ActionCreator<OfficeAddNewBoard> = (
  index: number,
  newData: OfficeDetails
) => ({
  payload: {
    index,
    newData
  },
  type: Actions.officeAddNewBoard
})

export const getBoardData: ActionCreator<OfficeGetBoardData> = (
  data: OfficeDetails[]
) => ({
  payload: {
    data
  },
  type: Actions.officeGetBoardData
})

export const addNewCard: ActionCreator<OfficeAddNewCard> = (
  id: string,
  index: string,
  newData: OfficeItemsDetails
) => ({
  payload: {
    id,
    index,
    newData
  },
  type: Actions.officeAddNewCard
})

export const setNewIcon: ActionCreator<OfficeSetNewIcon> = (
  id: string,
  icon: string
) => ({
  payload: {
    icon,
    id
  },
  type: Actions.officeSetNewIcon
})

export const setNewDescription: ActionCreator<OfficeSetNewDescription> = (
  id: string,
  description: string
) => ({
  payload: {
    description,
    id
  },
  type: Actions.officeSetNewDescription
})

export const setNewBoardName: ActionCreator<OfficeSetNewBoardName> = (
  id: string,
  boardName: string
) => ({
  payload: {
    boardName,
    id
  },
  type: Actions.officeSetNewBoardName
})

export const deleteCard: ActionCreator<OfficeDeleteCard> = (
  boardId: string,
  cardId: string
) => ({
  payload: {
    boardId,
    cardId
  },
  type: Actions.officeDeleteCard
})

export const restoreCard: ActionCreator<OfficeRestoreCard> = () => ({
  type: Actions.officeRestoreCard
})

export const resetCardData: ActionCreator<OfficeResetCardData> = () => ({
  type: Actions.officeResetCardData
})

export const changeStatus: ActionCreator<OfficeChangeStatus> = (
  boardId: string,
  cardId: string,
  status: string
) => ({
  payload: {
    boardId,
    cardId,
    status
  },
  type: Actions.officeChangestatus
})

export const filter: ActionCreator<OfficeFilter> = (data: Filter) => ({
  payload: {
    data
  },
  type: Actions.officeFilter
})

export const searchLoader: ActionCreator<OfficeSerachLoader> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.officeSearchLoader
})

export const searchDataFilter: ActionCreator<OfficeSearchData> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.officeSearchData
})
