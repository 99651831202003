import styled from 'styled-components'

import { scrollbars } from 'design/Styled'

export const Container = styled.div`
  ${scrollbars};
  display: block;
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
