const routerRoles = {
  contacts: [
    'ADMIN',
    'SUPER_ADMIN',
    'MANAGER',
    'TEAM_LEADER',
    'AGENT',
    'GUEST'
  ],
  events: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER', 'AGENT'],
  leads: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER', 'AGENT', 'GUEST'],
  mailBox: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER', 'AGENT'],
  office: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER'],
  reports: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER', 'AGENT'],
  settings: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER', 'AGENT'],
  tasks: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER', 'AGENT', 'GUEST'],
  teams: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER'],
  transactions: [
    'ADMIN',
    'SUPER_ADMIN',
    'MANAGER',
    'TEAM_LEADER',
    'AGENT',
    'GUEST'
  ],
  userProfile: [
    'ADMIN',
    'SUPER_ADMIN',
    'MANAGER',
    'TEAM_LEADER',
    'AGENT',
    'GUEST'
  ],
  users: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER', 'AGENT']
}

export default routerRoles
