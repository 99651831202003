import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { children, styledToggle, toggleItem, x } from 'design/Styled'

export const Container = styled.div`
  ${x};
  ${children};
  justify-content: center;
  font-weight: 400;
  color: ${Colors.Text};
`

export const Title = styled.span`
  margin-right: 1em;
`

export const ToggleItem = styled.div`
  ${toggleItem};
  font-weight: ${(props: any) => (props.isActive ? 500 : 300)};
`

export const StyledToggle = styled(Checkbox)`
  ${styledToggle};
`
