import client from 'queries/apollo'

import { GET_TAX_DATA } from 'queries/graphql/Settings/Taxes/Queries'
import Store from 'store/Store'

import {
  DispatchEnum,
  TaxDictType,
  TaxOfficeType,
} from 'store/Settings/Taxes/Types'
import {
  CityType,
  OfficeType,
} from './Types'

export const getTaxes = async () => {
  const { data }: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TAX_DATA,
  })

  const taxes: any = data.cities.reduce((obj: any, item: CityType) => {
    const key = `${item.state.name}_${item.name}`
    const value = {
      city: {
        _id: item._id,
        name: item.name,
        tax: item.tax,
      },
      state: {
        _id: item.state._id,
        name: item.state.name,
        tax: item.state.tax,
      }
    }
    obj[key] = value
    return obj
  }, {})

  const list: string[] = []
  const taxDict: TaxDictType = data.offices.reduce((dict: TaxDictType, item: OfficeType) => {
    const key = `${item.state}_${item.city}`
    const value: TaxOfficeType = {
      ...item,
      city: taxes[key].city,
      state: taxes[key].state,
    }
    dict[item._id] = value
    list.push(`${key}_${item._id}`)
    return dict
  }, {})

  list.sort()
  const taxList = list.map((item:string) => item.split('_')[2])

  Store.dispatch({
    payload: taxDict,
    type: DispatchEnum.SetTaxDict,
  })

  Store.dispatch({
    payload: taxList,
    type: DispatchEnum.SetTaxList,
  })

  return {
    dict: taxDict,
    list: taxList,
  }
}
