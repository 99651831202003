import * as React from 'react'

import ActionsButtons from '../ActionButtons'
import Insignia from '../Insignia'
import ViewModal from '../ViewModal'

import { MessageEmailCardData } from 'store/Mailbox/Types'

import {
  AttachmentCount,
  Attachments,
  Card,
  Col,
  Container,
  Envelope,
  Footer,
  From,
  Preview,
  Sidebar,
  SlideRow,
  Subject,
  Timestamp
} from './Styled'

import { faPaperclip, faReply, faShare, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPaperclip, faReply, faShare, faTrashAlt])

interface Props {
  CardData: MessageEmailCardData
}

interface State {
  senderName: string
  sentAgo: string
  showModal: boolean
}

class KanbanCard extends React.Component<Props, State> {
  public state = {
    senderName: '',
    sentAgo: '',
    showModal: false
  }

  private localTimer: any

  public componentDidMount() {
    this.trackTimestamp(0)
    this.createSenderName()
  }

  public componentWillUnmount() {
    clearTimeout(this.localTimer)
  }

  public render() {
    const { CardData } = this.props
    const { senderName, sentAgo, showModal } = this.state

    return (
      <Container>
        <Card>
          <SlideRow onClick={this.toggleModal}>
            <Col>
              <Insignia initials={CardData.from.initials} color={CardData.from.color} />
              <Timestamp>{sentAgo}</Timestamp>
            </Col>
            <Envelope>
              <From>{senderName}</From>
              <Subject>{CardData.subject}</Subject>
              <Preview>{CardData.preview}</Preview>
            </Envelope>
          </SlideRow>
          <Sidebar className="sidebar">
            <ActionsButtons actions={['reply', 'forward', 'trash']} vertical={true} messageData={CardData} />
          </Sidebar>
        </Card>
        {CardData.attachments.length > 0 && (
          <Footer>
            <Attachments>
              <FontAwesomeIcon icon={['fal', 'paperclip']} />
              <AttachmentCount>({CardData.attachments.length})</AttachmentCount>
            </Attachments>
          </Footer>
        )}
        {showModal && <ViewModal cardData={CardData} onClose={this.toggleModal} />}
      </Container>
    )
  }

  private toggleModal = async () => {
    this.setState(prev => {
      return { showModal: !prev.showModal }
    })
  }

  private createSenderName = () => {
    const {
      CardData: { from }
    } = this.props

    let senderName = from.value
    if (from.name && from.name.length > 0) {
      senderName = from.name
    }
    this.setState({ senderName })
  }

  private createTimestamp = () => {
    const hour = 60
    const day = hour * 24
    const week = day * 7
    const month = week * 4

    const now = new Date().getTime()
    const sentAt = new Date(this.props.CardData.sentAt).getTime()
    const minutes = Math.floor((now - sentAt) / 1000 / 60)

    if (minutes < hour) {
      this.setState({ sentAgo: `${minutes} min` })
      return this.trackTimestamp(1000 * 60)
    } else if (minutes < day) {
      this.setState({ sentAgo: `${Math.floor(minutes / hour)} h` })
    } else if (minutes < week) {
      this.setState({ sentAgo: `${Math.floor(minutes / day)} d` })
    } else if (minutes < month) {
      this.setState({ sentAgo: `${Math.floor(minutes / week)} wk` })
    }
  }

  private trackTimestamp = (wait: number) => {
    this.localTimer = setTimeout(() => {
      clearTimeout(this.localTimer)
      this.createTimestamp()
    }, wait)
  }
}

export default KanbanCard
