// Import Styled Components
import { CommercialCAP } from 'app/Teams/InviteModal/Team/Styled'

// Import Components
import client from 'queries/apollo'
import { getLoggedInUser } from 'utils'
import { getBoard, getOptions } from './UserQueries'
import { reformatData } from './Utils/FormattingData'

// Import Graphql Mutations
import { UPDATE_USER_GROUP_ORDER } from 'queries/graphql/Group/Mutation'
import { CREATE_TEAM, DELETE_TEAM } from 'queries/graphql/Teams/Mutation'
import {
  CREATE_USER,
  DELETE_USER,
  EXPORT_USERS,
  IMPORT_USERS,
  TRANSFER_USER,
  UPDATE_USER,
  UPDATE_USER_ORDER,
  USER_REASSIGN
} from 'queries/graphql/Users/Mutation'

// Import Graphql Queries
import { GET_GROUPS } from 'queries/graphql/Group/Queries'
import { GET_USERS } from 'queries/graphql/Users/Queries'

export const addUser = async (
  newUserData: any,
  addUserIndex: string,
  addBoardId: string,
  show: boolean,
  edit: boolean,
  officeID: string
) => {
  const loggedUser = await getLoggedInUser()
  const {
    _id,
    firstName,
    lastName,
    phone,
    office,
    role,
    individualResidentialCap,
    individualCommercialCap,
    capSetting,
    team,
    transactionFeeSetting,
    userName
  } = newUserData
  const residentialCap = parseInt(individualResidentialCap)
  const commercialCap = parseInt(individualCommercialCap)
  let board
  if (addBoardId || role) {
    let boardId: string = ''
    if (addBoardId) {
      boardId = addBoardId
    } else {
      boardId = role
    }
    board = await getBoard(boardId, officeID.split(', ')[0])
  }
  const officelist = []
  officelist.push(office)
  let user: any = {}
  if (show) {
    if (loggedUser.role === 'ADMIN') {
      const gr = officeID
        ? { officeGroup: role, officeGroupUserOrder: parseInt(addUserIndex) }
        : { group: role, userOrder: parseInt(addUserIndex) }
      user = await client.mutate({
        mutation: CREATE_USER,
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          }
        ],
        update: (cache: any, { data }: any) => {
          const proxy: any = cache.readQuery({
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          })

          const newData = reformatData(proxy.getGroups)
          const column = newData.find((group: any) => {
            return group._id === addBoardId
          })
          column.users.push(data.createUser)
          const addedData = newData.map((list: any) => {
            if (list._id === column._id) {
              return column
            }
            return list
          })
          cache.writeQuery({
            data: { getGroups: addedData },
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          })
        },
        variables: {
          ...gr,
          capSetting,
          firstName,
          individualCommercialCap: commercialCap,
          individualResidentialCap: residentialCap,
          lastName,
          office: officelist,
          password: 'password',
          phone,
          transactionFeeSetting,
          userName
        }
      })
    } else if (loggedUser.role === 'MANAGER') {
      user = await client.mutate({
        mutation: CREATE_USER,
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          }
        ],
        update: (cache: any, { data }: any) => {
          const proxy: any = cache.readQuery({
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          })
          const newData = reformatData(proxy.getGroups)
          const column = newData.find((group: any) => {
            return group._id === addBoardId
          })
          column.users.push(data.createUser)
          const addedData = newData.map((list: any) => {
            if (list._id === column._id) {
              return column
            }
            return list
          })
          cache.writeQuery({
            data: { getGroups: addedData },
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          })
        },
        variables: {
          capSetting,
          firstName,
          individualCommercialCap: commercialCap,
          individualResidentialCap: residentialCap,
          lastName,
          office: officelist,
          officeGroup: role,
          officeGroupUserOrder: parseInt(addUserIndex),
          password: 'password',
          phone,
          transactionFeeSetting,
          userName
        }
      })
    } else if (loggedUser.role === 'TEAM_LEADER') {
      user = await client.mutate({
        mutation: CREATE_USER,
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          }
        ],
        update: (cache: any, { data }: any) => {
          const proxy: any = cache.readQuery({
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          })
          const newData = reformatData(proxy.getGroups)
          const column = newData.find((group: any) => {
            return group._id === addBoardId
          })
          column.users.push(data.createUser)
          const addedData = newData.map((list: any) => {
            if (list._id === column._id) {
              return column
            }
            return list
          })
          cache.writeQuery({
            data: { getGroups: addedData },
            query: GET_GROUPS,
            variables: { sortOrder: 1, orderField: 'groupOrder' }
          })
        },
        variables: {
          capSetting,
          firstName,
          individualCommercialCap: commercialCap,
          individualResidentialCap: residentialCap,
          lastName,
          office: officelist,
          password: 'password',
          phone,
          teamGroup: role,
          teamGroupUserOrder: parseInt(addUserIndex),
          transactionFeeSetting,
          userName
        }
      })
    } else if (loggedUser.role === 'AGENT') {
      user = await client.mutate({
        mutation: CREATE_USER,
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          }
        ],
        update: (cache: any, { data }: any) => {
          const proxy: any = cache.readQuery({
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          })
          const newData = reformatData(proxy.getGroups)
          const column = newData.find((group: any) => {
            return group._id === addBoardId
          })
          column.users.push(data.createUser)
          const addedData = newData.map((list: any) => {
            if (list._id === column._id) {
              return column
            }
            return list
          })
          cache.writeQuery({
            data: { getGroups: addedData },
            query: GET_GROUPS,
            variables: { sortOrder: 1, orderField: 'groupOrder' }
          })
        },
        variables: {
          capSetting,
          firstName,
          individualCommercialCap: CommercialCAP,
          individualResidentialCap: residentialCap,
          lastName,
          office: officelist,
          password: 'password',
          phone,
          transactionFeeSetting,
          userGroup: role,
          userGroupUserOrder: parseInt(addUserIndex),
          userName
        }
      })
    }
    if (user && board.name === 'TEAM_LEADER') {
      await createTeam(user.data.createUser._id, team, office)
    }
    return null
  } else {
    if (edit) {
      const gr = officeID ? { officeGroupId: role } : { groupId: role }
      await client
        .mutate({
          mutation: TRANSFER_USER,
          refetchQueries: [
            {
              query: GET_GROUPS,
              variables: {
                office: officeID ? officeID.split(', ')[0] : undefined,
                orderField: 'groupOrder',
                sortOrder: 1
              }
            }
          ],
          variables: {
            ...gr,
            capSetting,
            firstName,
            individualCommercialCap: commercialCap,
            individualResidentialCap: residentialCap,
            lastName,
            officeId: officelist,
            phone,
            transactionFeeSetting,
            userId: _id
          }
        })
        .then(async () => {
          return await getOptions()
        })
      if (board && board.name === 'TEAM_LEADER') {
        return await createTeam(_id, team, office)
      }
      return true
    } else {
      return await client
        .mutate({
          mutation: CREATE_USER,
          refetchQueries: [
            {
              query: GET_GROUPS,
              variables: {
                office: officeID ? officeID.split(', ')[0] : undefined,
                orderField: 'groupOrder',
                sortOrder: 1
              }
            }
          ],
          variables: {
            capSetting,
            firstName,
            group: role,
            individualCommercialCap: commercialCap,
            individualResidentialCap: residentialCap,
            lastName,
            office: officelist,
            password: 'password',
            phone,
            transactionFeeSetting,
            userName
          }
        })
        .then(async () => {
          return await getOptions()
        })
    }
  }
}

export const deleteUser = async (cardId: string, officeID: string) => {
  await client.mutate({
    mutation: DELETE_USER,
    refetchQueries: [
      {
        query: GET_GROUPS,
        variables: {
          office: officeID ? officeID.split(', ')[0] : undefined,
          orderField: 'groupOrder',
          sortOrder: 1
        }
      },
      {
        query: GET_USERS,
        variables: { isActive: true }
      }
    ],
    variables: { userId: cardId }
  })
  return getOptions()
}

export const restoreUser = async (cardId: string, officeID: string) => {
  await client.mutate({
    mutation: UPDATE_USER,
    refetchQueries: [
      {
        query: GET_GROUPS,
        variables: {
          office: officeID ? officeID.split(', ')[0] : undefined,
          orderField: 'groupOrder',
          sortOrder: 1
        }
      },
      {
        query: GET_USERS,
        variables: { isActive: true }
      }
    ],
    variables: { _id: cardId, isActive: true, status: 'Active' }
  })
  return getOptions()
}

export const toggleCardStatus = async (cardId: string, status: string, officeID: string) => {
  client.mutate({
    mutation: UPDATE_USER,
    refetchQueries: [
      {
        query: GET_GROUPS,
        variables: {
          office: officeID ? officeID.split(', ')[0] : undefined,
          orderField: 'groupOrder',
          sortOrder: 1
        }
      },
      {
        query: GET_USERS,
        variables: { isActive: true }
      }
    ],
    variables: { _id: cardId, isActive: true, status: status }
  })
}

export const reorderLane = (boardId: string, sourceIndex: number, destinationIndex: number) => {
  client.mutate({
    mutation: UPDATE_USER_GROUP_ORDER,
    refetchQueries: [
      {
        query: GET_USERS,
        variables: { isActive: true }
      }
    ],
    variables: {
      board: boardId,
      destination: destinationIndex,
      source: sourceIndex
    }
  })
}

export const reorderCard = async (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
  const loggedUser = await getLoggedInUser()
  if (loggedUser.role === 'ADMIN') {
    client.mutate({
      mutation: UPDATE_USER_ORDER,
      refetchQueries: [
        {
          query: GET_USERS,
          variables: { isActive: true }
        }
      ],
      variables: {
        board: boardId,
        cardId: cardId,
        userOrderDestination: destinationIndex,
        userOrderSource: sourceIndex
      }
    })
  } else if (loggedUser.role === 'MANAGER') {
    client.mutate({
      mutation: UPDATE_USER_ORDER,
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: { orderField: 'groupOrder', sortOrder: 1 }
        },
        {
          query: GET_USERS,
          variables: { isActive: true }
        }
      ],
      variables: {
        board: boardId,
        cardId: cardId,
        officeGroupUserOrderDestination: destinationIndex,
        officeGroupUserOrderSource: sourceIndex
      }
    })
  } else if (loggedUser.role === 'TEAM_LEADER') {
    client.mutate({
      mutation: UPDATE_USER_ORDER,
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: { orderField: 'groupOrder', sortOrder: 1 }
        },
        {
          query: GET_USERS,
          variables: { isActive: true }
        }
      ],
      variables: {
        board: boardId,
        cardId: cardId,
        teamGroupUserOrderDestination: destinationIndex,
        teamGroupUserOrderSource: sourceIndex
      }
    })
  } else if (loggedUser.role === 'AGENT') {
    client.mutate({
      mutation: UPDATE_USER_ORDER,
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: { orderField: 'groupOrder', sortOrder: 1 }
        },
        {
          query: GET_USERS,
          variables: { isActive: true }
        }
      ],
      variables: {
        board: boardId,
        cardId: cardId,
        userGroupUserOrderDestination: destinationIndex,
        userGroupUserOrderSource: sourceIndex
      }
    })
  }
  return null
}

export const updateUser = (rosterName: string) => {
  client.mutate({
    mutation: UPDATE_USER,
    refetchQueries: [
      {
        query: GET_GROUPS,
        variables: { orderField: 'groupOrder', sortOrder: 1 }
      },
      {
        query: GET_USERS,
        variables: { isActive: true }
      }
    ],
    variables: { rosterName }
  })
}

export const moveUsers = async (cardId: string, destinationIndex: number, boardId: string, officeID: string) => {
  const loggedUser = await getLoggedInUser()
  if (loggedUser.role === 'ADMIN') {
    const gr = officeID
      ? { officeGroupId: boardId, officeGroupUserOrder: destinationIndex }
      : { groupId: boardId, userOrder: destinationIndex }
    return client
      .mutate({
        mutation: TRANSFER_USER,
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          },
          { query: GET_USERS, variables: { isActive: true } }
        ],
        variables: {
          ...gr,
          userId: cardId
        }
      })
      .then(() => {
        return getOptions()
      })
  } else if (loggedUser.role === 'MANAGER') {
    return client
      .mutate({
        mutation: TRANSFER_USER,
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          },
          { query: GET_USERS, variables: { isActive: true } }
        ],
        variables: {
          officeGroupId: boardId,
          officeGroupUserOrder: destinationIndex,
          userId: cardId
        }
      })
      .then(() => {
        return getOptions()
      })
  } else if (loggedUser.role === 'TEAM_LEADER') {
    return client
      .mutate({
        mutation: TRANSFER_USER,
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          },
          { query: GET_USERS, variables: { isActive: true } }
        ],
        variables: {
          groupId: boardId,
          teamGroupUserOrder: destinationIndex,
          userId: cardId
        }
      })
      .then(() => {
        return getOptions()
      })
  } else if (loggedUser.role === 'AGENT') {
    return client
      .mutate({
        mutation: TRANSFER_USER,
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              office: officeID ? officeID.split(', ')[0] : undefined,
              orderField: 'groupOrder',
              sortOrder: 1
            }
          },
          { query: GET_USERS, variables: { isActive: true } }
        ],
        variables: {
          groupId: boardId,
          userGroupUserOrder: destinationIndex,
          userId: cardId
        }
      })
      .then(() => {
        return getOptions()
      })
  }
}

export const createTeam = async (user: string, team: string, office: string) => {
  return await client.mutate({
    mutation: CREATE_TEAM,
    refetchQueries: [
      {
        query: GET_GROUPS,
        variables: { orderField: 'groupOrder', sortOrder: 1, office: office }
      },
      {
        query: GET_USERS,
        variables: { isActive: true }
      }
    ],
    variables: {
      leader: user,
      name: team,
      office: office
    }
  })
}

export const deleteTeam = async (cardId: string, officeID?: string) => {
  const deleteTeamId: any = []
  deleteTeamId.push(cardId)
  await client.mutate({
    mutation: DELETE_TEAM,
    refetchQueries: [
      {
        query: GET_GROUPS,
        variables: {
          office: officeID ? officeID.split(', ')[0] : undefined,
          orderField: 'groupOrder',
          sortOrder: 1
        }
      },
      {
        query: GET_USERS,
        variables: { isActive: true }
      }
    ],
    variables: { teamId: deleteTeamId }
  })
}

export const importUsers = async (groupid: string, file: any, officeID: string) => {
  const loggedUser = await getLoggedInUser()
  if (loggedUser.role === 'ADMIN') {
    client.mutate({
      mutation: IMPORT_USERS,
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: {
            office: officeID ? officeID.split(', ')[0] : undefined,
            orderField: 'groupOrder',
            sortOrder: 1
          }
        },
        {
          query: GET_USERS,
          variables: { isActive: true }
        }
      ],
      variables: officeID
        ? {
            file,
            office: officeID ? officeID.split(', ')[0] : undefined,
            officeGroup: groupid
          }
        : { file, group: groupid }
    })
  } else if (loggedUser.role === 'MANAGER') {
    client.mutate({
      mutation: IMPORT_USERS,
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: {
            office: officeID ? officeID.split(', ')[0] : undefined,
            orderField: 'groupOrder',
            sortOrder: 1
          }
        },
        {
          query: GET_USERS,
          variables: { isActive: true }
        }
      ],
      variables: {
        file,
        office: officeID ? officeID.split(', ')[0] : undefined,
        officeGroup: groupid
      }
    })
  } else if (loggedUser.role === 'TEAM_LEADER') {
    client.mutate({
      mutation: IMPORT_USERS,
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: {
            office: officeID ? officeID.split(', ')[0] : undefined,
            orderField: 'groupOrder',
            sortOrder: 1
          }
        },
        {
          query: GET_USERS,
          variables: { isActive: true }
        }
      ],
      variables: {
        file,
        office: officeID ? officeID.split(', ')[0] : undefined,
        teamGroup: groupid
      }
    })
  } else if (loggedUser.role === 'AGENT') {
    client.mutate({
      mutation: IMPORT_USERS,
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: {
            office: officeID ? officeID.split(', ')[0] : undefined,
            orderField: 'groupOrder',
            sortOrder: 1
          }
        },
        {
          query: GET_USERS,
          variables: { isActive: true }
        }
      ],
      variables: {
        file,
        office: officeID ? officeID.split(', ')[0] : undefined,
        userGroup: groupid
      }
    })
  }
}

export const exportUsers = async (groupid: string) => {
  const loggedUser = await getLoggedInUser()
  let url: any = {}
  if (loggedUser.role === 'ADMIN') {
    url = await client.query({
      query: EXPORT_USERS,
      variables: { group: groupid }
    })
  } else if (loggedUser.role === 'MANAGER') {
    url = await client.query({
      query: EXPORT_USERS,
      variables: { officeGroup: groupid }
    })
  } else if (loggedUser.role === 'TEAM_LEADER') {
    url = await client.query({
      query: EXPORT_USERS,
      variables: { teamGroup: groupid }
    })
  } else if (loggedUser.role === 'AGENT') {
    url = await client.query({
      query: EXPORT_USERS,
      variables: { userGroup: groupid }
    })
  }
  if (url && url.data) {
    setTimeout(() => {
      const response = {
        file: url.data.exportUsers
      }
      return (window.location.href = response.file)
    }, 100)
  }
}

export const userReassign = (userId: string, officeId: [string]) => {
  client.mutate({
    mutation: USER_REASSIGN,
    refetchQueries: [
      {
        query: GET_GROUPS,
        variables: {
          orderField: 'groupOrder',
          sortOrder: 1
        }
      }
    ],
    variables: {
      officeId,
      userId
    }
  })
}
