// Import Packages
import { groupBy } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { Card, Form, Grid, Image, Loader } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import Close from './Close'
import InProcess from './InProcess'
import { cardMockData } from './LeadSummaryMockDate'
import New from './New'

import client from 'queries/apollo'
import { GET_LEADS } from 'queries/graphql/Lead/Queries'
import { updateUserPass } from '../../Mutations'

import { UserPassType } from 'app/Dashboard/MainContent/Passes/Types'

// Import Images and Icons
import Colors from 'design/Colors'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import { Container, DescriptionWrapper, LeftPanel, RightPanel, StyledCard, StyledDropdown, Wrapper } from './Styled'

interface UserType {
  _id: string
  role: string
  profileImage: string
}

interface Props {
  closeModal: () => void
  onUpdate: (data: any) => void
  pass: UserPassType
}

interface State {
  animate: boolean
  timePeriod: number
  closedLead: any[]
  inProcessLead: any[]
  newLead: any[]
  loader: boolean
  user: UserType
}

const options = [{ key: 1, text: 30, value: 30 }, { key: 2, text: 60, value: 60 }, { key: 3, text: 90, value: 90 }]
class LeadSummaryModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    closedLead: [],
    inProcessLead: [],
    loader: true,
    newLead: [],
    timePeriod: 30,
    user: {} as UserType
  }

  public getTimePeriodData = async () => {
    const { timePeriod } = this.state
    const user: any = await getLoggedInUser({ fromCache: true })
    let leadListData: any = {}
    leadListData = await client.query({
      fetchPolicy: 'no-cache',
      query: GET_LEADS,
      variables: { userId: user._id }
    })

    if (leadListData) {
      this.setState({ loader: false, user })
    }

    let beforeDate: any
    const currentDate = moment()
    beforeDate = moment().subtract(timePeriod, 'days')

    const setTimePeriodForTransactions = leadListData.data.getLeads.filter((list: any) => {
      return (
        moment(moment(list.createdAt).format('YYYY-MM-DD')).isSameOrAfter(moment(beforeDate).format('YYYY-MM-DD')) &&
        moment(moment(list.createdAt).format('YYYY-MM-DD')).isSameOrBefore(moment(currentDate).format('YYYY-MM-DD'))
      )
    })

    const leadData = groupBy(setTimePeriodForTransactions, 'status')
    if ('In Process' in leadData) {
      this.setState({ inProcessLead: leadData['In Process'] })
    }
    if ('New' in leadData) {
      this.setState({ newLead: leadData['New'] })
    }
    if ('Closed' in leadData) {
      this.setState({ closedLead: leadData['Closed'] })
    }
  }

  public async componentDidMount() {
    const {
      pass: { timePeriod }
    } = this.props
    if (timePeriod !== null) {
      this.setState({ timePeriod }, () => this.getTimePeriodData())
    } else {
      this.setState({ timePeriod: 30 }, () => this.getTimePeriodData())
    }
  }

  public render() {
    const { animate, timePeriod, inProcessLead, newLead, closedLead, loader, user } = this.state
    const card = cardMockData.map((data: any, index: number) => {
      let background
      let count
      switch (data.meta) {
        case 'New':
          background = Colors.Orange250
          count = newLead.length
          break
        case 'In Process':
          background = Colors.Magenta50
          count = inProcessLead.length
          break
        default:
          background = Colors.Magenta60
          count = closedLead.length
          break
      }
      return (
        <Grid.Column key={index}>
          <StyledCard background={background}>
            <Card.Content>
              <Card.Header content={count} />
              <Card.Meta content={`(${data.meta})`} />
              <Card.Description
                content={
                  <DescriptionWrapper>
                    <Image src={data.chart} size="small" />
                    <div className="trend">{Strings.dashboard.passes.trend}</div>
                    <div className="month">{`Last ${timePeriod} Days`}</div>
                  </DescriptionWrapper>
                }
              />
            </Card.Content>
          </StyledCard>
        </Grid.Column>
      )
    })
    return (
      <Modal
        content={
          <Container>
            <LeftPanel>
              <Grid divided="vertically">
                <Grid.Row columns={2}>{card}</Grid.Row>
              </Grid>
            </LeftPanel>
            <RightPanel>
              <h3 className="ui dividing header">{`Last ${timePeriod} Days Lead Summary`}</h3>
              <Form size="mini" className="days-dropdown">
                <span>{Strings.dashboard.passes.time}</span>
                <StyledDropdown
                  name="timePeriod"
                  onChange={this.changeTimePeriod}
                  selection={true}
                  value={timePeriod}
                  options={options}
                />
              </Form>
              {loader ? (
                <Loader active={true} size="large" />
              ) : (
                <Wrapper>
                  {inProcessLead && inProcessLead.length > 0 && <InProcess user={user} inProcessLead={inProcessLead} />}
                  {newLead && newLead.length > 0 && <New user={user} newLead={newLead} />}
                  {closedLead && closedLead.length > 0 && <Close user={user} closedLead={closedLead} />}
                </Wrapper>
              )}
            </RightPanel>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={1000}
      />
    )
  }

  private changeTimePeriod = async (e: any, { value }: any) => {
    const { onUpdate, pass } = this.props
    this.setState({ timePeriod: value }, () => this.getTimePeriodData())

    const input = { timePeriod: value }
    try {
      const response = await updateUserPass(pass._id, input)
      onUpdate(response)
    } catch {
      // userPass not updated
    }
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default LeadSummaryModal
