import * as React from 'react'

import { Action, Container, Info } from './Styled'

import { ImageType, PermissionEnum } from 'shared/ImageZone/Types'

import { ActionEnum, ButtonsType } from './Types'

import {
  faArrowAltToBottom,
  faEnvelope,
  faEye,
  faLock,
  faPencilAlt,
  faSearchMinus,
  faSearchPlus,
  faTrashAlt,
  faUnlock
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faArrowAltToBottom,
  faEnvelope,
  faEye,
  faLock,
  faPencilAlt,
  faSearchMinus,
  faSearchPlus,
  faTrashAlt,
  faUnlock
])

interface Props {
  actions: string[]
  image: ImageType
  onAction: (action: ActionEnum, value: any) => void
  permission: PermissionEnum
  vertical?: boolean
}

interface State {
  buttons: ButtonsType
  infoPosition: string
  total: number
}

const ActionPermissions = {
  download: [PermissionEnum.Owner, PermissionEnum.Viewer],
  mail: [PermissionEnum.Owner, PermissionEnum.Viewer],
  trash: [PermissionEnum.Owner],
  view: [PermissionEnum.Owner, PermissionEnum.Viewer]
}

class ActionButtons extends React.Component<Props, State> {
  public state = {
    buttons: {} as ButtonsType,
    infoPosition: 'top center',
    total: 0
  }

  public componentDidMount = () => {
    this.getButtons()
    const { vertical } = this.props
    if (vertical) {
      this.setState({ infoPosition: 'right center' })
    }
  }

  public componentDidUpdate = (prev: Props) => {
    const { actions, permission } = this.props
    if (actions !== prev.actions || permission !== prev.permission) {
      this.getButtons()
    }
  }

  public render = () => {
    const { vertical, image } = this.props
    const {
      buttons: { download, trash },
      infoPosition,
      total
    } = this.state
    return (
      <Container vertical={vertical} total={total}>
        {download && (
          <Info
            position={infoPosition}
            inverted={true}
            size="mini"
            trigger={
              <Action href={image.url} download={image.name} target="_blank">
                <Icon icon={['fal', 'arrow-alt-to-bottom']} />
              </Action>
            }
            content="Download"
          />
        )}
        {trash && (
          <Info
            position={infoPosition}
            inverted={true}
            size="mini"
            trigger={
              <Action onClick={this.handleTrash}>
                <Icon icon={['fal', 'trash-alt']} />
              </Action>
            }
            content="Delete"
          />
        )}
      </Container>
    )
  }

  private getButtons = () => {
    const { actions, permission } = this.props
    const { buttons } = this.state
    let total = 0
    actions.forEach(action => {
      if (ActionPermissions[action] && ActionPermissions[action].includes(permission)) {
        buttons[action] = true
        total++
      }
    })
    this.setState({
      buttons,
      total
    })
  }

  private handleTrash = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { image, onAction } = this.props
    onAction(ActionEnum.Trash, image)
  }
}

export default ActionButtons
