// Import Packages
import { sixDots } from 'design/icons/dashboard'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface PropertyWrapperProps {
  totalindex: number
}

interface ActionsWrapperProps {
  ishidden: boolean
}

const DragHandle = styled.div`
  visibility: hidden;
  position: absolute;
  left: 0;
  cursor: grab;
  width: 20px;
  height: 16px;
  background: url(${sixDots}) 30% 50% no-repeat no-repeat;
  background-size: contain;
`

const PropertyWrapper = styled.div`
  display: flex;
  height: 35px;
  border: ${(props: PropertyWrapperProps) => props.totalindex % 2 === 1 ? `dashed 1px ${Colors.Grey400}` : 'none'};
  border-radius: 10px;
  margin-bottom: .5rem;
  background: ${(props: PropertyWrapperProps) => props.totalindex % 2 === 1 ? Colors.White1000 : Colors.White500};
  &:hover {
    ${DragHandle} {
      visibility: visible;
    }
  }
`

const PropertyHeader = styled.div`
  cursor: pointer;
  display: flex;
  padding: 0;
  margin-left: 1rem;
  margin-right: 2rem;
  width: 12.1rem;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
`

const PropertyValues = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-top: 0;
`

const PropertyNameText = styled.div`
  font-size: 12px;
  color: ${Colors.Black535};
  text-transform: uppercase;
  align-self: center;
  line-height: 1rem;
`

const ImageWrapper = styled.div`
  img {
    width: 12px;
    height: 12px;
  }
`

const ValueImageWrapper = styled.div`
  padding: 0 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 12px;
    height: 12px;
  }
`

const ActionsWrapper = styled.div`
  display: ${(props: ActionsWrapperProps) => props.ishidden ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 11rem;
  width: 50px;

  .svg-inline--fa {
    color: ${Colors.Black500};
    width: 14px;
    height: 20px;
    cursor: pointer;
  }
`

export {
  ActionsWrapper,
  DragHandle,
  ImageWrapper,
  PropertyHeader,
  PropertyNameText,
  PropertyValues,
  PropertyWrapper,
  ValueImageWrapper
}
