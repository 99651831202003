// Import Components
import client from 'queries/apollo'

import { FiltersData, TransactionPassFilter, UserType } from 'store/Transactions/Types'

// Import Graphql Queries
import { GET_CITIES } from 'queries/graphql/City/Queries'
import { GET_TASK, GET_TASK_ACTIVITY } from 'queries/graphql/Tasks/Queries'
import { GET_USER_CREDIT_DEBIT } from 'queries/graphql/UserProfile/Queries'
import { GET_USERS } from 'queries/graphql/Users/Queries'
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import { GET_STATES } from 'queries/graphql/States/Queries'
import {
  EXPORT_TRANSACTION,
  GET_RECENTLY_VIEW_TRANSACTIONS,
  GET_SORTED_TRANSACTION_BOARDS,
  GET_TRANSACTION_BOARDS,
  GET_TRANSACTION_BOARDS_DASHBOARD,
  GET_TRANSACTION_PROPERTY_CATEGORY,
  GET_TRANSACTION_PROPERTY_SUB_CATEGORIES
} from 'queries/graphql/TransactionBoard/Queries'
import {
  GET_CHECKLIST_LISTS,
  GET_COMMISSION_DISBURSEMENT,
  GET_FLYER_CATEGORIES,
  GET_TRANSACTION_COMMISSION,
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DOC_CHECKLIST,
  GET_TRANSACTION_MANAGER_NOTES,
  GET_TRANSACTION_PROPERTY_IMAGE,
  GET_TRANSACTION_TOLERANCE_CHECKER,
  GET_TRANSACTION_WORKFLOW,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_DOCUMENTS,
  GET_TRANSACTIONS_MESSAGES,
  GET_UNREAD_MESSAGE_COUNT
} from 'queries/graphql/Transactions/Queries'

import { GET_ELASTIC_DATA } from 'queries/graphql/ElasticSearch/Queries'

export const getTransactionBoards = async ({
  id,
  officeId,
  orderField = 'boardOrder',
  searchData,
  singleSkip,
  sortOrder = 1,
  user,
  userPassFilter,
}: {
  id?: string,
  officeId?: string,
  orderField?: string,
  searchData?: FiltersData,
  singleSkip?: number,
  sortOrder?: number
  user?: string,
  userPassFilter?: TransactionPassFilter,
}) => {
  const transactionBoards = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_BOARDS,
    variables: {
      id,
      officeId,
      orderField,
      searchData,
      singleSkip,
      sortOrder,
      user,
      userPassFilter,
    }
  })
  return transactionBoards.data.getTransactionBoards
}

export const getTransactionCategories = async () => {
  const response = await client.query({
    query: GET_TRANSACTION_PROPERTY_CATEGORY
  })

  return response.data.getPropertyCategories
}

export const getTransactionSubCategories = async (propertyCategoryId: string) => {
  const response = await client.query({
    query: GET_TRANSACTION_PROPERTY_SUB_CATEGORIES,
    variables: { propertyCategoryId }
  })

  return response.data.getPropertySubCategories
}

export const getStates = async () => {
  const response = await client.query({
    query: GET_STATES
  })

  return response.data.getStates
}

export const getCities = async (stateId: string) => {
  const response = await client.query({
    query: GET_CITIES,
    variables: { state_id: stateId }
  })

  return response.data.getCities
}

export const getTransactions = async ({
  contact,
  limit,
  officeId,
  searchData,
  shareWith,
  skip,
  userPassFilter,
}: {
  contact?: string,
  limit?: number,
  officeId?: string,
  searchData?: FiltersData,
  shareWith?: string,
  skip?: number,
  userPassFilter?: TransactionPassFilter,
}) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TRANSACTIONS,
    variables: {
      contact,
      officeId,
      searchData,
      shareWith,
      userPassFilter,
      skip,
      limit
    }
  })

  return response.data.getTransactions
}

export const getOfficesFilter = async () => {
  const response = await client.query({
    query: GET_OFFICES
  })
  const options = response.data.getOffices.reduce((accumulator: any, currentValue: any) => {
    accumulator.push({
      key: currentValue._id,
      text: currentValue.branchName,
      value: `${currentValue._id}, ${currentValue.branchName}`
    })
    return accumulator
  }, [])
  options.splice(0, 0, {
    key: '0',
    text: 'Select Office',
    value: 'Choose'
  })
  if (options.length > 2) {
    options.push({
      key: 'All',
      text: 'All',
      value: 'All'
    })
  }
  return options
}

export const getTransactionDetails = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_DETAILS,
    variables: { transactionId }
  })
  return response.data.getTransactions
}

export const getTransactionDocChecklist = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_DOC_CHECKLIST,
    variables: { transactionId }
  })

  return response.data.getDocTransactionCheckLists
}

export const getTransactionTask = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK,
    variables: { transactionId }
  })
  return response.data.getTasks
}

export const getTransactionDocument = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTIONS_DOCUMENTS,
    variables: { transactionId }
  })

  return response.data.getDocuments
}

export const getTransactionWorkflow = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_WORKFLOW,
    variables: { categoryName: 'Transactions' }
  })

  return response.data.getWorkflowCategories[0]
}

export const getTransactionActivities = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_ACTIVITY,
    variables: { transactionId }
  })

  return response.data.getActivities
}

export const exportTransactions = async (boardId: string, user: UserType) => {
  const url = await client.query({
    query: EXPORT_TRANSACTION,
    variables: {
      officeTransactionBoard: user.role === 'MANAGER' ? boardId : undefined,
      teamTransactionBoard: user.role === 'TEAM_LEADER' ? boardId : undefined,
      tenantTransactionBoard: user.role === 'ADMIN' ? boardId : undefined,
      transactionBoard: user.role === 'AGENT' ? boardId : undefined
    }
  })
  if (url && url.data) {
    setTimeout(() => {
      const response = {
        file: url.data.exportTransactions
      }
      return (window.location.href = response.file)
    }, 100)
  }
}

export const getTransactionPropertyImages = async (propertyId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_PROPERTY_IMAGE,
    variables: { propertyId }
  })

  return response.data.getPropertyImages
}

export const getManagerNote = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_MANAGER_NOTES,
    variables: { transactionId }
  })

  return response.data.getNotes
}

export const sortBoardTransaction = async (boardId: string, sortOrder: number, user: UserType) => {
  await client.query({
    fetchPolicy: 'network-only',
    query: GET_SORTED_TRANSACTION_BOARDS,
    variables: {
      contactOrderField: 'createdAt',
      id: boardId,
      orderField: 'boardOrder',
      sortContactOrder: sortOrder,
      sortOrder: 1,
      userId: user._id
    }
  })
}

export const getTransactionCommission = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_COMMISSION,
    variables: { transactionId }
  })

  return response.data.getPayments
}

export const getTransactionMessage = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTIONS_MESSAGES,
    variables: { transactionId }
  })

  return response.data.getMessages
}

export const getUserOffices = async () => {
  const offices = await client.query({
    query: GET_OFFICES
  })

  return offices.data.getOffices
}

export const getUsers = async () => {
  const users = await client.query({
    query: GET_USERS,
    variables: { isActive: true }
  })

  return users.data.getUsers
}

export const getUnreadMessageCount = async (transactionId: string, userId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_UNREAD_MESSAGE_COUNT,
    variables: { transactionId, userId }
  })

  return response.data.getUnreadMessageCount
}

export const getDocChecklist = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CHECKLIST_LISTS
  })

  return response.data.getDocCheckLists
}

export const getTransactionCreditDebit = async (userId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_CREDIT_DEBIT,
    variables: { userId, status: 'Not Applicable' }
  })

  return response.data.getUserCreditDebits
}

export const getElasticData = async (searchText: string, module?: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_ELASTIC_DATA,
    variables: { searchText, module }
  })

  return response.data.getElasticData
}

export const getCommissionDisbursement = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_COMMISSION_DISBURSEMENT,
    variables: { transactionId }
  })

  return response.data.getCommissionDisbursement
}

export const getTransactionBoardsDashboard = async (
  userID?: string,
  userPassFilter?: TransactionPassFilter,
  id?: string,
  singleSkip?: number
) => {
  const transactionBoards = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_BOARDS_DASHBOARD,
    variables: {
      id,
      orderField: 'boardOrder',
      singleSkip,
      sortOrder: 1,
      user: userID,
      userPassFilter
    }
  })
  return transactionBoards.data.getTransactionBoards
}

export const getRecentlyViewTransactions = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_RECENTLY_VIEW_TRANSACTIONS,
    variables: {}
  })
  return response.data.getRecentlyViewTransactions
}

export const getTransactionToleranceChecker = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_TOLERANCE_CHECKER,
    variables: { transactionId }
  })

  return response.data.getTransactionToleranceChecker
}

export const getFlyerCategories = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_FLYER_CATEGORIES,
    variables: { transactionId }
  })

  return response.data.getFlyerCategories
}
