// Import Packages
import moment from 'moment'
import * as React from 'react'
import Switch from 'react-switch'
import { Button, Grid } from 'semantic-ui-react'

// Import Utils
import { Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  ActionWrapper,
  BackButton,
  DateWrapper,
  DirectionContainer,
  HeaderWrapper,
  NextButton,
  StyledGrid,
  ToggleButton,
  ToggleTitle
} from './Styled'

interface Props {
  onSwitch: (checked: boolean) => void
  onChangeDate: (date: Date) => void
  calendarSwitch: boolean
}

interface State {
  checked: boolean
  date: Date
}

class CalendarHeader extends React.Component<Props, State> {
  public state = {
    checked: false,
    date: new Date()
  }

  public render() {
    const { calendarSwitch } = this.props
    const { checked, date } = this.state
    return (
      <HeaderWrapper>
        <StyledGrid>
          <Grid.Row columns={2}>
            <Grid.Column className="today">
              {calendarSwitch && (
                <Button basic={true} onClick={this.getToday}>
                  {Strings.eventsPopup.calendarPanel.today}
                </Button>
              )}
              <DateWrapper>
                {calendarSwitch ? moment(date).format('MMMM DD, YYYY') : moment(date).format('MMMM YYYY')}
              </DateWrapper>
            </Grid.Column>
            <Grid.Column>
              <ActionWrapper>
                <ToggleButton>
                  <ToggleTitle>
                    {checked ? Strings.eventsPopup.calendarPanel.week : Strings.eventsPopup.calendarPanel.month}
                  </ToggleTitle>
                  <Switch
                    onChange={this.handleChange}
                    checked={checked}
                    onColor={Colors.White520}
                    onHandleColor={Colors.White520}
                    handleDiameter={25}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={25}
                    width={60}
                    className="react-switch"
                    id="material-switch"
                  />
                </ToggleButton>
                <DirectionContainer>
                  <BackButton onClick={this.goToPrevious} />
                  <NextButton onClick={this.goToNext} />
                </DirectionContainer>
              </ActionWrapper>
            </Grid.Column>
          </Grid.Row>
        </StyledGrid>
      </HeaderWrapper>
    )
  }

  private handleChange = (checked: boolean) => {
    const { onSwitch } = this.props
    this.setState({ checked })
    onSwitch(checked)
  }

  private changeDateHandle = () => {
    const { onChangeDate } = this.props
    const { date } = this.state
    onChangeDate(date)
  }

  private getToday = () => {
    this.setState({ date: new Date() }, () => {
      this.changeDateHandle()
    })
  }

  private goToNext = () => {
    const { checked, date } = this.state
    const currentEventDate = moment(date)
    const nextEventDate = checked ? currentEventDate.add(1, 'week') : currentEventDate.add(1, 'month')
    this.setState({ date: new Date(nextEventDate.toString()) }, () => {
      this.changeDateHandle()
    })
  }

  private goToPrevious = () => {
    const { checked, date } = this.state
    const currentEventDate = moment(date)
    const nextEventDate = checked ? currentEventDate.add(-1, 'week') : currentEventDate.add(-1, 'month')
    this.setState({ date: new Date(nextEventDate.toString()) }, () => {
      this.changeDateHandle()
    })
  }
}

export default CalendarHeader
