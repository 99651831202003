import * as React from 'react'

import { styles } from './Styled'

import { TransitionEnum } from './Types'

interface Props {
  children: any
  className: string
  delay: number
  duration: number
  elem: any
  enter: string
  exit: string
  on: boolean
  stagger: number
  type: string
}

interface State {
  height: number
  transition: TransitionEnum
  width: number
}

class Transition extends React.Component<Props, State> {
  public static defaultProps = {
    className: '',
    delay: 0,
    duration: 300,
    elem: null,
    enter: '',
    exit: '',
    on: false,
    stagger: 0,
    type: 'fadeUp',
  }

  public state = {
    height: 0,
    transition: TransitionEnum.Exit,
    width: 0,
  }

  public componentDidMount = async () => {
    const { elem } = this.props
    if (elem && elem.current) {
      await this.stateUpdate({ transition: TransitionEnum.Before })
      await this.stateUpdate({
        height: elem.current.offsetHeight,
        width: elem.current.offsetWidth,
      })
    }
    this.handleTransition()
  }

  public componentDidUpdate = (prev: Props) => {
    if (prev.on !== this.props.on) {
      this.handleTransition()
    }
  }

  public render() {
    const { children, className, delay, duration, type, stagger } = this.props
    const { transition, height, width } = this.state

    return React.Children.map(children, (child: any, index: number) => {
      if (!child) {
        return null
      }
      return React.cloneElement(child, {
        className: [
          className,
          'rp-transition-wrapper',
          transition ? `rp-transition-${transition}` : ``,
          stagger ? `rp-transition-child rp-transition-stagger-${index}` : ``
        ].join(' '),
        key: index,
        style: styles({
          delay: (delay + stagger) * (index + 1),
          duration,
          height,
          style: transition ? `${this.props[transition] || type}-${transition}` : ``,
          width,
        })
      })
    })
  }

  private stateUpdate = async (state: any) => {
    return new Promise((resolve: any) => {
      this.setState(state, resolve)
    })
  }

  private handleTransition = async () => {
    const {
      children,
      delay,
      duration,
      on,
      stagger,
      elem,
    } = this.props
    const { transition } = this.state

    if (elem && !on && transition !== TransitionEnum.Before) {
      await this.stateUpdate({ transition: TransitionEnum.Enter })
    }

    window.requestAnimationFrame(async () => {
      await this.stateUpdate({ transition: on ? TransitionEnum.Enter : TransitionEnum.Exit })
      if (on) {
        window.setTimeout(() => {
          this.setState({ transition: TransitionEnum.None })
        }, delay + stagger * React.Children.count(children) + duration + 10)
      }
    })

  }
}

export default Transition
