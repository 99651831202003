import client from 'queries/apollo'

import { SEND_ACCOUNT_LINK, SEND_ACCOUNT_OTP } from 'queries/graphql/Account/Queries'
import { AccountLinkInputType } from './Types'

export const sendAccountLink = async (input: AccountLinkInputType) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: SEND_ACCOUNT_LINK,
    variables: { input }
  })
  return response.data.sendAccountLink
}

export const sendAccountOtp = async (input: AccountLinkInputType) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: SEND_ACCOUNT_OTP,
    variables: { input }
  })
  return response.data.sendAccountOtp
}
