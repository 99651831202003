import { FormErrorType, FormType } from './Types'

import { validateAddress } from 'shared/AddressInput/Validation'

const validEmail = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.?)+\.[a-zA-Z]{2,}$/
const validPhone = /^[0-9]{10}$/
const notNumbers = /[^0-9]/gi

export const handleValidation = (form: FormType) => {
  let errors = {} as FormErrorType
  let formIsValid = true

  if (!form.branchName) {
    formIsValid = false
    errors.branchName = 'Branch name can not be blank'
  } else if (form.branchName.length > 200) {
    formIsValid = false
    errors.branchName = `Branch name can not be longer than 200 characters`
  }

  if (form.email && !form.email.match(validEmail)) {
    formIsValid = false
    errors.email = 'Please include a valid email address'
  }

  if (form.phone && !form.phone.replace(notNumbers, '').match(validPhone)) {
    formIsValid = false
    errors.phone = 'You must include a 10-digit phone number'
  }

  if (!form.mls) {
    formIsValid = false
    errors.mls = 'Please select an MLS'
  }

  if (!form.manager) {
    formIsValid = false
    errors.mls = 'Please add a manager'
  }

  const { city, state, zipCode } = form
  const street = getStreetParts(form.streetAddress)
  const addressValidator = {
    ...street,
    city,
    state,
    zipCode
  }
  const address = validateAddress(addressValidator)
  if (!address.formIsValid) {
    formIsValid = false
    errors = {
      ...errors,
      ...address.errors
    }
  }

  return { errors, formIsValid }
}

const getStreetParts = (street: string) => {
  const streetNumber = street.match(/^\d+\s/)
  if (!streetNumber) {
    return {
      streetName: street,
      streetNumber: ''
    }
  }
  return {
    streetName: street.substring(streetNumber[0].length),
    streetNumber: streetNumber[0].trim()
  }
}
