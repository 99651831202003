// Import Packages
import moment from 'moment'
import * as React from 'react'

import ActivityHotsheet from 'design/icons/dashboard/ActivityHotsheet.png'
import Concierges from 'design/icons/dashboard/Concierge.png'
import LeadsSummary from 'design/icons/dashboard/leadSummary.png'
import MyAssistants from 'design/icons/dashboard/myAssistant.png'
import TransactionsSummary from 'design/icons/dashboard/transactionSummary.png'

// // Import Utils
// import { Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import { MemberProfile, Type } from './Styled'

export const CustomPassName = ({ rowData }: { rowData: any }) => {
  let image: any
  switch (rowData.passType) {
    case 'General':
      image = MyAssistants
      break
    case 'Leads':
      image = LeadsSummary
      break
    case 'Transactions':
      image = TransactionsSummary
      break
    case 'Concierge':
      image = Concierges
      break
    default:
      image = ActivityHotsheet
      break
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <MemberProfile background={image} />
      <div>{rowData.name}</div>
    </div>
  )
}

export const CustomActivatedOn = ({ rowData }: { rowData: any }) => {
  return <div>{moment(rowData.createdAt).format('ll')}</div>
}

export const CustomType = ({ rowData }: { rowData: any }) => {
  let background = ''
  let color = ''
  switch (rowData.passCategory) {
    case 'Free':
      background = Colors.DarkBlue205
      color = Colors.White1000
      break
    default:
      background = Colors.Green215
      color = Colors.White1000
      break
  }
  return (
    <Type background={background} color={color}>
      {rowData.passCategory}
    </Type>
  )
}

export const CustomAmount = ({ rowData }: { rowData: any }) => {
  return <div>${rowData.price}</div>
}

export const CustomAddedCount = ({ rowData }: { rowData: any }) => {
  return (
    <div>
      {rowData.addedCount
        ? rowData.addedCount !== 1
          ? `${rowData.addedCount} Users`
          : `${rowData.addedCount} User`
        : 'NA'}
    </div>
  )
}

export const CustomAction = ({ rowData }: { rowData: any }) => {
  let background = ''
  let color = ''
  let text = ''
  switch (rowData.passCategory) {
    case 'Free':
      background = ''
      color = ''
      text = 'NA'
      break
    default:
      background = Colors.Red835
      color = Colors.White1000
      text = 'Cancel'
      break
  }
  return (
    <Type background={background} color={color}>
      {text}
    </Type>
  )
}
