// Import Packages
import { Checkbox, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Envalope from 'design/icons/contacts/icon-envalope.png'
import Phone from 'design/icons/contacts/icon-phone.png'

// Import Colors
import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background: string
}

export const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox{
    min-width: 0px;
  }
`

export const Container = styled.div`
  ${y};
  padding: 0;
  height: 100%;
  overflow: hidden;
`

export const IconContainer = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: ${Colors.Pink210};
  align-items: center;
  justify-content: center;
  display: flex;
`

export const LabelIcon = styled.div`
  font-size: 25px;
  color: ${props => props.color || props.color};
`

export const ContactName = styled.div`
  font-size: 12px;
  margin-left: 10px;
  margin-top: 7px;
  cursor: pointer;
  text-transform: capitalize;
`

export const EmailIcon = styled.div`
  background: url(${Envalope});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 20px;
  cursor: pointer;
`

export const PhoneIcon = styled.div`
  background: url(${Phone});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 10px;
  cursor: pointer;
`

export const Type = styled.div`
  background-color: ${(props: Props) => props.background || props.background};
  min-width: 60px;
  max-width: 40px;
  justify-content: center;
  text-align: -webkit-center;
  color: ${Colors.White1000};
  font-size: 12px;
  font-weight: bold;
`

export const AddedOn = styled.div`
  font-style: italic;
`

export const Owner = styled.div`
  font-style: italic;
`

export const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

export const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

export const CheckboxWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
  }
`
