// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'
import { VictoryPie } from 'victory'

// Import Components
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Toast, { serverToast } from 'shared/Toast/Toast'
import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'
import { navigationMockData } from './NavigationMockData'
import NotesModal from './NotesModal'
import ShareWithModal from './ShareWith'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Contacts/Actions'
import * as LeadActions from 'store/Leads/Actions'

import { getContactActivities, getContactNotes } from 'app/Contacts/Dashboard/ContactQueries'
import { sendAccountLink } from 'app/Onboarding/Queries'
import { isPlanAllowed } from 'shared/Billing/Queries'

import { SubjectEnum } from 'app/Onboarding/Types'
import { Activities, ContactListDetails, ContactNotes, ContactShareWith } from 'store/Contacts/Types'
import { RouteParams } from '../Types'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Container,
  ContentWrapper,
  Email,
  FlexWrapper,
  FullName,
  Icon,
  Info,
  Labels,
  Name,
  People,
  PeopleName,
  PeopleStyledPopup,
  PersonContainer,
  ProfileProgressContainer,
  SharedWith,
  StyledMenu,
  StyledPopup,
  Wrapper
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faMapMarkerAlt, faPencilAlt, faPhone, faRedo } from '@fortawesome/pro-light-svg-icons'
import { faArchive, faCommentAltPlus, faHandHoldingUsd, faLayerPlus } from '@fortawesome/pro-regular-svg-icons'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createContactNotes, deleteContactNotes, editContactNotes } from 'app/Contacts/Dashboard/ContactMutations'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { getTransactionBoards } from 'app/Transactions/Dashboard/TransactionQueries'
AddIconToLibrary([
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faLayerPlus,
  faArchive,
  faCommentAltPlus,
  faHandHoldingUsd,
  faCaretRight,
  faPencilAlt,
  faRedo
])

interface UserType {
  _id: string
  profileImage: string
  role: string
  userName: string
}

interface StoreProps {
  setNewNotes: (newData: ContactNotes) => void
  setUpdateNotes: (id: string, newData: ContactNotes) => void
  setNotesData: (notes: ContactNotes[]) => void
  setDeleteNotes: (id: string) => void
  setActivities: (data: Activities[]) => void
  setContactTransactionPanel: (data: number) => void
  setContactTransactions: (data: any) => void
  notes: ContactNotes[]
  shareWith: ContactShareWith[]
}

interface OwnProps {
  shareUser: (user: ContactShareWith) => void
  showEditContactModal: () => void
  deleteShareUser: (userId: string) => void
  contactData: ContactListDetails
  toggleStatus: boolean
}

type Props = OwnProps & StoreProps & RouteComponentProps<RouteParams>

interface State {
  activeItem: string
  date: Date
  showNotesModal: boolean
  activeIndex: number
  showPeopleDetails: boolean
  showShareWith: boolean
  showAddTransactionModal: boolean
  addBoardId: string
  user: UserType
  showComposeMail: boolean
  showComposeMailForShare: boolean
  loader: boolean
  emailID: string
}

class LeftPanel extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    activeItem: '',
    addBoardId: '',
    date: new Date(),
    emailID: '',
    loader: false,
    showAddTransactionModal: false,
    showComposeMail: false,
    showComposeMailForShare: false,
    showNotesModal: false,
    showPeopleDetails: false,
    showShareWith: false,
    user: {} as UserType
  }

  public getDataResidential = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public async componentDidMount() {
    const { setNotesData, contactData } = this.props
    const notesData = await getContactNotes(contactData._id)
    setNotesData(notesData)
    const transactions = await getTransactionBoards({})
    const newBoardId = transactions.filter((board: any) => {
      return board.name === 'New'
    })
    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({ addBoardId: newBoardId[0]._id, user })
  }

  public render() {
    const { notes, contactData, shareWith, toggleStatus } = this.props
    const {
      activeItem,
      addBoardId,
      emailID,
      showNotesModal,
      showShareWith,
      showAddTransactionModal,
      showComposeMail,
      showComposeMailForShare,
      loader,
      user
    } = this.state
    const navigation = navigationMockData.map((items: any, index: any) => {
      return (
        <Menu.Item key={index} name={items.name} active={activeItem === items.name} onClick={this.handleItemClick}>
          <FontAwesomeIcon icon={['far', items.icon]} />
          {items.name === 'Notes' ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {items.name} <Labels circular={true}>{notes ? `${notes.length}` : null}</Labels>{' '}
            </div>
          ) : (
            <div>{items.name}</div>
          )}
          <FontAwesomeIcon icon={['fas', items.caretRight]} />
        </Menu.Item>
      )
    })
    const peoples = shareWith.map((items: any, index: number) => {
      return (
        <PeopleStyledPopup
          key={index}
          trigger={
            items.status === 'Pending' ? (
              <People background={items.profileImage} style={{ opacity: 0.5 }} />
            ) : (
              <People background={items.profileImage} />
            )
          }
          content={
            <PeopleName>
              <div style={{ fontWeight: 600 }}>
                {items.firstName} {items.lastName}
              </div>
              {items.type !== null && <div>{items.type}</div>}
              <Email>
                {items.status !== 'Pending' && (
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      this.mailTo(items)
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'envelope']} />
                  </span>
                )}
                {items.status !== 'Pending' && (
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      this.callTo(items)
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'phone']} />
                  </span>
                )}
                {((items.status !== 'Pending' && user.role !== 'GUEST' && user.role !== 'AGENT') ||
                  user._id === items._id) && (
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      this.viewTo(items)
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'eye']} />
                  </span>
                )}
                {(user.role === 'ADMIN' || user.role === 'MANAGER') &&
                  contactData.owner._id !== items._id && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.deleteTo(items)
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'trash']} />
                    </span>
                  )}
                {items.status === 'Pending' &&
                  user.role !== 'GUEST' && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.resendMail(items)
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'redo']} />
                    </span>
                  )}
              </Email>
            </PeopleName>
          }
          position="bottom center"
          hoverable={true}
        />
      )
    })
    return (
      <div>
        {showComposeMailForShare && <ComposeModal onClose={this.closeModal} defaultEmail={emailID} />}
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={contactData.email} />}
        {showNotesModal && (
          <NotesModal
            user={user}
            closeModal={this.closeModal}
            addNewNotes={this.addNewNotes}
            contactOwner={contactData.owner}
            notesData={notes}
            editNotes={this.editNotes}
            deleteNotes={this.deleteNotes}
            loader={loader}
          />
        )}
        {showAddTransactionModal && (
          <CreateTransactionModal
            mutationParams={{
              addBoardId,
              contactId: contactData._id,
              edit: false,
              index: '0',
              show: false,
              user
            }}
            onClose={this.addTransaction}
          />
        )}
        {showShareWith && (
          <ShareWithModal
            closeModal={this.closeModal}
            contactData={contactData}
            showUser={this.shareWith}
            shareWith={shareWith}
          />
        )}
        <Container>
          <ProfileProgressContainer>
            <div>
              <FullName>
                {contactData.firstName.charAt(0).toUpperCase()}
                {contactData.lastName.charAt(0).toUpperCase()}
              </FullName>
              <svg viewBox="0 0 400 400">
                <VictoryPie
                  standalone={false}
                  animate={{ duration: 5000 }}
                  width={400}
                  height={400}
                  data={this.getDataResidential(50)}
                  innerRadius={175}
                  labelRadius={120}
                  padding={0}
                  startAngle={0}
                  endAngle={360}
                  labels={['R : ', '30%']}
                  colorScale={[Colors.Black950, Colors.Black950]}
                />
              </svg>
            </div>
            <Info>
              <Name>
                {contactData.firstName} {contactData.lastName}
              </Name>
              <Icon>
                <StyledPopup
                  trigger={
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.sendMail()
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'envelope']} />
                    </span>
                  }
                  content={contactData.email ? contactData.email : 'No Email Added'}
                />
                <StyledPopup
                  trigger={<FontAwesomeIcon icon={['fal', 'phone']} />}
                  content={contactData.phone ? contactData.phone : 'No Phone Number Added'}
                />
                <span
                  onClick={e => {
                    e.stopPropagation()
                    this.props.showEditContactModal()
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
                </span>
              </Icon>
            </Info>
          </ProfileProgressContainer>
        </Container>
        <StyledMenu secondary={true} vertical={true}>
          {navigation}
        </StyledMenu>
        <ContentWrapper>
          {toggleStatus === false && (
            <div>
              <SharedWith>
                <span>{Strings.generalText.invite}</span>
                <span onClick={this.showShareWith}>{Strings.generalText.addInvite}</span>
              </SharedWith>
              <FlexWrapper>
                <PersonContainer onMouseLeave={() => this.showPeopleDetails(false)}>
                  <Wrapper>{peoples}</Wrapper>
                </PersonContainer>
              </FlexWrapper>
            </div>
          )}
        </ContentWrapper>
      </div>
    )
  }

  private resendMail = async (user: UserType) => {
    try {
      await sendAccountLink({
        type: SubjectEnum.Invite,
        userName: user.userName
      })
      Toast({ message: 'Mail Sent successfully', type: 'success' })
    } catch (e) {
      serverToast(e)
    }
  }

  private sendMail = () => {
    this.setState({ showComposeMail: true })
  }

  private addTransaction = async (result: any) => {
    const { setContactTransactions, setContactTransactionPanel } = this.props

    this.closeModal()
    if (!result) {
      return
    }

    try {
      setContactTransactions(result)
      setContactTransactionPanel(1)
      Toast({ message: 'Transaction added successfully', type: 'success' })
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }
  }

  private showPeopleDetails = (showPeopleDetails: boolean) => {
    this.setState({
      showPeopleDetails
    })
  }

  private showShareWith = () => {
    this.setState({ showShareWith: true })
  }

  private shareWith = async (item: ContactShareWith) => {
    const { shareUser, setActivities, contactData } = this.props
    try {
      shareUser(item)
      const contactactivites = await getContactActivities(contactData._id)
      setActivities(contactactivites)
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }
  }

  private viewTo = (items: ContactShareWith) => {
    this.props.history.push({
      pathname: `${Routes.primary.profile.path}/${items._id}`
    })
  }

  private deleteTo = (item: ContactShareWith) => {
    const { deleteShareUser } = this.props
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this user!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        deleteShareUser(item._id)
        ConfirmAlert('Deleted!', 'Your user has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your user is safe', 'error')
      }
    })
  }

  private mailTo = (items: ContactShareWith) => {
    this.setState({ showComposeMailForShare: true, emailID: items.userName })
  }

  private callTo = (item: ContactShareWith) => {
    window.location.href = `tel:${item.phones[0].value}`
  }

  private deleteNotes = (id: string) => {
    const { setDeleteNotes } = this.props
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this note!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        try {
          await deleteContactNotes(id)
          setDeleteNotes(id)
          ConfirmAlert('Deleted!', 'Your note has been deleted.', 'success')
          this.setState({ loader: false })
        } catch (error) {
          this.setState({ loader: false })
          Toast({ message: error.message, type: 'error' })
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your note is safe', 'error')
      }
    })
  }

  private editNotes = async (id: string, note: string) => {
    const { setUpdateNotes } = this.props
    this.setState({ loader: true })
    try {
      const res = await editContactNotes(id, note)
      setUpdateNotes(id, res)
      if (res) {
        this.setState({ loader: false })
      }
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }

  private addNewNotes = async (note: string) => {
    const { setNewNotes, contactData } = this.props
    this.setState({ loader: true })
    try {
      const res = await createContactNotes(contactData._id, note)
      setNewNotes(res)
      if (res) {
        this.setState({ loader: false })
      }
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }

  private closeModal = () => {
    this.setState({
      activeItem: '',
      showNotesModal: false,
      showShareWith: false,
      showAddTransactionModal: false,
      showComposeMail: false,
      showComposeMailForShare: false
    })
  }

  private handleItemClick = async (e: React.SyntheticEvent<EventTarget>, { name }: any) => {
    try {
      this.setState({ activeItem: name })
      if (name === 'Notes') {
        this.setState({ showNotesModal: true })
      } else {
        await isPlanAllowed('transaction')
        const user: any = getLoggedInUser({ fromCache: true })
        if (user.role === 'GUEST') {
          Toast({
            message:
              'You are not authorized to create a transaction. Please contact your administrator if you need additional assistance',
            type: 'error'
          })
        } else {
          this.setState({ showAddTransactionModal: true })
        }
      }
    } catch (error) {
      serverToast(error)
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  notes: state.contacts.notes,
  shareWith: state.contacts.shareWith,
  transactions: state.contacts.transactions
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setActivities: Actions.getActivities,
      setContactTransactionPanel: LeadActions.setContactTransactionPanel,
      setContactTransactions: Actions.addContactTransactions,
      setDeleteNotes: Actions.deleteNotes,
      setNewNotes: Actions.addNewNotes,
      setNotesData: Actions.getNotesData,
      setUpdateNotes: Actions.editNotes
    }
  )(LeftPanel)
)
