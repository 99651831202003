import { ActionCreator } from 'redux'
import Actions from 'store/Mailbox/ActionsTypes'
import { MailboxBoard, MailboxDeleteCard, MailboxGetBoardData } from './Types'

export const getBoardData: ActionCreator<MailboxGetBoardData> = (
  data: MailboxBoard
) => ({
  payload: {
    data
  },
  type: Actions.MailboxGetBoardData
})

export const deleteCard: ActionCreator<MailboxDeleteCard> = (
  boardId: string,
  cardId: string
) => ({
  payload: {
    boardId,
    cardId
  },
  type: Actions.MailboxDeleteCard
})
