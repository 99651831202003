import client from 'queries/apollo'

import { LOGIN_PASSWORD_USER } from 'queries/graphql/Account/Mutations'

import { LoginInputType } from './Types'

export const handlePasswordLogin = async (variables: LoginInputType) => {
  const response = await client.mutate({
    mutation: LOGIN_PASSWORD_USER,
    variables
  })

  return response.data.handlePasswordLogin
}
