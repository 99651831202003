// Import Packages
import { History } from 'history'
import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import Responsive from 'semantic-ui-react/dist/commonjs/addons/Responsive'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

// Import Components
import { getChecklistCategories, getDocTemplates } from '../ChecklistManagerQueries'
import { formatChecklist } from '../FormattingData'
import { AddSubtypeModal } from './AddModals'
import GridContent from './GridContent'
import GridHeader from './GridHeader'

import { getMlsTenantList } from 'shared/Mls/Queries'
import ChecklistEditorModal from 'shared/Settings/ChecklistEditorModal'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/ChecklistManager/Actions'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { EditCategoryModal, EditDocumentModal } from './EditModals'

// Import Styled Components
import { ChecklistManagerDetailsWrapper, Main, Modal, StyledGrid, YTitleText } from './Styled'

import { OptionType } from './Types'

import { MlsType } from 'shared/Mls/Types'

interface OwnProps {
  history?: History
}

interface DispatchProps {
  setNewProperty: (categoryIndex: number, propertyName: any, propertyId: string) => void
  setUpdateCategory: (newName: string, index: number) => void
  setUpdateDocument: (newName: string, index: number) => void
  setUpdateProperty: (newName: string, categoryIndex: number, propertyIndex: number) => void
  setUpdateChecklistData: (categories: any, documents: any) => void
}

type Props = OwnProps & {} & DispatchProps

interface State {
  activeMls: string
  currentCategoryId?: string
  currentCategoryIndex?: number
  currentCategoryName?: string
  currentDocumentId?: string
  currentDocumentIndex?: number
  currentDocumentName?: string
  currentPropertyId?: string
  currentPropertyIndex?: number
  currentPropertyName?: string
  mlsOptions: OptionType[]
  modal: any
  showAddSubtypeModal: boolean
  showEditCategoryModal: boolean
  showEditModal: boolean
  showPropertyEditModal: boolean
}

class ChecklistManagerDetails extends Component<Props, State> {
  public state = {
    activeMls: '',
    currentCategoryId: '',
    currentCategoryIndex: 0,
    currentCategoryName: '',
    currentDocumentId: '',
    currentDocumentIndex: 0,
    currentDocumentName: '',
    currentPropertyId: '',
    currentPropertyIndex: 0,
    currentPropertyName: '',
    mlsOptions: [
      {
        key: '',
        text: '',
        value: ''
      }
    ],
    modal: null,
    showAddSubtypeModal: false,
    showEditCategoryModal: false,
    showEditModal: false,
    showPropertyEditModal: false
  }

  public async componentDidMount() {
    const mlsList = await getMlsTenantList({})
    if (!mlsList) {
      return
    }
    const mlsOptions: OptionType[] = mlsList.map((item: MlsType) => {
      return {
        key: item._id,
        text: item.shortName,
        value: item._id
      }
    })
    const activeMls = mlsOptions[0].value

    this.setState({
      activeMls,
      mlsOptions
    })

    this.getChecklistsByMls(activeMls)
  }

  // Document Util functions
  public editDocument = (result: string, index: number) => {
    const { setUpdateDocument } = this.props
    setUpdateDocument(result, index)
  }

  public handleEditClick = (toggle: boolean, name?: string, index?: number, id?: string) => {
    this.setState({
      currentDocumentId: id,
      currentDocumentIndex: index,
      currentDocumentName: name,
      showEditModal: toggle
    })
  }

  // Category Util functions
  public editCategory = (result: string, categoryIndex: number) => {
    const { setUpdateCategory } = this.props
    setUpdateCategory(result, categoryIndex)
  }

  public handleEditCategoryClick = (toggle: boolean, categoryIndex?: number, name?: string, id?: string) => {
    this.setState({
      currentCategoryId: id,
      currentCategoryIndex: categoryIndex,
      currentCategoryName: name,
      showEditCategoryModal: toggle
    })
  }

  // Property Util functions
  public editProperty = (result: string, categoryIndex: number, propertyIndex: number) => {
    const { setUpdateProperty } = this.props
    setUpdateProperty(result, categoryIndex, propertyIndex)
  }

  public handleEditPropertyClick = (
    toggle: boolean,
    categoryIndex?: number,
    propertyIndex?: number,
    name?: string,
    id?: string,
    categoryName?: string
  ) => {
    this.setState({
      currentCategoryIndex: categoryIndex,
      currentCategoryName: categoryName,
      currentPropertyId: id,
      currentPropertyIndex: propertyIndex,
      currentPropertyName: name,
      showPropertyEditModal: toggle
    })
  }

  public addSubtype = (id: string, result: any, categoryIndex: number) => {
    const { setNewProperty } = this.props
    const { activeMls } = this.state
    setNewProperty(categoryIndex, result, id)
    this.handleUpdateMls(activeMls)
  }

  public handleAddPropertyClick = (toggle: boolean, id?: string, index?: number, name?: string) => {
    this.setState({
      currentCategoryId: id,
      currentCategoryIndex: index,
      currentCategoryName: name,
      showAddSubtypeModal: toggle,
      showPropertyEditModal: false
    })
  }

  public render() {
    const {
      activeMls,
      mlsOptions,
      modal,
      showAddSubtypeModal,
      showEditCategoryModal,
      showEditModal,
      showPropertyEditModal
    } = this.state
    return (
      <Responsive as={ChecklistManagerDetailsWrapper}>
        <Responsive as={Modal}>
          {modal}
          {showEditModal && (
            <EditDocumentModal
              editDocument={this.editDocument}
              documentIndex={this.state.currentDocumentIndex}
              documentId={this.state.currentDocumentId}
              handleClose={this.handleEditClick}
              value={this.state.currentDocumentName}
            />
          )}
          {/* {
            showPropertyEditModal && (
              <PropertyEditModal
                editProperty={this.editProperty}
                handleClose={this.handleEditPropertyClick}
                categoryIndex={this.state.currentCategoryIndex}
                propertyIndex={this.state.currentPropertyIndex}
                propertyId={this.state.currentPropertyId}
                value={this.state.currentPropertyName}
              />
            )
          } */}
          {showEditCategoryModal && (
            <EditCategoryModal
              editCategory={this.editCategory}
              handleClose={this.handleEditCategoryClick}
              categoryIndex={this.state.currentCategoryIndex}
              categoryId={this.state.currentCategoryId}
              value={this.state.currentCategoryName}
            />
          )}
          {(showAddSubtypeModal || showPropertyEditModal) && (
            <AddSubtypeModal
              addSubtype={this.addSubtype}
              editProperty={this.editProperty}
              handleClose={this.handleAddPropertyClick}
              categoryIndex={this.state.currentCategoryIndex}
              categoryId={this.state.currentCategoryId}
              categoryName={this.state.currentCategoryName}
              propertyIndex={this.state.currentPropertyIndex}
              propertyId={this.state.currentPropertyId}
              propertyName={this.state.currentPropertyName}
              edit={this.state.showPropertyEditModal}
              mlsId={activeMls}
            />
          )}
          <Responsive as={Main}>
            <YTitleText>{Strings.settings.propertyType}</YTitleText>
            <StyledGrid>
              <Table.Body>
                <GridHeader
                  activeMls={activeMls}
                  handleEditClick={this.handleEditClick}
                  mlsOptions={mlsOptions}
                  onUpdateMls={this.handleUpdateMls}
                  showChecklistEditor={this.showChecklistEditorModal}
                />
                <GridContent
                  activeMls={activeMls}
                  handleEditCategoryClick={this.handleEditCategoryClick}
                  handleEditPropertyClick={this.handleEditPropertyClick}
                  handleAddPropertyClick={this.handleAddPropertyClick}
                />
              </Table.Body>
            </StyledGrid>
          </Responsive>
        </Responsive>
      </Responsive>
    )
  }

  private getChecklistsByMls = async (mls: string) => {
    const { setUpdateChecklistData } = this.props

    const checklistCategory = await getChecklistCategories({ mls })
    const docTemplates = await getDocTemplates({ mls })
    const formattedData = await formatChecklist(checklistCategory, docTemplates)
    setUpdateChecklistData(formattedData, docTemplates)
  }

  private handleUpdateMls = (activeMls: string) => {
    this.setState({ activeMls })
    this.getChecklistsByMls(activeMls)
  }

  private showChecklistEditorModal = () => {
    const { activeMls } = this.state
    const modal = <ChecklistEditorModal mlsFilter={activeMls} onClose={this.closeModal} />
    this.setState({ modal })
  }

  private closeModal = () => {
    this.setState({ modal: null })
  }
}

const mapStateToProps = (state: AppState) => ({})

export default connect(
  mapStateToProps,
  {
    setNewProperty: Actions.addProperty,
    setUpdateCategory: Actions.editCategory,
    setUpdateChecklistData: Actions.updateChecklistData,
    setUpdateDocument: Actions.editDocument,
    setUpdateProperty: Actions.editProperty
  }
)(ChecklistManagerDetails)
