// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Icon } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import { deleteRecord, editContact, ShowComposeMail, SingleCheckBox, ToggleCheckBox } from './Contacts'
import { loggedUser, toggle } from './Contacts'

import { ContactListDetails } from 'store/Contacts/Types'

// Import Utils
import { getFirstCharacter, Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  AddedOn,
  CheckboxWrapper,
  ContactName,
  DropDownPopup,
  EmailIcon,
  IconContainer,
  LabelIcon,
  Owner,
  PhoneIcon,
  StyledCheckbox,
  StyledDropdown,
  StyledPopup,
  Type
} from './Styled'

let checked = false

export const Checked = (data: boolean) => {
  checked = !data
}

export const CustomHeaderCheckBox = () => {
  const onChange = () => {
    ToggleCheckBox()
  }
  return (
    <CheckboxWrapper>
      <StyledCheckbox checked={checked} onClick={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomContact = ({ rowData }: { rowData: ContactListDetails }) => {
  return <div>{rowData.phone}</div>
}

export const CustomCheckBox = ({ rowData }: { rowData: ContactListDetails }) => {
  const onChange = () => {
    SingleCheckBox(rowData._id)
  }
  return (
    <CheckboxWrapper>
      <StyledCheckbox checked={rowData.checked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomContactName = ({ rowData }: { rowData: ContactListDetails }) => {
  const sendMail = () => {
    ShowComposeMail(rowData.email)
  }
  return (
    <div style={{ display: 'flex' }}>
      <IconContainer>
        <LabelIcon color={rowData.color}>{getFirstCharacter(rowData.firstName.toUpperCase())}</LabelIcon>
      </IconContainer>
      <Link
        to={
          toggle
            ? loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER'
              ? Routes.contacts.root + Routes.contacts.details.path + `/${rowData._id}?pool=1`
              : {}
            : Routes.contacts.root + Routes.contacts.details.path + `/${rowData._id}?pool=0`
        }
      >
        <ContactName style={{ color: Colors.Black500 }}>{`${rowData.firstName} ${rowData.lastName}`}</ContactName>
      </Link>
      <StyledPopup
        trigger={
          <EmailIcon
            onClick={e => {
              e.stopPropagation()
              sendMail()
            }}
          />
        }
        content={rowData.email ? rowData.email : 'No Email Added'}
      />
      <StyledPopup trigger={<PhoneIcon />} content={rowData.phone ? rowData.phone : 'No Phone Number Added'} />
    </div>
  )
}

export const CustomType = ({ rowData }: { rowData: ContactListDetails }) => {
  let background = ''
  switch (rowData.type) {
    case 'Both':
      background = Colors.Magenta50
      break
    case 'Buyer':
      background = Colors.Green215
      break
    case 'Seller':
      background = Colors.Red835
      break
    default:
      background = Colors.Orange110
      break
  }
  return <Type background={background}>{rowData.type}</Type>
}

export const CustomActions = ({ rowData }: { rowData: ContactListDetails }) => {
  const deleteContact = () => {
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.kanbanView.recoverRecord,
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(result => {
      if (result.value) {
        deleteRecord(rowData._id)
        ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedRecord, 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeRecord, 'error')
      }
    })
  }
  const editContactDetails = () => {
    editContact(rowData)
  }
  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              <Link
                to={
                  toggle
                    ? loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER'
                      ? Routes.contacts.root + Routes.contacts.details.path + `/${rowData._id}?pool=1`
                      : {}
                    : Routes.contacts.root + Routes.contacts.details.path + `/${rowData._id}?pool=0`
                }
              >
                <Dropdown.Item text={Strings.contact.viewDetails} className="view" />
              </Link>
              {rowData.showEditButton && <Dropdown.Item text={Strings.contact.delete} onClick={deleteContact} />}
              {rowData.showEditButton && <Dropdown.Item text={Strings.leads.edit} onClick={editContactDetails} />}
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  )
}

export const CustomOwner = ({ rowData }: { rowData: ContactListDetails }) => (
  <Owner>{rowData.owner ? `${rowData.owner.firstName} ${rowData.owner.lastName}` : ''}</Owner>
)

export const CustomAddedOn = ({ rowData }: { rowData: ContactListDetails }) => (
  <AddedOn>{moment(rowData.createdAt).format('L')}</AddedOn>
)
