import moment from 'moment'
import * as React from 'react'

import { Container } from './Styled'

interface Props {
  date: string
}

class Date extends React.Component<Props, {}> {
  public render() {
    const { date } = this.props

    return (
      <Container>{moment(date).format('LL')}</Container>
    )
  }
}

export default Date