import moment from 'moment'
import * as React from 'react'

import { Container, Date, Envelope, Phone, StyledIcon, StyledPopup, StyledTimelineEvent, Title } from './Styled'

import { EventType } from './Types'

import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone])

interface Props {
  event: EventType
}

class TimelineEvent extends React.Component<Props, {}> {
  public render = () => {
    const {
      event,
      event: { userId: user }
    } = this.props
    return (
      <Container>
        <StyledTimelineEvent
          title={<Date>{moment(event.date).format('MMMM Do YYYY')}</Date>}
          createdAt={
            <span>
              <Title>
                {user.firstName} {user.lastName}
              </Title>
              <StyledPopup
                trigger={
                  <Envelope>
                    <FontAwesomeIcon icon={['far', 'envelope']} />
                  </Envelope>
                }
                content={user.userName || 'No Email Added'}
              />
              <StyledPopup
                trigger={
                  <Phone>
                    <FontAwesomeIcon icon={['fas', 'phone']} />
                  </Phone>
                }
                content={(user.phones[0] && user.phones[0].value) || 'No Phone Number Added'}
              />
            </span>
          }
          icon={<StyledIcon name="circle" />}
          style={{ lineHeight: '12px', marginLeft: '10px', top: '5px' }}
        />
      </Container>
    )
  }
}

export default TimelineEvent
