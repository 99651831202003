// Import Packages
import * as React from 'react'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  Numbers,
  StyledSegment,
  Text
} from './Styled'

interface Props {
  totalResult: number
  commissionReceived: number
  totalReferrals: number
  totalDue: number
  totalPrice: number
  module: string
}

interface State {
  showLineChart?: boolean
}

class Segments extends React.Component<Props, State> {
  public state = {
    showLineChart: false
  }

  public render() {
    const { totalResult, module, totalPrice, totalDue, commissionReceived, totalReferrals } = this.props
    return (
      <Container>
        <StyledSegment compact={true}>
          <Numbers>{totalResult}</Numbers>
          <Text>{Strings.reports.totalResults}</Text>
        </StyledSegment>
        {
          module === 'TRANSACTIONS' &&
          <>
            <StyledSegment compact={true}>
              <Numbers>{`$${Number(Number(totalPrice).toFixed(2)).toLocaleString()}`}</Numbers>
              <Text>{Strings.reports.totalPrice}</Text>
            </StyledSegment>
            <StyledSegment compact={true}>
              <Numbers>{`$${Number(Number(commissionReceived).toFixed(2)).toLocaleString()}`}</Numbers>
              <Text>{Strings.reports.totalCommissionReceived}</Text>
            </StyledSegment>
            <StyledSegment compact={true}>
              <Numbers>{`$${Number(Number(totalReferrals).toFixed(2)).toLocaleString()}`}</Numbers>
              <Text>{Strings.reports.totalReferrals}</Text>
            </StyledSegment>
            <StyledSegment compact={true}>
              <Numbers>{`$${Number(Number(totalDue).toFixed(2)).toLocaleString()}`}</Numbers>
              <Text>{Strings.reports.totalDue}</Text>
            </StyledSegment>
          </>
        }
      </Container>
    )
  }
}

export default Segments
