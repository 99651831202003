import * as React from 'react'

import Button from 'shared/Button'

import { Container, Options, SubAction } from './Styled'

import { PermissionEnum } from 'shared/DocumentZone/Types'
import { PDFDocumentType } from 'shared/PDFViewer/Types'
import { ActionEnum, ButtonsType } from './Types'

import {
  faArrowAltToBottom,
  faEllipsisVAlt,
  faEnvelope,
  faEye,
  faFileSignature,
  faLock,
  faPencilAlt,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faTimes,
  faTrashAlt,
  faUnlock,
} from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faArrowAltToBottom,
  faCheck,
  faEllipsisVAlt,
  faEnvelope,
  faEye,
  faFileSignature,
  faLock,
  faPencilAlt,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faTimes,
  faTrashAlt,
  faUnlock,
])

interface Props {
  actions: string[]
  doc: PDFDocumentType
  onAction: (action: ActionEnum, value: any) => void
  permission: PermissionEnum
  vertical?: boolean
}

interface State {
  buttons: ButtonsType
  labelPosition: string
  showEllipsisOptions: boolean
  showPlusOptions: boolean
}

const ActionPermissions = {
  cancel: PermissionEnum.None,
  confirm: PermissionEnum.None,
  download: PermissionEnum.Viewer,
  lock: PermissionEnum.Owner,
  mail: PermissionEnum.Viewer,
  markup: PermissionEnum.Viewer,
  options: PermissionEnum.Viewer,
  rename: PermissionEnum.Owner,
  rotate: PermissionEnum.Viewer,
  textract: PermissionEnum.Admin,
  trash: PermissionEnum.Owner,
  zoom: PermissionEnum.Viewer,
}

class ActionButtons extends React.Component<Props, State> {
  public state = {
    buttons: {} as ButtonsType,
    labelPosition: 'top center',
    showEllipsisOptions: false,
    showPlusOptions: false,
  }

  public componentDidMount = () => {
    this.getButtons()
    const { vertical } = this.props
    if (vertical) {
      this.setState({ labelPosition: 'right center' })
    }
  }

  public componentDidUpdate = (prev: Props) => {
    const { actions, permission } = this.props
    if (actions !== prev.actions || permission !== prev.permission) {
      this.getButtons()
    }
  }

  public render = () => {
    const { actions, vertical, doc } = this.props
    const {
      buttons: {
        cancel,
        confirm,
        download,
        markup,
        lock,
        mail,
        options,
        rename,
        rotate,
        trash,
        zoom,
      },
      labelPosition,
    } = this.state
    const { showEllipsisOptions } = this.state
    return (
      <Container vertical={vertical} total={actions.length}>
        {rename && (
          <Button.Icon
            icon={faPencilAlt}
            label='Rename'
            labelPosition={labelPosition}
            onClick={this.handleRename}
          />
        )}
        {mail && (
          <Button.Icon
            icon={faEnvelope}
            label='Send'
            labelPosition={labelPosition}
            onClick={this.handleMail}
          />
        )}
        {lock && (
          <Button.Icon
            icon={doc && doc.isLocked ? faLock : faUnlock}
            label={doc && doc.isLocked ? 'Unlock' : 'Lock'}
            labelPosition={labelPosition}
            onClick={this.toggleLock}
          />
        )}
        {download && (
          <Button.Icon
            icon={faArrowAltToBottom}
            label='Download'
            labelPosition={labelPosition}
            onClick={this.handleDownload}
          />
        )}
        {!options && (markup || trash || rotate || zoom) && (
          <React.Fragment>
            {markup && (
              <Button.Icon
                icon={faFileSignature}
                label='Edit / Sign'
                labelPosition={labelPosition}
                onClick={this.handleMarkup}
              />
            )}
            {rotate && (
              <Button.Icon
                icon={faRedo}
                label='Rotate'
                labelPosition={labelPosition}
                onClick={this.handleRotate}
              />
            )}
            {zoom && (
              <React.Fragment>
                <Button.Icon
                  icon={faSearchPlus}
                  label='Zoom In'
                  labelPosition={labelPosition}
                  onClick={this.handleZoomIn}
                />
                <Button.Icon
                  icon={faSearchMinus}
                  label='Zoom Out'
                  labelPosition={labelPosition}
                  onClick={this.handleZoomOut}
                />
              </React.Fragment>
            )}
            {trash && (
              <Button.Icon
                icon={faTrashAlt}
                label='Delete'
                labelPosition={labelPosition}
                onClick={this.handleTrash}
              />
            )}
          </React.Fragment>
        )}
        {options && (markup || rotate || trash) && (
          <Options
            trigger={
              <Button.Icon
                onClick={(e: any) => e.stopPropagation()}
                icon={faEllipsisVAlt}
              />
            }
            hoverable={true}
            open={showEllipsisOptions}
            onOpen={this.openEllipsisOptions}
            onClose={this.closeEllipsisOptions}
          >
            {markup && (
              <SubAction onClick={this.handleMarkup}>
                <span>
                  <Icon icon={faFileSignature} />
                </span>
                E-Sign
              </SubAction>
            )}
            {rotate && (
              <SubAction onClick={this.handleRotate}>
                <span>
                  <Icon icon={faRedo} />
                </span>
                Rotate
              </SubAction>
            )}
            {trash && (
              <SubAction onClick={this.handleTrash}>
                <span>
                  <Icon icon={faTrashAlt} />
                </span>
                Delete
              </SubAction>
            )}
          </Options>
        )}
        {confirm && (
          <Button.Bubble
            icon={faCheck}
            label='Submit'
            labelPosition={labelPosition}
            onClick={this.handleConfirm}
          />
        )}
        {cancel && (
          <Button.Icon
            icon={faTimes}
            label='Cancel'
            labelPosition={labelPosition}
            onClick={this.handleCancel}
          />
        )}
      </Container>
    )
  }

  private getButtons = () => {
    const { actions, permission } = this.props
    const buttons: ButtonsType = {}
    actions.forEach(action => {
      if (permission <= ActionPermissions[action]) {
        buttons[action] = true
      }
    })
    this.setState({ buttons })
  }

  private closeEllipsisOptions = () => {
    this.setState({ showEllipsisOptions: false })
  }

  private openEllipsisOptions = () => {
    this.setState({ showEllipsisOptions: true })
  }

  private toggleLock = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    if (doc.isLocked) {
      onAction(ActionEnum.Unlock, doc)
    } else {
      onAction(ActionEnum.Lock, doc)
    }
  }

  private handleDownload = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc } = this.props
    window.open(doc.url, '_blank')
  }

  private handleRename = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.Rename, doc)
  }

  private handleTrash = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.Trash, doc)
  }

  private handleZoomIn = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.ZoomIn, doc)
  }

  private handleZoomOut = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.ZoomOut, doc)
  }

  private handleRotate = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.Rotate, doc)
  }

  private handleMail = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.Mail, doc)
  }

  private handleMarkup = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.Markup, doc)
  }

  private handleConfirm = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.Confirm, doc)
  }

  private handleCancel = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { doc, onAction } = this.props
    onAction(ActionEnum.Cancel, doc)
  }
}

export default ActionButtons
