// Import Packages
import { Label, Tab } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Property from 'design/icons/leads/icon 1.png'
import Workflow from 'design/icons/leads/icon 2.png'
import Pro from 'design/icons/leads/paid.png'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display:flex;
  background-color: ${Colors.White1000};
  overflow-x: auto;

  .styledTab {
    border-right: 1px solid ${Colors.Black905};
    width: 100%;
  }
`

const StyledTab = styled(Tab)`
  .ui.secondary.pointing.menu {
    padding-top: 10px;
    padding-left: 30px;

    @media (max-width: 1100px) {
      padding-left: 15px;
    }
  }

  .ui.secondary.pointing.menu .item {
    margin-right: 25px;
    color: ${Colors.DarkBlue200};

    @media(max-width: 1150px) {
      margin-right: 0px;
      padding: 0.857143em 0.242857em;
    }

    @media (min-width: 1150px) and (max-width: 1350px) {
      margin-right: 0px;
      padding: 0.857143em 0.742857em;
    }

    @media (min-width: 1350px) and (max-width: 1450px) {
      margin-right: 0px;
      padding: 0.857143em 1.142857em;
    }
  }

  .fa-envelope-open-text {
    width: 18px;
    height: 16px;
    margin-right: 8px;
  }

  .fa-home-heart {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  .ui.secondary.pointing.menu {
    border-bottom: 1px solid ${Colors.DarkBlue200};
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 3px solid ${Colors.DarkBlue200};
    color: ${Colors.DarkBlue200};
    /* padding-left: 0;
    padding-right: 0; */
  }
`

const StyledLabel = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 0.4em !important;
  }

  &.ui.label {
    background: ${Colors.Red810} !important;
    color: ${Colors.White1000};
  }
`

const StyledActivityTab = styled(Tab)`
  .ui.secondary.pointing.menu {
    padding-top: 17px;
    margin-bottom: 0px !important;
  }

  .ui.secondary.pointing.menu .item {
    color: ${Colors.DarkBlue200};
  }

  .ui.secondary.pointing.menu .active.item {
    color: ${Colors.DarkBlue200};
  }

  .ui.secondary.pointing.menu {
    border-bottom: 1px solid ${Colors.DarkBlue200};
    margin-bottom: -6px;
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 0px;
  }
`

const ProImage = styled.div`
  background: url(${Pro});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 18px;
  align-self: center;
  margin-right: 3px;
`

const WorkflowImage = styled.div`
  background: url(${Workflow});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 22px;
  align-self: center;
  margin-right: 6px;
`

const PropertyPassImage = styled.div`
  background: url(${Property});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 22px;
  align-self: center;
  margin-right: 6px;
`

export {
  Container,
  ProImage,
  PropertyPassImage,
  StyledActivityTab,
  StyledLabel,
  StyledTab,
  WorkflowImage
}
