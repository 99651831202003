// Import Packages
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Icon } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

import { OfficeItemsDetails } from 'store/Offices/Types'

// Import Components
import {
  deleteCard,
  editOffice,
  ShowComposeMail,
  ShowOfficeReport,
  ShowViewDetails,
  SingleCheckBox,
  ToggleCheckBox,
  ToggleOfficeStatus,
} from './Offices'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  BranchName,
  CheckboxWrapper,
  DropDownPopup,
  EmailIcon,
  FullName,
  Label,
  Manager,
  Member,
  MemberProfile,
  PhoneIcon,
  ProfileImage,
  StyledCheckbox,
  StyledDropdown,
  StyledLabel,
  StyledPopup,
  StyledStatusDropdown,
} from './Styled'

const options = [
  { key: 1, text: 'Active', value: 'Active' },
  { key: 2, text: 'Inactive', value: 'Inactive' },
]

let checked = false

export const Checked = (data: boolean) => {
  checked = !data
}

export const CustomHeaderCheckBox = () => {
  const onChange = () => {
    ToggleCheckBox()
  }
  return (
    <CheckboxWrapper>
      <StyledCheckbox checked={checked} onClick={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomCheckBox = ({ rowData }: { rowData: OfficeItemsDetails }) => {
  const onChange = () => {
    SingleCheckBox(rowData._id)
  }
  return (
    <CheckboxWrapper>
      <StyledCheckbox checked={rowData.isChecked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomBranchName = ({ rowData }: { rowData: OfficeItemsDetails }) => {
  return (
    <BranchName>
      {rowData.branchName}
    </BranchName>
  )
}

export const CustomLocation = ({ rowData }: { rowData: OfficeItemsDetails }) => {
  const location = [rowData.street, rowData.city, rowData.state, rowData.country, rowData.zipCode].join(', ')
  return (
    <Manager>
      {location}
    </Manager>
  )
}

export const CustomActions = ({ rowData }: { rowData: OfficeItemsDetails }) => {
  const showViewDetails = () => {
    ShowViewDetails(rowData._id)
  }
  const deleteOffice = () => {
    deleteCard(rowData.state, rowData._id)
  }
  const editOfficeDetails = () => {
    editOffice(rowData)
  }
  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className='action-drop-down'>
            <Dropdown.Menu>
              <Dropdown.Item text={Strings.office.viewDetails} onClick={showViewDetails} />
              <Link
                to={Routes.primary.reports.path + '?previous-page=offices'}
                onClick={() => ShowOfficeReport('offices')}
              >
                <Dropdown.Item text={Strings.office.viewReport} />
              </Link>
              {rowData.showAddButton && <Dropdown.Item text={Strings.office.edit} onClick={editOfficeDetails} />}
              {rowData.showAddButton && <Dropdown.Item text={Strings.office.delete} onClick={deleteOffice} />}
            </Dropdown.Menu>
          </div>
        }
        trigger={
          <Icon style={{ cursor: 'pointer' }} name='caret down' />
        }
        hoverable={true}
        position='bottom left' />
    </StyledDropdown>
  )
}

export const CustomManager = ({ rowData }: { rowData: OfficeItemsDetails }) => {
  return (
    <Manager>
      <ProfileImage background={rowData.profileImage} />
      <FullName>{rowData.firstName ? `${rowData.firstName} ${rowData.lastName}` : `No Manager`}</FullName>
      <StyledPopup style={{ textTransform: 'lowercase' }} trigger={
        <EmailIcon onClick={() => ShowComposeMail(rowData.email)} />
      } content={rowData.email ? rowData.email : 'No Email Added'} />
      <StyledPopup trigger={
        <PhoneIcon />
      } content={rowData.phone ? rowData.phone : 'No Phone Number Added'} />
    </Manager>
  )
}

export const CustomTeamMembers = ({ rowData }: { rowData: OfficeItemsDetails }) => (
  <Member>
    {
      rowData.teamMembers.map((items: any, index: number) => {
        const left = index * 15
        while (index < 3) {
          return <StyledPopup trigger={
            <MemberProfile key={index} background={items.profileImage} left={left} />
          } content={`${items.firstName} ${items.lastName}`} />
        }
        return null
      })
    }
    {rowData.teamMembers.length > 3 ? (
      <Label>
        <StyledPopup trigger={
          <StyledLabel>...</StyledLabel>
        } content={`+${rowData.teamMembers.length - 3}`} />
      </Label>
    ) : null
    }
  </Member>
)

export const CustomStatus = ({ rowData }: { rowData: OfficeItemsDetails }) => {
  const onHandleChange = (event: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    if (rowData.status !== value) {
      if (value === 'Inactive') {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepItActive,
          confirmButtonText: Strings.kanbanView.yesMakeItInactive,
          showCancelButton: true,
          text: Strings.kanbanView.recoverOffices,
          title: Strings.kanbanView.sure,
          type: 'warning',
        }).then((result) => {
          if (result.value) {
            ToggleOfficeStatus(rowData._id, value)
          }
        })
      } else {
        ToggleOfficeStatus(rowData._id, value)
      }
    }
  }
  return (
    <div>
      <StyledStatusDropdown
        inline={true}
        options={options}
        onChange={onHandleChange}
        defaultValue={rowData.status === 'Inactive' ? options[1].value : options[0].value}
        background={rowData.status === 'Inactive' ? Colors.Black810 : Colors.Green500}
        color={rowData.status === 'Inactive' ? Colors.Black500 : Colors.White1000} />
    </div>
  )
}
