import { ActionCreator } from 'redux'
import Actions from 'store/Actions'
import {
  AddCategoryEvent,
  AddDocumentEvent,
  AddPropertyEvent,
  ChangeChecklistValueEvent,
  DeleteCategoryEvent,
  DeleteDocumentEvent,
  DeletePropertyEvent,
  EditCategoryEvent,
  EditDocumentEvent,
  EditPropertyEvent,
  ReinsertChecklistPropertyEvent,
  UpdateChecklistData
} from './Types'

export const addDocument: ActionCreator<AddDocumentEvent> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.addDocument
})

export const deleteDocument: ActionCreator<DeleteDocumentEvent> = (
  index: number
) => ({
  payload: {
    index
  },
  type: Actions.deleteDocument
})

export const editDocument: ActionCreator<EditDocumentEvent> = (
  newName: string,
  index: number
) => ({
  payload: {
    index,
    newName
  },
  type: Actions.editDocument
})

export const addCategory: ActionCreator<AddCategoryEvent> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.addCategory
})

export const deleteCategory: ActionCreator<DeleteCategoryEvent> = (
  index: number
) => ({
  payload: {
    index
  },
  type: Actions.deleteCategory
})

export const editCategory: ActionCreator<EditCategoryEvent> = (
  newName: string,
  index: number
) => ({
  payload: {
    index,
    newName
  },
  type: Actions.editCategory
})

export const addProperty: ActionCreator<AddPropertyEvent> = (
  categoryIndex: number,
  name: any,
  id: string
) => ({
  payload: {
    categoryIndex,
    id,
    name
  },
  type: Actions.addProperty
})

export const deleteProperty: ActionCreator<DeletePropertyEvent> = (
  categoryIndex: number,
  propertyIndex: number
) => ({
  payload: {
    categoryIndex,
    propertyIndex
  },
  type: Actions.deleteProperty
})

export const editProperty: ActionCreator<EditPropertyEvent> = (
  newName: string,
  categoryIndex: number,
  propertyIndex: number
) => ({
  payload: {
    categoryIndex,
    newName,
    propertyIndex
  },
  type: Actions.editProperty
})

export const changeChecklistValue: ActionCreator<ChangeChecklistValueEvent> = (
  categoryIndex: number,
  documentId: string,
  newValue: boolean,
  propertyIndex: number
) => ({
  payload: {
    categoryIndex,
    documentId,
    newValue,
    propertyIndex
  },
  type: Actions.changeChecklistValue
})

export const reinsertChecklistProperty: ActionCreator<
  ReinsertChecklistPropertyEvent
> = (categoryIndex, fromIndex, toIndex) => ({
  payload: {
    categoryIndex,
    fromIndex,
    toIndex
  },
  type: Actions.reinsertChecklistProperty
})

export const updateChecklistData: ActionCreator<UpdateChecklistData> = (
  categories: any,
  documents: any
) => ({
  payload: {
    categories,
    documents
  },
  type: Actions.updateChecklistData
})
