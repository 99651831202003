// Import Packages
import { Label, Popup, Tab } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'
interface Props {
  background: string
}

const Container = styled.div`
  display: flex;
`

const LeftPanel = styled.div`
  width: 40%;
  background: ${Colors.White510};
  padding: 20px;
  color: ${Colors.Black500};
`

const Due = styled.div`
  font-size: 12px;
`

const AddTaskName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 100px;
`

const StyledIcon = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: ${Colors.White1000};
  box-shadow: 1px 3px 10px 2px rgba(0,0,0,.2);
  display: flex;
  justify-content: center;
  align-items: center;

  .fa-envelope {
    width: 25px;
    height: 25px;
  }

  .fa-sms {
    width: 25px;
    height: 25px;
  }

  .fa-handshake {
    width: 25px;
    height: 25px;
  }

  .fa-ticket {
    width: 25px;
    height: 25px;
  }

  .fa-phone {
    width: 25px;
    height: 25px;
    transform: rotate(100deg);
  }
`

const Icons = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  margin-top: 14px;
`

const AddTaskNameText = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
`

const RelatedTo = styled.div`
  font-size: 12px;
  display: flex;
`

const EventDescription = styled.div`
  margin-left: 6px;
  text-align: justify;
  color: ${Colors.Black500};
  font-size: 12px;
  text-transform: capitalize;
`

const EventProperty = styled.div`
  margin-left: 6px;
  text-align: justify;
  color: ${Colors.Black500};
  font-size: 12px;
`

const Description = styled.div`
  margin-left: 6px;
  text-align: justify;
  color: ${Colors.Black500};
  font-size: 12px;
  text-transform: capitalize;
`

const Address = styled.div`
  margin-left: 6px;
  font-size: 12px;
  text-transform: 'capitalize';
`

const TaskDescription = styled.div`
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
`

const Divider = styled.div`
  border: 1px solid ${Colors.Black840};
  width: 290px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const Propfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue200};
  border-radius: 50%;
  margin-left: 4px;
`

const Labels = styled.div`
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  border: 1px solid ${Colors.DarkBlue220};
  margin-left: 4px;

  .fa-plus {
    margin-top: 7px;
    color: ${Colors.DarkBlue200};
  }
`

const RightPanel = styled.div`
  padding: 10px;
  width: 70%;
`

const StyledLabel = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }

  &.ui.label {
    background: ${Colors.Red810} !important;
    color: ${Colors.White1000};
  }
`

const StyledTab = styled(Tab)`
  .ui.secondary.pointing.menu .active.item {
    font-size: 16px;
    color: ${Colors.DarkBlue200};
    border-bottom: 3px solid ${Colors.DarkBlue200};
    padding-left: 0;
    padding-right: 0;
    margin-left: 35px;
  }

  .ui.secondary.pointing.menu .item {
    color: ${Colors.Black500};
    padding-left: 0;
    padding-right: 0;
    margin-left: 35px;
  }

  .ui.secondary.pointing.menu {
    font-size: 16px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(34,36,38,.15);
  }
`

const ContentWrapper = styled.section`
  margin: 0 auto;
  overflow-x: auto;
  margin-left: 0;
  margin-top: 20px;
  overflow: visible;
  width: 100%;
`

const FlexWrapper = styled.ul`
  width: 100%;
  padding: 0px;
  height: 85px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: auto;
  overflow-y: hidden;
  margin-top: 10px;
  ${scrollbars};
`

const SharedWith = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${Colors.Black500};
  display: flex;

  span:nth-child(1) {
    flex-grow: 1;
  }

  span:nth-child(2) {
    text-decoration: underline;
    color: ${Colors.DarkBlue200};
    cursor: pointer;
  }

`

const PersonContainer = styled.li`
  display: inline-block;
  margin-right: 20px;
  position: relative;
`

const Wrapper = styled.div`
  width: 75px;
  display: -webkit-inline-box;

  .people-name {
    position: relative;
  }

  .people-name:first-child .peoples {
    left: -5px;
  }
`

const People = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
`

const PeopleName = styled.div`
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
`

const PeopleStyledPopup = styled(Popup)`
  &.ui.popup {
    color: ${Colors.Black500};
    padding: 1em 1em;
  }
`

const Email = styled.div`
  display: flex;
  justify-content: center;

  .fa-phone {
    transform: rotate(100deg);
  }

  .fa-eye {
    color: ${Colors.DarkBlue200};
  }

  .svg-inline--fa {
    margin-right: 6px;
    cursor: pointer;
  }
`

export {
  Address,
  AddTaskName,
  AddTaskNameText,
  PeopleStyledPopup,
  Container,
  Divider,
  Due,
  Icons,
  Labels,
  LeftPanel,
  RelatedTo,
  RightPanel,
  Propfile,
  EventDescription,
  EventProperty,
  Description,
  SharedWith,
  StyledIcon,
  StyledLabel,
  TaskDescription,
  StyledTab,
  ContentWrapper,
  FlexWrapper,
  People,
  PeopleName,
  PersonContainer,
  Email,
  Wrapper
}
