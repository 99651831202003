import gql from 'graphql-tag'

export const SEND_ACCOUNT_LINK = gql`
  query sendAccountLink($input: AccountLinkInput) {
    sendAccountLink(input: $input) {
      _id
      userName
      firstName
      lastName
    }
  }
`

export const SEND_ACCOUNT_OTP = gql`
  query sendAccountOtp($input: AccountOtpInput) {
    sendAccountOtp(input: $input)
  }
`

export const GET_ACCOUNT_STATUS = gql`
  query getAccountStatus($userName: String!) {
    getAccountStatus(filter: { userName: $userName }) {
      status
    }
  }
`
