// Import Packages
import * as React from 'react'
import { Button, Form, Input } from 'semantic-ui-react'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'

// Import Images and Icons
import IconChangePassword from 'design/icons/userProfile/changePasswordPopup/Edit-Password.jpg'

// Import Components
import Modal from 'shared/Modal'
import PasswordInputs from 'shared/PasswordInputs'
import { validatePassword } from 'shared/PasswordInputs/Validation'
import Toast from 'shared/Toast'
import { handleValidation } from './Validation'

// Import Graphql Mutations
import client from 'queries/apollo'
import { CHANGE_PASSWORD } from 'queries/graphql/Users/Mutation'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import { DetailContainer, ImageContainer, SegmentGroup } from './Styled'

const ChangePasswordStrings = Strings.userProfile.changePassword

interface Props {
  handleClose: (toggle: boolean) => void
}

interface State {
  passwordDetails: any
  errors: any
  user: any
  animate: boolean
  form: any
}

export default class ChangePasswordModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    confirmPassword: '',
    errors: {},
    newPassword: '',
    oldPassword: '',
    passwordDetails: {},
    user: {},

    form: {
      email: '',
      errors: {},
      loading: false,
      oldPassword: '',
      password: '',
      passwordConfirm: ''
    }
  }

  public async componentDidMount() {
    const user = await getLoggedInUser()
    this.setState({ user: user })
  }

  public render() {
    const { errors, animate, form } = this.state
    return (
      <Modal
        content={
          <div>
            <Form>
              <SegmentGroup horizontal={true}>
                <ImageContainer>
                  <Image src={IconChangePassword} size="small" />
                </ImageContainer>
                <DetailContainer>
                  <Form.Field
                    control={Input}
                    name="oldPassword"
                    type="password"
                    placeholder="Current Password"
                    error={errors[`oldPassword`]}
                    value={form.oldPassword}
                    onChange={this.handleChange}
                  />
                  <div className="password-confirm">
                    <PasswordInputs
                      fluid={false}
                      onChange={this.handleChange}
                      password={form.password}
                      passwordError={!!form.errors[`password`]}
                      passwordConfirm={form.passwordConfirm}
                      passwordConfirmError={!!form.errors[`passwordConfirm`]}
                    />
                  </div>
                  <Button onClick={this.submit} size="large" type="submit">
                    {ChangePasswordStrings.save}
                  </Button>
                </DetailContainer>
              </SegmentGroup>
            </Form>
          </div>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={800}
      />
    )
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose(false)
    }, 300)
  }

  private handleChange = async (e: any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    form.errors[name] = ''
    this.setState({ form })
  }

  private submit = async () => {
    const { form, user } = this.state
    const resultOldPassword = handleValidation(form)
    const resultNewPassword = validatePassword(form)
    this.setState({ errors: resultOldPassword.errors })
    if (resultOldPassword.formIsValid) {
      if (resultNewPassword.formIsValid) {
        const updatePassword = await client.mutate({
          mutation: CHANGE_PASSWORD,
          variables: { oldPassword: form.oldPassword, newPassword: form.password, userId: user[`_id`] }
        })
        const { data } = updatePassword
        if (!data.updatePassword) {
          Toast({ message: 'Current Password Mismatch', type: 'error' })
        } else {
          Toast({ message: 'Password updated Successfully', type: 'success' })
          this.closeModal()
        }
      } else {
        Toast({ message: resultNewPassword.errors.password, type: 'error' })
      }
    }
  }
}
