import * as React from 'react'

import Transition from 'shared/Transition'

import {
  ChildLines,
  HeadLine,
  Expander,
  ToolTip,
} from './Styled'

interface Props {
  hasItems: boolean
  header: React.ReactNode
  defaultIsExpanded: boolean
  items: React.ReactNode
  requireExpanderClick: boolean
}

interface State {
  isExpanded: boolean
}

class GroupedList extends React.Component<Props, State> {
  public static defaultProps = {
    hasItems: true,
    defaultIsExpanded: false,
    requireExpanderClick: false,
  }

  public state = {
    isExpanded: false,
  }

  public componentDidMount = () => {
    const { defaultIsExpanded } = this.props
    this.setState({ isExpanded: defaultIsExpanded })
  }

  private containerRef: any = React.createRef()

  public render = () => {
    const {
      header,
      items,
      hasItems,
      requireExpanderClick,
    } = this.props

    const {
      isExpanded
    } = this.state

    return (
      <React.Fragment>
        <HeadLine onClick={this.handleHeadClick} isActive={!requireExpanderClick}>
          {hasItems && (
            <ToolTip
              content={`Click to ${isExpanded ? 'minimize' : 'expand'}`}
              position='top center'
              trigger={(
                <Expander
                  onClick={this.toggleExpansion}
                  isActive={isExpanded}
                />
              )}
            />
          )}
          {header}
        </HeadLine>
        <Transition
          on={isExpanded}
          type='expandY'
          elem={this.containerRef}
        >
          <ChildLines innerRef={this.containerRef}>
            {items}
          </ChildLines>
        </Transition>
      </React.Fragment>
    )
  }

  private handleHeadClick = (e:React.SyntheticEvent) => {
    const { requireExpanderClick } = this.props
    if (requireExpanderClick) {
      return
    }
    this.toggleExpansion(e)
  }

  private toggleExpansion = (e:React.SyntheticEvent) => {
    e.stopPropagation()
    this.setState((prev: State) => ({ isExpanded: !prev.isExpanded }))
  }
}

export default GroupedList
