// Import Packages
import * as React from 'react'
import { Icon } from 'semantic-ui-react'

// Import Components
import KanbanLaneHeaderPopup from './KanbanLaneHeaderPopup'

// Import Styled Components
import {
  Count,
  CounterIcon,
  CounterItems,
  DownButton,
  EditBoardName,
  Header,
  Pipe,
  PopupWrapper,
  RecordCount,
  StyledIcon,
  StyledIconContainer,
  StyledInput,
  StyledPopup,
  Title
} from './Styled'

interface Props {
  ActionComponent: React.ReactType | null
  appearance?: boolean
  boardId: string
  color: string
  counterIcon: string
  counterItems: number
  description: string
  icon: any
  icons: any[]
  itemsLength: number
  setColor: (color: string) => void
  setNewBoardName: (id: string, boardName: string) => void
  setNewDescription: (id: string, description: string) => void
  setNewIcon: (id: string, icon: string) => void
  showAddButton: boolean
  showAppearance?: boolean
  title: string
}

interface State {
  boardName: string
  isOpen: boolean
  modal: any
  showInputBox: boolean
  showModal: boolean
}

class KanbanLaneHeader extends React.Component<Props, State> {
  public static defaultProps = {
    counterIcon: '',
    counterItems: 0
  }

  public state = {
    boardName: '',
    isOpen: false,
    modal: null,
    showInputBox: false,
    showModal: false
  }

  public render() {
    const {
      ActionComponent,
      boardId,
      color,
      itemsLength,
      title,
      icons,
      icon,
      description,
      appearance,
      showAddButton,
      showAppearance,
      counterItems,
      counterIcon
    } = this.props
    const { boardName, showInputBox } = this.state
    const { modal, showModal } = this.state
    const toggleFunction = () => {
      this.onToggle()
      this.setState({ boardName: title })
    }
    return (
      <Header>
        <Title color={color}>
          {icon && (
            <StyledIconContainer>
              {description ? (
                <StyledPopup
                  position="top left"
                  trigger={<StyledIcon background={icon.imageUrl} />}
                  content={description}
                />
              ) : (
                <StyledIcon background={icon.imageUrl} />
              )}
            </StyledIconContainer>
          )}
          {showInputBox ? (
            <EditBoardName>
              <StyledInput
                placeholder="Search..."
                value={boardName}
                onChange={this.onChangeBoardName}
                width={icon ? 80 : 90}
              />
              <Icon name="check" onClick={this.setNewBoardName} />
            </EditBoardName>
          ) : (
            <div onClick={toggleFunction} style={{ marginTop: '2px' }}>{`${title.toUpperCase()}`}</div>
          )}
          {counterItems > 0 &&
            title !== 'Completed' && (
              <CounterItems>
                {counterIcon !== '' && <CounterIcon name={counterIcon} />}
                <Count>{counterItems}</Count>
              </CounterItems>
            )}
        </Title>
        <RecordCount color={color}>{`${itemsLength} Records`}</RecordCount>
        {ActionComponent && (
          <React.Fragment>
            <Pipe />
            <DownButton>
              <PopupWrapper
                trigger={<Icon name="caret down" />}
                content={
                  <KanbanLaneHeaderPopup
                    ActionComponent={ActionComponent}
                    appearance={appearance}
                    boardId={boardId}
                    closeActionPopup={this.closeActionPopup}
                    color={color}
                    description={description}
                    icons={icons}
                    setColor={this.setColor}
                    setIcon={icon}
                    setNewDescription={this.setNewDescription}
                    setNewIcon={this.setNewIcon}
                    showAddButton={showAddButton}
                    showAppearance={showAppearance}
                    toggleModal={this.toggleModal}
                  />
                }
                open={this.state.isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                hoverable={true}
                position={'bottom left'}
              />
            </DownButton>
          </React.Fragment>
        )}
        {showModal && modal}
      </Header>
    )
  }

  private handleOpen = () => {
    this.setState({ isOpen: true })
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private closeActionPopup = () => {
    this.setState({ isOpen: false })
  }

  private onChangeBoardName = (e: any) => {
    this.setState({ boardName: e.target.value })
  }

  private setNewBoardName = () => {
    const { boardId, setNewBoardName } = this.props
    const { boardName } = this.state
    setNewBoardName(boardId, boardName)
    this.onToggle()
  }

  private onToggle = () => {
    const { showInputBox } = this.state
    const { showAppearance, appearance } = this.props
    if (showAppearance || !appearance) {
      this.setState({ showInputBox: !showInputBox })
    }
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    setNewDescription(id, description)
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    setNewIcon(id, icon)
  }

  private setColor = (color: string) => {
    const { setColor } = this.props
    setColor(color)
  }

  private toggleModal = (modal: any) => {
    this.setState(prev => ({
      modal,
      showModal: !prev.showModal
    }))
  }
}

export default KanbanLaneHeader
