import * as React from 'react'

import Transition from 'shared/Transition'

import ConciergeDog from 'design/images/onboarding/concierge-dog.png'
import { Strings } from 'utils'

import { Col, Continue, Description, Img, StyledButton, Title } from '../../Styled'

import { ActionEnum, TransitEnum } from '../../Types'

interface Props {
  onAction: (action: ActionEnum, data: any) => void
  transit: TransitEnum
}

interface State {
  isReady: boolean
}

const {
  transactionModal: {
    CONCIERGE: { DESCRIPTION, INPUT, TITLE }
  }
} = Strings

class FinalStep extends React.Component<Props, State> {
  public state = {
    isReady: false
  }

  public componentDidMount = () => {
    const image = new Image()
    image.onload = () => {
      this.setState({ isReady: true })
    }
    image.src = ConciergeDog
  }

  public render() {
    const { transit } = this.props
    const { isReady } = this.state

    const transition = isReady && transit === TransitEnum.Enter

    return (
      <Col height={100}>
        <Transition stagger={30} on={transition}>
          <Img src={ConciergeDog} />
          <Title>{TITLE.final1}</Title>
          <Col>
            <Transition delay={60} on={transition} stagger={30}>
              <Description>{DESCRIPTION.final1}</Description>
              <Continue onClick={this.handleContinue}>{INPUT.finalAgain}</Continue>
              <Col>
                <StyledButton onClick={this.handleComplete} content={INPUT.finalClose} />
              </Col>
            </Transition>
          </Col>
        </Transition>
      </Col>
    )
  }

  private handleContinue = () => {
    const { onAction } = this.props
    onAction(ActionEnum.Step, null)
  }

  private handleComplete = () => {
    const { onAction } = this.props
    onAction(ActionEnum.Complete, null)
  }
}

export default FinalStep
