import { Action } from 'redux'

import {
  PropertyType,
  TransactionType,
} from 'app/Transactions/Details/Types'

export enum DispatchEnum {
  MergeTransaction = '@@MERGE_TRANSACTION',
  MergeTransactionProperty = '@@MERGE_TRANSACTION_PROPERTY',
}

export interface MergeTransactionAction extends Action {
  payload: Partial<TransactionType>
  type: DispatchEnum
}

export interface MergeTransactionPropertyAction extends Action {
  payload: {
    transactionId: string
    data: Partial<PropertyType>
  }
  type: DispatchEnum
}
