import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

import Button from 'shared/Button'
import Toast from 'shared/Toast/Toast'

import { Col, Line } from '../Styled'
import { Row, Bubble, ToolTip, Copy } from '../../Styled'

import { AppState } from 'store/CombineReducers'

import { UserPassType, UserPassDictType, PassStatusEnum } from 'store/Pass/Types'
import { ID, TOGGLE_OPTIONS, ActionEnum } from '../../Types'

interface StoreProps {
  passes: UserPassDictType
}

interface OwnProps {
  isAdmin: boolean
  pass: ID
  onAction: (action:ActionEnum, data:any) => void
}

type Props = StoreProps & OwnProps

class LineItem extends React.Component<Props, {}> {
  public render() {
    const { isAdmin, pass, passes } = this.props
    const { user, settings }:UserPassType.Base = passes[pass]
    const isPending = settings.status.includes('Pending')
    const request = settings.status.includes('Deactivation') ? 'Deactivation' : 'Activation'
    const url = isAdmin ? `${settings.tenantSubdomain}.idxpass.com` : `${settings.officeSubdomain}.idxpass.com`

    return (
      <Line justify='space-between'>
        <Row width={-1} spacing={0.2}>
          {isPending && (
            <ToolTip
              content={`Requested ${request} on ${moment(settings.requestedAt).format('ll')}`}
              position='top center'
              trigger={<div><Bubble status={settings.status} /></div>}
            />
          )}
          {!isAdmin && (
            <span>{user.firstName} {user.lastName}</span>
          )}
        </Row>
        <ToolTip
          content='Website URL (Click to Copy)'
          position='top center'
          trigger={(
            <Col primary={1}>
              <Copy
                justify={isAdmin ? 'flex-start' : 'flex-end'}
                text={url}
                onCopy={() => this.handleCopy(url)}
              >
                <span>{url}</span>
              </Copy>
            </Col>
          )}
        />
        <Col justify='flex-end' width={-1}>
          <Button.Toggle
            monochrome={0}
            name='Status'
            onChange={this.toggleStatus}
            options={TOGGLE_OPTIONS}
            showLabels={false}
            value={(settings.status === PassStatusEnum.Active || settings.status === PassStatusEnum.PendingDeactivation) ? PassStatusEnum.Active : PassStatusEnum.Inactive}
          />
        </Col>
      </Line>
    )
  }

  private handleCopy = (url:string) => {
    Toast({
      message: `Copied ${url} to clipboard`,
      type: 'success'
    })
  }

  private toggleStatus = async (e: any, { value }: any) => {

    const { pass, passes, onAction } = this.props
    const { user, settings } = passes[pass]
    const update = {
      _id: pass,
      settings: {
        status: value,
        isPending: settings.status !== PassStatusEnum.PendingActivation
      }
    }
    const willEnable = value === PassStatusEnum.Active
    let text = `This action will ${willEnable ? 'enable' : 'disable'} the website pass for ${user.firstName}. This will affect your monthly subscription costs.`
    const confirm = await ConfirmAlert({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Proceed',
      showCancelButton: true,
      text,
      title: `${willEnable ? 'Enable' : 'Disable'} User Website?`,
      type: 'warning'
    })
    if (!confirm.value) {
      return
    }
    onAction(ActionEnum.Update, update)
  }
}

const mapStateToProps = (state: AppState) => ({
  passes: state.userPass.dict
})

export default connect(
  mapStateToProps,
  {}
)(LineItem)
