import * as React from 'react'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import ConfirmAlert from 'sweetalert2'

import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'
import * as PassModal from '../PassModal'
import Pass from './Pass'

import { deleteUserPass, reorderingUserPass, updateUserPass } from '../Mutations'

import { Container } from './Styled'

import { PassSectionType, UserPassType } from 'app/Dashboard/MainContent/Passes/Types'
import { PassTypeEnum } from 'store/Pass/Types'
import { ModalType } from './Types'

interface Props {
  content: any
  getPassesSection: (data: any) => void
  section: PassSectionType
}

interface State {
  activePass: UserPassType
  modal: ModalType
  rename: string
  updatePassNameId: string
}

const SortableItem = SortableElement((props: any) => <Pass {...props} />)

const SortableList = SortableContainer(({ activePass, deletePass, passes, renamePass, showModal }: any) => {
  return (
    <Container width={-1}>
      {passes.map((pass: UserPassType, index: any) => (
        <SortableItem
          deletePass={deletePass}
          index={index}
          key={pass._id}
          pass={pass}
          renamePass={renamePass}
          showModal={showModal}
        />
      ))}
    </Container>
  )
})

class PassSection extends React.Component<Props, State> {
  public state = {
    activePass: {} as UserPassType,
    modal: {} as ModalType,
    rename: '',
    updatePassNameId: ''
  }

  public render() {
    const { getPassesSection, content } = this.props

    const { activePass, modal, rename } = this.state

    return (
      <React.Fragment>
        {modal.createTransaction && (
          <CreateTransactionModal onClose={this.closeTransactionModal} />
        )}
        {modal.rename && (
          <PassModal.RenameUserPass
            handleClose={this.closeModal}
            name={rename}
            updatePassName={this.updatePassName}
          />
        )}
        {modal.leadSummary && (
          <PassModal.LeadSummary
            closeModal={this.closeModal}
            onUpdate={getPassesSection}
            pass={activePass}
          />
        )}
        {modal.transactionSummary && (
          <PassModal.TransactionSummary
            closeModal={this.closeModal}
            onUpdate={getPassesSection}
            pass={activePass}
          />
        )}
        {modal.activityHotsheet && (
          <PassModal.ActivityHotSheet
            closeModal={this.closeModal}
            onUpdate={getPassesSection}
            pass={activePass}
          />
        )}
        {modal.assistant && (
          <PassModal.MyAssistant closeModal={this.closeModal} />
        )}
        {modal.concierge && (
          <PassModal.ConciergeSettings
            getPassSection={getPassesSection}
            onClose={this.closeModal}
            pass={activePass}
          />
        )}
        {modal.idxSettings && (
          <PassModal.IdxSettings onClose={this.closeModal} />
        )}
        {modal.websiteSolution && (
          <PassModal.WebsiteSolution onClose={this.closeModal} />
        )}
        {(content && content.length > 0) && (
            <SortableList
              axis="x"
              deletePass={this.deletePass}
              passes={content}
              lockAxis="x"
              lockToContainerEdges={true}
              onSortEnd={this.onSortEnd}
              renamePass={this.renamePass}
              showModal={this.showModal}
              useDragHandle={true}
            />
          )}
      </React.Fragment>
    )
  }

  private showModal = (activePass: any) => {
    const { passType } = activePass
    const modal = {} as ModalType

    switch (passType) {
      case PassTypeEnum.Transactions:
        modal.transactionSummary = true
        break

      case PassTypeEnum.Leads:
        modal.leadSummary = true
        break

      case PassTypeEnum.General:
        modal.assistant = true
        break

      case PassTypeEnum.ActivityHotSheet:
        modal.activityHotsheet = true
        break

      case PassTypeEnum.Concierge:
        modal.concierge = true
        break

      case PassTypeEnum.Idx:
        modal.idxSettings = true
        break

      case PassTypeEnum.WebsiteSolution:
        modal.websiteSolution = true
        break
    }

    this.setState({
      activePass,
      modal
    })
  }

  private updatePassName = async (name: string) => {
    const { getPassesSection } = this.props
    const { updatePassNameId } = this.state
    const input = {
      name
    }
    const response = await updateUserPass(updatePassNameId, input)
    getPassesSection(response)
  }

  private renamePass = (updatePassNameId: string, title: string) => {
    const modal = { rename: true }
    this.setState({ rename: title, updatePassNameId, modal })
  }

  private closeModal = (data?: boolean) => {
    this.setState({
      modal: {
        createTransaction: data
      } as ModalType
    })
  }

  private closeTransactionModal = () => {
    this.setState({ modal: {} as ModalType })
  }

  private deletePass = async (passId: string) => {
    const { getPassesSection } = this.props

    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, remove it!',
      showCancelButton: true,
      title: 'Confirm Pass Removal?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        const response = await deleteUserPass(passId)
        getPassesSection(response)
        ConfirmAlert('Deleted!', 'Your pass has been removed.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your pass is safe', 'error')
      }
    })
  }

  private onSortEnd = async ({ oldIndex, newIndex }: any) => {
    const { content, getPassesSection } = this.props
    const items = arrayMove(content, oldIndex, newIndex)
    const sortedData: any = []
    items.forEach((item: any, index: number) => {
      sortedData.push({
        _id: item._id,
        order: index + 1
      })
    })
    if (oldIndex !== newIndex) {
      const response = await reorderingUserPass(sortedData)
      getPassesSection(response)
    }
  }
}

export default PassSection
