// Import Packages
import * as React from 'react'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  Content,
  Reports,
  ReportsContainer,
  StyledButton,
  Text,
  Title,
  TriangleLeft,
  TriangleRight,
} from './Styled'

// Font Awesome Icons
import {
  faTable,
} from '@fortawesome/pro-light-svg-icons'
import {
  faChartPie,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faTable, faChartPie])

interface Props {
  activeTab: (tabNumber: number) => void
  showGridView: (view: string) => void
  tabActive: string
}

interface State {
  activeIndex: number
}

const icons = [
  { name: 'table', type: 'fal' }
]

class PickData extends React.Component<Props, State> {
  public state = {
    activeIndex: 0
  }

  public render() {
    const { tabActive } = this.props
    const { activeIndex } = this.state
    const icon = icons.map((items: any, index: any) => (
      <Content key={index} onClick={() => this.handleClick(index, items.name)}>
        <FontAwesomeIcon icon={[items.type, items.name]} />
        <StyledButton
          className={activeIndex === index ? 'active-button' : ''}>
          {activeIndex === index ? 'SELECTED' : 'SELECT'}
        </StyledButton>
      </Content>
    ))
    return (
      <Container>
        <Title onClick={this.activeTab} border={tabActive}>
          <Text><i>{Strings.reports.number3}</i>{Strings.reports.reportType}</Text>
          <TriangleLeft />
          <TriangleRight />
        </Title>
        <Reports>
          <ReportsContainer>
            {icon}
          </ReportsContainer>
        </Reports>
      </Container>
    )
  }

  private activeTab = () => {
    const { activeTab } = this.props
    activeTab(3)
  }

  private handleClick = (index: number, view: string) => {
    const { showGridView } = this.props
    this.setState({ activeIndex: index })
    showGridView(view)
  }
}

export default PickData
