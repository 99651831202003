import { createContact } from 'app/Contacts/Dashboard/ContactMutations'

import Toast from 'shared/Toast/Toast'
import { getLoggedInUser } from 'utils'

export const addNewContact = async (newContact: any) => {
  const user = await getLoggedInUser()

  const response: any = await createContact(newContact, '', newContact.contactBoard, false, user, false, false)
  if (!response) {
    Toast({
      message: 'An error occurred trying to create a new Contact',
      type: 'error'
    })
  }
}
