import Colors from 'design/Colors'
import styled from 'styled-components'

interface Props {
  background: string
}

const Description = styled.div`
  display: flex;
  color: ${Colors.Black500};
  margin-top: 20px;

  .fa-calendar-alt {
    width: 15px;
    height: 15px;
    color: ${Colors.DarkBlue200};
    margin-right: 4px;
  }
`

const DueIn = styled.div`
  text-align: end;
  margin-top: 12px;

  .ui.selection.dropdown {
    min-width: 8em;
  }

  .calendar {
    margin-right: 12px;
    margin-bottom: 6px;
  }
`

const Text = styled.div`
  width: 100%;
  text-align: justify;
  margin-right: 50px;
  font-size: 10px;
  line-height: 1.5;
`

const StyledCheckBox = styled.div`
  margin-top: 6px;

  .ui.checkbox input.hidden+label {
    margin-right: 15px;
    color: ${Colors.Black500};
    font-size: 12px;
  }

  .ui.checkbox label:before {
    width: 14px;
    height: 14px;
    border-radius: 0.214286rem;
    border: 1.5px solid ${Colors.Black550};
  }

  .ui.checkbox label:after {
    width: 14px;
    font-size: 12px;
  }
`

const Tags = styled.div`
  display: flex;
  margin-top: 20px;

  span {
    font-weight: bold;
    margin-right: 12px;
    color: ${Colors.DarkBlue200};
  }

  div {
    margin-right: 10px;
  }

  div > a.ui.label {
    color: ${Colors.DarkBlue200};
    position: relative;
    background-color: ${Colors.White490};
    font-size: 10px;
    font-weight: 500;
    min-width: 38px;
    width: auto;
  }

  .fa-times {
    position: absolute;
    top: -4px;
    right: 2px;
    height: 10px;
    width: 8px;
    color: ${Colors.Black530};
  }
`

const SharedWith = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 15px;

  span {
    font-weight: bold;
    margin-right: 20px;
    color: ${Colors.DarkBlue200};
    margin-top: 4px;
    font-size: 12px
  }
`

const Images = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  margin-right: 6px;
`

const Circle = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Black765};
  font-weight: bold;
  font-size: 8px;
  color: ${Colors.DarkBlue200};
`

export {
  Circle,
  Description,
  DueIn,
  Images,
  StyledCheckBox,
  SharedWith,
  Tags,
  Text,
}
