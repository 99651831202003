import gql from 'graphql-tag'

export const GET_TASK_BOARDS = gql`
  query getTaskBoards(
    $office: String
    $orderField: String
    $sortOrder: Int
    $user: String
    $searchData: TaskSearch
    $userPassFilter: UserPassTaskFilter
    $id: String
    $singleSkip: Int
  ) {
    getTaskBoards(
      where: { isActive: true, user: $user, office: $office }
      sorting: { field: $orderField, order: $sortOrder }
      filter: $searchData
      userPassFilter: $userPassFilter
      _id: $id
      singleSkip: $singleSkip
    ) {
      _id
      name
      boardOrder
      icon {
        key
        imageUrl
      }
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      tasks {
        _id
        type
        name
        description
        taskTime
        notes {
          _id
          description
          createdBy {
            _id
            firstName
            profileImage
          }
          createdAt
        }
        activities {
          _id
          type
          task {
            name
          }
          description
          updatedAt
        }
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
        }
        lead {
          _id
          firstName
          lastName
          shareWith {
            _id
            firstName
            lastName
            userName
            profileImage
            phones {
              type
              value
            }
          }
        }
        transaction {
          _id
          propertyId {
            _id
            type {
              _id
              name
            }
            subType {
              _id
              name
            }
            address {
              _id
              type
              streetNumber
              streetName
              city
              state
              country
              zipCode
            }
            images {
              _id
              name
              url
              order
            }
            price
            mlsId
          }
          transactionId
          transactionRole
          status
          tags
          progress
          createdAt
          updatedAt
          owner {
            _id
            firstName
            lastName
            userName
            profileImage
            phones {
              type
              value
            }
          }
          shareWith {
            _id
            firstName
            lastName
            userName
            profileImage
            phones {
              type
              value
            }
          }
        }
        messageEmail {
          attachments {
            _id
            url
            fileName
            fileType
          }
          bcc {
            _id
            name
            value
            initials
            color
          }
          cc {
            _id
            name
            value
            initials
            color
          }
          from {
            _id
            name
            value
            initials
            color
          }
          html
          preview
          subject
          text
          to {
            _id
            name
            value
            initials
            color
          }
        }
        sharedWith {
          _id
          firstName
          lastName
          userName
          profileImage
        }
        dueDate
        completionDate
        label {
          _id
          name
          icon
          colour
        }
        isActive
        status
      }
    }
  }
`
