import Colors from 'design/Colors'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import styled from 'styled-components'

const FeeGroup = styled.div`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    width: 100%;
    margin-left: 8px;
  }
`

const TypeSelectContainer = styled.div`
  display: flex;
  align-items: end;

  @media (max-width: 1100px) {
    width: 100%;
  }
`

const TextBoxContainer = styled.div`
  justify-content: flex-start;
  align-content: flex-end;
  display: flex;
  width: 100%;
`

const TextBoxLabel = styled(Label)`
  &&.label {
    border: none;
    width: inherit;
    background-color: transparent;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 10px;
  }
`

const TextBoxItem = styled.div`
  max-width: 33%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 30px;

  @media (max-width: 1150px) {
    max-width: 26%;
    margin-right: 6px;
  }

  @media (min-width: 1150px) and (max-width: 1250px) {
    max-width: 28%;
    margin-right: 10px;
  }

  @media (min-width: 1250px) and (max-width: 1350px) {
    max-width: 30%;
    margin-right: 12px;
  }

  .ui.input>input {
    font-size: 10px;
  }

  i.icon, i.icons {
    font-size: 10px;
    color: ${Colors.Black550};
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

`

const RangeItemContainer = styled.div`
  display: flex;

  @media (max-width: 1350px) {
    width: 100%;
  }
`

const AddItemContainer = styled.div`
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${Colors.DarkBlue200};
  font-weight: 600;
  text-decoration: underline;
  font-size: 12px;
`

const DeleteItemContainer = styled.div`
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${Colors.DarkBlue200};
  text-decoration: underline;
  padding-top: 50px;
  font-weight: 600;
  font-size: 12px;

  @media (max-width: 1350px) {
    margin-left: 0;
  }
`

const ProgressiveFeeContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
   width: 100%
  }
`

const BorderTree = styled.div`
  border: 3px solid ${Colors.White525};
  border-right: 0;
  padding: 2px;
  margin-left: 5px;
`

export {
  AddItemContainer,
  BorderTree,
  DeleteItemContainer,
  FeeGroup,
  ProgressiveFeeContainer,
  RangeItemContainer,
  TextBoxContainer,
  TextBoxItem,
  TextBoxLabel,
  TypeSelectContainer
}
