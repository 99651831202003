// Import Packages
import { sortBy } from 'lodash'

// Import Colors
import Colors from 'design/Colors'

const boardBorderColors = [`${Colors.Purple50}`, `${Colors.Orange490}`, `${Colors.Red10}`, `${Colors.Blue50}`, `${Colors.Green60}`, `${Colors.Grey800}`, `${Colors.Green560}`]

export const formatKanbanData = (newOfficeListData: any, officeSort: boolean) => {
  let loggedUser: any = localStorage.getItem('user')
  loggedUser = JSON.parse(loggedUser)
  const OfficeListData = Object.assign(newOfficeListData)
  for (const officelist of OfficeListData) {
    sortBy(officelist.offices, ['officeOrder'])
    if (officelist.offices) {
      officelist.color = getRandomColors()
      if (officelist.offices === null) {
        officelist.items = Object.assign([])
      } else {
        officelist.items = Object.assign(officelist.offices)
      }
      if (loggedUser.role === 'ADMIN') {
        officelist.showAddButton = true
      } else {
        officelist.showAddButton = false
      }
      if (officelist.items.length > 0) {
        const { items } = officelist
        // tslint:disable-next-line:forin
        for (const index in items) {
          if (items[index].users === null || items[index].users === undefined) {
            items[index].teamMembers = Object.assign([])
          } else {
            items[index].teamMembers = Object.assign(items[index].users)
          }
          if (items[index].users.length > 0) {
            const manager = getManagerDetails(items[index].users)
            items[index] = {
              ...items[index],
              ...manager,
            }
          }
        }
        if (!officeSort) {
          sortBy(officelist.offices, ['officeOrder'])
        }
      }
    }
  }
  return OfficeListData
}

export const reformatData = (newOfficeListData: any) => {

  const OfficeListData = Object.assign(newOfficeListData)
  for (const officelist of OfficeListData.getStates) {
    if (officelist.items || officelist.offices) {
      officelist.color = getRandomColors()
      if (officelist.items === null || officelist.items === undefined) {
        officelist.offices = Object.assign([])
      } else {
        officelist.offices = Object.assign(officelist.items)
      }
      // delete officelist.items
      if (officelist.offices.length > 0) {
        const { offices } = officelist
        // tslint:disable-next-line:forin
        for (const index in offices) {
          if (offices[index].teamMembers === undefined || offices[index].teamMembers === null) {
            offices[index].users = Object.assign([])
          } else {
            offices[index].users = Object.assign(offices[index].teamMembers)
          }
          // delete offices[index].teamMembers
          if (offices[index].firstName) {
            delete offices[index].firstName
            delete offices[index].lastName
            delete offices[index].profileImage
          }
          // offices[index] = {...offices[index], ...manager[0]}
        }
      }
    }
  }
  return OfficeListData
}

export const formatListData = (newOfficeListData: any) => {
  let loggedUser: any = localStorage.getItem('user')
  loggedUser = JSON.parse(loggedUser)

  const OfficeListData = Object.assign(newOfficeListData)
  // tslint:disable-next-line:forin
  for (const index in OfficeListData) {
    if (OfficeListData[index].users === null || OfficeListData[index].users === undefined) {
      OfficeListData[index].teamMembers = Object.assign([])
    } else {
      OfficeListData[index].teamMembers = Object.assign(OfficeListData[index].users)
    }
    if (OfficeListData[index].users.length > 0) {
      const manager = getManagerDetails(OfficeListData[index].users)
      OfficeListData[index] = {
        ...OfficeListData[index],
        ...manager,
      }
    }
    if (loggedUser.role === 'ADMIN') {
      OfficeListData[index].showAddButton = true
    } else {
      OfficeListData[index].showAddButton = false
    }
  }
  return OfficeListData
}

export const reformatListData = (newOfficeListData: any) => {
  const OfficeListData = Object.assign(newOfficeListData.getOffices)
  // tslint:disable-next-line:forin
  for (const index in OfficeListData) {
    if (OfficeListData[index].teamMembers === null || OfficeListData[index].teamMembers === undefined) {
      OfficeListData[index].users = Object.assign([])
    } else {
      OfficeListData[index].users = Object.assign(OfficeListData[index].teamMembers)
    }
    let manager = {}
    if (OfficeListData[index].users) {
      manager = OfficeListData[index].users.map((users: any) => {
        if (users.isPrimaryManager === true) {
          return {
            firstName: users.firstName,
            lastName: users.lastName,
            profileImage: users.profileImage
          }
        }
        return false
      })
    }
    OfficeListData[index] = { ...OfficeListData[index], ...manager[0] }
  }
  return OfficeListData
}

const getRandomColors = () => {
  return boardBorderColors[Math.floor(Math.random() * boardBorderColors.length)]
}

const getManagerDetails = (users:any) => {
  const manager: any = users.find((user:any) => user.isPrimaryManager) || users.find((user:any) => user.role === 'ADMIN') || users.find((user:any) => user.role === 'MANAGER') || {}

  return {
    firstName: manager.firstName || '',
    lastName: manager.lastName || '',
    managerId: manager._id || '',
    profileImage: manager.profileImage || '',
  }
}
