// Import Packages
import { Icon, Input, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { children, x } from 'design/Styled'

interface Props {
  background?: string
  width?: number
}

export const Header = styled.header`
  ${x};
  ${children};
  padding: 8px 15px;
  font-weight: 600;
`

export const Title = styled.div`
  font-size: 14px;
  display: flex;
  flex-grow: 1;
  color: ${props => props.color || Colors.Purple50};
  width: 10px;
  line-height: 1.2;
  align-items: center;
`

export const StyledIconContainer = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 3px solid ${Colors.White480};
  padding: 4px;
  margin-right: 10px;

  .fa-lock-alt {
    width: 15px;
    height: 15px;
    color: gray;
  }
`

export const StyledIcon = styled.div`
  cursor: pointer;
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 15px;
  align-self: center;
`

export const RecordCount = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.color || Colors.Purple50};
`

export const DownButton = styled.div`
  font-size: 14px;
  color: ${Colors.Black760};
  cursor: pointer;
  margin-left: 0;
  padding-left: 0.5em;

  & i.icon {
    width: auto;
    margin: 0;
  }
`

export const Pipe = styled.span`
  display: block;
  margin: 0 0.25em;
  height: 100%;
  width: 2px;
  background: ${Colors.EmphasisLine};
`

export const PopupWrapper = styled(Popup)`
  height: auto;
  width: 30%;
  margin-left: -30px !important;

  &.ui.bottom.left.popup:before {
    background: ${Colors.White470} !important;
    font-size: 25px;
  }

  &.ui.popup {
    max-width: 350px;
    background: ${Colors.White470};
    border-radius: 20px;
  }
`

export const EditBoardName = styled.div`
  margin-top: -5px;

  i.icon {
    font-size: 20px;
    margin-left: 10px;
  }
`

export const StyledInput = styled(Input)`
  height: 30px;
  width: ${(props: Props) => props.width || props.width}px;
`

export const StyledPopup = styled(Popup)`
  &.ui.top.left.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    margin-left: -8px;
  }
`

export const CounterItems = styled.span`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 2px;
`

export const CounterIcon = styled(Icon)`
  line-height: 1;
  color: ${Colors.ButtonDanger};
  &.icon {
    font-size: 1.1em;
  }
`

export const Count = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 1.5em;
  height: 1.5em;
  padding: 0 0.4em;
  left: -0.6em;
  font-size: 0.7em;
  line-height: 1;
  font-weight: normal;
  color: ${Colors.White1000};
  background: ${Colors.ButtonDanger};
  border-radius: 0.75em;
`
