// Import Packages
import * as React from 'react'
import ConfirmAlert from 'sweetalert2'

// Import Utils
import { generateID, Strings } from 'utils'

// Import Styled Components
import {
  ActionsWrapper,
  DocumentWrapper,
  ImageWrapper,
  StyledPopup,
  StyledResponsive,
  StyledTruncate,
  Title,
  TruncateWrapper,
} from './Styled'

// Font Awesome Icons
import {
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { createDocTemplate, deleteDocTemplate } from '../../../ChecklistManagerMutation'
AddIconToLibrary([faTrashAlt, faPencilAlt])

interface Props {
  addDocument: (data: any) => void
  editDocument: (result: string, index: number) => void
  handleEditClick: (toggle: boolean, name: string, index: number, id: string) => void
  index: number
  item: {
    _id: string,
    name: string,
  }
  mlsId: string
  removeDocument: (index: number) => void
}

class Document extends React.Component<Props, {}> {
  public render = () => {
    const { item } = this.props
    const { name, _id } = item
    return (
      <StyledResponsive
        as={DocumentWrapper}
        ishidden={name === Strings.settings.addString ? 1 : 0}
        islast={name === '' ? 1 : 0}
      >
        <ActionsWrapper ishidden={(name === Strings.settings.addString || name === '') ? 1 : 0}>
          <ImageWrapper onClick={this.props.handleEditClick.bind({}, true, name, this.props.index, _id)}>
            <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
          </ImageWrapper>
          <ImageWrapper onClick={this.handleRemoveDocument}>
            <FontAwesomeIcon icon={['fal', 'trash-alt']} />
          </ImageWrapper>
        </ActionsWrapper>
        <StyledResponsive
          as={Title}
          isadd={name === Strings.settings.addString ? 1 : 0}
          onClick={this.handleAddDocument}
        >
          <StyledPopup
            content={name}
            position={'bottom left'}
            size={'mini'}
            trigger={(
              <TruncateWrapper>
                <StyledTruncate
                  lines={2}
                  ellipsis={'...'}
                  trimWhitespace={true}
                >
                  {name}
                </StyledTruncate>
              </TruncateWrapper>
            )}
          />
        </StyledResponsive>
      </StyledResponsive>
    )
  }

  private handleAddDocument = async () => {
    const { item, mlsId } = this.props
    if (item.name === Strings.settings.addString) {
      const name = `New Document ` + generateID()
      const docTemplate = await createDocTemplate({
        mls: mlsId,
        name,
      })
      this.props.addDocument(docTemplate)
    }
  }

  private handleRemoveDocument = async () => {
    const { deleteTemplate } = Strings.checklistManager.alert
    const confirm = await ConfirmAlert({
      cancelButtonText: deleteTemplate.cancel,
      confirmButtonText: deleteTemplate.confirm,
      showCancelButton: true,
      title: deleteTemplate.title,
      type: 'warning',
    })
    if (!confirm.value) {
      return
    }

    const { item, removeDocument, index } = this.props
    deleteDocTemplate(item._id)
    removeDocument(index)
  }

}

export default Document
