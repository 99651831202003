import moment from 'moment'
import * as React from 'react'

import { getPreviousInvoices } from 'shared/Billing/Queries'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'

import { Strings } from 'utils'

import {
  Aside,
  Col,
  Container,
  Content,
  Footer,
  Header,
  Image,
  Line,
  Link,
  Pagination,
  ScrollY,
  Section,
  Status,
  Table
} from './Styled'

import { InvoiceListType } from 'shared/Billing/Types'

interface Props {
  onClose: () => void
}

interface State {
  batch: number
  invoices: InvoiceListType[]
  loading: string
  transition: boolean
}

const limit = 20

class BillingHistoryModal extends React.Component<Props, State> {
  public state = {
    batch: limit,
    invoices: [] as InvoiceListType[],
    loading: '',
    transition: true
  }

  public componentDidMount = () => {
    this.handlePagination()
  }

  public render() {
    const { batch, invoices, loading, transition } = this.state

    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loading} />}
            <Aside>
              <Image src={Strings.settings.profile.src} />
            </Aside>
            <Content padding={1}>
              <Header>
                <Col width={20}>Receipt No.</Col>
                <Col width={30}>Billing Cycle</Col>
                <Col width={20} className="center">
                  Payment Status
                </Col>
                <Col width={15} className="center">
                  Amount
                </Col>
                <Col width={15} className="center">
                  Invoice
                </Col>
              </Header>
              <Section>
                <ScrollY>
                  <Table>
                    {invoices.map((item: InvoiceListType, index: number) => (
                      <Line key={index}>
                        <Col width={20}>{item.receipt_number}</Col>
                        <Col width={30}>
                          {moment(item.startDate).format('ll')} - {moment(item.endDate).format('ll')}
                        </Col>
                        <Status status={item.status} className="center" width={20}>
                          {item.status}
                        </Status>
                        <Col width={15} className="center">
                          ${(item.amount_due / 100).toFixed(2)}
                        </Col>
                        <Col width={15} className="center">
                          <Link href={item.hosted_invoice_url} target="_blank">
                            View
                          </Link>
                        </Col>
                      </Line>
                    ))}
                  </Table>
                </ScrollY>
              </Section>
              <Footer>
                {batch === limit && (
                  <Pagination
                    disabled={!!loading}
                    size="mini"
                    icon="plus"
                    onClick={this.handlePagination}
                    content="show more"
                  />
                )}
              </Footer>
            </Content>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        maximum={true}
      />
    )
  }

  private handlePagination = async () => {
    this.setState({ loading: 'gathering invoices...' })
    const { invoices } = this.state

    let results: any
    if (invoices.length > 0) {
      const last = invoices[invoices.length - 1].id
      results = await getPreviousInvoices({ limit, starting_after: last })
    } else {
      results = await getPreviousInvoices({ limit })
    }

    if (!results) {
      this.setState({ loading: '' })
      return
    }

    const batch = results.length

    this.setState({
      batch,
      invoices: [...invoices, ...results],
      loading: ''
    })
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      onClose()
    }, 300)
  }
}

export default BillingHistoryModal
