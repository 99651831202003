import * as React from 'react'
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input'
import {
  PaymentRadioContainer,
  PaymentTypeRadio
} from './Styled'

const RadioItem = ({
  checked,
  label,
  name,
  value,
  onChange
}:{
  checked: boolean
  label: string
  name: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData ) => void
}) => {
  return <PaymentRadioContainer>
    <PaymentTypeRadio type="radio" name={name} value={value} checked={checked} onChange={onChange} label={label}/>
  </PaymentRadioContainer>
}

export default RadioItem