import client from 'queries/apollo'

import { PUBLIC_GET_TRANSACTION } from 'queries/graphql/Transactions/Queries'

export const getTransaction = async (_id: string) => {
  const response = await client.query({
    query: PUBLIC_GET_TRANSACTION,
    variables: { _id }
  })

  return response.data.publicGetTransaction
}
