// Import Packages
import * as React from 'react'
import { Input, Table } from 'semantic-ui-react'
import { ProfileType, SocialItemsType } from '../../Types'

import { updateProfile } from 'app/Settings/Mutations'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { Container, StyledTable, Title } from './Styled'

// Font Awesome Icons
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faInfoCircle])

interface Props {
  profile: ProfileType
}
interface State {
  social: SocialItemsType
}

class Social extends React.Component<Props, State> {
  public state = {
    social: {
      facebook: '',
      instagram: '',
      linkedin: '',
      twitter: ''
    } as SocialItemsType
  }

  public componentDidMount() {
    const { profile } = this.props
    const { social } = this.state
    if (profile.social) {
      profile.social.forEach((items: any) => {
        if (items.type === 'facebook') {
          social[items.type] = items.url
        } else if (items.type === 'twitter') {
          social[items.type] = items.url
        } else if (items.type === 'instagram') {
          social[items.type] = items.url
        } else if (items.type === 'linkedin') {
          social[items.type] = items.url
        }
      })
      this.setState({ social })
    }
  }

  public render() {
    const { social } = this.state
    return (
      <Container>
        <Title>{Strings.settings.profile.social}</Title>
        <StyledTable basic={true}>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </Table.Cell>
              <Table.Cell>
                <Input
                  name="facebook"
                  value={social['facebook']}
                  placeholder="www.facebook.com"
                  onChange={this.handleChange}
                  onKeyDown={this.onKeyDown}
                  onBlur={this.onBlur}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </Table.Cell>
              <Table.Cell>
                <Input
                  name="twitter"
                  value={social['twitter']}
                  placeholder="www.twitter.com"
                  onChange={this.handleChange}
                  onKeyDown={this.onKeyDown}
                  onBlur={this.onBlur}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </Table.Cell>
              <Table.Cell>
                <Input
                  name="instagram"
                  value={social['instagram']}
                  placeholder="www.instagram.com"
                  onChange={this.handleChange}
                  onKeyDown={this.onKeyDown}
                  onBlur={this.onBlur}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
              </Table.Cell>
              <Table.Cell>
                <Input
                  name="linkedin"
                  value={social['linkedin']}
                  placeholder="www.likedin.com"
                  onChange={this.handleChange}
                  onKeyDown={this.onKeyDown}
                  onBlur={this.onBlur}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </StyledTable>
      </Container>
    )
  }

  private handleChange = async (e: any, { name, value }: any) => {
    const { social } = this.state
    if (name === 'facebook') {
      social[`facebook`] = value
    } else if (name === 'twitter') {
      social[`twitter`] = value
    } else if (name === 'instagram') {
      social[`instagram`] = value
    } else if (name === 'linkedin') {
      social[`linkedin`] = value
    }
    this.setState({ social })
  }

  private onBlur = () => {
    this.updateSocial()
  }

  private onKeyDown = (e: any) => {
    this.updateSocial()
  }

  private updateSocial = async () => {
    const { social } = this.state
    const socialData = [
      { type: 'facebook', url: social['facebook'] },
      { type: 'twitter', url: social['twitter'] },
      { type: 'instagram', url: social['instagram'] },
      { type: 'linkedin', url: social['linkedin'] }
    ]
    const update: any = {
      social: socialData
    }
    await updateProfile(update)
  }
}

export default Social
