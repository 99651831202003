import { PaymentNumericEnum } from 'store/Transactions/Types'

/** returns a value that complies with the currency setting. Defaults on fixed
 * sourceOfTruth: string
 * the actual currency setting
 * 
 * fixed?: string
 * value to be returned in case of fixed value setting
 * 
 * percent?: string
 * value to be returned in case of percent value setting
 * 
 */

const fixedOrPercent = (sourceOfTruth: string, fixed?: string, percent?: string): string | undefined => {
    return PaymentNumericEnum.Percent === sourceOfTruth ? percent : fixed
}

export default fixedOrPercent