import * as React from 'react'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'

import Modal from 'shared/Modal'
import { getWorkflowCategory } from '../../WorkflowManagerQueries'

import { handleValidation } from './Validation'

import { Strings } from 'utils'

import {
  AddContactLeftPanel,
  AddForm,
  Container,
  StyledForm,
} from './Styled'

import {
  FormErrorType,
  FormType,
} from './Types'

interface Props {
  closeModal: () => void
  editCategoryWorkflow: (data: any) => void
  addCategoryWorkflow: (data: any) => void
  loggedUser: any
  editCategoriesData: any
}
interface Options {
  key: number
  text: string
  value: string
}

interface State {
  form: FormType
  animate: boolean
  categoryOptions: Options[]
  visibilityOptions: Options[]
  selectedWorkflowCategory: any
}

class AddWorkflowModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    categoryOptions: [],
    form: {
      errors: {} as FormErrorType,
    } as FormType,
    selectedWorkflowCategory: {
      key: '',
      text: '',
      value: ''
    },
    visibilityOptions: [],
  }

  public async componentDidMount() {
    const { loggedUser, editCategoriesData } = this.props
    const workflowCategory = await getWorkflowCategory()
    if (workflowCategory) {
      const categoryOptions: Options[] = []
      workflowCategory.forEach((element: any) => {
        const obj = {
          key: element._id,
          text: element.name,
          value: `${element._id}, ${element.name}`
        }
        categoryOptions.push(obj)
      })
      this.setState({ categoryOptions })
    }
    let visibilityOptions: Options[] = []
    switch (loggedUser.role) {
      case 'ADMIN':
        visibilityOptions = [
          { key: 1, value: 'Office', text: 'Office' },
          { key: 2, value: 'Team', text: 'Team' },
          { key: 3, value: 'Individual', text: 'Individual' }
        ]
        break
      case 'MANAGER':
        visibilityOptions = [
          { key: 1, value: 'Office', text: 'Office' },
          { key: 2, value: 'Team', text: 'Team' },
          { key: 3, value: 'Individual', text: 'Individual' }
        ]
        break
      case 'TEAM_LEADER':
        visibilityOptions = [
          { key: 1, value: 'Team', text: 'Team' },
          { key: 2, value: 'Individual', text: 'Individual' }
        ]
        break
      case 'AGENT':
        visibilityOptions = [
          { key: 1, value: 'Individual', text: 'Individual' }
        ]
        break
      default:
        visibilityOptions = [
          { key: 1, value: 'Office', text: 'Office' },
          { key: 2, value: 'Team', text: 'Team' },
          { key: 3, value: 'Individual', text: 'Individual' }
        ]
        break
    }
    this.setState({ visibilityOptions })

    if (editCategoriesData && Object.keys(editCategoriesData).length > 0) {
      const workflowCategorys = await getWorkflowCategory()
      const defaultCategory: any = workflowCategorys.find((element: any) => {
        return element._id === editCategoriesData.categoryId
      })
      this.setState({
        form: {
          errors: {} as FormErrorType,
          id: editCategoriesData._id,
          name: editCategoriesData.name,
          visibility: editCategoriesData.visibility,
          workflowCategory: editCategoriesData.categoryId
        },
        selectedWorkflowCategory: {
          key: defaultCategory._id,
          text: defaultCategory.name,
          value: `${defaultCategory._id}, ${defaultCategory.name}`
        },
      })
    }
  }

  public render() {
    const { editCategoriesData } = this.props
    const { form, form: { errors }, animate, categoryOptions, visibilityOptions, selectedWorkflowCategory } = this.state
    return (
      <Modal content={
        <Container>
          <AddContactLeftPanel>
            <Image src={Strings.tasksSetting.src} size='small' />
          </AddContactLeftPanel>
          <AddForm>
            <StyledForm size={'mini'}>
              <Form.Field
                control={Input}
                label='Workflow Name'
                name='name'
                value={form.name}
                error={!!errors.name}
                placeholder='Workflow Name'
                onChange={this.handleChange} />
              <Form.Field
                control={Select}
                label='Workflow Category'
                name='workflowCategory'
                value={selectedWorkflowCategory[`value`]}
                placeholder='Workflow Category'
                options={categoryOptions}
                onChange={this.handleChange}
                error={!!errors.workflowCategory} />
              <Form.Field
                control={Select}
                label='Visibility'
                name='visibility'
                value={form.visibility}
                error={!!errors.visibility}
                placeholder='Visibility'
                options={visibilityOptions}
                onChange={this.handleChange} />
              {
                editCategoriesData && Object.keys(editCategoriesData).length > 0 ?
                  <Button
                    onClick={this.updateWorkflow}
                    content={'UPDATE'} />
                  : <Button
                    onClick={this.workflowSubmit}
                    content={'SAVE'} />
              }
            </StyledForm>
          </AddForm>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={650} />
    )
  }

  private workflowSubmit = () => {
    const { addCategoryWorkflow } = this.props
    const { form } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    addCategoryWorkflow(form)
    this.closeModal()
  }

  private updateWorkflow = () => {
    const { editCategoryWorkflow } = this.props
    const { form } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    editCategoryWorkflow(form)
    this.closeModal()
  }

  private handleChange = (e: any, { name, value }: any) => {
    const { form } = this.state
    if (name === 'workflowCategory') {
      const res = value.split(', ')
      this.setState({
        selectedWorkflowCategory: {
          key: res[0],
          text: res[1],
          value: `${res[0]}, ${res[1]}`
        },
      })
      form[name] = res[0]
    } else if (name === 'visibility') {
      if (value === 'Individual') {
        form[name] = value
        form[`createdBy`] = this.props.loggedUser[`_id`]
      } else {
        form[name] = value
      }
    } else {
      form[name] = value
    }
    this.setState({ form })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default AddWorkflowModal
