// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Loader, Menu } from 'semantic-ui-react'

// // Import Components
import * as DashboardActions from 'store/Dashboard/Actions'

// Import Utils
import { Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Utils
import { getLoggedInUser, Routes } from 'utils'

// Import Styled Components
import {
  Active,
  CloseWrapper,
  Container,
  ContainerWrapper,
  Divider,
  Due,
  Filters,
  FormContainer,
  Icon,
  Infograph,
  Labels,
  Manager,
  Map,
  MLS,
  Name,
  Phone,
  PopUpContent,
  RelatedTo,
  StyledContainer,
  StyledForm,
  StyledMenu,
  Type,
  Wrapper
} from './Styled'

// Font Awesome Icons
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import {
  faAddressBook,
  faBuilding,
  faMapMarkedAlt,
  faMoneyBillWave,
  faPhone,
  faTasks,
  faUser,
  faUserFriends,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faAddressBook,
  faPhone,
  faEnvelope,
  faMapMarkedAlt,
  faUserFriends,
  faBuilding,
  faUser,
  faTasks,
  faMoneyBillWave,
  faUsers
])

const filters = [
  { id: 'All', name: 'All' },
  { id: 'Contacts', name: 'Contacts' },
  { id: 'Leads', name: 'Leads' },
  { id: 'Offices', name: 'Offices' },
  { id: 'Teams', name: 'Teams' },
  { id: 'Transactions', name: 'Transactions' },
  { id: 'Users', name: 'Users' },
  { id: 'Tasks', name: 'Tasks' }
]

interface UserType {
  _id: string
  role: string
  profileImage: string
}

interface StoreProps {
  setShowOfficeDetail: (id: string) => void
  setShowTeamDetail: (id: string) => void
  setShowTaskDetail: (id: string) => void
}

interface OwnProps {
  activeModule: (module: string) => void
  closeAdvanceSearchModal: () => void
  data: any
  module: string
  loader: boolean
}

type Props = StoreProps & OwnProps

interface State {
  searchData: object
  user: UserType
}

class DashboardSearchModal extends React.Component<Props, State> {
  public state = {
    searchData: {
      city: '',
      commercialCAPProgress: '',
      name: '',
      officeName: '',
      residentialCAPProgress: '',
      state: '',
      type: '',
      zipCode: ''
    },
    user: {} as UserType
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({ user })
  }

  public render() {
    const { data, module, loader, setShowOfficeDetail, setShowTeamDetail, setShowTaskDetail } = this.props
    const { user } = this.state
    const contactsCount = data.contacts.length
    const leadsCount = data.leads.length
    const officesCount = data.offices.length
    const teamsCount = data.teams.length
    const transactionCount = data.transactions.length
    const usersCount = data.users.length
    const tasksCount = data.tasks.length
    const allCount =
      data.contacts.length +
      data.teams.length +
      data.leads.length +
      data.offices.length +
      data.transactions.length +
      data.users.length +
      data.tasks.length
    const filter = filters.map((items: any, index: number) => {
      let count = 0
      switch (items.name) {
        case 'All':
          count = allCount
          break
        case 'Transactions':
          count = transactionCount
          break
        case 'Leads':
          count = leadsCount
          break
        case 'Contacts':
          count = contactsCount
          break
        case 'Offices':
          count = officesCount
          break
        case 'Teams':
          count = teamsCount
          break
        case 'Users':
          count = usersCount
          break
        case 'Tasks':
          count = tasksCount
          break
      }
      return (
        <Menu.Item
          key={index}
          name={items.name}
          id={items.id}
          active={module === items.name}
          onClick={this.handleItemClick}
        >
          {items.name}
          <Labels style={{ background: Colors.Red810 }} circular={true}>
            {count}
          </Labels>
        </Menu.Item>
      )
    })
    const contact = data.contacts.map((items: any, index: number) => (
      <Link key={index} to={Routes.contacts.root + Routes.contacts.details.path + `/${items._id}?pool=0`}>
        <Wrapper>
          <Icon>
            <div>
              <FontAwesomeIcon icon={['fas', 'address-book']} />
            </div>
            <div>{filters[1].name}</div>
          </Icon>
          <ContainerWrapper>
            <Name style={{ color: Colors.Black500 }}>{`${items.firstName} ${items.lastName}`}</Name>
            <Phone>
              <div>
                <FontAwesomeIcon icon={['fas', 'phone']} /> {items.phone}
              </div>
              <div>
                <FontAwesomeIcon icon={['far', 'envelope']} /> {items.email}
              </div>
            </Phone>
          </ContainerWrapper>
        </Wrapper>
        <Divider />
      </Link>
    ))
    const lead = data.leads.map((items: any, index: number) => (
      <Link
        key={index}
        to={
          items.tenantBoard !== undefined && items.tenantBoard !== null
            ? user.role === 'ADMIN' || user.role === 'MANAGER'
              ? Routes.leads.root + Routes.leads.details.path + `/${items._id}?pool=1`
              : ''
            : Routes.leads.root + Routes.leads.details.path + `/${items._id}?pool=0`
        }
      >
        <Wrapper>
          <Icon>
            <div>
              <FontAwesomeIcon icon={['fas', 'user-friends']} />
            </div>
            <div>{filters[2].name}</div>
          </Icon>
          <ContainerWrapper>
            <Name style={{ color: Colors.Black500 }}>{`${items.firstName} ${items.lastName}`}</Name>
            <Phone>
              <div>
                <FontAwesomeIcon icon={['fas', 'phone']} /> {items.phone}
              </div>
              <div>
                <FontAwesomeIcon icon={['far', 'envelope']} /> {items.email}
              </div>
            </Phone>
            <Type>
              {Strings.dashboard.type} {items.type}
            </Type>
          </ContainerWrapper>
        </Wrapper>
        <Divider />
      </Link>
    ))
    const office = data.offices.map((items: any, index: number) => (
      <Link to={Routes.primary.offices.path} key={index} onClick={() => setShowOfficeDetail(items._id)}>
        <div key={index}>
          <Wrapper>
            <Icon>
              <div>
                <FontAwesomeIcon icon={['fas', 'building']} />
              </div>
              <div>{filters[3].name}</div>
            </Icon>
            <ContainerWrapper>
              <Name style={{ color: Colors.Black500 }}>{`Branch Name : ${items.branchName}`}</Name>
              {/* <Manager>
              {items.state} <span style={{ fontStyle: 'italic' }}>{Strings.generalText.manager}</span>
            </Manager> */}
              <Map>
                <FontAwesomeIcon icon={['fas', 'map-marker-alt']} /> {`${items.city}, ${items.state}`}
              </Map>
            </ContainerWrapper>
          </Wrapper>
          <Divider />
        </div>
      </Link>
    ))
    const teams = data.teams.map((items: any, index: number) => (
      <Link to={Routes.primary.teams.path} key={index} onClick={() => setShowTeamDetail(items._id)}>
        <div key={index}>
          <Wrapper>
            <Icon>
              <div>
                <FontAwesomeIcon icon={['fas', 'users']} />
              </div>
              <div>{filters[4].name}</div>
            </Icon>
            <ContainerWrapper>
              <Name style={{ color: Colors.Black500 }}>{`Team Name : ${items.name}`}</Name>
              <Manager>
                {`${items.leader.firstName} ${items.leader.lastName}`}{' '}
                <span style={{ fontStyle: 'italic' }}>{Strings.generalText.leader}</span>
              </Manager>
            </ContainerWrapper>
          </Wrapper>
          <Divider />
        </div>
      </Link>
    ))
    const transaction = data.transactions.map((items: any, index: number) => (
      <Link to={Routes.transactions.root + `/${items._id}` + Routes.transactions.documents.path} key={index}>
        <Wrapper>
          <Icon>
            <div>
              <FontAwesomeIcon icon={['fas', 'building']} />
            </div>
            <div>{filters[5].name}</div>
          </Icon>
          <ContainerWrapper>
            <Name style={{ color: Colors.Black500 }}>{`Owner : ${items.owner.firstName} ${items.owner.lastName}`}</Name>
            <MLS>
              {Strings.dashboard.MLS} {items.propertyId.mlsId ? items.propertyId.mlsId : 'Not Provided'}
            </MLS>
            <Map>
              <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />{' '}
              {`${items.propertyId.address.city}, ${items.propertyId.address.state}`}
            </Map>
          </ContainerWrapper>
        </Wrapper>
        <Divider />
      </Link>
    ))
    const users = data.users.map((items: any, index: number) => (
      <Link to={Routes.primary.profile.path + `/${items._id}`} key={index}>
        <Wrapper>
          <Icon>
            <div>
              <FontAwesomeIcon icon={['fas', 'user']} />
            </div>
            <div>{filters[6].name}</div>
          </Icon>
          <ContainerWrapper>
            <Name style={{ color: Colors.Black500 }}>
              {`${items.firstName} ${items.lastName}`}
              <Active style={{ fontStyle: 'italic', fontSize: '12px', color: Colors.Black550 }}>
                ({items.status})
              </Active>
            </Name>
            <Map>
              <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />{' '}
              {items.addresses !== undefined && items.addresses !== null
                ? `${items.addresses[0].city}, ${items.addresses[0].state}`
                : 'Not Provided'}
            </Map>
          </ContainerWrapper>
        </Wrapper>
        <Divider />
      </Link>
    ))
    const task = data.tasks.map((items: any, index: number) => {
      let backgroundIcon: any
      let backgroundProgress = ''
      switch (items.type) {
        case 'Email':
          backgroundIcon = 'envelope'
          backgroundProgress = Colors.Red25
          break
        case 'Call':
          backgroundIcon = 'phone'
          backgroundProgress = Colors.Blue50
          break
        case 'Meeting':
          backgroundIcon = 'handshake'
          backgroundProgress = Colors.Purple50
          break
        case 'Sms':
          backgroundIcon = 'sms'
          backgroundProgress = Colors.Pink70
          break
        case 'Pass':
          backgroundIcon = 'ticket'
          backgroundProgress = Colors.Orange110
          break
      }
      return (
        <Link to={Routes.primary.tasks.path} key={index} onClick={() => setShowTaskDetail(items._id)}>
          <Wrapper>
            <Icon>
              <div>
                <FontAwesomeIcon icon={['fas', 'tasks']} />
              </div>
              <div>{filters[7].name}</div>
            </Icon>
            <ContainerWrapper>
              <Name>
                <FontAwesomeIcon
                  icon={['far', backgroundIcon]}
                  style={{ color: backgroundProgress, marginRight: '6px' }}
                />
                {items.name}
              </Name>
              <span>
                <RelatedTo>{`Related to : ${
                  items.lead in items ? `${items.lead.firstName} ${items.lead.lastName}` : 'Not Related Yet'
                  }`}</RelatedTo>
                <Due>
                  {Strings.dashboard.due}{' '}
                  <span style={{ fontStyle: 'italic' }}>{moment(items.dueDate).format('ll')}</span>
                </Due>
              </span>
            </ContainerWrapper>
          </Wrapper>
          <Divider />
        </Link>
      )
    })
    let results: any
    switch (module) {
      case 'Transactions':
        results = transaction
        break
      case 'Leads':
        results = lead
        break
      case 'Contacts':
        results = contact
        break
      case 'Offices':
        results = office
        break
      case 'Teams':
        results = teams
        break
      case 'Users':
        results = users
        break
      case 'Tasks':
        results = task
        break
    }
    return (
      <StyledContainer>
        <CloseWrapper onClick={this.closeAdvanceSearchModal} />
        <PopUpContent>
          <Container>
            <Filters>
              <StyledMenu vertical={true}>{filter}</StyledMenu>
            </Filters>
            <StyledForm size={'tiny'}>
              {loader ? (
                <FormContainer>
                  <Loader active={true} size="large" />
                </FormContainer>
              ) : module !== 'All' ? (
                <FormContainer>
                  {results.length === 0 ? <Infograph backgroundImage={Strings.generalText.fetchData} /> : results}
                </FormContainer>
              ) : allCount === 0 ? (
                <Infograph backgroundImage={Strings.generalText.fetchData} />
              ) : (
                      <FormContainer>
                        {contact}
                        {lead}
                        {office}
                        {teams}
                        {transaction}
                        {users}
                        {task}
                      </FormContainer>
                    )}
            </StyledForm>
          </Container>
        </PopUpContent>
      </StyledContainer>
    )
  }

  private closeAdvanceSearchModal = () => {
    const { closeAdvanceSearchModal } = this.props
    closeAdvanceSearchModal()
  }

  private handleItemClick = (e: any, { id, name }: any) => {
    const { activeModule } = this.props
    activeModule(name)
  }
}
export default connect(
  null,
  {
    setShowOfficeDetail: DashboardActions.showOfficeDetail,
    setShowTeamDetail: DashboardActions.showTeamDetail,
    setShowTaskDetail: DashboardActions.showTaskDetail
  }
)(DashboardSearchModal)
