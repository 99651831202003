import * as React from 'react'

import Template1 from 'templates/Listing/Template1'

import { TransactionType } from 'app/Transactions/Listing/Types'

interface Props {
  transaction: TransactionType
}

class Template2 extends React.Component<Props, {}> {
  public render = () => {
    const { transaction } = this.props
    return (
      <Template1 transaction={transaction} colorMode='dark' />
    )
  }
}

export default Template2
