// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'

// Import Store Types, Actions and Reducers
import * as DashboardActions from 'store/Dashboard/Actions'
import { Address } from 'store/Dashboard/Types'

// Import Styled Components
import { FlexContainer, Header, Image, ImageIcon, Line, SubHeader, TransactionContainer } from './Styled'

// Font Awesome Icons
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronRight])

interface Props {
  id: string
  transactionType: string
  mslID: string
  address: Address
  name: string
  image: string
  activeId: string
  transaction: any
  toggleDetailsPopup: ({ open, id }: { open: boolean; id: string }) => void
  removeFromList: (id: string) => void
}

// const PlaceholderTransactionType = 'B'

const Transaction = ({ address, name, id, image, mslID, toggleDetailsPopup, removeFromList, transactionType }: Props) => (
  <TransactionContainer
    onClick={() => {
      toggleDetailsPopup({ open: true, id })
      removeFromList(id)
    }}
  >
    <FlexContainer spacing="space-between">
      <FlexContainer spacing="flex-start">
        <Image src={image} />
        <ImageIcon>{transactionType.charAt(0)}</ImageIcon>
        <div style={{ lineHeight: '1.4' }}>
          <Header>{name}</Header>
          <SubHeader>{`MLS ID: ${mslID ? mslID : 'Not Provided'}`}</SubHeader>
          <SubHeader>{`${address.streetNumber} ${address.streetName}, ${address.city}, ${address.state}`}</SubHeader>
        </div>
      </FlexContainer>
      <FontAwesomeIcon icon={['fal', 'chevron-right']} />
    </FlexContainer>
    <Line />
  </TransactionContainer>
)

export default connect(
  null,
  { toggleDetailsPopup: DashboardActions.toggleDetailsPopup }
)(Transaction)
