import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Colors from 'design/Colors'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

const StyledIcon = styled(FontAwesomeIcon)`
    font-size: 17px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 17px !important;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 14px;
    }
    @media ( min-width: 1200px ) {
      font-size: 17px;
    }
`

const StyledSegment = styled(Segment)`
  &.ui.segment {
    background-color: ${Colors.transparent};
    border: none;
    box-shadow: none;
    display: flex;
    padding: 0px;
    margin: 0.5rem 0;
  }
`

const TextBox = styled(Input)`
  &.ui.input {
    width: 100%
    background-color: transparent;
    padding: 5px;
    color: ${Colors.DarkBlue200};
  }

  &.ui.fluid.input > input {
    border-color: ${Colors.Grey600};
    padding: inherit;
    border-style: solid;
    background-color: transparent;
    padding-left: 10px;
    background-color: ${Colors.White1000};
    border: none;
    color: ${Colors.DarkBlue200};
    :focus{
      border-color: ${Colors.Grey600};
    }

    ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export {
  StyledIcon,
  StyledSegment,
  TextBox
}
