// Import Packages
import * as React from 'react'
import { Menu } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import Notes from './Notes'

// Import Store Types and Actions
import { Owner } from 'store/Contacts/Types'
import { LeadNotes } from 'store/Leads/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  RightPanel,
  StyledTab,
} from './Styled'

interface UserType {
  _id: string
  role: string
  profileImage: string
}

interface Props {
  closeModal: () => void
  addNewNotes: (note: string) => void
  editNotes: (id: string, note: string) => void
  deleteNotes: (id: string) => void
  notesData: LeadNotes[]
  contactOwner: Owner
  loader: boolean
  user: UserType
}

interface State {
  animate: boolean
}

class ViewDetailsModal extends React.Component<Props, State> {
  public state = {
    animate: true
  }

  public render() {
    const { notesData, contactOwner, loader, user } = this.props
    const { animate } = this.state
    const panes = [
      {
        menuItem: (<Menu.Item key='Notes'>{Strings.leads.notes}</Menu.Item>),
        render: () => <Notes user={user} loader={loader} addNewNotes={this.addNewNotes} notesData={notesData} contactOwner={contactOwner} editNotes={this.editNotes} deleteNotes={this.deleteNotes} />
      },
    ]
    return (
      <Modal content={
        <Container>
          <RightPanel>
            <StyledTab menu={{ secondary: true, pointing: true }} panes={panes} />
          </RightPanel>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={630} />
    )
  }

  private deleteNotes = (id: string) => {
    const { deleteNotes } = this.props
    deleteNotes(id)
  }

  private editNotes = (id: string, note: string) => {
    const { editNotes } = this.props
    editNotes(id, note)
  }

  private addNewNotes = (note: string) => {
    const { addNewNotes } = this.props
    addNewNotes(note)
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default ViewDetailsModal
