import gql from 'graphql-tag'

export const GET_LEADS = gql`
  query getLeads(
    $userId: String
    $filter: LeadSearch
    $userPassFilter: UserPassLeadFilter
  ) {
    getLeads(
      where: { isActive: true, userId: $userId }
      filter: $filter
      userPassFilter: $userPassFilter
    ) {
      _id
      firstName
      lastName
      email
      phone
      propertyType
      createdAt
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      activities {
        _id
      }
      owner {
        _id
        firstName
        lastName
      }
      reassignHistory {
        userId {
          _id
          firstName
          lastName
          emails {
            value
          }
          phones {
            value
          }
        }
        date
      }
      leadScore
      createdAt
      lastAction
      referralFee
      zipCode
      tag
      isActive
      source
      status
      type
      checked @client
    }
  }
`

export const GET_LEADS_ON_STATE = gql`
  query getLeadsOnState(
    $state: String
    $isActive: Boolean
    $userId: String
    $filter: LeadSearch
  ) {
    getLeads(
      where: { state: $state, isActive: $isActive, userId: $userId }
      filter: $filter
    ) {
      _id
      firstName
      lastName
      email
      phone
      propertyType
      createdAt
      city {
        _id
        name
      }
      activities {
        _id
      }
      state {
        _id
        name
      }
      owner {
        _id
        firstName
        lastName
      }
      reassignHistory {
        userId {
          _id
          firstName
          lastName
          emails {
            value
          }
          phones {
            value
          }
        }
        date
      }
      leadScore
      createdAt
      lastAction
      referralFee
      zipCode
      tag
      isActive
      source
      status
      type
      checked @client
    }
  }
`

export const EXPORT_LEADS = gql`
  query exportLeads($board: String, $stateBoard: String, $tenantBoard: String) {
    exportLeads(
      where: {
        board: $board
        stateBoard: $stateBoard
        tenantBoard: $tenantBoard
      }
    )
  }
`

export const GET_LEAD_DETAILS = gql`
  query getLeads($leadId: String) {
    getLeads(where: { _id: $leadId, isActive: true }) {
      _id
      firstName
      lastName
      email
      phone
      propertyType
      createdAt
      city {
        _id
        name
      }
      activities {
        _id
      }
      state {
        _id
        name
      }
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      owner {
        _id
        firstName
        lastName
      }
      reassignHistory {
        userId {
          _id
          firstName
          lastName
          emails {
            value
          }
          userName
          phones {
            value
          }
        }
        date
      }
      notes {
        description
      }
      leadScore
      createdAt
      lastAction
      referralFee
      zipCode
      tag
      isActive
      source
      status
      type
    }
  }
`

export const GET_LEAD_NOTE = gql`
  query getLeadNotes($leadId: String) {
    getNotes(where: { lead: $leadId }) {
      _id
      description
      lead {
        firstName
        lastName
      }
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`
