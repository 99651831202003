// Import Components
import client from 'queries/apollo'

import { GET_DOC_TEMPLATES } from 'queries/graphql/Settings/ChecklistManager/DocTemplate/Queries'

export const getDocTemplates = async (where: any) => {
  where = {
    ...where,
    isActive: true
  }
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_DOC_TEMPLATES,
    variables: { where }
  })

  return response.data.getDocTemplate
}
