// Import Packages
import { Accordion, Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const Container = styled.div`
  margin-top: 10px;
  height: 550px;
  overflow: auto;
  ${scrollbars};

  .ui.button {
    font-size: 1rem;
    margin-top: 15px;
    border-radius: 0px;
    border-radius: 0px;
    width: 100px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    margin-left: 60px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .custom-progress {
    background: white;
  }
`

const StyledForm = styled(Form)`
  width: 88%;
  padding-left: 60px;
  font-size: 12px !important;

  .mr .ui.selection.dropdown {
    min-width: 20%;
    width: 100% !important;
  }

  .mr {
    width: 28% !important;
  }

  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  &.ui.form .field>.selection.dropdown {
    width: 67%;
    min-width: 100%;
  }

  &.ui.form .field.pets>.selection.dropdown {
    width: 35%;
  }

  &.ui.form .field.source {
    width: 66%;
  }

  &.ui.form .field.birthdate .ui.input{
    width: 35%;
  }

  &.ui.form .field.name .ui.input input {
    width: 10% !important;
  }

  .ui.action.input:not([class*="left action"])>.button:last-child,
  .ui.action.input:not([class*="left action"])>.buttons:last-child>.button,
  .ui.action.input:not([class*="left action"])>.dropdown:last-child {
    background: transparent;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-left: 0;
    width: 40px;
  }

  &.ui.form .field.pets>.selection.dropdown {
    width: 180px !important;
    min-width: 0px;
  }

  .birthdate {
    width: 405px;
  }

  .ui.action.input>.button, .ui.action.input>.buttons>.button{
    padding-top: 7px;
  }

  .ui.icon.button>.icon, .ui.icon.buttons .button>.icon {
    color: ${Colors.Black400};
  }

  .ui.icon.button>.icon, .ui.icon.buttons .button>.icon {
    height: 9px;
  }

`

const StyledAccordion = styled(Accordion)`
  &.ui.accordion {
    margin-top: 30px;
  }

  &.ui.accordion .title:not(.ui) {
    border-top: 1px solid ${Colors.White480};
    border-bottom: 1px solid ${Colors.White480};
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 55px;
    box-shadow: 0px 2px 5px 0px ${Colors.White480};
  }

  &.ui.accordion .title:not(.ui)>span {
    color: ${Colors.Black500};
  }

  &.ui.accordion .title:not(.ui)>span>i {
    font-size: 12px;
    color: ${Colors.DarkBlue200};
    font-weight: 600;
    margin-left: 5px;
  }
`



export {
  Container,
  StyledAccordion,
  StyledForm
}
