import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { y } from 'design/Styled'

export const Container = styled.div`
  ${y};
  position: relative;
  height: 100%;
  overflow: hidden;

  .block {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
  }
`

export const FlexWrapper = styled(Grid)`
  &.ui.grid {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
`
