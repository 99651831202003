// Import Packages
import * as React from 'react'
import { VictoryPie } from 'victory'

// Import Images and Icons
import IconUserEmail from 'design/icons/userProfile/teams/user_Email.png'
import IconUserLocation from 'design/icons/userProfile/teams/user_location.png'
import IconUserPhone from 'design/icons/userProfile/teams/user_Phone.png'

import { AddressType } from './Types'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  CardContent,
  CardHeader,
  CardImage,
  CardMeta,
  IconContainer,
  ImageWrapper,
  ProfileProgressContainer,
  StyledCard,
} from './Styled'

interface Props {
  dataItem: any
}

export default class StyledCardComponent extends React.Component<Props> {

  public getDataResidential = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public getDataCommercial = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public render() {
    const { dataItem } = this.props
    const { userName, firstName, lastName, profileImage, addresses, phones } = dataItem
    const HomeAddress = (addresses && addresses.length > 0) && addresses.find((list: AddressType) => {
      return list.type === 'Home'
    })
    const HomeAddressString = HomeAddress ? `${HomeAddress.streetNumber} ${HomeAddress.streetName}, ${HomeAddress.city}, ${HomeAddress.state} ${HomeAddress.zipCode}` : 'No Home Address Yet'

    return (
      <StyledCard>
        <CardContent>
          <ProfileProgressContainer>
            <div className='ResidentialProgress'>
              <svg viewBox="0 0 400 400" >
                <VictoryPie
                  standalone={false}
                  animate={{ duration: 5000 }}
                  width={400} height={400}
                  data={this.getDataResidential(30)}
                  innerRadius={175} labelRadius={120}
                  padding={0}
                  startAngle={0}
                  endAngle={360}
                  labels={['', '']}
                  colorScale={[Colors.Orange100, Colors.White900]}
                />
                <VictoryPie
                  standalone={false}
                  width={400} height={400}
                  data={this.getDataCommercial(50)}
                  innerRadius={150} labelRadius={50}
                  padding={25}
                  labels={['', '']}
                  startAngle={0}
                  endAngle={180}
                  colorScale={[Colors.Green500, Colors.White900]}
                />
              </svg>
              <CardImage floated='right' circular={true} size='tiny' src={profileImage} />
            </div>
          </ProfileProgressContainer>
          <CardHeader>{firstName} {lastName}</CardHeader>
          <CardMeta>
            <IconContainer style={{
              alignSelf: 'start',
              marginTop: '3px'
            }}>
              <ImageWrapper className="small" backgroundImage={IconUserLocation} />
            </IconContainer>{HomeAddressString}</CardMeta>
          <CardMeta>
            <IconContainer>
              <ImageWrapper className="small" backgroundImage={IconUserEmail} />
            </IconContainer>{userName}</CardMeta>
          <CardMeta>
            <IconContainer>
              <ImageWrapper className="small" backgroundImage={IconUserPhone} />
            </IconContainer>{(phones && phones[0]) ? phones[0].value : 'No Phone Number Added'}</CardMeta>
        </CardContent>
        {/* <CardFooter extra={true} textAlign="right">
          <StyledButton icon={true} >
            <ImageWrapper backgroundImage={IconUser} />
            {
              users.count > 0 ?
                <UpdatesCount>{users.count}</UpdatesCount>
                :
                null
            }
          </StyledButton>
          <StyledButton icon={true} >
            <ImageWrapper backgroundImage={IconFolder} />
            {
              data.count > 0 ?
                <UpdatesCount>{data.count}</UpdatesCount>
                :
                null
            }
          </StyledButton>
          <StyledButton icon={true} >
            <ImageWrapper backgroundImage={IconContactBook} />
            {
              contacts.count > 0 ?
                <UpdatesCount>{contacts.count}</UpdatesCount>
                :
                null
            }
          </StyledButton>
        </CardFooter> */}
      </StyledCard>
    )
  }
}
