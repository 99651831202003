// Import Packages
import * as React from 'react'
import { Button, Form, Input } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import { handleValidation } from './Validation'

// Import Utils
import { Strings } from 'utils'
import { DataItem, FormErrorType, FormType } from './Types'

// Import Styled Components
import {
  Header,
  StyledForm
} from './Styled'

interface Props {
  handleClose: (toggle: boolean) => void
  addLink: (form: FormType) => void
  updateLink: (id: string, form: FormType) => void
  editLinkData: DataItem
  edit: boolean
}

interface State {
  form: FormType
  animate: boolean
}

export default class AddLinkModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    form: {
      errors: {} as FormErrorType,
    } as FormType,
  }

  public componentDidMount() {
    const { editLinkData, edit } = this.props
    if (edit) {
      const form = {
        ...editLinkData,
        errors: {} as FormErrorType,
      }
      this.setState({ form })
    }
  }

  public saveLink = () => {
    const { addLink } = this.props
    const { form } = this.state
    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    addLink(form)
    this.closeModal()
  }

  public updateLink = () => {
    const { updateLink, editLinkData } = this.props
    const { form } = this.state
    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    updateLink(editLinkData._id, form)
    this.closeModal()
  }

  public handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  public render() {
    const { edit } = this.props
    const { animate, form, form: { errors } } = this.state
    return (
      <Modal content={
        <div>
          <Header>
            {Strings.generalText.addLink}
          </Header>
          <StyledForm size={'mini'}>
            <Form.Field
              control={Input}
              name="name"
              label='Name'
              placeholder='Name'
              onChange={this.handleChange}
              value={form.name}
              error={!!errors.name} />
            <Form.Field
              control={Input}
              name="url"
              label='Url'
              placeholder='Url'
              value={form.url}
              onChange={this.handleChange}
              error={!!errors.url} />
            {
              edit ? <Form.Field
                control={Button}
                className='save-button'
                onClick={this.updateLink}
                content={'UPDATE'} /> :
                <Form.Field
                  control={Button}
                  className='save-button'
                  onClick={this.saveLink}
                  content={'SAVE'} />
            }
          </StyledForm>
        </div>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={350} />
    )
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose(false)
    }, 300)
  }
}
