// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Dropdown, Icon } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'
import { VictoryPie } from 'victory'

import { LeadItemsDetails } from 'store/Leads/Types'

// Import Components
import {
  AcceptLead,
  editLead,
  loggedUser,
  ShowAddTransactionModal,
  ShowComposeMail,
  ShowUpdateScoreModal,
  SingleCheckBox,
  ToggleCheckBox
} from './Leads'

import { isPlanAllowed } from 'shared/Billing/Queries'
import { serverToast } from 'shared/Toast/Toast'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  AcceptButton,
  Activities,
  CheckboxWrapper,
  Circle,
  DropDownPopup,
  ProfileProgressContainer,
  StyeldSchedule,
  StyledDropdown,
  StyledPopup,
  StyledStatusDropdown,
  Type
} from './Styled'

// Font Awesome Icons
import { faComment, faEnvelope, faPhone, faPhoneVolume, faSms } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { reassignLead } from './LeadMutations'
import { toggle, ToggleLeadStatus } from './Leads'
AddIconToLibrary([faSms, faPhoneVolume, faComment, faPhone, faEnvelope])

const getDataResidential = (percent: number) => {
  return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
}

const options = [
  { key: 1, text: 'New', value: 'New' },
  { key: 2, text: 'In Process', value: 'In Process' },
  { key: 3, text: 'Failed', value: 'Failed' },
  { key: 4, text: 'Closed', value: 'Closed' }
]

let checked = false

export const Checked = (data: boolean) => {
  checked = !data
}

export const CustomHeaderCheckBox = () => {
  const onChange = () => {
    ToggleCheckBox()
  }
  return (
    <CheckboxWrapper>
      <Checkbox checked={checked} onClick={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomCheckBox = ({ rowData }: { rowData: LeadItemsDetails }) => {
  const onChange = () => {
    SingleCheckBox(rowData._id)
  }
  return (
    <CheckboxWrapper>
      <Checkbox checked={rowData.checked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomLeadName = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return (
    <Link
      to={
        toggle
          ? loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER'
            ? Routes.leads.root + Routes.leads.details.path + `/${rowData._id}?pool=1`
            : {}
          : Routes.leads.root + Routes.leads.details.path + `/${rowData._id}?pool=0`
      }
    >
      <span style={{ color: Colors.Black500, textTransform: 'capitalize' }}>
        {rowData.firstName} {rowData.lastName}
      </span>
    </Link>
  )
}

export const CustomScore = ({ rowData }: { rowData: LeadItemsDetails }) => {
  let color = ''
  if (rowData.leadScore >= 0 && rowData.leadScore < 25) {
    color = Colors.DarkBlue205
  } else if (rowData.leadScore >= 25 && rowData.leadScore < 50) {
    color = Colors.Grey950
  } else if (rowData.leadScore >= 50 && rowData.leadScore < 75) {
    color = Colors.Green80
  } else {
    color = Colors.Red10
  }
  return (
    <ProfileProgressContainer onClick={() => ShowUpdateScoreModal(rowData)}>
      <div>
        <svg viewBox="0 0 400 400">
          <VictoryPie
            standalone={false}
            animate={{ duration: 5000 }}
            width={400}
            height={400}
            data={getDataResidential(rowData.leadScore)}
            innerRadius={175}
            labelRadius={120}
            padding={0}
            startAngle={0}
            endAngle={360}
            labels={['R : ', '30%']}
            colorScale={[color, Colors.White900]}
          />
        </svg>
      </div>
      {/* <Circle color={color}><div>{rowData.score}</div></Circle> */}
      <Circle color={color}>
        <div>{rowData.leadScore}</div>
      </Circle>
    </ProfileProgressContainer>
  )
}

export const CustomSchedule = ({ rowData }: { rowData: LeadItemsDetails }) => {
  const sendMail = () => {
    ShowComposeMail(rowData.email)
  }
  return (
    <StyeldSchedule>
      <StyledPopup
        trigger={
          <span
            onClick={e => {
              e.stopPropagation()
              sendMail()
            }}
          >
            <FontAwesomeIcon icon={['fal', 'envelope']} />
          </span>
        }
        content={rowData.email ? rowData.email : 'No Email Added'}
      />
      <StyledPopup
        trigger={<FontAwesomeIcon icon={['fal', 'phone']} />}
        content={rowData.phone ? rowData.phone : 'No Phone Number Added'}
      />
      {/* <FontAwesomeIcon icon={['fal', 'comment']} /> */}
    </StyeldSchedule>
  )
}

export const CustomActivities = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return (
    // <Activities><div>{rowData.activities}</div></Activities>
    <Activities circular={true}>{rowData.activities.length}</Activities>
  )
}

export const CustomActions = ({ rowData }: { rowData: LeadItemsDetails }) => {
  const showAddTransactionModal = () => {
    ShowAddTransactionModal(rowData)
  }
  const editLeadDetails = () => {
    editLead(rowData)
  }
  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              <Link
                to={
                  toggle
                    ? loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER'
                      ? Routes.leads.root + Routes.leads.details.path + `/${rowData._id}?pool=1`
                      : {}
                    : Routes.leads.root + Routes.leads.details.path + `/${rowData._id}?pool=0`
                }
              >
                <Dropdown.Item text={Strings.leads.view} className="view" />
              </Link>
              {rowData.owner._id === loggedUser._id && (
                <Dropdown.Item text={Strings.leads.edit} onClick={editLeadDetails} />
              )}
              <Dropdown.Item text={Strings.leads.addTransaction} onClick={showAddTransactionModal} />
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  )
}

export const CustomType = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return <span>{rowData.type}</span>
}

export const CustomPoolType = ({ rowData }: { rowData: LeadItemsDetails }) => {
  let background = ''
  switch (rowData.type) {
    case 'Buyer':
      background = Colors.Green210
      break
    case 'Seller':
      background = Colors.Red835
      break
    case 'Renter':
      background = Colors.Purple50
      break
    default:
      background = Colors.Orange110
      break
  }
  return <Type background={background}>{rowData.type}</Type>
}

export const CustomLastActionDate = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return <span>{moment(rowData.lastAction).format('MMMM Do YYYY')}</span>
}

export const CustomOwner = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return (
    <span>
      {rowData.owner.firstName} {rowData.owner.lastName}
    </span>
  )
}

export const CustomSource = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return <span>{rowData.source}</span>
}

export const CustomPrice = ({ rowData }: { rowData: LeadItemsDetails }) => {
  let background = ''
  let defaultValue = ''
  switch (rowData.status) {
    case 'New':
      background = Colors.Orange250
      defaultValue = 'New'
      break
    case 'In Process':
      background = Colors.Magenta60
      defaultValue = 'In Process'
      break
    case 'Failed':
      background = Colors.Red200
      defaultValue = 'Failed'
      break
    default:
      background = Colors.Grey850
      defaultValue = 'Closed'
      break
  }

  const onHandleChange = (event: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    ToggleLeadStatus(rowData._id, value)
  }
  return (
    <div>
      <StyledStatusDropdown
        inline={true}
        onChange={onHandleChange}
        options={options}
        value={defaultValue}
        background={background}
      />
    </div>
  )
}

export const CustomCreatedDate = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return <span>{moment(new Date(rowData.createdAt)).format('L')}</span>
}

export const CustomLeadPoolActions = ({ rowData }: { rowData: LeadItemsDetails }) => {
  const acceptLead = async () => {
    try {
      await isPlanAllowed('leadPool')
    } catch (error) {
      return serverToast(error)
    }
    const reassignObj = {
      referralFee: 0,
      userId: loggedUser._id
    }
    ConfirmAlert({
      cancelButtonText: 'I Decline!',
      confirmButtonText: 'I Accept!',
      showCancelButton: true,
      text:
        'By accepting this lead, you agree to uphold the policy and procedures required by this lead exchange system including regular follow up and payment of any referral fees generated as a result.',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        const id = document.getElementById('lead')
        await reassignLead(rowData._id, reassignObj)
        AcceptLead()
        if (id) {
          id.click()
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'This lead declined.', 'error')
      }
    })
  }
  return (
    <>
      <Link
        to={
          toggle
            ? loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER'
              ? Routes.leads.root + Routes.leads.details.path + `/${rowData._id}?pool=1`
              : {}
            : Routes.leads.root + Routes.leads.details.path + `/${rowData._id}?pool=0`
        }
        id="lead"
      />
      <AcceptButton onClick={acceptLead}>Accept</AcceptButton>
    </>
  )
}

export const CustomLocation = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return (
    <div style={{ textTransform: 'capitalize' }}>
      {rowData.state && rowData.city && rowData.zipCode
        ? `${rowData.state.name}, ${rowData.city.name} ${rowData.zipCode}`
        : rowData.state && rowData.city
          ? `${rowData.state.name}, ${rowData.city.name}`
          : rowData.state
            ? `${rowData.state.name}`
            : `No Address`}
    </div>
  )
}

export const CustomReferral = ({ rowData }: { rowData: LeadItemsDetails }) => {
  return <div>{rowData.referralFee ? rowData.referralFee : `0`}</div>
}
