import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import {
  icon,
  styledToolTip,
} from 'design/Styled'

interface Props {
  type?: 'primary' | 'danger' | 'light' | 'dark'
}

const getStyle = ({ type }:Props) => {
  switch (type) {
    case 'dark':
      return css`
        background: ${Colors.TransparentBlack(0.6)};
        span {
          color: ${Colors.Text};
        }
        &:hover {
          background: ${Colors.TransparentBlack(0.8)};
        }
      `

    case 'light':
      return css`
        background: ${Colors.TransparentWhite(0.6)};
        span {
          color: ${Colors.Text};
        }
        &:hover {
          background: ${Colors.TransparentWhite(0.8)};
        }
      `

    case 'danger':
      return css`
        background: ${Colors.ButtonDanger};
        &:hover {
          background: ${Colors.ButtonDangerHover};
        }
      `

    case 'primary':
    default:
      return css`
        background: ${Colors.ButtonPrimary};
        &:hover {
          background: ${Colors.ButtonPrimaryHover};
        }
      `
  }
}

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`

export const Block = styled.div`
  display: flex;
  padding: 1em;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover span {
    transform: scale3d(1,1,1);
  }

  ${getStyle};
`

export const Button = styled.span`
  ${icon};
  padding: 0;
  color: ${Colors.TextLight};
`

export const Icon = styled(FontAwesomeIcon)``
