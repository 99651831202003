import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'
import { Accordion, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

interface Props {
  backgroundImage?: string
}

const Wrapper = styled.div`
  background: ${Colors.White1000};
  background: ${Colors.White1000};
  height: calc(88vh - 70px);
  overflow: auto;
  ${scrollbars};
`

const Header = styled.div`
  padding: 20px;
  padding-top: 14px;
  padding-bottom: 16px;

  .workflow {
    font-size: 12px;
    text-decoration: underline;
    color: ${Colors.DarkBlue200};
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .ui.icon.input>i.icon:not(.link) {
    font-size: 14px;
  }

  .ui.input {
    margin-right: 20px;
    width: 30%;
  }

  .ui.icon.input>input {
    border-radius: 2px;
    height: 28px;
    font-size: 12px;
  }
`

const Title = styled.div`
  font-size: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  color: ${Colors.DarkBlue200};
`

const Content = styled.div`
  background-color: ${Colors.White1000};
`

const StyledAccordionContent = styled(Accordion.Content)`
  display: none;
`

const StyledAccordionTitle = styled(Accordion.Title)`
  display: flex;
  padding: 12px !important;
  padding-left: 6px !important;

  &:active {
    ${StyledAccordionContent} {
      display: none;
    }
  }

  &.active.title {
    background-color: ${Colors.White535};
  }

  .fa-minus {
    height: 10px;
    width: 10px;
    color: ${Colors.DarkBlue200};
    margin-right: 8px;
    margin-top: 4px;
  }

  .fa-plus {
    height: 10px;
    width: 10px;
    color: ${Colors.DarkBlue200};
    margin-right: 8px;
    margin-top: 4px;
  }

  .fa-bars {
    height: 12px;
    width: 12px;
    color: ${Colors.DarkBlue200};
    margin-right: 8px;
  }
`

const StyledContent = styled.div`
  .accordionUI {
    padding: 20px;
    padding-top: 6px;
    font-size: 12px;
    overflow: auto;
    height: calc(88vh - 200px);

    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: ${Colors.transparent};
    }
  }

  .styled-accordion {
    border-bottom: 1px solid ${Colors.Grey100};
  }
`

const ContentHeader = styled.div`
  background: ${Colors.White535};
  display: flex;
  color: ${Colors.Black500};
  font-size: 14px;
  padding: 15px;
  padding-bottom: 10px;
  font-weight: 500;
`

const WorkflowTaskTitle = styled.div`
  flex-grow: 2;
  width: 0px;
  margin-left: 10px;
  user-select: none;
`

const DueDaysTitle = styled.div`
  flex-grow: 1;
  cursor: pointer;
  user-select: none;

  @media (max-width: 1100px) {
    margin-left: 68px;
  }
`

const ActionTitle = styled.div`
  margin-right: 32px;
  user-select: none;

  @media (max-width: 1100px) {
    margin-right: 28px;
  }
`

const TaskName = styled.div`
  flex-grow: 1;
  display: flex;
  width: 120px;
  color: ${props => props.color || props.color};

  @media (max-width: 1100px) {
    width: 116px;
  }

  @media (min-width: 1400px) {
    width: 146px;
  }

  .fa-envelope {
    width: 18px;
    height: 18px;
    margin-left: 15px;
  }

  .fa-phone {
    width: 16px;
    height: 16px;
    margin-left: 15px;
    transform: rotate(100deg);
  }

  .fa-handshake {
    width: 22px;
    height: 22px;
    margin-left: 15px;
  }

  .fa-ticket {
    width: 20px;
    height: 20px;
    margin-left: 15px;
  }

  .fa-sms {
    width: 18px;
    height: 18px;
    margin-left: 15px;
  }
`

const DueIn = styled.div`
  flex-grow: 1;
  text-align: center;
  color: ${Colors.Black500};
`

const Action = styled.div`
  flex-grow: 1;
  margin-right: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledDropdown = styled.div`
  width: auto;
  min-width: 32px;
  font-size: 10px;
`

const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

const Infograph = styled.div`
  background: ${(props: Props) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`


export {
  Action,
  ActionTitle,
  Content,
  ContentHeader,
  DueDaysTitle,
  DueIn,
  Header,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledContent,
  StyledDropdown,
  TaskName,
  DropDownPopup,
  Title,
  WorkflowTaskTitle,
  Wrapper,
  Infograph
}
