import { AddressErrorType, AddressValidationType } from './Types'

const validStreetName = /^[a-zA-Z0-9][^&<>"~;$^%{}?]{1,100}$/
const validCityName = /^[a-zA-Z\s-.']{1,50}$/
const notNumbers = /[^0-9]/gi

export const validateAddress = (form: AddressValidationType) => {
  const errors = {} as AddressErrorType
  let formIsValid = true

  if (!form.streetNumber && !form.streetName) {
    formIsValid = false
    errors.streetName = 'Address can not be blank.'
  } else if (!form.streetNumber || form.streetNumber.match(notNumbers)) {
    formIsValid = false
    errors.streetNumber = 'Address must include a valid street number and street name.'
  } else if (!form.streetName || !form.streetName.match(validStreetName)) {
    formIsValid = false
    errors.streetName = 'Address must include a valid street name.'
  }

  if (!form.city) {
    formIsValid = false
    errors.city = 'City can not be blank.'
  } else if (!form.city.match(validCityName)) {
    formIsValid = false
    errors.city = 'City can not include special characters.'
  }

  if (!form.state) {
    formIsValid = false
    errors.state = 'State can not be blank.'
  }

  if (!form.zipCode.trim()) {
    formIsValid = false
    errors.zipCode = 'Zip code can not be blank.'
  } else if (form.zipCode.trim().match(notNumbers)) {
    formIsValid = false
    errors.zipCode = 'Please only enter a 5-digit zip code.'
  }

  return { errors, formIsValid }
}
