import gql from 'graphql-tag'

import {
  officeFragment,
  officeMlsFragment,
  officeUserAddressFragment,
  officeUserFragment,
} from 'queries/graphql/Offices/Fragments'

export const GET_STATES = gql`
  query getStates {
    getStates(where: { isActive: true }) {
      _id
      name
    }
  }
`

export const GET_STATES_OFFICES = gql`
  query getStatesOffices(
    $id: String
    $orderField: String
    $sortOrder: Int
    $officeOrderField: String
    $sortOfficeOrder: Int
    $filter: OfficeSearch
    $singleSkip: Int
  ) {
    getStates(
      where: { isActive: true }
      sorting: { field: $orderField, order: $sortOrder }
      _id: $id
      singleSorting: { field: $officeOrderField, order: $sortOfficeOrder }
      filter: $filter
      singleSkip: $singleSkip
    ) {
      _id
      name
      isActive
      stateOrder
      icon
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      offices {
        ...OfficeFragment
        isActive
        users {
          ...OfficeUserFragment
          emails {
            value
          }
          addresses {
            ...OfficeUserAddressFragment
          }
          phones {
            type
            value
          }
        }
        mls {
          ...OfficeMlsFragment
        }
      }
    }
  }
  ${officeFragment}
  ${officeMlsFragment}
  ${officeUserFragment}
  ${officeUserAddressFragment}
`

export const GET_STATE_OFFICES = gql`
  query getStateOffice(
    $id: ID
    $orderField: String
    $sortOrder: Int
    $filter: OfficeSearch
    $singleSkip: Int
    $boardId: String
  ) {
    getStates(
      where: { _id: $id, isActive: true }
      sorting: { field: $orderField, order: $sortOrder }
      filter: $filter
      singleSkip: $singleSkip
      _id: $boardId
    ) {
      _id
      name
      isActive
      stateOrder
      icon
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      offices {
        ...OfficeFragment
        isActive
        users {
          ...OfficeUserFragment
          emails {
            value
          }
          addresses {
            ...OfficeUserAddressFragment
          }
          phones {
            type
            value
          }
        }
        mls {
          ...OfficeMlsFragment
        }
      }
    }
  }
  ${officeFragment}
  ${officeMlsFragment}
  ${officeUserFragment}
  ${officeUserAddressFragment}
`

export const GET_USER_STATES = gql`
  query getUserOfficeState {
    getUserOfficeState(where: { isActive: true }) {
      _id
      name
      offices {
        _id
      }
    }
  }
`
