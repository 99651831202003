// Import Packages
import { Form } from 'semantic-ui-react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { fontStyle } from 'design/Fonts'

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(155, 155, 155, 0.25);
  font-family: ${fontStyle};
`
const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  top: 76px;
  z-index: 100;
  position: fixed;
  cursor: pointer;
  left: 0px;
`

const Modal = styled.div`
  position: relative;
  padding: 0px;
  background-color: ${Colors.White1000};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.13);
  z-index: 101;
`

const SegmentGroup = styled(Segment.Group)`
  &.ui.horizontal.segments {
    border: none;
    box-shadow: none;
  }
`

const ImageContainer = styled(Segment)`
  .ui.horizontal.segments > &.segment {
    border: none;
    box-shadow: none;
    padding: 1em 34px;
    border-left: none;
    border-right-color: ${Colors.Grey950};
    border-right-width: 1.5px;
    border-right-style: solid;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: ${Colors.Black500};
    .header {
      margin: 10px auto;
      font-size: 16px;
      font-weight: 700;
    }
    .description {
      margin: 10px auto;
      font-size: 12px;
      text-align: center;
      justify-content: center;
      max-width: 250px;
    }
    .ui.small.image {
      width: 300px;
    }
  }
`

const DetailContainer = styled(Segment)`
  .ui.horizontal.segments > &.segment {
    border: none;
    box-shadow: none;
    margin: 0 20px;
    width: 700px;
  }
`

const TextBox = styled(Input)`
  &.ui.input {
    width: 100%;
    background-color: transparent;
    color: ${Colors.DarkBlue200};
    padding: 10px 0px;
    margin-bottom: 10px;
    ::placeholder {
      color: ${Colors.Grey1000};
    }
  }

  &.ui.fluid.input > input {
    border-color: ${Colors.Grey900};
    padding: inherit;
    border-style: solid;
    background-color: transparent;
    padding-left: 10px;
    color: ${Colors.DarkBlue200};
    :focus {
      border-color: ${Colors.Grey900};
    }

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::placeholder {
      color: ${Colors.Grey1000};
    }
  }
`

const StyledButton = styled(Button)`
  &.ui.button {
    padding: 1em 2.5em;
    background-color: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    margin-top: 20px;
    &:hover {
      background-color: ${Colors.DarkBlue200} !important;
      color: ${Colors.White1000} !important;
    }
    &:focus {
      background-color: ${Colors.DarkBlue200} !important;
      color: ${Colors.White1000} !important;
    }
    &:active {
      background-color: ${Colors.DarkBlue200} !important;
      color: ${Colors.White1000} !important;
    }
  }
`

const Row = styled.div`
  display: flex;
  .field {
    width: 50%;
    padding-right: 30px;
  }
  .label {
    color: ${Colors.Black750};
  }
`

const StyledForm = styled(Form)`
  &.ui.form .field > label {
    color: ${Colors.Black500};
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    font-weight: bold;
  }
`

export {
  Backdrop,
  DetailContainer,
  ImageContainer,
  Modal,
  ModalWrapper,
  Row,
  SegmentGroup,
  StyledButton,
  TextBox,
  StyledForm
}
