import * as React from 'react'

import {
  ToolTip,
  Button,
  Icon,
} from './Styled'

interface Props {
  className: string
  icon: any
  isActive: boolean
  label: string
  labelPosition: string
  onClick: (e:any) => void
  disabled?: boolean
}

class IconButton extends React.Component<Props, {}> {
  public static defaultProps = {
    className: '',
    isActive: false,
    label: '',
    labelPosition: 'top center',
    onClick: () => { return false },
    disabled:false
  }

  public render = () => {
    const {
      className,
      icon,
      isActive,
      label,
      labelPosition,
      onClick,
      disabled
    } = this.props

    const button = (
      <Button
        className={`rp-button-icon ${className} ${isActive ? 'rp-button-icon-active' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={onClick}
      >
        <Icon icon={icon} />
      </Button>
    )

    if (label) {
      return (
        <ToolTip
          position={labelPosition}
          content={label}
          trigger={button}
        />
      )
    } else {
      return button
    }
  }
}

export default IconButton
