import styled from 'styled-components'

import Colors from 'design/Colors'
import { y } from 'design/Styled'

export const Container = styled.div`
  ${y};
  width: 25%;
  max-width: 280px;
  background: ${Colors.Container};
  box-shadow: 0px 2px 30px rgba(50, 50, 50, 0.1);
  overflow: hidden;
`

export const Header = styled.section`
  ${y};
  position: relative;

  .rp-transaction-sidebar-tools {
    position: absolute;
    bottom: 0;
    z-index: 1;
  }
`

export const Col = styled.div`
  ${y};
`
