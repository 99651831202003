import * as React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

import ComposeModal from '../ComposeModal'

import * as MailboxActions from 'store/Mailbox/Actions'
import { InboxAttachment, MessageEmailCardData, MessageEmailViewData } from 'store/Mailbox/Types'
import { deleteMessageEmail } from '../MailboxMutations'
import { getMessageEmailById } from '../MailboxQueries'

import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'

import AddContactModal from 'app/Contacts/Dashboard/AddContactModal'
import { addNewContact } from 'shared/Contact/Utils'

import AddLeadModal from 'app/Leads/Dashboard/AddLeadModal'
import { addNewLead } from 'shared/Lead/Utils'

import AddEventModal from 'app/Events/AddEventModal'
import { createEvent } from 'app/Tasks/EventMutations'

import { isPlanAllowed } from 'shared/Billing/Queries'
import Toast, { serverToast } from 'shared/Toast/Toast'
import { getLoggedInUser, Strings } from 'utils'

import { Action, Container, Options, SubAction } from './Styled'

import { Props as ContactProps } from 'app/Contacts/Dashboard/AddContactModal/AddContactModal'
import { Props as EventProps } from 'app/Events/AddEventModal/AddEventModal'
import { Props as LeadProps } from 'app/Leads/Dashboard/AddLeadModal/AddLeadModal'
import { Props as ComposeProps } from 'shared/MessageEmail/ComposeModal/ComposeModal'
import { OwnProps as TransactionProps } from 'shared/Transaction/CreateTransactionModal/CreateTransactionModal'
import { ButtonType, ModalType } from './Types'

import {
  faAddressBook,
  faCalendarAlt,
  faFileInvoiceDollar,
  faMale,
  faPlusCircle,
  faReply,
  faReplyAll,
  faShare,
  faTrashAlt
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faAddressBook,
  faCalendarAlt,
  faFileInvoiceDollar,
  faMale,
  faPlusCircle,
  faReply,
  faReplyAll,
  faShare,
  faTrashAlt
])

interface Props {
  actions: string[]
  vertical?: boolean
  messageData: MessageEmailViewData | MessageEmailCardData
  setDeleteCard: (boardId: string, cardId: string) => void
}

interface State {
  buttons: ButtonType
  modal: ModalType
  modalProps: ContactProps | EventProps | LeadProps | ComposeProps | TransactionProps | any
  eventProps: EventProps
  showOptions: boolean
}

class ActionButtons extends React.Component<Props, State> {
  public state = {
    buttons: {
      forward: false,
      options: false,
      reply: false,
      replyAll: false,
      trash: false
    },
    eventProps: {} as EventProps,
    modal: {} as ModalType,
    modalProps: {},
    showOptions: false
  }

  public componentDidMount = () => {
    const { buttons } = this.state
    this.props.actions.forEach(action => {
      buttons[action] = true
    })
    this.setState({ buttons })
  }

  public render = () => {
    const { actions, vertical } = this.props

    const {
      buttons: { forward, options, reply, replyAll, trash },
      eventProps,
      modal,
      modalProps,
      showOptions
    } = this.state

    return (
      <React.Fragment>
        {modal.compose && <ComposeModal {...modalProps as ComposeProps} />}
        {modal.transaction && <CreateTransactionModal {...modalProps as TransactionProps} />}
        {modal.contact && <AddContactModal {...modalProps as ContactProps} />}
        {modal.lead && <AddLeadModal {...modalProps as LeadProps} />}
        {modal.event && <AddEventModal {...eventProps as EventProps} />}
        <Container vertical={vertical} total={actions.length}>
          {reply && (
            <Action onClick={this.replyMessage}>
              <Icon icon={['fal', 'reply']} />
            </Action>
          )}
          {replyAll && (
            <Action onClick={this.replyAllMessage}>
              <Icon icon={['fal', 'reply-all']} />
            </Action>
          )}
          {forward && (
            <Action onClick={this.forwardMessage}>
              <Icon icon={['fal', 'share']} />
            </Action>
          )}
          {trash && (
            <Action onClick={this.deleteMessage}>
              <Icon icon={['fal', 'trash-alt']} />
            </Action>
          )}
          {options && (
            <Options
              trigger={
                <Action>
                  <Icon icon={['fal', 'plus-circle']} />
                </Action>
              }
              on="click"
              open={showOptions}
              onOpen={this.openOptions}
              onClose={this.closeOptions}
            >
              <SubAction onClick={this.createTransaction}>
                <span>
                  <Icon icon={['fal', 'file-invoice-dollar']} />
                </span>
                Create Transaction
              </SubAction>
              <SubAction onClick={this.createContact}>
                <span>
                  <Icon icon={['fal', 'address-book']} />
                </span>
                Create Contact
              </SubAction>
              <SubAction onClick={this.createLead}>
                <span>
                  <Icon icon={['fal', 'male']} />
                </span>
                Create Lead
              </SubAction>
              <SubAction onClick={this.createEvent}>
                <span>
                  <Icon icon={['fal', 'calendar-alt']} />
                </span>
                Create Event
              </SubAction>
            </Options>
          )}
        </Container>
      </React.Fragment>
    )
  }

  private getMessageViewData = async () => {
    const { messageData } = this.props
    const viewData = await getMessageEmailById(messageData._id)
    return viewData || null
  }

  private closeModal = () => {
    this.setState({ modal: {} as ModalType })
  }

  private closeOptions = () => {
    this.setState({ showOptions: false })
  }

  private openOptions = () => {
    this.setState({ showOptions: true })
  }

  private replyMessage = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const messageData = await this.getMessageViewData()
    this.setState({
      modal: { compose: true },
      modalProps: {
        composerType: 'REPLY',
        messageData,
        onClose: this.closeModal
      } as ComposeProps
    })
  }

  private replyAllMessage = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const messageData = await this.getMessageViewData()
    this.setState({
      modal: { compose: true },
      modalProps: {
        composerType: 'REPLY-ALL',
        messageData,
        onClose: this.closeModal
      } as ComposeProps
    })
  }

  private forwardMessage = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const messageData = await this.getMessageViewData()
    this.setState({
      modal: { compose: true },
      modalProps: {
        composerType: 'FORWARD',
        messageData,
        onClose: this.closeModal
      } as ComposeProps
    })
  }

  private deleteMessage = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const {
      setDeleteCard,
      messageData: { _id }
    } = this.props

    const confirm = await ConfirmAlert({
      cancelButtonText: Strings.mailbox.alert.delete.cancel,
      confirmButtonText: Strings.mailbox.alert.delete.confirm,
      showCancelButton: true,
      text: Strings.mailbox.alert.delete.description,
      title: Strings.mailbox.alert.delete.title,
      type: 'warning'
    })

    if (confirm.value) {
      try {
        await deleteMessageEmail(_id)
        setDeleteCard('INBOX', _id)
        Toast({
          message: Strings.mailbox.alert.delete.success,
          type: 'success'
        })
      } catch {
        Toast({
          message: Strings.mailbox.alert.delete.success,
          type: 'error'
        })
      }
    }
  }

  private createTransaction = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    try {
      await isPlanAllowed('transaction')
    } catch (error) {
      return serverToast(error)
    }

    this.closeOptions()
    const {
      messageData: { attachments }
    } = this.props
    const docIds: string[] = attachments
      .filter((item: InboxAttachment) => item.fileType === 'application/pdf')
      .map((item: InboxAttachment) => item._id)

    this.setState({
      modal: { transaction: true },
      modalProps: {
        docIds,
        onClose: this.closeModal
      } as TransactionProps
    })
  }

  private createContact = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closeOptions()
    const user = await getLoggedInUser({ fromCache: true })
    const {
      messageData: { from }
    } = this.props
    const name = from.name && from.name.split(' ')
    const firstName = (name && name[0]) || ''
    const lastName = (name && name[1]) || ''

    this.setState({
      modal: { contact: true },
      modalProps: {
        addBoardId: '',
        closeModal: this.closeModal,
        createContact: addNewContact,
        edit: true,
        editContactDetails: {
          email: from.value || '',
          firstName,
          lastName,
          source: 'Email'
        },
        filterOptions: false,
        user
      } as ContactProps
    })
  }

  private createLead = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closeOptions()
    const {
      messageData: { from }
    } = this.props
    const name = from.name && from.name.split(' ')
    const firstName = (name && name[0]) || ''
    const lastName = (name && name[1]) || ''

    this.setState({
      modal: { lead: true },
      modalProps: {
        addBoardId: '',
        addLead: addNewLead,
        closeModal: this.closeModal,
        edit: true,
        editDetails: {
          email: from.value || '',
          firstName,
          lastName,
          source: 'Email'
        },
        leadPool: false
      } as LeadProps
    })
  }

  private createEvent = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closeOptions()

    const addNewEvent = async (event: any) => {
      await createEvent(event, false)
    }

    this.setState({
      modal: { event: true },
      modalProps: {
        addEvent: addNewEvent,
        closeModal: this.closeModal,
        edit: false,
        editEventObj: {},
        selectedData: {}
      } as EventProps
    })
  }
}

export default connect(
  null,
  {
    setDeleteCard: MailboxActions.deleteCard
  }
)(ActionButtons)
