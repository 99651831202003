// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { x } from 'design/Styled'

export const Container = styled.div`
  background: ${Colors.Container};
  margin-bottom: 25px;
  height: 330px;
`

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.8in;
  padding: 0.3in;
  box-shadow: 0 0 0.4in -0.4in #333;

  & > div:first-child {
    background: #444;
  }
`

export const Title = styled.div`
  ${x};
  justify-content: space-between;
  border-bottom: 2px solid ${Colors.Border};
  color: ${Colors.TextPrimary};
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  padding-left: 30px;
`

export const PhotoUploadButton = styled.img`
  display: block;
  cursor: pointer;
  width: 30px;
  margin: 0 0.5em;
`
