import NumberFormat from 'react-number-format'

import { Widths } from 'design/Media'
import styled, { css } from 'styled-components'

import Colors from 'templates/Listing/Colors'
import { fonts } from 'design/Fonts'
import { children, fa, x, y } from 'design/Styled'

interface Props {
  image?: string
  icon?: string
  colorMode?: string
}

const Icons = {
  email: '\f0e0',
  phone: '\f095',
}

const getColor = ({ colorMode }: Props, color: string) => {
  if (!colorMode) {
    return Colors.light[color]
  }

  return Colors[colorMode][color]
}

const getBackground = ({ image }:Props) => {
  if (!image) {
    return css``
  }

  return css`
    background-image: url('${image}');
    background-position: center;
    background-size: cover;
  `
}

const getIcon = ({ icon }:Props) => {
  if (!icon) {
    return ``
  }

  return css`
    &:before {
      ${fa};
      position: absolute;
      left: -1.8em;
      font-size: 0.8em;
      display: block;
      content: '${Icons[icon]}';
      ${icon === 'phone' ? 'transform: rotate(100deg);' : ''}
    }
  `
}

const getLink = ({ colorMode }: Props) => {
  colorMode = colorMode || 'light'
  return css`
    text-decoration: none;
    color: ${Colors[colorMode].Highlight};
    cursor: pointer;

    &:visited,
    &a:visited {
      color: ${Colors[colorMode].Highlight};
      text-decoration: none;
    }

    &:hover,
    &:active,
    &a:hover,
    &a:active {
      color: ${Colors[colorMode].HighlightHover};
      text-decoration: none;
    }
  `
}

const getShadow = ({ colorMode }: Props) => {
  colorMode = colorMode || 'light'
  return css`
    box-shadow: 0 2px 5px 0 ${Colors[colorMode].Shadow};
  `
}

const center = css`
  text-align: center;
`

const margin = css`
  margin: 0 0 0.6em 0;
`

export const Col = styled.div`
  ${y};
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Address = styled.h1`
  width: 100%;
  font: ${fonts.normal.bold};
  font-size: 1.5em;
  ${margin};

  span {
    display: block;
    width: 100%;
    ${center};
  }
`

export const Description = styled.p`
  font: ${fonts.large.regular};
  max-width: 600px;
  line-height: 1.3;
  ${margin};
`

export const Detail = styled.span`
  ${x};
  max-width: 220px;
  justify-content: space-between;
  font: ${fonts.large.regular};
  line-height: 1.3;
  padding: 0 1.5em;
  margin-bottom: 0.6em;
  padding-bottom: 0.4em;
  border-bottom: 1px solid;

  strong {
    font-weight: bold;
  }
`

export const Disclaimer = styled.span`
  ${margin};
  ${center};
  font: ${fonts.small.regular};
  line-height: 1.3;
`

export const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: 100px;
  ${margin};
`

export const Main = styled.div`
  ${y};

  .rp-listing-image {
    overflow: hidden;
    border-radius: 4px;
    margin: 0 0 1.5em 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${Widths.Landscape}px) {
    flex: 1;
    height: 100%;
    overflow: hidden;

    ${Col} {
      padding: 1em 0.6em;
    }
  }
`

export const Name = styled.h4`
  font: ${fonts.normal.bold};
  ${margin};
  ${center};
`

export const Footer = styled.footer`
  ${y};
  align-items: center;
  justify-content: center;
  padding: 1em 0;

  &:last-child {
    padding-bottom: 0;
  }
`

export const Link = styled.a`
  position: relative;
  ${margin};
  ${getIcon};
`

export const Price = styled(NumberFormat)`
  display: block;
  width: 100%;
  text-align: center;
  font: ${fonts.normal.bold};
  font-size: 1.4em;
  ${margin};
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 8px solid;
  border-radius: 50%;
  ${margin};
  ${getBackground};
`

export const Header = styled.div`
  ${y};
  flex: 1;
  padding: 1em 0;
`

export const Sidebar = styled.article`
  ${y};

  @media (min-width: ${Widths.Landscape}px) {
    font-size: 14px;
    max-width: 320px;
    height: 100%;
    overflow: hidden;

    .rp-scrollable-child > div {
      min-height: 100%;
      padding: 1em;
      padding-left: 1.6em;
    }
  }

  @media (min-width: ${Widths.Desktop}px) {
    max-width: 380px;
  }
`


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 1em 0.6em;

  .desktop {
    display: none;
  }
  .tablet {
    display: none;
  }
  .mobile {
    display: flex;
  }

  @media (min-width: ${Widths.Landscape}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    .tablet {
      display: flex;
    }
    .mobile {
      display: none;
    }
    .rp-scrollable-child {
      height: 100%;
    }
  }

  @media (min-width: ${Widths.Desktop}px) {
    .desktop {
      display: flex;
    }
  }

  color: ${(props:Props) => getColor(props, 'Text')};
  background: ${(props:Props) => getColor(props, 'Background')};

  ${Detail} {
    border-color: ${(props:Props) => getColor(props, 'Emphasis')};
    strong {
      color: ${(props:Props) => getColor(props, 'Bold')};
    }
  }

  ${Price} {
    color: ${(props:Props) => getColor(props, 'Highlight')};
  }

  ${Profile} {
    border-color: ${(props:Props) => getColor(props, 'Border')};
    ${getShadow};
  }

  ${Main} {
    .rp-listing-image {
      ${getShadow};
    }
  }

  ${Link} {
    ${getLink};
    &:before {
      color: ${(props:Props) => getColor(props, 'Emphasis')};
    }
  }
`
