import Konva from 'konva'
import { isEqual } from 'lodash'
import * as React from 'react'

import {
  Group,
  Label,
  Rect,
  Tag,
  Text,
  Transformer,
  useStrictMode,
} from 'react-konva'

import Colors from 'design/Colors'
import { PrimaryFontName, SecondaryFontName } from 'design/Fonts'

import {
  MarkupSourceEnum,
  PointType,
  MarkupType,
  StageType,
} from '../Types'

interface Props {
  onChange: (rectangle: MarkupType) => void
  onClick: (rectangle: MarkupType) => void
  rectangle: MarkupType
  source: MarkupSourceEnum
  container: StageType
}

interface State {
  allowGroupDrag: boolean
  isDelete: boolean
  isInitialTransform: boolean
  isTransform: boolean
  mousePosition: PointType
  showTransformer: boolean
}

// must track and update changes manually
useStrictMode(true)

class Rectangle extends React.Component<Props, State> {
  public static defaultProps = {
    onClick: (rectangle: MarkupType) => { return },
  }

  public state = {
    allowGroupDrag: false,
    isDelete: false,
    isInitialTransform: true,
    isTransform: true,
    mousePosition: {} as PointType,
    showTransformer: true
  }

  private rectangleRef = React.createRef<Konva.Rect>()
  private transformerRef = React.createRef<Konva.Transformer>()
  private timer: any = null

  public componentDidMount = () => {
    const rectangle = this.rectangleRef
    const transformer = this.transformerRef
    if (transformer.current && rectangle.current) {
      transformer.current.setNode(rectangle.current)
    }
  }

  public componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  public render = () => {
    const {
      rectangle,
      source,
      container,
    } = this.props

    const {
      allowGroupDrag,
      showTransformer,
    } = this.state

    const hsl = [220, 99, 57]
    const color = `${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%`
    const overlayFill = `hsla(${color}, ${source === MarkupSourceEnum.Textract ? 0.3 : 0.1})`
    const labelFill = `hsla(${color}, 1)`

    return (
      <Group
        draggable={allowGroupDrag}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        x={rectangle.left * container.width}
        y={rectangle.top * container.height}
      >
        {rectangle.label.text && (
          <Label
            x={0}
            y={-14}
            onMouseDown={() => this.toggleGroupDrag(true)}
            onMouseUp={() => this.toggleGroupDrag(false)}
            onMouseEnter={() => {
              document.body.style.cursor = 'move'
              this.toggleTransformer(true)
            }}
            onMouseLeave={() => {
              document.body.style.cursor = 'default'
              this.toggleTransformer(false)
            }}
          >
            <Tag fill={labelFill} />
            <Text
              align="left"
              fill={hsl[2] >= 60 ? Colors.TextDark : Colors.TextLight}
              fontFamily={`${PrimaryFontName}, ${SecondaryFontName}, sans-serif`}
              fontSize={11}
              height={14}
              lineHeight={0}
              offsetY={-1}
              padding={4}
              text={rectangle.label.text}
              verticalAlign="middle"
            />
          </Label>
        )}
        <Label
          x={-14}
          y={-14}
          onMouseDown={this.deleteMarkup}
          onMouseEnter={() => {
            document.body.style.cursor = 'pointer'
          }}
          onMouseLeave={() => {
            document.body.style.cursor = 'default'
          }}
        >
          <Tag fill={Colors.ButtonDanger} />
          <Text
            align="center"
            fill={Colors.TextLight}
            fontFamily={`${PrimaryFontName}, ${SecondaryFontName}, sans-serif`}
            fontSize={11}
            height={14}
            width={14}
            lineHeight={0}
            offsetY={-1}
            padding={4}
            text={String.fromCharCode(parseInt('\f00D7', 16))}
            verticalAlign="middle"
          />
        </Label>
        <Rect
          fill={overlayFill}
          height={rectangle.height * (container.height * container.scale.y)}
          id={rectangle.id}
          name={rectangle.label.value}
          onMouseDown={() => this.toggleGroupDrag(true)}
          onMouseEnter={() => {
            document.body.style.cursor = 'move'
            this.toggleTransformer(true)
          }}
          onMouseLeave={() => {
            document.body.style.cursor = 'default'
            this.toggleTransformer(false)
          }}
          onTransformEnd={this.updateScale}
          onTransformStart={() => this.toggleGroupDrag(false)}
          ref={this.rectangleRef}
          width={rectangle.width  * (container.width * container.scale.x)}
          x={0}
          y={0}
        />
        <Transformer
          anchorFill={showTransformer ? '#fff' : 'transparent'}
          anchorSize={showTransformer ? 8 : 0}
          anchorStrokeWidth={showTransformer ? 1 : 0}
          borderEnabled={showTransformer}
          keepRatio={false}
          onMouseEnter={() => this.toggleTransformer(true)}
          onMouseLeave={() => this.toggleTransformer(false)}
          ref={this.transformerRef}
          rotateEnabled={false}
        />
      </Group>
    )
  }

  private handleMouseDown = (e: any) => {
    const { allowGroupDrag } = this.state
    if (allowGroupDrag) {
      this.setState({
        isTransform: false,
        mousePosition: e.target.getStage().getPointerPosition()
      })
    } else {
      this.setState({ isTransform: true })
    }
  }

  private handleMouseUp = (e: any) => {
    const { onChange, rectangle, container } = this.props
    const { isDelete, isInitialTransform, isTransform, mousePosition: original } = this.state

    if (isDelete) {
      const deleted: MarkupType = {
        ...rectangle,
        height: 0,
        width: 0
      }
      return onChange(deleted)
    }

    if (isInitialTransform) {
      this.updateScale()
      this.handleClick(e)
    }

    if (isTransform) {
      this.setState({
        allowGroupDrag: true,
        isInitialTransform: false,
        isTransform: false
      })
      return
    }

    const final = e.target.getStage().getPointerPosition()

    if (isEqual(original, final)) {
      this.handleClick(e)
      return
    }

    const difference: PointType = {
      x: (final.x - original.x) / container.width / container.scale.x,
      y: (final.y - original.y) / container.height / container.scale.y
    }

    this.updatePosition(difference)
  }

  private updatePosition = (difference: PointType) => {
    const { onChange, rectangle } = this.props

    let newShape: MarkupType = {
      ...rectangle,
      left: rectangle.left + difference.x,
      top: rectangle.top + difference.y
    }

    onChange(newShape)
  }

  private updateScale = () => {
    const { onChange, rectangle } = this.props

    const rect = this.rectangleRef.current
    const trns = this.transformerRef.current
    if (!rect || !trns) {
      return
    }

    const scale = rect.scale()
    const position = rect.position()

    rect.scale({
      x: 1,
      y: 1
    })

    let newShape: MarkupType = {
      ...rectangle,
      height: rectangle.height * scale.y,
      left: rectangle.left + position.x,
      top: rectangle.top + position.y,
      width: rectangle.width * scale.x
    }

    onChange(newShape)
  }

  private handleClick = (e: any) => {
    const { onClick, rectangle } = this.props
    onClick(rectangle)
  }

  private deleteMarkup = (e: any) => {
    this.setState({ isDelete: true })
  }

  private toggleTransformer = (showTransformer: boolean) => {
    const { isTransform } = this.state
    if (isTransform) {
      return
    }

    if (showTransformer && this.timer) {
      clearTimeout(this.timer)
      this.setState({ showTransformer })
    } else {
      this.timer = setTimeout(() => {
        this.setState({ showTransformer })
      }, 100)
    }
  }

  private toggleGroupDrag = (allowGroupDrag: boolean) => {
    this.setState({ allowGroupDrag })
  }
}

export default Rectangle
