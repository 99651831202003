import { Reducer } from 'redux'
import { merge as _merge } from 'lodash'

import {
  DispatchEnum,
  TaxDictType,
  TaxOfficeType,
  TaxState,
} from './Types'

const initialState: TaxState = {
  dict: {} as TaxDictType,
  list: [],
}

const TaxReducer: Reducer<TaxState> = (
  state: TaxState = initialState,
  action
) => {
  switch (action.type) {
    case DispatchEnum.SetTax: {
      const { payload } = action
      const current: TaxOfficeType = state.dict[payload._id] || {} as TaxOfficeType
      const update: TaxOfficeType = _merge({}, current, payload)

      const dict = { ...state.dict }
      dict[payload._id] = update

      if (current.state.tax !== update.state.tax) {
        Object.keys(dict).forEach((id:string) => {
          if (dict[id].state._id === update.state._id) {
            dict[id].state.tax = update.state.tax
          }
        })
      }

      if (current.city.tax !== update.city.tax) {
        Object.keys(dict).forEach((id:string) => {
          if (dict[id].city._id === update.city._id) {
            dict[id].city.tax = update.city.tax
          }
        })
      }

      return {
        ...state,
        dict: { ...dict },
      }
    }

    case DispatchEnum.SetTaxDict: {
      const { payload } = action
      return {
        ...state,
        dict: { ...payload }
      }
    }

    case DispatchEnum.SetTaxList: {
      const { payload } = action
      return {
        ...state,
        list: [ ...payload ]
      }
    }

    default:
      return state
  }
}

export default TaxReducer
