// Import Packages
import * as React from 'react'

import ImageEditor from 'shared/ImageEditor'
import { ImageType as EditorImageType } from 'shared/ImageEditor/Types'
import { ProfileType } from '../../Types'

import { uploadCompanyLogo } from 'app/Settings/Mutations'

// Import Images
import Camera from 'design/icons/transactions/sidebar/photo-camera.png'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  Image,
  PhotoUploadButton,
  Title
} from './Styled'

interface Props {
  profile: ProfileType
}

interface State {
  companyLogo: EditorImageType
}

class BrandingLogo extends React.Component<Props, State> {
  public textInputCompanyLogo: any = React.createRef()

  public state = {
    companyLogo: {} as EditorImageType
  }

  public componentDidMount() {
    const { profile } = this.props
    this.setState({ companyLogo: { url: profile.companyLogo } })
  }

  public render() {
    const { companyLogo } = this.state
    return (
      <Container>
        <Title>
          <div>{Strings.settings.profile.brandingLogo}</div>
          <PhotoUploadButton
            src={Camera}
            onClick={this.upload}
          />
        </Title>
        <Image>
          <ImageEditor
            image={companyLogo}
            onChange={(image: EditorImageType) => this.handleImageChange(image)}
            onClick={() => ''}
          />
        </Image>
        <input
          type="file"
          ref={(node: any) => { this.textInputCompanyLogo = node }}
          id="hidden-new-file"
          style={{ display: 'none' }}
          name='fileName'
          onChange={(e: any) => this.uploadCompanyLogo(e.target.files[0])}
          multiple={true}
        />
      </Container>
    )
  }

  private handleImageChange = (image: EditorImageType) => {
    this.setState({ companyLogo: image })
  }

  private upload = () => {
    this.textInputCompanyLogo.click()
  }

  private uploadCompanyLogo = async (file: File) => {
    const response = await uploadCompanyLogo(file)
    this.setState({ companyLogo: { url: response } })
  }
}

export default BrandingLogo
