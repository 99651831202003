// Import Packages
import { History } from 'history'
import { sortBy } from 'lodash'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

// Import Components
import { CustomActivities, CustomScore } from './CustomComponents'

// Import Images and Icons
import Colors from 'design/Colors'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Styled Components
import {
  StyledTable,
} from './Styled'

interface Props extends RouteComponentProps<{}> {
  history: History
  closedLead: any[]
  user: any
}
interface State {
  data: any
  column: any
  direction: any
}

class Closed extends React.Component<Props, State> {
  public state = {
    column: undefined,
    data: [],
    direction: undefined,
  }

  public componentDidMount() {
    const { closedLead } = this.props
    this.setState({ data: closedLead })
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({ data: nextProps.closedLead })
  }

  public render() {
    const { history, user } = this.props
    const { column, direction, data } = this.state
    const inProcess = data.map((items: any, index: number) => (
      <Table.Row key={index} style={{ cursor: 'pointer' }} onClick={() => (items.tenantBoard in items) ? (user.role === 'ADMIN' || user.role === 'MANAGER') ? history.push(Routes.leads.root + Routes.leads.details.path + `/${items._id}?pool=1`) : '' : history.push(Routes.leads.root + Routes.leads.details.path + `/${items._id}?pool=0`)}>
        <Table.Cell>{`${items.firstName} ${items.lastName}`}</Table.Cell>
        <Table.Cell>{CustomScore(items.leadScore)}</Table.Cell>
        <Table.Cell>{items.type}</Table.Cell>
        <Table.Cell>{items.source}</Table.Cell>
        <Table.Cell>{CustomActivities(items.activities)}</Table.Cell>
      </Table.Row>
    ))
    return (
      <>
        {
          data.length !== 0 && <StyledTable singleLine={true} sortable={true}>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={{ borderBottom: `1px solid rgba(34,36,38,.1)`, color: Colors.Black500 }} colSpan='4'>{Strings.dashboard.passes.count} {data.length}</Table.Cell>
                <Table.Cell style={{ borderBottom: `1px solid rgba(34,36,38,.1)` }} colSpan='1'>
                  <div style={{ background: Colors.Magenta60, padding: 4, color: Colors.White1000, paddingTop: 2, paddingBottom: 2, fontWeight: 600, width: 60, textAlign: 'center' }}>Closed</div>
                </Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell sorted={column === 'firstName' ? direction : undefined} onClick={this.handleSort('firstName')}>{Strings.dashboard.passes.leadName}</Table.HeaderCell>
                <Table.HeaderCell sorted={column === 'leadScore' ? direction : undefined} onClick={this.handleSort('leadScore')}>{Strings.dashboard.passes.score}</Table.HeaderCell>
                <Table.HeaderCell sorted={column === 'type' ? direction : undefined} onClick={this.handleSort('type')}>{Strings.dashboard.passes.type}</Table.HeaderCell>
                <Table.HeaderCell sorted={column === 'source' ? direction : undefined} onClick={this.handleSort('source')}>{Strings.dashboard.passes.source}</Table.HeaderCell>
                <Table.HeaderCell sorted={column === 'activities' ? direction : undefined} onClick={this.handleSort('activities')}>{Strings.dashboard.passes.activities}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {inProcess}
            </Table.Body>
          </StyledTable>
        }
      </>
    )
  }

  public handleSort = (clickedColumn: any) => () => {
    const { column, data, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: sortBy(data, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }
}

export default withRouter(Closed)
