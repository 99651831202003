// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Button, Form, Icon, Input } from 'semantic-ui-react'

// Import Component
import { handleValidation } from './Validation'

// Import Store Types, Actions and Reducers
import { Owner, TaskNotes, UserType } from 'store/Tasks/Types'

import { ErrorType } from './Types'

// Import Styled Components
import { Container, Date, Description, PropFile, StyledForm, StyledNotes } from './Styled'

interface Props {
  addNewNotes: (note: string) => void
  editNotes: (id: string, note: string) => void
  deleteNotes: (id: string) => void
  notesData: TaskNotes[]
  taskOwner: Owner
  user: UserType
  loader: boolean
}

interface State {
  id: string
  note: string
  errors: ErrorType
  showEditButton: boolean
  showConfirmationModal: boolean
}

class Notes extends React.Component<Props, State> {
  public state = {
    errors: {} as ErrorType,
    id: '',
    note: '',
    showConfirmationModal: false,
    showEditButton: false
  }

  public render() {
    const { notesData, taskOwner, user, loader } = this.props
    const { note, showEditButton, errors } = this.state
    const notes = notesData.map((items: any, index: any) => {
      const editNotesFunction = () => {
        this.setState({ id: items._id, note: items.description, showEditButton: true })
      }
      const deleteNotesFunction = () => {
        this.deleteNotes(items._id)
      }
      return (
        <Container key={index}>
          <PropFile background={items.createdBy.profileImage} />
          <div style={{ width: '100%' }}>
            <Description>{items.description}</Description>
            <Date>
              <div style={{ flexGrow: 1 }}>{moment(items.createdAt).format('ll')}</div>
              <div
                style={{
                  marginRight: '10px',
                  display:
                    user._id === items.createdBy._id ||
                    user._id === taskOwner._id ||
                    user.role === 'ADMIN' ||
                    user.role === 'MANAGER'
                      ? 'block'
                      : 'none'
                }}
              >
                <Icon name="pencil" onClick={editNotesFunction} />
                <Icon name="trash" onClick={deleteNotesFunction} />
              </div>
            </Date>
          </div>
        </Container>
      )
    })
    return (
      <div>
        <StyledForm size={'small'}>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              name="note"
              placeholder="Add a note"
              onChange={this.handleChange}
              value={note}
              error={errors[`note`]}
            />
            {loader ? (
              <Form.Field control={Button} loading={true} content="LOADING" />
            ) : showEditButton ? (
              <Form.Field control={Button} content="EDIT" onClick={this.editNotes} />
            ) : (
              <Form.Field control={Button} content="SUBMIT" onClick={this.saveNotes} />
            )}
          </Form.Group>
        </StyledForm>
        <StyledNotes>{notes}</StyledNotes>
      </div>
    )
  }

  private deleteNotes = (id: string) => {
    const { deleteNotes } = this.props
    deleteNotes(id)
  }

  private editNotes = () => {
    const { editNotes } = this.props
    const { id, note } = this.state
    const result: any = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      editNotes(id, note)
      this.setState({ id: '', note: '', showEditButton: false })
    }
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    this.setState({ note: value })
  }

  private saveNotes = () => {
    const { addNewNotes } = this.props
    const { note } = this.state
    const result: any = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      addNewNotes(note)
      this.setState({ id: '', note: '' })
    }
  }
}

export default Notes
