import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { pinStripes } from 'design/Styled'

interface Props {
  url?: string
  x?: number
  y?: number
  size?: number
}

const getImage = ({ url }: Props) => {
  if (!url) {
    return css`
      ${pinStripes};

      &:before {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        content: 'No Image';
        font: ${fonts.xSmall.medium};
        color: ${Colors.Text};
      }
    `
  }

  return css`
    background-origin: border-box;
    background-image: url(${url});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  `
}

const getEdit = ({ size, x, y }: Props) => {
  const position = `translate3d(${x || 0}px, ${y || 0}px, 0px)`
  const scale = `scale3d(${size || 1}, ${size || 1}, ${size || 1})`
  return css`
    transform: ${position} ${scale};
  `
}

export const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
`

export const Zoom = styled.aside`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 300%;
  }
`

export const Image = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: move;

  ${getImage};
  ${getEdit};
`
