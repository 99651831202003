import gql from 'graphql-tag'

export const GET_INBOX = gql`
  query getMessagesEmail($skip: Int) {
    inbox: getMessagesEmail(
      where: { type: "INBOUND" }
      sorting: { field: "sentAt", order: -1 }
      skip: $skip
      limit: 20
    ) {
      results {
        _id
        attachments {
          _id
          url
          fileName
          fileType
        }
        from {
          _id
          name
          value
          initials
          color
        }
        isRead
        isReplied
        preview
        sentAt
        subject
      }
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
    }
  }
`

export const GET_MESSAGES_EMAIL = gql`
  query getMessagesEmail(
    $whereType: String
    $sortField: String
    $sortOrder: Int
    $skip: Int
    $limit: Int
  ) {
    getMessagesEmail(
      where: { type: $whereType }
      sorting: { field: $sortField, order: $sortOrder }
      skip: $skip
      limit: $limit
    ) {
      results {
        _id
        attachments {
          _id
          url
          fileName
          fileType
        }
        from {
          _id
          name
          value
          initials
          color
        }
        isRead
        isReplied
        preview
        sentAt
        subject
      }
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
    }
  }
`

export const GET_MESSAGE_EMAIL_BY_ID = gql`
  query getMessageEmailById($whereId: String) {
    getMessageEmailById: getMessagesEmail(
      where: { _id: $whereId }
      sorting: { field: "sentAt", order: -1 }
      skip: 0
      limit: 1
    ) {
      results {
        _id
        attachments {
          _id
          url
          fileName
          fileType
        }
        bcc {
          _id
          name
          value
          initials
          color
        }
        cc {
          _id
          name
          value
          initials
          color
        }
        from {
          _id
          name
          value
          initials
          color
        }
        html
        isRead
        isReplied
        preview
        sentAt
        spamScore
        subject
        text
        to {
          _id
          name
          value
          initials
          color
        }
      }
    }
  }
`
