import * as React from 'react'
import ReactQuill, { Quill } from 'react-quill'

import Modal from 'shared/Modal'
import Toast from 'shared/Toast'
import 'quill/dist/quill.bubble.css'

import { CloseButton, Container, fontSizes, ModalContent, Text } from './Styled'

import { TemplateEnumType } from './Types'

// Font Awesome Icons
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faTimes])

interface Props {
  isPaid: boolean
  templateType: TemplateEnumType
  html: string
  onChange: (content: any, delta: any, source: any, editor: any) => void
}

class QuillInline extends React.Component<Props, {}> {
  public state = {
    animate: false,
    showEditor: false
  }

  private modules = {
    history: {
      delay: 5000,
      maxStack: 300
    },
    toolbar: [
      [{ size: fontSizes }],
      ['bold', 'italic', 'underline'],
      [{ color: [] }, { background: [] }, 'link'],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      ['clean']
    ]
  }

  public componentDidMount = () => {
    const QuillAlignStyle = Quill.import('attributors/style/align')
    const QuillSize = Quill.import('attributors/style/size')
    QuillSize.whitelist = fontSizes
    const QuillBackgroundStyle = Quill.import('attributors/style/background')
    const QuillColorStyle = Quill.import('attributors/style/color')
    const QuillDirectionStyle = Quill.import('attributors/style/direction')
    const QuillFontStyle = Quill.import('attributors/style/font')

    Quill.register(QuillAlignStyle, true)
    Quill.register(QuillBackgroundStyle, true)
    Quill.register(QuillColorStyle, true)
    Quill.register(QuillDirectionStyle, true)
    Quill.register(QuillFontStyle, true)
    Quill.register(QuillSize, true)
  }

  public render() {
    const { html } = this.props

    const { animate, showEditor } = this.state

    return (
      <Container>
        <Text dangerouslySetInnerHTML={{ __html: html }} onClick={this.showEditor} />
        {showEditor && (
          <Modal
            content={
              <ModalContent>
                <ReactQuill
                  theme={'snow'}
                  value={html}
                  onChange={this.handleChange}
                  modules={this.modules}
                  placeholder=""
                />
                <CloseButton onClick={this.closeModal}>
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </CloseButton>
              </ModalContent>
            }
            className={animate ? 'zoomIn' : 'zoomOut'}
            closeModal={this.closeModal}
            width={700}
          />
        )}
      </Container>
    )
  }

  private closeModal = () => {
    this.setState({ animate: false })
    window.setTimeout(() => {
      this.setState({ showEditor: false })
    }, 300)
  }

  private showEditor = () => {
    const { isPaid, templateType } = this.props
    if (templateType === TemplateEnumType.Free) {
      this.setState({ showEditor: true, animate: true })
    } else if (isPaid) {
      this.setState({ showEditor: true, animate: true })
    } else {
      Toast({
        message: 'This feature is only available for paid subscriptions. Please upgrade to continue.',
        type: 'error'
      })
    }
  }

  private handleChange = (content: any, delta: any, source: any, editor: any) => {
    const { onChange } = this.props
    onChange(content, delta, source, editor)
  }
}

export default QuillInline
