import * as React from 'react'

import {
  Container,
  MemberLabel,
  Row,
  StyledInput,
} from './Styled'

interface Props {
  form: any,
  handleInputChange: (name: string, index: number, event: React.ChangeEvent<HTMLInputElement>) => void,
}

class TeamForm extends React.Component<Props> {

  public render() {
    return (
      <Container>
        <MemberLabel>Add members to your office</MemberLabel>
        {this.props.form.map((member: any, index: number) => {
          return (
            <Row key={member.key} className="team-member-row">
              <StyledInput
                placeholder="First Name"
                name={`member_${member.key}`}
                label={index + 1}
                labelPosition="left"
                fluid={true}
                value={member.firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange('firstName', index, event)}
                error={!!member.errors[`firstName`]}
              />
              <StyledInput
                placeholder="Last Name"
                name={`member_${member.key}`}
                value={member.lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange('lastName', index, event)}
                error={!!member.errors[`lastName`]}
              />
              <StyledInput
                placeholder={`Email`}
                name={`email_${member.key}`}
                value={member.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange('email', index, event)}
                error={!!member.errors[`email`]}
              />
            </Row>
          )
        })}
      </Container>
    )
  }
}

export default TeamForm
