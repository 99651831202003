import { Action } from 'redux'

export enum UserRoleEnum {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Leader = 'TEAM_LEADER',
  Agent = 'AGENT',
  Guest = 'GUEST'
}

export declare namespace User {
  export interface LoggedInType {
    _id: string
    firstName: string
    lastName: string
    role: UserRoleEnum
    userName: string
  }
}

export interface UsersItem {
  address: string
  commercialCap: number
  email: string
  firstName: string
  userName: string
  _id: string
  lastName: string
  office: Office[]
  phones: PhoneType[]
  profileImage: string
  residentialCap: number
  role: string
  status: string
  phone: PhoneType
  capSetting: Cap
  transactionFeeSetting: Cap
  teams: any
  individualResidentialCap: any
  individualCommercialCap: any
  userCap: UserCap
  showEditButton?: boolean
}

export interface UserCap {
  commercialCapStatus: string
  commercialCompletedCap: number
  commercialTargetCap: number
  residentialCapStatus: string
  residentialCompletedCap: 0
  residentialTargetCap: number
}
export interface Office {
  _id: string
  branchName: string
}

export interface Cap {
  _id: string
  name: string
}

export interface Options {
  key: string
  value: string
  text: string | null
}

export interface Filter {
  filter: boolean
  searchText: boolean
}

export interface FilterText {
  id: string
  content: string
  text: string
}

export interface FiltersData {
  city?: string | undefined
  email?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  officeName?: string | undefined
  state?: string | undefined
  status?: string | undefined
  zipCode?: string | undefined
}

export interface PhoneType {
  type: string
  value: string
}

export interface UserType {
  _id: string
  role: string
  profileImage: string
}

export interface UserGroup {
  description: string
  icon: string
  _id: string
  items: UsersItem[]
  name: string
}

export interface Filter {
  filter: boolean
  searchText: boolean
}

export interface Users {
  data: UserGroup[]
  currentlyDeleteCard: UsersItem | null
  boardId: string
  cardIndex: string
  showAddUserPopup: boolean
  showImportUsersPopup: boolean
  filter: Filter
  searchLoader: boolean
  searchData: any
}

// Actions
export interface AddNewBoardEvent extends Action {
  type: string
  payload: {
    index: number
    newData: UserGroup
  }
}

export interface GetBoardDataEvent extends Action {
  type: string
  payload: {
    data: UserGroup[]
  }
}

export interface AddNewCardEvent extends Action {
  type: string
  payload: {
    id: string
    index: string
    newData: UsersItem
  }
}

export interface ShowHidePopupEvent extends Action {
  type: string
}

export interface SetNewIcon extends Action {
  type: string
  payload: {
    id: string
    icon: string
  }
}

export interface SetNewDescription extends Action {
  type: string
  payload: {
    id: string
    description: string
  }
}

export interface SetNewBoardName extends Action {
  type: string
  payload: {
    id: string
    boardName: string
  }
}

export interface DeleteCard extends Action {
  type: string
  payload: {
    boardId: string
    cardId: string
  }
}

export interface RestoreCard extends Action {
  type: string
}

export interface ResetCardData extends Action {
  type: string
}

export interface UserFilter extends Action {
  type: string
  payload: {
    data: Filter
  }
}

export interface UserSerachLoader extends Action {
  type: string
  payload: {
    open: boolean
  }
}

export interface UserSearchData extends Action {
  type: string
  payload: {
    data: any
  }
}
