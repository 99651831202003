// Import Packages
import { TimelineEvent } from 'react-event-timeline'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollYContainer } from 'design/Styled'

interface Props {
  background: string
}

const Date = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -18px;

  span {
    margin-right: 5px;
    color: ${Colors.DarkBlue200};
  }
`

const TimeLineContainer = styled.div`
  ${scrollYContainer};
  height: 100%;
  flex: 1;
  overflow: auto;
  color: ${Colors.Black550};
  background: ${Colors.White1000};
  padding-left: 50px;

  &.notes-styling section > div:nth-child(1) {
    background: ${Colors.Grey100} !important;
    left: 16px !important;
    width: 1px !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) > span {
    align-self: auto !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) {
    width: 30px !important;
    height: 30px !important;
    background: ${Colors.DarkBlue205} !important;
    border: 1px solid ${Colors.Grey100} !important;
    justify-content: center !important;
    border: none !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) > span > i{
    font-size: 16px;
    margin-left: 4px;
    color: ${Colors.White1000};
    margin-top: -6px;
  }
`

const Container = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 14px;

  div {
    display: flex;
  }

  b {
    font-weight: bold;
    margin-right: 3px;
    color: ${Colors.Black500};
  }
`

const StyledTimelineEvent = styled(TimelineEvent)`
  background: white;
`

const Profile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  margin-top: -6px;
  margin-left: 5px;
  margin-right: 3px;
`

const Time = styled.div`
  position: absolute;
  left: -100px;
  top: 3px;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.DarkBlue200};
`

const Divider = styled.div`
  border-right: 1px solid ${Colors.Grey100};
  width: 40px;
  height: 26px;
`

const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.DarkBlue200};
  text-align: center;
  margin-top: 30px;

  ::before, ::after {
    display: inline-block;
    content: "";
    border-top: 1px solid ${Colors.DarkBlue200};
    width: 20px;
    margin: 0 1rem;
    transform: translateY(-0.3rem);
  }
`

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const People = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  cursor: pointer;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

export {
  Container,
  Divider,
  Date,
  Profile,
  StyledTimelineEvent,
  TimeLineContainer,
  StyledPopup,
  People,
  Time,
  Title,
  Wrapper,
}
