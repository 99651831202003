import { Form, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { scrollbars, x, y } from 'design/Styled'

import PngAddTicket from 'design/icons/dashboard/addPass.png'
import SvgTicket from 'design/icons/dashboard/vector_smart.svg'

export const Container = styled.div`
  margin-top: 20px;
  max-height: calc(100vh - 310px);
  overflow: auto;
  ${scrollbars};
`

export const Section = styled.section`
  ${y};
  margin-bottom: 1em;
`

export const Row = styled.div`
  ${x};
  align-items: flex-start;
  min-height: 150px;
`

export const PassName = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${Colors.DarkBlue205};
  font-weight: bold;
  margin-bottom: 15px;

  div:nth-child(1) {
    border-bottom: 1px solid ${Colors.Container};
    color: ${Colors.TextLight};
    padding-bottom: 8px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14);
  }

  .fa-chevron-down {
    margin-left: 15px;
    color: ${Colors.TextLight};
    width: 14px;
    height: 12px;
    margin-top: 3px;
    cursor: pointer;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14);
  }
`

export const AddSection = styled.div`
  font-weight: bold;
  color: ${Colors.TextLight};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  width: 15%;

  span {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14);
    white-space: nowrap;
  }

  .fa-plus {
    width: 12px;
    height: 16px;
    margin-right: 6px;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  align-items: baseline;

  &.ui.form .field .ui.input input,
  .ui.form .fields .field .ui.input input {
    border: none;
    box-shadow: none;
    background: transparent;
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.TextLight};

    ::placeholder {
      color: ${Colors.TextLight};
      font-size: 14px;
      font-weight: bold;
      text-shadow: 2px 2px #00000029;
    }
  }

  &.ui.mini.form {
    height: 70px;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue205} !important;
    color: ${Colors.TextLight} !important;
    margin-right: 20px;
  }
`

export const StyledPopup = styled(Popup)`
  .fa-trash-alt {
    margin-right: 10px;
    color: ${Colors.DarkBlue205};
  }

  .fa-pencil-alt {
    margin-right: 8px;
    color: ${Colors.DarkBlue205};
  }

  &.ui.bottom.left.popup {
    margin-left: -12px;
    padding: 15px;
    background: ${Colors.white605};
  }

  &.ui.bottom.left.popup:before {
    background: ${Colors.white605};
  }
`

export const Settings = styled.div`
  color: ${Colors.DarkBlue205};
  font-size: 12px;
  font-weight: 500;

  div:nth-child(1) {
    margin-bottom: 10px;
  }

  div {
    cursor: pointer;
  }
`

export const AddPass = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  height: 140px;
  background: url(${SvgTicket});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:before {
    display: flex;
    content: '';
    width: 70px;
    height: 70px;
    background: url(${PngAddTicket});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`
