// Import Packages
import { sortBy } from 'lodash'
import ConfirmAlert from 'sweetalert2'

// Import Components
import client, { cacheData } from 'queries/apollo'
import { Checked } from './CustomComponents'
import { reformatData, reformatListData } from './Utils/FormattingData'

import { OfficeItemsDetails } from 'store/Offices/Types'

// Import Utils
import { Strings } from 'utils'

// Import Graphql Schema Types
import { getStates } from 'queries/schema/schemaTypes'

// Import Graphql Mutations
import {
  CHANGE_OFFICE_STATUS,
  CREATE_OFFICE,
  DELETE_OFFICE,
  UPDATE_OFFICE,
  UPDATE_OFFICE_MANAGER,
  UPDATE_OFFICE_ORDER
} from 'queries/graphql/Offices/Mutation'
import { UPDATE_STATE_ORDER } from 'queries/graphql/States/Mutation'
import { TRANSFER_USER } from 'queries/graphql/Users/Mutation'

// Import Graphql Queries
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import { GET_STATES_OFFICES } from 'queries/graphql/States/Queries'
import { getBoardsOptions, getManagerBoardId } from './OfficeQueries'

export const ToggleCardStatus = (boardId: string, cardId: string, status: string) => {
  client.mutate({
    mutation: UPDATE_OFFICE,
    refetchQueries: [
      {
        query: GET_STATES_OFFICES,
        variables: { sortOrder: 1, orderField: 'officeOrder' }
      },
      { query: GET_OFFICES }
    ],
    variables: { id: cardId, isActive: true, status: status }
  })
}

export const ToggleListStatus = (cardId: string, status: string) => {
  client.mutate({
    mutation: UPDATE_OFFICE,
    refetchQueries: [{ query: GET_OFFICES }],
    update: (cache, { data }: any) => {
      const cachedata = cache.readQuery({ query: GET_OFFICES })
      const formatData = reformatListData(cachedata)
      const item = formatData.find((office: any) => {
        return office._id === cardId
      })
      item.status = status
      const newData = formatData.map((office: any) => {
        if (office._id === cardId) {
          return item
        }
        return office
      })

      cache.writeQuery({
        data: { getOffices: newData },
        query: GET_OFFICES
      })
    },
    variables: { id: cardId, isActive: true, status: status }
  })
}

export const transferOffice = async (newOfficeId: string, deleteOfficeId: string, show: boolean) => {
  await client.mutate({
    awaitRefetchQueries: true,
    mutation: DELETE_OFFICE,
    refetchQueries: [
      {
        query: GET_STATES_OFFICES,
        variables: { sortOrder: 1, orderField: 'officeOrder' }
      },
      { query: GET_OFFICES }
    ],
    update: (cache, { data }) => {
      if (show) {
        const cachedata = cache.readQuery({
          query: GET_STATES_OFFICES,
          variables: { sortOrder: 1, orderField: 'officeOrder' }
        }) as getStates
        if (cachedata && cachedata.getStates) {
          const stateData: any = []
          cachedata.getStates.forEach((states: any) => {
            const offices = states.offices.filter((office: any) => {
              return office._id !== deleteOfficeId
            })
            if (offices.length > 0) {
              stateData.push({
                ...states,
                offices
              })
            }
          })
          cacheData.writeQuery({
            data: { getStates: stateData },
            query: GET_STATES_OFFICES,
            variables: { sortOrder: 1, orderField: 'officeOrder' }
          })
        }
      }
    },
    variables: { deleteOfficeId, newOfficeId }
  })
  return getBoardsOptions()
}

export const deactivateOffice = (deleteOfficeId: string) => {
  const officeIds: any = []
  officeIds.push(deleteOfficeId)
  client.mutate({
    awaitRefetchQueries: true,
    mutation: CHANGE_OFFICE_STATUS,
    refetchQueries: [
      {
        query: GET_STATES_OFFICES,
        variables: { sortOrder: 1, orderField: 'officeOrder' }
      },
      { query: GET_OFFICES }
    ],
    variables: { officeIds: officeIds, status: 'Inactive' }
  })
}

export const addNewOffice = async (
  newOfficeData: OfficeItemsDetails,
  id: string,
  index: number,
  edit: boolean,
  show: boolean
) => {
  const { _id, branchName, city, manager, mls, mlsId, state, street, zipCode, phone, email } = newOfficeData

  const refetchQueries: any = [
    { query: GET_OFFICES },
    {
      query: GET_STATES_OFFICES,
      variables: {
        orderField: 'officeOrder',
        sortOrder: 1
      }
    }
  ]

  if (edit) {
    await client.mutate({
      awaitRefetchQueries: true,
      mutation: UPDATE_OFFICE,
      refetchQueries,
      variables: {
        branchName,
        city,
        email,
        id: _id,
        isActive: true,
        mls,
        mlsId,
        officeOrder: index,
        phone,
        state,
        status: 'Active',
        street,
        zipCode
      }
    })
    await client.mutate({
      mutation: UPDATE_OFFICE_MANAGER,
      refetchQueries,
      variables: {
        officeId: _id,
        userId: manager
      }
    })
    return getBoardsOptions()
  }

  const createOfficeMutation: any = {
    mutation: CREATE_OFFICE,
    refetchQueries,
    update: null,
    variables: {
      branchName,
      city,
      email,
      mls,
      mlsId,
      officeOrder: index,
      phone,
      state,
      street,
      zipCode
    }
  }

  if (show) {
    createOfficeMutation.update = (proxy: any, { data }: any) => {
      const proxyData: any = proxy.readQuery({
        query: GET_STATES_OFFICES,
        variables: {
          orderField: 'officeOrder',
          sortOrder: 1
        }
      })
      const newdata = reformatData(proxyData)
      const listColumn = newdata.getStates.find((list: any) => {
        return list._id === id
      })
      data.createOffice.users = Object.assign([])
      data.createOffice.officeOrder = index
      listColumn.offices.push(data.createOffice)
      const addedData = newdata.getStates.map((list: any) => {
        if (list._id === listColumn._id) {
          return listColumn
        }
        return list
      })
      sortBy(addedData.offices, ['officeOrder'])
      proxy.writeQuery({
        data: { getStates: addedData },
        query: GET_STATES_OFFICES,
        variables: {
          orderField: 'officeOrder',
          sortOrder: 1
        }
      })
    }
  } else {
    createOfficeMutation.update = (proxy: any, { data }: any) => {
      const proxyData: any = proxy.readQuery({ query: GET_OFFICES })
      const newdata = reformatListData(proxyData)
      data.createOffice.users = Object.assign([])
      newdata.push(data.createOffice)
      proxy.writeQuery({
        data: { getOffices: newdata },
        query: GET_OFFICES
      })
    }
  }

  const created = await client.mutate(createOfficeMutation)
  const office = created.data.createOffice
  const group = await getManagerBoardId()
  await client.mutate({
    mutation: TRANSFER_USER,
    refetchQueries,
    variables: {
      groupId: group._id,
      isPrimaryManager: true,
      officeId: [office._id],
      userId: manager
    }
  })

  return getBoardsOptions()
}

export const reorderCard = async (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
  client.mutate({
    mutation: UPDATE_OFFICE_ORDER,
    refetchQueries: [
      {
        query: GET_STATES_OFFICES,
        variables: { sortOrder: 1, orderField: 'officeOrder' }
      }
    ],
    variables: {
      board: boardId,
      cardId: cardId,
      destination: destinationIndex,
      source: sourceIndex
    }
  })
}

export const reorderLane = async (boardId: string, sourceIndex: number, destinationIndex: number) => {
  client.mutate({
    mutation: UPDATE_STATE_ORDER,
    refetchQueries: [
      {
        query: GET_STATES_OFFICES,
        variables: { sortOrder: 1, orderField: 'officeOrder' }
      },
      { query: GET_OFFICES }
    ],
    variables: {
      board: boardId,
      destination: destinationIndex,
      source: sourceIndex
    }
  })
}

export const changeOfficeStatus = async (status: string) => {
  const officeData = await client.query({ query: GET_OFFICES })
  const { data }: any = officeData
  const officeIds: any = []
  data.getOffices.forEach((office: any) => {
    if (office.isChecked === true) {
      officeIds.push(office._id)
    }
  })
  if (officeIds.length > 0) {
    const changeStatus = async () => {
      await client.mutate({
        mutation: CHANGE_OFFICE_STATUS,
        refetchQueries: [
          {
            query: GET_STATES_OFFICES,
            variables: { sortOrder: 1, orderField: 'officeOrder' }
          },
          { query: GET_OFFICES }
        ],
        variables: { officeIds: officeIds, status: status }
      })
    }
    if (status === 'Inactive') {
      await ConfirmAlert({
        cancelButtonText: Strings.kanbanView.noKeepItActive,
        confirmButtonText: Strings.kanbanView.yesMakeItInactive,
        showCancelButton: true,
        text: Strings.kanbanView.recoverOffices,
        title: Strings.kanbanView.sure,
        type: 'warning'
      }).then(async (result: any) => {
        if (result.value) {
          await changeStatus()
        }
      })
    } else {
      await changeStatus()
    }
    Checked(true)
  }
}
