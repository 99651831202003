import * as React from 'react'

import CreateModal from 'shared/Mls/CreateModal'
import { serverToast } from 'shared/Toast/Toast'

import { getVerifiedMlsList } from '../Queries'

import { Container, StyledDropdown } from './Styled'

import { MlsFilterType, MlsInputType, MlsType, OptionType } from '../Types'

interface Props {
  onUpdate: (value: string, mlsData: MlsType) => void
  state?: string
  value: string
  [inputProps: string]: any
}

interface State {
  list: MlsType[]
  modal: any
  options: OptionType[]
  optionsLocal: OptionType[]
}

class SearchInput extends React.Component<Props, State> {
  public state = {
    list: [] as MlsType[],
    modal: null,
    options: [] as OptionType[],
    optionsLocal: [] as OptionType[]
  }

  public componentDidMount = () => {
    this.handleGetMlsList()
  }

  public componentDidUpdate = (prev: Props) => {
    const { state } = this.props
    if (state !== prev.state) {
      this.handleGetMlsList()
    }
  }

  public render = () => {
    const { onUpdate, state, value, ...inputProps } = this.props

    const { modal, options } = this.state

    return (
      <Container className="rp-mls-search">
        <StyledDropdown
          options={options}
          search={true}
          selection={true}
          fluid={true}
          allowAdditions={true}
          value={value}
          onAddItem={this.handleAddition}
          onChange={this.handleChange}
          {...inputProps}
        />
        {modal}
      </Container>
    )
  }

  private handleAddition = async (e: React.SyntheticEvent, data: any) => {
    const { state } = this.props
    const mlsData: MlsInputType = {
      shortName: data.value,
      state
    }
    const modal = <CreateModal mlsInput={mlsData} onClose={this.closeModal} />
    this.setState({ modal })
  }

  private handleChange = (e: React.SyntheticEvent, { value }: any) => {
    const { onUpdate } = this.props
    const { list } = this.state
    const mls = list.find((item: MlsType) => item._id === value)
    if (!mls) {
      return
    }
    onUpdate(value, mls)
  }

  private handleGetMlsList = async () => {
    const { state } = this.props
    const { optionsLocal } = this.state
    const where = {} as MlsFilterType
    if (state) {
      where.state = state
    }

    let list: any
    try {
      list = await getVerifiedMlsList(where)
    } catch (error) {
      return serverToast(error)
    }

    let options: OptionType[] = list.map((mls: MlsType) => this.generateOption(mls))
    options = [...optionsLocal, ...options]

    this.setState({
      list,
      options
    })
  }

  private generateOption = (mls: MlsType) => {
    const { state } = this.props
    const stateText = state ? '' : `(${mls.state})`
    return {
      key: mls._id,
      text: `${mls.shortName} ${stateText}`,
      value: mls._id
    }
  }

  private closeModal = (mls: MlsType | null) => {
    const { onUpdate } = this.props
    this.setState({ modal: null })

    if (!mls) {
      return
    }

    const option: OptionType = this.generateOption(mls)

    this.setState((prev: State) => ({
      options: [option, ...prev.optionsLocal, ...prev.options],
      optionsLocal: [option, ...prev.optionsLocal]
    }))

    onUpdate(option.value, mls)
  }
}

export default SearchInput
