import Colors from 'design/Colors'
import { Icon, Popup, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

const LabelSegment = styled(Segment)`
  &.segment{
    align-items: center;
    background-color: ${Colors.Grey615} !important;
    display: flex;
    flex:1;
    justify-content: center;
    max-width: 20%;
    text-align: center;
    color: ${Colors.Black500};
    font-size: 12px !important;
    font-weight: 500;
  }
`

const StyledWrapper = styled(Segment.Group)`
  &.ui.horizontal.segments {
    background-color: ${Colors.White525} !important;
    height: 100px;
    border-radius: 0;
    border: none;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 20px;
  }
`

const ContentSegment = styled(Segment)`
  &.ui.segment {
    padding: 0px;
  }
  &.segment{
    display: flex;
    flex: 1;
    border: none !important;
  }
`

const StyledPopup = styled(Popup)`
  &.popup {
    z-index: 10001
  }
`

const StyledIcon = styled(Icon)`
  &.icon {
    height: unset;
    margin-left: 3px;
  }
`

export {
  ContentSegment,
  LabelSegment,
  StyledIcon,
  StyledPopup,
  StyledWrapper
}
