import { Form, Search } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

export const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

export const AddContactLeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35%;
  height: auto;
  background: ${Colors.Grey110};

  .ui.small.image {
    width: 250px;
  }
`

export const StyledIcon = styled.div`
  background: ${Colors.Black700};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50px;
  width: 70px;
`

export const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 15px;
  color: ${Colors.Black500};
`

export const Text = styled.div`
  width: 200px;
  text-align: center;
  margin-top: 5px;
  font-size: 10px;
  color: ${Colors.Black500};
`

export const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 55%;
`

export const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  .dollar>div {
    width: 10% !important;
    min-width: 25% !important;
  }

  .dollar>label {
    visibility: hidden;
  }

  .amount {
    width: 48%;
  }

  .external {
    width: 70%;
  }

  .ui.icon.input>i.icon {
    color: ${Colors.Black550};
  }

  .ui.selection.dropdown {
    min-width: 9.5em;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }

  .ui.search>.results {
    max-height: 180px;
    overflow: auto;
    ${scrollbars};
  }
`

export const StyledSearch = styled(Search)`
  &.ui.search {
    width: 70%;
  }

  .ui.icon.input>i.icon {
    display: none;
  }
`

export const CustomErrorDisplay=styled.div`
padding-left: .5em;
padding-right: .5em;
width: 100%;
flex: 1 1 auto;

.field, .field{
    width:100%;
    .selection.dropdown{
        width: 100% !important;
    }
}
.error-message {
    color: #9f3a38;
    font-size:10px;
}
`
