import Colors from 'design/Colors'
import { Grid } from 'semantic-ui-react'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'


const GraphSection = styled(Segment)`
  &.ui.segment {
    box-shadow: none;
    border: none;
    padding: 0px;
  }

  .ui.center.aligned.header, .ui.centered.header {
    font-size: 16px;
    font-weight: 600;
    color: ${Colors.DarkBlue200};
  }
`

const Content = styled(Segment)`
  &.ui.segment {
    box-shadow: none;
    border: none;
    margin: 0px;
    padding: 10px 20px;
  }
`

const Column = styled(Segment)`
  &.ui.segment {
    box-shadow: none;
    border: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.ui.segment:last-child {
    width: 70%;
    font-size: 12px;
    margin: 0 auto;
  }

  .ui.table tr td {
    color: ${Colors.Black500};
  }
`

const Section = styled(Segment)`
  &.ui.segment {
    box-shadow: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-align:  center;
  }
`

const TotalCommissionText = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${Colors.Black500};
  font-weight: 600;
`

const StyledTable = styled(Table)`
  &.ui.table tr td {
    border:none;
    padding: 5px 15px;
    width: 50%;
  }

  &.ui.table thead th {
    border:none;
    padding: 5px;
    width: 50%;
  }

  &.ui.basic.table {
    margin-top: 0;
  }

`
const StyledTableFooter = styled.div`
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: -8px;
  padding: 10px 0px;
  border-color: ${Colors.Black905};
  justify-content: space-evenly;
  display: flex;
  padding-top: 4px;
  color: ${Colors.Black500};
  font-weight: 600;
`

const FooterLabel = styled.div`
  width: 50%;
  padding: 2px;
`

const FooterValue = styled.div`
  width: 50%;
  padding: 2px 15px;
`


const PrincipalInputWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-color: ${Colors.Grey50};
  border-radius: 30px;
  padding-left: 10px;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  display: flex;
  overflow: hidden;
  align-items: center;
`

const PrincipalInput = styled.input`
  height: 100%;
  width: 100%;
  border-width: 0px;

  :focus{
    outline: none;
  }

  ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Cost = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${Colors.Black500};
`

const Graph = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .VictoryContainer {
    width: 65% !important;
  }
`

const VictoryLegend = styled.div`
  display: flex;
  font-size: 10px;
  color: ${Colors.Black500};

  .fa-rectangle-landscape {
    width: 30px;
    height: 15px;
  }
`

const StyledGrid = styled(Grid)`
  &.ui.grid {
    padding-left: 40px;
    padding-right: 40px;
  }
`

export {
  Column,
  Content,
  Cost,
  Graph,
  FooterLabel,
  FooterValue,
  GraphSection,
  PrincipalInput,
  PrincipalInputWrapper,
  Section,
  StyledTable,
  StyledTableFooter,
  TotalCommissionText,
  VictoryLegend,
  StyledGrid
}
