// Import Images and Icons
import ApperanceIcon1 from 'design/icons/tasks/apperance_icon_1.png'
import ApperanceIcon2 from 'design/icons/tasks/apperance_icon_2.png'
import ApperanceIcon3 from 'design/icons/tasks/apperance_icon_3.png'
import ApperanceIcon4 from 'design/icons/tasks/apperance_icon_4.png'
import ApperanceIcon5 from 'design/icons/tasks/apperance_icon_5.png'
import ApperanceIcon6 from 'design/icons/tasks/apperance_icon_6.png'
import ApperanceIcon7 from 'design/icons/tasks/apperance_icon_7.png'
import ApperanceIcon8 from 'design/icons/tasks/apperance_icon_8.png'
import ApperanceIcon9 from 'design/icons/tasks/apperance_icon_9.png'

export const Icons = [
  {
    url: ApperanceIcon1
  },
  {
    url: ApperanceIcon2
  },
  {
    url: ApperanceIcon3
  },
  {
    url: ApperanceIcon4
  },
  {
    url: ApperanceIcon5
  },
  {
    url: ApperanceIcon6
  },
  {
    url: ApperanceIcon7
  },
  {
    url: ApperanceIcon8
  },
  {
    url: ApperanceIcon9
  }
]

