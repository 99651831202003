// Import Packages
import * as React from 'react'
import { Button, Form, Input } from 'semantic-ui-react'

// Import Components
import { editDocTemplate } from 'app/Settings/ChecklistManager/ChecklistManagerMutation'
import Modal from 'shared/Modal'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Header,
  StyledForm
} from './Styled'

interface Props {
  documentIndex: number
  documentId: string
  editDocument: (result: string, index: number) => void
  handleClose: (toggle: boolean) => void
  value: string
}

interface State {
  result: string
  animate: boolean
}

export default class EditModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    result: ''
  }
  public componentDidMount() {
    this.setState({ result: this.props.value })
  }
  public handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ result: e.target.value })
  }
  public submitResult = () => {
    const { documentId, editDocument, documentIndex } = this.props
    const { result } = this.state
    editDocument(result, documentIndex)
    editDocTemplate(documentId, result)
    this.closeModal()
  }
  public render() {
    const { animate } = this.state
    return (
      <Modal content={
        <div>
          <Header>
            {Strings.generalText.editDocument}
          </Header>
          <StyledForm size={'mini'}>
            <Form.Field
              control={Input}
              name="state"
              label='Document'
              value={this.state.result}
              placeholder='Document'
              onChange={this.handleInputChange} />
            <Form.Field
              control={Button}
              className='save-button'
              onClick={this.submitResult}
              content={'SAVE'} />
          </StyledForm>
        </div>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={350} />
    )
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose(false)
    }, 300)
  }
}
