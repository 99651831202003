import * as React from 'react'
import Dropzone from 'react-dropzone'

import Toast from 'shared/Toast/Toast'
import MediaImage from 'design/icons/transactions/add_media.png'
import DocumentImage from 'design/icons/transactions/drop_zone.png'

import { Container, DragImage, DragImageContainer } from './Styled'

import { AcceptsEnum } from './Types'

interface Props {
  accepts: AcceptsEnum
  allowMultiple: boolean
  disableClick: boolean
  onDropEach: (file: File, index: number, total: number) => void
  showBorder: boolean
  showImage: boolean
  stagger: boolean
  disabled?: boolean
}

interface State {
  isDragOver: boolean
}

class DropZone extends React.Component<Props, State> {
  public static defaultProps = {
    accepts: AcceptsEnum.Any,
    allowMultiple: true,
    disableClick: false,
    showBorder: false,
    showImage: false,
    stagger: false
  }

  public state = {
    isDragOver: false
  }

  public render = () => {
    const { accepts, allowMultiple, children, disableClick, showBorder, showImage, disabled } = this.props
    const { isDragOver } = this.state

    return (
      <Container className="rp-dropzone-container" showBorder={showBorder} isDragOver={isDragOver}>
        <Dropzone
          accept={accepts}
          multiple={allowMultiple}
          activeClassName="drop-active"
          className="rp-dropzone"
          disableClick={disableClick}
          onDragEnter={this.handleDropzoneDragEnter}
          onDragLeave={this.handleDropzoneDrop}
          onDropAccepted={this.handleMultipleUploads}
          onDropRejected={this.handleRejection}
          rejectClassName="drop-reject"
          disabled={disabled}
        >
          <DragImageContainer className="rp-dropzone-image" showImage={showImage} isDragOver={isDragOver}>
            <DragImage src={accepts === AcceptsEnum.Image ? MediaImage : DocumentImage} />
          </DragImageContainer>
          {children}
        </Dropzone>
      </Container>
    )
  }

  private uploadAttachment = async (file: File, index: number, total: number) => {
    const { onDropEach } = this.props
    await onDropEach(file, index, total)
  }

  private handleDropzoneDragEnter = () => {
    this.setState({ isDragOver: true })
  }

  private handleDropzoneDrop = () => {
    this.setState({ isDragOver: false })
  }

  private handleMultipleUploads = async (files: File[]) => {
    this.handleDropzoneDrop()
    const { stagger } = this.props
    if (stagger) {
      await this.staggerUpload(files)
    } else {
      await this.parallelUpload(files)
    }
  }

  private handleRejection = async (files: File[]) => {
    const { accepts, allowMultiple } = this.props
    if (!allowMultiple && files.length > 1) {
      Toast({
        message: 'You can only upload 1 file',
        type: 'error'
      })
    }

    const rejectedTypes = files.filter((file: File) => !accepts.includes(file.type))
    if (rejectedTypes.length > 0) {
      rejectedTypes.forEach((file: File) => {
        Toast({
          message: `${file.name} is not the right type`,
          type: 'error'
        })
      })
      Toast({
        message: `Files must be of type ${accepts}.`,
        type: 'warning'
      })
    }
  }

  private staggerUpload = async (files: File[]) => {
    let index = 1
    const total = files.length
    for (const file of files) {
      await this.uploadAttachment(file, index, total)
      index++
    }
  }

  private parallelUpload = async (files: File[]) => {
    const total = files.length
    const promisedUploads = files.map((file: File, index: number) => this.uploadAttachment(file, index + 1, total))
    await Promise.all(promisedUploads)
  }
}

export default DropZone
