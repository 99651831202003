export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`feeName`]) {
    formIsValid = false
    errors[`feeName`] = true
  }

  if (!fields[`transactionType`]) {
    formIsValid = false
    errors[`transactionType`] = true
  }

  if (!fields[`amount`]) {
    formIsValid = false
    errors[`amount`] = true
  }

  return { errors, formIsValid }
}

