// Import Packages
import { Form, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background?: string
  space?: string
  left?: number
  right?: number
}

const StyledForm = styled(Form)`
  &.ui.form .field .ui.input input {
    border-radius: 2px;
  }

  &.ui.form .field .ui.input {
    width: 300px !important;
    font-size: 14px;
  }

  &.ui.form .fields {
    margin: 2px;
  }

  &.ui.form .fields, &.ui.form .field {
    font-size: 14px;
  }

  .ui.button {
    border-radius: 2px;
    font-size: 12px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

const StyledNotes = styled.div`
  width: 100%;
  overflow: auto;
  height: 390px;
  overflow-y: scroll;
  ${scrollbars};

  .add-notification {
    height: 394px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Profile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  min-width: 35px;
  height: 35px;
  align-self: center;
  border-radius: 50%;
  margin-bottom: 4px;
`

const Container = styled.div`
  border-bottom: 1px solid ${Colors.Black905};
  padding: 18px;
  display: flex;
  align-items: flex-start;

  .date, .icon {
    display: flex;
    align-items: center;
  }

  .description {
    width: 90%;
  }

  .edit {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-right: 6px;
  }

  .trash {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .feed-check {
    width: 16px;
    height: 16px;
    margin-left: 0px;
    cursor: pointer;
  }

  .check {
    width: 16px;
    height: 16px;
    margin-left: 0px;
    cursor: pointer;
  }

  .title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
  }
`

const Icons = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 8px;
  object-fit: contain;
  object-position: center;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
  }
`
const Title = styled.div`
  font-size: 16px;
  flex-grow: 1;
  text-align: justify;
  font-weight: 600;
  margin-right: 20px;
  user-select: none;
`

const Description = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: ${Colors.Black550};
  text-align: justify;
  margin-top: 14px;
  user-select: none;

  div {
    text-overflow: ellipsis;
    white-space: ${(props: Props) => props.space || 'normal'};
    overflow: hidden;
    margin-right: 10px;
  }

  i.icon {
    cursor: pointer;
    font-size: 1.4em;
  }
`

const Date = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: bold;
  width: 12%;
  color: ${Colors.Black500};
  justify-content: flex-end;
  white-space: nowrap;
`

const Wrapper = styled.div`
  display: flex;
`

const Discard = styled.div`
  height: 65px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid ${Colors.Grey120};
  background-color: ${Colors.Grey110};
  text-align: center;
  display: flex;
  justify-content: center;

  .ui.button {
    border: 2px solid ${Colors.Grey120};
    background: ${Colors.transparent};
  }
`

const NoAlerts = styled.div`
  height: 400px;
  min-height: 400px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div:nth-child(1) {
    font-size: 22px;
    font-weight: 600;
  }

  div:nth-child(2) {
    font-size: 16px;
    margin-top: 6px;
    color: ${Colors.Black550};
  }
`

const AddButton = styled.div`
  cursor: pointer;
  margin-top: 4px;

  .fa-plus {
    width: 35px;
    background-color: ${Colors.DarkBlue200};
    height: 35px;
    color: white;
    border-radius: 50%;
    padding: 10px;
    margin-right: 12px;
  }
`

export {
  AddButton,
  Container,
  Date,
  Description,
  Icons,
  Profile,
  StyledForm,
  StyledPopup,
  StyledNotes,
  Discard,
  Wrapper,
  Title,
  NoAlerts
}
