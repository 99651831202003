import client from 'queries/apollo'

import {
  CREATE_TRANSACTION_DOCUMENTS,
  CREATE_TRANSACTION_V2,
  CREATE_TRANSACTION_VIA_CONCIERGE
} from 'queries/graphql/Transactions/Mutation'
import { ConciergeIdxParamsType, ConciergeParamsType } from './Types'

export const createTransactionViaConcierge = async (params: ConciergeParamsType) => {
  const { file, office, token } = params
  const response = await client.mutate({
    context: {
      headers: {
        authorization: token
      }
    },
    mutation: CREATE_TRANSACTION_VIA_CONCIERGE,
    variables: { input: { office }, file }
  })

  return response.data.createTransactionViaConcierge
}

export const createTransactionViaConciergeIdx = async (token: string, input: ConciergeIdxParamsType) => {
  const response = await client.mutate({
    context: {
      headers: {
        authorization: token
      }
    },
    mutation: CREATE_TRANSACTION_V2,
    variables: { input }
  })

  return response.data.createTransaction
}

export const createTransactionDocument = async (files: File[], transactionId: string, token: string) => {
  const response = await client.mutate({
    context: {
      headers: {
        authorization: token
      }
    },
    mutation: CREATE_TRANSACTION_DOCUMENTS,
    variables: { transactionId, files }
  })

  return response.data.createDocument
}
