import {
  FormType,
  FormErrorType
} from './Types'

export const handleValidation = (form: FormType) => {
  let formIsValid = true
  const errors = {} as FormErrorType

  if (form.state < 0 || form.state > 100) {
    formIsValid = false
    errors.state = 'State tax must be between 0 and 100'
  }

  if (form.city < 0 || form.city > 100) {
    formIsValid = false
    errors.city = 'City tax must be between 0 and 100'
  }

  return {
    errors,
    formIsValid,
  }
}
