import { Action } from 'redux'
import { PaginationType } from 'store/Pagination/Types'

export enum DispatchEnum {
  SetUserPass = '@@SET_USER_PASS',
  SetUserPassDict = '@@SET_USER_PASS_DICT',
  SetUserPassList = '@@SET_USER_PASS_LIST',
  SetUserPassPagination = '@@SET_USER_PASS_PAGINATION',
}

export enum PassTypeEnum {
  Activity = 'Activity_Feed',
  ActivityHotSheet = 'Activity_HotSheet',
  Analytics = 'Analytics',
  Badges = 'Badges',
  Calender = 'Calender',
  Concierge = 'Concierge',
  Games = 'Games',
  General = 'General',
  Graphs = 'Graphs',
  Idx = 'Idx',
  Leads = 'Leads',
  Map = 'Map',
  Reports = 'Reports',
  Statistics = 'Statistics',
  TaskSheet = 'Task_Sheet',
  Transactions = 'Transactions',
  Trends = 'Trends',
  WebsiteSolution = 'Website_Solution',
}

export enum PassCategoryEnum {
  Free = 'Free',
  Paid = 'Paid'
}

export enum PassStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  PendingActivation = 'Pending Activation',
  PendingDeactivation = 'Pending Deactivation'
}

export declare namespace UserPassType {
  export interface Base {
    _id: string
    isActive: boolean
    isPlaceholder: boolean
    name: string
    order: number
    pass: Pass
    passCategory: PassCategoryEnum
    passStatus: boolean
    passType: PassTypeEnum
    price: number
    settings: Settings.Concierge.Base | Settings.Idx.Base | any
    timePeriod: number
    user: User
  }

  export interface Filter {
    _id: string
    custom: any
    isActive: boolean
    isPlaceholder: boolean
    isRequested: boolean
    name: string
    order: number
    pass: string
    passCategory: PassCategoryEnum
    passSection: string
    passStatus: boolean
    passType: PassTypeEnum
    price: string
    tenant: string
    timePeriod: number
    user: string
  }

  export interface Pagination extends PaginationType.Base {
    filter: Partial<UserPassType.Filter>
  }

  export interface Pass {
    _id: string
    name: string
    icon: string
  }

  export interface User {
    _id: string
    firstName: string
    lastName: string
    mlsId: string
  }

  export namespace Settings {
    export namespace Concierge {
      export interface Base {
        automaticTransaction: boolean
        additionalFee: number
      }
    }

    export namespace Idx {
      export interface Base {
        feedPassword: string
        feedUrl: string
        feedUsername: string
        mls: Mls
        status: PassStatusEnum
      }

      export interface Mls {
        _id: string
        shortName: string
        fullName: string
      }
    }

    export namespace WebsiteSolution {
      export interface Base {
        adminFee: number
        adminStatus: PassStatusEnum
        isPending: boolean
        mls: Mls
        mlsSubdomain: string
        office: Office
        officeSubdomain: string
        requestedAt: string
        status: PassStatusEnum
        template: string
      }

      export interface Office {
        _id: string
        branchName: string
        mlsId: string
      }

      export interface Mls {
        _id: string
        shortName: string
      }
    }
  }
}

export interface PaginatedUserPassType {
  results: UserPassType.Base[]
  pagination: UserPassType.Pagination
}

export interface UserPassDictType {
  [id: string]: UserPassType.Base
}

export interface UserPassState {
  dict: UserPassDictType
  list: string[]
  page: UserPassType.Pagination
}

export interface SetUserPassAction extends Action {
  payload: Partial<UserPassType.Base>
  type: DispatchEnum
}

export interface SetUserPassDictAction extends Action {
  payload: PaginatedUserPassType
  type: DispatchEnum
}

export interface SetUserPassListAction extends Action {
  payload: PaginatedUserPassType
  type: DispatchEnum
}

export interface SetUserPassPaginationAction extends Action {
  payload: UserPassType.Pagination
  type: DispatchEnum
}
