import * as React from 'react'
import OtpInput from 'react-otp-input'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import TokenRequestForm from 'app/Onboarding/TokenRequestForm'
import LoadingIndicator from 'shared/LoadingIndicator'
import { serverToast } from 'shared/Toast/Toast'

import { handleTokenLogin } from 'app/Onboarding/Mutations'

import { confirmBg, mail } from 'design/images/onboarding'
import { Routes } from 'utils'

import {
  Col,
  Container,
  Content,
  Highlight,
  Image,
  Resend,
  Text,
  Title,
} from './Styled'

import { MethodEnum } from 'app/Onboarding/TokenMethodToggle/Types'
import {
  FormErrorType,
  FormType,
} from 'app/Onboarding/TokenRequestForm/Types'
import {
  RouteParamsType,
  SubjectEnum,
} from 'app/Onboarding/Types'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'

interface Props extends RouteComponentProps<RouteParamsType, {}> { }

interface State {
  form: FormType
  loading: boolean
  sendText: string
  showResend: boolean
  token: string
}

class Confirmation extends React.Component<Props, State> {
  public state = {
    form: {
      email: '',
      errors: {} as FormErrorType,
      method: MethodEnum.Email,
      phone: '',
      subject: SubjectEnum.None,
    } as FormType,
    loading: true,
    sendText: `Check your email for a confirmation link.`,
    showResend: false,
    token: '',
  }

  public componentDidMount = async () => {
    const { state } = this.props.location
    const { params } = this.props.match

    const { form } = this.state
    let { token } = this.state

    if (params) {
      form.subject = params.subject || form.subject
      token = params.token || token
    }

    if (state) {
      form.email = state.email || form.email
      form.phone = state.phone || form.phone
      form.subject = state.subject || form.subject
      form.method = state.method || form.method
      token = state.token || token
    }

    const showResend = state && state.init

    this.setState({
      form,
      loading: false,
      showResend,
      token,
    }, async () => {
      if (!showResend) {
        await this.handleConfirmation()
        this.handleResend()
      }
    })

  }

  public render() {
    const {
      form,
      loading,
      sendText,
      showResend,
      token,
    } = this.state

    return (
      <React.Fragment>
        {loading && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
        {!loading && (
          <Container
            image={confirmBg}
            max={620}
          >
            <Content>
              {showResend ? (
                <Col>
                  <Title>Resend link?</Title>
                  <TokenRequestForm
                    form={form}
                    type={form.subject}
                    onSubmit={this.handleResend}
                    onUpdate={this.handleFormUpdate}
                  />
                  <Text>Enter the email address or phone number associated with your account.</Text>
                  <Text>Already confirmed? <Resend onClick={this.moveToLogin}>Login</Resend></Text>
                </Col>
              ) : (
                  <React.Fragment>
                    {form.method === MethodEnum.Email ? (
                      <Col>
                        <Image
                          src={mail}
                          max={200}
                        />
                        <Title>{sendText}</Title>
                        {form.email && <Highlight>{form.email}</Highlight>}
                        <Text>Please allow a few minutes for it to arrive.</Text>
                        <Text>Add&nbsp;<strong>noreply@realtypass.com</strong>&nbsp;to your address book.</Text>
                        <Text>Missed it? <Resend onClick={this.enableResend}>Send another</Resend></Text>
                      </Col>
                    ) : (
                      <Col>
                        <Title>{sendText}</Title>
                        {form.phone && <Highlight>{form.phone}</Highlight>}
                        <OtpInput
                          containerStyle={'rp-otp'}
                          numInputs={6}
                          onChange={this.handleOtpChange}
                          shouldAutoFocus={true}
                          value={token}
                        />
                        <Text>Please allow a few minutes for it to arrive.</Text>
                        <Text>Missed it? <Resend onClick={this.enableResend}>Send another</Resend><br /></Text>
                      </Col>
                    )}
                  </React.Fragment>
                )}
            </Content>
          </Container>
        )}
      </React.Fragment>
    )
  }

  private handleFormUpdate = (form: FormType) => {
    this.setState({ form })
  }

  private handleConfirmation = async () => {
    const {
      form: { method, subject },
      token,
    } = this.state

    if (!subject || !token) {
      return
    }

    try {
      await handleTokenLogin(subject, token)
      if (subject === SubjectEnum.Activate) {
        this.moveToTeamSize()
      } else if (subject === SubjectEnum.Invite || subject === SubjectEnum.Reset) {
        this.moveToReset()
      } else {
        throw new Error('An error occurred')
      }
    } catch (error) {
      serverToast(error)
      if (method === MethodEnum.Email) {
        this.setState({ showResend: true })
      } else {
        this.setState({ token: '' })
      }
    }
  }

  private handleOtpChange = (token: string) => {
    this.setState({ token }, () => {
      if (token.length === 6) {
        this.handleConfirmation()
      }
    })
  }

  private enableResend = () => {
    this.setState({ showResend: true })
  }

  private handleResend = () => {
    const { form } = this.state

    let sendText = ``
    if (form.method === MethodEnum.Sms) {
      sendText = form.phone ? `We've sent a code to:` : `Enter the code below`
    }

    if (form.method === MethodEnum.Email) {
      sendText = form.email ? `We've sent a link to:` : `Check your email for a confirmation link`
    }

    this.setState({
      sendText,
      showResend: false,
      token: '',
    })
  }

  private moveToReset = () => {
    const {
      form: {
        email,
        phone,
        method,
        subject,
      },
      token,
    } = this.state

    this.props.history.replace({
      pathname: Routes.onboarding.root + Routes.onboarding.resetPassword.path,
      state: {
        email,
        method,
        phone,
        subject,
        token,
      }
    })
  }

  private moveToTeamSize = () => {
    this.props.history.push({
      pathname: Routes.onboarding.root + Routes.onboarding.teamSize.path
    })
  }

  private moveToLogin = () => {
    this.props.history.replace({
      pathname: Routes.onboarding.root,
    })
  }
}

export default withRouter(Confirmation)
