// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface HeightProps {
  height: number
}

const VerticalLinesWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: calc(17.1rem + 30px);
  bottom: 0px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  pointer-events: none;
  z-index: 1000005;
`

const Line = styled.div`
  width: 1px;
  height: ${(props: HeightProps) => props.height + 'px'};
  background-color: ${Colors.Grey300};
  margin-right: 49px;
`

export { Line, VerticalLinesWrapper }
