// Import Packages
import { cloneDeep, sortBy } from 'lodash'

import Colors from 'design/Colors'
import { getLoggedInUser } from 'utils'

import { ListViewTransactionType } from 'app/Transactions/Dashboard/Types'
import { TransactionType } from 'app/Transactions/Details/Types'
import { UserType } from 'store/Transactions/Types'

const boardBorderColors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Grey800}`,
  `${Colors.Green560}`
]

const defaultTransactionBoard = [
  'New',
  'Updated',
  'Active',
  '$$ Received',
  'Payable',
  'Closed',
  'Failed'
]

export const addTransactionsToBoards = (transactions:any, boards:any) => {
  const transactionsPerBoard: any = boards.reduce((obj: any, board:any) => {
    obj[board.name] = []
    return obj
  }, {})

  transactions.forEach((transaction:any) => {
    const key = transaction.status
    transactionsPerBoard[key].push(transaction)
  })

  boards = boards.map((board:any) => {
    const key = board.name
    return {
      ...board,
      transactions: transactionsPerBoard[key]
    }
  })

  return boards
}

export const formatKanbanData = async (transactionBoardData: any, toggle?: boolean) => {
  let user: any = localStorage.getItem('user')
    ? localStorage.getItem('user')
    : '{}'
  user = JSON.parse(user)
  const transactionBoards = cloneDeep(transactionBoardData)
  for (const board of transactionBoards) {
    board.color = getRandomColors()

    board.items = Object.assign([])
    if (board.transactions) {
      board.items = Object.assign(board.transactions)
    }

    board.showAddButton = false
    if (board.name === 'New' && user.role !== 'GUEST' && (toggle || toggle === undefined)) {
      board.showAddButton = true
    }

    board.showAppearance = true
    if (defaultTransactionBoard.includes(board.name)) {
      board.showAppearance = false
    }
  }
  return sortBy(transactionBoards, ['boardOrder'])
}

export const formatListData = async (transactions: TransactionType[]) => {
  const user: UserType = await getLoggedInUser({ fromCache: true }) || {} as UserType
  const list: TransactionType[] = cloneDeep(transactions)

  const result: ListViewTransactionType[] = list.map((transaction: TransactionType) => {
    return {
      ...transaction,
      showAddButton: true,
      showEditButton: transaction.owner._id === user._id,
      checked: false,
      color: getRandomColors(),
    }
  })
  return result
}

const getRandomColors = () => {
  return boardBorderColors[Math.floor(Math.random() * boardBorderColors.length)]
}
