import * as React from 'react'

import {
  Container,
  Label,
  StyledToggle,
  ToolTip,
  Col,
} from './Styled'

import { OptionType } from './Types'

interface Props {
  monochrome: boolean | number | string
  name: string
  onChange: (e:any, data: any) => void
  options: OptionType[]
  showLabels: boolean
  tooltip: string
  value: string
}

class ToggleButton extends React.Component<Props, {}> {
  public static defaultProps = {
    monochrome: true,
    showLabels: true,
    tooltip: '',
  }

  public render = () => {
    const {
      options,
      value,
      name,
      monochrome,
      showLabels,
      tooltip,
    } = this.props

    const isChecked = value === options[1].value

    const Toggle = (
      <StyledToggle
        checked={isChecked}
        monochrome={monochrome}
        name={name}
        onChange={this.handleChange}
        toggle={true}
        value={value}
      />
    )

    return (
      <Container>
        {showLabels ? (
          <React.Fragment>
            <Label>{options[0].label}</Label>
            {Toggle}
            <Label>{options[1].label}</Label>
          </React.Fragment>
        ) : (
          <ToolTip
            position='top center'
            content={(
              <Col>
                {tooltip && <span>{tooltip}</span>}
                <span>
                  {!isChecked ? (
                    <strong>{options[0].label}</strong>
                  ) : options[0].label}
                  &nbsp;/&nbsp;
                  {isChecked ? (
                    <strong>{options[1].label}</strong>
                  ) : options[1].label}
                </span>
              </Col>
            )}
            trigger={Toggle}
          />
        )}
      </Container>
    )
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
    const { onChange, options } = this.props
    data.value = data.value !== options[0].value ? options[0].value : options[1].value
    onChange(e, data)
  }
}

export default ToggleButton
