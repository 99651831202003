// Import Packages
import * as QueryString from 'query-string'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Input } from 'semantic-ui-react'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'

import { StyledForm } from '../Styled'
interface Options {
  key: string
  text: string
  value: string
}

interface StoreProps {
  setFilter: (data: any) => void
  setChangeKey: (key: number) => void
  setResetFilterData: (reset: boolean) => void
  filter: any
  key: number
  resetData: boolean
}

interface OwnProps {
  categoryId: string
  report: string
}

type Props = StoreProps & OwnProps & RouteComponentProps<{}, {}>

interface State {
  activeIndex: string
  searchData: any
  cities: Options[]
  states: Options[]
  selectedCity: Options
  selectedState: Options
}

class TeamContent extends React.Component<Props, State> {
  public state = {
    activeIndex: '',
    cities: [],
    searchData: {},
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: []
  }

  public async componentDidMount() {
    const { setFilter, filter, setChangeKey, location } = this.props
    const query = QueryString.parse(location.search, { parseNumbers: true })
    if (query.team && !filter[`name`]) {
      this.setState(
        {
          searchData: {
            ...filter,
            name: query.team
          }
        },
        () => setFilter(this.state.searchData)
      )
    }
    setChangeKey(0)
  }

  public componentWillReceiveProps(nextProps: any) {
    const { setChangeKey, resetData } = this.props
    if (resetData ? resetData : nextProps.resetData) {
      this.setState({
        searchData: {},
        selectedCity: {
          key: '',
          text: '',
          value: ''
        },
        selectedState: {
          key: '',
          text: '',
          value: ''
        }
      })
      setChangeKey(1)
    }
  }

  public render() {
    const { report, key, filter } = this.props
    return (
      <div key={key}>
        <StyledForm size={'tiny'}>
          {report === 'Team Name' && (
            <Form.Field
              control={Input}
              label="Team Name"
              name="name"
              value={filter[`name`]}
              placeholder="Team Name"
              onChange={this.handleChange}
            />
          )}
          {report === 'Office Name' && (
            <Form.Field
              control={Input}
              label="Office Name"
              name="officeName"
              value={filter[`officeName`]}
              placeholder="Office Name"
              onChange={this.handleChange}
            />
          )}
          {report === 'Leader Name' && (
            <div>
              <Form.Field
                control={Input}
                label="First Name"
                name="firstName"
                value={filter[`firstName`]}
                placeholder="First Name"
                onChange={this.handleChange}
              />
              <Form.Field
                control={Input}
                label="Last Name"
                name="lastName"
                value={filter[`lastName`]}
                placeholder="Last Name"
                onChange={this.handleChange}
              />
            </div>
          )}
        </StyledForm>
      </div>
    )
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { setFilter, filter } = this.props
    if (name === 'firstName') {
      this.setState(
        {
          searchData: {
            ...filter,
            firstName: value
          }
        },
        () => setFilter(this.state.searchData)
      )
    } else if (name === 'lastName') {
      this.setState(
        {
          searchData: {
            ...filter,
            lastName: value
          }
        },
        () => setFilter(this.state.searchData)
      )
    } else if (name === 'officeName') {
      this.setState(
        {
          searchData: {
            ...filter,
            officeName: value
          }
        },
        () => setFilter(this.state.searchData)
      )
    } else {
      this.setState(
        {
          searchData: {
            ...filter,
            name: value
          }
        },
        () => setFilter(this.state.searchData)
      )
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.reports.filter,
  key: state.reports.key,
  resetData: state.reports.resetData
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setChangeKey: Actions.changeKey,
      setFilter: Actions.getFilter,
      setResetFilterData: Actions.resetFilterData
    }
  )(TeamContent)
)
