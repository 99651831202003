import * as React from 'react'
import {
  SliderContainer,
  SliderGroupContent,
  SliderMaxRange,
  SliderMinRange,
  SliderRangeContainer,
  SliderTitle,
  StyledOutput,
  StyledSlider
} from './Styled'

const SliderGroup = ({
  disabled,
  max,
  min,
  percentType,
  title,
  value,
  width,
  onChange
}: {
  disabled: boolean
  max: number
  min: number
  percentType?: boolean
  title: string
  value: number
  width?: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <SliderGroupContent width={width}>
      <SliderTitle>{title}</SliderTitle>
      <SliderContainer>
        <StyledSlider type="range" name={`slider${title}`} min={min} max={max} value={value} onChange={onChange} step={'25000'} disabled={disabled} />
        <StyledOutput value={value} min={min} max={max}>{percentType ? `${value}%` : `$${value}`}</StyledOutput>
      </SliderContainer>
      <SliderRangeContainer>
        <SliderMinRange>{`${min / 1000000}M`}</SliderMinRange>
        <SliderMaxRange>{`${max / 1000000}M`}</SliderMaxRange>
      </SliderRangeContainer>
    </SliderGroupContent>
  )
}

export default SliderGroup
