import gql from 'graphql-tag'

export const CREATE_CONTACT_BOARD = gql`
  mutation createContactBoard($name: String, $user: String, $order: Int) {
    createContactBoard(
      input: { name: $name, user: $user, boardOrder: $order }
    ) {
      _id
      name
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description

      isActive
      boardOrder
      contacts {
        _id
        firstName
        lastName
        phone
        email
        source
        isActive
        type
        teamContactOrder
        officeContactOrder
        tenantContactOrder
        createdAt
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
      }
    }
  }
`

export const UPDATE_CONTACT_BOARD_ORDER = gql`
  mutation updateContactBoardOrder(
    $boardId: ID
    $sourceIndex: Int
    $destinationIndex: Int
    $userId: String
  ) {
    updateContactBoardOrder(
      where: { _id: $boardId, boardOrder: $sourceIndex, user: $userId }
      input: { boardOrder: $destinationIndex }
    ) {
      _id
      name
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description

      isActive
      boardOrder
      contacts {
        _id
        firstName
        lastName
        phone
        email
        source
        isActive
        type
        teamContactOrder
        officeContactOrder
        tenantContactOrder
        createdAt
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
      }
    }
  }
`

export const DELETE_CONTACT_BOARD = gql`
  mutation deleteContactBoard($boardId: String) {
    deleteContactBoard(_id: $boardId)
  }
`

export const UPDATE_CONTACT_BOARD = gql`
  mutation updateContactBoard(
    $boardId: ID
    $boardName: String
    $icon: String
    $description: String
  ) {
    updateContactBoard(
      where: { _id: $boardId }
      input: { name: $boardName, icon: $icon, description: $description }
    ) {
      _id
      name
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description

      isActive
      boardOrder
      contacts {
        _id
        firstName
        lastName
        phone
        email
        source
        isActive
        type
        teamContactOrder
        officeContactOrder
        tenantContactOrder
        createdAt
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
      }
    }
  }
`
