import gql from 'graphql-tag'

export const GET_LEAD_BOARDS = gql`
  query getLeadBoards(
    $id: String
    $orderField: String
    $sortOrder: Int
    $userId: String
    $filter: LeadSearch
    $userPassFilter: UserPassLeadFilter
    $singleSkip: Int
  ) {
    getLeadBoards(
      where: { isActive: true, user: $userId }
      sorting: { field: $orderField, order: $sortOrder }
      filter: $filter
      _id: $id
      singleSkip: $singleSkip
      userPassFilter: $userPassFilter
    ) {
      _id
      name
      boardOrder
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      leads {
        _id
        firstName
        lastName
        email
        phone
        propertyType
        leadMaps {
          boardOrder
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
        activities {
          _id
        }
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
        leadScore
        createdAt
        zipCode
        tag
        isActive
        source
        status
        type
      }
    }
  }
`

export const GET_LEAD_BOARD_LEADS = gql`
  query getLeadBoardLeads(
    $id: String
    $orderField: String
    $sortOrder: Int
    $userId: String
    $filter: LeadSearch
    $boardId: String
    $singleSkip: Int
  ) {
    getLeadBoards(
      where: { state: $id, isActive: true, user: $userId }
      sorting: { field: $orderField, order: $sortOrder }
      filter: $filter
      _id: $boardId
      singleSkip: $singleSkip
    ) {
      _id
      name
      boardOrder
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      leads {
        _id
        firstName
        lastName
        email
        phone
        propertyType
        leadMaps {
          boardOrder
        }
        city {
          _id
          name
        }
        activities {
          _id
        }
        state {
          _id
          name
        }
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
        leadScore
        createdAt
        zipCode
        tag
        isActive
        source
        status
        type
      }
    }
  }
`

export const GET_SORTED_LEAD_BOARDS = gql`
  query getSortedLeadBoards(
    $orderField: String
    $sortOrder: Int
    $leadOrderField: String
    $sortLeadOrder: Int
    $id: String
    $userId: String
  ) {
    getLeadBoards(
      where: { isActive: true, user: $userId }
      sorting: { field: $orderField, order: $sortOrder }
      _id: $id
      singleSorting: { field: $leadOrderField, order: $sortLeadOrder }
    ) {
      _id
      name
      boardOrder
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      leads {
        _id
        firstName
        lastName
        email
        phone
        propertyType
        leadMaps {
          boardOrder
        }
        city {
          _id
          name
        }
        activities {
          _id
        }
        state {
          _id
          name
        }
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
        leadScore
        createdAt
        zipCode
        tag
        isActive
        source
        status
        type
      }
    }
  }
`
