// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import { GET_WORKFLOW_CATEGORY } from 'queries/graphql/Settings/WorkflowManager/WorkflowCategory/Queries'
import { GET_WORKFLOW_TASK } from 'queries/graphql/Settings/WorkflowManager/WorkflowTask/Queries'

export const getWorkflowTasks = async () => {
  const response = await client.query({
    query: GET_WORKFLOW_TASK
  })

  return response.data.getWorkflowTasks
}

export const getWorkflowCategory = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_WORKFLOW_CATEGORY
  })

  return response.data.getWorkflowCategories
}

export const getWorkflowCategoryTasks = async (workflowId: string, name?: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_WORKFLOW_TASK,
    variables: { workflowId, name: name ? name : undefined }
  })

  return response.data.getWorkflowTasks
}
