// Import Graphql Mutations
import {
  CREATE_CONTACT_BOARD,
  DELETE_CONTACT_BOARD,
  UPDATE_CONTACT_BOARD,
  UPDATE_CONTACT_BOARD_ORDER
} from 'queries/graphql/ContactBoard/Mutations'
import {
  CREATE_CONTACT,
  CREATE_CONTACT_NOTES,
  DELETE_CONTACT,
  DELETE_CONTACT_NOTES,
  DELETE_SHARE_WITH,
  EDIT_CONTACT_NOTES,
  IMPORT_CONTACTS,
  MOVE_CONTACT,
  SHARE_WITH,
  UPDATE_CONTACT,
  UPDATE_CONTACT_ORDER
} from 'queries/graphql/Contact/Mutation'

import { ContactListDetails, ShareWith, UserType } from 'store/Contacts/Types'

// Import Graphql Queries
import { GET_SORTED_CONTACT_BOARDS } from 'queries/graphql/ContactBoard/Queries'

// Import Components
import client from 'queries/apollo'

export const createContact = async (
  newContact: ContactListDetails,
  addContactIndex: string,
  addBoardId: string,
  show: boolean,
  user: UserType,
  filter: boolean,
  edit: boolean
) => {
  const { firstName, lastName, email, phone, source, contactBoard, type, _id } = newContact
  if (show) {
    return await client.mutate({
      mutation: CREATE_CONTACT,
      variables: {
        contactBoard: filter === false || user.role === 'AGENT' ? addBoardId : undefined,
        contactOrder: filter === false || user.role === 'AGENT' ? parseInt(addContactIndex) : undefined,
        email,
        firstName,
        lastName,
        officeContactBoard: filter !== false && user.role === 'MANAGER' ? addBoardId : undefined,
        officeContactOrder: filter !== false && user.role === 'MANAGER' ? parseInt(addContactIndex) : undefined,
        phone,
        source,
        teamContactBoard: filter !== false && user.role === 'TEAM_LEADER' ? addBoardId : undefined,
        teamContactOrder: filter !== false && user.role === 'TEAM_LEADER' ? parseInt(addContactIndex) : undefined,
        tenantContactBoard: filter !== false && user.role === 'ADMIN' ? addBoardId : undefined,
        tenantContactOrder: filter !== false && user.role === 'ADMIN' ? parseInt(addContactIndex) : undefined,
        type
      }
    })
  } else {
    if (edit) {
      return client.mutate({
        mutation: MOVE_CONTACT,
        variables: {
          contactBoard: filter === false || user.role === 'AGENT' ? contactBoard : undefined,
          contactId: _id,
          email,
          firstName,
          lastName,
          officeContactBoard: filter !== false && user.role === 'MANAGER' ? contactBoard : undefined,
          phone,
          source,
          teamContactBoard: filter !== false && user.role === 'TEAM_LEADER' ? contactBoard : undefined,
          tenantContactBoard: filter !== false && user.role === 'ADMIN' ? contactBoard : undefined,
          type
        }
      })
    } else {
      return await client.mutate({
        mutation: CREATE_CONTACT,
        variables: {
          contactBoard: filter === false || user.role === 'AGENT' ? contactBoard : undefined,
          email,
          firstName,
          lastName,
          officeContactBoard: filter !== false && user.role === 'MANAGER' ? contactBoard : undefined,
          phone,
          source,
          teamContactBoard: filter !== false && user.role === 'TEAM_LEADER' ? contactBoard : undefined,
          tenantContactBoard: filter !== false && user.role === 'ADMIN' ? contactBoard : undefined,
          type
        }
      })
    }
  }
}

export const moveContact = async (
  contactId: string,
  contactBoardId: string,
  destinationIndex: number,
  user: UserType,
  filter: boolean
) => {
  client.mutate({
    mutation: MOVE_CONTACT,
    variables: {
      contactBoard: filter === false || user.role === 'AGENT' ? contactBoardId : undefined,
      contactId,
      contactOrder: filter === false || user.role === 'AGENT' ? destinationIndex : undefined,
      officeContactBoard: filter !== false && user.role === 'MANAGER' ? contactBoardId : undefined,
      officeContactBoardOrder: filter !== false && user.role === 'MANAGER' ? destinationIndex : undefined,
      teamContactBoard: filter !== false && user.role === 'TEAM_LEADER' ? contactBoardId : undefined,
      teamContactBoardOrder: filter !== false && user.role === 'TEAM_LEADER' ? destinationIndex : undefined,
      tenantContactBoard: filter !== false && user.role === 'ADMIN' ? contactBoardId : undefined,
      tenantContactBoardOrder: filter !== false && user.role === 'ADMIN' ? destinationIndex : undefined
    }
  })
}

export const deleteContact = (contactListId: string[]) => {
  client.mutate({
    mutation: DELETE_CONTACT,
    variables: { contactId: contactListId }
  })
}

export const restore = (cardId: string) => {
  client.mutate({
    mutation: UPDATE_CONTACT,
    variables: { cardId, isActive: true }
  })
}

export const reorderCard = async (
  contactBoardId: string,
  cardId: string,
  sourceIndex: number,
  destinationIndex: number,
  user: UserType,
  filter: boolean
) => {
  client.mutate({
    mutation: UPDATE_CONTACT_ORDER,
    variables: {
      cardId,
      contactBoard: filter === false || user.role === 'AGENT' ? contactBoardId : undefined,
      contactDestinationBoardOrder: filter === false || user.role === 'AGENT' ? destinationIndex : undefined,
      contactSourceBoardOrder: filter === false || user.role === 'AGENT' ? sourceIndex : undefined,
      destinationIndex,
      officeContactBoard: filter !== false && user.role === 'MANAGER' ? contactBoardId : undefined,
      officeDestinationContactBoard: filter !== false && user.role === 'MANAGER' ? destinationIndex : undefined,
      officeSourceContactBoard: filter !== false && user.role === 'MANAGER' ? sourceIndex : undefined,
      teamContactBoard: filter !== false && user.role === 'TEAM_LEADER' ? contactBoardId : undefined,
      teamDestinationContactBoard: filter !== false && user.role === 'TEAM_LEADER' ? destinationIndex : undefined,
      teamSourceContactBoard: filter !== false && user.role === 'TEAM_LEADER' ? sourceIndex : undefined,
      tenantContactBoard: filter !== false && user.role === 'ADMIN' ? contactBoardId : undefined,
      tenantDestinationContactBoard: filter !== false && user.role === 'ADMIN' ? destinationIndex : undefined,
      tenantSourceContactBoard: filter !== false && user.role === 'ADMIN' ? sourceIndex : undefined
    }
  })
}

export const addNewContactBoard = (index: number, name: string, user: UserType) => {
  return client.mutate({
    mutation: CREATE_CONTACT_BOARD,
    variables: {
      name: name ? name : 'New Board',
      order: index,
      user: user._id
    }
  })
}

export const deleteContactBoard = (boardId: string, user: UserType) => {
  return client.mutate({
    mutation: DELETE_CONTACT_BOARD,
    variables: {
      boardId
    }
  })
}

export const updateContactBoard = async (boardId: string, value: string, field: string) => {
  await client.mutate({
    mutation: UPDATE_CONTACT_BOARD,
    variables: {
      boardId,
      boardName: field === 'name' ? value : undefined,
      description: field === 'description' ? value : undefined,
      icon: field === 'icon' ? value : undefined
    }
  })
}

export const reorderLane = async (
  boardId: string,
  sourceIndex: number,
  destinationIndex: number,
  user: UserType,
  filter: boolean
) => {
  await client.mutate({
    mutation: UPDATE_CONTACT_BOARD_ORDER,
    variables: {
      boardId,
      destinationIndex,
      sourceIndex,
      userId: filter === false ? user._id : undefined
    }
  })
}

export const sortBoardContact = async (boardId: string, sortOrder: number, user: UserType, filterOptions: boolean) => {
  if (filterOptions !== false) {
    await client.query({
      fetchPolicy: 'network-only',
      query: GET_SORTED_CONTACT_BOARDS,
      variables: {
        contactOrderField: 'firstName',
        id: boardId,
        orderField: 'boardOrder',
        sortContactOrder: sortOrder,
        sortOrder: 1
      }
    })
  } else {
    await client.query({
      fetchPolicy: 'network-only',
      query: GET_SORTED_CONTACT_BOARDS,
      variables: {
        contactOrderField: 'firstName',
        id: boardId,
        orderField: 'boardOrder',
        sortContactOrder: sortOrder,
        sortOrder: 1,
        userId: user._id
      }
    })
  }
}

export const updateContactDetails = async (contactDetails: ContactListDetails) => {
  const {
    _id,
    address,
    firstName,
    genderPrefix,
    lastName,
    source,
    phone,
    pets,
    children,
    married,
    dateOfBirth,
    anniversaryDate
  } = contactDetails
  return await client.mutate({
    mutation: UPDATE_CONTACT,
    variables: {
      address,
      anniversaryDate,
      children,
      contactId: _id,
      dateOfBirth,
      firstName,
      genderPrefix,
      lastName,
      married,
      pets,
      phone,
      source
    }
  })
}

export const shareWithUser = async (contactId: string, shareWithObject: ShareWith) => {
  const response = await client.mutate({
    mutation: SHARE_WITH,
    variables: { contactId: contactId, ...shareWithObject }
  })
  return response.data.sharedContactWith.shareWith
}

export const deleteShareWithUser = async (userId: string, contactId: string) => {
  const response = await client.mutate({
    mutation: DELETE_SHARE_WITH,
    variables: { contactId: contactId, userId: userId }
  })
  return response.data.deleteSharedContactWith.shareWith
}

export const createContactNotes = async (contactId: string, description: string) => {
  const res = await client.mutate({
    mutation: CREATE_CONTACT_NOTES,
    variables: { contactId, description }
  })
  return res.data.createNote
}

export const editContactNotes = async (contactNoteId: string, description: string) => {
  const res = await client.mutate({
    mutation: EDIT_CONTACT_NOTES,
    variables: { contactNoteId, description }
  })
  return res.data.updateNote
}

export const deleteContactNotes = async (contactNoteId: string) => {
  await client.mutate({
    mutation: DELETE_CONTACT_NOTES,
    variables: { contactNoteId }
  })
}

export const importContacts = async (groupid: string, file: any, filterOptions: boolean, user: UserType) => {
  const res = await client.mutate({
    mutation: IMPORT_CONTACTS,
    variables: {
      contactBoard: filterOptions === false || user.role === 'AGENT' ? groupid : undefined,
      file,
      officeContactBoard: filterOptions !== false && user.role === 'MANAGER' ? groupid : undefined,
      teamContactBoard: filterOptions !== false && user.role === 'TEAM_LEADER' ? groupid : undefined,
      tenantContactBoard: filterOptions !== false && user.role === 'ADMIN' ? groupid : undefined
    }
  })
  return res.data.importContacts
}
