// Import Packages
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { fontStyle } from 'design/Fonts'

interface ImageProps {
  backgroundImage: string
}

const StyledCard = styled(Card)`
  .ui.cards>&.card {
    width: 285px;
    margin: .875em 1.25em;
    border-radius: 1px;
    border-color: ${Colors.Black850};
    border-width: 1px;
    border-style: solid;
    box-shadow: none;
  }
`

const ProfileProgressContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  height: 65px;
  width: 65px;
  // background-color: gray;
  &.CommercialProgress {

  }
`

const StyledButton = styled(Button)`
  &.ui.icon.button {
    background-color: transparent;
    padding: 0px;
    font-size: 20px;
    color: ${Colors.Black550};
    position: relative;
  }
`

const CardContent = styled(Card.Content)`
  .ui.card>&.content, .ui.cards>.card>&.content {
    padding: 1em 1em 0.75em;
  }
`

const CardFooter = styled(Card.Content)`
  .ui.card>&.extra, .ui.cards>.card>&.extra {
    height: 40px;
    padding: 0.5em 0.75em;
  }
`

const CardHeader = styled(Card.Header)`
  .ui.card>.content>.header:not(.ui), .ui.cards>.card>.content>&.header:not(.ui){
    font-size: 14px;
    margin-bottom: 10px;
    color: ${Colors.Black500};
  }
`

const CardMeta = styled(Card.Meta)`
  .ui.card &.meta, .ui.cards>.card &.meta {
    font-size: 12px;
    font-family: ${fontStyle};
    display: flex;
    margin: 3px 0px;
    color: ${Colors.Black550};
    align-items: center;
    flex-grow: 1;
    width: 165px;
  }
`

const IconContainer = styled.div`
  padding: 0px;
  padding-right: 8px;
`

const CardImage = styled(Image)`
  .ui.card .content img, .ui.cards>.card .content & {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 9px;
  }

  &.ui.tiny.image {
    width: 65px;
    height: 65px;
  }
`

const UpdatesCount = styled.div`
  background-color: ${Colors.DarkBlue200};
  color: ${Colors.White1000};
  position: absolute;
  font-size: 9px;
  top: 0px;
  right: 0px;
  padding: 2px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
  margin: 0px 5px;
  &.small{
    width: 13px;
    height: 13px;
    margin: 0px;
  }
`

export {
  CardContent,
  CardFooter,
  CardHeader,
  CardImage,
  CardMeta,
  IconContainer,
  ImageWrapper,
  ProfileProgressContainer,
  StyledButton,
  StyledCard,
  UpdatesCount
}
