import moment from 'moment'
import 'moment/min/locales'

import { getUserIdFromCookie, hasSessionCookie } from 'utils'
import { LoggedInUserParamsType } from './Types'

import { getTenantDetails } from 'app/Settings/Queries'
import { getUserById } from 'shared/Users/Queries'

export const getLoggedInUser = async (params: LoggedInUserParamsType = {}) => {
  if (!hasSessionCookie()) {
    localStorage.clear()
    return null
  }

  const cachedUser = localStorage.getItem('user')
  if (params.fromCache && cachedUser) {
    return JSON.parse(cachedUser)
  }

  const userId: any = await getUserIdFromCookie('connect.sid')
  if (!userId) {
    return null
  }

  const user: any = await getUserById(userId)
  if (!user || user._id !== userId) {
    return null
  }

  if (!user.isLoggedIn) {
    user.isLoggedIn = true
  }

  if (user.role === 'ADMIN') {
    const cachedLocale = localStorage.getItem('locale')
    if (params.fromCache && cachedLocale) {
      moment.locale(cachedLocale)
    } else {
      const response = await getTenantDetails(user.tenant)
      const locale = (response && response.dateFormat) || 'en'
      moment.locale(locale)
      localStorage.setItem('locale', locale)
    }
  }

  localStorage.setItem('user', JSON.stringify(user))
  return user
}
