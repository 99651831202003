// Import Packages
import * as React from 'react'
import InputMask from 'react-input-mask'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'

// Import Components
import { handleValidation } from './validation'

// Import Utils
import { Strings } from 'utils'

// Import Colors
// import Colors from 'design/Colors'

// Import Styled Components
import { getGroups } from '../../../../../Users/UserQueries'
import { AddContactLeftPanel, AddForm, Container, StyledForm } from './Styled'

interface Props {
  editUser: any
  editInvitee: (data: any) => void
  closeModal: () => void
}

interface Options {
  key: string
  text: string
  value: string
}

interface State {
  Invitee: object
  errors: any
  roleOptions: Options[]
  selectedRole: Options
  userObj: any
}

const typeOptions = [
  { key: 1, text: 'Listing Agent', value: 'Listing Agent' },
  { key: 2, text: 'Selling Agent', value: 'Selling Agent' },
  { key: 3, text: 'Escrow', value: 'Escrow' },
  { key: 4, text: 'Title', value: 'Title' },
  { key: 5, text: 'Lender', value: 'Lender' },
  { key: 6, text: 'Attorney', value: 'Attorney' },
  { key: 7, text: 'Home Inspector', value: 'Home Inspector' },
  { key: 8, text: 'Other', value: 'Other' },
  { key: 9, text: 'Buyer', value: 'Buyer' },
  { key: 10, text: 'Seller', value: 'Seller' }
]

class Reports extends React.Component<Props, State> {
  public state = {
    Invitee: {},
    errors: {},
    roleOptions: [],
    selectedRole: {
      key: '',
      text: '',
      value: ''
    },
    userObj: {}
  }

  public async componentDidMount() {
    const { editUser } = this.props
    const groups: any = await getGroups()
    if (groups.data && groups.data.getGroups) {
      const roles: Options[] = []
      let selectedOption: Options = { key: '', text: '', value: '' }
      groups.data.getGroups.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`
        }
        if (editUser && element.name === editUser.role) {
          selectedOption = obj
        }
        if (obj.text !== 'ADMIN') {
          roles.push(obj)
        }
      })
      roles.splice(0, 0, {
        key: '0',
        text: 'Select Role',
        value: 'Choose'
      })
      this.setState({ roleOptions: roles, selectedRole: selectedOption })
    }

    if (Object.keys(editUser).length > 0) {
      let phone
      if (editUser.phones !== null) {
        phone = (editUser.phones && editUser.phones.find((card: any) => card.type === 'Mobile')) || editUser.phones[0]
      }
      this.setState({
        userObj: {
          ...editUser,
          email: editUser.userName,
          phone: phone.value || ''
        }
      })
    }
  }

  public render() {
    const { errors, userObj } = this.state
    return (
      <Container>
        <AddContactLeftPanel>
          <Image src={Strings.leads.image} size="small" />
        </AddContactLeftPanel>
        <AddForm>
          <StyledForm size={'mini'}>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                label="First Name"
                name="firstName"
                placeholder="First Name"
                onChange={this.handleChange}
                value={userObj[`firstName`]}
                error={errors[`firstName`] ? true : false}
              />
              <Form.Field
                control={Input}
                label="Last Name"
                name="lastName"
                placeholder="Last Name"
                onChange={this.handleChange}
                value={userObj[`lastName`]}
                error={errors[`lastName`] ? true : false}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                label="Email"
                name="email"
                placeholder="Email"
                value={userObj[`email`]}
                error={errors[`email`] ? true : false}
                onChange={this.handleChange}
                disabled={true}
              />
              <Form.Field control={Input} label="Phone" className="amount" error={errors[`phone`] ? true : false}>
                <InputMask
                  mask="(999) 999-9999"
                  name="phone"
                  placeholder="(xxx) xxx-xxxx"
                  onChange={this.onChangePhone}
                  value={userObj[`phone`]}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field
              control={Select}
              onChange={this.handleChange}
              placeholder="Collaborators Role"
              name="type"
              value={userObj[`type`]}
              label="Collaborators Role"
              options={typeOptions}
            />
            <Button content={'SAVE'} onClick={this.saveInvitee} />
          </StyledForm>
        </AddForm>
      </Container>
    )
  }

  private saveInvitee = () => {
    const { userObj } = this.state
    const { editInvitee, closeModal } = this.props
    const result = handleValidation(userObj)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      editInvitee(userObj)
      closeModal()
    }
  }

  private onChangePhone = (event: any) => {
    const { userObj } = this.state
    userObj[`phone`] = event.target.value
    this.setState({ userObj })
  }

  private handleChange = (e: any, { name, value }: any) => {
    const { userObj } = this.state
    userObj[name] = value
    this.setState({ userObj })
  }
}

export default Reports
