import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import { children, x, y } from 'design/Styled'

interface Props {
  custom?: CustomProps
}

interface CustomProps {
  duration?: number
}

export const IdxForm = styled(Form)`
  ${y};
  align-items: center;
  transition: all ${({ custom }: Props) => (custom && custom.duration) || 300}ms;

  &.ui.form > p {
    margin: 0;
    max-width: 400px;
  }
`

export const Section = styled.section`
  ${y};
  overflow: hidden;
`

export const Row = styled.div`
  ${x};
  ${children};
  max-height: 100%;
`
