import * as React from 'react'
import ReactToPrint from 'react-to-print'

import LoadingIndicator from 'shared/LoadingIndicator'
import PropertyImageModal from 'app/Transactions/PropertyImageModal'
import { isPlanAllowed } from 'shared/Billing/Queries'
import Modal from 'shared/Modal'
import Scrollable from 'shared/Scrollable'
import Toast from 'shared/Toast/Toast'
import Templates from 'templates/Flyer'

import { Accordion, Icon } from 'semantic-ui-react'

import {
  createFlyerTemplate,
  updateFlyerTemplate
} from 'app/Transactions/Dashboard/TransactionMutations'
import { getFlyerCategories } from 'app/Transactions/Dashboard/TransactionQueries'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

import {
  Container,
  Infograph,
  Row,
  Section,
  StyledAccordion,
  StyledButton,
  Template
} from './Styled'

import { TransactionType } from 'app/Transactions/Details/Types'

import {
  ImageType as ImageZoneImageType,
  ModeEnum as ImageZoneModeEnum
} from 'shared/ImageZone/Types'
import {
  FlyerCategories,
  FlyerSubCategories,
  FlyerTemplateEnum,
  FlyerTemplates,
  ModalType,
  TemplateEnumType,
  TemplateOptionType,
  UserType
} from './Types'

interface Props {
  transaction: TransactionType
  closeFlyerModal: () => void
}

interface State {
  activeTemplateIndex: number
  activeItem: string
  data: any[]
  init: any[]
  modal: ModalType
  scale: number
  transition: boolean
  flyerCategoryIndex: number
  subCategoryIndex: number
  flyerCategories: FlyerCategories[]
  flyerCategoryId: string
  subCategoryId: string
  templateName: string
  user: UserType
  updateTemplate: string
  loading: string
  isPaid: boolean
  templateType: TemplateEnumType
  subCategoryName: string
}

class Flyer extends React.Component<Props, State> {
  public state = {
    activeItem: '',
    activeTemplateIndex: 0,
    data: [] as any[],
    flyerCategories: [],
    flyerCategoryId: '',
    flyerCategoryIndex: 0,
    init: [] as any[],
    isPaid: false,
    loading: '',
    modal: {} as ModalType,
    scale: 0.6,
    subCategoryId: '',
    subCategoryIndex: 0,
    subCategoryName: 'All',
    templateName: FlyerTemplateEnum.Template1,
    templateType: TemplateEnumType.Free,
    transition: true,
    updateTemplate: '',
    user: {} as UserType
  }

  private printRef: any = null

  public componentDidMount = async () => {
    const user: UserType = await getLoggedInUser({ fromCache: true })
    let isPaid = false
    try {
      isPaid = await isPlanAllowed('transaction')
    } catch (error) {
      isPaid = false
    }
    const { transaction } = this.props
    const data: any[] = []
    const init: any[] = []

    this.refreshGetFlyerCategories()

    Templates.forEach((template: TemplateOptionType, index: number) => {
      data.push({})
      init.push({
        address: transaction.propertyId.address,
        companyLogo: transaction.tenant.companyLogo,
        description: transaction.propertyId.description,
        images: transaction.propertyId.images,
        mlsId: transaction.propertyId.mlsId,
        owner: transaction.owner,
        price: transaction.propertyId.price
      })
    })

    this.setState({
      data,
      init,
      isPaid,
      user
    })
  }

  public render() {
    const {
      transaction
    } = this.props
    const {
      activeTemplateIndex,
      data,
      init,
      modal,
      scale,
      transition,
      flyerCategoryIndex,
      subCategoryIndex,
      flyerCategories,
      updateTemplate,
      subCategoryName,
      loading,
      isPaid,
      templateType
    } = this.state

    const ActiveTemplate = Templates[activeTemplateIndex].component || 'div'

    return (
      <Modal
        content={
          <React.Fragment>
            {loading && <LoadingIndicator message={loading} />}
            {modal.image && (
              <PropertyImageModal
                active={0}
                mode={ImageZoneModeEnum.Select}
                transaction={transaction}
                onClose={this.closeImageModal}
              />
            )}
            <Container>
              <StyledAccordion>
                {flyerCategories.map((item: FlyerCategories, index: number) => (
                  <React.Fragment key={index}>
                    <Accordion.Title
                      active={flyerCategoryIndex === 0}
                      index={0}
                      id={item._id}
                      onClick={this.handleClick}
                    >
                      {item.name}
                      <Icon name="dropdown" />
                    </Accordion.Title>
                    <Accordion.Content active={flyerCategoryIndex === 0}>
                      {item.flyerSubCategories.map(
                        (list: FlyerSubCategories, key: number) => (
                          <React.Fragment key={key}>
                            <Accordion.Title
                              active={key === subCategoryIndex}
                              index={key}
                              name={list.name}
                              id={list._id}
                              onClick={this.handleSelectSubCategory}
                            >
                              {list.name}
                              <Icon name="dropdown" />
                            </Accordion.Title>
                            <Accordion.Content
                              style={{
                                transition: 'all 0.4s ease-in',
                                transitionDelay: '0.4s'
                              }}
                              active={key === subCategoryIndex}
                            >
                              {list.name === 'All'
                                ? Templates.map(
                                    (
                                      elements: TemplateOptionType,
                                      keys: number
                                    ) => (
                                      <Accordion.Content
                                        key={keys}
                                        onClick={() =>
                                          this.handleSelectTemplate(
                                            keys,
                                            elements.name,
                                            keys === 4
                                              ? TemplateEnumType.Pro
                                              : TemplateEnumType.Free
                                          )
                                        }
                                        active={true}
                                        style={{
                                          background:
                                            keys === activeTemplateIndex
                                              ? 'whitesmoke'
                                              : ''
                                        }}
                                      >
                                        {elements.name}{' '}
                                        {keys === 4 && (
                                          <span className="pro-version">
                                            ({TemplateEnumType.Pro})
                                          </span>
                                        )}
                                      </Accordion.Content>
                                    )
                                  )
                                : Templates.map(
                                    (
                                      elements: TemplateOptionType,
                                      keys: number
                                    ) => (
                                      <Accordion.Content
                                        key={keys}
                                        onClick={() =>
                                          this.handleSelectTemplate(
                                            keys,
                                            elements.name,
                                            keys === 4
                                              ? TemplateEnumType.Pro
                                              : TemplateEnumType.Free
                                          )
                                        }
                                        active={true}
                                        style={{
                                          background:
                                            keys === key - 1
                                              ? 'whitesmoke'
                                              : '',
                                          display:
                                            keys !== key - 1 ? 'none' : ''
                                        }}
                                      >
                                        {elements.name}{' '}
                                        {keys === 4 && (
                                          <span className="pro-version">
                                            ({TemplateEnumType.Pro})
                                          </span>
                                        )}
                                      </Accordion.Content>
                                    )
                                  )}
                            </Accordion.Content>
                          </React.Fragment>
                        )
                      )}
                    </Accordion.Content>
                  </React.Fragment>
                ))}
              </StyledAccordion>
              <Section primary={1}>
                {flyerCategoryIndex >= 0 && subCategoryIndex >= 0 ? (
                  <React.Fragment>
                    <Row>
                      {(isPaid || templateType === TemplateEnumType.Free) && (
                        <ReactToPrint
                          trigger={() => (
                            <StyledButton>Print To PDF</StyledButton>
                          )}
                          content={() => this.printRef}
                        />
                      )}
                      {updateTemplate ? (
                        <StyledButton onClick={this.updateTemplate}>
                          {Strings.flyer.updateButton}
                        </StyledButton>
                      ) : (
                        <StyledButton onClick={this.saveTemplate}>
                          {Strings.flyer.saveButton}
                        </StyledButton>
                      )}
                    </Row>
                    {data.length > 0 &&
                      init.length > 0 && (
                        <Scrollable>
                          <Template scale={scale}>
                            <ActiveTemplate
                              ref={(el: any) => (this.printRef = el)}
                              isPaid={isPaid}
                              templateType={templateType}
                              subCategoryName={subCategoryName}
                              init={init[activeTemplateIndex]}
                              data={data[activeTemplateIndex]}
                              onUpdate={this.handleUpdate}
                              onReplaceImage={this.handleReplaceImage}
                            />
                          </Template>
                        </Scrollable>
                      )}
                  </React.Fragment>
                ) : (
                  <Infograph backgroundImage={Strings.generalText.nothing} />
                )}
              </Section>
            </Container>
          </React.Fragment>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeFlyerModal}
        maximum={true}
      />
    )
  }

  private refreshGetTemplateData = () => {
    const {
      data,
      activeTemplateIndex,
      flyerCategoryId,
      flyerCategories,
      subCategoryId,
      flyerCategoryIndex,
      templateName
    } = this.state

    const flyerCategory: FlyerCategories[] = flyerCategories.filter(
      (list: FlyerCategories) => {
        return list._id === flyerCategoryId
      }
    )

    const flyerSubCategory: FlyerSubCategories[] = flyerCategory[
      flyerCategoryIndex
    ].flyerSubCategories.filter((list: FlyerSubCategories) => {
      return list._id === subCategoryId
    })

    const templateData: FlyerTemplates[] = flyerSubCategory[
      flyerCategoryIndex
    ].flyerTemplates.filter((list: FlyerTemplates) => {
      return list.title === templateName
    })

    if (templateData.length !== 0) {
      data[activeTemplateIndex] = templateData[flyerCategoryIndex].data
      this.setState({ updateTemplate: templateData[flyerCategoryIndex]._id })
    } else {
      this.setState({ updateTemplate: '' })
    }
    this.setState({ data })
  }

  private refreshGetFlyerCategories = async () => {
    const { transaction } = this.props
    const { flyerCategoryIndex, flyerCategoryId, subCategoryId } = this.state
    this.setState({ loading: 'Gathering data...' })
    try {
      const response = await getFlyerCategories(transaction._id)
      if (response) {
        if (!flyerCategoryId && !subCategoryId) {
          this.setState({
            flyerCategoryId: response[flyerCategoryIndex]._id,
            subCategoryId:
              response[flyerCategoryIndex].flyerSubCategories[
                flyerCategoryIndex
              ]._id
          })
        }
        this.setState(
          {
            flyerCategories: response,
            loading: ''
          },
          () => this.refreshGetTemplateData()
        )
      }
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: '' })
    }
  }

  private saveTemplate = async () => {
    const { transaction } = this.props
    const {
      activeTemplateIndex,
      data,
      flyerCategoryId,
      subCategoryId,
      templateName,
      user,
      isPaid,
      templateType
    } = this.state
    this.setState({ loading: 'Gathering data...' })
    try {
      if (templateType === TemplateEnumType.Free) {
        await createFlyerTemplate(
          transaction._id,
          user._id,
          templateName,
          flyerCategoryId,
          subCategoryId,
          data[activeTemplateIndex]
        )
        this.refreshGetFlyerCategories()
        this.setState({ loading: '' })
      } else {
        if (isPaid) {
          await createFlyerTemplate(
            transaction._id,
            user._id,
            templateName,
            flyerCategoryId,
            subCategoryId,
            data[activeTemplateIndex]
          )
          this.refreshGetFlyerCategories()
          this.setState({ loading: '' })
        } else {
          Toast({
            message:
              'This feature is only available for paid subscriptions. Please upgrade to continue.',
            type: 'error'
          })
        }
        this.setState({ loading: '' })
      }
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: '' })
    }
  }

  private updateTemplate = async () => {
    const { updateTemplate, data, activeTemplateIndex } = this.state
    this.setState({ loading: 'Gathering data...' })
    try {
      await updateFlyerTemplate(updateTemplate, data[activeTemplateIndex])
      this.refreshGetFlyerCategories()
      this.setState({ loading: '' })
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: '' })
    }
  }

  private closeFlyerModal = () => {
    const { closeFlyerModal } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      closeFlyerModal()
    }, 300)
  }

  private handleClick = (
    e: React.SyntheticEvent<EventTarget>,
    titleProps: any
  ) => {
    const { index, id } = titleProps
    const { flyerCategoryIndex } = this.state
    const newIndex = flyerCategoryIndex === index ? -1 : index
    this.setState({
      flyerCategoryId: id,
      flyerCategoryIndex: newIndex
    })
  }

  private handleSelectSubCategory = (
    e: React.SyntheticEvent<EventTarget>,
    titleProps: any
  ) => {
    const { index, id, name } = titleProps
    const { subCategoryIndex } = this.state
    const newIndex = subCategoryIndex === index ? -1 : index

    let activeIndex
    let templateName: FlyerTemplateEnum = FlyerTemplateEnum.Template1
    let templateType: TemplateEnumType = TemplateEnumType.Free
    switch (index) {
      case 1:
        activeIndex = 0
        templateName = FlyerTemplateEnum.Template1
        templateType = TemplateEnumType.Free
        break
      case 2:
        activeIndex = 1
        templateName = FlyerTemplateEnum.Template2
        templateType = TemplateEnumType.Free
        break
      case 3:
        activeIndex = 2
        templateName = FlyerTemplateEnum.Template3
        templateType = TemplateEnumType.Free
        break
      case 4:
        activeIndex = 3
        templateName = FlyerTemplateEnum.Template4
        templateType = TemplateEnumType.Free
        break
      case 5:
        activeIndex = 4
        templateName = FlyerTemplateEnum.Template5
        templateType = TemplateEnumType.Pro
        break
      default:
        activeIndex = 0
        templateName = FlyerTemplateEnum.Template1
        templateType = TemplateEnumType.Free
        break
    }

    this.setState(
      {
        activeTemplateIndex: activeIndex,
        subCategoryId: id,
        subCategoryIndex: newIndex,
        subCategoryName: name,
        templateName,
        templateType
      },
      () => this.refreshGetTemplateData()
    )
  }

  private handleSelectTemplate = (
    index: number,
    templateName: FlyerTemplateEnum,
    type: string
  ) => {
    this.setState(
      {
        activeTemplateIndex: index,
        templateName,
        templateType:
          type === TemplateEnumType.Pro
            ? TemplateEnumType.Pro
            : TemplateEnumType.Free
      },
      () => this.refreshGetTemplateData()
    )
  }

  private handleUpdate = (update: any) => {
    const { activeTemplateIndex, data } = this.state

    data[activeTemplateIndex] = update
    this.setState({ data })
  }

  private handleReplaceImage = (activeItem: string) => {
    this.setState({
      activeItem,
      modal: { image: true }
    })
  }

  private closeImageModal = (image: ImageZoneImageType | null) => {
    const { activeTemplateIndex, activeItem, data } = this.state

    this.closeModal()

    if (!image) {
      return
    }

    data[activeTemplateIndex][activeItem] = { url: image.url }

    this.setState({ data })
  }

  private closeModal = () => {
    this.setState({ modal: {} as ModalType })
  }
}

export default Flyer
