// Import Utils
import { generateID } from 'utils'

export const upgradeMockData = [
  {
    _id: generateID(),
    plan: 'Remove 5 Transaction Limitation'
  },
  {
    _id: generateID(),
    plan: 'Unlimited Storage'
  },
  {
    _id: generateID(),
    plan: 'Inclusion in National Lead Pool'
  },
  {
    _id: generateID(),
    plan: 'FREE Document Checklist Onboarding'
  },
  {
    _id: generateID(),
    plan: 'Unlimited Lead Records'
  },
  {
    _id: generateID(),
    plan: 'Unlimited Contact Records'
  },
  {
    _id: generateID(),
    plan: 'Create Additional Offices'
  },
  {
    _id: generateID(),
    plan: 'Priority Customer Support'
  },
  {
    _id: generateID(),
    plan: 'Access Premium Passes and More!'
  }
]

