// Import Packages
import * as Cookies from 'js-cookie'
import * as React from 'react'

// Import Components
import client from 'queries/apollo'
import { LOGOUT_USER } from 'queries/rest/Mutation'

// Import Utils
import { Routes } from 'utils'

interface Props {
  history: any
}

class Logout extends React.Component<Props, {}> {
  public componentDidMount() {
    client
      .mutate({
        mutation: LOGOUT_USER
      })
      .then(() => {
        Cookies.remove('connect.sid')
        Cookies.remove('connect.sid.sig')
        this.props.history.push(Routes.onboarding.root)
        localStorage.clear()
      })
  }
  public render() {
    return null
  }
}

export default Logout
