// Import Packages
import * as React from 'react'
import Ref from 'semantic-ui-react/dist/commonjs/addons/Ref'

// Import Components
import LoadingIndicator from 'shared/LoadingIndicator'
import StyledGrid from 'shared/StyledGrid'
import Toast from 'shared/Toast'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { UserProfileType, UserType } from 'store/UserProfile/Types'
import AddLinkModal from './AddLinkModal'
import { CustomActions, CustomLink, CustomName } from './CustomComponents'

// Import Queries And Mutations
import { createLink, deleteLinks, updateLink } from '../../../UserProfileMutations'
import { getLinks } from '../../../UserProfileQueries'

// Import Utils
import { LinkData } from 'store/UserProfile/Types'
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import { AddButtonContainer, Header, StyledContainer, Toggle, ToggleItem } from './Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus])

const { action: ActionStrings, name: NameStrings, link: LinkStrings } = Strings.userProfile.detailSection.links.columns
const MyDocumentsStrings = Strings.userProfile.detailSection.myDocuments

interface State {
  accountsData: LinkData[]
  containerHeight: number
  containerWidth: number
  user: UserType
  showLinkForm: boolean
  linkData: LinkData[]
  showAddLinkModal: boolean
  loading: boolean
  editLinkData: LinkData
  edit: boolean
}

interface Props {
  userProfile: UserProfileType
}

export default class Links extends React.Component<Props, State> {
  public container: HTMLElement | any = null
  public state = {
    accountsData: [],
    containerHeight: 0,
    containerWidth: 0,
    edit: false,
    editLinkData: {} as LinkData,
    linkData: [],
    loading: true,
    showAddLinkModal: false,
    showLinkForm: false,
    user: {} as UserType
  }

  public async componentDidMount() {
    this.refreshGetLinks()
  }

  public render() {
    const { containerHeight, showLinkForm, linkData, showAddLinkModal, loading, editLinkData, edit, user } = this.state

    const columnMetaData = [
      {
        customComponent: CustomName,
        enhanceWithRowData: true,
        id: NameStrings.id,
        title: NameStrings.name
      },
      {
        customComponent: CustomLink,
        enhanceWithRowData: true,
        id: LinkStrings.id,
        title: LinkStrings.name
      },
      {
        customComponent: CustomActions,
        customComponentMetadata: { showLinkForm, user, delete: this.deleteLink, edit: this.editLink },
        enhanceWithRowData: true,
        id: ActionStrings.id,
        sortable: false,
        title: ActionStrings.name
      }
    ]

    return (
      /* tslint:disable-next-line jsx-no-lambda */
      <Ref innerRef={(ref: HTMLElement) => (this.container = ref)}>
        <StyledContainer fluid={true}>
          {showAddLinkModal && (
            <AddLinkModal
              handleClose={this.closeModal}
              addLink={this.addLink}
              edit={edit}
              editLinkData={editLinkData}
              updateLink={this.updateLink}
            />
          )}
          {loading && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
          <Header>
            <ToggleItem isToggled={showLinkForm}>{MyDocumentsStrings.mine}</ToggleItem>
            <Toggle onChange={this.toggleForm} toggle={true} />
            <ToggleItem isToggled={!showLinkForm}>{MyDocumentsStrings.company}</ToggleItem>
            {((showLinkForm && (user.role === 'ADMIN' || user.role === 'MANAGER')) || !showLinkForm) && (
              <AddButtonContainer onClick={this.showAddLinkModal}>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </AddButtonContainer>
            )}
          </Header>
          <div className="table-body">
            <StyledGrid data={linkData} columnMetaData={columnMetaData} tableHeight={containerHeight} />
          </div>
        </StyledContainer>
      </Ref>
    )
  }

  private refreshGetLinks = async () => {
    const { showLinkForm } = this.state
    this.setState({ loading: true })
    const user: any = await getLoggedInUser({ fromCache: true })
    const linkData = await getLinks(showLinkForm, user._id)
    if (linkData) {
      this.setState({ loading: false })
    }
    this.setState({ user, linkData })
  }

  private deleteLink = async (id: string) => {
    try {
      await deleteLinks([id])
      this.refreshGetLinks()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: false })
    }
  }

  private editLink = async (data: LinkData) => {
    this.setState({ editLinkData: data, edit: true })
    this.showAddLinkModal()
  }

  private addLink = async (data: LinkData) => {
    const { user, showLinkForm } = this.state
    try {
      await createLink(showLinkForm, data, user._id)
      this.refreshGetLinks()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: false })
    }
  }

  private updateLink = async (id: string, data: LinkData) => {
    try {
      await updateLink(id, data)
      this.refreshGetLinks()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: false })
    }
  }

  private showAddLinkModal = () => {
    this.setState({ showAddLinkModal: true })
  }

  private closeModal = () => {
    this.setState({ showAddLinkModal: false, editLinkData: {} as LinkData, edit: false })
  }

  private toggleForm = () => {
    this.setState((prev: State) => ({ showLinkForm: !prev.showLinkForm }), () => this.refreshGetLinks())
  }
}
