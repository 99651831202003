import * as React from 'react'

import Template3 from 'templates/Listing/Template3'

import { TransactionType } from 'app/Transactions/Listing/Types'

interface Props {
  transaction: TransactionType
}

class Template4 extends React.Component<Props, {}> {
  public render = () => {
    const { transaction } = this.props
    return (
      <Template3 transaction={transaction} colorMode='dark' />
    )
  }
}

export default Template4
