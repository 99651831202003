// Import Packages
import styled from 'styled-components'

// Import Images and Icons
import { iconview } from 'design/icons/dashboard'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const FullWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: visible;
  overflow-y: visible;
  ${scrollbars};
`

const ContentContainer = styled.div`
  width: 93%;
  margin-top: 10px;
  margin-left: 22px;
  margin-right: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const ViewAllBtn = styled.div`
  padding: 8px 25px;
  width: 100%;
  border-radius: 8px;
  background-color: ${Colors.White1000};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 35px;
  cursor: pointer;
`

const ViewAllInner = styled.div`
  font-size: 12px;
  color: ${Colors.DarkBlue200};
  font-weight: 500;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    display: inline-block;
    margin-right: 12px;
    width: 18px;
    height: 18px;
    margin-top: -2px;
    background-image: url(${iconview});
    background-size: contain;
    background-repeat: no-repeat;
  }
`

export {
  ContentContainer,
  FullWrapper,
  ViewAllBtn,
  ViewAllInner
}
