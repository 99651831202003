const data = [
  {
    address: '123, Stockwood St., L.A.',
    commercialProgress: 35,
    email: 'steve@gmail.com',
    image: 'https://react.semantic-ui.com/images/avatar/large/steve.jpg',
    name: 'Martin Luther',
    phoneNumber: '+41 123556633',
    residentialProgress: 45,
    updates: {
      contacts:  {
        count: 2
      },
      data: {
        count: 0
      },
      users: {
        count: 0
      },
    }
  },
  {
    address: '123, Stockwood St., L.A.',
    commercialProgress: 35,
    email: 'steve@gmail.com',
    image: 'https://react.semantic-ui.com/images/avatar/large/molly.png',
    name: 'Molly Fernandes',
    phoneNumber: '+41 123556633',
    residentialProgress: 45,
    updates: {
      contacts:  {
        count: 2
      },
      data: {
        count: 1
      },
      users: {
        count: 0
      },
    }
  },
  {
    address: '123, Stockwood St., L.A.',
    commercialProgress: 35,
    email: 'steve@gmail.com',
    image: 'https://react.semantic-ui.com/images/avatar/large/steve.jpg',
    name: 'Mark Kevin',
    phoneNumber: '+41 123556633',
    residentialProgress: 45,
    updates: {
      contacts:  {
        count: 3
      },
      data: {
        count: 0
      },
      users: {
        count: 1
      },
    }
  },
  {
    address: '123, Stockwood St., L.A.',
    commercialProgress: 35,
    email: 'steve@gmail.com',
    image: 'https://react.semantic-ui.com/images/avatar/large/steve.jpg',
    name: 'John Leo',
    phoneNumber: '+41 123556633',
    residentialProgress: 45,
    updates: {
      contacts:  {
        count: 1
      },
      data: {
        count: 1
      },
      users: {
        count: 1
      },
    }
  },
  {
    address: '123, Stockwood St., L.A.',
    commercialProgress: 35,
    email: 'steve@gmail.com',
    image: 'https://react.semantic-ui.com/images/avatar/large/jenny.jpg',
    name: 'Tina',
    phoneNumber: '+41 123556633',
    residentialProgress: 45,
    updates: {
      contacts:  {
        count: 0
      },
      data: {
        count: 0
      },
      users: {
        count: 0
      },
    }
  }
]


export default data