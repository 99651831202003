// Import Packages
import * as React from 'react'
import { Link } from 'react-router-dom'

// Import Utils
import { getLoggedInUser, Routes } from 'utils'

import { OfficeItemsDetails } from 'store/Offices/Types'
import { Options, UserType } from 'store/Tasks/Types'

// Import Styled Components
import {
  Action,
  AddButtonContainer,
  Align,
  AlignContainer,
  Calendar,
  Container,
  CustomButton,
  Filter,
  FilterIcon,
  MyTasks,
  StyledCheckbox,
  StyledDropdown,
  Trello,
  TrelloContainer
} from 'shared/StyledBoard/Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus])

interface Props {
  showCalendarIcon?: boolean
  showTaskSearchModal?: boolean
  addList: () => void
  showDefaultView: (data: boolean) => void
  changeToggle: () => void
  deleteTasks: () => void
  toggleStatus: boolean
  showToggleCheckBox?: boolean
  offices: OfficeItemsDetails[]
  getOfficeTasks: (id: string, office: string) => void
  user: UserType
}

interface State {
  show: boolean
  searchText: string
  showSearchModal: boolean
  sortOrder: number
  selectedOption: Options
}

class StyledHeader extends React.Component<Props, State> {
  public user: any = []
  public state = {
    searchText: '',
    selectedOption: {} as Options,
    show: true,
    showSearchModal: false,
    sortOrder: 1
  }

  public options = [
    {
      key: '0',
      text: 'Choose an Action',
      value: 'Choose'
    },
    {
      key: '1',
      text: 'Delete Task(s)',
      value: 'Delete'
    }
  ]

  public async componentDidMount() {
    this.user = await getLoggedInUser()
  }

  public render() {
    const { showCalendarIcon, toggleStatus, showToggleCheckBox, offices, user } = this.props
    const { show } = this.state
    return (
      <Container>
        <Filter>
          {toggleStatus && (
            <Filter>
              <FilterIcon />
              <StyledDropdown
                inline={true}
                placeholder="Select Office"
                options={offices}
                onChange={this.onHandleChange}
              />
            </Filter>
          )}
          {showToggleCheckBox &&
            user.role !== 'GUEST' && (
              <MyTasks>
                <span style={{ fontWeight: !toggleStatus ? 600 : 400 }}>My Tasks</span>
                <StyledCheckbox className="tester" checked={toggleStatus} toggle={true} onChange={this.toggleStatus} />
                <span style={{ fontWeight: toggleStatus ? 600 : 400 }}>All Tasks</span>
              </MyTasks>
            )}
        </Filter>
        <CustomButton>
          {showCalendarIcon && (
            <Link to={Routes.primary.events.path}>
              <Calendar />
            </Link>
          )}
          <AlignContainer>
            <Align onClick={this.showListView} />
          </AlignContainer>
          <TrelloContainer>
            <Trello onClick={this.showKanbanView} />
          </TrelloContainer>
          {!show &&
            (this.user.role === 'ADMIN' || this.user.role === 'MANAGER') && (
              <Action>
                {' '}
                <StyledDropdown
                  inline={true}
                  options={this.options}
                  placeholder="Choose an Action"
                  onChange={this.handleChange}
                />
              </Action>
            )}
          {!show &&
            (this.user.role === 'ADMIN' || this.user.role === 'MANAGER') && (
              <AddButtonContainer onClick={this.props.addList}>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </AddButtonContainer>
            )}
        </CustomButton>
      </Container>
    )
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    if (value !== 'Choose') {
      const { deleteTasks } = this.props
      deleteTasks()
    }
  }

  private toggleStatus = () => {
    const { changeToggle } = this.props
    changeToggle()
  }

  private onHandleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const result = value.split(', ')
    const { getOfficeTasks } = this.props
    getOfficeTasks(result[0], result[1])
  }

  private showListView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: false
      },
      () => showDefaultView(this.state.show)
    )
  }

  private showKanbanView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: true
      },
      () => showDefaultView(this.state.show)
    )
  }
}

export default StyledHeader
