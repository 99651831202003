// Import Packages
import Truncate from 'react-truncate'
import { Popup, Responsive, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface TitleProps {
  isadd: number
}

interface ActionsWrapperProps {
  ishidden: number
}

interface DocumentWrapperProps {
  ishidden: number
  islast: number
}

export const ActionsWrapper = styled.div`
  display: ${(props: ActionsWrapperProps) => props.ishidden ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-evenly;
  transform: rotate(60deg);
  margin-left: -5px;
  position: absolute;
  right: -105px;
  top: 60%;
  width: 50px;
  z-index: 100;
  pointer-events: all;
  cursor: pointer;
`

export const DocumentWrapper = styled(Segment)`
  &&.ui.segment {
    display: block;
    height: 50px;
    transform: rotate(-60deg);
    border: none;
    box-shadow: none;
    background-color: ${Colors.transparent};
    width: 50px;
    padding: 0;
    margin: 0px;
    cursor: ${(props: DocumentWrapperProps) => props.islast ? 'auto' : (props.ishidden ? 'pointer' : 'auto')};
    ${ActionsWrapper} {
      visibility: hidden;
    }
    &:hover {
      ${ActionsWrapper} {
        visibility: visible;
      }
    }
  }
  .ui.horizontal.segments & {
    flex: none;
    margin-bottom: -20px;
  }
`

export const ImageWrapper = styled.div`
  margin-left: -8px;

  .svg-inline--fa {
    width: 12px;
    height: 12px;
    color: ${Colors.Black500};
  }
`

export const Title = styled(Segment)`
  &.ui.segment {
    color: ${(props: TitleProps) => props.isadd ? Colors.Black500 : Colors.DarkBlue200};
    text-transform: uppercase;
    border: none;
    box-shadow: none;
    background-color: ${Colors.transparent};
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
    width: 140px;
    height: 90%;
    border-top: 1px solid ${Colors.Black840};
    padding: 0 20px 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 14px;
    font-weight: 600;
  }
`

export const TruncateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;

  & span {
    position: relative;
  }

  & span span {
    white-space: nowrap;
  }

  & > span > span > :nth-child(3n) {
    left: 9px
  }
`

export const StyledPopup = styled(Popup)``

export const StyledResponsive = styled(Responsive)``

export const StyledTruncate = styled(Truncate)``
