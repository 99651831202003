// Import Packages
import { sortBy } from 'lodash'
import ConfirmAlert from 'sweetalert2'

// Import Components
import client from 'queries/apollo'
import { toggle } from './Leads'

import { LeadItemsDetails, LeadShareWith, UserType } from 'store/Leads/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Graphql Queries
import { GET_TASK, GET_TASK_ACTIVITY } from 'queries/graphql/Tasks/Queries'
import { GET_LEAD_DETAILS, GET_LEAD_NOTE, GET_LEADS } from 'queries/graphql/Lead/Queries'
import { GET_LEAD_BOARDS } from 'queries/graphql/LeadBoard/Queries'

// Import Graphql Schema Types
import { getLeadBoards, getLeadNotes } from 'queries/schema/schemaTypes'

// Import Graphql Mutations
import { APPLY_WORKFLOW } from 'queries/graphql/Transactions/Mutation'
import {
  CREATE_LEAD,
  CREATE_LEAD_NOTES,
  DELETE_LEAD,
  DELETE_LEAD_NOTES,
  DELETE_SHARE_WITH,
  EDIT_LEAD_NOTES,
  IMPORT_LEADS,
  REASSIGN_LEAD,
  SHARE_WITH,
  TRANSFER_LEAD,
  UPDATE_LEAD,
  UPDATE_LEAD_ORDER
} from 'queries/graphql/Lead/Mutations'
import {
  CREATE_LEAD_BOARD,
  DELETE_LEAD_BOARD,
  UPDATE_LEAD_BOARD,
  UPDATE_LEAD_BOARD_ORDER
} from 'queries/graphql/LeadBoard/Mutations'
import { CREATE_TASK, DELETE_TASK, UPDATE_BULK_TASKS, UPDATE_TASK } from 'queries/graphql/Tasks/Mutations'
import { getMyTaskBoards } from '../../Tasks/TaskQueries'

export const addLead = async (
  newUserData: LeadItemsDetails,
  addLeadIndex: string,
  addBoardId: string,
  show: boolean,
  toggleStatus: boolean,
  loggedInUser: UserType,
  userFilterState: string,
  edit: boolean
) => {
  const {
    _id,
    firstName,
    lastName,
    email,
    phone,
    propertyType,
    tenantBoard,
    type,
    source,
    state,
    city,
    zipCode,
    referralFee
  } = newUserData
  const index = parseInt(addLeadIndex)

  if (show) {
    if (toggleStatus) {
      return await client.mutate({
        mutation: CREATE_LEAD,
        refetchQueries: [
          {
            query: GET_LEADS
          },
          {
            query: GET_LEAD_BOARDS,
            variables: {
              orderField: 'boardOrder',
              sortOrder: 1
            }
          }
        ],
        variables: {
          city,
          email,
          firstName,
          lastName,
          phone,
          propertyType,
          referralFee: parseInt(referralFee),
          source,
          state,
          stateBoard:
            (userFilterState !== 'All' && userFilterState !== '') || loggedInUser.role === 'MANAGER'
              ? addBoardId
              : undefined,
          stateBoardOrder:
            (userFilterState !== 'All' && userFilterState !== '') || loggedInUser.role === 'MANAGER'
              ? index
              : undefined,
          tenantBoard:
            (userFilterState === 'All' || userFilterState === '') && loggedInUser.role === 'ADMIN'
              ? addBoardId
              : undefined,
          tenantBoardOrder:
            (userFilterState === 'All' || userFilterState === '') && loggedInUser.role === 'ADMIN' ? index : undefined,
          zipCode
        }
      })
    } else {
      return await client.mutate({
        mutation: CREATE_LEAD,
        refetchQueries: [
          {
            query: GET_LEAD_BOARDS,
            variables: {
              orderField: 'boardOrder',
              sortOrder: 1,
              userId: loggedInUser._id
            }
          },
          {
            query: GET_LEADS,
            variables: { userId: loggedInUser._id }
          }
        ],
        variables: {
          board: addBoardId,
          boardOrder: index,
          email,
          firstName,
          lastName,
          phone,
          propertyType,
          source,
          type
        }
      })
    }
  } else {
    if (edit) {
      return await client.mutate({
        mutation: TRANSFER_LEAD,
        refetchQueries: [
          {
            query: GET_LEADS,
            variables: { userId: loggedInUser._id }
          },
          {
            query: GET_LEAD_BOARDS,
            variables: {
              orderField: 'boardOrder',
              sortOrder: 1,
              userId: loggedInUser._id
            }
          }
        ],
        variables: {
          input: {
            board: tenantBoard,
            city,
            email,
            firstName,
            lastName,
            phone,
            propertyType,
            source,
            state,
            type,
            zipCode
          },
          where: { _id }
        }
      })
    } else {
      if (toggleStatus) {
        return await client.mutate({
          mutation: CREATE_LEAD,
          refetchQueries: [
            {
              query: GET_LEADS
            },
            {
              query: GET_LEAD_BOARDS,
              variables: {
                orderField: 'boardOrder',
                sortOrder: 1
              }
            }
          ],
          variables: {
            city,
            email,
            firstName,
            lastName,
            phone,
            propertyType,
            referralFee: parseInt(referralFee),
            source,
            state,
            stateBoard:
              (userFilterState !== 'All' && userFilterState !== '') || loggedInUser.role === 'MANAGER'
                ? tenantBoard
                : undefined,
            stateBoardOrder:
              (userFilterState !== 'All' && userFilterState !== '') || loggedInUser.role === 'MANAGER'
                ? index
                : undefined,
            tenantBoard:
              (userFilterState === 'All' || userFilterState === '') && loggedInUser.role === 'ADMIN'
                ? tenantBoard
                : undefined,
            tenantBoardOrder:
              (userFilterState === 'All' || userFilterState === '') && loggedInUser.role === 'ADMIN'
                ? index
                : undefined,
            zipCode
          }
        })
      } else {
        return await client.mutate({
          mutation: CREATE_LEAD,
          refetchQueries: [
            {
              query: GET_LEADS,
              variables: { userId: loggedInUser._id }
            },
            {
              query: GET_LEAD_BOARDS,
              variables: {
                orderField: 'boardOrder',
                sortOrder: 1,
                userId: loggedInUser._id
              }
            }
          ],
          update: (proxy, { data: { createLead } }) => {
            const data: any = proxy.readQuery({
              query: GET_LEADS,
              variables: {
                userId: loggedInUser._id
              }
            })
            data.getLeads.push(createLead)
            proxy.writeQuery({
              data: { getLeads: data.getLeads },
              query: GET_LEADS,
              variables: { userId: loggedInUser._id }
            })
          },
          variables: {
            board: tenantBoard,
            boardOrder: index,
            email,
            firstName,
            lastName,
            phone,
            propertyType,
            source
          }
        })
      }
    }
  }
}

export const addNewBoard = (index: number, name: string, loggedInUser: UserType) => {
  return client.mutate({
    mutation: CREATE_LEAD_BOARD,
    refetchQueries: [
      {
        query: GET_LEADS,
        variables: { userId: loggedInUser._id }
      },
      {
        query: GET_LEAD_BOARDS,
        variables: {
          orderField: 'boardOrder',
          sortOrder: 1,
          userId: loggedInUser._id
        }
      }
    ],
    variables: {
      name: name ? name : 'New Board',
      order: index,
      user: loggedInUser._id
    }
  })
}

export const reorderLane = (
  boardId: string,
  sourceIndex: number,
  destinationIndex: number,
  loggedInUser: UserType,
  userFilterState: string,
  toggleStatus: boolean
) => {
  if (!toggleStatus) {
    client.mutate({
      mutation: UPDATE_LEAD_BOARD_ORDER,
      update: (store, data) => {
        const storeData = store.readQuery({
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1,
            userId: loggedInUser._id
          }
        }) as getLeadBoards
        const boards = storeData.getLeadBoards
        sortBy(boards, ['boardOrder'])
        if (sourceIndex < destinationIndex) {
          for (let i = sourceIndex; i < destinationIndex; i++) {
            boards[i].boardOrder = i
          }
          boards[sourceIndex - 1].boardOrder = destinationIndex
        } else if (sourceIndex > destinationIndex) {
          for (let i = destinationIndex; i < sourceIndex; i++) {
            boards[i - 1].boardOrder = i + 1
          }
        }
        store.writeQuery({
          data: { getLeadBoards: sortBy(boards, ['boardOrder']) },
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1,
            userId: loggedInUser._id
          }
        })
      },
      variables: {
        boardId,
        destinationIndex,
        sourceIndex,
        state: userFilterState !== '' ? userFilterState : undefined,
        userId: userFilterState !== '' ? undefined : loggedInUser._id
      }
    })
  } else {
    client.mutate({
      mutation: UPDATE_LEAD_BOARD_ORDER,
      update: (store, data) => {
        const storeData = store.readQuery({
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1
          }
        }) as getLeadBoards
        const boards = storeData.getLeadBoards
        sortBy(boards, ['boardOrder'])
        if (sourceIndex < destinationIndex) {
          for (let i = sourceIndex; i < destinationIndex; i++) {
            boards[i].boardOrder = i
          }
          boards[sourceIndex - 1].boardOrder = destinationIndex
        } else if (sourceIndex > destinationIndex) {
          for (let i = destinationIndex; i < sourceIndex; i++) {
            boards[i - 1].boardOrder = i + 1
          }
        }
        store.writeQuery({
          data: { getLeadBoards: sortBy(boards, ['boardOrder']) },
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1
          }
        })
      },
      variables: {
        boardId,
        destinationIndex,
        sourceIndex,
        state: userFilterState !== '' ? userFilterState : undefined
      }
    })
  }
}

export const reorderCard = (
  boardId: string,
  cardId: string,
  sourceIndex: number,
  destinationIndex: number,
  loggedInUser: UserType
) => {
  if (toggle) {
    client.mutate({
      mutation: UPDATE_LEAD_ORDER,
      refetchQueries: [
        {
          query: GET_LEADS
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1
          }
        }
      ],
      variables: {
        board: boardId,
        cardId,
        myBoardDestinationOrder: destinationIndex,
        myBoardSourceOrder: sourceIndex
      }
    })
  } else {
    client.mutate({
      mutation: UPDATE_LEAD_ORDER,
      refetchQueries: [
        {
          query: GET_LEADS,
          variables: { userId: loggedInUser._id }
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1,
            userId: loggedInUser._id
          }
        }
      ],
      variables: {
        board: boardId,
        cardId,
        myBoardDestinationOrder: destinationIndex,
        myBoardSourceOrder: sourceIndex
      }
    })
  }
}

export const moveCard = (
  cardId: string,
  destinationIndex: number,
  destinationBoardId: string,
  sourceBoardId: string,
  loggedInUser: UserType,
  toggleStatus: boolean,
  userFilterState: string
) => {
  if (toggleStatus) {
    client.mutate({
      mutation: TRANSFER_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1
          }
        }
      ],
      variables: {
        input: {
          stateBoard:
            (userFilterState !== 'All' && userFilterState !== '') || loggedInUser.role === 'MANAGER'
              ? destinationBoardId
              : undefined,
          stateBoardOrder:
            (userFilterState !== 'All' && userFilterState !== '') || loggedInUser.role === 'MANAGER'
              ? destinationIndex
                ? destinationIndex
                : undefined
              : undefined,
          tenantBoard:
            (userFilterState === 'All' || userFilterState === '') && loggedInUser.role === 'ADMIN'
              ? destinationBoardId
              : undefined,
          tenantBoardOrder:
            (userFilterState === 'All' || userFilterState === '') && loggedInUser.role === 'ADMIN'
              ? destinationIndex
                ? destinationIndex
                : undefined
              : undefined
        },
        where: { _id: cardId }
      }
    })
  } else {
    client.mutate({
      mutation: TRANSFER_LEAD,
      variables: {
        input: {
          board: destinationBoardId,
          boardOrder: destinationIndex
        },
        where: { _id: cardId }
      }
    })
  }
}

export const deleteCard = (boardId: string, cardId: string, loggedInUser: UserType, toggleStatus: boolean) => {
  const leadListId = []
  leadListId.push(cardId)
  if (toggleStatus) {
    client.mutate({
      mutation: DELETE_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1
          }
        }
      ],
      variables: { leadId: leadListId }
    })
  } else {
    client.mutate({
      mutation: DELETE_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS,
          variables: { userId: loggedInUser._id }
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1,
            userId: loggedInUser._id
          }
        }
      ],
      variables: { leadId: leadListId }
    })
  }
}

export const updateLeadStatus = async (cardId: string, status: string, loggedInUser: UserType) => {
  if (toggle) {
    await client.mutate({
      mutation: UPDATE_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1
          }
        }
      ],
      variables: { leadId: cardId, status }
    })
  } else {
    await client.mutate({
      mutation: UPDATE_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS,
          variables: { userId: loggedInUser._id }
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1,
            userId: loggedInUser._id
          }
        }
      ],
      variables: { leadId: cardId, status }
    })
  }
}

export const restore = (cardId: string, loggedInUser: UserType) => {
  if (toggle) {
    return client.mutate({
      mutation: UPDATE_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1
          }
        }
      ],
      variables: { teamId: cardId, isActive: true }
    })
  } else {
    return client.mutate({
      mutation: UPDATE_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS,
          variables: { userId: loggedInUser._id }
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1,
            userId: loggedInUser._id
          }
        }
      ],
      variables: { teamId: cardId, isActive: true }
    })
  }
}

export const reassignLead = async (leadId: string, params: any) => {
  const currentUser: any = (await getLoggedInUser({ fromCache: true })) || undefined

  const where: any = {
    _id: leadId
  }

  const input: any = {
    city: params.city,
    lastAction: new Date(),
    referralFee: parseFloat(params.referralFee),
    state: params.state,
    zipCode: params.zipCode
  }

  const userParams: any = {
    _id: params.userId
  }

  const response: any = await client.mutate({
    mutation: REASSIGN_LEAD,
    refetchQueries: [
      {
        query: GET_LEADS,
        variables: { userId: currentUser._id }
      },
      {
        query: GET_LEAD_BOARDS,
        variables: {
          orderField: 'boardOrder',
          sortOrder: 1,
          userId: currentUser._id
        }
      }
    ],
    variables: {
      input,
      userParams,
      where
    }
  })

  return response.data.reassignLead
}

export const deleteLeads = async (loggedInUser: UserType, leadIds: string[]) => {
  let res
  if (toggle) {
    if (leadIds.length > 0) {
      await ConfirmAlert({
        cancelButtonText: Strings.kanbanView.noKeepIt,
        confirmButtonText: Strings.kanbanView.yesDeleteIt,
        showCancelButton: true,
        text: 'Remove selected Lead(s)',
        title: Strings.kanbanView.sure,
        type: 'warning'
      }).then((result: any) => {
        if (result.value) {
          client.mutate({
            mutation: DELETE_LEAD,
            refetchQueries: [
              { query: GET_LEADS },
              {
                query: GET_LEAD_BOARDS,
                variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
              }
            ],
            variables: { leadId: leadIds }
          })
          ConfirmAlert(Strings.kanbanView.deleted, 'Your Lead(s) has been deleted', 'success')
          res = 1
        } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
          ConfirmAlert(Strings.kanbanView.cancelled, 'Your Lead(s) is safe', 'error')
          res = 0
        }
      })
    } else {
      ConfirmAlert(Strings.kanbanView.error, Strings.leads.errorLeadText, 'error')
    }
  } else {
    if (leadIds.length > 0) {
      await ConfirmAlert({
        cancelButtonText: Strings.kanbanView.noKeepIt,
        confirmButtonText: Strings.kanbanView.yesDeleteIt,
        showCancelButton: true,
        text: 'Remove selected Lead(s)',
        title: Strings.kanbanView.sure,
        type: 'warning'
      }).then((result: any) => {
        if (result.value) {
          client.mutate({
            mutation: DELETE_LEAD,
            refetchQueries: [
              { query: GET_LEADS, variables: { userId: loggedInUser._id } },
              {
                query: GET_LEAD_BOARDS,
                variables: {
                  orderField: 'teamOfficeOrder',
                  sortOrder: 1,
                  userId: loggedInUser._id
                }
              }
            ],
            update: (proxy, { data: { deleteLead } }) => {
              const cachedata: any = proxy.readQuery({
                query: GET_LEADS,
                variables: {
                  userId: loggedInUser._id
                }
              })
              const filterLead: any = []
              leadIds.forEach((lead: string) => {
                cachedata.getLeads.forEach((cacheLead: any) => {
                  if (lead !== cacheLead._id) {
                    filterLead.push(cacheLead)
                  }
                })
              })
              proxy.writeQuery({
                data: { getLeads: filterLead },
                query: GET_LEADS,
                variables: {
                  userId: loggedInUser._id
                }
              })
            },
            variables: { leadId: leadIds }
          })
          ConfirmAlert(Strings.kanbanView.deleted, 'Your Lead(s) has been deleted', 'success')
          res = 1
        } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
          ConfirmAlert(Strings.kanbanView.cancelled, 'Your Lead(s) is safe', 'error')
          res = 0
        }
      })
    } else {
      ConfirmAlert(Strings.kanbanView.error, Strings.leads.errorLeadText, 'error')
    }
  }
  return res
}

export const importLeads = async (
  groupid: string,
  file: any,
  userFilterState: string,
  loggedInUser: UserType,
  toggleStatus: boolean
) => {
  if (toggleStatus) {
    client.mutate({
      mutation: IMPORT_LEADS,
      refetchQueries: [
        {
          query: GET_LEAD_BOARDS,
          variables: { orderField: 'boardOrder', sortOrder: 1 }
        },
        {
          query: GET_LEADS
        }
      ],
      variables: {
        file,
        stateBoard:
          (userFilterState !== 'All' && userFilterState !== '') || loggedInUser.role === 'MANAGER'
            ? groupid
            : undefined,
        tenantBoard:
          (userFilterState === 'All' || userFilterState === '') && loggedInUser.role === 'ADMIN' ? groupid : undefined
      }
    })
  } else {
    client.mutate({
      mutation: IMPORT_LEADS,
      refetchQueries: [
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1,
            userId: loggedInUser._id
          }
        },
        {
          query: GET_LEADS,
          variables: { userId: loggedInUser._id }
        }
      ],
      variables: {
        board: groupid,
        file
      }
    })
  }
}

export const deleteLeadBoard = async (boardId: string, loggedInUser: UserType) => {
  await client.mutate({
    mutation: DELETE_LEAD_BOARD,
    refetchQueries: [
      {
        query: GET_LEAD_BOARDS,
        variables: {
          orderField: 'boardOrder',
          sortOrder: 1,
          userId: loggedInUser._id
        }
      },
      {
        query: GET_LEADS,
        variables: { userId: loggedInUser._id }
      }
    ],
    update: (store, { data }: any) => {
      const storeData: any = store.readQuery({
        query: GET_LEAD_BOARDS,
        variables: {
          orderField: 'boardOrder',
          sortOrder: 1,
          userId: loggedInUser._id
        }
      })
      const filteredData = storeData.getLeadBoards.filter((leadBoard: any) => {
        return leadBoard._id !== boardId
      })
      store.writeQuery({
        data: { getLeadBoards: filteredData },
        query: GET_LEAD_BOARDS,
        variables: {
          orderField: 'boardOrder',
          sortOrder: 1,
          userId: loggedInUser._id
        }
      })
    },
    variables: { boardId }
  })
}

export const updateLeadBoard = async (boardId: string, loggedInUser: UserType, value: string, field: string) => {
  return await client.mutate({
    mutation: UPDATE_LEAD_BOARD,
    variables: {
      boardId,
      boardName: field === 'name' ? value : undefined,
      description: field === 'description' ? value : undefined,
      icon: field === 'icon' ? value : undefined
    }
  })
}

export const shareWith = async (leadId: string, shareWithObject: LeadShareWith) => {
  await client.mutate({
    mutation: SHARE_WITH,
    refetchQueries: [
      {
        query: GET_LEAD_DETAILS,
        variables: { leadId }
      }
    ],
    update: (cache, { data: { sharedWith } }) => {
      const cachedata: any = cache.readQuery({
        query: GET_LEAD_DETAILS,
        variables: { leadId }
      })
      cachedata.getLeads[0].shareWith = sharedWith.shareWith
      cache.writeQuery({
        data: { getLeads: cachedata.getLeads },
        query: GET_LEAD_DETAILS,
        variables: { leadId }
      })
    },
    variables: { leadId: leadId, ...shareWithObject }
  })

  return client.query({
    query: GET_LEAD_DETAILS,
    variables: { leadId }
  })
}

export const deleteShareWithUser = async (userId: string, leadId: string) => {
  await client.mutate({
    mutation: DELETE_SHARE_WITH,
    refetchQueries: [
      {
        query: GET_LEAD_DETAILS,
        variables: { leadId }
      }
    ],
    update: (cache, { data: { deleteSharedWith } }) => {
      const cachedata: any = cache.readQuery({
        query: GET_LEAD_DETAILS,
        variables: { leadId }
      })
      cachedata.getLeads[0].shareWith = deleteSharedWith.shareWith
      cache.writeQuery({
        data: { getLeads: cachedata.getLeads },
        query: GET_LEAD_DETAILS,
        variables: { leadId }
      })
    },
    variables: { leadId: leadId, userId: userId }
  })

  return client.query({
    query: GET_LEAD_DETAILS,
    variables: { leadId }
  })
}

export const createLeadTask = async (
  task: any,
  leadId: string,
  edit: boolean,
  ownerId: string,
  loggedUser: UserType | any
) => {
  const filter = false
  const { type, name, description, dueDate, sharedWith, _id, taskTime } = task
  const taskBoards = await getMyTaskBoards(loggedUser._id)
  const newBoardId = taskBoards.filter((board: any) => {
    return board.name === 'New Task'
  })
  if (!edit) {
    return await client.mutate({
      mutation: CREATE_TASK,
      refetchQueries: [
        {
          query: GET_TASK,
          variables: { leadId }
        },
        {
          query: GET_TASK_ACTIVITY,
          variables: { leadId }
        }
      ],
      variables: {
        assignee: ownerId,
        description,
        dueDate,
        leadId,
        name,
        officeTaskBoard: filter !== false && loggedUser.role === 'MANAGER' ? newBoardId[0]._id : undefined,
        sharedWith,
        taskBoard: filter === false || loggedUser.role === 'AGENT' ? newBoardId[0]._id : undefined,
        taskTime,
        teamTaskBoard: filter !== false && loggedUser.role === 'TEAM_LEADER' ? newBoardId[0]._id : undefined,
        tenantTaskBoard: filter !== false && loggedUser.role === 'ADMIN' ? newBoardId[0]._id : undefined,
        type
      }
    })
  } else {
    return await client.mutate({
      mutation: UPDATE_TASK,
      refetchQueries: [
        {
          query: GET_TASK,
          variables: { leadId }
        },
        {
          query: GET_TASK_ACTIVITY,
          variables: { leadId }
        }
      ],
      variables: {
        description,
        dueDate,
        leadId,
        name,
        officeTaskBoard: filter !== false && loggedUser.role === 'MANAGER' ? newBoardId[0]._id : undefined,
        sharedWith,
        taskBoard: filter === false || loggedUser.role === 'AGENT' ? newBoardId[0]._id : undefined,
        taskId: _id,
        taskTime,
        teamTaskBoard: filter !== false && loggedUser.role === 'TEAM_LEADER' ? newBoardId[0]._id : undefined,
        tenantTaskBoard: filter !== false && loggedUser.role === 'ADMIN' ? newBoardId[0]._id : undefined,
        type
      }
    })
  }
}

export const deleteLeadTask = async (taskId: string, leadId: string) => {
  const taskIds = []
  taskIds.push(taskId)
  await client.mutate({
    mutation: DELETE_TASK,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { leadId }
      },
      {
        query: GET_TASK_ACTIVITY,
        variables: { leadId }
      }
    ],
    variables: { taskId: taskIds }
  })
}

export const updateTask = async (taskId: string, status: string | undefined, leadId: string | undefined) => {
  return await client.mutate({
    mutation: UPDATE_TASK,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { leadId }
      },
      {
        query: GET_TASK_ACTIVITY,
        variables: { leadId }
      }
    ],
    variables: {
      status,
      taskId
    }
  })
}

export const ChangeLeadTaskToggleCheckBox = async (checked: boolean, leadId: string) => {
  const data: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TASK,
    variables: { leadId }
  })
  const newData = data.data.getTasks.map((list: any) => {
    return {
      ...list,
      checked: !checked
    }
  })
  client.writeQuery({
    data: { getTasks: newData },
    query: GET_TASK,
    variables: { leadId }
  })
  return newData
}

export const ToggleLeadTaskSingleCheckBox = async (id: string, leadId: string) => {
  const data: any = await client.query({
    query: GET_TASK,
    variables: { leadId }
  })
  const newData = data.data.getTasks.map((list: any) => {
    return {
      ...list,
      checked: list._id === id ? !list.checked : list.checked
    }
  })
  client.writeQuery({
    data: { getTasks: newData },
    query: GET_TASK,
    variables: { leadId }
  })

  return newData
}

export const ToggleLeadCheckBox = async (leadId: string) => {
  const data: any = await client.query({
    query: GET_TASK,
    variables: { leadId }
  })
  const newData = data.data.getTasks.map((list: any) => {
    return {
      ...list,
      checked: false
    }
  })
  client.writeQuery({
    data: { getTasks: newData },
    query: GET_TASK,
    variables: { leadId }
  })
  return newData
}

export const updateBulkTask = async (taskIds: string, status: string, leadId: string) => {
  const response = await client.mutate({
    mutation: UPDATE_BULK_TASKS,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { leadId }
      }
    ],
    variables: { taskIds, status }
  })

  return response.data.updateBulkTasks
}

export const applyWorkflow = async (workflowId: string, leadId: string) => {
  return await client.mutate({
    mutation: APPLY_WORKFLOW,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { leadId }
      }
    ],
    variables: { workflowId, leadId }
  })
}

export const deleteTasks = async (taskIds: string, leadId: string) => {
  await client.mutate({
    mutation: DELETE_TASK,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { leadId }
      },
      {
        query: GET_TASK_ACTIVITY,
        variables: { leadId }
      }
    ],
    variables: { taskId: taskIds }
  })
}

export const createLeadNotes = async (leadId: string, description: string) => {
  const response = await client.mutate({
    mutation: CREATE_LEAD_NOTES,
    update: (cache, { data: { createNote } }) => {
      const data = cache.readQuery({
        query: GET_LEAD_NOTE,
        variables: { leadId }
      }) as getLeadNotes
      if (data && data.getNotes) {
        data.getNotes.unshift(createNote)
      }
      cache.writeQuery({
        data: { getNotes: data.getNotes },
        query: GET_LEAD_NOTE,
        variables: { leadId }
      })
    },
    variables: { leadId, description }
  })

  return response.data.createNote
}

export const editLeadNotes = async (leadNoteId: string, description: string, leadId: string) => {
  const response = await client.mutate({
    mutation: EDIT_LEAD_NOTES,
    update: (cache, { data: { updateNote } }) => {
      const data = cache.readQuery({
        query: GET_LEAD_NOTE,
        variables: { leadId }
      }) as getLeadNotes
      let newdata
      if (data && data.getNotes) {
        newdata = data.getNotes.filter((note: any) => {
          if (note._id === leadNoteId) {
            return {
              ...note,
              description: updateNote.description
            }
          }
          return note
        })
      }
      cache.writeQuery({
        data: { getNotes: newdata },
        query: GET_LEAD_NOTE,
        variables: { leadId }
      })
    },
    variables: { leadNoteId, description }
  })

  return response.data.updateNote
}

export const deleteLeadNotes = async (leadNoteId: string, leadId: string) => {
  await client.mutate({
    mutation: DELETE_LEAD_NOTES,
    update: (cache, { data: { deleteNote } }) => {
      const data = cache.readQuery({
        query: GET_LEAD_NOTE,
        variables: { leadId }
      }) as getLeadNotes
      let newdata
      if (data && data.getNotes) {
        newdata = data.getNotes.filter((note: any) => {
          return note._id !== leadNoteId
        })
      }

      cache.writeQuery({
        data: { getNotes: newdata },
        query: GET_LEAD_NOTE,
        variables: { leadId }
      })
    },
    variables: { leadNoteId }
  })
}

export const updateLeadScore = async (leadId: string, score: number, loggedInUser: UserType) => {
  if (toggle) {
    return await client.mutate({
      mutation: UPDATE_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1
          }
        }
      ],
      variables: { leadId, score }
    })
  } else {
    return await client.mutate({
      mutation: UPDATE_LEAD,
      refetchQueries: [
        {
          query: GET_LEADS,
          variables: { userId: loggedInUser._id }
        },
        {
          query: GET_LEAD_BOARDS,
          variables: {
            orderField: 'boardOrder',
            sortOrder: 1,
            userId: loggedInUser._id
          }
        }
      ],
      variables: { leadId, score }
    })
  }
}
