import { Reducer } from 'redux'
import Actions from 'store/Settings/WorkflowManager/ActionsTypes'
import {
  addNewLabel,
  addNewTask,
  addNewWorkflow,
  deleteTask,
  deleteWorkflow,
  updateTask
} from './ReduxUtil'
import { WorkflowManagerState } from './Types'

const initialState: WorkflowManagerState = {
  categoriesData: [],
  labelManageData: [],
  taskData: []
}

const WorkflowManagerReducer: Reducer<WorkflowManagerState> = (
  state: WorkflowManagerState = initialState,
  action
) => {
  switch (action.type) {
    case Actions.workflowAddNewWorkflow:
      return {
        ...state,
        categoriesData: addNewWorkflow(
          state,
          action.payload.category,
          action.payload.newData
        )
      }
    case Actions.workflowGetCategoriesData:
      return {
        ...state,
        categoriesData: action.payload.data
      }
    case Actions.workflowDeleteWorkflow:
      return {
        ...state,
        categoriesData: deleteWorkflow(
          state,
          action.payload.category,
          action.payload.id
        )
      }
    case Actions.workflowAddNewLabel:
      return {
        ...state,
        labelManageData: addNewLabel(state, action.payload.newData)
      }
    case Actions.workflowGetLabelManageData:
      return {
        ...state,
        labelManageData: action.payload.data
      }
    case Actions.workflowAddNewTask:
      return {
        ...state,
        taskData: addNewTask(state, action.payload.newData)
      }
    case Actions.workflowGetTaskData:
      return {
        ...state,
        taskData: action.payload.data
      }
    case Actions.workflowUpdateTask:
      return {
        ...state,
        taskData: updateTask(state, action.payload.newData)
      }
    case Actions.workflowDeleteTask:
      return {
        ...state,
        taskData: deleteTask(state, action.payload.id)
      }
    default:
      return state
  }
}

export default WorkflowManagerReducer
