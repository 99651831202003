// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
    font-size: 12px;
    font-weight: 500;
  }

  &.ui.form .field>.selection.dropdown {
    width: 90%;
    font-size: 12px;
  }

  .ui.button {
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 30px;
    border-radius: 0px;
    width: 120px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    font-weight: bold;
    margin-right: 25px;
    margin-bottom: 100px;
  }
`

const AddContactLeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 45%;
  height: auto;
  background: ${Colors.Grey110};

  .ui.small.image {
    width: 250px;
  }
`

const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 45%;
  padding-left: 22px;
`

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 50px;
  color: ${Colors.Black500};
`

const ProgressContainer = styled.div`
  height: 250px;
  width: 250px;
  position: relative;
`

const PercentContainer = styled.div`
  position: absolute;
  top: 55px;
  left: 100px;

  .ui.small.image {
    width: 55px;
  }
`

const Percent = styled.div`
  font-size: 10px;
  margin-top: 8px;
  margin-left: -8px;
  font-weight: 600;
`

export {
  AddContactLeftPanel,
  AddForm,
  Container,
  Percent,
  PercentContainer,
  ProgressContainer,
  StyledForm,
  Text
}
