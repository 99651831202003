import { Reducer } from 'redux'
import Actions from 'store/Mailbox/ActionsTypes'

import { deleteCard } from './ReduxUtil'

import { Mailbox } from './Types'

const initialState: Mailbox = {
  boardId: '',
  cardIndex: -1,
  currentlyDeleteCard: null,
  data: {
    _id: 'INBOX',
    description: '',
    icon: 'INBOX',
    items: [],
    name: 'INBOX',
    pagination: null
  }
}

const MailboxReducer: Reducer<Mailbox> = (
  state: Mailbox = initialState,
  action
) => {
  switch (action.type) {
    case Actions.MailboxGetBoardData:
      return {
        ...state,
        data: action.payload.data
      }
    case Actions.MailboxDeleteCard:
      return deleteCard(state, action)
    default:
      return state
  }
}

export default MailboxReducer
