import styled from 'styled-components'

import Colors from 'design/Colors'

interface Props {
  isDragOver?: boolean
  showBorder?: boolean
  showImage?: boolean
}

const getBorder = ({ isDragOver, showBorder }: Props) => {
  if (isDragOver) {
    return `4px dotted ${Colors.DropZoneHighlight}`
  }
  if (showBorder) {
    return `4px dotted ${Colors.EmphasisLine}`
  }

  return `4px dotted ${Colors.transparent}`
}

const getOpacity = ({ isDragOver, showImage }: Props) => {
  if (isDragOver) {
    return 1
  }
  if (showImage) {
    return 0.3
  }
  return 0
}

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  border: ${getBorder};

  .rp-dropzone {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export const DragImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  padding: 1em;
  background-color: ${Colors.DropZoneContainer};
  z-index: ${({ isDragOver }: Props) => (isDragOver ? 999 : 0)};
  opacity: ${getOpacity};
  transition: opacity 300ms;
`

export const DragImage = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
`
