import { MethodEnum } from './TokenMethodToggle/Types'

export interface AccountLinkInputType {
  type: SubjectEnum
  userName?: string
  phone?: string
}

export enum SubjectEnum {
  Activate = 'ac',
  Confirm = 'ac',
  Invite = 'in',
  Reset = 'rs',
  None = '_'
}

export interface RouteParamsType {
  subject: SubjectEnum
  token: string
}

export interface LocationStateType {
  email: string
  init: boolean
  method: MethodEnum
  phone: string
  subject: SubjectEnum
  token: string
}
