import { MarkupType } from 'shared/MarkupTool/Types'
import { ExtractWordType } from './Types'

export const getWordsCenteredInBounds = (source: ExtractWordType[], bounds: MarkupType) => {
  const { height, left, top, width } = bounds

  const selection: ExtractWordType[] = source.filter((word: ExtractWordType) => {
    const { x, y } = word
    return y > top && y < top + height && x > left && x < left + width
  })

  return selection
}

export const getFullLineFromWords = (words: ExtractWordType[]) => {
  return words.map((word: ExtractWordType) => word.value).join(' ')
}
