// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Dropdown, Flag, Form, Label, Select, Input } from 'semantic-ui-react'

import DatePicker from 'shared/DatePicker'

// Import Images and Icons
import IconDocument from 'design/icons/userProfile/userInfo/icon-document.png'
import IconMap from 'design/icons/userProfile/userInfo/icon-map.png'

// Import Components
import client from 'queries/apollo'
import { GET_PRE_SIGNED_URL } from 'queries/graphql/Users/Queries'
import { updateProfile } from '../UserProfileMutations'
import Data from './DummyData'
import PersonalInfo from './PersonalInfo'

// Import Queries
import { Badge, Language, Options, UserProfileType, UserType } from 'store/UserProfile/Types'
import { getTransactionsSetting } from '../../Settings/TransactionFee/TransactionFeeDetails/TransactionFeeQueries'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import {
  BottomSegment,
  CommissionPlan,
  ContractDocument,
  DataItem,
  ImageWrapper,
  Office,
  ReferSegment,
  StyledProgress,
  UserInfo
} from './Styled'

// Font Awesome Icons
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons'
import { faArrowAltToBottom } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPencilAlt, faArrowAltToBottom])

const UserInfoStrings = Strings.userProfile.userInfo

interface State {
  badges: Badge[]
  email: string
  firstName: string
  joiningDate: moment.Moment | undefined | string
  changeJoiningDate: boolean
  openDatePicker: boolean
  languages: Language[]
  lastName: string
  lastlogin: moment.Moment | undefined
  mobileNo: string
  office: string
  rank: number
  referrals: {
    joined: number
    pending: number
  }
  storageLimit: number
  storageUnit: string
  storageUsed: number
  totalEmployees: number
  selectedLanguages: Language[]
  updatedData: UserProfileType
  contractDocument: string
  transactionsOptions: Options[]
  selectedTransactionOption: Options
  user: UserType
}

interface Props {
  openReferralPopup: () => void
  showToggleTab: (data: boolean) => void
  userProfile: UserProfileType
  showEditButton: string
  toggleTab: boolean
  userID: string
  storageUsed: number
  profileProgress: number
}

export default class UserInfoComponent extends React.Component<Props, State> {
  public textInput: any = React.createRef()

  public options = [
    {
      content: (
        <div>
          {' '}
          <Flag name="united states" />
          English
        </div>
      ),
      key: 'us',
      text: (
        <Label
          content={<Flag name="united states" />}
          onRemove={(e: any, lp: any) => {
            e.stopPropagation()
            this.removeCard(lp.value)
          }}
        />
      ),
      value: 'English'
    },
    {
      content: (
        <div>
          {' '}
          <Flag name="france" />
          French
        </div>
      ),
      key: 'fr',
      text: (
        <Label
          content={<Flag name="france" />}
          onRemove={(e: any, lp: any) => {
            e.stopPropagation()
            this.removeCard(lp.value)
          }}
        />
      ),
      value: 'French'
    },
    {
      content: (
        <div>
          {' '}
          <Flag name="spain" />
          Spanish
        </div>
      ),
      key: 'es',
      text: (
        <Label
          content={<Flag name="spain" />}
          onRemove={(e: any, lp: any) => {
            e.stopPropagation()
            this.removeCard(lp.value)
          }}
        />
      ),
      value: 'Spanish'
    },
    {
      content: (
        <div>
          {' '}
          <Flag name="germany" />
          German
        </div>
      ),
      key: 'de',
      text: (
        <Label
          content={<Flag name="germany" />}
          onRemove={(e: any, lp: any) => {
            e.stopPropagation()
            this.removeCard(lp.value)
          }}
        />
      ),
      value: 'German'
    },
    {
      content: (
        <div>
          {' '}
          <Flag name="china" />
          Chinese
        </div>
      ),
      key: 'cn',
      text: (
        <Label
          content={<Flag name="china" />}
          onRemove={(e: any, lp: any) => {
            e.stopPropagation()
            this.removeCard(lp.value)
          }}
        />
      ),
      value: 'Chinese'
    }
  ]
  public state = {
    badges: [],
    contractDocument: '',
    email: '',
    firstName: '',
    joiningDate: '',
    openDatePicker: false,
    changeJoiningDate: false,
    languages: [],
    lastName: '',
    lastlogin: moment(),
    mobileNo: '',
    office: '',
    rank: 0,
    referrals: {
      joined: 0,
      pending: 0
    },
    selectedLanguages: [],
    selectedTransactionOption: {
      key: '',
      text: '',
      value: ''
    },
    storageLimit: 0,
    storageUnit: '',
    storageUsed: 0,
    totalEmployees: 0,
    transactionsOptions: [],
    updatedData: {} as UserProfileType,
    user: {} as UserType
  }

  public async componentDidMount() {
    this.setState({ ...Data })
    const { userProfile } = this.props
    const user: UserType = await getLoggedInUser({ fromCache: true })
    this.setState({ user, joiningDate: moment(userProfile.joiningDate).format('MM/DD/YYYY') })
    let data: any = {}
    if (userProfile.languages !== null) {
      this.setState({ selectedLanguages: userProfile.languages })
    }
    const res = await getTransactionsSetting()
    if (res) {
      const transactionsOptions: Options[] = []
      res.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`
        }
        transactionsOptions.push(obj)
      })
      this.setState({ transactionsOptions })
    }
    this.setState({
      selectedTransactionOption: {
        key: userProfile.transactionFeeSetting[`_id`],
        text: userProfile.transactionFeeSetting[`name`],
        value: `${userProfile.transactionFeeSetting[`name`]}, ${userProfile.transactionFeeSetting[`_id`]}`
      }
    })
    if (userProfile.contractDocument && userProfile.contractDocument !== null) {
      data = await client.query({
        query: GET_PRE_SIGNED_URL,
        variables: { url: userProfile.contractDocument }
      })
      this.setState({ contractDocument: data.data.preSignedUrl })
    }
  }

  public render() {
    const { openReferralPopup, userProfile, showEditButton, toggleTab, profileProgress, storageUsed } = this.props
    const {
      user,
      firstName,
      lastName,
      rank,
      totalEmployees,
      referrals,
      email,
      mobileNo,
      selectedLanguages,
      contractDocument,
      openDatePicker,
      changeJoiningDate,
      transactionsOptions,
      selectedTransactionOption,
      joiningDate
    } = this.state
    return (
      <UserInfo>
        <PersonalInfo
          profileProgress={profileProgress}
          userProfile={userProfile}
          showEditButton={showEditButton}
          firstName={firstName}
          lastName={lastName}
          rank={rank}
          totalEmployees={totalEmployees}
          referrals={referrals}
          email={email}
          mobileNo={mobileNo}
          openReferralPopup={openReferralPopup}
        />
        <BottomSegment>
          <ReferSegment>{UserInfoStrings.referFriend}</ReferSegment>
          {userProfile.office &&
            userProfile.office[0] &&
            userProfile.office[0].branchName && (
              <Office>
                <ImageWrapper backgroundImage={IconMap} className={'icon'} />
                {`Office: ${userProfile.office[0].branchName}`}
              </Office>
            )}
          <ContractDocument>
            <div style={{ flex: 1 }}>
              <ImageWrapper backgroundImage={IconDocument} className={'icon'} />
              {UserInfoStrings.contractDocument}
            </div>
            {toggleTab ? (
              <div>Uploading...</div>
            ) : (
                <div>
                  {contractDocument &&
                    contractDocument !== null && (
                      <span style={{ marginRight: 10 }} onClick={this.downloadSampleFile}>
                        <FontAwesomeIcon icon={['fas', 'arrow-alt-to-bottom']} />
                      </span>
                    )}
                  {user.role === 'ADMIN' && (
                    <span onClick={this.handleFile}>
                      <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
                    </span>
                  )}
                </div>
              )}
          </ContractDocument>
          <CommissionPlan>
            <div style={{ flex: 1, wordBreak: 'break-word' }}>Commission Plan:</div>
            {user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER' ? (
              <Form.Field
                control={Select}
                options={transactionsOptions}
                placeholder="Plan Name"
                value={selectedTransactionOption[`value`]}
                onChange={this.commissionPlanUpdate}
              />
            ) : (
                userProfile.transactionFeeSetting[`name`]
              )}
          </CommissionPlan>
          <DataItem>
            <div>{UserInfoStrings.lastLogin}:</div>
            <div>
              {userProfile.lastLogin ? moment(userProfile.lastLogin).format('MM/DD/YYYY') : UserInfoStrings.notLoggedIn}
            </div>
          </DataItem>
          <DataItem>
            <div>{UserInfoStrings.memberSince}:</div>
            <div>
              {user[`role`] === 'ADMIN' ? (
                !changeJoiningDate ? (
                  <span
                    onClick={() => {
                      this.setState({
                        changeJoiningDate: true,
                      })
                    }}
                  >
                    {joiningDate
                      ? moment(joiningDate).format('MM/DD/YYYY')
                      : UserInfoStrings.notLoggedIn}
                  </span>
                ) : (
                    <span>
                      <Input
                        size='mini'
                        className='date'
                        name='joiningDate'
                        placeholder='Joining Date'
                        value={
                          joiningDate
                            ? moment(joiningDate).format('L')
                            : ''
                        }
                        onClick={() => {
                          this.setState({
                            openDatePicker: true,
                          })
                        }}
                        autoComplete='off'
                      />
                      <DatePicker
                        open={openDatePicker}
                        handleOpen={() => null}
                        handleClose={() => null}
                        onChangeDate={(value: Date) =>
                          this.onChangeDateForExpected(value)
                        }
                      />
                    </span>
                  )
              ) : joiningDate ? (
                moment(joiningDate).format('MM/DD/YYYY')
              ) : (
                    UserInfoStrings.notLoggedIn
                  )}
            </div>
          </DataItem>
          <DataItem>
            <div>{UserInfoStrings.languagesSpoken}:</div>
            <div>
              <Dropdown
                disabled={user.role === 'GUEST' ? true : false}
                placeholder="Languages"
                multiple={true}
                value={selectedLanguages}
                fluid={true}
                selection={true}
                options={this.options}
                onChange={this.handleChange}
              />
            </div>
          </DataItem>
          <DataItem>
            <div>{UserInfoStrings.storage}</div>
            <StyledProgress percent={Math.round((storageUsed / 100) * 100)} size={'tiny'} />
            <div style={{ paddingBottom: 10 }}>{`${storageUsed} MB of 100 MB used`}</div>
          </DataItem>
          <input
            type="file"
            ref={(node: any) => {
              this.textInput = node
            }}
            id="hidden-new-document"
            accept="application/pdf"
            style={{ display: 'none' }}
            name="documentName"
            onChange={(e: any) => {
              this.setDocument(e)
            }}
          />
        </BottomSegment>
      </UserInfo>
    )
  }

  public commissionPlanUpdate = async (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const { userID } = this.props
    const result = value.split(', ')
    this.setState({
      selectedTransactionOption: {
        key: result[1],
        text: result[0],
        value: `${result[0]}, ${result[1]}`
      }
    })
    const newObj = {
      transactionFeeSetting: result[1]
    }
    const userId = {
      _id: userID
    }
    await updateProfile(newObj, userId)
  }

  public downloadSampleFile = () => {
    const { contractDocument } = this.state
    if (contractDocument) {
      window.open(contractDocument, '_blank')
    }
  }

  public setDocument = async (e: any) => {
    const { userProfile, showToggleTab } = this.props
    showToggleTab(true)
    const file = e.target.files[0]
    const newObj = {
      contractDocument: file
    }
    let data: any = {}
    const response = await updateProfile(newObj, userProfile)
    if (response) {
      showToggleTab(false)
    }
    data = await client.query({
      query: GET_PRE_SIGNED_URL,
      variables: { url: response.data.updateUser[0].contractDocument }
    })
    this.setState({ contractDocument: data.data.preSignedUrl })
  }

  public handleFile = () => {
    const { user } = this.state
    if (user.role === 'ADMIN') {
      this.textInput.click()
    }
  }

  public removeCard = (data: string) => {
    const { selectedLanguages }: any = this.state
    const newObj = {} as UserProfileType
    const newLanguages = selectedLanguages.filter((lang: string) => lang !== data)
    newObj[`languages`] = newLanguages
    this.setState({ selectedLanguages: newLanguages, updatedData: newObj }, () => this.updateProfile())
  }

  private handleChange = (event: React.SyntheticEvent<EventTarget>, data: any) => {
    if (data.value.length > 0) {
      let { selectedLanguages }: any = this.state
      const newObj = {} as UserProfileType
      selectedLanguages = data.value
      newObj[`languages`] = selectedLanguages
      this.setState({ selectedLanguages, updatedData: newObj }, () => this.updateProfile())
    }
  }

  private updateProfile = async () => {
    const { userProfile } = this.props
    const { updatedData } = this.state
    await updateProfile(updatedData, userProfile)
  }

  private onChangeDateForExpected = async (date: Date) => {
    const newObj = {} as UserProfileType
    newObj[`joiningDate`] = date
    this.setState({ updatedData: newObj, joiningDate: moment(date).format('MM/DD/YYYY') }, () => this.updateProfile())
    this.handleClose()
  }

  private handleClose = () => {
    this.setState({
      openDatePicker: false,
      changeJoiningDate: false
    })
  }
}
