import gql from 'graphql-tag'

export const UPDATE_USER = gql`
  mutation updateUser(
    $_id: ID
    $officeId: [String]
    $isPrimaryManager: Boolean
    $status: String
    $role: String
    $teamCommissionSplit: Float
    $isActive: Boolean
    $firstName: String
    $lastName: String
    $email: EmailInput
    $phone: PhoneInput
    $individualResidentialCap: Int
    $individualCommercialCap: Int
    $capSetting: String
    $group: String
    $transactionFeeSetting: String
    $type: String
  ) {
    updateUser(
      where: { _id: $_id }
      input: {
        teamCommissionSplit: $teamCommissionSplit
        office: $officeId
        isPrimaryManager: $isPrimaryManager
        status: $status
        role: $role
        group: $group
        isActive: $isActive
        firstName: $firstName
        lastName: $lastName
        email: $email
        phones: $phone
        individualResidentialCap: $individualResidentialCap
        individualCommercialCap: $individualCommercialCap
        capSetting: $capSetting
        transactionFeeSetting: $transactionFeeSetting
        type: $type
      }
    ) {
      _id
      firstName
      lastName
      emails {
        type
        value
      }
      office {
        _id
        isDefault
        isActive
        branchName
      }
      userName
      hasTeam
      isPrimaryManager
      capSetting {
        _id
        name
      }
      profileImage
      role
      status
      teamCommissionSplit
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      transactionFeeSetting {
        _id
        name
      }
      phones {
        type
        value
      }
      type
    }
  }
`

export const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($officeId: [String], $status: String) {
    updateUser(
      where: { office: $officeId, isActive: true }
      input: { status: $status }
    ) {
      _id
      firstName
      lastName
      userName
      hasTeam
      role
      isPrimaryManager
      capSetting {
        _id
        name
      }
      profileImage
      status
      emails {
        type
        value
      }
      phones {
        type
        value
      }
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String
    $lastName: String
    $userName: String
    $phone: PhoneInput
    $office: [String]
    $individualResidentialCap: Int
    $individualCommercialCap: Int
    $capSetting: String
    $userOrder: Int
    $officeGroupUserOrder: Int
    $teamGroupUserOrder: Int
    $userGroupUserOrder: Int
    $userGroup: String
    $officeGroup: String
    $teamGroup: String
    $group: String
    $password: String
    $transactionFeeSetting: String
  ) {
    createUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        userName: $userName
        phones: $phone
        office: $office
        individualResidentialCap: $individualResidentialCap
        individualCommercialCap: $individualCommercialCap
        capSetting: $capSetting
        userOrder: $userOrder
        officeGroupUserOrder: $officeGroupUserOrder
        teamGroupUserOrder: $teamGroupUserOrder
        userGroupUserOrder: $userGroupUserOrder
        userGroup: $userGroup
        officeGroup: $officeGroup
        teamGroup: $teamGroup
        group: $group
        password: $password
        transactionFeeSetting: $transactionFeeSetting
      }
    ) {
      _id
      firstName
      lastName
      emails {
        type
        value
      }
      userName
      hasTeam
      isPrimaryManager
      capSetting {
        _id
        name
      }
      profileImage
      role
      status
      teamCommissionSplit
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      phones {
        type
        value
      }
      transactionFeeSetting {
        _id
        name
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($userId: ID) {
    deleteUser(userParams: { _id: $userId })
  }
`

export const UPDATE_USER_ORDER = gql`
  mutation updateUserOrder(
    $board: String
    $cardId: ID
    $userOrderDestination: Int
    $userOrderSource: Int
    $officeGroupUserOrderDestination: Int
    $officeGroupUserOrderSource: Int
    $teamGroupUserOrderDestination: Int
    $teamGroupUserOrderSource: Int
    $userGroupUserOrderDestination: Int
    $userGroupUserOrderSource: Int
  ) {
    updateUserOrder(
      where: {
        _id: $cardId
        group: $board
        userOrder: $userOrderSource
        officeGroupUserOrder: $officeGroupUserOrderSource
        teamGroupUserOrder: $teamGroupUserOrderSource
        userGroupUserOrder: $userGroupUserOrderSource
      }
      input: {
        userOrder: $userOrderDestination
        officeGroupUserOrder: $officeGroupUserOrderDestination
        teamGroupUserOrder: $teamGroupUserOrderDestination
        userGroupUserOrder: $userGroupUserOrderDestination
      }
    ) {
      _id
      firstName
      lastName
      emails {
        type
        value
      }
      office {
        _id
        isDefault
        isActive
        branchName
      }
      capSetting {
        _id
        name
      }
      userName
      role
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      status
      phones {
        type
        value
      }
    }
  }
`

export const TRANSFER_USER = gql`
  mutation transferUser(
    $userId: ID
    $officeId: [String]
    $groupId: String
    $officeGroupId: String
    $isPrimaryManager: Boolean
    $userOrder: Int
    $officeGroupUserOrder: Int
    $teamGroupUserOrder: Int
    $userGroupUserOrder: Int
    $firstName: String
    $lastName: String
    $email: EmailInput
    $phone: PhoneInput
    $individualResidentialCap: Int
    $individualCommercialCap: Int
    $capSetting: String
    $transactionFeeSetting: String
  ) {
    transferUser(
      where: { _id: $userId }
      input: {
        office: $officeId
        group: $groupId
        officeGroup: $officeGroupId
        isPrimaryManager: $isPrimaryManager
        userOrder: $userOrder
        officeGroupUserOrder: $officeGroupUserOrder
        teamGroupUserOrder: $teamGroupUserOrder
        userGroupUserOrder: $userGroupUserOrder
        firstName: $firstName
        lastName: $lastName
        email: $email
        phones: $phone
        individualResidentialCap: $individualResidentialCap
        individualCommercialCap: $individualCommercialCap
        capSetting: $capSetting
        transactionFeeSetting: $transactionFeeSetting
      }
    ) {
      _id
      firstName
      lastName
      emails {
        type
        value
      }
      office {
        _id
        isDefault
        isActive
        branchName
      }
      userName
      hasTeam
      isPrimaryManager
      capSetting {
        _id
        name
      }
      profileImage
      role
      status
      teamCommissionSplit
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      transactionFeeSetting {
        _id
        name
      }
      phones {
        type
        value
      }
    }
  }
`

export const IMPORT_USERS = gql`
  mutation importUsers(
    $group: String
    $officeGroup: String
    $teamGroup: String
    $userGroup: String
    $office: [String]
    $file: Upload
  ) {
    importUsers(
      input: {
        group: $group
        officeGroup: $officeGroup
        teamGroup: $teamGroup
        userGroup: $userGroup
        office: $office
      }
      file: $file
    )
  }
`

export const EXPORT_USERS = gql`
  query exportUsers(
    $group: String
    $officeGroup: String
    $teamGroup: String
    $userGroup: String
  ) {
    exportUsers(
      where: {
        group: $group
        officeGroup: $officeGroup
        teamGroup: $teamGroup
        userGroup: $userGroup
      }
    )
  }
`

export const CHANGE_PASSWORD = gql`
  mutation updatePassword(
    $userId: ID
    $oldPassword: String
    $newPassword: String
  ) {
    updatePassword(
      userParams: { _id: $userId }
      newPassword: $newPassword
      oldPassword: $oldPassword
    )
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($newPassword: String, $token: String) {
    resetPassword(input: { newPassword: $newPassword, token: $token })
  }
`

export const USER_REASSIGN = gql`
  mutation transferUser($userId: ID, $officeId: [String]) {
    transferUser(where: { _id: $userId }, input: { office: $officeId }) {
      _id
      firstName
      lastName
      emails {
        type
        value
      }
      office {
        _id
        isDefault
        isActive
        branchName
      }
      userName
      hasTeam
      isPrimaryManager
      capSetting {
        _id
        name
      }
      profileImage
      role
      status
      teamCommissionSplit
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      transactionFeeSetting {
        _id
        name
      }
      phones {
        type
        value
      }
    }
  }
`
