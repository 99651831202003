// Import Packages
import { lastIndexOf } from 'lodash'
import * as React from 'react'
import { Grid } from 'semantic-ui-react'

// Import Images and Icons
import { CustomeColor, WallpaperImage } from 'store/Dashboard/Types'

import { deleteUserColorWallpaper, updateUserWallpaper } from './CustomizeMutations'
import { getUserWallpaper } from './CustomizeQueries'

// Import Components
import Modal from 'shared/Modal'
import ImageUpload from './ImageUpload'
import PickColor from './PickColor'

// Import Styled Components
import { Container } from './Styled'

interface Props {
  addCustomeColor: (newData: CustomeColor) => void
  addWallpaperImage: (newData: WallpaperImage) => void
  closeModal: () => void
}

interface State {
  animate: boolean
  colors: string[]
  id: string
  imageUrls: string[]
}

class Customize extends React.Component<Props, State> {
  public state = {
    animate: true,
    colors: [],
    id: '',
    imageUrls: []
  }

  public async componentDidMount() {
    const response = await getUserWallpaper()
    this.setState({ imageUrls: response.imageUrls, colors: response.colors, id: response._id })
  }

  public render() {
    const { animate, imageUrls, colors } = this.state
    return (
      <Modal
        content={
          <Container>
            <Grid columns={2} divided={true}>
              <Grid.Row>
                <Grid.Column className="first-column">
                  <PickColor
                    deleteColor={this.deleteColor}
                    defaultColor={this.defaultColor}
                    color={colors}
                    addNewColor={this.addNewColor}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ImageUpload
                    deleteImage={this.deleteImage}
                    defaultUrl={this.defaultUrl}
                    wallpaper={imageUrls}
                    addWallpaperImage={this.addWallpaperImage}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={700}
      />
    )
  }

  private deleteImage = async (image: string) => {
    const { imageUrls, id } = this.state
    const deleteImage = imageUrls.filter(e => e !== image)
    const newData = {
      imageUrls: deleteImage
    }
    await deleteUserColorWallpaper(id, newData)
    this.setState({ imageUrls: deleteImage })
  }

  private deleteColor = async (color: string) => {
    const { colors, id } = this.state
    const index = lastIndexOf(colors, color)
    const deleteColor = colors.splice(0, index)
    const newData = {
      colors: deleteColor
    }
    await deleteUserColorWallpaper(id, newData)
    this.setState({ colors: deleteColor })
  }

  private defaultColor = async (color: string) => {
    const { id } = this.state
    const newData = {
      defaultColor: color
    }
    const response = await updateUserWallpaper(id, newData)
    this.setState({ imageUrls: response.imageUrls, colors: response.colors })
  }

  private defaultUrl = async (image: string) => {
    const { id } = this.state
    const newData = {
      defaultUrl: image
    }
    const response = await updateUserWallpaper(id, newData)
    this.setState({ imageUrls: response.imageUrls, colors: response.colors })
  }

  private addWallpaperImage = async (image: string[]) => {
    const { id } = this.state
    const newData = {
      file: image
    }
    const response = await updateUserWallpaper(id, newData)
    this.setState({ imageUrls: response.imageUrls, colors: response.colors })
  }

  private addNewColor = async (color: string) => {
    const { id } = this.state
    const newData = {
      colors: color
    }
    const response = await updateUserWallpaper(id, newData)
    this.setState({ imageUrls: response.imageUrls, colors: response.colors })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default Customize
