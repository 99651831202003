// Import Packages
import { Form, Input, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const CloseWrapper = styled.div`
  height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
`

const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  margin-top: 0px;
  z-index: 1;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.2);
`

const PopUpContent = styled.div`
  padding: 0 !important;
`

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const Filters = styled.div`
  width: 35%;
  position: relative;
  background: ${Colors.Grey110};
`

const FiltersTitle = styled.div`
  font-size: 10px;
  margin-top: 15px;
  text-align: center;
`

const StyledMenu = styled(Menu)`
  &.ui.vertical.menu {
    background-color: ${Colors.transparent};
    box-shadow: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    margin-left: 15px;
    cursor: pointer;
    width: 84%;
    border-top-color: ${Colors.DarkBlue275};
    border-bottom-color: ${Colors.DarkBlue275};
    margin-bottom: 30px;
  }

  &.ui.vertical.menu .item:before {
    background: ${Colors.DarkBlue275};
  }

  &.ui.vertical.menu .item {
    color: ${Colors.Black550};
    font-size: 12px;
  }

  &.ui.vertical.menu .active.item {
    background: ${Colors.transparent};
    border-radius: 0;
    color: ${Colors.DarkBlue200};
    font-weight: bold;
  }
`

const StyledForm = styled(Form)`
  width: 70%;
  padding: 30px;

  .ui.fluid.dropdown {
    margin-bottom: 20px;
    margin-top: 6px;
  }

  &.ui.form .field > label {
    font-size: 12px;
    color: ${Colors.Black550};
  }

  .ui.button {
    background: ${Colors.DarkBlue200};
    border-radius: 3px;
    color: ${Colors.White1000};
  }

  a.ui.label {
    color: ${Colors.Blue60};
    position: relative;
    background-color: ${Colors.White490};
    font-size: 10px;
    min-width: 38px;
    width: auto;
    margin-bottom: 5px;
  }

  .ui.action.input > .button {
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }

  .fa-times {
    position: absolute;
    top: -4px;
    right: 2px;
    height: 10px;
    width: 8px;
    color: ${Colors.Black530};
  }
`

const Type = styled.div`
  margin-bottom: 20px;

  .type {
    font-size: 12px;
    color: ${Colors.Black550};
    font-weight: 700;
    margin-bottom: 6px;
  }

  .icons {
    display: flex;
  }

  .fa-envelope {
    color: ${Colors.Red20};
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  .fa-sms {
    color: ${Colors.Pink70};
    width: 20px;
    height: 20px;
  }

  .fa-phone {
    color: ${Colors.Yellow70};
    transform: rotate(100deg);
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
`

const SearchContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  background: white;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 3px 5px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.06);
`

const SearchInput = styled(Input)`
  &.ui.input > input {
    max-width: 95%;
    font-size: 12px;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.06);
  }

  .fa-search {
    color: ${Colors.Black570};
    cursor: pointer;
    position: absolute;
    line-height: 1;
    text-align: center;
    top: 0;
    margin: 0;
    height: 100%;
    width: 1.071429em;
    opacity: 0.5;
  }

  .fa-times {
    color: ${Colors.Black570};
    cursor: pointer;
    position: absolute;
    line-height: 1;
    text-align: center;
    top: 0;
    right: 6px;
    margin: 0;
    height: 100%;
    width: 0.671429em;
    opacity: 0.5;
  }

  &.ui.input {
    width: 100%;
  }

  &.ui.icon.input > input {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`

const Tag = styled.div`
  background: ${Colors.DarkBlue200};
  padding: 3px 20px 3px 8px;
  font-size: 14px;
  color: ${Colors.White1000};
  position: relative;
  white-space: nowrap;
  margin-right: 5px;

  .times {
    position: absolute;
    top: -3px;
    right: 3px;
  }

  .times > .fa-times {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`

const Plus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: ${Colors.Black550};
`

const TagContainer = styled.div`
  display: flex;
  overflow: auto;
  max-width: 250px;

  ::-webkit-scrollbar {
    height: 3px;
  }

  @media (max-width: 1200px) {
    max-width: 150px;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    max-width: 250px;
  }
`

const SearchWrapper = styled.div`
  width: 40%;
  position: relative;
`

const Filter = styled.div`
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 600;
  color: ${Colors.DarkBlue205};
`

export {
  CloseWrapper,
  Container,
  Filters,
  FiltersTitle,
  PopUpContent,
  StyledContainer,
  StyledForm,
  StyledMenu,
  Type,
  Plus,
  SearchContainer,
  SearchWrapper,
  SearchInput,
  Tag,
  TagContainer,
  Filter
}
