import { BUYER_1 } from './Buyer01'
import { BUYER_2 } from './Buyer02'
import { BUYER_3 } from './Buyer03'
import { FOLLOW_UP_1 } from './FollowUp01'
import { NO_TEMPLATE } from './NoTemplate'
import { REFERRAL_1 } from './Referral01'
import { REFERRAL_2 } from './Referral02'
import { REFERRAL_3 } from './Referral03'
import { REFERRAL_4 } from './Referral04'
import { SELLER_1 } from './Seller01'
import { SELLER_2 } from './Seller02'
import { SELLER_3 } from './Seller03'
import { SELLER_4 } from './Seller04'

export const emailMessageTemplateOptions = [
  { key: 0, text: '(no template)', value: 0, template: NO_TEMPLATE },
  { key: 1, text: BUYER_1.subject, value: 1, template: BUYER_1 },
  { key: 2, text: BUYER_2.subject, value: 2, template: BUYER_2 },
  { key: 3, text: BUYER_3.subject, value: 3, template: BUYER_3 },
  { key: 4, text: SELLER_1.subject, value: 4, template: SELLER_1 },
  { key: 5, text: SELLER_2.subject, value: 5, template: SELLER_2 },
  { key: 6, text: SELLER_3.subject, value: 6, template: SELLER_3 },
  { key: 7, text: SELLER_4.subject, value: 7, template: SELLER_4 },
  { key: 8, text: FOLLOW_UP_1.subject, value: 8, template: FOLLOW_UP_1 },
  { key: 9, text: REFERRAL_1.subject, value: 9, template: REFERRAL_1 },
  { key: 10, text: REFERRAL_2.subject, value: 10, template: REFERRAL_2 },
  { key: 11, text: REFERRAL_3.subject, value: 11, template: REFERRAL_3 },
  { key: 12, text: REFERRAL_4.subject, value: 12, template: REFERRAL_4 }
]
