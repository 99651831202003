import { Reducer } from 'redux'
import Actions from 'store/UserProfile/ActionsTypes'
import {
  addCreditDebit,
  deleteCreditDebit,
  profilePercents,
  updateCreditDebit
} from './ReduxUtil'
import { ProfilePassFilter, UserProfile } from './Types'

const initialState: UserProfile = {
  creditDebit: [],
  profileProgress: 0,
  storageUsed: 0,
  userPassProfileFilter: {} as ProfilePassFilter
}

const UserProfileReducer: Reducer<UserProfile> = (
  state: UserProfile = initialState,
  action
) => {
  switch (action.type) {
    case Actions.getUserCreditDebit:
      return {
        ...state,
        creditDebit: action.payload.data
      }
    case Actions.addUserCreditDebit:
      return {
        ...state,
        creditDebit: addCreditDebit(state, action.payload.data)
      }
    case Actions.deleteUserCreditDebit:
      return {
        ...state,
        creditDebit: deleteCreditDebit(state, action.payload.data)
      }
    case Actions.updateUserCreditDebit:
      return {
        ...state,
        creditDebit: updateCreditDebit(state, action.payload.data)
      }
    case Actions.profilePercents:
      return {
        ...state,
        profileProgress: profilePercents(state, action.payload.data)
      }
    case Actions.userPassProfileFilter:
      return {
        ...state,
        userPassProfileFilter: action.payload.filters
      }
    case Actions.userStorageUsed:
      return {
        ...state,
        storageUsed: action.payload.data
      }
    default:
      return state
  }
}

export default UserProfileReducer
