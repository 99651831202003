// Import Packages
import moment from 'moment'
import * as React from 'react'

// Import Components
import ActionButtons from 'shared/Tasks/ActionButtons'

// Import Components
import ViewDetailsModal from 'app/Tasks/ViewDetailsModal'

// Import Store Types, Actions and Reducers
import { TaskCardDetails } from 'store/Tasks/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Card,
  CircleIcon,
  Container,
  ContentRow,
  DueDate,
  DueRow,
  Footer,
  Labels,
  Li,
  MemberProfile,
  Notes,
  RelatedTo,
  Sidebar,
  StyledLabel,
  StyledNotes,
  StyledPopup,
  TaskDecription,
  Title,
  Ul
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faPhone, faSms } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone, faSms])

interface Props {
  boardId: string
  CardData: TaskCardDetails
}

interface State {
  modal: any
}

const CardStyle = {
  Call: {
    color: Colors.Blue50,
    icon: 'phone'
  },
  Email: {
    color: Colors.Red25,
    icon: 'envelope'
  },
  Meeting: {
    color: Colors.Purple50,
    icon: 'handshake'
  },
  Pass: {
    color: Colors.Orange110,
    icon: 'ticket'
  },
  Sms: {
    color: Colors.Green100,
    icon: 'comment'
  }
}

class KanbanCard extends React.Component<Props, State> {
  public state = {
    modal: null
  }

  public render() {
    const {
      CardData,
      CardData: { activities, notes },
      boardId
    } = this.props
    const { modal } = this.state

    const Members = CardData.sharedWith.map((items: any, index: any) => {
      while (index < 3) {
        return (
          <Li key={index}>
            <StyledPopup
              trigger={<MemberProfile key={index} background={items.profileImage} />}
              content={`${items.firstName} ${items.lastName}`}
            />
          </Li>
        )
      }
      return null
    })

    const Count =
      CardData.sharedWith.length > 3 ? (
        <Labels>
          <StyledPopup trigger={<StyledLabel>...</StyledLabel>} content={`+${CardData.sharedWith.length - 3}`} />
        </Labels>
      ) : null

    return (
      <Container>
        <Card>
          <ContentRow>
            <DueRow>
              {CardData.dueDate ? (
                <DueDate
                  background={
                    moment(CardData.dueDate).isBefore(new Date()) && CardData.status !== 'Completed'
                      ? Colors.Red810
                      : Colors.White1000
                  }
                  color={
                    moment(CardData.dueDate).isBefore(new Date()) && CardData.status !== 'Completed'
                      ? Colors.White1000
                      : Colors.Black550
                  }
                  important={moment(CardData.dueDate).isBefore(new Date())}
                  progress={CardStyle[CardData.type].color}
                >
                  <span>{moment(CardData.dueDate).format('L')}</span>
                </DueDate>
              ) : (
                  <div />
                )}
              <CircleIcon>
                <FontAwesomeIcon icon={['fal', CardStyle[CardData.type].icon]} />
              </CircleIcon>
            </DueRow>
            <Title>{CardData.name}</Title>
            <RelatedTo>
              {Strings.tasks.relatedTo}{' '}
              {CardData.lead && Object.keys(CardData.lead).length > 0
                ? `${CardData.lead.firstName} ${CardData.lead.lastName}`
                : 'Not Related Yet'}
            </RelatedTo>
            <TaskDecription>{CardData.description}</TaskDecription>
          </ContentRow>
          <Sidebar className="sidebar">
            <ActionButtons
              actions={['complete', 'create', 'forward', 'options', 'reply', 'trash']}
              boardId={boardId}
              cardData={CardData}
            />
          </Sidebar>
        </Card>
        <Footer>
          <StyledNotes style={{ marginRight: 10 }} onClick={() => this.showDetails(1)}>
            {Strings.tasks.notes}
            <Notes circular={true}>{notes && notes !== null ? notes.length : 0}</Notes>
          </StyledNotes>
          <StyledNotes onClick={() => this.showDetails(0)}>
            {Strings.tasks.activity}
            <Notes circular={true}>{activities && activities !== null ? activities.length : 0}</Notes>
          </StyledNotes>
          <Ul style={{ display: 'flex', flex: '1 1 0%' }}>
            {Members}
            <Li>{Count}</Li>
          </Ul>
        </Footer>
        {modal}
      </Container>
    )
  }

  private toggleModal = async (modal: any = null) => {
    this.setState({ modal })
  }

  private showDetails = async (index: number) => {
    const { CardData } = this.props
    const user = await getLoggedInUser({ fromCache: true })
    const modal = (
      <ViewDetailsModal
        closeModal={() => this.toggleModal(null)}
        activeIndex={index}
        taskID={CardData._id}
        user={user}
      />
    )
    this.toggleModal(modal)
  }
}

export default KanbanCard
