import * as React from 'react'
import { connect } from 'react-redux'

import LoadingIndicator from 'shared/LoadingIndicator'
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Modal from 'shared/Modal'
import StyledGrid from 'shared/StyledBoard/GridView'
import KanbanView from 'shared/StyledBoard/KanbanView'
import { serverToast } from 'shared/Toast/Toast'

import AddOfficeModal from './AddOfficeModal'
import {
  CustomActions,
  CustomBranchName,
  CustomCheckBox,
  CustomHeaderCheckBox,
  CustomLocation,
  CustomManager,
  CustomStatus,
  CustomTeamMembers
} from './CustomComponents'
import DeleteOfficeModal from './DeleteOfficeModal'
import { Icons } from './Icons'
import OfficeCard from './OfficeCard'
import StyledHeader from './StyledHeader'
import ViewDetailsModal from './ViewDetailsModal'

import { isPlanAllowed } from 'shared/Billing/Queries'
import {
  addNewOffice,
  changeOfficeStatus,
  deactivateOffice,
  reorderCard,
  reorderLane,
  ToggleCardStatus,
  ToggleListStatus,
  transferOffice
} from './OfficeMutations'
import {
  addNewCard,
  ChangeToggleCheckBox,
  getBoardsOptions,
  resetData,
  restoreData,
  sortOffice,
  ToggleSingleCheckBox
} from './OfficeQueries'
import OfficeQuery from './OfficeQuery'

import { GET_OFFICES, GET_OFFICES_ON_STATES } from 'queries/graphql/Offices/Queries'
import { GET_STATE_OFFICES, GET_STATES_OFFICES } from 'queries/graphql/States/Queries'
import client, { cacheData } from 'queries/apollo'

import { AppState } from 'store/CombineReducers'
import * as Dashboard from 'store/Dashboard/Actions'
import * as officesActions from 'store/Offices/Actions'
import * as Actions from 'store/Reports/Actions'
import OfficeAction from './Actions'

import { getLoggedInUser, Strings } from 'utils'
import { formatKanbanData, formatListData } from './Utils/FormattingData'

import { Container, Error, ErrorContainer, ErrorText } from './Styled'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { Filter, FiltersData, OfficeDetails, OfficeItemsDetails, OptionsType, UserType } from 'store/Offices/Types'

interface StoreProps {
  setViewReport: (report: string) => void
  setBoardData: (data: OfficeDetails[]) => void
  setShowOfficeDetail: (data: string) => void
  setSearchDataFilter: (data: FiltersData) => void
  data: OfficeDetails[]
  searchLoader: boolean
  filter: Filter
  searchData: FiltersData
  officeID: string
}

type Props = StoreProps

interface State {
  boardName?: string
  officeOptions: OptionsType[]
  editOfficeCard: OfficeItemsDetails
  edit: boolean
  showAddContactModal: boolean
  showViewDetailsModal: boolean
  showDeleteOfficeModal: boolean
  deleteOfficeId: string
  boardId: string
  checked: boolean
  show: boolean
  id: string
  index: number
  appearance: boolean
  officeSort: boolean
  addOfficeBoardName: string
  list: boolean
  deleteTransfer: boolean
  officeFilter: boolean
  showComposeMail: string
  stateFilterValue: string
  loader: boolean
  user: UserType
}

export let ToggleStatus: any
export let ToggleOfficeStatus: any
export let ShowViewDetails: any
export let ToggleCheckBox: any
export let deleteCard: any
export let editOffice: any
export let sortOffices: any
export let SingleCheckBox: any
export let ShowComposeMail: any
export let ShowOfficeReport: any

class Office extends React.Component<Props, State> {
  public state = {
    addOfficeBoardName: '',
    appearance: true,
    boardId: '',
    checked: false,
    deleteOfficeId: '',
    deleteTransfer: true,
    edit: false,
    editOfficeCard: {} as OfficeItemsDetails,
    id: '',
    index: 0,
    list: false,
    listViewData: [],
    loader: true,
    officeFilter: false,
    officeOptions: [],
    officeSort: false,
    show: true,
    showAddContactModal: false,
    showComposeMail: '',
    showDeleteOfficeModal: false,
    showViewDetailsModal: false,
    stateFilterValue: 'Choose',
    user: {} as UserType
  }

  public columnMetaData = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.office.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomBranchName,
      enhanceWithRowData: true,
      id: Strings.office.branchNames,
      title: Strings.office.BranchName
    },
    {
      customComponent: CustomLocation,
      enhanceWithRowData: true,
      id: Strings.office.location,
      sortable: false,
      title: Strings.office.Location
    },
    {
      customComponent: CustomManager,
      enhanceWithRowData: true,
      id: Strings.office.manager,
      title: Strings.office.Manager
    },
    {
      customComponent: CustomTeamMembers,
      enhanceWithRowData: true,
      id: Strings.office.teamMemberse,
      sortable: false,
      title: Strings.office.TeamMembers
    },
    {
      customComponent: CustomStatus,
      enhanceWithRowData: true,
      id: Strings.office.status,
      title: Strings.office.Status
    },
    {
      customComponent: CustomActions,
      enhanceWithRowData: true,
      id: Strings.office.action,
      sortable: false,
      title: Strings.office.Action
    }
  ]

  public async componentDidMount() {
    const { setSearchDataFilter } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({ user })
    setSearchDataFilter({})
    const options = await getBoardsOptions()
    this.setState({ officeOptions: options })
    this.refreshGetOffices()
    this.assignToggleStatusMethod()
    this.assignShowViewDetailsModal()
    this.assignToggleCheckBox()
    this.deleteOffice()
    this.editOffice()
    this.sortStateOffices()
    this.assignSingleCheckBox()
    this.assignShowComposeMail()
    this.assignShowOfficeReport()
  }

  public render() {
    const { searchLoader, data: kanbanData, officeID } = this.props
    const { loader } = this.state
    const {
      showAddContactModal,
      showViewDetailsModal,
      showDeleteOfficeModal,
      appearance,
      editOfficeCard,
      edit,
      addOfficeBoardName,
      id,
      deleteOfficeId,
      boardId,
      list,
      deleteTransfer,
      showComposeMail,
      user
    } = this.state
    return (
      <React.Fragment>
        {showComposeMail && <ComposeModal onClose={this.closeEmailComposeModal} defaultEmail={showComposeMail} />}
        {showAddContactModal && (
          <AddOfficeModal
            addOfficeBoardName={addOfficeBoardName}
            edit={edit}
            officeDetails={editOfficeCard}
            onClose={this.addNewOffice}
          />
        )}
        {(showViewDetailsModal || officeID) && (
          <ViewDetailsModal closeModal={this.closeModal} officeId={officeID ? officeID : id} />
        )}
        {showDeleteOfficeModal && (
          <DeleteOfficeModal
            closeModal={this.closeModal}
            officeId={deleteOfficeId}
            boardId={boardId}
            deleteOffice={this.delete}
            deactivateOffice={this.deactivate}
            isList={list}
          />
        )}
        <Container>
          {(loader || searchLoader) && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
          <StyledHeader
            user={user}
            addList={this.addList}
            showDefaultView={this.showDefaultView}
            officeOptions={this.state.officeOptions}
            getStateOffices={this.getStateOffices}
            filterValue={this.state.stateFilterValue}
            changeOfficesStatus={this.changeOfficesStatus}
          />
          {this.state.show ? (
            <KanbanView
              data={kanbanData}
              CardComponent={OfficeCard}
              ActionComponent={OfficeAction}
              reorderCard={this.reorderOffices}
              reorderColumn={this.reorderColumn}
              addNewCard={this.addNewCard}
              icons={Icons}
              alterData={this.alterData}
              deleteCard={this.deleteCard}
              resetCardData={this.resetCardData}
              restoreCard={this.restoreCard}
              appearance={appearance}
              deleteTransfer={deleteTransfer}
              updateBoard={this.updateBoard}
            />
          ) : (
            <OfficeQuery query={GET_OFFICES}>
              {({ loading, error, data }) => {
                if (loading) {
                  return <LoadingIndicator type={IndicatorTypeEnum.Spinner} />
                }
                if (error) {
                  return (
                    <Modal
                      content={
                        <ErrorContainer>
                          <Error>{Strings.office.error}</Error>
                          <ErrorText>{Strings.office.somethingWentWrong}</ErrorText>
                        </ErrorContainer>
                      }
                      closeModal={this.closeModal}
                      width={400}
                    />
                  )
                }
                if (data && data.getOffices) {
                  const listViewData = formatListData(data.getOffices)
                  return (
                    <StyledGrid
                      data={listViewData}
                      tableHeight={50}
                      columnMetaData={this.columnMetaData}
                      addList={this.addList}
                    />
                  )
                }
                return null
              }}
            </OfficeQuery>
          )}
        </Container>
      </React.Fragment>
    )
  }

  private refreshGetOffices = async (id?: string, singleSkip?: number) => {
    const { setBoardData, searchData } = this.props
    const { show, officeSort, stateFilterValue } = this.state
    this.setState({ loader: true })
    if (show) {
      let result
      if (stateFilterValue === 'All' || stateFilterValue === 'Choose') {
        result = await client.query({
          fetchPolicy: 'network-only',
          query: GET_STATES_OFFICES,
          variables: { sortOrder: 1, orderField: 'officeOrder', filter: searchData, id, singleSkip }
        })
      } else {
        result = await client.query({
          query: GET_STATE_OFFICES,
          variables: {
            id: stateFilterValue.split(', ')[0],
            filter: searchData,
            sortOrder: 1,
            orderField: 'officeOrder',
            boardId: id ? id : undefined,
            singleSkip
          }
        })
      }
      const { data }: any = result
      if (data && data.getStates) {
        const kanbanData = formatKanbanData(data.getStates, officeSort)
        setBoardData(kanbanData)
        this.setState({ loader: false })
      }
    } else {
      let result
      if (stateFilterValue === 'All' || stateFilterValue === 'Choose') {
        result = await client.query({
          fetchPolicy: 'network-only',
          query: GET_OFFICES,
          variables: { filter: searchData }
        })
      } else {
        result = await client.query({
          query: GET_OFFICES_ON_STATES,
          variables: { state: stateFilterValue.split(', ')[1], filter: searchData }
        })
      }
      const { data }: any = result
      if (data && data.getOffices) {
        cacheData.writeQuery({
          data: { getOffices: data.getOffices },
          query: GET_OFFICES
        })
        this.setState({ loader: false })
      }
    }
  }

  private assignShowComposeMail = () => {
    ShowComposeMail = (email: string) => {
      this.setState({ showComposeMail: email })
    }
  }

  private assignShowOfficeReport = () => {
    ShowOfficeReport = (report: string) => {
      const { setViewReport } = this.props
      setViewReport(report)
    }
  }

  private showDefaultView = async (data: boolean) => {
    this.setState({ show: data, stateFilterValue: 'Choose' }, () => {
      this.refreshGetOffices()
    })
  }

  private assignToggleCheckBox = () => {
    ToggleCheckBox = () => {
      this.setState({ checked: !this.state.checked })
      const { checked } = this.state
      ChangeToggleCheckBox(checked)
    }
  }

  private assignSingleCheckBox = () => {
    SingleCheckBox = (id: string) => {
      ToggleSingleCheckBox(id)
    }
  }

  private addList = async () => {
    try {
      await isPlanAllowed('office')
      this.setState({ showAddContactModal: true, edit: false, addOfficeBoardName: '' })
    } catch (error) {
      serverToast(error)
    }
  }

  private alterData = (data: any) => {
    const { setBoardData } = this.props
    setBoardData(data)
  }

  private assignToggleStatusMethod = () => {
    ToggleStatus = async (boardId: string, cardId: string, status: string) => {
      await ToggleCardStatus(boardId, cardId, status)
      this.refreshGetOffices()
    }
    ToggleOfficeStatus = async (cardId: string, status: string) => {
      await ToggleListStatus(cardId, status)
      this.refreshGetOffices()
    }
  }

  private assignShowViewDetailsModal = () => {
    ShowViewDetails = (cardId: string) => {
      this.setState({ showViewDetailsModal: true, id: cardId })
    }
  }

  private resetCardData = async () => {
    await resetData()
    this.refreshGetOffices()
  }

  private restoreCard = async (boardId: string, cardId: string) => {
    await restoreData(cardId)
    this.refreshGetOffices()
  }

  private deleteOffice = () => {
    deleteCard = (board: string, cardId: string) => {
      this.setState({
        boardId: board,
        deleteOfficeId: cardId,
        list: true,
        showDeleteOfficeModal: true
      })
    }
  }

  private delete = async (newOfficeId: string) => {
    const { deleteOfficeId, show } = this.state
    const options = await transferOffice(newOfficeId, deleteOfficeId, show)
    this.setState({ officeOptions: options })
    this.refreshGetOffices()
    this.setState({ stateFilterValue: `All` })
  }

  private deactivate = async (officeId: string) => {
    await deactivateOffice(officeId)
    this.refreshGetOffices()
  }

  private editOffice = () => {
    editOffice = (officeDetail: OfficeItemsDetails) => {
      this.setState({
        addOfficeBoardName: '',
        edit: true,
        editOfficeCard: officeDetail,
        showAddContactModal: true
      })
    }
  }

  private deleteCard = (boardId: string, cardId: string) => {
    this.setState({
      boardId,
      deleteOfficeId: cardId,
      showDeleteOfficeModal: true
    })
  }

  private closeModal = () => {
    const { setShowOfficeDetail } = this.props
    this.setState({
      showAddContactModal: false,
      showComposeMail: '',
      showDeleteOfficeModal: false,
      showViewDetailsModal: false
    })
    setShowOfficeDetail('')
  }

  private closeEmailComposeModal = () => {
    this.setState({
      showComposeMail: ''
    })
  }

  private addNewOffice = async (newOfficeData: OfficeItemsDetails) => {
    this.closeModal()
    this.setState({ loader: true })
    if (!newOfficeData) {
      this.setState({ loader: false })
      return
    }

    const { id, index, edit, show } = this.state
    let options = []
    try {
      options = await addNewOffice(newOfficeData, id, index, edit, show)
    } catch (e) {
      // return null
    }
    this.setState({ loader: false })
    this.setState({ stateFilterValue: 'Choose' })
    if (options.length) {
      this.setState({ officeOptions: options })
    } else {
      options = await getBoardsOptions()
      this.setState({ officeOptions: options })
    }
    this.refreshGetOffices()
  }

  private addNewCard = async (id: string, index: string) => {
    try {
      await isPlanAllowed('office')

      const state = addNewCard(id)
      this.setState({
        addOfficeBoardName: state,
        edit: false,
        editOfficeCard: {} as OfficeItemsDetails,
        id,
        index: parseInt(index),
        showAddContactModal: true
      })
    } catch (error) {
      serverToast(error)
    }
  }

  private reorderOffices = async (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
    const { stateFilterValue } = this.state
    this.setState({ officeSort: false })
    if (stateFilterValue === 'All' || stateFilterValue === 'Choose') {
      await reorderCard(boardId, cardId, sourceIndex, destinationIndex)
      this.refreshGetOffices()
    }
  }

  private reorderColumn = async (boardId: string, sourceIndex: number, destinationIndex: number) => {
    const { stateFilterValue } = this.state
    if (stateFilterValue === 'All' || stateFilterValue === 'Choose') {
      reorderLane(boardId, sourceIndex, destinationIndex)
    }
  }

  private sortStateOffices = async () => {
    sortOffices = async (boardId: string, sortOrder: number) => {
      const { setBoardData } = this.props
      this.setState({ officeSort: true })
      const response = await sortOffice(boardId, sortOrder)
      if (boardId !== '') {
        const kanbanData = formatKanbanData(response.data.getStates, true)
        setBoardData(kanbanData)
      } else {
        const kanbanData = formatKanbanData(response.data.getOffices, true)
        setBoardData(kanbanData)
      }
    }
  }

  private getStateOffices = async (id: string, state: string) => {
    this.setState({ stateFilterValue: id === 'All' || id === 'Choose' ? id : `${id}, ${state}` }, () =>
      this.refreshGetOffices()
    )
  }

  private changeOfficesStatus = async (status: string) => {
    await changeOfficeStatus(status)
    this.refreshGetOffices()
    this.setState({ checked: false })
  }

  private updateBoard = async (id: string, singleSkip: number) => {
    this.refreshGetOffices(id, singleSkip)
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.offices.data,
  filter: state.offices.filter,
  officeID: state.dashboard.officeID,
  searchData: state.offices.searchData,
  searchLoader: state.offices.searchLoader
})

export default connect(
  mapStateToProps,
  {
    setBoardData: officesActions.getBoardData,
    setSearchDataFilter: officesActions.searchDataFilter,
    setShowOfficeDetail: Dashboard.showOfficeDetail,
    setViewReport: Actions.viewReport
  }
)(Office)
