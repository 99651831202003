// Import Packages
import Calendar from 'react-calendar'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const StyledCalendar = styled(Calendar)`
  &.react-calendar {
    border: none;
  }

  .react-calendar__navigation {
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    margin-bottom: 0;
  }

  .react-calendar__navigation button {
    color: ${Colors.White1000};
  }

  .react-calendar__month-view {
    background: ${Colors.White800};
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 0;
  }

  &.ui.top.popup:before {
    background: ${Colors.White800};
  }

  &.ui.bottom.popup:before {
    background: ${Colors.DarkBlue200};
  }
`

export { StyledCalendar, StyledPopup }
