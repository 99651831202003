import { ActionCreator } from 'redux'
import Actions from 'store/UserProfile/ActionsTypes'
import {
  AddUserCreditDebit,
  DeleteUserCreditDebit,
  GetUserCreditDebit,
  ProfilePassFilter,
  ProfilePercent,
  UpdateUserCreditDebit,
  UserPassProfileFilter,
  UserStorageUsed
} from './Types'

export const getUserCreditDebit: ActionCreator<GetUserCreditDebit> = data => ({
  payload: {
    data
  },
  type: Actions.getUserCreditDebit
})

export const addUserCreditDebit: ActionCreator<AddUserCreditDebit> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.addUserCreditDebit
})

export const deleteUserCreditDebit: ActionCreator<DeleteUserCreditDebit> = (
  data: string
) => ({
  payload: {
    data
  },
  type: Actions.deleteUserCreditDebit
})

export const updateUserCreditDebit: ActionCreator<UpdateUserCreditDebit> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.updateUserCreditDebit
})

export const profilePercent: ActionCreator<ProfilePercent> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.profilePercents
})

export const showUserPassProfileFilterData: ActionCreator<
  UserPassProfileFilter
> = (filters: ProfilePassFilter) => ({
  payload: {
    filters
  },
  type: Actions.userPassProfileFilter
})

export const showStorageUsed: ActionCreator<UserStorageUsed> = (
  data: number
) => ({
  payload: {
    data
  },
  type: Actions.userStorageUsed
})
