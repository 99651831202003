import client from 'queries/apollo'
import { getTransactionSettings_getTransactionSettings } from 'queries/schema/schemaTypes'
import { activeTransactionSettingIndex } from '../../../app/Settings/TransactionFee/TransactionFeeDetails/FeeSettings/FeeSettings'
import { GET_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Queries'

const calculatePropertyFee = (
  principal: number,
  base: number,
  baseMillionCap: number,
  additional: number,
  additionalMillionCap: number
) => {
  if (!principal) {
    principal = 0
  }
  if (!base) {
    base = 0
  }
  if (!additional) {
    additional = 0
  }
  const baseCap: number = baseMillionCap
  const additionalCap: number = additionalMillionCap
  let result: number = 0
  let testAmount: number = principal
  if (principal > 0 && baseCap > 0) {
    result = result + base
    testAmount = testAmount - baseCap
  }
  while (testAmount > 0 && additionalCap > 0) {
    testAmount = testAmount - additionalCap
    result = result + additional
  }
  return result
}

export const getPropertyFee = (
  getTransactionSettings: getTransactionSettings_getTransactionSettings,
  type: string,
  principal: number
) => {
  if (type === 'RESIDENTIAL') {
    const {
      residentialBaseFee,
      residentialFirstAmount,
      residentialAdditional,
      residentialIncrement
    } = getTransactionSettings
    return calculatePropertyFee(
      principal,
      residentialBaseFee,
      residentialFirstAmount,
      residentialAdditional,
      residentialIncrement
    )
  } else if (type === 'COMMERCIAL') {
    const {
      commercialBaseFee,
      commercialFirstAmount,
      commercialAdditional,
      commercialIncrement
    } = getTransactionSettings
    return calculatePropertyFee(
      principal,
      commercialBaseFee,
      commercialFirstAmount,
      commercialAdditional,
      commercialIncrement
    )
  } else {
    return 0
  }
}

export const getCommissionFee = (getTransactionSettings: any, expectedCommission: number): number => {
  const { commissionFeeType, progressiveFee } = getTransactionSettings
  let { flatFeeAmount, flatFeePercentage } = getTransactionSettings
  if (!flatFeeAmount) {
    flatFeeAmount = 0
  }
  if (!flatFeePercentage) {
    flatFeePercentage = 0
  }
  if (commissionFeeType === 'FLAT_FEE' && flatFeeAmount) {
    return flatFeeAmount
  } else if (commissionFeeType === 'FLAT_FEE_PERCENTAGE' && flatFeePercentage) {
    return expectedCommission * (flatFeePercentage / 100)
  } else if (commissionFeeType === 'HYBRID_FEE' && flatFeePercentage !== undefined && flatFeeAmount !== undefined) {
    return flatFeeAmount + expectedCommission * (flatFeePercentage / 100)
  } else if (commissionFeeType === 'PROGRESSIVE_FEE') {
    let result = 0
    let commission = expectedCommission
    if (progressiveFee) {
      for (let i = 0; i < progressiveFee!!.length; ++i) {
        let { minimum, percentage: rangePercent } = progressiveFee[i]
        const { maximum } = progressiveFee[i]
        if (i === 0 && minimum === 0) {
          minimum = 1
        }
        if (!rangePercent) {
          rangePercent = 0
        }
        if (commission <= maximum - minimum + 1 || i === progressiveFee.length - 1) {
          result = result + commission * (rangePercent / 100)
          commission = 0
        } else {
          result = result + (maximum - minimum + 1) * (rangePercent / 100)
          commission = commission - maximum
        }
      }
      return result
    } else {
      return 0
    }
  } else {
    return 0
  }
}

export const getOtherFee = (getTransactionSettings: any, expectedCommission: number): number => {
  const { otherFees } = getTransactionSettings
  let result: number = 0
  if (otherFees) {
    for (const dataItem of otherFees) {
      const { type, fee } = dataItem
      if (type === 'FIXED') {
        result = result + fee
      } else if (type === 'PERCENTAGE') {
        result = result + expectedCommission * (fee / 100)
      }
    }
    return result
  }
  return 0
}

const getRemoteData = () => {
  return client.query({
    query: GET_TRANSACTION_SETTING
  })
}

export const getResidentialCalculatedData = async () => {
  const { data }: any = await getRemoteData()
  const { getTransactionSettings, propertyFee, expectedCommission } = data
  const residentialAmount: number = getPropertyFee(
    getTransactionSettings[activeTransactionSettingIndex],
    'RESIDENTIAL',
    propertyFee
  )
  const commissionAmount: number = getCommissionFee(
    getTransactionSettings[activeTransactionSettingIndex],
    expectedCommission
  )
  const otherAmount: number = getOtherFee(getTransactionSettings[activeTransactionSettingIndex], expectedCommission)
  const totalFee: number = expectedCommission - (residentialAmount + commissionAmount + otherAmount)
  return {
    commissionFee: commissionAmount,
    expectedCommission,
    otherFee: otherAmount,
    propertyCost: propertyFee,
    propertyFee: residentialAmount,
    totalFee
  }
}

export const getCommercialCalculatedData = async () => {
  const { data }: any = await getRemoteData()
  const { getTransactionSettings, propertyFee, expectedCommission } = data
  const residentialAmount: number = getPropertyFee(
    getTransactionSettings[activeTransactionSettingIndex],
    'COMMERCIAL',
    propertyFee
  )
  const commissionAmount: number = getCommissionFee(
    getTransactionSettings[activeTransactionSettingIndex],
    expectedCommission
  )
  const otherAmount: number = getOtherFee(getTransactionSettings[activeTransactionSettingIndex], expectedCommission)
  const totalFee: number = expectedCommission - (residentialAmount + commissionAmount + otherAmount)
  return {
    commissionFee: commissionAmount,
    expectedCommission,
    otherFee: otherAmount,
    propertyCost: propertyFee,
    propertyFee: residentialAmount,
    totalFee
  }
}
