// Import Packages
import { History } from 'history'
import * as React from 'react'
import SpinningBubbles from 'react-loading'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Dimmer } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Toast from 'shared/Toast'
import { getIcons } from '../../Leads/Dashboard/LeadQueries'
import StyledGrid from 'shared/StyledBoard/GridView'
import KanbanView from 'shared/StyledBoard/KanbanView'
import ContactAction from './Actions'
import AddContactModal from './AddContactModal'
import ContactCard from './ContactCard'
import {
  createContact,
  deleteContact,
  deleteContactBoard,
  moveContact,
  reorderCard,
  reorderLane,
  restore,
  sortBoardContact,
  updateContactBoard
} from './ContactMutations'
import { exportUsers, getContactBoards, getContacts, getMyContactBoards, getMyContacts, reset } from './ContactQueries'
import {
  Checked,
  CustomActions,
  CustomAddedOn,
  CustomCheckBox,
  CustomContact,
  CustomContactName,
  CustomHeaderCheckBox,
  CustomOwner,
  CustomType
} from './CustomComponents'

import ImportContactsPopup from './ImportContactsPopup'
import StyledHeader from './StyledHeader'
import { formatKanbanData, formatListData } from './Utils/FormattingData'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Contacts/Actions'
import {
  ContactDetails,
  ContactItemsDetails,
  ContactListDetails,
  ContactPassFilter,
  Filter,
  IconsType,
  UserType
} from 'store/Contacts/Types'

import { getLoggedInUser, Routes, Strings } from 'utils'

import Colors from 'design/Colors'

import { Container } from './Styled'

interface StoreProps {
  setNewBoard: (index: number, newData: ContactDetails) => void
  setNewCard: (id: string, index: string, newData: ContactItemsDetails) => void
  setBoardData: (data: ContactDetails[]) => void
  setListData: (data: ContactListDetails[]) => void
  setResetCardData: () => void
  setRestoreCard: () => void
  setNewIcon: (id: string, icon: string) => void
  setNewDescription: (id: string, description: string) => void
  setNewBoardName: (id: string, boardName: string) => void
  setSortData: (boardId: string, sortOrder: number) => void
  setDeleteCard: (boardId: string, cardId: string) => void
  setDeleteBoard: (boardId: string) => void
  setDeleteListRecord: (cardId: string) => void
  setUpdateRecord: (newData: ContactDetails) => void
  setImportData: (boardId: string, data: ContactDetails) => void
  setToggleStatus: (open: boolean) => void
  setUserPassContactFilterData: (filter: ContactPassFilter) => void
  data: ContactDetails[]
  listData: ContactListDetails[]
  history: History
  searchLoader: boolean
  allContactStatus: boolean
  filter: Filter
  userPassContactFilter: ContactPassFilter
}

type Props = StoreProps & RouteComponentProps<{}, {}>

interface State {
  listViewData: ContactListDetails[]
  appearance: boolean
  boardName?: string
  showAddContactModal: boolean
  checked: boolean
  showContactDetails: boolean
  showImportFileModal: boolean
  show: boolean
  user: UserType
  addContactIndex: string
  addBoardId: string
  filterOptions: string
  detailContactData: ContactListDetails
  edit: boolean
  editContactDetails: ContactListDetails
  importContactBoardId: string
  icons: IconsType[]
  deletedCard: ContactListDetails
  loader: boolean
  showComposeMail: string
}

export let ToggleCheckBox: any
export let SingleCheckBox: any
export let ShowContactDetails: any
export let ShowImportFileModal: any
export let deleteContBoard: any
export let sortContacts: any
export let deleteRecord: any
export let editContact: any
export let exportUser: any
export let toggle: boolean = false
export let ShowComposeMail: any
export let loggedUser: any

class Contact extends React.Component<Props, State> {
  public state = {
    addBoardId: '',
    addContactIndex: '',
    appearance: true,
    boardName: '',
    checked: false,
    deletedCard: {} as ContactListDetails,
    detailContactData: {} as ContactListDetails,
    edit: false,
    editContactDetails: {} as ContactListDetails,
    filterOptions: 'myContacts',
    icons: [],
    importContactBoardId: '',
    listViewData: [],
    loader: true,
    show: true,
    showAddContactModal: false,
    showComposeMail: '',
    showContactDetails: false,
    showImportFileModal: false,
    user: {} as UserType
  }

  public columnMetaData = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.contact.checkbox,
      sortable: false,
      width: '1%'
    },
    {
      customComponent: CustomContactName,
      enhanceWithRowData: true,
      id: 'Contact Full Name',
      title: 'Contact Name'
    },
    {
      enhanceWithRowData: false,
      id: Strings.contact.sources,
      title: Strings.contact.Source
    },
    {
      customComponent: CustomType,
      enhanceWithRowData: true,
      id: Strings.contact.type,
      title: Strings.contact.Type
    },
    {
      customComponent: CustomOwner,
      enhanceWithRowData: true,
      id: Strings.contact.owners,
      title: Strings.contact.Owner
    },
    {
      customComponent: CustomContact,
      enhanceWithRowData: true,
      id: 'phone',
      title: Strings.contact.ContactNo
    },
    {
      customComponent: CustomAddedOn,
      enhanceWithRowData: true,
      id: Strings.contact.addedOns,
      title: Strings.contact.AddedOn
    },
    {
      customComponent: CustomActions,
      enhanceWithRowData: true,
      id: Strings.contact.action,
      sortable: false,
      title: Strings.contact.Action
    }
  ]

  public async componentDidMount() {
    const { setUserPassContactFilterData, location } = this.props
    const { allContactStatus } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = user
    toggle = allContactStatus
    if (location && location.search) {
      // showTransaction('')
    } else {
      setUserPassContactFilterData({} as ContactPassFilter)
    }
    this.refreshGetContacts()
    const iconsList = await getIcons()
    this.setState({ icons: iconsList, user })
    this.assignToggleCheckBox()
    this.assignSingleCheckBox()
    this.assignShowContactDetails()
    this.assignShowImportFileModal()
    this.deleteLane()
    this.SortBoardContacts()
    this.deleteContactRecord()
    this.editContactRecord()
    this.Export()
    this.assignShowComposeMail()

    window.onpopstate = () => {
      if (ShowContactDetails) {
        this.props.history.push(Routes.primary.contacts.path)
      }
      this.props.history.push(Routes.primary.dashboard.path)
    }
  }

  public render() {
    const { data, listData, setImportData, searchLoader, allContactStatus } = this.props
    const {
      appearance,
      showComposeMail,
      showAddContactModal,
      show,
      loader,
      showContactDetails,
      showImportFileModal,
      addBoardId,
      listViewData,
      user,
      icons,
      importContactBoardId,
      edit,
      editContactDetails
    } = this.state
    return (
      <Container>
        {(loader || searchLoader) && (
          <Dimmer active={true} inverted={true}>
            <SpinningBubbles type={'spinningBubbles'} color={Colors.DarkBlue200} />
          </Dimmer>
        )}
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={showComposeMail} />}
        {showImportFileModal && (
          <ImportContactsPopup
            closeModal={this.closeModal}
            boardId={importContactBoardId}
            filterOptions={allContactStatus}
            user={user}
            importData={setImportData}
          />
        )}
        {showAddContactModal && (
          <AddContactModal
            closeModal={this.closeModal}
            addBoardId={addBoardId}
            createContact={this.addContact}
            edit={edit}
            editContactDetails={editContactDetails}
            filterOptions={allContactStatus}
            user={user}
          />
        )}
        {!showContactDetails && (
          <StyledHeader
            user={user}
            addList={this.addList}
            showDefaultView={this.showDefaultView}
            deleteContacts={this.deleteContacts}
            toggleStatus={allContactStatus}
            changeToggle={this.changeToggleStatus}
          />
        )}
        {!showContactDetails &&
          show &&
          data.length > 0 && (
            <KanbanView
              data={data}
              CardComponent={ContactCard}
              ActionComponent={ContactAction}
              addNewCard={this.addNewCard}
              icons={icons}
              deleteCard={this.deleteCard}
              deleteRecord={this.deleteContactCard}
              resetCardData={this.resetCardData}
              restoreCard={this.restoreCard}
              alterData={this.alterData}
              // setNewBoard={allContactStatus === false ? this.setNewBoard : undefined}
              reorderColumn={this.reorderColumn}
              reorderCard={this.reorderContact}
              appearance={appearance}
              deleteTransfer={false}
              moveCard={this.moveContact}
              setNewBoardName={this.setNewBoardName}
              setNewIcon={this.setNewIcon}
              setNewDescription={this.setNewDescription}
              updateBoard={this.updateBoard}
            />
          )}
        {!showContactDetails &&
          !show &&
          listViewData && (
            <StyledGrid data={listData} tableHeight={50} columnMetaData={this.columnMetaData} addList={this.addList} />
          )}
      </Container>
    )
  }

  private refreshGetContacts = async (id?: string, singleSkip?: number) => {
    const { setBoardData, setListData, userPassContactFilter, location } = this.props
    this.setState({ loader: true })
    const user: any = await getLoggedInUser({ fromCache: true })
    if (toggle === true) {
      const kanbanData = await getContactBoards(undefined, id, singleSkip)
      const listView = await getContacts()
      const formatedData = await formatKanbanData(kanbanData, toggle)
      const formatedListData = await formatListData(listView, toggle)
      setBoardData(formatedData)
      setListData(formatedListData)
      if (kanbanData && listView) {
        this.setState({
          loader: false
        })
      }
    } else {
      const kanbanData = await getMyContactBoards(
        user,
        Object.keys(userPassContactFilter).length > 0 && location && location.search
          ? userPassContactFilter
          : undefined,
        id,
        singleSkip
      )
      const listView = await getMyContacts(
        user,
        Object.keys(userPassContactFilter).length > 0 && location && location.search ? userPassContactFilter : undefined
      )
      const formatedData = await formatKanbanData(kanbanData, toggle)
      const formatedListData = await formatListData(listView, toggle)
      setBoardData(formatedData)
      setListData(formatedListData)
      if (kanbanData && listView) {
        this.setState({
          loader: false
        })
      }
    }
  }

  private assignShowComposeMail = () => {
    ShowComposeMail = (email: string) => {
      this.setState({ showComposeMail: email })
    }
  }

  private showDefaultView = async (data: boolean) => {
    const { filter } = this.props
    this.setState({ show: data })
    if (filter.filter === false && filter.searchText === false) {
      this.refreshGetContacts()
    }
  }

  private changeToggleStatus = async () => {
    const { allContactStatus, setToggleStatus, filter } = this.props
    toggle = !allContactStatus
    setToggleStatus(!allContactStatus)
    if (filter.filter === false && filter.searchText === false) {
      this.refreshGetContacts()
    }
  }

  private assignShowImportFileModal = () => {
    ShowImportFileModal = (boardId: string) => {
      this.setState({ showImportFileModal: true, importContactBoardId: boardId })
    }
  }

  private assignShowContactDetails = () => {
    ShowContactDetails = (contactData: ContactListDetails) => {
      this.setState({ showContactDetails: true, detailContactData: contactData })
    }
  }

  private assignToggleCheckBox = () => {
    ToggleCheckBox = () => {
      const { setListData, listData } = this.props
      const { checked } = this.state
      this.setState({ checked: !this.state.checked }, () => {
        const newData = listData.map((dataItem: any) => {
          return {
            ...dataItem,
            checked: this.state.checked
          }
        })
        setListData(newData)
      })
      Checked(checked)
    }
  }

  private Export = () => {
    exportUser = (boardId: string) => {
      const { allContactStatus } = this.props
      const { user } = this.state
      exportUsers(boardId, allContactStatus, user)
    }
  }

  private assignSingleCheckBox = () => {
    SingleCheckBox = (id: string) => {
      const { setListData, listData } = this.props
      const newData = listData.map((dataItem: any) => {
        return {
          ...dataItem,
          checked: dataItem._id === id ? !dataItem.checked : dataItem.checked
        }
      })
      setListData(newData)
    }
  }

  private addList = () => {
    this.setState({ showAddContactModal: true })
  }

  private resetCardData = () => {
    const { setResetCardData } = this.props
    setResetCardData()
    reset()
  }

  private restoreCard = (cardId: string) => {
    const { setRestoreCard, setResetCardData } = this.props
    setRestoreCard()
    setResetCardData()
    restore(cardId)
    reset()
  }

  private deleteCard = (boardId: string, cardId: string) => {
    const { setDeleteCard, data } = this.props
    data.forEach((row: any) => {
      if (row._id === boardId) {
        row.items.forEach((card: any) => {
          if (card._id === cardId) {
            this.setState({ deletedCard: card })
          }
        })
      }
    })
    setDeleteCard(boardId, cardId)
  }

  private deleteContactCard = (boardId: string, cardId: string) => {
    const { setRestoreCard, setResetCardData } = this.props
    const { user, deletedCard } = this.state
    const contactListId = []
    contactListId.push(cardId)
    if (user[`_id`] === deletedCard[`owner`]._id) {
      deleteContact(contactListId)
      setResetCardData()
      ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
    } else {
      setRestoreCard()
      setResetCardData()
      ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotDeleteLead, 'error')
    }
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    const field = 'name'
    setNewBoardName(id, boardName)
    updateContactBoard(id, boardName, field)
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    const field = 'description'
    setNewDescription(id, description)
    updateContactBoard(id, description, field)
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    const field = 'icon'
    setNewIcon(id, icon)
    updateContactBoard(id, icon, field)
  }

  private closeModal = () => {
    this.setState({ showAddContactModal: false, showImportFileModal: false, showComposeMail: '' })
  }

  private addNewCard = (id: string, index: string) => {
    this.setState({ showAddContactModal: true, addContactIndex: index, addBoardId: id })
  }

  private addContact = async (newContact: any) => {
    const { setNewCard, setUpdateRecord, allContactStatus } = this.props
    const { addContactIndex, addBoardId, show, user, edit } = this.state
    try {
      const response: any = await createContact(
        newContact,
        addContactIndex,
        addBoardId,
        show,
        user,
        allContactStatus,
        edit
      )
      if (edit) {
        setUpdateRecord(newContact)
      } else {
        setNewCard(addBoardId, addContactIndex, response.data.createContact)
      }
      this.setState({ editContactDetails: {} as ContactListDetails })
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }
    this.refreshGetContacts()
  }

  private moveContact = async (
    cardId: string,
    destinationIndex: number,
    destinationBoardId: string,
    sourceBoardId: string
  ) => {
    const { user } = this.state
    const { data, allContactStatus } = this.props
    let movedCard: any = {}
    data.forEach((row: any) => {
      if (row._id === sourceBoardId) {
        row.items.forEach((card: any) => {
          if (card._id === cardId) {
            movedCard = card
          }
        })
      }
    })
    if (movedCard.owner._id === user[`_id`]) {
      await moveContact(cardId, destinationBoardId, destinationIndex, user, allContactStatus)
    } else {
      this.refreshGetContacts()
      Toast({
        message:
          'You are not authorized to move card. Please contact your administrator if you need additional assistance',
        type: 'error'
      })
    }
  }

  private reorderContact = async (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
    const { allContactStatus } = this.props
    const { user } = this.state
    if (allContactStatus === true) {
      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
        reorderCard(boardId, cardId, sourceIndex, destinationIndex, user, allContactStatus)
      }
    } else {
      reorderCard(boardId, cardId, sourceIndex, destinationIndex, user, allContactStatus)
    }
  }

  private alterData = (data: ContactDetails[]) => {
    const { setBoardData } = this.props
    setBoardData(data)
  }

  private deleteLane = () => {
    deleteContBoard = async (boardId: string) => {
      const { setDeleteBoard } = this.props
      const { user } = this.state
      ConfirmAlert({
        cancelButtonText: Strings.kanbanView.noKeepIt,
        confirmButtonText: Strings.kanbanView.yesDeleteIt,
        showCancelButton: true,
        text: Strings.kanbanView.recoverBoard,
        title: Strings.kanbanView.sure,
        type: 'warning'
      }).then(result => {
        if (result.value) {
          setDeleteBoard(boardId)
          deleteContactBoard(boardId, user)
          ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedBoard, 'success')
        } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
          ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
        }
      })
    }
  }

  private SortBoardContacts = () => {
    sortContacts = (boardId: string, sortOrder: number) => {
      const { user } = this.state
      const { setSortData, allContactStatus } = this.props
      setSortData(boardId, sortOrder)
      sortBoardContact(boardId, sortOrder, user, allContactStatus)
    }
  }

  private reorderColumn = (boardId: string, sourceIndex: number, destinationIndex: number) => {
    const { allContactStatus } = this.props
    const { user } = this.state
    reorderLane(boardId, sourceIndex, destinationIndex, user, allContactStatus)
  }

  private deleteContactRecord = () => {
    deleteRecord = async (cardId: string) => {
      const { setDeleteListRecord, setBoardData, setListData, allContactStatus } = this.props
      const { user } = this.state
      setDeleteListRecord(cardId)
      const contactListId = []
      contactListId.push(cardId)
      deleteContact(contactListId)
      let kanbanData: ContactDetails[] = []
      let listView: ContactListDetails[] = []
      if (!allContactStatus) {
        kanbanData = await getMyContactBoards(user)
        listView = await getMyContacts(user)
      } else {
        kanbanData = await getContactBoards()
        listView = await getContacts()
      }
      const formatedData = await formatKanbanData(kanbanData, allContactStatus)
      const formatedListData = await formatListData(listView, allContactStatus)
      setBoardData(formatedData)
      setListData(formatedListData)
    }
  }

  private editContactRecord = () => {
    editContact = (details: ContactListDetails) => {
      this.setState({
        addBoardId: '',
        edit: true,
        editContactDetails: details,
        showAddContactModal: true
      })
    }
  }

  private deleteContacts = () => {
    const { listData } = this.props
    const deleteIds: string[] = []
    listData.forEach((list: any) => {
      if (list.checked === true) {
        deleteIds.push(list._id)
      }
    })
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: 'Remove selected Contact(s)',
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        deleteContact(deleteIds)
        this.refreshGetContacts()
        ConfirmAlert(Strings.kanbanView.deleted, 'Your Contact(s) has been deleted', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, 'Your Contact(s) is safe', 'error')
      }
    })
  }

  private updateBoard = async (id: string, singleSkip: number) => {
    this.refreshGetContacts(id, singleSkip)
  }
}

const mapStateToProps = (state: AppState) => ({
  allContactStatus: state.contacts.allContactStatus,
  data: state.contacts.data,
  filter: state.contacts.filter,
  listData: state.contacts.listData,
  searchLoader: state.contacts.searchLoader,
  userPassContactFilter: state.contacts.userPassContactFilter
})

export default connect(
  mapStateToProps,
  {
    setBoardData: Actions.getBoardData,
    setDeleteBoard: Actions.deleteBoard,
    setDeleteCard: Actions.deleteCard,
    setDeleteListRecord: Actions.deleteListRecord,
    setImportData: Actions.importData,
    setListData: Actions.getListData,
    setNewBoard: Actions.addNewBoard,
    setNewBoardName: Actions.setNewBoardName,
    setNewCard: Actions.addNewCard,
    setNewDescription: Actions.setNewDescription,
    setNewIcon: Actions.setNewIcon,
    setResetCardData: Actions.resetCardData,
    setRestoreCard: Actions.restoreCard,
    setSortData: Actions.sortData,
    setToggleStatus: Actions.toggleStatus,
    setUpdateRecord: Actions.editRecord,
    setUserPassContactFilterData: Actions.showUserPassContactFilterData
  }
)(Contact)
