import * as React from 'react'

import GridItem from './GridItem'
import ListItem from './ListItem'

import { getLoggedInUser } from 'utils'

import { Container } from './Styled'

import { PDFDocumentType } from 'shared/PDFViewer/Types'
import { ActionEnum } from './ActionButtons/Types'
import { PermissionEnum } from '../Types'

interface Props {
  docs: PDFDocumentType[]
  getPermission: (doc: PDFDocumentType) => Promise<PermissionEnum>
  gridActions: string[]
  listActions: string[]
  onAction: (action: ActionEnum, data: any) => void
  viewFormat: string
}

interface State {
  user: any
}

class Documents extends React.Component<Props, State> {
  public static defaultProps = {
    gridActions: ['delete'],
    listActions: ['delete'],
    viewFormat: 'grid'
  }

  public state = {
    user: null
  }

  public componentDidMount = async () => {
    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({ user })
  }

  public render = () => {
    const { docs, getPermission, gridActions, listActions, viewFormat } = this.props

    const { user } = this.state
    return (
      <Container>
        {viewFormat === 'grid' &&
          docs.map((doc: PDFDocumentType) => (
            <GridItem
              actions={gridActions}
              doc={doc}
              getPermission={getPermission}
              key={doc._id}
              onAction={this.handleAction}
              user={user}
            />
          ))}

        {viewFormat === 'list' &&
          docs.map((doc: PDFDocumentType) => (
            <ListItem
              actions={listActions}
              doc={doc}
              getPermission={getPermission}
              key={doc._id}
              onAction={this.handleAction}
              user={user}
            />
          ))}
      </Container>
    )
  }

  private handleAction = (action: ActionEnum, data: any) => {
    const { onAction } = this.props
    onAction(action, data)
  }
}

export default Documents
