// Import Packages
import * as QueryString from 'query-string'
import * as React from 'react'
import SpinningBubbles from 'react-loading'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { Dimmer } from 'semantic-ui-react'

// Import Components
import Scrollable from 'shared/Scrollable'
import Toast from 'shared/Toast'
import AddContactModal from '../Dashboard/AddContactModal'
import LeftPanel from './LeftPanel'
import RightLanel from './RightPanel'

// Import Mutations and Queries
import { createContact, deleteShareWithUser, shareWithUser } from '../Dashboard/ContactMutations'
import { getContactBoards, getContactDetails, getContacts, getMyContactBoards, getMyContacts } from '../Dashboard/ContactQueries'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Contacts/Actions'
import { ContactDetails, ContactListDetails, ContactShareWith, UserType } from 'store/Contacts/Types'
import { RouteParams } from './Types'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'
import { formatKanbanData, formatListData } from '../Dashboard/Utils/FormattingData'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  BackButton,
  StyledGrid
} from './Styled'

// Font Awesome Icons
import {
  faArrowCircleLeft,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faArrowCircleLeft])

export let loggedUser: any
export let toggleStatus: boolean

interface StoreProps {
  setShareWithUser: (data: ContactShareWith) => void
  setNewShareWithUser: (shareWithObj: ContactShareWith) => void
  setContactDetail: (data: ContactListDetails) => void
  setDeleteContactShareWithUser: (data: string) => void
  setBoardData: (data: ContactDetails[]) => void
  setListData: (data: ContactListDetails[]) => void
  setUpdateRecord: (newData: ContactListDetails) => void
  onBack: () => void
  contactData: ContactListDetails
  shareWith: ContactShareWith[]
  contactDetail: ContactListDetails
  allContactStatus: boolean
  activeIndex: number
}

type Props = StoreProps & RouteComponentProps<RouteParams>

interface State {
  details: ContactListDetails
  contactId: string
  showAddContactModal: boolean
  user: UserType
}

class ContactDetail extends React.Component<Props, State> {
  public state = {
    contactId: '',
    details: {} as ContactListDetails,
    showAddContactModal: false,
    user: {} as UserType
  }

  public refreshUpdateContact = async () => {
    const { setShareWithUser, setContactDetail, match, location } = this.props

    const contactId = match.params.id
    const details: any = await getContactDetails(contactId)
    const user: any = await getLoggedInUser({ fromCache: true })

    this.setState({
      contactId,
      details,
      user
    })

    const query = QueryString.parse(location.search, { parseNumbers: true })
    toggleStatus = !!query.pool

    setContactDetail(details)
    setShareWithUser(details.shareWith)
  }

  public async componentDidMount() {
    this.refreshUpdateContact()
  }

  public render() {
    const { allContactStatus, activeIndex } = this.props
    const { details, showAddContactModal, user } = this.state
    if (Object.keys(details).length > 0) {
      return (
        <Scrollable>
          {
            showAddContactModal && <AddContactModal user={user} filterOptions={allContactStatus} createContact={this.updateContact} closeModal={this.closeModal} edit={true} editContactDetails={details} />
          }
          <StyledGrid>
            <Grid.Row className='back-button-row'>
              <Link to={Routes.primary.contacts.path}>
                <BackButton ><FontAwesomeIcon icon={['fas', 'arrow-circle-left']} /><span>{Strings.generalText.back}</span></BackButton>
              </Link>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <LeftPanel showEditContactModal={this.showEditContactModal} contactData={details} deleteShareUser={this.deleteShareWithUser} shareUser={this.shareWithUser} toggleStatus={toggleStatus} />
              </Grid.Column>
              <Grid.Column width={12}>
                <RightLanel contactData={details} activeIndex={activeIndex} />
              </Grid.Column>
            </Grid.Row>
          </StyledGrid>
        </Scrollable>
      )
    } else {
      return (
        <Dimmer active={true} inverted={true}>
          <SpinningBubbles type={'spinningBubbles'} color={Colors.DarkBlue200} />
        </Dimmer>
      )
    }
  }

  private updateContact = async (updateContact: ContactListDetails) => {
    const { setBoardData, setListData, allContactStatus, setUpdateRecord } = this.props
    const { user } = this.state
    try {
      await createContact(updateContact, '', '', false, user, allContactStatus, true)
      setUpdateRecord(updateContact)
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }
    let res: ContactDetails[] = []
    let listData: ContactListDetails[] = []
    if (allContactStatus === true) {
      res = await getContactBoards()
      listData = await getContacts()
    } else {
      res = await getMyContactBoards(user)
      listData = await getMyContacts(user)
    }
    const formatedData = await formatKanbanData(res, allContactStatus)
    const formatedListData = await formatListData(listData, allContactStatus)
    setBoardData(formatedData)
    setListData(formatedListData)
    this.refreshUpdateContact()
  }

  private closeModal = () => {
    this.setState({ showAddContactModal: false })
  }

  private showEditContactModal = () => {
    this.setState({ showAddContactModal: true })
  }

  private deleteShareWithUser = async (userId: string) => {
    const { setDeleteContactShareWithUser } = this.props
    const { contactId } = this.state
    setDeleteContactShareWithUser(userId)
    await deleteShareWithUser(userId, contactId)
  }

  private shareWithUser = async (shareObj: ContactShareWith) => {
    const { setNewShareWithUser } = this.props
    const { contactId } = this.state
    try {
      const res = await shareWithUser(contactId, shareObj)
      setNewShareWithUser(res)
    } catch (error) {
      Toast({ message: 'Email already exists', type: 'error' })
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  activeIndex: state.leads.activeIndex,
  allContactStatus: state.contacts.allContactStatus,
  contactDetail: state.contacts.contactDetail,
  data: state.contacts.data,
  listData: state.contacts.listData,
  shareWith: state.contacts.shareWith,
  transactions: state.contacts.transactions
})

export default withRouter(connect(
  mapStateToProps,
  {
    setBoardData: Actions.getBoardData,
    setContactDetail: Actions.getContactDetail,
    setDeleteContactShareWithUser: Actions.deleteContactShareWithUser,
    setListData: Actions.getListData,
    setNewShareWithUser: Actions.addShareWithUser,
    setShareWithUser: Actions.getShareWithUser,
    setUpdateRecord: Actions.editRecord,
  }
)(ContactDetail))
