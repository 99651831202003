import gql from 'graphql-tag'

export const CREATE_EVENT = gql`
  mutation createEvent(
    $name: String
    $description: String
    $startDate: Date
    $endDate: Date
    $startTime: String
    $endTime: String
    $sharedWith: [String]
    $leadId: String
    $transactionId: String
    $type: String
  ) {
    createEvent(
      input: {
        name: $name
        description: $description
        startDate: $startDate
        endDate: $endDate
        startTime: $startTime
        endTime: $endTime
        sharedWith: $sharedWith
        lead: $leadId
        transaction: $transactionId
        type: $type
      }
    ) {
      _id
      type
      name
      description
      startDate
      endDate
      startTime
      endTime
      createdAt
      updatedAt
      createdBy {
        _id
        firstName
        lastName
        profileImage
        phones {
          type
          value
        }
      }
      type
      sharedWith {
        _id
        firstName
        lastName
        profileImage
      }
      lead {
        _id
        firstName
        lastName
        email
      }
      transaction {
        _id
        transactionId
        owner {
          _id
          firstName
          lastName
          userName
        }
      }
    }
  }
`

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $eventId: String
    $name: String
    $description: String
    $startDate: Date
    $endDate: Date
    $startTime: String
    $endTime: String
    $sharedWith: [String]
  ) {
    updateEvent(
      where: { _id: $eventId }
      input: {
        name: $name
        description: $description
        startDate: $startDate
        endDate: $endDate
        startTime: $startTime
        endTime: $endTime
        sharedWith: $sharedWith
      }
    ) {
      _id
      type
      name
      description
      startDate
      endDate
      startTime
      endTime
      createdAt
      updatedAt
      createdBy {
        _id
        firstName
        lastName
        profileImage
        phones {
          type
          value
        }
      }
      type
      sharedWith {
        _id
        firstName
        lastName
        profileImage
      }
      lead {
        _id
        firstName
        lastName
        email
      }
      transaction {
        _id
        transactionId
        owner {
          _id
          firstName
          lastName
          userName
        }
      }
    }
  }
`

export const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: String!) {
    deleteEvent(_id: $eventId)
  }
`
