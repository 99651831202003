import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, headerMargin, x, y } from 'design/Styled'
import styled from 'styled-components'

export const Container = styled.section`
  ${y};
  align-items: stretch;

  & > div {
    border-left: 1px solid ${Colors.EmphasisLine};
  }
  & > div:first-child {
    border: none;
  }
`

export const Title = styled.h3`
  ${headerMargin};
  font: ${fonts.xSmall.bold};
  color: ${Colors.Text};
`

export const DateItem = styled.span`
  ${x};
  ${children};
  ${headerMargin};
  font: ${fonts.xxSmall.regular};
  color: ${Colors.Black600};

  & :first-child {
    width: 20%;
    min-width: 70px;
  }
`
