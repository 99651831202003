import * as React from 'react'
import { connect } from 'react-redux'

import { Remove } from 'app/Dashboard/SidePanel/Transactions/Transactions'
import Button from 'shared/Button'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'

import Sidebar from 'app/Transactions/Details/Sidebar'
import SubNavigation from 'app/Transactions/Details/SubNavigation'
import { Activity, Commission, Documents, Notes, Workflow } from 'app/Transactions/Details/SubPages'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'

import {
  getTransactionCommission,
  getTransactionDetails,
  getTransactionDocChecklist as getChecklist,
  getTransactionDocument,
  getTransactionMessage
} from 'app/Transactions/Dashboard/TransactionQueries'

import { ActionEnum } from 'shared/DocumentZone/Documents/ActionButtons/Types'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { ChecklistItemType } from 'app/Transactions/Details/SubPages/Documents/Checklist/Types'
import { TransactionType, UserType } from 'app/Transactions/Details/Types'
import { Messages as MessageType } from 'store/Transactions/Types'

import { getLoggedInUser, Routes } from 'utils'

import {
  Container,
  Expander,
  Section,
  Subpage,
} from './Styled'

import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronDoubleLeft])

interface StoreProps {
  documentCount: number
  messageCount: any
  setChecklist: (data: ChecklistItemType) => void
  setCommission: (data: any) => void
  setDocumentCount: (count: number) => void
  setMessagesData: (messages: MessageType[]) => void
  setPaymentApprove: (approve: boolean) => void
  setPaymentReleased: (release: boolean) => void
  setShareUsers: (data: any) => void
  setTransaction: (transaction: TransactionType) => void
  setTransactionDocuments: (data: any) => void
  transaction: TransactionType
}

interface OwnProps {
  transactionId: string
  onClose: () => void
}

type Props = StoreProps & OwnProps

interface State {
  hideSidebar: boolean
  loading: boolean
  resizeTrigger: boolean
  subpage: string
  transition: boolean
  user: UserType
}

const {
  activity: { key: activityPath },
  commission: { key: commissionPath },
  documents: { key: documentsPath },
  messages: { key: messagesPath },
  workflow: { key: workflowPath }
} = Routes.transactions

class QuickViewModal extends React.Component<Props, State> {
  public state = {
    hideSidebar: false,
    loading: true,
    resizeTrigger: false,
    subpage: 'documents',
    transition: true,
    user: {} as UserType
  }

  public timer: any = null

  public componentDidMount = async () => {
    const { transaction, transactionId } = this.props

    const user: any = (await getLoggedInUser({ fromCache: true })) || ({} as UserType)
    this.setState({ user })

    if (transaction._id !== transactionId) {
      await this.refreshTransactionDetails()
    }
    this.setState({ loading: false })
  }

  public componentWillUnmount = () => {
    if (this.timer) {
      window.clearTimeout(this.timer)
    }
  }

  public render = () => {
    const { documentCount, messageCount, transaction } = this.props
    const { hideSidebar, loading, resizeTrigger, subpage, transition, user } = this.state

    return (
      <Modal
        content={
          <React.Fragment>
            {loading && <LoadingIndicator type={IndicatorTypeEnum.Banner} />}
            <Container>
              {transaction.owner && (
                <React.Fragment>
                  <Expander className={(hideSidebar ? 'hidden' : '') + ' ' + (loading ? 'loading delay-1' : 'delay-1')}>
                    <Section width={-1}>
                      <Sidebar transaction={transaction} />
                    </Section>
                    <Button.Bubble
                      icon={faChevronDoubleLeft}
                      className='expander'
                      onClick={this.toggleSidebar}
                      size={24}
                    />
                  </Expander>
                  <Section className={loading ? 'loading delay-2' : 'delay-2'} primary={true} width={-1}>
                    <SubNavigation
                      count={{
                        documents: documentCount,
                        notes: messageCount
                      }}
                      subpage={subpage}
                      onNavigate={this.handleSubNavigation}
                    />
                    <Subpage className={loading ? 'loading delay-2' : 'delay-2'}>
                      {subpage === documentsPath && (
                        <Documents
                          onAction={this.handleDocumentAction}
                          resizeTrigger={resizeTrigger}
                          transaction={transaction}
                        />
                      )}
                      {subpage === commissionPath && <Commission transaction={transaction} user={user} />}
                      {subpage === messagesPath && <Notes transactionId={transaction._id} />}
                      {subpage === workflowPath && <Workflow />}
                      {subpage === activityPath && <Activity transactionId={transaction._id} />}
                    </Subpage>
                  </Section>
                </React.Fragment>
              )}
            </Container>
          </React.Fragment>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        maximum={true}
      />
    )
  }

  private handleSubNavigation = (subpage: string) => {
    this.setState({ subpage })
  }

  private toggleSidebar = () => {
    this.setState(
      (prev: State) => ({
        hideSidebar: !prev.hideSidebar,
        resizeTrigger: true
      }),
      () => {
        this.timer = window.setTimeout(() => {
          this.setState({ resizeTrigger: false })
        }, 320)
      }
    )
  }

  private handleDocumentAction = (action: string, data: any) => {
    switch (action) {
      case ActionEnum.View:
        this.setState({ hideSidebar: true })
        break

      case ActionEnum.Close:
        this.setState({ hideSidebar: false })
        break

      default:
        break
    }
  }

  private refreshTransactionDetails = async () => {
    const {
      setChecklist,
      setCommission,
      setDocumentCount,
      setMessagesData,
      setPaymentApprove,
      setPaymentReleased,
      setShareUsers,
      setTransaction,
      setTransactionDocuments,
      transactionId: id
    } = this.props

    this.setState({ loading: true })

    const details: any = await getTransactionDetails(id)
    setTransaction(details[0])
    setShareUsers(details[0].shareWith)

    const messages: any = await getTransactionMessage(id)
    setMessagesData(messages)

    const response: any = await getTransactionCommission(id)
    if (details && response) {
      setCommission(response)
      setPaymentApprove(response.isApproved)
      setPaymentReleased(response.isReleased)
    }

    const checklist = await getChecklist(id)
    setChecklist(checklist)
    const documents: any = await getTransactionDocument(id)
    setTransactionDocuments(documents)

    const count = documents.filter((element: any) => !element.isViewed)
    setDocumentCount(count.length)

    this.setState({ loading: false })
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({
        transition: true
      })
      onClose()
      Remove()
    }, 300)
  }
}

const mapStateToProps = (state: AppState) => ({
  documentCount: state.transactions.documentCount,
  messageCount: state.transactions.messageCount,
  transaction: state.transactions.transactionDetail
})

export default connect(
  mapStateToProps,
  {
    setChecklist: Actions.getTransactionDocumentChecklist,
    setCommission: Actions.getCommission,
    setDocumentCount: Actions.documentCount,
    setMessagesData: Actions.getMessagesData,
    setPaymentApprove: Actions.paymentApprove,
    setPaymentReleased: Actions.paymentReleased,
    setShareUsers: Actions.getShareWithUser,
    setTransaction: Actions.getTransactionDetail,
    setTransactionDocuments: Actions.getTransactionDocuments
  }
)(QuickViewModal)
