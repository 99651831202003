import { Button, Radio } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, primaryStyledButton, text, x, y } from 'design/Styled'

export const Container = styled.div`
  ${y};
  width: 100%;
  height: 100%;
  padding: 1em;
`

export const Title = styled.h1`
  ${x};
  ${text};
  font: ${fonts.xLarge.bold};
  text-align: left;
  border-bottom: 2px solid ${Colors.EmphasisLine}
  margin-bottom: 0.8em;
  padding: 0.6em 0;
`

export const Col = styled.section`
  ${y};
`

export const Row = styled.div`
  ${x};
  ${children};
  flex-wrap: wrap;
  margin-bottom: 0.8em;
`

export const StyledRadio = styled(Radio)``

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
`
