import { History } from 'history'
import * as React from 'react'
import { Component } from 'react'
import { Button, Form } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'
import Strings from 'utils/Strings'
import CapsSettings from './CapsSettings'
import AddCustomPlanModal from './CustomPlan'
import FeeSettings from './FeeSettings'
import {
  Container,
  Modal,
  StyledTab,
  TransactionFeeDetailsWrapper
} from './Styled'
import { createTransactionSetting, deleteTransactionSetting } from './TransactionFeeMutations'
import { getTransactionsSetting } from './TransactionFeeQueries'

interface Props {
  history?: History
}

interface Options {
  key: string
  text: string
  value: string
}

interface State {
  addPlan: boolean
  deleteTransactionSettingId: string
  transactionsOptions: Options[]
  showDelete: boolean
  planAdded: boolean
  deleteTransactionSettingStatus: boolean
  activeIndex: number
}

class TransactionFeeDetails extends Component<Props, State> {

  public state = {
    activeIndex: 0,
    addPlan: false,
    deleteTransactionSettingId: '',
    deleteTransactionSettingStatus: false,
    planAdded: false,
    showDelete: false,
    transactionsOptions: []
  }

  public async componentDidMount() {
    const res = await getTransactionsSetting()
    if (res) {
      const transactionsOptions: Options[] = []
      res.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`,
        }
        transactionsOptions.push(obj)
      })
      this.setState({ transactionsOptions })
    }
  }

  public render() {
    const {
      activeIndex,
      addPlan,
      deleteTransactionSettingStatus,
      planAdded,
      showDelete,
      transactionsOptions,
    } = this.state

    const panes = [
      {
        menuItem: Strings.transactionFeeSettings.tabs.feeSettings,
        render: () => (
          <FeeSettings
            deleteTransactionSettingStatus={deleteTransactionSettingStatus}
            planAdded={planAdded}
            showDeletePlan={this.showDelete}
            transactionsOptions={transactionsOptions}
          />
        )
      },
      {
        menuItem: Strings.transactionFeeSettings.tabs.capSettings,
        render: () => <CapsSettings />
      },
    ]

    return (
      <TransactionFeeDetailsWrapper>
        {addPlan && (
          <AddCustomPlanModal
            closeModal={this.closeModal}
            createPlan={this.createPlan}
          />
        )}
        <Modal>
          <Container>
            <StyledTab
              activeIndex={activeIndex}
              menu={{ secondary: true, pointing: true }}
              onTabChange={this.handleTabChange}
              panes={panes}
            />
            <div
              className={'add-plan'}
              style={{ visibility: activeIndex === 1 ? 'hidden' : 'visible' }}
            >
              <Form.Field
                control={Button}
                content={'ADD PLAN'}
                onClick={this.addPlan}
              />
              {showDelete && (
                <Form.Field
                  control={Button}
                  content={'DELETE PLAN'}
                  onClick={this.deletePlan}
                />
              )}
            </div>
          </Container>
        </Modal>
      </TransactionFeeDetailsWrapper>
    )
  }

  private addPlan = () => {
    this.setState({ addPlan: true })
  }

  private handleTabChange = (e: any, { activeIndex }: any) => {
    this.setState({ activeIndex })
  }

  private deletePlan = () => {
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.transactionFeeSettings.tabs.recoverDeleteSettings,
      title: Strings.transactionFeeSettings.tabs.sureDeleteSettings,
      type: 'warning',
    }).then(async (result) => {
      if (result.value) {
        try {
          const { deleteTransactionSettingId } = this.state
          this.setState({ deleteTransactionSettingStatus: true })
          await deleteTransactionSetting(deleteTransactionSettingId)
          const res = await getTransactionsSetting()
          const transactionsOptions: Options[] = []
          res.forEach((element: any) => {
            const obj: Options = {
              key: element._id,
              text: element.name,
              value: `${element.name}, ${element._id}`,
            }
            transactionsOptions.push(obj)
          })
          this.setState({ transactionsOptions })
          this.showDelete(false, '')
          ConfirmAlert(
            Strings.kanbanView.deleted,
            Strings.transactionFeeSettings.tabs.deletedTransactionSetting,
            'success'
          )
        } catch (error) {
          ConfirmAlert(
            Strings.kanbanView.cancelled,
            Strings.transactionFeeSettings.tabs.errorDeletingTransactionSetting,
            'error'
          )
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(
          Strings.kanbanView.cancelled,
          Strings.transactionFeeSettings.tabs.safeTransactionSetting,
          'error'
        )
      }
    })
  }

  private closeModal = () => {
    this.setState({ addPlan: false })
  }

  private createPlan = async (transaction: any) => {
    const res = await getTransactionsSetting()
    const defaultSettings = res.find((data: any) => {
      return data.isDefault === true
    })
    const newData = {
      ...defaultSettings,
      ...transaction,
      isDefault: false
    }
    const result = await createTransactionSetting(newData)
    const transactionsOptions: Options[] = []
    res.forEach((element: any) => {
      const obj: Options = {
        key: element._id,
        text: element.name,
        value: `${element.name}, ${element._id}`,
      }
      transactionsOptions.push(obj)
    })
    transactionsOptions.push({
      key: result._id,
      text: result.name,
      value: `${result.name}, ${result._id}`
    })
    this.setState({ transactionsOptions, planAdded: true })
  }

  private showDelete = (data: boolean, id: string) => {
    this.setState({ showDelete: data, deleteTransactionSettingId: id })
  }
}

export default TransactionFeeDetails
