import styled from 'styled-components'

export const Container = styled.div`
  padding-left: 70px;
  padding-right: 70px;
`

export const AddForm = styled.div`
  p {
    color: #646464;
    font-size: 0.92857143em;
    font-weight: 700;
  }
  .save-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background: #4e5f80 !important;
    color: #fff !important;
  }
  .dashboard {
    display: flex;
    flex-wrap: wrap;
    gap: 0 30px;
    align-items: flex-start;
    &__row {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(50% - 15px);
      label {
        color: #646464;
        font-size: 0.92857143em;
        font-weight: 700;
        margin: 0 0 0.28571429rem 0;
        display: block;
      }
    }
    &__col {
      margin: 0;
      .ui.input,
      .ui.selection.dropdown {
        width: 100%;
        & > input,
        & > .text {
          font-size: 1em !important;
        }
      }
    }
  }
`
