import styled from 'styled-components'

import Colors from 'design/Colors'

interface Props {
  width: number
}

export const Container = styled.article`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 6px;
  overflow: auto;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.transparent};
  }
`

export const OverflowContainer = styled.div`
  width: 100%;
`

export const PageContainer = styled.section`
  position: relative;
  margin: 8px 0;
  box-shadow: 0 0 0 1px ${Colors.EmphasisContainer};
  width: ${(props: Props) => props.width + 'px' || '100%'};
`
