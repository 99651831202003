export const handleEventValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`name`] || fields[`name`].trim() === '') {
    formIsValid = false
    errors[`name`] = true
  }

  if (!fields[`type`]) {
    formIsValid = false
    errors[`type`] = true
  }

  if (!fields[`description`] || fields[`description`].trim() === '') {
    formIsValid = false
    errors[`description`] = true
  }

  if (!fields[`startDate`]) {
    formIsValid = false
    errors[`startDate`] = true
  }

  if (!fields[`endDate`]) {
    formIsValid = false
    errors[`endDate`] = true
  }

  if (!fields[`startTime`]) {
    formIsValid = false
    errors[`startTime`] = true
  }

  if (!fields[`endTime`]) {
    formIsValid = false
    errors[`endTime`] = true
  }

  if (!fields[`sharedWith`]) {
    formIsValid = false
    errors[`sharedWith`] = true
  }

  return { errors, formIsValid }
}
