// import { cloneDeep } from 'lodash'
import { Task, TaskDetails, TaskItemsDetails, TaskNotes } from './Types'

export const addNewBoard = (
  state: Task,
  index: number,
  newData: TaskDetails
): TaskDetails[] => {
  const { data } = state
  const feeData = data.slice()
  feeData.splice(index, 0, newData)
  return [...feeData]
}

export const addNewCard = (
  state: Task,
  id: string,
  index: string,
  newData: TaskItemsDetails
): Task['data'] => {
  const { data } = state
  let boards = data.slice()
  boards = boards.map((list: any) => {
    if (list._id === id) {
      const newList = list.items.slice()
      newList.splice(index ? index : 0, 0, newData)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return boards
}

export const setNewIcon = (
  state: Task,
  id: string,
  icon: string
): TaskDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        icon: icon
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewDescription = (
  state: Task,
  id: string,
  description: string
): TaskDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        description: description
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewBoardName = (
  state: Task,
  id: string,
  boardName: string
): TaskDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        type: boardName
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const deleteCard = (
  state: Task,
  boardId: string,
  cardId: string
): TaskDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === boardId) {
      const newList = list.items.slice()
      const listIndex = newList.findIndex((laneItem: any) => {
        return laneItem._id === cardId
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const restoreCard = (
  state: Task,
  id: string,
  index: string,
  newData: TaskItemsDetails | null
): Task['data'] => {
  if (newData) {
    const { data } = state
    let feeData = data.slice()
    feeData = feeData.map((list: any) => {
      if (list._id === id) {
        const newList = list.items.slice()
        newList.splice(index, 0, newData)
        return {
          ...list,
          items: newList
        }
      } else {
        return list
      }
    })
    return feeData
  } else {
    return state.data
  }
}

export const changeStatus = (
  state: Task,
  boardId: string,
  cardId: string,
  status: string
): TaskDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === boardId) {
      const newList = list.items.slice()
      const cardIndex = newList.findIndex((laneItem: any) => {
        return laneItem._id === cardId
      })
      const card = { ...newList[cardIndex] }
      card.status = status
      newList[cardIndex] = card
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const addNewNotes = (
  state: Task,
  newData: TaskNotes,
  cardId: string
): any => {
  const { data, notes } = state
  const notesCopy = notes.slice()
  notesCopy.splice(0, 0, newData)

  const dataCopy = JSON.parse(JSON.stringify(data))
  if (cardId !== '') {
    dataCopy.findIndex((board: any, boardIndex: number) => {
      const result = board.items.findIndex((card: any, cardIndex: number) => {
        if (card._id === cardId) {
          if (dataCopy[boardIndex].items[cardIndex].notes) {
            dataCopy[boardIndex].items[cardIndex].notes.splice(0, 0, newData)
          } else {
            dataCopy[boardIndex].items[cardIndex].notes = [newData]
          }
          return true
        }
        return false
      })

      if (result > -1) {
        return true
      }

      return false
    })
  }

  return {
    data: dataCopy,
    notes: notesCopy
  }
}

export const editNotes = (
  state: Task,
  id: string,
  newData: TaskNotes
): TaskNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  const noteIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  const note = { ...feeData[noteIndex] }
  note[`description`] = newData
  feeData[noteIndex] = note
  return [...feeData]
}

export const deleteNotes = (state: Task, id: string): TaskNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  const noteIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  feeData.splice(noteIndex, 1)
  return [...feeData]
}

export const deleteListRecord = (
  state: Task,
  cardId: string
): Task['listData'] => {
  const { listData } = state
  const listRecords = listData.slice()
  const index = listRecords.findIndex((data: any) => {
    return data._id === cardId
  })
  listRecords.splice(index, 1)
  return listRecords
}

export const editRecord = (state: Task, newData: any): Task['listData'] => {
  const { listData } = state
  let listRecords = listData.slice()
  listRecords = listRecords.map((list: any) => {
    if (list._id === newData._id) {
      return newData
    } else {
      return list
    }
  })
  return listRecords
}

export const importData = (
  state: Task,
  boardId: string,
  importedData: any
): Task['data'] => {
  const { data } = state
  let boardData = data.slice()
  boardData = boardData.map((list: any) => {
    if (list._id === boardId) {
      let newList = list.items.slice()
      if (newList.length > 0) {
        newList = newList.concat(importedData)
      } else {
        newList = importedData
      }
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })

  return boardData
}

export const updateCard = (
  state: Task,
  task: any,
  boardId: string
): Task['data'] => {
  const { data } = state
  const dataCopy = JSON.parse(JSON.stringify(data))

  if (task._id !== '' && boardId === '') {
    dataCopy.findIndex((board: any, boardIndex: number) => {
      return board.items.findIndex((card: any, cardIndex: number) => {
        if (card._id === task._id) {
          dataCopy[boardIndex].items[cardIndex] = {
            ...card,
            ...task
          }
          return true
        }
        return false
      }) > -1
        ? true
        : false
    })
  }

  return dataCopy
}
