// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Dropdown, Icon } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

import ClickToUpload from 'design/images/dashboard/clickToUpload.jpg'

// Import Components
import { loggedUser } from '../Transactions/Transactions'

import { TransactionList } from 'store/Transactions/Types'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  CheckboxWrapper,
  DropDownPopup,
  Labels,
  Li,
  MemberProfile,
  Profile,
  Status,
  StyledDropdown,
  StyledPopup,
  Type
} from './Styled'

let checked = false

export const Checked = (data: boolean) => {
  checked = !data
}

export const CustomHeaderCheckBox = () => {
  const onChange = () => {}
  return (
    <CheckboxWrapper>
      <Checkbox checked={checked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomCheckBox = ({ rowData }: { rowData: TransactionList }) => {
  const onChange = () => {
    // SingleCheckBox(rowData._id)
  }
  return (
    <CheckboxWrapper>
      <Checkbox checked={rowData.checked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomPropertyAddress = ({ rowData }: { rowData: TransactionList }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <MemberProfile
        background={
          rowData.propertyId.images && rowData.propertyId.images.length > 0
            ? rowData.propertyId.images[0].url
            : ClickToUpload
        }
      />
      <Link to={Routes.transactions.root + `/${rowData._id}` + Routes.transactions.documents.path}>
        <div style={{ marginLeft: 8, color: 'black', textTransform: 'capitalize' }}>
          {rowData.propertyId && rowData.propertyId.address
            ? `${rowData.propertyId.address.streetNumber} ${rowData.propertyId.address.streetName}`
            : 'No Address Found'}
        </div>
        <div style={{ marginLeft: 8, color: 'black', textTransform: 'capitalize' }}>
          {rowData.propertyId && rowData.propertyId.address
            ? `${rowData.propertyId.address.city}, ${rowData.propertyId.address.state} ${
                rowData.propertyId.address.zipCode
              }`
            : 'No Address Found'}
        </div>
      </Link>
    </div>
  )
}

export const CustomPropertySide = ({ rowData }: { rowData: TransactionList }) => {
  let background = ''
  let text = ''
  switch (rowData.transactionRole) {
    case 'Buyer':
      background = Colors.Green215
      text = 'Buy'
      break
    case 'Seller':
      background = Colors.DarkBlue500
      text = 'Sell'
      break
    case 'Both':
      background = Colors.Purple50
      text = 'Both'
      break
    default:
      background = Colors.Red835
      break
  }
  return <Type background={background}>{text.toUpperCase()}</Type>
}

export const CustomAddedDate = ({ rowData }: { rowData: TransactionList }) => {
  return <div style={{ fontStyle: 'italic' }}>{moment(rowData.createdAt).format('LL')}</div>
}

export const CustomListingId = ({ rowData }: { rowData: TransactionList }) => {
  return <div>{rowData.propertyId.mlsId ? rowData.propertyId.mlsId : 'Not yet given'}</div>
}

export const CustomModifiedDate = ({ rowData }: { rowData: TransactionList }) => {
  return <div style={{ fontStyle: 'italic' }}>{moment(rowData.updatedAt).format('LL')}</div>
}

export const CustomPrice = ({ rowData }: { rowData: TransactionList }) => {
  return <div>${rowData.propertyId.price}</div>
}

export const CustomOwnerName = ({ rowData }: { rowData: TransactionList }) => {
  const changeOwnership = () => {
    if (loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER' || loggedUser._id === rowData.owner._id) {
      // ChangeOwner(rowData._id)
    }
  }
  return (
    <div style={{ cursor: 'pointer' }} onClick={changeOwnership}>
      {rowData.owner.firstName} {rowData.owner.lastName}
    </div>
  )
}

export const CustomSharedWith = ({ rowData }: { rowData: TransactionList }) => {
  return (
    <div>
      {rowData.shareWith && (
        <Profile>
          {rowData.shareWith.map((items: any, index: number) => {
            while (index < 3) {
              return (
                <Li key={index}>
                  <StyledPopup
                    trigger={<MemberProfile key={index} background={items.profileImage} />}
                    content={`${items.firstName} ${items.lastName}`}
                  />
                </Li>
              )
            }
            return null
          })}
          {rowData.shareWith.length > 3 ? (
            <Labels>
              <StyledPopup trigger={<div>...</div>} content={`+${rowData.shareWith.length - 3}`} />
            </Labels>
          ) : null}
        </Profile>
      )}
    </div>
  )
}

export const CustomStatus = ({ rowData }: { rowData: TransactionList }) => {
  let background = ''
  switch (rowData.status) {
    case 'New':
      background = Colors.Orange250
      break
    case 'Active':
      background = Colors.Green70
      break
    case '$$ Received':
      background = Colors.Blue50
      break
    case 'Payable':
      background = Colors.Magenta50
      break
    case 'Closed':
      background = Colors.Red55
      break
    case 'Failed':
      background = Colors.Grey800
      break
    default:
      background = Colors.Orange250
      break
  }
  return <Status background={background}>{rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}</Status>
}

export const CustomActions = ({ rowData }: { rowData: TransactionList }) => {
  const deleteTransaction = () => {
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.kanbanView.recoverRecord,
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(result => {
      if (result.value) {
        // deleteListTransaction(rowData._id)
        ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedRecord, 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeRecord, 'error')
      }
    })
  }
  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              <Link
                to={Routes.transactions.root + `/${rowData._id}` + Routes.transactions.documents.path}
                onClick={(e: any) => {
                  localStorage.setItem('transaction', JSON.stringify(rowData))
                }}
              >
                <Dropdown.Item text={Strings.transactionDashboard.viewDetails} className="view" />
              </Link>
              <Dropdown.Item text={Strings.transactionDashboard.viewNotes} />
              {(loggedUser.role === 'ADMIN' ||
                loggedUser.role === 'MANAGER' ||
                loggedUser._id === rowData.owner._id) && (
                <Dropdown.Item text={Strings.transactionDashboard.changeOwnership} />
              )}
              {(loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER') && (
                <Dropdown.Item text={Strings.transactionDashboard.delete} onClick={deleteTransaction} />
              )}
              <Link
                to={Routes.transactions.root + `/${rowData._id}` + Routes.transactions.commission.path}
                onClick={(e: any) => {
                  localStorage.setItem('transaction', JSON.stringify(rowData))
                  setTimeout(() => {
                    // ShowManagerNotes()
                  }, 1000)
                }}
              >
                <Dropdown.Item text={Strings.transactionDashboard.viewNotes} className="view" />
              </Link>
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  )
}
