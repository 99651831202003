// Import Packages
import * as React from 'react'

// Import Styled Components
import {
  Icon,
  IconContainer,
  ItemContainer,
  // Text,
  Title
} from './Styled'

interface Props {
  icon: string
  title: string
  text: string
}

const ActionItem = ({ title, text, icon }: Props) => (
  <ItemContainer>
    <IconContainer><Icon backgroundImage={icon} /></IconContainer>
    <div>
      <Title>{title}</Title>
      {/* <Text>{text}</Text> */}
    </div>
  </ItemContainer>
)

export default ActionItem
