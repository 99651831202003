import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ConfirmAlert from 'sweetalert2'

import { isPlanAllowed } from 'shared/Billing/Queries'
import Toast, { serverToast } from 'shared/Toast/Toast'

import LoadingIndicator from 'shared/LoadingIndicator'
import ListView from 'shared/StyledBoard/GridView'
import KanbanView from 'shared/StyledBoard/KanbanView'
import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'
import ActionComponent from '../Dashboard/Actions'
import TransactionCard from '../Dashboard/TransactionCard'
import ChangeOwnership from './ChangeOwnership'
import ListViewColumn from './ListView'
import {
  CustomOwnerName,
  CustomSharedWith,
} from './CustomComponents'
import StyledHeader from './StyledHeader'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'

import { getIcons } from 'app/Leads/Dashboard/LeadQueries'
import { changeTransactionOwnership, deleteTransaction, moveTransaction, reorderCard } from './TransactionMutations'
import {
  exportTransactions,
  getOfficesFilter,
  getTransactionBoards,
  getTransactions,
  sortBoardTransaction
} from './TransactionQueries'

import { getLoggedInUser, Strings } from 'utils'
import ImportTransactionsPopup from './ImportTransactionsPopup'
import { addTransactionsToBoards, formatKanbanData, formatListData } from './Utils/FormattingData'

import { Container } from './Styled'

import { ListViewTransactionType } from './Types'
import { RowType } from './ListView/Types'
import { TransactionType } from 'app/Transactions/Details/Types'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import {
  Filter,
  FiltersData,
  IconsType,
  Options,
  Transaction,
  TransactionPassFilter,
  UserType
} from 'store/Transactions/Types'

export let ShowImportFileModal: any
export let exportTransaction: any
export let sortTransactions: any
export let deleteListTransaction: any
export let ChangeOwner: any
export let loggedUser: any

interface Props extends RouteComponentProps<{}, {}> {
  filter: Filter
  isKanbanView: boolean
  listData: ListViewTransactionType[]
  longPress: boolean
  officeFilter: string
  searchData: FiltersData
  searchLoader: boolean
  setAllRecentTransactions: (allTransactions: Transaction[], allListTransactions: ListViewTransactionType[]) => void
  setDeleteCard: (boardId: string, cardId: string) => void
  setDeleteRecord: (cardId: string) => void
  setImportData: (data: string) => void
  setKanbanView: (open: boolean) => void
  setNewBoard: (index: number, newData: Transaction) => void
  setNewBoardName: (id: string, boardName: string) => void
  setNewCard: (id: string, index: string, newData: TransactionType) => void
  setNewDescription: (id: string, description: string) => void
  setNewIcon: (id: string, icon: string) => void
  setOfficeFilter: (id: string) => void
  setResetCardData: () => void
  setRestoreCard: () => void
  setSearchDataFilter: (data: FiltersData) => void
  setShowConfetti: (data: boolean) => void
  setShowTransaction: (data: string) => void
  setShowUserPassTransactionFilterData: (data: TransactionPassFilter) => void
  setSortData: (boardId: string, sortOrder: number) => void
  setTransferTransaction: (cardId: string, destinationBoardId: string, index: number, sourceBoardId: string) => void
  setUpdateRecord: (editedData: TransactionType) => void
  transactionOwnerID: string
  transactions: Transaction[]
  userPassTransactionFilter: TransactionPassFilter
}

interface State {
  activeItem: {
    images: string[]
    mslID: number
    name: string
    address: string
    date: string
    price: number
    progress: {
      current: number
      total: number
    }
  } | null
  checked: boolean
  listViewData: ListViewTransactionType[]
  sidebarIsOpen: boolean
  showAddTransactionModal: boolean
  show: boolean
  icons: IconsType[]
  appearance: boolean
  addCardIndex: string
  showOwnershipModal: boolean
  addBoardId: string
  user: UserType
  edit: boolean
  deletedCard: TransactionType
  showImportFileModal: boolean
  importTransactionBoardId: string
  filterOptions: Options[]
  filterState: string
  transactionId: string
  loader: boolean
  transactionOwnerId: string
  toggle: boolean
}

class TransactionDashboard extends React.Component<Props, State> {
  public state = {
    activeItem: null,
    addBoardId: '',
    addCardIndex: '',
    appearance: true,
    checked: false,
    deletedCard: {} as TransactionType,
    edit: false,
    filterOptions: [],
    filterState: 'Choose',
    icons: [],
    importTransactionBoardId: '',
    listViewData: [],
    loader: true,
    show: true,
    showAddTransactionModal: false,
    showImportFileModal: false,
    showOwnershipModal: false,
    sidebarIsOpen: false,
    toggle: false,
    transactionId: '',
    transactionOwnerId: '',
    user: {} as UserType
  }

  public columnMetaData = [
    {
      customComponent: (row:RowType) => (
        <ListViewColumn.Checkbox
          isChecked={row.rowData.checked}
          onChange={() => this.toggleCheckbox(row)}
        />
      ),
      customHeadingComponent: (row:any) => (
        <ListViewColumn.Checkbox
          isChecked={this.state.checked}
          onChange={() => this.toggleCheckbox(row)}
        />
      ),
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.checkbox,
      width: '1%'
    },
    {
      customComponent: (row:RowType) => (
        <ListViewColumn.Address row={row} />
      ),
      enhanceWithRowData: true,
      id: 'propertyId.address.streetNumber',
      title: 'Property'
    },
    {
      customComponent: (row:RowType) => (
        <ListViewColumn.Badge label={row.rowData.transactionRole} />
      ),
      enhanceWithRowData: true,
      id: 'transactionRole',
      title: 'Property Side'
    },
    {
      enhanceWithRowData: true,
      id: 'transactionId',
      title: 'Transaction Id'
    },
    {
      customComponent: ({ rowData: { propertyId: { mlsId }}}:RowType) => (
        <div>{mlsId || 'Not yet given'}</div>
      ),
      enhanceWithRowData: true,
      id: 'propertyId.mlsId',
      title: 'MLS ID'
    },
    {
      customComponent: ({ rowData: { propertyId: { price }}}:RowType) => (
        <div>{price.toLocaleString()}</div>
      ),
      enhanceWithRowData: true,
      id: 'propertyId.price',
      title: 'Price'
    },
    {
      customComponent: CustomOwnerName,
      enhanceWithRowData: true,
      id: 'owner.firstName',
      title: 'Owner Name'
    },
    {
      customComponent: CustomSharedWith,
      enhanceWithRowData: true,
      id: 'sharedWith',
      title: 'Shared With'
    },
    {
      customComponent: ({ rowData: { createdAt }}:RowType) => (
        <ListViewColumn.Date date={createdAt} />
      ),
      enhanceWithRowData: true,
      id: 'createdAt',
      title: 'Added Date'
    },
    {
      customComponent: ({ rowData: { updatedAt }}:RowType) => (
        <ListViewColumn.Date date={updatedAt} />
      ),
      enhanceWithRowData: true,
      id: 'updatedAt',
      title: 'Modified Date'
    },
    {
      customComponent: (row:RowType) => (
        <ListViewColumn.Badge label={row.rowData.status} />
      ),
      enhanceWithRowData: true,
      id: 'status',
      title: 'Status'
    }
  ]

  public async componentDidMount() {
    const {
      setOfficeFilter,
      setSearchDataFilter,
      setShowTransaction,
      setShowUserPassTransactionFilterData,
      location
    } = this.props
    const user: any = await getLoggedInUser({ fromCache: true }) || {} as UserType
    this.setState({ user })
    loggedUser = user
    setOfficeFilter('')
    setSearchDataFilter({} as FiltersData)
    this.refreshGetTransactions()
    const iconsList = await getIcons()
    let officeOptions: any
    if (user.role === 'ADMIN' || user.role === 'MANAGER') {
      officeOptions = await getOfficesFilter()
    }
    if (location && location.search) {
      // setShowTransaction('')
    } else {
      setShowTransaction('')
      setShowUserPassTransactionFilterData({} as TransactionPassFilter)
    }
    this.setState({ icons: iconsList, user, filterOptions: officeOptions })
    this.assignShowImportFileModal()
    this.assignChangeOwner()
    this.SortBoardTransactions()
    this.assignDeleteTransaction()
    this.Export()
  }

  public componentDidUpdate(prev:Props) {
    const { isKanbanView } = this.props
    if (prev.isKanbanView !== isKanbanView) {
      this.refreshGetTransactions()
    }
  }

  public render() {
    const { isKanbanView, setImportData, listData, searchLoader, transactions } = this.props
    const {
      addBoardId,
      addCardIndex,
      appearance,
      edit,
      filterOptions,
      filterState,
      icons,
      importTransactionBoardId,
      loader,
      show,
      showAddTransactionModal,
      showImportFileModal,
      showOwnershipModal,
      transactionOwnerId,
      user,
    } = this.state
    return (
      <React.Fragment>
        {showOwnershipModal && (
          <ChangeOwnership
            closeModal={this.closeModal}
            changeOwnership={this.changeOwnership}
            transactionOwnerId={transactionOwnerId}
          />
        )}
        {showImportFileModal && (
          <ImportTransactionsPopup
            closeModal={this.closeModal}
            boardId={importTransactionBoardId}
            user={user}
            importData={setImportData}
          />
        )}
        {showAddTransactionModal && (
          <CreateTransactionModal
            officeId={filterState.includes(',') ? filterState.split(',')[0] : undefined}
            mutationParams={{
              addBoardId,
              edit,
              index: addCardIndex,
              show,
              user
            }}
            onClose={this.addTransaction}
          />
        )}
        <Container>
          {(loader || searchLoader) && <LoadingIndicator type={IndicatorTypeEnum.Banner} />}
          <StyledHeader
            addList={this.addList}
            defaultView={isKanbanView}
            deleteTransactions={this.deleteTransactions}
            filterOptions={filterOptions}
            filterState={filterState}
            getOfficeTransactions={this.getOfficeTransactions}
            showDefaultView={this.toggleView}
            transactions={transactions}
            user={user}
            changeToggle={this.changeToggleStatus}
            toggleStatus={this.state.toggle}
          />
          {isKanbanView &&
            transactions.length > 0 && (
              <KanbanView
                ActionComponent={ActionComponent}
                addNewCard={this.addNewCard}
                alterData={this.alterData}
                appearance={appearance}
                CardComponent={TransactionCard}
                data={transactions}
                deleteCard={this.deleteCard}
                deleteRecord={this.deleteTransactionCard}
                deleteTransfer={false}
                icons={icons}
                moveCard={this.moveTransaction}
                reorderCard={this.reorderTransactions}
                resetCardData={this.resetCardData}
                restoreCard={this.restoreCard}
                setNewBoardName={this.setNewBoardName}
                setNewDescription={this.setNewDescription}
                setNewIcon={this.setNewIcon}
                updateBoard={this.updateBoard}
              />
            )}
          {!isKanbanView &&
            listData && (
              <ListView
                addList={this.addList}
                columnMetaData={this.columnMetaData}
                data={listData}
                tableHeight={50}
              />
            )}
        </Container>
      </React.Fragment>
    )
  }

  private refreshGetTransactions = async (id?: string, singleSkip?: number) => {
    const {
      isKanbanView,
      listData,
      location,
      officeFilter: officeId,
      setAllRecentTransactions,
      transactionOwnerID,
      userPassTransactionFilter,
    } = this.props
    const {
      toggle,
      user: _user,
    } = this.state

    this.setState({ loader: true })

    const isSearch = location && location.search
    const isFiltered = Object.keys(userPassTransactionFilter).length > 0
    const ownerId = transactionOwnerID && isSearch ? transactionOwnerID : undefined
    const enableAdditions = !toggle

    let boardParams: any = {
      id,
      singleSkip
    }
    let transactionParams: any = {}

    if (officeId) {
      boardParams = {
        ...boardParams,
        officeId,
        user: ownerId,
      }
      transactionParams = {
        officeId,
        shareWith: ownerId
      }
    } else {
      if (_user.role === 'TEAM_LEADER' && enableAdditions) {
        boardParams.user = _user._id
        transactionParams.shareWith = _user._id
      } else if (ownerId) {
        boardParams.user = transactionOwnerID
        transactionParams.shareWith = transactionOwnerID
      } else if (isFiltered && isSearch) {
        boardParams.userPassFilter = userPassTransactionFilter
        transactionParams.userPassFilter = userPassTransactionFilter
      }
    }

    /** TODO:
      * AGENT users can't receive populated boards ??
      * and so merge getTransactions results into the boards
      * it should all be done like this since it's faster to query empty boards
      * and transactions per board _id
      */
    let kanban = await getTransactionBoards(boardParams)
    let kanbanClone = [ ...kanban ]
    let list = [ ...listData ]
    let listLimit = []
    const limit = 20

    if (!isKanbanView || _user.role === 'AGENT') {
      listLimit = await getTransactions({ ...transactionParams, limit }) || []
      /* splices results into previous list instead of overwriting */
      list.splice(0, listLimit.length, ...listLimit)
      if (_user.role === 'AGENT') {
        kanban = addTransactionsToBoards(list, kanban)
      }
    }

    const transactionKanbanData = await formatKanbanData(kanban, enableAdditions)
    const transactionListData = await formatListData(list)
    setAllRecentTransactions(transactionKanbanData, transactionListData)
    this.setState({ loader: false })

    /** TODO:
      * The following is a hack to receive the rest of the ListView results
      * in a manner that does not (heavily) block the initial UI.
      * It should be discarded once
      * getTransactions and ListView are updated to handle true pagination
      */
    if (listLimit.length === limit && (!isKanbanView || _user.role === 'AGENT')) {
      const listRemainder = await getTransactions({ ...transactionParams, skip: limit }) || []
      const fullList = [...list, ...listRemainder]
      if (_user.role === 'AGENT') {
        kanbanClone = addTransactionsToBoards(fullList, kanbanClone)
      }
      const _transactionKanbanData = await formatKanbanData(kanbanClone, enableAdditions)
      const fullListData = await formatListData(fullList)
      setAllRecentTransactions(_transactionKanbanData, fullListData)
    }
  }

  private changeToggleStatus = async () => {
    this.setState(
      (prev: State) => ({ toggle: !prev.toggle }),
      () => {
        this.refreshGetTransactions()
      }
    )
  }

  private assignChangeOwner = () => {
    ChangeOwner = (transactionId: string, transactionOwnerId: string) => {
      this.setState({ showOwnershipModal: true, transactionId, transactionOwnerId })
    }
  }

  private changeOwnership = async (data: any) => {
    const { transactionId } = this.state
    await changeTransactionOwnership(data, transactionId)
    this.refreshGetTransactions()
  }

  private addList = async () => {
    try {
      await isPlanAllowed('transaction')
    } catch (error) {
      return serverToast(error)
    }
    this.setState({ showAddTransactionModal: true })
  }

  private toggleView = async (isKanbanView: boolean) => {
    const {
      transactions,
      setKanbanView
    } = this.props
    setKanbanView(isKanbanView)

    if (!isKanbanView) {
      const newBoardId =
        transactions &&
        transactions.filter((board: any) => {
          return board.name === 'New'
        })
      this.setState({ addBoardId: newBoardId.length !== 0 ? newBoardId[0]._id : '' })
    }
  }

  private Export = () => {
    exportTransaction = (boardId: string) => {
      const { user } = this.state
      exportTransactions(boardId, user)
    }
  }

  private toggleCheckbox = (row:any) => {
    const { setAllRecentTransactions, listData, transactions } = this.props
    const { checked } = this.state

    const update = [ ...listData ]
    if (row.rowData) {
      update[row.griddleKey].checked = !listData[row.griddleKey].checked
    } else {
      update.forEach((_, index: number) => {
        update[index].checked = !checked
      })
      this.setState({ checked: !checked })
    }
    setAllRecentTransactions(transactions, update)
  }

  private assignShowImportFileModal = () => {
    ShowImportFileModal = (boardId: any) => {
      this.setState({ showImportFileModal: true, importTransactionBoardId: boardId })
    }
  }

  private assignDeleteTransaction = () => {
    deleteListTransaction = async (transactionId: string) => {
      const { setDeleteRecord } = this.props
      const ids = []
      ids.push(transactionId)
      deleteTransaction(ids)
      setDeleteRecord(transactionId)
      this.refreshGetTransactions()
    }
  }

  private closeModal = () => {
    this.setState({ showAddTransactionModal: false, showOwnershipModal: false, showImportFileModal: false })
  }

  private resetCardData = () => {
    const { setResetCardData } = this.props
    setResetCardData()
  }

  private restoreCard = () => {
    const { setRestoreCard, setResetCardData } = this.props
    setRestoreCard()
    setResetCardData()
  }

  private deleteCard = async (boardId: string, cardId: string) => {
    const { setDeleteCard } = this.props
    const { user } = this.state
    if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
      setDeleteCard(boardId, cardId)
    }
  }

  private deleteTransactionCard = async (boardId: string, cardId: string) => {
    const { setResetCardData, setRestoreCard } = this.props
    const { user } = this.state
    if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
      const transactionListId = []
      transactionListId.push(cardId)
      try {
        await deleteTransaction(transactionListId)
        this.refreshGetTransactions()
      } catch (error) {
        Toast({ message: error.message, type: 'error' })
      }
      ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
    } else {
      setRestoreCard()
      setResetCardData()
      ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotDeleteTransaction, 'error')
    }
    this.setState({
      filterState: 'All'
    })
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    setNewBoardName(id, boardName)
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    setNewDescription(id, description)
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    setNewIcon(id, icon)
  }

  private addNewCard = async (id: string, index: string) => {
    try {
      await isPlanAllowed('transaction')
    } catch (error) {
      return serverToast(error)
    }
    this.setState({ showAddTransactionModal: true, addCardIndex: index, addBoardId: id })
  }

  private addTransaction = async (result: any) => {
    const { setNewCard, setUpdateRecord, officeFilter, setShowConfetti } = this.props
    const { addBoardId, addCardIndex, edit } = this.state

    this.closeModal()
    if (!result) {
      return
    }

    try {
      // const response: any = await createTransaction(newTransaction, addCardIndex, addBoardId, show, user, edit)
      if (edit) {
        setUpdateRecord(result)
      } else {
        setNewCard(addBoardId, addCardIndex, result)
        setShowConfetti(true)
        setTimeout(() => {
          setShowConfetti(false)
        }, 6000)
      }
      Toast({ message: 'Transaction added successfully', type: 'success' })
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }

    this.refreshGetTransactions()
    if (officeFilter) {
      this.setState({
        filterState: officeFilter
      })
    } else {
      this.setState({
        filterState: 'All'
      })
    }
  }

  private alterData = async (data: any) => {
    const { setAllRecentTransactions, listData } = this.props
    setAllRecentTransactions(data, listData)
  }

  private getOfficeTransactions = async (id: string, value: string) => {
    const {
      setAllRecentTransactions,
      filter,
      searchData,
      setOfficeFilter,
      transactionOwnerID,
      location
    } = this.props
    this.setState({
      filterState: value
    })
    if (id === 'All' || id === 'Choose') {
      setOfficeFilter('')
    } else {
      setOfficeFilter(id)
    }
    if (filter.filter === true || filter.searchText === true) {
      if (id === 'All' || id === 'Choose') {
        const transactionBoards = await getTransactionBoards({
          searchData,
          user: transactionOwnerID && location && location.search ? transactionOwnerID : undefined
        })
        const transactions = await getTransactions({
          searchData,
          shareWith: transactionOwnerID && location && location.search ? transactionOwnerID : undefined
        })
        const transactionKanbanData = await formatKanbanData(transactionBoards)
        const transactionListData = await formatListData(transactions)
        setAllRecentTransactions(transactionKanbanData, transactionListData)
      } else {
        const transactionBoards = await getTransactionBoards({
          searchData,
          user: transactionOwnerID && location && location.search ? transactionOwnerID : undefined,
          officeId: id,
        })
        const transactions = await getTransactions({
          searchData,
          shareWith: transactionOwnerID && location && location.search ? transactionOwnerID : undefined,
          officeId: id
        })
        const transactionKanbanData = await formatKanbanData(transactionBoards)
        const transactionListData = await formatListData(transactions)
        setAllRecentTransactions(transactionKanbanData, transactionListData)
      }
    } else {
      if (id === 'All' || id === 'Choose') {
        const boardResult = await getTransactionBoards({
          user: transactionOwnerID && location && location.search ? transactionOwnerID : undefined
        })
        const listResult = await getTransactions({
          shareWith: transactionOwnerID && location && location.search ? transactionOwnerID : undefined
        })
        const transactionKanbanData = await formatKanbanData(boardResult)
        const transactionListData = await formatListData(listResult)
        setAllRecentTransactions(transactionKanbanData, transactionListData)
      } else {
        const boardResult = await getTransactionBoards({
          officeId: id,
          user: transactionOwnerID && location && location.search ? transactionOwnerID : undefined
        })
        const listResult = await getTransactions({
          officeId: id,
          shareWith: transactionOwnerID && location && location.search ? transactionOwnerID : undefined
        })
        const transactionKanbanData = await formatKanbanData(boardResult)
        const transactionListData = await formatListData(listResult)
        setAllRecentTransactions(transactionKanbanData, transactionListData)
      }
    }
  }

  private SortBoardTransactions = () => {
    sortTransactions = (boardId: string, sortOrder: number) => {
      const { user } = this.state
      const { setSortData } = this.props
      setSortData(boardId, sortOrder)
      sortBoardTransaction(boardId, sortOrder, user)
    }
  }

  private deleteTransactions = () => {
    const { listData } = this.props
    const deleteIds: any = []
    listData.forEach((list: any) => {
      if (list.checked === true) {
        deleteIds.push(list._id)
      }
    })
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: 'Remove selected transaction(s)',
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        deleteTransaction(deleteIds)
        this.refreshGetTransactions()
        ConfirmAlert(Strings.kanbanView.deleted, 'Your transaction(s) has been deleted', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, 'Your transaction(s) is safe', 'error')
      }
      this.setState({
        filterState: 'All'
      })
    })
  }

  private moveTransaction = async (
    cardId: string,
    destinationIndex: number,
    destinationBoardId: string,
    sourceBoardId: string
  ) => {
    const { user } = this.state
    const { transactions } = this.props
    const destinationBoard = transactions.filter((data: any) => data._id === destinationBoardId)
    const sourceBoard = transactions.filter((data: any) => data._id === sourceBoardId)

    if (
      (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') &&
      (destinationBoard[0].name !== 'Active' &&
        sourceBoard[0].name !== 'New' &&
        destinationBoard[0].name !== 'Updated' &&
        destinationBoard[0].name !== 'New')
    ) {
      // setTransferTransaction(cardId, destinationBoardId, destinationIndex, sourceBoardId)
      try {
        await moveTransaction(cardId, destinationBoardId, destinationIndex, user)
        this.refreshGetTransactions()
      } catch (error) {
        Toast({ message: 'Please approve remaining checklist items prior to approving payment', type: 'error' })
        this.refreshGetTransactions()
      }
    } else {
      ConfirmAlert(
        Strings.kanbanView.error,
        'You are not authorized to change this status. Please contact your administrator if you need additional assistance',
        'error'
      )
      this.refreshGetTransactions()
    }
  }

  private reorderTransactions = async (
    boardId: string,
    cardId: string,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    const { user } = this.state
    await reorderCard(boardId, cardId, sourceIndex, destinationIndex, user)
  }

  private updateBoard = async (id: string, singleSkip: number) => {
    this.refreshGetTransactions(id, singleSkip)
  }
}

const mapStateToProps = (state: AppState) => ({
  isKanbanView: state.transactions.showDefaultView,
  filter: state.transactions.filter,
  listData: state.transactions.listData,
  officeFilter: state.transactions.officeFilter,
  searchData: state.transactions.searchData,
  searchLoader: state.transactions.searchLoader,
  transactionOwnerID: state.transactions.transactionOwnerID,
  transactions: state.transactions.allTransactions,
  userPassTransactionFilter: state.transactions.userPassTransactionFilter
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setAllRecentTransactions: Actions.getAllRecentTransactions,
      setKanbanView: Actions.showDefaultView,
      setDeleteCard: Actions.deleteCard,
      setDeleteRecord: Actions.deleteRecord,
      setNewBoard: Actions.addNewBoard,
      setNewBoardName: Actions.setNewBoardName,
      setNewCard: Actions.addNewCard,
      setNewDescription: Actions.setNewDescription,
      setNewIcon: Actions.setNewIcon,
      setResetCardData: Actions.resetCardData,
      setRestoreCard: Actions.restoreCard,
      setSearchDataFilter: Actions.searchDataFilter,
      setShowConfetti: Actions.showConfetti,
      setOfficeFilter: Actions.showOfficeFilter,
      setShowTransaction: Actions.showTransaction,
      setShowUserPassTransactionFilterData: Actions.showUserPassTransactionFilterData,
      setSortData: Actions.sortData,
      setTransferTransaction: Actions.transferTransaction,
      setUpdateRecord: Actions.editRecord
    }
  )(TransactionDashboard)
)
