import client from 'queries/apollo'

import { LOGIN_TOKEN_USER } from 'queries/graphql/Account/Mutations'

import { SubjectEnum } from './Types'

export const handleTokenLogin = async (subject: SubjectEnum, token: string) => {
  const response = await client.mutate({
    mutation: LOGIN_TOKEN_USER,
    variables: {
      subject,
      token
    }
  })
  return response.data.handleTokenLogin
}
