import { Reducer } from 'redux'
import Actions from 'store/Leads/ActionsTypes'
import {
  addNewBoard,
  addNewCard,
  addNewNotes,
  addWorkflow,
  createWorkflowTask,
  deleteCard,
  deleteMultipleWorkflowTasks,
  deleteNotes,
  deleteWorkflowTask,
  editNotes,
  restoreCard,
  setNewBoardName,
  setNewDescription,
  setNewIcon,
  updateMultipleWorkflowTasks,
  updateWorkflowTask
} from './ReduxUtil'
import {
  FiltersData,
  LeadItemsDetails,
  LeadPassFilter,
  LeadState
} from './Types'

const initialState: LeadState = {
  activeIndex: 0,
  activities: [],
  archivedLead: {},
  boardId: '',
  cardIndex: '',
  currentlyDeleteCard: null,
  data: [],
  filter: {
    filter: false,
    searchText: false
  },
  leadPool: false,
  listData: [],
  notes: [],
  searchData: {} as FiltersData,
  searchLoader: false,
  showNoteModal: false,
  userPassLeadFilter: {} as LeadPassFilter,
  workflowTask: []
}

const LeadReducer: Reducer<LeadState> = (
  state: LeadState = initialState,
  action
) => {
  switch (action.type) {
    case Actions.leadAddNewBoard:
      return {
        ...state,
        data: addNewBoard(state, action.payload.index, action.payload.newData)
      }
    case Actions.leadGetBoardData:
      return {
        ...state,
        data: action.payload.data
      }
    case Actions.leadGetListData:
      return {
        ...state,
        listData: action.payload.data
      }
    case Actions.leadAddNewCard:
      return {
        ...state,
        data: addNewCard(
          state,
          action.payload.id,
          action.payload.index,
          action.payload.newData
        )
      }
    case Actions.leadSetNewIcon:
      return {
        ...state,
        data: setNewIcon(state, action.payload.id, action.payload.icon)
      }
    case Actions.leadSetNewDescription:
      return {
        ...state,
        data: setNewDescription(
          state,
          action.payload.id,
          action.payload.description
        )
      }
    case Actions.leadSetNewBoardName:
      return {
        ...state,
        data: setNewBoardName(
          state,
          action.payload.id,
          action.payload.boardName
        )
      }
    case Actions.leadDeleteCard:
      const { data } = state
      const feeData = data.slice()
      let index: string
      let temp: LeadItemsDetails
      const callback = () => {
        return {
          ...state,
          boardId: action.payload.boardId,
          cardIndex: index,
          currentlyDeleteCard: temp,
          data: deleteCard(state, action.payload.boardId, action.payload.cardId)
        }
      }
      feeData.forEach((list: any) => {
        if (list.id === action.payload.boardId) {
          const newList = list.items.slice()
          const listIndex = newList.findIndex((laneItem: any) => {
            return laneItem.id === action.payload.cardId
          })
          index = listIndex
          temp = newList[listIndex]
          callback()
        }
      })
      return callback()
    case Actions.leadRestoreCard:
      return {
        ...state,
        data: restoreCard(
          state,
          state.boardId,
          state.cardIndex,
          state.currentlyDeleteCard
        )
      }
    case Actions.leadResetCardData:
      return {
        ...state,
        boardId: '',
        cardIndex: '',
        currentlyDeleteCard: null
      }
    case Actions.leadAddNewNotes:
      return {
        ...state,
        notes: addNewNotes(state, action.payload.newData)
      }
    case Actions.leadGetNotesData:
      return {
        ...state,
        notes: action.payload.data
      }
    case Actions.leadEditNotes:
      return {
        ...state,
        notes: editNotes(state, action.payload.newData)
      }
    case Actions.leadDeleteNotes:
      return {
        ...state,
        notes: deleteNotes(state, action.payload.id)
      }
    case Actions.leadShowNoteModal:
      return {
        ...state,
        showNoteModal: action.payload.data
      }
    case Actions.leadFilter:
      return {
        ...state,
        filter: action.payload.data
      }
    case Actions.leadSearchLoader:
      return {
        ...state,
        searchLoader: action.payload.open
      }
    case Actions.leadarchive:
      return {
        ...state,
        archivedLead: action.payload.data
      }
    case Actions.leadPool:
      return {
        ...state,
        leadPool: action.payload.open
      }
    case Actions.leadSearchFilter:
      return {
        ...state,
        searchData: action.payload.data
      }
    case Actions.getLeadWorkflowTask:
      return {
        ...state,
        workflowTask: action.payload.data
      }
    case Actions.createLeadWorkflowTask:
      return {
        ...state,
        workflowTask: createWorkflowTask(state, action.payload.data)
      }
    case Actions.deleteLeadWorkflowTask:
      return {
        ...state,
        workflowTask: deleteWorkflowTask(state, action.payload.data)
      }
    case Actions.updateLeadWorkflowTask:
      return {
        ...state,
        workflowTask: updateWorkflowTask(state, action.payload.data)
      }
    case Actions.leadAddWorkflow:
      return {
        ...state,
        workflowTask: addWorkflow(state, action.payload.newData)
      }
    case Actions.leadGetWorkflow:
      return {
        ...state,
        workflowTask: action.payload.data
      }
    case Actions.deleteMultipleLeadWorkflowTask:
      return {
        ...state,
        workflowTask: deleteMultipleWorkflowTasks(state, action.payload.ids)
      }
    case Actions.updateMutlipleLeadWorkflowTask:
      return {
        ...state,
        workflowTask: updateMultipleWorkflowTasks(
          state,
          action.payload.ids,
          action.payload.status
        )
      }
    case Actions.setContactTransactionPanel:
      return {
        ...state,
        activeIndex: action.payload.data
      }
    case Actions.leadActivities: {
      return {
        ...state,
        activities: action.payload.data
      }
    }
    case Actions.userPassLeadFilter:
      return {
        ...state,
        userPassLeadFilter: action.payload.filters
      }
    default:
      return state
  }
}

export default LeadReducer
