import gql from 'graphql-tag'

export const UPDATE_CITY = gql`
  mutation updateCity($cityId: String, $stateId: ID) {
    updateCity(_id: $cityId, input: { state: $stateId }) {
      _id
    }
  }
`

export const UPDATE_CITY_V2 = gql`
  mutation updateCity($id: String, $input: CityInput) {
    updateCity(_id: $id, input: $input) {
      _id
    }
  }
`

export const GET_OR_CREATE_CITY = gql`
  mutation createCity($input: CityInput) {
    createCity(input: $input) {
      _id
      name
      state {
        _id
        name
      }
    }
  }
`
