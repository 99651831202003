import { Dimmer } from 'semantic-ui-react'
import styled, { css, keyframes } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { y } from 'design/Styled'

interface Props {
  height?: number
  stagger?: number
}

const backgroundSize = 400

const stripeAnimation = keyframes`
  0% {
    background-position 0;
  }
  100% {
    background-position: ${backgroundSize}px;
  }
`

const stripes = css`
  background: ${Colors.LoadingStripeEven};
  background-image: linear-gradient(
    140deg,
    ${Colors.LoadingStripeOdd} 0%,
    ${Colors.LoadingStripeOdd} 25%,
    ${Colors.transparent} 25.1%,
    ${Colors.transparent} 40%,
    ${Colors.LoadingStripeOdd} 75.1%,
    ${Colors.LoadingStripeOdd} 100%
  );
  background-size: ${backgroundSize}px;
  background-repeat: repeat;
  animation-name: ${stripeAnimation};
  animation-duration: ${(props: Props) => (props.stagger || 0) + 1500}ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

export const Container = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  margin-top: -5px;
  background: ${Colors.transparent};
  z-index: 999;
  cursor: pointer;

  &:hover {
    .rp-loading-stripes {
      min-height: 1.4em;
      background: ${Colors.LoadingStripeOdd};
    }
    .rp-loading-message {
      opacity: 1;
    }
  }
`

export const Stripes = styled.div`
  ${stripes};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props: Props) => props.height || 2}px;
  min-height: 0;
  overflow: hidden;
  transition: all 300ms;
`

export const Message = styled.span`
  display: block;
  position: relative;
  font: ${fonts.small.regular};
  color: ${Colors.LoadingStripeEven};
  opacity: 0;
  transition: all 150ms 150ms;
`

export const StyledDimmer = styled(Dimmer)`
  &.ui.dimmer > .content {
    ${y};
    justify-content: center;
    align-items: center;
  }
`

export const SpinnerMessage = styled.span`
  margin: 0;
  padding: 1em;
  font: ${fonts.small.regular};
  font-style: italic;
  color: ${Colors.TextPrimary} !important;
`
