// Import Packages
import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import SpinningBubbles from 'react-loading'
import { connect } from 'react-redux'
import { Dimmer, Popup } from 'semantic-ui-react'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Components
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import { deleteMessageEmail } from 'shared/MessageEmail/MailboxMutations'
import SignatureModal from 'shared/MessageEmail/SignatureModal'
import KanbanView from './KanbanView'
import { getInboxBoard } from './MailboxQueries'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Dashboard from 'store/Dashboard/Actions'
import * as MailboxActions from 'store/Mailbox/Actions'
import { MailboxBoard as MailboxBoardType } from 'store/Mailbox/Types'
import { TaskDetails } from 'store/Tasks/Types'

// Import Styled Components
import Colors from 'design/Colors'
import { Button, ButtonRow, Container, Copy, Header, UserEmailAddress } from './Styled'

// Font Awesome Icons
import { faCog, faPlusCircle, faSync } from '@fortawesome/pro-light-svg-icons'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faCog, faPlusCircle, faSync])

interface Props {
  ActionComponent: React.ReactType | null
  addNewCard: (id: string, index: string) => void
  alterData: (data: any) => void
  appearance?: boolean
  boardData: TaskDetails[]
  CardComponent: React.ReactType
  convertNewCard: (boardId: string, listIndex: string, inputData: any) => any
  deleteCard: (boardId: string, cardId: string) => void
  deleteMessageEmailCard: (boardId: string, cardId: string) => void
  getMailboxBoardData: (data: MailboxBoardType) => void
  icons: any[]
  mailboxBoardData: MailboxBoardType
  moveCard: (cardId: string, destinationIndex: number, destinationBoardId: string, sourceBoardId: string) => void
  reorderCard: (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => void
  paddingLeft?: number
  paddingRight?: number
  price?: number
  resetCardData: () => void
  restoreCard: () => void
  setNewBoardName: (id: string, boardName: string) => void
  setNewDescription: (id: string, description: string) => void
  setNewIcon: (id: string, icon: string) => void
  toggleSignatureModal: (data: boolean) => void
  toggleSignature: boolean
}

interface State {
  loading: boolean
  modal: any
  searchText: string
  showModal: boolean
  userEmailAddress: string
  isOpen: boolean
}

class MailBoxBoard extends React.Component<Props, State> {
  public state = {
    isOpen: false,
    loading: true,
    modal: null,
    searchText: '',
    showModal: false,
    userEmailAddress: ''
  }

  public componentDidMount = async () => {
    const {
      toggleSignature,
      mailboxBoardData: { items }
    } = this.props
    if (!items || items.length < 1) {
      this.updateMailbox('INBOX', 0)
    } else {
      this.setState({ loading: false })
    }

    if (toggleSignature) {
      this.toggleSignatureModal()
    }

    const user = await getLoggedInUser({ fromCache: true })
    let email: any = user.emails.find((item: any) => item.type === 'RealtyPass')
    email = email ? email.value : ''
    this.setState({ userEmailAddress: email })
  }

  public render() {
    const { ActionComponent, appearance, boardData, CardComponent, icons, mailboxBoardData, price } = this.props
    const { loading, modal, showModal, userEmailAddress } = this.state

    if (loading) {
      return (
        <Dimmer active={true} inverted={true}>
          <SpinningBubbles type={'spinningBubbles'} color={Colors.DarkBlue200} />
        </Dimmer>
      )
    } else {
      return (
        <React.Fragment>
          {showModal && modal}
          <Container>
            <Header>
              <UserEmailAddress>
                <FA icon={['fas', 'envelope']} /> {userEmailAddress}
                <Popup
                  trigger={
                    <div>
                      <CopyToClipboard text={userEmailAddress}>
                        <Copy>{Strings.generalText.copy}</Copy>
                      </CopyToClipboard>
                    </div>
                  }
                  content="Email copied!"
                  size="mini"
                  inverted={true}
                  on="click"
                  open={this.state.isOpen}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                />
              </UserEmailAddress>
              <ButtonRow>
                <Button onClick={this.toggleComposeModal}>
                  <FA icon={['fal', 'plus-circle']} />
                </Button>
                <Button onClick={() => this.updateMailbox('INBOX', 0)}>
                  <FA icon={['fal', 'sync']} />
                </Button>
                <Button onClick={this.toggleSignatureModal}>
                  <FA icon={['fal', 'cog']} />
                </Button>
              </ButtonRow>
            </Header>
            <KanbanView
              ActionComponent={ActionComponent}
              addNewCard={this.addNewCard}
              alterData={this.alterData}
              appearance={appearance}
              boardData={boardData}
              boardType={'TASK'}
              CardComponent={CardComponent}
              convertNewCard={this.convertNewCard}
              deleteCard={this.deleteCard}
              icons={icons}
              mailboxData={mailboxBoardData}
              moveCard={this.moveCard}
              reorderCard={this.reorderCard}
              price={price}
              resetCardData={this.resetCardData}
              restoreCard={this.restoreCard}
              setNewBoardName={this.setNewBoardName}
              setNewDescription={this.setNewDescription}
              setNewIcon={this.setNewIcon}
              updateMailbox={this.updateMailbox}
            />
          </Container>
        </React.Fragment>
      )
    }
  }

  private handleOpen = () => {
    this.setState({ isOpen: true })

    setTimeout(() => {
      this.setState({ isOpen: false })
    }, 2000)
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private toggleComposeModal = () => {
    this.setState(prev => ({
      modal: <ComposeModal onClose={this.toggleComposeModal} />,
      showModal: !prev.showModal
    }))
  }

  private toggleSignatureModal = () => {
    const { toggleSignatureModal } = this.props
    this.setState(prev => ({
      modal: <SignatureModal onClose={this.toggleSignatureModal} />,
      showModal: !prev.showModal
    }))
    toggleSignatureModal(this.state.showModal)
  }

  private resetCardData = () => {
    const { resetCardData } = this.props
    resetCardData()
  }

  private restoreCard = () => {
    const { restoreCard } = this.props
    restoreCard()
  }

  private deleteCard = (boardId: string, cardId: string) => {
    const { deleteCard } = this.props
    deleteCard(boardId, cardId)
  }

  private moveCard = (cardId: string, destinationIndex: number, destinationBoardId: string, sourceBoardId: string) => {
    const { moveCard } = this.props
    moveCard(cardId, destinationIndex, destinationBoardId, sourceBoardId)
  }

  private reorderCard = (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
    const { reorderCard } = this.props
    reorderCard(boardId, cardId, sourceIndex, destinationIndex)
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    setNewBoardName(id, boardName)
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    setNewDescription(id, description)
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    setNewIcon(id, icon)
  }

  private addNewCard = (id: string, index: string) => {
    const { addNewCard } = this.props
    addNewCard(id, index)
  }

  private alterData = (data: any) => {
    const { alterData } = this.props
    alterData(data)
  }

  private convertNewCard = async (boardId: string, listIndex: string, inputData: any) => {
    const { convertNewCard, deleteMessageEmailCard } = this.props
    const convertedCard = await convertNewCard(boardId, listIndex, inputData)
    if (convertedCard) {
      deleteMessageEmailCard('INBOX', inputData._id)
      await deleteMessageEmail(inputData._id)
    }
  }

  private updateMailbox = async (type: string, skip: number) => {
    const { getMailboxBoardData } = this.props
    if (type === 'INBOX') {
      const inbox = await getInboxBoard(skip)
      if (inbox) {
        this.setState({ loading: false })
        getMailboxBoardData(inbox)
      }
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  mailboxBoardData: state.mailbox.data,
  toggleSignature: state.dashboard.toggleSignatureModal
})

export default connect(
  mapStateToProps,
  {
    deleteMessageEmailCard: MailboxActions.deleteCard,
    getMailboxBoardData: MailboxActions.getBoardData,
    toggleSignatureModal: Dashboard.toggleSignatureModal
  }
)(MailBoxBoard)
