// Import Packages
import * as React from 'react'
import { Button, Form, Select } from 'semantic-ui-react'

// Import Images and Icons
import Modal from 'shared/Modal'

// Import Graphql Schema Types
import { getOffices_getOffices } from 'queries/schema/schemaTypes'

// Import Graphql Queries
import { getOfficesList } from '../UserQueries'

import { Office, Options } from 'store/Users/Types'

// Import Styled Components
import { AddForm, Container, StyledForm } from './Styled'

interface Props {
  currentOffice: Office
  changeUserOffice: (id: string) => void
  closeModal: () => void
}

interface State {
  animate: boolean
  officeOptions: Options[]
  officeId: string
  branchName: string
}

class ReassignModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    branchName: '',
    officeId: '',
    officeOptions: []
  }

  public async componentDidMount() {
    const { data } = await getOfficesList()
    const { currentOffice } = this.props
    if (data && data.getOffices && data.getOffices.length > 0) {
      const officeOptions: Options[] = []
      data.getOffices.forEach((element: getOffices_getOffices) => {
        if (currentOffice._id !== element._id) {
          const obj: Options = {
            key: element._id,
            text: element.branchName,
            value: `${element.branchName}, ${element._id}`
          }
          officeOptions.push(obj)
        }
      })
      this.setState({ officeOptions })
    }
  }

  public render() {
    const { currentOffice } = this.props
    const { animate, officeOptions, branchName } = this.state
    return (
      <Modal
        content={
          <Container>
            <AddForm>
              <StyledForm>
                <Form.Field
                  control={Select}
                  label={`Transfer from ${currentOffice.branchName} To ${branchName}`}
                  name="changeOwnership"
                  placeholder="Please type in office"
                  options={officeOptions}
                  openOnFocus={true}
                  search={true}
                  selection={true}
                  onChange={this.handleChange}
                />
                <Button style={{ width: '170px' }} type="button" onClick={this.changeOffice} content="Change" />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={400}
        height={200}
      />
    )
  }

  private handleChange = async (event: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const res = value.split(', ')
    this.setState({ officeId: res[1], branchName: res[0] })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private changeOffice = () => {
    const { changeUserOffice } = this.props
    const { officeId } = this.state
    changeUserOffice(officeId)
    this.closeModal()
  }
}

export default ReassignModal
