import { FormType, FormErrorType } from './Types'

export const handleValidation = (fields: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!fields.leaderName) {
    formIsValid = false
    errors.leaderName = true
  }

  if (!fields.teamName) {
    formIsValid = false
    errors.teamName = true
  }

  if (!fields.office) {
    formIsValid = false
    errors.office = true
  }

  return { errors, formIsValid }
}

export const handleTeamCapValidation = (fields: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!fields.leaderName) {
    formIsValid = false
    errors.leaderName = true
  }

  if (!fields.teamName) {
    formIsValid = false
    errors.teamName = true
  }

  if (!fields.office) {
    formIsValid = false
    errors.office = true
  }

  if (!fields.teamCap.residentialTargetCap) {
    formIsValid = false
    errors.teamCapResidential = true
  }

  if (!fields.teamCap.commercialTargetCap) {
    formIsValid = false
    errors.teamCapCommercial = true
  }

  return { errors, formIsValid }
}
