// Import Packages
import * as React from 'react'

// Import Styled Components
import {
  ButtonContainer,
  FloatingMenuIcon,
  FloatingMenuItem as MenuItem,
  ImageWrapper,
  Label
} from './Styled'

interface Props {
  icon: string
  className: string
  label?: string
  action?: () => void
  upload?: (label: string | undefined) => void
}

class FloatingMenuItem extends React.Component<Props> {

  public render() {
    const {
      className,
      icon,
      label,
    } = this.props
    return (
      <ButtonContainer>
        {label && <Label>{label}</Label>}
        <MenuItem onClick={this.handleActionClick}>
          <FloatingMenuIcon className={className} onClick={this.handleLabelClick}><ImageWrapper backgroundImage={icon} /></FloatingMenuIcon>
        </MenuItem>
      </ButtonContainer>
    )
  }

  private handleActionClick = () => {
    const { action } = this.props
    if (action) {
      action()
    }
  }

  private handleLabelClick = () => {
    const {
      label,
      upload,
    } = this.props

    if (upload) {
      upload(label)
    }
  }
}

export default FloatingMenuItem
