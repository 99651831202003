// Import Packages
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import styled from 'styled-components'

interface ImageProps {
  backgroundImage: string
}

const StyledContainer = styled(Container)`
  &.ui.fluid.container {
    border: none;
    box-shadow: none;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 90%;
    max-height: 90%;
  }
`

const CardGroup = styled(Card.Group)`
  &.ui.cards {
    padding: 28px;
  }
`

const Infograph = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`

export {
  CardGroup,
  Infograph,
  StyledContainer
}
