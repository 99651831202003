// Import Packages
import * as React from 'react'
import Dropzone from 'react-dropzone'

// Import Images and Icons
import IconUpload from 'design/icons/users/upload_file.png'

// Import Components
import Modal from 'shared/Modal'
import { importLeads } from '../LeadMutations'
import { importLeadSuccessfully } from '../Leads'

// Import Store Types, Actions and Reducers
import { UserType } from 'store/Leads/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  FileDropSection,
  ImageContainer,
  ImageWrapper,
  ModalWrapper,
  SampleFileSection,
  SegmentGroup,
  StyledButton,
  StyledDivider,
  StyledProgress,
  UploadSection
} from './Styled'

const ImportLeadsStrings = Strings.users.importLeads

interface Props {
  handleClose: () => void
  boardId: string
  userFilterState: string
  user: UserType
  toggleStatus: boolean
}

interface State {
  fileName: File
  percent: number,
  dropzoneProgress: boolean
  manualUploadProgress: boolean
  animate: boolean
}

let setStateInterval = 0
export default class ImportLeadsPopup extends React.Component<Props, State> {
  public textInput: any = React.createRef()

  public state = {
    animate: true,
    dropzoneProgress: false,
    fileName: {} as File,
    manualUploadProgress: false,
    percent: 0,
  }

  public dropzoneStartCount = (file: any) => {
    const { handleClose } = this.props
    let percent = 0
    this.setState({ dropzoneProgress: true, fileName: file })
    setStateInterval = window.setInterval(async () => {
      percent += (Math.random() * 70)
      percent = (percent >= 100) ? 100 : percent
      await this.importLeads()
      if (percent === 100) {
        window.clearInterval(setStateInterval)
        handleClose()
        importLeadSuccessfully()
        this.setState({ fileName: file, dropzoneProgress: false })
      }
      this.setState({
        percent
      })
    }, 2000)
  }

  public manualUploadStartCount = (file: File) => {
    let percent = 0
    this.setState({ manualUploadProgress: true, fileName: file })
    setStateInterval = window.setInterval(async () => {
      percent += (Math.random() * 25)
      percent = (percent >= 100) ? 100 : percent
      await this.importLeads()
      if (percent === 100) {
        window.clearInterval(setStateInterval)
        this.closeModal()
        importLeadSuccessfully()
        this.setState({ fileName: file, manualUploadProgress: false })
      }
      this.setState({
        percent
      })
    }, 500)
  }

  public componentWillUnmount() {
    window.clearInterval(setStateInterval)
  }

  public render() {
    const { dropzoneProgress, manualUploadProgress, percent, animate } = this.state
    let fileName = ''
    if (this.state.fileName && this.state.fileName[0]) {
      fileName = this.state.fileName[0].name
    }
    return (
      <Modal
        content={
          <ModalWrapper>
            <div className="header">{ImportLeadsStrings.title}</div>
            <SegmentGroup horizontal={true}>
              <FileDropSection>
                <div className="dropSectionText">{ImportLeadsStrings.dragAndDrop}</div>
                <Dropzone className="test" onDrop={acceptedFiles => this.dropzoneStartCount(acceptedFiles)}>
                  <ImageContainer className="largeContainer">
                    {
                      dropzoneProgress ?
                        <div className='progress-container' style={{ textAlign: 'center' }}>
                          <StyledProgress percent={percent} indicating={true} />
                        </div> :
                        <ImageWrapper backgroundImage={IconUpload} className="large" />
                    }
                  </ImageContainer>
                </Dropzone>
              </FileDropSection>
              <UploadSection>
                <ImageContainer>
                  <ImageWrapper backgroundImage={IconUpload} />
                </ImageContainer>
                <StyledDivider horizontal={true}>{ImportLeadsStrings.selectFile}</StyledDivider>
                {Object.keys(fileName).length > 0 &&
                  <span style={{ textAlign: 'center' }}>{fileName}</span>
                }
                <input
                  type="file"
                  ref={(node: any) => { this.textInput = node }}
                  id="hidden-new-file"
                  style={{ display: 'none' }}
                  name='fileName'
                  onChange={(e: any) => this.manualUploadStartCount(e.target.files)}
                />
                {
                  manualUploadProgress ?
                    <div className='progress-container' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <StyledProgress percent={percent} indicating={true} />
                    </div> :
                    <StyledButton onClick={this.handleFile}>{ImportLeadsStrings.uploadFile}</StyledButton>
                }
              </UploadSection>
            </SegmentGroup>
            <SampleFileSection>
              <div className="textContainer">
                <div className="downloadText">{ImportLeadsStrings.downloadSample}</div>
              </div>
              <StyledButton onClick={this.downloadSampleFile}>{ImportLeadsStrings.download}</StyledButton>
            </SampleFileSection>
          </ModalWrapper>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal} width={800}
      />
    )
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose()
    }, 300)
  }

  private handleFile = () => {
    this.textInput.click()
  }

  private downloadSampleFile = () => {
    window.location.href = 'https://s3-us-west-2.amazonaws.com/onechimp/sample/csv/sample_lead.csv'
  }

  private importLeads = () => {
    const { boardId, userFilterState, user, toggleStatus } = this.props
    const { fileName } = this.state
    importLeads(boardId, fileName[0], userFilterState, user, toggleStatus)
  }
}
