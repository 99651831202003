// Import Packages
import * as React from 'react'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Type,
} from './Styled'

export const CustomPropertySide = (side: string) => {
  let background = ''
  let text = ''
  switch (side) {
    case 'Buyer':
      background = Colors.Green215
      text = 'Buy'
      break
    case 'Seller':
      background = Colors.Red835
      text = 'Sell'
      break
    default:
      background = Colors.Purple50
      text = 'Both'
      break
  }
  return (
    <Type background={background}>{text.toUpperCase()}</Type>
  )
}

export const CustomAddress = (property: any) => {
  return (
    <>
      <div style={{ textTransform: 'capitalize' }}>{property && property.address ? `${property.address.streetNumber} ${property.address.streetName}` : 'No Address Found'}</div>
      <div style={{ textTransform: 'capitalize' }}>{property && property.address ? `${property.address.city}, ${property.address.state}` : 'No Address Found'}</div>
      <div style={{ textTransform: 'capitalize' }}>{property && property.address ? `${property.address.zipCode}` : 'No Address Found'}</div>
    </>
  )
}
