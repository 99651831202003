import * as React from 'react'
import ConfirmAlert from 'sweetalert2'

import DropZone from 'shared/DropZone'
import ActionButtons from 'shared/ImageZone/ActionButtons'
import Images from 'shared/ImageZone/Images'
import LoadingIndicator from 'shared/LoadingIndicator'

import { getLoggedInUser, Strings } from 'utils'

import { Close, Col, Container, Header, Image, Nav, Row, Text, Title, ToolTip } from './Styled'

import { AcceptsEnum } from 'shared/DropZone/Types'
import { ActionEnum } from 'shared/ImageZone/ActionButtons/Types'
import { ImageType, ModeEnum, PermissionEnum } from './Types'

import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronLeft, faChevronRight, faTimes])

interface Props {
  allowMultiple: boolean
  disableClick: boolean
  gridActions: string[]
  images: ImageType[]
  mode: ModeEnum
  onAction: (action: ActionEnum, data: any) => void
  onUploadEach: (file: File, index: number, total: number) => void
  globalPermission: PermissionEnum
  viewActions: string[]
}

interface State {
  activeIndex: number
  infoText: string
  isSorting: boolean
  loading: boolean
  loadingMessage: string
  permission: PermissionEnum
}

class ImageZone extends React.Component<Props, State> {
  public static defaultProps = {
    allowMultiple: true,
    disableClick: false,
    gridActions: ['trash'],
    images: [] as ImageType[],
    mode: ModeEnum.Default,
    onAction: (action: ActionEnum, data: any) => {
      return
    },
    globalPermission: PermissionEnum.None,
    viewActions: ['trash'],
  }

  public state = {
    activeIndex: -1,
    infoText: Strings.imageZone.title.dropClickBelow,
    isSorting: false,
    loading: false,
    loadingMessage: '',
    permission: PermissionEnum.None
  }

  public mounted: boolean = true

  public componentDidMount = () => {
    const { gridActions } = this.props

    if (gridActions.includes('sort')) {
      this.setState({ infoText: Strings.imageZone.title.dropBelow + ' ' + Strings.imageZone.title.organize })
    }
  }

  public componentWillUnmount = () => {
    this.mounted = false
  }

  public render = () => {
    const { allowMultiple, disableClick, gridActions, images, viewActions } = this.props

    const { activeIndex, infoText, isSorting, loading, loadingMessage, permission } = this.state

    return (
      <Container>
        {loading && <LoadingIndicator message={loadingMessage} />}
        {activeIndex < 0 ? (
          <Header justify="space-between" padding={1}>
            <Text>{infoText}</Text>
          </Header>
        ) : (
          <Header justify="space-between" padding={'0.5em 1em'}>
            <Row>
              <ToolTip
                position={'top center'}
                content={'Close preview'}
                trigger={
                  <Close onClick={this.deactivateImage}>
                    <Icon icon={['fal', 'times']} />
                  </Close>
                }
              />
              {images.length > 1 && (
                <React.Fragment>
                  <ToolTip
                    position={'top center'}
                    content={'Previous'}
                    trigger={
                      <Nav className={'previous'} onClick={() => this.handleNav(-1)}>
                        <Icon icon={['fas', 'chevron-left']} />
                      </Nav>
                    }
                  />
                  <ToolTip
                    position={'top center'}
                    content={'Next'}
                    trigger={
                      <Nav className={'next'} onClick={() => this.handleNav(1)}>
                        <Icon icon={['fas', 'chevron-right']} />
                      </Nav>
                    }
                  />
                </React.Fragment>
              )}
            </Row>
            <Title>{images[activeIndex].name}</Title>
            <Row>
              <ActionButtons
                actions={viewActions}
                image={images[activeIndex]}
                onAction={this.handleAction}
                permission={permission}
              />
            </Row>
          </Header>
        )}
        <DropZone
          accepts={AcceptsEnum.Image}
          allowMultiple={allowMultiple}
          disableClick={disableClick || activeIndex >= 0 || isSorting}
          onDropEach={this.handleDropEach}
          showBorder={activeIndex < 0}
          showImage={activeIndex < 0}
          stagger={true}
        >
          {activeIndex < 0 ? (
            <Col>
              <Images
                images={images}
                onAction={this.handleAction}
                getPermission={this.getPermission}
                gridActions={gridActions}
              />
            </Col>
          ) : (
            <Col>
              {permission !== PermissionEnum.None ? (
                <Image src={images[activeIndex].url} />
              ) : (
                <Text>You do not have permission to view this image.</Text>
              )}
            </Col>
          )}
        </DropZone>
      </Container>
    )
  }

  private activateImage = async (activeIndex: number) => {
    const { images, onAction } = this.props
    this.setState({ activeIndex: -1 })

    if (images[activeIndex]) {
      const permission = await this.getPermission(images[activeIndex])
      window.requestAnimationFrame(() => {
        this.setState({
          activeIndex,
          permission
        })
        onAction(ActionEnum.View, images[activeIndex])
      })
    }
  }

  private deactivateImage = () => {
    const { onAction } = this.props
    this.setState({ activeIndex: -1 })
    return onAction(ActionEnum.Close, null)
  }

  private handleNav = (direction: number) => {
    const { images } = this.props
    const { activeIndex } = this.state

    let index = activeIndex + direction
    index = index < 0 ? images.length - 1 : index
    index = index > images.length - 1 ? 0 : index

    this.activateImage(index)
  }

  private handleDropEach = async (file: File, index: number, total: number) => {
    const { onUploadEach } = this.props
    if (this.mounted && index === 1) {
      this.setState({ loading: true })
    }
    if (this.mounted) {
      this.setState({ loadingMessage: `${index}/${total} Uploading image ${file.name}...` })
    }
    await onUploadEach(file, index, total)
    if (this.mounted && index === total) {
      this.setState({ loading: false })
    }
  }

  private handleAction = (action: ActionEnum, data: any) => {
    const { mode, onAction } = this.props

    switch (action) {
      case ActionEnum.View:
        if (mode === ModeEnum.Select) {
          onAction(ActionEnum.Select, data)
        } else {
          this.handleViewImage(data)
        }
        break

      case ActionEnum.Trash:
        this.handleDelete(action, data)
        break

      case ActionEnum.SortStart:
        this.setState({ isSorting: true })
        break

      case ActionEnum.Sort:
      case ActionEnum.SortEnd:
        onAction(action, data)
        window.requestAnimationFrame(() => {
          this.setState({ isSorting: false })
        })
        break

      default:
        return onAction(action, data)
    }
  }

  private handleViewImage = async (doc: ImageType) => {
    const { images } = this.props

    const index = images.findIndex((item: ImageType) => item._id === doc._id)
    if (index < 0) {
      return
    }
    this.activateImage(index)
  }

  private handleDelete = async (action: ActionEnum, data: any) => {
    const { images, onAction } = this.props
    const { activeIndex } = this.state

    const {
      imageZone: {
        alert: { delete: del }
      }
    } = Strings

    const confirm = await ConfirmAlert({
      cancelButtonText: del.cancel,
      confirmButtonText: del.confirm,
      showCancelButton: true,
      text: del.description,
      title: del.title,
      type: 'warning'
    })

    if (confirm.value) {
      if (images.length === 1) {
        this.setState({ activeIndex: -1 })
      } else if (activeIndex !== -1) {
        this.handleNav(-1)
      }
      onAction(action, data)
    }
  }

  private getPermission = async (image: ImageType) => {
    const { globalPermission } = this.props
    if (globalPermission !== PermissionEnum.None) {
      return globalPermission
    }

    const user: any = await getLoggedInUser({ fromCache: true })
    if (!user) {
      return PermissionEnum.None
    }

    if (user.role === 'ADMIN' || user.role === 'MANAGER') {
      return PermissionEnum.Owner
    }

    return PermissionEnum.Viewer
  }
}

export default ImageZone
