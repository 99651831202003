import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'

import reducers from './CombineReducers'

let middleware
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true
  })
  middleware = applyMiddleware(logger)
} else {
  middleware = applyMiddleware()
}

export default createStore(reducers, composeWithDevTools(middleware))
