import { Strings } from 'utils'
import { CommissionItemValueType } from 'app/Transactions/Details/Types'

export const GRAPHIC = {
  Concierge: '',
  Idx: Strings.transactionModal.image.idx,
  ManualAdd: Strings.transactionModal.image.add,
  ManualEdit: Strings.transactionModal.image.edit
}

export const ROLE_OPTIONS: OptionType[] = [
  { key: 'Buyer', text: 'Buyer', value: 'Buyer' },
  { key: 'Seller', text: 'Seller', value: 'Seller' },
  { key: 'Both', text: 'Both', value: 'Both' }
]

export enum ActionEnum {
  Loading = 'transactionForm:loading',
  Submit = 'transactionForm.submit',
  UpdateAll = 'transactionForm:updateAll',
  UpdateOne = 'transactionForm:updateOne'
}

export enum ModeEnum {
  Concierge = 'Concierge',
  Idx = 'Idx',
  ManualAdd = 'ManualAdd',
  ManualEdit = 'ManualEdit'
}

export interface OptionType {
  key: string
  text: string
  value: string
}

export interface FormErrorType {
  amount?: string
  bathrooms?: number
  bedrooms?: number
  city?: string
  currency?: string
  description?: string
  mlsId?: string
  office?: string
  price?: string
  squareFt?: number
  state?: string
  streetAddress?: string
  streetName?: string
  streetNumber?: string
  subType?: string
  transactionRole?: string
  type?: string
  zipCode?: string
}

export interface FormType {
  bathrooms: number
  bedrooms: number
  city: string
  description: string
  errors: FormErrorType
  feeObj?: Partial<CommissionItemValueType>
  mlsId: string
  office: string
  price: string
  squareFt: number
  state: string
  streetAddress: string
  streetName: string
  streetNumber: string
  subType: string
  transactionRole: string
  type: string
  zipCode: string
}

export interface OfficeType {
  _id: string
  branchName: string
}

export interface CategoryType {
  _id: string
  name: string
  propertySubCategories: SubcategoryType[]
}

export interface SubcategoryType {
  _id: string
  name: string
}
