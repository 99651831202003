// Import Packages
import { Label, Tab } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Details from 'design/icons/contacts/details.png'
import Transaction from 'design/icons/contacts/transaction.png'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display:flex;
  background-color: ${Colors.White1000};
  overflow-x: auto;

  .styledTab {
    width: 100%;
  }
`

const StyledTab = styled(Tab)`
  border-right: 1px solid ${Colors.White480};
  width: 76.8% !important;

  .ui.secondary.pointing.menu {
    padding-top: 10px;
    padding-left: 30px;
  }

  .ui.form .field.pets>.selection.dropdown {
    width: 30%;
  }

  .ui.form .field.married>.selection.dropdown {
    min-width: 94%;
  }

  .ui.secondary.pointing.menu .item {
    margin-right: 50px;
    color: ${Colors.Black500};

    @media(max-width: 1250px) {
      margin-right: 0px;
      padding: 0.857143em 0.742857em;
    }
  }

  .fa-envelope-open-text {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .fa-home-heart {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .ui.secondary.pointing.menu {
    border-bottom: 1px solid ${Colors.Grey1000};
    padding-left: 60px;
    margin-bottom: 0;
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 3px solid ${Colors.DarkBlue200};
    color: ${Colors.DarkBlue200};
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledLabel = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 0.4em !important;
  }

  &.ui.label {
    background: ${Colors.Red810} !important;
    color: ${Colors.White1000};
  }
`

const StyledActivityTab = styled(Tab)`
  min-width: 23%;

  .ui.secondary.pointing.menu {
    padding-top: 13px;
    margin-bottom: 0;
  }

  .ui.secondary.pointing.menu .item {
    color: ${Colors.DarkBlue200};
  }

  .ui.secondary.pointing.menu .active.item {
    color: ${Colors.DarkBlue200};
  }

  .ui.secondary.pointing.menu {
    border-bottom: 2px solid ${Colors.White480};
    background: ${Colors.White545};
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 0px;
  }
`

const DetailsIcon = styled.div`
  background: url(${Details});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
  margin-right: 8px;
`

const TransactionIcon = styled.div`
  background: url(${Transaction});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
  margin-right: 8px;
`

export {
  Container,
  DetailsIcon,
  StyledActivityTab,
  StyledLabel,
  StyledTab,
  TransactionIcon
}
