// Import Packages
import { capitalize } from 'lodash'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import ConfirmAlert from 'sweetalert2'

// Import Components
import { deleteTeam } from './UserMutation'
import { getOfficeManager } from './UserQueries'
import { deleteCard, editUser, loggedUser, ShowComposeMail, ToggleStatus } from './Users'

// Import Store Types, Actions and Reducers
import { UsersItem } from 'store/Users/Types'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  // ChatIcon,
  DropDownPopup,
  EmailIcon,
  FullName,
  IconContainer,
  ImageContainer,
  PhoneIcon,
  StyledDropdown,
  StyledName,
  StyledPopup,
  StyledStatusDropdown
} from './Styled'

const usersStrings = Strings.users

const options = [
  {
    key: usersStrings.status.active.value,
    text: usersStrings.status.active.label,
    value: usersStrings.status.active.value
  },
  {
    key: usersStrings.status.inactive.value,
    text: usersStrings.status.inactive.label,
    value: usersStrings.status.inactive.value
  },
  {
    key: usersStrings.status.pending.value,
    text: usersStrings.status.pending.label,
    value: usersStrings.status.pending.value
  }
]

export const CustomActions = ({ rowData }: { rowData: UsersItem }) => {
  const deleteUser = async () => {
    if (loggedUser._id === rowData._id) {
      ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotDelete, 'error')
    } else {
      if (rowData.role === 'MANAGER') {
        const count = await getOfficeManager(rowData.office[0]._id)
        if (count > 1) {
          deleteCard(rowData._id)
        } else {
          ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.onlyManager, 'error')
        }
      } else if (rowData.role === 'TEAM_LEADER') {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesMoveIt,
          showCancelButton: true,
          text: Strings.kanbanView.deleteTeam,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async result => {
          if (result.value) {
            await deleteTeam(rowData.teams._id)
            deleteCard(rowData._id)
            ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          } else {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          }
        })
      } else {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesDeleteIt,
          showCancelButton: true,
          text: Strings.kanbanView.recoverRecord,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(result => {
          if (result.value) {
            deleteCard(rowData._id)
            ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedRecord, 'success')
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeRecord, 'error')
          }
        })
      }
    }
  }
  const editUserDetails = () => {
    editUser({ ...rowData, role: rowData.role === 'TEAM LEADER' ? 'TEAM_LEADER' : rowData.role })
  }
  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              <Link to={Routes.primary.profile.path + `/${rowData._id}`}>
                <Dropdown.Item text={'View'} className="view" />
              </Link>
              {rowData.showEditButton &&
                loggedUser._id !== rowData._id && <Dropdown.Item text={'Edit'} onClick={editUserDetails} />}
              {rowData.showEditButton &&
                loggedUser._id !== rowData._id && <Dropdown.Item text={'Delete'} onClick={deleteUser} />}
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  )
}

export const CustomProfileImage = ({ rowData }: { rowData: UsersItem }) => {
  const classname = rowData.status === usersStrings.status.inactive.value ? 'disabled' : ''
  return <ImageContainer background={rowData.profileImage} className={classname} />
}

export const CustomName = ({ rowData }: { rowData: UsersItem }) => {
  let phone: any
  if (rowData.phones) {
    phone = rowData.phones[0] && rowData.phones.find(card => card.type === 'Mobile')
  }
  const classname = rowData.status === usersStrings.status.inactive.value ? 'disabled' : ''
  const sendMail = () => {
    ShowComposeMail(rowData.userName)
  }
  return (
    <StyledName className={classname}>
      <Link to={Routes.primary.profile.path + `/${rowData._id}`}>
        <div style={{ color: Colors.Black500 }}>
          <FullName>{`${rowData.firstName} ${rowData.lastName}`}</FullName>
        </div>
      </Link>
      <IconContainer>
        <StyledPopup
          trigger={
            <EmailIcon
              onClick={e => {
                e.stopPropagation()
                sendMail()
              }}
            />
          }
          content={rowData.userName}
        />
        <StyledPopup trigger={<PhoneIcon />} content={phone ? phone.value : 'Not Found'} />
        {/* <ChatIcon /> */}
      </IconContainer>
    </StyledName>
  )
}

export const CustomRole = ({ rowData }: { rowData: UsersItem }) => {
  const classname = rowData.status === usersStrings.status.inactive.value ? 'disabled' : ''
  return <div className={classname}>{capitalize(rowData.role)}</div>
}

export const CustomOffice = ({ rowData }: { rowData: UsersItem }) => {
  const classname = rowData.status === usersStrings.status.inactive.value ? 'disabled' : ''
  return (
    <div className={classname}>
      {rowData.office && rowData.office[0] ? `${rowData.office[0].branchName}` : 'No Office'}
    </div>
  )
}

export const CustomCap = ({ rowData }: { rowData: UsersItem }) => {
  const classname = rowData.status === usersStrings.status.inactive.value ? 'disabled' : ''
  if (rowData.userCap) {
    return (
      <div className={classname}>{`$${rowData.userCap.residentialTargetCap} / $${
        rowData.userCap.commercialTargetCap
      }`}</div>
    )
  }
  return <div className={classname}>No Cap</div>
}

export const CustomStatus = ({ rowData }: { rowData: UsersItem }) => {
  let background
  let color
  switch (rowData.status) {
    case usersStrings.status.inactive.value:
      background = Colors.Black810
      color = Colors.Black500
      break
    case usersStrings.status.active.value:
      background = Colors.Green500
      color = Colors.White1000
      break
    case usersStrings.status.pending.value:
      background = Colors.Red800
      color = Colors.White1000
      break
    default:
      background = Colors.Black810
      break
  }
  const handleChangeStatus = (e: any, { value }: any) => {
    if (rowData.status !== value) {
      if (value === 'Inactive') {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepItActive,
          confirmButtonText: Strings.kanbanView.yesMakeItInactive,
          showCancelButton: true,
          text: Strings.kanbanView.inactivateUser,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(result => {
          if (result.value) {
            ToggleStatus(rowData._id, value)
          }
        })
      } else {
        ToggleStatus(rowData._id, value)
      }
    }
  }
  return (
    <div>
      <StyledStatusDropdown
        inline={true}
        options={options}
        onChange={handleChangeStatus}
        value={rowData.status}
        background={background}
        color={color}
      />
    </div>
  )
}
