export interface QuillModulesType {
  history: {
    delay: number
    maxStack: number
  }
  toolbar: {
    container: string
  }
}

export enum TemplateEnumType {
  Free = 'FREE',
  Pro = 'PRO'
}
