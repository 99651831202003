import Colors from 'design/Colors'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import styled from 'styled-components'

const PaymentTypeRadio = styled(Form.Radio)`
  .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {
    background-color: ${Colors.DarkBlue200};
  }

  .ui.checkbox input.hidden+label {
    font-size: 12px;
    color: ${Colors.Black500};
  }
`

const PaymentRadioContainer = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: center;

  @media(max-width:1350px){
    margin-left: 8px;
    margin-right: 0;
  }
`

export {
  PaymentRadioContainer,
  PaymentTypeRadio
}
