import gql from 'graphql-tag'

export const LOGIN_PASSWORD_USER = gql`
  mutation handlePasswordLogin($userName: String!, $password: String!) {
    handlePasswordLogin(userName: $userName, password: $password)
  }
`

export const LOGIN_TOKEN_USER = gql`
  mutation handleTokenLogin($subject: SubjectEnum!, $token: String!) {
    handleTokenLogin(subject: $subject, token: $token)
  }
`

export const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount {
    activateAccount
  }
`
