// Import Packages
import * as React from 'react'
import InputMask from 'react-input-mask'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import { handleCapSettingValidation } from './CapSettingPlanValidation'
import { handleTeamValidation } from './TeamValidation'
import { handleValidation } from './Validation'

// Import Graphql Schema Types
import { getGroups_getGroups, getOffices_getOffices } from 'queries/schema/schemaTypes'

// Import Graphql Queries
import { getCapSettings, getGroups, getOfficeManager, getOfficesList } from '../UserQueries'

// Import Utils
import { Strings } from 'utils'

// Import Store Types, Actions and Reducers
import { Options, UsersItem } from 'store/Users/Types'
import { FormErrorType, FormType } from './Types'

// Import Styled Components
import { getTransactionsSetting } from 'app/Settings/TransactionFee/TransactionFeeDetails/TransactionFeeQueries'
import { AddContactLeftPanel, AddForm, Container, StyledForm } from './Styled'

interface Props {
  closeModal: () => void
  addNewUser: (newUserData: UsersItem | any) => void
  userBoard: string
  editDetails: UsersItem | any
  edit: boolean
  officeID: string
}

interface State {
  form: FormType
  roleOptions: Options[]
  officeOptions: Options[]
  capSettingOptions: Options[]
  selectedCapSetting: Options
  selectedRole: Options
  selectedOffice: Options
  transactionSettings: Options[]
  selectedTransactionSetting: Options
  capSettingErrors: object
  teamErrors: object
  errorUerName: object
  animate: boolean
  capStatus: boolean
  loading: string
}

class AddUserModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    capSettingErrors: {},
    capSettingOptions: [],
    capStatus: false,
    errorUerName: {},
    form: {
      errors: {} as FormErrorType
    } as FormType,
    loading: '',
    officeOptions: [],
    roleOptions: [],
    selectedCapSetting: {
      key: '',
      text: '',
      value: ''
    },
    selectedOffice: {
      key: '',
      text: '',
      value: ''
    },
    selectedRole: {
      key: '',
      text: '',
      value: ''
    },
    selectedTransactionSetting: {
      key: '',
      text: '',
      value: ''
    },
    teamErrors: {},
    transactionSettings: []
  }

  public async componentDidMount() {
    const { userBoard, edit, editDetails, officeID } = this.props
    const { form } = this.state
    this.setState({ loading: edit ? 'Gathering data...' : '' })
    const { data }: any = await getOfficesList()
    if (data && data.getOffices && data.getOffices.length > 0) {
      const offices: Options[] = []
      let selectedOption: Options = { key: '', text: '', value: '' }
      data.getOffices.forEach((element: getOffices_getOffices) => {
        const obj: Options = {
          key: element._id,
          text: element.branchName,
          value: `${element.branchName}, ${element._id}`
        }
        if (Object.keys(editDetails).length > 0 && element._id === editDetails.office[0]._id) {
          selectedOption = obj
        }
        if (data.getOffices.length === 1) {
          selectedOption = obj
        }
        offices.push(obj)
      })
      this.setState({ officeOptions: offices, selectedOffice: selectedOption })
    }
    if (officeID) {
      this.setState({
        selectedOffice: {
          key: officeID.split(', ')[0],
          text: officeID.split(', ')[1],
          value: `${officeID.split(', ')[1]}, ${officeID.split(', ')[0]}`
        }
      })
    }
    const groups: any = await getGroups(officeID.split(', ')[0])
    if (groups.data && groups.data.getGroups) {
      const roles: Options[] = []
      let selectedOption: Options = { key: '', text: '', value: '' }
      groups.data.getGroups.forEach((element: getGroups_getGroups) => {
        const obj: Options = {
          key: element._id,
          text: element.name === 'TEAM_LEADER' ? 'TEAM LEADER' : element.name,
          value: `${element.name}, ${element._id}`
        }
        if (element._id === userBoard || (editDetails && element.name === editDetails.role)) {
          selectedOption = obj
        }
        if (obj.text !== 'ADMIN' && obj.text !== 'TEAM_LEADER') {
          roles.push(obj)
        }
      })
      roles.splice(0, 0, {
        key: '0',
        text: 'Select Role',
        value: 'Choose'
      })
      this.setState({ roleOptions: roles, selectedRole: selectedOption })
    }
    const capSettings: any = await getCapSettings()
    if (capSettings.data && capSettings.data.getCapSettings) {
      const capSetting: Options[] = []
      let status: boolean = false
      let selectedOption: Options = { key: '', text: '', value: '' }
      capSettings.data.getCapSettings.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`
        }
        if (Object.keys(editDetails).length > 0 && element._id === editDetails.capSetting._id) {
          selectedOption = obj
        }
        if (element.name === 'Default' && element.capStatus === 'OFF') {
          status = true
        }
        capSetting.push(obj)
      })
      this.setState({ capSettingOptions: capSetting, selectedCapSetting: selectedOption, capStatus: status })
    }
    const transactionSettings: any = await getTransactionsSetting()
    if (transactionSettings) {
      const transactionSettingOptions: Options[] = []
      let selectedOption: Options = { key: '', text: '', value: '' }
      transactionSettings.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`
        }
        if (Object.keys(editDetails).length > 0 && element._id === editDetails.transactionFeeSetting._id) {
          selectedOption = obj
        }
        if (transactionSettings.length === 1) {
          selectedOption = obj
        }
        transactionSettingOptions.push(obj)
      })
      this.setState({ transactionSettings: transactionSettingOptions, selectedTransactionSetting: selectedOption })
    }
    if (userBoard) {
      form[`role`] = userBoard
      this.setState({ form })
    }
    if (edit) {
      let phone
      if (editDetails.phones !== null) {
        phone = editDetails.phones && editDetails.phones.find((card: any) => card.type === 'Mobile')
      }
      this.setState({
        form: {
          ...editDetails,
          errors: {} as FormErrorType,
          individualCommercialCap: editDetails.userCap.commercialTargetCap.toString(),
          individualResidentialCap: editDetails.userCap.residentialTargetCap.toString(),
          phone: phone.value || '',
          team: editDetails.teams ? editDetails.teams.name : ''
        },
        loading: ''
      })
    }
  }

  public render() {
    const { userBoard, edit, officeID } = this.props
    const {
      loading,
      roleOptions,
      officeOptions,
      capSettingOptions,
      selectedCapSetting,
      selectedRole,
      selectedOffice,
      form,
      form: { errors },
      errorUerName,
      teamErrors,
      capSettingErrors,
      animate,
      transactionSettings,
      selectedTransactionSetting
    } = this.state
    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loading} />}
            <AddContactLeftPanel>
              {edit ? (
                <Image src={Strings.users.addUser.src1} size="small" />
              ) : (
                <Image src={Strings.users.addUser.src} size="small" />
              )}
            </AddContactLeftPanel>
            <AddForm>
              <StyledForm size={'mini'}>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    value={form.firstName}
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                    onChange={this.handleChange}
                    error={!!errors.firstName}
                  />
                  <Form.Field
                    control={Input}
                    value={form.lastName}
                    error={!!errors.lastName}
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    value={form.userName}
                    error={!!errors.userName}
                    label="Email"
                    name="userName"
                    placeholder="Email"
                    disabled={edit ? true : false}
                    onChange={this.handleChange}
                  />
                  <Form.Field control={Input} label="Phone No." error={!!errors.phone}>
                    <InputMask
                      mask="(999) 999-9999"
                      name="phone"
                      placeholder="(xxx) xxx-xxxx"
                      onChange={this.onChange}
                      value={form.phone}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Select}
                    label="Role"
                    name="role"
                    options={roleOptions}
                    value={selectedRole.value}
                    error={!!errors.role}
                    disabled={userBoard ? true : false}
                    placeholder="Role"
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    style={{ width: '100%' }}
                    control={Select}
                    value={selectedOffice.value}
                    label="Office"
                    name="office"
                    options={officeOptions}
                    disabled={officeOptions.length === 1 || officeID ? true : false}
                    placeholder="Office"
                    onChange={this.handleChange}
                    error={!!errors.office}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Select}
                    style={{ width: '100%' }}
                    value={selectedTransactionSetting.value}
                    label="Commission Plan"
                    name="transactionFeeSetting"
                    options={transactionSettings}
                    placeholder="Commission Plan"
                    onChange={this.handleChange}
                    disabled={transactionSettings.length > 1 ? false : true}
                    error={!!errors.transactionFeeSetting}
                  />
                  <Form.Field
                    control={Select}
                    style={{ width: '100%' }}
                    label="Cap Setting"
                    name="capSetting"
                    options={capSettingOptions}
                    value={selectedCapSetting.value}
                    placeholder="Company (Default)"
                    onChange={this.handleChange}
                    error={!!errors.capSetting}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  {(selectedRole.text === 'TEAM_LEADER' || selectedRole.text === 'TEAM LEADER') && (
                    <Form.Field
                      value={form.team}
                      control={Input}
                      label="Team Name"
                      placeholder="Team Name"
                      name="team"
                      onChange={this.handleChange}
                      error={teamErrors[`team`]}
                    />
                  )}
                </Form.Group>
                {selectedCapSetting.text === 'Custom' && (
                  <Form.Group widths="equal" style={{ width: '208px' }}>
                    <Form.Field
                      control={Input}
                      value={form.individualResidentialCap}
                      icon="dollar"
                      iconPosition="left"
                      label="Residential CAP"
                      name="individualResidentialCap"
                      onChange={this.handleChange}
                      error={capSettingErrors[`individualResidentialCap`]}
                    />
                    <Form.Field
                      control={Input}
                      value={form.individualCommercialCap}
                      icon="dollar"
                      iconPosition="left"
                      label="Commerical CAP"
                      name="individualCommercialCap"
                      onChange={this.handleChange}
                      error={capSettingErrors[`individualResidentialCap`]}
                    />
                  </Form.Group>
                )}
                <div style={{ color: 'red' }}>
                  {errorUerName[`userName`] !== undefined && errorUerName[`userName`] === 'Email already exists'
                    ? `Email : ${errorUerName[`userName`]}`
                    : ''}{' '}
                </div>
                <Button onClick={this.userSubmit} content={'SAVE'} />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        minWidth={700}
        width={730}
      />
    )
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private onChange = (event: any) => {
    const { form } = this.state
    form[`phone`] = event.target.value
    this.setState({ form })
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form, selectedOffice } = this.state
    if (name === 'office') {
      const res = value.split(', ')
      form[name] = res[1]
      this.setState({
        selectedOffice: {
          key: res[1],
          text: res[0],
          value: `${res[0]}, ${res[1]}`
        }
      })
    } else if (name === 'role') {
      const res = value.split(', ')
      if (form[`role`] === 'TEAM_LEADER' && res[0] === 'MANAGER') {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.teamLeaderToManager, 'error')
      } else if (form[`role`] === 'MANAGER' && res[0] === 'TEAM_LEADER') {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.managerToTeamLeader, 'error')
      } else if (form[`role`] === 'MANAGER' && res[0] === 'AGENT') {
        const count = await getOfficeManager(selectedOffice.key)
        if (count > 1) {
          form[name] = res[1]
          this.setState({
            selectedRole: {
              key: res[1],
              text: res[0],
              value: `${res[0]}, ${res[1]}`
            }
          })
        } else {
          ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.onlyManager, 'error')
        }
      } else if (form[`role`] === 'AGENT' && res[0] === 'TEAM_LEADER') {
        form[name] = res[1]
        this.setState({
          selectedRole: {
            key: res[1],
            text: res[0],
            value: `${res[0]}, ${res[1]}`
          }
        })
      } else if (form[`role`] === 'TEAM_LEADER' && res[0] === 'AGENT') {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesMoveIt,
          showCancelButton: true,
          text: Strings.kanbanView.deleteTeam,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async result => {
          if (result.value) {
            form[name] = res[1]
            this.setState({
              selectedRole: {
                key: res[1],
                text: res[0],
                value: `${res[0]}, ${res[1]}`
              }
            })
            ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          } else {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          }
        })
      } else if (form[`role`] === res[0]) {
        this.setState({ selectedRole: this.state.selectedRole })
      } else if (res[0] === 'GUEST') {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.notAllowed, 'error')
      } else {
        if (value !== 'Choose') {
          form[name] = res[1]
          this.setState({
            selectedRole: {
              key: res[1],
              text: res[0],
              value: `${res[0]}, ${res[1]}`
            }
          })
        }
      }
    } else if (name === 'capSetting') {
      const res = value.split(', ')
      form[name] = res[1]
      this.setState({
        selectedCapSetting: {
          key: res[1],
          text: res[0],
          value: `${res[0]}, ${res[1]}`
        }
      })
    } else if (name === 'transactionFeeSetting') {
      const res = value.split(', ')
      form[name] = res[1]
      this.setState({
        selectedTransactionSetting: {
          key: res[1],
          text: res[0],
          value: `${res[0]}, ${res[1]}`
        }
      })
    } else {
      form[name] = value
    }
    this.setState({ form })
  }

  private addNewCard = async () => {
    const { addNewUser } = this.props
    const {
      form,
      selectedRole,
      selectedOffice,
      selectedTransactionSetting,
      selectedCapSetting,
      errorUerName
    } = this.state
    form[`role`] = selectedRole.key
    form[`office`] = selectedOffice.key
    form[`transactionFeeSetting`] = selectedTransactionSetting.key
    form[`capSetting`] = selectedCapSetting.key
    form[`phone`] = {
      type: 'Mobile',
      value: form[`phone`]
    }
    this.setState({ form })
    errorUerName[`userName`] = ''
    try {
      if (form) {
        await addNewUser(form)
        this.closeModal()
      }
    } catch (error) {
      form[`phone`] = form[`phone`].value
      errorUerName[`userName`] = 'Email already exists'
      this.setState({ errorUerName })
    }
  }

  private userSubmit = () => {
    const { form, selectedCapSetting, selectedRole, selectedOffice } = this.state
    form[`role`] = selectedRole.key
    if (selectedOffice.key) {
      form[`office`] = selectedOffice.key
    }
    const validation = handleValidation(form)
    let capSettingValidationResult: any = {}
    let teamValidationResult: any = {}
    if (selectedCapSetting.text === 'Custom') {
      capSettingValidationResult = handleCapSettingValidation(form)
    } else {
      capSettingValidationResult[`formIsValid`] = true
    }
    if (selectedRole.text === 'TEAM LEADER') {
      teamValidationResult = handleTeamValidation(form)
    } else {
      teamValidationResult[`formIsValid`] = true
    }

    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({
        capSettingErrors: capSettingValidationResult.errors ? capSettingValidationResult.errors : {},
        form,
        teamErrors: teamValidationResult.errors ? teamValidationResult.errors : {}
      })
      return
    }

    if (validation.formIsValid && teamValidationResult.formIsValid && capSettingValidationResult.formIsValid) {
      this.setState({
        form: {
          errors: {},
          ...form
        }
      })
      this.addNewCard()
    }
  }
}

export default AddUserModal
