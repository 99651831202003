import { ErrorType, FormErrorType, FormType } from './Types'

const validPassword = /^[a-zA-Z0-9~!@#$%^&*\-+,.]{8,}$/
const hasSpecialCharacter = /[~!@#$%^&*\-+,.]+/g
const hasUppercase = /[A-Z]+/g
const hasLowercase = /[a-z]+/g
const hasNumber = /[0-9]+/g
const message = {
  blankPassword: 'Password can not be blank',
  lower: 'at least 1 lowercase letter',
  match: 'Passwords do not match',
  number: 'at least 1 number',
  size: 'at least 8 characters long',
  special: 'at least 1 special character ~!@#$%^&*-+,.',
  upper: 'at least 1 uppercase letter'
}

export const validatePassword = (fields: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!fields.password) {
    formIsValid = false
    errors.password = message.blankPassword
  } else if (fields.password !== fields.passwordConfirm) {
    formIsValid = false
    errors.password = message.match
  } else if (!fields.password.match(validPassword)) {
    formIsValid = false
    errors.password = 'Password must be ' + message.size
  } else if (!fields.password.match(hasSpecialCharacter)) {
    formIsValid = false
    errors.password = 'Password must include ' + message.special
  } else if (!fields.password.match(hasUppercase)) {
    formIsValid = false
    errors.password = 'Password must include ' + message.upper
  } else if (!fields.password.match(hasLowercase)) {
    formIsValid = false
    errors.password = 'Password must include ' + message.lower
  } else if (!fields.password.match(hasNumber)) {
    formIsValid = false
    errors.password = 'Password must include ' + message.number
  }

  const result: ErrorType = { errors, formIsValid }
  return result
}

export const getPasswordPopup = (password: string) => {
  const status = [
    { valid: true, message: message.size },
    { valid: true, message: message.upper },
    { valid: true, message: message.lower },
    { valid: true, message: message.number },
    { valid: true, message: message.special }
  ]
  let isValid = true

  if (!password.match(validPassword)) {
    isValid = false
    status[0].valid = false
  }

  if (!password.match(hasUppercase)) {
    isValid = false
    status[1].valid = false
  }

  if (!password.match(hasLowercase)) {
    isValid = false
    status[2].valid = false
  }

  if (!password.match(hasNumber)) {
    isValid = false
    status[3].valid = false
  }

  if (!password.match(hasSpecialCharacter)) {
    isValid = false
    status[4].valid = false
  }

  return { status, isValid }
}

export const getPasswordConfirmPopup = (password: string, passwordConfirm: string) => {
  const status = [{ valid: true, message: message.match }]
  let isValid = true

  if (password.length === 0 || password !== passwordConfirm) {
    isValid = false
    status[0].valid = false
  }

  return { status, isValid }
}
