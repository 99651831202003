// Import Packages
import * as React from 'react'
import { Button, Icon, Image } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Images and Icons
import Circle from 'design/icons/teams/blue_circle.png'

// Import Components
import client from 'queries/apollo'
import { GetTeamDetail } from '../../Teams'

// Import Graphql Mutations
import { DELETE_INVITE_MEMBER } from 'queries/graphql/Teams/Mutation'

// Import Graphql Queries
import { GET_OFFICES_TEAMS, GET_TEAMS } from 'queries/graphql/Teams/Queries'
import { GET_TEAMS_DETAILS } from 'queries/graphql/Teams/Queries'
import { GET_PRE_SIGNED_URL } from 'queries/graphql/Users/Queries'
import { FormType } from '../../InviteModal/InviteMember/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Commission,
  Container,
  ContainerWrapper,
  Free,
  InvitationDate,
  Profile,
  SliderContainer,
  StyledGroup,
  StyledSlider,
  TeamAgreement,
  UserName
} from './Styled'

const settings = {
  dots: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500
}

interface Props {
  cardId: string
  editInvite: (data: FormType) => void
}

interface State {
  inviteMember: FormType[]
}

class PendingInvites extends React.Component<Props, State> {
  public state = {
    inviteMember: []
  }

  public async componentDidMount() {
    const res = await client.query({ query: GET_TEAMS_DETAILS, variables: { isActive: true, id: this.props.cardId } })
    const { data }: any = res
    this.setState({ inviteMember: data.getTeams[0].members })
  }

  public render() {
    const { inviteMember } = this.state
    const inviteMembers = inviteMember.map((memberObject: any, index: number) => {
      if (memberObject.role !== 'TEAM_LEADER' && memberObject.status !== 'Active') {
        const d = new Date(memberObject.teamInvitedDate)
        let url: any =
          'https://onechimp.s3.amazonaws.com/team_agreements/1551165628136_Screenshot%20from%202019-02-21%2013-24-49.png?AWSAccessKeyId=AKIAJX3IMR4CU4BOS4MQ&Expires=1551776006&Signature=yTGNyQ9lCey9%2F7gnmF0F8Pv9npM%3D'
        let i
        let teamFile = ''
        let value = 0
        if (memberObject.teamAgreement) {
          url = this.getPreSignedUrl(memberObject)
          i = memberObject.teamAgreement.lastIndexOf('/')
          teamFile = memberObject.teamAgreement.substring(i + 1)
          value = teamFile.indexOf('_')
        }
        return (
          <Container key={index}>
            <Profile>
              <Image src={'https://image.flaticon.com/icons/png/512/149/149071.png'} size="small" />
            </Profile>
            <UserName>
              {memberObject.firstName} {memberObject.lastName}
            </UserName>
            <InvitationDate>
              {Strings.teams.invitationDate} {d.toLocaleDateString()}{' '}
            </InvitationDate>
            <TeamAgreement
              onClick={() => teamFile.substring(value + 1) !== 'No Agreement Found' && this.downloadFile(url)}
            >
              <span style={{ width: '90px' }}>{Strings.teams.teamAgreement}</span>
              <span style={{ display: 'flex' }}>
                <Icon name="file pdf outline" />
                <span style={{ width: '100px', wordBreak: 'break-word' }}>{teamFile.substring(value + 1)}</span>
              </span>
            </TeamAgreement>
            <ContainerWrapper>
              <Free>
                <div>{Strings.teams.fee}</div>
                <div>
                  <Icon name="dollar sign" />
                  {memberObject.teamFee}
                </div>
              </Free>
              <Commission>
                <div>{Strings.teams.commission}</div>
                <div>
                  {memberObject.teamCommissionSplit}
                  <Icon name="percent" />
                </div>
              </Commission>
            </ContainerWrapper>
            <StyledGroup widths="equal">
              <Button basic={true} type="button" content="EDIT" onClick={() => this.edit(memberObject)} />
              <Button basic={true} type="button" content="CANCEL" onClick={() => this.delete(memberObject)} />
            </StyledGroup>
          </Container>
        )
      } else {
        return null
      }
    })
    if (inviteMember !== null) {
      return (
        <SliderContainer background={Circle}>
          <StyledSlider {...settings}>{inviteMembers}</StyledSlider>
        </SliderContainer>
      )
    } else {
      return (
        <Container>
          <TeamAgreement>{Strings.teams.noPending}</TeamAgreement>
        </Container>
      )
    }
  }

  private getPreSignedUrl = async (memberObject: any) => {
    const result = await client.query({ query: GET_PRE_SIGNED_URL, variables: { url: memberObject.teamAgreement } })
    return result
  }

  private edit = (data: FormType) => {
    const { editInvite } = this.props
    editInvite(data)
  }

  private downloadFile = async (fileUrl: any) => {
    const url = await fileUrl
    const link = document.createElement('a')
    link.setAttribute('href', url.data.preSignedUrl)
    link.setAttribute('download', url.data.preSignedUrl.split('https://onechimp.s3.amazonaws.com/').slice(1))
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  private delete = (res: any) => {
    const { cardId } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.teams.recoverInvitation,
      title: Strings.teams.cancelInvitation,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        ConfirmAlert(Strings.kanbanView.deleted, Strings.teams.deleteInvitation, 'success')
        client.mutate({
          mutation: DELETE_INVITE_MEMBER,
          refetchQueries: [
            { query: GET_OFFICES_TEAMS, variables: { sortOrder: 1, orderField: 'teamOfficeOrder' } },
            { query: GET_TEAMS, variables: { isActive: true } }
          ],
          update: (cache, { data }: any) => {
            this.setState({ inviteMember: data.deleteInviteMember.inviteMember })
          },
          variables: { teamId: cardId, userId: res._id }
        })
        this.deletePendingInvitesUser(res._id)
        GetTeamDetail()
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, Strings.teams.safeInvitation, 'error')
      }
    })
  }

  private deletePendingInvitesUser = async (userId: string) => {
    const { inviteMember } = this.state
    const feeData = inviteMember.slice()
    const listIndex = feeData.findIndex((laneItem: any) => {
      return laneItem._id === userId
    })
    feeData.splice(listIndex, 1)
    this.setState({ inviteMember: feeData })
  }
}

export default PendingInvites
