// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import { ShowCustomizeModal } from 'app/Navbar/Menu/Menu'
import { ContentContainer, DateContainer, Header, SubHeader } from './Styled'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'

// Import Colors
import Colors from 'design/Colors'

import { faTools } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faTools])

interface Props {
  themeImage: string
}

interface State {
  user: any
  time: any
}

const getTime = () => {
  return moment().format('hh:mm A')
}

class Welcome extends React.Component<Props, State> {
  public setIntervalForTime: any = null

  public state = {
    time: getTime(),
    user: {}
  }

  public updateTime = () => {
    this.setState({ time: getTime() })
  }

  public async componentDidMount() {
    const user = await getLoggedInUser()
    this.setState({ user })
    this.setIntervalForTime = window.setInterval(this.updateTime, 1000)
  }

  public componentWillUnmount() {
    clearInterval(this.setIntervalForTime)
  }

  public render() {
    const { themeImage } = this.props
    const { user, time } = this.state
    return (
      <ContentContainer>
        <DateContainer background={themeImage ? Colors.White1000 : Colors.White1000}>{time}</DateContainer>
        <Header background={themeImage ? Colors.White1000 : Colors.White1000}>
          {selectTimeOfDay(moment().format('HH'))}, {user[`firstName`]}
        </Header>
        <SubHeader onClick={() => ShowCustomizeModal()} background={themeImage ? Colors.White1000 : Colors.White1000}>
          <span>
            <FontAwesomeIcon icon={['fal', 'tools']} />
          </span>
          <strong>Customize</strong>
        </SubHeader>
      </ContentContainer>
    )
  }
}

const selectTimeOfDay = (date: string) => {
  const parsed = parseFloat(date)
  if (parsed < 12) {
    return Strings.welcome.morning
  }
  if (parsed >= 12 && parsed < 17) {
    return Strings.welcome.afternoon
  }
  return Strings.welcome.evening
}

const mapStateToProps = (state: AppState) => ({
  themeImage: state.dashboard.themeImage
})

export default connect(mapStateToProps)(Welcome)
