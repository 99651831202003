// Import Packages
import * as React from 'react'

// Import Components
import FloatingMenuItem from './FloatingMenuItem'

// Import Styled Components
import {
  FloatingMenu
} from './Styled'

interface ActionButton {
  icon: string
  label?: string
  action?: () => void
}

interface Props {
  buttons?: ActionButton[]
  mainButtonIcon: string
  isMenu: boolean
  mainButtonAction?: () => void
  upload?: (label: string | undefined) => void
}

interface State {
  toggled: boolean
}

class FloatingAction extends React.Component<Props, State> {
  public state = {
    toggled: false
  }

  public mainAction = () => {
    const { isMenu, mainButtonAction } = this.props
    if (isMenu) {
      this.setState({ toggled: !this.state.toggled })
    } else if (mainButtonAction) {
      mainButtonAction()
    }
  }

  public render() {
    const { mainButtonIcon, upload } = this.props
    return <FloatingMenu>
      <FloatingMenuItem upload={upload} icon={mainButtonIcon} action={this.mainAction} key="MAIN" className="main" />
    </FloatingMenu>
  }
}

export default FloatingAction
