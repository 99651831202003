import gql from 'graphql-tag'

export const CREATE_LEAD_BOARD = gql`
  mutation createLeadBoard($name: String, $user: String, $order: Int) {
    createLeadBoard(input: { name: $name, user: $user, boardOrder: $order }) {
      _id
      boardOrder
      name
      isActive
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description
      leads {
        _id
        firstName
        lastName
        email
        phone
        propertyType
        leadMaps {
          boardOrder
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        leadScore
        zipCode
        tag
        isActive
        source
        status
        type
      }
    }
  }
`

export const UPDATE_LEAD_BOARD_ORDER = gql`
  mutation updateLeadBoardOrder(
    $boardId: String
    $sourceIndex: Int
    $destinationIndex: Int
    $state: String
    $userId: String
  ) {
    updateLeadBoardOrder(
      where: {
        _id: $boardId
        boardOrder: $sourceIndex
        user: $userId
        state: $state
      }
      input: { boardOrder: $destinationIndex }
    ) {
      _id
      boardOrder
      name
      isActive
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description
      leads {
        _id
        firstName
        lastName
        email
        phone
        propertyType
        leadMaps {
          boardOrder
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        leadScore
        zipCode
        tag
        isActive
        source
        status
        type
      }
    }
  }
`

export const DELETE_LEAD_BOARD = gql`
  mutation deleteLeadBoard($boardId: String) {
    deleteLeadBoard(_id: $boardId)
  }
`

export const UPDATE_LEAD_BOARD = gql`
  mutation updateLeadBoard(
    $boardId: String
    $boardName: String
    $icon: String
    $description: String
  ) {
    updateLeadBoard(
      where: { _id: $boardId }
      input: { name: $boardName, icon: $icon, description: $description }
    ) {
      boardOrder
      name
      isActive
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description
      leads {
        _id
        firstName
        lastName
        email
        phone
        propertyType
        leadMaps {
          boardOrder
        }
        city {
          _id
          name
        }
        state {
          _id
          name
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        leadScore
        zipCode
        tag
        isActive
        source
        status
        type
      }
    }
  }
`
