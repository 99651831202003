// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

// Import Components
import ContactSearchBar from 'app/Contacts//Dashboard/ContactSearchModal/ContactSearchBar'
import DashboardSearchBar from 'app/Dashboard/DashboardSearchModal/DashboardSearchBar'
import LeadSearchBar from 'app/Leads/Dashboard/LeadSearchModal/LeadSearchBar'
import OfficeSearchBar from 'app/Offices/OfficeSearchModal/OfficeSearchBar'
import TaskSearchBar from 'app/Tasks/TaskSearchModal/TaskSearchBar'
import TeamSearchBar from 'app/Teams/TeamSearchModal/TeamSearchBar'
import TransactionSearchBar from 'app/Transactions/Dashboard/TransactionSearchModal/TransactionSearchBar'
import UserSearchBar from 'app/Users/UserSearchModal/UserSearchBar'
import Links from './Links'
import Menu from './Menu'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'

// Import Utils
import { Routes } from 'utils'

// Import Styled Components
import {
  NavbarContainer,
  NavbarWrapper
} from './Styled'

interface Props extends RouteComponentProps<{}> {
  visible: boolean
  history: any
}

class Navbar extends React.Component<Props> {
  public state = {}

  public render() {
    const { visible, history } = this.props
    let SearchBar
    switch (history.location.pathname) {
      case '/contacts':
        SearchBar = <ContactSearchBar />
        break
      case '/leads':
        SearchBar = <LeadSearchBar />
        break
      case '/offices':
        SearchBar = <OfficeSearchBar />
        break
      case '/tasks':
        SearchBar = <TaskSearchBar />
        break
      case '/teams':
        SearchBar = <TeamSearchBar />
        break
      case '/transactions':
        SearchBar = <TransactionSearchBar />
        break
      case '/users':
        SearchBar = <UserSearchBar />
        break
      case '/dashboard':
        SearchBar = <DashboardSearchBar />
        break
      default:
        SearchBar = ''
        break
    }
    return (
      <React.Fragment>
        {visible && (
          <NavbarContainer path={history.location.pathname}>
            <NavbarWrapper path={history.location.pathname}>
              <Menu />
              {SearchBar}
              <Links
                dashboard={
                  history.location.pathname === Routes.primary.dashboard.path
                }
              />
            </NavbarWrapper>
          </NavbarContainer>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  visible: state.layout.navigation.visible
})

export default withRouter(connect(mapStateToProps)(Navbar))
