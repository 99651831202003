import * as React from 'react'
import { match as matchType } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import Scrollable from 'shared/Scrollable'
import {
  Confirmation,
  Login,
  PassSelection,
  Register,
  ResetPassword,
  TeamSelection,
  Welcome
} from './Pages'

import { FullPageWrapper } from './Styled'

import { Routes } from 'utils'

const {
  confirm,
  confirmToken,
  teamSize,
  passSelection,
  register,
  registerToken,
  resetPassword,
  welcome,
  root
} = Routes.onboarding

interface Props {
  match: matchType<{}>
}

class Onboarding extends React.Component<Props> {
  public render() {
    const { match } = this.props
    return (
      <FullPageWrapper>
        <Scrollable>
          <Switch>
            <Route exact={true} path={root} component={Login} />
            <Route path={match.url + resetPassword.path} component={ResetPassword} />
            <Route path={match.url + confirmToken.path} component={Confirmation} />
            <Route path={match.url + confirm.path} component={Confirmation} />
            <Route path={match.url + teamSize.path} component={TeamSelection} />
            <Route path={match.url + passSelection.path} component={PassSelection} />
            <Route path={match.url + registerToken.path} component={Register} />
            <Route path={match.url + register.path} component={Register} />
            <Route path={match.url + welcome.path} component={Welcome} />
          </Switch>
        </Scrollable>
      </FullPageWrapper>
    )
  }
}
export default Onboarding
