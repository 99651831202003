import { FormErrorType, FormType } from './Types'

const validEmail = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.?)+\.[a-zA-Z]{2,}$/

export const validateEmail = (form: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!form.email) {
    formIsValid = false
    errors.email = 'Email can not be blank.'
  } else if (!form.email.match(validEmail)) {
    formIsValid = false
    errors.email = 'Please include a valid email'
  }

  return { errors, formIsValid }
}

export const validateListingId = (form: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!form.listingId) {
    formIsValid = false
    errors.email = 'Listing ID can not be blank.'
  }

  return { errors, formIsValid }
}
