export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`firstName`] || !fields[`firstName`].match(/^[A-Z,a-z]+$/)) {
    formIsValid = false
    errors[`firstName`] = true
  }

  if (!fields[`lastName`] || !fields[`lastName`].match(/^[A-Z,a-z]+$/)) {
    formIsValid = false
    errors[`lastName`] = true
  }

  if (
    !fields[`userName`] ||
    !fields[`userName`].match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    formIsValid = false
    errors[`userName`] = true
  }

  if (!fields[`phone`] || !fields[`phone`].match(/^(\([0-9]{3}\) | [0-9]{3}-)[0-9]{3}-[0-9]{4}$/)) {
    formIsValid = false
    errors[`phone`] = true
  }

  if (!fields[`role`]) {
    formIsValid = false
    errors[`role`] = true
  }

  if (!fields[`capSetting`]) {
    formIsValid = false
    errors[`capSetting`] = true
  }

  if (!fields[`office`]) {
    formIsValid = false
    errors[`office`] = true
  }

  return { errors, formIsValid }
}
