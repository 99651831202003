import gql from 'graphql-tag'

export const GET_DOC_TEMPLATES = gql`
  query getDocTemplate($where: DocTemplateFilter) {
    getDocTemplate(where: $where) {
      _id
      description
      isActive
      name
      role
      template {
        _id
        url
        thumbnailUrl
        extractMarkup {
          id
          label {
            text
            value
          }
          value
          page
          top
          left
          width
          height
        }
        extractRawUrl
        extractWordsUrl
      }
    }
  }
`
