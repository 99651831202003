import { Button, Dropdown, Label, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background?: string
  color?: string
}

export const Container = styled.div`
  ${y};
  padding: 0;
  height: 100%;
  overflow: hidden;
`

export const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

export const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

export const StyeldSchedule = styled.div`
  display: flex;

  .fa-phone {
    width: 12px;
    height: 12px;
    margin-right: 15px;
    transform: rotate(100deg);
    cursor: pointer;
  }

  .fa-envelope {
    width: 15px;
    height: 15px;
    margin-right: 15px;
    cursor: pointer;
  }

  .fa-comment {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
`

export const Activities = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

export const ProfileProgressContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  cursor: pointer;
`

export const Circle = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: ${ props => props.color || props.color};
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4px;
  bottom: 0;
  left: 4px;
  right: 0;
`

export const AcceptButton = styled(Button)`
  &.ui.button {
    font-size: 12px;
    padding: 6px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0px;
    color: ${Colors.White1000};
    background: ${Colors.DarkBlue200};
  }
`
export const Type = styled.div`
  background-color: ${(props: Props) => props.background || props.background};
  color: ${Colors.White1000};
  text-transform: uppercase;
  text-align: center;
  width: 60px;
`

export const StyledStatusDropdown = styled(Dropdown)`
  background: ${(props: Props) => props.background || props.background};
  display: flex !important;
  width: auto;
  min-width: 70px;

  &.ui.dropdown .menu {
    left: unset;
    top: unset;
    margin-top: 1.714286em !important;
  }

  &.ui.dropdown {
    position: unset;
  }

  &.ui.inline.dropdown {
    width: 98px;
  }

  &.ui.inline.dropdown .dropdown.icon {
    color: ${Colors.White1000};
    flex-grow: 1;
    align-self: center;
    text-align: right;
    margin-right: 10px;
  }

  &.ui.inline.dropdown>.text {
    color: ${Colors.White1000};
    margin-left: 5px;
    font-weight: 500;
    flex-grow: 1;
  }

  &.actionDropdown {
    text-align: left !important;
  }
`

export const CheckboxWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`

export const ErrorContainer = styled.div`
  padding: 30px;
  font-size: 20px;
`

export const Error = styled.div`
  font-size: 30px;
  text-align: center;
  color: ${Colors.Red10};
`

export const ErrorText = styled.div`
  font-size: 30px;
  color: ${Colors.Black500};
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
  }
`
