// Import Packages
import { Accordion, Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background?: string
  border?: string
}

const Container = styled.div`
  margin-top: 20px;

  h2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${Colors.DarkBlue205};
  }
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
  border-top: 3px solid ${(props: Props) => props.border || Colors.White1000};
  white-space: nowrap;
  cursor: pointer;
`

const Triangle = styled.div`
  position: absolute;
  border-top: 23px solid ${Colors.transparent};
  border-bottom: 23px solid ${Colors.transparent};
  border-left: 23px solid ${Colors.White1000};
  right: -23px;
  top: 0;
`

const Text = styled.div`
  background-color: ${Colors.White1000};
  height: 45px;
  padding: 12px;
  padding-left: 20px;
  color: ${Colors.DarkBlue200};
  font-weight: 500;

  i {
    margin-right: 8px;
    font-family: Georgia, serif;
    font-size: 24px;
    font-weight: bold;
  }
`

const Reports = styled.div`
  background-color: ${Colors.White1000};
  height: 470px;
  overflow: auto;
  ${scrollbars};
`

const AccordionContainer = styled.div`
  padding-top: 25px;
`

const StyledAccordion = styled(Accordion)`
  &.ui.accordion .title {
    background-color: ${Colors.White525};
    color: ${Colors.Black555} !important;
    margin-bottom: 5px;
    font-size: 14px !important;
    user-select: none;
  }

  &.ui.accordion:not(.styled) .title~.content:not(.ui) {
    padding: 0;
  }

  span {
    margin-left: 20px;
  }

  .fa-caret-down {
    float: right;
    margin-right: 20px;
    width: 12px;
    height: 12px;
    margin-top: 4px;
    color: ${Colors.Black555};
  }

  .fa-caret-right {
    float: right;
    margin-right: 20px;
    width: 12px;
    height: 12px;
    margin-top: 4px;
    color: ${Colors.Black555};
  }

`

const StyledContent = styled.div`
  font-size: 13px;
  border-bottom: 1px solid ${Colors.Grey555};
  padding: 10px;
  padding-left: 20px;
  display: flex;
  cursor: pointer;
  background-color: ${(props: Props) => props.background || props.background};

  span {
    margin-left: 14px;
    color: ${Colors.Black555};
  }
`

const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox {
    min-height: 14px;
  }
  
  &.ui.checkbox.disabled{
    opacity: 0.5;
    cursor:not-allowed;
  }

  &.ui.fitted.checkbox label {
    padding-left: 1.85714em !important;
  }

  &.ui.checkbox label:before {
    width: 14px;
    height: 14px;
    top: 2px;
    border: 1.5px solid ${Colors.Black555};
    border-radius: 2px;
  }
`

const Infograph = styled.div`
  background: ${(props: Props) => `url(${props.background}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`

export {
  AccordionContainer,
  Container,
  Reports,
  StyledAccordion,
  StyledCheckbox,
  StyledContent,
  Title,
  Triangle,
  Text,
  Infograph
}
