import Colors from 'design/Colors'
import HeaderImage from 'design/images/header.png'
import { x, y } from 'design/Styled'
import styled from 'styled-components'

export const Container = styled.section`
  ${y};
  position: relative;
  background: ${Colors.Container};
  width: 8.5in;
  height: 11in;
  margin: 0 auto;
  padding: 0;
`

export const Hero = styled.div`
  display: block;
  width: 100%;
  height: 3in;
  background: #444;
`

export const Details = styled.div`
  display: flex;
  background: ${Colors.TextPrimaryHover};
  height: 6in;
`

export const Description = styled.div`
  width: 60%;
  height: 100%;
`

export const Row = styled.div`
  ${x};
  justify-content: space-between;
  position: relative;
  width: 40%;
  height: 100%;
  padding: 0.15in;
  flex-direction: column;
`

export const Image = styled.div`
  ${x};
  justify-content: center;
  width: 100%;
  height: 1.8in;
  background: #444;
  box-shadow: 0 0 0.4in -0.4in #333;
`

export const Info = styled.div`
  ${x};
  justify-content: space-between;
  height: 1.8in;
  padding-right: 1em;
  margin-top: 12px;
`

export const Text = styled.div``

export const Address = styled.div``

export const Price = styled.div`
  font-weight: bold;
`

export const Footer = styled.div`
  background: #fff;
  margin-right: 14px;
  height: 3.9in;
  padding: 1em;
`

export const CompanyLogo = styled.div`
  ${y} justify-content: center;
  align-items: center;
  height: 1in;
  background-color: #444;
`

export const Logo = styled.div`
  width: 10%;
  height: 55%;
  background: #444;
`

export const MLSId = styled.div`
  font-size: 13pt;
  color: white;
  font-weight: bold;
  padding-bottom: 0.2em;
`

export const Header = styled.div`
  background: url(${HeaderImage});
  background-size: contain;
  background-repeat: round;
  background-position: center;
  height: 1in;
`

export const Category = styled.div`
  ${x};
  justify-content: flex-end;
  height: 100%;
  font-size: 24pt;
  font-family: 'Fredericka the Great';
  text-transform: uppercase;
  color: #fff;
  padding-right: 1.5em;
`
