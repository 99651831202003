// Import Packages
import * as React from 'react'
import Dropzone from 'react-dropzone'

// Import Images and Icons
import IconUpload from 'design/icons/users/upload_file.png'

// Import Components
import { TaskItemsDetails, UserType } from 'store/Tasks/Types'
import { importTasks } from '../TaskMutations'
import { ImportTaskSuccessfully } from '../Tasks'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Backdrop,
  FileDropSection,
  ImageContainer,
  ImageWrapper,
  Modal,
  ModalWrapper,
  SampleFileSection,
  SegmentGroup,
  StyledButton,
  StyledDivider,
  StyledProgress,
  UploadSection
} from './Styled'

const importTasksStrings = Strings.users.importTasks

interface Props {
  boardId: string
  filterOptions: boolean
  user: UserType
  closeModal: () => void
  importData: (boardId: string, data: TaskItemsDetails) => void
}

interface State {
  fileName: File
  percent: number,
  dropzoneProgress: boolean
  manualUploadProgress: boolean
}

let setStateInterval = 0
export default class ImportTasksPopup extends React.Component<Props, State> {
  public textInput: any = React.createRef()

  public state = {
    dropzoneProgress: false,
    fileName: {} as File,
    manualUploadProgress: false,
    percent: 0,
  }

  public dropzoneStartCount = (file: any) => {
    const { closeModal } = this.props
    let percent = 0
    this.setState({ dropzoneProgress: true, fileName: file })
    setStateInterval = window.setInterval(async () => {
      percent += (Math.random() * 70)
      percent = (percent >= 100) ? 100 : percent
      if (percent >= 100) {
        window.clearInterval(setStateInterval)
        await this.importTasks()
        ImportTaskSuccessfully()
        closeModal()
        this.setState({ fileName: file, dropzoneProgress: false })
      }
      this.setState({
        percent
      })
    }, 2000)
  }

  public manualUploadStartCount = (file: File) => {
    const { closeModal } = this.props
    let percent = 0
    this.setState({ manualUploadProgress: true, fileName: file })
    setStateInterval = window.setInterval(async () => {
      percent += (Math.random() * 25)
      percent = (percent >= 100) ? 100 : percent
      if (percent >= 100) {
        window.clearInterval(setStateInterval)
        await this.importTasks()
        ImportTaskSuccessfully()
        closeModal()
        this.setState({ fileName: file, manualUploadProgress: false })
      }
      this.setState({
        percent
      })
    }, 500)
  }

  public componentWillUnmount() {
    window.clearInterval(setStateInterval)
  }

  public render() {
    const { closeModal } = this.props
    const { dropzoneProgress, manualUploadProgress, percent } = this.state
    let fileName = ''
    if (this.state.fileName && this.state.fileName[0]) {
      fileName = this.state.fileName[0].name
    }
    return (
      <ModalWrapper>
        <Backdrop onClick={closeModal.bind({}, false)} />
        <Modal id="paymentInfoModal">
          <div className="header">{importTasksStrings.title}</div>
          <SegmentGroup horizontal={true}>
            <FileDropSection>
              <div className="dropSectionText">{importTasksStrings.dragAndDrop}</div>
              <Dropzone className="test" onDrop={acceptedFiles => this.dropzoneStartCount(acceptedFiles)}>
                <ImageContainer className="largeContainer">
                  {
                    dropzoneProgress ?
                      <div className='progress-container' style={{ textAlign: 'center' }}>
                        <StyledProgress percent={percent} indicating={true} />
                      </div> :
                      <ImageWrapper backgroundImage={IconUpload} className="large" />
                  }
                </ImageContainer>
              </Dropzone>
            </FileDropSection>
            <UploadSection>
              <ImageContainer>
                <ImageWrapper backgroundImage={IconUpload} />
              </ImageContainer>
              <StyledDivider horizontal={true}>{importTasksStrings.selectFile}</StyledDivider>
              {Object.keys(fileName).length > 0 &&
                <span style={{ textAlign: 'center' }}>{fileName}</span>
              }
              <input
                type="file"
                ref={(node: any) => { this.textInput = node }}
                id="hidden-new-file"
                style={{ display: 'none' }}
                name='fileName'
                onChange={(e: any) => this.manualUploadStartCount(e.target.files)}
              />
              {
                manualUploadProgress ?
                  <div className='progress-container' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <StyledProgress percent={percent} indicating={true} />
                  </div> :
                  <StyledButton onClick={this.handleFile}>{importTasksStrings.uploadFile}</StyledButton>
              }
            </UploadSection>
          </SegmentGroup>
          <SampleFileSection>
            <div className="textContainer">
              <div className="downloadText">{importTasksStrings.downloadSample}</div>
            </div>
            <StyledButton onClick={this.downloadSampleFile}>{importTasksStrings.download}</StyledButton>
          </SampleFileSection>
        </Modal>
      </ModalWrapper>
    )
  }

  private handleFile = () => {
    this.textInput.click()
  }

  private downloadSampleFile = () => {
    window.location.href = 'https://onechimp.s3-us-west-2.amazonaws.com/sample/csv/sample_task.csv'
  }

  private importTasks = async () => {
    const { boardId, filterOptions, user, importData } = this.props
    const { fileName } = this.state
    const response = await importTasks(boardId, fileName[0], filterOptions, user)
    importData(boardId, response)
  }
}
