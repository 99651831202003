export const navigationMockData = [
  {
    caretRight: 'caret-right',
    icon: 'comment-alt-plus',
    name: 'Notes',
  },
  {
    caretRight: 'caret-right',
    icon: 'layer-plus',
    name: 'Create Transaction',
  },
  {
    caretRight: 'caret-right',
    icon: 'hand-holding-usd',
    name: 'Reassign Lead',
  },
  {
    caretRight: 'caret-right',
    icon: 'archive',
    name: 'Archive Lead',
  }
]
