// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import { UPDATE_USER_SIGNATURE_DATA } from 'queries/graphql/UserProfile/Mutation'
import { GET_USER_SIGNATURE_DATA } from 'queries/graphql/UserProfile/Queries'

export const updateSignatureData = async (id: string, signature: any) => {
  const {
    address,
    colorLink,
    colorText,
    company,
    companyPosition,
    fontFamily,
    fontSize,
    name,
    nameTitle,
    phone,
    profileImage,
    templateId,
    urlFacebook,
    urlLinkedin,
    urlTwitter,
    urlWebsite
  } = signature
  const mutation = await client.mutate({
    mutation: UPDATE_USER_SIGNATURE_DATA,
    refetchQueries: [
      {
        query: GET_USER_SIGNATURE_DATA,
        variables: { id }
      }
    ],
    variables: {
      _id: id,
      address,
      colorLink,
      colorText,
      company,
      companyPosition,
      fontFamily,
      fontSize,
      name,
      nameTitle,
      phone,
      profileImage,
      templateId,
      urlFacebook,
      urlLinkedin,
      urlTwitter,
      urlWebsite
    }
  })

  if (mutation.data) {
    return true
  }

  return false
}
