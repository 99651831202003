// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Timeline } from 'react-event-timeline'

// Import Styled Components
import { Container, People, StyledPopup, StyledTimelineEvent, TimeLineContainer } from './Styled'

// Import Store Types, Actions and Reducers
import { Activities } from 'store/Contacts/Types'

// Font Awesome Icons
import { faCheck, faEnvelope, faHandshake, faPhone, faSms, faTicket } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCheck, faEnvelope, faPhone, faSms, faHandshake, faTicket])

interface StoreProps {
  activities: Activities[]
}

type Props = StoreProps

const taskIcons = [
  { name: 'phone', value: 'Call' },
  { name: 'envelope', value: 'Email' },
  { name: 'sms', value: 'Message' },
  { name: 'handshake', value: 'Meeting' },
  { name: 'ticket', value: 'Passes' }
]

class Activity extends React.Component<Props> {
  public render() {
    const { activities } = this.props
    let timeLine
    if (activities) {
      timeLine = activities.map((items: any, index: number) => {
        let showIcon: any
        if (items.task) {
          showIcon = taskIcons.find((iconObj: any) => {
            return iconObj.value === items.task.name
          })
        }
        return (
          <Timeline key={index}>
            <StyledTimelineEvent
              title={
                <Container>
                  <div>
                    <p>
                      <b style={{ textTransform: 'capitalize' }}>{items.type.toLowerCase()}</b> {items.description}
                    </p>
                  </div>
                </Container>
              }
              createdAt={moment(items.updatedAt).format('LL')}
              icon={
                items.owner !== null ? (
                  <StyledPopup
                    trigger={<People background={items.owner[`profileImage`]} />}
                    content={`${items.owner[`firstName`]} ${items.owner[`lastName`]}`}
                  />
                ) : showIcon && showIcon.name ? (
                  <FontAwesomeIcon icon={['far', showIcon.name]} />
                ) : (
                  <FontAwesomeIcon icon={['far', 'check']} />
                )
              }
              style={{ lineHeight: '12px', marginLeft: '10px', top: '5px' }}
            />
          </Timeline>
        )
      })
    }
    return <TimeLineContainer className="notes-styling">{timeLine}</TimeLineContainer>
  }
}

export default Activity
