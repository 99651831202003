import * as React from 'react'
import ConfirmAlert from 'sweetalert2'

import LoadingIndicator from 'shared/LoadingIndicator'
import Billing from './Billing'
import Plans from './Plans'

import { updateBillingPlan } from 'shared/Billing/Mutations'
import PaymentMethodModal from 'shared/Billing/PaymentMethodModal'
import { getCancellationPreview, getPaymentMethods, getUpcomingInvoice } from 'shared/Billing/Queries'
import Toast, { serverToast } from 'shared/Toast/Toast'
import { Strings } from 'utils'

import { Col, Container } from './Styled'

import { BillingCycleType, BillingPlanEnum, InvoiceType, LineItemType, PaymentMethodType } from 'shared/Billing/Types'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'

interface State {
  loading: boolean
  invoice: InvoiceType
  modal: any
  paymentMethods: PaymentMethodType[]
}

class Profile extends React.Component<{}, State> {
  public state = {
    invoice: {
      cycle: {
        plan: BillingPlanEnum.None,
        status: 'error'
      } as BillingCycleType,
      lines: [] as LineItemType[]
    } as InvoiceType,
    loading: true,
    modal: null,
    paymentMethods: [] as PaymentMethodType[]
  }

  public componentDidMount = () => {
    this.handleGetBillingData()
    this.handleGetPaymentMethods()
  }

  public render() {
    const { invoice, loading, modal } = this.state

    return (
      <Container>
        <Col width={65}>
          <Plans cycle={invoice.cycle} onUpdate={this.handleManagePlan} />
          {loading && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
        </Col>
        <Col width={35}>
          <Billing invoice={invoice} onUpdate={this.handleManagePlan} />
        </Col>
        {modal}
      </Container>
    )
  }

  private handleManagePlan = async (planType: BillingPlanEnum) => {
    if (planType === BillingPlanEnum.Free) {
      let message = ''
      const cancellation: any = await getCancellationPreview()
      if (cancellation) {
        message = ` Upon canceling, you will be invoiced for the remaining amounts on your billing cycle: $${
          cancellation.total_decimal
        }`
      }
      const {
        billing: {
          alert: { downgrade }
        }
      } = Strings
      const confirm = await ConfirmAlert({
        cancelButtonText: downgrade.cancel,
        confirmButtonText: downgrade.confirm,
        showCancelButton: true,
        text: downgrade.description + message,
        title: downgrade.title,
        type: 'warning'
      })
      if (!confirm.value) {
        return
      }
    }

    const { paymentMethods } = this.state

    if (planType === BillingPlanEnum.Paid && paymentMethods.length > 0) {
      const setPaymentMethod = (paymentMethod: PaymentMethodType | null) => {
        this.setState({ modal: null })
        if (paymentMethod) {
          this.handleCheckout(planType, paymentMethod.id || '')
        }
      }
      const modal = <PaymentMethodModal methods={paymentMethods} onClose={setPaymentMethod} />
      this.setState({ modal })
      return
    } else {
      this.handleCheckout(planType, '')
    }
  }

  private handleCheckout = async (plan: BillingPlanEnum, stripePaymentMethodId: string) => {
    this.setState({ loading: true })
    const input = {
      plan,
      stripePaymentMethodId
    }
    const billing: any = await updateBillingPlan(input)
    if (!billing) {
      this.setState({ loading: false })
      return
    }

    if (billing.sessionId && (window as any).Stripe) {
      const stripe = (window as any).Stripe(billing.publicKey)
      const result: any = await stripe.redirectToCheckout({
        sessionId: billing.sessionId
      })

      if (result.error) {
        Toast({
          message: result.error.message,
          type: 'error'
        })
      }
    }

    await this.handleGetBillingData()

    if (billing.status === 'success') {
      Toast({
        message: 'Your billing and plan information has been updated.',
        type: 'success'
      })
    }

    this.setState({ loading: false })
    await this.handleGetPaymentMethods()
  }

  private handleGetBillingData = async () => {
    this.setState({ loading: true })
    try {
      const invoice: any = await getUpcomingInvoice('network-only')
      if (invoice) {
        this.setState({ invoice })
      }
    } catch (error) {
      serverToast(error)
    }
    this.setState({ loading: false })
  }

  private handleGetPaymentMethods = async () => {
    try {
      const methods = await getPaymentMethods()
      if (!methods.error && methods.cards) {
        const paymentMethods = methods.cards
        this.setState({ paymentMethods })
      }
    } catch (error) {
      serverToast(error)
    }
  }
}

export default Profile
