// Import Components
import client from 'queries/apollo'

// Import Graphql Mutations
import {
  CREATE_PUBLIC_NOTIFICATION,
  DELETE_CREATED_BY_PUBLIC_NOTIFICATION,
  DELETE_FEED_NOTIFICATION,
  DELETE_PUBLIC_NOTIFICATION,
  UPDATE_FEED_NOTIFICATION,
  UPDATE_PUBLIC_NOTIFICATION
} from 'queries/graphql/Notification/Mutation'
import { GET_FEED_NOTIFICATION, GET_PUBLIC_NOTIFICATION } from 'queries/graphql/Notification/Queries'

// Public Notification
export const createPublicNotification = async (data: any) => {
  await client.mutate({
    mutation: CREATE_PUBLIC_NOTIFICATION,
    variables: { ...data }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PUBLIC_NOTIFICATION
  })

  return response.data.getPublicNotifications
}

export const updatePublicNotification = async (data: any) => {
  await client.mutate({
    mutation: UPDATE_PUBLIC_NOTIFICATION,
    variables: { ...data }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PUBLIC_NOTIFICATION
  })

  return response.data.getPublicNotifications
}

export const deletePublicNotification = async () => {
  await client.mutate({
    mutation: DELETE_PUBLIC_NOTIFICATION
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PUBLIC_NOTIFICATION
  })

  return response.data.getPublicNotifications
}

export const deleteCreatedByPublicNotification = async (id: string) => {
  await client.mutate({
    mutation: DELETE_CREATED_BY_PUBLIC_NOTIFICATION,
    variables: { id }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PUBLIC_NOTIFICATION
  })

  return response.data.getPublicNotifications
}

// Public Notification
export const updateFeedNotification = async (data: any) => {
  await client.mutate({
    mutation: UPDATE_FEED_NOTIFICATION,
    variables: { ...data }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_FEED_NOTIFICATION
  })

  return response.data.getFeedNotifications
}

export const deleteFeedNotification = async () => {
  await client.mutate({
    mutation: DELETE_FEED_NOTIFICATION
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_FEED_NOTIFICATION
  })

  return response.data.getFeedNotifications
}
