import { Strings } from 'utils'

import { LoginFormErrorType, LoginFormType } from './Types'

export const handleValidation = (fields: LoginFormType) => {
  const errors = {} as LoginFormErrorType
  let formIsValid = true

  if (
    !fields.userName ||
    !fields.userName.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    formIsValid = false
    errors.userName = `${Strings.onboarding.inputUsername} can not be empty`
  }

  if (!fields.password || !fields.password.match(/^[A-Za-z0-9!@#$%^&*]*$/)) {
    formIsValid = false
    errors.password = `${Strings.onboarding.inputPassword} can not be empty`
  }

  return { errors, formIsValid }
}
