// Import Packages
import { Button } from 'semantic-ui-react'
import { css } from 'styled-components'
import styled from 'styled-components'

// Import Fonts, Colors
import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

const children = css`
  & > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  & > :first-child {
    margin-left: 0;
  }

  & > :last-child {
    margin-right: 0;
  }
`

const padding = css`
  padding: 1em 2.5em;
`

const text = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Black500};
  margin: 0;
  line-height: 1.5;
`

const row = css`
  display: block;
  width: 100%;

  &:before,
  &:after {
    content: '';
    display: table;
    width: 100%;
    position: relative;
  }
`

const flexRow = css`
  ${children};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const flexCol = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const Container = styled.div`
  ${text} display: flex;
  flex-direction: column;
  height: 90vh;
  background: ${Colors.White1000};
`

export const Header = styled.header`
  ${flexCol};
`

export const HeaderRow = styled.section`
  ${flexRow} ${padding}
  padding-top: 1.1em;
  padding-bottom: 1.1em;
  justify-content: space-between;
  width: 100%;
  background: ${Colors.White700};
`

export const Envelope = styled.section`
  ${flexRow} flex: 1;
`

export const EnvelopeCol = styled.section`
  ${flexCol} flex: 1;
`

export const MessageActions = styled.div`
  ${flexRow};
`

export const Subject = styled.h1`
  ${row}
  ${padding}
  ${text}
  font: ${fonts.large.regular};
  text-align: left;
`

export const From = styled.h2`
  ${text}
  font: ${fonts.medium.medium};
`

export const Content = styled.section`
  ${flexRow} ${padding}
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: flex-start;
`

export const IFrame = styled.iframe`
  ${flexRow} width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
`

export const Meta = styled.ul`
  ${flexRow} margin: 0;
  padding: 0.8em 0 0;

  & > :first-child {
    padding-left: 0;
  }

  & > :last-child {
    border-right: none;
  }
`

export const MetaItem = styled.li`
  ${text};
  ${flexRow};
  margin: 0;
  padding: 0 1em;
  font: ${fonts.small.regular};
  border-right: 1px solid ${Colors.Black500};
`

export const AttachmentUl = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  margin-bottom: 1em;
  padding: 0;
`

export const AttachmentLi = styled.li`
  ${padding};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  wodth: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  justify-content: space-between;
  width: 100%;
`

export const Text = styled.span`
  ${text};
`

export const Plaintext = styled.pre`
  ${text} width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
`

export const FileActions = styled(Button.Group)``

export const FileButton = styled(Button)`
  ${flexRow};
`
export const FileDetails = styled.div`
  ${flexRow};
`
