export interface UserType {
  _id: string
  accountNumber: string
  bankName: string
  ein: string
  paymentMethod: PaymentMethodEnum
  role: string
  routingNumber: string
  taxID: string
  taxReportName: string
  firstName : string
  lastName:string
}

export interface FormErrorType{
  accountNumber: string
  bankName: string
  ein: string
  paymentMethod: string
  routingNumber: string
  taxID: string
  taxReportName: string
}

export interface FormType {
  accountNumber: string
  bankName: string
  ein: string
  paymentMethod: PaymentMethodEnum
  routingNumber: string
  taxID: string
  taxReportName: string
  errors: FormErrorType
}

export enum PaymentMethodEnum {
  Direct = 'Direct Deposit',
  Ach = 'ACH',
  Check = 'Check',
  PayPal = 'PayPal',
}
