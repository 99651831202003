import { Tab } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { row, scrollXContainer, scrollSize } from 'design/Styled'

interface ImageProps {
  backgroundImage: string
}

export const Container = styled.div`
  background-color: ${Colors.transparent};
  height: 70%;
  border: none;
  margin: 0px;
  padding: 0.5em 0em;
  border-radius: .28571429rem;
  position: relative;

  .ui.button {
    position: absolute;
    top: 20px;
    right: 0px;
    font-size: 0.8rem;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

export const StyledTab = styled(Tab)`
  .ui.secondary.pointing.menu {
    ${row};
    ${scrollXContainer};
    justify-content: space-between;
    padding-bottom: ${scrollSize}px;
    border: none;

    a.item {
      flex: 1;
      justify-content: center;
      border: none;
      white-space: nowrap;
      color: ${Colors.TextDark};
      box-shadow: inset 0 -5px 0 -3px ${Colors.TableHeadLine};
      transition: all 200ms;
      &.active {
        color: ${Colors.TextPrimary};
        box-shadow: inset 0 -6px 0 -3px ${Colors.TextPrimary};
      }
    }
  }
`

export const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 23px;
  height: 21px;
  align-self: center;
  margin: 0px;
  margin-right: 5px;
`
