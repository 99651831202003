// Import Utils
import { generateID } from 'utils'

export const formatChecklist = (checklist: any, documents: any, mls: string = '') => {
  const checklistData = Object.assign(checklist)
  for (const data of checklistData) {
    if (data.docCheckLists.length === 0 || data.docCheckLists === null || data.docCheckLists === undefined) {
      data.property = Object.assign([])
      data.property.push({
        _id: generateID(),
        name: '+ Add Property Type',
        value: {}
      })
    } else {
      data.property = Object.assign([], data.docCheckLists)
      data.property.push({
        _id: generateID(),
        name: '+ Add Property Type',
        value: {}
      })
      if (data.docCheckLists) {
        const property = Object.assign([], data.docCheckLists)
        // tslint:disable-next-line:forin
        for (const index in property) {
          if (property[index].checkListSubType) {
            property[index].name = property[index].checkListSubType
          } else {
            property[index].name = ''
          }
          let valueObj = {}
          let documentsList: any = []
          if (property[index].docTemplates) {
            documentsList = Object.assign(property[index].docTemplates)
          } else {
            documentsList = Object.assign([])
          }
          if (!property[index].docTemplates) {
            documents.forEach((document: any) => {
              valueObj = {
                ...valueObj,
                [document._id]: false
              }
            })
          } else {
            documents.forEach((document: any) => {
              const docIndex = documentsList.findIndex((doc: any) => {
                return doc._id === document._id
              })
              if (docIndex >= 0) {
                valueObj = {
                  ...valueObj,
                  [document._id]: true
                }
              } else {
                valueObj = {
                  ...valueObj,
                  [document._id]: false
                }
              }
            })
          }
          property[index].value = Object.assign(valueObj)
        }
      }
    }
  }
  return checklistData
}
