import styled from 'styled-components'

import { x, children, text } from 'design/Styled'

export const Container = styled.div`
  ${x};
  ${children};
  align-items: center;
  justify-content: space-between;
`

export const Label = styled.span`
  ${text};
  flex: 1;
  font-weight: bold;
  line-height: 1;
  text-align: center;
`
