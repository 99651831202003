import { Radio } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'

export const StyledRadio = styled(Radio)`
  &.ui.radio.checkbox {
    font-size: 1em;
    label {
      color: ${Colors.Text};
    }
    input {
      &:checked~.box:after,
      &:checked~label:after,
      &:focus:checked~label:after {
        background-color: ${Colors.ButtonPrimary};
      }
    }
  }
`
