// Import Packages
import * as React from 'react'

// Import Utils
import { Strings } from 'utils'

import { UserType } from 'store/Users/Types'

// Import Styled Components
import {
  AddButtonContainer,
  Align,
  AlignContainer,
  Calendar,
  CloseIcon,
  Container,
  CustomButton,
  CustomInput,
  Filter,
  FilterIcon,
  SearchIcon,
  SearchInput,
  StyledDropdown,
  Trello,
  TrelloContainer
} from 'shared/StyledBoard/Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus])

interface Options {
  key: string
  value: string
  text: string
}

interface Props {
  filterValue: string
  showCalendarIcon?: boolean
  showTaskSearchModal?: boolean
  showDefaultView: (data: boolean) => void
  addList: () => void
  groups: Options[]
  getGroupsUsers: (id: string, office: string) => void
  user: UserType
}

interface State {
  show: boolean
  searchText: string
  showSearchModal: boolean
  sortOrder: number
  value: string
}

class StyledHeader extends React.Component<Props, State> {
  public state = {
    searchText: '',
    show: true,
    showSearchModal: false,
    sortOrder: 1,
    value: ''
  }

  public render() {
    const { showTaskSearchModal, showCalendarIcon, groups, filterValue, user } = this.props
    const { searchText, show } = this.state
    return (
      <div>
        <Container>
          {user.role === 'ADMIN' && (
            <Filter>
              <FilterIcon />{' '}
              <StyledDropdown
                inline={true}
                placeholder="Select Role"
                options={groups}
                value={filterValue}
                onChange={this.onHandleChange}
              />
            </Filter>
          )}
          <SearchInput style={{ width: !show ? '40%' : '50%' }}>
            <CustomInput placeholder={Strings.kanbanView.search} style={{ display: 'none' }}>
              {showTaskSearchModal ? (
                <input
                  value={searchText}
                  onChange={this.onChangeHandle}
                  onFocus={this.showSearchModal}
                  onBlur={this.closeModal}
                />
              ) : (
                <input value={searchText} onChange={this.onChangeHandle} />
              )}
              <SearchIcon />
              <CloseIcon onClick={this.clearState} />
            </CustomInput>
          </SearchInput>
          <CustomButton>
            {showCalendarIcon && <Calendar />}
            <AlignContainer>
              <Align onClick={this.showListView} />
            </AlignContainer>
            <TrelloContainer>
              <Trello onClick={this.showKanbanView} />
            </TrelloContainer>
            {!show && (
              <AddButtonContainer onClick={this.props.addList} style={{ marginLeft: 20 }}>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </AddButtonContainer>
            )}
          </CustomButton>
        </Container>
      </div>
    )
  }

  private showListView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: false
      },
      () => showDefaultView(this.state.show)
    )
  }

  private showKanbanView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: true
      },
      () => showDefaultView(this.state.show)
    )
  }

  private showSearchModal = () => {
    this.setState({ showSearchModal: true })
  }

  private closeModal = () => {
    this.setState({ showSearchModal: false })
  }

  private onHandleChange = (e: any, { value }: any) => {
    const result = value.split(', ')
    const { getGroupsUsers } = this.props
    getGroupsUsers(result[0], value)
  }

  private onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value })
  }

  private clearState = () => {
    this.setState({ searchText: '' })
  }
}

export default StyledHeader
