import gql from 'graphql-tag'

export const GET_TASK = gql`
  query getTasks(
    $office: String
    $leadId: String
    $transactionId: String
    $searchData: TaskSearch
    $createdBy: String
    $userPassFilter: UserPassTaskFilter
  ) {
    getTasks(
      where: {
        lead: $leadId
        isActive: true
        transaction: $transactionId
        createdBy: $createdBy
        office: $office
      }
      filter: $searchData
      userPassFilter: $userPassFilter
    ) {
      _id
      type
      name
      description
      createdAt
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      notes {
        _id
        description
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        createdAt
      }
      activities {
        _id
        type
        task {
          name
        }
        description
        updatedAt
      }
      sharedWith {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      lead {
        _id
        firstName
        lastName
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
      transaction {
        _id
        propertyId {
          _id
          type {
            _id
            name
          }
          subType {
            _id
            name
          }
          address {
            _id
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          images {
            _id
            name
            url
            order
          }
          price
          mlsId
        }
        transactionId
        transactionRole
        status
        tags
        progress
        createdAt
        updatedAt
        owner {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
      dueDate
      taskTime
      completionDate
      label {
        _id
        name
        icon
        colour
      }
      isActive
      status
      checked @client
    }
  }
`

export const GET_TASK_ACTIVITY = gql`
  query getLeadTaskActivities(
    $leadId: String
    $contactId: String
    $transactionId: String
    $taskId: String
  ) {
    getActivities(
      where: {
        leadId: $leadId
        contact: $contactId
        transaction: $transactionId
        task: $taskId
      }
    ) {
      _id
      type
      task {
        name
      }
      owner {
        firstName
        lastName
        profileImage
      }
      description
      updatedAt
    }
  }
`

export const GET_TASK_DETAILS = gql`
  query getTaskDetails($taskId: String) {
    getTasks(where: { _id: $taskId }) {
      _id
      type
      name
      description
      createdAt
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      notes {
        _id
        description
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        createdAt
      }
      activities {
        _id
        type
        task {
          name
        }
        description
        owner {
          firstName
          lastName
          profileImage
        }
        updatedAt
      }
      sharedWith {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      lead {
        _id
        firstName
        lastName
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
      transaction {
        _id
        propertyId {
          _id
          type {
            _id
            name
          }
          subType {
            _id
            name
          }
          address {
            _id
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          images {
            _id
            name
            url
            order
          }
          price
          mlsId
        }
        transactionId
        transactionRole
        status
        tags
        progress
        createdAt
        updatedAt
        owner {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
      dueDate
      taskTime
      completionDate
      label {
        _id
        name
        icon
        colour
      }
      isActive
      status
    }
  }
`

export const GET_TASK_NOTE = gql`
  query getTaskNotes($taskId: String) {
    getNotes(where: { task: $taskId }) {
      _id
      description
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdAt
    }
  }
`

export const EXPORT_TASKS = gql`
  query exportTasks(
    $taskBoard: String
    $teamTaskBoard: String
    $officeTaskBoard: String
    $tenantTaskBoard: String
  ) {
    exportTasks(
      where: {
        taskBoard: $taskBoard
        teamTaskBoard: $teamTaskBoard
        officeTaskBoard: $officeTaskBoard
        tenantTaskBoard: $tenantTaskBoard
      }
    )
  }
`
