import * as React from 'react'
import Clipboard from 'react-copy-to-clipboard'

import { clipboard } from 'design/icons/dashboard'
import { Strings } from 'utils'

import { Container, Copy, Image, Row, StyledPopup, Text, Title } from './Styled'

import { TransactionType } from 'app/Transactions/Details/Types'

interface Props {
  transaction: TransactionType
}

interface State {
  isCopied: boolean
}

class Email extends React.Component<Props, State> {
  public state = {
    isCopied: false
  }

  public render = () => {
    const {
      transaction: {
        transactionEmail,
        owner: { userName }
      }
    } = this.props

    return (
      <Container>
        <Title>{Strings.generalText.upload}</Title>
        <Row>
          <Image src={Strings.generalText.uploadDocsVaiEmail} />
          <Text>{transactionEmail || userName}</Text>
        </Row>
        <StyledPopup
          trigger={
            <Copy>
              <Image src={clipboard} />
              <Clipboard text={transactionEmail || userName}>
                <Text>{Strings.generalText.copy}</Text>
              </Clipboard>
            </Copy>
          }
          content="Email copied!"
          size="mini"
          inverted={true}
          on="click"
          open={this.state.isCopied}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
        />
      </Container>
    )
  }

  private handleOpen = () => {
    this.setState({ isCopied: true })

    setTimeout(() => {
      this.setState({ isCopied: false })
    }, 2000)
  }

  private handleClose = () => {
    this.setState({ isCopied: false })
  }
}

export default Email
