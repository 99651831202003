import { Form, Input } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { pinStripes, text, x } from 'design/Styled'
import { Strings } from 'utils'

interface Props {
  image?: string
  isLocked?: boolean
  isViewed?: boolean
}

const space = (n: number = 1) => {
  return `${10 * n}px`
}

const getThumbnail = ({ image, isLocked }: Props) => {
  if (isLocked) {
    return css`
      background-image: url(${Strings.transactionDocumentTab.documentOverview.icons.lockPdf});
    `
  }

  if (!image) {
    return css`
      ${pinStripes};

      &:before {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0.8em;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.3;
        content: 'No Preview Available';
        font: ${fonts.xSmall.medium};
        color: ${Colors.Text};
      }
    `
  }

  return css`
    background-image: url(${image});
  `
}

const getLock = ({ isLocked }: Props) => {
  return isLocked ? 'block' : 'none'
}

const getView = ({ isViewed }: Props) => {
  return !isViewed ? 'block' : 'none'
}

const title = css`
  padding: 0 ${space()};
  font-size: 12px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Container = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  float: left;
  padding: 0;
  cursor: pointer;

  margin: ${space()} ${space(1.5)};
  width: 96%;
  width: calc(100% - ${space(3)});
  max-width: 160px;

  @media (min-width: 600px) {
    width: 45%;
    width: calc(50% - ${space(3)});
  }

  @media (min-width: 900px) {
    margin: ${space()} ${space(1.5)};
    width: 30.333%;
    width: calc(33.333% - ${space(3)});
  }

  @media (min-width: 1200px) {
    margin: ${space()} ${space(1.5)};
    width: 14%;
    width: calc(16.666% - ${space(3)});
  }

  @media (min-width: 1600px) {
    margin: ${space()} ${space(1.5)};
    width: 10%;
    width: calc(12.5% - ${space(3)});
  }

  &:before {
    display: ${getView};
    content: 'NEW';
    position: absolute;
    top: 2.25em;
    left: -0.75em;
    font: ${fonts.small.bold};
    background: ${Colors.ButtonDanger};
    color: ${Colors.TextLight};
    padding: 0.3em 0.6em;
    border-radius: 0.9em;
    z-index: 99;
  }

  &:after {
    display: ${getLock};
    position: absolute;
    bottom: 0.3em;
    right: 0.3em;
    font-family: 'Font Awesome 5 Free';
    content: '\f023';
    font-size: 1.5em;
    font-weight: 900;
    color: ${Colors.TextDark};
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  &:hover .sidebar {
    opacity: 0.8;
    transform: translate3d(0, 0, 0);
    &:hover {
      opacity: 1;
    }
  }

  .ui.input > input {
    width: 100px;
    font-size: 10px;
    margin-bottom: 4px;
    width: 100%;
  }
`

export const Title = styled.figcaption`
  ${x};
  ${text};
  width: 100%;
  justify-content: center;
  margin-bottom: ${space()};
  color: ${Colors.Text};
  z-index: 1;

  span {
    ${title};
  }
`

export const Thumbnail = styled.div`
  display: block;
  position: relative;
  width: 100%;
  background-color: ${Colors.Container};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
  box-shadow: 0 0 13px 5px ${Colors.CardShadow};
  overflow: hidden;

  &:after {
    display: block;
    content: '';
    padding-bottom: 126%;
  }

  ${getThumbnail};
`

export const Sidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  height: 100%;
  width: 30px;
  background: ${Colors.EmphasisContainer};
  transition: all 200ms;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  box-shadow: 0 0 16px 5px ${Colors.transparent};
  overflow: hidden;
`

export const StyledForm = styled(Form)`
  &.ui.form {
    margin-bottom: -3px;
  }
`

export const StyledInput = styled(Input)`
  &.ui.input {
    ${title};
    padding: 0;
    border: none;
    box-shadow: 0 2px 4px -4px ${Colors.ContainerShadow};

    &.ui.input.rp-inline-input > input {
      border: none;
      border-radius: 0;
      padding: 0;
    }
  }
`
