// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Divider, Grid, Image } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import { upgradeMockData } from './UpgradeMockData'

import * as Actions from '../../../../store/Dashboard/Actions'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Styled Components
import {
  ButtomContainer,
  Container,
  Plans,
  StyledGrid,
  Title,
} from './Styled'

// Font Awesome Icons
import {
  faCheckCircle
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCheckCircle])

interface StoreProps {
  setShowPricingTabInSettings: (data: boolean) => void
}

interface OwnProps {
  closeModal: () => void
}

type Props = StoreProps & OwnProps

interface State {
  animate: boolean
}

class UpgradeModal extends React.Component<Props, State> {
  public state = {
    animate: true
  }

  public render() {
    const { animate } = this.state
    return (
      <Modal
        content={
          <Container>
            <Divider />
            <Title>
              {Strings.generalText.think} <span>{Strings.generalText.upgradeText}</span>
            </Title>
            <StyledGrid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Image src={Strings.generalText.upgrade} />
                </Grid.Column>
                <Grid.Column>
                  {
                    upgradeMockData.map((items: any, index: number) => (
                      <Plans key={index}>
                        <FontAwesomeIcon icon={['fal', 'check-circle']} />
                        <span>{items.plan}</span>
                      </Plans>
                    ))
                  }
                </Grid.Column>
              </Grid.Row>
            </StyledGrid>
            <Link to={Routes.settings.root} onClick={this.closeModal} >
              <ButtomContainer>
                <Button content={'VIEW PLANS'} />
              </ButtomContainer>
            </Link>
          </Container>
        } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={630}
      />
    )
  }

  private closeModal = () => {
    const { closeModal, setShowPricingTabInSettings } = this.props
    setShowPricingTabInSettings(true)
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default connect(
  null,
  {
    setShowPricingTabInSettings: Actions.showPricingTabInSettings,
  }
)(UpgradeModal)
