// Import Packages
import { cloneDeep, sortBy } from 'lodash'

// Import Colors
import Colors from 'design/Colors'

const boardBorderColors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Grey800}`,
  `${Colors.Green560}`
]

const defaultContactBoard = [
  'Buyer',
  'Seller',
  'Both',
  'Renter',
  'Closed',
  'Other'
]

export const formatKanbanData = async (
  contactBoardData: any,
  filter: boolean
) => {
  let user: any = localStorage.getItem('user')
    ? localStorage.getItem('user')
    : '{}'
  user = JSON.parse(user)
  const loggedInUser = user
  const contactBoard = cloneDeep(contactBoardData)
  if (contactBoard) {
    for (const contact of contactBoard) {
      contact.color = getRandomColors()
      if (!contact.contacts) {
        contact.items = Object.assign([])
      } else {
        contact.items = Object.assign(contact.contacts)
      }
      // contact.showAddButton = true
      if (
        loggedInUser.role === 'ADMIN' ||
        loggedInUser.role === 'MANAGER' ||
        filter === false
      ) {
        if (loggedInUser.role !== 'GUEST') {
          contact.showAddButton = true
        }
      } else {
        contact.showAddButton = false
      }
      if (defaultContactBoard.includes(contact.name)) {
        contact.showAppearance = false
      } else {
        contact.showAppearance = true
      }
    }
  }
  return sortBy(contactBoard, ['boardOrder'])
}

export const formatListData = async (contactsData: any, filter: boolean) => {
  let user: any = localStorage.getItem('user')
    ? localStorage.getItem('user')
    : '{}'
  user = JSON.parse(user)
  const loggedInUser = user

  const contactData: any = []
  const contactListData = Object.assign(contactsData)
  // tslint:disable-next-line:forin
  for (const index in contactListData) {
    if (filter === true) {
      if (
        loggedInUser.role === 'MANAGER' ||
        loggedInUser.role === 'ADMIN' ||
        loggedInUser.role === 'AGENT'
      ) {
        contactListData[index].showAddButton = true
      } else {
        contactListData[index].showAddButton = false
      }
    } else {
      contactListData[index].showAddButton = true
    }
    if (contactListData[index].owner._id === loggedInUser._id) {
      contactListData[index].showEditButton = true
    } else {
      contactListData[index].showEditButton = false
    }
    contactListData[index].checked = false
    contactListData[index].color = getRandomColors()
    contactData.push(contactListData[index])
  }
  return contactData
}

const getRandomColors = () => {
  return boardBorderColors[Math.floor(Math.random() * boardBorderColors.length)]
}
