import * as React from 'react'
import * as ReactGA from 'react-ga'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import LoadingIndicator from 'shared/LoadingIndicator'
import Toast, { formToast, serverToast } from 'shared/Toast/Toast'
import ConciergeModal from 'shared/Transaction/ConciergeModal'

import { Routes, Strings } from 'utils'

import { getRecaptchaSiteKey } from 'shared/Transaction/ConciergeModal/Queries'
import { handlePasswordLogin } from './Mutations'
import { handleValidation } from './Validation'

import logo from 'design/images/logo-large.png'
import { conciergeIcon, facebookIcon, googleIcon } from 'design/images/onboarding'

import {
  Break,
  Button,
  Col,
  ConciergeIcon,
  ConciergeSubtitle,
  ConciergeTitle,
  Confirm,
  Container,
  Content,
  Forgot,
  Form,
  Icon,
  Input,
  Logo,
  Register,
  Row,
  Social,
  Terms
} from './Styled'

import { SubjectEnum } from 'app/Onboarding/Types'
import { LoginFormErrorType, LoginFormType } from './Types'

interface Props extends RouteComponentProps<{}> {
  password: string
  userName: string
}

interface State {
  form: LoginFormType
  loading: boolean
  modal: any
  recaptchaSiteKey: string
}

class Login extends React.Component<Props, State> {
  public state = {
    form: {
      errors: {} as LoginFormErrorType,
      password: '',
      userName: ''
    },
    loading: false,
    modal: null,
    recaptchaSiteKey: ''
  }

  public componentDidMount = async () => {
    const recaptchaSiteKey = await getRecaptchaSiteKey()
    this.setState({ recaptchaSiteKey })
  }

  public render() {
    const { form, loading, modal } = this.state
    return (
      <Container>
        {loading && <LoadingIndicator height={3} message="logging in..." />}
        <Content>
          <Logo src={logo} />
          <Form onSubmit={this.handleSubmit}>
            <Input
              placeholder={Strings.onboarding.inputUsername}
              type="email"
              name="userName"
              value={form.userName}
              onChange={this.handleChange}
              error={!!form.errors.userName}
            />
            <Input
              placeholder={Strings.onboarding.inputPassword}
              name="password"
              type="password"
              value={form.password}
              onChange={this.handleChange}
              error={!!form.errors.password}
            />
            <Row justify="space-between">
              <Col>
                <Register onClick={this.moveToRegister}>{Strings.onboarding.notMember}</Register>
                <Forgot onClick={this.moveToReset}>{Strings.onboarding.forgot}</Forgot>
                <Confirm onClick={this.moveToConfirm}>{Strings.onboarding.confirm}</Confirm>
              </Col>
              <Button>{Strings.onboarding.login}</Button>
            </Row>
          </Form>
          <Break justify="center">
            <span>{Strings.onboarding.loginAlternative}</span>
          </Break>
          <Row>
            <Social justify="center" href={`${process.env.REACT_APP_SERVER_URL}/auth/facebook`}>
              <Icon src={facebookIcon} />
              <span>{Strings.onboarding.loginFacebook}</span>
            </Social>
            <Social justify="center" href={`${process.env.REACT_APP_SERVER_URL}/auth/google`}>
              <Icon src={googleIcon} />
              <span>{Strings.onboarding.loginGoogle}</span>
            </Social>
          </Row>
          <Break />
          <Row justify="center">
            <ConciergeIcon src={conciergeIcon} />
            <Col>
              <ConciergeTitle>{Strings.onboarding.concierge.title}</ConciergeTitle>
              <ConciergeSubtitle>{Strings.onboarding.concierge.subtitle}</ConciergeSubtitle>
            </Col>
            <Button onClick={this.toggleConciergeModal}>{Strings.onboarding.concierge.link}</Button>
          </Row>
          <Break />
          <Terms justify="center">
            <span>&copy; {Strings.onboarding.company}</span>
            <span style={{ cursor: 'pointer' }}>
              <b onClick={() => this.showTerms('terms')}>{Strings.onboarding.terms}</b>
              <b onClick={() => this.showTerms('policy')}>{Strings.onboarding.policy}</b>
            </span>
          </Terms>
        </Content>
        {modal}
      </Container>
    )
  }

  private showTerms = (data: string) => {
    if (data === 'terms') {
      window.open(Strings.onboarding.termsPdf)
    } else {
      window.open(Strings.onboarding.PolicyPdf)
    }
  }

  private moveToDashboard = () => {
    this.props.history.push({
      pathname: Routes.primary.dashboard.path
    })
  }

  private moveToRegister = () => {
    this.props.history.push({
      pathname: Routes.onboarding.root + Routes.onboarding.register.path
    })
  }

  private moveToReset = () => {
    const {
      form: { userName }
    } = this.state
    this.props.history.push({
      pathname: Routes.onboarding.root + Routes.onboarding.resetPassword.path,
      state: { email: userName }
    })
  }

  private moveToConfirm = () => {
    const {
      form: { userName }
    } = this.state
    this.props.history.push({
      pathname: Routes.onboarding.root + Routes.onboarding.confirm.path,
      state: {
        email: userName,
        init: true,
        subject: SubjectEnum.Activate
      }
    })
  }

  private handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    this.setState({ loading: true })

    const {
      form,
      form: { password, userName }
    } = this.state
    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      formToast(validation.errors)
      this.setState({
        form,
        loading: false
      })
      return
    }

    try {
      const success = await handlePasswordLogin({ password, userName })
      if (success) {
        return this.moveToDashboard()
      }
      Toast({
        message: 'Unable to login. An error occurred.',
        type: 'error'
      })
    } catch (error) {
      serverToast(error)
    }

    this.setState({ loading: false })
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { form } = this.state
    form[e.target.name] = e.target.value
    form.errors = {}
    this.setState({ form })
  }

  private toggleConciergeModal = () => {
    const { recaptchaSiteKey } = this.state

    ReactGA.event({
      action: 'Concierge Settings',
      category: 'Transactions'
    })

    const modal = <ConciergeModal recaptchaSiteKey={recaptchaSiteKey} onClose={this.closeModal} />
    this.setState({ modal })
  }

  private closeModal = () => {
    this.setState({ modal: null })
  }
}

export default withRouter(Login)
