import { Icon, Input } from 'semantic-ui-react'

import Colors from 'design/Colors'
import styled from 'styled-components'

const CustomInput = styled(Input)`
  width: 100%;

  & .check.circle {
    color: ${Colors.Blue50};
  }
  & .warning.circle {
    color: ${Colors.Red50};
  }

  &.hidden-icon .icon {
    opacity: 0 !important;
  }
`

const CustomPopupIcon = styled(Icon)`
  &.check.circle {
    color: ${Colors.Blue50};
  }
  &.warning.circle {
    color: ${Colors.Red50};
  }
`

export { CustomInput, CustomPopupIcon }
