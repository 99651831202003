import { Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { SecondaryFontName } from 'design/Fonts'
import { text, x, y } from 'design/Styled'

interface Props {
  image?: string
  max?: number
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-size: ${(props: Props) => props.max || 400}px;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 600px) {
    background-image: url(${(props: Props) => props.image || ''});
  }

  .rp-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5em;
    & > div {
      font-size: 40px;
      margin: 0 0.25em;
    }
    input {
      border: 1px solid ${Colors.EmphasisLine};
      border-radius: 4px;
      &:focus {
        outline: none;
      }
    }
  }
`

export const Image = styled.img`
  display: block;
  margin: 0 auto 0.8em;
  width: 100%;
  max-width: ${(props: Props) => props.max || 400}px;
`

export const Content = styled.div`
  ${y};
  ${text};
  max-width: 400px;
  z-index: 2;
`

export const Title = styled.h1`
  color: ${Colors.TextPrimary};
  font-size: 36px;
  font-weight: 600;
  margin: 0 0 0.5em 0;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  font-family: ${SecondaryFontName};
`

export const Highlight = styled.h2`
  color: ${Colors.Text};
  font-size: 24px;
  font-family: ${SecondaryFontName};
  font-weight: 500;
  text-align: center;
  line-height: 1;
  margin: -0.25em 0 0.5em 0;
`

export const Resend = styled.a`
  padding-left: 5px;
  cursor: pointer;
`

export const Col = styled.section`
  ${y};
`

export const Text = styled.span`
  ${x};
  ${text};
  line-height: 1.2;
  justify-content: center;
  text-align: center;
  margin: 0 0 0.5em 0;
`

export const StyledForm = styled(Form)`
  width: 100%;
`

export const StyledInput = styled(Input)``
