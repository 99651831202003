import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, x, y } from 'design/Styled'

export const Container = styled.section`
  ${y};
  & > div {
    margin: 0.5em 0;
  }
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Owner = styled.strong`
  font: ${fonts.medium.bold};
  color: ${Colors.Text};
  text-transform: capitalize;
`

export const Image = styled.img`
  width: 32px;
`

export const Label = styled.strong`
  font: ${fonts.xSmall.bold};
  color: ${Colors.Text};
`

export const Value = styled.span`
  display: flex;
  align-items: center;
  font: ${fonts.xSmall.regular};
  color: ${Colors.TextMedium};
`

export const Icon = styled(FontAwesomeIcon)`
  margin-left: 0.8em;
  font-size: 14px;
  cursor: pointer;
`

export const StyledInput = styled(Input)`
  &.ui.input {
    flex: 1;
    width: 0;
    font-size: ${fonts.xxSmall.size};

    & > input,
    & > input:focus,
    & > input:active {
      border: none;
      border-radius: 0;
      padding: 0.3em 0.5em;
      background: ${Colors.EmphasisContainer};
    }
  }
`
