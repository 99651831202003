import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  .ui.dropdown > .text {
    white-space: nowrap;
  }
`

export const StyledDropdown = styled(Dropdown)``
