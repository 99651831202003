import * as React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

import AddTeamModal from 'app/Teams/AddTeamModal/AddTeamModal'
import LoadingIndicator from 'shared/LoadingIndicator'
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Modal from 'shared/Modal'
import StyledGrid from 'shared/StyledBoard/GridView'
import KanbanView from 'shared/StyledBoard/KanbanView'
import Toast from 'shared/Toast'

import AddUserModal from './AddUserModal'
import {
  CustomActions,
  CustomCap,
  CustomName,
  CustomOffice,
  CustomProfileImage,
  CustomRole,
  CustomStatus
} from './CustomComponents'
import Icons from './Icons'
import { ImportUsersPopup } from './Popups'
import StyledHeader from './StyledHeader'
import UserCard from './UserCard'

import { GET_USERS } from 'queries/graphql/Users/Queries'
import {
  addUser,
  createTeam,
  deleteTeam,
  deleteUser,
  exportUsers,
  moveUsers,
  reorderCard,
  reorderLane,
  restoreUser,
  toggleCardStatus
} from './UserMutation'
import {
  getBoard,
  getFilterGroups,
  getGroupUsers,
  getOfficeManager,
  getOptions,
  getUserDetail,
  getUsers,
  getUsersGroup,
  reset,
  sortUsers
} from './UserQueries'
import UsersListQuery from './UsersListQuery'
import { formatKanbanData, formatListData } from './Utils/FormattingData'

import { cacheData } from 'queries/apollo'
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Users/Actions'
import ContactAction from './Actions'

import { getLoggedInUser, Strings } from 'utils'

import { Container, Error, ErrorContainer, ErrorText } from './Styled'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { Filter, FiltersData, Options, UserGroup, UsersItem, UserType } from 'store/Users/Types'

interface StoreProps {
  setNewBoard: (index: number, newData: UserGroup) => void
  setNewCard: (id: string, index: string, newData: UsersItem) => void
  setBoardData: (data: UserGroup[]) => void
  setHideAddUserPopup: () => void
  setResetCardData: () => void
  setRestoreCard: () => void
  setNewIcon: (id: string, icon: string) => void
  setNewDescription: (id: string, description: string) => void
  setNewBoardName: (id: string, boardName: string) => void
  setSearchDataFilter: (data: FiltersData) => void
  data: UserGroup[]
  showAddUserPopup: boolean
  searchLoader: boolean
  filter: Filter
  searchData: FiltersData
}

type Props = StoreProps

interface State {
  newBoard?: UserGroup
  newCard?: UsersItem
  boardName?: string
  showAddUserModal: boolean
  show: boolean
  appearance: boolean
  addUserIndex: string
  addBoardId: string
  edit: boolean
  editDetails: UsersItem
  filterState: string
  groups: Options[]
  userFilter: boolean
  userSort: boolean
  showTeamModal: boolean
  moveCardId: string
  moveUser: UsersItem
  officeBoardId: string
  teamLeader: string
  destinationIndex: number
  destinationBoard: string
  showImportUsersPopup: boolean
  importUserBoardId: string
  user: UserType
  showComposeMail: string
  officeID: string
  loader: boolean
}

const userGridViewStrings = Strings.users.gridView

export let ShowAddUserModal: any
export let ToggleStatus: any
export let deleteCard: any
export let editUser: any
export let sortgroupUsers: any
export let importUser: any
export let importUserSuccessfully: any
export let exportUser: any
export let ShowComposeMail: any
export let loggedUser: any

class Users extends React.Component<Props, State> {
  public state = {
    addBoardId: '',
    addUserIndex: '',
    appearance: true,
    destinationBoard: '',
    destinationIndex: 0,
    edit: false,
    editDetails: {} as UsersItem,
    filterState: 'Choose',
    groups: [],
    importUserBoardId: '',
    loader: true,
    moveCardId: '',
    moveUser: {} as UsersItem,
    officeBoardId: '',
    officeID: '',
    show: true,
    showAddUserModal: false,
    showComposeMail: '',
    showImportUsersPopup: false,
    showTeamModal: false,
    teamLeader: '',
    user: {} as UserType,
    userFilter: false,
    userSort: false
  }

  public columnMetaData = [
    {
      customComponent: CustomProfileImage,
      enhanceWithRowData: true,
      id: userGridViewStrings.user.id,
      paddingLeft: 0,
      sortable: false,
      title: userGridViewStrings.user.title,
      width: '7%'
    },
    {
      customComponent: CustomName,
      enhanceWithRowData: true,
      id: userGridViewStrings.name.id,
      title: userGridViewStrings.name.title,
      width: '35%'
    },
    {
      customComponent: CustomRole,
      enhanceWithRowData: true,
      id: userGridViewStrings.role.id,
      title: userGridViewStrings.role.title
    },
    {
      customComponent: CustomOffice,
      enhanceWithRowData: true,
      id: userGridViewStrings.office.id,
      title: userGridViewStrings.office.title
    },
    {
      customComponent: CustomCap,
      enhanceWithRowData: true,
      id: userGridViewStrings.cap.id,
      title: userGridViewStrings.cap.title
    },
    {
      customComponent: CustomStatus,
      enhanceWithRowData: true,
      id: userGridViewStrings.status.id,
      title: userGridViewStrings.status.title
    },
    {
      customComponent: CustomActions,
      enhanceWithRowData: true,
      id: userGridViewStrings.action.id,
      sortable: false,
      title: userGridViewStrings.action.title
    }
  ]

  public async componentDidMount() {
    const { setSearchDataFilter } = this.props
    const options = await getOptions()
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = user
    setSearchDataFilter({} as FiltersData)
    this.setState({ groups: options, user: user })
    this.refreshGetUsers()
    this.assignShowAddUserModal()
    this.assignToggleStatus()
    this.deleteuser()
    this.edit()
    this.sortGroupUser()
    this.Import()
    this.ImportedSuccessfully()
    this.Export()
    this.assignShowComposeMail()
  }

  public render() {
    const { searchLoader, data: kanbanData } = this.props
    const {
      user,
      officeID,
      loader,
      showAddUserModal,
      showComposeMail,
      appearance,
      addBoardId,
      editDetails,
      edit,
      groups,
      showTeamModal,
      officeBoardId,
      teamLeader,
      showImportUsersPopup,
      importUserBoardId,
      filterState
    } = this.state
    return (
      <React.Fragment>
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={showComposeMail} />}
        {showAddUserModal && (
          <AddUserModal
            closeModal={this.closeModal}
            addNewUser={this.addUser}
            officeID={officeID}
            userBoard={addBoardId}
            editDetails={editDetails}
            edit={edit}
          />
        )}
        {showImportUsersPopup && (
          <ImportUsersPopup
            officeID={officeID}
            handleClose={this.setHideImportUsersPopup}
            boardId={importUserBoardId}
          />
        )}
        {showTeamModal && (
          <AddTeamModal
            closeModal={this.closeModal}
            addNewTeam={this.addNewTeam}
            boardId={officeBoardId}
            teamLeader={teamLeader}
          />
        )}
        <Container>
          {(loader || searchLoader) && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
          {Object.keys(groups).length > 0 && (
            <StyledHeader
              addList={this.addList}
              filterValue={filterState}
              getGroupsUsers={this.getGroupsUsers}
              groups={groups}
              showDefaultView={this.showDefaultView}
              user={user}
            />
          )}
          {this.state.show &&
            Object.keys(user).length > 0 && (
              <KanbanView
                ActionComponent={ContactAction}
                addNewCard={this.addNewCard}
                alterData={this.alterData}
                appearance={appearance}
                CardComponent={UserCard}
                data={kanbanData}
                deleteCard={this.deleteCard}
                deleteTransfer={false}
                icons={Icons}
                moveCard={this.moveUsers}
                reorderCard={this.reorderUsers}
                reorderColumn={this.reorderColumn}
                resetCardData={this.resetCardData}
                restoreCard={this.restoreCard}
                updateBoard={this.updateBoard}
              />
            )}
          {this.state.show === false &&
            Object.keys(user).length > 0 && (
              <UsersListQuery query={GET_USERS} variables={{ isActive: true }}>
                {({ loading, error, data }) => {
                  if (loading) {
                    return <LoadingIndicator type={IndicatorTypeEnum.Spinner} />
                  }
                  if (error) {
                    return (
                      <Modal
                        content={
                          <ErrorContainer>
                            <Error>{Strings.office.error}</Error>
                            <ErrorText>{Strings.office.somethingWentWrong}</ErrorText>
                          </ErrorContainer>
                        }
                        closeModal={this.closeModal}
                        width={400}
                      />
                    )
                  }
                  if (data && data.getUsers) {
                    const userData: any = formatListData(data.getUsers, user)
                    return (
                      <StyledGrid
                        data={userData}
                        tableHeight={50}
                        columnMetaData={this.columnMetaData}
                        addList={this.addList}
                      />
                    )
                  }
                  return null
                }}
              </UsersListQuery>
            )}
        </Container>
      </React.Fragment>
    )
  }

  private assignShowComposeMail = () => {
    ShowComposeMail = (email: string) => {
      this.setState({ showComposeMail: email })
    }
  }

  private showDefaultView = async (data: boolean) => {
    const { filter } = this.props
    this.setState({ show: data }, () => {
      if (filter.filter === false && filter.searchText === false) {
        this.refreshGetUsers()
      }
    })
  }

  private setHideImportUsersPopup = () => {
    this.setState({ showImportUsersPopup: !this.state.showImportUsersPopup })
  }

  private Import = () => {
    importUser = (boardId: string) => {
      this.setState({ importUserBoardId: boardId, showImportUsersPopup: true })
    }
  }

  private ImportedSuccessfully = () => {
    importUserSuccessfully = async () => {
      this.refreshGetUsers()
    }
  }

  private Export = () => {
    exportUser = (boardId: string) => {
      exportUsers(boardId)
    }
  }

  private assignToggleStatus = () => {
    ToggleStatus = async (cardId: string, status: string) => {
      const { officeID } = this.state
      await toggleCardStatus(cardId, status, officeID)
      await this.refreshGetUsers()
    }
  }

  private assignShowAddUserModal = () => {
    ShowAddUserModal = (addBoardId: string) => {
      this.setState({ showAddUserModal: true, addBoardId })
    }
  }

  private closeModal = () => {
    this.setState({ showAddUserModal: false, showTeamModal: false, showComposeMail: '' })
  }

  private addList = () => {
    this.setState({ showAddUserModal: true, edit: false, addBoardId: '', editDetails: {} as UsersItem })
  }

  private resetCardData = async () => {
    const { officeID } = this.state
    const options = await reset(officeID)
    this.setState({ groups: options })
  }

  private restoreCard = async (boardId: string, cardId: string) => {
    const { officeID } = this.state
    const options = await restoreUser(cardId, officeID)
    reset(officeID)
    this.setState({ groups: options })
  }

  private deleteCard = async (boardId: string, cardId: string) => {
    const { user, officeID }: any = this.state
    if (user._id === cardId) {
      ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotDelete, 'error')
    } else {
      // popup to ask for manager if only 1 manager
      const sourceBoard = await getBoard(boardId, officeID)
      if (sourceBoard === 'MANAGER') {
        const userData = await getUserDetail(cardId)
        const count = await getOfficeManager(userData.office[0]._id)
        if (count > 1) {
          try {
            const options = await deleteUser(cardId, officeID)
            this.setState({ groups: options })
            ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
          } catch (error) {
            Toast({ message: error.message, type: 'error' })
          }
        } else {
          ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.onlyManager, 'error')
        }
      } else if (sourceBoard === 'TEAM_LEADER') {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesMoveIt,
          showCancelButton: true,
          text: Strings.kanbanView.deleteTeam,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async result => {
          if (result.value) {
            const userData = await getUserDetail(cardId)
            try {
              await deleteTeam(userData.teams._id, officeID)
            } catch (error) {
              Toast({ message: error.message, type: 'error' })
            }
            const options = await deleteUser(cardId, officeID)
            this.setState({ groups: options })
            ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
            await this.refreshGetUsers()
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          } else {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          }
        })
      } else {
        try {
          const options = await deleteUser(cardId, officeID)
          this.setState({ groups: options })
          ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
          await this.refreshGetUsers()
        } catch (error) {
          Toast({ message: error.message, type: 'error' })
        }
      }
    }
  }

  private deleteuser = () => {
    const { officeID } = this.state
    deleteCard = async (cardId: string) => {
      const options = await deleteUser(cardId, officeID)
      this.setState({ groups: options })
    }
  }

  private addNewCard = (id: string, index: string) => {
    this.setState({
      showAddUserModal: true,
      addUserIndex: index,
      addBoardId: id,
      edit: false,
      editDetails: {} as UsersItem
    })
  }

  private addUser = async (newUserData: UsersItem) => {
    const { addUserIndex, addBoardId, show, edit, officeID } = this.state

    const response = await addUser(newUserData, addUserIndex, addBoardId, show, edit, officeID)
    await this.refreshGetUsers()

    return response
  }

  private reorderColumn = async (boardId: string, sourceIndex: number, destinationIndex: number) => {
    if (!this.state.userFilter) {
      await reorderLane(boardId, sourceIndex, destinationIndex)
      await this.refreshGetUsers()
    }
  }

  private alterData = (data: UserGroup[]) => {
    const { setBoardData } = this.props
    setBoardData(data)
  }

  private reorderUsers = async (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
    if (!this.state.userFilter) {
      await reorderCard(boardId, cardId, sourceIndex, destinationIndex)
      this.refreshGetUsers()
    }
  }

  private edit = () => {
    editUser = (userData: UsersItem) => {
      this.setState({ showAddUserModal: true, editDetails: userData, edit: true, addBoardId: '' })
    }
  }

  private sortGroupUser = () => {
    sortgroupUsers = async (boardId: string, sortOrder: number) => {
      const { officeID } = this.state
      this.setState({ userSort: true })
      const response = await sortUsers(boardId, sortOrder, officeID)
      const kanbanData = formatKanbanData(response.data.getGroups, this.state.user)
      this.props.setBoardData(kanbanData)
    }
  }

  private refreshGetUsers = async (id?: string, singleSkip?: number) => {
    const { searchData, setBoardData } = this.props
    const { show, user, filterState } = this.state
    this.setState({ loader: true })
    if (show) {
      let result
      if (filterState === 'All' || filterState === 'Choose') {
        this.setState({ userFilter: false, officeID: '' })
        result = await getFilterGroups(searchData, id, singleSkip)
      } else {
        this.setState({ userFilter: true, officeID: filterState })
        result = await getGroupUsers(filterState.split(', ')[0], searchData, id, singleSkip)
      }
      const { data }: any = result
      const kanbanData = formatKanbanData(data.getGroups, user)
      setBoardData(kanbanData)
      this.setState({ loader: false })
    } else {
      let result
      if (filterState === 'All' || filterState === 'Choose') {
        this.setState({ officeID: '' })
        result = await getUsers(searchData)
      } else {
        this.setState({ officeID: filterState })
        result = await getUsersGroup(filterState.split(', ')[0], searchData)
      }
      const { data }: any = result
      cacheData.writeQuery({
        data: { getUsers: data.getUsers },
        query: GET_USERS,
        variables: { isActive: true }
      })
      this.setState({ loader: false })
    }
  }

  private getGroupsUsers = async (id: string, group: string) => {
    this.setState(
      {
        filterState: group
      },
      () => this.refreshGetUsers()
    )
  }

  private moveUsers = async (
    cardId: string,
    destinationIndex: number,
    destinationBoardId: string,
    sourceBoardId: string
  ) => {
    const { user, officeID }: any = this.state
    if (user._id === cardId) {
      ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotMove, 'error')
    } else {
      const destinationBoard = await getBoard(destinationBoardId, officeID)
      const sourceBoard = await getBoard(sourceBoardId, officeID)
      if ((sourceBoard.name === 'GUEST' || sourceBoard.name === 'AGENT') && destinationBoard.name === 'TEAM_LEADER') {
        this.setState({ loader: true })
        const userData = await getUserDetail(cardId)
        if (userData) {
          this.setState({
            loader: false
          })
        }
        this.setState({
          showTeamModal: true,
          officeBoardId: userData.office[0]._id,
          teamLeader: userData.userName,
          moveCardId: cardId,
          moveUser: userData,
          destinationBoard: destinationBoardId,
          destinationIndex
        })
      } else if (sourceBoard.name === 'TEAM_LEADER' && destinationBoard.name === 'MANAGER') {
        // error - cannot move a teamleader to manager
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.teamLeaderToManager, 'error')
      } else if (sourceBoard.name === 'TEAM_LEADER' && destinationBoard.name === 'AGENT') {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesMoveIt,
          showCancelButton: true,
          text: Strings.kanbanView.movingTeam,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async result => {
          if (result.value) {
            const userData = await getUserDetail(cardId)
            await deleteTeam(userData.teams._id, officeID)
            const options = await moveUsers(cardId, destinationIndex, destinationBoardId, officeID)
            this.setState({ groups: options })
            this.refreshGetUsers()
            ConfirmAlert(Strings.kanbanView.movedSuccessfully, Strings.kanbanView.moveCard, 'success')
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          } else {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          }
        })
      } else if (sourceBoard.name === 'MANAGER' && destinationBoard.name === 'TEAM_LEADER') {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.managerToTeamLeader, 'error')
      } else if (sourceBoard.name === 'MANAGER' && destinationBoard.name === 'AGENT') {
        // popup to ask for manager if only 1 manager
        const userData = await getUserDetail(cardId)
        const count = await getOfficeManager(userData.office[0]._id)
        if (count > 1) {
          const options = await moveUsers(cardId, destinationIndex, destinationBoardId, officeID)
          this.refreshGetUsers()
          this.setState({ groups: options })
        } else {
          ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.onlyManager, 'error')
        }
      } else if (destinationBoard.name === 'GUEST') {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.notAllowed, 'error')
      } else if (destinationBoard.name === 'ADMIN') {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.notAllowedToAdmin, 'error')
      } else {
        const options = await moveUsers(cardId, destinationIndex, destinationBoardId, officeID)
        this.refreshGetUsers()
        this.setState({ groups: options })
      }
      await this.refreshGetUsers()
    }
  }

  private addNewTeam = (newTeamData: any) => {
    const { moveCardId, moveUser, destinationBoard, destinationIndex, officeID }: any = this.state
    createTeam(moveCardId, newTeamData.teamName, moveUser.office[0]._id).then(async () => {
      const options = await moveUsers(moveCardId, destinationIndex, destinationBoard, officeID)
      this.refreshGetUsers()
      this.setState({ groups: options })
    })
  }

  private updateBoard = async (id: string, singleSkip: number) => {
    this.refreshGetUsers(id, singleSkip)
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.users.data,
  filter: state.users.filter,
  searchData: state.users.searchData,
  searchLoader: state.users.searchLoader,
  showAddUserPopup: state.users.showAddUserPopup,
  showImportUsersPopup: state.users.showImportUsersPopup
})

export default connect(
  mapStateToProps,
  {
    setBoardData: Actions.getBoardData,
    setHideAddUserPopup: Actions.hideAddUserPopup,
    setHideImportUsersPopup: Actions.hideImportUsersPopup,
    setNewBoard: Actions.addNewBoard,
    setNewBoardName: Actions.setNewBoardName,
    setNewCard: Actions.addNewCard,
    setNewDescription: Actions.setNewDescription,
    setNewIcon: Actions.setNewIcon,
    setResetCardData: Actions.resetCardData,
    setRestoreCard: Actions.restoreCard,
    setSearchDataFilter: Actions.searchDataFilter
  }
)(Users)
