// Import Components
import client from 'queries/apollo'

import { FiltersData } from 'store/Users/Types'

// Import Graphql Queries
import { GET_GROUP, GET_GROUP_USERS, GET_GROUPS } from 'queries/graphql/Group/Queries'
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import { GET_USER_CAP_SETTING } from 'queries/graphql/Settings/TransactionFee/CapSettings/Queries'
import { GET_OFFICE_MANAGERS, GET_USER_DETAIL, GET_USERS, GET_USERS_ON_GROUP } from 'queries/graphql/Users/Queries'

export const getOfficesList = () => {
  return client.query({
    fetchPolicy: 'network-only',
    query: GET_OFFICES
  })
}

export const getGroups = (office?: string) => {
  return client.query({
    fetchPolicy: 'network-only',
    query: GET_GROUPS,
    variables: {
      office: office ? office : undefined,
      orderField: 'groupOrder',
      sortOrder: 1
    }
  })
}

export const getUsers = (filter: FiltersData) => {
  return client.query({
    fetchPolicy: 'network-only',
    query: GET_USERS,
    variables: {
      filter,
      isActive: true
    }
  })
}

export const getUsersGroup = (office: string, filter: FiltersData) => {
  return client.query({
    fetchPolicy: 'network-only',
    query: GET_USERS_ON_GROUP,
    variables: {
      filter,
      isActive: true,
      office
    }
  })
}

export const getFilterGroups = (filter?: FiltersData, id?: string, singleSkip?: number) => {
  return client.query({
    fetchPolicy: 'network-only',
    query: GET_GROUPS,
    variables: {
      filter,
      id,
      orderField: 'groupOrder',
      singleSkip,
      sortOrder: 1
    }
  })
}

export const getGroupUsers = (office: string, filter: FiltersData, id?: string, singleSkip?: number) => {
  return client.query({
    fetchPolicy: 'network-only',
    query: GET_GROUP_USERS,
    variables: {
      filter,
      id,
      office,
      orderField: 'groupOrder',
      singleSkip,
      sortOrder: 1
    }
  })
}

export const getCapSettings = () => {
  return client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_CAP_SETTING
  })
}

export const reset = async (officeID: string) => {
  await client.query({
    query: GET_GROUPS,
    variables: {
      office: officeID ? officeID.split(', ')[0] : undefined,
      orderField: 'groupOrder',
      sortOrder: 1
    }
  })
  await client.query({
    query: GET_USERS,
    variables: { isActive: true }
  })
  return getOptions()
}

export const getOptions = async () => {
  const groups: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_OFFICES
  })
  const options = groups.data.getOffices.reduce((accumulator: any, currentValue: any) => {
    accumulator.push({
      key: currentValue._id,
      text: currentValue.branchName,
      value: `${currentValue._id}, ${currentValue.branchName}`
    })
    return accumulator
  }, [])
  options.splice(0, 0, {
    key: '0',
    text: 'Select Office',
    value: 'Choose'
  })
  if (options.length > 0) {
    options.push({
      key: 'All',
      text: 'All',
      value: 'All'
    })
  }
  return options
}

export const getBoard = async (boardId: string, officeID: string) => {
  const groups: any = await client.query({
    query: GET_GROUP,
    variables: {
      _id: boardId,
      office: officeID ? officeID.split(', ')[0] : undefined,
      orderField: 'groupOrder',
      sortOrder: 1
    }
  })

  return groups.data.getGroups[0]
}

export const sortUsers = async (boardId: string, sortOrder: number, officeID: string) => {
  if (boardId !== '') {
    return await client.query({
      fetchPolicy: 'network-only',
      query: GET_GROUPS,
      variables: {
        id: boardId,
        office: officeID ? officeID.split(', ')[0] : undefined,
        orderField: 'groupOrder',
        sortOrder: 1,
        sortUserOrder: sortOrder,
        userOrderField: 'firstName'
      }
    })
    // const { data }: any = result
    // if (data && data.getGroups) {
    //   cacheData.writeQuery({
    //     data: { getGroups: data.getGroups },
    //     query: GET_GROUPS,
    //     variables: { sortOrder: 1, orderField: 'groupOrder' }
    //   })
    // }
  } else {
    return await client.query({
      fetchPolicy: 'network-only',
      query: GET_USERS,
      variables: {
        isActive: true,
        orderField: 'firstName',
        sortOrder: sortOrder
      }
    })
    // const { data }: any = result
    // if (data && data.getUsers) {
    //   cacheData.writeQuery({
    //     data: { getUsers: data.getUsers },
    //     query: GET_USERS,
    //     variables: { isActive: true }
    //   })
    // }
  }
}

export const getUserDetail = async (cardId: string) => {
  const users = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_DETAIL,
    variables: { userId: cardId }
  })

  return users.data.getUsers[0]
}

export const getOfficeManager = async (officeId: string) => {
  const users = await client.query({
    fetchPolicy: 'network-only',
    query: GET_OFFICE_MANAGERS,
    variables: { officeId: officeId }
  })
  return users.data.getUsers.length
}
