import * as React from 'react'

import { Container } from './Styled'
import { TransactionRoleEnum, TransactionStatusEnum } from 'store/Transactions/Types'

import Colors from 'design/Colors'

interface State {
  background: string
}

interface Props {
  label: TransactionRoleEnum | TransactionStatusEnum | string
}

class Badge extends React.Component<Props, State> {
  public state = {
    background: '',
  }

  public componentDidMount() {
    this.getStyle()
  }

  public componentDidUpdate(prev:Props) {
    const { label } = this.props
    if (prev.label !== label) {
      this.getStyle()
    }
  }

  public render() {
    const { label } = this.props
    const { background } = this.state

    return (
      <Container background={background}>
        {label}
      </Container>
    )
  }

  private getStyle = () => {
    const { label } = this.props

    let background = ''

    switch (label) {
      case TransactionRoleEnum.Buyer:
        background = Colors.TransactionBuyer
        break
      case TransactionRoleEnum.Seller:
        background = Colors.TransactionSeller
        break
      case TransactionRoleEnum.Both:
        background = Colors.TransactionBoth
        break
      case TransactionStatusEnum.New:
        background = Colors.StatusNew
        break
      case TransactionStatusEnum.Active:
        background = Colors.StatusActive
        break
      case TransactionStatusEnum.Received:
        background = Colors.StatusReceived
        break
      case TransactionStatusEnum.Payable:
        background = Colors.Magenta50
        break
      case TransactionStatusEnum.Closed:
        background = Colors.Red55
        break
      case TransactionStatusEnum.Failed:
        background = Colors.StatusFailed
        break
      default:
        break
    }

    this.setState({ background })
  }
}

export default Badge