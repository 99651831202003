import * as React from 'react'
import { Component } from 'react'

import { getLoggedInUser } from 'utils'

import { Address, Container, Icon, Price } from './Styled'

import { TransactionType, UserType } from 'app/Transactions/Details/Types'

import { faPencilAlt } from '@fortawesome/pro-light-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPencilAlt])

interface Props {
  showTransactionModal: () => void
  transaction: TransactionType
}

interface State {
  user: UserType
}

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default class Details extends Component<Props, State> {
  public state = {
    user: {} as UserType
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    if (!user) {
      return
    }
    this.setState({ user })
  }

  public render() {
    const {
      showTransactionModal,
      transaction: {
        owner,
        propertyId: { address, price }
      }
    } = this.props

    const { user } = this.state

    return (
      <Container>
        <Address primary={true}>
          {`${address.streetNumber} ${address.streetName}`}
          <br />
          {`${address.city}, ${address.state} ${address.zipCode}`}
        </Address>
        <Price>
          ${numberWithCommas(price)}
          {(user.role === 'ADMIN' || user.role === 'MANAGER' || owner._id === user._id) && (
            <span onClick={showTransactionModal}>
              <Icon icon={['fal', 'pencil-alt']} />
            </span>
          )}
        </Price>
      </Container>
    )
  }
}
