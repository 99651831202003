// Import Packages
import { Popup } from 'semantic-ui-react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { fontStyle } from 'design/Fonts'

interface Props {
  background: string
  left?: number
  color?: string
}

const Container = styled.div`
  min-height: 15px;
`

const CardWrapper = styled(Card)`
  &.ui.card {
    margin: 0 auto;
    border-radius: 0px;
    width: 95%;
    background: ${Colors.White1000};
    border: 1px solid ${Colors.Blue910};
  }
`

const CardContent = styled(Card.Content)`
  padding: 20px 0px 6px 20px;
`

const Divider = styled.div`
  border-bottom: 1px solid ${Colors.DarkBlue200};
  width: 90%;
  margin: 0 auto;
`

const ExtraContent = styled.div`
  font-size: 12px;
  display: flex;
  padding: 5px 20px 5px 20px;
  margin-top: 5px;
  margin-bottom: 6px;
  align-items: center;
`

const StyledDropdown = styled(Dropdown)`
  background: ${(props: Props) => props.background || props.background};
  text-align: -webkit-center !important;
  width: auto;
  min-width: 70px;
  padding: 3px;

  &.ui.inline.dropdown .dropdown.icon {
    color: ${(props: Props) => props.color || props.color};
  }

  &.ui.inline.dropdown>.text {
    color: ${(props: Props) => props.color || props.color};
    margin-left: 5px;
    font-weight: 600;
  }

  &.ui.inline.dropdown .dropdown.icon {
    margin-left: 10px;
  }
`

const CardHeader = styled(Card.Header)`
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.Black500};
  text-transform: capitalize;

  .ui.card>.content>&.header:not(.ui){
    margin-bottom: 10px;
    color: ${Colors.Black500};
    padding: 0px 10px 0px 120px;
  }
`

const CardMeta = styled(Card.Meta)`
  .ui.card &.meta, .ui.cards>.card &.meta {
    font-size: 12px;
    font-family: ${fontStyle};
    display: flex;
    color: ${Colors.Black550};
    align-items: center;
    margin-top: 5px;
    cursor: pointer;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .fa-people-carry {
    height: 16px;
    width: 16px;
    color: ${Colors.Text};
    margin-top: 3px;
  }
`

const IconContainer = styled.div`
  padding: 0px;
  padding-right: 8px;
  margin-right: 0px !important;

`

const CardImage = styled(Image)`
  .ui.card .content & {
    margin-right: 18px;
  }
  &.ui.tiny.image {
    width: 70px;
    height: 70px;
  }
`

interface ImageProps {
  backgroundImage: string
}

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
  margin: 0px;
  margin-right: 5px;
  &.small{
    width: 13px;
    height: 13px;
  }
  margin-right: 10px !important;
`

const CardFooter = styled(Card.Content)`
  align-items: center;
  display: flex;
  height: 25px;
  flex: 1;
  justify-content: flex-end;
  align-items: end;

  .fa-redo {
    font-size: 16px;
    margin-right: 8px;
  }
`

const StyledButton = styled(Button)`
  &.ui.icon.button {
    background-color: transparent;
    padding: 0px;
    font-size: 20px;
    color: ${Colors.Black550};
    position: relative;
    margin: 0 0 0 .25em;
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    margin-left: -10px !important;
  }
`

export {
  CardContent,
  CardFooter,
  CardHeader,
  CardImage,
  CardMeta,
  CardWrapper,
  Container,
  Divider,
  ExtraContent,
  IconContainer,
  ImageWrapper,
  StyledButton,
  StyledDropdown,
  StyledPopup
}
