import * as React from 'react'
import { Component } from 'react'
import Responsive from 'semantic-ui-react/dist/commonjs/addons/Responsive'
import Strings from 'utils/Strings'
import {
  BorderTree,
  Plus,
  SettingSection,
  VerticalText,
} from '../Styled'
import SliderGroup from './SliderGroup'
import {
  FeeGroup,
  FeeGroupRow,
  // ItemContainer,
  LargeFeeGroupRow,
  SliderDetailContainer
} from './Styled'
import TextBoxItem from './TextBoxItem'

interface NewData {
  additional?: number
  additionalMillionCap?: number
  base?: number
  baseMillionCap?: number
}

interface PropertyFeeProps {
  additional: number
  additionalMillionCap: number
  base: number
  baseMillionCap: number
  min: number
  max: number
  title: string
  width?: number
  onChangeData: (newData: NewData) => void
}

class PropertyFee extends Component<PropertyFeeProps, {}> {

  public onChangeBase = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChangeData } = this.props
    if (e.target.value === '') {
      onChangeData({ base: parseFloat('0') })
    } else {
      onChangeData({ base: parseFloat(e.target.value) })
    }
  }

  public onChangeBaseCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChangeData } = this.props
    if (e.target.value === '') {
      onChangeData({ baseMillionCap: parseFloat('0') })
    } else {
      onChangeData({ baseMillionCap: parseFloat(e.target.value) })
    }
  }

  public onChangeAdditional = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChangeData } = this.props
    if (e.target.value === '') {
      onChangeData({ additional: parseFloat('0') })
    } else {
      onChangeData({ additional: parseFloat(e.target.value) })
    }
  }

  public onChangeAdditionalCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChangeData } = this.props
    if (e.target.value === '') {
      onChangeData({ additionalMillionCap: parseFloat('0') })
    } else {
      onChangeData({ additionalMillionCap: parseFloat(e.target.value) })
    }
  }

  public render() {
    const { base, min, max, title, additional, width, baseMillionCap, additionalMillionCap } = this.props
    return (<Responsive as={SettingSection} width={width} horizontal={true}>
      <VerticalText as='h4' textAlign='center'>
        {title}
      </VerticalText>
      <BorderTree />
      <Responsive as={FeeGroup} >
        <Responsive as={LargeFeeGroupRow} horizontal={true}>
          <SliderGroup title={Strings.transactionFeeSettings.baseFee.header} min={min} max={max} value={baseMillionCap} onChange={this.onChangeBaseCap} width={50} titleInfo={Strings.transactionFeeSettings.baseFee.info} />
          <SliderGroup title={Strings.transactionFeeSettings.priceIncrement.header} min={min} max={max} value={additionalMillionCap} onChange={this.onChangeAdditionalCap} width={50} titleInfo={Strings.transactionFeeSettings.priceIncrement.info} />
        </Responsive>
        <Responsive as={FeeGroupRow}>
          <Responsive as={SliderDetailContainer} horizontal={true}>
            <TextBoxItem width={48} label={Strings.transactionFeeSettings.baseFee.label} value={base} onChange={this.onChangeBase} placeholder={Strings.transactionFeeSettings.enterAmount} />
            <Plus>+</Plus>
            <TextBoxItem width={48} label={Strings.transactionFeeSettings.priceIncrement.label} value={additional} onChange={this.onChangeAdditional} placeholder={Strings.transactionFeeSettings.enterAmount} />
          </Responsive>
        </Responsive>
      </Responsive>
    </Responsive>
    )
  }
}

export default PropertyFee


