// Import Packages
import { Dropdown, Form, Popup, Table } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Filters from 'design/icons/styledBoard/icon-filter.png'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars, scrollYContainer, y } from 'design/Styled'

interface Props {
  background?: string
  color?: string
}

const Container = styled.div`
  background-color: ${Colors.White1000};
`

const TableContainer = styled.div`
  flex: 1;
  ${scrollYContainer};
`

const GridContainer = styled.div`
  background: ${Colors.White1000};
  position: relative;
  padding-top: 25px;
  overflow: auto;
  overflow-y: hidden;
  flex: 1;
  ${scrollbars};

  .table-styled {
    flex: 1;
    ${scrollYContainer};
  }

  .ui.form {
    margin-bottom: 20px;
    align-items: center;
  }
`

const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

const StyleTask = styled.div`
  display: flex;
  align-items: center;

  .fa-envelope {
    height: 18px;
    width: 18px;
    margin-right: 10px;
    color: ${Colors.Grey120};
  }

  .fa-phone {
    height: 15px;
    width: 15px;
    margin-right: 10px;
    color: ${Colors.Grey120};
  }

  .fa-handshake {
    height: 18px;
    width: 18px;
    margin-right: 10px;
    color: ${Colors.Grey120};
  }

  .fa-ticket {
    height: 18px;
    width: 18px;
    margin-right: 10px;
    color: ${Colors.Grey120};
  }

  .fa-sms {
    height: 18px;
    width: 18px;
    margin-right: 10px;
    color: ${Colors.Grey120};
  }
`

const StyledForm = styled(Form)`
  padding: 75px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 55px;
  display: flex;
  align-items: baseline;

  &.ui.form .task {
    display: flex;
    flex-grow: 1;
  }

  &.ui.form .apply {
    margin-left: 15px;
  }

  &.ui.form .cancel {
    margin-left: 12px;
  }

  &.ui.form .field>.selection.dropdown {
    font-size: 12px;
    white-space: nowrap;
  }

  &.ui.form .fields {
    flex-grow: 1;
  }

  .ui.button {
    font-size: 12px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    white-space: nowrap;
  }
`

const AddButton = styled.div`
  text-align: end;
  margin-right: 25px;

  .fa-plus {
    width: 35px;
    background-color: ${Colors.DarkBlue200};
    height: 35px;
    color: white;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
  }
`

const StyledStatusDropdown = styled(Dropdown)`
  background: ${(props: Props) => props.background || Colors.White1000};
  display: flex !important;
  width: auto;
  min-width: 70px;

  &.ui.inline.dropdown {
    width: 100px;
  }

  &.ui.inline.dropdown .dropdown.icon {
    color: ${Colors.White1000};
    flex-grow: 1;
    align-self: center;
    text-align: right;
    margin-right: 10px;
  }

  &.ui.inline.dropdown>.text {
    color: ${Colors.White1000};
    margin-left: 5px;
    font-weight: 500;
    flex-grow: 1;
  }

  &.actionDropdown {
    text-align: left !important;
  }
`

const StyledSelectDropdown = styled(Dropdown)`
  &.ui.inline.dropdown .dropdown.icon {
    margin-left: 6px;
  }

  &.ui.inline.dropdown {
    margin-right: 40px;
    white-space: nowrap;
    width: 105px;
  }

  &.ui.dropdown>.dropdown.icon {
    color: ${Colors.Black550};
  }

  &.ui.inline.dropdown>.text {
    color: ${Colors.Black550};
  }
`

const StyledStatusDropdowns = styled(Dropdown)`
  background: ${Colors.TextLight};
  display: flex !important;
  width: auto;

  &.ui.inline.dropdown .dropdown.icon {
    flex-grow: 1;
    align-self: center;
    text-align: right;
    margin-right: 10px;
  }

  &.ui.inline.dropdown>.text {
    margin-left: 5px;
    font-weight: 500;
    flex-grow: 1;
  }

  &.actionDropdown {
    text-align: left !important;
  }

  &.ui.dropdown .menu {
    min-width: 100%;
  }
`

const CheckboxWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`

const FilterIcon = styled.div`
  background: url(${Filters});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  align-self: center;
  margin-right: 6px;
`

const Profile = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black500};
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
`

const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
  font-style: normal;

  div {
    ${y};
    height: 100%;
    color: ${Colors.DarkBlue220};
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
`

const StyledTable = styled(Table)`
  &.ui.table thead th{
    font-size: 14px;
    color: ${Colors.DarkBlue200};
  }

  &.ui.table {
    margin: 0 0;
  }

  .body {
    font-size: 12px;
    color: ${Colors.Black500};
    background: rgb(250, 252, 255);
  }
`

export {
  AddButton,
  CheckboxWrapper,
  DropDownPopup,
  FilterIcon,
  Container,
  GridContainer,
  StyledDropdown,
  StyledStatusDropdown,
  StyledStatusDropdowns,
  StyleTask,
  StyledForm,
  StyledSelectDropdown,
  TableContainer,
  Profile,
  Li,
  Labels,
  StyledPopup,
  MemberProfile,
  StyledTable
}
