import { History } from 'history'
import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/ChecklistManager/Actions'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import { StyledGrid } from './Styled'

interface OwnProps {
  history?: History
}

interface State {
  workflowId: string
  workflowName: string
}

interface DispatchProps {
  editCategory: (newName: string, index: number) => void
  editDocument: (newName: string, index: number) => void
  editProperty: (newName: string, categoryIndex: number, propertyIndex: number) => void
}

type Props = OwnProps & {} & DispatchProps

class WorkflowManagerDetails extends Component<Props, State> {
  public state = {
    workflowId: '',
    workflowName: ''
  }

  public render() {
    const { workflowId, workflowName } = this.state

    return (
      <StyledGrid>
        <Grid.Row style={{ paddingBottom: '0' }}>
          <Grid.Column width={6}>
            <LeftPanel getWorkflowId={this.setWorkflowId} />
          </Grid.Column>
          <Grid.Column width={10} style={{ paddingLeft: '14px' }}>
            <RightPanel workflowId={workflowId} workflowName={workflowName} />
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    )
  }

  private setWorkflowId = (id: string, name: string) => {
    this.setState({ workflowId: id, workflowName: name })
  }
}

const mapStateToProps = (state: AppState) => ({})

export default connect(
  mapStateToProps,
  {
    editCategory: Actions.editCategory,
    editDocument: Actions.editDocument,
    editProperty: Actions.editProperty
  }
)(WorkflowManagerDetails)
