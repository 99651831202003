import * as React from 'react'
import 'react-tagsinput/react-tagsinput.css'
import { Button, Form, Input, TextArea } from 'semantic-ui-react'
import { Strings } from 'utils'
import { Content, ContentHeader, SelectTask, StyledButton, StyledForm } from './Styled'
import { handleValidation } from './validation'

import { FormErrorType, FormType } from './Types'

// Font Awesome Icons
import {
  faCalendarAlt,
  faComment,
  faEnvelope,
  faPencilAlt,
  faPhoneVolume,
  faTimes
} from '@fortawesome/pro-light-svg-icons'
import { faShareAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCalendarAlt, faTimes, faShareAlt, faComment, faEnvelope, faPhoneVolume, faPencilAlt])

interface Props {
  addNewTask: (data: any) => void
  closeAddTaskModal: () => void
  updateTask: (data: any) => void
  editTaskData: any
}

interface State {
  form: FormType
  initalState: any
  name: string
  selectTask: number
}

const taskIcons = [
  { name: 'phone', value: 'Call' },
  { name: 'envelope', value: 'Email' },
  { name: 'sms', value: 'Sms' },
  { name: 'handshake', value: 'Meeting' }
]

class AddWorkflowTaskModal extends React.Component<Props, State> {
  public state = {
    form: {
      errors: {} as FormErrorType
    } as FormType,
    initalState: {},
    name: '',
    selectTask: 0
  }

  public componentDidMount() {
    const { editTaskData } = this.props
    if (editTaskData && Object.keys(editTaskData).length) {
      const index = taskIcons.findIndex((icons: any) => {
        return icons.value === editTaskData.type
      })
      const form = {
        ...editTaskData,
        errors: {} as FormErrorType
      }
      this.setState({
        form,
        initalState: editTaskData,
        selectTask: index
      })
    }
  }

  public render() {
    const { editTaskData } = this.props
    const {
      selectTask,
      form,
      form: { errors }
    } = this.state
    const taskIcon = taskIcons.map((items: any, index: any) => {
      return (
        <div key={index}>
          {selectTask === index ? (
            <div className="selectTask">
              <FontAwesomeIcon icon={['far', items.name]} />
            </div>
          ) : (
            <div className="task" onClick={() => this.selectTask(index)}>
              <FontAwesomeIcon icon={['far', items.name]} />
            </div>
          )}
        </div>
      )
    })
    return (
      <div>
        <ContentHeader>{editTaskData && Object.keys(editTaskData).length ? 'Edit Task' : 'Add Task'}</ContentHeader>
        <Content>
          <span className="select-task">{Strings.tasks.selectTask}</span>
          <SelectTask>{taskIcon}</SelectTask>
          <StyledForm size={'mini'}>
            <Form.Field
              control={Input}
              label="Task Name"
              name="name"
              value={form.name}
              error={!!errors.name}
              placeholder="Task Name"
              onChange={this.handleChange}
            />
            <Form.Field
              control={TextArea}
              label="Task Description"
              name="description"
              value={form.description}
              error={!!errors.description}
              placeholder="Description"
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              label="Due In"
              name="dueDate"
              value={form.dueDate}
              error={!!errors.dueDate}
              placeholder="Number of Days"
              autoComplete="off"
              onChange={this.handleChange}
            />
          </StyledForm>
          <StyledForm size={'tiny'}>
            <StyledButton>
              {editTaskData && Object.keys(editTaskData).length ? (
                <div>
                  <Form.Field control={Button} content="SAVE" onClick={this.updateTask} />
                  <Form.Field
                    control={Button}
                    content="CANCEL"
                    className="last-editChild"
                    onClick={this.cancelUpdateTask}
                  />
                </div>
              ) : (
                <div>
                  <Form.Field control={Button} content="SAVE" onClick={this.addTask} />
                  <Form.Field
                    control={Button}
                    className="last-child"
                    content="SAVE & ADD NEXT"
                    onClick={this.addNextTask}
                  />
                  <Form.Field
                    control={Button}
                    className="last-child"
                    content="CANCEL"
                    onClick={this.cancelUpdateTask}
                  />
                </div>
              )}
            </StyledButton>
          </StyledForm>
        </Content>
      </div>
    )
  }

  private selectTask = (index: number) => {
    const { form } = this.state
    form[`type`] = taskIcons[index].value
    this.setState({ selectTask: index })
  }

  private addNextTask = () => {
    const { addNewTask } = this.props
    const { form } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    addNewTask(form)
    this.setState({
      form: {
        description: '',
        dueDate: '',
        errors: {} as FormErrorType,
        name: ''
      },
      selectTask: 0
    })
  }

  private handleChange = (e: any, { name, value }: any) => {
    const { form } = this.state
    if (name === 'dueDate') {
      if (value === '') {
        form[name] = ''
      } else {
        form[name] = parseInt(value)
      }
    } else {
      form[name] = value
    }
    this.setState({ form })
  }

  private updateTask = () => {
    const { closeAddTaskModal, updateTask } = this.props
    const { form } = this.state
    updateTask(form)
    closeAddTaskModal()
  }

  private addTask = () => {
    const { closeAddTaskModal, addNewTask } = this.props
    const { form, selectTask } = this.state
    form[`type`] = taskIcons[selectTask].value

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    addNewTask(form)
    closeAddTaskModal()
  }

  private cancelUpdateTask = () => {
    const { closeAddTaskModal } = this.props
    this.setState(
      {
        form: {
          errors: {} as FormErrorType
        } as FormType
      },
      () => closeAddTaskModal()
    )
  }
}

export default AddWorkflowTaskModal
