// Import Packages
import * as React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Import Images and Icons
import Logo from 'design/images/logo.png'

// Import Styled Components
import { Container, ProfileImage } from './Styled'

const Notification = ({ message }: any) => (
  <div>
    {message && (
      <Container>
        <ProfileImage background={Logo} />
        <span style={{ textTransform: 'initial' }}>{message}</span>
      </Container>
    )}
  </div>
)

export default function({ message, type }: any) {
  toast(<Notification message={message} />, { type })
}

export const serverToast = (error: any) => {
  const pushError = (message: string) => {
    return toast(<Notification message={message} />, { type: 'error' })
  }

  try {
    const { graphQLErrors, networkError } = error
    const errors = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors : networkError
    if (graphQLErrors.length > 0) {
      errors.forEach((e: any) => {
        pushError(e.message)
      })
    } else if (networkError.result) {
      pushError(networkError.result)
    }
  } catch {
    if (error.message) {
      pushError(error.message)
    } else {
      pushError('An error occurred')
    }
  }
}

export const formToast = (errors: object) => {
  Object.keys(errors).forEach((key: string) => {
    toast(<Notification message={errors[key]} />, { type: 'error' })
  })
}
