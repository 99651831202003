import Colors from 'design/Colors'
import { Form, Search } from 'semantic-ui-react'
import styled from 'styled-components'

const Container = styled.div`
  display:flex;
`

const LeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35%;
  height: auto;
  background: ${Colors.Grey110};

  .ui.small.image {
    width: 190px;
  }
`

const StyledDirectForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }
/*
  &.ui.form {
    width: 180px;
  } */

  &.ui.form .field {
    font-size: 12px;
  }

  .ui.input {
    width: 220px;
  }

  .ui.icon.input>i.icon {
    font-size: 8px;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 30px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

const RightPanel = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 55%;
  height: auto;
`

const StyledSearch = styled(Search)`
  &.ui.search {
    width: 100%;
  }

  &.ui.search>.results {
    overflow: auto;
    max-height: 200px;
  }

  .ui.icon.input>i.icon {
    display: none;
  }

  .ui.input {
    height: 30px;
  }
`

const StyledLabel = styled.label`
  font-weight: 700;
  margin: 0 0 .28571429rem 0;
  font-size: 11px;
  color: darkslategrey;
`

const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 10px;
  color: ${Colors.Black500};
`

export {
  Container,
  LeftPanel,
  RightPanel,
  StyledDirectForm,
  StyledLabel,
  StyledSearch,
  Title,
}
