import { ActionCreator } from 'redux'
import Actions from 'store/Settings/WorkflowManager/ActionsTypes'
import {
  WorkflowAddNewLabel,
  WorkflowAddNewTask,
  WorkflowAddNewWorkflow,
  WorkflowCategories,
  WorkflowCategoriesItemsDetails,
  WorkflowDeleteTask,
  WorkflowDeleteWorkflow,
  WorkflowGetCategoriesData,
  WorkflowGetLabelManageData,
  WorkflowGetTaskData,
  WorkflowLabelManage,
  WorkflowTask,
  WorkflowUpdateTask
} from './Types'

export const addNewWorkflow: ActionCreator<WorkflowAddNewWorkflow> = (
  category: string,
  newData: WorkflowCategoriesItemsDetails[]
) => ({
  payload: {
    category,
    newData
  },
  type: Actions.workflowAddNewWorkflow
})

export const getCategoriesData: ActionCreator<WorkflowGetCategoriesData> = (
  data: WorkflowCategories[]
) => ({
  payload: {
    data
  },
  type: Actions.workflowGetCategoriesData
})

export const deleteWorkflow: ActionCreator<WorkflowDeleteWorkflow> = (
  category: string,
  id: string
) => ({
  payload: {
    category,
    id
  },
  type: Actions.workflowDeleteWorkflow
})

export const addNewLabel: ActionCreator<WorkflowAddNewLabel> = (
  newData: WorkflowLabelManage[]
) => ({
  payload: {
    newData
  },
  type: Actions.workflowAddNewLabel
})

export const getLabelManageData: ActionCreator<WorkflowGetLabelManageData> = (
  data: WorkflowLabelManage[]
) => ({
  payload: {
    data
  },
  type: Actions.workflowGetLabelManageData
})

export const addNewTask: ActionCreator<WorkflowAddNewTask> = (
  newData: WorkflowTask[]
) => ({
  payload: {
    newData
  },
  type: Actions.workflowAddNewTask
})

export const getTaskData: ActionCreator<WorkflowGetTaskData> = (
  data: WorkflowTask[]
) => ({
  payload: {
    data
  },
  type: Actions.workflowGetTaskData
})

export const updateTask: ActionCreator<WorkflowUpdateTask> = (
  newData: WorkflowTask[]
) => ({
  payload: {
    newData
  },
  type: Actions.workflowUpdateTask
})

export const deleteTask: ActionCreator<WorkflowDeleteTask> = (id: string) => ({
  payload: {
    id
  },
  type: Actions.workflowDeleteTask
})
