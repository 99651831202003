import * as React from 'react'

import PlanCard from 'shared/Billing/PlanCard'

import { Strings } from 'utils'

import {
  CardRow,
  Container,
  Footnote,
  FootnoteLink,
  ScrollY,
  StyledButton,
  StyledPopup,
  TableHeader,
  TableRow,
  Td,
  Th,
  Title
} from './Styled'

import { cards, tiers } from './MockData'

import { BillingCycleType, BillingPlanEnum } from 'shared/Billing/Types'

import { faDollarSign } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faDollarSign])

interface Props {
  cycle: BillingCycleType
  onUpdate: (plan: BillingPlanEnum) => void
}

interface State {
  isOpen: boolean
}

class Pricing extends React.Component<Props, State> {
  public state = {
    isOpen: false
  }

  public render() {
    const { isOpen } = this.state

    const { free, paid } = cards

    const { cycle } = this.props

    return (
      <Container>
        <ScrollY>
          <Title>
            <span>{Strings.settings.plans.plansAndFeature}</span>
            <span>{Strings.settings.plans.growth}</span>
          </Title>
          <CardRow justify="center">
            <PlanCard
              details={free.details}
              dogHeight={100}
              price={free.price}
              priceColor={free.priceColor}
              title={free.title}
            >
              <StyledButton
                disabled={cycle?.plan === BillingPlanEnum.Free}
                content={cycle?.plan === BillingPlanEnum.Free ? 'Active' : 'Select'}
                onClick={this.handleCancelPlan}
              />
              <Footnote>{Strings.settings.plans.credit}</Footnote>
            </PlanCard>
            <PlanCard
              details={paid.details}
              dogHeight={120}
              price={paid.price}
              priceColor={paid.priceColor}
              title={paid.title}
            >
              <StyledButton
                disabled={cycle?.plan === BillingPlanEnum.Paid}
                content={cycle?.plan === BillingPlanEnum.Paid ? 'Active' : 'Upgrade'}
                onClick={this.handleUpgradePlan}
              />
              <StyledPopup
                open={isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                on="click"
                position="top center"
                trigger={<FootnoteLink>{Strings.settings.plans.tiered}</FootnoteLink>}
                content={
                  <div className={isOpen ? 'animated zoomIn' : 'zoomOut'}>
                    <TableHeader columns={3}>
                      <Th>Pricing Tier</Th>
                      <Th>Transaction</Th>
                      <Th>Cost</Th>
                    </TableHeader>
                    {tiers.map((tier: any, index: number) => (
                      <TableRow columns={3} key={index}>
                        <Td className="tier">{tier.name}</Td>
                        <Td>{tier.count}</Td>
                        <Td className="cost">
                          <Icon icon={['fas', 'dollar-sign']} />
                          <span>{tier.cost}</span>
                        </Td>
                      </TableRow>
                    ))}
                  </div>
                }
              />
            </PlanCard>
          </CardRow>
        </ScrollY>
      </Container>
    )
  }

  private handleOpen = () => {
    this.setState({ isOpen: true })
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private handleCancelPlan = () => {
    const { onUpdate } = this.props
    onUpdate(BillingPlanEnum.Free)
  }

  private handleUpgradePlan = () => {
    const { onUpdate } = this.props
    onUpdate(BillingPlanEnum.Paid)
  }
}

export default Pricing
