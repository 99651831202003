// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface IconProps {
  backgroundImage?: string
}

const Icon = styled.div`
  background: ${(props: IconProps) => `url(${props.backgroundImage})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
`

const ItemContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  cursor: pointer;
  align-items: center;
`

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${Colors.White1000};
  align-items: center;
  justify-content: center;
  display: flex;
  border: 3px solid ${Colors.White480};
  margin-right: 15px;

  i.icon {
    font-size: 17px;
    margin-left: 2px;
    color: ${Colors.DarkBlue200};
  }
`

const Title = styled.div`
  font-size: 12px;
  color: ${Colors.DarkBlue200};
  font-weight: 500;
`

const Text = styled.div`
  font-size: 10px;
  color: ${Colors.Black550};
`

export {
  Icon,
  IconContainer,
  ItemContainer,
  Text,
  Title
}
