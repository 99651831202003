// Import Packages
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const ChecklistManagerDetailsWrapper = styled(Segment)`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
`

const Modal = styled(Container)`
  &&.ui.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${Colors.transparent};
  }
`

const Main = styled.div`
  border: none;
  box-shadow: none;
  padding: 0;
  position: relative;
  background-color: ${Colors.Blue1000};
  min-width: 97%;
  height: 100%;
  transform: translate(0, 0);
  overflow-x: auto;
  overflow-y: hidden;
  ${scrollbars};
`

const CloseImageWrapper = styled.div`
  img {
    width: 30px;
    width: 30px;
  }
`

const StyledGrid = styled.table`
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
  padding: 0;
  height: 100%;
  width: 100%;
  border: none;
  border-collapse: collapse;
`

const YTitleText = styled.div`
  font-size: 18px;
  color: ${Colors.Black550};
  text-transform: uppercase;
  transform: rotate(-90deg);
  position: absolute;
  bottom: 10px;
  transform-origin: center left;
  left: 15px;
  z-index: 100;
  font-weight: bold;
`

export {
  ChecklistManagerDetailsWrapper,
  CloseImageWrapper,
  Main,
  Modal,
  StyledGrid,
  YTitleText
}
