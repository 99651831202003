import styled, { css } from 'styled-components'

interface Props {
  src?: string
  isActive?: boolean
}

const getActive = ({ isActive }: Props) => {
  if (!isActive) {
    return ``
  }

  return css`
    background-color: #444;
  `
}

export const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  .slick-slider,
  .slick-list {
    width: 100%;
    height: 100%;
    outline: none;
  }
  .slick-track,
  .slick-slide div {
    height: 100%;
    outline: none;
  }
`

export const Slide = styled.section`
  display: flex !important;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url('${(props:Props) => props.src || ''}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  outline: none;
`

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  transition: opacity 200ms;

  &:hover {
    opacity: 1;
  }

  &.left {
    left: 0;
    justify-content: flex-start;
  }

  &.right {
    right: 0;
    justify-content: flex-end;
  }

  .rp-button-bubble {
    position: absolute;
    top: 50%;
    margin-top: -15px;
  }
`

export const Dot = styled.div`
  display: block;
  background-color: #FFF;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.3em solid #FFF;
  cursor: pointer;
  box-shadow: 0 0 7px 2px rgba(0,0,0,0.1);
  ${getActive};
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0.5em;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0.8;
  transition: opacity 200ms;
  z-index: 2;

  &:hover {
    opacity: 1;
  }

  & ${Dot} {
    margin: 0 0.5em;
  }

  & ${Dot}:first-child {
    margin-left: 0;
  }

  & ${Dot}:last-child {
    margin-right: 0;
  }
`
