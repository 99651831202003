// Import Packages
import { ColumnDefinition, plugins, RowDefinition } from 'griddle-react'
import { Component } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'

// Import Images and Icons
import IconAscending from 'design/icons/styledGrid/icon_asc.png'
import IconDescending from 'design/icons/styledGrid/icon_desc.png'

// Import Utils
import { Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import { ImageWrapper, Infograph, StyledGrid as Griddle } from './Styled'

interface Props {
  data: any[]
  columnMetaData: any[]
  tableHeight: number
}

// Grid Util
const rowDataSelector = (state: any, { griddleKey }: { griddleKey: number }) => {
  return state
    .get('data')
    .find((rowMap: any) => rowMap.get('griddleKey') === griddleKey)
    .toJSON()
}

const enhancedWithRowData = connect((state, props: { griddleKey: number }) => {
  return {
    rowData: rowDataSelector(state, props)
  }
})

// Component
export default class StyledGrid extends Component<Props, {}> {
  public state = {}

  public render() {
    const { data, columnMetaData } = this.props
    const NewLayout = ({ Table }: any) => {
      return (
        <div className="table-styled">
          <Table />
        </div>
      )
    }

    const styleConfig = {
      classNames: {},
      icons: {
        TableHeadingCell: {
          sortAscendingIcon: <ImageWrapper backgroundImage={IconAscending} />,
          sortDescendingIcon: <ImageWrapper backgroundImage={IconDescending} />
        }
      },
      styles: {
        Cell: {
          color: Colors.Black500,
          fontSize: 12,
          paddingLeft: 20,
          paddingRight: 0
        },
        Row: {
          backgroundColor: Colors.White510,
          borderBottomColor: Colors.Red110,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          height: 50
        },
        Table: {
          borderCollapse: 'collapse',
          fontFamily: 'Lato',
          width: '100%'
        },
        TableHeading: {
          backgroundColor: Colors.White900,
          borderBottomColor: Colors.Red110,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          height: 50
        },
        TableHeadingCell: {
          color: Colors.Black590,
          fontSize: 14,
          fontWeight: 600,
          height: 50,
          paddingLeft: 20,
          paddingRight: 0,
          textAlign: 'left',
          width: 10
        }
      }
    }

    return (
      <>
        {data.length === 0 ? (
          <Infograph backgroundImage={Strings.generalText.nothing} />
        ) : (
          <Griddle
            data={data}
            components={{
              Layout: NewLayout
            }}
            classNames={['griddle-table']}
            styleConfig={styleConfig}
            plugins={[plugins.LocalPlugin]}
            pageProperties={{
              currentPage: 1,
              pageSize: data.length
            }}
          >
            <RowDefinition>
              {columnMetaData.map((column, index) => {
                const { enhanceWithRowData, ...columnProps } = column
                let customComponent = null
                if (column.customComponent) {
                  customComponent = enhanceWithRowData
                    ? enhancedWithRowData(column.customComponent)
                    : column.customComponent
                }
                return <ColumnDefinition key={index} {...columnProps} customComponent={customComponent} />
              })}
            </RowDefinition>
          </Griddle>
        )}
      </>
    )
  }
}
