import gql from 'graphql-tag'

export const CREATE_CONTACT = gql`
  mutation createContact(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $source: String
    $type: String
    $contactBoard: String
    $contactOrder: Int
    $teamContactBoard: String
    $teamContactOrder: Int
    $officeContactBoard: String
    $officeContactOrder: Int
    $tenantContactBoard: String
    $tenantContactOrder: Int
  ) {
    createContact(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        source: $source
        type: $type
        contactBoard: $contactBoard
        contactOrder: $contactOrder
        teamContactBoard: $teamContactBoard
        teamContactOrder: $teamContactOrder
        officeContactBoard: $officeContactBoard
        officeContactOrder: $officeContactOrder
        tenantContactBoard: $tenantContactBoard
        tenantContactOrder: $tenantContactOrder
      }
    ) {
      _id
      firstName
      lastName
      phone
      email
      source
      isActive
      type
      teamContactOrder
      officeContactOrder
      tenantContactOrder
      createdAt
      shareWith {
        _id
        firstName
        lastName
        profileImage
        status
        type
      }
      owner {
        _id
        firstName
        lastName
        profileImage
      }
    }
  }
`

export const MOVE_CONTACT = gql`
  mutation transferContact(
    $contactId: ID
    $contactBoard: String
    $teamContactBoard: String
    $officeContactBoard: String
    $tenantContactBoard: String
    $contactBoardOrder: Int
    $teamContactBoardOrder: Int
    $officeContactBoardOrder: Int
    $tenantContactBoardOrder: Int
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $source: String
    $type: String
  ) {
    transferContact(
      where: { _id: $contactId }
      input: {
        contactBoard: $contactBoard
        teamContactBoard: $teamContactBoard
        officeContactBoard: $officeContactBoard
        tenantContactBoard: $tenantContactBoard
        contactOrder: $contactBoardOrder
        teamContactOrder: $teamContactBoardOrder
        officeContactOrder: $officeContactBoardOrder
        tenantContactOrder: $tenantContactBoardOrder
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        source: $source
        type: $type
      }
    ) {
      _id
      firstName
      lastName
      source
      type
      email
      phone
    }
  }
`

export const DELETE_CONTACT = gql`
  mutation deleteContact($contactId: [String]) {
    deleteContact(_id: $contactId)
  }
`

export const UPDATE_CONTACT = gql`
  mutation updateContact(
    $contactId: ID
    $isActive: Boolean
    $firstName: String
    $lastName: String
    $source: String
    $phone: String
    $pets: Boolean
    $dateOfBirth: Date
    $married: Boolean
    $anniversaryDate: Date
    $children: Boolean
    $address: AddressInput
    $genderPrefix: String
  ) {
    updateContact(
      where: { _id: $contactId }
      input: {
        isActive: $isActive
        firstName: $firstName
        genderPrefix: $genderPrefix
        lastName: $lastName
        source: $source
        phone: $phone
        pets: $pets
        dateOfBirth: $dateOfBirth
        married: $married
        anniversaryDate: $anniversaryDate
        children: $children
        address: $address
      }
    ) {
      _id
      firstName
      lastName
      phone
      email
      source
      isActive
      type
      teamContactOrder
      officeContactOrder
      tenantContactOrder
      createdAt
      shareWith {
        _id
        firstName
        lastName
        profileImage
        status
        type
      }
      owner {
        _id
        firstName
        lastName
        profileImage
      }
      address {
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      married
      pets
      dateOfBirth
      anniversaryDate
      children
    }
  }
`

export const UPDATE_CONTACT_ORDER = gql`
  mutation updateContactOrder(
    $contactBoard: String
    $teamContactBoard: String
    $officeContactBoard: String
    $tenantContactBoard: String
    $cardId: ID
    $contactSourceBoardOrder: Int
    $teamContactSourceBoardOrder: Int
    $officeContactSourceBoardOrder: Int
    $tenantContactSourceBoardOrder: Int
    $contactDestinationBoardOrder: Int
    $teamContactDestinationBoardOrder: Int
    $officeContactDestinationBoardOrder: Int
    $tenantContactDestinationBoardOrder: Int
  ) {
    updateContactOrder(
      where: {
        _id: $cardId
        contactBoard: $contactBoard
        teamContactBoard: $teamContactBoard
        officeContactBoard: $officeContactBoard
        tenantContactBoard: $tenantContactBoard
        contactOrder: $contactSourceBoardOrder
        teamContactOrder: $teamContactSourceBoardOrder
        officeContactOrder: $officeContactSourceBoardOrder
        tenantContactOrder: $tenantContactSourceBoardOrder
      }
      input: {
        contactOrder: $contactDestinationBoardOrder
        teamContactOrder: $teamContactDestinationBoardOrder
        officeContactOrder: $officeContactDestinationBoardOrder
        tenantContactOrder: $tenantContactDestinationBoardOrder
      }
    ) {
      _id
      firstName
      lastName
      phone
      email
      source
      isActive
      type
      teamContactOrder
      officeContactOrder
      tenantContactOrder
      createdAt
      shareWith {
        _id
        firstName
        lastName
        profileImage
        status
        type
      }
      owner {
        _id
        firstName
        lastName
        profileImage
      }
      address {
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      married
      pets
      dateOfBirth
      anniversaryDate
      children
    }
  }
`

export const SHARE_WITH = gql`
  mutation shareWith(
    $contactId: ID
    $userId: ID
    $firstName: String
    $lastName: String
    $email: String
    $type: String
  ) {
    sharedContactWith(
      where: { _id: $contactId }
      userParams: {
        _id: $userId
        firstName: $firstName
        lastName: $lastName
        userName: $email
        type: $type
      }
    ) {
      _id
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
        status
      }
    }
  }
`

export const DELETE_SHARE_WITH = gql`
  mutation deleteShareWith($contactId: ID, $userId: ID) {
    deleteSharedContactWith(
      where: { _id: $contactId }
      userParams: { _id: $userId }
    ) {
      _id
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
        status
      }
    }
  }
`

export const CREATE_CONTACT_NOTES = gql`
  mutation createContactNote($contactId: String, $description: String) {
    createNote(input: { contact: $contactId, description: $description }) {
      _id
      description
      contact {
        firstName
        lastName
      }
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const EDIT_CONTACT_NOTES = gql`
  mutation editContactNote($contactNoteId: String, $description: String) {
    updateNote(
      where: { _id: $contactNoteId }
      input: { description: $description }
    ) {
      _id
      description
      contact {
        firstName
        lastName
      }
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const DELETE_CONTACT_NOTES = gql`
  mutation deleteContactNote($contactNoteId: String) {
    deleteNote(_id: $contactNoteId)
  }
`

export const IMPORT_CONTACTS = gql`
  mutation importContacts(
    $file: Upload
    $contactBoard: String
    $teamContactBoard: String
    $officeContactBoard: String
    $tenantContactBoard: String
  ) {
    importContacts(
      input: {
        contactBoard: $contactBoard
        teamContactBoard: $teamContactBoard
        officeContactBoard: $officeContactBoard
        tenantContactBoard: $tenantContactBoard
      }
      file: $file
    ) {
      _id
      firstName
      lastName
      phone
      email
      source
      isActive
      type
      teamContactOrder
      officeContactOrder
      tenantContactOrder
      createdAt
      shareWith {
        _id
        firstName
        lastName
        profileImage
        status
        type
      }
      owner {
        _id
        firstName
        lastName
        profileImage
      }
    }
  }
`
