import moment from 'moment'
import * as React from 'react'

import Transition from 'shared/Transition'
import { getLoggedInUser } from 'utils'

import { handwriting } from 'design/Fonts'
import {
  Col,
  Container,
  Item,
  Label,
  List,
  Point,
  Row,
  StyledDropdown,
  DropdownItem,
  StyledInput,
} from './Styled'

import {
  MarkupLabelEnum,
  MarkupSourceEnum,
  OptionType,
  MarkupType,
  StageType,
  UserType,
} from '../Types'

interface Props {
  container: StageType,
  labels: OptionType[]
  onChange: (rectangle: MarkupType) => void
  rectangle: MarkupType
  source: MarkupSourceEnum,
}

interface State {
  fonts: OptionType[]
  isOpen: boolean
  user: UserType
}

class Menu extends React.Component<Props, State> {
  public state = {
    fonts: [] as OptionType[],
    isOpen: false,
    user: {} as UserType,
  }

  public componentDidMount = async () => {
    const { rectangle, onChange } = this.props
    const fonts: OptionType[] = Object.keys(handwriting).map((key:string) => {
      const { name, fontFamily } = handwriting[key]
      return {
        text: name,
        value: fontFamily,
      }
    })

    const init = { ...rectangle }
    init.style = {
      fontFamily: init.label.value === MarkupLabelEnum.Signature ? fonts[0].value : 'sans-serif',
      fontSize: 14,
      fontScaleFactor: 0.0014,
      offset: 4,
      lineHeight: 1,
      ...rectangle.style,
    }
    init.meta = {
      date: moment(new Date()).format('ll'),
      ...rectangle.meta,
    }

    const user: UserType = await getLoggedInUser({ fromCache: true }) || {} as UserType
    if (init.label.value === MarkupLabelEnum.Signature) {
      init.value = `${user.firstName} ${user.lastName}`
    }

    onChange(init)
    this.setState({
      fonts,
      isOpen: true,
      user,
    })
  }

  public componentDidUpdate = (prev: Props) => {
    const { rectangle: { width: pWidth, height: pHeight, left: pLeft, top: pTop }} = prev
    const { rectangle: { width, height, left, top }} = this.props
    if (width !== pWidth || height !== pHeight || left !== pLeft || top !== pTop) {
      this.refreshPopup()
    }
  }

  public render = () => {
    const {
      container,
      labels,
      rectangle,
      source,
    } = this.props

    const {
      fonts,
      isOpen,
    } = this.state

    const position = {
      x: (rectangle.left + (rectangle.width / 2)) * container.scale.x * container.width,
      y: (rectangle.top + rectangle.height) * container.scale.y * container.height,
    }

    const isSignature = rectangle.label.value === MarkupLabelEnum.Signature
    const isNote = rectangle.label.value === MarkupLabelEnum.Note

    const labelOptions:any = labels.map((item: OptionType, key: number) => ({
      key,
      ...item,
      content: <DropdownItem>{item.text}</DropdownItem>
    }))

    const fontOptions:any = fonts.map((item: OptionType, key: number) => ({
      key,
      ...item,
      content: <DropdownItem style={{ fontFamily: item.value }}>{item.text}</DropdownItem>
    }))

    return (
      <Transition on={isOpen} type='fadeIn'>
        <Container
          children={(
            <React.Fragment>
              {source === MarkupSourceEnum.User ? (
                <Row padding={'0.8em 1em'}>
                  <Col>
                    <Label>Type</Label>
                    <StyledDropdown
                      name='label'
                      selection={true}
                      options={labelOptions}
                      value={rectangle.label && rectangle.label.value}
                      onChange={this.handleLabelChange}
                    />
                  </Col>
                  {!isNote && (
                    <Col>
                      <Label>Size</Label>
                      <StyledInput
                        name='fontSize'
                        type='number'
                        min={8}
                        max={72}
                        value={rectangle.style && rectangle.style.fontSize}
                        onChange={this.handleStyleChange}
                      />
                    </Col>
                  )}
                  {isSignature && (
                    <Col>
                      <Label>Font</Label>
                      <StyledDropdown
                        name='fontFamily'
                        selection={true}
                        options={fontOptions}
                        value={rectangle.style && rectangle.style.fontFamily}
                        onChange={this.handleStyleChange}
                        style={{ fontFamily: rectangle.style ? rectangle.style.fontFamily : 'sans-serif' }}
                      />
                    </Col>
                  )}
                </Row>
              ) : (
                <List>
                  {labels.map((item: OptionType, index: number) => {
                    return (
                      <Item
                        key={index}
                        onClick={() => this.handleLabelChange(null, {name: 'label', value: item.value})}
                      >
                        {item.text}
                      </Item>
                    )
                  })}
                </List>
              )}
            </React.Fragment>
          )}
          flowing={true}
          open={isOpen}
          position='bottom center'
          trigger={(<Point position={position} />)}
        />
      </Transition>
    )
  }

  private handleStyleChange = (e:any, { name, value }:any) => {
    const { onChange, rectangle } = this.props
    const update = { ...rectangle }
    if (name === 'fontSize') {
      value = parseInt(value)
    }
    update.style = {
      ...update.style,
      [name]: value
    }
    onChange(update)
  }

  private handleLabelChange = (e:any, { name, value }:any) => {
    const { onChange, rectangle, labels } = this.props
    const { fonts, user } = this.state
    const update = { ...rectangle }
    update.label = labels.find((item:OptionType) => item.value === value) || rectangle.label

    if (update.label.value !== rectangle.label.value) {
      const style: any = { ...update.style }
      switch (update.label.value) {
        case MarkupLabelEnum.Signature:
          style.fontFamily = fonts[0].value
          update.value = `${user.firstName} ${user.lastName}`
          break

        case MarkupLabelEnum.Text:
        case MarkupLabelEnum.Note:
          style.fontFamily = 'Roboto, sans-serif'
          break

        default:
          break
      }
      update.style = style
      onChange(update)
    }
  }

  private refreshPopup = () => {
    this.setState({ isOpen: false }, () => {
      this.setState({ isOpen: true })
    })
  }
}

export default Menu
