// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const AddContactLeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35%;
  height: auto;
  background: ${Colors.Grey110};

  .ui.small.image {
    width: 250px;
  }
`

const Text = styled.div`
  width: 200px;
  text-align: center;
  margin-top: 5px;
  font-size: 18px;
  color: ${Colors.Black500};
  font-weight: 600;
`

const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 60%;

  h2 {
    font-size: 1.014286rem;
    text-align: center;
    margin-bottom: 40px;
    color: ${Colors.DarkBlue205};
  }
`

const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  &.ui.form {
    max-width: 60%;
    margin: 0 auto;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

const Icons = styled.div`
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: 50%;
  background-color: ${Colors.DarkBlue205};
  align-items: center;
  justify-content: center;
  display: flex;

  .svg-inline--fa {
    width: 26px;
    height: 26px;
    color: ${Colors.White1000};
  }
`

export {
  AddContactLeftPanel,
  AddForm,
  Container,
  Text,
  StyledForm,
  Icons
}
