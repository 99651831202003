// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Import Components
import ActionButtons from 'shared/Tasks/ActionButtons'
import { ShowViewDetails } from '../Tasks'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Tasks/Actions'
import { Activities, TaskCardDetails, TaskNotes } from 'store/Tasks/Types'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Card,
  CircleIcon,
  Container,
  ContentRow,
  DueDate,
  DueRow,
  Footer,
  Labels,
  Li,
  MemberProfile,
  Notes,
  RelatedTo,
  Sidebar,
  StyledLabel,
  StyledNotes,
  StyledPopup,
  TaskDecription,
  Title,
  Ul
} from 'shared/Tasks/KanbanCard/Styled'

// Font Awesome Icons
import { faEnvelope, faPhone, faSms } from '@fortawesome/pro-light-svg-icons'
import { faMinus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone, faSms, faMinus])

interface UserType {
  _id: string
  role: string
  profileImage: string
}

interface StoreProps {
  setNotesData: (data: any) => void
  setTaskActivity: (data: any) => void
  notes: TaskNotes[]
  activities: Activities[]
}

interface OwnProps {
  changeStatus: (status: string) => void
  CardData: TaskCardDetails
  boardId: string
}

type Props = StoreProps & OwnProps

interface State {
  activities: Activities[]
  notes: TaskNotes[]
  user: UserType
}

class TasksCard extends React.Component<Props, State> {
  public state = {
    activities: [],
    notes: [],
    user: {} as UserType
  }

  public async componentDidMount() {
    const user: UserType = await getLoggedInUser({ fromCache: true })
    this.setState({ user })
  }

  public render() {
    const { CardData, boardId } = this.props
    const { user } = this.state
    let toggle = true
    if (CardData.lead) {
      CardData.lead.shareWith.forEach((item: any) => {
        if (item._id === user._id) {
          toggle = false
        }
      })
    }

    const Members = CardData.sharedWith.map((items: any, index: any) => {
      while (index < 3) {
        return (
          <Li key={index}>
            <StyledPopup
              trigger={<MemberProfile key={index} background={items.profileImage} />}
              content={`${items.firstName} ${items.lastName}`}
            />
          </Li>
        )
      }
      return null
    })

    const Count =
      CardData.sharedWith.length > 3 ? (
        <Labels>
          <StyledPopup trigger={<StyledLabel>...</StyledLabel>} content={`+${CardData.sharedWith.length - 3}`} />
        </Labels>
      ) : null

    let backgroundIcon: any
    let backgroundProgress = ''
    switch (CardData.type) {
      case 'Email':
        backgroundIcon = 'envelope'
        backgroundProgress = Colors.Red25
        break
      case 'Call':
        backgroundIcon = 'phone'
        backgroundProgress = Colors.Blue50
        break
      case 'Meeting':
        backgroundIcon = 'handshake'
        backgroundProgress = Colors.Purple50
        break
      case 'Sms':
        backgroundIcon = 'sms'
        backgroundProgress = Colors.Green100
        break
      case 'Pass':
        backgroundIcon = 'ticket'
        backgroundProgress = Colors.Orange110
        break
    }
    return (
      <Container>
        <Card>
          <ContentRow onClick={this.showNotes}>
            <DueRow>
              {CardData.dueDate ? (
                <DueDate
                  background={
                    moment(moment(CardData.dueDate).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) &&
                      CardData.status !== 'Completed'
                      ? Colors.Red810
                      : Colors.White1000
                  }
                  color={
                    moment(moment(CardData.dueDate).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) &&
                      CardData.status !== 'Completed'
                      ? Colors.White1000
                      : Colors.Black550
                  }
                  important={moment(moment(CardData.dueDate).format('YYYY-MM-DD')).isBefore(
                    moment().format('YYYY-MM-DD')
                  )}
                  progress={backgroundProgress}
                >
                  <span>{moment(CardData.dueDate).format('L')}</span>
                </DueDate>
              ) : (
                  <div />
                )}
              <CircleIcon>
                <FontAwesomeIcon icon={['fal', backgroundIcon]} />
              </CircleIcon>
            </DueRow>
            <Title>{CardData.name}</Title>
            <RelatedTo>
              {Strings.tasks.relatedTo}
              {CardData.lead && Object.keys(CardData.lead).length > 0 ? (
                <Link
                  to={
                    toggle
                      ? user.role === 'ADMIN' || user.role === 'MANAGER'
                        ? Routes.leads.root + Routes.leads.details.path + `/${CardData.lead._id}?pool=1`
                        : ''
                      : Routes.leads.root + Routes.leads.details.path + `/${CardData.lead._id}?pool=0`
                  }
                >
                  <div style={{ color: Colors.Black500, marginLeft: 3 }}>
                    {' '}
                    {CardData.lead.firstName} {CardData.lead.lastName}
                  </div>
                </Link>
              ) : CardData.transaction && Object.keys(CardData.transaction).length > 0 ? (
                <Link
                  to={Routes.transactions.root + `/${CardData.transaction._id}` + Routes.transactions.documents.path}
                >
                  <div style={{ color: Colors.Black500, marginLeft: 3 }}>
                    {' '}
                    {CardData.transaction.owner.firstName} {CardData.transaction.owner.lastName}
                  </div>
                </Link>
              ) : (
                    'Not Related Yet'
                  )}
            </RelatedTo>
            <TaskDecription>{CardData.description}</TaskDecription>
          </ContentRow>
          <Sidebar className="sidebar">
            <ActionButtons
              actions={['complete', 'edit', 'forward', 'options', 'reply', 'trash']}
              boardId={boardId}
              cardData={CardData}
            />
          </Sidebar>
        </Card>
        <Footer>
          <StyledNotes style={{ marginRight: 5, flex: 1 }} onClick={this.showNotes}>
            {Strings.tasks.notes}
            <Notes circular={true}>{CardData.notes && CardData.notes !== null ? CardData.notes.length : 0}</Notes>
          </StyledNotes>
          <StyledNotes style={{ flex: 1 }} onClick={this.showViewDetails}>
            {Strings.tasks.activity}
            <Notes circular={true}>
              {CardData.activities && CardData.activities !== null ? CardData.activities.length : 0}
            </Notes>
          </StyledNotes>
          <Ul style={{ display: 'flex', marginLeft: -25, flex: 1, marginRight: 10 }}>
            {Members}
            <Li>{Count}</Li>
          </Ul>
        </Footer>
      </Container>
    )
  }

  private showNotes = (e: React.SyntheticEvent<EventTarget>) => {
    e.stopPropagation()
    const { CardData } = this.props
    ShowViewDetails(CardData._id, 1)
  }

  private showViewDetails = () => {
    const { CardData } = this.props
    ShowViewDetails(CardData._id)
  }
}

const mapStateToProps = (state: AppState) => ({
  activities: state.tasks.activities,
  notes: state.tasks.notes
})

export default connect(
  mapStateToProps,
  {
    setNotesData: Actions.getNotesData,
    setTaskActivity: Actions.getTaskActivities
  }
)(TasksCard)
