// Import Packages
import { ColumnDefinition, plugins, RowDefinition } from 'griddle-react'
import * as React from 'react'
import { connect } from 'react-redux'

import IconAscending from 'design/icons/styledBoard/icon_asc.png'
import IconDescending from 'design/icons/styledBoard/icon_desc.png'

import CustomPaging from './CustomPaging'

import { Strings } from 'utils'

import Colors from 'design/Colors'

import { Container, ImageWrapper, Infograph, StyledGriddle, TableContainer } from './Styled'

interface Props {
  addList: () => void
  data: any[]
  columnMetaData: any[]
  tableHeight?: number
  paddingLeft?: number
  paddingRight?: number
}

interface State {
  pager: any
  perPage: number
  gridKey: number
  pageNumber: number
}

const rowDataSelector = (state: any, { griddleKey }: { griddleKey: number }) => {
  return state
    .get('data')
    .find((rowMap: any) => rowMap.get('griddleKey') === griddleKey)
    .toJSON()
}

const enhancedWithRowData = connect((state, props: { griddleKey: number }) => {
  return {
    rowData: rowDataSelector(state, props)
  }
})

export default class GridView extends React.Component<Props, State, {}> {
  public state = {
    gridKey: new Date().getTime(),
    pageNumber: 1,
    pager: '',
    perPage: 10
  }

  public onPerPage = async (pageLimit: number) => {
    this.setState({ perPage: pageLimit })
  }

  public handlePagination = (data: any) => {
    this.setState({ pager: data })
  }

  public addList = () => {
    const { addList } = this.props
    addList()
  }

  public setPageNumber = (activePage: any) => {
    this.setState({ pageNumber: activePage })
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({ gridKey: new Date().getTime() })
  }

  public render() {
    const { data, columnMetaData, paddingLeft, paddingRight } = this.props
    const { pager, perPage, gridKey, pageNumber } = this.state
    const CustomLayout = ({ Table, Pagination }: any) => (
      <Container padding={1} primary={true}>
        <TableContainer>
          {data.length === 0 ? <Infograph backgroundImage={Strings.generalText.nothing} /> : <Table />}
        </TableContainer>
        <Pagination
          addList={this.addList}
          handlePagination={this.handlePagination}
          onChange={this.onPerPage}
          page={pageNumber}
          perPage={perPage}
          rowData={data}
          setPageNumber={this.setPageNumber}
        />
      </Container>
    )

    const styleConfig = {
      icons: {
        TableHeadingCell: {
          sortAscendingIcon: <ImageWrapper backgroundImage={IconAscending} />,
          sortDescendingIcon: <ImageWrapper backgroundImage={IconDescending} />
        }
      },
      styles: {
        Cell: {
          color: Colors.Black500,
          fontSize: 12,
          paddingLeft: paddingLeft ? paddingLeft : 20,
          paddingRight: paddingRight ? paddingRight : 20
        },
        Row: {
          backgroundColor: Colors.White800,
          borderBottomColor: Colors.Red110,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          height: 50
        },
        Table: {
          borderCollapse: 'collapse',
          fontFamily: 'Lato',
          width: '100%'
        },
        TableHeading: {
          backgroundColor: Colors.White900,
          borderBottomColor: Colors.Red110,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          height: 50
        },
        TableHeadingCell: {
          color: Colors.DarkBlue210,
          fontSize: 14,
          fontWeight: 600,
          height: 50,
          paddingLeft: paddingLeft ? paddingLeft : 20,
          textAlign: 'left',
          width: 10
        }
      }
    }

    return (
      <Container>
        <StyledGriddle
          data={pager}
          components={{
            Layout: CustomLayout,
            Pagination: CustomPaging
          }}
          useCustomPagerComponent="true"
          customPagerComponent={CustomPaging}
          classNames={['griddle-table']}
          styleConfig={styleConfig}
          plugins={[plugins.LocalPlugin]}
          pageProperties={{
            currentPage: 1,
            pageSize: perPage
          }}
          key={gridKey}
        >
          <RowDefinition>
            {columnMetaData.map((column, index) => {
              const { enhanceWithRowData, ...columnProps } = column

              let customComponent = null
              if (column.customComponent) {
                customComponent = enhanceWithRowData
                  ? enhancedWithRowData(column.customComponent)
                  : column.customComponent
              }
              return <ColumnDefinition key={index} {...columnProps} customComponent={customComponent} />
            })}
          </RowDefinition>
        </StyledGriddle>
      </Container>
    )
  }
}
