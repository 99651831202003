// Import Packages
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import * as React from 'react'
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar'
import SpinningBubbles from 'react-loading'
import { Dimmer } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import Toast from 'shared/Toast'
import CalendarHeader from '../../Events/CalendarHeader'
import CalendarPopup from '../../Events/CalendarPopup'
import AddTasksModal from '../../Tasks/AddTasksModal'
import AddEventModal from '../AddEventModal'

// Import Store Types, Actions and Reducers
import { EventDetails, UserType } from 'store/Events/Types'
import { Event } from 'store/Events/Types'

// Import Colors
import Colors from 'design/Colors'
import { getLoggedInUser } from 'utils'

// Import Styled Components
import { createEvent } from '../../Tasks/EventMutations'
import { createTask, deleteEvent, deleteTasks } from '../../Tasks/TaskMutations'
import { getMyTaskBoards, getMyTasks } from '../../Tasks/TaskQueries'
import { getEvents } from '../EventQueries'
import { Wrapper } from './Styled'

import { getTenantDetails } from 'app/Settings/Queries'

interface ExtendedEvent extends Event {
  color: string
}

interface State {
  calendarSwitch: boolean
  date: Date
  edit: boolean
  editEventObj: EventDetails | any
  eventsData: ExtendedEvent[]
  eventType: string
  showAddEventPopup: boolean
  addEventData: EventDetails | any
  loggedUser: UserType
  loader: boolean
  culture: string
}

const colors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Green560}`
]

const allViews = Object.keys(Views).map((item: string) => Views[item])

const localizers = momentLocalizer(moment)

class CalendarPanel extends React.Component<Event, State> {
  public state = {
    addEventData: {},
    calendarSwitch: false,
    culture: 'en',
    date: new Date(),
    edit: false,
    editEventObj: {},
    eventType: 'Event',
    eventsData: [],
    loader: true,
    loggedUser: {} as UserType,
    showAddEventPopup: false
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    let res
    if (user.role !== 'GUEST') {
      res = await getTenantDetails(user.tenant)
      if (res && res.calenderWeek !== 'Sunday') {
        this.setState({ culture: 'en-GB' })
      }
    }
    const response = await getEvents()
    const listView = await getMyTasks(user._id)
    if (response && listView) {
      this.setState({
        loader: false
      })
    }
    const newData = response.map((dataItem: any) => {
      const startDateTime = moment(moment(dataItem.startDate).format('L') + ' ' + dataItem.startTime)
      const endDateTime = moment(moment(dataItem.endDate).format('L') + ' ' + dataItem.endTime)
      return {
        ...dataItem,
        _id: dataItem._id,
        allDay: false,
        category: 'Event',
        color: this.getRandomColors(),
        date: moment(dataItem.createdAt).format('ll'),
        description: dataItem.description,
        end: new Date(
          endDateTime.year(),
          endDateTime.month(),
          endDateTime.date(),
          endDateTime.hour(),
          endDateTime.minute(),
          endDateTime.second()
        ),
        phone:
          dataItem.createdBy && dataItem.createdBy.phones && dataItem.createdBy.phones.length > 0
            ? dataItem.createdBy.phones[0].value
            : null,
        sharedWith: dataItem.sharedWith,
        start: new Date(
          startDateTime.year(),
          startDateTime.month(),
          startDateTime.date(),
          startDateTime.hour(),
          startDateTime.minute(),
          startDateTime.second()
        ),
        title: dataItem.name
      }
    })

    const taskListViewFilter = listView.filter((list: any) => {
      return list.status !== 'Completed'
    })
    const newTaskData = taskListViewFilter.map((dataItem: any) => {
      const startDateTime = moment(moment(dataItem.dueDate).format('L') + ' ' + moment(dataItem.taskTime).format('LT'))
      const endDateTime = moment(moment(dataItem.dueDate).format('L') + ' ' + moment(dataItem.taskTime).format('LT'))
      return {
        ...dataItem,
        _id: dataItem._id,
        allDay: false,
        category: 'Task',
        color: this.getRandomColors(),
        date: moment().format('ll'),
        description: dataItem.description,
        end: new Date(
          endDateTime.year(),
          endDateTime.month(),
          endDateTime.date(),
          endDateTime.hour(),
          endDateTime.minute(),
          endDateTime.second()
        ),
        phone:
          dataItem.createdBy && dataItem.createdBy.phones && dataItem.createdBy.phones.length > 0
            ? dataItem.createdBy.phones[0].value
            : null,
        sharedWith: dataItem.sharedWith,
        start: new Date(
          startDateTime.year(),
          startDateTime.month(),
          startDateTime.date(),
          startDateTime.hour(),
          startDateTime.minute(),
          startDateTime.second()
        ),
        title: dataItem.name,
        type: dataItem.type
      }
    })

    const dataset = newData.concat(newTaskData)
    this.setState({ eventsData: dataset, loggedUser: user })
  }

  public render() {
    const {
      calendarSwitch,
      date,
      eventsData,
      showAddEventPopup,
      addEventData,
      edit,
      editEventObj,
      eventType,
      loader,
      culture
    } = this.state
    const formats = {
      eventTimeRangeFormat: ({ start, end }: any, culture: any, localizer: any) => {
        return localizer.format(start, 'LT', culture) + ' — ' + localizer.format(end, 'LT', culture)
      },
      timeGutterFormat: (dates: any, culture: any, localizer: any) => {
        return localizer.format(dates, 'LT', culture)
      }
    }

    return (
      <Wrapper>
        {loader && (
          <Dimmer active={true} inverted={true}>
            <SpinningBubbles type={'spinningBubbles'} color={Colors.DarkBlue200} />
          </Dimmer>
        )}
        {showAddEventPopup &&
          eventType === 'Event' && (
            <AddEventModal
              calendarSwitch={calendarSwitch}
              closeModal={this.closeModal}
              addEvent={this.addEvent}
              selectedData={addEventData}
              edit={edit}
              editEventObj={editEventObj}
            />
          )}
        {showAddEventPopup &&
          eventType === 'Task' && (
            <AddTasksModal
              eventCalender={true}
              closeModal={this.closeModal}
              addWorkflowTask={this.addTask}
              edit={edit}
              editDetailObj={editEventObj}
            />
          )}
        <BigCalendar
          dayPropGetter={this.customDayPropGetter}
          eventPropGetter={this.customeventPropGetter}
          date={date}
          events={eventsData}
          culture={culture}
          formats={formats}
          step={15}
          localizer={localizers}
          defaultDate={new Date()}
          defaultView={Views.MONTH}
          views={allViews}
          view={calendarSwitch ? Views.WEEK : Views.MONTH}
          selectable={true}
          popup={true}
          onSelectSlot={this.addSlotEvent}
          components={{
            event: this.event,
            toolbar: this.CustomCalendarToolBar
          }}
        />
      </Wrapper>
    )
  }

  private closeModal = () => {
    this.setState({ showAddEventPopup: false, eventType: 'Event' })
  }

  private addEvent = async (event: any) => {
    const user: any = await getLoggedInUser({ fromCache: true })
    const { eventsData, edit }: any = this.state
    const response = await createEvent(event, edit)
    let data: any = {}
    if (user.role !== 'GUEST') {
      if (edit) {
        data = response.data.updateEvent
        const index = eventsData.findIndex((events: any) => events._id === event._id)
        eventsData.splice(index, 1)
      } else {
        data = response.data.createEvent
      }
      const startDateTime = moment(moment(data.startDate).format('L') + ' ' + data.startTime)
      const endDateTime = moment(moment(data.endDate).format('L') + ' ' + data.endTime)
      eventsData.push({
        ...data,
        _id: data._id,
        category: 'Event',
        color: this.getRandomColors(),
        date: moment(data.createdAt).format('ll'),
        description: data.description,
        end: new Date(
          endDateTime.year(),
          endDateTime.month(),
          endDateTime.date(),
          endDateTime.hour(),
          endDateTime.minute(),
          endDateTime.second()
        ),
        phone:
          data.createdBy && data.createdBy.phones && data.createdBy.phones.length > 0
            ? data.createdBy.phones[0].value
            : null,
        sharedWith: data.sharedWith,
        start: new Date(
          startDateTime.year(),
          startDateTime.month(),
          startDateTime.date(),
          startDateTime.hour(),
          startDateTime.minute(),
          startDateTime.second()
        ),
        title: data.name
      })
      this.setState({ eventsData })
    } else {
      Toast({
        message:
          'You are not authorized to create an event. Please contact your administrator if you need additional assistance',
        type: 'error'
      })
    }
  }

  private addSlotEvent = async (slot: any) => {
    const user: any = await getLoggedInUser({ fromCache: true })
    if (user.role !== 'GUEST') {
      if (moment(moment(slot.start).format('YYYY-MM-DD')).isSameOrAfter(moment().format('YYYY-MM-DD'))) {
        this.setState({ showAddEventPopup: true, addEventData: slot, edit: false, editEventObj: {} })
      } else {
        Toast({ message: 'Cannot create Events for Dates Passed', type: 'error' })
      }
    } else {
      Toast({
        message:
          'You are not authorized to create an event. Please contact your administrator if you need additional assistance',
        type: 'error'
      })
    }
  }

  private event = ({ event }: any) => (
    <CalendarPopup
      data={event}
      editEvent={this.editEvent}
      deleteEvent={this.deleteEvent}
      user={this.state.loggedUser}
    />
  )

  private addTask = async (newTask: any) => {
    const { loggedUser, eventsData, edit }: any = this.state
    const taskBoards = await getMyTaskBoards(loggedUser[`_id`])
    const newBoardId = taskBoards.filter((board: any) => {
      return board.name === 'New Task'
    })
    const response: any = await createTask(newTask, '0', newBoardId._id, 'kanban', loggedUser, edit, false)
    let data: any = {}
    let transaction: any = {}
    if (edit) {
      data = response.data.updateTask
      const transactionData = eventsData.find((task: any) => task._id === data._id)
      transaction = transactionData.transaction
      const index = eventsData.findIndex((task: any) => task._id === data._id)
      eventsData.splice(index, 1)
    } else {
      data = response
    }
    const startDateTime = moment(data.dueDate)
    eventsData.push({
      ...data,
      _id: data._id,
      allDay: false,
      category: 'Task',
      color: this.getRandomColors(),
      date: moment().format('ll'),
      description: data.description,
      end: new Date(
        startDateTime.year(),
        startDateTime.month(),
        startDateTime.date(),
        startDateTime.hour(),
        startDateTime.minute(),
        startDateTime.second()
      ),
      phone:
        data.createdBy && data.createdBy.phones && data.createdBy.phones.length > 0
          ? data.createdBy.phones[0].value
          : null,
      sharedWith: data.sharedWith,
      start: new Date(
        startDateTime.year(),
        startDateTime.month(),
        startDateTime.date(),
        startDateTime.hour(),
        startDateTime.minute(),
        startDateTime.second()
      ),
      title: data.name,
      transaction: transaction,
      type: data.type
    })
    this.setState({ eventsData })
  }

  private editEvent = (event: any) => {
    this.setState({ edit: true, editEventObj: event, showAddEventPopup: true, eventType: event.category })
  }

  private deleteEvent = async (data: any) => {
    const { eventsData } = this.state
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text:
        data.category === 'Task'
          ? 'You will not be able to recover this task!'
          : 'You will not be able to recover this event!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        if (data.category === 'Task') {
          await deleteTasks(data._id)
          const feeData = eventsData.slice()
          const index = feeData.findIndex((events: any) => events._id === data._id)
          feeData.splice(index, 1)
          this.setState({ eventsData: feeData })
        } else {
          await deleteEvent(data._id)
          const feeData = eventsData.slice()
          const index = feeData.findIndex((events: any) => events._id === data._id)
          feeData.splice(index, 1)
          this.setState({ eventsData: feeData })
        }
        ConfirmAlert(
          'Deleted!',
          data.category === 'Task' ? 'Your task has been deleted.' : 'Your event has been deleted.',
          'success'
        )
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', data.category === 'Task' ? 'Your task is safe' : 'Your event is safe', 'error')
      }
    })
  }

  private switchHandle = (status: boolean) => {
    this.setState({ calendarSwitch: status })
  }

  private changeDateHandle = (date: Date) => {
    this.setState({ date })
  }

  private CustomCalendarToolBar = () => (
    <CalendarHeader
      onSwitch={this.switchHandle}
      onChangeDate={this.changeDateHandle}
      calendarSwitch={this.state.calendarSwitch}
    />
  )

  private customDayPropGetter = (date: Date) => {
    const index = this.state.eventsData.findIndex((event: any) => event.start.getTime() === date.getTime())
    if (index > -1) {
      return {
        className: 'special-day'
      }
    }
    return {}
  }

  private getRandomColors = () => {
    return colors[Math.floor(Math.random() * colors.length)]
  }

  private customeventPropGetter = (event: ExtendedEvent) => {
    return {
      style: {
        borderLeft: `4px solid ${event.color}`
      }
    }
  }
}

export default CalendarPanel
