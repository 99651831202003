// Import Packages
import * as React from 'react'

import AddressInput from 'shared/AddressInput'
import { default as MlsSearchInput } from 'shared/Mls/SearchInput'
import StateDropdown from 'shared/StateDropdown'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'
import { MlsType } from 'shared/Mls/Types'

import { Container, Row, StyledForm, StyledInput, Title } from './Styled'

interface Props {
  form: any
  handleAddressChange: (address: AddressType) => void
  handleInputChange: (name: string, event: React.ChangeEvent<HTMLInputElement>) => void
  handleDropdownChange: (event: React.SyntheticEvent, data: any) => void
  handleMlsChange: (id: string, mlsData: MlsType) => void
  tenantType: string
}

class TenantForm extends React.Component<Props, {}> {
  public render() {
    const {
      form,
      form: { errors }
    } = this.props

    return (
      <Container>
        <Title>Address</Title>
        <StyledForm>
          <StyledInput
            placeholder={this.getTenantLabel()}
            name="name"
            value={form.name}
            onChange={this.handleInputChange}
            error={!!errors[`name`]}
          />
          <StyledInput
            placeholder="Domain Name"
            name="domain"
            label=".realtypass.com"
            labelPosition="right"
            fluid={true}
            value={form.domain}
            onChange={this.handleInputChange}
            error={!!errors[`domain`]}
          />
          <Row>
            <AddressInput
              address={form as AddressType}
              name="streetName"
              placeholder="Street Address"
              onChange={this.updateAddress}
              error={!!errors[`streetName`]}
            />
          </Row>
          <Row>
            <AddressInput
              address={form as AddressType}
              mode={AddressModeEnum.City}
              name="city"
              placeholder="City"
              onChange={this.updateCity}
              error={!!errors[`city`]}
            />
            <StateDropdown
              placeholder="State"
              value={form.state}
              onChange={this.handleDropdownChange}
              error={!!errors[`state`]}
            />
          </Row>
          <Row>
            <StyledInput
              className="zipcode"
              type="text"
              placeholder="Zip Code"
              name="zipCode"
              value={form.zipCode}
              onChange={this.handleInputChange}
              error={!!errors[`zipCode`]}
            />
            <MlsSearchInput placeholder="MLS" state={form.state} value={form.mls} onUpdate={this.handleMlsChange} />
          </Row>
        </StyledForm>
      </Container>
    )
  }

  private getTenantLabel = () => {
    const { tenantType } = this.props
    switch (tenantType) {
      case 'INDIVIDUAL':
        return 'Name'
      case 'TEAM':
        return 'Team Name'
      default:
        return 'Company Name'
    }
  }

  private updateAddress = (address: AddressType) => {
    const { handleAddressChange } = this.props
    handleAddressChange(address)
  }

  private updateCity = (address: AddressType) => {
    const { form, handleAddressChange } = this.props
    const update = {
      ...(form as AddressType),
      city: address.city,
      state: address.state
    }
    handleAddressChange(update)
  }

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleInputChange } = this.props
    const name = e.target.name
    handleInputChange(name, e)
  }

  private handleMlsChange = (value: string, mlsData: MlsType) => {
    const { handleMlsChange } = this.props
    handleMlsChange(value, mlsData)
  }

  private handleDropdownChange = (e: any, data: any) => {
    const { handleDropdownChange } = this.props
    handleDropdownChange(e, data)
  }
}

export default TenantForm
