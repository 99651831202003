// Import Packages
import * as React from 'react'
import { Component } from 'react'
import { Motion, spring } from 'react-motion'
import ConfirmAlert from 'sweetalert2'

// Import Components
import Property from './Property'

// Import Utils
import { generateID, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  ActionImageWrapper,
  ActionsWrapper,
  CategoryHeader,
  CategoryNameText,
  CategoryWrapper,
  ImageWrapper,
  StyledItem
} from './Styled'

// Font Awesome Icons
import {
  faMinus,
  faPencilAlt,
  faPlus,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createChecklistCategory, deleteChecklistCategory, reorderDocCheckList } from 'app/Settings/ChecklistManager/ChecklistManagerMutation'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus, faMinus, faPencilAlt, faTrashAlt])

// Dragger Util

function clamp(n: number, min: number, max: number) {
  return Math.max(Math.min(n, max), min)
}

const springConfig = { stiffness: 300, damping: 50 }

// Component
interface PropertyItem {
  name: string
  value: object
}
interface Props {
  category: {
    _id: string
    isLocked: boolean
    name: string
    property: PropertyItem[]
  }
  mls: string
  documents: Array<{
    id: string,
    name: string
  }>
  index: number
  categories: any
  onUpdateHeight: () => void
  addProperty: (categoryIndex: number) => void
  changeChecklistValue: (categoryIndex: number, documentId: string, newValue: boolean, propertyIndex: number) => void
  removeProperty: (categoryIndex: number, propertyIndex: number) => void
  handleEditPropertyClick: (toggle: boolean, categoryIndex?: number, propertyIndex?: number, name?: string, id?: string, categoryName?: string) => void
  handleAddClick: (res: any) => void
  handleEditClick: (toggle: boolean, categoryIndex?: number, name?: string, id?: string) => void
  handleRemoveClick: (categoryIndex: number) => void
  handleAddPropertyClick: (toggle: boolean, id?: string, index?: number, name?: string) => void
  reinsertChecklistProperty: (categoryIndex: number, fromIndex: number, toIndex: number) => void
}

interface State {
  isOpen: boolean
  topDeltaY: number
  mouseY: number
  isPressed: boolean
  originalPosOfLastPressed: PropertyItem,
}
class Category extends Component<Props, State> {
  public state = {
    isOpen: false,
    isPressed: false,
    mouseY: 0,
    originalPosOfLastPressed: {
      name: '',
      value: {}
    },
    topDeltaY: 0
  }

  public componentDidMount() {
    this.setState({ originalPosOfLastPressed: this.props.category.property[0] })
    window.addEventListener('touchmove', this.handleTouchMove)
    window.addEventListener('touchend', this.handleMouseUp)
    window.addEventListener('mousemove', this.handleMouseMove)
    window.addEventListener('mouseup', this.handleMouseUp)
  }

  public handleTouchStart = (key: number, pressLocation: number, e: TouchEvent) => {
    this.handleMouseDown(key, pressLocation, e.touches[0])
  }

  public handleTouchMove = (e: TouchEvent) => {
    e.preventDefault()
    this.handleMouseMove(e.touches[0])
  }

  public handleMouseDown = (pos: number, pressY: number, { pageY }: { pageY: number }) => {
    this.setState({
      isPressed: true,
      mouseY: pressY,
      originalPosOfLastPressed: this.props.category.property[pos],
      topDeltaY: pageY - pressY
    })
  }

  public handleMouseMove = ({ pageY }: { pageY: number }) => {
    const { isPressed, topDeltaY, originalPosOfLastPressed } = this.state
    const { reinsertChecklistProperty, index: categoryIndex, category, categories } = this.props
    if (isPressed) {
      const mouseY: number = pageY - topDeltaY
      const currentRow: number = clamp(Math.round(mouseY / 45), 0, category.property.length - 2)
      const index = category.property.findIndex((item) => {
        return Object.is(item, originalPosOfLastPressed)
      })
      if (currentRow !== index) {
        reinsertChecklistProperty(categoryIndex, index, currentRow)
        const checkList = categories[categoryIndex]
        const { property } = checkList
        const items = property.filter((data: any) => {
          return data.name !== '+ Add Property Type'
        })
        const sortedData: any = []
        items.forEach((item: any, i: number) => {
          sortedData.push({
            _id: item._id,
            order: i + 1
          })
        })
        reorderDocCheckList(sortedData)
      }

      this.setState({ mouseY })
    }
  }

  public handleMouseUp = () => {
    this.setState({ isPressed: false, topDeltaY: 0 })
  }

  public toggleItemOpen = (toggle: boolean) => {
    this.setState({ isOpen: toggle }, () => this.props.onUpdateHeight())
  }

  public handleClick = async () => {
    if (this.props.category.name === Strings.settings.addCategory) {
      const { mls } = this.props
      const categoryName = `New Category ${generateID()}`
      const res = await createChecklistCategory(categoryName, mls)
      this.props.handleAddClick(res)
    }
  }

  public handleRemoveCategory = () => {
    const { category, index: categoryIndex, handleRemoveClick } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.settings.taxSetup.recoverCategory,
      title: Strings.kanbanView.sure,
      type: 'warning',
    }).then((result) => {
      if (result.value) {
        deleteChecklistCategory(category._id)
        handleRemoveClick(categoryIndex)
        ConfirmAlert(
          Strings.kanbanView.deleted,
          Strings.settings.taxSetup.categoryDeleted,
          'success'
        )
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(
          Strings.kanbanView.cancelled,
          Strings.settings.taxSetup.categorySafed,
          'error'
        )
      }
    })
  }

  public addNewProperty = () => {
    const { addProperty, index } = this.props
    addProperty(index)
  }

  public render() {
    const { category, index: categoryIndex, documents, changeChecklistValue, handleAddPropertyClick, handleEditPropertyClick } = this.props
    const { isOpen, mouseY, isPressed, originalPosOfLastPressed } = this.state
    return (
      <CategoryWrapper {...(isOpen ? { propertynumber: category.property.length > 0 ? category.property.length : 1 } : {})}>
        <CategoryHeader totalindex={categoryIndex} onClick={this.handleClick.bind({})} horizontal={true}>
          {category.name !== Strings.settings.addCategory ? (
            !isOpen
              ? (<ImageWrapper onClick={this.toggleItemOpen.bind({}, true)}>
                <FontAwesomeIcon icon={['fal', 'plus']} />
              </ImageWrapper>)
              : (<ImageWrapper onClick={this.toggleItemOpen.bind({}, false)} isMinus={isOpen}>
                <FontAwesomeIcon icon={['fal', 'minus']} />
              </ImageWrapper>)
          ) : (<ImageWrapper onClick={this.toggleItemOpen.bind({}, true)}>
            <FontAwesomeIcon icon={['fal', 'plus']} />
          </ImageWrapper>)
          }
          <CategoryNameText>
            {
              category.name !== Strings.settings.addCategory ? category.name :
                <span style={{ textDecoration: 'underline', color: Colors.DarkBlue200 }}>{Strings.settings.Category}</span>
            }
          </CategoryNameText>
          <ActionsWrapper ishidden={category.isLocked}>
            <ActionImageWrapper onClick={this.props.handleEditClick.bind({}, true, categoryIndex, category.name, category._id)}>
              <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
            </ActionImageWrapper>
            <ActionImageWrapper onClick={this.handleRemoveCategory.bind({})}>
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </ActionImageWrapper>
          </ActionsWrapper>
        </CategoryHeader>
        {isOpen &&
          category.property.map((property: PropertyItem, index) => {
            const style = Object.is(originalPosOfLastPressed, property) && isPressed
              ? {
                scale: spring(1.01, springConfig),
                shadow: spring(16, springConfig),
                y: mouseY,
              }
              : {
                scale: spring(1, springConfig),
                shadow: spring(1, springConfig),
                y: spring(index * 45, springConfig),
              }
            return <Motion style={style} key={index}>
              {({ scale, shadow, y }) =>
                <StyledItem
                  className="demo8-item"
                  style={{
                    WebkitTransform: `translate3d(0, ${y}px, 0) scale(${scale})`,
                    boxShadow: `rgba(0, 0, 0, 0.2) 0px ${shadow}px ${2 * shadow}px 0px`,
                    transform: `translate3d(0, ${y}px, 0) scale(${scale})`,
                    zIndex: (property === originalPosOfLastPressed) ? 9999 : index,
                  }}>
                  <Property
                    categoryIndex={categoryIndex}
                    categoryId={category._id}
                    categoryName={category.name}
                    index={index}
                    key={index}
                    propertyId={property[`_id`]}
                    name={property.name}
                    handleEditClick={handleEditPropertyClick}
                    handleAddPropertyClick={handleAddPropertyClick}
                    removeProperty={this.props.removeProperty}
                    totalindex={index}
                    values={property.value}
                    documents={documents}
                    addProperty={this.addNewProperty}
                    changeChecklistValue={changeChecklistValue}
                    y={y}
                    mouseDownCallback={this.handleMouseDown}
                    touchStartCallback={this.handleTouchStart} />
                </StyledItem>
              }
            </Motion>
          })}
      </CategoryWrapper>
    )
  }
}

export default Category
