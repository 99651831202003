// Import Packages
import Slider from 'react-slick'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background: string
}

const Container = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;

  :focus {
    outline: none;
  }
`

const Profile = styled.div`
  .ui.small.image {
    width: 80px;
    border: 3px solid ${Colors.White540};
    border-radius: 50%;
  }
`

const UserName = styled.div`
  margin-top: 5px;
  font-weight: 600;
  color: ${Colors.Black500};
  font-size: 14px;
  text-transform:capitalize;
`

const InvitationDate = styled.div`
  color: ${Colors.Black550};
  font-size: 10px;
`

const TeamAgreement = styled.div`
  display: flex;
  color: ${Colors.Black550};
  font-size: 10px;
  margin-top: 15px;
  cursor: pointer;

  span {
    font-weight: 600;
  }

  i.icon {
    margin-left: 10px;
    font-size: 12px;
    color: ${Colors.DarkBlue200};
  }
`

const ContainerWrapper = styled.div`
  display: flex;
  margin-top: 25px;
  flex: 1;
  width: 100%;
`

const Free = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  background: ${Colors.White530};
  text-align: center;
  color: ${Colors.Black500};
  flex: 0.5;

  div:nth-child(1) {
    font-size: 10px;
    font-weight: 600;
  }

  div:nth-child(2) {
    margin-top: 6px;
    font-weight: 600;
  }

  i.icon {
    font-size: 12px;
    margin-right: -2px;
  }
`

const Commission = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  background: ${Colors.DarkBlue200};
  color: ${Colors.White1000};
  text-align: center;
  flex: 0.5;
  opacity: 0.5;

  div:nth-child(1) {
    font-size: 10px;
  }

  div:nth-child(2) {
    margin-top: 6px;
    font-weight: 600;
  }

  i.icon {
    font-size: 10px;
  }
`

const SliderContainer = styled.div`
  .slick-dots li button:before {
    content: '';
    background: url(${(props: Props) => props.background || props.background});
    width: 10px;
    height: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .slick-dots li {
    height: 0px;
    width: 3px;
  }
`

const StyledSlider = styled(Slider)`
  &.slick-slider {
    width: 70%;
    margin: 0 auto;
  }

  .slick-next {
    display: none !important;
  }

  .slick-prev {
    display: none !important;
  }
`

const StyledGroup = styled(Form.Group)`
  display: flex;
  margin-left: 6px;

  .ui.basic.button, .ui.basic.buttons .button {
    width: 95px;
    margin-top: 25px;
    border-radius: 4%;
    background: ${Colors.DarkBlue200} !important;
    color: ${Colors.White1000} !important;
    font-weight: 600;
  }
`

export {
  Commission,
  Container,
  ContainerWrapper,
  Free,
  InvitationDate,
  Profile,
  SliderContainer,
  StyledSlider,
  TeamAgreement,
  UserName,
  StyledGroup
}
