import * as React from 'react'

import ImageEditor from 'shared/ImageEditor'
import QuillInline from 'shared/QuillInline'

import {
  Address,
  CallOut,
  Category,
  Col,
  CompanyLogo,
  Container,
  Description,
  Details,
  Header,
  Hero,
  Image,
  ImageCol,
  ImageRow,
  Images,
  Info,
  LeftRP,
  Line,
  Logo,
  MLSId,
  Owner,
  OwnerDetail,
  OwnerImage,
  Price,
  RightRP,
  Row,
  Text
} from './Styled'

import { ImageType as PropertyImageType } from 'app/Transactions/Details/Types'
import { CategoryEnum } from 'app/Transactions/Flyer/Types'
import { ImageType as EditorImageType } from 'shared/ImageEditor/Types'
import { DataType, InitType, TemplateEnumType } from './Types'

import { Strings } from 'utils'

interface Props {
  data: Partial<DataType>
  init: Partial<InitType>
  isPaid: boolean
  subCategoryName: string
  templateType: TemplateEnumType
  onUpdate: (data: Partial<DataType>) => void
  onReplaceImage: (item: string) => void
}

interface State {
  address: string
  companyLogo: EditorImageType
  ownerDetails: string
  price: string
  profileImage: EditorImageType
}

const DEFAULT_IMAGE = { url: '' } as EditorImageType
const DEFAULT_TEXT = ''

class Template3 extends React.Component<Props, State> {
  public state = {
    address: '',
    companyLogo: {} as EditorImageType,
    ownerDetails: '',
    price: '',
    profileImage: {} as EditorImageType
  }

  public componentDidMount = () => {
    const { init, onUpdate } = this.props

    const data = {
      image1: DEFAULT_IMAGE,
      image2: DEFAULT_IMAGE,
      image3: DEFAULT_IMAGE,
      image4: DEFAULT_IMAGE,
      image5: DEFAULT_IMAGE,
      image6: DEFAULT_IMAGE,
      text1: DEFAULT_TEXT
    } as DataType

    if (init.address) {
      data.text1 = `
        <p><span style="font-size: 16pt;">${init.description ||
          'Insert text here...'}</span></p>
      `
      this.setState({
        address: `
        <p>
          <strong style="font-size: 18pt; color: #fff; text-transform: capitalize;">${
            init.address.streetNumber
          } ${init.address.streetName}</strong>
        </p>
        <p>
          <strong style="font-size: 18pt; color: #fff; text-transform: capitalize;">${
            init.address.city
          }, ${init.address.state} ${init.address.zipCode}</strong>
        </p>
      `,
        price: `
          <span style="font-size: 28pt; color: #fff;">$${init.price &&
            init.price.toLocaleString()}</span>
      `
      })
    }
    if (init.owner) {
      this.setState({
        ownerDetails: `
          <p>
            <span style="font-size: 18pt; font-weight: bold; color: #fff; text-transform: capitalize;">${
              init.owner.firstName
            } ${init.owner.lastName}</span>
          </p>
          <p>
            <span style="font-size: 16pt; color: #fff;">${init.owner.phones &&
              init.owner.phones[0].value}</span>
          </p>
          <p>
            <span style="font-size: 16pt; color: #fff;">${
              init.owner.userName
            }</span>
          </p>
        `
      })
    }
    if (init.images) {
      init.images.forEach((image: PropertyImageType, index: number) => {
        if (index < 6) {
          data[`image${index + 1}`] = { url: image.url }
        }
      })
    }
    onUpdate(data)

    this.setState({
      companyLogo: { url: init.companyLogo && init.companyLogo },
      profileImage: { url: init.owner && init.owner.profileImage }
    })
  }

  public render() {
    const { data, init, isPaid, templateType, subCategoryName } = this.props

    const {
      address,
      companyLogo,
      ownerDetails,
      price,
      profileImage
    } = this.state

    return (
      <Container>
        <Header>
          <Line style={{ marginLeft: 6, marginRight: 30 }} />
          <LeftRP />
          <Category>
            {subCategoryName === 'All'
              ? CategoryEnum.PriceReduced
              : subCategoryName}
          </Category>
          <RightRP />
          <Line style={{ marginRight: 6, marginLeft: 30 }} />
        </Header>
        <Info>
          <Address>
            <Text
              dangerouslySetInnerHTML={{ __html: address || DEFAULT_TEXT }}
            />
          </Address>
          <Price>
            <Text dangerouslySetInnerHTML={{ __html: price || DEFAULT_TEXT }} />
          </Price>
        </Info>
        <Hero>
          <ImageRow>
            <ImageEditor
              image={data.image1 || DEFAULT_IMAGE}
              onChange={(image: EditorImageType) =>
                this.handleImageChange('image1', image)
              }
              onClick={() => this.handleImageClick('image1')}
            />
          </ImageRow>
          <ImageCol>
            <Col>
              <Images>
                <ImageEditor
                  image={data.image2 || DEFAULT_IMAGE}
                  onChange={(image: EditorImageType) =>
                    this.handleImageChange('image2', image)
                  }
                  onClick={() => this.handleImageClick('image2')}
                />
              </Images>
              <Images>
                <ImageEditor
                  image={data.image3 || DEFAULT_IMAGE}
                  onChange={(image: EditorImageType) =>
                    this.handleImageChange('image3', image)
                  }
                  onClick={() => this.handleImageClick('image3')}
                />
              </Images>
            </Col>
          </ImageCol>
        </Hero>
        <Details>
          <Row>
            <Image>
              <ImageEditor
                image={data.image4 || DEFAULT_IMAGE}
                onChange={(image: EditorImageType) =>
                  this.handleImageChange('image4', image)
                }
                onClick={() => this.handleImageClick('image4')}
              />
            </Image>
            <Image>
              <ImageEditor
                image={data.image5 || DEFAULT_IMAGE}
                onChange={(image: EditorImageType) =>
                  this.handleImageChange('image5', image)
                }
                onClick={() => this.handleImageClick('image5')}
              />
            </Image>
            <Image>
              <ImageEditor
                image={data.image6 || DEFAULT_IMAGE}
                onChange={(image: EditorImageType) =>
                  this.handleImageChange('image6', image)
                }
                onClick={() => this.handleImageClick('image6')}
              />
            </Image>
          </Row>
        </Details>
        <CallOut>
          <Description>
            <QuillInline
              isPaid={isPaid}
              templateType={templateType}
              html={data.text1 || DEFAULT_TEXT}
              onChange={(content: any, delta: any, source: any, editor: any) =>
                this.handleTextChange('text1', content)
              }
            />
          </Description>
          <Owner>
            <OwnerImage>
              <ImageEditor
                image={profileImage || DEFAULT_IMAGE}
                onChange={(image: EditorImageType) =>
                  this.handleImageChange('profileImage', image)
                }
                onClick={() => ''}
              />
            </OwnerImage>
            <OwnerDetail>
              <Text
                dangerouslySetInnerHTML={{
                  __html: ownerDetails || DEFAULT_TEXT
                }}
              />
            </OwnerDetail>
          </Owner>
        </CallOut>
        <CompanyLogo>
          <MLSId>
            {Strings.flyer.listing} {init.mlsId ? init.mlsId : '-'}{' '}
            {Strings.flyer.courtesy}
          </MLSId>
          <Logo>
            <ImageEditor
              image={companyLogo || DEFAULT_IMAGE}
              onChange={(image: EditorImageType) =>
                this.handleImageChange('companyLogo', image)
              }
              onClick={() => ''}
            />
          </Logo>
        </CompanyLogo>
      </Container>
    )
  }

  private handleTextChange = (item: string, content: string) => {
    const { data, onUpdate } = this.props

    const update = { ...data }
    update[item] = content
    onUpdate(update)
  }

  private handleImageChange = (item: string, image: EditorImageType) => {
    const { data, onUpdate } = this.props

    const update = { ...data }
    update[item] = image
    onUpdate(update)

    if (item === 'companyLogo') {
      this.setState({
        companyLogo: image
      })
    } else if (item === 'profileImage') {
      this.setState({
        profileImage: image
      })
    } else {
      return
    }
  }

  private handleImageClick = async (item: string) => {
    const { onReplaceImage } = this.props
    onReplaceImage(item)
  }
}

export default Template3
