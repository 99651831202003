// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Icon } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import { DeleteListTask, editTask, loggedUser, ShowViewDetails, SingleCheckBox, ToggleCheckBox } from './Tasks'

import { TaskItemsDetails } from 'store/Tasks/Types'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Address,
  CheckboxWrapper,
  DropDownPopup,
  Due,
  EventDescription,
  Labels,
  Li,
  MemberProfile,
  Notes,
  Profile,
  Status,
  StyledCheckbox,
  StyledDropdown,
  StyledDropdownItem,
  StyledPopup,
  Task
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faPhone, faSms } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone, faSms])

let checked = false

export const Checked = (data: boolean) => {
  checked = !data
}

export const CustomHeaderCheckBox = () => {
  const onChange = () => {
    ToggleCheckBox()
  }
  return (
    <CheckboxWrapper>
      <StyledCheckbox checked={checked} onClick={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomCheckBox = ({ rowData }: { rowData: TaskItemsDetails }) => {
  const onChange = () => {
    SingleCheckBox(rowData._id)
  }
  return (
    <CheckboxWrapper>
      <StyledCheckbox checked={rowData.checked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomTask = ({ rowData }: { rowData: TaskItemsDetails }) => {
  let backgroundIcon: any
  let backgroundProgress = ''
  switch (rowData.type) {
    case 'Email':
      backgroundIcon = 'envelope'
      backgroundProgress = Colors.Red25
      break
    case 'Call':
      backgroundIcon = 'phone'
      backgroundProgress = Colors.Blue50
      break
    case 'Meeting':
      backgroundIcon = 'handshake'
      backgroundProgress = Colors.Purple50
      break
    case 'Sms':
      backgroundIcon = 'sms'
      backgroundProgress = Colors.Pink70
      break
    case 'Pass':
      backgroundIcon = 'ticket'
      backgroundProgress = Colors.Orange110
      break
  }
  return (
    <div style={{ display: 'flex' }}>
      <FontAwesomeIcon icon={['fal', backgroundIcon]} style={{ color: backgroundProgress }} />
      <Task>{rowData.name}</Task>
    </div>
  )
}

export const CustomRelatedTo = ({ rowData }: { rowData: TaskItemsDetails }) => {
  let toggle = true
  if (rowData.lead) {
    rowData.lead.shareWith.forEach((item: any) => {
      if (item._id === loggedUser._id) {
        toggle = false
      }
    })
  }
  return rowData.lead && Object.keys(rowData.lead).length > 0 ? (
    <Link
      to={
        toggle
          ? loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER'
            ? Routes.leads.root + Routes.leads.details.path + `/${rowData.lead._id}?pool=1`
            : ''
          : Routes.leads.root + Routes.leads.details.path + `/${rowData.lead._id}?pool=0`
      }
    >
      <div style={{ color: Colors.Black500, textTransform: 'capitalize' }}>
        {rowData.lead.firstName} {rowData.lead.lastName}
      </div>
    </Link>
  ) : rowData.transaction && Object.keys(rowData.transaction).length > 0 ? (
    <Link to={Routes.transactions.root + `/${rowData.transaction._id}` + Routes.transactions.documents.path}>
      <EventDescription>
        {rowData.transaction &&
          rowData.transaction.owner &&
          `${rowData.transaction.owner.firstName} ${rowData.transaction.owner.lastName}`}
      </EventDescription>
      <div style={{ textTransform: 'capitalize' }}>
        <Address>
          {rowData.transaction.propertyId && rowData.transaction.propertyId.address
            ? `${rowData.transaction.propertyId.address.streetNumber} ${
            rowData.transaction.propertyId.address.streetName
            }`
            : 'No Address Found'}
        </Address>
        <Address>
          {rowData.transaction.propertyId && rowData.transaction.propertyId.address
            ? `${rowData.transaction.propertyId.address.city}, ${rowData.transaction.propertyId.address.state} ${
            rowData.transaction.propertyId.address.zipCode
            }`
            : 'No Address Found'}
        </Address>
      </div>
    </Link>
  ) : (
        <div>Not Related Yet</div>
      )
}

export const CustomSharedWith = ({ rowData }: { rowData: TaskItemsDetails }) => {
  return (
    <Profile>
      {rowData.sharedWith.map((items: any, index: number) => {
        while (index < 3) {
          return (
            <Li key={index}>
              <StyledPopup
                trigger={<MemberProfile key={index} background={items.profileImage} />}
                content={`${items.firstName} ${items.lastName}`}
              />
            </Li>
          )
        }
        return null
      })}
      {rowData.sharedWith.length > 3 ? (
        <Labels>
          <StyledPopup trigger={<div>...</div>} content={`+${rowData.sharedWith.length - 3}`} />
        </Labels>
      ) : null}
    </Profile>
  )
}

export const CustomDue = ({ rowData }: { rowData: TaskItemsDetails }) => {
  const dateTime = moment(moment(rowData.dueDate).format('L') + ' ' + moment(rowData.taskTime).format('LTS'))

  return (
    <Due
      background={
        moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed'
          ? Colors.Red810
          : Colors.transparent
      }
      color={
        moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed'
          ? Colors.White1000
          : Colors.Black500
      }
    >
      {moment(rowData.dueDate).format('L') === 'Invalid date' ? '-' : moment(rowData.dueDate).format('L')}
    </Due>
  )
}

export const CustomActions = ({ rowData }: { rowData: TaskItemsDetails }) => {
  const showViewDetails = () => {
    ShowViewDetails(rowData._id)
  }
  const showNotes = () => {
    ShowViewDetails(rowData._id, 1)
  }
  const deleteTask = () => {
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'Remove selected task',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        DeleteListTask(rowData._id)
        ConfirmAlert('Deleted!', 'Your task has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your task is safe', 'error')
      }
    })
  }
  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              <StyledDropdownItem onClick={showNotes}>
                {Strings.tasks.notes}
                <Notes circular={true}>{rowData.notes.length}</Notes>
              </StyledDropdownItem>
              <StyledDropdownItem onClick={showViewDetails}>
                {Strings.tasks.activity}
                <Notes circular={true}>{rowData.activities.length}</Notes>
              </StyledDropdownItem>
              {loggedUser.role !== 'GUEST' && (
                <>
                  <Dropdown.Item text={'Edit'} onClick={() => editTask(rowData)} />
                  <Dropdown.Item text={'Delete'} onClick={() => deleteTask()} />
                </>
              )}
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  )
}

export const CustomStatus = ({ rowData }: { rowData: TaskItemsDetails }) => {
  let background = ''
  switch (rowData.status) {
    case 'New Task':
      background = Colors.Orange250
      break
    case 'In Progress':
      background = Colors.Magenta60
      break
    case 'Completed':
      background = Colors.Green70
      break
  }
  return <Status background={background}>{rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}</Status>
}
