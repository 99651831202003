// Import Components
import client from 'queries/apollo'

// Import Graphql Mutation
import {
  CREATE_CHECKLIST_CATEGORY,
  DELETE_CHECKLIST_CATEGORY,
  EDIT_CHECKLIST_CATEGORY
} from 'queries/graphql/Settings/ChecklistManager/ChecklistCategory/Mutation'
import {
  ADD_DOC_TEMPLATE,
  CREATE_DOC_CHECKLIST,
  DELETE_DOC_CHECKLIST,
  EDIT_DOC_CHECKLIST,
  REMOVE_DOC_TEMPLATE,
  REORDER_DOCCHECKLIST
} from 'queries/graphql/Settings/ChecklistManager/DocChecklist/Mutations'
import {
  CREATE_DOC_TEMPLATE,
  DELETE_DOC_TEMPLATE,
  EDIT_DOC_TEMPLATE
} from 'queries/graphql/Settings/ChecklistManager/DocTemplate/Mutation'

export const addDocTemplate = async (docChecklistId: string, docTemplateId: string) => {
  const response = await client.mutate({
    mutation: ADD_DOC_TEMPLATE,
    variables: { docChecklistId, docTemplateId }
  })

  return response.data.addDocTemplate
}

export const removeDocTemplate = async (docChecklistId: string, docTemplateId: string) => {
  const response = await client.mutate({
    mutation: REMOVE_DOC_TEMPLATE,
    variables: { docChecklistId, docTemplateId }
  })

  return response.data.removeDocTemplate
}

export const editDocTemplate = async (docTemplateId: string, name: string) => {
  const response = await client.mutate({
    mutation: EDIT_DOC_TEMPLATE,
    variables: { docTemplateId, name }
  })

  return response.data.updateDocTemplate
}

export const editChecklistCategory = async (checklistId: string, name: string) => {
  const response = await client.mutate({
    mutation: EDIT_CHECKLIST_CATEGORY,
    variables: { checklistId, name }
  })
  return response.data.updateCheckListCategory
}

export const editDocChecklist = async (id: string, input: any) => {
  const response = await client.mutate({
    mutation: EDIT_DOC_CHECKLIST,
    variables: { id, input }
  })
  return response.data.updateCheckListCategory
}

export const createDocTemplate = async (input: any) => {
  const response = await client.mutate({
    mutation: CREATE_DOC_TEMPLATE,
    variables: { input }
  })

  return response.data.createDocTemplate
}

export const deleteDocTemplate = async (docTemplateId: string) => {
  const response = await client.mutate({
    mutation: DELETE_DOC_TEMPLATE,
    variables: { docTemplateId }
  })

  return response.data.deleteDocTemplate
}

export const createChecklistCategory = async (categoryName: string, mls: string) => {
  const response = await client.mutate({
    mutation: CREATE_CHECKLIST_CATEGORY,
    variables: { categoryName, mls }
  })

  return response.data.createCheckListCategory
}

export const deleteChecklistCategory = async (checklistCategoryId: string) => {
  const response = await client.mutate({
    mutation: DELETE_CHECKLIST_CATEGORY,
    variables: { checklistCategoryId }
  })

  return response.data.deleteCheckListCategory
}

export const createDocChecklist = async (input: any) => {
  const response = await client.mutate({
    mutation: CREATE_DOC_CHECKLIST,
    variables: { input }
  })

  return response.data.createDocCheckList
}

export const deleteDocChecklist = async (docChecklistId: string) => {
  const response = await client.mutate({
    mutation: DELETE_DOC_CHECKLIST,
    variables: { docChecklistId }
  })

  return response.data.deleteDocCheckList
}

export const reorderDocCheckList = async (data: any) => {
  const response = await client.mutate({
    mutation: REORDER_DOCCHECKLIST,
    variables: { docChecklist: data }
  })

  return response.data.reorderingDocChecklist
}
