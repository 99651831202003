// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'

// Import Components
import Content from './Content'
import { leftPanelMockData, otherLeftPanelMockData } from './LeftPanelMenusMockData'

// Import Reducer and Actions
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Dashboard/Actions'

// Import Colors
import Colors from 'design/Colors'
import { getLoggedInUser } from 'utils'

// Import Styled Components
import {
  AccordionTitle,
  Container,
  Title,
  Wrapper
} from './Styled'

// Font Awesome Icons
import {
  faCog,
  faCogs,
  faFileChartLine,
  faUser,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faUser, faCogs, faCog, faFileChartLine])

interface StoreProps {
  showSetting: (name: string) => void
  setShowPricingTabInSettings: (data: boolean) => void
  setShowWorkflowTabInSettings: (data: boolean) => void
  setShowNotificationTabInSettings: (data: boolean) => void
  setShowChecklistTabInSettings: (data: boolean) => void
  setShowFeeTabInSettings: (data: boolean) => void
  setShowTaxTabInSettings: (data: boolean) => void
  showNotificationTab: boolean
  showPricingTab: boolean
  showWorkflowTab: boolean
  showChecklistTab: boolean
  showTexTab: boolean
  showFeeTab: boolean
}

type Props = StoreProps

interface State {
  activeContent: string
  activeIndex: number
  menuId: string
  user: any
  setLocalizationDefault: boolean
}

class LeftPanel extends React.Component<Props, State> {
  public state = {
    activeContent: '',
    activeIndex: 0,
    menuId: '',
    setLocalizationDefault: true,
    user: {},
  }

  public async componentDidMount() {
    const { showSetting, showPricingTab, showWorkflowTab, showNotificationTab, showChecklistTab, showTexTab, showFeeTab } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({ user })
    if (showPricingTab) {
      this.setState({ menuId: '5d37e7593b88441ab22413d3', activeIndex: 0.5 })
      showSetting('Plans & Billing')
    }
    if (showWorkflowTab) {
      this.setState({ activeContent: '5d37e7593b88441ab22413a55' })
      showSetting('Workflow Manager')
    }
    if (showNotificationTab) {
      this.setState({ activeContent: '5d37e7593b88441ab22413a77', activeIndex: 1 })
      showSetting('Notifications')
    }
    if (showChecklistTab) {
      this.setState({ activeContent: '5d37e7593b88441ab22413579', activeIndex: 0 })
      showSetting('Checklist Manager')
    }
    if (showTexTab) {
      this.setState({ activeContent: '5d37e7593b88441ab22413033', activeIndex: 0 })
      showSetting('Tax Setup')
    }
    if (showFeeTab) {
      this.setState({ activeContent: '5d37e7593b88441ab22413963', activeIndex: 0 })
      showSetting('Fee Settings')
    }
  }

  public componentWillReceiveProps(nextProps: any) {
    const { showSetting } = this.props
    if (nextProps.showPricingTab) {
      this.setState({ menuId: '5d37e7593b88441ab22413d3', activeIndex: 0.5, activeContent: '' })
      showSetting('Plans & Billing')
    }
  }

  public render() {
    const { showWorkflowTab, showChecklistTab, showFeeTab, showTexTab } = this.props
    const { activeIndex, activeContent, menuId, user, setLocalizationDefault } = this.state
    return (
      <Container>
        {(user[`role`] === 'ADMIN') &&
          <Accordion>
            {
              leftPanelMockData.map((items: any, index: number) => (
                <Wrapper key={index} style={{ height: menuId === items._id ? '34px' : '' }}>
                  <AccordionTitle
                    active={activeIndex === index}
                    index={index}
                    id={items._id}
                    name={items.menuName}
                    onClick={this.handleClick}
                    background={menuId === items._id ? Colors.White525 : Colors.White1000} >
                    {
                      items.icon === 'rocketchat' ?
                        <span><FontAwesomeIcon icon={['fab', items.icon]} /></span> :
                        <span><FontAwesomeIcon icon={['fal', items.icon]} /></span>
                    }
                    <Title color={activeIndex === index ? Colors.DarkBlue200 : Colors.Black500}>{items.menuName}</Title>
                    {items.menuName !== 'Plans & Billing' && <Icon name='dropdown' />}
                  </AccordionTitle>
                  {
                    items.content.length ? <Accordion.Content
                      active={activeIndex === index}
                      content={<Content setLocalizationDefault={setLocalizationDefault} user={user} showTexTab={showTexTab} showFeeTab={showFeeTab} showChecklistTab={showChecklistTab} showWorkflowTab={showWorkflowTab} contentData={items.content} onActive={this.onActive} activeContent={activeContent} />} /> : null
                  }
                </Wrapper>
              ))
            }
          </Accordion>
        }
        {(user[`role`] === 'TEAM_LEADER' || user[`role`] === 'AGENT' || user[`role`] === 'MANAGER') &&
          <Accordion>
            {
              otherLeftPanelMockData.map((items: any, index: number) => (
                <Wrapper key={index} style={{ height: menuId === items._id ? '34px' : '' }}>
                  <AccordionTitle
                    active={activeIndex === index}
                    index={index}
                    id={items._id}
                    name={items.menuName}
                    onClick={this.handleClick}
                    background={menuId === items._id ? Colors.White525 : Colors.White1000} >
                    {
                      items.icon === 'rocketchat' ?
                        <span><FontAwesomeIcon icon={['fab', items.icon]} /></span> :
                        <span><FontAwesomeIcon icon={['fal', items.icon]} /></span>
                    }
                    <Title color={activeIndex === index ? Colors.DarkBlue200 : Colors.Black500}>{items.menuName}</Title>
                    <Icon name='dropdown' />
                  </AccordionTitle>
                  {
                    items.content.length ? <Accordion.Content
                      active={activeIndex === index}
                      content={<Content setLocalizationDefault={setLocalizationDefault} user={user} showTexTab={showTexTab} showFeeTab={showFeeTab} showChecklistTab={showChecklistTab} showWorkflowTab={showWorkflowTab} contentData={items.content} onActive={this.onActive} activeContent={activeContent} />} /> : null
                  }

                </Wrapper>
              ))
            }
          </Accordion>
        }
      </Container>
    )
  }

  private handleClick = (e: React.SyntheticEvent<HTMLDivElement>, titleProps: any) => {
    const { index, id, name } = titleProps
    const { activeIndex, user } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })

    if (user[`role`] === 'ADMIN') {
      if (index === 1) {
        this.setState({ menuId: id })
        this.onActive(id, name)
      }
    } else {
      if (index === 2) {
        this.setState({ menuId: id })
        this.onActive(id, name)
      }
    }
  }

  private onActive = (id: string, name: string) => {
    const { showSetting, setShowPricingTabInSettings, setShowWorkflowTabInSettings, setShowNotificationTabInSettings, setShowChecklistTabInSettings, setShowTaxTabInSettings, setShowFeeTabInSettings } = this.props
    setShowPricingTabInSettings(false)
    setShowWorkflowTabInSettings(false)
    setShowNotificationTabInSettings(false)
    setShowChecklistTabInSettings(false)
    setShowFeeTabInSettings(false)
    setShowTaxTabInSettings(false)
    this.setState({ activeContent: id })
    showSetting(name)
    if (name !== 'Plans & Billing') {
      this.setState({ menuId: '' })
    }
    this.setState({ setLocalizationDefault: false })
  }

}

const mapStateToProps = (state: AppState) => ({
  showChecklistTab: state.dashboard.showChecklistTab,
  showFeeTab: state.dashboard.showFeeTab,
  showNotificationTab: state.dashboard.showNotificationTab,
  showPricingTab: state.dashboard.showPricingTab,
  showTexTab: state.dashboard.showTexTab,
  showWorkflowTab: state.dashboard.showWorkflowTab,
})

export default connect(
  mapStateToProps,
  {
    setShowChecklistTabInSettings: Actions.showChecklistTabInSettings,
    setShowFeeTabInSettings: Actions.showFeeTabInSettings,
    setShowNotificationTabInSettings: Actions.showNotificationTabInSettings,
    setShowPricingTabInSettings: Actions.showPricingTabInSettings,
    setShowTaxTabInSettings: Actions.showTaxTabInSettings,
    setShowWorkflowTabInSettings: Actions.showWorkflowTabInSettings,
  }
)(LeftPanel)
