// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import Ref from 'semantic-ui-react/dist/commonjs/addons/Ref'

// Import Components
import LoadingIndicator from 'shared/LoadingIndicator'
import Toast from 'shared/Toast'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import * as Actions from 'store/UserProfile/Actions'
import { DocumentItem, UserType } from 'store/UserProfile/Types'
import {
  createFolder,
  deleteFileFolder,
  downloadFileFolder,
  renameFileFolder,
  uploadMyDocument
} from '../../../UserProfileMutations'
import { getAllFolderFiles } from '../../../UserProfileQueries'
import AddFolderModal from './AddFolderModal'
import { EditFileFolder } from './AddFolderModal/Types'
import { CustomActions, CustomAmount, CustomName } from './CustomComponents'
import NavigatorGrid from './NavigatorGrid'
import { formatData } from './Utils/FormattingData'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import { StyledContainer } from './Styled'

const MyDocumentsStrings = Strings.userProfile.detailSection.myDocuments

interface StoreProps {
  setShowStorageUsed: (data: number) => void
}

interface OwnProps {
  tenant: string
}

type Props = StoreProps & OwnProps

interface State {
  documentsData: DocumentItem[]
  containerHeight: number
  containerWidth: number
  path: string[]
  refinedData: DocumentItem[]
  loading: boolean
  remainingStorageUsed: number
  user: UserType
  toggle: boolean | undefined
  addFolder: boolean
  edit: boolean
  editFileFolder: EditFileFolder
}

class MyDocuments extends React.Component<Props, State> {
  public textInputForFileUpload: any = React.createRef()
  public container: HTMLElement | any = null

  public state = {
    addFolder: false,
    containerHeight: 0,
    containerWidth: 0,
    documentsData: [],
    edit: false,
    editFileFolder: {} as EditFileFolder,
    loading: true,
    path: ['NONE'],
    refinedData: [],
    remainingStorageUsed: 0,
    toggle: false,
    user: {} as UserType
  }

  public async componentDidMount() {
    this.refreshGetAllFolderFiles()
    this.setState({ containerHeight: this.container.offsetHeight, containerWidth: this.container.offsetWidth })
  }

  public onClickItem = (id: string) => {
    const { documentsData } = this.state
    const refinedData: DocumentItem[] = documentsData.filter((dataItem: DocumentItem) => dataItem.parent === id)
    const parentIndex = documentsData.findIndex((dataItem: DocumentItem) => dataItem.id === id)
    let parentItem: DocumentItem
    const newPath: string[] = this.state.path.slice()
    if (parentIndex > -1) {
      parentItem = Object.assign({}, documentsData[parentIndex])
      newPath.push(parentItem.id)
      this.setState({ refinedData, path: newPath })
    }
  }

  public onClickBack = () => {
    const { documentsData } = this.state
    const newPath: string[] = this.state.path.slice()
    let refinedData
    newPath.pop()
    if (newPath.length === 0) {
      refinedData = documentsData.filter((dataItem: any) => dataItem.parent === MyDocumentsStrings.none)
    } else {
      const parentId = newPath[newPath.length - 1]
      refinedData = documentsData.filter((dataItem: any) => dataItem.parent === parentId)
    }
    this.setState({ refinedData, path: newPath })
  }

  public jump = (index: number) => {
    const { documentsData } = this.state
    const newPath: string[] = this.state.path.slice(0, index + 1)
    let refinedData
    if (newPath.length === 1) {
      refinedData = documentsData.filter((dataItem: any) => dataItem.parent === MyDocumentsStrings.none)
    } else {
      const parentId = newPath[newPath.length - 1]
      refinedData = documentsData.filter((dataItem: any) => dataItem.parent === parentId)
    }
    this.setState({ refinedData, path: newPath })
  }

  public render() {
    const {
      documentsData,
      refinedData,
      containerHeight,
      path,
      loading,
      addFolder,
      editFileFolder,
      edit,
      user,
      toggle
    } = this.state
    const finalData = refinedData.map((item: DocumentItem) => {
      return {
        ...item,
        modified: moment(item.modified).format('MM/DD/YYYY LT')
      }
    })

    const columnMetaData = [
      {
        customComponent: CustomName,
        customComponentMetadata: { download: this.downloadFile },
        enhanceWithRowData: true,
        id: MyDocumentsStrings.columns.fileName.id,
        title: MyDocumentsStrings.columns.fileName.title
      },
      {
        customComponent: CustomAmount,
        enhanceWithRowData: true,
        id: MyDocumentsStrings.columns.modified.id,
        title: MyDocumentsStrings.columns.modified.title
      },
      {
        customComponent: CustomActions,
        customComponentMetadata: {
          toggle,
          user,
          delete: this.deleteFileAndFolder,
          rename: this.renameFileAndFolder,
          download: this.downloadFile
        },
        enhanceWithRowData: true,
        id: MyDocumentsStrings.columns.action.id,
        sortable: false,
        title: MyDocumentsStrings.columns.action.title
      }
    ]

    return (
      /* tslint:disable-next-line jsx-no-lambda */
      <Ref innerRef={(ref: HTMLElement) => (this.container = ref)}>
        <StyledContainer fluid={true}>
          {addFolder && (
            <AddFolderModal
              handleClose={this.closeModal}
              addFolder={this.addFolder}
              editFileFolder={editFileFolder}
              edit={edit}
              updateFileFolder={this.updateFileFolder}
            />
          )}
          {loading && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
          <NavigatorGrid
            allData={documentsData}
            data={finalData}
            columnMetaData={columnMetaData}
            tableHeight={containerHeight}
            onClickBack={this.onClickBack}
            jump={this.jump}
            onClickItem={this.onClickItem}
            path={path}
            toggleForm={this.toggleForm}
            showAddFolder={this.showAddFolder}
            user={user}
            upload={this.upload}
          />
          <input
            type="file"
            ref={(node: any) => {
              this.textInputForFileUpload = node
            }}
            id="hidden-new-file"
            style={{ display: 'none' }}
            name="fileName"
            onChange={(e: any) => {
              this.uploadFile(e.target.files)
              this.textInputForFileUpload.value = null
            }}
            multiple={true}
          />
        </StyledContainer>
      </Ref>
    )
  }

  private refreshGetAllFolderFiles = async (statusToggle?: boolean) => {
    const { tenant, setShowStorageUsed } = this.props
    const { path, toggle } = this.state
    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({ loading: true, user })
    const response = await getAllFolderFiles(!toggle ? user._id : undefined)
    if (!toggle) {
      setShowStorageUsed(response.totalSize)
    }
    const result = formatData(response, !toggle ? user._id : tenant)
    if (result) {
      this.setState({ loading: false })
    }
    const refinedData = result.filter((dataItem: any) => dataItem.parent === MyDocumentsStrings.none)
    this.setState({ documentsData: result, refinedData, remainingStorageUsed: response.remainingSize })
    if (statusToggle) {
      this.setState({ path: ['NONE'] })
    } else {
      this.jump(path.length - 1)
    }
  }

  private updateFileFolder = async (newKey: string) => {
    const { editFileFolder } = this.state
    this.setState({ loading: true })
    try {
      await renameFileFolder(editFileFolder[`key`], newKey)
      this.refreshGetAllFolderFiles()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: false })
    }
  }

  private renameFileAndFolder = async (key: string, type: string, fileName: string) => {
    const newData = {
      fileName,
      key,
      type
    }
    this.setState({ edit: true, editFileFolder: newData })
    this.showAddFolder()
  }

  private deleteFileAndFolder = async (key: string) => {
    try {
      await deleteFileFolder(key)
      this.refreshGetAllFolderFiles()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: false })
    }
  }

  private downloadFile = async (key: string) => {
    try {
      const response = await downloadFileFolder(key)
      window.open(response, 'blank')
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: false })
    }
  }

  private toggleForm = (toggle: boolean) => {
    this.setState({ toggle }, () => {
      this.refreshGetAllFolderFiles(true)
    })
  }

  private addFolder = async (name: string) => {
    const { user, path, toggle } = this.state
    const pathData: any = path.join('/')
    this.setState({ loading: true })
    try {
      await createFolder(!toggle ? user._id : undefined, pathData !== 'NONE' ? pathData : undefined, name)
      this.refreshGetAllFolderFiles()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: false })
    }
  }

  private showAddFolder = () => {
    this.setState({ addFolder: true })
  }

  private closeModal = () => {
    this.setState({ addFolder: false, edit: false, editFileFolder: {} as EditFileFolder })
  }

  private upload = () => {
    this.textInputForFileUpload.click()
  }

  private uploadFile = async (files: File) => {
    const { user, path, toggle, remainingStorageUsed } = this.state
    let size = 0
    for (const key in files) {
      if (files && key !== 'length' && key !== 'item') {
        size = size + files[key].size
      }
    }
    if (size / 1000000 > remainingStorageUsed) {
      Toast({ message: 'Storage is full', type: 'error' })
      return
    }
    const pathData: any = path.join('/')
    this.setState({ loading: true })
    let folderPath
    if (pathData === 'NONE') {
      folderPath = undefined
    } else {
      folderPath = pathData.slice(pathData.indexOf('/'))
    }
    try {
      await uploadMyDocument(!toggle ? user._id : undefined, folderPath, files)
      this.refreshGetAllFolderFiles()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
      this.setState({ loading: false })
    }
  }
}

export default connect(
  null,
  {
    setShowStorageUsed: Actions.showStorageUsed
  }
)(MyDocuments)
