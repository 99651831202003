import React from 'react'

import Button from 'shared/Button'
import { Container, Label } from './Styled'

import { PaginationType } from 'store/Pagination/Types'

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([ faAngleLeft, faAngleRight ])

interface Props {
  onChange: (pagination: PaginationType.Base) => void
  pagination: PaginationType.Base
  currentLength: number
}

class PaginationRecords extends React.Component<Props,{}> {
  public render() {
    const {
      currentLength,
      pagination: { count, limit, pages },
    } = this.props

    const hasRecords = count > 0 || pages.current > 0
    const isOnePage = pages.total <= 1
    const start = pages.current + 1
    const end = start + (currentLength - 1)

    const prevDisabled = isOnePage || pages.current === pages.previous
    const nextDisabled = isOnePage || pages.current === pages.next

    return (
      <Container>
        <Button.Bubble
          className='nav-button'
          isDisabled={prevDisabled}
          label={prevDisabled ? '' : 'Previous'}
          icon={faAngleLeft}
          onClick={() => this.handleNavigation('previous')}
        />
        <Label>
          {!hasRecords && (
            `No Records`
          )}
          {(hasRecords && limit === 1) && (
            `Record ${start} of ${count}`
          )}
          {(hasRecords && isOnePage) && (
            `All Records`
          )}
          {(hasRecords && !isOnePage && limit > 1) && (
            `Records ${start} - ${end} of ${count}`
          )}
        </Label>
        <Button.Bubble
          className='nav-button'
          isDisabled={nextDisabled}
          label={nextDisabled ? '' : 'Next'}
          icon={faAngleRight}
          onClick={() => this.handleNavigation('next')}
        />
      </Container>
    )
  }

  private handleNavigation = (direction: string) => {
    const {
      onChange,
      pagination,
      pagination: { pages },
    } = this.props

    if (pages.current !== pages[direction]) {
      pagination.skip = pages[direction]
      onChange(pagination)
    }
  }
}

export default PaginationRecords
