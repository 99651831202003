import * as React from 'react'
import { Container } from 'semantic-ui-react'
import SettingItem from './SettingItem'
import {
  StyledDivider,
  StyledSegment,
} from './Styled'

// Font Awesome Icons
import {
  faUser,
  faUsers
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faUser, faUsers])

interface SettingItem {
  max: number
  min: number
  name: string
  value: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SettingsGroup = ({
  data,
  disabled,
  groupTitle,
  image
}: {
  data: any
  disabled: boolean
  groupTitle: string
  image: string
}) => {
  return (
    <Container>
      <StyledSegment vertical={true} size={'large'} horizontal={true}>
        {groupTitle !== 'TEAM' ? <FontAwesomeIcon icon={['fal', 'user']} /> : <FontAwesomeIcon icon={['fal', 'users']} />}
        <span>{groupTitle}</span>
      </StyledSegment>
      <StyledSegment vertical={true} >
        {
          data.map((item: any, index: number) => {
            return <SettingItem {...item} key={index} index={index} disabled={disabled} />
          })
        }
      </StyledSegment>
      {groupTitle !== 'TEAM' ? <StyledDivider /> : null}
    </Container>
  )
}

export default SettingsGroup
