import { Reducer } from 'redux'
import Actions from 'store/Actions'
import MockWidgets from 'utils/MockWidgets'
import {
  addCustomeColor,
  addNewNotes,
  addPass,
  addWallpaperImage,
  addWidgetSection,
  deletePass,
  deleteWidgetSection,
  renamePass,
  renameWidgetSection
} from './ReduxUtil'
import { DashboardState, Section } from './Types'

const initialState: DashboardState = {
  categories: [],
  colors: [],
  customeColor: '',
  detailsPopup: {
    id: '',
    open: false
  },
  eventsPopup: {
    open: false
  },
  notes: [],
  officeID: '',
  taskID: '',
  showChecklistTab: false,
  showFeeTab: false,
  showNotificationTab: false,
  showPricingTab: false,
  showTexTab: false,
  showWorkflowTab: false,
  teamID: '',
  themeImage: '',
  toggleComposeMail: '',
  toggleComposeMailForDocument: {},
  toggleSharedWithPopup: false,
  toggleSignatureModal: false,
  userProfile: '',
  userSections: [],
  wallpaper: [],
  widgetSection: [],
  widgets: [],
  widgetsPopup: {
    id: '',
    open: false
  }
}

interface Widget {
  img: string
  id: string
  title: string
}

const dashboardReducer: Reducer<DashboardState> = (
  state: DashboardState = initialState,
  action
) => {
  switch (action.type) {
    case Actions.dashboardGetUserProfile:
      return {
        ...state,
        userProfile: action.payload.profile
      }
    case Actions.dashboardAddWidgetSection:
      return {
        ...state,
        widgetSection: addWidgetSection(state, action.payload.newData)
      }
    case Actions.dashboardGetWidgetSection:
      return {
        ...state,
        widgetSection: action.payload.data
      }
    case Actions.dashboardRenameWidgetSection:
      return {
        ...state,
        widgetSection: renameWidgetSection(
          state,
          action.payload.id,
          action.payload.newName
        )
      }
    case Actions.dashboardDeleteWidgetSection:
      return {
        ...state,
        widgetSection: deleteWidgetSection(state, action.payload.id)
      }
    case Actions.dashboardAddPass:
      return {
        ...state,
        widgetSection: addPass(state, action.payload.id, action.payload.newData)
      }
    case Actions.dashboardDeletePass:
      return {
        ...state,
        widgetSection: deletePass(
          state,
          action.payload.sectionId,
          action.payload.passId
        )
      }
    case Actions.dashboardRenamePass:
      return {
        ...state,
        widgetSection: renamePass(
          state,
          action.payload.section,
          action.payload.passId,
          action.payload.newName
        )
      }
    case Actions.dashboardSetThemeImage:
      return {
        ...state,
        themeImage: action.payload.newImage
      }
    case Actions.dashboardSetCustomeColor:
      return {
        ...state,
        customeColor: action.payload.newColor
      }
    case Actions.dashboardAddCustomeColor:
      return {
        ...state,
        colors: addCustomeColor(state, action.payload.newData)
      }
    case Actions.dashboardGetCustomeColor:
      return {
        ...state,
        colors: action.payload.data
      }
    case Actions.dashboardAddWallpaperImage:
      return {
        ...state,
        wallpaper: addWallpaperImage(state, action.payload.newData)
      }
    case Actions.dashboardGetWallpaperImage:
      return {
        ...state,
        wallpaper: action.payload.data
      }

    case Actions.toggleDetailsPopup:
      return { ...state, detailsPopup: { ...action.payload } }
    case Actions.toggleSharedWithPopup:
      return { ...state, toggleSharedWithPopup: action.payload.open }
    case Actions.toggleComposeMail:
      return { ...state, toggleComposeMail: action.payload.open }
    case Actions.toggleComposeMailForDocument:
      return { ...state, toggleComposeMailForDocument: action.payload.open }
    case Actions.toggleEventsPopup:
      return { ...state, eventsPopup: { open: action.payload.open } }
    case Actions.toggleWidgetsPopup:
      return {
        ...state,
        widgetsPopup: {
          id: action.payload.sectionId,
          open: action.payload.open
        }
      }
    case Actions.addWidget: {
      return {
        ...state,
        userSections: addNewWidget(
          state,
          action.payload.widgetId,
          action.payload.sectionId
        ),
        widgetsPopup: {
          id: '',
          open: false
        }
      }
    }
    case Actions.renderAllWidgets: {
      return {
        ...state,
        widgets: action.payload.widgets
      }
    }
    case Actions.userSectionsChanged: {
      return {
        ...state,
        userSections: action.payload.userSections
      }
    }
    case Actions.dashboardAddNotes:
      return {
        ...state,
        notes: addNewNotes(state, action.payload.newData)
      }
    case Actions.dashboardGetNotes:
      return {
        ...state,
        notes: action.payload.data
      }
    case Actions.showPricingTab:
      return {
        ...state,
        showPricingTab: action.payload.data
      }
    case Actions.showWorkflowTab:
      return {
        ...state,
        showWorkflowTab: action.payload.data
      }
    case Actions.showNotificationTab:
      return {
        ...state,
        showNotificationTab: action.payload.data
      }
    case Actions.showChecklistTab:
      return {
        ...state,
        showChecklistTab: action.payload.data
      }
    case Actions.showFeeTab:
      return {
        ...state,
        showFeeTab: action.payload.data
      }
    case Actions.showTaxTab:
      return {
        ...state,
        showTexTab: action.payload.data
      }
    case Actions.dashboardCategoriesFilter:
      return {
        ...state,
        categories: action.payload.data
      }
    case Actions.dashboardTeamID:
      return {
        ...state,
        teamID: action.payload.data
      }
    case Actions.dashboardOfficeID:
      return {
        ...state,
        officeID: action.payload.data
      }
    case Actions.dashboardTaskID:
      return {
        ...state,
        taskID: action.payload.data
      }
    case Actions.dashboardSignatureModal:
      return {
        ...state,
        toggleSignatureModal: action.payload.data
      }
    default:
      return state
  }
}

const addNewWidget = (
  state: DashboardState,
  widgetId: string,
  sectionId: string
): DashboardState['userSections'] => {
  const index = state.userSections.map((s: Section) => s.id).indexOf(sectionId)
  const itemToUpdate = state.userSections[index]
  return [
    ...state.userSections.slice(0, index),
    {
      ...itemToUpdate,
      widgets: [...itemToUpdate.widgets, { ...findWidgetToAdd(widgetId) }]
    },
    ...state.userSections.slice(index + 1)
  ]
}

const findWidgetToAdd = (id: string) =>
  MockWidgets.reduce(
    (acc: Widget, curr: Widget) => {
      if (curr.id === id) {
        acc = curr
      }
      return acc
    },
    { id: '', title: '', img: '' }
  )

export default dashboardReducer
