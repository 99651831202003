import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, text, x, y } from 'design/Styled'

export const Container = styled.div`
  ${y};
  ${text};
  height: 100%;
  overflow: hidden;
  background: ${Colors.Container};

  .rp-dropzone-container {
    overflow: hidden;
  }
`

export const PDFContainer = styled.section`
  ${y};
  height: 100%;
  border: 1px solid ${Colors.EmphasisLine};
  padding: 1em;
`

export const Header = styled.header`
  ${x};

  .previous svg {
    position: relative;
    left: -1px;
  }

  .next svg {
    position: relative;
    left: 1px;
  }

  .layout {
    color: ${Colors.TextLink};
    opacity: 0.5;

    &.rp-button-icon-active {
      opacity: 1;
    }
  }
`

export const Text = styled.strong`
  ${text};
`

export const Col = styled.section`
  ${y};
  height: 100%;
`

export const Row = styled.div`
  ${x};
  ${children};
  width: auto;
`

export const Title = styled.h2`
  ${text};
  font: ${fonts.large.regular};
  color: ${Colors.TextLink};
  text-align: center;
`
