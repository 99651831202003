import * as React from 'react'

import Financial from './Financial'
import TotalIncome from './TotalIncome'
import Transaction from './Transaction'

import {
  CommissionType,
  TransactionType,
  UserType,
} from 'app/Transactions/Details/Types'
import {
  TotalCommissions,
} from 'store/Transactions/Types'

import {
  IncomeWrapper,
} from './Styled'

interface Props {
  commission: CommissionType
  updateCommission: (data: Partial<CommissionType>) => void
  getCommission: (data: Partial<CommissionType>) => void
  user: UserType
  transaction: TransactionType
  totalCommissions: TotalCommissions
}

const Income = ({ commission, updateCommission, getCommission, user, transaction, totalCommissions }: Props) => (
  <IncomeWrapper>
    <Financial
      commission={commission}
      getCommission={getCommission}
      transaction={transaction}
      updateCommission={updateCommission}
      user={user}
    />
    <TotalIncome
      commission={commission}
      totalCommissions={totalCommissions}
    />
    <Transaction
      commission={commission}
      transaction={transaction}
      user={user}
    />
  </IncomeWrapper>
)

export default Income
