// Import Packages
import { Card, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Colors from 'design/Colors'
import Envalope from 'design/icons/contacts/icon-envalope.png'
import Manager from 'design/icons/contacts/icon-manager.png'
import Phone from 'design/icons/contacts/icon-phone.png'
import { y } from 'design/Styled'

interface Props {
  background?: string
  color?: string
}

const Container = styled.div`
  min-height: 15px;
`
const CardWrapper = styled(Card)`
  &.ui.card {
    margin: 0 auto;
    border-radius: 0px;
    width: 95%;
    background: ${Colors.White1000};
    border: 1px solid ${Colors.Blue910};
  }
`

const CardContent = styled(Card.Content)`
  display: flex;
  padding: 20px 0px 6px 20px;
`

const CircleLable = styled.div`
  font-size: 20px;

  .ui.circular.label, .ui.circular.labels .label {
    background: ${Colors.White460};
    color: ${props => props.color || props.color};
    font-size: 20px;
    font-weight: 400;
  }
`

const Content = styled.div`
  font-size: 15px;
  margin-left: 6px;
`

const ContactName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.Black500};
  cursor: pointer;
  text-transform: capitalize;
`

const ContactSouse = styled.div`
  font-size: 12px;
  color: ${Colors.Black500};
`

const Contact = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black550};
`

const ContactEmail = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black550};
  cursor: pointer;
`

const ExtraContent = styled.div`
  font-size: 12px;
  display: flex;
  padding: 5px 20px 20px 20px;
  font-style: italic;
  margin-top: 5px;
  margin-bottom: -12px;
  align-items: center;
`

const Owner = styled.div`
  color: ${Colors.Black550};
  flex-grow: 1;
`

const Added = styled.div`
  color: ${Colors.Black550};
  text-align: end;
  white-space: nowrap;
`

const Divider = styled.div`
  border-bottom: 1px solid ${Colors.Blue50};
  width: 90%;
  margin: 0 auto;
`

const PhoneIcon = styled.div`
  background: url(${Phone});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 10px;
  cursor: pointer;
`

const EmailIcon = styled.div`
  background: url(${Envalope});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 10px;
  cursor: pointer;
`

const OwnerIcon = styled.div`
  background: url(${Manager});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 5px;
  display: inline-block;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

const Profile = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black500};
  align-items: center;
  flex-grow: 1;
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 23px;
  height: 23px;
  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
  font-style: normal;

  div {
    ${y};
    height: 100%;
    color: ${Colors.DarkBlue220};
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
`

export {
  Added,
  CardContent,
  CardWrapper,
  CircleLable,
  Contact,
  ContactEmail,
  ContactName,
  ContactSouse,
  Container,
  Content,
  Divider,
  Labels,
  EmailIcon,
  ExtraContent,
  Owner,
  OwnerIcon,
  PhoneIcon,
  Profile,
  ProfileImage,
  StyledPopup,
  Li,
  MemberProfile
}
