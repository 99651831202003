import * as React from 'react'

import {
  Container,
  TimeStamp,
  StyledTextArea
} from './Styled'

import {
  MarkupLabelEnum,
  MarkupModeEnum,
  MarkupType,
  StageType,
} from '../Types'

interface Props {
  container: StageType
  mode: MarkupModeEnum
  onChange: (rectange: MarkupType) => void
  onClick: (rectange: MarkupType) => void
  rectangle: MarkupType
}

class Markup extends React.Component<Props,{}> {
  public render = () => {
    const {
      container,
      mode,
      rectangle,
      rectangle: { top, left, width, height },
    } = this.props

    const relative = {
      width: container.scale.x * container.width,
      height: container.scale.y * container.height,
    }

    const isSignature = rectangle.label.value === MarkupLabelEnum.Signature

    return (
      <Container
        type={rectangle.label.value}
        mode={mode}
        dimensions={{
          top: top * relative.height,
          left: left * relative.width,
          width: width * relative.width,
          height: height * relative.height,
          offset: rectangle.style.offset || 4,
        }}
        onClick={this.handleClick}
      >
        <StyledTextArea
          autoFocus={true}
          value={rectangle.value}
          style={{
            ...rectangle.style,
            fontSize: (rectangle.style.fontSize * container.width) * (rectangle.style.fontScaleFactor || 0.0014),
          }}
          disabled={mode < MarkupModeEnum.Editor || isSignature}
          onChange={this.handleChange}
        />
        {rectangle.meta && rectangle.label.value === MarkupLabelEnum.Signature && (
          <React.Fragment>
            <TimeStamp>{rectangle.meta.date || ''}</TimeStamp>
          </React.Fragment>
        )}
      </Container>
    )
  }

  private handleChange = (e:React.SyntheticEvent, { value }: any) => {
    const { onChange, rectangle } = this.props
    const update = { ...rectangle }
    update.value = value
    onChange(update)
  }

  private handleClick = () => {
    const { onClick, rectangle } = this.props
    onClick(rectangle)
  }
}

export default Markup
