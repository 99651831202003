import styled from 'styled-components'

import Colors from 'design/Colors'
import { text, x, y } from 'design/Styled'

export const Container = styled.div`
  ${text};
  ${x};
  height: 100%;
  overflow: hidden;
  background: ${Colors.Container};

  & .emphasis-container {
    max-width: 500px;
    background: ${Colors.EmphasisContainer};
  }
`

export const Section = styled.section`
  ${y};
`
