import styled from 'styled-components'

import {
  children,
  x,
  y,
} from 'design/Styled'

export const Container = styled.div`
  ${x};
  ${children};
  height: 100%;
`

export const Col = styled.section`
  ${y};
  height: 100%;
`
