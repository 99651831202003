// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import { GET_INBOX } from 'queries/graphql/MessageEmail/Queries'

export const getInboxBoard = async (skip: number) => {
  const query = await client.query({
    fetchPolicy: 'network-only',
    query: GET_INBOX,
    variables: { skip }
  })

  const { data } = query
  if (data && data.inbox) {
    const {
      inbox: { pagination, results }
    } = data
    const inbox = {
      _id: 'INBOX',
      description: '',
      icon: '',
      items: results,
      name: 'INBOX',
      pagination: pagination
    }
    return inbox
  }

  return false
}
