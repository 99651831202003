import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, primaryStyledButton, scrollYContainer, text, x, y } from 'design/Styled'

interface Props {
  status?: string
}

export const Container = styled.div`
  ${x};
  ${text};
  height: 100%;
`

export const Content = styled.section`
  ${y};
  justify-content: center;
  height: 100%;
`

export const Header = styled.header`
  ${x};
  ${children};
  font: ${fonts.medium.bold};
  margin: 0;
  padding: 1em 0;
  background: ${Colors.EmphasisContainer};
`

export const Section = styled.section`
  ${y};
  flex: 1;
  overflow: hidden;
`

export const ScrollY = styled.div`
  ${scrollYContainer};
`

export const Table = styled.ul`
  ${y};
  list-style: none;
  margin: 0;
  padding: 0;

  & > :last-child {
    border-bottom: none;
  }
`

export const Line = styled.li`
  ${x};
  ${children};
  border-bottom: 1px solid ${Colors.EmphasisLine};
`

export const Col = styled.div`
  ${y};
  padding: 1em;

  &.center {
    text-align: center;
  }
`

export const Link = styled.a`
  ${y};
`

export const Aside = styled.aside`
  ${y};
  width: 35%;
  height: 100%;
  padding: 1em;
  align-items: center;
  justify-content: center;
  background: ${Colors.ImagePanelContainer};
`

export const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 200px;
`

export const Status = styled(Col)`
  color: ${({ status }: Props) => (status && status.toLowerCase() === 'paid' ? Colors.TextSuccess : Colors.TextError)};
  font-weight: bold;
`

export const Footer = styled.footer`
  ${y};
  margin-top: 1em;
  align-items: center;
  justify-content: center;
`

export const Pagination = styled(Button)`
  ${primaryStyledButton};
`
