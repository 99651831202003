import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import * as React from 'react'
import { Ref } from 'semantic-ui-react'

import Modal from 'shared/Modal'
import QuillEditor from 'shared/QuillEditor'

import { getMyContacts } from 'app/Contacts/Dashboard/ContactQueries'
import { sendMessageEmail } from 'shared/MessageEmail/MailboxMutations'
import { getSignatureData } from 'shared/MessageEmail/SignatureModal/Queries'
import { serverToast } from 'shared/Toast/Toast'

import { getLoggedInUser } from 'utils'

import { emailMessageTemplateOptions } from 'templates/EmailMessage'
import { signatureTemplates } from 'templates/EmailSignature'

import {
  Clear,
  Container,
  EmailAddressDropdown,
  Footer,
  InputRow,
  Label,
  Options,
  QuillContainer,
  Send,
  SubjectInput,
  TemplateDropdown,
  Title,
  ToButton,
  ToRow,
  UnTabInput
} from './Styled'

import { AttachmentType } from 'shared/QuillEditor/Types'
import { EmailAddress as ExtendedEmailType, MessageEmailViewData as MessageType } from 'store/Mailbox/Types'
import { ContactType, EmailType, OptionType, PhoneType, UserType } from './Types'

export interface Props {
  onClose: () => void
  sendDocument?: AttachmentType
  defaultEmail?: any
  composerType: string
  messageData: MessageType | null
}

interface State {
  attachments: AttachmentType[]
  bounceFocus: boolean
  user: UserType
  customEmailOptions: OptionType[]
  emailOptions: OptionType[]
  messageDelta: any
  messageHtml: string
  messageText: string
  subject: string
  template: number
  toAddresses: string[]
  transition: boolean
}

class ComposeModal extends React.Component<Props, State> {
  public static defaultProps = {
    composerType: 'NEW',
    messageData: null
  }

  public state = {
    attachments: [] as AttachmentType[],
    bounceFocus: true,
    customEmailOptions: [] as OptionType[],
    emailOptions: [] as OptionType[],
    messageDelta: [],
    messageHtml: '',
    messageText: '',
    subject: '',
    template: 0,
    toAddresses: [],
    transition: true,
    user: {} as UserType
  }

  private toDropdownRef = null

  public componentDidMount = async () => {
    const { composerType, defaultEmail, messageData, sendDocument } = this.props

    const user: any = await getLoggedInUser({ fromCache: true })
    if (!user) {
      return
    }

    if (sendDocument) {
      this.handleAttachmentChange([sendDocument])
    }

    const replySignature = '<p><br><br>[[EMBEDDED_SIGNATURE]]<br><br>--------<br><br></p>'
    const customOptions: any = []
    let emailOptions: any = []
    let toAddresses: any = []
    let subject: string = ''
    let messageHtml: string = ''

    if (messageData) {
      messageHtml = replySignature + `${messageData.html || ''}`
      subject = messageData.subject || ''

      if (composerType === 'REPLY' || composerType === 'REPLY-ALL') {
        subject = `RE: ${subject}`
        toAddresses.push(messageData.from.value)
      }

      if (composerType === 'REPLY-ALL') {
        messageData.to.forEach((item: ExtendedEmailType) => {
          if (item.value.trim()) {
            toAddresses.push(item.value)
            customOptions.push({
              key: item.value,
              text: item.value,
              value: item.value
            })
          }
        })
      }

      if (composerType === 'FORWARD') {
        subject = `FWD: ${subject}`
      }
    }

    const usersEmails = user.emails.map((item: EmailType) => item.value)
    emailOptions = customOptions.filter((item: OptionType) => item.value && !usersEmails.includes(item.value))
    toAddresses = toAddresses.filter((item: string) => !usersEmails.includes(item))

    const contactResults: any = await getMyContacts(user)
    if (contactResults) {
      const contacts: OptionType[] = contactResults.map((contact: ContactType) => {
        return {
          key: contact.email,
          text: contact.email,
          value: contact.email
        }
      })
      emailOptions = [...emailOptions, ...contacts]
    }

    if (!defaultEmail) {
      this.setState({ toAddresses })
    } else {
      toAddresses.push(defaultEmail)
    }

    this.setState({
      customEmailOptions: emailOptions,
      emailOptions,
      messageHtml,
      subject,
      toAddresses,
      user
    })
  }

  public componentWillUnmount = () => {
    this.toDropdownRef = null
  }

  public render() {
    const {
      attachments,
      bounceFocus,
      emailOptions,
      messageHtml,
      subject,
      template,
      toAddresses,
      transition
    } = this.state
    return (
      <Modal
        content={
          <Container>
            <Title>Compose Mail</Title>
            <Options>
              <InputRow>
                <Label>To</Label>
                <ToRow onKeyDown={this.handleToKeydown}>
                  {toAddresses.map((address: string, index: number) => (
                    <ToButton
                      key={address}
                      content={address}
                      compact={true}
                      size="mini"
                      icon={<Clear name="close" onClick={() => this.handleClearAddress(index)} />}
                      labelPosition="right"
                    />
                  ))}
                  <Ref innerRef={this.handleRef}>
                    <EmailAddressDropdown
                      allowAdditions={true}
                      fluid={true}
                      noResultsMessage={'Begin typing an address. Press enter to add.'}
                      onAddItem={this.handleAddition}
                      onChange={this.handleToChange}
                      options={emailOptions}
                      search={true}
                      selection={true}
                      selectOnNavigation={false}
                      value={''}
                    />
                  </Ref>
                </ToRow>
              </InputRow>
              <UnTabInput onFocus={this.handleTabbing} tabIndex={bounceFocus ? null : -1} />
              <InputRow>
                <Label>Subject</Label>
                <SubjectInput
                  fluid={true}
                  name="subject"
                  onChange={this.handleSubjectChange}
                  type="text"
                  value={subject}
                />
              </InputRow>
              <InputRow>
                <Label>Template</Label>
                <TemplateDropdown
                  fluid={true}
                  name="template"
                  onChange={this.handleTemplateChange}
                  options={emailMessageTemplateOptions}
                  selection={true}
                  value={template}
                />
              </InputRow>
            </Options>
            <QuillContainer>
              <QuillEditor
                attachments={attachments}
                deleteDraft={this.closeSelf}
                handleAttachmentChange={this.handleAttachmentChange}
                handleMessageChange={this.handleMessageChange}
                html={messageHtml}
              />
            </QuillContainer>
            <Footer>
              <Send onClick={this.handleSendMessage}>Send</Send>
            </Footer>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
      />
    )
  }

  private handleRef = (node: any) => {
    this.toDropdownRef = node
  }

  private handleClearAddress = async (index: number) => {
    const { toAddresses } = this.state
    toAddresses.splice(index, 1)
    this.setState({ toAddresses })
  }

  private handleAddition = (e: React.SyntheticEvent, data: any) => {
    this.setState(prev => {
      const custom = [...prev.customEmailOptions, { text: data.value, value: data.value }]
      const total = [...prev.emailOptions, { text: data.value, value: data.value }]
      return {
        customEmailOptions: custom,
        emailOptions: total
      }
    })
  }

  private handleTabbing = (e: React.SyntheticEvent) => {
    const { bounceFocus } = this.state
    if (bounceFocus) {
      const node: any = this.toDropdownRef
      if (node) {
        const input = node.querySelector('input')
        input.focus()
        this.setState({ bounceFocus: false })
      }
    }
  }

  private handleToChange = (e: React.SyntheticEvent, data: any) => {
    const { toAddresses } = this.state
    const addresses: any = toAddresses.slice()
    if (data.value && !addresses.includes(data.value)) {
      addresses.push(data.value)
      this.setState({
        bounceFocus: true,
        toAddresses: addresses
      })
    }
  }

  private handleToKeydown = (e: React.KeyboardEvent) => {
    if (e.key.toLowerCase() === 'tab' || e.which === 9) {
      return
    }
    this.setState({ bounceFocus: true })
  }

  private handleSubjectChange = (e: React.SyntheticEvent, data: any) => {
    this.setState({ subject: data.value })
  }

  private handleTemplateChange = (e: React.SyntheticEvent, { value }: any) => {
    const { user } = this.state

    const template: any = emailMessageTemplateOptions[value].template
    let html = template.html || ''

    if (template.vars) {
      template.vars.forEach((item: string) => {
        const re = new RegExp(`\\[\\[${item}\\]\\]`, 'g')
        switch (item) {
          case 'USER_FIRST_NAME':
            const userFirstName = user.firstName ? user.firstName : null
            html = html.replace(re, userFirstName || 'REPLACE_WITH_YOUR_FIRST_NAME')
            break
          case 'USER_PHONE_NUMBER':
            const userPhone = user.phones ? user.phones.find((phone: PhoneType) => phone.type === 'Work') : null
            html = html.replace(re, (userPhone && userPhone.value) || 'REPLACE_WITH_YOUR_PHONE_NUMBER')
            break
          default:
            break
        }
      })
    }
    this.setState({
      messageHtml: html,
      subject: template.subject || '',
      template: value
    })
  }

  private handleMessageChange = (content: any, delta: any, source: any, editor: any) => {
    this.setState({
      messageDelta: editor.getContents().ops,
      messageHtml: content,
      messageText: editor.getText()
    })
  }

  private handleAttachmentChange = (attachments: AttachmentType[]) => {
    this.setState({ attachments })
  }

  private handleSendMessage = async () => {
    const { attachments, messageDelta, messageText, subject, toAddresses, user } = this.state

    const deltaConverterOptions = {
      inlineStyles: {
        indent: (value: any, op: any) => {
          const indentSize = parseInt(value, 10) * 3
          const side = op.attributes['direction'] === 'rtl' ? 'right' : 'left'
          return 'padding-' + side + ':' + indentSize + 'em'
        },
        size: {
          huge: 'font-size: 24px',
          large: 'font-size: 18px',
          normal: 'font-size: 16px',
          small: 'font-size: 12px'
        }
      }
    }

    const converter = new QuillDeltaToHtmlConverter(messageDelta, deltaConverterOptions)
    let html = converter.convert()

    let signatureHtml = `<p>${user.firstName} ${user.lastName}</p>`
    const data: any = await getSignatureData(user._id)
    if (data && data.signature) {
      const {
        signature,
        signature: { templateId }
      } = data
      signatureHtml = signatureTemplates[templateId || 0](signature)
    }

    html = html.replace(/\[\[EMBEDDED_SIGNATURE\]\]/g, signatureHtml)
    html = html || ' '

    const input = {
      attachments: attachments.map((attachment: AttachmentType) => attachment._id),
      html,
      subject,
      text: messageText || ' ',
      to: toAddresses
    }
    try {
      await sendMessageEmail(input)
      this.closeSelf()
    } catch (error) {
      serverToast(error)
    }
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({
        transition: true
      })
      onClose()
    }, 300)
  }
}

export default ComposeModal
