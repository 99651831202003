import { Dropdown, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Chat from 'design/icons/teams/chat.png'
import Envalope from 'design/icons/teams/icon-envalope.png'
import Phone from 'design/icons/teams/icon-phone.png'

import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background?: string
  left?: number
  color?: string
}

export const Container = styled.div`
  ${y};
  padding: 0;
  height: 100%;
  overflow: hidden;

  .disabled {
    opacity: 0.4;
  }
`

export const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

export const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

export const StyledStatusDropdown = styled(Dropdown)`
  background: ${(props: Props) => props.background || props.background};
  text-align: -webkit-center !important;
  width: auto;
  min-width: 70px;

  &.ui.inline.dropdown .dropdown.icon {
    color: ${(props: Props) => props.color || props.color};
  }

  &.ui.dropdown .menu {
    left: unset;
    top: unset;
  }

  &.ui.dropdown {
    position: unset;
  }

  &.ui.inline.dropdown>.text {
    color: ${(props: Props) => props.color || props.color};
    margin-left: 5px;
    font-weight: 500;
  }

  &.actionDropdown {
    text-align: left !important;
  }
`

export const StyledName = styled.div`
  display: flex;
  align-items: center;

  .postText {
    font-size: 11px;
  }
`

export const IconContainer = styled.div`
  display: flex;
  margin-left: 10px;
`

export const FullName = styled.div`
  width: 100px;
  word-break: break-all;
  text-transform: capitalize;
  cursor: pointer;
`

export const EmailIcon = styled.div`
  background: url(${Envalope});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 20px;
  cursor: pointer;
`

export const PhoneIcon = styled.div`
  background: url(${Phone});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 12px;
  cursor: pointer;
`

export const ChatIcon = styled.div`
  background: url(${Chat});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 10px;
`

interface ImageProps {
  backgroundImage: string
}

export const ImageWrapper = styled.div`
  background: ${(props: ImageProps) =>
    `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
  align-self: center;
  margin: 0px;
  margin-right: 5px;
  margin-left: 10px;
`

export const ImageContainer = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
  align-self: center;
  margin-right: 6px;
  border-radius: 50%;
`

export const ErrorContainer = styled.div`
  padding: 30px;
  font-size: 20px;
`

export const Error = styled.div`
  font-size: 30px;
  text-align: center;
  color: ${Colors.Red10};
`

export const ErrorText = styled.div`
  font-size: 30px;
  color: ${Colors.Black500};
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
  }
`
