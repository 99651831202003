// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Dropdown, Menu } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'
import { VictoryPie } from 'victory'

// Import Components
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Toast, { serverToast } from 'shared/Toast/Toast'
import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'
import { ToggleLeadStatus } from '../../Dashboard/Leads'
import { editLead, loggedUser, ShowUpdateScoreModal } from '../LeadDetail'

import { navigationMockData } from './NavigationMockData'
import NotesModal from './NotesModal'
import ReassignLeadModal from './ReassignLeadModal'
import ShareWithModal from './ShareWith'

// Import Store Types, Actions and Reducers
import { SubjectEnum } from 'app/Onboarding/Types'
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Leads/Actions'
import { LeadItemsDetails, LeadNotes, LeadShareWith, OptionType, UserType } from 'store/Leads/Types'

import { sendAccountLink } from 'app/Onboarding/Queries'
import { getTransactionBoards } from 'app/Transactions/Dashboard/TransactionQueries'
import { isPlanAllowed } from 'shared/Billing/Queries'
import { updateLeadStatus } from '../../Dashboard/LeadMutations'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Container,
  ContentWrapper,
  Email,
  FlexWrapper,
  FullName,
  Icon,
  Info,
  Labels,
  Name,
  Pencil,
  People,
  PeopleName,
  PeopleStyledPopup,
  PersonContainer,
  ProfileProgressContainer,
  Role,
  Score,
  SharedWith,
  Status,
  StyledMenu,
  StyledPopup,
  Wrapper
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faMapMarkerAlt, faPhone, faRedo, faTrash } from '@fortawesome/pro-light-svg-icons'
import { faArchive, faCommentAltPlus, faHandHoldingUsd, faLayerPlus } from '@fortawesome/pro-regular-svg-icons'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faArchive,
  faCaretRight,
  faCommentAltPlus,
  faEnvelope,
  faHandHoldingUsd,
  faLayerPlus,
  faMapMarkerAlt,
  faPhone,
  faRedo,
  faTrash
])

interface StoreProps {
  setArchivedLeads: (data: LeadItemsDetails) => void
  setNewNotes: (note: LeadNotes) => void
  setUpdateNotes: (note: LeadNotes) => void
  setDeleteNotes: (id: string) => void
  setNotesData: (notes: LeadNotes[]) => void
  setShowNoteModal: (data: boolean) => void
  setContactTransactionPanel: (data: number) => void
  notes: LeadNotes[]
  showNote: boolean
  leadData: LeadItemsDetails
  leadPool: boolean
}

interface OwnProps {
  shareUser: (user: LeadShareWith) => void
  deleteShareUser: (userId: string) => void
  toggleStatus: boolean
  leadData: LeadItemsDetails
}

type Props = OwnProps & StoreProps & RouteComponentProps<{}>

interface State {
  activeItem: string
  addBoardId: string
  date: Date
  showNotesModal: boolean
  showReassignLeadModal: boolean
  showShareWithModal: boolean
  activeIndex: number
  status: OptionType
  showPeopleDetails: boolean
  showAddTransactionModal: boolean
  user: UserType
  showComposeMail: boolean
  showComposeMailForShare: boolean
  emailID: string
}

const StatusOptions = [
  { key: 1, text: 'New', value: 'New' },
  { key: 2, text: 'In Process', value: 'In Process' },
  { key: 3, text: 'Failed', value: 'Failed' },
  { key: 4, text: 'Closed', value: 'Closed' }
]

class LeftPanel extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    activeItem: '',
    addBoardId: '',
    date: new Date(),
    emailID: '',
    showAddTransactionModal: false,
    showComposeMail: false,
    showComposeMailForShare: false,
    showNotesModal: false,
    showPeopleDetails: false,
    showReassignLeadModal: false,
    showShareWithModal: false,
    status: {} as OptionType,
    user: {} as UserType
  }

  public getDataResidential = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public async componentDidMount() {
    const { setNotesData, leadData } = this.props
    setNotesData(leadData.notes)
    this.setState({
      status: {
        key: 1,
        text: leadData.status,
        value: leadData.status
      }
    })
    const transactions = await getTransactionBoards({})
    const newBoardId = transactions.filter((board: any) => {
      return board.name === 'New'
    })
    const user = await getLoggedInUser()
    this.setState({ addBoardId: newBoardId[0]._id, user })
  }

  public render() {
    const { leadData, notes, showNote, toggleStatus } = this.props
    const {
      activeItem,
      addBoardId,
      emailID,
      showAddTransactionModal,
      showComposeMail,
      showComposeMailForShare,
      showNotesModal,
      showReassignLeadModal,
      showShareWithModal,
      status,
      user
    } = this.state
    let navigation
    const contentDisplayState = loggedUser._id === leadData[`owner`]._id

    if (!toggleStatus) {
      navigation = navigationMockData.map((items: any, index: any) => {
        return (
          <Menu.Item key={index} name={items.name} active={activeItem === items.name} onClick={this.handleItemClick}>
            <FontAwesomeIcon icon={['far', items.icon]} />
            {items.name === 'Reassign Lead' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {items.name}{' '}
                {leadData.reassignHistory && leadData.reassignHistory.length > 0 ? (
                  <Labels circular={true}>{leadData.reassignHistory.length}</Labels>
                ) : null}
              </div>
            ) : items.name === 'Notes' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {items.name}{' '}
                {leadData.notes && leadData.notes.length > 0 ? <Labels circular={true}>{notes.length}</Labels> : null}{' '}
              </div>
            ) : items.name === 'Archive Lead' && !contentDisplayState ? null : (
              <div>{items.name}</div>
            )}
            <FontAwesomeIcon icon={['fas', items.caretRight]} />
          </Menu.Item>
        )
      })
    } else {
      const option = navigationMockData.filter((data: any) => {
        return data.name !== 'Create Transaction' && data.name !== 'Notes' && data.name !== 'Archive Lead'
      })
      navigation = option.map((items: any, index: any) => {
        return (
          <Menu.Item key={index} name={items.name} active={activeItem === items.name} onClick={this.handleItemClick}>
            <FontAwesomeIcon icon={['far', items.icon]} />
            {<div>{items.name}</div>}
            <FontAwesomeIcon icon={['fas', items.caretRight]} />
          </Menu.Item>
        )
      })
    }

    let color = ''
    if (leadData.leadScore >= 0 && leadData.leadScore < 25) {
      color = Colors.DarkBlue205
    } else if (leadData.leadScore >= 25 && leadData.leadScore < 50) {
      color = Colors.Grey950
    } else if (leadData.leadScore >= 50 && leadData.leadScore < 75) {
      color = Colors.Green80
    } else {
      color = Colors.Red10
    }

    const leadSharedWith = leadData.shareWith.map((items: any, index: number) => {
      return (
        <PeopleStyledPopup
          key={index}
          trigger={
            items.status === 'Pending' ? (
              <People background={items.profileImage} style={{ opacity: 0.5 }} />
            ) : (
              <People background={items.profileImage} />
            )
          }
          content={
            <PeopleName>
              <div style={{ fontWeight: 600 }}>
                {items.firstName} {items.lastName}
              </div>
              {items.type !== null && <div>{items.type}</div>}
              <Email>
                {items.status !== 'Pending' && (
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      this.mailTo(items)
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'envelope']} />
                  </span>
                )}
                {items.status !== 'Pending' && (
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      this.callTo(items)
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'phone']} />
                  </span>
                )}
                {((items.status !== 'Pending' && loggedUser.role !== 'GUEST' && loggedUser.role !== 'AGENT') ||
                  loggedUser._id === items._id) && (
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      this.viewTo(items)
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'eye']} />
                  </span>
                )}
                {(loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER') &&
                  leadData.owner._id !== items._id && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.deleteTo(items)
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'trash']} />
                    </span>
                  )}
                {items.status === 'Pending' &&
                  loggedUser.role !== 'GUEST' && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.resendMail(items)
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'redo']} />
                    </span>
                  )}
              </Email>
            </PeopleName>
          }
          position="bottom center"
          hoverable={true}
        />
      )
    })
    return (
      <div>
        {showComposeMailForShare && <ComposeModal onClose={this.closeModal} defaultEmail={emailID} />}
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={leadData.email} />}
        {showAddTransactionModal &&
          leadData && (
            <CreateTransactionModal
              mutationParams={{
                addBoardId,
                edit: false,
                index: '0',
                leadId: leadData._id,
                show: false,
                user
              }}
              onClose={this.addTransaction}
            />
          )}
        {showReassignLeadModal && <ReassignLeadModal closeModal={this.closeModal} leadData={leadData} />}
        {showShareWithModal && (
          <ShareWithModal
            closeModal={this.closeModal}
            leadData={leadData}
            showUser={this.shareWith}
            shareWith={leadData.shareWith}
          />
        )}
        {(showNote || showNotesModal) && (
          <NotesModal
            closeModal={this.closeModal}
            addNewNotes={this.addNewNotes}
            notesData={notes}
            editNotes={this.editNotes}
            deleteNotes={this.deleteNotes}
            leadData={leadData}
          />
        )}
        <Container>
          <ProfileProgressContainer>
            <div style={{ position: 'relative', cursor: 'pointer' }}>
              <FullName>
                {leadData.firstName.charAt(0).toUpperCase()}
                {leadData.lastName.charAt(0).toUpperCase()}
                <Pencil
                  onClick={e => {
                    e.stopPropagation()
                    editLead(leadData)
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
                </Pencil>
              </FullName>
              <svg viewBox="0 0 400 400">
                <VictoryPie
                  standalone={false}
                  animate={{ duration: 5000 }}
                  width={400}
                  height={400}
                  data={this.getDataResidential(leadData.leadScore)}
                  innerRadius={175}
                  labelRadius={120}
                  padding={0}
                  startAngle={0}
                  endAngle={360}
                  labels={['R : ', '30%']}
                  colorScale={[color, Colors.Black950]}
                />
              </svg>
            </div>
            <Info>
              <Name>
                {leadData.firstName} {leadData.lastName}
              </Name>
              <Score>
                <b>Score:</b> {leadData.leadScore}{' '}
                <span
                  onClick={e => {
                    e.stopPropagation()
                    ShowUpdateScoreModal(leadData)
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
                </span>
              </Score>
              <Role>{`${leadData.propertyType} - ${leadData.type}`}</Role>
              <Icon>
                <StyledPopup
                  trigger={
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.sendMail()
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'envelope']} />
                    </span>
                  }
                  content={leadData.email ? leadData.email : 'No Email Added'}
                />
                <StyledPopup
                  trigger={<FontAwesomeIcon icon={['fal', 'phone']} />}
                  content={leadData.phone ? leadData.phone : 'No Phone Number Added'}
                />
              </Icon>
            </Info>
          </ProfileProgressContainer>
        </Container>
        {!toggleStatus && (
          <Status>
            {Strings.leads.status}
            <Dropdown
              placeholder="Select Status"
              fluid={true}
              selection={true}
              value={status.value}
              options={StatusOptions}
              onChange={this.handleChange}
            />
          </Status>
        )}
        <div style={{ background: Colors.White1000 }}>
          <StyledMenu secondary={true} vertical={true}>
            {navigation}
          </StyledMenu>
        </div>
        <ContentWrapper>
          {toggleStatus === false && (
            <div>
              <SharedWith>
                <span>{Strings.generalText.invite}</span>
                <span onClick={this.showShareWith}>{Strings.generalText.addInvite}</span>
              </SharedWith>
              <FlexWrapper>
                <PersonContainer onMouseLeave={() => this.showPeopleDetails(false)}>
                  <Wrapper>{leadSharedWith}</Wrapper>
                </PersonContainer>
              </FlexWrapper>
            </div>
          )}
        </ContentWrapper>
      </div>
    )
  }

  private resendMail = async (user: UserType) => {
    try {
      await sendAccountLink({
        type: SubjectEnum.Invite,
        userName: user.userName
      })
      Toast({ message: 'Mail Sent successfully', type: 'success' })
    } catch (e) {
      serverToast(e)
    }
  }

  private sendMail = () => {
    this.setState({ showComposeMail: true })
  }

  private addTransaction = async (result: any) => {
    const { setContactTransactionPanel } = this.props
    this.closeModal()

    if (!result) {
      return
    }
    setContactTransactionPanel(1)
    this.props.history.push({
      pathname: `${Routes.contacts.root}${Routes.contacts.details.path}/${result.contact._id}?pool=0`
    })
  }

  private mailTo = (items: LeadShareWith) => {
    this.setState({ showComposeMailForShare: true, emailID: items.lastName })
  }

  private viewTo = (items: LeadShareWith) => {
    this.props.history.push({
      pathname: `${Routes.primary.profile.path}/${items._id}`
    })
  }

  private shareWith = (item: LeadShareWith) => {
    const { shareUser } = this.props
    shareUser(item)
  }

  private deleteTo = (item: LeadShareWith) => {
    const { deleteShareUser } = this.props
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this user!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        deleteShareUser(item._id)
        ConfirmAlert('Deleted!', 'Your user has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your user is safe', 'error')
      }
    })
  }

  private showPeopleDetails = (showPeopleDetails: boolean) => {
    this.setState({
      showPeopleDetails
    })
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement> | any, { value }: any) => {
    const { leadData } = this.props
    this.setState({
      status: {
        key: 1,
        text: leadData.value,
        value: leadData.value
      }
    })
    ToggleLeadStatus(leadData._id, value)
  }

  private callTo = (item: any) => {
    window.location.href = `tel:${item.phones[0].value}`
  }

  private deleteNotes = (id: string) => {
    const { setDeleteNotes } = this.props
    setDeleteNotes(id)
  }

  private editNotes = (note: LeadNotes) => {
    const { setUpdateNotes } = this.props
    setUpdateNotes(note)
  }

  private addNewNotes = (note: LeadNotes) => {
    const { setNewNotes } = this.props
    setNewNotes(note)
  }

  private closeModal = () => {
    const { setShowNoteModal } = this.props
    setShowNoteModal(false)
    this.setState({
      activeItem: '',
      showAddTransactionModal: false,
      showComposeMail: false,
      showComposeMailForShare: false,
      showNotesModal: false,
      showReassignLeadModal: false,
      showShareWithModal: false
    })
  }

  private handleItemClick = async (e: React.SyntheticEvent<EventTarget>, { name }: any) => {
    const { leadData, setArchivedLeads } = this.props
    this.setState({ activeItem: name })
    if (name === 'Notes') {
      this.setState({ showNotesModal: true })
    }
    if (name === 'Reassign Lead') {
      this.setState({ showReassignLeadModal: true })
    }
    if (name === 'Archive Lead') {
      ConfirmAlert({
        showCancelButton: true,
        text: `${Strings.kanbanView.archiveSure} ${leadData.firstName} ${leadData.lastName}`,
        title: Strings.kanbanView.sure,
        type: 'warning'
      }).then(result => {
        if (result.value) {
          this.props.history.push({
            pathname: `${Routes.primary.leads.path}`
          })
          updateLeadStatus(leadData._id, 'Closed', loggedUser)
          setArchivedLeads(leadData)
        }
      })
    }
    if (name === 'Create Transaction') {
      try {
        await isPlanAllowed('transaction')
      } catch (error) {
        return serverToast(error)
      }
      const user = await getLoggedInUser({ fromCache: true })
      if (user.role === 'GUEST') {
        Toast({
          message:
            'You are not authorized to create a transaction. Please contact your administrator if you need additional assistance',
          type: 'error'
        })
      } else {
        this.setState({ showAddTransactionModal: true })
      }
    }
  }

  private showShareWith = () => {
    const { status } = this.state
    if (status.value !== 'Closed') {
      this.setState({ showShareWithModal: true })
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  leadPool: state.leads.leadPool,
  notes: state.leads.notes,
  showNote: state.leads.showNoteModal
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setArchivedLeads: Actions.archivedLeads,
      setContactTransactionPanel: Actions.setContactTransactionPanel,
      setDeleteNotes: Actions.deleteNotes,
      setNewNotes: Actions.addNewNotes,
      setNotesData: Actions.getNotesData,
      setShowNoteModal: Actions.showNoteModal,
      setUpdateNotes: Actions.editNotes
    }
  )(LeftPanel)
)
