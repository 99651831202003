import gql from 'graphql-tag'

export const GET_GROUPS = gql`
  query getGroups(
    $orderField: String
    $sortOrder: Int
    $id: String
    $userOrderField: String
    $sortUserOrder: Int
    $filter: UserSearch
    $office: String
    $singleSkip: Int
  ) {
    getGroups(
      where: { isActive: true, office: $office }
      sorting: { field: $orderField, order: $sortOrder }
      _id: $id
      singleSorting: { field: $userOrderField, order: $sortUserOrder }
      filter: $filter
      singleSkip: $singleSkip
    ) {
      _id
      name
      groupOrder
      isActive
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      users {
        _id
        firstName
        lastName
        role
        phones {
          type
          value
        }
        addresses {
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        userName
        status
        profileImage
        office {
          _id
          branchName
        }
      }
    }
  }
`

export const GET_GROUP_USERS = gql`
  query getGroupUsers(
    $id: String
    $orderField: String
    $sortOrder: Int
    $office: String
    $singleSkip: Int
  ) {
    getGroups(
      where: { isActive: true, office: $office }
      _id: $id
      sorting: { field: $orderField, order: $sortOrder }
      singleSkip: $singleSkip
    ) {
      _id
      name
      groupOrder
      isActive
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      users {
        _id
        firstName
        lastName
        role
        phones {
          type
          value
        }
        addresses {
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        userName
        status
        profileImage
        office {
          _id
          branchName
        }
      }
    }
  }
`

export const GET_GROUP = gql`
  query getGroup(
    $orderField: String
    $sortOrder: Int
    $_id: String
    $office: String
  ) {
    getGroups(
      where: { _id: $_id, isActive: true, office: $office }
      sorting: { field: $orderField, order: $sortOrder }
    ) {
      _id
      name
      groupOrder
      isActive
    }
  }
`

export const GET_GROUP_ID = gql`
  query getGroupId($name: String) {
    getGroups(
      where: { name: $name, isActive: true }
      sorting: { field: "groupOrder", order: 1 }
    ) {
      _id
      name
      isActive
    }
  }
`
