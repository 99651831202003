// Import Packages
import { IconName } from '@fortawesome/free-solid-svg-icons'
import * as React from 'react'

// Import Styled Components
import {
  StyledIcon,
  StyledSegment,
  Text
} from './Styled'

const DetailItem = ({
  icon,
  text
}: {
  icon: IconName,
  text: string
}) => {
  return (
    <StyledSegment>
      <StyledIcon icon={['fas', icon]} className="fa-sm" />
      <Text>{text}</Text>
    </StyledSegment>
  )
}

export default DetailItem
