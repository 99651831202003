// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const StyledForm = styled(Form)`
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
.loader-main{
    min-height:150px;
    display: flex;
    align-items: center;
    justify-content: center;    
}
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  &.ui.form .field.disabled>label, .ui.form .fields.disabled>label {
    opacity: 1;
  }

  &.ui.form .field.disabled>.ui.dropdown:not(.button)>.default.text  {
    color: ${Colors.Black50};
  }

  &.ui.form .field.disabled>.selection.dropdown {
    background-color: ${Colors.Black950};
  }

  &.ui.form .field>.selection.dropdown {
    width: 70%;
  }

  &.ui.form .ui.input {
    width: 75% !important;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200} !important;
    color: ${Colors.White1000} !important;
  }
    .ui.loading.disabled.button{
    min-width:117px;
    }
`

export {
  Container,
  StyledForm,
}
