import { Button, Grid, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import {
  children,
  link,
  primaryStyledButton,
  scrollYContainer,
  text,
  x,
  y
} from 'design/Styled'

export const Container = styled.div`
  ${y};
  flex: 1;
  overflow: hidden;
`

export const ScrollY = styled.div`
  ${scrollYContainer};
  padding-top: 2em;
  padding-bottom: 2em;
`

export const Title = styled.h1`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  font: ${fonts.large.medium};
  color: ${Colors.TextPrimary};
  text-align: center;

  span:nth-child(2) {
    color: ${Colors.Text};
  }
`

export const CardRow = styled.div`
  ${x};
  ${children};
  align-items: flex-end;
`

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
`

export const Footnote = styled.small`
  ${text};
  margin: 0.85em 0 0;
  width: 100%;
  text-align: center;
  font: ${fonts.xSmall.regular};
`

export const FootnoteLink = styled(Footnote)`
  ${link};
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    min-width: 330px;
    padding: 1.833em 2em;
    padding-bottom: 0;
    background: ${Colors.Container};
  }

  &.ui.popup:before {
    background: ${Colors.Container} !important;
  }
`

export const TableHeader = styled(Grid)`
  &.ui.grid {
    background: ${Colors.DarkBlue205};
    color: ${Colors.White1000};
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 15px !important;
    height: 20px;
  }

  &.ui.grid > .column:not(.row) {
    padding-top: 1px;
  }
`

export const Th = styled(Grid.Column)`
  text-transform: uppercase;
`

export const TableRow = styled(Grid)`
  &.ui.grid {
    margin-bottom: 20px !important;
    background: ${Colors.DarkBlue256};
    color: ${Colors.DarkBlue205};
    text-align: center;
  }

  & > div {
    border-right: 1px solid ${Colors.EmphasisLine};
  }
  & > div:last-child {
    border-right: none;
  }
`

export const Td = styled(Grid.Column)`
  ${x};
  color: ${Colors.Text};

  &.tier {
    font-weight: 600;
    text-transform: uppercase;
    color: ${Colors.TextPrimary};
  }

  &.cost {
    span {
      font-size: 20px;
    }
    .fa-dollar-sign {
      position: relative;
      top: -0.85em;
      width: 10px;
      height: 10px;
      font-size: 10px;
    }
  }
`
