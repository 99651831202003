import client from 'queries/apollo'

import { GET_PASSES, GET_PASSES_SECTION, HOT_SHEET_PASS } from 'queries/graphql/Passes/Queries'

export const getPassesSection = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PASSES_SECTION
  })

  return response.data.getPassSections
}

export const getPassesForSettings = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PASSES,
    variables: { usersCount: true }
  })

  return response.data.getPasses
}

export const getPasses = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PASSES,
    variables: { usersCount: false }
  })

  return response.data.getPasses
}

export const getPassesSearch = async (searchText?: string, passType?: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PASSES,
    variables: {
      passType: passType ? passType : undefined,
      searchText: searchText ? searchText : undefined,
      usersCount: false
    }
  })

  return response.data.getPasses
}

export const hotSheetPass = async (passId: string, timePeriod: number) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: HOT_SHEET_PASS,
    variables: { passId, timePeriod }
  })

  return response.data.hotSheetPass
}
