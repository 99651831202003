import { Accordion, Button } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import {
  children,
  primaryStyledButton,
  scrollYContainer,
  x,
  y
} from 'design/Styled'

interface Props {
  scale?: number
  backgroundImage?: string
}

export const Container = styled.section`
  ${x};
  ${children};
  flex: 1;
  align-items: stretch;
  overflow: hidden;
  height: 100%;

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .rp-scrollable-scroller {
    overflow: auto;
  }
`

export const Section = styled.section`
  ${y};
  background: ${Colors.EmphasisContainer};
  margin-left: 0;
`

export const Menu = styled.ul`
  ${y};
  min-width: 200px;
  max-width: 280px;
  list-style: none;
  margin: 0;
  padding: 0;
  background: ${Colors.Container};
  margin-left: 20px;
`

export const MenuItem = styled.li`
  ${x};
  padding: 0.8em;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;

  &.active {
    background-color: ${Colors.ButtonDisable};
  }
`

export const Row = styled.div`
  ${x};
  ${children};
  justify-content: flex-end;
  margin-bottom: 0.8em;
  padding: 20px;
`

export const Template = styled.article`
  display: block;
  transform: scale(${(props: Props) => props.scale || 1});
  transform-origin: 1 0;
  transition: all 300ms;
  height: 0in;
`

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
  margin-right: 2em !important;
`

export const Hidden = styled.div`
  display: none;
`

export const StyledAccordion = styled(Accordion)`
  &.accordion {
    width: 20%;
    ${scrollYContainer};
    padding: 1em;
    margin-right: 0;
    color: ${Colors.Text};
    user-select: none;
  }

  .pro-version {
    font-weight: bold;
    color: ${Colors.TextPrimary};
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    color: ${Colors.Text} !important;
  }

  .content > .title {
    margin-left: 2em;
  }

  .content > .content {
    margin-left: 1em;
    cursor: pointer;
    padding: 0.6em !important;
  }
`

export const Infograph = styled.div`
  background: ${(props: Props) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-self: center;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
`
