import SummaryPass1 from 'design/icons/dashboard/leadSummaryPass(1).jpg'
import SummaryPass2 from 'design/icons/dashboard/leadSummaryPass(2).jpg'
import SummaryPass3 from 'design/icons/dashboard/leadSummaryPass(3).jpg'
import SummaryPass4 from 'design/icons/dashboard/leadSummaryPass(4).jpg'

export const propertyMockData = [
  {
    listingId: 5941341,
    price: 100000,
    propertyAddress: '16609 Sonara St.Orange',
    propertySide: 'Buyer',
    transactionId: 10259
  },
  {
    listingId: 5941341,
    price: 100000,
    propertyAddress: '15175 Sunwood Blvd Tukvilla',
    propertySide: 'Seller',
    transactionId: 10259
  }
]

export const cardMockData = [
  {
    chart: SummaryPass1,
    header: 4,
    meta: 'New',
    month: '3',
    trend: 'Monthly Trend'
  },
  {
    chart: SummaryPass2,
    header: 3,
    meta: 'Payable',
    month: '3',
    trend: 'Monthly Trend'
  },
  {
    chart: SummaryPass4,
    header: 2,
    meta: 'Received',
    month: '3',
    trend: 'Monthly Trend'
  },
  {
    chart: SummaryPass3,
    header: 3,
    meta: 'Updated',
    month: '3',
    trend: 'Monthly Trend'
  }
]
