// Import Packages
import { sortBy } from 'lodash'

// Import Components
import { getLoggedInUser } from 'utils'

// Import Colors
import Colors from 'design/Colors'

const ListViewCircleTextColors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Grey850}`,
  `${Colors.Green560}`
]

let loggedUser: any = {}
export const formatKanbanData = (newTeamData: any, teamSort: boolean) => {
  getLoggedInUser().then((response: any) => {
    loggedUser = response
  })
  const teamListData = Object.assign(newTeamData)
  for (const teamlist of teamListData) {
    if (teamlist.teams) {
      teamlist.color = getRandomColors()
      teamlist.name = teamlist.branchName
      if (teamlist.teams === null) {
        teamlist.items = Object.assign([])
      } else {
        teamlist.items = Object.assign(teamlist.teams)
      }
      if (loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER') {
        teamlist.showAddButton = true
      } else {
        teamlist.showAddButton = false
      }
      if (teamlist.items.length > 0) {
        const { items } = teamlist
        // tslint:disable-next-line:forin
        for (const index in items) {
          items[index].teamName = items[index].name
          if (items[index].teamCap) {
            items[index].cap =
              items[index].teamCap.residentialTargetCap +
              items[index].teamCap.commercialTargetCap
          }
          if (items[index].members !== null) {
            items[index].pendingInvites = items[index].members.length
          } else {
            items[index].pendingInvites = 0
          }
          if (items[index].leader) {
            const leader = {
              email: items[index].leader.userName,
              firstName: items[index].leader.firstName,
              lastName: items[index].leader.lastName,
              phone: items[index].leader.phone,
              profileImage: items[index].leader.profileImage
            }
            items[index] = { ...items[index], ...leader }
          }
        }
      }
    }
  }
  if (!teamSort) {
    sortBy(teamListData, ['teamOfficeOrder'])
  }
  return teamListData
}

export const reformatData = (newTeamListData: any) => {
  const teamListData = Object.assign(newTeamListData)
  for (const teamlist of teamListData.getOfficeTeams) {
    if (teamlist.items || teamlist.teams) {
      teamlist.color = getRandomColors()
      if (teamlist.items === null || teamlist.items === undefined) {
        teamlist.teams = Object.assign([])
      } else {
        teamlist.teams = Object.assign(teamlist.items)
      }
      if (teamlist.teams.length > 0) {
        const { teams } = teamlist
        // tslint:disable-next-line:forin
        for (const index in teams) {
          if (teams[index].firstName) {
            delete teams[index].firstName
            delete teams[index].lastName
            delete teams[index].profileImage
            delete teams[index].phone
            delete teams[index].email
          }
        }
      }
    }
  }
  return teamListData
}

export const formatListData = (newTeamListData: any) => {
  // let loggedUser: any = {}
  // getLoggedInUser().then((response: any) => {
  //   loggedUser = response
  // })
  const teamListData = Object.assign(newTeamListData)
  // tslint:disable-next-line:forin
  for (const index in teamListData) {
    teamListData[index].teamName = teamListData[index].name
    teamListData[index].branchName = teamListData[index].office.branchName
    if (teamListData[index].members !== null) {
      teamListData[index].pendingInvites = teamListData[index].members.length
    } else {
      teamListData[index].pendingInvites = 0
    }
    if (loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER') {
      teamListData[index].showAddButton = true
    } else {
      teamListData[index].showAddButton = false
    }
    if (teamListData[index].teamCap) {
      teamListData[index].cap =
        teamListData[index].teamCap.residentialTargetCap +
        teamListData[index].teamCap.commercialTargetCap
    }
    if (teamListData[index].leader) {
      const leader = {
        email: teamListData[index].leader.userName,
        firstName: teamListData[index].leader.firstName,
        lastName: teamListData[index].leader.lastName,
        phone: teamListData[index].leader.phone,
        profileImage: teamListData[index].leader.profileImage,
        userName: teamListData[index].leader.userName
      }
      teamListData[index] = { ...teamListData[index], ...leader }
    }
  }
  return teamListData
}

export const reformatListData = (newteamListData: any) => {
  const teamListData = Object.assign(newteamListData.getTeams)
  // tslint:disable-next-line:forin
  for (const index in teamListData) {
    delete teamListData[index].firstName
    delete teamListData[index].lastName
    delete teamListData[index].profileImage
    delete teamListData[index].phone
    delete teamListData[index].email
    delete teamListData[index].userName
  }
  return teamListData
}

const getRandomColors = () => {
  return ListViewCircleTextColors[
    Math.floor(Math.random() * ListViewCircleTextColors.length)
  ]
}
