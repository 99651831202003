const Actions = {
  teamAddNewBoard: 'ADD_TEAM_NEW_BOARD',
  teamAddNewCard: 'ADD_TEAM_NEW_CARD',
  teamDeleteCard: 'DELETE_TEAM_CARD',
  teamFilter: 'TEAM_FILTER',
  teamGetBoardData: 'GET_TEAM_BOARD_DATA',
  teamResetCardData: 'RESET_TEAM_CARD_DATA',
  teamRestoreCard: 'RESTORE_TEAM_CARD',
  teamSearchData: 'TEAM_SEARCH_DATA',
  teamSearchLoader: 'TEAM_SEARCH_LOADER',
  teamSetNewBoardName: 'SET_TEAM_NEW_BOARD_NAME',
  teamSetNewDescription: 'SET_TEAM_NEW_DESCRIPTION',
  teamSetNewIcon: 'SET_TEAM_NEW_ICON'
}

export default Actions
