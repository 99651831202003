import { validatePassword } from 'shared/PasswordInputs/Validation'
import { notNumbers, validEmail, validName, validPhone } from 'utils/Validation'

import { ErrorType, FormErrorType, FormType } from './Types'

export const handleValidation = (fields: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!fields.firstName) {
    formIsValid = false
    errors.firstName = 'First name can not be blank'
  } else if (!fields.firstName.match(validName)) {
    formIsValid = false
    errors.firstName = 'First name can only include letters, dashes and apostrophes'
  }

  if (!fields.lastName) {
    formIsValid = false
    errors.lastName = 'Last name can not be blank'
  } else if (!fields.lastName.match(validName)) {
    formIsValid = false
    errors.lastName = 'Last name can only include letters, dashes and apostrophes'
  }

  if (!fields.email || !fields.email.match(validEmail)) {
    formIsValid = false
    errors.email = 'You must include a valid email address'
  }

  const phone = fields.phone ? fields.phone : ''
  if (!fields.phone || !phone.replace(notNumbers, '').match(validPhone)) {
    formIsValid = false
    errors.phone = 'You must include a 10-digit phone number'
  }

  const { errors: passwordError } = validatePassword(fields)
  if (passwordError.password) {
    formIsValid = false
    errors.password = passwordError.password
  }

  const result: ErrorType = { errors, formIsValid }
  return result
}
