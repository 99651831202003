// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import { GET_MESSAGE_EMAIL_BY_ID } from 'queries/graphql/MessageEmail/Queries'

export const getMessageEmailById = async (id: string) => {
  const query = await client.query({
    query: GET_MESSAGE_EMAIL_BY_ID,
    variables: {
      whereId: id
    }
  })

  let result: any = false
  if (query.data && query.data.getMessageEmailById) {
    result = query.data.getMessageEmailById.results[0]
  }
  return result
}
