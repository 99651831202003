// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Button, Form, Icon, Input } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import Toast from 'shared/Toast'
import { addManagerNote, deleteManagerNotes, updateManagerNote } from '../../../../../Dashboard/TransactionMutations'
import { getManagerNote } from '../../../../../Dashboard/TransactionQueries'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'
import { ManagerNotes, UserType } from 'store/Transactions/Types'
import { handleValidation } from './Validation'

import { ErrorType } from './Types'

// Import Utils
import { getLoggedInUser } from 'utils'

// Import Styled Components
import { Container, Date, Description, Propfile, StyledForm, StyledNotes } from './Styled'

interface StoreProps {
  setNewManagerNote: (newData: ManagerNotes) => void
  setUpdateManagerNote: (id: string, newData: ManagerNotes) => void
  setManagerNotes: (notes: ManagerNotes[]) => void
  setDeleteManagerNote: (id: string) => void
  transactionId: string
  managerNotes: ManagerNotes[]
  user: UserType
}

interface OwnProps {
  transactionId: string
  user: UserType
}

type Props = StoreProps & OwnProps

interface State {
  id: string
  note: string
  showEditButton: boolean
  showConfirmationModal: boolean
  errors: ErrorType
  role: string
  userId: string
  loader: boolean
}

class Notes extends React.Component<Props, State> {
  public state = {
    errors: {} as ErrorType,
    id: '',
    loader: false,
    note: '',
    role: '',
    showConfirmationModal: false,
    showEditButton: false,
    userId: ''
  }

  public async componentDidMount() {
    const { setManagerNotes, transactionId } = this.props
    const respone = await getManagerNote(transactionId)
    setManagerNotes(respone)
    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({
      role: user.role,
      userId: user._id
    })
  }

  public render() {
    const { managerNotes } = this.props
    const { note, showEditButton, errors, role, userId, loader } = this.state
    const notes = managerNotes.map((items: any, index: any) => {
      return (
        <Container key={index}>
          {(role === 'ADMIN' || role === 'MANAGER') && (
            <>
              <Propfile background={items.createdBy.profileImage} />
              <div>
                <Description>{items.description}</Description>
                <Date>
                  {this.dateMatch(moment(items.createdAt).format('ll')) === 0 && (
                    <div style={{ flexGrow: 1 }}>Today</div>
                  )}
                  {this.dateMatch(moment(items.createdAt).format('ll')) === -1 && (
                    <div style={{ flexGrow: 1 }}>Yesterday</div>
                  )}
                  {this.dateMatch(moment(items.createdAt).format('ll')) !== 0 &&
                    this.dateMatch(moment(items.createdAt).format('ll')) !== -1 && (
                      <div style={{ flexGrow: 1 }}>{moment(items.createdAt).format('ll')}</div>
                    )}
                  {userId === items.createdBy._id && (
                    <div style={{ marginRight: '10px' }}>
                      <Icon
                        name="pencil"
                        onClick={() => {
                          this.editNotes(items)
                        }}
                      />
                      <Icon
                        name="trash"
                        onClick={() => {
                          this.deleteNotes(items._id)
                        }}
                      />
                    </div>
                  )}
                </Date>
              </div>
            </>
          )}
        </Container>
      )
    })
    return (
      <div style={{ paddingLeft: 30 }}>
        <StyledForm size={'small'}>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              name="note"
              placeholder="Add a note"
              onChange={this.handleChange}
              value={note}
              error={errors[`note`]}
            />
            {loader ? (
              <Form.Field control={Button} loading={true} content="LOADING" />
            ) : showEditButton ? (
              <Form.Field control={Button} content="EDIT" onClick={this.updateNotes} />
            ) : (
              <Form.Field control={Button} content="SUBMIT" onClick={this.saveNotes} />
            )}
          </Form.Group>
        </StyledForm>
        <StyledNotes>{notes}</StyledNotes>
      </div>
    )
  }

  private dateMatch = (date: string) => {
    const todayDate = moment()
    const a = moment(date)
    const b = moment(todayDate)
    return a.diff(b, 'days')
  }

  private deleteNotes = async (id: string) => {
    const { setDeleteManagerNote } = this.props
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this manager note!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        try {
          await deleteManagerNotes(id)
          setDeleteManagerNote(id)
          ConfirmAlert('Deleted!', 'Your manager note has been deleted.', 'success')
        } catch (error) {
          Toast({ message: error.message, type: 'error' })
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your manager note is safe', 'error')
      }
    })
  }

  private editNotes = (data: any) => {
    this.setState({
      id: data._id,
      note: data.description,
      showEditButton: true
    })
  }

  private updateNotes = async () => {
    const { setUpdateManagerNote } = this.props
    const { note, id } = this.state
    const result = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      this.setState({ loader: true })
      try {
        const data = await updateManagerNote(id, note.trim())
        setUpdateManagerNote(id, data)
        if (data) {
          this.setState({ loader: false })
        }
      } catch (error) {
        this.setState({ loader: false })
        Toast({ message: error.message, type: 'error' })
      }
      this.setState({ id: '', note: '', showEditButton: false })
    }
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    this.setState({ note: value })
  }

  private saveNotes = async () => {
    const { setNewManagerNote, transactionId } = this.props
    const { note } = this.state
    const result = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      this.setState({ loader: true })
      try {
        const data = await addManagerNote(note.trim(), transactionId)
        setNewManagerNote(data)
        if (data) {
          this.setState({ loader: false })
        }
      } catch (error) {
        this.setState({ loader: false })
        Toast({ message: error.message, type: 'error' })
      }
      this.setState({ id: '', note: '' })
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  managerNotes: state.transactions.managerNotes
})

export default connect(
  mapStateToProps,
  {
    setDeleteManagerNote: Actions.deleteManagerNote,
    setManagerNotes: Actions.getManagerNotes,
    setNewManagerNote: Actions.addNewManagerNote,
    setUpdateManagerNote: Actions.editManagerNote
  }
)(Notes)
