// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollYContainer } from 'design/Styled'

const CommissionWrapper = styled.section`
  width: 100%;
  background-color: ${Colors.White1000};
  height: 100%;
  flex: 1;
  padding-top: 20px;
  overflow: auto;
  ${scrollYContainer};
`

export {
  CommissionWrapper,
}
