import styled, { css } from 'styled-components'

import Pro from 'design/icons/transactions/paid.png'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { x, y } from 'design/Styled'

interface Props {
  background?: string
  isActive?: boolean
  isPaid?: boolean
}

const getPaidIcon = (props: Props) => {
  if (props.isPaid) {
    return css`
      padding-left: 2.2em;
      &:before {
        display: block;
        position: absolute;
        top: 0.8em;
        left: 1em;
        content: '';
        width: 1.2em;
        height: 1.2em;
        background-image: url(${Pro});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    `
  }
  return ``
}

export const Container = styled.div`
  ${y};
  height: 100%;
`

export const Section = styled.div`
  ${y};
  overflow: hidden;
`

export const Menu = styled.nav`
  ${x};
  justify-content: center;
  align-items: center;
  margin: 1em 0;
`

export const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0.8em 1em;
  min-height: 2em;
  font: ${fonts.medium.medium};
  color: ${(props: Props) =>
    props.isActive ? Colors.TextPrimary : Colors.TextMedium};
  box-shadow: 0 ${(props: Props) => (props.isActive ? 3 : 0)}px 0 0
    ${(props: Props) =>
      props.isActive ? Colors.TextPrimary : Colors.TextMedium};
  transition: all 200ms;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 0 0
      ${(props: Props) =>
        props.isActive ? Colors.TextPrimary : Colors.TextMedium};
  }

  ${getPaidIcon};
`
