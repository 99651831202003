// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Select } from 'semantic-ui-react'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import { Options } from 'store/Reports/Types'

import { getStates } from '../../../../Leads/Dashboard/LeadQueries'

import {
  StyledForm
} from '../Styled'

interface StoreProps {
  setFilter: (data: any) => void
  setChangeKey: (key: number) => void
  setResetFilterData: (reset: boolean) => void
  setShowState: (data: Options) => void
  setShowCity: (data: Options) => void
  filter: any
  key: number
  resetData: boolean
  selectedCity: Options
  selectedState: Options
}

interface OwnProps {
  categoryId: string
  report: string
}

type Props = StoreProps & OwnProps

interface State {
  activeIndex: string
  searchData: any
  cities: Options[]
  states: Options[]
  selectedCity: Options
  selectedState: Options
}

const optionsType = [
  { key: 1, text: 'Buyer', value: 'Buyer' },
  { key: 2, text: 'Seller', value: 'Seller' },
  { key: 3, text: 'Both', value: 'Both' },
  { key: 4, text: 'Renter', value: 'Renter' },
  { key: 6, text: 'Other', value: 'Other' },
]

const optionsSource = [
  { key: 1, text: 'Call', value: 'Call' },
  { key: 2, text: 'Email', value: 'Email' },
  { key: 3, text: 'Trade', value: 'Trade' },
  { key: 4, text: 'Referral', value: 'Referral' },
  { key: 5, text: 'Seminar', value: 'Seminar' },
  { key: 6, text: 'Website', value: 'Website' },
  { key: 7, text: 'Third Party', value: 'Third Party' },
]

const optionsStatus = [
  { key: 1, text: 'New', value: 'New' },
  { key: 2, text: 'In-process', value: 'In-process' },
  { key: 3, text: 'Closed', value: 'Closed' },
  { key: 4, text: 'Failed', value: 'Failed' },
]

const optionsPropertType = [
  { key: 1, text: 'Residential', value: 'Residential' },
  { key: 2, text: 'Commercial', value: 'Commercial' },
]

class LeadContent extends React.Component<Props, State> {
  public state = {
    activeIndex: '',
    cities: [],
    searchData: {},
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: [],
  }

  public async componentDidMount() {
    const { setChangeKey } = this.props
    const states = await getStates()
    if (states) {
      const statesData: Options[] = []
      states.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`,
        }
        element.name && statesData.push(obj)
      })
      this.setState({ states: statesData })
    }
    setChangeKey(0)
  }

  public componentWillReceiveProps(nextProps: any) {
    const { setChangeKey, resetData } = this.props
    if (resetData ? resetData : nextProps.resetData) {
      this.setState({
        searchData: {},
        selectedCity: {
          key: '',
          text: '',
          value: ''
        },
        selectedState: {
          key: '',
          text: '',
          value: ''
        },
      })
      setChangeKey(1)
    }
  }

  public render() {
    const { report, key, filter, selectedState } = this.props
    const { states } = this.state
    return (
      <div key={key}>
        <StyledForm size={'tiny'}>
          {
            report === 'Lead Name' &&
            <div>
              <Form.Field
                control={Input}
                label='First Name'
                name='firstName'
                value={filter[`firstName`]}
                placeholder='First Name'
                onChange={this.handleChange} />
              <Form.Field
                control={Input}
                label='Last Name'
                name='lastName'
                value={filter['lastName']}
                placeholder='Last Name'
                onChange={this.handleChange} />
            </div>
          }
          {
            report === 'Type' &&
            <Form.Field
              control={Select}
              label='Type'
              name='type'
              placeholder='Type'
              value={filter[`type`]}
              options={optionsType}
              onChange={this.handleChange} />
          }
          {
            report === 'Location' &&
            <div>
              <Form.Field
                control={Select}
                label='State'
                name='state'
                placeholder='State'
                value={selectedState[`value`]}
                options={states}
                onChange={this.handleChange} />
              <Form.Field
                control={Input}
                label='Zip Code'
                name='zipCode'
                value={filter[`address`] && filter[`address`].zipCode}
                placeholder='Zip Code'
                onChange={this.handleChange} />
            </div>
          }
          {
            report === 'Source' &&
            <Form.Field
              control={Select}
              label='Source'
              name='source'
              placeholder='Source'
              value={filter[`source`]}
              options={optionsSource}
              onChange={this.handleChange} />
          }
          {
            report === 'Property Type' &&
            <Form.Field
              control={Select}
              label='Property Type'
              name='propertyType'
              placeholder='Property Type'
              value={filter[`propertyType`]}
              options={optionsPropertType}
              onChange={this.handleChange} />
          }
          {
            report === 'Status' &&
            <Form.Field
              control={Select}
              label='Status'
              name='status'
              placeholder='Status'
              value={filter[`status`]}
              options={optionsStatus}
              onChange={this.handleChange} />
          }
        </StyledForm>
      </div>
    )
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { setFilter, filter, setShowState } = this.props
    if (name === 'firstName') {
      this.setState({
        searchData: {
          ...filter,
          firstName: value,
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'lastName') {
      this.setState({
        searchData: {
          ...filter,
          lastName: value,
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'state') {
      const result = value.split(', ')
      this.setState({
        searchData: {
          ...filter,
          state: result[0],
        }
      }, () => setFilter(this.state.searchData))
      this.setState({
        selectedState: {
          key: result[1],
          text: result[0],
          value: `${result[0]}, ${result[1]}`,
        }
      }, () => setShowState(this.state.selectedState))
    } else if (name === 'zipCode') {
      this.setState({
        searchData: {
          ...filter,
          zipCode: value,
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'type') {
      this.setState({
        searchData: {
          ...filter,
          type: value
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'source') {
      this.setState({
        searchData: {
          ...filter,
          source: value
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'propertyType') {
      this.setState({
        searchData: {
          ...filter,
          propertyType: value
        }
      }, () => setFilter(this.state.searchData))
    } else {
      this.setState({
        searchData: {
          ...filter,
          status: value
        }
      }, () => setFilter(this.state.searchData))
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.reports.filter,
  key: state.reports.key,
  resetData: state.reports.resetData,
  selectedCity: state.reports.selectedCity,
  selectedState: state.reports.selectedState,
})

export default connect(
  mapStateToProps,
  {
    setChangeKey: Actions.changeKey,
    setFilter: Actions.getFilter,
    setResetFilterData: Actions.resetFilterData,
    setShowCity: Actions.showCity,
    setShowState: Actions.showState,
  }
)(LeadContent)
