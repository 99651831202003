import * as React from 'react'
import Integration from './Integration'
import Passes from './Passes'
import Pricing from './Pricing'
import { Container, StyledTab } from './Styled'

import { BillingCycleType, BillingPlanEnum } from 'shared/Billing/Types'

interface Props {
  cycle: BillingCycleType
  onUpdate: (plan: BillingPlanEnum) => void
}

class Company extends React.Component<Props, {}> {
  public state = {}

  public panes = [
    {
      menuItem: 'PRICING',
      render: () => <Pricing cycle={this.props.cycle} onUpdate={this.props.onUpdate} />
    },
    { menuItem: 'PASSES', render: () => <Passes /> },
    { menuItem: 'INTEGRATIONS', render: () => <Integration /> }
  ]

  public render() {
    return (
      <Container>
        <StyledTab menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </Container>
    )
  }
}

export default Company
