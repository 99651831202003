import Colors from 'design/Colors'
import styled, { css } from 'styled-components'

const dragBarPadding = 20

export const fontSizes = [
  '16pt',
  '18pt',
  '20pt',
  '22pt',
  '24pt',
  '26pt',
  '28pt',
  '30pt',
  '32pt',
  '34pt'
]

const fontSizePicker = () => {
  let style = ``
  fontSizes.forEach((size: string) => {
    style += `
      .ql-picker-label[data-value="${size}"]::before,
      .ql-picker-item[data-value="${size}"]::before {
        content: '${size}' !important;
      }
    `
  })
  return css`
    ${style};
  `
}

export const CloseButton = styled.div`
  position: absolute;
  top: ${dragBarPadding + 12}px;
  right: 22px;

  .fa-times {
    transform: scale(1.5);
    cursor: pointer;
  }
`

export const Container = styled.div`
  margin: -1.1em;
  border: 0.1em dashed ${Colors.TransparentWhite(0)};
  border-radius: 6px;
  box-shadow: 0 0 0 -5px ${Colors.ContainerShadow};
  background-color: ${Colors.TransparentWhite(0)};
  transition: border-color 100ms, background-color 100ms, box-shadow 200ms;

  &:hover {
    background-color: ${Colors.Container};
    border-color: ${Colors.ButtonPrimary};
    box-shadow: 0 0 14px -5px ${Colors.ButtonPrimary};
  }
`

export const Text = styled.div`
  padding: 1em;

  p {
    line-height: 1.3;
  }
`

export const ModalContent = styled.div`
  position: relative;
  padding-top: ${dragBarPadding}px;

  button.ql-bold:before {
    content: '\f032';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
  }

  button.ql-italic:before {
    content: '\f033';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
  }

  .ql-toolbar button.ql-list[value='ordered']:before {
    content: '\f0cb';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
  }

  .ql-toolbar button.ql-list[value='bullet']:before {
    content: '\f0ca';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
  }

  .ql-container {
    font-size: 16pt;
    background: ${Colors.Container};
  }

  .ql-toolbar.ql-snow {
    background: ${Colors.Container};
  }

  .ql-snow .ql-picker.ql-size {
    .ql-picker-item {
      line-height: 1;
      padding: 4px;
    }
    ${fontSizePicker()};
  }
`
