// Import Packages
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const StyledContainer = styled(Container)`
  &.ui.fluid.container {
    border: none;
    box-shadow: none;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 90%;
    max-height: 91%;
  }

  .table-body {
    overflow: auto;
    height: 460px;
    ${scrollbars};
  }
`

const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

const FloatingMenuContainer = styled.div`
  position: absolute;
  right: -22px;
  top: 4px;
`

export {
  FloatingMenuContainer,
  DropDownPopup,
  StyledContainer,
  StyledDropdown
}
