import gql from 'graphql-tag'

export const GET_CONTACTS = gql`
  query getContacts(
    $userId: String
    $searchData: ContactSearch
    $userPassFilter: UserPassContactFilter
  ) {
    getContacts(
      where: { isActive: true, userId: $userId }
      filter: $searchData
      userPassFilter: $userPassFilter
    ) {
      _id
      firstName
      lastName
      phone
      email
      source
      isActive
      type
      teamContactOrder
      officeContactOrder
      tenantContactOrder
      createdAt
      dateOfBirth
      anniversaryDate
      shareWith {
        _id
        firstName
        lastName
        profileImage
        status
        type
      }
      owner {
        _id
        firstName
        lastName
        profileImage
      }
      address {
        streetNumber
        streetName
        city
        state
        zipCode
      }
    }
  }
`
export const GET_CONTACT_DETAILS = gql`
  query getContactDetails($contactId: ID) {
    getContacts(where: { _id: $contactId }) {
      _id
      firstName
      lastName
      phone
      email
      source
      isActive
      type
      teamContactOrder
      officeContactOrder
      tenantContactOrder
      createdAt
      genderPrefix
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      owner {
        _id
        firstName
        lastName
        profileImage
      }
      address {
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      married
      pets
      dateOfBirth
      anniversaryDate
      children
    }
  }
`

export const GET_CONTACT_NOTE = gql`
  query getContactNotes($contactId: String) {
    getNotes(where: { contact: $contactId }) {
      _id
      description
      contact {
        firstName
        lastName
      }
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const EXPORT_CONTACTS = gql`
  query exportContacts(
    $contactBoard: String
    $teamContactBoard: String
    $officeContactBoard: String
    $tenantContactBoard: String
  ) {
    exportContacts(
      where: {
        contactBoard: $contactBoard
        teamContactBoard: $teamContactBoard
        officeContactBoard: $officeContactBoard
        tenantContactBoard: $tenantContactBoard
      }
    )
  }
`
