import styled from 'styled-components'

import { scrollYContainer } from 'design/Styled'

export const Container = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const Scroller = styled.div`
  ${scrollYContainer};
`

export const Child = styled.div`
  display: block;
`
