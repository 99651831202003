export const SIGNATURE_01 = (input: any /*SignatureInput*/) => {
  const {
    address,
    colorLink,
    colorText,
    companyPosition,
    fontFamily,
    fontSize,
    name,
    nameTitle,
    profileImage,
    urlFacebook,
    urlLinkedin,
    urlTwitter,
    urlWebsite
  } = input

  let website = urlWebsite
  if (urlWebsite && !urlWebsite.match(/^(http|https):\/\//gi)) {
    website = `http://${urlWebsite}`
  }

  const socialIcons = [
    { name: 'Facebook', url: urlFacebook, image: 'facebook_100x100.jpg' },
    { name: 'Linkedin', url: urlLinkedin, image: 'linkedin_100x100.jpg' },
    { name: 'Twitter', url: urlTwitter, image: 'twitter_100x100.jpg' }
  ]

  const iconSize = fontSize * 5
  const iconPadding = iconSize * 0.5

  return `
  <table style="font-family:${fontFamily};color:${colorText};" cellpadding="0" cellspacing="0">
   <tbody>
    <tr>
     <td style="width:${iconSize +
       iconPadding}px;padding:0;font-family:${fontFamily};text-align:center;vertical-align:middle;" valign="middle" width="${iconSize +
    iconPadding}">
      <img alt="${name}" width="${iconSize}" height="${iconSize}" border="0" style="width:${iconSize}px;height:${iconSize}px;border-radius:50%;border:0;box-shadow:0 0 15px -10px #333" src="${profileImage}">
     </td>

     <td style="font-family:${fontFamily};border-left:1px solid #ccc;padding:0;padding-left:${iconPadding /
    2}px;vertical-align:top;" valign="top">
      <table style="font-family:${fontFamily}" cellpadding="0" cellspacing="0">
       <tbody>
        <tr>
         <td style="font-family:${fontFamily};padding-bottom:${fontSize *
    0.4}px;padding-top:${fontSize *
    0.75}px; padding-left:0; padding-right:0; vertical-align:top;" valign="top">
          <strong><span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize +
    1}px;line-height:${fontSize * 1.25}px;">${
    nameTitle ? nameTitle + ' ' + name : name
  }</span></strong>
         </td>
        </tr>
        <tr>
         <td style="font-family:${fontFamily};color:${colorText};padding-bottom:${fontSize *
    0.5}px; padding-top:0; padding-left:0; padding-right:0;line-height:${fontSize *
    1.25}px;vertical-align:top;" valign="top">
          <span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize}px;">${companyPosition}</span><br/>
          <span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize}px;">
            <span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize}px;opacity:0.8">Address:</span> ${address}
          </span><br/>
          <span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize}px;">
            <span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize}px;opacity:0.8">Website:</span>
            <a href="${website}" target="_blank" title="Visit my website" style="text-decoration:none;color:${colorLink};">${urlWebsite}</a>
          </span>
         </td>
        </tr>
        <tr>
         <td style="font-family:${fontFamily}; padding-bottom:${fontSize *
    0.75}px; padding-top:0; padding-left:0; padding-right:0; line-height:${fontSize *
    1.2}px; vertical-align:top;" valign="top">
          ${socialIcons
            .map(icon => {
              if (icon.url.trim() !== '') {
                return `<span style="padding-right:4px"><a href="${
                  icon.url
                }" target="_blank" style="text-decoration:none;">
                  <img border="0" width="16" alt="${
                    icon.name
                  }" style="border:0;height:16px;width:16px" src="${
                  process.env.REACT_APP_SERVER_URL
                }/images/icons/brand/${icon.image}">
                </a></span>`
              }
              return ''
            })
            .join('')}
         </td>
        </tr>
       </tbody>
      </table>
     </td>
    </tr>
   </tbody>
  </table>
  `
}
