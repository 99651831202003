export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`name`]) {
    formIsValid = false
    errors[`name`] = true
  }

  return { errors, formIsValid }
}
