// Import Components
import client, { cacheData } from 'queries/apollo'
import { Checked } from './CustomComponents'

// Import Graphql Mutations
import { UPDATE_OFFICE } from 'queries/graphql/Offices/Mutation'

// Import Graphql Queries
import { GET_GROUP_ID } from 'queries/graphql/Group/Queries'
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import { GET_STATES_OFFICES } from 'queries/graphql/States/Queries'

export const ChangeToggleCheckBox = (checked: boolean) => {
  const data: any = client.readQuery({ query: GET_OFFICES })
  const newData = data.getOffices.map((list: any) => {
    return {
      ...list,
      isChecked: !checked
    }
  })
  client.writeQuery({
    data: { getOffices: newData },
    query: GET_OFFICES
  })
  Checked(checked)
}

export const ToggleSingleCheckBox = (id: string) => {
  const data: any = client.readQuery({ query: GET_OFFICES })
  const newData = data.getOffices.map((list: any) => {
    return {
      ...list,
      isChecked: list._id === id ? !list.isChecked : list.isChecked
    }
  })
  client.writeQuery({
    data: { getOffices: newData },
    query: GET_OFFICES
  })
}

export const resetData = async () => {
  await client.query({
    query: GET_STATES_OFFICES,
    variables: { sortOrder: 1, orderField: 'officeOrder' }
  })
  await client.query({ query: GET_OFFICES, fetchPolicy: 'network-only' })
  return getBoardsOptions()
}

export const restoreData = async (cardId: string) => {
  await client.mutate({
    mutation: UPDATE_OFFICE,
    refetchQueries: [
      {
        query: GET_STATES_OFFICES,
        variables: { sortOrder: 1, orderField: 'officeOrder' }
      },
      { query: GET_OFFICES }
    ],
    variables: { id: cardId, isActive: true }
  })
  resetData()
}

export const addNewCard = (id: string) => {
  const boardName: any = cacheData.readQuery({
    query: GET_STATES_OFFICES,
    variables: { sortOrder: 1, orderField: 'officeOrder' }
  })
  const state = boardName.getStates.find((states: any) => {
    return states._id === id
  })
  return state.name
}

export const sortOffice = async (boardId: string, sortOrder: number) => {
  let result
  if (boardId !== '') {
    result = await client.query({
      fetchPolicy: 'network-only',
      query: GET_STATES_OFFICES,
      variables: {
        id: boardId,
        officeOrderField: 'branchName',
        orderField: 'officeOrder',
        sortOfficeOrder: sortOrder,
        sortOrder: 1
      }
    })
    return result
  } else {
    result = await client.query({
      fetchPolicy: 'network-only',
      query: GET_OFFICES,
      variables: { sortOrder: sortOrder, orderField: 'branchName' }
    })
  }
  return result
}

export const getBoardsOptions = async () => {
  const offices: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_STATES_OFFICES,
    variables: { sortOrder: 1, orderField: 'officeOrder' }
  })
  const options = offices.data.getStates.reduce((accumulator: any, currentValue: any) => {
    currentValue.name && accumulator.push({
      key: currentValue._id,
      text: currentValue.name,
      value: `${currentValue._id}, ${currentValue.name}`
    })
    return accumulator
  }, [])
  options.splice(0, 0, {
    key: '0',
    text: 'Select State',
    value: 'Choose'
  })
  if (options.length > 2) {
    options.push({
      key: 'All',
      text: 'All',
      value: 'All'
    })
  }
  return options
}

export const getManagerBoardId = async () => {
  const board: any = await client.query({
    query: GET_GROUP_ID,
    variables: { name: 'MANAGER' }
  })
  return board.data.getGroups[0]
}
