// Import Packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from 'styled-components'
import styled from 'styled-components'

// Import Fonts, Colors
import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

const textStyle = css`
  color: ${Colors.Black500};
  margin: 0;
  line-height: 1.5;
`

const text = css`
  font: ${fonts.small.regular};
  ${textStyle};
`

const mediumText = css`
  font: ${fonts.xSmall.regular};
  ${textStyle};
`

const smallText = css`
  font: ${fonts.xxSmall.regular};
  ${textStyle};
`

const nowrapText = css`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const padX = css`
  padding-right: 1em;
  padding-left: 1em;
`

const padY = css`
  padding-top: 0.8em;
  padding-bottom: 0.8em;
`

const row = css`
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;

  & > :first-child {
    margin-left: 0;
  }
  & > :last-child {
    margin-right: 0;
  }
`

const Container = styled.div`
  margin: 0 auto;
  padding: 0;
  border-radius: 0px;
  width: 95%;
  background: ${Colors.White1000};
  border: 1px solid ${Colors.Blue910};
  box-shadow: 0 1px 3px 0 ${Colors.CardShadow}, 0 0 0 1px ${Colors.CardShadow};
`

const Card = styled.div`
  ${row} &:hover .sidebar {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    box-shadow: 16px 0 16px 5px ${Colors.Black50};
  }
`

const Col = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 0.8em;
  margin-right: 0.8em;
`

const Envelope = styled(Col)`
  overflow: hidden;
`

const From = styled.h1`
  ${text} ${nowrapText}
  font-weight: bold;
`

const Subject = styled.h2`
  ${mediumText} ${nowrapText}
  font-style: italic;
`

const Preview = styled.p`
  ${smallText};
`

const Timestamp = styled.span`
  ${mediumText} width: 100%;
  padding-top: 5px;
  text-align: center;
`

const Footer = styled.div`
  ${row}
  ${text}
  ${padX}
  flex-wrap: wrap;

  :before {
    content: '';
    display: block;
    width: 100%;
    margin: 0 auto;
    background: ${Colors.Black905};
    height: 1px;
  }
`

const Attachments = styled.div`
  ${row} ${padY}
  justify-content: flex-end;
`

const AttachmentCount = styled.small`
  padding-left: 4px;
`

const SlideRow = styled.div`
  ${row}
  ${padX}
  ${padY}
`

const Sidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  width: 20%;
  max-width: 50px;
  height: 100%;
  background: ${Colors.White800};
  transition: all 200ms;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  box-shadow: 0 0 16px 5px ${Colors.transparent};
`

const SidebarButton = styled(FontAwesomeIcon)`
  display: block;
  height: 33%;
  color: ${Colors.Black550};
  transform: scale3d(0.9, 0.9, 0.9);
  transition: transform, color 200ms;
  cursor: pointer;

  &:hover {
    color: ${Colors.Black500};
    transform: scale3d(1, 1, 1);
  }
`

export {
  AttachmentCount,
  Attachments,
  Card,
  Col,
  Container,
  Envelope,
  Footer,
  From,
  Preview,
  Sidebar,
  SidebarButton,
  SlideRow,
  Subject,
  Timestamp
}
