// Import Packages
import * as React from 'react'
import {
  Container,
  Infograph
} from './Styled'

// Import Utils
import { Strings } from 'utils'

class EmailDrip extends React.Component {
  public state = {}

  public render() {
    return (
      <Container>
        <Infograph background={Strings.generalText.coming} />
      </Container>
    )
  }
}

export default EmailDrip
