// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { fontStyle } from 'design/Fonts'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const FloatingMenuItem = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  display: inline-block;
	margin-right: 8px;
	padding: 4px 10px;
	border-radius: 12px;
  transition: opacity 250ms ease-in-out;
  display: flex;
  justify-content: center;
  width: 80px;
  cursor: pointer;
`

const FloatingMenuIcon = styled.div`
  display: inline-block;
	width: 35px;
	height: 35px;
	line-height: 45px;
	vertical-align: middle;
	background: ${Colors.Green700};
	border-radius: 50%;
	border: none;
	box-shadow: 0 2px 4px rgba(0,0,0,.2);
  text-align: center;
  font-size: 32px;
	color: ${Colors.White1000};
  vertical-align: middle;
  display: flex;
  justify-content: center;

  &.main {
    width: 35px;
    height: 35px;
    background-color: ${Colors.DarkBlue200};
  }
`

const Label = styled.div`
  color: ${Colors.Black500};
  font-size: 14px;
  font-family: ${fontStyle};
  background-color: ${Colors.White600};
  padding: 2px 10px;
  border-radius: 7px;
  cursor: default;
`

interface ImageProps {
  backgroundImage: string
}

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 35%;
  height: 45%;
  align-self: center;
  margin: 0px 10px;
  display: inline-block;

  .main & {
    width: 50%;
    height: 50%;
  }
`

export {
  ButtonContainer,
  FloatingMenuIcon,
  FloatingMenuItem,
  ImageWrapper,
  Label
}
