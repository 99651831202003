// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Button, Form, Select } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import GridView from 'shared/WorkflowGrid'
import { GET_TASK } from 'queries/graphql/Tasks/Queries'
import Toast from 'shared/Toast'
import {
  getTransactionDetails,
  getTransactionTask,
  getTransactionWorkflow
} from '../../../Dashboard/TransactionQueries'
import AddWorkflowTasksModal from '../Workflow/AddWorkflowTasksModal'
import {
  Checked,
  CustomActions,
  CustomAssignedTo,
  CustomCheckBox,
  CustomDescription,
  CustomDueDate,
  CustomDueIn,
  CustomHeaderCheckBox,
  CustomStatus,
  CustomTask
} from './CustomComponents'
import Tables from './Table'

// Import Store Types, Actions and Reducers
import client from 'queries/apollo'
import { AppState } from '../../../../../store/CombineReducers'
import * as Actions from '../../../../../store/Transactions/Actions'
import { Options, ShareWith, TransactionList, TransactionWorkflow } from '../../../../../store/Transactions/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import {
  applyWorkflow,
  ChangeTransactionTaskToggleCheckBox,
  createTransactionTask,
  deleteTasks,
  deleteTransactionTask,
  ToggleTransactionCheckBox,
  ToggleTransactionTaskSingleCheckBox,
  updateBulkTask,
  updateTask
} from '../../../Dashboard/TransactionMutations'
import { AddButton, GridContainer, StyledForm, StyledSelectDropdown } from './Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { createEvent } from '../../../../Tasks/EventMutations'
AddIconToLibrary([faPlus])

interface StoreProps {
  setNewTransactionWorkflowTask: (data: TransactionWorkflow) => void
  setDeleteTransactionWorkflowTask: (data: string) => void
  setTransactionWorkflowTask: (data: TransactionWorkflow[]) => void
  setUpdateTransactionWorkflowTask2: (data: TransactionWorkflow) => void
  setDeleteMutlipleTransactionWorkflowTask: (data: TransactionWorkflow) => void
  setupdateMultipleTransactionWorkflowTask: (data: any, status: TransactionWorkflow) => void
  workflowTask: TransactionWorkflow[]
  shareWith: ShareWith
  transactionDetail: TransactionList
}

type Props = StoreProps

interface State {
  checked?: boolean
  listViewData?: TransactionList[]
  showAddTaskModal?: boolean
  edit: boolean
  taskDetailObj: any
  transactionId: string
  transactionOwnerId: string
  transactionSharedUser: any
  task: string
  taskData: any
  workflows: TransactionWorkflow[]
  workflowOptions: Options[]
  loader: boolean
  user: {
    _id: string
    transactionOwnerId: string
    role: string
  }
}

export let loggedUser: any
export let ToggleCheckBox: any
export let SingleCheckBox: any
export let deleteTask: any
export let ToggleTaskStatus: any
export let editTransactionTask: any
export let transactionShareWith: any
export let transactionOwner: any
export let reassignTask: any

const statusOptions = [
  { key: 0, text: 'Select Action', value: 'Choose' },
  { key: 1, text: 'Delete Task(s)', value: 'Delete Task' },
  { key: 2, text: 'In Progress', value: 'In Progress' },
  { key: 3, text: 'Completed', value: 'Completed' }
]

class Workflow extends React.Component<Props, State> {
  public state = {
    checked: false,
    edit: false,
    listViewData: [],
    loader: false,
    showAddTaskModal: false,
    task: '',
    taskData: [],
    taskDetailObj: {},
    transactionId: '',
    transactionOwnerId: '',
    transactionSharedUser: [],
    user: {
      _id: '',
      role: '',
      transactionOwnerId: ''
    },
    workflowOptions: [],
    workflows: []
  }

  public columnMetaDataForActionWorkflow = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomTask,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.task,
      title: Strings.leads.columnWorkflowMetaData.title.task
    },
    {
      customComponent: CustomDescription,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.taskDescription,
      title: Strings.leads.columnWorkflowMetaData.title.taskDescription
    },
    {
      customComponent: CustomDueIn,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.dueIn,
      title: Strings.leads.columnWorkflowMetaData.title.dueIn
    },
    {
      customComponent: CustomAssignedTo,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.assignedTo,
      sortable: false,
      title: Strings.leads.columnWorkflowMetaData.title.assignedTo
    },
    {
      customComponent: CustomStatus,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.status,
      title: Strings.leads.columnWorkflowMetaData.title.status
    },
    {
      customComponent: CustomActions,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.Action,
      sortable: false,
      title: Strings.leads.columnWorkflowMetaData.title.Action
    }
  ]

  public columnMetaDataForWorkflow = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomTask,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.task,
      title: Strings.leads.columnWorkflowMetaData.title.task
    },
    {
      customComponent: CustomDescription,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.taskDescription,
      title: Strings.leads.columnWorkflowMetaData.title.taskDescription
    },
    {
      customComponent: CustomDueIn,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.dueIn,
      title: Strings.leads.columnWorkflowMetaData.title.dueIn
    },
    {
      customComponent: CustomAssignedTo,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.assignedTo,
      sortable: false,
      title: Strings.leads.columnWorkflowMetaData.title.assignedTo
    },
    {
      customComponent: CustomStatus,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.status,
      title: Strings.leads.columnWorkflowMetaData.title.status
    }
  ]

  public columnMetaDataForTasks = [
    {
      customComponent: CustomTask,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.task,
      title: Strings.leads.columnWorkflowMetaData.title.task
    },
    {
      customComponent: CustomDescription,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.taskDescription,
      title: Strings.leads.columnWorkflowMetaData.title.taskDescription
    },
    {
      customComponent: CustomDueDate,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.dueIn,
      title: Strings.leads.columnWorkflowMetaData.title.dueIn
    }
  ]

  public async componentDidMount() {
    const { setTransactionWorkflowTask, shareWith, transactionDetail } = this.props
    transactionShareWith = shareWith
    transactionOwner = transactionDetail.owner
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = {
      _id: user._id,
      role: user.role,
      transactionOwnerId: transactionDetail && transactionDetail.owner && transactionDetail.owner._id
    }
    const tasks = await getTransactionTask(transactionDetail._id)
    const options = await getTransactionWorkflow()
    let workflowDropOptions: any = []
    if (options) {
      workflowDropOptions = options.workflows.map((item: any) => {
        return {
          key: item._id,
          text: item.name,
          value: `${item.name}, ${item._id}`
        }
      })
    }
    setTransactionWorkflowTask(tasks)
    this.setState({
      transactionId: transactionDetail._id,
      transactionOwnerId: transactionDetail && transactionDetail.owner && transactionDetail.owner._id,
      transactionSharedUser: transactionDetail.shareWith,
      user: {
        _id: user._id,
        role: user.role,
        transactionOwnerId: transactionDetail && transactionDetail.owner && transactionDetail.owner._id
      },
      workflowOptions: workflowDropOptions,
      workflows: options.workflows
    })
    this.assignToggleCheckBox()
    this.assignSingleCheckBox()
    this.delete()
    this.changeTaskStatus()
    this.editTask()
    this.changeAssignee()
  }

  public render() {
    const {
      showAddTaskModal,
      edit,
      taskDetailObj,
      task,
      taskData,
      workflowOptions,
      user,
      loader,
      transactionSharedUser
    } = this.state
    const { workflowTask } = this.props
    return (
      <GridContainer>
        {showAddTaskModal && (
          <AddWorkflowTasksModal
            transactionSharedUser={transactionSharedUser}
            closeModal={this.closeModal}
            user={user}
            addWorkflowTask={this.addWorkflowTask}
            edit={edit}
            editDetailObj={taskDetailObj}
            addEvent={this.addEvent}
          />
        )}
        {
          <StyledForm>
            {(user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === user.transactionOwnerId) && (
              <>
                <div className="task">
                  <Form.Field
                    className="transaction"
                    value={task}
                    control={Select}
                    options={workflowOptions}
                    placeholder="Select Workflow"
                    onChange={this.handleChange}
                  />
                  {task ? (
                    !loader ? (
                      <>
                        <Form.Field
                          style={{ marginLeft: 15 }}
                          control={Button}
                          content={'APPLY WORKFLOW'}
                          onClick={this.applyWorkflow}
                          disabled={loader}
                        />
                        <Form.Field
                          className="cancel"
                          control={Button}
                          content={'CANCEL'}
                          onClick={this.handleCancelTask}
                        />
                      </>
                    ) : (
                      <Button basic={true} loading={true} style={{ height: 32, marginLeft: 15 }}>
                        Loading
                      </Button>
                    )
                  ) : null}
                </div>
                <div>
                  {' '}
                  <StyledSelectDropdown
                    inline={true}
                    options={statusOptions}
                    placeholder="Select Action"
                    onChange={this.handleActionChange}
                  />
                </div>
              </>
            )}
            {user.role !== 'GUEST' && (
              <AddButton style={{ visibility: task ? 'hidden' : 'visible' }}>
                <span onClick={this.showAddTaskModal}>
                  <FontAwesomeIcon icon={['fas', 'plus']} />
                </span>
              </AddButton>
            )}
          </StyledForm>
        }
        {task && <Tables task={taskData} workflow={workflowTask} />}
        {!task &&
          (user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === user.transactionOwnerId) && (
            <GridView data={workflowTask} tableHeight={50} columnMetaData={this.columnMetaDataForActionWorkflow} />
          )}
        {!task &&
          (user.role !== 'ADMIN' && user.role !== 'MANAGER' && user._id !== user.transactionOwnerId) && (
            <GridView data={workflowTask} tableHeight={50} columnMetaData={this.columnMetaDataForWorkflow} />
          )}
      </GridContainer>
    )
  }

  public componentWillReceiveProps(nextProps: any, prevProps: any) {
    transactionShareWith = nextProps.shareWith
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const res = value.split(', ')
    const { workflows } = this.state
    workflows.forEach((element: any) => {
      if (element.name === res[0]) {
        this.setState({ taskData: element.workflowTasks, task: value, loader: false })
      }
    })
  }

  private addEvent = async (newEvent: any) => {
    const { transactionId } = this.state
    newEvent = { ...newEvent, transactionId: transactionId }
    try {
      await createEvent(newEvent, false)
      Toast({ message: 'Created Event Successfully', type: 'success' })
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }

  private handleActionChange = async (e: any, { value }: any) => {
    const { checked, transactionId } = this.state
    const {
      setDeleteMutlipleTransactionWorkflowTask,
      setTransactionWorkflowTask,
      setupdateMultipleTransactionWorkflowTask
    } = this.props
    const data: any = client.readQuery({
      query: GET_TASK,
      variables: { transactionId }
    })
    const taskIds: any = []
    data.getTasks.forEach((task: any) => {
      if (task.checked === true) {
        taskIds.push(task._id)
      }
    })
    if (value === 'Delete Task') {
      if (taskIds.length > 0) {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesDeleteIt,
          showCancelButton: true,
          text: 'Remove selected task(s)',
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async (result: any) => {
          if (result.value) {
            if (checked === true) {
              ToggleCheckBox()
            } else {
              const response = await ToggleTransactionCheckBox(transactionId)
              setTransactionWorkflowTask(response)
            }
            await deleteTasks(taskIds, transactionId)
            setDeleteMutlipleTransactionWorkflowTask(taskIds)
            ConfirmAlert(Strings.kanbanView.deleted, 'Your task(s) has been deleted.', 'success')
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, 'Your task(s) is safe', 'error')
          }
        })
      } else {
        ConfirmAlert(Strings.kanbanView.error, Strings.tasks.errorTaskText, 'error')
      }
    } else if (value === 'In Progress' || value === 'Completed') {
      if (taskIds.length > 0) {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesUpdateIt,
          showCancelButton: true,
          text: Strings.kanbanView.updateRecord,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async (result: any) => {
          if (result.value) {
            if (checked === true) {
              ToggleCheckBox()
            } else {
              const response = await ToggleTransactionCheckBox(transactionId)
              setTransactionWorkflowTask(response)
            }
            await updateBulkTask(taskIds, value, transactionId)
            setupdateMultipleTransactionWorkflowTask(taskIds, value)
            ConfirmAlert(Strings.kanbanView.updated, Strings.kanbanView.updatedCard, 'success')
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          }
        })
      } else {
        ConfirmAlert(Strings.kanbanView.error, Strings.tasks.errorUpdateTaskText, 'error')
      }
    }
  }

  private handleCancelTask = () => {
    this.setState({ task: '', taskData: {} })
    const { checked } = this.state
    if (checked === true) {
      ToggleCheckBox()
    }
  }

  private applyWorkflow = async () => {
    const { task, transactionId } = this.state
    const { setTransactionWorkflowTask } = this.props
    this.setState({
      loader: true
    })
    const res = task.split(', ')
    try {
      const response = await applyWorkflow(res[1], transactionId)
      setTransactionWorkflowTask(response.data.applyWorkflow)
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
    this.handleCancelTask()
  }

  private setTransactionWorkflowTask = async () => {
    const { setTransactionWorkflowTask } = this.props
    const { transactionId } = this.state
    const tasks = await getTransactionTask(transactionId)
    setTransactionWorkflowTask(tasks)
    Checked(true)
    this.setState({ checked: false })
  }

  private showAddTaskModal = async () => {
    const { transactionId } = this.state
    const transactions = await getTransactionDetails(transactionId)
    this.setState({
      showAddTaskModal: true,
      taskDetailObj: {},
      transactionSharedUser: transactions[0].shareWith,
      edit: false
    })
    this.setTransactionWorkflowTask()
  }

  private closeModal = () => {
    this.setState({ showAddTaskModal: false })
  }

  private editTask = () => {
    editTransactionTask = async (taskDetail: any) => {
      const { transactionId } = this.state
      const transactions = await getTransactionDetails(transactionId)
      this.setState({
        edit: true,
        showAddTaskModal: true,
        taskDetailObj: taskDetail,
        transactionSharedUser: transactions[0].shareWith
      })
      this.setTransactionWorkflowTask()
    }
  }

  private changeTaskStatus = () => {
    ToggleTaskStatus = async (taskId: string, value: string) => {
      const { setUpdateTransactionWorkflowTask2 } = this.props
      const { transactionId } = this.state
      const res = await updateTask(taskId, value, undefined, transactionId)
      setUpdateTransactionWorkflowTask2(res)
      this.setTransactionWorkflowTask()
    }
  }

  private changeAssignee = () => {
    reassignTask = async (taskId: string, userId: string) => {
      const { setUpdateTransactionWorkflowTask2 } = this.props
      const { transactionId } = this.state
      const res = await updateTask(taskId, undefined, userId, transactionId)
      setUpdateTransactionWorkflowTask2(res)
    }
  }

  private delete = () => {
    deleteTask = async (taskId: string) => {
      const { transactionId } = this.state
      const { setDeleteTransactionWorkflowTask } = this.props
      setDeleteTransactionWorkflowTask(taskId)
      deleteTransactionTask(taskId, transactionId)
    }
  }

  private addWorkflowTask = async (task: any) => {
    const { edit, transactionId, transactionOwnerId, user } = this.state
    const { setNewTransactionWorkflowTask, setUpdateTransactionWorkflowTask2 } = this.props
    try {
      const response = await createTransactionTask(task, transactionId, edit, transactionOwnerId, user)
      if (edit) {
        setUpdateTransactionWorkflowTask2(response.data.updateTask)
      } else {
        setNewTransactionWorkflowTask(response.data.createTask)
      }
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }

  private assignToggleCheckBox = () => {
    ToggleCheckBox = async () => {
      const { checked, transactionId } = this.state
      const { setTransactionWorkflowTask } = this.props
      this.setState({ checked: !checked })
      Checked(checked)
      const data = await ChangeTransactionTaskToggleCheckBox(checked, transactionId)
      setTransactionWorkflowTask(data)
    }
  }

  private assignSingleCheckBox = () => {
    SingleCheckBox = async (id: string) => {
      const { transactionId } = this.state
      const { setTransactionWorkflowTask } = this.props
      const data = await ToggleTransactionTaskSingleCheckBox(id, transactionId)
      setTransactionWorkflowTask(data)
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  shareWith: state.transactions.shareWith,
  transactionDetail: state.transactions.transactionDetail,
  workflowTask: state.transactions.workflowTask
})

export default connect(
  mapStateToProps,
  {
    setDeleteMutlipleTransactionWorkflowTask: Actions.deleteMutlipleTransactionWorkflowTask,
    setDeleteTransactionWorkflowTask: Actions.deleteTransactionWorkflowTask,
    setNewTransactionWorkflowTask: Actions.createTransactionWorkflowTask,
    setTransactionWorkflowTask: Actions.getTransactionWorkflowTask,
    setupdateMultipleTransactionWorkflowTask: Actions.updateMultipleTransactionWorkflowTask,
    setUpdateTransactionWorkflowTask2: Actions.updateTransactionWorkflowTask
  }
)(Workflow)
