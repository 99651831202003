// Import Packages
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars, y } from 'design/Styled'
interface Props {
  background?: string
  left?: number
}

const DashboardContainer = styled.section`
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: auto;
  height: calc(100vh - 81px);
  margin: 0px auto;
`

const SidebarHiddenClose = styled.div`
  width: calc(100% - 400px);
  height: calc(100vh - 81px);
  top: 76px;
  z-index: 200000;
  position: fixed;
  cursor: pointer;
  left: 0px;
`

const Label = styled.div`
  width: 20px;
  height: 20px;
  background: ${Colors.White1000};
  border: 1px solid ${Colors.DarkBlue220};
  position: absolute;
  border-radius: 50%;
  color: white;
  left: 30px;
  top: -10px;
`

const Shared = styled.div`
  display: flex;
  position: relative;
  margin-left: 6px;
`

const SharedProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
  position: absolute;
  left: ${(props: Props) => props.left || props.left}px;
  border: 1px solid;
  border-radius: 50%;
  border: 1px solid ${Colors.DarkBlue220};
`

const Status = styled.div`
  background-color: ${(props: Props) => props.background || props.background};
  min-width: 75px;
  max-width: 40px;
  justify-content: center;
  text-align: -webkit-center;
  color: ${Colors.White1000};
  font-size: 12px;
  font-weight: bold;
`

const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

const StyledLabel = styled.div`
  text-align: center;
  color: ${Colors.DarkBlue220};
  font-weight: bold;
`

const Type = styled.div`
  background-color: ${(props: Props) => props.background || props.background};
  min-width: 60px;
  max-width: 40px;
  justify-content: center;
  text-align: -webkit-center;
  color: ${Colors.White1000};
  font-size: 12px;
  font-weight: bold;
`

const CheckboxWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`

const StyledGridContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
  background-color: ${Colors.White800};
  width: 95%;
  margin-left: 40px;
  margin-bottom: 25px;

  .table-styled {
    height: 550px;
    width: 100%;
    overflow-y: scroll;
    ${scrollbars};
  }

  .table-styled > table > tbody > p:nth-last-child(1) {
    display: none;
  }
  `
const Profile = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black500};
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
`

const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 25px;
  min-height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
  font-style: normal;

  div {
    ${y};
    height: 100%;
    color: ${Colors.DarkBlue220};
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
`

const GridContainer = styled.div`
  overflow: auto;
  height: 550px;

  .table-styled {
    height: 550px;
    width: 100%;
    overflow-y: scroll;
    ${scrollbars};
  }
`

export {
  CheckboxWrapper,
  DashboardContainer,
  Label,
  SidebarHiddenClose,
  SharedProfile,
  StyledDropdown,
  StyledLabel,
  Shared,
  Status,
  StyledGridContainer,
  GridContainer,
  Type,
  Profile,
  Li,
  StyledPopup,
  MemberProfile,
  DropDownPopup,
  Labels
}
