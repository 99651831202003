import * as React from 'react'

import {
  StyledRadio,
} from './Styled'

interface Props {
  label: string
  group: string
  value: any
  checked: boolean
  onClick: (e:any, data:any) => void
}

class RadioButton extends React.Component<Props, {}> {
  public render = () => {
    const {
      checked,
      group,
      label,
      onClick,
      value,
    } = this.props

    return (
      <StyledRadio
        className='rp-button-radio'
        checked={checked}
        label={label}
        name={group}
        onClick={onClick}
        value={value}
      />
    )
  }
}

export default RadioButton
