export const SIGNATURE_03 = (input: any /*SignatureInput*/) => {
  const {
    colorText,
    company,
    companyPosition,
    fontFamily,
    fontSize,
    name,
    nameTitle
  } = input

  return `
    <table style="font-family:${fontFamily};color:${colorText};" cellpadding="0" cellspacing="0">
     <tbody>
      <tr>
       <td style="font-family:${fontFamily};padding-bottom:${fontSize *
    0.4}px;padding-top:${fontSize *
    0.75}px; padding-left:0; padding-right:0; vertical-align:top;" valign="top">
        <strong><span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize +
    1}px;line-height:${fontSize * 1.25}px;">${
    nameTitle ? nameTitle + ' ' + name : name
  }</span></strong>
       </td>
      </tr>
      <tr>
       <td style="font-family:${fontFamily};color:${colorText};padding-bottom:${fontSize *
    0.5}px; padding-top:0; padding-left:0; padding-right:0;line-height:${fontSize *
    1.25}px;vertical-align:top;" valign="top">
        <span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize}px;">${companyPosition}</span><br/>
        <span style="font-family:${fontFamily};color:${colorText};font-size:${fontSize}px;opacity: 0.85;">${company}</span><br/>
       </td>
      </tr>
     </tbody>
    </table>
  `
}
