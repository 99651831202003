// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import { GET_CHECKLIST_CATEGORIES } from 'queries/graphql/Settings/ChecklistManager/ChecklistCategory/Queries'
import { GET_PROPERTY_SUB_CATEGORY } from 'queries/graphql/Settings/ChecklistManager/DocChecklist/Queries'
import { GET_DOC_TEMPLATES } from 'queries/graphql/Settings/ChecklistManager/DocTemplate/Queries'
import { GET_USER_STATES } from 'queries/graphql/States/Queries'

export const getChecklistCategories = async (where: any) => {
  where = {
    ...where,
    isActive: true
  }
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CHECKLIST_CATEGORIES,
    variables: { where }
  })
  return response.data.getCheckListCategories
}

export const getDocTemplates = async (where: any) => {
  where = {
    ...where,
    isActive: true
  }
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_DOC_TEMPLATES,
    variables: { where }
  })

  return response.data.getDocTemplate
}

export const getStates = async () => {
  const states = await client.query({
    query: GET_USER_STATES
  })

  return states.data.getUserOfficeState
}

export const getCategoryChecklist = async (categoryName: any) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PROPERTY_SUB_CATEGORY,
    variables: { categoryName }
  })
  if (categoryName) {
    return response.data.getPropertyCategories[0].propertySubCategories
  } else {
    const subCategories: any = response.data.getPropertyCategories.map((propertyCategory: any) => {
      return propertyCategory.propertySubCategories
    })
    return subCategories
  }
}
