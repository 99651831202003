export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`title`]) {
    formIsValid = false
    errors[`title`] = 'Can Not be empty'
  }

  if (!fields[`content`]) {
    formIsValid = false
    errors[`content`] = 'Can Not be empty'
  }

  return { errors, formIsValid }
}
