// Import Packages
import moment from 'moment'
import * as React from 'react'

// Import Components
import { Activities } from 'store/Transactions/Types'
import { getTransactionActivities } from '../../../Dashboard/TransactionQueries'
import ActivityContent from './ActivityContent'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Date,
  // Divider,
  TimeLineContainer,
  Title,
  Wrapper
} from './Styled'

interface State {
  activities: Activities[]
  uniqueDates: Activities[]
}

interface Props {
  transactionId: string
}

class Activity extends React.Component<Props, State> {
  public state = {
    activities: [],
    uniqueDates: []
  }

  public async componentDidMount() {
    const { transactionId } = this.props
    const activities = await getTransactionActivities(transactionId)
    const newDistinctDates = [...activities.map((activity: any) => moment(activity.updatedAt).format('LL'))]
    const unique = newDistinctDates.filter((elem, index, self) => {
      return index === self.indexOf(elem)
    })
    this.setState({ activities, uniqueDates: unique })
  }

  public render() {
    const { activities, uniqueDates } = this.state
    return (
      <TimeLineContainer className="notes-styling">
        <Title>Transaction Life Cycle</Title>
        {activities.length > 0 &&
          uniqueDates.length > 0 && (
            <Wrapper>
              {uniqueDates.map((items: any, index: number) => {
                return (
                  <div key={index}>
                    <Date>
                      {this.dateMatch(items) === 0 && <span>{Strings.transactionActivityTab.today}</span>}
                      {this.dateMatch(items) === -1 && <span>{Strings.transactionActivityTab.yesterday}</span>}
                      {items}
                    </Date>
                    <ActivityContent content={activities} currentDate={items} />
                  </div>
                )
              })}
            </Wrapper>
          )}
      </TimeLineContainer>
    )
  }

  private dateMatch = (date: string) => {
    const todayDate = moment()
    const a = moment(date)
    const b = moment(todayDate)
    return a.diff(b, 'days')
  }
}

export default Activity
