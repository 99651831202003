import * as React from 'react'

import Button from 'shared/Button'
import Transition from 'shared/Transition'
import Carousel from 'templates/Listing/Carousel'

import { ImageType } from 'templates/Listing/Types'

import {
  Container,
  Row,
  StyledModal,
} from './Styled'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([ faTimes ])

interface Props {
  active: number
  images: ImageType[]
  onClose: () => void
}

interface State {
  transition: boolean
}

class LightBox extends React.Component<Props, State> {
  public state = {
    transition: true,
  }

  private DURATION: number = 300

  public render = () => {
    const {
      active,
      images,
    } = this.props

    const { transition } = this.state

    return (
      <Transition
        on={transition}
        type='fadeIn'
        duration={this.DURATION}
      >
        <StyledModal
          open={true}
          content={(
            <Container>
              <Row primary={true}>
                <Carousel
                  images={images}
                  settings={{
                    initialSlide: active
                  }}
                />
              </Row>
              <Row justify='center' padding={'1em 0 0'}>
                <Button.Bubble
                  className='close'
                  icon={faTimes}
                  onClick={this.closeSelf}
                  type='light'
                  size={30}
                />
              </Row>
            </Container>
          )}
          onClose={this.closeSelf}
        />
      </Transition>
    )
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      onClose()
    }, this.DURATION)
  }
}

export default LightBox
