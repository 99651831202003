// Import Packages
import { ChromePicker } from 'react-color'
import { Button, Dropdown, Icon, Input, Label, Popup } from 'semantic-ui-react'
import { css } from 'styled-components'
import styled from 'styled-components'

// Import Fonts, Colors
import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const children = css`
  & > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  & > :first-child {
    margin-left: 0;
  }

  & > :last-child {
    margin-right: 0;
  }
`

const xPadding = css`
  padding-left: 2.5em;
  padding-right: 2.5em;
`

const yPadding = css`
  padding-top: 1em;
  padding-bottom: 1em;
`

const text = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Black500};
  margin: 0;
  line-height: 1.5;
`

const row = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;
`

const col = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const Container = styled.div`
  ${text} ${col}
  font-size: 90%;
  min-height: 90vh;
  background: ${Colors.White1000};

  .ui.label {
    ${text} width: 100%;
    max-width: 110px;
    background: ${Colors.transparent};
  }
`

export const Fa = styled(FontAwesomeIcon)``

export const FontContainer = styled.div`
  ${row} ${xPadding}

  .col {
    ${row} width: 25%;
  }

  .ui.label {
    max-width: 80px;
  }
`

export const Footer = styled.footer`
  ${row}
  ${xPadding}
  ${yPadding}
  ${children}
`

export const FormContainer = styled.section`
  ${row} ${xPadding}
  flex-wrap: wrap;

  .col {
    ${row} width: 50%;
  }

  .ui.input {
    padding-right: 0.833em;
  }
`

export const Header = styled.h1`
  ${row}
  ${text}
  ${xPadding}
  ${yPadding}
  padding-top: 2em;
  font: ${fonts.xLarge.bold};
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 10px;
`

export const Nav = styled.div`
  font-size: 2em;
  color: ${Colors.Black400};
`

export const PickColor = styled.div`
  display: flex;
  min-height: 2.7em;
  width: 80%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  cursor: pointer;
`

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
  min-width: 280px;
`

export const PreviewContainer = styled.section`
  ${row} align-items: center;
  justify-content: center;
  font-size: 100%;
  flex: 1;
  background: ${Colors.Grey110};
`

export const SocialContainer = styled.section`
  ${row}
  ${xPadding}
  ${children}
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${Colors.CardShadow};
`

export const StyledButton = styled(Button)`
  &.ui.button {
    background: ${Colors.ButtonPrimary};
    color: ${Colors.White1000};
    border-radius: 0;
    text-transform: uppercase;
  }
`

export const StyledChromePicker = styled(ChromePicker)`
  &.chrome-picker > div:nth-child(2) {
    height: 120px;
    background: ${Colors.white605};
  }

  &.chrome-picker {
    /* width: 180px !important; */
  }
`

export const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    display: flex;
    flex: 1;
    min-width: 8em;
  }
`

export const StyledInput = styled(Input)`
  display: flex;
  flex: 1;

  &.ui.icon.input > i.icon {
    opacity: 1;
  }

  &.ui[class*='left icon'].input > input {
    padding-left: 3.5em !important;
  }
`

export const StyledIcon = styled(Icon)`
  &:before {
    font-size: 3.025em;
    margin-left: -1px;
  }

  &.facebook {
    color: ${Colors.Facebook};
  }

  &.twitter {
    color: ${Colors.Twitter};
  }

  &.linkedin {
    color: ${Colors.LinkedIn};
  }
`

export const StyledLabel = styled(Label)``

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    position: relative;
    padding: 0;
    background: ${Colors.white605};
    margin-left: 12px;
  }

  &.ui.right.center.popup:before {
    background: ${Colors.white605};
  }
`
