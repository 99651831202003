import gql from 'graphql-tag'

export const GET_TENANT = gql`
  query getTenants($id: String) {
    getTenants(where: { _id: $id }) {
      _id
      companyName
      bankName
      accountNumber
      routingNumber
      companyType
      domainName
      billingAddress {
        type
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      shippingAddress {
        type
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      dateFormat
      calenderWeek
      companyLogo
      email
      phone
      social {
        type
        url
      }
      paymentTolerance
      maxImportLimit
      referralBonus
      maxAgentReferrals
      maxTeamSize
    }
  }
`
