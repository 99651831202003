// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const Container = styled.div`
  background: ${Colors.White1000};
  padding: 30px;
  text-align: justify;
  height: calc(88vh - 70px);
  overflow: auto;
  overflow-x: hidden;
  ${scrollbars};
`

export {
  Container
}

