import { ActionCreator } from 'redux'
import Actions from 'store/Teams/ActionsTypes'
import {
  Filter,
  TeamAddNewBoard,
  TeamAddNewCard,
  TeamDeleteCard,
  TeamDetails,
  TeamFilter,
  TeamGetBoardData,
  TeamItemsDetails,
  TeamResetCardData,
  TeamRestoreCard,
  TeamSearchData,
  TeamSerachLoader,
  TeamSetNewBoardName,
  TeamSetNewDescription,
  TeamSetNewIcon
} from './Types'

export const addNewBoard: ActionCreator<TeamAddNewBoard> = (
  index: number,
  newData: TeamDetails
) => ({
  payload: {
    index,
    newData
  },
  type: Actions.teamAddNewBoard
})

export const getBoardData: ActionCreator<TeamGetBoardData> = (
  data: TeamDetails[]
) => ({
  payload: {
    data
  },
  type: Actions.teamGetBoardData
})

export const addNewCard: ActionCreator<TeamAddNewCard> = (
  id: string,
  index: string,
  newData: TeamItemsDetails
) => ({
  payload: {
    id,
    index,
    newData
  },
  type: Actions.teamAddNewCard
})

export const setNewIcon: ActionCreator<TeamSetNewIcon> = (
  id: string,
  icon: string
) => ({
  payload: {
    icon,
    id
  },
  type: Actions.teamSetNewIcon
})

export const setNewDescription: ActionCreator<TeamSetNewDescription> = (
  id: string,
  description: string
) => ({
  payload: {
    description,
    id
  },
  type: Actions.teamSetNewDescription
})

export const setNewBoardName: ActionCreator<TeamSetNewBoardName> = (
  id: string,
  boardName: string
) => ({
  payload: {
    boardName,
    id
  },
  type: Actions.teamSetNewBoardName
})

export const deleteCard: ActionCreator<TeamDeleteCard> = (
  boardId: string,
  cardId: string
) => ({
  payload: {
    boardId,
    cardId
  },
  type: Actions.teamDeleteCard
})

export const restoreCard: ActionCreator<TeamRestoreCard> = () => ({
  type: Actions.teamRestoreCard
})

export const resetCardData: ActionCreator<TeamResetCardData> = () => ({
  type: Actions.teamResetCardData
})

export const filter: ActionCreator<TeamFilter> = (data: Filter) => ({
  payload: {
    data
  },
  type: Actions.teamFilter
})

export const searchLoader: ActionCreator<TeamSerachLoader> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.teamSearchLoader
})

export const searchDataFilter: ActionCreator<TeamSearchData> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.teamSearchData
})
