import { Button, Form } from 'semantic-ui-react'
import { css } from 'styled-components'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

const children = css`
  & > :first-child {
    margin-left: 0;
  }
  & > :last-child {
    margin-right: 0;
  }
`

const text = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Text};
  margin: 0;
  line-height: 1.5;
`

const row = css`
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
`

const col = css`
  margin-right: 10px;
  ${children};
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;

  span {
    margin-left: 0.8em;
  }
`

export const Aside = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  width: 40%;
  background: ${Colors.ImagePanelContainer};
`

export const Col = styled.div`
  ${col};
`

export const Container = styled.div`
  ${text};
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  width: 100%;

  .ui.form textarea {
    margin-bottom: 1em;
  }
`

export const Content = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3em;
`

export const FormField = styled(Form.Field)`
  ${col} flex-direction: column;
  align-items: flex-start;

  label {
    padding-bottom: 0.8em;
  }

  .ui.input {
    width: 75%;
    & > input {
      border-radius: 0;
    }
  }

  .ui.selection.dropdown {
    border-radius: 0;
  }

  .ui.button {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0 !important;
    border-left: none;
    background: ${Colors.transparent};
    &:hover {
      background: ${Colors.transparent};
    }
  }
`

export const Image = styled.img`
  width: 100%;
`

export const Row = styled.div`
  ${row};
  ${children};
  padding: 1em;
  margin-bottom: 0.5em;

  &.interactive {
    margin-bottom: 0;
    padding: 0.8em 1em;
    cursor: pointer;
    transition: 200ms all;
    &:hover {
      color: ${Colors.ButtonPrimary};
      background: ${Colors.EmphasisContainer};
    }
  }

  &.input-row {
    margin-top: 2em;
  }
`

export const StyledButton = styled(Button)`
  &.ui.button {
    min-width: 80px;
    background: ${Colors.ButtonPrimary};
    color: ${Colors.White1000};
    border-radius: 0;
    text-transform: uppercase;
    margin-right: 0.5em;
  }
`
