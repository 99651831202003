import { omit, pullAt } from 'lodash'
import * as React from 'react'
import { Component } from 'react'
import { Input } from 'semantic-ui-react'
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input'
import client from 'queries/apollo'
import { Strings } from 'utils'
import { UPDATE_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Mutation'
import { GET_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Queries'
import { UPDATE_TRANSACTION_SETTING_LOCAL } from 'queries/graphql/Settings/TransactionFee/Local/FeeSettingLocalMutation'
import { activeTransactionSettingIndex } from '../FeeSettings'
import { SettingRowSection, VerticalText } from '../Styled'
import TransactionSettingQuery from '../TransactionSettingQuery'
import ProgressiveFeeItem from './ProgressiveFeeItem'
import RadioItem from './RadioItem'
import {
  AddItemContainer,
  BorderTree,
  FeeGroup,
  ProgressiveFeeContainer,
  TextBoxContainer,
  TextBoxItem,
  TextBoxLabel,
  TypeSelectContainer
} from './Styled'

interface ProgressiveFeeNewData {
  maximum?: number | null
  minimum?: number | null
  percentage?: number | null
}

class CommissionFee extends Component<{}, {}> {
  private transactionFeeId = ''

  public onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newObj
    if (e.target.value === '') {
      newObj = {
        flatFeeAmount: parseFloat('0')
      }
    } else {
      newObj = {
        flatFeeAmount: parseFloat(e.target.value)
      }
    }

    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public onPercentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newObj
    if (e.target.value === '') {
      newObj = {
        flatFeePercentage: parseFloat('0')
      }
    } else {
      newObj = {
        flatFeePercentage: parseFloat(e.target.value)
      }
    }

    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public onChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const newType = data.value.toString()
    const newObj = {
      commissionFeeType: newType
    }

    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING,
      variables: {
        _id: this.transactionFeeId,
        ...newObj
      }
    })
  }

  public addProgressiveFeeItem = (ProgressiveFeeData: any) => {
    let newObj = {}
    if (ProgressiveFeeData === null) {
      newObj = {
        progressiveFee: [
          {
            __typename: 'ProgressiveFee',
            maximum: 0,
            minimum: 0,
            percentage: 0
          }
        ]
      }
    } else {
      ProgressiveFeeData.push({
        __typename: 'ProgressiveFee',
        maximum: 0,
        minimum: 0,
        percentage: 0
      })
      newObj = {
        progressiveFee: ProgressiveFeeData
      }
    }

    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING,
      variables: {
        _id: this.transactionFeeId,
        ...newObj
      }
    })
  }

  public deleteProgressiveFeeItem = (ProgressiveFeeData: any, id: number) => {
    pullAt(ProgressiveFeeData, [id])
    const newObj = {
      progressiveFee: ProgressiveFeeData
    }
    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      refetchQueries: GET_TRANSACTION_SETTING,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public changeItem = (ProgressiveFeeData: any, id: number, newData: ProgressiveFeeNewData) => {
    const newObj = {
      progressiveFee: [
        ...ProgressiveFeeData.slice(0, id),
        omit(Object.assign({}, ProgressiveFeeData[id], newData), '__typename'),
        ...ProgressiveFeeData.slice(id + 1)
      ]
    }
    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      refetchQueries: GET_TRANSACTION_SETTING,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public render() {
    return (
      <SettingRowSection>
        <VerticalText as="h4" textAlign="center">
          {Strings.transactionFeeSettings.types.commission.name}
        </VerticalText>
        <BorderTree />
        <TransactionSettingQuery fetchPolicy="network-only" query={GET_TRANSACTION_SETTING}>
          {({ loading, error, data }) => {
            if (loading) {
              return 'Loading...'
            }
            if (error) {
              return `Error! ${error.message}`
            }
            if (data && data.getTransactionSettings && data.getTransactionSettings[activeTransactionSettingIndex]!!) {
              const { getTransactionSettings } = data
              this.transactionFeeId = getTransactionSettings[activeTransactionSettingIndex]!!._id
              return (
                <FeeGroup>
                  <TypeSelectContainer>
                    <RadioItem
                      name="paymentType"
                      value={Strings.transactionFeeSettings.flat.key}
                      checked={
                        getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                        Strings.transactionFeeSettings.flat.key
                      }
                      onChange={this.onChange}
                      label={Strings.transactionFeeSettings.flat.label}
                    />
                    <RadioItem
                      name="paymentType"
                      value={Strings.transactionFeeSettings.flatPercent.key}
                      checked={
                        getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                        Strings.transactionFeeSettings.flatPercent.key
                      }
                      onChange={this.onChange}
                      label={Strings.transactionFeeSettings.flatPercent.label}
                    />
                    <RadioItem
                      name="paymentType"
                      value={Strings.transactionFeeSettings.hybrid.key}
                      checked={
                        getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                        Strings.transactionFeeSettings.hybrid.key
                      }
                      onChange={this.onChange}
                      label={Strings.transactionFeeSettings.hybrid.label}
                    />
                    <RadioItem
                      name="paymentType"
                      value={Strings.transactionFeeSettings.progressive.key}
                      checked={
                        getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                        Strings.transactionFeeSettings.progressive.key
                      }
                      onChange={this.onChange}
                      label={Strings.transactionFeeSettings.progressive.label}
                    />
                    {getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                    Strings.transactionFeeSettings.progressive.key ? (
                      <AddItemContainer
                        onClick={() =>
                          this.addProgressiveFeeItem(
                            getTransactionSettings[activeTransactionSettingIndex]!!!!.progressiveFee
                          )
                        }
                      >
                        {Strings.transactionFeeSettings.addMore}
                      </AddItemContainer>
                    ) : null}
                  </TypeSelectContainer>
                  <TextBoxContainer>
                    {getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                      Strings.transactionFeeSettings.flat.key ||
                    getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                      Strings.transactionFeeSettings.hybrid.key ? (
                      <TextBoxItem>
                        <TextBoxLabel>{Strings.transactionFeeSettings.enterFeeLabel}</TextBoxLabel>
                        <Input
                          type="number"
                          icon="dollar"
                          iconPosition="left"
                          value={getTransactionSettings[activeTransactionSettingIndex]!!.flatFeeAmount}
                          onChange={this.onValueChange}
                        />
                      </TextBoxItem>
                    ) : null}
                    {getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                      Strings.transactionFeeSettings.flatPercent.key ||
                    getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                      Strings.transactionFeeSettings.hybrid.key ? (
                      <TextBoxItem>
                        <TextBoxLabel>{Strings.transactionFeeSettings.enterPercentFeeLabel}</TextBoxLabel>
                        <Input
                          type="number"
                          icon="percent"
                          iconPosition="left"
                          value={getTransactionSettings[activeTransactionSettingIndex]!!.flatFeePercentage}
                          onChange={this.onPercentChanged}
                        />
                      </TextBoxItem>
                    ) : null}
                    {getTransactionSettings[activeTransactionSettingIndex]!!.commissionFeeType ===
                    Strings.transactionFeeSettings.progressive.key ? (
                      <ProgressiveFeeContainer>
                        {data &&
                          getTransactionSettings[activeTransactionSettingIndex]!! &&
                          getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee &&
                          getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee!!.map(
                            (amountItem: any, index: number) => {
                              const onChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value === '') {
                                  this.changeItem(
                                    getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee,
                                    index,
                                    { minimum: parseFloat('0') }
                                  )
                                } else {
                                  this.changeItem(
                                    getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee,
                                    index,
                                    { minimum: parseFloat(e.target.value) }
                                  )
                                }
                              }
                              const onChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value === '') {
                                  this.changeItem(
                                    getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee,
                                    index,
                                    { maximum: parseFloat('0') }
                                  )
                                } else {
                                  this.changeItem(
                                    getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee,
                                    index,
                                    { maximum: parseFloat(e.target.value) }
                                  )
                                }
                              }
                              const onChangePercent = (e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value === '') {
                                  this.changeItem(
                                    getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee,
                                    index,
                                    { percentage: parseFloat('0') }
                                  )
                                } else {
                                  this.changeItem(
                                    getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee,
                                    index,
                                    { percentage: parseFloat(e.target.value) }
                                  )
                                }
                              }
                              const deleteItem = () => {
                                this.deleteProgressiveFeeItem(
                                  getTransactionSettings[activeTransactionSettingIndex]!!.progressiveFee,
                                  index
                                )
                              }
                              return (
                                <ProgressiveFeeItem
                                  key={index}
                                  index={index}
                                  onChangeMin={onChangeMin}
                                  onChangeMax={onChangeMax}
                                  onChangePercent={onChangePercent}
                                  deleteItem={deleteItem}
                                  max={amountItem!!.maximum}
                                  min={amountItem!!.minimum}
                                  percent={amountItem!!.percentage}
                                />
                              )
                            }
                          )}
                      </ProgressiveFeeContainer>
                    ) : null}
                  </TextBoxContainer>
                </FeeGroup>
              )
            }
            return null
          }}
        </TransactionSettingQuery>
      </SettingRowSection>
    )
  }
}

export default CommissionFee
