import { Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { SecondaryFontName } from 'design/Fonts'
import { x, y } from 'design/Styled'

export const Container = styled(Form)`
  ${y};
  margin-top: 1em;
  margin-bottom: 1em;
`

export const Row = styled.div`
  ${x};
  justify-content: space-between;

  .ui.input {
    margin: 6px;
    width: 100%;
  }

  @media (max-width: 414px) {
    flex-wrap: wrap;
  }
`

export const MemberLabel = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  font-family: ${SecondaryFontName};
  color: ${Colors.DarkBlue200};
`

export const StyledInput = styled(Input)`
  width: 100%;
  margin: 4px 6px;
`
