import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ConfirmAlert from 'sweetalert2'

import LoadingIndicator from 'shared/LoadingIndicator'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Toast, { serverToast } from 'shared/Toast/Toast'
import ReassignModal from '../ReassignModal'
import { ToggleStatus } from '../Users'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import * as Transactions from 'store/Transactions/Actions'
import * as UsersActions from 'store/Users/Actions'

import { sendAccountLink } from 'app/Onboarding/Queries'
import { userReassign } from '../UserMutation'

import { Office, UserType } from 'store/Users/Types'

import { SubjectEnum } from 'app/Onboarding/Types'
import { UsersItem } from 'store/Users/Types'

import { UserGroup } from 'store/Users/Types'
import { getFilterGroups } from '../UserQueries'
import { formatKanbanData } from '../Utils/FormattingData'

import Colors from 'design/Colors'
import IconContactBook from 'design/icons/users/userCard/team_ic_1.png'
import IconFolder from 'design/icons/users/userCard/team_ic_2.png'
import IconUser from 'design/icons/users/userCard/team_ic_3.png'
import IconUserEmail from 'design/icons/users/userCard/user_Email.png'
import IconUserLocation from 'design/icons/users/userCard/user_location.png'
import IconUserPhone from 'design/icons/users/userCard/user_Phone.png'

import { getLoggedInUser, Routes, Strings } from 'utils'

import {
  CardContent,
  CardFooter,
  CardHeader,
  CardImage,
  CardMeta,
  CardWrapper,
  Container,
  Divider,
  ExtraContent,
  ImageWrapper,
  StyledButton,
  StyledDropdown,
  StyledPopup
} from './Styled'

import { faRedo } from '@fortawesome/pro-light-svg-icons'
import { faPeopleCarry } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faRedo, faPeopleCarry])

interface StoreProps {
  setBoardData: (data: UserGroup[]) => void
  setViewReport: (report: string) => void
  setTransaction: (userID: string) => void
}

interface OwnProps {
  CardData: UsersItem
  longPress: boolean
  length: number
  order: number
}

type Props = StoreProps & OwnProps
interface State {
  showComposeMail: boolean
  modal: boolean
  user: UserType
  loader: boolean
  currentOffice: Office
}

const options = [
  { key: 1, text: 'Active', value: 'Active' },
  { key: 2, text: 'Inactive', value: 'Inactive' },
  { key: 3, text: 'Pending', value: 'Pending' }
]

class UserCard extends React.Component<Props, State> {
  public state = {
    currentOffice: {} as Office,
    loader: false,
    modal: false,
    showComposeMail: false,
    user: {} as UserType
  }

  public async componentDidMount() {
    const { CardData } = this.props
    const user: UserType = await getLoggedInUser({ fromCache: true })
    this.setState({ user, currentOffice: CardData.office[0] })
  }

  public render() {
    const { CardData, longPress, length, order } = this.props
    const { showComposeMail, modal, user, loader, currentOffice } = this.state
    let background
    let color
    let phone
    const address = CardData[`addresses`] && CardData[`addresses`][0] ? CardData[`addresses`][0] : null
    if (CardData.phones !== null) {
      phone = CardData.phones[0] && CardData.phones.find(card => card.type === 'Mobile')
    }
    switch (CardData.status) {
      case 'Inactive':
        background = Colors.Black810
        color = Colors.Black500
        break
      case 'Active':
        background = Colors.Green500
        color = Colors.White400
        break
      case 'Pending':
        background = Colors.Red800
        color = Colors.White1000
        break
      default:
        background = Colors.Black810
        break
    }

    return (
      <Container>
        {loader && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={CardData.userName} />}
        {modal && (
          <ReassignModal
            closeModal={this.closeModal}
            currentOffice={currentOffice}
            changeUserOffice={this.changeUserOffice}
          />
        )}
        <CardWrapper>
          <CardContent>
            <Link
              to={Routes.primary.profile.path + `/${CardData._id}`}
              onClick={(e: any) => longPress && e.preventDefault()}
            >
              <CardImage floated="left" circular={true} size="tiny" src={CardData.profileImage} />
              <CardHeader>{`${CardData.firstName} ${CardData.lastName}`}</CardHeader>
            </Link>
            <CardMeta>
              <StyledPopup
                trigger={<ImageWrapper className="small" backgroundImage={IconUserLocation} />}
                content={
                  address != null
                    ? [
                        address.streetNumber,
                        address.streetName,
                        address.city,
                        address.state,
                        address.country,
                        address.zipCode
                      ].join(', ')
                    : `No Address Added`
                }
              />
              <StyledPopup
                trigger={
                  <ImageWrapper
                    className="small"
                    backgroundImage={IconUserEmail}
                    onClick={e => {
                      e.stopPropagation()
                      this.sendMail()
                    }}
                  />
                }
                content={CardData.userName}
              />
              <StyledPopup
                trigger={<ImageWrapper className="small" backgroundImage={IconUserPhone} />}
                content={phone ? phone.value : `No Phone Number Added`}
              />
              {CardData.role !== 'ADMIN' &&
                user.role === 'ADMIN' && (
                  <div onClick={this.showReassignModal}>
                    <FontAwesomeIcon icon={['fas', 'people-carry']} />
                  </div>
                )}
            </CardMeta>
          </CardContent>
          <Divider />
          <ExtraContent>
            <div>
              {' '}
              <StyledDropdown
                className={length - 1 === order ? 'upward' : ''}
                inline={true}
                options={options}
                onChange={this.handleChangeStatus}
                defaultValue={CardData.status}
                background={background}
                color={color}
              />
            </div>
            <CardFooter extra={true} textAlign="right">
              {CardData.status === 'Pending' && (
                <StyledButton icon={true} onClick={() => this.resendMail(CardData)}>
                  <FontAwesomeIcon icon={['fal', 'redo']} />
                </StyledButton>
              )}
              <Link
                to={Routes.primary.profile.path + `/${CardData._id}`}
                onClick={(e: any) => longPress && e.preventDefault()}
              >
                <StyledButton icon={true}>
                  <ImageWrapper backgroundImage={IconUser} />
                </StyledButton>
              </Link>
              <Link
                to={Routes.primary.transactions.path + '?previous-page=users'}
                onClick={() => this.props.setTransaction(CardData._id)}
              >
                <StyledButton icon={true}>
                  <ImageWrapper backgroundImage={IconFolder} />
                </StyledButton>
              </Link>
              <Link
                to={Routes.primary.contacts.path + '?previous-page=users'}
                onClick={(e: any) => longPress && e.preventDefault()}
              >
                <StyledButton icon={true}>
                  <ImageWrapper backgroundImage={IconContactBook} />
                </StyledButton>
              </Link>
            </CardFooter>
          </ExtraContent>
        </CardWrapper>
      </Container>
    )
  }

  private changeUserOffice = async (officeId: string) => {
    const { CardData, setBoardData } = this.props
    const { user } = this.state
    this.setState({ loader: true })
    try {
      await userReassign(CardData._id, [officeId])
      const result = await getFilterGroups()
      const { data }: any = result
      const kanbanData = formatKanbanData(data.getGroups, user)
      setBoardData(kanbanData)
      this.setState({ loader: false })
    } catch (e) {
      serverToast(e)
      this.setState({ loader: false })
    }
  }

  private showReassignModal = () => {
    this.setState({ modal: true })
  }

  private resendMail = async (user: UsersItem) => {
    try {
      await sendAccountLink({
        type: SubjectEnum.Invite,
        userName: user.userName
      })
      Toast({ message: 'Mail Sent successfully', type: 'success' })
    } catch (e) {
      serverToast(e)
    }
  }

  private sendMail = () => {
    this.setState({ showComposeMail: true })
  }

  private closeModal = () => {
    this.setState({ showComposeMail: false, modal: false })
  }

  private handleChangeStatus = async (e: any, { value }: any) => {
    const { CardData } = this.props
    if (CardData.status !== value) {
      if (value === 'Inactive') {
        await ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepItActive,
          confirmButtonText: Strings.kanbanView.yesMakeItInactive,
          showCancelButton: true,
          text: Strings.kanbanView.inactivateUser,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async result => {
          if (result.value) {
            await ToggleStatus(CardData._id, value)
          }
        })
      } else {
        await ToggleStatus(CardData._id, value)
      }
    }
  }
}

const mapStateToProps = (state: AppState) => ({})

export default connect(
  mapStateToProps,
  {
    setBoardData: UsersActions.getBoardData,
    setTransaction: Transactions.showTransaction,
    setViewReport: Actions.viewReport
  }
)(UserCard)
