import * as React from 'react'

import {
  Clipping,
  Container,
  ProgressBar,
  ProgressText,
} from './Styled'

interface Props {
  percent: number
}

const Progress = ({ percent }: Props) => (
  <Container>
    <ProgressBar percent={percent} />
    <ProgressText percent={percent} />
    <Clipping className='clip-path' percent={percent} />
  </Container>
)

export default Progress
