// Import Packages
import { Grid, Image, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Envalope from 'design/icons/offices/icon-envalope.png'
import Phone from 'design/icons/offices/icon-phone.png'
import MapIcon from 'design/icons/offices/map.png'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background?: string
  color?: string
}

const Header = styled.div`
  display: flex;
  position: relative;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.5);
  color: ${Colors.Black500};
`

const Branch = styled.div`
  border-right: 2px solid ${Colors.Black850};
  width: 40%;
  padding: 20px 0px 0px 20px;
`

const BranchName = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
`

const Location = styled.div`
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  align-items: center;
`

const Map = styled.div`
  background: url(${MapIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 3px;
  display: inline-block;
`

const Level = styled.div`
  border-right: 2px solid ${Colors.Black850};
  width: 15%;
`

const Numbers = styled.div`
  color: ${props => props.color || props.color};
  font-size: 50px;
  text-align: center;
  height: 54px;
  margin-top: 12px;
  margin-bottom: 5px;
`

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: 8px;
`

const Profile = styled.div`
  display: flex;
  width: 26%;
  padding: 25px 0px 0px 15px;
`

const ProfileInfo = styled.div`
  margin-right: 15px;
`

const FullName = styled.div`
  font-size: 12px;
  font-weight: bold;
  width: 80px;
  text-transform: capitalize;
  line-height: 1.2;
`


const Manager = styled.div`
  font-size: 10px;
  text-align: end;
  font-style: italic;
`

const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PhoneIcon = styled.div`
  background: url(${Phone});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  cursor: pointer;
`

const EmailIcon = styled.div`
  background: url(${Envalope});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 5px;
  cursor: pointer;
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 60px;
  align-self: center;
  margin-top: -20px;
  border: 3px solid ${Colors.White850};
  border-radius: 50%;
`

const Content = styled.div`
  display: flex;
  padding: 30px 0px 0px 30px;
  height: 570px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${Colors.White500};
  ${scrollbars};
`

const ProfileProgressContainer = styled.div`
  height: 90px;
  width: 90px;
`

const StyledColumn = styled(Grid.Column)`
  margin-right: 15px;
  margin-bottom: 0;
  padding-bottom: 0;
  height: 20%;
  margin-bottom: 30px !important;
`

const MemberImage = styled(Image)`
  position: absolute !important;
  top: 5px;
  bottom: 0;
  left: 7px;
  right: 0;
  margin: 0;
  padding: 7px;
  cursor: pointer;

  &.ui.tiny.image {
    width: 80px;
    height: 80px;
  }
`

const MemberName = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: ${Colors.Black500};
`

const PopupContainer = styled.div`
  font-size: 10px;
  color: ${Colors.Black500};
`

const PopupLocation = styled.div`
  .fa-map-marker-alt {
    margin-right: 10px;
  }
`

const PopupPhone = styled.div`
  display: flex;

  .fa-phone {
    margin-right: 8px;
    margin-top: 4px;
    transform: rotate(100deg);
  }
`

const PopupEmail = styled.div`
  display: flex;
  cursor: pointer;

  .fa-envelope {
    margin-right: 8px;
    margin-top: 4px;
  }
`

const Triangle = styled.div`
  border-right: 20px solid transparent;
  border-top: 20px solid ${Colors.White1000};
  width: 20px;
  position: absolute;
  left: 0;
  bottom: -20px;
`

const StyledPopup = styled(Popup)`
  &.ui.top.left.popup {
    margin-left: 30px;
    margin-bottom: -5px;
    border-bottom-left-radius: 0;
  }

  &.ui.top.left.popup:before {
    display: none;
  }
`

const StyledGrid = styled(Grid)`
  &.ui.grid {
    width: 100%;
    height: 100%;
  }

  &.ui.grid>.row {
    display: block;
  }

  &.ui.grid>.row>[class*="two wide"].column {
    width: 14.8% !important;
    height: auto;
  }
`

const Progress = styled.div`
  .fa-circle {
    color: ${(props: Props) => props.color || props.color};
    margin-right: 6px;
    margin-top: 4px;
  }
`

export {
  Branch,
  BranchName,
  Content,
  Progress,
  EmailIcon,
  FullName,
  Header,
  Icons,
  Level,
  Location,
  Manager,
  Map,
  MemberImage,
  MemberName,
  Numbers,
  PhoneIcon,
  PopupContainer,
  PopupEmail,
  PopupLocation,
  PopupPhone,
  Profile,
  ProfileImage,
  ProfileInfo,
  ProfileProgressContainer,
  StyledColumn,
  StyledGrid,
  StyledPopup,
  Text,
  Triangle
}
