import { Checkbox, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  children,
  styledToggle,
  styledToolTip,
  text,
  x,
  y,
} from 'design/Styled'

export const Container = styled.div`
  ${x};
  ${children};
`

export const Col = styled.div`
  ${y};
  span {
    width: 100%;
    text-align: center;
  }
`

export const StyledToggle = styled(Checkbox)`
  ${styledToggle};
`

export const Label = styled.span`
  ${text};
`

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`
