import gql from 'graphql-tag'

export const UPDATE_TENANT = gql`
  mutation updateTenant(
    $companyName: String
    $bankName: String
    $accountNumber: String
    $routingNumber: String
    $billingAddress: AddressInput
    $shippingAddress: AddressInput
    $dateFormat: String
    $calenderWeek: String
    $email: String
    $phone: String
    $social: [SocialInput]
    $paymentTolerance: Int
    $maxImportLimit: Int
    $referralBonus: Int
    $maxAgentReferrals: Int
    $maxTeamSize: Int
  ) {
    updateTenant(
      input: {
        companyName: $companyName
        bankName: $bankName
        accountNumber: $accountNumber
        routingNumber: $routingNumber
        billingAddress: $billingAddress
        shippingAddress: $shippingAddress
        dateFormat: $dateFormat
        calenderWeek: $calenderWeek
        email: $email
        phone: $phone
        social: $social
        paymentTolerance: $paymentTolerance
        maxImportLimit: $maxImportLimit
        referralBonus: $referralBonus
        maxAgentReferrals: $maxAgentReferrals
        maxTeamSize: $maxTeamSize
      }
    ) {
      _id
      companyName
      companyType
      domainName
      bankName
      accountNumber
      routingNumber
      billingAddress {
        type
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      shippingAddress {
        type
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      dateFormat
      calenderWeek
      email
      phone
      social {
        type
        url
      }
      paymentTolerance
      maxImportLimit
      referralBonus
      maxAgentReferrals
      maxTeamSize
    }
  }
`

export const UPLOAD_COMPANY_LOGO = gql`
  mutation uploadCompanyLogo($file: Upload!) {
    uploadCompanyLogo(file: $file)
  }
`
