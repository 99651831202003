import Colors from 'design/Colors'
import { Divider, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledSegment = styled(Segment)`
  .fa-user {
    width: 20px;
    height: 20px;
    color: ${Colors.Black500};
  }

  .fa-users {
    width: 25px;
    height: 18px;
    color: ${Colors.Black500};
  }

  span {
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
    color: ${Colors.Black500};
  }

  &.ui.vertical.segment {
    border-bottom: none;
    padding-bottom: 0;
  }
`

const StyledDivider = styled(Divider)`
  &.ui.divider {
    margin-top: 30px;
    margin-bottom: 3px;
    color: ${Colors.Grey400};
  }
`


export {
  StyledDivider,
  StyledSegment,
}
