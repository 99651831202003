// Import Packages
import { Input } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Utils
import { Routes } from 'utils'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  path: string
}

const onDashboard = (path: string): boolean =>
  path === Routes.primary.dashboard.path

const NavbarContainer = styled.nav`
  width: 100%;
  background-color: ${(props: Props) =>
    onDashboard(props.path) ? Colors.White1000 : Colors.White1000};
  padding: 16px 0px 16px 0px;
  position: ${props => (onDashboard(props.path) ? 'relative' : 'relative')};
  box-shadow: 0px 1px 9px 4px rgba(0, 0, 0, 0.21);
  z-index: 99;
`

const NavbarWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: ${(props: Props) =>
    onDashboard(props.path) ? `space-between` : `space-between`};
  align-items: center;
`

const SearchContainer = styled.div`
  align-items: center;
  display: flex;
  width: 40%;
  background: white;
  border-radius: .28571429rem;
  border: 1px solid rgba(34,36,38,.15);
  padding: 3px 5px;
  box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.06);
`

const SearchInput = styled(Input)`
  &.ui.input>input {
    font-size: 12px;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.06);
  }

  .fa-search {
    margin-top: 8px;
    color: ${Colors.Black570};
  }

  .fa-times {
    margin-left: 8px;
    margin-top: 8px;
    color: ${Colors.Black570};
    cursor: pointer;
  }

  &.ui.input {
    width: 100%;
  }

  &.ui.icon.input>input {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`

const Tag = styled.div`
  background: ${Colors.DarkBlue200};
  padding: 3px 20px 3px 8px;
  font-size: 14px;
  color: ${Colors.White1000};
  position: relative;
  white-space: nowrap;

  .times {
    position: absolute;
    top: -3px;
    right: 3px;
  }

  .times>.fa-times {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`

const Plus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: ${Colors.Black550};
`

export {
  NavbarContainer,
  NavbarWrapper,
  Plus,
  SearchInput,
  SearchContainer,
  Tag
}
