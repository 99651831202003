import Konva from 'konva'
import { debounce } from 'lodash'
import * as React from 'react'
import { Layer } from 'react-konva'

import Menu from './Menu'
import Markup from './Markup'
import Rectangle from './Rectangle'

import { generateID } from 'utils'
import { defaultLabels } from './Mocks'

import { Container, StyledStage } from './Styled'

import {
  MarkupType,
  MarkupModeEnum,
  MarkupSourceEnum,
  OptionType,
  PointType,
  StageType,
} from './Types'

interface Props {
  className: string
  markup: MarkupType[]
  onMarkup: (markup: MarkupType[]) => void
  mode: MarkupModeEnum
  source: MarkupSourceEnum
}

interface State {
  labels: OptionType[]
  activeIndex: number
  dropdownOptions: OptionType[]
  dropdownPosition: PointType
  menu: MarkupModeEnum
  mouseIsDown: boolean
  mouseIsDragging: boolean
  mousePosition: PointType
  stage: StageType
  transformOrigin: PointType
}

class MarkupTool extends React.Component<Props, State> {
  public static defaultProps = {
    className: '',
  }

  public state = {
    labels: [] as OptionType[],
    activeIndex: -1,
    dropdownOptions: [] as OptionType[],
    dropdownPosition: {} as PointType,
    menu: MarkupModeEnum.None,
    mouseIsDown: false,
    mouseIsDragging: false,
    mousePosition: {} as PointType,
    stage: {
      height: 0,
      scale: { x: 1, y: 1 },
      width: 0
    },
    transformOrigin: {} as PointType,
  }

  private containerRef: any = React.createRef<HTMLDivElement>()
  private layerRef = React.createRef<Konva.Layer>()
  private initWidth: number = 1
  private initHeight: number = 1
  private threshold: number = 6

  public componentDidMount = () => {
    const { markup } = this.props
    this.setLabels(markup)
    window.addEventListener('resize', debounce(this.updateDimensions, 100))
  }

  public componentDidUpdate = (prev: Props) => {
    const container = this.containerRef.current
    if (!container) {
      return
    }
    const {
      stage: { scale }
    } = this.state
    if (
      this.initWidth !== container.clientWidth ||
      this.initHeight !== container.clientHeight ||
      scale.x !== 1 ||
      scale.y !== 1
    ) {
      this.updateDimensions()
    }
  }

  public componentWillUnmount = () => {
    window.removeEventListener('resize', debounce(this.updateDimensions, 100))
  }

  public render = () => {
    const { className, mode, source, markup } = this.props
    const { activeIndex, labels, menu, stage, mouseIsDragging } = this.state

    return (
      <Container className={className} innerRef={this.containerRef}>
        {mode >= MarkupModeEnum.Editor && (
          <StyledStage
            height={stage.height}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
            scale={stage.scale}
            width={stage.width}
            isActive={mouseIsDragging}
          >
            <Layer ref={this.layerRef}>
              {markup.map((item: MarkupType, index: number) => (
                <Rectangle
                  key={index}
                  onChange={(update: MarkupType) => this.updateRectangle(update, index)}
                  onClick={() => this.handleRectangleClick(index)}
                  rectangle={item}
                  source={source}
                  container={stage}
                />
              ))}
            </Layer>
          </StyledStage>
        )}
        {(source !== MarkupSourceEnum.Textract && mode >= MarkupModeEnum.Viewer) && markup.map((item: MarkupType, index: number) => (
          <Markup
            key={index}
            rectangle={item}
            onChange={(update: MarkupType) => this.updateRectangle(update, activeIndex)}
            onClick={() => this.handleRectangleClick(index)}
            container={stage}
            mode={mode}
          />
        ))}
        {(mode > MarkupModeEnum.Viewer && menu === mode && labels.length > 0 && activeIndex > -1 && activeIndex < markup.length) && (
          <Menu
            labels={labels}
            onChange={(update: MarkupType) => this.updateRectangle(update, activeIndex)}
            rectangle={markup[activeIndex]}
            source={source}
            container={stage}
          />
        )}
      </Container>
    )
  }

  private handleMouseDown = (e: any) => {
    this.setState((current:State) => ({
      mouseIsDown: true,
      menu: e.target.getClassName() === 'Stage' ? MarkupModeEnum.None : current.menu,
    }))
  }

  private handleMouseUp = (e: any) => {
    this.setState({
      mouseIsDown: false,
      mouseIsDragging: false,
    })
  }

  private handleMouseMove = (e: any) => {
    const { mode, markup } = this.props
    const {
      labels,
      mouseIsDown,
      mouseIsDragging,
    } = this.state

    if (!mouseIsDown) {
      return
    }

    const targetIsStage = e.target.getClassName() === 'Stage'

    if (!mouseIsDragging && targetIsStage) {
      const position = this.getRelativePointer(e.target)
      const newRect: MarkupType = {
        height: 0,
        id: generateID(),
        label: mode === MarkupModeEnum.Editor ? labels[2] : {} as OptionType,
        left: position.x,
        style: {},
        top: position.y,
        width: 0,
      }

      markup.push(newRect)
      this.setState({ mouseIsDragging: true })
      this.updateMarkup(markup)
    } else if (!mouseIsDragging && !targetIsStage) {
      const transformOrigin = this.getRelativePointer(e.target)
      this.setState({ transformOrigin })
    }

    if (mouseIsDragging && targetIsStage) {
      const position = this.getRelativePointer(e.target)
      const latest = markup.length - 1
      markup[latest].width = position.x - markup[latest].left
      markup[latest].height = position.y - markup[latest].top

      this.setState({ mouseIsDragging: true })
      this.updateMarkup(markup)
    }
  }

  private updateRectangle = (rectangle: MarkupType, index?: number) => {
    const { source, markup } = this.props

    if (!index && index !== 0) {
      index = markup.findIndex((item: MarkupType) => item.id === rectangle.id)
      if (index < 0) {
        return
      }
    }

    markup[index] = { ...rectangle }
    const update = this.sanitizeRectangles(markup)

    this.setState({
      mouseIsDown: false,
      mouseIsDragging: false,
    })

    this.updateMarkup(update)
    if (source === MarkupSourceEnum.Textract) {
      this.setLabels(markup)
    }
  }

  private updateMarkup = (markup: MarkupType[]) => {
    const { onMarkup } = this.props
    onMarkup(markup)
  }

  private handleRectangleClick = (activeIndex: number) => {
    const { mode, markup } = this.props
    if (activeIndex >= markup.length) {
      return
    }

    this.setState({
      activeIndex,
      menu: mode
    })
  }

  private setLabels = (markup: MarkupType[]) => {
    const { source } = this.props
    let defaults = defaultLabels[source]
    let labels = [ ...defaults ]

    if (source === MarkupSourceEnum.Textract) {
      const active = markup.map((item: MarkupType) => item.label.value)
      labels = defaults.filter((label: OptionType) => {
        return !active.includes(label.value)
      })
    }

    this.setState({ labels })
  }

  private passesThreshold = (markup: MarkupType) => {
    const { stage: { width, scale }} = this.state
    const relativeThreshold = (this.threshold * scale.x / width)
    const result = !(markup.width > relativeThreshold * -1 && markup.width < relativeThreshold)
    return result
  }

  private sanitizeDimensions = (markup: MarkupType) => {
    const { stage, transformOrigin } = this.state
    if (markup.width < 0) {
      markup.left = markup.left + markup.width
      markup.width *= -1
    }

    if (markup.height < 0) {
      markup.top = markup.top + markup.height
      markup.height *= -1
    }

    if (markup.left > 1 || markup.left < -1) {
      markup.left = transformOrigin.x + (markup.left * (stage.scale.x / stage.width))
    }

    if (markup.top > 1 || markup.top < -1) {
      markup.top = transformOrigin.y + (markup.top * (stage.scale.y / stage.height))
    }

    return markup
  }

  private sanitizeRectangles = (markup: MarkupType[]) => {
    markup = markup.filter((rect: MarkupType) => this.passesThreshold(rect))
    markup = markup.map((rect: MarkupType) => this.sanitizeDimensions(rect))
    return markup
  }

  private getRelativePointer = (target: any) => {
    const {
      stage: {
        height,
        scale,
        width,
      }
    } = this.state
    const position = target.getStage().getPointerPosition()
    return {
      x: position.x / width / scale.x,
      y: position.y / height / scale.y,
    }
  }

  private updateDimensions = () => {
    const container = this.containerRef.current
    if (!container) {
      return
    }

    const { stage } = this.state
    const scale = container.clientWidth / this.initWidth

    stage.width = container.clientWidth
    stage.height = container.clientHeight
    stage.scale = { x: scale, y: scale }

    this.initWidth = container.clientWidth
    this.initHeight = container.clientHeight

    this.setState({ stage })
  }
}

export default MarkupTool
