import gql from 'graphql-tag'

export const GET_PDF_DOCUMENT = gql`
  query getPdfDocument($id: String) {
    getPdfDocument: getDocuments(where: { _id: $id }) {
      _id
      url
      thumbnailUrl
      extractMarkup {
        id
        label {
          text
          value
        }
        value
        page
        top
        left
        width
        height
      }
      extractRawUrl
      extractWordsUrl
      fileName
    }
  }
`

export const GET_TEXTRACT_DOCUMENT = gql`
  query getTextractDocument($id: String) {
    getTextractDocument: getDocument(where: { _id: $id }) {
      _id
      url
      thumbnailUrl
      extractMarkup {
        id
        label {
          text
          value
        }
        value
        page
        top
        left
        width
        height
      }
      extractRawUrl
      extractWordsUrl
      fileName
    }
  }
`

export const GET_TRANSACTION_DOCUMENTS = gql`
  query getTransactionDocuments($transactionId: String) {
    getDocuments(where: { transaction: $transactionId, isActive: true }) {
      _id
      createdAt
      extractMarkup {
        id
        label {
          text
          value
        }
        value
        page
        top
        left
        width
        height
      }
      extractRawUrl
      extractWordsUrl
      fileName
      fileType
      isActive
      isLocked
      isViewed
      owner {
        _id
        firstName
        lastName
      }
      rotation
      thumbnailUrl
      transaction {
        _id
        transactionId
        owner {
          _id
        }
      }
      url
    }
  }
`

export const GET_DOCUMENTS_WITH_IDS = gql`
  query getDocumentsWithIds($ids: [String]) {
    getDocumentsWithIds(ids: $ids) {
      _id
      createdAt
      extractMarkup {
        id
        label {
          text
          value
        }
        value
        page
        top
        left
        width
        height
      }
      extractRawUrl
      extractWordsUrl
      fileName
      fileType
      isActive
      isLocked
      isViewed
      owner {
        _id
        firstName
        lastName
      }
      rotation
      thumbnailUrl
      transaction {
        _id
        transactionId
        owner {
          _id
        }
      }
      url
    }
  }
`
