import client from 'queries/apollo'

import {
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_V2,
  CREATE_TRANSACTION_DOCUMENTS_FROM,
  UPDATE_TRANSACTION_V2,
  UPDATE_TRANSACTION_COMMISSION
} from 'queries/graphql/Transactions/Mutation'

import { getTransactionBoards } from 'app/Transactions/Dashboard/TransactionQueries'

import {
  CreateTransactionParamsType,
  UpdateTransactionParamsType
} from './Types'

export const createTransaction = async (
  params: CreateTransactionParamsType
) => {
  const {
    addBoardId,
    contactId,
    edit,
    index,
    leadId,
    show,
    transaction: {
      city,
      isActive,
      mlsId,
      office,
      price,
      state,
      streetName,
      streetNumber,
      subType,
      transactionRole,
      type,
      zipCode
    },
    user
  } = params

  const propertyData = {
    address: {
      city,
      state,
      streetName: streetName.trim(),
      streetNumber: streetNumber.trim(),
      zipCode
    },
    mlsId,
    price: parseFloat(price),
    subType,
    type
  }

  let officeTransactionBoard: any
  try {
    const officeBoards = await getTransactionBoards({ officeId: office })
    const newBoard = officeBoards.find(
      (item: any) => item.name.toLowerCase() === 'new'
    )
    officeTransactionBoard = newBoard._id
  } catch {
    officeTransactionBoard = addBoardId || undefined
  }

  let variables: any = {
    isActive: isActive === undefined ? true : isActive,
    office,
    officeTransactionBoard,
    propertyData,
    teamTransactionBoard: user.role === 'TEAM_LEADER' ? addBoardId : undefined,
    tenantTransactionBoard: user.role === 'ADMIN' ? addBoardId : undefined,
    transactionBoard: user.role === 'AGENT' ? addBoardId : undefined,
    transactionRole
  }

  if (show) {
    variables = {
      ...variables,
      officeTransactionOrder:
        user.role === 'MANAGER' ? parseInt(index) : undefined,
      teamTransactionOrder:
        user.role === 'TEAM_LEADER' ? parseInt(index) : undefined,
      tenantTransactionOrder:
        user.role === 'ADMIN' ? parseInt(index) : undefined,
      transactionOrder: user.role === 'AGENT' ? parseInt(index) : undefined
    }
  } else if (!edit) {
    variables = {
      ...variables,
      contactId,
      leadId
    }
  }

  const response = await client.mutate({
    mutation: CREATE_TRANSACTION,
    variables
  })

  return response.data.createTransaction
}

export const createTransactionV2 = async (
  params: CreateTransactionParamsType
) => {
  const {
    contactId,
    edit,
    index,
    leadId,
    show,
    transaction: {
      bathrooms,
      bedrooms,
      city,
      description,
      feeObj,
      isActive,
      mlsId,
      office,
      price,
      squareFt,
      state,
      streetName,
      streetNumber,
      subType,
      transactionRole,
      type,
      zipCode
    },
    user
  } = params

  const propertyInput = {
    address: {
      city,
      state,
      streetName: streetName.trim(),
      streetNumber: streetNumber.trim(),
      zipCode
    },
    bathrooms,
    bedrooms,
    description,
    feeObj,
    mlsId,
    price: parseFloat(price),
    squareFt,
    subType,
    type
  }

  const input: any = {
    propertyInput,
    transactionRole,
    office,
    isActive: isActive === undefined ? true : isActive
  }

  if (show) {
    const order = parseInt(index)
    switch (user.role) {
      case 'ADMIN':
        input.tenantTransactionOrder = order
        break

      case 'MANAGER':
        input.officeTransactionOrder = order
        break

      case 'TEAM_LEADER':
        input.teamTransactionOrder = order
        break

      case 'AGENT':
        input.transactionOrder = order
        break

      default:
        break
    }
  } else if (!edit) {
    input.contact = contactId
    input.leadId = leadId
  }

  const response = await client.mutate({
    mutation: CREATE_TRANSACTION_V2,
    variables: { input }
  })

  return response.data.createTransaction
}

export const updateTransaction = async (
  id: string,
  params: UpdateTransactionParamsType
) => {
  const {
    city,
    isActive,
    mlsId,
    price,
    state,
    streetName,
    streetNumber,
    subType,
    transactionRole,
    type,
    zipCode
  } = params

  const input = {
    isActive,
    propertyId: {
      address: {
        city,
        state,
        streetName,
        streetNumber,
        zipCode
      },
      mlsId,
      price: price ? parseFloat(price) : undefined,
      subType,
      type
    },
    transactionRole
  }

  const response = await client.mutate({
    mutation: UPDATE_TRANSACTION_V2,
    variables: { id, input }
  })

  return response.data.updateTransaction
}

export const createTransactionDocumentsFrom = async (
  docIds: string[],
  transactionId: string
) => {
  const response = await client.mutate({
    mutation: CREATE_TRANSACTION_DOCUMENTS_FROM,
    variables: { docIds, transactionId }
  })

  return response.data.createTransactionDocumentsFrom
}

export const updateExpectedCommission = async (
  expectedCommission: number,
  commissionId: string) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_TRANSACTION_COMMISSION,
      variables: {
        commissionId: commissionId,
        expectedCommission,
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}
