import Colors from 'design/Colors'
import { Tab } from 'semantic-ui-react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

const InviteSection = styled.div`
  background: ${Colors.Grey110};
  width: 33%;
`

const StyledTab = styled(Tab)`
  .ui.secondary.pointing.menu .active.item {
    color: ${Colors.DarkBlue200};
    border-bottom-color: ${Colors.DarkBlue200};
  }

  .ui.secondary.pointing.menu .item {
    color: ${Colors.Black500};
  }

  .ui.secondary.pointing.menu {
    border-bottom: none;
    padding: 15px 0px 0px 25px;
  }

  .ui.secondary.pointing.menu a:nth-child(2){
    margin-left: 30px;
  }
`

export {
  Container,
  InviteSection,
  StyledTab
}
