import {
  WorkflowCategories,
  WorkflowLabelManage,
  WorkflowManagerState,
  WorkflowTask
} from './Types'

export const addNewLabel = (
  state: WorkflowManagerState,
  newData: WorkflowLabelManage
): WorkflowLabelManage[] => {
  const { labelManageData } = state
  const feeData = labelManageData.slice()
  const length = labelManageData.length
  feeData.splice(length, 0, newData)
  return [...feeData]
}

export const addNewWorkflow = (
  state: WorkflowManagerState,
  category: string,
  newData: WorkflowCategories
): WorkflowCategories[] => {
  const { categoriesData } = state
  let feeData = categoriesData.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === category) {
      const newList = list.workflows.slice()
      newList.push(newData)
      return {
        ...list,
        workflows: newList
      }
    } else {
      return list
    }
  })

  return [...feeData]
}

export const deleteWorkflow = (
  state: WorkflowManagerState,
  category: string,
  id: string
): WorkflowCategories[] => {
  const { categoriesData } = state
  let feeData = categoriesData.slice()
  feeData = feeData.map((list: any) => {
    if (list.name === category) {
      const newList = list.workflows.slice()
      const listIndex = newList.findIndex((laneItem: any) => {
        return laneItem._id === id
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        workflows: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const addNewTask = (
  state: WorkflowManagerState,
  newData: WorkflowTask
): WorkflowTask[] => {
  const { taskData } = state
  const feeData = taskData.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const deleteTask = (
  state: WorkflowManagerState,
  id: string
): WorkflowTask[] => {
  const { taskData } = state
  const feeData = taskData.slice()
  const newList = feeData.slice()
  const listIndex = newList.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  newList.splice(listIndex, 1)
  return [...newList]
}

export const updateTask = (
  state: WorkflowManagerState,
  newData: WorkflowTask
): WorkflowTask[] => {
  const { taskData } = state
  const feeData = taskData.slice()
  const taskIndex = feeData.findIndex((items: any) => {
    return items._id === newData[`_id`]
  })
  feeData[taskIndex] = newData
  return [...feeData]
}
