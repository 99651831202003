// Import Packages
import { Checkbox, Form, Search } from 'semantic-ui-react'
import { css } from 'styled-components'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { styledToggle, x } from 'design/Styled'

interface Props {
  background?: string
  isToggled?: boolean
}

const children = css`
  & > :first-child {
    margin-left: 0;
  }
  & > :last-child {
    margin-right: 0;
  }
`

const col = css`
  margin-right: 10px;
  ${children};
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;

  span {
    margin-left: 0.8em;
  }
`

const Container = styled.div`
  display: flex;
  .react-datepicker__time-container {
    width: 150px;
  }
  .react-datepicker__time-box {
    width: 100% !important;
  }
  .react-datepicker__time-list-item{
    padding: 0 !important;
  }
`

const AddLeftPanel = styled.div`
  width: 35%;
  height: auto;
  background: ${Colors.Grey110};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ui.small.image {
    width: 250px;
  }
`

const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 55%;

  span {
    color: ${Colors.Black500};
    font-weight: 700;
    font-size: 12px
  }
`

const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  .rdt input {
    height: 33px;
  }

  &.ui.form .field .ui.input {
    width: 50%;
  }

  &.ui.form .field .ui.input>button {
    width: 42px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }

  .ui.button.save-button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    font-weight: bold;
  }

  span {
    display: flex;
  }

  .duedate {
    width: 405px;
  }

  &.ui.popup {
    padding: 0;
  }
`

const FormField = styled(Form.Field)`
  ${col} flex-direction: column;
  align-items: flex-start;

  label {
    padding-bottom: 0.8em;
  }

  .ui.input {
    width: 75%;
    & > input {
      border-radius: 0;
    }
  }

  .ui.selection.dropdown {
    border-radius: 0;
  }

  .ui.button {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0 !important;
    border-left: none;
    background: ${Colors.transparent};
    &:hover {
      background: ${Colors.transparent};
    }
  }
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: center;
  margin-right: 5px;
`

const SelectTask = styled.div`
  display: flex;
  margin-bottom: 15px;

  .selectTask {
    width: 35px;
    height: 35px;
    background-color: red;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    margin-right: 20px;
  }

  .task {
    cursor: pointer;
    margin-right: 20px;
    margin-top: 10px;
    color: ${Colors.Black500};
  }

  .fa-phone {
    width: 15px;
    height: 15px;
  }

  .fa-envelope {
    width: 15px;
    height: 15px;
  }

  .fa-comment {
    width: 15px;
    height: 15px;
  }

  .fa-handshake {
    width: 20px;
    height: 15px;
  }

  .fa-ticket {
    width: 15px;
    height: 15px;
  }
`

const StyledSearch = styled(Search)`
  &.ui.search {
    width: 80%;
  }

  .ui.icon.input>i.icon {
    display: none;
  }

  .ui.input {
    height: 30px;
    margin-bottom: 10px;
}
`

const StyledLabel = styled.label`
  font-weight: 700;
  margin: 0 0 .28571429rem 0;
  font-size: 11px;
  color: darkslategrey;
`

const Toggle = styled(Checkbox)`
  ${styledToggle};
  margin-left: 8px;
  margin-right: 8px;
`

const ToggleItem = styled.div`
  color: ${(props: Props) => props.isToggled ? Colors.Grey50 : Colors.Black550};
`

const SharedWithTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  color: ${Colors.Text};
`

const Header = styled.div`
  ${x};
  ${children};
  font-size: 12px;
  font-weight: bold;
  padding: 12px;
  padding-left: 10px;
  color: ${Colors.Text};
  background-color: ${Colors.EmphasisContainer};
  margin-bottom: 8px;
`

export {
  AddForm,
  AddLeftPanel,
  Container,
  FormField,
  ProfileImage,
  SelectTask,
  StyledLabel,
  StyledForm,
  StyledSearch,
  Toggle,
  Header,
  ToggleItem,
  SharedWithTitle
}
