import { Dropdown, Pagination } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { children, x } from 'design/Styled'

export const Container = styled.footer`
  ${x};
  justify-content: space-between;
  margin: 1.8em 0 1em;
  padding-right: 90px;
  font-size: 12px;
`

export const Col = styled.div`
  ${x};
  ${children};
  width: auto;
`

export const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    min-width: 4em;
    height: 30px;
  }
`

export const PerPage = styled.div`
  white-space: nowrap;
  color: ${Colors.DarkBlue210};
`

export const TotalPage = styled.div`
  white-space: nowrap;
  color: ${Colors.DarkBlue210};
`

export const StyledPagination = styled(Pagination)`
  .ui.menu {
    box-shadow: none;
  }
`
