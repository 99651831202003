// Import Packages
import * as React from 'react'
import styled from 'styled-components'

// Import Components
import Modal from 'shared/Modal'

import {
  Image
} from './Styled'

interface Props {
  closeModal: () => void
  title: string
}

class PaymentModal extends React.Component<Props> {
  public state = {
    animate: true,
  }
  public componentDidMount() {
    setTimeout(() => {
      this.closeModal()
    }, 3000)
  }

  public render() {
    const { title } = this.props
    const { animate } = this.state
    return (
      <Modal content={
        <ModalWrapper>
          <Image background={title} />
        </ModalWrapper>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={360} />
    )
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

}

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default PaymentModal
