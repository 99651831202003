// Import Packages
import { Accordion } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background?: string
}

const Wrapper = styled.div`
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${Colors.Black500};
  background: ${(props: Props) => props.background || props.background};
  cursor: pointer;
`

const StyledAccordion = styled(Accordion)`
  &.ui.accordion, .ui.accordion .accordion {
    padding-left: 35px !important;
    max-width: 100% !important;
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }

  &.ui.accordion .title:not(.ui) {
    font-weight: 500 !important;
  }
`

const Title = styled.div`
  flex-grow: 1;
  color: ${props => props.color || props.color};
`

const WrapperSubMenu = styled.div`
  padding-left: 25px;
  padding-top: 10px;
  font-weight: 400;
  padding-bottom: 10px;
  color: ${Colors.Black500};
  background: ${(props: Props) => props.background || props.background};
  cursor: pointer;
`

export {
  StyledAccordion,
  Title,
  WrapperSubMenu,
  Wrapper
}

