// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
  height: 300px;
`

const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
    font-size: 12px;
    font-weight: 500;
  }

  &.ui.form .field>.selection.dropdown {
    width: 200px;
    font-size: 12px;
  }

  .ui.button {
    font-size: 12px;
    border-radius: 0px;
    width: 90px !important;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    font-weight: bold;
  }
`

const LeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
  height: auto;
  background: ${Colors.Grey110};

  .ui.small.image {
    width: 150px;
  }
`

const AddForm = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 10px;
  color: ${Colors.Black500};
`

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  color: ${Colors.Black500};
  width: 200px;
  text-align: center;
`

const ProgressContainer = styled.div`
  height: 250px;
  width: 250px;
  position: relative;
`

const PercentContainer = styled.div`
  position: absolute;
  top: 55px;
  left: 100px;

  .ui.small.image {
    width: 55px;
  }
`

const Percent = styled.div`
  font-size: 10px;
  margin-top: 8px;
  margin-left: -8px;
  font-weight: 600;
`

const StyledText = styled.text`
  text-align: center;
  font-size: 15px;
  font-weight: 550;
  margin-bottom: 10px;
`

export {
  LeftPanel,
  AddForm,
  Container,
  Percent,
  PercentContainer,
  ProgressContainer,
  StyledForm,
  StyledText,
  Text,
  Title,
}
