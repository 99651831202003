// Import Packages
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import styled from 'styled-components'

// Import Colors
import { scrollbars } from 'design/Styled'

const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  ${scrollbars};

`
const GraphSectionContainer = styled(Segment)`
  display: flex;
  background-color: transparent;
  border: none;

  &.ui.segment {
    background: none;
    border: 0;
    box-shadow: none;
    height: 180px;
    padding-left: 0;
  }

  .ui.segment:first-child {
    padding-left: 0;
  }
`

interface ImageProps {
  backgroundImage: string
}

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 22px;
  align-self: center;
  margin: 0px;
  display: inline-block;
  position: absolute;
  top: -12px;
  right: 0px;
  background-size: 22px;
  @media ( min-width: 991px ) and (max-width: 1200px) {
    width: 20px;
    height: 20px;
    background-size: 20px;
  }
  @media ( min-width: 1200px ) {
    width: 22px;
    height: 22px;
    background-size: 22px;
  }
`

const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown {
    width: 100%;
  }

  &.ui.top.right.pointing.dropdown>.menu {
    margin: 1.3em 0 0;
    right: -12px;
    z-index: 99999;
  }
`

export {
  Container,
  GraphSectionContainer,
  ImageWrapper,
  StyledDropdown
}
