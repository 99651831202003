import * as React from 'react'
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'

import Templates from 'templates/Listing'

import { getTransaction } from './Queries'
import { Container } from './Styled'
import { ParamsType, TransactionType } from './Types'

type Props = {} & RouteComponentProps<ParamsType>

interface State {
  transaction: TransactionType
}

class Listing extends React.Component<Props, State> {
  public state = {
    transaction: {} as TransactionType
  }

  public componentDidMount = () => {
    const {
      templateId,
      transactionId,
    } = this.props.match.params

    if (!templateId || !transactionId) {
      window.location.href = `https://realtypass.com`
      return
    }

    this.getTemplateData()
  }

  public componentDidUpdate = (prev:Props) => {
    const { transactionId } = this.props.match.params

    if (prev.match.params.transactionId !== transactionId) {
      this.getTemplateData()
    }
  }

  public render = () => {
    const { templateId } = this.props.match.params
    const { transaction } = this.state

    const ActiveTemplate = Templates[templateId] ? Templates[templateId].component : 'div'

    return (
      <Container>
        {transaction._id && (
          <ActiveTemplate transaction={transaction} />
        )}
      </Container>
    )
  }

  private getTemplateData = async () => {
    const { transactionId } = this.props.match.params

    const transaction: TransactionType = await getTransaction(transactionId) || {} as TransactionType
    this.setState({ transaction })
  }
}

export default withRouter(Listing)
