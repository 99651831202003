// WIP: part of an iterative update to the entire Transaction Cache
// upates to the Transaction Cache should interact with these settings
import { ActionCreator } from 'redux'
import {
  DispatchEnum,
  MergeTransactionAction,
  MergeTransactionPropertyAction,
} from './Types'

// temporary source
import {
  PropertyType,
  TransactionType,
} from 'app/Transactions/Details/Types'

// temporary: for compatability with earlier version
export const mergeTransaction: ActionCreator<MergeTransactionAction> = (payload: Partial<TransactionType>) => ({
  payload,
  type: DispatchEnum.MergeTransaction
})

export const mergeTransactionProperty: ActionCreator<MergeTransactionPropertyAction> = (transactionId: string, data: Partial<PropertyType>) => ({
  payload: {
    transactionId,
    data
  },
  type: DispatchEnum.MergeTransactionProperty
})
