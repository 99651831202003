// Import Components
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import client from 'queries/apollo'
import { GET_TASK_BOARDS } from 'queries/graphql/TaskBoard/Queries'
import {
  EXPORT_TASKS,
  GET_TASK,
  GET_TASK_ACTIVITY,
  GET_TASK_DETAILS,
  GET_TASK_NOTE
} from 'queries/graphql/Tasks/Queries'

import { FiltersData, TaskPassFilter, UserType } from 'store/Tasks/Types'

export const getTaskBoards = async (
  searchData?: FiltersData,
  office?: string,
  userPassFilter?: TaskPassFilter,
  id?: string,
  singleSkip?: number
) => {
  const taskBoards = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_BOARDS,
    variables: {
      id,
      office: office !== 'Choose' ? office : undefined,
      orderField: 'boardOrder',
      searchData,
      singleSkip,
      sortOrder: 1,
      userPassFilter
    }
  })

  return taskBoards.data.getTaskBoards
}

export const getTaskBoardsSearch = async (searchData: FiltersData, office?: string) => {
  const taskBoards = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_BOARDS,
    variables: {
      office: office !== 'Choose' ? office : undefined,
      orderField: 'boardOrder',
      searchData,
      sortOrder: 1
    }
  })

  return taskBoards.data.getTaskBoards
}

export const getTasks = async (searchData?: FiltersData, office?: string, userPassFilter?: TaskPassFilter) => {
  const tasks = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK,
    variables: {
      office: office !== 'Choose' ? office : undefined,
      searchData,
      userPassFilter
    }
  })
  return tasks.data.getTasks
}

export const getTasksSearch = async (searchData: FiltersData, office?: string) => {
  const tasks = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK,
    variables: { searchData, office: office !== 'Choose' ? office : undefined }
  })

  return tasks.data.getTasks
}

export const getMyTaskBoards = async (
  userId: string,
  userPassFilter?: TaskPassFilter,
  id?: string,
  singleSkip?: number
) => {
  const taskBoards = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_BOARDS,
    variables: {
      id,
      orderField: 'boardOrder',
      singleSkip,
      sortOrder: 1,
      user: userId,
      userPassFilter
    }
  })
  return taskBoards.data.getTaskBoards
}

export const getMyTaskBoardsSearch = async (userId: string, searchData: FiltersData) => {
  const taskBoards = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_BOARDS,
    variables: {
      orderField: 'boardOrder',
      searchData,
      sortOrder: 1,
      user: userId
    }
  })

  return taskBoards.data.getTaskBoards
}

export const getMyTasks = async (userId: string, userPassFilter?: TaskPassFilter) => {
  const tasks = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK,
    variables: { createdBy: userId, userPassFilter }
  })
  return tasks.data.getTasks
}

export const getMyTasksSearch = async (userId: string, searchData: FiltersData) => {
  const tasks = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK,
    variables: { user: userId, searchData }
  })

  return tasks.data.getTasks
}

export const getTaskDetailsData = async (taskId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_DETAILS,
    variables: { taskId }
  })
  return response.data.getTasks[0]
}

export const getTaskActivities = async (taskId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_ACTIVITY,
    variables: { taskId }
  })

  return response.data.getActivities
}

export const getTaskNotes = async (taskId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_NOTE,
    variables: { taskId }
  })
  return response.data.getNotes
}

export const getOptions = async () => {
  const offices: any = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_OFFICES
  })
  const options = offices.data.getOffices.reduce((accumulator: any, currentValue: any) => {
    accumulator.push({
      key: currentValue._id,
      text: currentValue.branchName,
      value: `${currentValue._id}, ${currentValue.branchName}`
    })
    return accumulator
  }, [])
  options.splice(0, 0, {
    key: '0',
    text: 'Select Office',
    value: 'Choose'
  })
  if (options.length > 2) {
    options.push({
      key: 'All',
      text: 'All',
      value: 'All'
    })
  }
  return options
}

export const exportTasks = async (groupid: string, filterOptions: boolean, user: UserType) => {
  const url = await client.query({
    query: EXPORT_TASKS,
    variables: {
      officeTaskBoard: filterOptions !== false && user.role === 'MANAGER' ? groupid : undefined,
      taskBoard: filterOptions === false || user.role === 'AGENT' ? groupid : undefined,
      teamTaskBoard: filterOptions !== false && user.role === 'TEAM_LEADER' ? groupid : undefined,
      tenantTaskBoard: filterOptions !== false && user.role === 'ADMIN' ? groupid : undefined
    }
  })
  if (url && url.data) {
    setTimeout(() => {
      const response = {
        file: url.data.exportTasks
      }
      return (window.location.href = response.file)
    }, 100)
  }
}
