const Colors = {
  light: {
    Background: '#f2f7f8',
    Bold: '#5671a7',
    Border: '#fff',
    Emphasis: '#bdbdbd',
    Highlight: '#129518',
    HighlightHover: '#137317',
    Shadow: 'rgba(0,0,0,0.2)',
    Text: '#2f3033',
  },
  dark: {
    Background: '#272727',
    Bold: '#5671a7',
    Border: '#353535',
    Emphasis: '#525151',
    Highlight: '#6ba06d',
    HighlightHover: '#59905b',
    Shadow: 'rgba(0,0,0,0.2)',
    Text: '#c1c1c1',
  }
}

export default Colors
