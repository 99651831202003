import Colors from 'design/Colors'
import logo from 'design/images/logo.png'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

const Logo = styled.div`
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 62px;
  height: 50px;
  margin-top: -1px;
  margin-right: 8px;
  cursor: pointer;
`

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`

const ActiveRoute = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${Colors.Black500};
  margin-left: 6px;
`

const Dropdown = styled.div`
  display: flex;
  cursor: pointer;
  text-transform: capitalize;

  .fa-chevron-down {
    margin-left: 15px;
    margin-top: 1px;
    color: ${Colors.Black500};
  }
`

const Menus = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.Black500};
  margin-bottom: 6px;
`

const Route = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Black500};
  margin-bottom: 10px;
  cursor: pointer;

  span:nth-child(2) {
    font-size: 14px;
    margin-left: 8px;
    text-transform: capitalize;
  }

  .svg-inline--fa {
    width: 16px;
    height: 16px;
  }

  .fa-handshake, .fa-users, .fa-cog {
    margin-right: 1px;
  }

`

const StyledGrid = styled(Grid)`
  &.ui.grid>.column:not(.row) {
    padding-right: 50px;
    min-height: 198px;
  }

  &.ui.grid {
    position: absolute;
    background: white;
    z-index: 9999;
    top: 76px;
    left: 126px;
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
  }
`

const Container = styled.div`
  position: relative;
`

export {
  ActiveRoute,
  Container,
  Dropdown,
  Menus,
  Logo,
  MenuContainer,
  Route,
  StyledGrid,
}
