import { ActionCreator } from 'redux'
import Actions from 'store/Contacts/ActionsTypes'
import {
  AddContactTransactions,
  ContactActivities,
  ContactAddNewBoard,
  ContactAddNewCard,
  ContactAddNewNotes,
  ContactAddShareWith,
  ContactAllContactStatus,
  ContactDeleteBoard,
  ContactDeleteCard,
  ContactDeleteListRecord,
  ContactDeleteNotes,
  ContactDeleteShareWith,
  ContactDetailDescription,
  ContactDetails,
  ContactEditDetails,
  ContactEditNotes,
  ContactEditRecord,
  ContactFilter,
  ContactGetBoardData,
  ContactGetListData,
  ContactGetNotesData,
  ContactGetShareWith,
  ContactImportData,
  ContactItemsDetails,
  ContactListDetails,
  ContactNotes,
  ContactPassFilter,
  ContactResetCardData,
  ContactRestoreCard,
  ContactSearchData,
  ContactSerachLoader,
  ContactSetNewBoardName,
  ContactSetNewDescription,
  ContactSetNewIcon,
  ContactSortBoardData,
  Filter,
  GetContactTransactions,
  UserPassContactFilter
} from './Types'

export const addNewBoard: ActionCreator<ContactAddNewBoard> = (
  index: number,
  newData: ContactDetails
) => ({
  payload: {
    index,
    newData
  },
  type: Actions.contactAddNewBoard
})

export const getBoardData: ActionCreator<ContactGetBoardData> = (
  data: ContactDetails[]
) => ({
  payload: {
    data
  },
  type: Actions.contactGetBoardData
})

export const addNewCard: ActionCreator<ContactAddNewCard> = (
  id: string,
  index: string,
  newData: ContactItemsDetails
) => {
  return {
    payload: {
      id,
      index,
      newData
    },
    type: Actions.contactAddNewCard
  }
}

export const setNewIcon: ActionCreator<ContactSetNewIcon> = (
  id: string,
  icon: string
) => ({
  payload: {
    icon,
    id
  },
  type: Actions.contactSetNewIcon
})

export const setNewDescription: ActionCreator<ContactSetNewDescription> = (
  id: string,
  description: string
) => ({
  payload: {
    description,
    id
  },
  type: Actions.contactSetNewDescription
})

export const setNewBoardName: ActionCreator<ContactSetNewBoardName> = (
  id: string,
  boardName: string
) => ({
  payload: {
    boardName,
    id
  },
  type: Actions.contactSetNewBoardName
})

export const deleteBoard: ActionCreator<ContactDeleteBoard> = (
  boardId: string
) => ({
  payload: {
    boardId
  },
  type: Actions.contactDeleteBoard
})

export const deleteCard: ActionCreator<ContactDeleteCard> = (
  boardId: string,
  cardId: string
) => ({
  payload: {
    boardId,
    cardId
  },
  type: Actions.contactDeleteCard
})

export const restoreCard: ActionCreator<ContactRestoreCard> = () => ({
  type: Actions.contactRestoreCard
})

export const resetCardData: ActionCreator<ContactResetCardData> = () => ({
  type: Actions.contactResetCardData
})

export const getListData: ActionCreator<ContactGetListData> = (
  data: ContactListDetails[]
) => ({
  payload: {
    data
  },
  type: Actions.contactGetListData
})

export const addNewNotes: ActionCreator<ContactAddNewNotes> = (
  newData: ContactNotes[]
) => ({
  payload: {
    newData
  },
  type: Actions.contactAddNewNotes
})

export const getNotesData: ActionCreator<ContactGetNotesData> = (
  data: ContactNotes[]
) => ({
  payload: {
    data
  },
  type: Actions.contactGetNotesData
})

export const editNotes: ActionCreator<ContactEditNotes> = (
  id: string,
  newData: ContactNotes[]
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.contactEditNotes
})

export const deleteNotes: ActionCreator<ContactDeleteNotes> = (id: string) => ({
  payload: {
    id
  },
  type: Actions.contactDeleteNotes
})

export const sortData: ActionCreator<ContactSortBoardData> = (
  boardId: string,
  sortOrder: number
) => ({
  payload: {
    boardId,
    sortOrder
  },
  type: Actions.contactSortBoardData
})

export const deleteListRecord: ActionCreator<ContactDeleteListRecord> = (
  cardId: string
) => ({
  payload: {
    cardId
  },
  type: Actions.contactDeleteListRecord
})

export const editRecord: ActionCreator<ContactEditRecord> = (newData: any) => ({
  payload: {
    newData
  },
  type: Actions.contactEditRecord
})

export const addShareWithUser: ActionCreator<ContactAddShareWith> = (
  shareWithObj: any
) => ({
  payload: {
    shareWithObj
  },
  type: Actions.contactAddShareWith
})

export const getShareWithUser: ActionCreator<ContactGetShareWith> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.contactGetShareWith
})

export const deleteContactShareWithUser: ActionCreator<
  ContactDeleteShareWith
> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.contactDeleteShareWith
})

export const getContactDetail: ActionCreator<ContactDetailDescription> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.contactDetails
})

export const editContactDetail: ActionCreator<ContactEditDetails> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.contactEditDetails
})

export const importData: ActionCreator<ContactImportData> = (
  boardId: string,
  importedData: any
) => ({
  payload: {
    boardId,
    importedData
  },
  type: Actions.contactImportData
})

export const getActivities: ActionCreator<ContactActivities> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.contactActivities
})

export const filter: ActionCreator<ContactFilter> = (data: Filter) => ({
  payload: {
    data
  },
  type: Actions.contactFilter
})

export const searchLoader: ActionCreator<ContactSerachLoader> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.contactSearchLoader
})

export const toggleStatus: ActionCreator<ContactAllContactStatus> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.contactToggleStatus
})

export const searchDataFilter: ActionCreator<ContactSearchData> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.contactSearchFilter
})

export const getContactTransaction: ActionCreator<GetContactTransactions> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.getContactTransaction
})

export const addContactTransactions: ActionCreator<AddContactTransactions> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.addContactTransaction
})

export const showUserPassContactFilterData: ActionCreator<
  UserPassContactFilter
> = (filters: ContactPassFilter) => ({
  payload: {
    filters
  },
  type: Actions.userPassContactFilter
})
