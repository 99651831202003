import { generateID } from 'utils'
import {
  Commission,
  OtherFeeNewData,
  ProgressiveFeeNewData,
  TransactionFeeState
} from './Types'

// Reducer Util Functions
export const changeOtherFeeItem = (
  state: TransactionFeeState,
  id: string,
  newData: OtherFeeNewData
): TransactionFeeState['otherFee'] => {
  const { otherFee } = state
  const feeData = otherFee.data.map((feeItem, index) => {
    if (feeItem.id === id) {
      return {
        ...feeItem,
        ...newData
      }
    } else {
      return feeItem
    }
  })
  return {
    ...otherFee,
    data: [...feeData]
  }
}

export const addOtherFeeItem = (
  state: TransactionFeeState
): TransactionFeeState['otherFee'] => {
  const { otherFee } = state
  return {
    ...otherFee,
    data: [
      ...otherFee.data,
      {
        id: generateID(),
        name: '',
        type: 'FIXED',
        value: 0
      }
    ]
  }
}

export const deleteOtherFeeItem = (
  state: TransactionFeeState,
  id: string
): TransactionFeeState['otherFee'] => {
  const { otherFee } = state
  const feeData = otherFee.data.filter(feeItem => {
    return feeItem.id !== id
  })
  return {
    ...otherFee,
    data: [...feeData]
  }
}

export const changeProgressiveFeeItem = (
  state: TransactionFeeState,
  id: string,
  newData: ProgressiveFeeNewData
): Commission => {
  const { commissionFee } = state
  if (commissionFee && commissionFee.amount && commissionFee.amount.range) {
    const feeData = commissionFee.amount.range.map((feeItem, index) => {
      if (feeItem.id === id) {
        return {
          ...feeItem,
          ...newData
        }
      } else {
        return feeItem
      }
    })
    return {
      ...commissionFee,
      amount: {
        ...commissionFee.amount,
        range: feeData
      }
    }
  } else {
    return commissionFee
  }
}

export const addProgressiveFeeItem = (
  state: TransactionFeeState
): Commission => {
  const { commissionFee } = state
  const rangeArray = commissionFee.amount.range
  if (rangeArray) {
    rangeArray.push({
      id: generateID(),
      max: 0,
      min: 0,
      percent: 0
    })
    return {
      ...commissionFee,
      amount: {
        ...commissionFee.amount,
        range: rangeArray
      }
    }
  } else {
    return commissionFee
  }
}

export const deleteProgressiveFeeItem = (
  state: TransactionFeeState,
  id: string
): Commission => {
  const { commissionFee } = state
  if (commissionFee && commissionFee.amount && commissionFee.amount.range) {
    const feeData = commissionFee.amount.range.filter(feeItem => {
      return feeItem.id !== id
    })
    return {
      ...commissionFee,
      amount: {
        ...commissionFee.amount,
        range: feeData
      }
    }
  } else {
    return commissionFee
  }
}
