import gql from 'graphql-tag'

export const CREATE_WORKFLOW_TASK = gql`
  mutation createWorkflowTask(
    $type: String
    $name: String
    $description: String
    $dueDate: Int
    $workflowId: String
  ) {
    createWorkflowTask(
      input: {
        type: $type
        name: $name
        description: $description
        dueDate: $dueDate
        workflow: $workflowId
      }
    ) {
      _id
      type
      name
      description
      dueDate
      isActive
    }
  }
`

export const DELETE_WORKFLOW_TASK = gql`
  mutation deleteWorkflowTask($workflowTaskId: String) {
    deleteWorkflowTask(_id: $workflowTaskId)
  }
`

export const UPDATE_WORKFLOW_TASK = gql`
  mutation updateWorkflowTask(
    $type: String
    $name: String
    $description: String
    $dueDate: Int
    $workflowId: String
    $workflowTaskId: String
  ) {
    updateWorkflowTask(
      where: { _id: $workflowTaskId }
      input: {
        type: $type
        name: $name
        description: $description
        dueDate: $dueDate
        workflow: $workflowId
      }
    ) {
      _id
      type
      name
      description
      dueDate
      isActive
    }
  }
`

export const REORDER_WORKFLOW_TASK = gql`
  mutation reorderingWorkflowTask($workflowTasks: [WorkflowTaskFilter]) {
    reorderingWorkflowTask(input: $workflowTasks) {
      _id
      order
    }
  }
`
