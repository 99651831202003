import * as React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import Toast, { serverToast } from 'shared/Toast/Toast'
import * as Steps from './Steps'

import {
  getConciergeSettings,
  getRecaptchaSiteKey,
  getTokenUser
} from './Queries'

import { Container } from './Styled'

import {
  ActionEnum,
  DocTemplateType,
  FormErrorType,
  FormType,
  OfficeType,
  OptionType,
  SettingsType,
  StepEnum,
  TransitEnum
} from './Types'

interface Props {
  onClose: () => void
  recaptchaSiteKey?: string
}

interface State {
  additional: boolean
  attempt: number
  duration: number
  form: FormType
  loading: boolean
  offices: OptionType[]
  recaptcha: any
  settings: SettingsType
  siteKey: string
  step: StepEnum
  token: string
  transit: TransitEnum
  transition: boolean
}

class ConciergeModal extends React.Component<Props, State> {
  public state = {
    additional: false,
    attempt: 0,
    duration: 500,
    form: {
      email: '',
      errors: {} as FormErrorType,
      listingId: '',
      office: '',
      transaction: ''
    } as FormType,
    loading: false,
    offices: [] as OptionType[],
    recaptcha: null,
    settings: {} as SettingsType,
    siteKey: '',
    step: StepEnum.None,
    token: '',
    transit: TransitEnum.Exit,
    transition: true
  }

  private recaptchaRef = React.createRef<ReCAPTCHA>()

  public componentDidMount = async () => {
    const { recaptchaSiteKey } = this.props
    let siteKey = ''
    if (!recaptchaSiteKey) {
      siteKey = await getRecaptchaSiteKey()
      if (!siteKey) {
        Toast({
          message: 'Sorry, Concierge is not available right now',
          type: 'warning'
        })
        return this.closeSelf()
      }
    } else {
      siteKey = recaptchaSiteKey
    }
    this.setState({ siteKey }, () => {
      this.handleStep()
    })
  }

  public render = () => {
    const {
      additional,
      form,
      loading,
      offices,
      settings,
      siteKey,
      step,
      token,
      transit,
      transition
    } = this.state

    const activeOffice =
      (form.office &&
        settings.enabledOffices &&
        settings.enabledOffices[form.office]) ||
      ({} as OfficeType)

    return (
      <Modal
        content={
          <React.Fragment>
            {loading && <LoadingIndicator />}
            <Container padding={1}>
              {siteKey && (
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  size="invisible"
                  sitekey={siteKey}
                  badge="bottomleft"
                  onChange={this.getRecaptcha}
                  onExpired={this.handleRecaptchaReset}
                  onErrored={this.handleRecaptchaError}
                />
              )}
              {step === StepEnum.Email && (
                <Steps.Email
                  form={form}
                  onAction={this.handleAction}
                  transit={transit}
                />
              )}
              {step === StepEnum.Office && (
                <Steps.Office
                  form={form}
                  offices={offices}
                  onAction={this.handleAction}
                  transit={transit}
                />
              )}
              {step === StepEnum.Idx && (
                <Steps.Idx
                  form={form}
                  office={activeOffice}
                  onAction={this.handleAction}
                  token={token}
                  transit={transit}
                />
              )}
              {step === StepEnum.Document && (
                <Steps.Document
                  isAdditional={additional}
                  office={activeOffice}
                  onAction={this.handleAction}
                  templates={
                    (activeOffice.mls &&
                      settings.enabledTemplates[activeOffice.mls._id]) ||
                    ([] as DocTemplateType[])
                  }
                  token={token}
                  transit={transit}
                />
              )}
              {step === StepEnum.MultiDocument && (
                <Steps.MultiDocument
                  onAction={this.handleAction}
                  token={token}
                  transaction={form.transaction}
                  transit={transit}
                />
              )}
              {step === StepEnum.Final && (
                <Steps.Final onAction={this.handleAction} transit={transit} />
              )}
            </Container>
          </React.Fragment>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        maximum={true}
      />
    )
  }

  private handleAction = (action: ActionEnum, data: any) => {
    switch (action) {
      case ActionEnum.Loading:
        this.setState({ loading: data })
        break

      case ActionEnum.Step:
        this.handleStep()
        break

      case ActionEnum.Input:
        this.handleInputChange(null, data)
        break

      case ActionEnum.Complete:
        this.closeSelf()
        break

      default:
        break
    }
  }

  private handleStep = async () => {
    const { recaptcha, step } = this.state

    if (!recaptcha && step !== StepEnum.None) {
      this.getRecaptcha(null)
      return
    }

    switch (step) {
      case StepEnum.None:
        this.handleTransition(StepEnum.Email)
        break

      case StepEnum.Email: {
        const { form, token } = this.state

        if (!token) {
          this.setState({ attempt: -1 }, () => {
            this.handleRecaptchaReset()
          })
          return
        }

        try {
          const settings: SettingsType = await getConciergeSettings(token)
          if (typeof settings.isAllowed === undefined) {
            throw new Error('An error occurred')
          }

          if (!settings.conciergePass || !settings.conciergePass.passStatus) {
            throw new Error(
              'Sorry, the Concierge Pass is currently disabled on your account. Please contact your Administrator to have it set up.'
            )
          }

          if (Object.keys(settings.enabledOffices).length < 1) {
            throw new Error(
              'Sorry, your account does not have any Concierge-enabled offices. Please contact your Administrator to have it set up.'
            )
          }

          const offices: OptionType[] = []
          for (const [key, item] of Object.entries(settings.enabledOffices)) {
            offices.push({
              key,
              text: item.branchName,
              value: key
            })
          }
          form.office = offices.length > 0 ? offices[0].value : form.office

          if (offices.length > 1) {
            this.handleTransition(StepEnum.Office)
          } else if (settings.enabledOffices[form.office].idxEnabled) {
            this.handleTransition(StepEnum.Idx)
          } else if (settings.enabledOffices[form.office].conciergeEnabled) {
            this.handleTransition(StepEnum.Document)
          }

          this.setState({
            form,
            offices,
            settings,
            token
          })
        } catch (error) {
          serverToast(error)
          this.setState({ token: '' })
        }
        break
      }

      case StepEnum.Office: {
        const {
          form: { office },
          settings: { enabledOffices }
        } = this.state
        if (enabledOffices[office].idxEnabled) {
          this.handleTransition(StepEnum.Idx)
        } else if (enabledOffices[office].conciergeEnabled) {
          this.handleTransition(StepEnum.Document)
        }
        break
      }

      case StepEnum.Idx:
        this.handleTransition(StepEnum.MultiDocument)
        break

      case StepEnum.Document:
      case StepEnum.MultiDocument:
        this.handleTransition(StepEnum.Final)
        break

      case StepEnum.Final: {
        const {
          offices,
          settings: { idxPasses }
        } = this.state
        this.setState({ additional: true }, () => {
          if (offices.length > 1) {
            this.handleTransition(StepEnum.Office)
          } else {
            this.handleTransition(
              idxPasses.length ? StepEnum.Idx : StepEnum.Document
            )
          }
        })
        break
      }
    }
  }

  private getRecaptcha = async (recaptcha: any) => {
    const {
      attempt,
      form: { email },
      token
    } = this.state

    if (!recaptcha) {
      if (!this.recaptchaRef || !this.recaptchaRef.current) {
        return
      }
      this.recaptchaRef.current.execute()
      this.setState({ loading: true })
      return
    }

    let updatedToken = token
    if (recaptcha && email) {
      try {
        updatedToken = await getTokenUser(email, recaptcha)
      } catch (e) {
        serverToast(e)
        updatedToken = ''
      }
    }

    this.setState(
      {
        loading: false,
        recaptcha,
        token: updatedToken
      },
      () => {
        if (updatedToken && attempt === 0) {
          this.handleStep()
        }
      }
    )
  }

  private handleRecaptchaError = () => {
    this.setState({ loading: false })
  }

  private handleRecaptchaReset = () => {
    const { attempt, step } = this.state

    if (!this.recaptchaRef || !this.recaptchaRef.current) {
      return
    }
    this.recaptchaRef.current.reset()

    this.setState(
      {
        attempt: attempt + 1,
        recaptcha: null
      },
      () => {
        if (this.state.attempt < 3) {
          this.getRecaptcha(null)
        } else if (step !== StepEnum.Email) {
          this.handleTransition(StepEnum.Email)
          this.setState({ attempt: 0 })
          Toast({
            message: 'Your session has expired. Please try again',
            type: 'warning'
          })
        }
      }
    )
  }

  private handleInputChange = (
    e: React.SyntheticEvent | null,
    { name, value }: any
  ) => {
    const { form } = this.state
    form.errors[name] = ''
    form[name] = value

    this.setState({ form })
  }

  private handleTransition = (step: StepEnum) => {
    this.setState({ transit: TransitEnum.Exit }, () => {
      window.setTimeout(() => {
        this.setState({
          step,
          transit: TransitEnum.Enter
        })
      }, 300)
    })
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({ transition: true })
      onClose()
    }, 300)
  }
}

export default ConciergeModal
