// Import Packages
import styled from 'styled-components'

// Import Colors
import { scrollbars } from 'design/Styled'

export const Container = styled.div`
  height: calc(88vh - 70px);
  overflow: auto;
  overflow-x: hidden;
  ${scrollbars};
`

