import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

export const Container = styled.div``

export const StyledDropdown = styled(Dropdown)`
  &.ui.search.dropdown > .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
`
