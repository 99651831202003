import { PassStatusEnum } from 'store/Pass/Types'

export enum ActionEnum {
  Complete = 'concierge:complete',
  Input = 'concierge:input',
  Loading = 'concierge:loading',
  Step = 'concierge:step'
}

export enum StepEnum {
  None = 0,
  Email = 25,
  Office = 30,
  Idx = 50,
  Document = 75,
  MultiDocument = 75.001,
  Final = 100
}

export enum TransitEnum {
  Enter,
  Exit
}

export interface OptionType {
  key: string
  text: string
  value: string
}

export interface OfficeType {
  _id: string
  branchName: string
  mls: MlsType
  idxEnabled?: boolean
  conciergeEnabled?: boolean
}

export interface ConciergePassType {
  _id: string
  passStatus: boolean
}

export interface IdxPassType {
  _id: string
  settings: {
    mls: MlsType
    status: PassStatusEnum
  }
}

export interface FormType {
  email: string
  errors: FormErrorType
  listingId: string
  transaction: string
  office: string
}

export interface FormErrorType {
  email?: string
  listingId?: string
  office?: string
}

export interface ConciergeParamsType {
  file: File
  office?: string
  token: string
}

export interface ConciergeIdxParamsType {
  office: string
  propertyInput: {
    address: {
      streetNumber: string
      streetName: string
      city: string
      state: string
      zipCode: string
    }
    bathrooms: number
    bedrooms: number
    description: string
    mlsId: string
    price: string
    squareFt: number
    subType: string
    type: string
  }
  transactionRole: string
}

export interface SettingsType {
  conciergeOffices: OfficeType[]
  conciergePass: ConciergePassType
  enabledIdx: IdxByMlsType
  enabledOffices: OfficeByIdType
  enabledTemplates: TemplatesByMlsType
  idxOffices: OfficeType[]
  idxPasses: IdxPassType[]
  isAllowed?: boolean
}

export interface OfficeByIdType {
  [officeId: string]: OfficeType
}

export interface TemplatesByMlsType {
  [mlsId: string]: DocTemplateType[]
}

export interface IdxByMlsType {
  [mlsId: string]: IdxPassType
}

export interface DocTemplateType {
  _id: string
  mls: MlsType
  name: string
  template: TemplateType
  textRact: boolean
}

export interface MlsType {
  _id: string
  shortName: string
}

export interface TemplateType {
  _id: string
  extractMarkup: ExtractMarkupType[]
  thumbnailUrl: string
}

export interface ExtractMarkupType {
  value: string
}
