import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'
import { Checkbox, Container, Form, Header, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledSegmentGroup = styled(Segment.Group)`
  &.ui.horizontal.segments {
    border: none;
    box-shadow: none;
    margin-top: 0 !important;
    overflow-y: auto;
    max-height: 88%;
  }
  ${scrollbars};
`

const SettingsSegment = styled(Segment)`
  &.segment{
    max-width: 70%;
    margin: 0px 30px;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-right: 1px solid #22242626 !important;
    height: 100%;
  }
`

const ExclusionSegment = styled(Segment)`
  &.segment{
    max-width: 30%;
    border-left: none !important;
  }
`

const ExclusionContainer = styled(Container)`
  margin-top: 20px;

  h2 {
    font-size: 14px !important;
    color: ${Colors.Black500} !important;
    margin-top: 10px !important;
  }

  p {
    font-size: 12px;
    color: ${Colors.Black500};
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledSwitch = styled(Checkbox)`
  &.ui.toggle.tester input:checked~.box:before,
  &.ui.toggle.tester input:checked~label:before,
  &.ui.toggle.tester input:focus:checked~.box:before,
  &.ui.toggle.tester input:focus:checked~label:before {
    width: 30px;
      height: 18px;
    background-color: ${Colors.DarkBlue200} !important;
  }

  &.ui.toggle.checkbox label::before {
    width: 30px;
    height: 18px;
    background-color: ${Colors.Grey565} !important;
  }

  &.ui.toggle.checkbox input:checked~label:after {
    left: 0.85rem !important;
  }

  &.ui.toggle.checkbox label::after {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-left: 2px;
  }

  &.ui.fitted.toggle.checkbox {
    margin-left: 10px;
    margin-top: 1px;
  }
`

const StyledHeader = styled(Header)`
  &&.ui.header {
    margin: 0;
  }

  &.ui.header {
    font-size: 16px;
    color: ${Colors.Black500};
  }
`

const StyledForm = styled(Form)`
  &.ui.form [class*="equal width"].fields>.field, .ui[class*="equal width"].form .fields>.field {
    max-width: 165px;
  }

  &.ui.form .field>.selection.dropdown {
    width: 50%;
    border-radius: 0;
  }

  &.ui.mini.form {
    background: ${Colors.White530};
    padding-top: 10px;
    padding-bottom: 1px;
    padding-left: 25px;
  }

  .ui.button {
    font-size: 0.9rem;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    padding: 0.685714em 1.5em .78571429em;
  }
`

const Wrapper = styled.div`
  padding: 20px;
`

export {
  ExclusionContainer,
  ExclusionSegment,
  HeaderContainer,
  SettingsSegment,
  StyledHeader,
  StyledSegmentGroup,
  StyledSwitch,
  StyledForm,
  Wrapper
}
