import SummaryPass1 from 'design/icons/dashboard/leadSummaryPass(1).jpg'
import SummaryPass2 from 'design/icons/dashboard/leadSummaryPass(2).jpg'
import SummaryPass3 from 'design/icons/dashboard/leadSummaryPass(3).jpg'

export const inProcessMockData = [
  {
    activities: 4,
    leadName: 'Maria Martinez',
    score: 100,
    source: 'Call',
    type: 'Seller'
  },
  {
    activities: 5,
    leadName: 'Maria Martinez',
    score: 25,
    source: 'Referral',
    type: 'Rental'
  }
]

export const cardMockData = [
  {
    chart: SummaryPass2,
    header: 4,
    meta: 'New',
    month: '3',
    trend: 'Monthly Trend'
  },
  {
    chart: SummaryPass1,
    header: 3,
    meta: 'In Process',
    month: '3',
    trend: 'Monthly Trend'
  },
  {
    chart: SummaryPass3,
    header: 3,
    meta: 'Closed',
    month: '3',
    trend: 'Monthly Trend'
  }
]
