import client from 'queries/apollo'

import { ACTIVATE_ACCOUNT } from 'queries/graphql/Account/Mutations'

export const handleUserActivation = async () => {
  const response = await client.mutate({
    mutation: ACTIVATE_ACCOUNT
  })

  return response.data.activateAccount
}
