import Colors from 'design/Colors'
import { Form, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

interface Props {
  background: string
}

const ContentHeader = styled.div`
  background: ${Colors.White535};
  display: flex;
  color: ${Colors.DarkBlue200};
  padding-left: 40px;
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  font-weight: 500;
`

const Content = styled.div`
  padding: 40px;
  padding-top: 25px;
  padding-left: 30px;

  .select-task {
    font-size: 12px;
    color: ${Colors.Black500};
    font-weight: 500;
  }
`

const StyledForm = styled(Form)`
  &.ui.tiny.form {
    width: 70%;
  }

  &.ui.form .field>label {
    font-weight: 500;
    color: ${Colors.Black500};
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ui.checkbox input.hidden+label {
    color: ${Colors.Black500};
    font-size: 12px;
  }

  .ui.checkbox label:before {
    width: 14px;
    height: 14px;
    border-radius: 0.214286rem;
    border: 1.5px solid ${Colors.Black550};
  }

  .ui.checkbox label:after {
    width: 14px;
    font-size: 12px;
  }
`

const Calendar = styled.div`
  display: flex;
  margin-top: 30px;

  .fa-calendar-alt {
    width: 50px;
    height: 25px;
    margin-top: 6px;
    color: ${Colors.Blue60};
  }
`

const AssignLabel = styled.div`
  margin-top: 25px;
  font-size: 12px;

  span {
    margin-top: 5px;
    color: ${Colors.Black500};
    white-space: nowrap;
    font-weight: 500;
  }

  div:nth-child(3) {
    display: flex;
    margin-top: 8px;
    align-items: center;
  }

  .fa-pencil-alt{
    width: 16px;
    height: 16px;
    margin-left: 10px;
    margin-top: 8px;
    color: ${Colors.DarkBlue200};
    cursor: pointer;
  }
`

const SelectLabel = styled.div`
  background: ${Colors.Grey605};
  margin-top: 3px;
  width: 125px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Black500};
  cursor: pointer;
`

const DueTime = styled.div`
  display: flex;
  margin-top: 15px;

  .ui.checkbox {
    margin-right: 15px;
  }

  .ui.checkbox label{
    color: ${Colors.Black500};
    font-size: 12px;
    white-space: nowrap;
  }
`

const Tags = styled.div`
  width: 70%;
  margin-top: 10px;
  margin-bottom: 15px;

  .react-tagsinput {
    padding-left: 5px;
    padding-top: 2px;
    border-radius: 3px;
    height: 32.2812px;
    border-color: rgba(34, 36, 38, 0.15);
  }

  .react-tagsinput-tag {
    background-color: ${Colors.Grey610};
    border-radius: 0px;
    border: none;
    color: ${Colors.Black500};
    font-size: 12px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .react-tagsinput-remove {
    margin-left: 8px;
    color: ${Colors.Black500};
  }
`

const StyledButton = styled.div`
  margin-top: 30px;

  .ui.button {
    color: ${Colors.White1000};
    background: ${Colors.DarkBlue200};
    border-radius: 2px;
    margin-right: 12px;
    font-size: 12px;
  }

  div {
    display: flex;
    align-items: baseline;
  }
`

const InviteTeam = styled.div`
  display: flex;
  color: ${Colors.Blue60};
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  align-self: center;

  .fa-share-alt {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    min-width: 340px;
  }
`

const Container = styled.div`
  padding: 10px;
`

const Text = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  color: ${Colors.Blue60};
`

const PopupForm = styled(Form)`
  &.ui.form .field .ui.input {
    width: 205px !important;
    height: 33px;
  }

  &.ui.form .field .ui.input input {
    border-radius: 3px;
  }

  &.ui.form .field .ui.button {
    height: 33px;
    border-radius: 3px;
    color: ${Colors.White1000};
    background-color: ${Colors.Blue60};
  }
`

const Profile = styled.div`
  display: flex;
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: center;
  margin-right: 10px;
`

const Circle = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: ${Colors.Black850};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  span {
    color: ${Colors.Blue60};
    font-weight: bold;
    margin-top: 5px;
  }
`

const AssignIcon = styled.div`
  width: 30px;
  height: 30px;
  background: ${props => props.color || props.color};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-inline--fa {
    color: ${Colors.White1000};
    width: 12px;
    height: 12px;
  }

  .fa-phone {
    transform: rotate(100deg);
  }
`

const SelectTask = styled.div`
  display: flex;
  margin-bottom: 15px;

  .selectTask {
    width: 35px;
    height: 35px;
    background-color: red;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    margin-right: 20px;
  }

  .task {
    cursor: pointer;
    margin-right: 20px;
    margin-top: 10px;
    color: ${Colors.Black500};
  }

  .fa-phone {
    width: 15px;
    height: 15px;
  }

  .fa-envelope {
    width: 15px;
    height: 15px;
  }

  .fa-comment {
    width: 15px;
    height: 15px;
  }

  .fa-handshake {
    width: 20px;
    height: 15px;
  }

  .fa-ticket {
    width: 15px;
    height: 15px;
  }
`

export {
  AssignIcon,
  AssignLabel,
  Circle,
  Calendar,
  Content,
  Container,
  ContentHeader,
  DueTime,
  InviteTeam,
  PopupForm,
  Profile,
  ProfileImage,
  SelectLabel,
  StyledButton,
  StyledForm,
  StyledPopup,
  SelectTask,
  Tags,
  Text
}
