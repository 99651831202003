// Import Packages
import * as React from 'react'
import { VictoryPie } from 'victory'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Activities,
  Circle,
  ProfileProgressContainer,
} from './Styled'

const getDataResidential = (percent: number) => {
  return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
}

export const CustomScore = (score: number) => {
  let color = ''
  if (score >= 0 && score < 25) {
    color = Colors.DarkBlue205
  } else if (score >= 25 && score < 50) {
    color = Colors.Grey950
  } else if (score >= 50 && score < 75) {
    color = Colors.Green80
  } else {
    color = Colors.Red10
  }
  return (
    <ProfileProgressContainer>
      <div>
        <svg viewBox="0 0 400 400" >
          <VictoryPie
            standalone={false}
            animate={{ duration: 5000 }}
            width={400} height={400}
            data={getDataResidential(score)}
            innerRadius={175} labelRadius={120}
            padding={0}
            startAngle={0}
            endAngle={360}
            labels={['R : ', '30%']}
            colorScale={[Colors.Orange100, Colors.White900]}
          />
        </svg>
      </div>
      <Circle color={color}><div>{score}</div></Circle>
    </ProfileProgressContainer>
  )
}

export const CustomActivities = (activities: any) => {
  return (
    <Activities circular={true}>{activities.length}</Activities>
  )
}
