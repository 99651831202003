// Import Components
import client from 'queries/apollo'
import { GET_MY_REPORT } from 'queries/graphql/Report/Queries'

// Import Graphql Mutations
import { CREATE_MY_REPORT, DELETE_MY_REPORT, UPDATE_MY_REPORT } from 'queries/graphql/Report/Mutation'

// For super admin current not use
export const createMyReport = async (module: string, reportName: string) => {
  await client.mutate({
    mutation: CREATE_MY_REPORT,
    variables: { module, reportName }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_MY_REPORT
  })

  return response.data.getMyReports
}

export const updateMyReport = async (updateData: object) => {
  await client.mutate({
    mutation: UPDATE_MY_REPORT,
    variables: { ...updateData }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_MY_REPORT
  })

  return response.data.getMyReports
}

export const deleteMyReport = async (id: string) => {
  await client.mutate({
    mutation: DELETE_MY_REPORT,
    variables: { id }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_MY_REPORT
  })

  return response.data.getMyReports
}
