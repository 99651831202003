import { ActionCreator } from 'redux'
import {
  DispatchEnum,
  SetTaxAction,
  TaxOfficeType,
} from './Types'

export const setTax: ActionCreator<SetTaxAction> = (payload: Partial<TaxOfficeType>) => ({
  payload,
  type: DispatchEnum.SetTax
})
