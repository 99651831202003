import {
  Input,
  Label,
  Segment,
} from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { y } from 'design/Styled'

const FeeGroup = styled.div`
  ${y};
  width: 100%;
  padding-left: 0.5em;
`

const OtherItemContainer = styled(Segment.Group)`
  &&.ui.segments{
    background-color: ${Colors.White525};
    height: 120px;
    border-radius: 0;
    display: flex;
    margin-bottom: 5px;
    position: relative;
    border: none;
  }
`

const TypeSection = styled(Segment)`
  &&.ui.segment {
    width: 40%;
    height: 100%;
  }
`

const SliderSection = styled(Segment)`
  &&.ui.segment {
    width: 60%;
    height: 100%;
    display: flex;
    border: none;
    border-left: none !important;
    padding-left: 0;
    margin-right: 20px !important;

    @media (max-width: 1150px) {
      width: 30%;
      margin-right: 0px !important;
    }

    @media (min-width: 1150px) and (max-width: 1250px) {
      width: 40%;
      margin-right: 8px !important;
    }

    @media (min-width: 1250px) and (max-width: 1350px) {
      width: 45%;
      margin-right: 8px !important;
    }
  }
`

const TextInputContainer = styled(Segment.Group)`
  &&.ui.segments {
    display: flex;
    height: 40%;
    justify-content: flex-start;
    background-color: transparent;
    border: none;
    box-shadow: none;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 0;
  }

  .ui.input>input {
    font-size: 10px;
    border-radius: 0;
    height: 30px;
  }
`

const RadioButtonContainer = styled.div`
  display: flex;
  height: 40%;
  padding-left: 10px;
`

const CloseIconContainer = styled.div`
  position: absolute;
  top: 3px;
  right: 0px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`

const AddMoreContainer = styled.div`
  width: 100%;
  height: 20px;
  justify-content: flex-end;
  display: flex;
  padding-right: 5px;
  cursor: pointer;
`

const AddMoreButton = styled.div`
  font-size: 12px;
  color: ${Colors.DarkBlue200};
  text-decoration: underline;
  font-weight: 600;
`

const NameTextBox = styled(Input)`

`

const TextBoxLabel = styled(Label)`
  &.ui.basic.label {
    border: none;
    width: inherit;
    font-size: 12px;
    background-color: transparent;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: ${Colors.Black500};
    font-weight: 400;
  }
`

export {
  AddMoreButton,
  AddMoreContainer,
  CloseIconContainer,
  FeeGroup,
  NameTextBox,
  OtherItemContainer,
  SliderSection,
  TypeSection,
  RadioButtonContainer,
  TextBoxLabel,
  TextInputContainer,
}
