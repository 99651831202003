// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { primaryStyledButton } from 'design/Styled'

export const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
  height: 100%;
`

export const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
    font-size: 12px;
    font-weight: 500;
  }

  &.ui.form .field>.selection.dropdown {
    width: 200px;
    font-size: 12px;
  }

  .ui.button {
    ${primaryStyledButton};
  }
`

export const AddForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
