// Import Packages
import { Progress } from 'semantic-ui-react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { fontStyle } from 'design/Fonts'

const ModalWrapper = styled.div`
  .header {
    margin: 10px auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
  .description {
    margin: 10px auto;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    max-width: 60%;
  }
`
const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  top: 76px;
  position: fixed;
  cursor: pointer;
  left: 0px;
`

const Modal = styled.div`
  position: relative;
  padding: 0px;
  background-color: ${Colors.White1000};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.13);
  z-index: 2002;
  padding-top: 20px;
  padding-bottom: 20px;
`

const SegmentGroup = styled(Segment.Group)`
  &.ui.horizontal.segments {
    border: none;
    box-shadow: none;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
  }
`

const FileDropSection = styled(Segment)`
  .ui.horizontal.segments > &.segment {
    border: none;
    box-shadow: none;
    padding: 1em 34px;
    border-left: none;
    border-right-color: ${Colors.Grey950};
    border-right-width: 1.5px;
    border-right-style: solid;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: ${Colors.Black500};
    width: 350px;
    padding: 0px;
    line-height: 0px;

    .dropSectionText {
    }

    .fileSpecText {
      font-size: 11px;
      margin-left: 5px;
    }
  }
`

const UploadSection = styled(Segment)`
  .ui.horizontal.segments > &.segment {
    border: none;
    box-shadow: none;
    margin: 0 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
    height: 300px;
    align-self: center;
    justify-content: space-evenly;
    .label {
      color: ${Colors.Black750};
    }
  }
`

const StyledButton = styled(Button)`
  &.ui.button {
    padding: 0.75em 2em;
    background-color: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    width: fit-content;
    align-self: center;
    border-radius: 2px;
    letter-spacing: 0.4px;
    font-weight: 500;
    font-size: 15px;
    &:hover {
      background-color: ${Colors.DarkBlue200} !important;
      color: ${Colors.White1000} !important;
    }
    &:focus {
      background-color: ${Colors.DarkBlue200} !important;
      color: ${Colors.White1000} !important;
    }
    &:active {
      background-color: ${Colors.DarkBlue200} !important;
      color: ${Colors.White1000} !important;
    }
  }
`

const ImageContainer = styled.div`
  height: 50px;

  &.largeContainer {
    padding: 40px;
    border-width: 2px;
    border-color: ${Colors.Green50};
    border-style: dashed;
    border-radius: 50%;
    margin: 30px;
    width: 180px;
    height: 180px;
  }

  .progress-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .uploading {
    font-size: 12px;
    color: ${Colors.Green310};
  }
`

const StyledDivider = styled(Divider)`
  &.ui.horizontal.divider {
    font-size: 14px;
    font-family: ${fontStyle};
    font-style: italic;
  }
`

const SampleFileSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-top: 30px;
  border-top-color: blue;
  border-top-style: solid;
  border-top-width: 2px;
  margin-left: 70px;
  margin-right: 70px;
  margin-bottom: 25px;
  align-items: center;

  .textContainer {
    margin: 0px 50px;
  }
  .downloadText {
    font-size: 14px;
    font-family: ${fontStyle};
  }
  .descriptionText {
    font-size: 12px;
    font-family: ${fontStyle};
    font-style: italic;
  }
`

interface ImageProps {
  backgroundImage: string
}

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) =>
    `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 90%;
  align-self: center;
  margin: 0px;
  margin-top: 10px;

  &.large {
    height: 70%;
  }
`

const StyledProgress = styled(Progress)`
  &.ui.progress {
    align-items: center;
    margin: 40px 0 15px !important;
    border-radius: 18px;
    height: 10px;
    border: 2px solid ${Colors.Green310};
    background: ${Colors.transparent};
    width: 125px;
    max-width: none;
  }

  &.ui.progress .bar {
    background-color: ${Colors.Green310} !important;
    height: 6px;
  }
`

export {
  Backdrop,
  FileDropSection,
  ImageContainer,
  ImageWrapper,
  Modal,
  ModalWrapper,
  SampleFileSection,
  SegmentGroup,
  StyledButton,
  StyledProgress,
  StyledDivider,
  UploadSection
}
