// Import Utils
import { generateID } from 'utils'

export const reportMockData = [
  {
    _id: generateID(),
    module: 'Transactions',
    reportName: [
      { _id: '5de9e8264d879e1be1001ba', name: 'Monthly Production (past 30 days)' },
      { _id: '5de9e8264d879e1be1002ba', name: 'Quarterly Production (past 90 days)' },
      { _id: '5de9e8264d879e1be1003ba', name: 'YTD Production (Jan 1, YYYY - Today)' },
    ],
  },
  {
    _id: generateID(),
    module: 'Leads',
    reportName: [
      { _id: '5de9e8264d879e1be1004ba', name: 'Monthly Summary (past 30 days)' },
      { _id: '5de9e8264d879e1be1005ba', name: 'Quarterly Summary (past 90 days)' },
    ],
  },
  {
    _id: generateID(),
    module: 'Contacts',
    reportName: [
      { _id: '5de9e8264d879e1be1006ba', name: 'Monthly Summary (past 30 days)' },
      { _id: '5de9e8264d879e1be1007ba', name: 'Quarterly Summary (past 90 days)' },
    ],
  },
  {
    _id: generateID(),
    module: 'Tasks',
    reportName: [
      { _id: '5de9e8264d879e1be1008ba', name: 'Monthly Summary (past 30 days)' },
      { _id: '5de9e8264d879e1be1009ba', name: 'Quarterly Summary (past 90 days)' },
    ],
  },
  {
    _id: generateID(),
    module: 'Users',
    reportName: [
      { _id: '5de9e8264d879e1be2001ba', name: 'New Users (past 30 days)' },
      { _id: '5de9e8264d879e1be2002ba', name: 'Quarterly Summary (past 90 days)' },
      { _id: '5de9e8264d879e1be2003ba', name: 'YTD Summary (Jan 1, YYYY - Today)' },
    ],
  },
  {
    _id: generateID(),
    module: 'Teams',
    reportName: [
      { _id: '5de9e8264d879e1be2004ba', name: 'Global Detail List (Shows ALL records)' }
    ],
  },
  {
    _id: generateID(),
    module: 'Offices',
    reportName: [
      { _id: '5de9e8264d879e1be2005ba', name: 'Global Detail List (Shows ALL records)' },
    ],
  },
]

export const reportMockDataAgent = [
  {
    _id: generateID(),
    module: 'Transactions',
    reportName: [
      { _id: '5de9e8264d879e1be1001ba', name: 'Monthly Production (past 30 days)' },
      { _id: '5de9e8264d879e1be1002ba', name: 'Quarterly Production (past 90 days)' },
      { _id: '5de9e8264d879e1be1003ba', name: 'YTD Production (Jan 1, YYYY - Today)' },
    ],
  },
  {
    _id: generateID(),
    module: 'Leads',
    reportName: [
      { _id: '5de9e8264d879e1be1004ba', name: 'Monthly Summary (past 30 days)' },
      { _id: '5de9e8264d879e1be1005ba', name: 'Quarterly Summary (past 90 days)' },
    ],
  },
  {
    _id: generateID(),
    module: 'Contacts',
    reportName: [
      { _id: '5de9e8264d879e1be1006ba', name: 'Monthly Summary (past 30 days)' },
      { _id: '5de9e8264d879e1be1007ba', name: 'Quarterly Summary (past 90 days)' },
    ],
  },
  {
    _id: generateID(),
    module: 'Tasks',
    reportName: [
      { _id: '5de9e8264d879e1be1008ba', name: 'Monthly Summary (past 30 days)' },
      { _id: '5de9e8264d879e1be1009ba', name: 'Quarterly Summary (past 90 days)' },
    ],
  },
]

export const systemGeneratedMyReportMockData = [
  {
    _id: generateID(),
    module: 'Transactions',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Leads',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Contacts',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Tasks',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Users',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Teams',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Offices',
    reportName: [],
  },
]

export const systemGeneratedMyReportMockDataAgent = [
  {
    _id: generateID(),
    module: 'Transactions',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Leads',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Contacts',
    reportName: [],
  },
  {
    _id: generateID(),
    module: 'Tasks',
    reportName: [],
  }
]
