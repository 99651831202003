import * as Images from 'design/images/dashboard/widgets/all/'
import generateID from 'utils/generateId'

const MockWidgets = [
  {
    id: generateID(),
    img: Images.activityFeed,
    title: 'Activity Feed'
  },
  {
    id: generateID(),
    img: Images.analytics,
    title: 'Analytics'
  },
  {
    id: generateID(),
    img: Images.badges,
    title: 'Badges'
  },
  {
    id: generateID(),
    img: Images.bottom03,
    title: 'trends'
  },
  {
    id: generateID(),
    img: Images.bottom04,
    title: 'games'
  },
  {
    id: generateID(),
    img: Images.calendar,
    title: 'calendar'
  },
  {
    id: generateID(),
    img: Images.graphs,
    title: 'graphs'
  },
  {
    id: generateID(),
    img: Images.green01,
    title: 'reports'
  },
  {
    id: generateID(),
    img: Images.leads,
    title: 'leads'
  },
  {
    id: generateID(),
    img: Images.map,
    title: 'map'
  },
  {
    id: generateID(),
    img: Images.statistics,
    title: 'statistics'
  },
  {
    id: generateID(),
    img: Images.taskSheet,
    title: 'task sheet'
  }
]

export default MockWidgets
