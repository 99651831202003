import client from 'queries/apollo'

import { UPDATE_BILLING_PLAN } from 'queries/graphql/Billing/Mutations'

import { Actions } from 'store/Billing/Types'
import Store from 'store/Store'
import { CheckoutSessionInput } from './Types'

export const updateBillingPlan = async (input: CheckoutSessionInput) => {
  const result: any = await client.mutate({
    mutation: UPDATE_BILLING_PLAN,
    variables: { input }
  })
  const checkout = result.data.updateBillingPlan
  if (checkout && checkout.status === 'success') {
    Store.dispatch({
      payload: input.plan,
      type: Actions.BillingUpdatePlan
    })
  }
  return result.data.updateBillingPlan
}
