// Import Packages
import { Label, Menu, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${Colors.Black500};
  text-align: center;

  .fa-pencil-alt {
    cursor: pointer;
  }
`

const ProfileProgressContainer = styled.div`
  width: 110px;
  position: relative;
  text-align: center;

  text {
    display: none;
  }
`

const FullName = styled.div`
  position: absolute;
  font-size: 40px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${Colors.Orange250};
  text-transform: capitalize;
  flex-direction: column;
`

const Info = styled.div`
  width: 130px;
  margin-left: -5px;
  margin-top: 15px;
`

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
`

const Score = styled.div`
`

const Role = styled.div`
  font-style: italic;
  white-space: pre;
  font-size: 14px;
`

const Icon = styled.div`
  margin-top: 8px;

  .fa-map-marker-alt {
    margin-right: 12px;
    color: ${Colors.Black500};
    cursor: pointer;
  }

  .fa-envelope {
    margin-right: 12px;
    color: ${Colors.Black500};
    cursor: pointer;
  }

  .fa-pencil-alt {
    color: ${Colors.Black500};
    cursor: pointer;
  }

  .fa-phone {
    margin-right: 12px;
    transform: rotate(100deg);
    width: 12px;
    height: 16px;
    color: ${Colors.Black500};
    cursor: pointer;
  }
`

const Status = styled.div`
  font-size: 12px;
  margin-top: 20px;
`

const StyledMenu = styled(Menu)`
  &.ui.secondary.vertical.menu>.item {
    color: ${Colors.DarkBlue200};
    font-weight: 500;
  }

  &.ui.vertical.menu>a:nth-child(even) {
    background: ${Colors.White1000};
  }

  &.ui.vertical.menu>a:nth-child(odd) {
    background: ${Colors.White800};
  }

  &.ui.secondary.vertical.menu>.item {
    margin-bottom: 0;
    border-radius: 0 !important;
  }

  &.ui.vertical.menu {
    width: 100%;
    background-color: ${Colors.White1000};
    margin-left: 1px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .svg-inline--fa {
    margin-right: 15px;
    width: 16px;
    height: 16px;
  }

  .fa-caret-right {
    margin-right: 15px;
    width: 16px;
    height: 16px;
  }

  .fa-caret-right {
    margin-right: 0px;
    width: 12px;
    height: 12px;
  }

  &.ui.vertical.menu>a {
    padding: 20px;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }

  &.ui.vertical.menu>a>div {
    flex-grow: 1;
  }

  &.ui.secondary.menu .active.item {
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    border-radius: 0 !important;
  }
`

const PersonContainer = styled.li`
  display: inline-block;
  margin-right: 20px;
  position: relative;
`

const Wrapper = styled.div`
  width: 75px;
  display: -webkit-inline-box;

  .people-name {
    position: relative;
  }

  .people-name:first-child .peoples {
    left: -5px;
  }
`

const People = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
`

const PeopleName = styled.div`
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
`

const PeopleStyledPopup = styled(Popup)`
  &.ui.popup {
    color: ${Colors.Black500};
    padding: 1em 1em;
  }
`

const Email = styled.div`
  display: flex;
  justify-content: center;

  .fa-phone {
    transform: rotate(100deg);
  }

  .fa-eye {
    color: ${Colors.DarkBlue200};
  }

  .svg-inline--fa {
    margin-right: 6px;
    cursor: pointer;
  }
`

const ContentWrapper = styled.section`
  margin: 0 auto;
  overflow-x: auto;
  margin-left: 0;
  margin-top: 20px;
  overflow: visible;
`

const FlexWrapper = styled.ul`
  width: 100%;
  padding: 0px;
  height: 85px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: auto;
  overflow-y: hidden;
  margin-top: 10px;
  ${scrollbars};
`

const SharedWith = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${Colors.Black500};
  display: flex;

  span:nth-child(1) {
    flex-grow: 1;
  }

  span:nth-child(2) {
    text-decoration: underline;
    color: ${Colors.DarkBlue200};
    cursor: pointer;
  }

`

const Labels = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: lowercase;
  }
`

const Pencil = styled.div`
  .svg-inline--fa {
    width: 18px;
    height: 18px;
    margin-top: 16px;
    color: ${Colors.Black500};
  }
`

export {
  Container,
  ContentWrapper,
  Email,
  FlexWrapper,
  FullName,
  Icon,
  Info,
  Labels,
  Name,
  People,
  PeopleName,
  PersonContainer,
  ProfileProgressContainer,
  PeopleStyledPopup,
  Role,
  Score,
  SharedWith,
  Status,
  StyledMenu,
  StyledPopup,
  Wrapper,
  Pencil
}
