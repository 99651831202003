import * as React from 'react'

import { Actions, Container, Hover, Image, StyledPopup, Title } from './Styled'

import { ActionType, ChecklistItemType } from '../Types'

import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faInfoCircle])

interface Props {
  actions: ActionType[]
  allowActions: boolean
  item: ChecklistItemType
  onAction: (itemId: string, action: ActionType) => void
}

interface State {
  current: ActionType
}

export default class Document extends React.Component<Props, State> {
  public state = {
    current: {} as ActionType
  }

  public componentDidMount = () => {
    this.setCurrent()
  }

  public componentDidUpdate = (prev: Props) => {
    if (this.props.item.status !== prev.item.status) {
      this.setCurrent()
    }
  }

  public render() {
    const { current } = this.state
    const {
      actions,
      allowActions,
      item: { _id, description, name },
      onAction
    } = this.props

    return (
      <Container>
        <Title>
          {name}
          {description && (
            <StyledPopup
              position="bottom center"
              trigger={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
              content={description}
            />
          )}
        </Title>
        <Actions>
          <Image src={current.src} className="active" />
          <Hover className="hover">
            {allowActions &&
              actions.map((action: ActionType, index: number) => {
                if (current.src !== action.src) {
                  return <Image key={index} src={action.src} onClick={() => onAction(_id, action)} />
                }
                return null
              })}
          </Hover>
        </Actions>
      </Container>
    )
  }

  private setCurrent = () => {
    const {
      actions,
      item: { status }
    } = this.props

    const current: any = actions.find((item: ActionType) => item.value === status)
    if (current) {
      this.setState({ current: current })
    }
  }
}
