import { addLead } from 'app/Leads/Dashboard/LeadMutations'
import Toast from 'shared/Toast/Toast'
import { getLoggedInUser } from 'utils'

export const addNewLead = async (newLeadData: any) => {
  const user = await getLoggedInUser()

  const response: any = await addLead(newLeadData, '', newLeadData.tenantBoard, false, false, user, '', false)
  if (!response) {
    Toast({
      message: 'An error occurred trying to create a new Lead',
      type: 'error'
    })
  }
}
