import Colors from 'design/Colors'
import { Tab } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  y
} from 'design/Styled'

const Container = styled.div`
  ${y};
  height: 100%;
  background: ${Colors.Container};
`

const StyledTab = styled(Tab)`
  ${y};
  height: 100%;

  .ui.secondary.pointing.menu .item {
    margin: 0 2em;
    font-weight: 600;
    color: ${Colors.Text};
  }

  .ui.secondary.pointing.menu .active.item {
    color: ${Colors.ButtonPrimary};
    border-bottom: 3px solid ${Colors.ButtonPrimary};
  }

  .ui.secondary.menu {
    padding-top: 0.5em;
    justify-content: center;
  }

  .ui.secondary.pointing.menu {
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 0;
    border-bottom: 1px solid ${Colors.EmphasisLine};
  }
`

export {
  Container,
  StyledTab
}
