export const cards = {
  free: {
    details: [
      '5 Transactions Included',
      'Basic Passes',
      '1000 Record Limit',
      'Email & Chat Support'
    ],
    price: 0,
    priceColor: '#4e5f80',
    title: 'Free'
  },
  paid: {
    details: [
      'Multi-Office Support',
      'Lead System',
      'Onboarding Assistance',
      'Premium Pass Access',
      'Phone Support'
    ],
    price: 99,
    priceColor: '#00821c',
    title: 'Pay as you go'
  }
}

export const tiers = [
  { name: 'Tier 1', count: '1 - 50', cost: 5 },
  { name: 'Tier 2', count: '51 - 100', cost: 4 },
  { name: 'Tier 3', count: '101 - 200', cost: 3 },
  { name: 'Tier 4', count: '201 +', cost: 2 }
]
