// Import Graphql Mutations
import {
  CREATE_TASK,
  CREATE_TASK_NOTES,
  DELETE_EVENT,
  DELETE_TASK,
  DELETE_TASK_NOTES,
  EDIT_TASK_NOTES,
  IMPORT_TASKS,
  MOVE_TASK,
  UPDATE_TASK,
  UPDATE_TASK_ORDER
} from 'queries/graphql/Tasks/Mutations'

// Import Components
import { CREATE_TASK_BOARD, DELETE_TASK_BOARD, UPDATE_TASK_BOARD_ORDER } from 'queries/graphql/TaskBoard/Mutations'
import client from 'queries/apollo'

import { TaskItemsDetails, UserType } from 'store/Tasks/Types'

export const createTask = async (
  newTask: TaskItemsDetails | any,
  addTaskIndex: string,
  addBoardId: string,
  show: string,
  user: UserType | any,
  edit: boolean,
  filter: boolean
) => {
  const { description, dueDate, messageEmail, name, sharedWith, type, taskTime, _id } = newTask
  let result: any = null

  if (show === 'kanban') {
    if (edit) {
      return await client.mutate({
        mutation: UPDATE_TASK,
        variables: {
          description,
          dueDate,
          name,
          sharedWith,
          taskId: _id,
          taskTime,
          type
        }
      })
    } else {
      result = await client.mutate({
        mutation: CREATE_TASK,
        variables: {
          description,
          dueDate,
          messageEmail,
          name,
          officeTaskBoard: filter !== false && user.role === 'MANAGER' ? addBoardId : undefined,
          officeTaskOrder: filter !== false && user.role === 'MANAGER' ? parseInt(addTaskIndex) : undefined,
          sharedWith,
          taskBoard: filter === false || user.role === 'AGENT' ? addBoardId : undefined,
          taskOrder: filter === false || user.role === 'AGENT' ? parseInt(addTaskIndex) : undefined,
          taskTime,
          teamTaskBoard: filter !== false && user.role === 'TEAM_LEADER' ? addBoardId : undefined,
          teamTaskOrder: filter !== false && user.role === 'TEAM_LEADER' ? parseInt(addTaskIndex) : undefined,
          tenantTaskBoard: filter !== false && user.role === 'ADMIN' ? addBoardId : undefined,
          tenantTaskOrder: filter !== false && user.role === 'ADMIN' ? parseInt(addTaskIndex) : undefined,
          type
        }
      })
    }
  } else {
    if (edit) {
      return await client.mutate({
        mutation: UPDATE_TASK,
        variables: {
          description,
          dueDate,
          name,
          sharedWith,
          taskId: _id,
          taskTime,
          type
        }
      })
    } else {
      result = await client.mutate({
        mutation: CREATE_TASK,
        variables: {
          description,
          dueDate,
          messageEmail,
          name,
          officeTaskBoard: filter !== false && user.role === 'MANAGER' ? addBoardId : undefined,
          sharedWith,
          taskBoard: filter === false || user.role === 'AGENT' ? addBoardId : undefined,
          taskTime,
          teamTaskBoard: filter !== false && user.role === 'TEAM_LEADER' ? addBoardId : undefined,
          tenantTaskBoard: filter !== false && user.role === 'ADMIN' ? addBoardId : undefined,
          type
        }
      })
    }
  }

  if (result.data) {
    result = result.data.createTask
  }
  return result
}

export const updateShareWithTasks = async (newTask: TaskItemsDetails | any) => {
  const { _id, sharedWith } = newTask
  return await client.mutate({
    mutation: UPDATE_TASK,
    variables: {
      sharedWith,
      taskId: _id
    }
  })
}

export const deleteTasks = async (taskIds: string[]) => {
  const response = await client.mutate({
    mutation: DELETE_TASK,
    variables: { taskId: taskIds }
  })

  return response.data.deleteTask
}

export const createTaskNotes = async (taskId: string, description: string) => {
  const res = await client.mutate({
    mutation: CREATE_TASK_NOTES,
    variables: { taskId, description }
  })
  return res.data.createNote
}

export const editTaskNotes = async (taskNoteId: string, description: string) => {
  const res = await client.mutate({
    mutation: EDIT_TASK_NOTES,
    variables: { taskNoteId, description }
  })
  return res.data.updateNote
}

export const deleteTaskNotes = async (taskNoteId: string) => {
  await client.mutate({
    mutation: DELETE_TASK_NOTES,
    variables: { taskNoteId }
  })
}

export const moveTask = async (
  taskId: string,
  taskBoardId: string,
  destinationIndex: number,
  user: any,
  filter: boolean
) => {
  await client.mutate({
    mutation: MOVE_TASK,
    variables: {
      officeTaskBoard: filter !== false && user.role === 'MANAGER' ? taskBoardId : undefined,
      officeTaskBoardOrder: filter !== false && user.role === 'MANAGER' ? destinationIndex : undefined,
      taskBoard: filter === false || user.role === 'AGENT' ? taskBoardId : undefined,
      taskId,
      taskOrder: filter === false || user.role === 'AGENT' ? destinationIndex : undefined,
      teamTaskBoard: filter !== false && user.role === 'TEAM_LEADER' ? taskBoardId : undefined,
      teamTaskBoardOrder: filter !== false && user.role === 'TEAM_LEADER' ? destinationIndex : undefined,
      tenantTaskBoard: filter !== false && user.role === 'ADMIN' ? taskBoardId : undefined,
      tenantTaskBoardOrder: filter !== false && user.role === 'ADMIN' ? destinationIndex : undefined
    }
  })
}

export const addNewTaskBoard = (index: number, name: string, user: UserType) => {
  return client.mutate({
    mutation: CREATE_TASK_BOARD,
    variables: {
      name: name ? name : 'New Board',
      order: index,
      user: user._id
    }
  })
}

export const reorderLane = async (
  boardId: string,
  sourceIndex: number,
  destinationIndex: number,
  user: UserType,
  filter: boolean
) => {
  await client.mutate({
    mutation: UPDATE_TASK_BOARD_ORDER,
    variables: {
      boardId,
      destinationIndex,
      sourceIndex,
      userId: filter === false ? user._id : undefined
    }
  })
}

export const reorderCard = async (
  taskBoardId: string,
  cardId: string,
  sourceIndex: number,
  destinationIndex: number,
  user: UserType | any,
  filter: boolean
) => {
  client.mutate({
    mutation: UPDATE_TASK_ORDER,
    variables: {
      cardId,
      destinationIndex,
      officeDestinationTaskBoard: filter !== false && user.role === 'MANAGER' ? destinationIndex : undefined,
      officeSourceTaskBoard: filter !== false && user.role === 'MANAGER' ? sourceIndex : undefined,
      officeTaskBoard: filter !== false && user.role === 'MANAGER' ? taskBoardId : undefined,
      taskBoard: filter === false || user.role === 'AGENT' ? taskBoardId : undefined,
      taskDestinationBoardOrder: filter === false || user.role === 'AGENT' ? destinationIndex : undefined,
      taskSourceBoardOrder: filter === false || user.role === 'AGENT' ? sourceIndex : undefined,
      teamDestinationTaskBoard: filter !== false && user.role === 'TEAM_LEADER' ? destinationIndex : undefined,
      teamSourceTaskBoard: filter !== false && user.role === 'TEAM_LEADER' ? sourceIndex : undefined,
      teamTaskBoard: filter !== false && user.role === 'TEAM_LEADER' ? taskBoardId : undefined,
      tenantDestinationTaskBoard: filter !== false && user.role === 'ADMIN' ? destinationIndex : undefined,
      tenantSourceTaskBoard: filter !== false && user.role === 'ADMIN' ? sourceIndex : undefined,
      tenantTaskBoard: filter !== false && user.role === 'ADMIN' ? taskBoardId : undefined
    }
  })
}

export const importTasks = async (groupid: string, file: any, filterOptions: boolean, user: UserType) => {
  const res = await client.mutate({
    mutation: IMPORT_TASKS,
    variables: {
      file,
      officeTaskBoard: filterOptions !== false && user.role === 'MANAGER' ? groupid : undefined,
      taskBoard: filterOptions === false || user.role === 'AGENT' ? groupid : undefined,
      teamTaskBoard: filterOptions !== false && user.role === 'TEAM_LEADER' ? groupid : undefined,
      tenantTaskBoard: filterOptions !== false && user.role === 'ADMIN' ? groupid : undefined
    }
  })
  return res.data.importTasks
}

export const deleteTaskBoard = (boardId: string, user: UserType) => {
  return client.mutate({
    mutation: DELETE_TASK_BOARD,
    variables: {
      boardId
    }
  })
}

export const deleteEvent = async (eventId: string) => {
  const response = await client.mutate({
    mutation: DELETE_EVENT,
    variables: { eventId }
  })

  return response.data.deleteEvent
}
