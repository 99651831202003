import { Popup, Dropdown, Input } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { children, text, x, y } from 'design/Styled'

import { PointType } from '../Types'

interface Props {
  position?: PointType
}

const getPosition = ({ position }:Props) => {
  if (!position) {
    position = {
      x: 0,
      y: 0,
    }
  }

  return css`
    top: ${position.y}px;
    left: ${position.x}px;
  `
}

const list = css`
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 2;
`

const listItem = css`
  display: block;
  padding: 0.5em 0.8em 0.5em !important;
  border-bottom: 1px solid ${Colors.TableHeadLine};
  color: ${Colors.Text};
  transition: all 200ms;
  cursor: pointer;

  &:hover {
    color: ${Colors.TextDark};
    background: ${Colors.ListEven};
  }
`

export const Container = styled(Popup)`
  &.ui.popup {
    ${text};
    font-size: 0.9em;
    background: ${Colors.ListOdd};
    padding: 0;
    border-radius: 0.25em;
    box-shadow: 0 0.1em 0.8em -0.5em ${Colors.ContainerShadow};

    &.bottom,
    &.top,
    &.center {
      &:before {
        z-index: 1;
        background: ${Colors.ListOdd};
      }
    }
  }
`

export const Point = styled.div`
  position: absolute;
  ${getPosition};
`

export const List = styled.ul`
  position: relative;
  ${list};
`

export const Item = styled.li`
  ${listItem};
`

export const Row = styled.div`
  ${x};
  ${children};
  font-size: 11px;
`

export const Col = styled.section`
  ${y};
`

export const Label = styled.label`
  font-weight: bold;
  font-size: 90%;
  color: ${Colors.TextMedium};
  margin-bottom: 0.5em;
`

export const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    ${x};
    white-space: nowrap;
    min-width: 10em;
    padding: .78571429em 2.1em .78571429em 1em;
    > .text {
      white-space: nowrap;
    }
  }
`

export const DropdownItem = styled(Dropdown.Item)``

export const StyledInput = styled(Input)``
