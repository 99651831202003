// Import Components
import client from 'queries/apollo'

// Import Graphql Mutation
import {
  CREATE_WORKFLOW,
  DELETE_WORKFLOW,
  REORDER_WORKFLOW,
  UPDATE_WORKFLOW
} from 'queries/graphql/Settings/WorkflowManager/Workflow/Mutation'
import {
  CREATE_WORKFLOW_TASK,
  DELETE_WORKFLOW_TASK,
  REORDER_WORKFLOW_TASK,
  UPDATE_WORKFLOW_TASK
} from 'queries/graphql/Settings/WorkflowManager/WorkflowTask/Mutation'

export const createWorkflow = async (data: any) => {
  const { name, workflowCategory, visibility, createdBy } = data
  const response = await client.mutate({
    mutation: CREATE_WORKFLOW,
    variables: { name, categoryId: workflowCategory, visibility, createdBy }
  })

  return response.data.createWorkflow
}

export const updateWorkflow = async (data: any) => {
  const { id, name, workflowCategory, visibility, createdBy } = data
  const response = await client.mutate({
    mutation: UPDATE_WORKFLOW,
    variables: { id, name, categoryId: workflowCategory, visibility, createdBy }
  })

  return response.data.updateWorkflow
}

export const deleteWorkFlow = async (workflowId: string) => {
  const response = await client.mutate({
    mutation: DELETE_WORKFLOW,
    variables: { workflowId }
  })

  return response.data.deleteWorkflow
}

export const createWorkflowTask = async (data: any, workflowId: string) => {
  const { type, name, description, dueDate } = data
  const response = await client.mutate({
    mutation: CREATE_WORKFLOW_TASK,
    variables: { type, name, description, dueDate, workflowId }
  })

  return response.data.createWorkflowTask
}

export const deleteWorkflowTask = async (workflowTaskId: string) => {
  const response = await client.mutate({
    mutation: DELETE_WORKFLOW_TASK,
    variables: { workflowTaskId }
  })

  return response.data.deleteWorkflowTask
}

export const updateWorkflowTask = async (data: any, workflowTaskId: string, workflowId: string) => {
  const { type, name, description, dueDate } = data

  const response = await client.mutate({
    mutation: UPDATE_WORKFLOW_TASK,
    variables: { type, name, description, dueDate, workflowId, workflowTaskId }
  })

  return response.data.updateWorkflowTask
}

export const reorderWorkflow = async (data: any) => {
  const response = await client.mutate({
    mutation: REORDER_WORKFLOW,
    variables: { workflows: data }
  })

  return response.data.reorderWorkflow
}

export const reorderWorkflowTasks = async (data: any) => {
  const response = await client.mutate({
    mutation: REORDER_WORKFLOW_TASK,
    variables: { workflowTasks: data }
  })

  return response.data.reorderingWorkflowTask
}
