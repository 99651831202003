import gql from 'graphql-tag'

export const CREATE_WORKFLOW = gql`
  mutation createWorkflow(
    $name: String
    $categoryId: String
    $visibility: String
    $createdBy: String
  ) {
    createWorkflow(
      input: {
        name: $name
        workflowCategory: $categoryId
        visibility: $visibility
        createdBy: $createdBy
      }
    ) {
      _id
      name
      visibility
      isActive
    }
  }
`

export const UPDATE_WORKFLOW = gql`
  mutation updateWorkflow(
    $id: String
    $name: String
    $categoryId: String
    $visibility: String
    $createdBy: String
  ) {
    updateWorkflow(
      _id: $id
      input: {
        name: $name
        workflowCategory: $categoryId
        visibility: $visibility
        createdBy: $createdBy
      }
    ) {
      _id
      name
      visibility
      isActive
    }
  }
`

export const DELETE_WORKFLOW = gql`
  mutation deleteWorkflow($workflowId: String) {
    deleteWorkflow(_id: $workflowId)
  }
`

export const REORDER_WORKFLOW = gql`
  mutation reorderingWorkflow($workflows: [WorkflowFilter]) {
    reorderingWorkflow(input: $workflows) {
      _id
      order
    }
  }
`
