// Import Packages
import Colors from 'design/Colors'
import moment from 'moment'
import * as React from 'react'
import { Checkbox, Dropdown, Icon } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

import { TransactionWorkflow } from 'store/Transactions/Types'

// Import Components
import {
  deleteTask,
  editTransactionTask,
  loggedUser,
  SingleCheckBox,
  ToggleCheckBox,
  ToggleTaskStatus
} from './Workflow'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  CheckboxWrapper,
  DropDownPopup,
  Labels,
  Li,
  MemberProfile,
  Profile,
  StyledDropdown,
  StyledPopup,
  StyledStatusDropdown,
  StyleTask
  // StyledStatusDropdowns,
} from './Styled'

// Font Awesome Icons
import { faComment, faEnvelope, faHandshake } from '@fortawesome/pro-regular-svg-icons'
import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faHandshake, faComment, faPhone, faEnvelope])

const options = [
  { key: 1, text: 'New Task', value: 'New Task' },
  { key: 2, text: 'In Progress', value: 'In Progress' },
  { key: 3, text: 'Completed', value: 'Completed' }
]

let checked = false

export const Checked = (data: boolean) => {
  checked = !data
}

export const CustomHeaderCheckBox = () => {
  const onChange = () => {
    ToggleCheckBox()
  }
  return (
    <CheckboxWrapper>
      <Checkbox checked={checked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomCheckBox = ({ rowData }: { rowData: TransactionWorkflow }) => {
  const onChange = () => {
    SingleCheckBox(rowData._id)
  }
  return (
    <CheckboxWrapper>
      <Checkbox checked={rowData.checked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomTask = ({ rowData }: { rowData: TransactionWorkflow }) => {
  let dateTime: any
  if (rowData.taskTime === null) {
    dateTime = moment(rowData.dueDate).format('L')
  } else {
    dateTime = moment(moment(rowData.dueDate).format('L') + ' ' + moment(rowData.taskTime).format('LTS'))
  }
  let taskIcons
  if (rowData.type === 'Email') {
    taskIcons = (
      <FontAwesomeIcon
        icon={['far', 'envelope']}
        style={{
          color:
            moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed' ? 'red' : 'rgb(100, 100, 100)'
        }}
      />
    )
  }
  if (rowData.type === 'Call') {
    taskIcons = (
      <FontAwesomeIcon
        icon={['fas', 'phone']}
        style={{
          color:
            moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed' ? 'red' : 'rgb(100, 100, 100)'
        }}
      />
    )
  }
  if (rowData.type === 'Meeting') {
    taskIcons = (
      <FontAwesomeIcon
        icon={['far', 'handshake']}
        style={{
          color:
            moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed' ? 'red' : 'rgb(100, 100, 100)'
        }}
      />
    )
  }
  if (rowData.type === 'Sms') {
    taskIcons = (
      <FontAwesomeIcon
        icon={['far', 'sms']}
        style={{
          color:
            moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed' ? 'red' : 'rgb(100, 100, 100)'
        }}
      />
    )
  }
  if (rowData.type === 'Pass') {
    taskIcons = (
      <FontAwesomeIcon
        icon={['far', 'ticket']}
        style={{
          color:
            moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed' ? 'red' : 'rgb(100, 100, 100)'
        }}
      />
    )
  }
  return (
    <StyleTask
      style={{
        textDecoration: rowData.status === 'Completed' ? 'line-through' : '',
        color:
          moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed' ? 'red' : 'rgb(100, 100, 100)'
      }}
    >
      {taskIcons}
      <div>{rowData.name}</div>
    </StyleTask>
  )
}

export const CustomDueIn = ({ rowData }: { rowData: TransactionWorkflow }) => {
  let dateTime: any
  if (rowData.taskTime === null) {
    dateTime = moment(rowData.dueDate).format('L')
  } else {
    dateTime = moment(moment(rowData.dueDate).format('L') + ' ' + moment(rowData.taskTime).format('LTS'))
  }
  return (
    <div
      style={{
        fontStyle: 'italic',
        textDecoration: rowData.status === 'Completed' ? 'line-through' : '',
        color:
          moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed' ? 'red' : 'rgb(100, 100, 100)'
      }}
    >
      {moment().to(dateTime)}
    </div>
  )
}

export const CustomDueDate = ({ rowData }: { rowData: TransactionWorkflow }) => {
  return <div style={{ fontStyle: 'italic' }}>{rowData.dueDate}</div>
}

export const CustomDescription = ({ rowData }: { rowData: TransactionWorkflow }) => {
  let dateTime: any
  if (rowData.taskTime === null) {
    dateTime = moment(rowData.dueDate).format('L')
  } else {
    dateTime = moment(moment(rowData.dueDate).format('L') + ' ' + moment(rowData.taskTime).format('LTS'))
  }
  return (
    <div
      style={{
        textDecoration: rowData.status === 'Completed' ? 'line-through' : '',
        color:
          moment(moment(dateTime)).isBefore(moment()) && rowData.status !== 'Completed' ? 'red' : 'rgb(100, 100, 100)'
      }}
    >
      {rowData.description}
    </div>
  )
}

export const CustomStatus = ({ rowData }: { rowData: TransactionWorkflow }) => {
  let changeStatusAccess = false
  let background = ''
  let defaultValue = ''
  if (rowData.sharedWith && rowData.sharedWith.length > 0) {
    rowData.sharedWith.forEach((data: any) => {
      if (
        data._id === loggedUser._id ||
        loggedUser.role === 'ADMIN' ||
        loggedUser.role === 'MANAGER' ||
        loggedUser._id === loggedUser.transactionOwnerId
      ) {
        changeStatusAccess = true
      }
    })
  }

  switch (rowData.status) {
    case 'New Task':
      background = Colors.Orange250
      defaultValue = 'New Task'
      break
    case 'In Progress':
      background = Colors.Magenta60
      defaultValue = 'In Progress'
      break
    case 'Completed':
      background = Colors.Green700
      defaultValue = 'Completed'
      break
    default:
      background = Colors.Orange250
      defaultValue = 'New Task'
      break
  }
  const onHandleChange = (event: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    ToggleTaskStatus(rowData._id, value)
  }

  return (
    <div>
      {changeStatusAccess === false ? (
        <StyledStatusDropdown
          inline={true}
          onChange={onHandleChange}
          options={options}
          value={defaultValue}
          background={background}
          disabled={true}
        />
      ) : (
        <StyledStatusDropdown
          inline={true}
          onChange={onHandleChange}
          options={options}
          value={defaultValue}
          background={background}
        />
      )}
    </div>
  )
}

export const CustomActions = ({ rowData }: { rowData: TransactionWorkflow }) => {
  const deleteLeadTask = () => {
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: 'Remove selected task',
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(result => {
      if (result.value) {
        deleteTask(rowData._id)
        ConfirmAlert(Strings.kanbanView.deleted, 'Your task has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, 'Your task is safe', 'error')
      }
    })
  }
  const editTask = () => {
    editTransactionTask(rowData)
  }
  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              <Dropdown.Item text={Strings.leads.edit} onClick={editTask} />
              <Dropdown.Item text={Strings.leads.delete} onClick={deleteLeadTask} />
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  )
}

export const CustomAssignedTo = ({ rowData }: { rowData: TransactionWorkflow }) => {
  return (
    <div>
      {rowData.sharedWith && (
        <Profile>
          {rowData.sharedWith.map((items: any, index: number) => {
            while (index < 3) {
              return (
                <Li key={index}>
                  <StyledPopup
                    trigger={<MemberProfile key={index} background={items.profileImage} />}
                    content={`${items.firstName} ${items.lastName}`}
                  />
                </Li>
              )
            }
            return null
          })}
          {rowData.sharedWith.length > 3 ? (
            <Labels>
              <StyledPopup trigger={<div>...</div>} content={`+${rowData.sharedWith.length - 3}`} />
            </Labels>
          ) : null}
        </Profile>
      )}
    </div>
  )
}
