import * as React from 'react'

import Scrollable from 'shared/Scrollable'
import Toast, { serverToast } from 'shared/Toast/Toast'

import { isPlanAllowed } from 'shared/Billing/Queries'
import { getIdxPasses } from 'shared/Transaction/Queries'
import { uploadImagesViaIdx } from './Mutations'

import {
  Col,
  Container,
  Fa,
  Form,
  Image,
  InlineButton,
  StyledInput,
  Text,
} from './Styled'

import { Strings } from 'utils'

import { UserPassType } from 'app/Dashboard/MainContent/Passes/Types'
import { PropertyType } from 'app/Transactions/Details/Types'
import { PassStatusEnum } from 'store/Pass/Types'
import { ActionEnum, StatusEnum } from './Types'

import { faSearch } from '@fortawesome/pro-light-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faSearch])

interface Props {
  onAction: (action: ActionEnum, data: any) => void
  property: PropertyType
}

interface State {
  disabled: boolean
  listingId: string
  status: StatusEnum
}

class MlsUpload extends React.Component<Props, State> {
  public state = {
    disabled: false,
    listingId: '',
    status: StatusEnum.Inactive,
  }

  public componentDidMount = async () => {
    const {
      property: { mlsId }
    } = this.props

    let status: StatusEnum = StatusEnum.Inactive
    try {
      await isPlanAllowed('idx')
    } catch (e) {
      status = StatusEnum.Unpaid
    }

    const passes: UserPassType[] = await getIdxPasses()
    const hasActivePass = passes.length && passes.find((item: UserPassType) => item.settings.status === PassStatusEnum.Active)
    if (hasActivePass) {
      status = StatusEnum.Active
    }

    this.setState({
      listingId: mlsId || '',
      status,
    })
  }

  public render() {
    const {
      disabled,
      listingId,
      status,
    } = this.state

    return (
      <Container justify="center">
        <Scrollable>
          <Col padding={'1em 0 3em'}>
            <Form onSubmit={this.getIdxImages}>
              <Image max={180} src={Strings.transactionDashboard.mls} />
              {status === StatusEnum.Active && (
                <React.Fragment>
                  <Text>Get property images from your IDX feed.</Text>
                  <Text>
                    Enter the <strong>Property Listing ID</strong> below.
                  </Text>
                  <StyledInput
                    disabled={disabled}
                    label={
                      <InlineButton onClick={this.getIdxImages}>
                        <Fa icon={faSearch} />
                      </InlineButton>
                    }
                    labelPosition="right"
                    name="listingId"
                    onChange={this.handleChange}
                    placeholder="Listing ID"
                    value={listingId}
                  />
                </React.Fragment>
              )}
            </Form>
            {status === StatusEnum.Inactive && (
              <React.Fragment>
                <Text>Your account does not have an active IDX feed enabled.</Text>
                <Text>Please contact your administrator to set up this feature.</Text>
              </React.Fragment>
            )}
            {status === StatusEnum.Unpaid && (
              <React.Fragment>
                <Text>This feature is only available for paid subscription plans with an active IDX Pass.</Text>
                <Text>Please upgrade your account or contact your administrator to set this up.</Text>
              </React.Fragment>
            )}
          </Col>
        </Scrollable>
      </Container>
    )
  }

  private handleChange = async (e: any, { value }: any) => {
    this.setState({ listingId: value })
  }

  private getIdxImages = async () => {
    const { property, onAction } = this.props
    const { disabled, listingId } = this.state

    if (disabled) {
      return
    }

    let error = false
    const count = property.images.length
    this.setState({ disabled: true })
    onAction(ActionEnum.Start, null)

    let images: any = null
    try {
      images = await uploadImagesViaIdx({
        listingId,
        propertyId: property._id
      })
    } catch (e) {
      error = true
      serverToast(e)
    }

    const added = images ? images.length - count : 0
    if (!error && added >= 0) {
      Toast({
        message: `${added === 0 ? 'No' : added} new photo${added === 1 ? '' : 's'} were uploaded`,
        type: added > 0 ? 'success' : 'warning'
      })
    }
    this.setState({ disabled: false })
    onAction(ActionEnum.Complete, images)
  }
}

export default MlsUpload
