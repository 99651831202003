// Import Packages
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const StyledGrid = styled(Grid)`
  &.ui.grid.container {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding: 0;
    height: 100%;
    width: 100% !important;
  }
`

const StyledGridContainer = styled.td`
  padding: 0
`

const StyledContent = styled.tr`
  height: 100%;
  padding: 0px;
  margin-bottom: 115px;
  overflow-y: auto;
  position:relative;
`

const TitleColumn = styled(Grid.Column)`
  .ui.grid>.row>&.column{
    width: 19.3rem;
    background-color: ${Colors.Black855};
    padding: 0px;
    padding-left: 2rem;
    position: relative;
  }
  .segment {
    flex-grow: unset !important;
  }
`

const ChecklistValueColumn = styled(Grid.Column)`
  .ui.grid>.row>&.column{
    padding-left: 2.2rem;
    background-color: ${Colors.White1000};
    flex: 1;
  }
`

const ChecklistGridContainer = styled(Grid.Row)`
  .ui.grid>&.row {
    width:100%;
    padding: 0;
  }
`

const AbsoluteGrid = styled(Grid)`
  &.ui.grid {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

const AbsoluteGridRow = styled(Grid.Row)`
  .ui.grid>&.row {
    width:100%;
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
  }
`

const AbsoluteGridColumn = styled(Grid.Column)`
  .ui.grid>[class*="one column"].row>&.column {
    margin-bottom: 30px;
  }
`


export {
  AbsoluteGrid,
  AbsoluteGridColumn,
  AbsoluteGridRow,
  ChecklistGridContainer,
  ChecklistValueColumn,
  StyledContent,
  StyledGrid,
  StyledGridContainer,
  TitleColumn
}
