// Import Packages
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  text-align: center;

  .ui.button {
    font-size: 0.8rem;
    margin-bottom: 20px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }

  .ui.divider {
    border-top: 3px solid ${Colors.DarkBlue205} !important;
    border-bottom: 3px solid ${Colors.DarkBlue205} !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin: 0;
  }
`

const Title = styled.div`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 12px;
  color: ${Colors.DarkBlue205};
  font-family: none;
  font-size: 24px;
  margin-top: 3px;
  margin-bottom: 4px;

  span {
    font-weight: 600;
    color: ${Colors.DarkBlue205};
  }
`

const StyledGrid = styled(Grid)`
  img.ui.image {
    width: 80%;
  }
`

const Plans = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .fa-check-circle {
    color: ${Colors.Green90};
    margin-right: 6px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    color: ${Colors.Black500};
  }
`

const ButtomContainer = styled.div`
  background: ${Colors.white605};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .ui.button {
    font-size: 0.8rem;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

export {
  ButtomContainer,
  Container,
  Plans,
  Title,
  StyledGrid
}
