import styled from 'styled-components'

import { scrollbars, x } from 'design/Styled'

export const Container = styled.div`
  ${x};
  ${scrollbars};
  display: block;
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
`

export const Area = styled.div``

export const Item = styled.div``
