// Import Packages
import { debounce } from 'lodash'
import * as React from 'react'
import { Form, Icon, Input, Menu } from 'semantic-ui-react'

// Import Utils
import { Strings } from 'utils'

// Import Store Types, Actions and Reducers
import { FiltersData, FilterText, Options } from 'store/Teams/Types'

// Import Styled Components
import {
  CloseWrapper,
  Container,
  Filter,
  Filters,
  FiltersTitle,
  PopUpContent,
  StyledContainer,
  StyledForm,
  StyledMenu,
} from './Styled'

const filters = [
  { id: 'Team Name', name: 'Team Name', text: 'name' },
  { id: 'Office Name', name: 'Office Name', text: 'officeName' },
  { id: 'Leader Name', name: 'Leader Name', text: 'leaderName' },
]

interface Props {
  filtersContent: (id: string, name: string, text: string, searchData: FiltersData) => void
  closeAdvanceSearchModal: () => void
  filter: (data: FiltersData, state: Options) => void
  data: FiltersData
  filterText: FilterText[]
  selectedCity: Options
  selectedState: Options
}

interface State {
  activeItem: string
  searchData: FiltersData
  states: Options[]
  selectedState: Options
}

class ContactSearchModal extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    activeItem: '',
    searchData: {
      firstName: undefined,
      lastName: undefined,
      name: undefined,
      officeName: undefined,
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: [],
  }

  public async componentDidMount() {
    const { data, selectedState } = this.props
    this.setState({ searchData: data, selectedState })
  }

  public componentWillReceiveProps(nextProps: any) {
    const { filterText } = this.props
    const lastElement = nextProps.filterText.slice(-1)[0]
    if (lastElement !== undefined) {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: lastElement.content })
      }
    } else {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: '' })
      }
    }
  }

  public render() {
    const { activeItem, searchData } = this.state
    return (
      <StyledContainer>
        <CloseWrapper onClick={this.closeAdvanceSearchModal} />
        <PopUpContent>
          <Container>
            <Filters>
              <FiltersTitle>{Strings.advanceSearchModal.filters}</FiltersTitle>
              <StyledMenu vertical={true}>
                {
                  filters.map((items: any, index: number) => (
                    <Menu.Item
                      key={index}
                      name={items.name}
                      id={items.id}
                      text={items.text}
                      active={activeItem === items.name}
                      onClick={this.handleItemClick} />
                  ))
                }
              </StyledMenu>
            </Filters>
            <StyledForm size={'tiny'}>
              {
                activeItem === '' &&
                <Filter>
                  <Icon name='filter' />
                  <div>Select Filter</div>
                </Filter>
              }
              {
                activeItem === 'Team Name' &&
                <Form.Field
                  control={Input}
                  label='Team Name'
                  name='name'
                  value={searchData.name}
                  placeholder='Team Name'
                  onChange={this.handleChange} />
              }
              {
                activeItem === 'Office Name' &&
                <Form.Field
                  control={Input}
                  label='Office Name'
                  name='officeName'
                  value={searchData.officeName}
                  placeholder='Office Name'
                  onChange={this.handleChange} />
              }
              {
                activeItem === 'Leader Name' &&
                <div>
                  <Form.Field
                    control={Input}
                    label='First Name'
                    name='firstName'
                    value={searchData.firstName}
                    placeholder='First Name'
                    onChange={this.handleChange} />
                  <Form.Field
                    control={Input}
                    label='Last Name'
                    name='lastName'
                    value={searchData.lastName}
                    placeholder='Last Name'
                    onChange={this.handleChange} />
                </div>
              }
            </StyledForm>
          </Container>
        </PopUpContent>
      </StyledContainer>
    )
  }

  private closeAdvanceSearchModal = () => {
    const { closeAdvanceSearchModal } = this.props
    closeAdvanceSearchModal()
  }

  private handleItemClick = (e: React.SyntheticEvent<EventTarget>, { id, name, text }: any) => {
    const { filtersContent } = this.props
    const { searchData } = this.state
    this.setState({ activeItem: name })
    filtersContent(id, name, text, searchData)
  }

  private getTeamsBoardsData = async () => {
    const { filter } = this.props
    const { searchData, selectedState } = this.state

    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(() => {
      filter(searchData, selectedState)
    }, 1000)

    this.debounceJob()

  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { searchData } = this.state
    searchData[name] = value
    this.setState({ searchData }, () => this.getTeamsBoardsData())
  }
}

export default ContactSearchModal
