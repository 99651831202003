import * as React from 'react'
import { ApolloProvider } from 'react-apollo'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import client from 'queries/apollo'
import App from './app/App'
import * as serviceWorker from './registerServiceWorker'
import store from './store/Store'

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Route component={App} />
      </Provider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root') as HTMLElement
)
serviceWorker.unregister()
