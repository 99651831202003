import { Office, OfficeDetails, OfficeItemsDetails } from './Types'

export const addNewBoard = (
  state: Office,
  index: number,
  newData: OfficeDetails
): OfficeDetails[] => {
  const { data } = state
  const feeData = data.slice()
  feeData.splice(index, 0, newData)
  return [...feeData]
}

export const addNewCard = (
  state: Office,
  id: string,
  index: string,
  newData: OfficeItemsDetails
): Office['data'] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      const listLength = list.items.length
      const newList = list.items.slice()
      newList.splice(index ? index : listLength, 0, newData)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return feeData
}

export const setNewIcon = (
  state: Office,
  id: string,
  icon: string
): OfficeDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      return {
        ...list,
        icon: icon
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewDescription = (
  state: Office,
  id: string,
  description: string
): OfficeDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      return {
        ...list,
        description: description
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewBoardName = (
  state: Office,
  id: string,
  boardName: string
): OfficeDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      return {
        ...list,
        type: boardName
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const deleteCard = (
  state: Office,
  boardId: string,
  cardId: string
): OfficeDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === boardId) {
      const newList = list.items.slice()
      const listIndex = newList.findIndex((laneItem: any) => {
        return laneItem.id === cardId
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const restoreCard = (
  state: Office,
  id: string,
  index: string,
  newData: OfficeItemsDetails | null
): Office['data'] => {
  if (newData) {
    const { data } = state
    let feeData = data.slice()
    feeData = feeData.map((list: any) => {
      if (list.id === id) {
        const newList = list.items.slice()
        newList.splice(index, 0, newData)
        return {
          ...list,
          items: newList
        }
      } else {
        return list
      }
    })
    return feeData
  } else {
    return state.data
  }
}

export const changeStatus = (
  state: Office,
  boardId: string,
  cardId: string,
  status: string
): OfficeDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === boardId) {
      const newList = list.items.slice()
      const cardIndex = newList.findIndex((laneItem: any) => {
        return laneItem.id === cardId
      })
      const card = { ...newList[cardIndex] }
      card.status = status
      newList[cardIndex] = card
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}
