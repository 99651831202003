import { Reducer } from 'redux'
import Actions from 'store/Reports/ActionsTypes'
import {
  addNewMyReport,
  addNewTransaction,
  deleteMyReport,
  deleteTransaction
} from './ReduxUtil'
import { ReportState } from './Types'

const initialState: ReportState = {
  applyFilter: [],
  columnMetaData: [],
  dates: {},
  filter: {},
  generatedReportID: '',
  key: 0,
  module: 'MY REPORTS',
  pickData: [],
  report: '',
  reportData: [],
  resetData: false,
  selectedCity: {},
  selectedState: {},
  systemReport: 'TRANSACTIONS',
  toggle: {
    contact: false,
    lead: false,
    task: false
  },
  transactionData: []
}

const ReportReducer: Reducer<ReportState> = (
  state: ReportState = initialState,
  action
) => {
  switch (action.type) {
    // My Report Module
    case Actions.reportAddNewMyReport:
      return {
        ...state,
        reportData: addNewMyReport(
          state,
          action.payload.module,
          action.payload.newData
        )
      }
    case Actions.reportGetReportData:
      return {
        ...state,
        reportData: action.payload.data
      }
    case Actions.reportDeleteMyReport:
      return {
        ...state,
        reportData: deleteMyReport(
          state,
          action.payload.moduleId,
          action.payload.reportId
        )
      }

    // Transaction Module
    case Actions.reportAddNewTransaction:
      return {
        ...state,
        transactionData: addNewTransaction(
          state,
          action.payload.module,
          action.payload.newData
        )
      }
    case Actions.reportGetTransaction:
      return {
        ...state,
        transactionData: action.payload.data
      }
    case Actions.reportDeleteTransaction:
      return {
        ...state,
        transactionData: deleteTransaction(
          state,
          action.payload.moduleId,
          action.payload.reportId
        )
      }
    case Actions.viewReport:
      return {
        ...state,
        report: action.payload.report
      }
    case Actions.reportGetFilter:
      return {
        ...state,
        filter: action.payload.data
      }
    case Actions.reportGetPickData:
      return {
        ...state,
        pickData: action.payload.data
      }
    case Actions.reportGetApplyFilter:
      return {
        ...state,
        applyFilter: action.payload.data
      }
    case Actions.reportColumnMetaData:
      return {
        ...state,
        columnMetaData: action.payload.data
      }
    case Actions.reportModule:
      return {
        ...state,
        module: action.payload.data
      }
    case Actions.reportDatesFilters:
      return {
        ...state,
        dates: action.payload.data
      }
    case Actions.reportResetDataFilter:
      return {
        ...state,
        resetData: action.payload.reset
      }
    case Actions.reportChangeKey:
      return {
        ...state,
        key: action.payload.key
      }
    case Actions.reportChangeToggle:
      return {
        ...state,
        toggle: action.payload.toggle
      }
    case Actions.reportState:
      return {
        ...state,
        selectedState: action.payload.state
      }
    case Actions.reportCity:
      return {
        ...state,
        selectedCity: action.payload.city
      }
    case Actions.systemReport:
      return {
        ...state,
        systemReport: action.payload.module
      }
    case Actions.reportGeneratedID:
      return {
        ...state,
        generatedReportID: action.payload.id
      }
    default:
      return state
  }
}

export default ReportReducer
