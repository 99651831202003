import { Form, Input } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, x } from 'design/Styled'

interface Props {
  image?: string
  isLocked?: boolean
  isViewed?: boolean
}

const getThumbnail = ({ image, isLocked }: Props) => {
  const generatedImage = css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.transparent};
    box-shadow: none;
    &:after {
      display: block;
      font-family: 'Font Awesome 5 Free';
      content: '\f023';
      font-size: 1.3em;
      font-weight: 900;
      color: ${Colors.TextDark};
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  `
  if (isLocked) {
    return css`
      ${generatedImage};
      &:after {
        content: '\f023';
        color: ${Colors.TextDark};
        -webkit-font-smoothing: antialiased;
      }
    `
  }

  if (!image) {
    return css`
      ${generatedImage};
      &:after {
        content: '\f1c1';
        color: ${Colors.TextPrimary};
        cursor: pointer;
      }
    `
  }

  return css`
    background-image: url(${image});
  `
}

const getView = ({ isViewed }: Props) => {
  return !isViewed ? 'block' : 'none'
}

export const Container = styled.div`
  ${x};
  ${children};
  font-size: 12px;
  position: relative;
  padding: 0.5em 1em;
  background: ${Colors.ListOdd};
  border-bottom: 1px solid ${Colors.EmphasisLine};
  transition: background 200ms;

  &:hover {
    background: ${Colors.ListEven};
  }
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  cursor: pointer;

  &:before {
    display: ${getView};
    content: 'NEW';
    font: ${fonts.small.bold};
    background: ${Colors.ButtonDanger};
    color: ${Colors.TextLight};
    padding: 3px 7px;
    border-radius: 3px;
    z-index: 99;
    margin-right: 0.5em;
  }
`

export const Filename = styled.span``

export const Owner = styled.span`
  text-transform: capitalize;
`

export const StyledForm = styled(Form)`
  &.ui.form {
    margin-bottom: -3px;
  }
`

export const StyledInput = styled(Input)`
  &.ui.input {
    padding: 0;
    border: none;
    box-shadow: 0 2px 4px -4px ${Colors.ContainerShadow};

    &.ui.input.rp-inline-input > input {
      border: none;
      border-radius: 0;
      padding: 0;
    }
  }
`

export const Thumbnail = styled.div`
  display: block;
  position: relative;
  width: 24px;
  height: 28px;
  background-color: ${Colors.Container};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
  box-shadow: 0 0 3px 0px ${Colors.CardShadow};
  overflow: hidden;
  ${getThumbnail};
`
