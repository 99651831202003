import gql from 'graphql-tag'

export const MOVE_TRANSACTION = gql`
  mutation moveTransaction(
    $transactionId: String
    $transactionBoard: String
    $teamTransactionBoard: String
    $officeTransactionBoard: String
    $tenantTransactionBoard: String
    $transactionOrder: Int
    $teamTransactionOrder: Int
    $officeTransactionOrder: Int
    $tenantTransactionOrder: Int
  ) {
    transferTransaction(
      where: { _id: $transactionId }
      input: {
        transactionBoard: $transactionBoard
        teamTransactionBoard: $teamTransactionBoard
        officeTransactionBoard: $officeTransactionBoard
        tenantTransactionBoard: $tenantTransactionBoard
        transactionOrder: $transactionOrder
        teamTransactionOrder: $teamTransactionOrder
        officeTransactionOrder: $officeTransactionOrder
        tenantTransactionOrder: $tenantTransactionOrder
      }
    ) {
      _id
      propertyId {
        _id
        type {
          _id
          name
        }
        subType {
          _id
          name
        }
        address {
          _id
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        images {
          _id
          name
          url
          order
        }
        price
        mlsId
      }
      transactionId
      transactionEmail
      transactionRole
      status
      tags
      progress
      createdAt
      updatedAt
      owner {
        _id
        firstName
        lastName
        profileImage
        userName
      }
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
    }
  }
`

export const UPDATE_TRANSACTION_BOARD_ORDER = gql`
  mutation updateTransactionBoardOrder(
    $boardId: ID
    $sourceIndex: Int
    $destinationIndex: Int
  ) {
    updateTransactionBoardOrder(
      where: { _id: $boardId, boardOrder: $sourceIndex }
      input: { boardOrder: $destinationIndex }
    ) {
      _id
      name
      boardOrder
      icon {
        _id
        key
        imageUrl
      }
      description
      transactions {
        _id
        propertyId {
          _id
          type {
            _id
            name
          }
          subType {
            _id
            name
          }
          address {
            _id
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          images {
            _id
            name
            url
            order
          }
          price
          mlsId
        }
        transactionId
        transactionEmail
        transactionRole
        status
        tags
        progress
        createdAt
        updatedAt
        teamTransactionOrder
        officeTransactionOrder
        tenantTransactionOrder
        owner {
          _id
          firstName
          lastName
          profileImage
          userName
        }
        shareWith {
          _id
          firstName
          lastName
          userName
          role
          status
          type
          phones {
            type
            value
          }
          emails {
            type
            value
          }
          profileImage
        }
      }
    }
  }
`
