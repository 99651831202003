import * as React from 'react'

import {
  RouteComponentProps,
  withRouter
} from 'react-router-dom'

import TokenRequestForm from 'app/Onboarding/TokenRequestForm'
import LoadingIndicator from 'shared/LoadingIndicator'
import PasswordInputs from 'shared/PasswordInputs'
import Toast, { formToast, serverToast } from 'shared/Toast/Toast'

import { validatePassword } from 'shared/PasswordInputs/Validation'
import { resetPassword } from './Mutations'

import { confirmBg, kennel } from 'design/images/onboarding'
import { Routes } from 'utils'

import {
  Container,
  Content,
  Image,
  Return,
  Row,
  StyledButton,
  StyledForm,
  Title,
} from './Styled'

import { MethodEnum } from 'app/Onboarding/TokenMethodToggle/Types'
import { FormType as TokenRequestFormType } from 'app/Onboarding/TokenRequestForm/Types'
import {
  RouteParamsType,
  SubjectEnum,
} from 'app/Onboarding/Types'
import {
  FormErrorType,
  FormType,
} from './Types'

interface Props extends RouteComponentProps<RouteParamsType, {}> { }

interface State {
  form: FormType
  loading: boolean
  token: string
}

class ResetPassword extends React.Component<Props, State> {
  public state = {
    form: {
      email: '',
      errors: {} as FormErrorType,
      method: MethodEnum.Email,
      password: '',
      passwordConfirm: '',
      phone: '',
      subject: SubjectEnum.Reset,
    } as FormType,
    loading: false,
    token: '',
  }

  public componentDidMount = async () => {
    const { location: { state: params } } = this.props
    if (params && params.token) {
      this.setState({ token: params.token })
    } else if (params && params.email) {
      const { form } = this.state
      form.email = params.email
      this.setState({ form })
    }
  }

  public render() {
    const {
      form,
      form: { errors },
      loading,
      token,
    } = this.state

    return (
      <React.Fragment>
        {loading && <LoadingIndicator />}
        <Container
          image={confirmBg}
          max={620}
        >
          <Content>
            <Image
              src={kennel}
              max={200}
            />
            {token ? (
              <React.Fragment>
                <Title>Please create a password.</Title>
                <StyledForm onSubmit={this.submitPassword}>
                  <PasswordInputs
                    fluid={false}
                    onChange={this.handleChange}
                    password={form.password}
                    passwordError={!!errors.password}
                    passwordConfirm={form.passwordConfirm}
                    passwordConfirmError={!!errors.passwordConfirm}
                  />
                  <StyledButton
                    content="Continue"
                    size="large"
                    type="submit"
                  />
                </StyledForm>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Title>Reset your password</Title>
                <TokenRequestForm
                  form={form}
                  type={SubjectEnum.Reset}
                  onSubmit={this.moveToConfirmation}
                  onUpdate={this.handleFormUpdate}
                />
                <Row justify="center">
                  Remember password? <Return onClick={this.moveToLogin}>Login</Return>
                </Row>
              </React.Fragment>
            )}
          </Content>
        </Container>
      </React.Fragment>
    )
  }

  private handleFormUpdate = (update: TokenRequestFormType) => {
    const form = {
      ...this.state.form,
      ...update
    }
    this.setState({ form })
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>|null, { name, value }:any) => {
    const { form } = this.state
    form[name] = value
    form.errors[name] = ''
    this.setState({ form })
  }

  private submitPassword = async (e: React.FormEvent) => {
    e.preventDefault()
    this.setState({ loading: true })

    const { form, token } = this.state
    const validation = validatePassword(form)

    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form, loading: false })
      formToast(validation.errors)
      return
    }

    try {
      await resetPassword({
        newPassword: form.password,
        token,
      })
      Toast({
        message: 'Password successfully set',
        type: 'success'
      })
      this.moveToDashboard()
    } catch (error) {
      serverToast(error)
    }

    this.setState({ loading: false })
  }

  private moveToDashboard = () => {
    this.props.history.replace({
      pathname: Routes.primary.dashboard.path
    })
  }

  private moveToConfirmation = () => {
    const {
      form: {
        email,
        method,
        phone,
        subject,
      }
    } = this.state

    this.props.history.replace({
      pathname: Routes.onboarding.root + Routes.onboarding.confirm.path,
      state: {
        email,
        method,
        phone,
        subject,
      }
    })
  }

  private moveToLogin = () => {
    this.props.history.replace({
      pathname: Routes.onboarding.root,
    })
  }
}

export default withRouter(ResetPassword)
