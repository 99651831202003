// Import Packages
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

interface Props {
  background: string
  color: string
}

const StyledGrid = styled(Grid)`
  &.ui.grid>.row>[class*="four wide"].column {
    padding-right: 0;
    border: none;
    background: ${(props: Props) => props.color || '#4e5f80a8'};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1) !important;
    z-index: 1;
    position: relative;
  }

  &.ui.grid>.row>[class*="twelve wide"].column {
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.ui.grid>.row>[class*="twelve wide"].column, &.ui.celled.grid, &.ui.celled.grid>.row {
    border: none;
    box-shadow: none;
  }

  &.ui.celled.grid {
    margin: 0;
    height: calc(100vh - 81px);
    width: 100%;
    position: relative;
  }

  .wallpaperContainer {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top:0;
    left:0;
    object-fit: cover;
    width: 100vw;
    height: 100%;
    padding: 0 !important;
  }
`

const Container = styled.div`
  .block {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99999;
    }
`

export {
  Container,
  StyledGrid,
}
