// Import Packages
import { Dropdown, Segment } from 'semantic-ui-react'
// import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Header = styled.tr`
  height: 115px;
  background-color: ${Colors.Black800};
  padding-top: 0;
  padding-bottom: 0;
`

const Title = styled.div`
  width: 270px;
  min-width: 270px;
  max-width: 270px;
  background-color: ${Colors.Black855};
  padding: 0px;
  padding-left: 2rem;
  position: relative;
  justify-content: center;
  &:after {
    content: ' ';
    display: block;
    width: 70px;
    height: 136px;
    transform: rotate(30deg);
    position: absolute;
    right: -38px;
    top: -29px;
    background-color: ${Colors.Black855};
    z-index: 1;
  }
`

const XTitleText = styled(Segment)`
  &.ui.segment {
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin: 0;
    padding: 1em 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.Black500};
    text-transform: uppercase;
    margin-left: 1rem;
    align-self: center;
    z-index: 2;
    height: 100%;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
`

const StyledDropdown = styled(Dropdown)``

const EditorButton = styled.a`
  width: 100%;
  font-size: 80%;
  cursor: pointer;
`

const Cols = styled.td`
  background-color: ${Colors.White515};
  flex: 1;
  display: flex;
  width: 100% !important;
  height: 115px;
  padding: 0;
`

const DocumentsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-right: 65px;
  flex: 1;
  margin-top: 10px;
  margin-left: -5px;
`

export {
  Cols,
  DocumentsContainer,
  EditorButton,
  Header,
  StyledDropdown,
  Title,
  XTitleText,
}
