export interface TemplateOptionType {
  name: FlyerTemplateEnum
  component: any
}

export enum TemplateEnumType {
  Free = 'FREE',
  Pro = 'PRO'
}

export enum CategoryEnum {
  JustListed = 'Just Listed',
  OpenHouse = 'Open House',
  PriceReduced = 'Price Reduced',
  Pending = 'Pending',
  Sold = 'Sold'
}

export enum FlyerTemplateEnum {
  Template1 = 'Template1',
  Template2 = 'Template2',
  Template3 = 'Template3',
  Template4 = 'Template4',
  Template5 = 'Template5'
}

export interface ModalType {
  image?: boolean
}

export interface FlyerCategories {
  _id: string
  name: string
  flyerSubCategories: FlyerSubCategories[]
}

export interface FlyerSubCategories {
  _id: string
  name: string
  flyerTemplates: FlyerTemplates[]
}

export interface FlyerTemplates {
  _id: string
  title: string
  data: JSON
  type: string
}

export interface UserType {
  _id: string
  role: string
  profileImage: string
}
