// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Modal from 'shared/Modal'
import Toast from 'shared/Toast'
import Activity from './Activity'
import Notes from './Notes'

// Import Colors
import Colors from 'design/Colors'

import { createTaskNotes, deleteTaskNotes, editTaskNotes, updateShareWithTasks } from '../TaskMutations'
import { getTaskDetailsData } from '../TaskQueries'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Tasks/Actions'
import { Activities, ShareWith, TaskCardDetails, TaskNotes, UserType } from 'store/Tasks/Types'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Styled Components
import {
  Address,
  AddTaskName,
  AddTaskNameText,
  Container,
  ContentWrapper,
  Description,
  Divider,
  Due,
  Email,
  EventDescription,
  FlexWrapper,
  LeftPanel,
  People,
  PeopleName,
  PeopleStyledPopup,
  PersonContainer,
  RelatedTo,
  RightPanel,
  SharedWith,
  StyledIcon,
  StyledLabel,
  StyledTab,
  TaskDescription,
  Wrapper
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faPhone, faSms } from '@fortawesome/pro-light-svg-icons'
import { faMinus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone, faSms, faMinus])

interface StoreProps {
  closeModal: () => void
  setNewNotes: (note: TaskNotes, cardId: string) => void
  setUpdateNotes: (id: string, note: string) => void
  setDeleteNotes: (id: string) => void
  setTaskDetails: (data: TaskCardDetails) => void
  setNotesData: (data: TaskNotes) => void
  setTaskActivities: (data: Activities[]) => void
  refreshTasks?: () => void
  activeIndex: number
  taskDetail: TaskCardDetails
  notes: TaskNotes[]
  activities: Activities[]
  user: UserType
}

interface OwnProps {
  closeModal: () => void
  refreshTasks?: () => void
  activeIndex: number
  taskID: string
  user: UserType
}

type Props = StoreProps & OwnProps & RouteComponentProps<{}>

interface State {
  activeIndex: number
  activeIndexNone: boolean
  animate: boolean
  showPeopleDetails: boolean
  sharedWithActiveIndex: number
  loader: boolean
  modal: any
}

class ViewDetailsModal extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    activeIndexNone: false,
    animate: true,
    loader: false,
    modal: null,
    sharedWithActiveIndex: 0,
    showPeopleDetails: false
  }

  public async componentDidMount() {
    const { taskID, setTaskDetails, setNotesData, setTaskActivities } = this.props
    const response = await getTaskDetailsData(taskID)
    setTaskDetails(response)
    setNotesData(response.notes)
    setTaskActivities(response.activities)
  }

  public render() {
    const { activeIndex, notes, taskDetail, activities, user } = this.props
    const { activeIndexNone, animate, modal, loader } = this.state

    let backgroundIcon: any
    let backgroundColor: any
    switch (taskDetail.type) {
      case 'Email':
        backgroundIcon = 'envelope'
        backgroundColor = Colors.Red25
        break
      case 'Call':
        backgroundIcon = 'phone'
        backgroundColor = Colors.Blue50
        break
      case 'Meeting':
        backgroundIcon = 'handshake'
        backgroundColor = Colors.Purple50
        break
      case 'Sms':
        backgroundIcon = 'sms'
        backgroundColor = Colors.Pink70
        break
      case 'Pass':
        backgroundIcon = 'ticket'
        backgroundColor = Colors.Orange110
        break
    }

    const panes = [
      {
        menuItem: (
          <Menu.Item key="Activity">
            {Strings.tasks.activity}
            <StyledLabel circular={true}>{activities.length}</StyledLabel>
          </Menu.Item>
        ),
        render: () => <Activity activities={activities} />
      },
      {
        menuItem: (
          <Menu.Item key="Notes">
            {Strings.tasks.notes}
            <StyledLabel circular={true}>{notes.length}</StyledLabel>
          </Menu.Item>
        ),
        render: () => (
          <Notes
            loader={loader}
            addNewNotes={this.addNewNotes}
            notesData={notes}
            editNotes={this.editNotes}
            deleteNotes={this.deleteNotes}
            taskOwner={taskDetail.createdBy}
            user={user}
          />
        )
      }
    ]
    let toggle = true
    if (taskDetail.lead) {
      taskDetail.lead.shareWith.forEach((item: any) => {
        if (item._id === user._id) {
          toggle = false
        }
      })
    }
    const taskSharedWith =
      taskDetail &&
      taskDetail.sharedWith &&
      taskDetail.sharedWith.map((items: any, index: any) => {
        return (
          <PeopleStyledPopup
            key={index}
            trigger={
              items.status === 'Pending' ? (
                <People background={items.profileImage} style={{ opacity: 0.5 }} />
              ) : (
                  <People background={items.profileImage} />
                )
            }
            content={
              <PeopleName>
                <div style={{ fontWeight: 600 }}>
                  {items.firstName} {items.lastName}
                </div>
                <Email>
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      this.mailTo(items)
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'envelope']} />
                  </span>
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      this.callTo(items)
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'phone']} />
                  </span>
                  {((user.role !== 'GUEST' && user.role !== 'AGENT') || user._id === items._id) && (
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        this.viewTo(items)
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'eye']} />
                    </span>
                  )}
                  {(user.role === 'ADMIN' || user.role === 'MANAGER') &&
                    user._id !== items._id && (
                      <span
                        onClick={e => {
                          e.stopPropagation()
                          this.deleteTo(items)
                        }}
                      >
                        <FontAwesomeIcon icon={['fal', 'trash']} />
                      </span>
                    )}
                </Email>
              </PeopleName>
            }
            position="bottom center"
            hoverable={true}
          />
        )
      })
    return (
      <Modal
        content={
          <Container>
            {modal}
            {Object.keys(taskDetail).length > 0 && (
              <>
                <LeftPanel>
                  <Due>{moment(taskDetail.dueDate).format('LL')}</Due>
                  <AddTaskName>
                    <StyledIcon>
                      <FontAwesomeIcon icon={['fal', backgroundIcon]} style={{ color: backgroundColor }} />
                    </StyledIcon>
                    <AddTaskNameText>{taskDetail.name}</AddTaskNameText>
                    <TaskDescription>{taskDetail.description}</TaskDescription>
                    {taskDetail.lead && (
                      <RelatedTo>
                        <span style={{ fontWeight: 600 }}>{Strings.tasks.relatedTo}</span>
                        <Link
                          to={
                            toggle
                              ? user.role === 'ADMIN' || user.role === 'MANAGER'
                                ? Routes.leads.root + Routes.leads.details.path + `/${taskDetail.lead._id}?pool=1`
                                : ''
                              : Routes.leads.root + Routes.leads.details.path + `/${taskDetail.lead._id}?pool=0`
                          }
                        >
                          <Description>
                            {taskDetail.lead && `${taskDetail.lead.firstName} ${taskDetail.lead.lastName}`}
                          </Description>
                        </Link>
                      </RelatedTo>
                    )}
                    {taskDetail.transaction && (
                      <RelatedTo>
                        <span style={{ fontWeight: 600 }}>{Strings.tasks.relatedTo}</span>
                        <Link
                          to={
                            Routes.transactions.root +
                            `/${taskDetail.transaction._id}` +
                            Routes.transactions.documents.path
                          }
                        >
                          <EventDescription>
                            {taskDetail.transaction &&
                              taskDetail.transaction.owner &&
                              `${taskDetail.transaction.owner.firstName} ${taskDetail.transaction.owner.lastName}`}
                          </EventDescription>
                          <div style={{ textTransform: 'capitalize' }}>
                            <Address>
                              {taskDetail.transaction.propertyId && taskDetail.transaction.propertyId.address
                                ? `${taskDetail.transaction.propertyId.address.streetNumber} ${
                                taskDetail.transaction.propertyId.address.streetName
                                }`
                                : 'No Address Found'}
                            </Address>
                            <Address>
                              {taskDetail.transaction.propertyId && taskDetail.transaction.propertyId.address
                                ? `${taskDetail.transaction.propertyId.address.city}, ${
                                taskDetail.transaction.propertyId.address.state
                                } ${taskDetail.transaction.propertyId.address.zipCode}`
                                : 'No Address Found'}
                            </Address>
                          </div>
                        </Link>
                      </RelatedTo>
                    )}
                    <Divider />
                    <ContentWrapper>
                      <SharedWith>
                        <span>{Strings.leads.sharedWith}</span>
                      </SharedWith>
                      <FlexWrapper>
                        <PersonContainer onMouseLeave={() => this.showPeopleDetails(false)}>
                          <Wrapper>{taskSharedWith}</Wrapper>
                        </PersonContainer>
                      </FlexWrapper>
                    </ContentWrapper>
                  </AddTaskName>
                </LeftPanel>
                <RightPanel>
                  <StyledTab
                    menu={{ secondary: true, pointing: true }}
                    panes={panes}
                    activeIndex={activeIndexNone ? this.state.activeIndex : activeIndex}
                    onTabChange={this.handleTabChange}
                  />
                </RightPanel>
              </>
            )}
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        width={900}
      />
    )
  }

  private callTo = (item: ShareWith) => {
    window.location.href = `tel:${item.phones[0].value}`
  }

  private mailTo = (item: ShareWith) => {
    const modal = <ComposeModal onClose={this.closeModal} defaultEmail={item.userName} />
    this.setState({ modal })
  }

  private closeModal = () => {
    this.setState({ modal: null })
  }

  private showPeopleDetails = (showPeopleDetails: boolean) => {
    this.setState({
      showPeopleDetails
    })
  }

  private viewTo = (items: ShareWith) => {
    this.props.history.push({
      pathname: `${Routes.primary.profile.path}/${items._id}`
    })
  }

  private deleteTo = (item: ShareWith) => {
    const {
      taskDetail: { sharedWith, _id }
    }: any = this.props
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this user!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        if (sharedWith.length) {
          sharedWith.splice(sharedWith.findIndex((e: any) => e._id === item._id), 1)
        }
        await updateShareWithTasks({ _id, sharedWith: sharedWith.map((e: any) => e._id) })
        ConfirmAlert('Deleted!', 'Your user has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your user is safe', 'error')
      }
    })
  }

  private handleTabChange = (e: React.SyntheticEvent<EventTarget>, { activeIndex }: any) => {
    this.setState({ activeIndex, activeIndexNone: true })
  }

  private deleteNotes = async (id: string) => {
    const { setDeleteNotes, refreshTasks } = this.props
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this note!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        try {
          try {
            await deleteTaskNotes(id)
            setDeleteNotes(id)
            if (refreshTasks) {
              refreshTasks()
            }
            ConfirmAlert('Deleted!', 'Your user has been deleted.', 'success')
          } catch (error) {
            Toast({ message: error.message, type: 'error' })
          }
        } catch (error) {
          this.setState({ loader: false })
          Toast({ message: error.message, type: 'error' })
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your note is safe', 'error')
      }
    })
  }

  private editNotes = async (id: string, note: string) => {
    const { setUpdateNotes } = this.props
    this.setState({ loader: true })
    try {
      const response = await editTaskNotes(id, note)
      setUpdateNotes(response._id, response.description)
      if (response) {
        this.setState({ loader: false })
      }
      this.setState({ loader: false })
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }

  private addNewNotes = async (note: string) => {
    const { setNewNotes, taskDetail, refreshTasks } = this.props
    this.setState({ loader: true })
    try {
      const response: any = await createTaskNotes(taskDetail._id, note)
      if (refreshTasks) {
        refreshTasks()
      }
      this.setState({ loader: false })
      if (response) {
        setNewNotes(response, taskDetail._id)
      }
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }

  private closeSelf = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

const mapStateToProps = (state: AppState) => ({
  activities: state.tasks.activities,
  data: state.tasks.data,
  listData: state.tasks.listData,
  notes: state.tasks.notes,
  taskDetail: state.tasks.taskDetail
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setDeleteNotes: Actions.deleteNotes,
      setNewNotes: Actions.addNewNotes,
      setNotesData: Actions.getNotesData,
      setTaskActivities: Actions.getTaskActivities,
      setTaskDetails: Actions.getTaskDetails,
      setUpdateNotes: Actions.editNotes
    }
  )(ViewDetailsModal)
)
