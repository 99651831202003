// Import Packages
import * as React from 'react'

// Import Components
import Data from './DummyData'
import StyledCard from './StyledCard'

import { UserProfileType } from 'store/UserProfile/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  CardGroup,
  Infograph,
  StyledContainer
} from './Styled'

interface DataItem {
  address: string
  commercialProgress: number
  email: string
  image: string
  name: string
  phoneNumber: string
  residentialProgress: number
  updates: {
    contacts: {
      count: number
    },
    data: {
      count: number
    },
    users: {
      count: number
    }
  }
}
interface Props {
  userProfile: UserProfileType
  showEditButton: string
}
interface State {
  data: DataItem[]
}

export default class Teams extends React.Component<Props, State> {
  public state = {
    data: []
  }
  public componentDidMount() {
    this.setState({ data: Data })
  }

  public render() {
    const { userProfile } = this.props
    // const { data } = this.state
    return (
      <StyledContainer fluid={true}>
        {userProfile.teams &&
          <CardGroup>
            <StyledCard dataItem={userProfile.teams.leader} />
            {
              userProfile.teams.members.map((item: any, index: number) => {
                return <StyledCard dataItem={item} key={index} />
              })
            }
          </CardGroup>
        }
        {!userProfile.teams &&
          <Infograph backgroundImage={Strings.generalText.nothing} />
        }
      </StyledContainer>
    )
  }
}
