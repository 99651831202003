import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import {
  children,
  primaryStyledButton,
  x,
  y,
} from 'design/Styled'

export const Container = styled.section`
  ${y};
  width: 25%;
  max-width: 280px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 2;
`

export const Header = styled.header`
  ${x};
  ${children};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.8em;
  background-color: ${Colors.Blue800};
`

export const Title = styled.h2`
  font: ${fonts.medium.regular};
  color: ${Colors.Text};
  font-weight: 500;
  margin: 0;
`

export const Image = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 0 0 0.25em;
  transition: transform 200ms, opacity 100ms;
  cursor: pointer;
`

export const Actions = styled.div`
  ${x};
  width: auto;
`

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
  align-self: center;
`
