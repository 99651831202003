import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Input, Select } from 'semantic-ui-react'

import DatePicker from 'shared/DatePicker'
import Modal from 'shared/Modal'

// Tasks
import { setDueDate } from 'shared/Tasks/Mutations'
import * as TaskActions from 'store/Tasks/Actions'
import { TaskCardDetails } from 'store/Tasks/Types'

import { OptionType, QuickDateType } from './Types'

import { Strings } from 'utils'

import { Aside, Col, Container, Content, FormField, Image, Row, StyledButton } from './Styled'

// Font Awesome Icons
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { faCalendarAlt, faCalendarDay, faCalendarStar, faCalendarWeek } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCalendarAlt, faCalendarDay, faCalendarStar, faCalendarWeek])

interface Props {
  onClose: () => void
  taskData: TaskCardDetails
  updateTaskCard: (task: any) => void
}

interface State {
  dueDate: Date
  dueTime: number
  loading: boolean
  quickDates: QuickDateType[]
  showDatePicker: boolean
  timeOptions: OptionType[]
  transition: boolean
}

class SetDueDateModal extends React.Component<Props, State> {
  public state = {
    dueDate: new Date(),
    dueTime: 9,
    loading: false,
    quickDates: [] as QuickDateType[],
    showDatePicker: false,
    timeOptions: [] as OptionType[],
    transition: true
  }

  public componentWillMount = () => {
    const {
      taskData: { dueDate }
    } = this.props
    const now = new Date()

    this.handleDatePick(moment(dueDate || now))

    const quickDates = [
      {
        icon: 'calendar-day',
        moment: moment(now).startOf('hour'),
        name: 'Today'
      },
      {
        icon: 'calendar-week',
        moment: moment(now)
          .startOf('hour')
          .add(1, 'days'),
        name: 'Tomorrow'
      },
      {
        icon: 'calendar-star',
        moment: moment(now)
          .startOf('hour')
          .add(1, 'weeks'),
        name: 'Next Week'
      },
      {
        icon: 'calendar-alt',
        moment: moment(now)
          .startOf('hour')
          .add(1, 'months'),
        name: 'Next Month'
      }
    ]
    const timeOptions = Array(24)
      .fill(0)
      .map((item: any, index: number) => {
        const time: any = moment(now)
          .startOf('day')
          .add(index, 'hours')
        return {
          id: index,
          text: time.format('h:mm a'),
          value: index
        }
      })
    this.setState({
      quickDates,
      timeOptions
    })
  }

  public render() {
    const { dueDate, dueTime, loading, quickDates, showDatePicker, timeOptions, transition } = this.state
    return (
      <Modal
        content={
          <Container>
            <Aside>
              <Image src={Strings.tasks.dueDateImage} />
            </Aside>
            <Content>
              {quickDates.map((quick: QuickDateType, index: number) => (
                <Row className="interactive" key={index} onClick={() => this.handleDatePick(quick.moment)}>
                  <Col>
                    <Icon icon={['fal', quick.icon as IconName]} />
                    <span>{quick.name}</span>
                  </Col>
                  <Col>{quick.moment.format('MMM D, h:mm a')}</Col>
                </Row>
              ))}
              <Row className="input-row">
                <FormField
                  action={{
                    icon: (
                      <DatePicker
                        minDate={true}
                        date={new Date()}
                        onChangeDate={(date: any) => this.handleDatePick(moment(date))}
                        open={showDatePicker}
                        handleOpen={this.openDatePicker}
                        handleClose={this.closeDatePicker}
                      />
                    ),
                    onClick: this.openDatePicker
                  }}
                  control={Input}
                  label="Date"
                  name="date"
                  placeholder="Select Date"
                  onClick={this.openDatePicker}
                  value={moment(dueDate).format('MMM D, YYYY')}
                />
                <FormField
                  control={Select}
                  disabled={false}
                  label="Time"
                  name="time"
                  onChange={this.handleTimePick}
                  options={timeOptions}
                  placeholder="Select Time"
                  value={dueTime}
                />
              </Row>
              <Row>
                <StyledButton loading={loading} compact={true} size="small" onClick={this.setDueDate}>
                  Save
                </StyledButton>
                <StyledButton compact={true} size="small" onClick={this.closeSelf}>
                  Cancel
                </StyledButton>
              </Row>
            </Content>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
      />
    )
  }

  private openDatePicker = () => {
    this.setState({ showDatePicker: true })
  }

  private closeDatePicker = () => {
    this.setState({ showDatePicker: false })
  }

  private handleDatePick = (date: any) => {
    this.setState(prev => {
      if (date.hours() === 0 && prev.dueTime !== 0) {
        date.hours(prev.dueTime)
      }
      return {
        dueDate: date.toDate(),
        dueTime: date.hours()
      }
    })
    this.closeDatePicker()
  }

  private handleTimePick = (e: any, data: any) => {
    this.setState(prev => {
      const date = moment(prev.dueDate).hours(data.value)
      return {
        dueDate: date.toDate(),
        dueTime: data.value
      }
    })
  }

  private setDueDate = async () => {
    const {
      taskData: { _id },
      updateTaskCard
    } = this.props
    const { dueDate, dueTime } = this.state
    this.setState({ loading: true })

    const newDate = moment(dueDate)
      .hours(dueTime)
      .toDate()
    const result = await setDueDate(_id, newDate)
    if (!result) {
      this.setState({ loading: false })
      return
    }
    updateTaskCard(result)
    this.closeSelf()
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({
        transition: true
      })
      onClose()
    }, 300)
  }
}

export default connect(
  null,
  {
    updateTaskCard: TaskActions.updateCard
  }
)(SetDueDateModal)
