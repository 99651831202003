import client from 'queries/apollo'

import { UPDATE_CITY_V2 } from 'queries/graphql/City/Mutation'
import { UPDATE_STATE } from 'queries/graphql/States/Mutation'

export const updateCityTax = async (id: string, tax: number) => {
  const response: any = await client.mutate({
    mutation: UPDATE_CITY_V2,
    variables: {
      id,
      input: { tax: parseFloat(Number(tax).toFixed(2)) }
    }
  })

  return response.data.updateCity
}

export const updateStateTax = async (id: string, tax: number) => {
  const response: any = await client.mutate({
    mutation: UPDATE_STATE,
    variables: {
      id,
      input: { tax: parseFloat(Number(tax).toFixed(2)) }
    }
  })

  return response.data.updateState
}
