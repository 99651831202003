import { Card, Dropdown, Form } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { children, x } from 'design/Styled'

interface Props {
  background: string
}

export const Container = styled.div`
  background: ${Colors.Grey110};

  .ui.loader {
    top: 50%;
    left: 72%;
  }
`

export const Title = styled.h1`
  color: ${Colors.TextPrimary};
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const StyledForm = styled(Form)`
  ${x};
  ${children};
  &.ui.form {
    font-size: 11px;
  }
`

export const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    border-radius: 0;
    min-width: 0;
  }
`

export const Label = styled.span``

export const TopPanel = styled.div`
  display: flex;
  padding: 40px;
  align-items: center;
  padding-top: 0;

  h2 {
    color: ${Colors.DarkBlue205};
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 0;
  }
`

export const BottomPanel = styled.div`
  display: flex;
  padding: 40px;
  align-items: flex-start;
  padding-top: 0;

  h2 {
    color: ${Colors.DarkBlue205};
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 0;
  }
`

export const StyledCard = styled(Card)`
  &.ui.card,
  .ui.cards > .card {
    border-radius: 0;
    box-shadow: #e7ebef -2px 0px 13px 2px;
  }

  &.ui.card > .content,
  .ui.cards > .card > .content {
    text-align: center;
  }

  &.ui.card > .content > .header:not(.ui),
  .ui.cards > .card > .content > .header:not(.ui) {
    font-size: 30px;
    color: ${(props: Props) => props.background || props.background};
  }

  &.ui.card .meta,
  .ui.cards > .card .meta {
    font-weight: 600;
    color: ${Colors.Black500};
    font-size: 12px;
  }

  .ui.small.image {
    width: 60px;
  }
`

export const ContactLeftPanel = styled.div`
  width: 100%;

  .fa-user-plus {
    width: 30px;
    height: 30px;
  }

  .fa-users {
    width: 30px;
    height: 30px;
  }

  .fa-user {
    width: 30px;
    height: 30px;
    margin-top: 10px;
  }

  .contact {
    font-size: 14px;
    font-weight: 600;
    color: ${Colors.DarkBlue200};
    padding-left: 15px;
    display: flex;
    align-items: center;

    div:nth-child(2) {
      margin-left: 22px;
    }
  }

  .referral {
    font-size: 14px;
    font-weight: 600;
    color: ${Colors.DarkBlue200};
    padding-left: 15px;
    display: flex;
  }

  .referral1 {
    margin-right: 30px;
    margin-bottom: 98px;
    /* margin-top: -10px; */
  }
`

export const TaskLeftPanel = styled.div`
  width: 50%;

  .fa-tasks {
    width: 20px;
    height: 20px;
  }

  .fa-money-bill {
    width: 30px;
    height: 30px;
  }

  .task {
    font-size: 14px;
    font-weight: 600;
    color: ${Colors.DarkBlue200};
    padding-left: 15px;
    display: flex;

    div:nth-child(2) {
      margin-left: 10px;
    }
  }
`

export const TaskRightPanel = styled.div`
  width: 50%;
  text-align: -webkit-center;
`

export const DescriptionWrapper = styled.div`
  margin-top: 15px;
  text-align: center;

  img.ui.image {
    display: -webkit-inline-box;
  }

  .trend {
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
    color: ${Colors.Black500};
  }

  .month {
    font-size: 10px;
    color: ${Colors.Black500};
  }
`

export const Transaction = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const Task = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const PastDue = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${Colors.Black500};
  margin-bottom: 12px;
  text-align: center;
  cursor: pointer;

  .fa-calendar-alt {
    width: 18px;
    height: 18px;
  }
`

export const Upcoming = styled.div`
  font-weight: 600;
  color: ${Colors.Black500};
  font-size: 12px;
  text-align: center;
  cursor: pointer;
`

export const Contact = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
`
