import { ActionCreator } from 'redux'
import Actions from 'store/Users/ActionsTypes'
import {
  AddNewBoardEvent,
  AddNewCardEvent,
  DeleteCard,
  Filter,
  GetBoardDataEvent,
  ResetCardData,
  RestoreCard,
  SetNewBoardName,
  SetNewDescription,
  SetNewIcon,
  ShowHidePopupEvent,
  UserFilter,
  UserGroup,
  UserSearchData,
  UserSerachLoader,
  UsersItem
} from './Types'

export const addNewBoard: ActionCreator<AddNewBoardEvent> = (
  index: number,
  newData: UserGroup
) => ({
  payload: {
    index,
    newData
  },
  type: Actions.addNewBoard
})

export const getBoardData: ActionCreator<GetBoardDataEvent> = (
  data: UserGroup[]
) => ({
  payload: {
    data
  },
  type: Actions.getBoardData
})

export const addNewCard: ActionCreator<AddNewCardEvent> = (
  id: string,
  index: string,
  newData: UsersItem
) => ({
  payload: {
    id,
    index,
    newData
  },
  type: Actions.addNewCard
})

export const showAddUserPopup: ActionCreator<ShowHidePopupEvent> = () => ({
  type: Actions.showAddUserPopup
})

export const hideAddUserPopup: ActionCreator<ShowHidePopupEvent> = () => ({
  type: Actions.hideAddUserPopup
})

export const showImportUsersPopup: ActionCreator<ShowHidePopupEvent> = () => ({
  type: Actions.showImportUsersPopup
})

export const hideImportUsersPopup: ActionCreator<ShowHidePopupEvent> = () => ({
  type: Actions.hideImportUsersPopup
})

export const setNewIcon: ActionCreator<SetNewIcon> = (
  id: string,
  icon: string
) => ({
  payload: {
    icon,
    id
  },
  type: Actions.setNewIcon
})

export const setNewDescription: ActionCreator<SetNewDescription> = (
  id: string,
  description: string
) => ({
  payload: {
    description,
    id
  },
  type: Actions.setNewDescription
})

export const setNewBoardName: ActionCreator<SetNewBoardName> = (
  id: string,
  boardName: string
) => ({
  payload: {
    boardName,
    id
  },
  type: Actions.setNewBoardName
})

export const deleteCard: ActionCreator<DeleteCard> = (
  boardId: string,
  cardId: string
) => ({
  payload: {
    boardId,
    cardId
  },
  type: Actions.deleteCard
})

export const resetCardData: ActionCreator<ResetCardData> = () => ({
  type: Actions.resetCardData
})

export const restoreCard: ActionCreator<RestoreCard> = () => ({
  type: Actions.restoreCard
})

export const filter: ActionCreator<UserFilter> = (data: Filter) => ({
  payload: {
    data
  },
  type: Actions.userFilter
})

export const searchLoader: ActionCreator<UserSerachLoader> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.userSearchLoader
})

export const searchDataFilter: ActionCreator<UserSearchData> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.userSearchData
})
