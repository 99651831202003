// Import Packages
import { Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const ExtendedInput = styled(Input)`
  width: 100%;
  margin: 4px 6px;
`

const ExtendedForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const FlexWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;

  .ui.input {
    margin: 6px;
    width: 100%;
  }

  @media (max-width: 414px) {
    flex-wrap: wrap;
  }
`

const FullPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${Colors.White1000};
`

const ContentWrapper = styled.div`
  width: 98%;
  max-width: 1260px;
  margin: 0 auto;
  height: 100%;
`

export {
  ContentWrapper,
  ExtendedInput,
  ExtendedForm,
  FlexWrapper,
  FullPageWrapper
}
