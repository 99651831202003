// Import Packages
import * as React from 'react'

import { Options, UserType } from 'store/Contacts/Types'

// Import Styled Components
import {
  Action,
  AddButtonContainer,
  Align,
  AlignContainer,
  Calendar,
  Container,
  CustomButton,
  Filter,
  StyledCheckbox,
  StyledDropdown,
  Trello,
  TrelloContainer
} from 'shared/StyledBoard/Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus])

interface Props {
  showCalendarIcon?: boolean
  showTaskSearchModal?: boolean
  toggleStatus: boolean
  user: UserType
  addList: () => void
  showDefaultView: (data: boolean) => void
  deleteContacts: () => void
  changeToggle: () => void
}

interface State {
  show: boolean
  searchText: string
  showSearchModal: boolean
  sortOrder: number
  selectedOption: Options
}

class StyledBoard extends React.Component<Props, State> {
  public state = {
    searchText: '',
    selectedOption: {} as Options,
    show: true,
    showSearchModal: false,
    sortOrder: 1
  }

  public options = [
    {
      key: '0',
      text: 'Choose an Action',
      value: 'Choose'
    },
    {
      key: '1',
      text: 'Delete Contact(s)',
      value: 'Delete'
    }
  ]

  public filterOptions = [
    {
      key: '0',
      text: 'Choose an Action',
      value: 'Choose'
    },
    {
      key: '1',
      text: 'My Contacts',
      value: 'myContacts'
    },
    {
      key: '2',
      text: 'All',
      value: 'All'
    }
  ]

  public render() {
    const { showCalendarIcon, toggleStatus, user } = this.props
    const { show } = this.state
    return (
      <Container>
        <Filter style={{ visibility: user.role !== 'AGENT' && user.role !== 'GUEST' ? 'visible' : 'hidden' }}>
          <span style={{ fontWeight: !toggleStatus ? 600 : 400 }}>My Contacts</span>
          <StyledCheckbox className="tester" checked={toggleStatus} toggle={true} onChange={this.toggleStatus} />
          <span style={{ fontWeight: toggleStatus ? 600 : 400 }}>All Contacts</span>
        </Filter>
        <CustomButton>
          {showCalendarIcon && <Calendar />}
          <AlignContainer>
            <Align onClick={this.showListView} />
          </AlignContainer>
          <TrelloContainer>
            <Trello onClick={this.showKanbanView} />
          </TrelloContainer>
          {!show &&
            (user.role === 'ADMIN' || user.role === 'MANAGER') && (
              <Action>
                {' '}
                <StyledDropdown
                  inline={true}
                  options={this.options}
                  placeholder="Choose an Action"
                  onChange={this.handleChange}
                />
              </Action>
            )}
          {!show &&
            user.role !== 'GUEST' && (
              <AddButtonContainer onClick={this.props.addList}>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </AddButtonContainer>
            )}
        </CustomButton>
      </Container>
    )
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    if (value !== 'Choose') {
      const { deleteContacts } = this.props
      deleteContacts()
    }
  }

  private toggleStatus = () => {
    const { changeToggle } = this.props
    changeToggle()
  }

  private showListView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: false
      },
      () => showDefaultView(this.state.show)
    )
  }

  private showKanbanView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: true
      },
      () => showDefaultView(this.state.show)
    )
  }
}

export default StyledBoard
