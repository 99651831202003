// Import Packages
import * as React from 'react'
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'

// Import Components
import KanbanLaneContent from './KanbanLaneContent'
import KanbanLaneFooter from './KanbanLaneFooter'
import KanbanLaneHeader from './KanbanLaneHeader'

// Import Styled Components
import { KanbanWrapper, Price } from './Styled'

// Font Awesome Icons
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faDollarSign])

interface Props {
  addNewCard: (id: string, index: string) => void
  setNewIcon?: (id: string, icon: string) => void
  setNewDescription?: (id: string, description: string) => void
  setNewBoardName?: (id: string, boardName: string) => void
  navigatePage?: (boardId: string, singleSkip: number) => void
  CardComponent: React.ReactType
  ActionComponent: React.ReactType
  icons: any[]
  price?: number
  appearance?: boolean
  item?: any
  index: number
  color: string
  counterIcon?: string
  counterItems?: number
}

interface State {
  colorSet: string
}

export default class Lane extends React.Component<Props, State> {
  public state = {
    colorSet: ''
  }

  public componentDidMount() {
    const { color } = this.props
    this.setState({ colorSet: color })
  }

  public render() {
    const {
      item,
      CardComponent,
      ActionComponent,
      icons,
      appearance,
      index,
      price,
      color,
      counterIcon,
      counterItems
    } = this.props
    const {
      item: { pagination },
      navigatePage
    } = this.props
    const { colorSet } = this.state
    return (
      <Draggable key={item._id} draggableId={item._id} index={index}>
        {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
          <KanbanWrapper
            innerRef={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            color={colorSet ? colorSet : color}
          >
            <KanbanLaneHeader
              icon={item.icon}
              description={item.description}
              title={item.name}
              color={colorSet ? colorSet : color}
              boardId={item._id}
              itemsLength={(pagination && pagination.count) || item.items.length}
              setColor={this.setColor}
              ActionComponent={ActionComponent}
              icons={icons}
              counterIcon={counterIcon}
              counterItems={counterItems}
              setNewIcon={this.setNewIcon}
              setNewDescription={this.setNewDescription}
              setNewBoardName={this.setNewBoardName}
              appearance={appearance}
              showAddButton={item.showAddButton}
              showAppearance={item.showAppearance}
            />
            {price && (
              <Price color={colorSet ? colorSet : color}>
                <FontAwesomeIcon icon={['far', 'dollar-sign']} />
                {Number(price).toLocaleString()}
              </Price>
            )}
            <KanbanLaneContent
              content={item.items}
              CardComponent={CardComponent}
              droppableId={item._id}
              boardId={item._id}
              addNewCard={this.addNewCard}
              showAddButton={item.showAddButton}
              color={colorSet ? colorSet : color}
              itemsLength={item.items.length}
            />
            <KanbanLaneFooter
              color={colorSet ? colorSet : color}
              itemsLength={item.items.length}
              boardId={item._id}
              addNewCard={this.addNewCard}
              showAddButton={item.showAddButton}
              pagination={pagination}
              navigatePage={navigatePage}
            />
          </KanbanWrapper>
        )}
      </Draggable>
    )
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    if (setNewBoardName) {
      setNewBoardName(id, boardName)
    }
  }

  private setColor = (color: string) => {
    this.setState({ colorSet: color })
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    if (setNewDescription) {
      setNewDescription(id, description)
    }
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    if (setNewIcon) {
      setNewIcon(id, icon)
    }
  }

  private addNewCard = (id: string, index: string) => {
    const { addNewCard } = this.props
    addNewCard(id, index)
  }
}
