// Import Packages
import { set } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Accordion, Form, Input } from 'semantic-ui-react'

// Import Components
import DatePicker from 'shared/DatePicker'
import ContactContent from './FilterContent/ContactContent'
import LeadContent from './FilterContent/LeadContent'
import OfficeContent from './FilterContent/OfficeContent'
import TaskContent from './FilterContent/TaskContent'
import TeamContent from './FilterContent/TeamContent'
import TransactionContent from './FilterContent/TransactionContent'
import UserContent from './FilterContent/UserContent'
import {
  contactMockData,
  leadMockData,
  officeMockData,
  taskMockData,
  teamMockData,
  transactionMockData,
  userMockData
} from './FiltersMockData'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import { Toggle } from 'store/Reports/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import {
  AccordionContainer,
  Container,
  Infograph,
  MyLeads,
  Reports,
  StyledAccordion,
  StyledCheck,
  Text,
  Title,
  TriangleLeft,
  TriangleRight
} from './Styled'

// Font Awesome Icons
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCaretDown])

interface StoreProps {
  setApplyFilter: (data: any) => void
  setFilter: (data: any) => void
  setChangeToggle: (data: Toggle) => void
  systemReport: string
  resetData: boolean
  module: string
  filter: any
  toggle: Toggle
}

interface OwnProps {
  activeTab: (tabNumber: number) => void
  monthlySummary: string
  tabActive: string
}

type Props = StoreProps & OwnProps

interface State {
  activeIndex: number
  filtersData: any
  checkBox: boolean
  openFromDate: boolean
  openToDate: boolean
  fromDate: string
  toDate: string
  setModule: string
  users: object
}

class PickData extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    checkBox: false,
    dates: {},
    filtersData: [],
    fromDate: '',
    openFromDate: false,
    openToDate: false,
    setModule: '',
    toDate: '',
    users: {}
  }

  public componentDidMount() {
    const { module, setChangeToggle, toggle, systemReport } = this.props
    let data: any
    switch (module === 'MY REPORTS' ? systemReport : module) {
      case 'TRANSACTIONS':
        data = transactionMockData
        break
      case 'LEADS':
        data = leadMockData
        break
      case 'CONTACTS':
        data = contactMockData
        break
      case 'USERS':
        data = userMockData
        break
      case 'TEAMS':
        data = teamMockData
        break
      case 'OFFICES':
        data = officeMockData
        break
      case 'TASKS':
        data = taskMockData
        break
      default:
        data = []
        break
    }
    this.setState({ filtersData: data, fromDate: '', toDate: '', setModule: module })
    const newData = {
      contact: toggle.contact,
      lead: toggle.lead,
      task: toggle.task
    }
    setChangeToggle(newData)
  }

  public async componentWillReceiveProps(nextProps: any) {
    const { module, setChangeToggle } = this.props
    const users: any = await getLoggedInUser({ fromCache: true })
    this.setState({ users })
    if (module !== nextProps.module) {
      let data: any
      switch (nextProps.module ? nextProps.module : module) {
        case 'TRANSACTIONS':
          data = transactionMockData
          break
        case 'LEADS':
          data = leadMockData
          break
        case 'CONTACTS':
          data = contactMockData
          break
        case 'USERS':
          data = userMockData
          break
        case 'TEAMS':
          data = teamMockData
          break
        case 'OFFICES':
          data = officeMockData
          break
        case 'TASKS':
          data = taskMockData
          break
        default:
          data = []
          break
      }
      const newData = {
        contact: false,
        lead: false,
        task: false
      }
      setChangeToggle(newData)
      this.setState({
        filtersData: data,
        fromDate: '',
        toDate: '',
        setModule: nextProps.module ? nextProps.module : module
      })
    } else {
      let data: any
      switch (nextProps.systemReport) {
        case 'TRANSACTIONS':
          data = transactionMockData
          break
        case 'LEADS':
          data = leadMockData
          break
        case 'CONTACTS':
          data = contactMockData
          break
        case 'USERS':
          data = userMockData
          break
        case 'TEAMS':
          data = teamMockData
          break
        case 'OFFICES':
          data = officeMockData
          break
        case 'TASKS':
          data = taskMockData
          break
        default:
          return
      }
      this.setState({
        filtersData: data,
        fromDate: '',
        toDate: '',
        setModule: nextProps.module ? nextProps.module : module
      })
    }
    if (nextProps.resetData) {
      this.setState({ toDate: '', fromDate: '' })
    }
  }

  public render() {
    const { tabActive, toggle, filter, systemReport } = this.props
    const { activeIndex, filtersData, openFromDate, openToDate, setModule, users } = this.state
    const filters = filtersData.map((items: any, index: any) => (
      <StyledAccordion key={index}>
        <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
          <span className="accordion">{items.filterCategory}</span>
          {activeIndex === index ? (
            <FontAwesomeIcon icon={['fas', 'caret-down']} />
          ) : (
            <FontAwesomeIcon icon={['fas', 'caret-right']} />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index}>
          {(setModule === 'TRANSACTIONS' || systemReport === 'TRANSACTIONS') && (
            <TransactionContent categoryId={items._id} report={items.filterCategory} />
          )}
          {(setModule === 'LEADS' || systemReport === 'LEADS') && (
            <LeadContent categoryId={items._id} report={items.filterCategory} />
          )}
          {(setModule === 'CONTACTS' || systemReport === 'CONTACTS') && (
            <ContactContent categoryId={items._id} report={items.filterCategory} />
          )}
          {(setModule === 'USERS' || systemReport === 'USERS') && (
            <UserContent categoryId={items._id} report={items.filterCategory} />
          )}
          {(setModule === 'TEAMS' || systemReport === 'TEAMS') && (
            <TeamContent categoryId={items._id} report={items.filterCategory} />
          )}
          {(setModule === 'OFFICES' || systemReport === 'OFFICES') && (
            <OfficeContent categoryId={items._id} report={items.filterCategory} />
          )}
          {(setModule === 'TASKS' || systemReport === 'TASKS') && (
            <TaskContent categoryId={items._id} report={items.filterCategory} />
          )}
        </Accordion.Content>
      </StyledAccordion>
    ))
    let key: any
    let my: any
    let all: any
    switch (setModule !== 'MY REPORTS' ? setModule : systemReport) {
      case 'LEADS':
        key = toggle.lead
        my = 'My Leads'
        all = 'Lead Pool'
        break
      case 'CONTACTS':
        key = toggle.contact
        my = 'My Contacts'
        all = 'All Contacts'
        break
      default:
        key = toggle.task
        my = 'My Tasks'
        all = 'All Tasks'
        break
    }
    return (
      <Container>
        <Title onClick={this.activeTab} border={tabActive}>
          <Text>
            <i>{Strings.reports.number2}</i>
            {Strings.reports.applyYourFilters}
          </Text>
          <TriangleLeft />
          <TriangleRight />
        </Title>
        <Reports>
          {(users[`role`] === 'ADMIN' || users[`role`] === 'MANAGER') &&
            (setModule === 'LEADS' ||
              setModule === 'CONTACTS' ||
              setModule === 'TASKS' ||
              systemReport === 'LEADS' ||
              systemReport === 'CONTACTS' ||
              systemReport === 'TASKS') && (
              <MyLeads>
                <span style={{ fontWeight: !key ? 600 : 400 }}>{my}</span>
                <StyledCheck className="tester" checked={key} toggle={true} onChange={this.toggleStatus} />
                <span style={{ fontWeight: key ? 600 : 400 }}>{all}</span>
              </MyLeads>
            )}
          {filtersData.length === 0 ? (
            <Infograph background={Strings.generalText.nothing} />
          ) : (
            <AccordionContainer>
              <Form
                size={'tiny'}
                style={{
                  marginBottom: 20,
                  marginLeft: 20,
                  width: '41%'
                }}
              >
                <Form.Field
                  control={Input}
                  name="fromDate"
                  value={filter[`from`] ? moment(filter[`from`]).format('L') : ''}
                  label="From Date"
                  onClick={() => this.handleOpen('fromDate')}
                  autoComplete="off"
                  action={{
                    icon: (
                      <DatePicker
                        onChangeDate={this.onFormDate}
                        open={openFromDate}
                        maxDate={true}
                        date={
                          filter[`to`]
                            ? moment(filter[`to`])
                                .add(-2, 'days')
                                .format('L')
                            : ''
                        }
                        handleOpen={() => this.handleOpen('fromDate')}
                        handleClose={this.handleClose}
                      />
                    ),
                    onClick: () => this.handleOpen('fromDate')
                  }}
                  placeholder="From Date"
                />
                <Form.Field
                  control={Input}
                  name="toDate"
                  value={
                    filter[`to`]
                      ? moment(filter[`to`])
                          .add(-2, 'days')
                          .format('L')
                      : ''
                  }
                  label="To Date"
                  onClick={() => this.handleOpen('toDate')}
                  autoComplete="off"
                  action={{
                    icon: (
                      <DatePicker
                        onChangeDate={this.onToDate}
                        minDate={true}
                        date={filter[`from`] ? moment(filter[`from`]).format('L') : ''}
                        open={openToDate}
                        handleOpen={() => this.handleOpen('toDate')}
                        handleClose={this.handleClose}
                      />
                    ),
                    onClick: () => this.handleOpen('toDate')
                  }}
                  placeholder="To Date"
                />
              </Form>
              {filters}
            </AccordionContainer>
          )}
        </Reports>
      </Container>
    )
  }

  private toggleStatus = () => {
    const { setChangeToggle, toggle, systemReport } = this.props
    const { setModule } = this.state
    let newData: any
    switch (setModule !== 'MY REPORTS' ? setModule : systemReport) {
      case 'LEADS':
        newData = {
          contact: toggle.contact,
          lead: !toggle.lead,
          task: toggle.task
        }
        break
      case 'CONTACTS':
        newData = {
          contact: !toggle.contact,
          lead: toggle.lead,
          task: toggle.task
        }
        break
      default:
        newData = {
          contact: toggle.contact,
          lead: toggle.lead,
          task: !toggle.task
        }
        break
    }
    setChangeToggle(newData)
  }

  private onToDate = (date: Date) => {
    const { setFilter, filter } = this.props
    const setFromDate = set(
      filter,
      'to',
      moment(date)
        .add(2, 'days')
        .format('L')
    )
    setFilter(setFromDate)
    this.setState({ toDate: moment(date).format('L') })
    this.handleClose()
  }

  private onFormDate = (date: Date) => {
    const { setFilter, filter } = this.props
    const setFromDate = set(filter, 'from', moment(date).format('L'))
    setFilter(setFromDate)
    this.setState({ fromDate: moment(date).format('L') })
    this.handleClose()
  }

  private handleClose = () => {
    this.setState({ openFromDate: false, openToDate: false })
  }

  private handleOpen = (open: string) => {
    if (open === 'toDate') {
      this.setState({ openToDate: true })
    }
    if (open === 'fromDate') {
      this.setState({ openFromDate: true })
    }
  }

  private activeTab = () => {
    const { activeTab } = this.props
    activeTab(2)
  }

  private handleClick = (e: React.SyntheticEvent<EventTarget>, titleProps: any) => {
    const { checkBox } = this.state
    if (!checkBox) {
      const { index } = titleProps
      const { activeIndex } = this.state
      const newIndex = activeIndex === index ? -1 : index
      this.setState({ activeIndex: newIndex })
    }
    this.setState({ checkBox: false })
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.reports.filter,
  module: state.reports.module,
  resetData: state.reports.resetData,
  systemReport: state.reports.systemReport,
  toggle: state.reports.toggle
})

export default connect(
  mapStateToProps,
  {
    setApplyFilter: Actions.getApplyFilter,
    setChangeToggle: Actions.changeToggle,
    setFilter: Actions.getFilter
  }
)(PickData)
