import { Reducer } from 'redux'
import Actions from 'store/Actions'
import { LayoutActions, LayoutState } from './Types'

const initialState: LayoutState = {
  navigation: {
    visible: false
  }
}

const layoutReducer: Reducer<LayoutState> = (
  state: LayoutState = initialState,
  action
) => {
  switch ((action as LayoutActions).type) {
    case Actions.toggleNavigation:
      return {
        ...state,
        navigation: { ...state.navigation, visible: action.payload.toggle }
      }
    default:
      return state
  }
}

export default layoutReducer
