// Import Packages
import * as React from 'react'
import { Icon } from 'semantic-ui-react'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import ConfirmAlert from 'sweetalert2'

// Import Utils
import { LinkData } from 'store/UserProfile/Types'
import { Strings } from 'utils'

// Import Styled Components
import {
  DropDownPopup,
  Link,
  StyledDropdown
} from './Styled'

const { editLabel, deleteLabel, openLink } = Strings.userProfile.detailSection.accounts

const sanitizeUrl = (url:string) => {
  if (!url.match(/^(http|\/)/i)) {
    url = `http://${url}`
  }
  return url
}

const CustomName = ({ rowData }: { rowData: LinkData }) => <div>{rowData.name}</div>

const CustomLink = ({ rowData }: { rowData: LinkData }) => {
  const url = sanitizeUrl(rowData.url)
  return (
    <Link href={url} target='_blank'>{rowData.url}</Link>
  )
}

const CustomActions = ({ rowData, cellProperties }: { rowData: LinkData, cellProperties: any }) => {
  const {
    user,
    delete: deleteLink,
    edit: editLink,
    showLinkForm: toggleStatus
  } = cellProperties.customComponentMetadata
  const deleteItem = () => {
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this link!',
      title: 'Are you sure?',
      type: 'warning',
    }).then(async (result) => {
      if (result.value) {
        deleteLink(rowData._id)
        ConfirmAlert(
          'Deleted!',
          'Your link has been deleted.',
          'success'
        )
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(
          'Cancelled',
          'Your link is safe',
          'error'
        )
      }
    })
  }

  const editItem = () => {
    editLink(rowData)
  }

  const openLinkItem = () => {
    const url = sanitizeUrl(rowData.url)
    window.open(url, '_blank')
  }

  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className='action-drop-down'>
            {
              ((toggleStatus && (user.role === 'ADMIN' || user.role === 'MANAGER')) || !toggleStatus) ? <Dropdown.Menu>
                <Dropdown.Item text={openLink} onClick={openLinkItem} />
                <Dropdown.Item text={editLabel} onClick={editItem} />
                <Dropdown.Item text={deleteLabel} onClick={deleteItem} />
              </Dropdown.Menu> :
                <Dropdown.Menu>
                  <Dropdown.Item text={openLink} onClick={openLinkItem} />
                </Dropdown.Menu>
            }
          </div>
        }
        trigger={
          <Icon style={{ cursor: 'pointer' }} name='caret down' />
        }
        hoverable={true}
        position='bottom left' />
    </StyledDropdown>
  )
}

export {
  CustomActions,
  CustomName,
  CustomLink
}
