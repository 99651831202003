import { Button, Input } from 'semantic-ui-react'
import { css } from 'styled-components'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { scrollbars, scrollbarYPadding, text, x, y } from 'design/Styled'

interface Props {
  image: string
}

const textPadding = css`
  padding: 0.8em 1em;
`

const separationMargin = css`
  margin-bottom: 1em;
`

export const StyledInput = styled(Input)``

export const AssignButton = styled(Button)`
  &.ui.button {
    background: ${Colors.ButtonPrimary};
    color: ${Colors.White1000};
    border-radius: 0;
    text-transform: uppercase;
  }
`

export const Container = styled.div`
  ${text};
  ${x};
  align-items: flex-start;
  height: 100%;
`

export const Aside = styled.aside`
  ${y};
  max-width: 300px;
  height: 100%;
  background: ${Colors.EmphasisContainer};
`

export const Content = styled.section`
  ${y};
  height: 100%;
`

export const Transactions = styled.section`
  ${y};
  ${scrollbars};
  ${scrollbarYPadding};
  overflow: auto;
`

export const CreateButton = styled.a`
  ${text};
  color: ${Colors.ButtonPrimary};
  text-decoration: underline;
  cursor: pointer;
`

export const Table = styled.table`
  ${separationMargin};
  width: 100%;
  border-collapse: collapse;

  thead {
    ${text};
    background: ${Colors.EmphasisContainer};
  }

  tr {
    border-bottom: 1px solid ${Colors.CardShadow};
  }

  tbody > :last-child {
    border-bottom: none;
  }

  th,
  td {
    ${text};
    ${textPadding};
  }
`

export const Image = styled.img`
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
`

export const NotListed = styled.footer`
  ${text};
  ${textPadding};
  display: block;
  width: 100%;
  background: ${Colors.EmphasisContainer};

  span {
    font-style: italic;
    margin-right: 10px;
  }
`

export const Title = styled.h1`
  ${text};
  font: ${fonts.large.medium};
  color: ${Colors.TextDark};
  margin-bottom: 1em;

  em {
    font-style: normal;
    opacity: 0.8;
  }
`

export const TransactionListing = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    line-height: 1;
  }
`

export const TransactionThumb = styled.div`
  display: block;
  position: relative;
  float: left;
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
  background-image: ${(props: Props) => `url(${props.image})` || ''};
  background-size: cover;
  background-position: center;
  border-radius: 50%;
`
