// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Menu } from 'semantic-ui-react'

// Import Components
import Activity from './Activity'
import Details from './Details'
import Transactions from './Transactions'

// Import Store Types, Actions and Reducers
import { getContactActivities } from 'app/Contacts/Dashboard/ContactQueries'
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Contacts/Actions'
import { Activities, ContactListDetails } from 'store/Contacts/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  DetailsIcon,
  StyledActivityTab,
  StyledLabel,
  StyledTab,
  TransactionIcon
} from './Styled'

interface OwnProps {
  contactData: ContactListDetails
  activeIndex: number
}

interface StoreProps {
  setActivities: (activities: Activities[]) => void
  activities: Activities[]
}

type Props = StoreProps & OwnProps

interface State {
  activeIndex: number
}

class RightPanel extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
  }

  public async componentDidMount() {
    const { contactData, activeIndex, setActivities } = this.props
    const activities = await getContactActivities(contactData._id)
    setActivities(activities)

    this.setState({ activeIndex })
  }

  public render() {
    const { activities, contactData } = this.props
    const { activeIndex } = this.state
    const panes = [
      {
        menuItem:
        {
          content: 'Details',
          icon: <DetailsIcon />,
          key: 'Details',
        },
        render: () => <Details />
      },
      {
        menuItem:
        {
          content: 'Transactions',
          icon: <TransactionIcon />,
          key: 'Transactions',
        },
        render: () => <Transactions contactData={contactData} />
      }
    ]
    const activityPanes = [
      {
        menuItem:
          (
            <Menu.Item key='Activity'>{Strings.leads.Activity}<StyledLabel circular={true}>{activities.length}</StyledLabel></Menu.Item>),
        render: () => <Activity activities={activities} />
      },
    ]
    return (
      <Container>
        <StyledTab className='styledTab' activeIndex={activeIndex} menu={{ secondary: true, pointing: true }} panes={panes} onTabChange={this.handleTabChange} />
        <StyledActivityTab menu={{ secondary: true, pointing: true }} panes={activityPanes} />
      </Container>
    )
  }

  public handleTabChange = (e: React.SyntheticEvent<EventTarget>, { activeIndex }: any) => this.setState({ activeIndex })
}

const mapStateToProps = (state: AppState) => ({
  activities: state.contacts.activities,
})

export default connect(
  mapStateToProps,
  {
    setActivities: Actions.getActivities,
  }
)(RightPanel)
