// Import Packages
import * as QueryString from 'query-string'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

// Import Images and Icons
import IconAccounts from 'design/icons/userProfile/Icon_accounts.png'
import IconBasicDetail from 'design/icons/userProfile/Icon_basic_detail.png'
import IconDocuments from 'design/icons/userProfile/Icon_document.png'
import IconLinks from 'design/icons/userProfile/Icon_link.png'
import IconTeams from 'design/icons/userProfile/Icon_team.png'

// Import Components
import { UserCreditDebit, UserProfileType } from 'store/UserProfile/Types'
import Accounts from './Accounts'
import BasicDetails from './BasicDetails'
import Links from './Links'
import MyDocuments from './MyDocuments'
import PaymentInfo from './PaymentInfo'
import Teams from './Teams'

// Import Utils
import Strings from 'utils/Strings'

// Import Styled Components
import { Container, ImageWrapper, StyledTab } from './Styled'

interface Props extends RouteComponentProps<{}> {
  openAccTransaction: () => void
  deleteUserCreditDebit: (data: string) => void
  getUserCreditDebit: (data: UserCreditDebit[]) => void
  updateUserCreditDebit: (data: UserCreditDebit) => void
  creditDebit: UserCreditDebit[]
  userProfile: UserProfileType
  showEditButton: string
  activeIndex: number
  userID: string
}

class DetailSection extends React.Component<Props, {}> {
  public state = {
    activeIndex: 0
  }

  public componentDidMount() {
    const { location } = this.props
    const query = QueryString.parse(location.search, { parseNumbers: true })
    if (query.tab === 'Documents') {
      this.setState({
        activeIndex: 3
      })
    } else if (query.tab === 'Links') {
      this.setState({
        activeIndex: 4
      })
    } else {
      this.setState({
        activeIndex: this.props.activeIndex
      })
    }
  }

  public render() {
    const {
      openAccTransaction,
      userProfile,
      showEditButton,
      getUserCreditDebit,
      deleteUserCreditDebit,
      updateUserCreditDebit,
      creditDebit,
      userID
    } = this.props
    const { links, myDocuments, accounts, basicDetails, teams, paymentInfo } = Strings.userProfile.detailSection.tabs
    const { activeIndex } = this.state
    const panes = [
      {
        menuItem: {
          key: basicDetails.key,
          icon: <ImageWrapper backgroundImage={IconBasicDetail} />,
          content: basicDetails.title
        },
        render: () => <BasicDetails userProfile={userProfile} showEditButton={showEditButton} />
      },
      {
        menuItem: { key: teams.key, icon: <ImageWrapper backgroundImage={IconTeams} />, content: teams.title },
        render: () => <Teams userProfile={userProfile} showEditButton={showEditButton} />
      },
      {
        menuItem: { key: accounts.key, icon: <ImageWrapper backgroundImage={IconAccounts} />, content: accounts.title },
        render: () => (
          <Accounts
            openAccTransaction={openAccTransaction}
            userProfile={userProfile}
            getUserCreditDebit={getUserCreditDebit}
            deleteUserCreditDebit={deleteUserCreditDebit}
            updateUserCreditDebit={updateUserCreditDebit}
            creditDebit={creditDebit}
          />
        )
      },
      {
        menuItem: {
          key: myDocuments.key,
          icon: <ImageWrapper backgroundImage={IconDocuments} />,
          content: myDocuments.title
        },
        render: () => <MyDocuments tenant={userProfile.tenant} />
      },
      {
        menuItem: {
          key: links.key,
          icon: <ImageWrapper className="link" backgroundImage={IconLinks} />,
          content: links.title
        },
        render: () => <Links userProfile={userProfile} />
      },
      {
        menuItem: {
          key: paymentInfo.key,
          icon: <ImageWrapper backgroundImage={IconDocuments} />,
          content: paymentInfo.title
        },
        render: () => <PaymentInfo userID={userID} />
      }
    ]
    return (
      <Container>
        <StyledTab
          menu={{ secondary: true, pointing: true }}
          activeIndex={activeIndex}
          panes={panes}
          onTabChange={this.handleTabChange}
        />
      </Container>
    )
  }

  private handleTabChange = (e: React.SyntheticEvent<HTMLDivElement>, { activeIndex }: any) =>
    this.setState({ activeIndex })
}

export default withRouter(DetailSection)
