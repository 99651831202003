// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Dropdown, Header } from 'semantic-ui-react'

// Import Components
import Toast, { serverToast } from 'shared/Toast/Toast'
import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'
import ClickToUpload from 'design/images/dashboard/clickToUpload.jpg'
import Transaction from './Transaction'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'
import { Transaction as TransactionProp } from 'store/Transactions/Types'

// Import Styled Components
import { isPlanAllowed } from 'shared/Billing/Queries'
import { getRecentlyViewTransactions, getTransactionBoardsDashboard, getTransactions } from 'app/Transactions/Dashboard/TransactionQueries'
import { formatKanbanData, formatListData } from 'app/Transactions/Dashboard/Utils/FormattingData'
import { SubText, TransactionsContainer } from './Styled'
import { Labels } from './Styled'
import { Container, Line } from './Transaction/Styled'

// Import Colors
import Colors from 'design/Colors'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Font Awesome Icons
import { faChevronDown, faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronDown, faPlusCircle])

interface StoreProps {
  transactions: TransactionProp[]
  open: boolean
  setAddNewCard: (id: string, index: string, newData: any) => void
  setAllRecentTransactions: (allTransactions: any, allListTransactions: any) => void
  setMoveTransaction: (id: string, status: string, newData: any) => void
  setShowConfetti: (data: boolean) => void
}

type Props = StoreProps & RouteComponentProps<{}>

interface State {
  showAddTransactionModal: boolean
  status: string
  activeId: string
  deleteActive: boolean
  addBoardId: string
  newTransactionBoard: any
  selectedId: string
  statusOptions: any
  recentlyViewTransactions: any
}

export let Remove: any
export let totalPages: any
const recentOptionId = '_recentOptionId'

class Transactions extends React.Component<Props, State> {
  public state = {
    activeId: '',
    addBoardId: '',
    deleteActive: false,
    newTransactionBoard: [],
    selectedId: '',
    showAddTransactionModal: false,
    status: 'Updated',
    recentlyViewTransactions: [], 
    statusOptions: []
  }

  public async componentDidMount() {
    const { transactions } = this.props
    let status = await getTransactionBoardsDashboard()

    const recentlyViewTransactions = await getRecentlyViewTransactions()
    
    status = [...status, { _id: recentOptionId, name: 'Recent', pagination: { count: recentlyViewTransactions.length } }]
    
    const statusOptions: any = status.map((element: any) => {
      return {
        content: (
          <div>
            {element.name} <Labels circular={true}>{element.pagination ? element.pagination.count : 0}</Labels>
          </div>
        ),
        key: element._id,
        text: element.name,
        value: `${element.name}, ${element._id}`
      }
    })
    const newBoardId = transactions.filter((board: any) => {
      return board.name === 'New'
    })
    this.setState({ addBoardId: newBoardId[0]._id, statusOptions: statusOptions, recentlyViewTransactions })
    this.assignRemove()
  }

  public remove = async () => {
    if (!this.props.open && this.state.activeId) {
      const { setMoveTransaction } = this.props
      const { status, activeId } = this.state
      const transactionBoards = await getTransactionBoardsDashboard()
      const transactionKanbanData = await formatKanbanData(transactionBoards)
      window.setTimeout(() => {
        setMoveTransaction(activeId, status, transactionKanbanData)
        this.setState({ deleteActive: false })
      }, 1000)
    }
  }

  public async componentWillReceiveProps() {
    let status = await getTransactionBoardsDashboard()

    const recentlyViewTransactions = await getRecentlyViewTransactions()

    status = [...status, { _id: recentOptionId, name: 'Recent', pagination: { count: recentlyViewTransactions.length } }]

    const statusOptions: any = status.map((element: any) => {
      return {
        content: (
          <div>
            {element.name} <Labels circular={true}>{element.pagination ? element.pagination.count : 0}</Labels>
          </div>
        ),
        key: element._id,
        text: element.name,
        value: `${element.name}, ${element._id}`
      }
    })
    this.setState({ statusOptions, recentlyViewTransactions })
  }

  public assignRemove = () => {
    Remove = () => {
      this.remove()
    }
  }

  public render() {
    const { showAddTransactionModal, statusOptions } = this.state
    return (
      <TransactionsContainer>
        {showAddTransactionModal && (
          <CreateTransactionModal transactionDetailView={true} onClose={this.closeTransactionModal} />
        )}
        {statusOptions.length > 0 && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '93.4%',
                color: Colors.White1000,
                marginBottom: 5
              }}
            >
              <SubText>
                <Header as="h3" style={{ color: Colors.White1000 }}>
                  {Strings.dashboard.transactionStatus}
                </Header>
              </SubText>
              <span style={{ fontWeight: 500 }}> {Strings.dashboard.create}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '89%' }}>
              <SubText>
                <Header as="h4">
                  <Header.Content>
                    <Dropdown
                      icon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                      inline={true}
                      header=""
                      options={statusOptions}
                      onChange={this.handleChange}
                      defaultValue={statusOptions[1][`value`]}
                    />
                  </Header.Content>
                </Header>
              </SubText>
              <span onClick={this.showAddTransactionModal}>
                <FontAwesomeIcon icon={['fal', 'plus-circle']} />
              </span>
            </div>
          </>
        )}
        <Line />
        <Container className="add-to-list slide-fade demo-container">
          {this.props.transactions.length !== 0 && this.renderRecentTransactions()}
          {totalPages > 20 && (
            <div className="more" onClick={this.transactionsBoard}>
              More
            </div>
          )}
        </Container>
      </TransactionsContainer>
    )
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement> | any, { value }: any) => {
    const res = value.split(', ')
    this.setState({ status: res[0] })
  }

  private showAddTransactionModal = async () => {
    try {
      await isPlanAllowed('transaction')
      const user: any = await getLoggedInUser({ fromCache: true })
      if (user.role === 'GUEST') {
        Toast({
          message:
            'You are not authorized to create a transaction. Please contact your administrator if you need additional assistance',
          type: 'error'
        })
      } else {
        this.setState({ showAddTransactionModal: true })
      }
    } catch (error) {
      serverToast(error)
    }
  }

  private closeTransactionModal = async (result: any) => {
    const { setAddNewCard, setAllRecentTransactions, setShowConfetti } = this.props
    const { addBoardId } = this.state

    this.setState({ showAddTransactionModal: false })

    if (!result) {
      return
    }

    setShowConfetti(true)
    setTimeout(() => {
      setShowConfetti(false)
    }, 6000)
    setAddNewCard(addBoardId, '', result)
    const transactionBoards = await getTransactionBoardsDashboard()
    const transactions = await getTransactions({})
    const transactionKanbanData = await formatKanbanData(transactionBoards)
    const transactionListData = await formatListData(transactions)
    setAllRecentTransactions(transactionKanbanData, transactionListData)
  }

  private renderRecentTransactions = () => {
    const { status } = this.state
    const mostRecent: any = this.props.transactions.filter((element: any) => {
      return element.name === this.state.status
    })
    let items = []
    if (mostRecent && mostRecent[0]) {
      items = mostRecent[0].items
      totalPages = mostRecent[0].pagination.count
    } else if (status === 'Recent') {
      items = this.state.recentlyViewTransactions
      totalPages = 1
    }
    return items.map(
      (element: any): React.ReactNode => {
        let text = ''
        switch (element.transactionRole) {
          case 'Buyer':
            text = 'Buy'
            break
          case 'Seller':
            text = 'Sell'
            break
          case 'Both':
            text = 'Both'
            break
          default:
            break
        }
        return (
          // tslint:disable-next-line:jsx-key
          <li
            key={element._id}
            style={{
              transition:
                !this.props.open &&
                this.state.activeId &&
                this.state.activeId === element._id &&
                this.state.deleteActive
                  ? 'all 0.4s ease-out'
                  : '',
              transitionDelay:
                !this.props.open &&
                this.state.activeId &&
                this.state.activeId === element._id &&
                this.state.deleteActive
                  ? '0.4s'
                  : ''
            }}
            className={
              !this.props.open && this.state.activeId && this.state.activeId === element._id && this.state.deleteActive
                ? ''
                : `show ${!this.state.deleteActive && 'animated fadeInDown slow'}`
            }
          >
            <Transaction
              name={`${element.owner.firstName} ${element.owner.lastName}`}
              transactionType={text}
              address={element.propertyId.address}
              mslID={element.propertyId.mlsId}
              id={element._id}
              image={
                element.propertyId && element.propertyId.images.length > 0
                  ? element.propertyId.images[0].url
                  : ClickToUpload
              }
              key={element._id}
              transaction={element}
              removeFromList={this.removeFromList}
              activeId={this.state.activeId}
            />
          </li>
        )
      }
    )
  }

  private transactionsBoard = () => {
    this.props.history.push(Routes.primary.transactions.path)
  }

  private removeFromList = (id: string) => {
    this.setState({ deleteActive: true, activeId: id }, () => this.remove())
  }
}

const mapStateToProps = (state: AppState) => ({
  open: state.dashboard.detailsPopup.open,
  transactions: state.transactions.allTransactions
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setAddNewCard: Actions.addNewCard,
      setAllRecentTransactions: Actions.getAllRecentTransactions,
      setMoveTransaction: Actions.moveTransaction,
      setShowConfetti: Actions.showConfetti
    }
  )(Transactions)
)
