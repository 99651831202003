export interface ButtonsType {
  download?: boolean
  mail?: boolean
  rename?: boolean
  sort?: boolean
  trash?: boolean
}

export enum ActionEnum {
  Close = 'image:close',
  Mail = 'image:mail',
  Rename = 'image:rename',
  Select = 'image:select',
  Sort = 'image:sort',
  SortEnd = 'image:sortEnd',
  SortStart = 'image:sortStart',
  Trash = 'image:trash',
  View = 'image:view'
}
