import jwtDecode from 'jwt-decode'
import * as QueryString from 'query-string'
import * as React from 'react'
import * as ReactGA from 'react-ga'
import InputMask from 'react-input-mask'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { registration as registrationImage } from 'design/images/onboarding'

import TokenMethodToggle from 'app/Onboarding/TokenMethodToggle'
import LoadingIndicator from 'shared/LoadingIndicator'
import PasswordInputs from 'shared/PasswordInputs'
import Toast, { formToast, serverToast } from 'shared/Toast/Toast'

import { registerTenant } from './Mutations'
import { handleValidation } from './Validation'

import { Routes, Strings } from 'utils'

import {
  Col,
  Container,
  Feature,
  Header,
  HeaderBold,
  HeaderGray,
  HeaderLarge,
  HeaderTop,
  Image,
  Return,
  Row,
  StyledButton,
  StyledForm,
  StyledInput
} from './Styled'

import { MethodEnum } from 'app/Onboarding/TokenMethodToggle/Types'
import { SubjectEnum } from 'app/Onboarding/Types'
import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { FormErrorType, FormType, MatchParams, ResponseErrorType, TenantType } from './Types'

interface Props extends RouteComponentProps<MatchParams, {}> {}

interface State {
  form: FormType
  loading: boolean
}

class Register extends React.Component<Props, State> {
  public instance: any

  public state = {
    form: {
      errors: {} as FormErrorType,
      method: MethodEnum.Email
    } as FormType,
    loading: true
  }

  public componentDidMount = async () => {
    const script = document.createElement('script')
    script.async = true
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '512197116095246');
        fbq('track', 'PageView');
    `
    this.instance.appendChild(script)

    await this.handleSocialRegistration()

    const { form } = this.state
    const query = QueryString.parse(this.props.location.search)
    if (query.ref) {
      form.referralId = query.ref.toString()
    }

    this.setState({
      form,
      loading: false
    })
  }

  public render() {
    const {
      loading,
      form,
      form: { errors }
    } = this.state

    return (
      <React.Fragment>
        {loading && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
        <Container>
          <Col padding={1} primary={true} width={70}>
            <Image src={registrationImage} />
            <Header>
              Register for Your <HeaderBold>FREE All-Inclusive</HeaderBold> Platform.
            </Header>
            <TokenMethodToggle name={'method'} value={form.method} onChange={this.handleChange} />
            <StyledForm onSubmit={this.handleSubmit}>
              <Row>
                <StyledInput
                  placeholder="First Name"
                  name="firstName"
                  value={form.firstName}
                  onChange={this.handleChange}
                  error={!!errors.firstName}
                />
                <StyledInput
                  placeholder="Last Name"
                  name="lastName"
                  value={form.lastName}
                  onChange={this.handleChange}
                  error={!!errors.lastName}
                />
              </Row>
              <Row>
                <StyledInput
                  placeholder="Email Address"
                  name="email"
                  value={form.email}
                  onChange={this.handleChange}
                  error={!!errors.email}
                />
                <InputMask mask="(999) 999-9999" value={form.phone} onChange={this.handlePhoneChange}>
                  {() => <StyledInput placeholder="Phone Number" name="phone" error={!!errors.phone} />}
                </InputMask>
              </Row>
              <Row>
                <PasswordInputs
                  fluid={false}
                  onChange={this.handleChange}
                  password={form.password}
                  passwordConfirm={form.passwordConfirm}
                  passwordConfirmError={!!errors.passwordConfirm}
                  passwordError={!!errors.password}
                />
              </Row>
              <Row justify="center">
                <StyledButton size="large" type="submit" content={Strings.onboarding.nextButton} />
              </Row>
              <Row justify="center">
                Already Registered? <Return onClick={this.moveToLogin}>Login</Return>
              </Row>
            </StyledForm>
          </Col>
          <Col width={30}>
            <HeaderTop>{Strings.onboarding.featureHeaderLine1}</HeaderTop>
            <HeaderLarge>{Strings.onboarding.featureHeaderLine2}</HeaderLarge>
            <HeaderGray>{Strings.onboarding.featureHeaderLine3}</HeaderGray>
            {Strings.onboarding.features.map((item: string, index: number) => (
              <Feature key={index}>{item}</Feature>
            ))}
          </Col>
        </Container>
        <div ref={el => (this.instance = el)} />
        <noscript>
          <img
            alt=""
            height="1"
            width="1"
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=512197116095246&ev=PageView&noscript=1"
          />
        </noscript>
      </React.Fragment>
    )
  }

  private moveToConfirmation = (tenant: TenantType) => {
    const {
      form: { email, phone }
    } = this.state
    let {
      form: { method }
    } = this.state

    if (tenant.errors.findIndex((item: ResponseErrorType) => item.code === 'USER_DUPLICATE_PHONE_NUMBER') > -1) {
      method = MethodEnum.Email
      Toast({
        message: 'The phone number was not unique, so we sent a confirmation link to your email address',
        type: 'warning'
      })
    }
    this.props.history.push({
      pathname: Routes.onboarding.root + Routes.onboarding.confirm.path,
      state: {
        email,
        method,
        phone,
        subject: SubjectEnum.Activate
      }
    })
  }

  private handleSocialRegistration = async () => {
    const { socialToken } = this.props.match.params

    if (socialToken && socialToken.length > 0) {
      try {
        const form: any = await jwtDecode(socialToken)
        form.password = Math.random().toString(36)
        try {
          const result = await registerTenant(form)
          if (form.facebook) {
            window.location.replace(`${process.env.REACT_APP_SERVER_URL}/auth/facebook`)
          } else if (form.google) {
            window.location.replace(`${process.env.REACT_APP_SERVER_URL}/auth/google`)
          } else {
            this.moveToConfirmation(result)
          }
        } catch (error) {
          serverToast(error)
        }
      } catch (error) {
        Toast({
          message: 'Invalid token',
          type: 'error'
        })
      }
    }
  }

  private handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const { form } = this.state
    this.setState({ loading: true })
    const result = handleValidation(form)

    ReactGA.event({
      action: 'Sign Up',
      category: 'User'
    })

    if (result.formIsValid) {
      try {
        const tenant = await registerTenant(form)
        this.moveToConfirmation(tenant)
      } catch (error) {
        serverToast(error)
      }
    } else {
      form.errors = result.errors
      this.setState({ form })
      formToast(form.errors)
    }

    this.setState({ loading: false })
  }

  private handleChange = (e: any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    form.errors[name] = ''
    this.setState({ form })
  }

  private handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleChange(null, { name: 'phone', value: e.target.value })
  }

  private moveToLogin = () => {
    this.props.history.replace({
      pathname: Routes.onboarding.root
    })
  }
}

export default withRouter(Register)
