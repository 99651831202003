import { isEqual } from 'lodash'
import * as React from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult
} from 'react-beautiful-dnd'
import * as ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

import DropZone from 'shared/DropZone'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import PDFViewer from 'shared/PDFViewer'
import { serverToast } from 'shared/Toast/Toast'

import { Strings } from 'utils'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/ChecklistManager/Actions'
import {
  createDocTemplate,
  deleteDocTemplate,
  reorderDocTemplate,
  updateDocTemplate,
  updateDocTemplateDocument
} from '../ChecklistManagerMutations'
import { getDocTemplates } from '../ChecklistManagerQueries'

import { MarkupType, MarkupModeEnum, MarkupSourceEnum } from 'shared/MarkupTool/Types'
import { PDFDocumentType } from 'shared/PDFViewer/Types'
import { DocumentItem as ChecklistItemType } from 'store/Settings/ChecklistManager/Types'
import { OptionType } from './Types'

import {
  ActionCol,
  AddButton,
  Checklist,
  ChecklistSection,
  Col,
  Container,
  Description,
  DescriptionCol,
  DescriptionInput,
  Edit,
  Footer,
  Grip,
  Header,
  Item,
  ItemTitle,
  ItemTitleInput,
  Labels,
  Nav,
  PDFChildren,
  PDFHeader,
  PDFSection,
  PDFTitle,
  RoleSelector,
  Row,
  Title,
  Trash
} from './Styled'

// Font Awesome Icons
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faGripLines } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronLeft, faChevronRight, faGripLines])

interface OwnProps {
  mlsFilter: string
  onClose: () => void
}

interface DispatchProps {
  checklistItems: ChecklistItemType[]
  updateChecklistData: (categories: any, documents: any) => void
}

type Props = OwnProps & {} & DispatchProps

interface State {
  activeItem: ChecklistItemType
  editItem: ChecklistItemType
  isUploading: boolean
  transition: boolean
}

const defaultRoleOptions: OptionType[] = [
  { text: 'Buyer', value: 'Buyer' },
  { text: 'Seller', value: 'Seller' },
  { text: 'Both', value: 'Both' }
]

class ChecklistEditorModal extends React.Component<Props, State> {
  public state = {
    activeItem: {} as ChecklistItemType,
    editItem: {} as ChecklistItemType,
    isUploading: false,
    transition: true
  }

  private pollTimer: number = 0
  private portal: any

  public componentDidMount = async () => {
    this.portal = document.getElementById('__dnd-portal')

    const { mlsFilter, updateChecklistData } = this.props
    const docTemplates = await getDocTemplates({ mls: mlsFilter })
    updateChecklistData(null, docTemplates)

    this.pollUpdates()
  }

  public componentWillUnmount = () => {
    window.clearTimeout(this.pollTimer)
  }

  public render = () => {
    const { checklistItems } = this.props
    const { activeItem, editItem, isUploading, transition } = this.state
    return (
      <Modal
        content={
          <Container>
            <ChecklistSection padding={1} width={50}>
              <Title>Checklist Editor</Title>
              <Header>
                <Col width={25}>Document Name</Col>
                <Col width={50}>Description</Col>
                <Col width={25}>Role</Col>
              </Header>
              <DragDropContext onDragEnd={this.reorderItems}>
                <Droppable droppableId="CHECKLIST">
                  {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
                    <Checklist innerRef={dropProvided.innerRef} {...dropProvided.droppableProps}>
                      {checklistItems.map((item: ChecklistItemType, index: number) => (
                        <Draggable key={item._id} draggableId={item._id} index={index}>
                          {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
                            const child: any = (
                              <Item innerRef={dragProvided.innerRef} {...dragProvided.draggableProps}>
                                {item.template &&
                                  !item.template.extractWordsUrl && (
                                    <LoadingIndicator message="Extracting document data..." />
                                  )}
                                <Row>
                                  <Col width={25}>
                                    <Grip {...dragProvided.dragHandleProps}>
                                      <Icon icon={['fas', 'grip-lines']} />
                                    </Grip>
                                    {(item._id !== editItem._id || (item._id === editItem._id && item.template)) && (
                                      <ItemTitle title={item.name} onClick={() => this.activateItem(item)}>
                                        <span>{item.name}</span>
                                      </ItemTitle>
                                    )}
                                    {item._id === editItem._id &&
                                      !item.template && (
                                        <ItemTitleInput
                                          value={item.name}
                                          onChange={(e: any, { value }: any) => this.updateItem(value, 'name', index)}
                                        />
                                      )}
                                  </Col>
                                  <DescriptionCol width={50}>
                                    {item._id !== editItem._id ? (
                                      <Description>{item.description || ''}</Description>
                                    ) : (
                                      <DescriptionInput
                                        rows={3}
                                        value={item.description || ''}
                                        onChange={(e: any, { value }: any) =>
                                          this.updateItem(value, 'description', index)
                                        }
                                      />
                                    )}
                                  </DescriptionCol>
                                  <Col width={25} justify="space-between">
                                    <RoleSelector
                                      value={item.role}
                                      options={defaultRoleOptions}
                                      onChange={(e: any, { value }: any) => this.updateItem(value, 'role', index)}
                                    />
                                    <ActionCol>
                                      <Edit onClick={() => this.editItem(item)}>
                                        {item._id !== editItem._id ? (
                                          <Icon icon={['fal', 'pencil-alt']} />
                                        ) : (
                                          <Icon icon={['fal', 'check']} />
                                        )}
                                      </Edit>
                                      <Trash onClick={() => this.deleteItem(index)}>
                                        <Icon icon={['fal', 'trash-alt']} />
                                      </Trash>
                                    </ActionCol>
                                  </Col>
                                </Row>
                                {item._id === activeItem._id &&
                                  item.template &&
                                  item.template.extractMarkup.length > 0 && (
                                    <Labels>
                                      {item.template.extractMarkup.map((markup: MarkupType, muIndex: number) => (
                                        <Row key={markup.id}>
                                          {/* <Trash
                                          onClick={() => this.deleteMarkupItem(index, muIndex)}
                                        ><Icon icon={['fal', 'trash-alt']}/></Trash> */}
                                          <strong>{markup.label.text}</strong>
                                          {markup.value && markup.value !== '' && <i>{markup.value}</i>}
                                        </Row>
                                      ))}
                                    </Labels>
                                  )}
                              </Item>
                            )
                            return dragSnapshot.isDragging ? ReactDOM.createPortal(child, this.portal) : child
                          }}
                        </Draggable>
                      ))}
                      {isUploading && (
                        <Item>
                          <LoadingIndicator message="Creating template file..." />
                          <Row justify="center">Preparing new item...</Row>
                        </Item>
                      )}
                    </Checklist>
                  )}
                </Droppable>
              </DragDropContext>
              <Footer>
                <AddButton justify="center" onClick={this.addItem}>
                  <Icon icon={['fal', 'plus']} />
                  <span>Add Item</span>
                </AddButton>
              </Footer>
            </ChecklistSection>

            <PDFSection padding={1} width={50}>
              <DropZone
                disableClick={!!activeItem.template}
                onDropEach={this.handleFileUpload}
                showBorder={true}
                showImage={!activeItem.template}
                stagger={true}
              >
                {activeItem.template && (
                  <PDFChildren>
                    <PDFHeader>
                      <Nav onClick={() => this.navItem(-1)}>
                        <Icon icon={['fal', 'chevron-left']} />
                      </Nav>
                      <PDFTitle>{activeItem.name}</PDFTitle>
                      <Nav onClick={() => this.navItem(1)}>
                        <Icon icon={['fal', 'chevron-right']} />
                      </Nav>
                    </PDFHeader>
                    <PDFViewer
                      doc={activeItem.template}
                      markupMode={MarkupModeEnum.Owner}
                      markupSource={MarkupSourceEnum.Textract}
                      onUpdate={this.updateDocument}
                    />
                  </PDFChildren>
                )}
              </DropZone>
            </PDFSection>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        maximum={true}
      />
    )
  }

  private updateItem = async (value: any, field: string, index: number) => {
    const { checklistItems, updateChecklistData } = this.props
    const result: ChecklistItemType[] = checklistItems.slice()
    result[index][field] = value
    updateChecklistData(null, result)

    const mustConfirm = ['description', 'name']
    if (!mustConfirm.includes(field)) {
      await updateDocTemplate(result[index])
    }
  }

  private activateItem = (activeItem: ChecklistItemType) => {
    this.setState({ activeItem: {} as ChecklistItemType })
    window.requestAnimationFrame(() => {
      this.setState({ activeItem })
    })
  }

  private navItem = (direction: number) => {
    const { checklistItems } = this.props
    const { activeItem } = this.state
    let index = checklistItems.findIndex((item: ChecklistItemType) => item._id === activeItem._id)
    if (index < 0) {
      return
    }

    index = index + direction
    index = index < 0 ? checklistItems.length - 1 : index
    index = index > checklistItems.length - 1 ? 0 : index

    this.activateItem(checklistItems[index])
  }

  private editItem = (item: ChecklistItemType) => {
    const { checklistItems, updateChecklistData } = this.props
    this.setState((prev: State) => {
      let editItem = item
      if (prev.editItem._id === item._id) {
        editItem = {} as ChecklistItemType
        updateDocTemplate(item)
        updateChecklistData(null, checklistItems)
      }
      this.activateItem(item)
      return {
        editItem
      }
    })
  }

  private reorderItems = async (drop: DropResult) => {
    const { checklistItems, updateChecklistData } = this.props
    const { destination, source } = drop

    if (!destination) {
      return
    }

    const result: ChecklistItemType[] = checklistItems.slice()
    const [item] = result.splice(source.index, 1)
    result.splice(destination.index, 0, item)

    updateChecklistData(null, result)
    await reorderDocTemplate(item._id, destination.index)
  }

  private addItem = async (e: any, file: any = null) => {
    this.setState({ isUploading: true })
    const { checklistItems, mlsFilter: mls, updateChecklistData } = this.props
    const result: ChecklistItemType[] = checklistItems.slice()

    const newItem: ChecklistItemType = await createDocTemplate({ mls }, file)
    if (!newItem) {
      this.setState({ isUploading: false })
      return
    }

    result.push(newItem)
    updateChecklistData(null, result)
    this.setState({ isUploading: false })

    this.pollUpdates()
  }

  private updateDocument = async (document: PDFDocumentType) => {
    const { checklistItems, updateChecklistData } = this.props
    const { activeItem } = this.state

    const result: ChecklistItemType[] = JSON.parse(JSON.stringify(checklistItems))
    const index = result.findIndex((item: ChecklistItemType) => item._id === activeItem._id)
    if (index < 0) {
      return
    }

    const previous = result[index].template
    result[index].template = document

    let shouldUpdate = true
    try {
      result[index] = await this.updateChecklistTitle(result[index])
    } catch (error) {
      result[index] = checklistItems[index]
      shouldUpdate = false
      serverToast(error)
    }

    if (shouldUpdate && !isEqual(previous.extractMarkup, document.extractMarkup)) {
      await updateDocTemplateDocument(activeItem.template._id, { extractMarkup: document.extractMarkup })
    }

    updateChecklistData(null, result)
    this.setState({ activeItem: result[index] })
  }

  private updateChecklistTitle = async (checklistItem: ChecklistItemType) => {
    if (!checklistItem.template || !checklistItem.template.extractMarkup) {
      return checklistItem
    }

    const {
      template: { extractMarkup }
    } = checklistItem
    const title = extractMarkup.find((markup: MarkupType) => markup.label.value === 'title')
    if (title && title.value && title.value !== '' && checklistItem.name !== title.value) {
      checklistItem.name = title.value
      await updateDocTemplate(checklistItem)
    }
    return checklistItem
  }

  private deleteItem = async (index: number) => {
    const { deleteTemplate } = Strings.checklistManager.alert
    const confirm = await ConfirmAlert({
      cancelButtonText: deleteTemplate.cancel,
      confirmButtonText: deleteTemplate.confirm,
      showCancelButton: true,
      title: deleteTemplate.title,
      type: 'warning'
    })
    if (!confirm.value) {
      return
    }

    const { checklistItems, updateChecklistData } = this.props
    const { activeItem } = this.state
    const result: ChecklistItemType[] = checklistItems.slice()
    result.splice(index, 1)

    updateChecklistData(null, result)
    if (checklistItems[index]._id === activeItem._id) {
      this.activateItem({} as ChecklistItemType)
    }
    await deleteDocTemplate(checklistItems[index]._id)
  }

  // private deleteMarkupItem = async (checklistIndex:number, markupIndex:number) => {
  //   const { checklistItems, updateChecklistData } = this.props
  //   const checklist:ChecklistItemType[] = checklistItems.slice()
  //   const markup:MarkupType[] = checklist[checklistIndex].template.extractMarkup.slice()
  //   markup.splice(markupIndex, 1)
  //   checklist[checklistIndex].template.extractMarkup = markup
  //
  //   updateChecklistData(null, checklist)
  //   await updateDocTemplateDocument(checklist[checklistIndex].template._id, { extractMarkup: markup })
  // }

  private pollUpdates = () => {
    const { checklistItems, mlsFilter, updateChecklistData } = this.props
    const shouldPoll = checklistItems.find((item: ChecklistItemType) => item.template && !item.template.extractWordsUrl)

    if (!shouldPoll) {
      window.clearTimeout(this.pollTimer)
      this.pollTimer = -1
      return
    }

    this.pollTimer = window.setTimeout(async () => {
      const { activeItem } = this.state
      const docTemplates = await getDocTemplates({ mls: mlsFilter })
      if (activeItem._id && activeItem._id !== '') {
        const reactivate = docTemplates.find((item: ChecklistItemType) => item._id === activeItem._id)
        if (reactivate) {
          this.setState({ activeItem: reactivate })
        }
      }
      updateChecklistData(null, docTemplates)
      this.pollUpdates()
    }, 10000)
  }

  private handleFileUpload = async (file: File) => {
    await this.addItem(null, file)
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({
        transition: true
      })
      onClose()
    }, 300)
  }
}

const mapStateToProps = (state: AppState) => ({
  checklistItems: state.settings.checklistManager.documents
})

export default connect(
  mapStateToProps,
  {
    editDocument: Actions.editDocument,
    updateChecklistData: Actions.updateChecklistData
  }
)(ChecklistEditorModal)
