// Import Packages
import Colors from 'design/Colors'
import { Grid, Segment } from 'semantic-ui-react'

// Import Colors
import { scrollbars } from 'design/Styled'
import styled from 'styled-components'

interface Props {
  background: string
}

const UserProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 68px;
  height: 68px;
  align-self: start;
  margin-top: 10px;
  border-radius: 50%;
`

const StyledGrid = styled(Grid)`
  &.ui.grid>.row>[class*="six wide"].column {
    display:flex;
    align-items: center;
  }

  &.ui.grid>.row>[class*="ten wide"].column {
    margin-top: -8px;
    margin-bottom: -8px;
    overflow: auto;
    display: -webkit-box;
    justify-content: flex-end;
    ${scrollbars};
  }
`

const UserDuration = styled.div`
  font-size: 14px;
  color: ${Colors.Black500};

  span {
    font-weight: 500;
    color: ${Colors.Black500};
  }
`

const UserName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.Black500};
  text-transform: capitalize;
`

const Container = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
`

const StyledSegment = styled(Segment)`
  &.ui.compact.segment {
    text-align: center;
    flex-basis: 140px;
    margin-bottom: 14px;
    border: none;
    box-shadow: none;
    margin-right: 10px;
    margin-top: 16px;
  }

  &.ui.compact.segment.chart-segment {
    cursor: pointer;
  }

  .fa-chart-area {
    width: 50px;
    height: 50px;
    color: ${Colors.DarkBlue200};
  }
`

const Numbers = styled.div`
  font-size: 25px;
  margin-bottom: 8px;
  margin-top: 6px;
  color: ${Colors.DarkBlue200};
`

const Text = styled.div`
  color: ${Colors.Black500};
  font-weight: 500;
  white-space: nowrap;
`

export {
  Container,
  Numbers,
  StyledGrid,
  StyledSegment,
  Text,
  UserDuration,
  UserName,
  UserProfile,
}

