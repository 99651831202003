import gql from 'graphql-tag'

export const GET_TRANSACTION_BOARDS = gql`
  query getTransactionBoards(
    $orderField: String
    $sortOrder: Int
    $officeId: String
    $searchData: SearchTransaction
    $user: String
    $userPassFilter: UserPassTransactionFilter
    $singleSkip: Int
    $id: String
  ) {
    getTransactionBoards(
      where: { isActive: true, office: $officeId, user: $user }
      sorting: { field: $orderField, order: $sortOrder }
      filter: $searchData
      userPassFilter: $userPassFilter
      _id: $id
      singleSkip: $singleSkip
    ) {
      _id
      name
      boardOrder
      icon {
        _id
        key
        imageUrl
      }
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      transactions {
        _id
        propertyId {
          _id
          type {
            _id
            name
          }
          subType {
            _id
            name
          }
          address {
            _id
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          images {
            _id
            name
            url
            order
          }
          price
          mlsId
        }
        transactionId
        transactionEmail
        transactionRole
        status
        tags
        progress
        createdAt
        updatedAt
        teamTransactionOrder
        officeTransactionOrder
        tenantTransactionOrder
        owner {
          _id
          firstName
          lastName
          profileImage
          userName
        }
        shareWith {
          _id
          firstName
          lastName
          userName
          role
          status
          type
          phones {
            type
            value
          }
          emails {
            type
            value
          }
          profileImage
          userName
        }
      }
    }
  }
`

export const GET_TRANSACTION_PROPERTY_CATEGORY = gql`
  query getPropertyCategories {
    getPropertyCategories {
      _id
      name
      propertySubCategories {
        _id
        name
      }
    }
  }
`

export const GET_TRANSACTION_PROPERTY_SUB_CATEGORIES = gql`
  query getPropertySubCategories($propertyCategoryId: String) {
    getPropertySubCategories(where: { propertyCategory: $propertyCategoryId }) {
      _id
      name
    }
  }
`

export const EXPORT_TRANSACTION = gql`
  query exportTransactions(
    $transactionBoard: String
    $teamTransactionBoard: String
    $officeTransactionBoard: String
    $tenantTransactionBoard: String
  ) {
    exportTransactions(
      where: {
        transactionBoard: $transactionBoard
        teamTransactionBoard: $teamTransactionBoard
        officeTransactionBoard: $officeTransactionBoard
        tenantTransactionBoard: $tenantTransactionBoard
      }
    )
  }
`

export const GET_SORTED_TRANSACTION_BOARDS = gql`
  query getSortedTransactionsBoards(
    $orderField: String
    $sortOrder: Int
    $transactionOrderField: String
    $sortTransactionOrder: Int
    $id: String
  ) {
    getTransactionBoards(
      where: { isActive: true }
      sorting: { field: $orderField, order: $sortOrder }
      _id: $id
      singleSorting: {
        field: $transactionOrderField
        order: $sortTransactionOrder
      }
    ) {
      _id
      name
      boardOrder
      icon {
        _id
        key
        imageUrl
      }
      description
      transactions {
        _id
        propertyId {
          _id
          type {
            _id
            name
          }
          subType {
            _id
            name
          }
          address {
            _id
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          images {
            _id
            name
            url
            order
          }
          price
          mlsId
        }
        transactionId
        transactionEmail
        transactionRole
        teamTransactionOrder
        officeTransactionOrder
        tenantTransactionOrder
        status
        tags
        progress
        createdAt
        updatedAt
        owner {
          _id
          firstName
          lastName
          profileImage
          userName
        }
        shareWith {
          _id
          firstName
          lastName
          userName
          role
          status
          type
          phones {
            type
            value
          }
          emails {
            type
            value
          }
          profileImage
        }
      }
    }
  }
`

export const GET_TRANSACTION_BOARDS_DASHBOARD = gql`
  query getTransactionBoards(
    $orderField: String
    $sortOrder: Int
    $officeId: String
    $searchData: SearchTransaction
    $user: String
    $userPassFilter: UserPassTransactionFilter
    $singleSkip: Int
    $id: String
  ) {
    getTransactionBoards(
      where: { isActive: true, office: $officeId, user: $user }
      sorting: { field: $orderField, order: $sortOrder }
      filter: $searchData
      userPassFilter: $userPassFilter
      _id: $id
      singleSkip: $singleSkip
      singleSorting: { field: "createdAt", order: -1 }
      applySortingOnAll: true
    ) {
      _id
      name
      boardOrder
      icon {
        _id
        key
        imageUrl
      }
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      transactions {
        _id
        propertyId {
          _id
          type {
            _id
            name
          }
          subType {
            _id
            name
          }
          address {
            _id
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          images {
            _id
            name
            url
            order
          }
          price
          mlsId
        }
        transactionId
        transactionEmail
        transactionRole
        status
        tags
        progress
        createdAt
        updatedAt
        teamTransactionOrder
        officeTransactionOrder
        tenantTransactionOrder
        owner {
          _id
          firstName
          lastName
          profileImage
          userName
        }
        shareWith {
          _id
          firstName
          lastName
          userName
          role
          status
          type
          phones {
            type
            value
          }
          emails {
            type
            value
          }
          profileImage
          userName
        }
      }
    }
  }
`

export const GET_RECENTLY_VIEW_TRANSACTIONS = gql`
  query getRecentlyViewTransactions {
    getRecentlyViewTransactions {
      _id
      propertyId {
        _id
        type {
          _id
          name
        }
        subType {
          _id
          name
        }
        address {
          _id
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        images {
          _id
          name
          url
          order
        }
        price
        mlsId
      }
      transactionId
      transactionEmail
      transactionRole
      status
      tags
      progress
      createdAt
      updatedAt
      teamTransactionOrder
      officeTransactionOrder
      tenantTransactionOrder
      owner {
        _id
        firstName
        lastName
        profileImage
        userName
      }
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
        userName
      }
    }
  }
`
