import Griddle from 'griddle-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { scrollbars, x, y } from 'design/Styled'

interface ImageProps {
  backgroundImage: string
}

export const Container = styled.section`
  ${y};
  flex: 1;
  overflow: hidden;
`

export const TableContainer = styled.div`
  ${x};
  ${scrollbars};
  flex: 1;
  align-items: flex-start;
  background: ${Colors.Container};
  box-shadow: 0 0 12px -8px ${Colors.KanbanShadow};
  overflow: auto;
`

export const StyledGriddle = styled(Griddle)`
  &.griddle-cell {
    height: 50px;
  }

  &.griddle-table {
    width: 100%;
  }

  .griddle-table-heading {
    color: red !important;
  }
`

export const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin: 0px 5px;
  display: inline-block;
`

export const Infograph = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`
