// Import Packages
import { Button, Card, Dropdown, Label, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Manager from 'design/icons/contacts/icon-manager.png'

// Import Colors
import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background?: string
  color?: string
}

const Container = styled.div`
  min-height: 15px;
`
const CardWrapper = styled(Card)`
  &.ui.card {
    margin: 0 auto;
    border-radius: 0px;
    width: 95%;
    background: ${Colors.White1000};
    border: 1px solid ${Colors.Blue910};
  }
`

const CardContent = styled(Card.Content)`
  display: flex;
  padding: 20px 0px 6px 20px;
`

const ExtraContent = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 20px 20px;
  margin-top: 5px;
  margin-bottom: -12px;
`

const Owner = styled.div`
  display: flex;
  color: ${Colors.Black550};
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
`

const Status = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  display: inherit;
`

const Added = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  color: ${Colors.Black550};
  flex-grow: 1;
  justify-content: flex-end;
`

const Divider = styled.div`
  border-bottom: 1px solid ${Colors.DarkBlue260};
  width: 90%;
  margin: 0 auto;
`

const OwnerIcon = styled.div`
  background: url(${Manager});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 5px;
  display: inline-block;
`

const Profile = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black500};
  align-items: center;
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 23px;
  height: 23px;
  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Score = styled.div`
  margin-top: 6px;
`

const ProfileProgressContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  cursor: pointer;
`

const Circle = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: ${ props => props.color || props.color};
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4px;
  bottom: 0;
  left: 4px;
  right: 0;
`

const Content = styled.div`
  margin-left: 20px;
  font-size: 12px;
  flex-grow: 1;
`

const LeadName = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${Colors.Black500};
  text-transform: capitalize;
`

const Type = styled.div`
  color: ${Colors.Black500};
`

const Address = styled.div`
  color: ${Colors.Black500};

  .fa-map-marker-alt {
    margin-right: 6px;
  }
`

const Price = styled.div`
  color: ${Colors.Black500};

  .fa-money-bill-alt{
    margin-right: 6px;
  }
`

const CreatedDate = styled.div`
  color: ${Colors.Black550};
`

const Schedule = styled.div`
  display: flex;
  flex-direction: column;

  .fa-phone {
    color: ${Colors.Black550};
    margin-bottom: 15px;
    margin-top: 5px;
    transform: rotate(100deg);
    width: 13px;
    height: 15px;
    cursor: pointer;
  }

  .fa-envelope {
    color: ${Colors.Black550};
    margin-bottom: 15px;
    cursor: pointer;
  }

  .fa-sms {
    color: ${Colors.Black550};
    cursor: pointer;
  }
`

const StyledDropdown = styled(Dropdown)`
  &.ui.inline.dropdown {
    width: 70px;
    color: ${Colors.Black550};
  }

  &.ui.inline.dropdown>.text {
    font-weight: 100;
  }
`

const StyledLabel = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

const StyledStatusDropdown = styled(Dropdown)`
  background: ${(props: Props) => props.background || props.background};
  display: flex !important;
  width: auto;
  min-width: 40px;
  margin-left: 5px;
  margin-right: 5px;

  &.ui.inline.dropdown {
    width: 80px;
  }

  &.ui.dropdown .menu>.item{
    line-height: 0.6em;
  }
  &.ui.inline.dropdown .dropdown.icon {
    color: ${Colors.White1000};
    flex-grow: 1;
    align-self: center;
    text-align: right;
    margin-right: 10px;
  }

  &.ui.inline.dropdown>.text {
    color: ${Colors.White1000};
    margin-left: 5px;
    font-weight: 500;
    flex-grow: 1;
  }

  &.actionDropdown {
    text-align: left !important;
  }
`

const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;

  div {
    ${y};
    height: 100%;
    color: ${Colors.DarkBlue220};
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
  }
`

const AcceptButton = styled(Button)`
  &.ui.button {
    font-size: 12px;
    padding: 6px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0px;
    color: ${Colors.White1000};
    background: ${Colors.DarkBlue200};
  }
`

export {
  AcceptButton,
  Added,
  Address,
  CardContent,
  CardWrapper,
  Circle,
  Container,
  Content,
  CreatedDate,
  Divider,
  ExtraContent,
  LeadName,
  Owner,
  OwnerIcon,
  Price,
  Profile,
  ProfileImage,
  ProfileProgressContainer,
  Schedule,
  Score,
  Status,
  StyledDropdown,
  StyledLabel,
  StyledStatusDropdown,
  Type,
  Li,
  MemberProfile,
  StyledPopup,
  Labels
}
