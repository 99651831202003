import * as React from 'react'

import {
  SliderContainer,
  SliderGroupContent,
  SliderMaxRange,
  SliderMinRange,
  SliderRangeContainer,
  SliderTitle,
  StyledOutput,
  StyledSlider
} from './Styled'

const SliderGroup = ({
  disabled,
  index,
  max,
  min,
  title,
  value,
  width,
  onChange
}: {
  disabled: boolean
  index?: number
  max: number
  min: number
  title: string
  value: number
  width?: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <SliderGroupContent width={width}>
      <SliderTitle>{title}</SliderTitle>
      <SliderContainer>
        <StyledSlider type="range" name={`slider${title}`} min={min} max={max} value={value} onChange={onChange} index={index} step={'500'} disabled={disabled} />
        <StyledOutput value={value} min={min} max={max}>{`$${value}`}</StyledOutput>
      </SliderContainer>
      <SliderRangeContainer>
        <SliderMinRange>{`$${min / 1000}`}</SliderMinRange>
        <SliderMaxRange>{`$${max / 1000} K`}</SliderMaxRange>
      </SliderRangeContainer>
    </SliderGroupContent>
  )
}

export default SliderGroup
