// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Button, Form, Select } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import GridView from 'shared/WorkflowGrid'
import { GET_TASK } from 'queries/graphql/Tasks/Queries'
import Toast from 'shared/Toast'
import { getLeadDetails, getLeadsWorkflow, getLeadTask } from '../../../Dashboard/LeadQueries'
import { getLeadActivities } from '../../../Dashboard/LeadQueries'
import AddWorkflowTasksModal from '../Workflow/AddWorkflowTasksModal'
import {
  Checked,
  CustomActions,
  CustomAssignedTo,
  CustomCheckBox,
  CustomDescription,
  CustomDueDate,
  CustomDueIn,
  CustomHeaderCheckBox,
  CustomStatus,
  CustomTask
} from './CustomComponents'
import Tables from './Table'

// Import Store Types, Actions and Reducers
import client from 'queries/apollo'
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Leads/Actions'
import { Activities, LeadItemsDetails, LeadShareWith, TaskType, WorkflowOption, WorkflowType } from 'store/Leads/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import {
  applyWorkflow,
  ChangeLeadTaskToggleCheckBox,
  createLeadTask,
  deleteLeadTask,
  deleteTasks,
  ToggleLeadCheckBox,
  ToggleLeadTaskSingleCheckBox,
  updateBulkTask,
  updateTask
} from '../../../Dashboard/LeadMutations'
import { AddButton, GridContainer, StyledForm, StyledSelectDropdown } from './Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { createEvent } from '../../../../Tasks/EventMutations'
AddIconToLibrary([faPlus])

interface Props {
  setNewLeadWorkflowTask: (data: WorkflowType) => void
  setDeleteLeadWorkflowTask: (data: string) => void
  setLeadWorkflowTask: (data: WorkflowType) => void
  setUpdateLeadWorkflowTask: (data: WorkflowType) => void
  setNewWorkflow: (newData: WorkflowType) => void
  setWorkflow: (newData: WorkflowType) => void
  setDeleteMultipleLeadWorkflowTask: (data: WorkflowType) => void
  setUpdateMultipleLeadWorkflowTask: (data: WorkflowType, status: WorkflowType) => void
  setActivities: (data: Activities) => void
  getActivityCount: (count: number) => void
  workflowTask: WorkflowType[]
  leadData: LeadItemsDetails
  toggleStatus: boolean
}

interface State {
  checked?: boolean
  showAddTaskModal?: boolean
  edit: boolean
  taskDetailObj: TaskType
  leadId: string
  leadOwnerId: string
  leadSharedUser: LeadShareWith[]
  task: string
  taskData: TaskType[]
  workflows: WorkflowType[]
  workflowOptions: WorkflowOption[]
  loader: boolean
  user: {
    _id: string
    leadOwnerId: string
    role: string
  }
}

export let loggedUser: any
export let ToggleCheckBox: any
export let SingleCheckBox: any
export let deleteTask: any
export let ToggleTaskStatus: any
export let editLeadTask: any
export let leadShareWith: any
export let leadOwner: any
export let reassignTask: any

const statusOptions = [
  { key: 0, text: 'Select Action', value: 'Choose' },
  { key: 1, text: 'Delete Task(s)', value: 'Delete Task' },
  { key: 2, text: 'In Progress', value: 'In Progress' },
  { key: 3, text: 'Completed', value: 'Completed' }
]

class Workflow extends React.Component<Props, State> {
  public state = {
    checked: false,
    edit: false,
    leadId: '',
    leadOwnerId: '',
    leadSharedUser: [],
    loader: false,
    showAddTaskModal: false,
    task: '',
    taskData: [],
    taskDetailObj: {} as TaskType,
    user: {
      _id: '',
      leadOwnerId: '',
      role: ''
    },
    workflowOptions: [],
    workflows: []
  }

  public columnMetaDataForActionWorkflow = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomTask,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.task,
      title: Strings.leads.columnWorkflowMetaData.title.task
    },
    {
      customComponent: CustomDescription,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.taskDescription,
      title: Strings.leads.columnWorkflowMetaData.title.taskDescription
    },
    {
      customComponent: CustomDueIn,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.dueIn,
      title: Strings.leads.columnWorkflowMetaData.title.dueIn
    },
    {
      customComponent: CustomAssignedTo,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.assignedTo,
      sortable: false,
      title: Strings.leads.columnWorkflowMetaData.title.assignedTo
    },
    {
      customComponent: CustomStatus,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.status,
      title: Strings.leads.columnWorkflowMetaData.title.status
    },
    {
      customComponent: CustomActions,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.Action,
      sortable: false,
      title: Strings.leads.columnWorkflowMetaData.title.Action
    }
  ]

  public columnMetaDataForWorkflow = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomTask,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.task,
      title: Strings.leads.columnWorkflowMetaData.title.task
    },
    {
      customComponent: CustomDescription,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.taskDescription,
      title: Strings.leads.columnWorkflowMetaData.title.taskDescription
    },
    {
      customComponent: CustomDueIn,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.dueIn,
      title: Strings.leads.columnWorkflowMetaData.title.dueIn
    },
    {
      customComponent: CustomAssignedTo,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.assignedTo,
      sortable: false,
      title: Strings.leads.columnWorkflowMetaData.title.assignedTo
    },
    {
      customComponent: CustomStatus,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.status,
      title: Strings.leads.columnWorkflowMetaData.title.status
    }
  ]

  public columnMetaDataForTasks = [
    {
      customComponent: CustomTask,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.task,
      title: Strings.leads.columnWorkflowMetaData.title.task
    },
    {
      customComponent: CustomDescription,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.taskDescription,
      title: Strings.leads.columnWorkflowMetaData.title.taskDescription
    },
    {
      customComponent: CustomDueDate,
      enhanceWithRowData: true,
      id: Strings.leads.columnWorkflowMetaData.id.dueIn,
      title: Strings.leads.columnWorkflowMetaData.title.dueIn
    }
  ]

  public async componentDidMount() {
    const { setLeadWorkflowTask, leadData } = this.props
    leadShareWith = leadData.shareWith
    leadOwner = leadData.owner
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = {
      _id: user._id,
      leadOwnerId: leadData._id,
      role: user.role
    }
    const tasks = await getLeadTask(leadData._id)
    const options = await getLeadsWorkflow()
    let workflowDropOptions: any = []
    if (options) {
      workflowDropOptions = options.workflows.map((item: any) => {
        return {
          key: item._id,
          text: item.name,
          value: `${item.name}, ${item._id}`
        }
      })
    }
    setLeadWorkflowTask(tasks)
    this.setState({
      leadId: leadData._id,
      leadOwnerId: leadData.owner._id,
      leadSharedUser: leadData.shareWith,
      user: {
        _id: user._id,
        leadOwnerId: leadData.owner._id,
        role: user.role
      },
      workflowOptions: workflowDropOptions,
      workflows: options.workflows
    })
    this.assignToggleCheckBox()
    this.assignSingleCheckBox()
    this.delete()
    this.changeTaskStatus()
    this.editTask()
    this.changeAssignee()
  }

  public render() {
    const {
      showAddTaskModal,
      edit,
      taskDetailObj,
      task,
      taskData,
      workflowOptions,
      user,
      loader,
      leadSharedUser
    } = this.state
    const { workflowTask, toggleStatus } = this.props
    return (
      <GridContainer>
        {showAddTaskModal && (
          <AddWorkflowTasksModal
            leadSharedUser={leadSharedUser}
            closeModal={this.closeModal}
            user={user}
            addWorkflowTask={this.addWorkflowTask}
            edit={edit}
            editDetailObj={taskDetailObj}
            addEvent={this.addEvent}
          />
        )}
        {
          <StyledForm>
            {(user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === user.leadOwnerId) && (
              <>
                <div className="task">
                  <Form.Field
                    className="transaction"
                    value={task}
                    control={Select}
                    options={workflowOptions}
                    placeholder="Select Workflow"
                    onChange={this.handleChange}
                    disabled={toggleStatus}
                  />
                  {task ? (
                    !loader ? (
                      <>
                        <Form.Field
                          style={{ marginLeft: 5 }}
                          control={Button}
                          content={'APPLY WORKFLOW'}
                          onClick={this.applyWorkflow}
                          disabled={loader}
                        />
                        <Form.Field
                          className="cancel"
                          control={Button}
                          content={'CANCEL'}
                          onClick={this.handleCancelTask}
                        />
                      </>
                    ) : (
                      <Button basic={true} loading={true} style={{ height: 32, marginLeft: 15 }}>
                        Loading
                      </Button>
                    )
                  ) : null}
                </div>
                <div>
                  {' '}
                  <StyledSelectDropdown
                    inline={true}
                    options={statusOptions}
                    placeholder="Select Action"
                    onChange={this.handleActionChange}
                    disabled={toggleStatus}
                  />
                </div>
              </>
            )}
            {user.role !== 'GUEST' && (
              <AddButton style={{ visibility: task || toggleStatus ? 'hidden' : 'visible' }}>
                <span onClick={this.showAddTaskModal}>
                  <FontAwesomeIcon icon={['fas', 'plus']} />
                </span>
              </AddButton>
            )}
          </StyledForm>
        }
        {task && <Tables task={taskData} workflow={workflowTask} />}
        {!task &&
          (user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === user.leadOwnerId) && (
            <GridView data={workflowTask} tableHeight={50} columnMetaData={this.columnMetaDataForActionWorkflow} />
          )}
        {!task &&
          (user.role !== 'ADMIN' && user.role !== 'MANAGER' && user._id !== user.leadOwnerId) && (
            <GridView data={workflowTask} tableHeight={50} columnMetaData={this.columnMetaDataForWorkflow} />
          )}
      </GridContainer>
    )
  }

  public componentWillReceiveProps(nextProps: any, prevProps: any) {
    leadShareWith = nextProps.shareWith
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const res = value.split(', ')
    const { workflows } = this.state
    workflows.forEach((element: any) => {
      if (element._id === res[1]) {
        this.setState({ taskData: element.workflowTasks, task: value, loader: false })
      }
    })
  }

  private addEvent = async (newEvent: any) => {
    const { leadData } = this.props
    newEvent = { ...newEvent, leadId: leadData._id }
    const response = await createEvent(newEvent, false)
    if (response) {
      Toast({ message: 'Event created Successfully', autoClose: 2000, progress: 0 })
    }
    this.setActivities()
  }

  private handleActionChange = async (e: any, { value }: any) => {
    const { checked, leadId } = this.state
    const { setDeleteMultipleLeadWorkflowTask, setLeadWorkflowTask, setUpdateMultipleLeadWorkflowTask } = this.props
    const data: any = client.readQuery({
      query: GET_TASK,
      variables: { leadId }
    })
    const taskIds: any = []
    data.getTasks.forEach((task: any) => {
      if (task.checked === true) {
        taskIds.push(task._id)
      }
    })
    if (value === 'Delete Task') {
      if (taskIds.length > 0) {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesDeleteIt,
          showCancelButton: true,
          text: 'Remove selected task(s)',
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async (result: any) => {
          if (result.value) {
            if (checked === true) {
              ToggleCheckBox()
            } else {
              const response = await ToggleLeadCheckBox(leadId)
              setLeadWorkflowTask(response)
            }
            await deleteTasks(taskIds, leadId)
            setDeleteMultipleLeadWorkflowTask(taskIds)
            this.setActivities()
            ConfirmAlert(Strings.kanbanView.deleted, 'Your task(s) has been deleted.', 'success')
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, 'Your task(s) is safe', 'error')
          }
        })
      } else {
        ConfirmAlert(Strings.kanbanView.error, Strings.tasks.errorTaskText, 'error')
      }
    } else if (value === 'In Progress' || value === 'Completed') {
      if (taskIds.length > 0) {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepIt,
          confirmButtonText: Strings.kanbanView.yesUpdateIt,
          showCancelButton: true,
          text: Strings.kanbanView.updateRecord,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(async (result: any) => {
          if (result.value) {
            if (checked === true) {
              ToggleCheckBox()
            } else {
              const response = await ToggleLeadCheckBox(leadId)
              setLeadWorkflowTask(response)
            }
            await updateBulkTask(taskIds, value, leadId)
            setUpdateMultipleLeadWorkflowTask(taskIds, value)
            this.setActivities()
            ConfirmAlert(Strings.kanbanView.updated, Strings.kanbanView.updatedCard, 'success')
          } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
            ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
          }
        })
      } else {
        ConfirmAlert(Strings.kanbanView.error, Strings.tasks.errorUpdateTaskText, 'error')
      }
    }
  }

  private handleCancelTask = () => {
    this.setState({ task: '', taskData: [] })
    const { checked } = this.state
    if (checked === true) {
      ToggleCheckBox()
    }
  }

  private applyWorkflow = async () => {
    const { task, leadId } = this.state
    const { setLeadWorkflowTask } = this.props
    this.setState({
      loader: true
    })
    const res = task.split(', ')
    const response = await applyWorkflow(res[1], leadId)
    setLeadWorkflowTask(response.data.applyWorkflow)
    this.handleCancelTask()
    this.setActivities()
  }

  private getLeadTask = async () => {
    const { leadData, setLeadWorkflowTask } = this.props
    const tasks = await getLeadTask(leadData._id)
    setLeadWorkflowTask(tasks)
    this.setActivities()
    Checked(true)
    this.setState({ checked: false })
  }

  private showAddTaskModal = async () => {
    const { leadId } = this.state
    const leads = await getLeadDetails(leadId)
    this.setState({
      leadSharedUser: leads.shareWith,
      showAddTaskModal: true,
      taskDetailObj: {} as TaskType
    })
    this.getLeadTask()
  }

  private closeModal = () => {
    this.setState({ showAddTaskModal: false })
  }

  private editTask = () => {
    editLeadTask = async (taskDetail: TaskType | any) => {
      const { leadId } = this.state
      const leads = await getLeadDetails(leadId)
      this.setState({ edit: true, showAddTaskModal: true, taskDetailObj: taskDetail, leadSharedUser: leads.shareWith })
      this.getLeadTask()
    }
  }

  private changeTaskStatus = () => {
    ToggleTaskStatus = async (taskId: string, value: string) => {
      const { setUpdateLeadWorkflowTask } = this.props
      const res: any = await updateTask(taskId, value, undefined)
      setUpdateLeadWorkflowTask(res.data.updateTask)
      this.getLeadTask()
    }
  }

  private changeAssignee = () => {
    reassignTask = async (taskId: string, userId: string) => {
      const { setUpdateLeadWorkflowTask } = this.props
      const res: any = await updateTask(taskId, undefined, userId)
      setUpdateLeadWorkflowTask(res.data.updateTask)
      this.setActivities()
    }
  }

  private delete = () => {
    deleteTask = async (taskId: string) => {
      const { setDeleteLeadWorkflowTask } = this.props
      const { leadId } = this.state
      setDeleteLeadWorkflowTask(taskId)
      deleteLeadTask(taskId, leadId)
    }
  }

  private addWorkflowTask = async (task: TaskType | any) => {
    const { edit, leadId, leadOwnerId, user } = this.state
    const { setNewLeadWorkflowTask, setUpdateLeadWorkflowTask } = this.props
    const response: any = await createLeadTask(task, leadId, edit, leadOwnerId, user)
    if (edit) {
      setUpdateLeadWorkflowTask(response.data.updateTask)
    } else {
      setNewLeadWorkflowTask(response.data.createTask)
    }
  }

  private assignToggleCheckBox = () => {
    ToggleCheckBox = async () => {
      const { checked, leadId } = this.state
      const { setLeadWorkflowTask } = this.props
      this.setState({ checked: !checked })
      Checked(checked)
      const data = await ChangeLeadTaskToggleCheckBox(checked, leadId)
      setLeadWorkflowTask(data)
    }
  }

  private assignSingleCheckBox = () => {
    SingleCheckBox = async (id: string) => {
      const { leadId } = this.state
      const { setLeadWorkflowTask } = this.props
      const data = await ToggleLeadTaskSingleCheckBox(id, leadId)
      setLeadWorkflowTask(data)
    }
  }

  private setActivities = async () => {
    const { leadData, setActivities, getActivityCount } = this.props
    const activities = await getLeadActivities(leadData._id)
    setActivities(activities)
    getActivityCount(activities.length)
  }
}

const mapStateToProps = (state: AppState) => ({
  workflowTask: state.leads.workflowTask
})

export default connect(
  mapStateToProps,
  {
    setActivities: Actions.getActivities,
    setDeleteLeadWorkflowTask: Actions.deleteLeadWorkflowTask,
    setDeleteMultipleLeadWorkflowTask: Actions.deleteMultipleLeadWorkflowTask,
    setLeadWorkflowTask: Actions.getLeadWorkflowTask,
    setNewLeadWorkflowTask: Actions.createLeadWorkflowTask,
    setNewWorkflow: Actions.addWorkflow,
    setUpdateLeadWorkflowTask: Actions.updateLeadWorkflowTask,
    setUpdateMultipleLeadWorkflowTask: Actions.updateMultipleLeadWorkflowTask,
    setWorkflow: Actions.getWorkflow
  }
)(Workflow)
