import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, x, y } from 'design/Styled'

export const Container = styled.section`
  ${y};
  margin: 0.5em 0;
  color: ${Colors.Text};
`

export const Row = styled.div`
  ${x};
  ${children};
  margin-bottom: 0.5em;
`

export const Copy = styled(Row)`
  img {
    padding: 0 6px;
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Title = styled.h3`
  ${x};
  margin: 0;
  margin-bottom: 0.5em;
  font: ${fonts.xSmall.bold};
`

export const Image = styled.img`
  width: 20px;
`

export const Text = styled.span`
  font: ${fonts.xxSmall.regular};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: all;
`

export const StyledPopup = styled(Popup)``
