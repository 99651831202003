// Import Packages
import * as React from 'react'
import { Tab } from 'semantic-ui-react'

// Import Components
import AppearanceComponent from './Appearance'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { Container } from './Styled'

interface Props {
  setColor: (color: string) => void
  setNewIcon: (id: string, icon: string) => void
  setNewDescription: (id: string, description: string) => void
  closeActionPopup: () => void
  ActionComponent: React.ReactType
  color: string
  title: string
  boardId: string
  icons: any[]
  setIcon: string
  description: string
  appearance?: boolean
  showAddButton?: boolean
  showAppearance?: boolean
}

interface State {
  activeIndex: number
}

class KanbanLaneHeaderPopup extends React.Component<Props, State> {
  public state = {
    activeIndex: 0
  }

  public render() {
    const {
      ActionComponent,
      boardId,
      color,
      icons,
      description,
      setIcon,
      showAddButton,
      showAppearance,
      title
    } = this.props
    const { activeIndex } = this.state
    const panes = [
      {
        menuItem: `${Strings.kanbanView.actions}`,
        render: () => (
          <ActionComponent
            title={title}
            boardId={boardId}
            showAddButton={showAddButton}
            closeActionPopup={this.closeActionPopup}
            showAppearance={showAppearance}
          />
        )
      },
      {
        menuItem: `${showAppearance ? Strings.kanbanView.appearance : ''}`,
        render: () => (
          <AppearanceComponent
            description={description}
            boardId={boardId}
            color={color}
            onChange={this.fetchSetColor}
            icons={icons}
            setIcon={setIcon}
            setNewIcon={this.setNewIcon}
            setNewDescription={this.setNewDescription}
          />
        )
      }
    ]

    return (
      <Container>
        <Tab
          menu={{ secondary: true, pointing: true }}
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
          panes={panes}
        />
      </Container>
    )
  }

  private closeActionPopup = () => {
    const { closeActionPopup } = this.props
    closeActionPopup()
  }

  private handleTabChange = (e: any, { activeIndex }: any) => {
    const { appearance, showAppearance } = this.props
    if (showAppearance) {
      this.setState({ activeIndex })
    } else {
      if (!appearance) {
        this.setState({ activeIndex })
      }
    }
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    setNewDescription(id, description)
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    setNewIcon(id, icon)
  }

  private fetchSetColor = (color: string) => {
    const { setColor } = this.props
    setColor(color)
  }
}

export default KanbanLaneHeaderPopup
