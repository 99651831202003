// Import Packages
import { Card, Dropdown, Label, Table } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background: string
}

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};

  .ui.loader {
    top: 50%;
    left: 72%;
  }
`

const LeftPanel = styled.div`
  width: 40%;
  height: auto;

  h3.ui.header {
    padding-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    color: ${Colors.DarkBlue205};
    text-transform: uppercase;
    font-size: 16px;
  }

  .ui.dividing.header {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }

  .ui.grid {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-top: 0rem;
    ${scrollbars};
  }

  .ui.grid > .row {
    margin-top: 5px !important;
  }

  .ui.grid > [class*='two column'].row > .column {
    font-size: 12px;
    color: ${Colors.Black400};
  }
`

const StyledCard = styled(Card)`
  &.ui.card,
  .ui.cards > .card {
    border-radius: 0;
    box-shadow: #e7ebef -2px 0px 13px 2px;
  }

  &.ui.card > .content,
  .ui.cards > .card > .content {
    text-align: center;
  }

  &.ui.card > .content > .header:not(.ui),
  .ui.cards > .card > .content > .header:not(.ui) {
    font-size: 30px;
    margin-top: 0.48575em;
    margin-bottom: 5px;
    color: ${(props: Props) => props.background || props.background};
  }

  &.ui.card .meta,
  .ui.cards > .card .meta {
    font-weight: 600;
    color: ${Colors.Black500};
    font-size: 12px;
  }

  .ui.small.image {
    width: 60px;
  }
`

const DescriptionWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  .trend {
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
  }

  .month {
    font-size: 10px;
  }
`

const RightPanel = styled.div`
  background: ${Colors.Grey110};
  width: 60%;
  height: auto;

  .ui.mini.form {
    position: absolute;
    top: 24px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ui.mini.form > span {
    margin-right: 6px;
    font-weight: 600;
  }

  .ui.grid {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    overflow: auto;
    height: 500px;
    ${scrollbars};
  }

  .ui.grid > [class*='two column'].row > .column {
    font-size: 12px;
    color: ${Colors.Black400};
  }

  .title {
    margin-top: 10px;
  }

  img.ui.image {
    width: 170px;
    height: 110px;
  }

  h3.ui.header {
    padding-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    color: ${Colors.DarkBlue205};
    text-transform: uppercase;
    font-size: 12px;
  }

  .ui.dividing.header {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }
`

const Content = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
`

const Configure = styled.div`
  .ui.button {
    font-size: 0.7rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    white-space: nowrap;
    width: 100px;
  }
`

const Icons = styled.div`
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: 50%;
  background-color: ${Colors.DarkBlue215};
  align-items: center;
  justify-content: center;
  display: flex;
  border: 2px solid ${Colors.White556};
  margin-right: 15px;

  .svg-inline--fa {
    width: 20px;
    height: 20px;
    color: ${Colors.White556};
  }
`

const Text = styled.div`
  font-size: 11px;
  margin-right: 15px;
  color: ${Colors.DarkBlue205};
  text-align: justify;

  div:nth-child(1) {
    font-size: 13px;
    font-weight: 600;
    color: ${Colors.Black400};
    margin-bottom: 4px;
  }
`

const Wrapper = styled.div`
  overflow: auto;
  height: 450px;
  margin-bottom: 10px;
  ${scrollbars};
`

const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    min-width: 5em;
    padding: 3px;
    min-height: 0px;
    font-size: 10px;
    border-radius: 0;
    padding-left: 8px;
  }

  &.ui.selection.dropdown > .dropdown.icon {
    top: 0.185714em;
  }
`

const StyledTable = styled(Table)`
  &.ui.table {
    border: 0;
    margin-left: 16px;
    margin-right: 15px;
    border-radius: 0;
    width: 94.8%;
    box-shadow: #e7ebef -2px 0px 13px 2px;
  }

  &.ui.table thead {
    font-size: 12px;
  }

  &.ui.table tbody {
    font-size: 12px;
    color: ${Colors.Black500};
  }

  &.ui.sortable.table thead th {
    background: ${Colors.White1000};
    border: 0;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    color: ${Colors.Black500};
  }
`

const ProfileProgressContainer = styled.div`
  position: relative;
  height: 28px;
  width: 28px;
  cursor: pointer;
`

const Circle = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: ${props => props.color || props.color};
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4px;
  bottom: 0;
  left: 4px;
  right: 0;
`

const Activities = styled(Label)`
  &.ui.circular.label,
  .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
    font-size: 10px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

const Type = styled.div`
  background-color: ${(props: Props) => props.background || props.background};
  min-width: 35px;
  max-width: 35px;
  justify-content: center;
  text-align: -webkit-center;
  color: ${Colors.White1000};
  font-size: 10px;
  font-weight: bold;
`

export {
  Activities,
  Configure,
  Container,
  Content,
  Icons,
  LeftPanel,
  RightPanel,
  Text,
  Wrapper,
  StyledDropdown,
  StyledTable,
  ProfileProgressContainer,
  Circle,
  StyledCard,
  DescriptionWrapper,
  Type
}
