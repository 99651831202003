import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, headerMargin, link, x, y } from 'design/Styled'

interface Props {
  background: string
  status: string
}

export const Container = styled.section`
  ${y};
  margin: 0.5em 0;

  .fa-info-circle {
    cursor: pointer;
  }
`

export const Header = styled.header`
  ${x};
  ${children};
  ${headerMargin};
  align-items: center;
  justify-content: space-between;
  font: ${fonts.xSmall.medium};
  color: ${Colors.Text};
`

export const Title = styled.h3`
  ${x};
  margin: 0;
  flex: 1;
  align-items: center;
  font: ${fonts.medium.bold};

  span {
    margin-right: 0.3em;
  }
`

export const InviteLink = styled.a`
  ${link};
`

export const List = styled.ul`
  ${x};
  flex-wrap: wrap;
  margin: 0.5em 0;
  padding: 0;
`

export const ListItem = styled.li`
  ${x};
  width: auto;
  margin-right: 0.5em;
`

export const ProfilePopup = styled(Popup)``

export const ProfileImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  width: 35px;
  height: 35px;
  opacity: ${(props: Props) => (props.status === 'Pending' ? 0.5 : 1)};
  background-color: ${Colors.ButtonPrimary};
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${(props: Props) => props.background || ''});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }
`

export const ProfileInfo = styled.div`
  ${y};
  color: ${Colors.Text};
  font: ${fonts.xSmall.regular};
  text-align: center;
  text-transform: capitalize;

  & > strong,
  & > span {
    margin-bottom: 0.5em;
  }
`

export const Contact = styled.div`
  ${x};
  ${children};
  justify-content: center;

  .fa-phone {
    transform: rotate(100deg);
  }

  .fa-eye {
    color: ${Colors.DarkBlue200};
  }

  .svg-inline--fa {
    margin-right: 6px;
    cursor: pointer;
  }
`

export const InfoPopup = styled(Popup)``
