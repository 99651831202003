// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  background: ${Colors.White1000};
  height: calc(88vh - 70px);
  overflow: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
      background: ${Colors.transparent};
  }
`

const Title = styled.div`
  border-bottom: 2px solid ${Colors.Black765};
  color: ${Colors.DarkBlue200};
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  padding-left: 30px;
`

const StyledForm = styled(Form)`
  padding-left: 25px;
  padding-top: 25px;
  padding-right: 100px;
  margin-bottom: 20px;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.ui.form .company-name {
    display: flex;
    align-items: center;
  }

  &.ui.form .company-name>label {
    width: 120px;
    font-weight: 500;
    color: ${Colors.Black500};
  }

  &.ui.form .field>label {
    font-weight: 500;
    color: ${Colors.Black500};
  }

  &.ui.form .company-name>.ui.input {
    width: 55%;
  }

  &.ui.form input,
  &.ui.form .company-name>.ui.input>input,
  &.ui.form .field>.ui.input>input,
  .ui.selection.dropdown {
    border-radius: 1px !important;
    max-height: 33px;

  }

  .ui.selection.dropdown {
    min-width: 9.7em;

    @media(max-width: 1250px) {
      min-width: 5em;
    }
  }

  &.ui.form .fields .field .ui.input input {
    width: 100px;
  }

  .ui.checkbox .box:before, .ui.checkbox label:before {
    border-radius: 0;
    width: 15px;
    height: 15px;
    border: 1.5px solid ${Colors.Black760};
  }

  .ui.checkbox .box:after, .ui.checkbox label:after {
    font-size: 12px;
    width: 15px;
    height: 15px;
    color: ${Colors.Black760};
  }

  .ui.checkbox>label {
    font-size: 12px;
    color: ${Colors.Black500};
  }
`

const Address = styled.div`
  font-size: 16px;
  color: ${Colors.Black500};
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
`

export {
  Address,
  Container,
  StyledForm,
  Title,
}
