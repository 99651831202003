import * as React from 'react'

import Passes from './Passes'
import Welcome from './Welcome'

import { getLoggedInUser } from 'utils'

import { AddForm, Container } from './Styled'

import { UserType } from './Types'
import Modal from 'shared/Modal'
import PaymentInfo from 'app/UserProfile/ProfileDetail/DetailSection/PaymentInfo'
import { PaymentMethodEnum } from 'app/UserProfile/ProfileDetail/DetailSection/PaymentInfo/Types'

interface State {
  user: UserType
  isModalOpen?: boolean
}

class MainContent extends React.Component<{}, State> {
  public state = {
    user: {} as UserType, 
    isModalOpen: false
  }

  public componentDidMount = async () => {
    const user = await getLoggedInUser({ fromCache: false }) || {} as UserType
    this.setState({ user })
    this.checkAndOpenModal(user)
  }

  private checkAndOpenModal(user: UserType) {
    const { paymentMethod, taxID , routingNumber, bankName, accountNumber} = user
    if (!paymentMethod || !taxID) {
      this.setState({ isModalOpen: true })
    }
    if(paymentMethod === PaymentMethodEnum.Ach || paymentMethod === PaymentMethodEnum.Direct){
      if(!routingNumber || !bankName || !accountNumber){
        this.setState({ isModalOpen: true })
      }
    }
  }

  handleOpenModal = () => {
    this.setState({ isModalOpen: true })
  }


  handleCloseModal = () => {
    this.setState({ isModalOpen: false })
  }

  public render() {
    const { user,isModalOpen } = this.state
    return (
      <Container>
        {user.role !== 'GUEST' && (
          <Passes />
        )}
        {isModalOpen &&
        <Modal content={
          <AddForm>
          <PaymentInfo userID={user?._id} prevComp='dashboard' closeModal={this.handleCloseModal} />
          </AddForm>
        }
        width={600}
        height={250} 
        closeModal={this.handleCloseModal}/>
        }
        <Welcome />

      </Container>
    )
  }

 
}

export default MainContent
