import * as React from 'react'
import { connect } from 'react-redux'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'

import { moveTransaction } from 'app/Transactions/Dashboard/TransactionMutations'
import { getTransactionBoards, getTransactionDocChecklist } from 'app/Transactions/Dashboard/TransactionQueries'

import Carousel from './Carousel'
import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'
import Progress from './Progress'
import PropertyInformation from './PropertyInformation'
import Scrollable from 'shared/Scrollable'
import Tools from './Tools'

import {
  Container,
  Col,
  Header,
} from './Styled'

import { getLoggedInUser } from 'utils'

import { OptionType, TransactionType } from 'app/Transactions/Details/Types'

interface StoreProps {
  commission: any
  setPaymentApprove: (approve: boolean) => void
  setPaymentReleased: (release: boolean) => void
  setTransactionDetail: (data: any) => void
  setTransactionDocumentChecklist: (data: any) => void
  shareWith: any
}

interface OwnProps {
  transaction: TransactionType
}

type Props = OwnProps & StoreProps

interface State {
  showAddTransactionModal: boolean
  statusOptions: OptionType[]
}

class Sidebar extends React.Component<Props, State> {
  public state = {
    showAddTransactionModal: false,
    statusOptions: [] as OptionType[]
  }

  public componentDidMount = async () => {
    const status: any = await getTransactionBoards({})
    const statusOptions: OptionType[] = []
    status.forEach((element: any) => {
      if (element.name !== 'Updated') {
        statusOptions.push({
          key: element._id,
          text: element.name,
          value: element.name
        })
      }
    })
    this.setState({ statusOptions })
  }

  public render() {
    const { commission, shareWith, transaction } = this.props
    const { showAddTransactionModal, statusOptions } = this.state

    return (
      <Container className="rp-transaction-sidebar">
        {showAddTransactionModal && (
          <CreateTransactionModal hideDocs={true} onClose={this.closeModal} transactionId={transaction._id} />
        )}
        <Header>
          <Carousel
            transaction={transaction}
            statusOptions={statusOptions}
            moveTransaction={this.moveTransaction}
          />
          {transaction.propertyId.images.length > 0 && (
            <Tools transaction={transaction} />
          )}
        </Header>
        <Col primary={true} overflow='hidden'>
          <Scrollable>
            <PropertyInformation
              commission={commission}
              people={shareWith}
              transaction={transaction}
              showTransactionModal={this.handleShowTransactionModal}
            />
          </Scrollable>
        </Col>
        <Progress percent={Math.round(transaction.progress * 100.0) / 100.0} />
      </Container>
    )
  }

  private moveTransaction = async (e: any, { value }: any) => {
    const { setPaymentApprove, setPaymentReleased, setTransactionDetail, transaction } = this.props

    const { statusOptions } = this.state

    const user: any = await getLoggedInUser({ fromCache: true })
    if (!user) {
      return
    }

    const status: any = statusOptions.find((item: OptionType) => item.value === value)
    if (!status && user.role !== 'ADMIN' && user.role !== 'MANAGER') {
      return
    }

    switch (value) {
      case 'New':
      case '$$ Received':
      case 'Active':
        setPaymentApprove(false)
        setPaymentReleased(false)
        break

      case 'Payable':
        setPaymentApprove(true)
        setPaymentReleased(false)
        break

      case 'Closed':
        setPaymentApprove(true)
        setPaymentReleased(true)
        break
    }

    const response: any = await moveTransaction(transaction._id, status.key, 0, user)
    if (response) {
      setTransactionDetail(response)
    }
  }

  private closeModal = async (result: any) => {
    const { setTransactionDetail, setTransactionDocumentChecklist } = this.props
    this.setState({ showAddTransactionModal: false })
    if (result) {
      const docChecklist = await getTransactionDocChecklist(result._id)
      setTransactionDetail(result)
      setTransactionDocumentChecklist(docChecklist)
    }
  }

  private handleShowTransactionModal = () => {
    this.setState({ showAddTransactionModal: true })
  }
}

const mapStateToProps = (state: AppState) => ({
  commission: state.transactions.commission,
  shareWith: state.transactions.shareWith
})

export default connect(
  mapStateToProps,
  {
    setPaymentApprove: Actions.paymentApprove,
    setPaymentReleased: Actions.paymentReleased,
    setTransactionDetail: Actions.getTransactionDetail,
    setTransactionDocumentChecklist: Actions.getTransactionDocumentChecklist
  }
)(Sidebar)
