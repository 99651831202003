export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`reportName`]) {
    formIsValid = false
    errors[`reportName`] = 'Can Not be empty'
  }

  if (!fields[`module`]) {
    formIsValid = false
    errors[`module`] = 'Can Not be empty'
  }

  return { errors, formIsValid }
}
