import { Button, Input } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

interface Props {
  justify?: string
  width?: number
}

const x = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: Props) => props.justify || 'flex-start'};
  width: ${(props: Props) => props.width || 100}%;
`

const y = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props: Props) => props.justify || 'flex-start'};
`

const text = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Text};
  margin: 0;
  line-height: 1.5;
`

const button = css`
  min-width: 80px;
  border-radius: 0;
  text-transform: uppercase;
`

export const Container = styled.div`
  ${y};
  width: 100%;
  height: 100%;
  padding: 1em;
`

export const Row = styled.div`
  ${x};
  flex-wrap: wrap;
  margin-bottom: 0.8em;
`

export const Label = styled.label`
  ${text};
  font: ${fonts.small.bold};
  width: 100%;
  margin-bottom: 0.5em;
`

export const Title = styled.h1`
  ${x};
  ${text};
  font: ${fonts.xLarge.bold};
  text-align: left;
  border-bottom: 2px solid ${Colors.EmphasisLine}
  margin-bottom: 0.8em;
  padding: 0.6em 0;
`

export const StyledInput = styled(Input)`
  width: 100%;
`

export const Footer = styled(Row)`
  margin: 1em 0 0;
`

export const Create = styled(Button)`
  &.ui.button {
    ${button};
    background: ${Colors.ButtonPrimary};
    color: ${Colors.White1000};
  }
`

export const Cancel = styled(Button)`
  &.ui.button {
    ${button};
  }
`
