import { sortBy } from 'lodash'
import { LeadDetails, LeadItemsDetails, LeadNotes, LeadState } from './Types'

export const addNewBoard = (state: LeadState, index: number, newData: LeadDetails): LeadDetails[] => {
  const { data } = state
  const feeData = data.slice()
  feeData.splice(index, 0, newData)
  return [...feeData]
}

export const addNewCard = (
  state: LeadState,
  id: string,
  index: string,
  newData: LeadItemsDetails
): LeadState['data'] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      const listLength = list.items.length
      const newList = list.items.slice()
      newList.splice(index ? index : listLength, 0, newData)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return feeData
}

export const setNewIcon = (state: LeadState, id: string, icon: string): LeadDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      return {
        ...list,
        icon: icon
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewDescription = (state: LeadState, id: string, description: string): LeadDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      return {
        ...list,
        description: description
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewBoardName = (state: LeadState, id: string, boardName: string): LeadDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      return {
        ...list,
        type: boardName
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const deleteCard = (state: LeadState, boardId: string, cardId: string): LeadDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === boardId) {
      const newList = list.items.slice()
      const listIndex = newList.findIndex((laneItem: any) => {
        return laneItem.id === cardId
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const restoreCard = (
  state: LeadState,
  id: string,
  index: string,
  newData: LeadItemsDetails | null
): LeadState['data'] => {
  if (newData) {
    const { data } = state
    let feeData = data.slice()
    feeData = feeData.map((list: any) => {
      if (list.id === id) {
        const newList = list.items.slice()
        newList.splice(index, 0, newData)
        return {
          ...list,
          items: newList
        }
      } else {
        return list
      }
    })
    return feeData
  } else {
    return state.data
  }
}

export const addNewNotes = (state: LeadState, newData: any): LeadNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const editNotes = (state: LeadState, newData: any): LeadNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  const noteIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === newData._id
  })
  let note = { ...feeData[noteIndex] }
  note = newData
  feeData[noteIndex] = note
  return [...feeData]
}

export const deleteNotes = (state: LeadState, id: string): LeadNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  const noteIndex = feeData.findIndex((laneItem: any) => {
    return laneItem.id === id
  })
  feeData.splice(noteIndex, 1)
  return [...feeData]
}

export const createWorkflowTask = (state: LeadState, newData: any): LeadState['workflowTask'] => {
  const { workflowTask } = state
  const feeData = workflowTask.slice()
  feeData.push(newData)
  const newdata = sortBy(feeData, ['dueDate'])
  return newdata
}

export const deleteWorkflowTask = (state: LeadState, data: any): LeadState['workflowTask'] => {
  const { workflowTask } = state
  const workflowTaskData = workflowTask.slice()
  const index = workflowTaskData.findIndex((shared: any) => {
    return shared._id === data
  })
  workflowTaskData.splice(index, 1)
  const newdata = sortBy(workflowTaskData, ['dueDate'])
  return newdata
}

export const updateWorkflowTask = (state: LeadState, data: any): LeadState['workflowTask'] => {
  const { workflowTask } = state
  const workflowTaskData = workflowTask.slice()
  const index = workflowTaskData.findIndex((element: any) => {
    return element._id === data._id
  })
  workflowTaskData.splice(index, 1, data)
  const newdata = sortBy(workflowTaskData, ['dueDate'])
  return newdata
}

export const addWorkflow = (state: LeadState, newData: any): LeadState['workflowTask'] => {
  const { workflowTask } = state
  const feeData = workflowTask.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const deleteMultipleWorkflowTasks = (state: LeadState, data: any): LeadState['workflowTask'] => {
  const { workflowTask } = state
  const workflowTaskData = workflowTask.slice()
  const newData: any = []
  workflowTaskData.forEach((workflow: any) => {
    if (data.includes(workflow._id) !== true) {
      newData.push(workflow)
    }
  })
  return newData
}

export const updateMultipleWorkflowTasks = (state: LeadState, data: any, status: string): LeadState['workflowTask'] => {
  const { workflowTask } = state
  let workflowTaskData = workflowTask.slice()
  workflowTaskData = workflowTaskData.map((workflow: any) => {
    if (data.includes(workflow._id) === true) {
      return {
        ...workflow,
        status
      }
    } else {
      return workflow
    }
  })
  return workflowTaskData
}
