// Import Packages
import styled from 'styled-components'

// Import Colors and Fonts
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  img.ui.image {
    width: 50%;
  }
`

const Text = styled.div`
  margin-top: 15px;
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.DarkBlue205};
`

export { Container, Text }
