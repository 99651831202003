import { Popup } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { children, styledToolTip, x, y } from 'design/Styled'

import PngDots from 'design/icons/dashboard/sixDots.png'
import SvgTicket from 'design/icons/dashboard/vector_smart.svg'

import DefaultIcon from 'design/icons/dashboard/ActivityHotsheet.png'

interface Props {
  icon?: string
  size?: number
}

const getThumbnail = (props: Props) => {
  const icon = props.icon || DefaultIcon
  const size = props.size || 4
  return css`
    background-image: url('${icon}');
    background-size: ${size * 0.6}em;
    width: ${size}em;
    height: ${size}em;
  `
}

export const Caret = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  color: ${Colors.TextPrimary};
  transition: opacity 200ms;
`

export const Dots = styled.div`
  background: url(${PngDots});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 18px;
  align-self: center;
  position: absolute;
  top: 60px;
  right: -4px;
  cursor: pointer;
  z-index: 9999;
  transition: opacity 200ms;
`

export const Container = styled.div`
  position: relative;
  background: url(${SvgTicket});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 140px;
  cursor: pointer;
  min-width: 100px;
  margin-right: 15px;

  ${Dots} {
    opacity: 0;
  }
  ${Caret} {
    opacity: 0;
  }

  &:hover {
    ${Dots} {
      opacity: 1;
    }
    ${Caret} {
      opacity: 1;
    }
  }
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 0;
  }
`

export const Settings = styled.div`
  ${y};
  color: ${Colors.TextPrimary};
  font-size: 12px;
  font-weight: 500;
`

export const Option = styled.div`
  ${x};
  ${children};
  padding: 0.5em 1em;
  z-index: 2;
  cursor: pointer;

  &:hover {
    background: ${Colors.EmphasisContainer};
  }

  .svg-inline--fa {
    min-width: 14px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${Colors.Orange250};
  text-transform: capitalize;
  padding: 15px;
`

export const Title = styled.span`
  color: ${Colors.TextPrimary};
  text-transform: uppercase;
  font-size: 0.8rem !important;
  font-weight: bold;
  text-align: center;
  line-height: 1.3;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

export const PassImage = styled.div`
  margin: 0 auto 0.8em auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid ${Colors.ButtonPrimary};
  border-radius: 50%;

  ${getThumbnail};
`

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`
