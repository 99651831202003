import * as React from 'react'

import {
  ToolTip,
  Button,
  Icon,
} from './Styled'

interface Props {
  className: string
  icon: any
  isDisabled: boolean
  label: string
  labelPosition: string
  onClick: (e:any) => void
  size: number
  type: 'primary' | 'danger' | 'light' | 'disable'
}

class BubbleButton extends React.Component<Props, {}> {
  public static defaultProps = {
    className: '',
    isDisabled: false,
    label: '',
    labelPosition: 'top center',
    size: 22,
    type: 'primary',
  }

  public render = () => {
    const {
      className,
      icon,
      isDisabled,
      label,
      labelPosition,
      onClick,
      size,
      type,
    } = this.props

    const style = isDisabled ? 'disable' : type

    const button = (
      <Button
        className={`rp-button-bubble rp-button-bubble-${style} ${className}`}
        onClick={onClick}
        size={size}
        type={style}
      >
        <Icon icon={icon} />
      </Button>
    )

    if (label) {
      return (
        <ToolTip
          position={labelPosition}
          content={label}
          trigger={button}        
        />
      )
    } else {
      return button
    }
  }
}

export default BubbleButton
