import * as React from 'react'

import { Strings } from 'utils'

import { Add, Footer, Label, PageNav, Row } from './Styled'

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faAngleLeft, faAngleRight])

interface Props {
  addNewCard: (id: string, index: string) => void
  boardId: string
  color: string
  itemsLength: number
  showAddButton: boolean
  pagination?: any
  navigatePage?: (boardId: string, skip: number) => void
}

class KanbanLaneFooter extends React.Component<Props> {
  public state = {}

  public render() {
    const { boardId, color, itemsLength, showAddButton } = this.props
    const getBoardIdFunction = () => {
      this.addNewCard(boardId, String(itemsLength + 1))
    }
    return (
      <Footer>
        <Add onClick={getBoardIdFunction} isVisible={showAddButton}>
          {Strings.kanbanView.add}
        </Add>
        <Row>
          <PageNav
            className={this.getNavStatus('previous')}
            color={color}
            onClick={() => this.navigatePage('previous')}
          >
            <Icon icon={['fas', 'angle-left']} />
          </PageNav>
          <Label color={color}>{this.getPaginationLabel()}</Label>
          <PageNav className={this.getNavStatus('next')} color={color} onClick={() => this.navigatePage('next')}>
            <Icon icon={['fas', 'angle-right']} />
          </PageNav>
        </Row>
      </Footer>
    )
  }

  private addNewCard = (id: string, index: string) => {
    const { addNewCard } = this.props
    addNewCard(id, index)
  }

  private navigatePage = (direction: string) => {
    const {
      navigatePage,
      pagination: { pages },
      boardId
    } = this.props
    if (this.getNavStatus(direction) === 'disabled') {
      return
    }
    if (navigatePage) {
      navigatePage(boardId, pages[direction])
    }
  }

  private getNavStatus = (direction: string) => {
    const {
      pagination: { pages }
    } = this.props
    return pages[direction] !== pages.current && pages.total > 0 ? '' : 'disabled'
  }

  private getPaginationLabel = () => {
    const {
      itemsLength,
      pagination: {
        pages: { current }
      }
    } = this.props

    if (itemsLength === 0 && current === 0) {
      return `0 ${Strings.kanbanView.records}`
    }

    return `${Strings.kanbanView.records} ${current + 1} - ${current + itemsLength}`
  }
}

export default KanbanLaneFooter
