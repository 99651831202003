// Import Packages
import * as React from 'react'
import { Icon } from 'semantic-ui-react'

// Import Styled Components
import { StyledCalendar, StyledPopup } from './Styled'

interface Props {
  onChangeDate: (date: any) => void
  handleOpen?: () => void
  handleClose?: () => void
  open?: boolean
  minDate?: boolean
  maxDate?: boolean
  date?: any
}

class DatePicker extends React.Component<Props> {
  public state = {}

  public render() {
    const { open, minDate, maxDate, date } = this.props
    return (
      <StyledPopup
        trigger={<Icon name="calendar alternate outline" />}
        content={
          minDate ? (
            <StyledCalendar onChange={this.onChangeDate} minDate={date ? new Date(date) : undefined} />
          ) : maxDate ? (
            <StyledCalendar onChange={this.onChangeDate} maxDate={date ? new Date(date) : undefined} />
          ) : (
            <StyledCalendar onChange={this.onChangeDate} />
          )
        }
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        position="bottom right"
        on="click"
      />
    )
  }

  private onChangeDate = (date: any) => {
    const { onChangeDate } = this.props
    onChangeDate(date)
  }

  private handleOpen = () => {
    const { handleOpen } = this.props
    if (handleOpen) {
      handleOpen()
    }
  }

  private handleClose = () => {
    const { handleClose } = this.props
    if (handleClose) {
      handleClose()
    }
  }
}

export default DatePicker
