// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'

// Import Images and Icons
import ExportIcon from 'design/icons/users/actions/action_icon_1.png'
import ImportIcon from 'design/icons/users/actions/action_icon_2.png'
import ReportIcon from 'design/icons/users/actions/action_icon_3.png'
import SortIcon from 'design/icons/users/actions/action_icon_4.png'

// Import Components
import { exportUser, importUser, ShowAddUserModal, sortgroupUsers } from '../Users'
import ActionItem from './ActionItem'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Users/Actions'

// Import Utils
import { Strings } from 'utils'

interface Props {
  boardId: string
  closeActionPopup: () => void
  showAddUserPopup: () => void
  showImportUsersPopup: () => void
  showAddButton?: boolean
  title?: string
}

interface State {
  sortOrder: number
}

class ActionsComponent extends React.Component<Props, State>{
  public state = {
    sortOrder: 1
  }

  public render() {
    const { showAddButton, title } = this.props
    return (
      <div>
        {showAddButton && <span onClick={this.showAddUserModal}><ActionItem title={Strings.users.kanbanView.addUser} text={Strings.users.kanbanView.actionText} icon={ReportIcon} /></span>}
        {showAddButton && <span onClick={this.showExportFileModal}><ActionItem title={Strings.users.kanbanView.export} text={Strings.users.kanbanView.actionText} icon={ExportIcon} /></span>}
        {(showAddButton && title !== 'MANAGER') && <span onClick={this.showImportFileModal}><ActionItem title={Strings.users.kanbanView.import} text={Strings.users.kanbanView.actionText} icon={ImportIcon} onClick={this.props.showImportUsersPopup} /></span>}
        <span onClick={this.handleChange}><ActionItem title={Strings.kanbanView.sort} text={Strings.kanbanView.sortAction} icon={SortIcon} /></span>
      </div>
    )
  }

  private showAddUserModal = () => {
    const { closeActionPopup, boardId } = this.props
    ShowAddUserModal(boardId)
    closeActionPopup()
  }

  private showImportFileModal = () => {
    const { closeActionPopup, boardId } = this.props
    importUser(boardId)
    closeActionPopup()
  }

  private showExportFileModal = () => {
    const { closeActionPopup, boardId } = this.props
    exportUser(boardId)
    closeActionPopup()
  }

  private handleChange = () => {
    const { sortOrder } = this.state
    const { boardId } = this.props
    if (sortOrder === 1) {
      this.setState({ sortOrder: -1 })
      sortgroupUsers(boardId, this.state.sortOrder)
    } else {
      this.setState({ sortOrder: 1 })
      sortgroupUsers(boardId, this.state.sortOrder)
    }
  }
}

const mapStateToProps = (state: AppState) => ({
})

export default connect(
  mapStateToProps,
  {
    showAddUserPopup: Actions.showAddUserPopup,
    showImportUsersPopup: Actions.showImportUsersPopup
  }
)(ActionsComponent)
