// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

// Import Components
import { isPlanAllowed } from 'shared/Billing/Queries'
import Modal from 'shared/Modal'
import { serverToast } from 'shared/Toast/Toast'
import { assistantAdminMockData, assistantUsersMockData } from './AssistantMockData'

// Import Actions and Reducer
import * as Actions from 'store/Dashboard/Actions'

// Import Images and Icons
import Colors from 'design/Colors'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Styled Components
import {
  Configure,
  Container,
  Content,
  Images,
  LeftPanel,
  Text,
  Wrapper,
} from './Styled'

// Font Awesome Icons
import {
  faCheckSquare,
  faCog,
  faEnvelopeOpenText,
  faNetworkWired,
} from '@fortawesome/pro-light-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCheckSquare, faEnvelopeOpenText, faCog, faNetworkWired])

export interface UserType {
  _id: string
  role: string
  profileImage: string
}

interface StoreProps {
  setShowWorkflowTabInSettings: (data: boolean) => void
  setShowNotificationTabInSettings: (data: boolean) => void
  setShowChecklistTabInSettings: (data: boolean) => void
  setShowFeeTabInSettings: (data: boolean) => void
  setShowTaxTabInSettings: (data: boolean) => void
  setToggleSignatureModal: (data: boolean) => void
}

interface OwnProps {
  closeModal: (data?: boolean) => void
}

type Props = StoreProps & OwnProps & RouteComponentProps<{}>
interface State {
  setup: any
  animate: boolean
  user: UserType
}

class MyAssistant extends React.Component<Props, State> {
  public state = {
    animate: true,
    setup: [],
    user: {} as UserType
  }

  public async componentDidMount() {
    const { setShowNotificationTabInSettings, setShowWorkflowTabInSettings, setShowChecklistTabInSettings, setShowFeeTabInSettings, setShowTaxTabInSettings } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    if (user.role === 'ADMIN') {
      this.setState({ setup: assistantAdminMockData })
    } else {
      this.setState({ setup: assistantUsersMockData })
    }
    this.setState({ user })
    setShowTaxTabInSettings(false)
    setShowFeeTabInSettings(false)
    setShowNotificationTabInSettings(false)
    setShowWorkflowTabInSettings(false)
    setShowChecklistTabInSettings(false)
  }

  public render() {
    const { animate, setup } = this.state
    const myAssistant = setup.map((data: any, index: number) => (
      <Content key={index}>
        <Images background={data.icon} />
        <Text><div style={{ marginTop: data.title === 'Let’s get started by creating a transaction!' ? 15 : 0 }}>{data.title}</div><div>{data.text}</div></Text>
        <Configure onClick={() => this.goToDetail(data.title)}><Button content={data.button} style={{ background: data.button === 'ALL SET TO GO' ? Colors.DarkBlue510 : Colors.DarkBlue200 }} /></Configure>
      </Content>
    ))
    return (
      <>
        <Modal content={
          <Container>
            <LeftPanel>
              <h3 className="ui dividing header">{Strings.dashboard.passes.myAssistant}</h3>
              <Wrapper>
                {myAssistant}
              </Wrapper>
            </LeftPanel>
          </Container>
        } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={1000} />
      </>
    )
  }

  private goToDetail = async (title: string) => {
    const { user } = this.state
    if (title === 'Complete Profile') {
      this.props.history.push({
        pathname: `${Routes.primary.profile.path}/${user._id}`
      })
    } else if (title === 'Setup Workflows') {
      const { setShowWorkflowTabInSettings } = this.props
      setShowWorkflowTabInSettings(true)
      this.props.history.push({
        pathname: `${Routes.settings.root}`
      })
    } else if (title === 'Configure Notifications') {
      const { setShowNotificationTabInSettings } = this.props
      setShowNotificationTabInSettings(true)
      this.props.history.push({
        pathname: `${Routes.settings.root}`
      })
    } else if (title === 'Checklist Manager') {
      const { setShowChecklistTabInSettings } = this.props
      setShowChecklistTabInSettings(true)
      this.props.history.push({
        pathname: `${Routes.settings.root}`
      })
    } else if (title === 'Tax Setup') {
      const { setShowTaxTabInSettings } = this.props
      setShowTaxTabInSettings(true)
      this.props.history.push({
        pathname: `${Routes.settings.root}`
      })
    } else if (title === 'Fee Settings') {
      const { setShowFeeTabInSettings } = this.props
      setShowFeeTabInSettings(true)
      this.props.history.push({
        pathname: `${Routes.settings.root}`
      })
    } else if (title === 'Let’s get started by creating a transaction!') {
      try { await isPlanAllowed('transaction') } catch (error) { return serverToast(error) }
      this.closeModal(true)
    } else if (title === 'Setup Email Signature') {
      const { setToggleSignatureModal } = this.props
      setToggleSignatureModal(true)
      this.props.history.push({
        pathname: `${Routes.primary.mailbox.path}`
      })
    }
  }

  private closeModal = (data?: boolean) => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal(data)
    }, 300)
  }
}

export default withRouter(connect(
  null,
  {
    setShowChecklistTabInSettings: Actions.showChecklistTabInSettings,
    setShowFeeTabInSettings: Actions.showFeeTabInSettings,
    setShowNotificationTabInSettings: Actions.showNotificationTabInSettings,
    setShowTaxTabInSettings: Actions.showTaxTabInSettings,
    setShowWorkflowTabInSettings: Actions.showWorkflowTabInSettings,
    setToggleSignatureModal: Actions.toggleSignatureModal
  }
)(MyAssistant))
