// Import Packages
import * as React from 'react'

// Import Styled Components
import {
  Route,
} from './Styled'

// Font Awesome Icons
import {
  faChevronDown,
  faFileInvoice,
  faTachometerAltFast,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { getLoggedInUser } from 'utils'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronDown, faTachometerAltFast, faFileInvoice])

interface Props {
  onChangeRoute: (route: string) => void
  route: any
}

class StyledRoute extends React.Component<Props, {}> {
  public render() {
    const { route } = this.props
    return (
      <div>
        {
          route.map((items: any, index: number) => {
            return (
              <div key={index} onClick={() => this.handleChange(items.route)}>
                <Route>
                  {
                    items.icon === 'whatsapp' ?
                      <span><FontAwesomeIcon icon={['fab', items.icon]} /></span> :
                      <span><FontAwesomeIcon icon={['fal', items.icon]} /></span>
                  }
                  <span>{items.route}</span>
                </Route>
              </div>
            )
          }
          )
        }
      </div>
    )
  }

  private handleChange = (route: string) => {
    const { onChangeRoute } = this.props
    onChangeRoute(route)
  }
}

export default StyledRoute
