export enum MenuActionEnum {
  Billing = 'billing',
  Logout = 'logout',
  Password = 'password',
  Profile = 'profile',
  Support = 'support'
}

export enum PrivilegeEnum {
  GUEST = 0,
  AGENT = 100,
  TEAM_LEADER = 200,
  MANAGER = 300,
  ADMIN = 400,
  SUPERADMIN = 500
}

export interface MenuItemType {
  action: MenuActionEnum
  privilege: PrivilegeEnum
  title: string
  divide?: boolean
}

export interface TierType {
  cost: number
  max: number | null
  min: number
  title: string
}

export interface UserType {
  _id: string
  role: string
  profileImage: string
}
