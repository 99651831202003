import { AddressErrorType, AddressType } from 'shared/AddressInput/Types'

export interface AddressPropType extends AddressType {
  type: string
}

export interface FormType extends AddressType {
  errors: FormErrorType
  type: string
}

export interface FormErrorType extends AddressErrorType {
  type?: string
}

export interface OptionType {
  text: string
  value: string
}

export enum FilterOptionEnum {
  Mls = 'mlsId',
  Transaction = 'transactionId',
  Debit = 'totalDebits',
  Commission = 'expectedCommission',
  Status = 'status',
  Role = 'transactionRole',
  Owner = 'owner',
  Address = 'address',
}