// Import Packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup, TextArea } from 'semantic-ui-react'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface ImageProps {
  backgroundImage: string
}

interface Props {
  display?: string
  edited?: string
}

const StyledContainer = styled(Container)`
  &.ui.fluid.container {
    border: none;
    box-shadow: none;
    overflow-y: auto;
    min-height: 90%;
    max-height: 90%;
    height: 90%;
    ${scrollbars};
  }
`

const StyledGrid = styled(Grid)`
  &.ui.grid {
    height: calc(100% - 10px);
    margin: 0px;
    ${scrollbars};
  }
`

const StyledHeader = styled.div`
  width: 100%;
  height: 30px;
  display: flex;

  @media (max-width: 1250px) {
    flex-direction: column;
    height: auto;
  }
`

const Content = styled.div`
  height: calc(100% - 25px);
  padding: 20px 0px;
`

const LeftColumn = styled(Grid.Column)`
  .ui[class*="two column"].grid>.row>&.column {
    width: 65%;
    padding: 10px 40px;
    background-color: ${Colors.White1000};
  }
`

const StyledDivider = styled(Divider)`
  &.ui.divider {
    margin: 0.5rem 0;
  }
`

const RightColumn = styled(Grid.Column)`
  .ui[class*="two column"].grid>.row>&.column {
    width: 35%;
    padding: 0px 30px;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      padding: 0px 20px;
    }
    @media ( min-width: 1200px ) {
      padding: 0px 30px;
    }
  }
`

const HeaderItem = styled(Segment)`
  .birthdate {
    display: flex;
    align-items: center;
  }
  &.ui.segment {
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    padding-right: 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: ${Colors.Grey600};
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 11px;
      padding-right: 20px;
    }
    @media ( min-width: 1200px ) {
      font-size: 13px;
      padding-right: 5px;
    }
  }
`

const StyledButton = styled(Button)`
  &.ui.button {
    background-color: transparent;
    padding: 0 8px;
    font-size: 20px;
    color: ${Colors.Grey600};
    position: relative;
    display: ${(props: Props) => props.display || props.display};
    :hover {
      background-color: transparent;
    }
    :active {
      background-color: transparent;
    }
    :focus {
      background-color: transparent;
    }
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 18px;
    }
    @media ( min-width: 1200px ) {
      font-size: 20px;
    }
  }
`

const ContentHeaderAction = styled(FontAwesomeIcon)`
  padding-left: 10px;
  font-size: 25px;
  color: ${Colors.Grey600};
  @media ( min-width: 991px ) and (max-width: 1200px) {
    font-size: 25px;
  }
  @media ( min-width: 1200px ) {
    font-size: 25px;
  }
`

const ContentHeader = styled(Header)`
  &.ui.small.header{
    align-items: center;
    display: flex;
    color: ${Colors.DarkBlue200};
  }
`

const DescriptionContainer = styled(Container)`
  &.ui.container {
    display: flex;
    font-size: 13px;
    color: ${Colors.DarkBlue200};
    text-align: justify;
    align-items: flex-start;
    padding-bottom: 20px;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 12px;
    }
    @media ( min-width: 1200px ) {
      font-size: 13px;
    }
  }
`

const StyledSegment = styled(Segment)`
  &.ui.segment {
    background-color: ${Colors.transparent};
    border: none;
    box-shadow: none;
    color: ${Colors.DarkBlue200};
    padding: 0.5rem 1rem;
    padding-bottom: 0px;
    padding-top: 0px;
    padding: initial;
  }
  .ui.small.header {
    color: ${Colors.DarkBlue200};
  }
`

const TextBox = styled(Input)`
  &.ui.input {
    width: 100%;
    margin-top: 5px;
    background-color: transparent;
    color: ${Colors.DarkBlue200};
  }

  &.ui.input>input {
    line-height: 1.8em;
  }

  &.ui.fluid.input > input {
    border-color: ${Colors.Grey600};
    padding: inherit;
    border-style: solid;
    background-color: transparent;
    padding-left: 10px;
    margin-top: -5px;
    width: 50px!important;
    color: ${Colors.DarkBlue200};
    :focus{
      border-color: ${Colors.Grey600};
    }

    ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const TitleContainer = styled.div`
  display: flex;
  font-weight: 600;
  color: darkgrey;
  width: ${(props: Props) => props.edited};
`

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 27px;
  height: 25px;
  align-self: center;
  margin: 0px 5px;
  margin-left: 0;
`

const DetailItemNumber = styled.div`
  display: flex;
  align-items: baseline;

  div:first-child {
    flex-grow: 1;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  width: 18px;
  font-size: 16px;
  margin-right: 12px;

  @media ( min-width: 1200px ) {
    font-size: 16px;
  }
`

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  white-space: normal;
  margin-right: 30px;
  cursor: pointer;

  .ui.input>input {
    width: 100px;
  }
  ${scrollbars};

  &.ui.top.left.popup {
    z-index: 9999;
  }
`

const BioContainer = styled(TextArea)`
  width: 100%;
  height: 190px;
  border-color: ${Colors.Grey600};
  padding: inherit;
  border-style: solid;
  background-color: transparent;
  padding-left: 10px;
  margin-top: -5px;
  color: ${Colors.DarkBlue200};
  :focus{
    border-color: ${Colors.Grey600};
  }

  ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const StyledPopup = styled(Popup)`

  &.ui.top.left.popup {
    z-index: 9999;
  }
`

const BioAddressContainer = styled.div`
  height: 310px;
  overflow: auto;
  align-items: flex-start;
  width: 100%;
  white-space: pre-wrap;

  .ui.button {
    font-size: 0.8rem;
    margin-top: 12px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }

  /* @media (max-width: 1300px ) {
    height: 200px;
  } */

  .ql-tooltip {
    left: 0 !important;
  }

  ${scrollbars};

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none;
    height: 160px;

    ${scrollbars};
  }

  .quill {
    display: flex;
    flex-direction: column-reverse;
  }

  .ql-editor {
    padding-left: 0;
  }

  .ql-toolbar.ql-snow {
    border-left: none;
    border-right: none;
    padding-left: 0px;
  }
`

const QuillWrapper = styled.div`
  position: relative;

  button.ql-bold:before {
    content: "\f032";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
  }

  button.ql-italic:before {
    content: "\f033";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
  }

  .ql-toolbar button.ql-list[value=ordered]:before {
    content: "\f0cb";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
  }

  .ql-toolbar button.ql-list[value=bullet]:before {
    content: "\f0ca";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
  }
`

export {
  AddressContainer,
  BioContainer,
  BioAddressContainer,
  Content,
  ContentHeader,
  ContentHeaderAction,
  DescriptionContainer,
  DetailItemNumber,
  HeaderItem,
  ImageWrapper,
  LeftColumn,
  RightColumn,
  StyledButton,
  StyledContainer,
  StyledDivider,
  StyledGrid,
  StyledIcon,
  StyledHeader,
  StyledSegment,
  StyledPopup,
  TextBox,
  TitleContainer,
  QuillWrapper
}
