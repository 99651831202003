// Import Packages
import { Label } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { fonts } from 'design/Fonts'

const TransactionsContainer = styled.div`
  width: 100%;

  .fa-plus-circle {
    width: 22px;
    height: 23px;
    color: ${Colors.White1000};
    cursor: pointer;

    @media (max-width: 1100px) {
      margin-left: 10px;
    }
  }
`

const SubText = styled.div`
  color: ${Colors.White1000};
  font: ${fonts.small.regular};
  font-size: 16px;
  font-weight: 600;
  display: flex;
  padding-left: 6px;
  align-items: center;
  flex-grow: 1;

  .ui.inline.dropdown {
    color: ${Colors.White1000};
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .ui.inline.dropdown .dropdown.icon {
    display: none;
  }

  .ui.header:last-child {
    white-space: nowrap;
  }

  .fa-chevron-down {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    color: ${Colors.White1000};
  }
`

const Labels = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

export { TransactionsContainer, SubText, Labels }
