const Actions = {
  addCategory: '@@checklistManager/ADD_CATEGORY',
  addDocument: '@@checklistManager/ADD_DOCUMENT',
  addOtherFeeItem: '@@transactionFee/ADD_OTHER_FEE_ITEM',
  addProgressiveCommissionItem:
    '@@transactionFee/ADD_PROGRESSIVE_COMMISSION_ITEM',
  addProperty: '@@checklistManager/ADD_PROPERTY',
  addTax: '@@taxSetup/ADD_TAX',
  changeAdditionalCap: '@@transactionFee/CHANGE_ADDITIONAL_CAP',
  changeChecklistValue: `@@checklistManager/CHANGE_CHECKLIST_VALUE`,
  changeCommercialCap: '@@transactionFee/CHANGE_COMMERCIAL_CAP',
  changeCommercialFeeProperty:
    '@@transactionFee/CHANGE_COMMERCIAL_FEE_PROPERTY',
  changeCommissionFee: '@@transactionFee/CHANGE_COMMISSION_FEE',
  changeCommissionType: '@@transactionFee/CHANGE_COMMISSION_TYPE',
  changeExclusionCap: '@@transactionFee/CHANGE_EXCLUSION_CAP',
  changeExpectedCommission: '@@transactionFee/CHANGE_EXPECTED_COMMISSION',
  changeOtherFeeItem: '@@transactionFee/CHANGE_OTHER_FEE_ITEM',
  changePrincipalPropertyCost:
    '@@transactionFee/CHANGE_PRINCIPAL_PROPERTY_COST',
  changeProgressiveCommissionItem:
    '@@transactionFee/CHANGE_PROGRESSIVE_COMMISSION_ITEM',
  changeResidentialCap: '@@transactionFee/CHANGE_RESIDENTIAL_CAP',
  changeResidentialFeeProperty:
    '@@transactionFee/CHANGE_RESIDENTIAL_FEE_PROPERTY',
  changeTeamCap: '@@transactionFee/CHANGE_TEAM_CAP',
  deleteCategory: '@@checklistManager/DELETE_CATEGORY',
  deleteDocument: '@@checklistManager/DELETE_DOCUMENT',
  deleteOtherFeeItem: '@@transactionFee/DELETE_OTHER_FEE_ITEM',
  deleteProgressiveCommissionItem:
    '@@transactionFee/DELETE_PROGRESSIVE_COMMISSION_ITEM',
  deleteProperty: '@@checklistManager/DELETE_PROPERTY',
  deleteTax: '@@taxSetup/DELETE_TAX',
  editCategory: '@@checklistManager/EDIT_CATEGORY',
  editDocument: '@@checklistManager/EDIT_DOCUMENT',
  editProperty: '@@checklistManager/EDIT_PROPERTY',
  getTax: '@@taxSetup/GET_TAXES',
  reinsertChecklistProperty: '@@checklistManager/REINSERT_CHECKLIST_PROPERTY',
  toggleCaps: '@@transactionFee/TOGGLE_CAPS',
  updateChecklistData: '@@checklistManager/UPDATE_CHECKLIST_DATA',
  updateTax: '@@taxSetup/UPDATE_TAX'
}

export default Actions
