import { debounce, mergeWith, omit } from 'lodash'
import client from 'queries/apollo'
import { cacheData } from 'queries/apollo'
import { activeTransactionSettingIndex } from '../app/Settings/TransactionFee/TransactionFeeDetails/FeeSettings/FeeSettings'
import { UPDATE_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Mutation'
import { GET_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Queries'
import { getTransactionSettings } from 'queries/schema/schemaTypes'

function undefinedChecker(objValue: any, srcValue: any) {
  return srcValue === null ? objValue : undefined
}

const changeRemoteData = debounce(() => {
  const cacheStoreData = cacheData.readQuery({
    query: GET_TRANSACTION_SETTING
  }) as getTransactionSettings
  client.mutate({
    mutation: UPDATE_TRANSACTION_SETTING,
    variables: {
      _id: cacheStoreData.getTransactionSettings!![activeTransactionSettingIndex]._id,
      ...omit(cacheStoreData.getTransactionSettings!![activeTransactionSettingIndex], '_id')
    }
  })
}, 800)

export const updateTransactionSettingLocals = (obj: any, { data }: { data: any }, { cache }: { cache: any }) => {
  const cacheStoreData = cache.readQuery({
    query: GET_TRANSACTION_SETTING
  }) as getTransactionSettings
  const newData = mergeWith(
    {},
    cacheStoreData.getTransactionSettings!![activeTransactionSettingIndex],
    data,
    undefinedChecker
  )
  cache.writeQuery({
    data: { updateTransactionSetting: newData },
    query: UPDATE_TRANSACTION_SETTING
  })
  changeRemoteData()
  return newData
}

export default updateTransactionSettingLocals
