import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import {
  icon,
  styledToolTip,
} from 'design/Styled'

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`

export const Button = styled.span`
  ${icon};
  padding: 0;
  color: ${Colors.Text};
  &.disabled{
    opacity:0.2;
    pointer-events: none;
  }

`

export const Icon = styled(FontAwesomeIcon)``
