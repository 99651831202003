// Import Packages
import { Checkbox, Dropdown, Table } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background?: string
  color?: string
}

const Container = styled.div`
  background: ${Colors.White1000};
  padding: 30px;
  text-align: justify;
`

const Notification = styled.div`
  margin-top: 50px;
`

const Title = styled.div`
  font-size: 16px;
  color: ${Colors.DarkBlue200};
  font-weight: 500;
`

const Description = styled.div`
  font-size: 12px;
  font-style: italic;
  margin-top: 15px;
  color: ${Colors.Black500};
`

const StyledTable = styled(Table)`
  &.ui.table {
    border: none;
    border-bottom: 1px solid rgba(34,36,38,.15);
    color: ${Colors.Black500};
    font-size: 12px;
  }

  &.ui.table thead>tr>th {
    font-size: 14px;
    color: ${Colors.Black500};
  }

  &.ui.table thead {
    font-size: 14px;
  }

  .fa-envelope {
    height: 20px;
    width: 20px;
    margin-right: 6px;
  }

  .fa-volume {
    height: 20px;
    width: 20px;
    margin-right: 6px;
  }
`

const Logo = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: center;
  margin-right: 6px;
`

const StyledCheckbox = styled(Checkbox)`
  &.ui.toggle.checkbox label::before {
    width: 30px;
    height: 18px;
    background-color: ${Colors.Grey565} !important;
  }

  &.ui.toggle.checkbox.test input:checked~.box:before,
  &.ui.toggle.checkbox.test input:checked~label:before,
  &.ui.toggle.checkbox.test input:focus:checked~.box:before,
  &.ui.toggle.checkbox.test input:focus:checked~label:before {
    width: 30px;
    height: 18px;
    background-color: ${Colors.DarkBlue200} !important;
  }

  &.ui.toggle.checkbox input:checked~label:after {
    left: 0.85rem !important;
  }

  &.ui.toggle.checkbox label::after {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-left: 2px;
  }
`

const StyledDropdown = styled(Dropdown)`
  background: ${(props: Props) => props.background || props.background};
  display: flex !important;
  width: auto;
  min-width: 70px;

  &.ui.inline.dropdown {
    width: 120px;
    font-size: 12px;
    height: 22px;
    align-items: center;
  }

  &.ui.inline.dropdown .dropdown.icon {
    color: ${(props: Props) => props.color || props.color};
    flex-grow: 1;
    align-self: center;
    text-align: right;
    margin-right: 10px;
  }

  &.ui.inline.dropdown>.text {
    color: ${(props: Props) => props.color || props.color};
    margin-left: 5px;
    font-weight: 500;
    flex-grow: 1;
  }

  &.actionDropdown {
    text-align: left !important;
  }
`

export {
  Container,
  Description,
  Logo,
  StyledCheckbox,
  StyledDropdown,
  Notification,
  StyledTable,
  Title
}
