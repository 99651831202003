import { Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { SecondaryFontName } from 'design/Fonts'
import { children, x, y } from 'design/Styled'

export const Row = styled.div`
  ${x};
  ${children};

  & .rp-state-dropdown {
    width: 100%;
  }
`

export const Container = styled.div`
  ${x};
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;

  @media (min-width: 600px) {
    padding: 50px 70px 50px 85px;
    box-shadow: inset 0 0 0 20px ${Colors.EmphasisLine};
    max-width: 600px;

    &:before,
    &:after {
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      content: '';
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 20px solid ${Colors.EmphasisLine};
      border-left: 20px solid ${Colors.Container};
      border-top: 20px solid ${Colors.Container};
      background: ${Colors.Container};
    }

    &:before {
      left: -50px;
      transform: rotate(-45deg);
    }

    &:after {
      right: -50px;
      transform: rotate(135deg);
    }
  }

  & .rp-algolia-places,
  & .rp-mls-search {
    display: flex;
    flex: 1;
  }

  & .rp-mls-search .ui.fluid.dropdown,
  & .rp-algolia-places .ap-input {
    max-height: 38px;
  }
`

export const StyledForm = styled(Form)`
  ${y};

  & > div {
    margin-bottom: 0.6em;
  }

  @media (min-width: 600px) {
    padding-left: 2em;
  }
`

export const StyledInput = styled(Input)`
  &.ui.input {
    &.street-number {
      width: 30%;
    }
    &.zipcode {
      width: 30%;
    }
  }
`

export const Title = styled.div`
  display: none;

  @media (min-width: 600px) {
    display: flex;
    padding: 0;
    width: 0;
    align-self: flex-end;
    font-size: 42px;
    font-family: ${SecondaryFontName};
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    color: ${Colors.DarkBlue200};
    transform: rotate(-90deg);
  }
`
