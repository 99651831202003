// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Select } from 'semantic-ui-react'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import { Options } from 'store/Reports/Types'

import { getCities, getStates } from '../../../../Transactions/Dashboard/TransactionQueries'

import {
  StyledForm
} from '../Styled'

interface StoreProps {
  setFilter: (data: any) => void
  setResetFilterData: (reset: boolean) => void
  setChangeKey: (key: number) => void
  setShowState: (data: Options) => void
  setShowCity: (data: Options) => void
  filter: any
  resetData: boolean
  key: number
  selectedCity: Options
  selectedState: Options
  setModule: string
}

interface OwnProps {
  categoryId: string
  report: string
}

type Props = StoreProps & OwnProps

interface State {
  activeIndex: string
  searchData: any
  cities: Options[]
  states: Options[]
  selectedCity: Options
  selectedState: Options
}

const optionsType = [
  { key: 1, text: 'Buyer', value: 'Buyer' },
  { key: 2, text: 'Seller', value: 'Seller' },
  { key: 3, text: 'Both', value: 'Both' },
]

const optionsStatus = [
  { key: 1, text: 'New', value: 'New' },
  { key: 2, text: 'Active', value: 'Active' },
  { key: 3, text: '$$ Received', value: '$$ Received' },
  { key: 4, text: 'Payable', value: 'Payable' },
  { key: 5, text: 'Closed', value: 'Closed' },
  { key: 6, text: 'Failed', value: 'Failed' },
]

class TransactionContent extends React.Component<Props, State> {
  public state = {
    activeIndex: '',
    cities: [],
    searchData: {},
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: [],
  }

  public async componentDidMount() {
    const { setChangeKey, selectedState } = this.props
    const states = await getStates()
    if (states) {
      const statesData: Options[] = []
      states.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`,
        }
        element.name && statesData.push(obj)
      })
      this.setState({ states: statesData })
    }
    if (selectedState && Object.keys(selectedState).length > 0) {
      const result = selectedState[`value`].split(', ')
      const cities: any = await getCities(result[1])
      if (cities) {
        const citiesData: Options[] = []
        cities.forEach((element: any) => {
          const obj: Options = {
            key: element._id,
            text: element.name,
            value: `${element.name}, ${element._id}`,
          }
          citiesData.push(obj)
        })
        this.setState({ cities: citiesData })
      }
    }
    setChangeKey(0)
  }

  public componentWillReceiveProps(nextProps: any) {
    const { setChangeKey, resetData } = this.props
    if (resetData ? resetData : nextProps.resetData) {
      this.setState({
        searchData: {},
        selectedCity: {
          key: '',
          text: '',
          value: ''
        },
        selectedState: {
          key: '',
          text: '',
          value: ''
        },
      })
      setChangeKey(1)
    }
  }

  public render() {
    const { report, key, filter, selectedCity, selectedState } = this.props
    const { states, cities } = this.state
    return (
      <div key={key}>
        <StyledForm size={'tiny'}>
          {
            report === 'Transaction ID' &&
            <Form.Field
              control={Input}
              label='Transaction ID'
              name='transactionId'
              value={filter[`transactionId`]}
              placeholder='Transaction ID'
              onChange={this.handleChange} />
          }
          {
            report === 'MLS Number' &&
            <Form.Field
              control={Input}
              label='MLS Number'
              type='number'
              name='mlsId'
              value={filter[`mlsId`]}
              placeholder='MLS Number'
              onChange={this.handleChange} />
          }
          {
            report === 'Type' &&
            <Form.Field
              control={Select}
              label='Type'
              name='transactionRole'
              placeholder='Type'
              value={filter[`transactionRole`]}
              options={optionsType}
              onChange={this.handleChange} />
          }
          {
            report === 'Status' &&
            <Form.Field
              control={Select}
              label='Status'
              name='status'
              placeholder='Status'
              value={filter[`status`]}
              options={optionsStatus}
              onChange={this.handleChange} />
          }
          {
            report === 'Transaction Owner Name' &&
            <div>
              <Form.Field
                control={Input}
                label='First Name'
                name='firstName'
                value={filter[`owner`] && filter[`owner`].firstName}
                placeholder='First Name'
                onChange={this.handleChange} />
              <Form.Field
                control={Input}
                label='Last Name'
                name='lastName'
                value={filter[`owner`] && filter[`owner`].lastName}
                placeholder='Last Name'
                onChange={this.handleChange} />
            </div>
          }
          {
            report === 'Address' &&
            <div>
              <Form.Field
                control={Input}
                name='streetNumber'
                value={filter[`address`] && filter[`address`].streetNumber}
                label='Street No.'
                placeholder='Street No.'
                onChange={this.handleChange} />
              <Form.Field
                control={Input}
                name='streetName'
                value={filter[`address`] && filter[`address`].streetName}
                label='Street Name'
                placeholder='Street Name'
                onChange={this.handleChange} />
              <Form.Field
                control={Select}
                label='State'
                name='state'
                placeholder='State'
                value={selectedState[`value`]}
                options={states}
                onChange={this.handleChange} />
              <Form.Field
                control={Select}
                label='City'
                name='city'
                placeholder='City'
                value={selectedCity[`value`]}
                options={cities}
                onChange={this.handleChange} />
              <Form.Field
                control={Input}
                label='Zip Code'
                name='zipCode'
                value={filter[`address`] && filter[`address`].zipCode}
                placeholder='Zip Code'
                onChange={this.handleChange} />
            </div>
          }
        </StyledForm>
      </div>
    )
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { setFilter, filter, setShowCity, setShowState } = this.props
    if (name === 'firstName') {
      this.setState({
        searchData: {
          ...filter,
          owner: {
            ...filter[`owner`],
            firstName: value,
          },
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'lastName') {
      this.setState({
        searchData: {
          ...filter,
          owner: {
            ...filter[`owner`],
            lastName: value,
          },
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'city') {
      const result = value.split(', ')
      this.setState({
        searchData: {
          ...filter,
          address: {
            ...filter[`address`],
            city: result[0],
          },
        }
      }, () => setFilter(this.state.searchData))
      this.setState({
        selectedCity: {
          key: result[1],
          text: result[0],
          value: `${result[0]}, ${result[1]}`,
        }
      }, () => setShowCity(this.state.selectedCity))
    } else if (name === 'state') {
      const result = value.split(', ')
      this.setState({
        searchData: {
          ...filter,
          address: {
            ...filter[`address`],
            state: result[0],
          },
        }
      }, () => setFilter(this.state.searchData))
      this.setState({
        selectedState: {
          key: result[1],
          text: result[0],
          value: `${result[0]}, ${result[1]}`,
        }
      }, () => setShowState(this.state.selectedState))
      const cities: any = await getCities(result[1])
      if (cities) {
        const citiesData: Options[] = []
        cities.forEach((element: any) => {
          const obj: Options = {
            key: element._id,
            text: element.name,
            value: `${element.name}, ${element._id}`,
          }
          citiesData.push(obj)
        })
        this.setState({ cities: citiesData })
      }
    } else if (name === 'zipCode') {
      this.setState({
        searchData: {
          ...filter,
          address: {
            ...filter[`address`],
            zipCode: value,
          },
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'transactionId') {
      this.setState({
        searchData: {
          ...filter,
          transactionId: parseInt(value)
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'mlsId') {
      this.setState({
        searchData: {
          ...filter,
          mlsId: parseInt(value)
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'streetNumber') {
      this.setState({
        searchData: {
          ...filter,
          address: {
            ...filter[`address`],
            streetNumber: value
          },
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'streetName') {
      this.setState({
        searchData: {
          ...filter,
          address: {
            ...filter[`address`],
            streetName: value
          },
        }
      }, () => setFilter(this.state.searchData))
    } else if (name === 'status') {
      this.setState({
        searchData: {
          ...filter,
          status: value
        }
      }, () => setFilter(this.state.searchData))
    } else {
      this.setState({
        searchData: {
          ...filter,
          transactionRole: value
        }
      }, () => setFilter(this.state.searchData))
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.reports.filter,
  key: state.reports.key,
  resetData: state.reports.resetData,
  selectedCity: state.reports.selectedCity,
  selectedState: state.reports.selectedState,
  setModule: state.reports.module,
})

export default connect(
  mapStateToProps,
  {
    setChangeKey: Actions.changeKey,
    setFilter: Actions.getFilter,
    setResetFilterData: Actions.resetFilterData,
    setShowCity: Actions.showCity,
    setShowState: Actions.showState,
  }
)(TransactionContent)
