// Import Packages
import * as React from 'react'
import InputMask from 'react-input-mask'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'

// Import Components
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import { getContactBoards, getMyContactBoards } from '../ContactQueries'
import { handleValidation } from './Validation'

// Import Store Types, Actions and Reducers
import { ContactItemsDetails, UserType } from 'store/Contacts/Types'
import { FormErrorType, FormType } from './Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { AddContactLeftPanel, AddForm, Container, StyledForm } from './Styled'

export interface Props {
  closeModal: () => void
  addBoardId?: string
  createContact: (newContact: any) => void
  edit?: boolean
  editContactDetails?: ContactItemsDetails | any
  filterOptions?: boolean
  user?: UserType | any
}

interface Options {
  key: string
  value: string
  text: string
}

interface State {
  form: FormType
  selectedBoard: Options
  typeOptions: Options[]
  typeSelection: boolean
  animate: boolean
  loading: string
}

const SourceOptions = [
  { key: 1, value: 'Call', text: 'Call' },
  { key: 2, value: 'Email', text: 'Email' },
  { key: 3, value: 'Trade', text: 'Trade' },
  { key: 4, value: 'Referral', text: 'Referral' },
  { key: 5, value: 'Seminar', text: 'Seminar' },
  { key: 6, value: 'Website', text: 'Website' },
  { key: 7, value: 'Third_Party', text: 'Third Party' }
]

const defaultContactBoard = ['Buyer', 'Seller', 'Both', 'Renter']

class AddContactModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    form: {
      errors: {} as FormErrorType
    } as FormType,
    loading: '',
    selectedBoard: {
      key: '',
      text: '',
      value: ''
    },
    typeOptions: [],
    typeSelection: false
  }

  public async componentDidMount() {
    const { addBoardId, edit, editContactDetails, filterOptions, user } = this.props
    this.setState({ loading: edit ? 'Gathering data...' : '' })
    let contactBoards
    if (filterOptions === true) {
      contactBoards = await getContactBoards()
    } else {
      contactBoards = await getMyContactBoards(user)
    }
    let selection = false
    if (contactBoards) {
      let boards: Options[] = []
      let selectedOption: Options = { key: '', text: '', value: '' }
      contactBoards.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`
        }
        if (element._id === addBoardId || (editContactDetails && element.name === editContactDetails.type)) {
          selectedOption = obj
        }

        if (element._id === addBoardId && defaultContactBoard.includes(element.name)) {
          selection = true
          selectedOption = obj
        }
        if (editContactDetails && editContactDetails.type === element.name) {
          selection = false
          selectedOption = obj
        }
        boards.push(obj)
      })
      boards = boards.filter((board: any) => {
        return defaultContactBoard.includes(board.text)
      })
      this.setState({ selectedBoard: selectedOption, typeOptions: boards, typeSelection: selection, loading: '' })
    }

    const form = {
      ...editContactDetails,
      errors: {} as FormErrorType
    }

    if (edit && editContactDetails) {
      this.setState({ form, loading: '' })
    }
  }

  public render() {
    const { edit } = this.props
    const {
      loading,
      form,
      form: { errors },
      typeOptions,
      selectedBoard,
      typeSelection,
      animate
    } = this.state
    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loading} />}
            <AddContactLeftPanel>
              {edit ? (
                <Image src={Strings.contact.src1} size="small" />
              ) : (
                  <Image src={Strings.contact.src} size="small" />
                )}
            </AddContactLeftPanel>
            <AddForm>
              <StyledForm size={'mini'}>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                    onChange={this.handleChange}
                    error={!!errors.firstName}
                    value={form.firstName}
                  />
                  <Form.Field
                    control={Input}
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                    onChange={this.handleChange}
                    error={!!errors.lastName}
                    value={form.lastName}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field control={Input} label="Phone" error={!!errors.phone}>
                    <InputMask
                      mask="(999) 999-9999"
                      name="phone"
                      placeholder="(xxx) xxx-xxxx"
                      onChange={this.handlePhoneChange}
                      value={form.phone}
                    />
                  </Form.Field>
                  <Form.Field
                    control={Input}
                    label="Email"
                    name="email"
                    disabled={edit ? true : false}
                    placeholder="Email"
                    onChange={this.handleChange}
                    error={!!errors.email}
                    value={form.email}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Select}
                    label="Source"
                    name="source"
                    placeholder="Source"
                    options={SourceOptions}
                    onChange={this.handleChange}
                    error={!!errors.source}
                    value={form.source}
                  />
                  <Form.Field
                    control={Select}
                    label="Type"
                    name="contactBoard"
                    placeholder="Type"
                    options={typeOptions}
                    disabled={typeSelection}
                    value={selectedBoard.value}
                    onChange={this.handleChange}
                    error={errors[`type`] ? true : false}
                  />
                </Form.Group>
                <Button onClick={this.contactSubmit} content={'SAVE'} />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={700}
      />
    )
  }

  private contactSubmit = () => {
    const { form, selectedBoard } = this.state
    form['contactBoard'] = selectedBoard.key
    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    this.addNewCard()
    this.closeModal()
  }

  private handlePhoneChange = (e: any) => {
    const { form } = this.state
    form.phone = e.target.value
    this.setState({ form })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form, typeSelection } = this.state
    if (name === 'contactBoard' && typeSelection === false) {
      const res = value.split(', ')
      form[name] = res[1]
      form[`type`] = res[0]
      this.setState({
        selectedBoard: {
          key: res[1],
          text: res[0],
          value: `${res[0]}, ${res[1]}`
        }
      })
    } else {
      form[name] = value
    }
    this.setState({ form })
  }

  private addNewCard = () => {
    const { form } = this.state
    const { createContact } = this.props
    createContact(form)
  }
}

export default AddContactModal
