// Import Packages
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

// Import Utils
import { getLoggedInUser, Routes } from 'utils'

// Import Styled Components
import {
  BGImage,
  Header,
  HeaderGray,
  Rocket,
  Text,
  TextBold,
  TextColored,
  TextContainer,
  WelcomeContainer
} from './Styled'

interface Props extends RouteComponentProps<{}> { }

interface State {
  currentUser: any
}

class Welcome extends React.Component<Props, State> {
  public state = {
    currentUser: {
      firstName: ''
    }
  }

  public async componentDidMount() {
    const user = await getLoggedInUser()
    this.setState({
      currentUser: user
    })
  }

  public render() {
    const { currentUser } = this.state
    return (
      <WelcomeContainer>
        <BGImage />
        <TextContainer>
          {currentUser.firstName.length > 0 && (
            <HeaderGray>{currentUser.firstName}...</HeaderGray>
          )}
          <Header>Welcome to the Family.</Header>
          <Text>
            We are incredibly proud to have a new member join our pack! Top agents like you are what makes the real estate industry flourish. By joining RealtyPass, you have put your best foot forward and can now make the entire transaction process better for all parties involved.  Finding and managing your business has never been easier, and you will have assistance every step of the way to ensure your leads stay hot and your transactions close on time.
          </Text>
          <Text>
            Finn is your friendly mascot who will guide you through the application and help make the transaction process quick, easy, and painless. You have a whole suite of tools at your fingertips and we hope you enjoy using them!  Can’t find what you need in our knowledge base?  Our stellar support team is standing by to help.
          </Text>
          <TextColored>
            Thank you for joining <TextBold>RealtyPass!</TextBold>
          </TextColored>
          <Button onClick={this.moveToDashboard}>Let's Go!</Button>
        </TextContainer>
        <Rocket />
      </WelcomeContainer>
    )
  }

  private moveToDashboard = () => {
    this.props.history.push(Routes.primary.dashboard.path)
  }
}
export default withRouter(Welcome)
