// Import Packages
import { debounce, set, unset } from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import client, { cacheData } from 'queries/apollo'

// Import Components
import TeamSearchModal from './TeamSearchModal'

// Import Graphql Queries
import { TeamDetails } from 'store/Teams/Types'
import { GET_OFFICES_TEAMS, GET_TEAMS } from 'queries/graphql/Teams/Queries'
import { formatKanbanData } from '../Utils/FormattingData'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Teams/Actions'
import { Filter, FiltersData, FilterText, Options } from 'store/Teams/Types'

// Import Styled Components
import { Plus, SearchContainer, SearchInput, SearchWrapper, Tag, TagContainer } from './Styled'

// Font Awesome Icons
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faSearch, faTimes])

interface Props {
  setBoardData: (data: TeamDetails[]) => void
  setFilter: (data: Filter) => void
  setSearchLoader: (open: boolean) => void
  setSearchDataFilter: (data: FiltersData) => void
  data: object[]
}

interface State {
  open: boolean
  searchText: string
  filterText: FilterText[]
  filter: FiltersData
  data: FiltersData
  selectedCity: Options
  selectedState: Options
}

export let loggedUser: any
class TeamSearchBar extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    data: {
      firstName: undefined,
      lastName: undefined,
      name: undefined,
      officeName: undefined
    },
    filter: {},
    filterText: [],
    open: false,
    searchText: '',
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    }
  }

  public render() {
    const { filterText, open, searchText, data, selectedState, selectedCity } = this.state
    return (
      <SearchWrapper>
        <SearchContainer>
          <div style={{ display: filterText.length === 0 ? 'none' : 'flex' }}>
            <TagContainer>
              {filterText.map((items: any, index: number) => (
                <Tag key={index}>
                  {items.content}
                  <span className="times" onClick={() => this.deleteFilterText(items.id, items.text)}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                  </span>
                </Tag>
              ))}
            </TagContainer>
            <Plus>+</Plus>
          </div>
          <SearchInput
            icon={
              searchText ? (
                <span>
                  <span onClick={this.closeSearcModal}>
                    <FontAwesomeIcon icon={['far', 'search']} style={{ right: '25px' }} />
                  </span>
                  <span onClick={this.clearState}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                  </span>
                </span>
              ) : (
                <span onClick={this.closeSearcModal}>
                  <FontAwesomeIcon icon={['far', 'search']} style={{ right: '6px' }} />
                </span>
              )
            }
            placeholder="Search"
            onClick={this.showAdvanceSearcModal}
            onChange={this.handleChange}
            value={searchText}
          />
        </SearchContainer>
        {open && (
          <TeamSearchModal
            data={data}
            filterText={filterText}
            selectedCity={selectedCity}
            selectedState={selectedState}
            filter={this.filter}
            filtersContent={this.filtersContent}
            closeAdvanceSearchModal={this.showAdvanceSearcModal}
          />
        )}
      </SearchWrapper>
    )
  }

  private filter = (data: object, state: Options) => {
    const newList = this.state.filterText.slice()
    this.setState({ data, selectedState: state })
    const searchObj = {}
    newList.forEach((element: any) => {
      if (this.state.searchText !== undefined && this.state.searchText !== '') {
        searchObj['searchText'] = this.state.searchText
      }
      if (element.text === 'leaderName') {
        if (data['firstName']) {
          searchObj['firstName'] = data['firstName']
        }
        if (data['lastName']) {
          searchObj['lastName'] = data['lastName']
        }
      }
      if (element.text in data) {
        searchObj[element.text] = data[element.text]
      }
    })
    this.setState(
      {
        filter: searchObj
      },
      () => this.searchingForBothView()
    )
  }

  private searchingForBothView = async () => {
    const { setSearchLoader, setSearchDataFilter, setBoardData } = this.props
    const { filter } = this.state
    setSearchDataFilter(filter)
    setSearchLoader(true)
    let data: any = {}
    let listData: any = {}
    data = await client.query({
      fetchPolicy: 'no-cache',
      query: GET_OFFICES_TEAMS,
      variables: { sortOrder: 1, orderField: 'teamOfficeOrder', filter }
    })

    const kanbanData = formatKanbanData(data.data.getOfficeTeams, false)
    setBoardData(kanbanData)

    listData = await client.query({
      fetchPolicy: 'no-cache',
      query: GET_TEAMS,
      variables: { isActive: true, filter }
    })

    setBoardData(data.data.getOfficeTeams)

    if (data && listData) {
      setSearchLoader(false)
    }

    cacheData.writeQuery({
      data: { getTeams: listData.data.getTeams },
      query: GET_TEAMS,
      variables: { isActive: true }
    })
  }

  private deleteFilterText = (id: string, text: string) => {
    const { setFilter } = this.props
    const { filterText, searchText } = this.state
    const newList = filterText.slice()
    if (this.state.searchText === '') {
      unset(this.state.filter, 'searchText')
    }
    if (text === 'leaderName') {
      unset(this.state.filter, 'firstName')
      unset(this.state.filter, 'lastName')
    } else {
      unset(this.state.filter, text)
    }
    const listIndex = newList.findIndex((item: any) => {
      return item.id === id
    })
    newList.splice(listIndex, 1)
    const newData = {
      filter: newList.length !== 0 ? true : false,
      searchText: searchText ? true : false
    }
    setFilter(newData)
    this.setState({ filterText: newList })
    this.searchingForBothView()
  }

  private defaultFilter = (data: FiltersData) => {
    const newList = this.state.filterText.slice()
    this.setState({ data })
    const searchObj = {}
    newList.forEach((element: any) => {
      if (this.state.searchText !== undefined && this.state.searchText !== '') {
        searchObj['searchText'] = this.state.searchText
      }
      if (element.text === 'leaderName') {
        if (data['firstName'] && data['firstName'] !== undefined) {
          searchObj['firstName'] = data['firstName']
        }
        if (data['lastName'] && data['lastName'] !== undefined) {
          searchObj['lastName'] = data['lastName']
        }
      }
      if (element.text in data && data[element.text] !== undefined) {
        searchObj[element.text] = data[element.text]
      }
    })
    this.setState(
      {
        filter: searchObj
      },
      () => {
        this.searchingForBothView()
      }
    )
  }

  private filtersContent = (id: string, content: string, text: string, searchData: object) => {
    const { filterText, searchText } = this.state
    const { setFilter } = this.props
    if (filterText.length !== 0) {
      const strIndex = filterText.findIndex((item: any) => {
        return item.id === id
      })
      if (strIndex !== -1) {
        return
      }
      const newContent = [...filterText, { id: id, content: content, text: text }]
      this.setState({ filterText: newContent }, () => this.defaultFilter(searchData))
      const newData = {
        filter: true,
        searchText: searchText ? true : false
      }
      setFilter(newData)
    } else {
      const newContent = [...filterText, { id: id, content: content, text: text }]
      this.setState({ filterText: newContent }, () => this.defaultFilter(searchData))
      const newData = {
        filter: true,
        searchText: searchText ? true : false
      }
      setFilter(newData)
    }
  }

  private clearState = () => {
    this.setState(
      {
        data: {
          firstName: undefined,
          lastName: undefined,
          name: undefined,
          officeName: undefined
        },
        filter: {},
        filterText: [],
        searchText: '',
        selectedCity: {
          key: '',
          text: '',
          value: ''
        },
        selectedState: {
          key: '',
          text: '',
          value: ''
        }
      },
      () => this.searchingForBothView()
    )
    unset(this.state.filter, 'searchText')
  }

  private searchTextDebounce = async () => {
    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(() => {
      this.searchingForBothView()
    }, 1000)

    this.debounceJob()
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const { filterText } = this.state
    const { setFilter } = this.props
    this.setState({ searchText: value, open: false }, () => {
      const newData = {
        filter: filterText.length !== 0 ? true : false,
        searchText: value ? true : false
      }
      setFilter(newData)
    })
    set(this.state.filter, 'searchText', value)
    if (this.state.filter['searchText'] === '') {
      unset(this.state.filter, 'searchText')
    }
    this.searchTextDebounce()
  }

  private closeSearcModal = () => {
    this.setState({ open: false })
  }

  private showAdvanceSearcModal = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.teams.data
})

export default connect(
  mapStateToProps,
  {
    setBoardData: Actions.getBoardData,
    setFilter: Actions.filter,
    setSearchDataFilter: Actions.searchDataFilter,
    setSearchLoader: Actions.searchLoader
  }
)(TeamSearchBar)
