import { Mailbox } from './Types'

export const deleteCard = (state: Mailbox, action: any) => {
  const {
    data,
    data: { items, pagination }
  } = state
  let newState = { ...state }
  if (items && items.length > 0) {
    const list = items.slice()
    const index = items.findIndex(item => item._id === action.payload.cardId)
    const [temp] = list.splice(index, 1)
    newState = {
      ...newState,
      boardId: action.payload.boardId,
      cardIndex: index,
      currentlyDeleteCard: temp,
      data: {
        ...data,
        items: list
      }
    }
  }
  if (pagination) {
    const {
      pages,
      count,
      pages: { last, next }
    } = pagination
    const newPagination = {
      ...pagination,
      count: count - 1,
      pages: {
        ...pages,
        last: last - 1,
        next: next ? next - 1 : null
      }
    }
    newState = {
      ...newState,
      data: {
        ...newState.data,
        pagination: newPagination
      }
    }
  }
  return newState
}
