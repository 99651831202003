import Template1 from './Template1'
import Template2 from './Template2'
import Template3 from './Template3'
import Template4 from './Template4'
import Template5 from './Template5'

import { FlyerTemplateEnum, TemplateOptionType } from 'app/Transactions/Flyer/Types'

const Templates: TemplateOptionType[] = [
  { name: FlyerTemplateEnum.Template1, component: Template1 },
  { name: FlyerTemplateEnum.Template2, component: Template2 },
  { name: FlyerTemplateEnum.Template3, component: Template3 },
  { name: FlyerTemplateEnum.Template4, component: Template4 },
  { name: FlyerTemplateEnum.Template5, component: Template5 }
]

export default Templates
