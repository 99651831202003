import * as React from 'react'
import ReactQuill, { Quill } from 'react-quill'

import DropZone from 'shared/DropZone'
import { createAttachment, deleteAttachment } from './QuillMutations'

import 'react-quill/dist/quill.snow.css'
import { AttachIcon, AttachmentButton, AttachmentRow, AttachRow, Clear, Container, Editor, Toolbar } from './Styled'

import { AttachmentType } from './Types'

import { faCamera, faImage, faLink } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCamera, faImage, faLink])

interface Props {
  attachments: AttachmentType[]
  deleteDraft: () => void | null
  handleAttachmentChange: (newAttachments: AttachmentType[]) => void | null
  handleMessageChange: (content: any, delta: any, source: any, editor: any) => void
  html: string
}

class QuillEditor extends React.Component<Props, {}> {
  public static defaultProps = {
    attachments: [],
    deleteDraft: null,
    handleAttachmentChange: null,
    html: ''
  }

  private reactQuillRef: any

  private quillModules = {
    history: {
      delay: 2000,
      maxStack: 500
    },
    toolbar: {
      container: '#QuillToolbar',
      handlers: {
        image: () => this.handleInlineImage(this.reactQuillRef)
      }
    }
  }

  public componentDidMount = () => {
    const icons = Quill.import('ui/icons')
    icons.bold = ''
    icons.italic = ''
    icons.list = ''

    const QuillAlignStyle = Quill.import('attributors/style/align')
    const QuillBackgroundStyle = Quill.import('attributors/style/background')
    const QuillColorStyle = Quill.import('attributors/style/color')
    const QuillDirectionStyle = Quill.import('attributors/style/direction')
    const QuillFontStyle = Quill.import('attributors/style/font')

    Quill.register(QuillAlignStyle, true)
    Quill.register(QuillBackgroundStyle, true)
    Quill.register(QuillColorStyle, true)
    Quill.register(QuillDirectionStyle, true)
    Quill.register(QuillFontStyle, true)
  }

  public componentWillUnmount = () => {
    this.reactQuillRef = null
  }

  public render = () => {
    const { attachments, deleteDraft, handleAttachmentChange, handleMessageChange, html } = this.props
    return (
      <Container>
        <DropZone disableClick={true} onDropEach={this.uploadAttachment}>
          <Editor>
            {handleAttachmentChange && (
              <AttachRow>
                <label>Attach</label>
                <AttachIcon onClick={this.handleUploadAttachment}>
                  <FA icon={['fal', 'link']} />
                </AttachIcon>
                <AttachIcon onClick={this.handleUploadAttachment}>
                  <FA icon={['fal', 'camera']} />
                </AttachIcon>
                <AttachIcon onClick={this.handleUploadAttachment}>
                  <FA icon={['fal', 'image']} />
                </AttachIcon>
                {false && (
                  <AttachIcon>
                    <FA icon={['fab', 'dropbox']} />
                  </AttachIcon>
                )}
              </AttachRow>
            )}
            <ReactQuill
              ref={el => (this.reactQuillRef = el)}
              theme="snow"
              value={html}
              onChange={handleMessageChange}
              modules={this.quillModules}
              placeholder="Start Your Message..."
            />
            <Toolbar id="QuillToolbar">
              <div>
                <button className="custom custom-undo" onClick={this.undo} />
                <button className="custom custom-redo" onClick={this.redo} />
                <select className="ql-size">
                  <option value="small" />
                  <option value="" />
                  <option value="large" />
                  <option value="huge" />
                </select>
                <button className="custom ql-bold" />
                <button className="custom ql-italic" />
                <button className="ql-align" value="" />
                <button className="ql-align" value="center" />
                <button className="ql-align" value="right" />
                <button className="ql-align" value="justify" />
                <button className="custom ql-list" value="ordered" />
                <button className="custom ql-list" value="bullet" />
                <button className="ql-indent" value="-1" />
                <button className="ql-indent" value="+1" />
                <button className="ql-image" />
                <button className="ql-link" />
              </div>
              {deleteDraft && (
                <button className="custom custom-delete" onClick={deleteDraft}>
                  Delete Draft
                </button>
              )}
            </Toolbar>
            {handleAttachmentChange &&
              attachments.length > 0 && (
                <AttachmentRow>
                  {attachments.map((attachment: AttachmentType, index: number) => (
                    <AttachmentButton
                      key={attachment._id}
                      content={attachment.fileName}
                      compact={true}
                      size="mini"
                      icon={<Clear name="close" onClick={() => this.handleClearAttachment(index, attachment._id)} />}
                      labelPosition="right"
                    />
                  ))}
                </AttachmentRow>
              )}
          </Editor>
        </DropZone>
      </Container>
    )
  }

  private undo = () => {
    const editor = this.reactQuillRef.getEditor()
    editor.history.undo()
  }

  private redo = () => {
    const editor = this.reactQuillRef.getEditor()
    editor.history.redo()
  }

  private handleInlineImage = (quill: any) => {
    const range = quill.getEditor().getSelection()
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/jpg')
    input.click()
    input.onchange = async () => {
      const file = input.files && input.files[0]

      let attachment = await createAttachment(file, 'inline')
      if (attachment && attachment.data) {
        attachment = attachment.data.createMessageEmailAttachment.url
        quill.getEditor().insertEmbed(range.index, 'image', attachment)
        quill.getEditor().setSelection(range.index + 2)
      } else {
        quill.getEditor().insertEmbed(range.index, 'image', 'null.jpg')
        quill.getEditor().setSelection(range.index + 2)
      }
    }
  }

  private handleUploadAttachment = () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/jpg, application/pdf')
    input.click()
    input.onchange = async () => {
      const file = input.files && input.files[0]
      if (file) {
        this.uploadAttachment(file)
      }
    }
  }

  private uploadAttachment = async (file: File) => {
    const result = await createAttachment(file, 'attachment')
    if (result && result.data) {
      const attachment = result.data.createMessageEmailAttachment
      this.handleAddAttachment(attachment)
    }
  }

  private handleAddAttachment = (attachment: AttachmentType) => {
    const { attachments, handleAttachmentChange } = this.props
    if (handleAttachmentChange) {
      const list: AttachmentType[] = attachments.slice()
      list.push(attachment)
      handleAttachmentChange(list)
    }
  }

  private handleClearAttachment = async (index: number, id: string) => {
    const { attachments, handleAttachmentChange } = this.props
    if (handleAttachmentChange) {
      const list: AttachmentType[] = attachments.slice()
      list.splice(index, 1)
      handleAttachmentChange(list)

      await deleteAttachment(id)
    }
  }
}

export default QuillEditor
