// Import Packages
import * as React from 'react'

import { OptionType, UserType } from 'store/Leads/Types'

// Import Styled Components
import {
  Action,
  AddButtonContainer,
  Align,
  AlignContainer,
  Calendar,
  Container,
  CustomButton,
  Filter,
  FilterIcon,
  MyLeads,
  StyledCheckbox,
  StyledDropdown,
  Trello,
  TrelloContainer
} from 'shared/StyledBoard/Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus])

interface Props {
  showCalendarIcon?: boolean
  showTaskSearchModal?: boolean
  showToggleCheckBox?: boolean
  showDefaultView: (data: boolean) => void
  addList: () => void
  toggleStatus: boolean
  changeToggle: () => void
  options: OptionType
  getStateLeads: (id: string, office: string) => void
  deleteLeads: () => void
  loggedUser: boolean
  user: UserType
}

interface State {
  show: boolean
  searchText: string
  showSearchModal: boolean
  sortOrder: number
  value: string
}

const bulkOptions = [
  {
    key: '0',
    text: 'Choose an Action',
    value: 'Choose'
  },
  {
    key: '1',
    text: 'Delete Lead(s)',
    value: 'Delete'
  }
]
class StyledHeader extends React.Component<Props, State> {
  public state = {
    searchText: '',
    show: true,
    showSearchModal: false,
    sortOrder: 1,
    value: ''
  }

  public render() {
    const { showCalendarIcon, showToggleCheckBox, toggleStatus, options, user } = this.props
    const { show, value } = this.state
    return (
      <Container>
        <Filter>
          {toggleStatus && (
            <Filter>
              <FilterIcon />
              <StyledDropdown
                inline={true}
                placeholder='Select State'
                options={options}
                onChange={this.onHandleChange}
              />
            </Filter>
          )}
          {showToggleCheckBox &&
            user.role !== 'GUEST' && (
              <MyLeads>
                <span style={{ fontWeight: !toggleStatus ? 600 : 400 }}>My Leads</span>
                <StyledCheckbox className='tester' checked={toggleStatus} toggle={true} onChange={this.toggleStatus} />
                <span style={{ fontWeight: toggleStatus ? 600 : 400 }}>Lead Pool</span>
              </MyLeads>
            )}
        </Filter>
        <CustomButton>
          {showCalendarIcon && <Calendar />}
          <AlignContainer>
            <Align onClick={this.showListView} />
          </AlignContainer>
          <TrelloContainer>
            <Trello onClick={this.showKanbanView} />
          </TrelloContainer>
          {!show &&
            toggleStatus &&
            (user.role === 'ADMIN' || user.role === 'MANAGER') && (
              <Action>
                {' '}
                <StyledDropdown
                  inline={true}
                  options={bulkOptions}
                  placeholder='Choose an Action'
                  onChange={this.handleChange}
                  value={value}
                />
              </Action>
            )}
          {!show &&
            !toggleStatus &&
            user.role !== 'GUEST' && (
              <Action>
                {' '}
                <StyledDropdown
                  inline={true}
                  options={bulkOptions}
                  placeholder='Choose an Action'
                  onChange={this.handleChange}
                  value={value}
                />
              </Action>
            )}
          {!show &&
            user.role !== 'GUEST' && (
              <AddButtonContainer onClick={this.props.addList}>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </AddButtonContainer>
            )}
        </CustomButton>
      </Container>
    )
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { value }: any) => {
    this.setState({ value })
    if (value !== 'Choose') {
      const { deleteLeads } = this.props
      deleteLeads()
    }
  }

  private toggleStatus = () => {
    const { changeToggle } = this.props
    changeToggle()
  }

  private showListView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: false
      },
      () => showDefaultView(this.state.show)
    )
  }

  private showKanbanView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: true
      },
      () => showDefaultView(this.state.show)
    )
  }

  private onHandleChange = (e: React.SyntheticEvent<EventTarget>, { value }: any) => {
    const result = value.split(', ')
    const { getStateLeads } = this.props
    getStateLeads(result[0], result[1])
  }
}

export default StyledHeader
