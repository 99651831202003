import client from 'queries/apollo'

import {
  GET_CURRENT_BILLING_CYCLE,
  GET_PAYMENT_METHODS,
  GET_PLAN_ALLOWANCE,
  GET_PREVIOUS_INVOICES,
  GET_UPCOMING_INVOICE
} from 'queries/graphql/Billing/Queries'

import { Actions, BillingState } from 'store/Billing/Types'
import Store from 'store/Store'
import { BillingPlanEnum } from './Types'

export const isPlanAllowed = async (product: string) => {
  const result: any = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_PLAN_ALLOWANCE,
    variables: { product }
  })

  return result.data.getPlanAllowance
}

export const getBillingUsage = async (fetchPolicy: any) => {
  try {
    const response = await client.query({
      fetchPolicy,
      query: GET_CURRENT_BILLING_CYCLE,
      variables: { transactionWhere: {} }
    })

    if (response.data) {
      const { getBillingCycle: cycle, getTransactionsCountTenant: lifetimeTransactions } = response.data
      const usage: any = cycle.subscriptionItems.reduce((obj: any, current: any) => {
        obj[current.product] = current.quantity || 0
        return obj
      }, {})

      if (cycle.plan === BillingPlanEnum.Free) {
        usage.transaction = lifetimeTransactions
      }

      const payload: BillingState = {
        cycle,
        usage
      }

      Store.dispatch({
        payload,
        type: Actions.BillingUpdateState
      })
      return payload
    }
  } catch (error) {
    return {} as BillingState
  }

  return {} as BillingState
}

export const getUpcomingInvoice = async (fetchPolicy: any = 'cache-first') => {
  const response: any = await client.query({
    fetchPolicy,
    query: GET_UPCOMING_INVOICE,
    variables: { filter: {} }
  })

  return response.data.getUpcomingInvoice
}

export const getCancellationPreview = async () => {
  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_UPCOMING_INVOICE,
    variables: {
      filter: {
        subscription_cancel_now: true,
        subscription_prorate: false
      }
    }
  })

  return response.data.getUpcomingInvoice
}

export const getPreviousInvoices = async (filter: any) => {
  const response: any = await client.query({
    query: GET_PREVIOUS_INVOICES,
    variables: { filter }
  })

  return response.data.getPreviousInvoices
}

export const getPaymentMethods = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PAYMENT_METHODS
  })

  return response.data.getPaymentMethods
}
