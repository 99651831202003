// Import Packages
import { TimelineEvent } from 'react-event-timeline'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background: string
}

const TimeLineContainer = styled.div`
  height: 400px;
  overflow: auto;
  color: ${Colors.Black550};
  ${scrollbars};

  &.notes-styling section > div:nth-child(1) {
    background: ${Colors.Grey100} !important;
    left: 20px !important;
    width: 1px !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) > span {
    align-self: auto !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) {
    width: 40px !important;
    height: 40px !important;
    background: ${Colors.White1000} !important;
    border: 1px solid ${Colors.Grey100} !important;
    justify-content: center !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) > span > i{
    font-size: 18px;
    margin-left: 4px;
    color: ${Colors.Black620};
    margin-top: -8px;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) > span {
    width: 38px !important;
    height: 38px !important;
  }
`

const Container = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 14px;

  div {
    display: flex;
  }

  b {
    font-weight: bold;
    margin-right: 3px;
    font-style: italic;
    color: ${Colors.DarkBlue200}
  }
`

const StyledTimelineEvent = styled(TimelineEvent)`
  background: white;
`

const Profile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  margin-top: -6px;
  margin-left: 5px;
  margin-right: 3px;
`

const Time = styled.div`
  position: absolute;
  left: -100px;
  top: 3px;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.DarkBlue200};
`

const People = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 38px;
  height: 38px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  cursor: pointer;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

export {
  Container,
  Profile,
  StyledTimelineEvent,
  TimeLineContainer,
  People,
  StyledPopup,
  Time
}
