import gql from 'graphql-tag'

export const UPDATE_BILLING_PLAN = gql`
  mutation updateBillingPlan($input: StripeCheckoutSessionInput) {
    updateBillingPlan(input: $input) {
      sessionId
      publicKey
      status
      error {
        code
        type
        message
      }
    }
  }
`
