// Import Components
import { debounce } from 'lodash'
import client from 'queries/apollo'

// Import Graphql Mutations
import { UPDATE_TENANT, UPLOAD_COMPANY_LOGO } from 'queries/graphql/Tenant/Mutations'

export const updateProfile = async (data: any) => {
  await changeRemoteData(data)
}

const changeRemoteData = debounce(async (data: any) => {
  const response = await client.mutate({
    mutation: UPDATE_TENANT,
    variables: {
      ...data
    }
  })
  return response.data.updateTenant
}, 500)

export const uploadCompanyLogo = async (file: File) => {
  const response = await client.mutate({
    mutation: UPLOAD_COMPANY_LOGO,
    variables: { file }
  })
  return response.data.uploadCompanyLogo
}
