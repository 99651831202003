// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Icon } from 'semantic-ui-react'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import ConfirmAlert from 'sweetalert2'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { deleteUserCreditDebits, editUserCreditDebit } from '../../../UserProfileComponent'
import { DropDownPopup, StyledDropdown } from './Styled'

const { editLabel, deleteLabel } = Strings.userProfile.detailSection.accounts
interface DataItem {
  amount: number
  currency: string
  currencySymbol: string
  createdAt: moment.Moment
  feeName: string
  status: string
  transactionId: string
  transactionType: string
}

const CustomTransactionId = ({ rowData }: { rowData: DataItem }) => (
  <span>{rowData.transactionId ? rowData.transactionId : `--`}</span>
)

const CustomAmount = ({ rowData }: { rowData: DataItem }) => (
  <span>${`${rowData.amount.toLocaleString()} (${rowData.transactionType === 'Credit' ? 'C' : 'D'})`}</span>
)

const CustomPaid = ({ rowData }: { rowData: DataItem }) => {
  return <span>{rowData.status}</span>
}

const CustomActions = ({ rowData }: { rowData: DataItem }) => {
  const deleteItem = () => {
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this credit/debit entry!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        deleteUserCreditDebits(rowData[`_id`])
        ConfirmAlert('Deleted!', 'Your credit/debit entry has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your credit/debit entry is safe', 'error')
      }
    })
  }

  const editItem = () => {
    editUserCreditDebit(rowData)
  }

  return rowData.status === 'Not Applicable' ? (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              <Dropdown.Item text={editLabel} onClick={editItem} />
              <Dropdown.Item text={deleteLabel} onClick={deleteItem} />
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  ) : null
}

export { CustomActions, CustomAmount, CustomPaid, CustomTransactionId }
