import gql from 'graphql-tag'

// Public Notification
export const GET_PUBLIC_NOTIFICATION = gql`
  query getPublicNotifications {
    getPublicNotifications {
      _id
      title
      content
      senderId {
        _id
        firstName
        lastName
        profileImage
      }
      receiverId {
        _id
        firstName
        lastName
      }
      isViewed
      createdAt
    }
  }
`

export const GET_UNREAD_PUBLIC_NOTIFICATION = gql`
  query getUnreadPublicNotificationCount($id: String) {
    getUnreadPublicNotificationCount(userId: $id)
  }
`

// Feed Notification
export const GET_FEED_NOTIFICATION = gql`
  query getFeedNotifications {
    getFeedNotifications {
      _id
      title
      content
      category
      senderId {
        _id
        firstName
        lastName
        profileImage
      }
      receiverId {
        _id
        firstName
        lastName
      }
      url
      refData
      pageRoute
      isViewed
      createdAt
    }
  }
`

export const GET_UNREAD_FEED_NOTIFICATION = gql`
  query getUnreadFeedNotificationCount {
    getUnreadFeedNotificationCount
  }
`

export const GET_UNREAD_FEED_PUBLIC_NOTIFICATION = gql`
  query getUnreadFeedPublicNotificationCount {
    getUnreadFeedPublicNotificationCount {
      totalCount
      sound
    }
  }
`
