import { PaymentNumericEnum } from 'store/Transactions/Types'

export enum ActionEnum {
  Cancel = 'itemForm:cancel',
  Change = 'itemForm:change',
  Submit = 'itemForm:submit',
}

export interface FormType {
  errors: FormErrorType
  fee?: any
  name?: string
  type?: PaymentNumericEnum
}

export interface FormErrorType {
  fee?: string
  name?: string
  type?: string
}

export interface OptionType {
  key: string
  text: string
  value: PaymentNumericEnum
}
