// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'



const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const AddContactLeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35%;
  height: auto;
  background: ${Colors.Grey110};

  .ui.small.image {
    width: 190px;
  }
`

const StyledIcon = styled.div`
  background: ${Colors.Black700};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50px;
  width: 70px;
`

const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 15px;
  color: ${Colors.Black500};
`

const Text = styled.div`
  width: 220px;
  text-align: center;
  margin-top: 15px;
  font-size: 10px;
  color: ${Colors.Black500};
`

const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 55%;
  height: auto;
`

const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  &.ui.form .role .ui.input input {
    max-width: 248px;
  }

  &.ui.form .brokerage .ui.input input {
    max-width: 400px;
  }

  &.ui.form .field>.selection.dropdown {
    width: 49%;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

export {
  AddContactLeftPanel,
  AddForm,
  Container,
  StyledForm,
  StyledIcon,
  Text,
  Title,
}
