// Import Packages
import { debounce, escapeRegExp, filter } from 'lodash'
import * as React from 'react'
import InputMask from 'react-input-mask'
import { Button, Form, Image, Input } from 'semantic-ui-react'

// Import Components
import AddressInput from 'shared/AddressInput'
import LoadingIndicator from 'shared/LoadingIndicator'
import { default as MlsSearchInput } from 'shared/Mls/SearchInput'
import Modal from 'shared/Modal'
import StateDropdown from 'shared/StateDropdown'
import { formToast } from 'shared/Toast/Toast'
import { getUsers } from './Queries'
import { handleValidation } from './Validation'

// Import Colors
import Colors from 'design/Colors'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  AddForm,
  AddLeftPanel,
  Container,
  StyledCenter,
  StyledForm,
  StyledGroup,
  StyledSearch
} from './Styled'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'
import { MlsType } from 'shared/Mls/Types'
import { FormErrorType, FormType, SearchOptionType, UserType } from './Types'

interface Props {
  addOfficeBoardName: string
  edit: boolean
  officeDetails: any
  onClose: (result: any) => void
}

interface State {
  form: FormType
  loading: string
  managerText: string
  searching: boolean
  transition: boolean
  userOptions: SearchOptionType[]
  userResults: SearchOptionType[]
}

class AddOfficeModal extends React.Component<Props, State> {
  public state = {
    form: { errors: {} as FormErrorType } as FormType,
    loading: '',
    managerText: '',
    searching: false,
    transition: true,
    userOptions: [] as SearchOptionType[],
    userResults: [] as SearchOptionType[]
  }

  public componentDidMount = async () => {
    this.setState({ loading: 'Gathering data...' })
    const { addOfficeBoardName, edit, officeDetails } = this.props
    const { form } = this.state

    const users = await this.getUserOptions()
    const userOptions = (users && users.options) || ([] as SearchOptionType[])
    let managerText = ''

    if (edit) {
      const { branchName, city, email, mls, mlsId, phone, state, street, zipCode } = officeDetails

      form.branchName = branchName || ''
      form.city = city || ''
      form.email = email || ''
      form.mls = (mls && mls._id) || ''
      form.mlsId = mlsId || ''
      form.phone = phone || ''
      form.state = state || addOfficeBoardName || ''
      form.streetAddress = street || ''
      form.streetAddress = street || ''
      form.zipCode = zipCode || ''

      if (users) {
        form.manager = users.manager._id
        managerText = users.manager.title
      }
    }

    this.setState((prev: State) => ({
      form,
      loading: '',
      managerText,
      userOptions
    }))
  }

  public render() {
    const { edit } = this.props
    const {
      form,
      form: { errors },
      loading,
      managerText,
      searching,
      transition,
      userResults
    } = this.state

    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loading} />}
            <AddLeftPanel>
              <StyledCenter>
                <Image src={edit ? Strings.office.src1 : Strings.office.src} size="small" />
              </StyledCenter>
            </AddLeftPanel>
            <AddForm>
              <StyledForm size={'mini'}>
                <Form.Field
                  control={Input}
                  label="Branch Name"
                  name="branchName"
                  value={form.branchName}
                  placeholder="Branch Name"
                  onChange={this.handleInputChange}
                  error={!!errors.branchName}
                />
                <div className="field">
                  <label>Street Address</label>
                  <AddressInput
                    address={{
                      city: form.city,
                      state: form.state,
                      streetAddress: form.streetAddress,
                      streetName: form.streetAddress,
                      zipCode: form.zipCode
                    }}
                    error={!!errors[`street`]}
                    name="street"
                    onChange={this.handleAddressChange}
                    placeholder="Street Address"
                    size="mini"
                  />
                </div>
                <StyledGroup widths="equal">
                  <div className="field">
                    <label>City</label>
                    <AddressInput
                      address={{
                        city: form.city,
                        state: form.state,
                        streetAddress: form.streetAddress,
                        streetName: form.streetAddress,
                        zipCode: form.zipCode
                      }}
                      error={!!errors.city}
                      mode={AddressModeEnum.City}
                      name="city"
                      onChange={this.handleCityChange}
                      placeholder="City"
                      size="mini"
                    />
                  </div>
                  <div className="field">
                    <label>State</label>
                    <StateDropdown
                      placeholder="State"
                      name="state"
                      value={form.state}
                      onChange={this.handleInputChange}
                      size={'mini'}
                    />
                  </div>
                  <Form.Field
                    control={Input}
                    placeholder="Zipcode"
                    name="zipCode"
                    label="Zipcode"
                    value={form.zipCode}
                    onChange={this.handleInputChange}
                    error={!!errors.zipCode}
                  />
                </StyledGroup>
                <Form.Group widths="equal" style={{ width: '34.3%' }}>
                  <Form.Field error={!!errors.manager}>
                    <label>{Strings.office.managerName}</label>
                    <StyledSearch
                      loading={searching}
                      selectFirstResult={true}
                      onResultSelect={this.handleResultSelect}
                      onSelectionChange={this.handleResultSelect}
                      onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
                      name="manager"
                      placeholder="Manager Name"
                      results={userResults}
                      value={managerText}
                    />
                  </Form.Field>
                  <Form.Field
                    control={Input}
                    placeholder="Email"
                    name="email"
                    label={
                      <div
                        style={{
                          color: Colors.Black500,
                          fontSize: '.92857143em',
                          fontWeight: 'bold',
                          marginBottom: '5px'
                        }}
                      >
                        {'Email'}
                        <span
                          style={{
                            color: Colors.Black550,
                            fontSize: '10px',
                            fontStyle: 'italic',
                            marginLeft: '5px'
                          }}
                        >
                          {Strings.leads.optional}
                        </span>
                      </div>
                    }
                    value={form.email}
                    onChange={this.handleInputChange}
                    error={!!errors.email}
                  />
                  <Form.Field
                    control={Input}
                    label={
                      <div
                        style={{
                          color: Colors.Black500,
                          fontSize: '.92857143em',
                          fontWeight: 'bold',
                          marginBottom: '5px'
                        }}
                      >
                        {Strings.leads.phone}
                        <span
                          style={{
                            color: Colors.Black550,
                            fontSize: '10px',
                            fontStyle: 'italic',
                            marginLeft: '5px'
                          }}
                        >
                          {Strings.leads.optional}
                        </span>
                      </div>
                    }
                  >
                    <InputMask
                      mask="(999) 999-9999"
                      name="phone"
                      placeholder="(xxx) xxx-xxxx"
                      onChange={this.handlePhoneChange}
                      value={form.phone}
                    />
                  </Form.Field>
                </Form.Group>
                <StyledGroup width="equal">
                  <div className="field">
                    <label>MLS</label>
                    <MlsSearchInput
                      error={!!errors.mls}
                      onUpdate={this.handleMlsChange}
                      placeholder="MLS"
                      state={form.state}
                      value={form.mls}
                      size='mini'
                    />
                  </div>
                  <Form.Field
                    control={Input}
                    label="Office ID"
                    name="mlsId"
                    value={form.mlsId}
                    placeholder="Office ID"
                    onChange={this.handleInputChange}
                    error={!!errors.mlsId}
                  />
                </StyledGroup>
                <Button type="button" onClick={this.handleSubmit} content="SAVE" />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        minWidth={750}
        width={700}
      />
    )
  }

  private handleAddressChange = (address: AddressType) => {
    const { form } = this.state
    const updated = {
      ...form,
      ...address
    }
    this.setState({ form: updated })
  }

  private handleCityChange = (address: AddressType) => {
    const { form } = this.state
    const updated = {
      ...form,
      city: address.city,
      state: address.state
    }
    if (!form.zipCode) {
      updated.zipCode = address.zipCode
    }
    this.setState({ form: updated })
  }

  private handleMlsChange = (id: string, mlsData: MlsType) => {
    const { form } = this.state
    form.mls = id
    this.setState({ form })
  }

  private handleSearchChange = (e: any, { value }: any) => {
    const { form, userOptions } = this.state
    form.manager = ''
    this.setState({
      form,
      managerText: value,
      searching: true
    })
    setTimeout(() => {
      const isMatch = (result: any) => re.test(result.title)
      const re = new RegExp(escapeRegExp(value), 'i')
      this.setState({
        searching: false,
        userResults: filter(userOptions, isMatch)
      })
    }, 300)
  }

  private getUserOptions = async () => {
    const { officeDetails } = this.props
    const officeUsers = await getUsers()
    if (!officeUsers) {
      return null
    }

    let manager = {} as SearchOptionType
    const filtered = officeUsers.filter((user: UserType) => user.role !== 'TEAM_LEADER')
    const options: SearchOptionType[] = filtered.map((user: UserType) => {
      const option: SearchOptionType = {
        _id: user._id,
        description: user.emails.length > 0 ? user.emails[0].value : '',
        phone: user.phones.length > 0 ? user.phones[0].value : '',
        title: `${user.firstName} ${user.lastName}`
      }
      if (option._id === officeDetails.managerId) {
        manager = option
      }
      return option
    })

    return {
      manager,
      options
    }
  }

  private closeSelf = (result: any = null) => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      onClose(result)
    }, 300)
  }

  private handleResultSelect = (event: React.SyntheticEvent<HTMLDivElement>, { result }: any) => {
    const { form } = this.state
    form.manager = result._id
    form.email = result.description
    form.phone = result.phone
    this.setState({
      form,
      managerText: result.title
    })
  }

  private handlePhoneChange = (event: any) => {
    this.handleInputChange(event, { name: 'phone', value: event.target.value })
  }

  private handleInputChange = async (event: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private handleSubmit = () => {
    this.setState({ loading: 'Validating data...' })
    const { officeDetails } = this.props
    const { form } = this.state
    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      this.setState({ loading: '' })
      formToast(validation.errors)
      return
    }

    const result = {
      ...officeDetails,
      ...form,
      street: form.streetAddress
    }
    this.setState({ loading: '' })
    this.closeSelf(result)
  }
}

export default AddOfficeModal
