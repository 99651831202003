// Import Packages
import * as React from 'react'

// Import Components
import AddressInput from 'shared/AddressInput'
import { validateAddress } from 'shared/AddressInput/Validation'
import Modal from 'shared/Modal'
import StateDropdown from 'shared/StateDropdown'
import Toast from 'shared/Toast/Toast'
import { UpdateProgress } from '../../../../UserProfileComponent'

import { AddressModeEnum, AddressType, AddressValidationType } from 'shared/AddressInput/Types'

import { AddressPropType, FormErrorType, FormType } from './Types'

// Import Styled Components
import { Container, Row, StyledButton, StyledForm, StyledInput, Title } from './Styled'

interface Props {
  closeModal: () => void
  edit: (address: AddressPropType) => void
  address: AddressPropType
}

interface State {
  form: FormType
  transition: boolean
}

class AddressFormModal extends React.Component<Props, State> {
  public state = {
    form: {
      city: '',
      errors: {} as FormErrorType,
      state: '',
      streetAddress: '',
      type: '',
      zipCode: ''
    } as FormType,
    transition: true
  }

  public componentDidMount = async () => {
    const { address } = this.props
    const { form } = this.state
    if (address) {
      const streetAddress = `${address.streetNumber || ''} ${address.streetName || ''}`
      const initialForm = {
        ...form,
        ...address,
        streetAddress: streetAddress.trim()
      }
      this.setState({ form: initialForm })
    }
  }

  public render() {
    const {
      form,
      form: { errors },
      transition
    } = this.state
    return (
      <Modal
        content={
          <Container padding={2}>
            <Title>Please Enter Your {form.type} Address</Title>
            <StyledForm onSubmit={this.saveAddress}>
              <Row>
                <AddressInput
                  address={form as AddressType}
                  error={!!errors.streetAddress}
                  name="streetAddress"
                  onChange={this.handleAddressChange}
                  placeholder="Street Address"
                  showNumberPopup={false}
                />
              </Row>
              <AddressInput
                address={form as AddressType}
                error={!!errors.city}
                mode={AddressModeEnum.City}
                name="city"
                onChange={this.handleCityChange}
                placeholder="City"
              />
              <StateDropdown
                error={!!errors.state}
                name="state"
                onChange={this.handleInputChange}
                placeholder="State"
                value={form.state}
              />
              <StyledInput
                className="zipcode"
                type="text"
                placeholder="Zip Code"
                name="zipCode"
                value={form.zipCode}
                onChange={this.handleInputChange}
                error={!!errors.zipCode}
              />
              <Row justify="center">
                <StyledButton type="submit" content="Save" />
              </Row>
            </StyledForm>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={500}
      />
    )
  }

  private handleAddressChange = (address: AddressType) => {
    const { form } = this.state
    const updatedForm = {
      ...form,
      ...address
    }
    this.setState({ form: updatedForm })
  }

  private handleCityChange = (address: AddressType) => {
    const { form } = this.state
    const updatedForm = {
      ...form,
      city: address.city,
      state: address.state
    }
    this.setState({ form: updatedForm })
  }

  private handleInputChange = (e: any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    form.errors[name] = ''
    this.setState({ form })
  }

  private saveAddress = () => {
    const { address, edit } = this.props
    const { form } = this.state

    const validation = validateAddress(form as AddressValidationType)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      Object.keys(validation.errors).forEach((key: string) => {
        Toast({
          message: validation.errors[key],
          type: 'error'
        })
      })
      return this.setState({ form })
    }

    if (edit) {
      const update: any = {
        ...form,
        type: address.type
      }
      delete update.streetAddress
      delete update.errors
      edit(update as AddressPropType)
    }
    UpdateProgress()
    this.closeModal()
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default AddressFormModal
