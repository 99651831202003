import gql from 'graphql-tag'

export const EDIT_DOC_TEMPLATE = gql`
  mutation editDocTemplate($docTemplateId: String, $name: String) {
    updateDocTemplate(where: { _id: $docTemplateId }, input: { name: $name }) {
      _id
      description
      isActive
      name
      role
    }
  }
`

export const UPDATE_DOC_TEMPLATE = gql`
  mutation updateDocTemplate($docTemplateId: String, $input: DocTemplateInput) {
    updateDocTemplate(where: { _id: $docTemplateId }, input: $input) {
      _id
    }
  }
`

export const CREATE_DOC_TEMPLATE = gql`
  mutation createDocTemplate($input: DocTemplateInput, $file: Upload) {
    createDocTemplate(input: $input, file: $file) {
      _id
      description
      isActive
      name
      role
      template {
        _id
        url
        thumbnailUrl
        extractMarkup {
          id
          label {
            text
            value
          }
          value
          page
          top
          left
          width
          height
        }
        extractRawUrl
        extractWordsUrl
      }
    }
  }
`

export const DELETE_DOC_TEMPLATE = gql`
  mutation deleteDocTemplate($docTemplateId: String) {
    deleteDocTemplate(_id: $docTemplateId)
  }
`

export const REORDER_DOC_TEMPLATE = gql`
  mutation updateDocTemplateOrder($id: String, $order: Int) {
    updateDocTemplateOrder(_id: $id, order: $order) {
      _id
    }
  }
`

export const UPDATE_DOC_TEMPLATE_DOCUMENT = gql`
  mutation updateDocument($id: String, $extractMarkup: [DocumentMarkupInput]) {
    updateDocument(
      where: { _id: $id }
      input: { extractMarkup: $extractMarkup }
    ) {
      _id
    }
  }
`
