// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import {
  CREATE_TRANSACTION_SETTING,
  DELETE_TRANSACTION_SETTING
} from 'queries/graphql/Settings/TransactionFee/FeeSettings/Mutation'

export const createTransactionSetting = async (newCustomSetting: any) => {
  const response = await client.mutate({
    mutation: CREATE_TRANSACTION_SETTING,
    variables: {
      ...newCustomSetting
    }
  })

  return response.data.createTransactionSetting
}

export const deleteTransactionSetting = async (id: string) => {
  const response = await client.mutate({
    mutation: DELETE_TRANSACTION_SETTING,
    variables: { id }
  })
  return response.data.deleteTransactionSetting
}
