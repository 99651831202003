// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background: string
  size: number
}

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  width: 2.5em;
  height: 2.5em;
  font-size: ${(props: Props) => `${props.size}em`};
  border-radius: 50%;
  color: ${Colors.White1000};
  background-color: ${(props: Props) => props.background};
  line-height: 0;
`

export { Circle }
