import { Button, Dropdown, Form, Input } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, primaryStyledButton, x, y } from 'design/Styled'

interface Props {
  duration?: number
}

const text = css`
  font: ${fonts.large.regular};
  width: 100%;
  padding: 0 1em 1em;
  margin: 0;
  text-align: center;
`

export const Container = styled.section`
  ${y};
  height: 100%;
  overflow: hidden;

  .grecaptcha-badge {
    display: none !important;
  }

  .office-step.rp-transition-child {
    ${x};
    justify-content: center;
  }
`

export const Title = styled.h1`
  width: 100%;
  padding: 0 1em;
  padding-bottom: 1em;
  margin: 0;
  text-align: center;
  color: ${Colors.TextPrimary};
`

export const Description = styled.p`
  ${text};
  color: ${Colors.Text};
`

export const Col = styled.div`
  ${y};
  align-items: center;
  justify-content: center;
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const List = styled.ul`
  ${y};
  max-height: 100px;
  margin: 0;
  padding: 0;
  list-style: none;
`

export const ListItem = styled.li`
  ${x};
  padding: 0.25em;
  colors: ${Colors.TextDark};
  font: ${fonts.small.medium};
  justify-content: center;
  text-align: center;
`

export const Bold = styled.strong`
  color: ${Colors.TextPrimary};
`

export const Step = styled(Col)`
  animation-duration: ${({ duration }: Props) => `${duration}ms` || '300ms'};
`

export const StyledForm = styled(Form)`
  width: 100%;
  transition: all 100ms;
`

export const Label = styled.label`
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 0.5em;
`

export const StyledInput = styled(Input)`
  ${x};
  align-items: stretch;
  justify-content: center;
  max-width: 300px;
  .ui.button {
    background-color: ${Colors.ButtonPrimary};
    color: ${Colors.TextLight};
  }
`

export const StyledDropdown = styled(Dropdown)`
  ${x};
  width: 100%;
  max-width: 400px;
  margin-bottom: 1em;
`

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
`

export const Continue = styled.a`
  ${text};
  cursor: pointer;
`

export const Img = styled.img`
  display: block;
  margin: 1em 0;
  width: 100%;
  max-width: 150px;
`
