// Import Packages
import { debounce } from 'lodash'
import * as React from 'react'

// Import Components
import DashboardSearchModal from 'app/Dashboard/DashboardSearchModal'

import { getElasticData } from '../../Transactions/Dashboard/TransactionQueries'

// Import Styled Components
import {
  SearchContainer,
  SearchInput,
  SearchWrapper,
} from './Styled'

// Font Awesome Icons
import {
  faSearch,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faSearch, faTimes])

interface State {
  module: string
  open: boolean
  searchText: string
  filterText: any
  contacts: any
  leads: any
  offices: any
  tasks: any
  transactions: any
  users: any
  teams: any
  loader: boolean
}

class DashboardSerachBar extends React.Component<{}, State> {
  public debounceJob: any = null

  public state = {
    filterText: [],
    loader: false,
    module: 'All',
    open: false,
    searchText: '',

    contacts: [],
    leads: [],
    offices: [],
    tasks: [],
    teams: [],
    transactions: [],
    users: [],
  }

  public render() {
    const { open, searchText, module, loader } = this.state
    return (
      <SearchWrapper>
        <SearchContainer>
          <SearchInput
            icon={
              searchText ?
                <span>
                  <span>
                    <FontAwesomeIcon icon={['far', 'search']} style={{ right: '25px' }} />
                  </span>
                  <span onClick={this.clearState}><FontAwesomeIcon icon={['far', 'times']} /></span>
                </span> :
                <span>
                  <FontAwesomeIcon icon={['far', 'search']} style={{ right: '6px' }} />
                </span>
            }
            placeholder='Find transactions, leads, tasks and more...'
            onClick={this.showAdvanceSearcModal}
            onChange={this.handleChange}
            value={searchText} />
        </SearchContainer>
        {
          open &&
          <DashboardSearchModal
            closeAdvanceSearchModal={this.closeSearcModal}
            data={this.state}
            activeModule={this.activeModule}
            module={module}
            loader={loader}
          />
        }
      </SearchWrapper>
    )
  }

  private activeModule = (module: string) => {
    this.setState({ module })
  }

  private getElasticSearchData = async () => {
    const { searchText } = this.state

    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(async () => {
      this.setState({ loader: true })
      let data: any = []
      data = await getElasticData(searchText)
      if (data) {
        this.setState({ loader: false })
      }
      if (data === null) {
        this.setState({ loader: false })
      }
      const transactionObj: any = []
      const leadObj: any = []
      const contactObj: any = []
      const officeObj: any = []
      const teamObj: any = []
      const userObj: any = []
      const taskObj: any = []
      if (data !== null) {
        for (const result of data) {
          switch (Object.keys(result)[0]) {
            case 'transactions':
              transactionObj.push(Object.values(result)[0])
              break
            case 'leads':
              leadObj.push(Object.values(result)[0])
              break
            case 'contacts':
              contactObj.push(Object.values(result)[0])
              break
            case 'offices':
              officeObj.push(Object.values(result)[0])
              break
            case 'teams':
              teamObj.push(Object.values(result)[0])
              break
            case 'users':
              userObj.push(Object.values(result)[0])
              break
            case 'tasks':
              taskObj.push(Object.values(result)[0])
              break
          }
        }
      }
      if (searchText) {
        this.setState({
          contacts: contactObj,
          leads: leadObj,
          offices: officeObj,
          tasks: taskObj,
          teams: teamObj,
          transactions: transactionObj,
          users: userObj,
        })
      } else {
        this.setState({
          contacts: [],
          leads: [],
          offices: [],
          tasks: [],
          teams: [],
          transactions: [],
          users: [],
        })
      }

    }, 1000)

    this.debounceJob()
  }

  private clearState = () => {
    this.setState({ searchText: '' }, () => this.getElasticSearchData())
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    this.setState({ searchText: value }, () => this.getElasticSearchData())
  }

  private closeSearcModal = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  private showAdvanceSearcModal = () => {
    this.setState({ open: true })
  }
}

export default DashboardSerachBar
