// Import Packages
import { sortBy } from 'lodash'

// Import Colors
import Colors from 'design/Colors'

// import { loggedUser } from '../Users'
// Import Components
// import { getLoggedInUser } from 'utils'

const boardBorderColors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Grey800}`,
  `${Colors.Green560}`
]

// let loggedUser: any = {}
export const formatKanbanData = (usersGroupData: any, loggedUser: any) => {
  // getLoggedInUser().then((response: any) => {
  //   loggedUser = response
  // })
  const usersGroup = Object.assign(usersGroupData)
  for (const group of usersGroup) {
    if (group.name === 'TEAM_LEADER') {
      group.name = group.name.replace('_', ' ')
    }
    group.color = getRandomColors()
    if (
      group.name === loggedUser.role ||
      group.name === 'GUEST' ||
      group.name === 'TEAM LEADER' ||
      (loggedUser.role === 'TEAM_LEADER' && group.name === 'AGENT')
    ) {
      group.showAddButton = false
    } else {
      group.showAddButton = true
    }
    if (!group.users) {
      group.items = Object.assign([])
    } else {
      group.items = Object.assign(group.users)
    }
    sortBy(group.items, ['groupOrder'])
  }
  return usersGroup
}

export const reformatData = (usersGroupData: any) => {
  const usersGroup = Object.assign(usersGroupData)
  for (const group of usersGroup) {
    group.color = getRandomColors()
    if (!group.items) {
      group.users = Object.assign([])
    } else {
      group.users = Object.assign(group.items)
    }
    sortBy(group.users, ['groupOrder'])
  }
  return usersGroup
}

export const formatListData = (usersData: any, loggedUser: any) => {
  const userData: any = []
  const userListData = Object.assign(usersData)
  // tslint:disable-next-line:forin
  for (const index in userListData) {
    if (userListData[index].role === 'TEAM_LEADER') {
      userListData[index].role = userListData[index].role.replace('_', ' ')
    }
    if (userListData[index].role !== 'GUEST') {
      userListData[index].showAddButton = true
    } else {
      userListData[index].showAddButton = false
    }
    if (loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER') {
      userListData[index].showEditButton = true
    } else {
      userListData[index].showEditButton = false
    }
    if (userListData[index].role !== 'ADMIN') {
      userData.push(userListData[index])
    }
  }
  return userData
}

const getRandomColors = () => {
  return boardBorderColors[Math.floor(Math.random() * boardBorderColors.length)]
}
