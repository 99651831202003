// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

// Import Components
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import SignatureModal from 'shared/MessageEmail/SignatureModal'
import ActionItem from './ActionItem'

import * as MailboxActions from 'store/Mailbox/Actions'
import { MailboxBoard as MailboxBoardType } from 'store/Mailbox/Types'
import { getInboxBoard } from '../../MailboxQueries'

interface Props {
  getMailboxBoardData: (data: MailboxBoardType) => void
  toggleModal: (modal: any) => void
}

class Actions extends React.Component<Props, {}> {
  public render() {
    return (
      <div>
        <ActionItem title="Compose New Email" icons={<Icon name="plus circle" />} action={this.toggleComposeModal} />
        <ActionItem title="Refresh Board" icons={<Icon name="sync" />} action={() => this.updateMailbox('INBOX', 0)} />
        <ActionItem title="Edit Signature" icons={<Icon name="cog" />} action={this.toggleSignatureModal} />
      </div>
    )
  }

  private toggleComposeModal = () => {
    this.props.toggleModal(<ComposeModal onClose={this.toggleComposeModal} />)
  }

  private toggleSignatureModal = () => {
    this.props.toggleModal(<SignatureModal onClose={this.toggleSignatureModal} />)
  }

  private updateMailbox = async (type: string, skip: number) => {
    const { getMailboxBoardData } = this.props
    if (type === 'INBOX') {
      const inbox = await getInboxBoard(skip)
      if (inbox) {
        getMailboxBoardData(inbox)
      }
    }
  }
}

export default connect(
  null,
  {
    getMailboxBoardData: MailboxActions.getBoardData
  }
)(Actions)
