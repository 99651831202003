import client from 'queries/apollo'

import { UPDATE_TASK } from 'queries/graphql/Tasks/Mutations'
import { CREATE_USER } from 'queries/graphql/Users/Mutation'
import { GET_NEW_USER_SETTINGS } from 'queries/graphql/Users/Queries'

import { getTaskDetailsData } from 'app/Tasks/TaskQueries'
import { SharedUser } from 'store/Tasks/Types'
import { getLoggedInUser } from 'utils'

export const shareTaskWith = async (taskId: string, userData: SharedUser) => {
  let newShare = userData._id

  if (newShare === '') {
    const user = await getLoggedInUser()
    const office = user.office[0]
    const settings = await client.query({
      fetchPolicy: 'network-only',
      query: GET_NEW_USER_SETTINGS
    })
    const {
      data: { capSettings, transactionSettings, groups, offices }
    } = settings

    if (!capSettings || !transactionSettings || !groups || !offices) {
      return null
    }

    const capSetting = capSettings.find((item: any) => item.name && item.name.toLowerCase() === 'default')
    const transactionSetting = transactionSettings.find((item: any) => item.isDefault)
    const group = groups.find(
      (item: any) => item.name && item.name.toLowerCase() === 'guest' && item.office && item.office._id === office._id
    )

    if (!capSetting || !transactionSetting || !group || !office) {
      return null
    }

    const newUser = await client.mutate({
      mutation: CREATE_USER,
      variables: {
        capSetting: capSetting._id,
        firstName: userData.firstName,
        group: group._id,
        lastName: userData.lastName,
        office: [office._id],
        phone: {
          type: 'Mobile',
          value: '(***) ***-****'
        },
        transactionFeeSetting: transactionSetting._id,
        userName: userData.userName
      }
    })

    if (!newUser) {
      return null
    }

    newShare = newUser.data.createUser._id
  }

  let sharedIds: string[] = [newShare]
  const task = await getTaskDetailsData(taskId)
  if (task.sharedWith) {
    sharedIds = task.sharedWith.map((user: any) => user._id)
    if (!sharedIds.includes(newShare)) {
      sharedIds.push(newShare)
    }
  }

  const result = await client.mutate({
    fetchPolicy: 'no-cache',
    mutation: UPDATE_TASK,
    variables: {
      sharedWith: sharedIds,
      taskId
    }
  })

  return result.data.updateTask
}

export const setDueDate = async (taskId: string, dueDate: Date) => {
  const result = await client.mutate({
    fetchPolicy: 'no-cache',
    mutation: UPDATE_TASK,
    variables: {
      dueDate,
      taskId
    }
  })

  return result.data.updateTask
}
