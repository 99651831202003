// Import Components
import client from 'queries/apollo'
import { GET_USER_WALLPAPER } from 'queries/graphql/Passes/Queries'

// Import Graphql Mutations
import { DELETE_USER_COLOR_WALLPAPER, UPDATE_USER_WALLPAPER } from 'queries/graphql/Passes/Mutation'

export const updateUserWallpaper = async (id: string, data: any) => {
  await client.mutate({
    mutation: UPDATE_USER_WALLPAPER,
    variables: {
      ...data,
      id
    }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_WALLPAPER
  })

  return response.data.getUserWallpaper
}

export const deleteUserColorWallpaper = async (id: string, newData: object) => {
  await client.mutate({
    mutation: DELETE_USER_COLOR_WALLPAPER,
    variables: {
      ...newData,
      id
    }
  })

  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_WALLPAPER
  })

  return response.data.getUserWallpaper
}
