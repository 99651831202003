import styled from 'styled-components'

import Colors from 'design/Colors'
import {
  scrollYContainer,
  y,
} from 'design/Styled'

interface Props {
  background: string
  align?: string
  color?: string
}

export const Container = styled.div`
  ${y};
  flex: 1;
  overflow: hidden;
`

export const ScrollY = styled.div`
  ${scrollYContainer};
  padding: 0 1em 2em 1em;
`

export const Type = styled.div`
  background-color: ${(props: Props) => props.background || props.background};
  min-width: 50px;
  max-width: 40px;
  justify-content: center;
  text-align: -webkit-center;
  color: ${(props: Props) => props.color || Colors.Black500};
  font-size: 12px;
  font-weight: bold;
`

export const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 25px;
  min-height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  margin-right: 6px;
`
