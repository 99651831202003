// Import Packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChromePicker } from 'react-color'
import { Button, Grid, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Color from 'design/icons/dashboard/color.png'
import PickColor from 'design/icons/dashboard/Pick-color.png'
import FileUpload from 'design/icons/dashboard/Upload-image.png'
import Wallpaper from 'design/icons/dashboard/wallpaper.png'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  color?: string
  background?: string
}

const Container = styled.div`
  .ui.grid {
    padding: 20px;
  }

  .first-column {
    border-right: 2px solid ${Colors.Red110};
  }
`

const CustomizeTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-bottom: 14px;

  span:nth-child(2) {
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.Black500};
  }
`

const StyledColor = styled.div`
  background: ${(props: Props) => props.color || props.color};
  height: 43px;
  width: 67px;
  border-radius: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid;
`

const ColorImage = styled.div`
  background: url(${Color});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  margin-right: 10px;
`

const PickColorImage = styled.div`
  background: url(${PickColor});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 66px;
  height: 60px;
  align-self: center;
  margin-top: -8px;
  cursor: pointer;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 25px;
  z-index: 99999;
  width: 18px !important;
  height: 18px;
  top: 5px;
  visibility: hidden;
  background-color:  ${Colors.Black61};
  border-radius: 10px;
  color: ${Colors.White1000};
  cursor: pointer;
`

const Customize = styled.div`
  height: 270px;
  overflow: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.White1000};
  }
`

const StyledColorGrid = styled(Grid)`
  &.ui.grid>.row {
    overflow: auto;

    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: ${Colors.White1000};
    }
  }

  &.ui.grid>.row>.column {
    :hover {
      ${StyledFontAwesomeIcon} {
        visibility: visible;
      }
    }
  }

  &.ui.grid>.row>.column>img, .ui.grid>.row>img {
    height: 100px;
    cursor: pointer;

    :hover {
      ${StyledFontAwesomeIcon} {
        visibility: visible;
      }
    }
  }
`

const WallpaperImg = styled.div`
  background: url(${Wallpaper});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  margin-right: 10px;
`

const Wallpapers = styled.div`
  background: url(${FileUpload});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 66px;
  height: 60px;
  align-self: center;
  margin-left: 1px;
  margin-top: -8px;
  cursor: pointer;
`

const StyledImages = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 43px;
  width: 67px;
  border-radius: 12px;
  margin-bottom: 20px;
  cursor: pointer;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    position: relative;
    padding: 0;
    background: ${Colors.white605};
    margin-left: 12px;
  }

  &.ui.right.center.popup:before {
    background: ${Colors.white605};
  }
`

const StyledChromePicker = styled(ChromePicker)`
  &.chrome-picker>div:nth-child(2) {
    height: 120px;
    background: ${Colors.white605};
  }

  &.chrome-picker {
    /* width: 180px !important; */
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  left: 95px;

  &.ui.button {
    font-size: 10px;
    background: ${Colors.DarkBlue205};
    color: ${Colors.White1000};
  }
`

export {
  StyledColor,
  Customize,
  Container,
  CustomizeTitle,
  ColorImage,
  PickColorImage,
  StyledColorGrid,
  WallpaperImg,
  Wallpapers,
  StyledChromePicker,
  StyledFontAwesomeIcon,
  StyledImages,
  StyledPopup,
  StyledButton,
}
