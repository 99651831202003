import * as React from 'react'

import { connect } from 'react-redux'
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'

import Toast from 'shared/Toast/Toast'
import LeftPanel from './LeftPanel'

import ChecklistManagerDetails from './ChecklistManager/ChecklistManagerDetails'
import Taxes from './Taxes'
import TransactionFeeDetails from './TransactionFee/TransactionFeeDetails'
import WorkflowManagerDetails from './WorkflowManager/WorkflowManagerDetails'
import Notifications from './Notifications'
import PlanAndBilling from './PlanAndBilling'
import Profile from './Profile'
import SystemDefaults from './SystemDefaults'

import * as Actions from 'store/Dashboard/Actions'

import {
  Container,
  Sidebar,
  Main,
} from './Styled'

import { Routes } from 'utils'

interface DispatchProps {
  setShowPricingTabInSettings: (data: boolean) => void
}

type Props = DispatchProps & RouteComponentProps<{}>

class Settings extends React.Component<Props> {
  public state = {
    setting: ''
  }

  public componentDidMount() {
    const { setShowPricingTabInSettings } = this.props
    if (this.props.history.location.search === '?checkout=success') {
      setShowPricingTabInSettings(true)
      Toast({
        message: 'Your billing information was successfully updated.',
        type: 'success'
      })
    }

    if (this.props.history.location.search === '?checkout=error') {
      setShowPricingTabInSettings(true)
      Toast({
        message: 'An error occurred while updating your billing information.',
        type: 'error'
      })
    }
  }

  public render() {
    const { settings: { root: base }} = Routes
    return (
      <Container>
        <Sidebar>
          <LeftPanel showSetting={this.handleNavigation} />
        </Sidebar>
        <Main>
          <Switch>
            <Route
              path={base + '/checklists'}
              component={ChecklistManagerDetails}
            />
            <Route
              path={base + '/taxes'}
              component={Taxes}
            />
            <Route
              path={base + '/fees'}
              component={TransactionFeeDetails}
            />
            <Route
              path={base + '/plan'}
              component={PlanAndBilling}
            />
            <Route
              path={base + '/notifications'}
              component={Notifications}
            />
            <Route
              path={base + '/defaults'}
              component={SystemDefaults}
            />
            <Route
              path={base + '/workflows'}
              component={WorkflowManagerDetails}
            />
            <Route
              path={base + '/account'}
              component={Profile}
            />
            <Route
              path={base}
              component={Profile}
            />
          </Switch>
        </Main>
      </Container>
    )
  }

  private handleNavigation = (page: string) => {
    const { settings: { root: base }} = Routes
    let url = base

    switch (page) {
      case 'Notifications':
        url += '/notifications'
        break

      case 'System Defaults':
        url += '/defaults'
        break

      case 'Profile':
        url += '/account'
        break

      case 'Checklist Manager':
        url += '/checklists'
        break

      case 'Tax Setup':
        url += '/taxes'
        break

      case 'Fee Settings':
        url += '/fees'
        break

      case 'Workflow Manager':
        url += '/workflows'
        break

      case 'Plans & Billing':
        url += '/plan'
        break

      default:
        url += '/account'
        break
    }

    this.props.history.push({
      pathname: url
    })
  }
}

export default withRouter(connect(
  null,
  {
    setShowPricingTabInSettings: Actions.showPricingTabInSettings,
  }
)(Settings))
