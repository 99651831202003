import * as React from 'react'

import Button from 'shared/Button'

import Strings from 'utils/Strings'

import {
  Col,
  Container,
  Delete,
  Row,
  StyledInput,
  StyledLabel,
} from './Styled'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faTimes])

interface Props {
  index: number
  item: any
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRadioChange: (e: any, data: any) => void
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  deleteItem: () => void
}

class OtherFeeItem extends React.Component<Props,{}> {
  private SIZE: number = 20

  public render = () => {
    const {
      item,
      index,
      onNameChange,
      onRadioChange,
      onValueChange,
      deleteItem,
    } = this.props

    return (
      <Container>
        <Col>
          <StyledLabel>Fee Name</StyledLabel>
          <StyledInput
            value={item.name}
            onChange={onNameChange}
            placeholder='Fee Name'
            size={'small'}
          />
        </Col>
        <Col>
          <StyledLabel>Amount</StyledLabel>
          <StyledInput
            icon={item.type === 'FIXED' ? 'dollar' : 'percent'}
            max={item.type === 'FIXED' ? 1000 : 100}
            min={0}
            onChange={onValueChange}
            placeholder='Fee Amount'
            size={'small'}
            step='0.01'
            type='number'
            value={item.fee}
          />
          <Row>
            <Button.Radio
              group={`paymentType${index}`}
              value={Strings.transactionFeeSettings.fixed.key}
              checked={item.type === Strings.transactionFeeSettings.fixed.key}
              onClick={onRadioChange}
              label={Strings.transactionFeeSettings.fixed.label}
            />
            <Button.Radio
              group={`paymentType${index}`}
              value={Strings.transactionFeeSettings.percentage.key}
              checked={item.type === Strings.transactionFeeSettings.percentage.key}
              onClick={onRadioChange}
              label={Strings.transactionFeeSettings.percentage.label}
            />
          </Row>
        </Col>
        <Delete size={this.SIZE}>
          <Button.Bubble
            icon={faTimes}
            onClick={deleteItem}
            size={this.SIZE}
            type='danger'
          />
        </Delete>
      </Container>
    )
  }
}

export default OtherFeeItem
