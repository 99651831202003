export interface ImageType {
  _id: string
  name: string
  order: number
  url: string
}

export enum PermissionEnum {
  Owner,
  Viewer,
  None
}

export enum ModeEnum {
  Default,
  Select
}
