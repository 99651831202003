// Import Utils
import { generateID } from 'utils'

export const leftPanelMockData = [
  {
    _id: '5d37e7593b88441ab22413a44',
    content: [
      {
        _id: generateID(),
        name: 'Localization'
      },
      {
        _id: generateID(),
        name: 'System Defaults'
      },
      {
        _id: '5d37e7593b88441ab22413a55',
        name: 'Workflow Manager'
      },
      {
        _id: '5d37e7593b88441ab22413415',
        content: [
          {
            _id: '5d37e7593b88441ab22413579',
            name: 'Checklist Manager'
          },
          {
            _id: '5d37e7593b88441ab22413033',
            name: 'Tax Setup'
          },
          {
            _id: '5d37e7593b88441ab22413963',
            name: 'Fee Settings'
          }
        ],
        name: 'On Boarding Setup',
      }
    ],
    icon: 'user',
    menuName: 'Account',
  },
  {
    _id: '5d37e7593b88441ab22413d3',
    content: [
    ],
    icon: 'file-chart-line',
    menuName: 'Plans & Billing',
  },
  {
    _id: generateID(),
    content: [
      {
        _id: generateID(),
        name: 'Notifications'
      }
    ],
    icon: 'rocketchat',
    menuName: 'Communication',
  },
  // {
  //   _id: generateID(),
  //   content: [
  //   ],
  //   icon: 'cog',
  //   menuName: 'Service',
  // }
]


export const otherLeftPanelMockData = [
  {
    _id: '5d37e7593b88441ab22413a44',
    content: [
      {
        _id: '5d37e7593b88441ab22413a55',
        name: 'Workflow Manager'
      }
    ],
    icon: 'user',
    menuName: 'Account',
  },
  {
    _id: '5d37e7593b88441ab22413a66',
    content: [
      {
        _id: '5d37e7593b88441ab22413a77',
        name: 'Notifications'
      }
    ],
    icon: 'rocketchat',
    menuName: 'Communication',
  },
]
