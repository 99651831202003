// Import Packages
import { cloneDeep, sortBy } from 'lodash'

// Import Colors
import Colors from 'design/Colors'

const boardBorderColors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Grey800}`,
  `${Colors.Green560}`
]

const defaultTaskBoard = ['New Task', 'In Progress', 'Completed']

export const formatKanbanData = async (
  taskBoardData: any,
  toggleStatus: boolean
) => {
  let user: any = localStorage.getItem('user')
    ? localStorage.getItem('user')
    : '{}'
  user = JSON.parse(user)
  const loggedInUser = user

  const taskBoard = cloneDeep(taskBoardData)
  for (const task of taskBoard) {
    task.color = getRandomColors()
    if (!task.tasks) {
      task.items = Object.assign([])
    } else {
      task.items = Object.assign(task.tasks)
    }
    // task.showAddButton = true
    if (toggleStatus) {
      if (
        (loggedInUser.role === 'ADMIN' || loggedInUser.role === 'MANAGER') &&
        task.name === 'New Task'
      ) {
        task.showAddButton = true
      } else {
        task.showAddButton = false
      }
    } else if (
      !toggleStatus &&
      task.name === 'New Task' &&
      loggedInUser.role !== 'GUEST'
    ) {
      task.showAddButton = true
    }
    if (defaultTaskBoard.includes(task.name)) {
      task.showAppearance = false
    } else {
      task.showAppearance = true
    }
  }
  return sortBy(taskBoard, ['boardOrder'])
}

export const formatListData = async (tasksData: any, toggleStatus: boolean) => {
  let user: any = localStorage.getItem('user')
    ? localStorage.getItem('user')
    : '{}'
  user = JSON.parse(user)
  const loggedInUser = user

  const taskData: any = []
  if (tasksData && tasksData !== undefined) {
    const taskListData = Object.assign(tasksData)
    // tslint:disable-next-line:forin
    for (const index in taskListData) {
      if (toggleStatus) {
        if (loggedInUser.role === 'MANAGER' || loggedInUser.role === 'ADMIN') {
          taskListData[index].showAddButton = true
        } else {
          taskListData[index].showAddButton = false
        }
      } else {
        taskListData[index].showAddButton = true
      }
      if (taskListData[index].createdBy._id === loggedInUser._id) {
        taskListData[index].showEditButton = true
      } else {
        taskListData[index].showEditButton = false
      }
      taskListData[index].checked = false
      taskListData[index].color = getRandomColors()
      taskData.push(taskListData[index])
    }
  }
  return taskData
}

const getRandomColors = () => {
  return boardBorderColors[Math.floor(Math.random() * boardBorderColors.length)]
}
