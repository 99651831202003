// Import Packages
import { TimelineEvent } from 'react-event-timeline'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background: string
}

const TimeLineContainer = styled.div`
  height: 550px;
  overflow: auto;
  color: ${Colors.Black550};
  width: 230px;
  padding: 8px;
  padding-top: 6px;

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
      background: ${Colors.transparent};
  }

  .fa-phone {
    transform: rotate(100deg);
  }

  &.notes-styling section > div:nth-child(1) {
    background: ${Colors.Grey100} !important;
    left: 18px !important;
    width: 1px !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(2) {
    margin-left: 5px !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) {
    width: 35px !important;
    height: 35px !important;
    background: ${Colors.White1000} !important;
    border: 1px solid ${Colors.Grey100} !important;
    justify-content: center !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) > span > i{
    font-size: 14px;
    margin-left: 4px;
    color: ${Colors.Black750};
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) > span{
    width: 35px !important;
    height: 35px !important;
  }
`

const Container = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 14px;

  div {
    display: flex;
  }

  b {
    font-weight: bold;
    margin-right: 3px;
    font-style: italic;
    color: ${Colors.DarkBlue200}
  }
`

const StyledTimelineEvent = styled(TimelineEvent)`
  background: white;
`

const People = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  cursor: pointer;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

export {
  Container,
  People,
  StyledPopup,
  StyledTimelineEvent,
  TimeLineContainer
}
