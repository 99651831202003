import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import {
  children,
  dangerStyledButton,
  headerMargin,
  link,
  primaryStyledButton,
  x,
  y,
} from 'design/Styled'

export const Container = styled.section`
  ${y};
  height: 100%;
  background: ${Colors.Container};
  font-size: 14px;
`

export const Header = styled.header`
  ${x};
  ${children};
  padding: 1em;
  justify-content: space-between;
  background: ${Colors.ContainerHighlight};
`

export const Body = styled.section`
  ${y};
  flex: 1;
  height: 100%;
  overflow: hidden;

  .rp-scrollable-child {
    padding: 0.6em 0.8em;
    font: ${fonts.small.regular};
  }
`

export const Title = styled.div`
  flex-grow: 1;

  h1 {
    ${headerMargin};
    font: ${fonts.medium.bold};
    color: ${Colors.Text};
  }

  h2,
  h3 {
    ${headerMargin};
    font: ${fonts.small.regular};
    color: ${Colors.Text};
  }
`

export const History = styled.a`
  ${link};
  font: ${fonts.small.bold};
`

export const Invoice = styled.ul`
  ${y};
  margin: 0;
  padding: 0;
  list-style: none;
`

export const Line = styled.li`
  ${x};
  ${children};
  margin: 0;
  padding: 1.2em 1em;
  justify-content: space-between;

  strong {
    font: ${fonts.small.bold};
  }

  span {
    font: ${fonts.small.regular};
  }
`

export const Total = styled(Line)`
  margin-top: 0.6em;
  border-top: 1px solid ${Colors.EmphasisLine};
  border-bottom: 1px solid ${Colors.EmphasisLine};
`

export const Footer = styled.footer`
  ${x};
  ${children};
  padding: 1em 0.8em;
`

export const Col = styled.div`
  ${y};
`

export const ManageButton = styled(Button)`
  ${primaryStyledButton};
  width: 100%;
`

export const CancelButton = styled(Button)`
  ${dangerStyledButton};
  width: 100%;
`
