const Routes = {
  contacts: {
    details: {
      key: 'details',
      path: '/details'
    },
    root: '/contacts'
  },
  leads: {
    details: {
      key: 'details',
      path: '/details'
    },
    root: '/leads'
  },
  logout: {
    key: 'logout',
    path: '/logout'
  },
  onboarding: {
    confirm: {
      key: 'confirm',
      path: '/confirm'
    },
    confirmToken: {
      key: 'confirm',
      path: '/confirm/:subject/:token'
    },
    events: {
      key: 'events',
      path: '/events'
    },
    login: {
      key: 'login',
      path: '/login'
    },
    passSelection: {
      key: 'passSelection',
      path: '/select-a-pass'
    },
    register: {
      key: 'register',
      path: '/signup'
    },
    registerToken: {
      key: 'register',
      path: '/signup/:socialToken'
    },
    resetPassword: {
      key: 'resetPassword',
      path: '/reset-password'
    },
    root: '/login',
    teamSize: {
      key: 'teamSize',
      path: '/select-team-size'
    },
    welcome: {
      key: 'welcome',
      path: '/welcome'
    }
  },
  primary: {
    contacts: {
      key: 'contacts',
      path: '/contacts'
    },
    dashboard: {
      key: 'dashboard',
      path: '/dashboard'
    },
    events: {
      key: 'events',
      path: '/events'
    },
    leads: {
      key: 'leads',
      path: '/leads'
    },
    mailbox: {
      key: 'mailbox',
      path: '/mailbox'
    },
    offices: {
      key: 'offices',
      path: '/offices'
    },
    profile: {
      key: 'profile',
      path: '/profile'
    },
    reports: {
      key: 'reports',
      path: '/reports'
    },
    settings: {
      key: 'settings',
      path: '/settings'
    },
    tasks: {
      key: 'tasks',
      path: '/tasks'
    },
    teams: {
      key: 'teams',
      path: '/teams'
    },
    transactions: {
      key: 'transactions',
      path: '/transactions'
    },
    users: {
      key: 'users',
      path: '/users'
    }
  },
  settings: {
    checklistManager: {
      key: 'checklistManager',
      path: '/checklistManager'
    },
    root: '/settings',
    settings: {
      key: 'settings',
      path: '/setting'
    },
    taxSetup: {
      key: 'taxSetup',
      path: '/taxSetup'
    },
    transactionFee: {
      key: 'transactionFee',
      path: '/transactionFee'
    },
    workflowManager: {
      key: 'workflowManager',
      path: '/workflowManager'
    }
  },
  transactions: {
    activity: {
      activeIcon: `${require('design/icons/transactions/header/timeline-clicked.png')}`,
      icon: `${require('design/icons/transactions/header/timeline.png')}`,
      key: 'activity',
      path: '/activity'
    },
    commission: {
      activeIcon: `${require('design/icons/dashboard/transactions/commissionClicked.png')}`,
      icon: `${require('design/icons/dashboard/transactions/commission.png')}`,
      key: 'commission',
      path: '/commission'
    },
    documents: {
      activeIcon: `${require('design/icons/transactions/header/Documents-clicked.png')}`,
      icon: `${require('design/icons/transactions/header/Documents.png')}`,
      key: 'documents',
      path: '/documents'
    },
    marketing: {
      activeIcon: `${require('design/icons/transactions/header/megaphone-clicked.png')}`,
      icon: `${require('design/icons/transactions/header/megaphone.png')}`,
      key: 'marketing',
      path: '/marketing'
    },
    messages: {
      activeIcon: `${require('design/icons/transactions/header/chat-clicked.png')}`,
      icon: `${require('design/icons/transactions/header/chat.png')}`,
      key: 'notes',
      path: '/notes'
    },
    root: '/transactions/details',
    workflow: {
      activeIcon: `${require('design/icons/dashboard/transactions/workflowClicked.png')}`,
      icon: `${require('design/icons/dashboard/transactions/workflow.png')}`,
      key: 'workflow',
      path: '/workflow'
    }
  },
  listing: {
    path: '/listing',
    params: '/:templateId/:transactionId'
  }
}

export default Routes
