// Import Packages
import * as React from 'react'

// Import Components
import {
  ChangeOwner,
  loggedUser,
} from './Dashboard'

// Import Styled Components
import {
  Labels,
  Li,
  MemberProfile,
  Profile,
  StyledPopup,
} from './Styled'

export const CustomOwnerName = ({ rowData }: { rowData: any }) => {
  const changeOwnership = () => {
    if (loggedUser.role === 'ADMIN' || loggedUser.role === 'MANAGER' || loggedUser._id === rowData.owner._id) {
      ChangeOwner(rowData._id, rowData.owner._id)
    }
  }
  return (
    <div style={{ cursor: 'pointer' }} onClick={changeOwnership}>
      {rowData.owner.firstName} {rowData.owner.lastName}
    </div>
  )
}

export const CustomSharedWith = ({ rowData }: { rowData: any }) => {
  return (
    <div>
      {rowData.shareWith && (
        <Profile>
          {rowData.shareWith.map((items: any, index: number) => {
            while (index < 3) {
              return (
                <Li key={index}>
                  <StyledPopup
                    trigger={<MemberProfile key={index} background={items.profileImage} />}
                    content={`${items.firstName} ${items.lastName}`}
                  />
                </Li>
              )
            }
            return null
          })}
          {rowData.shareWith.length > 3 ? (
            <Labels>
              <StyledPopup trigger={<div>...</div>} content={`+${rowData.shareWith.length - 3}`} />
            </Labels>
          ) : null}
        </Profile>
      )}
    </div>
  )
}