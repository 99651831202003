const Actions = {
  addNewBoard: 'ADD_NEW_USERS_BOARD',
  addNewCard: 'ADD_NEW_USERS_CARD',
  deleteCard: 'DELETE_USERS_CARD',
  getBoardData: 'GET_USERS_BOARD_DATA',
  hideAddUserPopup: 'HIDE_ADD_USER_POPUP',
  hideImportUsersPopup: 'HIDE_IMPORT_USERS_POPUP',
  resetCardData: 'RESET_USERS_CARD_DATA',
  restoreCard: 'RESTORE_USERS_CARD',
  setNewBoardName: 'SET_NEW_USERS_BOARD_NAME',
  setNewDescription: 'SET_NEW_USERS_DESCRIPTION',
  setNewIcon: 'SET_NEW_USERS_ICON',
  showAddUserPopup: 'SHOW_ADD_USER_POPUP',
  showImportUsersPopup: 'SHOW_IMPORT_USERS_POPUP',
  userFilter: 'USER_FILTER',
  userSearchData: 'USER_SEARCH_DATA',
  userSearchLoader: 'USER_SEARCH_LOADER'
}

export default Actions
