// Import Packages
import { Button, Icon } from 'semantic-ui-react'

import Colors from 'design/Colors'
import { css } from 'styled-components'
import styled from 'styled-components'

const row = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`

export const Toolbar = styled.div`
  &.ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: none;
    border-right: none;
    &:after {
      display: none;
    }

    button.custom-undo:before {
      content: "\f3e5";
    }

    button.custom-redo:before {
      content: "\f064";
    }

    button.ql-bold:before {
      content: "\f032";
    }

    button.ql-italic:before {
      content: "\f033";
    }

    button.ql-list[value=ordered]:before {
      content: "\f0cb";
    }

    button.ql-list[value=bullet]:before {
      content: "\f0ca";
    }

    button.custom {
      &:before {
        color: ${Colors.Black500}
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 600;
      }
      &.disabled:before {
        opacity: 0.45
      }
    }

    button.ql-active:before {
      color: ${Colors.ButtonPrimary}
    }

    button.custom-delete {
      color: ${Colors.ButtonDanger}
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      &::before {
        content: "\f2ed";
        color: ${Colors.ButtonDanger}
        margin-right: 0.25em;
      }
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .quill {
    display: flex;
    flex: 1;
    position: relative;
    margin-bottom: 10px;
  }

  .ql-editor {
    width: 100%;
    padding: 0;
  }

  .ql-container.ql-snow {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border: none;
  }
`

export const Editor = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  width: 100%;
`

export const AttachRow = styled.div`
  ${row};

  svg {
    width: 16px;
    height: 16px;
  }

  label {
    padding-right: 1em;
  }
`

export const AttachIcon = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
`

export const AttachmentRow = styled.section`
  ${row};
  margin: 0;
  padding: 0;
  padding-top: 1em;
`

export const AttachmentButton = styled(Button)``

export const Clear = styled(Icon)``

export const DragImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8);
`

export const DragImage = styled.img`
  width: 30%;
  max-width: 100px;
`
