// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

// Import Components
import AddReportModal from '../AddReportModal'
import { modulesMockData, modulesMockDataAgent } from './ModulesMockData'
import Segment from './Segment'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import { ApplyFilter, MyReportModule, Options, PickData } from 'store/Reports/Types'

// Import Utils
import { getLoggedInUser } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Container,
  StyledGrid,
} from './Styled'

interface Props {
  showReports: (title: string) => void
  setFilter: (data: any) => void
  setApplyFilter: (data: any) => void
  setPickData: (data: PickData[]) => void
  setPerviewModule: (data: string) => void
  setColumnData: (date: ApplyFilter[]) => void
  setDatesFilters: (data: object) => void
  setResetFilterData: (reset: boolean) => void
  setShowState: (data: Options) => void
  setShowCity: (data: Options) => void
  setShowSystemReport: (module: string) => void
  setShowGeneratedReportId: (id: string) => void
  activeIndex: string
  reportData: MyReportModule[]
  report: string
  module: string
}

interface State {
  activeItem: number
  showAddReportModal: boolean,
  showCustomizeReportModal: boolean
  modulesData: any
}

class Modules extends React.Component<Props, State> {
  public state = {
    activeItem: 0,
    modulesData: [],
    showAddReportModal: false,
    showCustomizeReportModal: false,
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({ activeItem: nextProps.activeIndex })
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    const { report, showReports, setShowSystemReport } = this.props
    showReports(report.toUpperCase())
    setShowSystemReport(report.toUpperCase())
    if (user.role !== 'AGENT') {
      this.setState({ modulesData: modulesMockData })
    } else {
      this.setState({ modulesData: modulesMockDataAgent })
    }
  }

  public render() {
    const { module } = this.props
    const { showAddReportModal, modulesData } = this.state
    const modules = modulesData.map((items: any, index: any) => (
      <Grid.Column key={index}>
        <Segment
          index={index}
          color={items.title === module ? Colors.White1000 : Colors.Black500}
          className={items.title === module ? 'active-segment' : ''}
          icon={items.icon}
          title={items.title}
          activeItem={this.activeItem}
        />
      </Grid.Column>
    ))
    return (
      <React.Fragment>
        {showAddReportModal && (
          <AddReportModal closeModal={this.closeModal} />
        )}
        <Container>
          <StyledGrid>
            <Grid.Row columns={10}>
              <Grid.Column>
                <Segment
                  index={0.5}
                  icon={'plus'}
                  title={'CREATE REPORT'}
                  activeItem={this.showAddReportModal}
                />
              </Grid.Column>
              {modules}
            </Grid.Row>
          </StyledGrid>
        </Container>
      </React.Fragment>
    )
  }

  private showAddReportModal = () => {
    this.setState({ showAddReportModal: true })
  }

  private activeItem = (index: number, title: string) => {
    const { showReports, setFilter, setApplyFilter, setPickData, setPerviewModule, setShowGeneratedReportId, setColumnData, setDatesFilters, setResetFilterData, setShowCity, setShowState, setShowSystemReport } = this.props
    this.setState({ activeItem: index })
    if (title === 'MY REPORTS') {
      setShowSystemReport('TRANSACTIONS')
    } else {
      setShowSystemReport('')
      setShowGeneratedReportId('')
    }
    setPerviewModule(title)
    showReports(title)
    setPickData([])
    setApplyFilter([])
    setFilter({})
    setColumnData([])
    setDatesFilters({})
    setResetFilterData(false)
    setShowCity({} as Options)
    setShowState({} as Options)
  }

  private closeModal = () => {
    this.setState({ showAddReportModal: false })
  }
}

const mapStateToProps = (state: AppState) => ({
  module: state.reports.module,
  report: state.reports.report,
  reportData: state.reports.reportData,
})

export default connect(
  mapStateToProps,
  {
    setApplyFilter: Actions.getApplyFilter,
    setColumnData: Actions.getColumnData,
    setDatesFilters: Actions.datesFilters,
    setFilter: Actions.getFilter,
    setPerviewModule: Actions.perviewModule,
    setPickData: Actions.getPickData,
    setResetFilterData: Actions.resetFilterData,
    setShowCity: Actions.showCity,
    setShowGeneratedReportId: Actions.showGeneratedReportId,
    setShowState: Actions.showState,
    setShowSystemReport: Actions.showSystemReport,
  }
)(Modules)
