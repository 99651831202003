// Import Packages
import moment from 'moment'
import 'moment/min/locales'
import * as React from 'react'
import InputMask from 'react-input-mask'
import { Form, Input, Select } from 'semantic-ui-react'
import { ProfileType } from '../../Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { updateProfile } from 'app/Settings/Mutations'
import { Container, DateFormat, StyledForm, Title } from './Styled'

const localeOptions = [
  { key: 1, text: 'United States', value: 'en' },
  { key: 2, text: 'Australia', value: 'en-au' },
  { key: 3, text: 'Canada', value: 'en-ca' },
  { key: 4, text: 'Singapore', value: 'en-SG' },
  { key: 5, text: 'United Kingdom', value: 'en-gb' }
]

const calendarOptions = [
  { key: 1, text: 'Monday (Default)', value: 'Monday (Default)' },
  { key: 2, text: 'Sunday', value: 'Sunday' }
]

interface Options {
  key: string
  text: string
  value: string
}
interface State {
  localization: any
  selectedLocale: Options
  selectedDefaultDay: Options
}
interface Props {
  profile: ProfileType
}

class Localization extends React.Component<Props, State> {
  public state = {
    localization: {},
    selectedDefaultDay: {
      key: '',
      text: '',
      value: ''
    },
    selectedLocale: {
      key: '',
      text: '',
      value: ''
    }
  }

  public componentDidMount() {
    const { profile } = this.props
    const localeSelectedOption: any = localeOptions.find((data: any) => data.value === profile.dateFormat)
    const defaultDaySelectedOption: any = calendarOptions.find((data: any) => data.value === profile.calenderWeek)
    this.setState({
      localization: profile,
      selectedLocale: localeSelectedOption,
      selectedDefaultDay: defaultDaySelectedOption
    })
  }

  public render() {
    const { localization, selectedLocale, selectedDefaultDay } = this.state
    return (
      <Container>
        <Title>{Strings.settings.profile.localization}</Title>
        <StyledForm size={'tiny'}>
          {/* <Form.Field control={Select} label='Time Zone' options={timeZoneOptions} placeholder='UTC 5:00 Eastern Time' /> */}
          <Form.Field
            control={Select}
            label="Date And Number Format"
            options={localeOptions}
            placeholder="United States"
            onChange={this.handleChange}
            value={selectedLocale && selectedLocale.value}
            name="dateFormat"
          />
          <DateFormat>{Strings.settings.profile.dateFormat}</DateFormat>
          <Form.Field
            control={Select}
            label="Calendar (First Day Of Week)"
            options={calendarOptions}
            placeholder="Monday (Default)"
            onChange={this.handleChange}
            value={selectedDefaultDay && selectedDefaultDay.value}
            name="calenderWeek"
          />
          <Form.Field
            control={Input}
            label="Email"
            placeholder="Email-Address"
            onChange={this.handleChange}
            name="email"
            value={localization[`email`]}
            onBlur={this.onBlur}
          />
          <Form.Field control={Input} label="Contact Number">
            <InputMask
              mask="(999) 999-9999"
              name="phone"
              placeholder="(xxx) xxx-xxxx"
              onChange={this.onChange}
              value={localization[`phone`]}
              onBlur={this.onBlur}
            />
          </Form.Field>
        </StyledForm>
      </Container>
    )
  }

  private onChange = (event: any) => {
    const { localization } = this.state
    localization[`phone`] = event.target.value
    this.setState({ localization })
  }

  private handleChange = async (event: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { localization } = this.state
    let option: any = {}
    if (name === 'dateFormat') {
      moment.locale(value)
      option = localeOptions.find((data: any) => data.value === value)
      localization[name] = value
      this.setState({ selectedLocale: option })
    } else if (name === 'calenderWeek') {
      option = calendarOptions.find((data: any) => data.value === value)
      localization[name] = value
      this.setState({ selectedDefaultDay: option })
    } else {
      localization[name] = value
    }
    this.setState({ localization })
    await updateProfile(localization)
  }

  private onBlur = async () => {
    const { localization } = this.state
    await updateProfile(localization)
  }
}

export default Localization
