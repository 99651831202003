import * as React from 'react'

import Button from 'shared/Button'
import { handleValidation } from './Validation'

import {
  StyledInput,
  StyledDropdown,
} from './Styled'
import { Col } from '../Styled'

import { CommissionItemType } from 'app/Transactions/Details/Types'
import {
  PaymentKeyEnum,
  PaymentNumericEnum,
} from 'store/Transactions/Types'
import {
  ActionEnum,
  FormType,
  OptionType,
} from './Types'

import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([ faCheck, faTimes ])

interface Props {
  form: FormType
  item: CommissionItemType
  onAction: (action:ActionEnum, data:any) => void
  isEditing?: boolean
  loading?:boolean
}

class ItemForm extends React.Component<Props,{}> {
  private OPTIONS:OptionType[] = [
    { key: '$', text: '$', value: PaymentNumericEnum.Fixed },
    { key: '%', text: '%', value: PaymentNumericEnum.Percent }
  ]

  public render = () => {
    const {
      item,
      form,
      form: { errors },
      isEditing,
      loading
    } = this.props
    const isEditableName = item.key === PaymentKeyEnum.OtherFee || item.key === PaymentKeyEnum.ReferralFee || !item.key
    const isTransactionFee = item.key === PaymentKeyEnum.TransactionFee
    const isCommissionFee = item.key === PaymentKeyEnum.TransactionFee
    const isTaxFee = item.key === PaymentKeyEnum.LocalTax || item.key === PaymentKeyEnum.StateTax

    return (
      <React.Fragment>
        {!isEditableName ? (
          <Col>{form.name}</Col>
        ) : (
          <StyledInput
            error={!!errors.name}
            name='name'
            onChange={this.handleChange}
            placeholder='Name'
            value={isEditing && form.name}
          />
        )}
        <StyledInput
          error={!!errors.fee}
          name='fee'
          onChange={this.handleChange}
          placeholder='Value'
          type='number'
          value={isEditing && form.fee}
        />
        <StyledDropdown
          disabled={isTransactionFee || isCommissionFee || isTaxFee}
          error={!!errors.type}
          fluid={true}
          name='type'
          onChange={this.handleChange}
          options={this.OPTIONS}
          placeholder='Type'
          selection={true}
          value={isEditing && form.type}
        />
        <Col justify='flex-end'>
          <Button.Bubble
            icon={faCheck}
            label='Submit'
            onClick={this.handleSubmit}
            isDisabled={loading}
          />
          <Button.Icon
            icon={faTimes}
            label='Cancel'
            onClick={this.handleCancel}
            disabled={loading}
          />
        </Col>
      </React.Fragment>
    )
  }

  private validateAmount = (value: string): boolean => {
    if(value === '') return true
    const regex = /^\d+(\.\d{0,2})?$/
    return regex.test(value)
  }

  private handleChange = (e: any, data: any) => {
    const { onAction, form } = this.props
    if (data.name === 'fee' && form.type === PaymentNumericEnum.Fixed && !this.validateAmount(data.value)) {
      return
    }
    if (data.name === 'type' && data.value === PaymentNumericEnum.Fixed) {
      const fee = parseFloat(form.fee)
      const roundedFee = isNaN(fee) ? 0 : fee.toFixed(2)
      onAction(ActionEnum.Change, { name: 'fee', value: roundedFee })
    }
    onAction(ActionEnum.Change, data)
  }

  private handleSubmit = () => {
    const { form, item, onAction } = this.props

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      onAction(ActionEnum.Change, { name: 'errors', value: validation.errors })
      // formToast(validation.errors)
      return
    }

    onAction(ActionEnum.Submit, !!item._id)
  }

  private handleCancel = () => {
    const { onAction } = this.props
    onAction(ActionEnum.Cancel, null)
  }
}
export default ItemForm
