import { omit, pullAt } from 'lodash'
import { Component } from 'react'
import * as React from 'react'
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input'
import client from 'queries/apollo'
import { UPDATE_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Mutation'
import { GET_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Queries'
import { UPDATE_TRANSACTION_SETTING_LOCAL } from 'queries/graphql/Settings/TransactionFee/Local/FeeSettingLocalMutation'
import Strings from 'utils/Strings'
import { activeTransactionSettingIndex } from '../FeeSettings'
import { BorderTree, SettingRowSection, VerticalText } from '../Styled'
import TransactionSettingQuery from '../TransactionSettingQuery'
import OtherFeeItem from './OtherFeeItem'
import { AddMoreButton, AddMoreContainer, FeeGroup } from './Styled'

class OtherFee extends Component<{}, {}> {
  private transactionFeeId = ''
  public onChangeRadio = (otherFeesData: any, id: number, type: string) => {
    const newObj = {
      otherFees: [
        ...otherFeesData.slice(0, id),
        omit(Object.assign({}, otherFeesData[id], { type }), '__typename'),
        ...otherFeesData.slice(id + 1)
      ]
    }
    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public onNameChange = (otherFeesData: any, id: number, name: string) => {
    const newObj = {
      otherFees: [
        ...otherFeesData.slice(0, id),
        omit(Object.assign({}, otherFeesData[id], { name }), '__typename'),
        ...otherFeesData.slice(id + 1)
      ]
    }
    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public deleteItem = (otherFeesData: any, id: number) => {
    pullAt(otherFeesData, [id])
    const newObj = {
      otherFees: otherFeesData
    }
    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      refetchQueries: GET_TRANSACTION_SETTING,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public onValueChange = (otherFeesData: any, id: number, value: number) => {
    const newObj = {
      otherFees: [
        ...otherFeesData.slice(0, id),
        omit(Object.assign({}, otherFeesData[id], { fee: value }), '__typename'),
        ...otherFeesData.slice(id + 1)
      ]
    }
    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public addItem = (otherFeesData: any) => {
    // const { addOtherFeeItem } = this.props
    // addOtherFeeItem()
    let newObj = {}
    if (otherFeesData === null) {
      newObj = {
        otherFees: [
          {
            __typename: 'OtherFee',
            fee: 0,
            name: '',
            type: 'FIXED'
          }
        ]
      }
    } else {
      otherFeesData.push({
        __typename: 'OtherFee',
        fee: 0,
        name: '',
        type: 'FIXED'
      })
      newObj = {
        otherFees: otherFeesData
      }
    }

    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING,
      variables: {
        _id: this.transactionFeeId,
        ...newObj
      }
    })
  }

  public render() {
    return (
      <SettingRowSection>
        <VerticalText as="h4" textAlign="center">
          {Strings.transactionFeeSettings.types.other.name}
        </VerticalText>
        <BorderTree />
        <TransactionSettingQuery query={GET_TRANSACTION_SETTING}>
          {({ loading, error, data }) => {
            if (loading) {
              return 'Loading...'
            }
            if (error) {
              return `Error! ${error.message}`
            }
            if (data && data.getTransactionSettings && data.getTransactionSettings[activeTransactionSettingIndex]) {
              const { getTransactionSettings } = data
              this.transactionFeeId = getTransactionSettings[activeTransactionSettingIndex]._id
              return (
                <FeeGroup>
                  {data &&
                    getTransactionSettings[activeTransactionSettingIndex] &&
                    getTransactionSettings[activeTransactionSettingIndex].otherFees &&
                    getTransactionSettings[activeTransactionSettingIndex].otherFees!!.map(
                      (item: any, index: number) => {
                        const onRadioChange = (
                          e: React.ChangeEvent<HTMLInputElement>,
                          valueData: InputOnChangeData
                        ) => {
                          this.onChangeRadio(
                            getTransactionSettings[activeTransactionSettingIndex].otherFees,
                            index,
                            valueData.value.toString()
                          )
                        }
                        const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                          this.onNameChange(
                            getTransactionSettings[activeTransactionSettingIndex].otherFees,
                            index,
                            e.target.value
                          )
                        }
                        const deleteItem = () => {
                          this.deleteItem(getTransactionSettings[activeTransactionSettingIndex].otherFees, index)
                        }
                        const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                          this.onValueChange(
                            getTransactionSettings[activeTransactionSettingIndex].otherFees,
                            index,
                            parseFloat(e.target.value)
                          )
                        }
                        return (
                          <OtherFeeItem
                            item={item}
                            index={index}
                            onNameChange={onNameChange}
                            deleteItem={deleteItem}
                            onRadioChange={onRadioChange}
                            key={index}
                            onValueChange={onValueChange}
                          />
                        )
                      }
                    )}
                  <AddMoreContainer>
                    <AddMoreButton
                      onClick={() => this.addItem(getTransactionSettings[activeTransactionSettingIndex].otherFees)}
                    >
                      {Strings.transactionFeeSettings.addMore}
                    </AddMoreButton>
                  </AddMoreContainer>
                </FeeGroup>
              )
            }
            return null
          }}
        </TransactionSettingQuery>
      </SettingRowSection>
    )
  }
}

export default OtherFee
