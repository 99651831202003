import Colors from 'design/Colors'
import RPFlag from 'design/images/RPFlag.png'
import RPLine from 'design/images/RPLine.png'
import { x, y } from 'design/Styled'
import styled from 'styled-components'

export const Container = styled.div`
  ${y};
  position: relative;
  background: ${Colors.Container};
  width: 8.5in;
  height: 11in;
  margin: 0 auto;
  padding: 0;
`

export const Hero = styled.div`
  display: block;
  width: 100%;
  height: 3in;
  background: #444;
`

export const CallOut = styled.div`
  ${x} display: flex;
  position: absolute;
  top: 3in;
  left: 0.25in;
  padding: 0.1in;
  padding-left: 0.25in;
  width: 4.5in;
  height: 2in;
  background: #fff;
  z-index: 999;
`

export const Details = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 6in;
  padding-top: 1.25in;
  background: #eee;
`

export const Description = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 50%;
  padding: 0.25in;
`

export const Row = styled.div`
  ${x} justify-content: space-between;
  position: relative;
  width: 100%;
  height: 50%;
  padding: 0.25in;
`

export const Image = styled.div`
  ${x} justify-content: center;
  width: 30%;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 0.4in -0.4in #333;
`

export const Text = styled.div``

export const CompanyBranding = styled.div``

export const CompanyLogo = styled.div`
  ${y} justify-content: center;
  align-items: center;
  height: 1in;
  background-color: #444;
`

export const Logo = styled.div`
  width: 10%;
  height: 55%;
  background: #444;
`

export const MLSId = styled.div`
  font-size: 13pt;
  color: white;
  font-weight: bold;
  padding-bottom: 0.2em;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1in;
`

export const Category = styled.div`
  font-size: 20pt;
  font-family: 'Fredericka the Great';
  text-transform: uppercase;
  color: ${Colors.Text};
  text-align: center;
`

export const LeftRP = styled.div`
  background: url(${RPFlag});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  width: 38px;
`

export const RightRP = styled.div`
  background: url(${RPFlag});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  width: 38px;
  transform: rotate(180deg);
`

export const Line = styled.div`
  background: url(${RPLine});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 6px;
  width: 38%;
`
