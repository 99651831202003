import { ActionCreator } from 'redux'
import Actions from 'store/Actions'
import {
  AddNewWidget,
  CustomeColor,
  DashboardAddCustomeColor,
  DashboardAddNewNotes,
  DashboardAddPass,
  DashboardAddWallpaperImage,
  DashboardAddWidgetSection,
  DashboardCategoriesFilter,
  DashboardDeletePass,
  DashboardDeleteWidgetSection,
  DashboardGetCustomeColor,
  DashboardGetNotesData,
  DashboardGetWallpaperImage,
  DashboardGetWidgetSection,
  DashboardOfficeID,
  DashboardTaskID,
  DashboardRenamePass,
  DashboardRenameWidgetSection,
  DashboardSetCustomeColor,
  DashboardSetThemeImage,
  DashboardState,
  DashboardTeamID,
  DashboardUserProfile,
  Passes,
  PaymentNotes,
  RenderAllWidgets,
  ShowChecklistTab,
  ShowFeeTab,
  ShowNotificationTab,
  ShowPricingTab,
  ShowTaxTab,
  ShowWorkTab,
  SignatureModal,
  ToggleComposeMail,
  ToggleComposeMailForDocument,
  ToggleDetailsPopup,
  ToggleDetailsPopupShardWith,
  ToggleEventsPopUp,
  ToggleWidgetsPopup,
  UserSectionChanged,
  WallpaperImage,
  WidgetSection
} from './Types'

// Section
export const getUserProfile: ActionCreator<DashboardUserProfile> = (
  profile: string
) => ({
  payload: {
    profile
  },
  type: Actions.dashboardGetUserProfile
})

export const addWidgetSection: ActionCreator<DashboardAddWidgetSection> = (
  newData: WidgetSection
) => ({
  payload: {
    newData
  },
  type: Actions.dashboardAddWidgetSection
})

export const getWidgetSection: ActionCreator<DashboardGetWidgetSection> = (
  data: WidgetSection[]
) => ({
  payload: {
    data
  },
  type: Actions.dashboardGetWidgetSection
})

export const renameWidgetSection: ActionCreator<
  DashboardRenameWidgetSection
> = (id: string, newName: string) => ({
  payload: {
    id,
    newName
  },
  type: Actions.dashboardRenameWidgetSection
})

export const deleteWidgetSection: ActionCreator<
  DashboardDeleteWidgetSection
> = (id: string) => ({
  payload: {
    id
  },
  type: Actions.dashboardDeleteWidgetSection
})

export const setThemeImage: ActionCreator<DashboardSetThemeImage> = (
  newImage: string
) => ({
  payload: {
    newImage
  },
  type: Actions.dashboardSetThemeImage
})

export const setCustomeColor: ActionCreator<DashboardSetCustomeColor> = (
  newColor: string
) => ({
  payload: {
    newColor
  },
  type: Actions.dashboardSetCustomeColor
})

export const addPass: ActionCreator<DashboardAddPass> = (
  id: string,
  newData: Passes
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.dashboardAddPass
})

export const deletePass: ActionCreator<DashboardDeletePass> = (
  sectionId: string,
  passId: string
) => ({
  payload: {
    passId,
    sectionId
  },
  type: Actions.dashboardDeletePass
})

export const renamePass: ActionCreator<DashboardRenamePass> = (
  section: string,
  passId: string,
  newName: string
) => ({
  payload: {
    newName,
    passId,
    section
  },
  type: Actions.dashboardRenamePass
})

export const addCustomeColor: ActionCreator<DashboardAddCustomeColor> = (
  newData: CustomeColor
) => ({
  payload: {
    newData
  },
  type: Actions.dashboardAddCustomeColor
})

export const getCustomeColor: ActionCreator<DashboardGetCustomeColor> = (
  data: CustomeColor[]
) => ({
  payload: {
    data
  },
  type: Actions.dashboardGetCustomeColor
})

export const addWallpaperImage: ActionCreator<DashboardAddWallpaperImage> = (
  newData: WallpaperImage
) => ({
  payload: {
    newData
  },
  type: Actions.dashboardAddWallpaperImage
})

export const getWallpaperImage: ActionCreator<DashboardGetWallpaperImage> = (
  data: WallpaperImage[]
) => ({
  payload: {
    data
  },
  type: Actions.dashboardGetWallpaperImage
})

export const toggleDetailsPopup: ActionCreator<ToggleDetailsPopup> = (payload: {
  open: boolean
  id: string
}) => ({
  payload,
  type: Actions.toggleDetailsPopup
})

export const toggleEventsPopup: ActionCreator<ToggleEventsPopUp> = (payload: {
  open: boolean
}) => ({
  payload,
  type: Actions.toggleEventsPopup
})

export const toggleWidgetsPopup: ActionCreator<ToggleWidgetsPopup> = (payload: {
  open: boolean
  sectionId: string
}) => ({
  payload,
  type: Actions.toggleWidgetsPopup
})

export const addNewWidget: ActionCreator<AddNewWidget> = (payload: {
  sectionId: string
  widgetId: string
}) => ({
  payload,
  type: Actions.addWidget
})

export const renderAllWidgets: ActionCreator<RenderAllWidgets> = (payload: {
  widgets: Array<{
    title: string
    id: string
    img: string
  }>
}) => ({
  payload,
  type: Actions.renderAllWidgets
})

export const userSectionChanged: ActionCreator<UserSectionChanged> = (payload: {
  userSections: DashboardState['userSections']
}) => ({
  payload,
  type: Actions.userSectionsChanged
})

export const addNewNotes: ActionCreator<DashboardAddNewNotes> = (
  newData: PaymentNotes[]
) => ({
  payload: {
    newData
  },
  type: Actions.dashboardAddNotes
})

export const getNotesData: ActionCreator<DashboardGetNotesData> = (
  data: PaymentNotes[]
) => ({
  payload: {
    data
  },
  type: Actions.dashboardGetNotes
})

export const toggleDetailsSharedWithPopup: ActionCreator<
  ToggleDetailsPopupShardWith
> = (open: boolean) => ({
  payload: {
    open
  },
  type: Actions.toggleSharedWithPopup
})

export const toggleComposeMail: ActionCreator<ToggleComposeMail> = (
  open: string
) => ({
  payload: {
    open
  },
  type: Actions.toggleComposeMail
})

export const toggleComposeMailDocument: ActionCreator<
  ToggleComposeMailForDocument
> = (open: any) => ({
  payload: {
    open
  },
  type: Actions.toggleComposeMailForDocument
})

export const showPricingTabInSettings: ActionCreator<ShowPricingTab> = (
  data: boolean
) => ({
  payload: {
    data
  },
  type: Actions.showPricingTab
})

export const showWorkflowTabInSettings: ActionCreator<ShowWorkTab> = (
  data: boolean
) => ({
  payload: {
    data
  },
  type: Actions.showWorkflowTab
})

export const showNotificationTabInSettings: ActionCreator<
  ShowNotificationTab
> = (data: boolean) => ({
  payload: {
    data
  },
  type: Actions.showNotificationTab
})

export const showChecklistTabInSettings: ActionCreator<ShowChecklistTab> = (
  data: boolean
) => ({
  payload: {
    data
  },
  type: Actions.showChecklistTab
})

export const showFeeTabInSettings: ActionCreator<ShowFeeTab> = (
  data: boolean
) => ({
  payload: {
    data
  },
  type: Actions.showFeeTab
})

export const showTaxTabInSettings: ActionCreator<ShowTaxTab> = (
  data: boolean
) => ({
  payload: {
    data
  },
  type: Actions.showTaxTab
})

export const categoriesFilter: ActionCreator<DashboardCategoriesFilter> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.dashboardCategoriesFilter
})

export const showTeamDetail: ActionCreator<DashboardTeamID> = (
  data: string
) => ({
  payload: {
    data
  },
  type: Actions.dashboardTeamID
})

export const showOfficeDetail: ActionCreator<DashboardOfficeID> = (
  data: string
) => ({
  payload: {
    data
  },
  type: Actions.dashboardOfficeID
})

export const showTaskDetail: ActionCreator<DashboardTaskID> = (
  data: string
) => ({
  payload: {
    data
  },
  type: Actions.dashboardTaskID
})

export const toggleSignatureModal: ActionCreator<SignatureModal> = (
  data: boolean
) => ({
  payload: {
    data
  },
  type: Actions.dashboardSignatureModal
})
