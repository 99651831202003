// Import Packages
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  border?: string
}

const Container = styled.div`
  margin-top: 20px;
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
  border-top: 3px solid ${(props: Props) => props.border || Colors.White1000};
  white-space: nowrap;
  cursor: pointer;
`

const TriangleLeft = styled.div`
  position: absolute;
  border-top: 23px solid ${Colors.transparent};
  border-bottom: 23px solid ${Colors.transparent};
  border-left: 23px solid ${Colors.White500};
  top: 0;
`

const TriangleRight = styled.div`
  position: absolute;
  border-top: 23px solid ${Colors.transparent};
  border-bottom: 23px solid ${Colors.transparent};
  border-left: 23px solid ${Colors.White1000};
  right: -23px;
  top: 0;
`

const Text = styled.div`
  background-color: ${Colors.White1000};
  height: 45px;
  padding: 12px;
  padding-left: 20px;
  color: ${Colors.DarkBlue200};
  font-weight: 500;

  i {
    margin-right: 8px;
    font-family: Georgia, serif;
    font-size: 24px;
    font-weight: bold;
    margin-left: 14px;
  }
`

const Reports = styled.div`
  background-color: ${Colors.White1000};
  min-height: 470px;
`

const ReportsContainer = styled.div`
  display: flex;
  padding: 30px;
  border-bottom: 1px solid ${Colors.Grey555};
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .fa-table {
    width: 60px;
    height: 60px;
    color: ${Colors.DarkBlue200};
  }

  .fa-chart-pie {
    width: 60px;
    height: 60px;
    color: ${Colors.DarkBlue200};
  }
`

const StyledButton = styled(Button)`
  &.ui.button {
    font-size: 12px;
    border-radius: 0;
    margin-top: 15px;
    background-color: ${Colors.White525};
    color: ${Colors.Black450};
  }

  &.ui.button.active-button {
    font-size: 12px;
    border-radius: 0;
    margin-top: 15px;
    background-color: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

export {
  Container,
  Content,
  Reports,
  ReportsContainer,
  StyledButton,
  Text,
  Title,
  TriangleLeft,
  TriangleRight,
}

