import styled from 'styled-components'
import { Stage } from 'react-konva'

interface Props {
  isActive?: boolean
}

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const StyledStage = styled(Stage)`
  position: relative;
  z-index: ${({ isActive }: Props) => isActive ? 999 : 5};
`
