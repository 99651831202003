import { omit } from 'lodash'
import client from 'queries/apollo'

import {
  CREATE_DOC_TEMPLATE,
  DELETE_DOC_TEMPLATE,
  REORDER_DOC_TEMPLATE,
  UPDATE_DOC_TEMPLATE,
  UPDATE_DOC_TEMPLATE_DOCUMENT
} from 'queries/graphql/Settings/ChecklistManager/DocTemplate/Mutation'

import { DocumentItem } from 'store/Settings/ChecklistManager/Types'

export const createDocTemplate = async (input: any = {}, file: any = null) => {
  const response = await client.mutate({
    mutation: CREATE_DOC_TEMPLATE,
    variables: {
      file,
      input
    }
  })

  return response.data.createDocTemplate
}

export const deleteDocTemplate = async (docTemplateId: string) => {
  const response = await client.mutate({
    mutation: DELETE_DOC_TEMPLATE,
    variables: { docTemplateId }
  })

  return response.data.deleteDocTemplate
}

export const reorderDocTemplate = async (id: string, order: number) => {
  const response = await client.mutate({
    mutation: REORDER_DOC_TEMPLATE,
    variables: {
      id,
      order
    }
  })

  return response.data.deleteDocTemplate
}

export const updateDocTemplate = async (input: DocumentItem) => {
  const { _id } = input
  const response = await client.mutate({
    mutation: UPDATE_DOC_TEMPLATE,
    variables: {
      docTemplateId: _id,
      input: omit(input, ['_id', 'template', 'isActive', 'tenant'])
    }
  })

  return response.data.deleteDocTemplate
}

export const updateDocTemplateDocument = async (id: string, updates: any) => {
  const { extractMarkup, extractPages, extractRaw } = updates
  const response = await client.mutate({
    mutation: UPDATE_DOC_TEMPLATE_DOCUMENT,
    variables: {
      extractMarkup,
      extractPages,
      extractRaw,
      id
    }
  })

  return response.data.updateDocument
}
