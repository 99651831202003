import Address from './Address'
import Badge from './Badge'
import Checkbox from './Checkbox'
import Date from './Date'

export default {
  Address,
  Badge,
  Checkbox,
  Date
}