import * as React from 'react'
import NumberFormat from 'react-number-format'
import { Button, Form, Image, Input, Table } from 'semantic-ui-react'

import Concierges from 'design/icons/dashboard/Concierge.png'

import { updateUserPass } from '../../Mutations'

import Modal from 'shared/Modal'

import {
  AddContactLeftPanel,
  AddForm,
  Container,
  StyledCheckbox,
  StyledPopup,
  Title,
} from './Styled'

import {
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faInfoCircle])

interface Props {
  getPassSection: (data: any) => void
  onClose: () => void
  pass: any
}

interface State {
  additionalFee: number
  animate: boolean
  automaticTransaction: boolean
  enable: boolean
}

class ConciergeSettingsModal extends React.Component<Props, State> {
  public state = {
    additionalFee: 0,
    animate: true,
    automaticTransaction: false,
    enable: false,
  }

  public async componentDidMount() {
    const { pass, pass: { settings }} = this.props
    this.setState({
      additionalFee: settings.additionalFee,
      automaticTransaction: settings.automaticTransaction,
      enable: pass.passStatus,
    })
  }

  public render() {
    const {
      additionalFee,
      animate,
      automaticTransaction,
      enable,
    } = this.state

    return (
      <Modal content={
        <Container>
          <AddContactLeftPanel>
            <Image src={Concierges} size='small' />
            <Title>{'CONFIGURE CONCIERGE'}</Title>
          </AddContactLeftPanel>
          <AddForm>
            <Table basic='very'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    Disable/Enable
                    {
                      !enable && <StyledPopup trigger={
                        <FontAwesomeIcon icon={['fal', 'info-circle']} />
                      } content={'Please ensure Purchase and Sale documents have been added to your document checklists'} />
                    }
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: 'right' }}>
                    <StyledCheckbox
                      className="test"
                      toggle={true}
                      checked={enable}
                      name='enable'
                      onChange={this.switchHandleChange}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Automatically Create Transaction?</Table.Cell>
                  <Table.Cell style={{ textAlign: 'right' }}>
                    <StyledCheckbox
                      className="test"
                      toggle={true}
                      checked={automaticTransaction}
                      name='transaction'
                      onChange={this.switchHandleChange}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Additional Fee (Optional)</Table.Cell>
                  <Table.Cell style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                    <Form.Field
                      control={Input}
                      name='price' >
                      <NumberFormat
                        icon='dollar'
                        iconPosition='left'
                        placeholder='Price'
                        onValueChange={this.handlePropertyPrice}
                        value={additionalFee !== 0 ? additionalFee : ''}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Form.Field>
                    <StyledPopup trigger={
                      <FontAwesomeIcon icon={['fal', 'info-circle']} />
                    } content={'Additional fee assessed to transaction creators for use of this enhanced feature'} />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Button content={'SAVE'} onClick={this.save} />
          </AddForm>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeSelf} width={700} />
    )
  }

  private save = async () => {
    const {
      pass,
      getPassSection,
    } = this.props

    const {
      additionalFee,
      automaticTransaction,
      enable,
    } = this.state

    const settings = {
      additionalFee: additionalFee || 0,
      automaticTransaction,
    }
    const input = {
      passStatus: enable,
      settings,
    }
    const response = await updateUserPass(pass._id, input)
    getPassSection(response)
    this.closeSelf()
  }

  private switchHandleChange = (e: any, { name }: any) => {
    if (name === 'enable') {
      this.setState({ enable: !this.state.enable })
    } else {
      this.setState({ automaticTransaction: !this.state.automaticTransaction })
    }
  }

  private handlePropertyPrice = (values: any) => {
    const { floatValue } = values
    this.setState({ additionalFee: floatValue })
  }

  private closeSelf = async () => {
    const { onClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      onClose()
    }, 300)
  }
}

export default ConciergeSettingsModal
