// Import Packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

// Import Fonts
import { fontStyle } from 'design/Fonts'

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(155, 155, 155, 0.25);
  font-family: ${fontStyle};
`
const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  top: 76px;
  z-index: 100;
  position: fixed;
  cursor: pointer;
  left: 0px;
`

const Modal = styled.div`
  position: relative;
  padding: 0px;
  background-color: ${Colors.White1000};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.13);
  z-index: 9999;
`

const SegmentGroup = styled(Segment.Group)`
  &.ui.horizontal.segments {
    border: none;
    box-shadow: none;
    margin-top: 60px;
    margin-bottom: 60px;
  }
`

const StatisticsContainer = styled(Segment)`
  .ui.horizontal.segments > &.segment {
    border: none;
    box-shadow: none;
    padding: 0px 34px 100px;
    border-left: none;
    border-right-color: ${Colors.Grey950};
    border-right-width: 1px;
    border-right-style: solid;
    width: 300px;
    height: 500px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    color: ${Colors.Black500};
    .header {
      margin: 10px auto;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
    }
    .description {
      font-size: 14px;
      justify-content: center;
      max-width: 300px;
      padding: 20px 0px;
    }
    .ui.small.image {
      width: 300px;
    }
  }
`

const DetailContainer = styled(Segment)`
  .ui.horizontal.segments > &.segment {
    border: none;
    box-shadow: none;
    padding-top: 0px;
    margin: 0 20px;
    height: 500px;
    width: 500px;
  }
`

const StyledGrid = styled(Grid)`
  &.ui.grid {
    max-width: 250px;
  }
`

const StyledRow = styled(Grid.Row)`
  .ui.grid > &.row {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const StyledColumn = styled(Grid.Column)`
  .ui[class*="two column"].grid>.row>&.column {
    font-size: 18px;
    font-family: ${fontStyle};
    align-items: center;
    display: flex;
    width: 70%;
    color: ${Colors.Black500};
    &.value {
      width: 30%;
      justify-content: flex-end;
    }
    &.pending {
      color: ${Colors.Red700};
      font-weight: bold;
    }
    &.joined {
      color: ${Colors.Green500};
      font-weight: bold;
    }
  }
`

const ShareSection = styled.div`
  margin-top: 40px;
`

const ShareLinkContainer = styled.div`
  text-align: center;
  height: 40px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  cursor: pointer;
  overflow: auto;
  width: 100%;
  white-space: nowrap;
  ${scrollbars};

  &.heading {
    font-weight: bold;
    background-color: ${Colors.White500};
    color: ${Colors.Black500};
    font-family: ${fontStyle}
  }
  &.link {
    color: ${Colors.DarkBlue200};
    border-color: ${Colors.White500};
    border-width: 1px;
    border-style: solid;
  }
`

const SocialIcons = styled.div`
  height: 50px;
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
`

const SocialButton = styled.div`
  height: 35px;
  cursor: pointer;
`

const SocialAction = styled(FontAwesomeIcon)`
  font-size: 28px;
  color: ${Colors.DarkBlue200};
`

export {
  Backdrop,
  DetailContainer,
  Modal,
  ModalWrapper,
  SegmentGroup,
  ShareLinkContainer,
  ShareSection,
  SocialAction,
  SocialButton,
  SocialIcons,
  StatisticsContainer,
  StyledColumn,
  StyledGrid,
  StyledRow
}
