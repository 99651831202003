import client from 'queries/apollo'

import { createUserPass, updateUserPass } from './Mutations'

import { GET_IDX_PASSES } from 'queries/graphql/Passes/Queries'

import { UserPassType } from 'app/Dashboard/MainContent/Passes/Types'
import { PassStatusEnum, PassTypeEnum } from 'store/Pass/Types'
import { MlsType, SettingsType } from './Types'

export const getIdxData = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_IDX_PASSES
  })

  if (!response.data) {
    return [] as SettingsType[]
  }

  const { mlsList, getUserPasses } = response.data
  if (!mlsList || !getUserPasses.results) {
    return [] as SettingsType[]
  }

  let primaryPass = { settings: {} as MlsType } as UserPassType
  const passes = getUserPasses.results.filter((item: UserPassType) => {
    const isPrimary = !item.settings.status && !item.settings.mls
    if (isPrimary) {
      primaryPass = { ...item }
    }
    return !isPrimary
  })

  const settings: SettingsType[] = []
  for await (const mls of mlsList) {
    let pass: any = passes.find((userPass: UserPassType) => {
      return userPass.settings.mls && userPass.settings.mls._id === mls._id
    })

    if (!pass) {
      const input = {
        isActive: true,
        pass: primaryPass.pass._id,
        passType: PassTypeEnum.Idx,
        settings: {
          mls: mls._id,
          status: PassStatusEnum.Inactive
        }
      }
      try {
        pass = await createUserPass(input)
      } catch (e) {
        pass = primaryPass
      }
    } else if (!pass.isActive) {
      await updateUserPass(pass._id, { isActive: true })
    }

    settings.push({
      _id: pass._id || '',
      mls: pass.settings.mls || mls,
      status: pass.settings.status || PassStatusEnum.Inactive
    })
  }

  return settings
}
