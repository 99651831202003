// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Divider, Image } from 'semantic-ui-react'

// Import Utils
import ClickToUpload from 'design/images/dashboard/clickToUpload.jpg'
import { TransactionList } from 'store/Transactions/Types'
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import { ChangeOwner } from '../Dashboard'
import {
  Address,
  CardContent,
  CardWrapper,
  Container,
  Content,
  CreatedDate,
  ExtraContent,
  Labels,
  Li,
  MemberProfile,
  MLSId,
  Price,
  // Profile,
  StyledLabel,
  StyledPopup,
  StyledProgress,
  Tag,
  TransactionName,
  Ul
} from './Styled'

// Font Awesome Icons
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons'
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faMapMarkerAlt, faDollarSign])

interface Props {
  changeStatus: (status: string) => void
  CardData: TransactionList
  boardId: string
  longPress: boolean
}

class TransactionCard extends React.Component<Props> {
  public state = {}

  public render() {
    const { CardData } = this.props
    let background = ''
    let text = ''
    switch (CardData.transactionRole) {
      case 'Buyer':
        background = Colors.Green215
        text = 'BUY'
        break
      case 'Seller':
        background = Colors.Red835
        text = 'SELL'
        break
      case 'Both':
        background = Colors.Purple50
        text = 'BOTH'
        break
      default:
        background = Colors.Red835
        break
    }
    const Card = CardData.shareWith.map((items: any, index: number) => {
      while (index < 3) {
        return (
          <Li key={index}>
            <StyledPopup
              trigger={<MemberProfile onClick={this.changeOwnership} key={index} background={items.profileImage} />}
              content={`${items.firstName} ${items.lastName}`}
            />
          </Li>
        )
      }
      return null
    })
    const Count =
      CardData.shareWith.length > 3 ? (
        <Labels>
          <StyledPopup trigger={<StyledLabel>...</StyledLabel>} content={`+${CardData.shareWith.length - 3}`} />
        </Labels>
      ) : null

    let color = ''
    if (CardData.progress >= 0 && CardData.progress < 25) {
      color = Colors.Grey950
    } else if (CardData.progress >= 25 && CardData.progress < 50) {
      color = Colors.DarkBlue205
    } else if (CardData.progress >= 50 && CardData.progress < 75) {
      color = Colors.Red10
    } else {
      color = Colors.Green80
    }
    return (
      <Container>
        <CardWrapper>
          <Link
            to={Routes.transactions.root + `/${CardData._id}` + Routes.transactions.documents.path}
            onClick={(e: any) => {
              e.stopPropagation()
              localStorage.setItem('transaction', JSON.stringify(CardData))
            }}
          >
            <CardContent>
              <Image
                src={
                  CardData.propertyId.images && CardData.propertyId.images.length > 0
                    ? CardData.propertyId.images[0].url
                    : ClickToUpload
                }
                size="small"
              />
              <Tag background={background}>{text}</Tag>
              <Content>
                <TransactionName>
                  {CardData.owner ? `${CardData.owner.firstName} ${CardData.owner.lastName}` : ''}
                </TransactionName>
                <div style={{ display: 'flex' }}>
                  <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
                  <div style={{ textTransform: 'capitalize' }}>
                    <Address>
                      {CardData.propertyId && CardData.propertyId.address
                        ? `${CardData.propertyId.address.streetNumber} ${CardData.propertyId.address.streetName}`
                        : 'No Address Found'}
                    </Address>
                    <Address>
                      {CardData.propertyId && CardData.propertyId.address
                        ? `${CardData.propertyId.address.city}, ${CardData.propertyId.address.state} ${
                            CardData.propertyId.address.zipCode
                          }`
                        : 'No Address Found'}
                    </Address>
                    <MLSId>
                      {Strings.transactionDashboard.addTransaction.mlsId}
                      {CardData.propertyId.mlsId ? CardData.propertyId.mlsId : 'Not Provided'}
                    </MLSId>
                    <CreatedDate>{moment(CardData.updatedAt).format('LL')}</CreatedDate>
                  </div>
                </div>
              </Content>
            </CardContent>
          </Link>
          {CardData.progress > 0 ? <StyledProgress background={color} percent={CardData.progress} /> : <Divider />}
          <ExtraContent>
            <Price>
              <FontAwesomeIcon icon={['far', 'dollar-sign']} />
              {CardData.propertyId && CardData.propertyId.price ? CardData.propertyId.price.toLocaleString() : ''}
            </Price>
            <Ul style={{ display: 'flex' }}>
              {Card}
              <Li>{Count}</Li>
            </Ul>
          </ExtraContent>
        </CardWrapper>
      </Container>
    )
  }

  private changeOwnership = async () => {
    const { CardData } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    if (user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === CardData.owner._id) {
      ChangeOwner(CardData._id)
    }
  }
}

export default TransactionCard
