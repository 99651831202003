import { Reducer } from 'redux'
import Actions from 'store/Teams/ActionsTypes'
import {
  addNewBoard,
  addNewCard,
  deleteCard,
  restoreCard,
  setNewBoardName,
  setNewDescription,
  setNewIcon
} from './ReduxUtil'
import { Team, TeamItemsDetails } from './Types'

const initialState: Team = {
  boardId: '',
  cardIndex: '',
  currentlyDeleteCard: null,
  data: [],
  filter: {
    filter: false,
    searchText: false
  },
  searchData: {},
  searchLoader: false
}

const TeamReducer: Reducer<Team> = (state: Team = initialState, action) => {
  switch (action.type) {
    case Actions.teamAddNewBoard:
      return {
        ...state,
        data: addNewBoard(state, action.payload.index, action.payload.newData)
      }
    case Actions.teamGetBoardData:
      return {
        ...state,
        data: action.payload.data
      }
    case Actions.teamAddNewCard:
      return {
        ...state,
        data: addNewCard(state, action.payload.id, action.payload.index, action.payload.newData)
      }
    case Actions.teamSetNewIcon:
      return {
        ...state,
        data: setNewIcon(state, action.payload.id, action.payload.icon)
      }
    case Actions.teamSetNewDescription:
      return {
        ...state,
        data: setNewDescription(state, action.payload.id, action.payload.description)
      }
    case Actions.teamSetNewBoardName:
      return {
        ...state,
        data: setNewBoardName(state, action.payload.id, action.payload.boardName)
      }
    case Actions.teamDeleteCard:
      const { data } = state
      const feeData = data.slice()
      let index: string
      let temp: TeamItemsDetails
      const callback = () => {
        return {
          ...state,
          boardId: action.payload.boardId,
          cardIndex: index,
          currentlyDeleteCard: temp,
          data: deleteCard(state, action.payload.boardId, action.payload.cardId)
        }
      }
      feeData.forEach((list: any) => {
        if (list.id === action.payload.boardId) {
          const newList = list.items.slice()
          const listIndex = newList.findIndex((laneItem: any) => {
            return laneItem.id === action.payload.cardId
          })
          index = listIndex
          temp = newList[listIndex]
          callback()
        }
      })
      return callback()
    case Actions.teamRestoreCard:
      return {
        ...state,
        data: restoreCard(state, state.boardId, state.cardIndex, state.currentlyDeleteCard)
      }
    case Actions.teamResetCardData:
      return {
        ...state,
        boardId: '',
        cardIndex: '',
        currentlyDeleteCard: null
      }
    case Actions.teamFilter:
      return {
        ...state,
        filter: action.payload.data
      }
    case Actions.teamSearchLoader:
      return {
        ...state,
        searchLoader: action.payload.open
      }
    case Actions.teamSearchData:
      return {
        ...state,
        searchData: action.payload.data
      }
    default:
      return state
  }
}

export default TeamReducer
