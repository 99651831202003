import gql from 'graphql-tag'

export const UPDATE_USER_GROUP_ORDER = gql`
  mutation updateGroupOrder($board: String, $source: Int, $destination: Int) {
    updateGroupOrder(
      where: { _id: $board, groupOrder: $source }
      input: { groupOrder: $destination }
    ) {
      _id
      name
      groupOrder
      isActive
      users {
        _id
        firstName
        lastName
        role
        phones {
          type
          value
        }
        addresses {
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        userName
        status
        profileImage
      }
    }
  }
`
