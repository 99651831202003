import gql from 'graphql-tag'

export const GET_PROPERTY_SUB_CATEGORY = gql`
  query getPropertyCategories($categoryName: String) {
    getPropertyCategories(where: { name: $categoryName }) {
      _id
      name
      propertySubCategories {
        _id
        name
      }
    }
  }
`
