import { combineReducers, Reducer } from 'redux'

// Import state types and reducers here.
import transactionFeeReducer from './TransactionFee/Reducer'
import { TransactionFeeState } from './TransactionFee/Types'

import checklistManagerReducer from './ChecklistManager/Reducer'
import { ChecklistManagerState } from './ChecklistManager/Types'

import WorkflowManagerReducer from './WorkflowManager/Reducer'
import { WorkflowManagerState } from './WorkflowManager/Types'

import TaxReducer from './Taxes/Reducer'
import { TaxState } from './Taxes/Types'

// The top-level state object
export interface SettingsState {
  transactionFee: TransactionFeeState
  checklistManager: ChecklistManagerState
  workflowManager: WorkflowManagerState
  taxes: TaxState
}

const settingsReducer: Reducer<SettingsState> = combineReducers<SettingsState>({
  checklistManager: checklistManagerReducer,
  taxes: TaxReducer,
  transactionFee: transactionFeeReducer,
  workflowManager: WorkflowManagerReducer
})

export default settingsReducer
