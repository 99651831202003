// Import Packages
import { IconName } from '@fortawesome/free-solid-svg-icons'
import * as React from 'react'

// Import Styled Components
import {
  StyledIcon,
  StyledSegment,
  TextBox
} from './Styled'

const InputItem = ({
  icon,
  placeholder,
  name,
  text,
  onChange
}: {
  icon: IconName,
  placeholder: string,
  text: string,
  name: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <StyledSegment>
      <StyledIcon icon={['fab', icon]} className="fa-sm" />
      <TextBox fluid={true} type='text' value={text} name={name} onChange={onChange} placeholder={placeholder} >
        <input />
      </TextBox>
    </StyledSegment>
  )
}

export default InputItem
