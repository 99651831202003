import * as React from 'react'

import {
  Block,
  Button,
  Icon,
  ToolTip,
} from './Styled'

interface Props {
  icon: any
  isActive: boolean
  label: string
  onClick: () => void
  type: 'primary' | 'danger' | 'light' | 'dark'
}

class BlockButton extends React.Component<Props, {}> {
  public static defaultProps = {
    isActive: false,
    onClick: () => { return false },
    type: 'primary',
  }

  public render = () => {
    const {
      icon,
      isActive,
      label,
      onClick,
      type,
    } = this.props

    return (
      <ToolTip
        position={'top center'}
        content={label}
        trigger={
          <Block
            className={`rp-button-block ${isActive ? 'rp-button-block-active' : ''}`}
            onClick={onClick}
            type={type}
          >
            <Button>
              <Icon icon={icon} />
            </Button>
          </Block>
        }
      />
    )
  }
}

export default BlockButton
