import Colors from 'design/Colors'
import styled from 'styled-components'

interface StyledOutputProps {
  max: number
  min: number
  value: number
}

interface SliderGroupContentProps {
  width?: number
}

const StyledSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 20px;
  background: ${Colors.DarkBlue200};
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  background-color: ${Colors.DarkBlue200};
  margin: 0 auto;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${Colors.DarkBlue295};
    box-shadow: 0px 0px 10px;
    cursor: pointer;
  }

  ::-moz-range-thumb {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${Colors.DarkBlue295};
    box-shadow: 0px 0px 10px;
    cursor: pointer;
  }
`

const StyledOutput = styled.div`
  font-size: 10px;
  background-color: ${Colors.Black510};
  display: inline-block;
  padding: 1px 6px;
  border-radius: 5px;
  color: ${Colors.White1000};
  position: absolute;
  top: -12px;
  min-width:30px;
  font-weight: 600;
  text-align: center;
  left: ${ (props: StyledOutputProps) => `calc(${((props.value - props.min) / (props.max - props.min)) * 92}% - 10px)`};

  ::before{
    content: "";
    position: absolute;
    bottom: -6px;
    right: 7px;
    border: 0px solid ${Colors.Black510};
    border-top: 12px solid ${Colors.Black510};
    border-left: 6px solid transparent;
    border-bottom-color: transparent;
    transform: rotate(34deg);
  }
`

const SliderGroupContent = styled.div`
  margin: 0px;
  height: 100px;
  width: ${(props: SliderGroupContentProps) => props.width ? `${props.width}%` : '100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

const SliderTitle = styled.div`
  font-size: 14px;
`

const SliderRangeContainer = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-top: -8px;
  color: ${Colors.Black500};
`
const SliderContainer = styled.div`
  width: 100%;
  height: 35%;
  display: flex;
  align-items: flex-end;
  position: relative;
`

const SliderMinRange = styled.div`
  font-size: 12px;
`

const SliderMaxRange = styled.div`
  font-size: 12px;
`

export {
  SliderContainer,
  SliderGroupContent,
  SliderMaxRange,
  SliderMinRange,
  SliderRangeContainer,
  SliderTitle,
  StyledOutput,
  StyledSlider
}
