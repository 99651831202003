// Import Components
import client from 'queries/apollo'
import { Checked } from './CustomComponents'

import { FiltersData } from 'store/Teams/Types'

// Import Graphql Queries
import { GET_OFFICE_TEAMS, GET_OFFICES_TEAMS, GET_TEAMS, GET_TEAMS_ON_OFFICE } from 'queries/graphql/Teams/Queries'

export const getOfficeTeam = async (filter: FiltersData, id?: string, singleSkip?: number) => {
  const officesTeam = await client.query({
    fetchPolicy: 'network-only',
    query: GET_OFFICES_TEAMS,
    variables: {
      filter,
      id,
      orderField: 'teamOfficeOrder',
      singleSkip,
      sortOrder: 1
    }
  })

  return officesTeam.data.getOfficeTeams
}

export const getOfficesTeam = async (id: string, filter: FiltersData, boardId?: string, singleSkip?: number) => {
  const officesTeam = await client.query({
    fetchPolicy: 'network-only',
    query: GET_OFFICE_TEAMS,
    variables: {
      boardId,
      filter,
      id,
      orderField: 'teamOfficeOrder',
      singleSkip,
      sortOrder: 1
    }
  })

  return officesTeam.data.getOfficeTeams
}

export const getTeams = async (filter: FiltersData) => {
  const officesTeam = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TEAMS,
    variables: { isActive: true, filter }
  })

  return officesTeam.data.getTeams
}

export const getTeamOnOffice = async (filter: FiltersData, office: string) => {
  const officesTeam = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TEAMS_ON_OFFICE,
    variables: { office, filter, isActive: true }
  })

  return officesTeam.data.getTeams
}

export const ChangeToggleCheckBox = (checked: boolean) => {
  const data: any = client.readQuery({
    query: GET_TEAMS,
    variables: { isActive: true }
  })
  const newData = data.getTeams.map((list: any) => {
    return {
      ...list,
      checked: !checked
    }
  })
  client.writeQuery({
    data: { getTeams: newData },
    query: GET_TEAMS,
    variables: { isActive: true }
  })
  Checked(checked)
}

export const ToggleSingleCheckBox = (id: string) => {
  const data: any = client.readQuery({
    query: GET_TEAMS,
    variables: { isActive: true }
  })
  const newData = data.getTeams.map((list: any) => {
    return {
      ...list,
      checked: list._id === id ? !list.checked : list.checked
    }
  })
  client.writeQuery({
    data: { getTeams: newData },
    query: GET_TEAMS,
    variables: { isActive: true }
  })
}

export const reset = async () => {
  await client.query({
    query: GET_OFFICES_TEAMS,
    variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
  })
  await client.query({ query: GET_TEAMS, variables: { isActive: true } })
  return getOptions()
}

export const sortTeam = async (boardId: string, sortOrder: number) => {
  let result
  if (boardId !== '') {
    result = await client.query({
      fetchPolicy: 'network-only',
      query: GET_OFFICES_TEAMS,
      variables: {
        id: boardId,
        orderField: 'teamOfficeOrder',
        sortOrder: 1,
        sortTeamOrder: sortOrder,
        teamOrderField: 'name'
      }
    })
  } else {
    result = await client.query({
      fetchPolicy: 'network-only',
      query: GET_TEAMS,
      variables: { sortOrder: sortOrder, orderField: 'name', isActive: true }
    })
  }
  return result
}

export const getOptions = async () => {
  const offices: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_OFFICES_TEAMS,
    variables: { sortOrder: 1, orderField: 'teamOfficeOrder' }
  })
  const options = offices.data.getOfficeTeams.reduce((accumulator: any, currentValue: any) => {
    accumulator.push({
      key: currentValue._id,
      text: currentValue.branchName,
      value: `${currentValue._id}, ${currentValue.branchName}`
    })
    return accumulator
  }, [])
  options.splice(0, 0, {
    key: '0',
    text: 'Select Office',
    value: 'Choose'
  })
  if (options.length > 2) {
    options.push({
      key: 'All',
      text: 'All',
      value: 'All'
    })
  }
  return options
}
