import { combineReducers, Reducer } from 'redux'

import BillingReducer from './Billing/Reducer'
import ContactReducer from './Contacts/Reducer'
import DashboardReducer from './Dashboard/Reducer'
import LayoutReducer from './Layout/Reducer'
import leadReducer from './Leads/Reducer'
import MailboxReducer from './Mailbox/Reducer'
import NotificationsReducer from './Notifications/Reducer'
import OfficesReducer from './Offices/Reducer'
import UserPassReducer from './Pass/Reducer'
import ReportReducer from './Reports/Reducer'
import SettingsReducer from './Settings/Reducer'
import TaskReducer from './Tasks/Reducer'
import TeamReducer from './Teams/Reducer'
import transactionReducer from './Transactions/Reducer'
import UserProfileReducer from './UserProfile/Reducer'
import UsersReducer from './Users/Reducer'

import { BillingState } from './Billing/Types'
import { Contact } from './Contacts/Types'
import { DashboardState } from './Dashboard/Types'
import { LayoutState } from './Layout/Types'
import { LeadState } from './Leads/Types'
import { Mailbox } from './Mailbox/Types'
import { Notifications } from './Notifications/Types'
import { Office } from './Offices/Types'
import { UserPassState } from './Pass/Types'
import { ReportState } from './Reports/Types'
import { SettingsState } from './Settings/Reducer'
import { Task } from './Tasks/Types'
import { Team } from './Teams/Types'
import { TransactionState } from './Transactions/Types'
import { UserProfile } from './UserProfile/Types'
import { Users } from './Users/Types'

// The top-level state object
export interface AppState {
  billing: BillingState
  contacts: Contact
  dashboard: DashboardState
  layout: LayoutState
  leads: LeadState
  mailbox: Mailbox
  notification: Notifications
  offices: Office
  reports: ReportState
  settings: SettingsState
  tasks: Task
  teams: Team
  transactions: TransactionState
  userPass: UserPassState
  userProfile: UserProfile
  users: Users
}

const reducers: Reducer<AppState> = combineReducers<AppState>({
  billing: BillingReducer,
  contacts: ContactReducer,
  dashboard: DashboardReducer,
  layout: LayoutReducer,
  leads: leadReducer,
  mailbox: MailboxReducer,
  notification: NotificationsReducer,
  offices: OfficesReducer,
  reports: ReportReducer,
  settings: SettingsReducer,
  tasks: TaskReducer,
  teams: TeamReducer,
  transactions: transactionReducer,
  userPass: UserPassReducer,
  userProfile: UserProfileReducer,
  users: UsersReducer,
})

export default reducers
