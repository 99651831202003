// Import Packages
import * as React from 'react'
import { Button, Form, Image, Input } from 'semantic-ui-react'

// Import Images and Icons
import Modal from 'shared/Modal'

// Import Components
import { handleValidation } from './Validation'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  AddForm,
  AddLeftPanel,
  Container,
  StyledForm,
} from './Styled'

interface Props {
  closeModal: () => void
  createPlan: (transaction: any) => void
}

interface State {
  transaction: any
  errors: any
  animate: boolean
}

class AddTeamModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    errors: {},
    transaction: {}
  }

  public render() {
    const onButtonClickFunction = () => {
      this.transactionSubmit()
    }
    const { animate, errors } = this.state
    return (
      <Modal content={
        <Container>
          <AddLeftPanel>
            <Image src={Strings.settings.customPaln} />
          </AddLeftPanel>
          <AddForm>
            <StyledForm size={'mini'}>
              <Form.Field
                control={Input}
                label='Plan Name'
                name='name'
                placeholder='Plan Name'
                onChange={this.handleChange}
                error={errors[`name`]} />
              <div className='equal'>
                <Form.Field control={Button} onClick={onButtonClickFunction} content={'SAVE'} />
                <Form.Field control={Button} onClick={this.closeModal} content={'CANCEL'} />
              </div>
            </StyledForm>
          </AddForm>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={600} />
    )
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private handleChange = (e: any, { name, value }: any) => {
    const { transaction } = this.state
    transaction[name] = value
    this.setState({ transaction })
  }


  private addCustomPlan = () => {
    const { createPlan } = this.props
    const { transaction } = this.state
    createPlan(transaction)
  }

  private transactionSubmit = () => {
    const { transaction } = this.state
    const result = handleValidation(transaction)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      this.addCustomPlan()
      this.closeModal()
    }
  }
}

export default AddTeamModal
