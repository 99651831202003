import { Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import { text, x, y } from 'design/Styled'

export const Container = styled.div`
  ${y};
`

export const Row = styled.div`
  ${x};
  ${text};
  justify-content: center;
  text-align: center;
  margin-bottom: 1em;
`

export const Text = styled.span`
  ${x};
  ${text};
  line-height: 1.2;
  justify-content: center;
  text-align: center;
  margin: 0 0 1em 0;
`

export const StyledInput = styled(Input)`
  margin-bottom: 1em;
`

export const StyledForm = styled(Form)``
