// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  text-align: center;

  .ui.button {
    font-size: 0.8rem;
    margin-bottom: 20px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

const Title = styled.div`
  text-align: center;
  border-bottom: 1px solid ${Colors.Black905};
  padding-top: 10px;
  padding-bottom: 12px;
  color: ${Colors.Black500};
  font-weight: 600;
  font-size: 12px;
`

interface StyledOutputProps {
  max: number
  min: number
  value: number
}

interface SliderGroupContentProps {
  width?: number
}

const StyledSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 20px;
  background: ${Colors.DarkBlue200};
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  background-color: ${Colors.DarkBlue200};
  margin: 0 auto;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${Colors.DarkBlue295};
    box-shadow: 0px 0px 10px;
    cursor: pointer;
  }

  ::-moz-range-thumb {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${Colors.DarkBlue295};
    box-shadow: 0px 0px 10px;
    cursor: pointer;
  }
`

const StyledOutput = styled.div`
  font-size: 12px;
  background-color: ${props => props.color || Colors.Black510};
  display: inline-block;
  padding: 1px 6px;
  border-radius: 5px;
  color: ${Colors.White1000};
  position: absolute;
  top: -26px;
  min-width: 35px;
  font-weight: 600;
  text-align: center;
  left: ${ (props: StyledOutputProps) => `calc(${((props.value - props.min) / (props.max - props.min)) * 92}% - 20px)`};

  ::before{
    content: "";
    position: absolute;
    bottom: -6px;
    right: 7px;
    border: 0px solid ${props => props.color || Colors.Black510};
    border-top: 12px solid ${props => props.color || Colors.Black510};
    border-left: 6px solid transparent;
    border-bottom-color: transparent;
    transform: rotate(34deg);
  }
`

const SliderGroupContent = styled.div`
  margin: 0px;
  height: 100px;
  width: ${(props: SliderGroupContentProps) => props.width ? `${props.width}%` : '100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 12px;
`

const SliderContainer = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: flex-end;
  position: relative;
`

const Slider = styled.div`
  margin: 20px;
  background: ${Colors.White525};
`

export {
  Container,
  Slider,
  SliderContainer,
  SliderGroupContent,
  StyledOutput,
  StyledSlider,
  Title,
}
