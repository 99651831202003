import styled from 'styled-components'

import { clipboard } from 'design/icons/dashboard'

import Colors from 'design/Colors'
import { x, y } from 'design/Styled'

export const Container = styled.div`
  ${y};
  padding: 0;
  height: 100%;
  overflow: hidden;
`

export const Header = styled.div`
  ${x};
  justify-content: space-between;
  padding: 1.6em 2em 0.8em;
  font-size: 14px;
`

export const UserEmailAddress = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${Colors.Black550};
  font-weight: 100;

  svg {
    margin-right: 10px;
    font-size: 1.2em;
  }
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > :first-child {
    background: ${Colors.ButtonPrimary};
    color: ${Colors.White1000};
    border-left: 1px solid ${Colors.Black1000};
    :hover svg {
      transform: none;
    }
  }
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Grey550};
  border: 1px solid ${Colors.Black1000};
  color: ${Colors.Black550};
  border-left: none;
  cursor: pointer;

  svg {
    font-size: 1.8em;
    padding: 0.2em;
    transform: rotate(1deg);
    transition: transform 500ms ease-in-out;
  }

  :hover svg {
    transform: rotate(179deg);
  }
`

export const Copy = styled.div`
  font-size: 10px;
  cursor: pointer;
  text-decoration: underline;
  color: ${Colors.Black600};
  display: flex;
  align-items: center;
  margin-left: 10px;

  &:before {
    width: 15px;
    height: 15px;
    background-image: url(${clipboard});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 3px;
    content: '';
  }
`
