// Import Packages
import * as React from 'react'
import styled from 'styled-components'

// Import Components
import { getTenantDetails } from 'app/Settings/Queries'
import GeneralConfiguration from './GeneralConfiguration'
import UserConfiguration from './UserConfiguration'

// Import Colors
import Colors from 'design/Colors'
import { getLoggedInUser } from 'utils'

const Container = styled.div`
  height: calc(88vh - 70px);
  overflow: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
      background: ${Colors.transparent};
  }
`

interface State {
  profile: any
}
class SystemDefaults extends React.Component<{}, State> {
  public state = {
    profile: {}
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    const response = await getTenantDetails(user.tenant)
    this.setState({ profile: response })
  }

  public render() {
    const { profile } = this.state
    return (
      <Container>
        {(Object.keys(profile).length > 0) &&
          <>
            <GeneralConfiguration profile={profile} />
            <UserConfiguration profile={profile} />
          </>
        }
      </Container>
    )
  }

}

export default SystemDefaults
