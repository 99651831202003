const Actions = {
  reportAddNewMyReport: 'ADD_REPORT_NEW_MY_REPORT',
  reportDeleteMyReport: 'DELETE_REPORT_MY_REPORT',
  reportGetReportData: 'GET_REPORT_REPORT_DATA',

  reportAddNewTransaction: 'ADD_REPORT_NEW_TRANSACTION',
  reportDeleteTransaction: 'DELETE_REPORT_TRANSACTION',
  reportGetTransaction: 'GET_REPORT_TRANSACTION_DATA',

  reportChangeKey: 'REPORT_CHANGE_KEY',
  reportChangeToggle: 'REPORT_CHANGE_TOGGLE',
  reportCity: 'REPORT_CITY',
  reportColumnMetaData: 'COLUMN_META_DATA',
  reportDatesFilters: 'REPORT_DATES_FILTERS',
  reportGeneratedID: 'REPORT_GENERATED_ID',
  reportGetApplyFilter: 'GET_APPLY_FILTER',
  reportGetFilter: 'GET_FILTER',
  reportGetPickData: 'GET_PICK_DATA',
  reportModule: 'REPORT_MODULE',
  reportResetDataFilter: 'REPORT_RESET_FILTER_DATA',
  reportState: 'REPORT_STATE',
  systemReport: 'SYSTEM_REPORT',

  viewReport: 'VIEW_REPORT'
}

export default Actions
