import axios from 'axios'
import * as React from 'react'

import Modal from 'shared/Modal'
import Toast from 'shared/Toast/Toast'
import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'

import { createTransactionDocuments } from 'app/Transactions/Dashboard/TransactionMutations'
import { getTransactions } from 'app/Transactions/Dashboard/TransactionQueries'
import ClickToUpload from 'design/images/dashboard/clickToUpload.jpg'

import { genericGetTransactions } from 'shared/Transaction/Queries'

import { isPlanAllowed } from 'shared/Billing/Queries'
import { serverToast } from 'shared/Toast/Toast'
import { Strings } from 'utils'

import {
  Aside,
  AssignButton,
  Container,
  Content,
  CreateButton,
  Image,
  NotListed,
  StyledInput,
  Table,
  Title,
  TransactionListing,
  Transactions,
  TransactionThumb
} from './Styled'

import { DocumentType, TransactionType } from './Types'

interface Props {
  documents: DocumentType[] | File[]
  onClose: () => void
  owner?: any
}

interface State {
  attemptSearch: boolean
  filteredTransactions: TransactionType[]
  loader: boolean
  modal: any
  ownerName: string | null
  searchInput: string
  showModal: boolean
  transactions: TransactionType[]
  transition: boolean
}

const validStatus = ['New', 'Updated', 'Active', '$$ Received', 'Payable']

class AddDocumentModal extends React.Component<Props, State> {
  public state = {
    attemptSearch: true,
    filteredTransactions: [] as TransactionType[],
    loader: false,
    modal: null,
    ownerName: null,
    searchInput: '',
    showModal: false,
    transactions: [] as TransactionType[],
    transition: true
  }

  public componentWillMount = () => {
    this.updateTransactions()
  }

  public render() {
    const { loader, modal, showModal, filteredTransactions, searchInput, transition } = this.state
    return (
      <Modal
        content={
          <Container>
            <Aside padding={1} width={35} justify="center">
              <Image src={Strings.transactionDashboard.addDocumentImage} />
              <StyledInput
                name="search"
                onChange={this.filterTransactions}
                placeholder="Search for a transaction..."
                value={searchInput}
              />
            </Aside>
            <Content padding={2} width={65}>
              {this.getTitle()}
              <Transactions primary={true}>
                <Table>
                  <thead>
                    <tr>
                      <th>Listing ID</th>
                      <th>Address</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTransactions.map((transaction: any, index: number) => {
                      if (!transaction.isActive || !validStatus.includes(transaction.status)) {
                        return null
                      }
                      const property = transaction.propertyId
                      const images = property && property.images
                      const address = property && property.address
                      const thumb = images.length > 0 ? images[0].url : ClickToUpload
                      return (
                        <tr key={index}>
                          <td>
                            <TransactionListing>
                              <TransactionThumb image={thumb} />
                              <span>{transaction.transactionId}</span>
                            </TransactionListing>
                          </td>
                          <td>{address && `${address.streetNumber} ${address.streetName}, ${address.city}`}</td>
                          <td>
                            <AssignButton
                              loading={loader[transaction._id]}
                              compact={true}
                              size="small"
                              onClick={() => this.assignDocument(transaction)}
                            >
                              Assign
                            </AssignButton>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Transactions>
              <NotListed>
                <span>Transaction not listed here?</span>
                <CreateButton onClick={this.openAddTransactionModal}>Create Transaction</CreateButton>
              </NotListed>
            </Content>
            {showModal && modal}
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        vw={60}
        vh={70}
      />
    )
  }

  private getTitle = () => {
    const { documents } = this.props
    const { ownerName } = this.state

    if (documents.length > 1) {
      return (
        <Title>
          Add all documents to <em>{ownerName}</em> available transactions.
        </Title>
      )
    } else if ((documents as DocumentType[])[0].fileName) {
      return (
        <Title>
          Add <em>{(documents as DocumentType[])[0].fileName}</em> to <em>{ownerName}</em> available transactions.
        </Title>
      )
    } else if ((documents as File[])[0].name) {
      return (
        <Title>
          Add <em>{(documents as File[])[0].name}</em> to <em>{ownerName}</em> available transactions.
        </Title>
      )
    }

    return (
      <Title>
        Add document to <em>{ownerName}</em> available transactions.
      </Title>
    )
  }

  private isPdf = (fileType: string) => {
    if (fileType !== 'application/pdf') {
      Toast({
        message: 'You can only assign PDF documents',
        type: 'error'
      })
      return false
    }
    return true
  }

  private alertUploadFailure = (name: string) => {
    let message = 'Failed to upload'
    if (name.length > 0) {
      message = `Failed to upload ${name}`
    }
    Toast({
      message,
      type: 'error'
    })
  }

  private assignDocument = async (transaction: TransactionType) => {
    const { documents } = this.props
    const { loader } = this.state
    loader[transaction._id] = true
    this.setState({ loader })

    if ((documents as DocumentType[])[0].url) {
      const promisedDocuments = (documents as DocumentType[]).map((document: DocumentType) =>
        this.handleDocumentTypeAssign(document, transaction._id)
      )
      await Promise.all(promisedDocuments)
    } else if ((documents as File[])[0].type) {
      const promisedDocuments = (documents as File[]).map((document: File) =>
        this.handleFileTypeAssign(document, transaction._id)
      )
      await Promise.all(promisedDocuments)
    }

    loader[transaction._id] = false
    this.setState({ loader })
    this.closeSelf()
  }

  private handleDocumentTypeAssign = async (document: DocumentType, transactionId: string) => {
    const { fileName, fileType, url } = document

    if (!this.isPdf(fileType)) {
      return
    }

    const blob = await axios({
      method: 'get',
      responseType: 'blob',
      url
    })

    if (blob.data) {
      blob.data['lastModifiedDate'] = new Date()
      blob.data['lastModified'] = new Date()
      blob.data['name'] = fileName

      this.createDocument(blob.data as File, transactionId)
    } else {
      this.alertUploadFailure(fileName)
    }
  }

  private handleFileTypeAssign = async (document: File, transactionId: string) => {
    const { type } = document
    if (!this.isPdf(type)) {
      return
    }
    return this.createDocument(document as File, transactionId)
  }

  private createDocument = async (file: File, id: string) => {
    const doc = await createTransactionDocuments(file, id)
    if (!doc) {
      this.alertUploadFailure(file.name)
    }
    return doc
  }

  private openAddTransactionModal = async (e: React.MouseEvent<{}>) => {
    const { documents } = this.props
    e.preventDefault()
    try {
      await isPlanAllowed('transaction')
    } catch (error) {
      return serverToast(error)
    }

    let docIds: any = []
    if ((documents as DocumentType[])[0].url) {
      docIds = (documents as DocumentType[]).map((doc: DocumentType) => doc._id)
    }
    const modal = <CreateTransactionModal docIds={docIds} onClose={this.closeModal} />
    this.setState({
      modal,
      showModal: true
    })
  }

  private updateTransactions = async () => {
    const { owner } = this.props
    const { attemptSearch } = this.state
    let transactions: TransactionType[]
    if (owner && attemptSearch) {
      transactions = await genericGetTransactions({ owner: owner._id })
      if (transactions.length === 0) {
        this.setState({ attemptSearch: false })
        transactions = await getTransactions({})
      } else if (owner && owner.firstName && owner.lastName) {
        this.setState({ ownerName: `${owner.firstName} ${owner.lastName}'s` })
      }
    } else {
      transactions = await getTransactions({})
    }

    if (transactions.length > 0) {
      const loader = transactions.reduce((obj: any, item: TransactionType) => {
        obj[item._id] = false
        return obj
      }, {})
      this.setState({
        filteredTransactions: transactions,
        loader,
        transactions
      })
    }
  }

  private filterTransactions = (e: any, { value }: any) => {
    const { transactions } = this.state

    const filteredTransactions = transactions.filter((item: TransactionType) => {
      const { city, streetName, streetNumber } = item.propertyId.address
      const address = `${streetNumber} ${streetName} ${city}`.toLowerCase()
      return address.includes(value.toLowerCase())
    })

    this.setState({
      filteredTransactions,
      searchInput: value
    })
  }

  private closeModal = (result: any) => {
    this.setState({ showModal: false })
    if (result) {
      this.updateTransactions()
      Toast({
        message: 'A new transaction has been created with the selected documents',
        type: 'success'
      })
      this.closeSelf()
    }
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({ transition: true })
      onClose()
    }, 300)
  }
}

export default AddDocumentModal
