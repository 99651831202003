import client from 'queries/apollo'

import { CREATE_MLS } from 'queries/graphql/Mls/Mutations'

import { MlsInputType } from './Types'

export const createUnverifiedMls = async (input: MlsInputType) => {
  input = {
    ...input,
    isActive: true,
    isVerified: false
  }
  const result = await client.mutate({
    mutation: CREATE_MLS,
    variables: { input }
  })

  return result.data.createMls
}
