import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Label } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'

export const StyledInput = styled(Input)``

export const InlineButton = styled(Label)`
  &.ui.label {
    display: flex;
    min-width: 36px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colors.EmphasisLine};
  }
  cursor: pointer;
`

export const Fa = styled(FontAwesomeIcon)``
