// import Colors from 'design/Colors'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledGrid = styled(Grid)`

`

export {
  StyledGrid,
}
