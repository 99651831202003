// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import { GET_GROUPS } from 'queries/graphql/Group/Queries'

export const getGroups = () => {
  return client.query({
    query: GET_GROUPS,
    variables: { orderField: 'groupOrder', sortOrder: 1 }
  })
}
