// Import Packages
import * as React from 'react'
import { Checkbox, Form, Input } from 'semantic-ui-react'

// Import Components
import { updateProfile } from 'app/Settings/Mutations'
import AddressInput from 'shared/AddressInput'
import StateDropdown from 'shared/StateDropdown'
import { ProfileType } from '../Types'

// Import Utils
import { Strings } from 'utils'

import { Address, Container, StyledForm, Title } from './Styled'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'
import { FormType } from './Types'

interface Props {
  profile: ProfileType
}

interface State {
  shippingIsBilling: boolean
  form: FormType
}

class Company extends React.Component<Props, State> {
  public state = {
    form: {
      billingAddress: {} as AddressType,
      companyName: '',
      shippingAddress: {} as AddressType
    } as FormType,
    shippingIsBilling: true
  }

  public async componentDidMount() {
    const { profile } = this.props
    const billing = profile[`billingAddress`] as AddressType
    const shipping = profile[`shippingAddress`] as AddressType

    let billingAddress = {} as AddressType
    let shippingAddress = {} as AddressType
    let shippingIsBilling = false

    if (billing) {
      const billingStreet = [billing.streetNumber, billing.streetName].join(' ')
      billingAddress = {
        ...billing,
        streetAddress: billingStreet
      }
    }

    if (shipping) {
      const shippingStreet = [shipping.streetNumber, shipping.streetName].join(' ')
      shippingAddress = {
        ...shipping,
        streetAddress: shippingStreet
      }
    }

    if (billing && shipping) {
      shippingIsBilling =
        [billing.streetNumber, billing.streetName, billing.city, billing.state, billing.zipCode].join(' ') ===
        [shipping.streetNumber, shipping.streetName, shipping.city, shipping.state, shipping.zipCode].join(' ')
    }

    const form: FormType = {
      accountNumber: profile.accountNumber || undefined,
      bankName: profile.bankName || '',
      billingAddress,
      companyName: profile.companyName || '',
      routingNumber: profile.routingNumber || undefined,
      shippingAddress
    }
    this.setState({
      form,
      shippingIsBilling
    })
  }

  public render() {
    const {
      form,
      form: { billingAddress, shippingAddress },
      shippingIsBilling
    } = this.state

    return (
      <Container>
        <Title>{Strings.settings.profile.company}</Title>
        <StyledForm size={'tiny'}>
          <Form.Field
            control={Input}
            label="Company name"
            className="company-name"
            value={form.companyName}
            onChange={this.handleInput}
            name="companyName"
          />
          <Form.Field
            control={Input}
            label="Bank name"
            className="company-name"
            value={form.bankName}
            onChange={this.handleInput}
            name="bankName"
          />
          <Form.Field
            control={Input}
            label="Bank Account Number"
            className="company-name"
            value={form.accountNumber}
            onChange={this.handleInput}
            name="accountNumber"
          />
          <Form.Field
            control={Input}
            label="Bank Routing Number"
            className="company-name"
            value={form.routingNumber}
            onChange={this.handleInput}
            name="routingNumber"
          />
          <Address>{Strings.settings.profile.billingAddress}</Address>
          <Form.Group widths="equal">
            <div className="field">
              <label>Street Address</label>
              <AddressInput
                address={billingAddress}
                name="streetAddress"
                onChange={this.handleBillingStreet}
                placeholder="Street Address"
                size={'small'}
                showNumberPopup={false}
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <label>City</label>
              <AddressInput
                address={billingAddress}
                mode={AddressModeEnum.City}
                name="city"
                onChange={this.handleBillingCity}
                placeholder="City"
                size={'small'}
              />
            </div>
            <div className="field">
              <label>State</label>
              <StateDropdown
                name="state"
                onChange={this.handleBillingInput}
                placeholder="State"
                value={billingAddress.state}
              />
            </div>
            <Form.Field
              control={Input}
              label="Zip Code"
              placeholder="Zip Code"
              value={billingAddress.zipCode}
              onChange={this.handleBillingInput}
              name="zipCode"
            />
          </Form.Group>
          <Address>{Strings.settings.profile.shippingAddress}</Address>
          <Form.Field
            control={Checkbox}
            checked={shippingIsBilling}
            label="Same As Billing"
            onChange={this.toggleShippingAddress}
          />
          {!shippingIsBilling && (
            <React.Fragment>
              <Form.Group widths="equal">
                <div className="field">
                  <label>Street Address</label>
                  <AddressInput
                    address={shippingAddress}
                    name="streetAddress"
                    onChange={this.handleShippingStreet}
                    placeholder="Street Address"
                    showNumberPopup={false}
                    size={'small'}
                  />
                </div>
              </Form.Group>
              <Form.Group widths="equal">
                <div className="field">
                  <label>City</label>
                  <AddressInput
                    address={shippingAddress}
                    mode={AddressModeEnum.City}
                    name="city"
                    onChange={this.handleShippingCity}
                    placeholder="City"
                    size={'small'}
                  />
                </div>
                <div className="field">
                  <label>State</label>
                  <StateDropdown
                    name="state"
                    onChange={this.handleShippingInput}
                    placeholder="State"
                    value={shippingAddress.state}
                  />
                </div>
                <Form.Field
                  control={Input}
                  label="Zipcode"
                  placeholder="Zip-Code"
                  value={shippingAddress.zipCode}
                  onChange={this.handleShippingInput}
                  name="zipCode"
                />
              </Form.Group>
            </React.Fragment>
          )}
        </StyledForm>
      </Container>
    )
  }

  private handleInput = (event: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
    this.handleSubmit()
  }

  private handleBillingStreet = (address: AddressType) => {
    const { form } = this.state
    form.billingAddress = { ...address }
    this.setState({ form })
    this.handleSubmit()
  }

  private handleBillingCity = (address: AddressType) => {
    const { form } = this.state
    form.billingAddress.city = address.city
    form.billingAddress.state = address.state
    this.setState({ form })
    this.handleSubmit()
  }

  private handleBillingInput = (event: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { form } = this.state
    form.billingAddress[name] = value
    this.setState({ form })
    this.handleSubmit()
  }

  private handleShippingStreet = (address: AddressType) => {
    const { form } = this.state
    form.shippingAddress = { ...address }
    this.setState({ form })
    this.handleSubmit()
  }

  private handleShippingCity = (address: AddressType) => {
    const { form } = this.state
    form.shippingAddress.city = address.city
    form.shippingAddress.state = address.state
    this.setState({ form })
    this.handleSubmit()
  }

  private handleShippingInput = (event: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { form } = this.state
    form.shippingAddress[name] = value
    this.setState({ form })
    this.handleSubmit()
  }

  private toggleShippingAddress = () => {
    const { shippingIsBilling } = this.state
    this.setState({ shippingIsBilling: !shippingIsBilling })
    window.requestAnimationFrame(() => {
      this.handleSubmit()
    })
  }

  private handleSubmit = async () => {
    const { profile } = this.props
    const { form, shippingIsBilling } = this.state
    if (shippingIsBilling) {
      form.shippingAddress = form.billingAddress
    }
    this.setState({ form })

    const update: any = {
      ...profile,
      ...form
    }
    await updateProfile(update)
  }
}

export default Company
