import * as React from 'react'

import Modal from 'shared/Modal'
import LoadingIndicator from 'shared/LoadingIndicator'
import Scrollable from 'shared/Scrollable'
import Transition from 'shared/Transition'

import AdminMode from './AdminMode'
import AgentMode from './AgentMode'

import { isPlanAllowed } from 'shared/Billing/Queries'

import { getLoggedInUser } from 'utils'

import {
  Aside,
  Image,
  Col,
  Title,
  Container,
  Text,
} from './Styled'

import { ActionEnum, ModeEnum, TransitionType } from './Types'
import { User, UserRoleEnum } from 'store/Users/Types'

interface Props {
  onClose: () => void
}

interface State {
  isAllowed: boolean
  loading: boolean
  mode: ModeEnum
  transition: TransitionType
  user: User.LoggedInType
}

class WebsiteSolutionModal extends React.Component<Props, State> {
  public state = {
    isAllowed: false,
    loading: true,
    mode: ModeEnum.None,
    transition: {
      modal: true,
      paywall: false,
    } as TransitionType,
    user: {} as User.LoggedInType,
  }

  public componentDidMount = async () => {
    const { transition } = this.state
    let isAllowed = false
    let mode = ModeEnum.None

    try {
      await isPlanAllowed('idx')
      isAllowed = true
    } catch (e) {
      transition.paywall = true
      this.setState({
        isAllowed,
        loading: false
      })
      return
    }

    const user: User.LoggedInType = await getLoggedInUser({ fromCache: true }) || {} as User.LoggedInType
    switch (user.role) {
      case UserRoleEnum.Admin:
        mode = ModeEnum.Admin
        break
      case UserRoleEnum.Manager:
      case UserRoleEnum.Leader:
      case UserRoleEnum.Agent:
        mode = ModeEnum.Agent
        break
      default:
        mode = ModeEnum.None
        break
    }
    this.setState({
      isAllowed,
      loading: false,
      mode,
      transition,
      user,
    })
  }

  public render() {
    const {
      isAllowed,
      loading,
      mode,
      transition,
      user,
    } = this.state

    return (
      <Modal
        content={
          <React.Fragment>
            {loading && <LoadingIndicator />}
            <Container>
              <Aside>
                <Image max={190} src='https://onechimp.s3-us-west-2.amazonaws.com/app/graphics/websiteSolution.png' />
                <Title>Configure Websites</Title>
              </Aside>
              <Scrollable>
                <Col padding={1.8}>
                  {!isAllowed && (
                    <Transition on={transition.paywall}>
                      <Col justify="center">
                        <Text>This feature is only available with a paid subscription and an IDX Pass with at least one active MLS.</Text>
                        <Text>Please configure IDX for your account or contact your administrator.</Text>
                      </Col>
                    </Transition>
                  )}
                  {isAllowed && mode === ModeEnum.Admin && (
                    <AdminMode
                      user={user}
                      onAction={this.handleAction}
                    />
                  )}
                  {isAllowed && mode === ModeEnum.Agent && (
                    <AgentMode
                      user={user}
                      onAction={this.handleAction}
                    />
                  )}
                </Col>
              </Scrollable>
            </Container>
          </React.Fragment>
        }
        className={transition.modal ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
      />
    )
  }

  private handleAction = async (action: ActionEnum, data: any) => {
    switch (action) {
      case ActionEnum.Loading:
        this.setState({ loading: data })
        break
      default:
        break
    }
  }

  private closeSelf = () => {
    const { onClose } = this.props
    const { transition } = this.state
    transition.modal = false
    this.setState({ transition })
    window.setTimeout(() => {
      onClose()
    }, 300)
  }
}

export default WebsiteSolutionModal
