// Import Packages
import { Dropdown, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import CircleNext from 'design/icons/Events/circle_next.png'
import CirclePrev from 'design/icons/Events/circle_prev.png'

// Import Colors
import Colors from 'design/Colors'

const HeaderWrapper = styled.div`
  height: 35px;
  margin-top: 30px;
`

const DateWrapper = styled.div`
  font-weight: bold;
  font-size: 19px;
  margin-top: -8px;
  color: ${Colors.DarkBlue200};
`

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ToggleButton = styled.div`
  margin-right: 20px;
  margin-top: -15px;
  display: flex;
  align-items: center;

  .react-switch-bg {
    background: ${Colors.White520} !important;
  }
`

const ToggleTitle = styled.div`
  margin-right: 15px;
  color: ${Colors.DarkBlue200};
`

const DirectionContainer = styled.div`
  cursor: pointer;
  display: flex;
`

const BackButton = styled.div`
  background: url(${CirclePrev});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
  margin-top: -15px;
  width: 25px;
  height: 25px;
  align-self: center;
`

const NextButton = styled.div`
  background: url(${CircleNext});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
  margin-top: -15px;
  width: 25px;
  height: 25px;
  align-self: center;
`

const SettingItem = styled.div`
  font-size: 20px;
  margin-top: -12px;
  color: ${Colors.DarkBlue200};
`

const StyledDropdown = styled(Dropdown)`
  text-align: -webkit-center !important;
  width: auto;

  i.icon {
    display: none;
  }

  &.ui.dropdown .menu>.item {
    font-size: 12px;
    color: ${Colors.Black300};
  }

  &.ui.dropdown>.left.menu {
    left: -40px !important;
  }

  &.ui.pointing.dropdown>.menu {
    top: 20px;
  }

  &.ui.pointing.dropdown>.menu:after {
    left: 50.5%;
  }

  &.actionDropdown {
    text-align: rigth !important;
  }
`

const StyledGrid = styled(Grid)`
  &.ui.grid>.row>.today {
    display: flex;
  }

  &.ui.grid>.row>.today>.ui.basic.button {
    margin-top: -16px;
    margin-right: 25px;
  }
`

export {
  ActionWrapper,
  BackButton,
  DateWrapper,
  DirectionContainer,
  HeaderWrapper,
  NextButton,
  SettingItem,
  StyledDropdown,
  StyledGrid,
  ToggleButton,
  ToggleTitle
}
