import * as React from 'react'

import Scrollable from 'shared/Scrollable'
import Images from 'templates/Listing/Images'

import {
  EmailType,
  PhoneType,
  TransactionType,
} from 'app/Transactions/Listing/Types'

import {
  Address,
  Col,
  Container,
  Description,
  Detail,
  Disclaimer,
  Footer,
  Header,
  Link,
  Main,
  Name,
  Price,
  Profile,
  Sidebar,
} from './Styled'

interface Props {
  transaction: TransactionType
  colorMode: string
}

interface State {
  email: EmailType
  paragraphs: string[]
  phone: PhoneType
}

class Template1 extends React.Component<Props, State> {
  public static defaultProps = {
    colorMode: 'light'
  }

  public state = {
    email: {} as EmailType,
    phone: {} as PhoneType,
    paragraphs: [],
  }

  public componentDidMount = () => {
    const { transaction: {
      owner: { emails, phones },
      propertyId: { description },
    }} = this.props

    const email = emails.find((item:EmailType) => item.type === 'Primary') || {} as EmailType
    const phone = phones.find((item:PhoneType) => item.type === 'Work') || {} as PhoneType
    const paragraphs = this.getParagraphs(description || '')

    this.setState({
      email,
      paragraphs,
      phone,
    })
  }

  public render = () => {
    const {
      colorMode,
      transaction: {
        owner,
        propertyId: property,
        propertyId: { address, images, mlsId },
        tenant,
      },
    } = this.props

    const {
      email,
      phone,
      paragraphs,
    } = this.state

    const agent = (
      <React.Fragment>
        <Profile image={owner.profileImage} />
        <Name>{owner.firstName} {owner.lastName}</Name>
        {phone.value && <Link icon='phone' href={`tel:${phone.value}`}>{phone.value}</Link>}
        {email.value && <Link icon='email' href={`mailto:${email.value}`}>{email.value}</Link>}
      </React.Fragment>
    )

    const meta = (
      <React.Fragment>
        <Disclaimer>Listing {mlsId} provided by {tenant.companyName}<br/>via <Link href='https://realtypass.com'>RealtyPass</Link></Disclaimer>
      </React.Fragment>
    )

    return (
      <Container colorMode={colorMode}>
        <Sidebar>
          <Scrollable>
            <Col>
              <Header align='center'>
                <Price
                  displayType='text'
                  prefix='$'
                  thousandSeparator={true}
                  value={property.price}
                />
                <Address>
                  <span>{address.streetNumber} {address.streetName}</span>
                  <span>{address.city}, {address.state} {address.zipCode}</span>
                </Address>
                {paragraphs.map((item: string, index: number) => (
                  <Description key={index}>{item}</Description>
                ))}
                {(property.bedrooms || property.bathrooms || property.squareFt) && (
                  <Col padding={1} align='center'>
                    {property.bedrooms && <Detail><span>Bedrooms: </span><strong>{property.bedrooms}</strong></Detail>}
                    {property.bathrooms && <Detail><span>Bathroom: </span><strong>{property.bathrooms}</strong></Detail>}
                    {property.squareFt && <Detail><span>Square Ft: </span><strong>{property.squareFt}</strong></Detail>}
                  </Col>
                )}
              </Header>
              <Footer className='tablet'>
                {agent}
              </Footer>
              <Footer className='tablet'>
                {meta}
              </Footer>
            </Col>
          </Scrollable>
        </Sidebar>
        <Main>
          <Scrollable>
            <Col>
              <Images images={images} />
            </Col>
          </Scrollable>
        </Main>
        <Footer className='mobile'>
          {agent}
        </Footer>
        <Footer className='mobile'>
          {meta}
        </Footer>
      </Container>
    )
  }

  private getParagraphs = (text:string='') => {
    const punctuation = text.match(/[.!?]\s/gi) || []
    const paragraphs = text.split(/[.!?]\s|$/gi).reduce((arr: string[], item: string, index: number) => {
      const sentence = `${item}${punctuation.length > index ? punctuation[index] : ''}`
      if (Math.round(index/3) === index/3) {
        arr.push(sentence)
      } else {
        arr[arr.length - 1] += ` ${sentence}`
      }
      return arr
    }, [])
    return paragraphs
  }
}

export default Template1
