import Colors from 'design/Colors'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

const GraphColumn = styled(Segment)`
  &.ui.segment {
    width: 40%;
    padding-left: 0;
    padding-right: 0;
  }
`
const InputWrapper = styled(Segment)`
  align-items: center;
  &.segment {
    display: inline-flex;
    padding: 0px;
    width: 100%;
  }
`

const InputElement = styled(Input)`
  &.ui.input {
    width: 100%;
    background-color: transparent;
    border-radius: 20px;
  }

  &.ui.fluid.input > .label:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right:none;
    border-color: ${Colors.Grey50};
  }

  &.ui.fluid.input > input {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-color: ${Colors.Grey50};
    padding: 0px;
    border-style: solid;
    background-color: transparent;
    :focus{
      border-color: ${Colors.Grey50};
      border-left: none;
    }

    ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const InputLabel = styled(Label)`
  &&.ui.basic.label {
    border: none;
    width: 100%;
    background-color: transparent;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`

const TextBoxItem = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 30px;
`

const Dividers = styled(Divider)`
  &.ui.divider:not(.vertical):not(.horizontal) {
    border-top: 1px dashed ${Colors.Black905};
    border-bottom: 1px dashed ${Colors.Black905};
  }
`

export {
  Dividers,
  InputElement,
  InputLabel,
  InputWrapper,
  GraphColumn,
  TextBoxItem
}
