// Import Packages
import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Image, Popup } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import ReferralsList from './ReferralsList'

import { UserProfileType } from 'store/UserProfile/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  DetailContainer,
  SegmentGroup,
  ShareLinkContainer,
  ShareSection,
  SocialAction,
  SocialButton,
  SocialIcons,
  StatisticsContainer,
  StyledColumn,
  StyledGrid,
  StyledRow
} from './Styled'

// Font Awesome Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faFacebook, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faEnvelopeSquare, faPen } from '@fortawesome/free-solid-svg-icons'
library.add(fab, faPen, faCheck, faFacebook, faLinkedin, faTwitterSquare, faEnvelopeSquare)

const ReferralPopupStrings = Strings.userProfile.referralPopup

interface Props {
  handleClose: (toggle: boolean) => void
  userProfile: UserProfileType
}

interface State {
  shareLink: string
  animate: boolean
  isOpen: boolean
}

const AddAccTransactionStrings = Strings.userProfile.addAccTransaction

export default class ReferralPopup extends React.Component<Props, State> {
  public state = {
    animate: true,
    isOpen: false,
    shareLink: ''
  }

  public render() {
    const { userProfile } = this.props
    const { animate } = this.state
    return (
      <Modal
        content={
          <SegmentGroup horizontal={true}>
            <StatisticsContainer>
              <Image src={AddAccTransactionStrings.referral} size="small" />
              <StyledGrid columns={2}>
                <StyledRow>
                  <StyledColumn className="joined">{ReferralPopupStrings.joined.label}</StyledColumn>
                  <StyledColumn className="value joined">{userProfile.referrals.length}</StyledColumn>
                </StyledRow>
              </StyledGrid>
              <ShareSection>
                <Popup
                  trigger={
                    <div>
                      <CopyToClipboard text={userProfile.referralUrl}>
                        <ShareLinkContainer className={'heading'}>
                          {ReferralPopupStrings.statistics.copyAndShare}
                        </ShareLinkContainer>
                      </CopyToClipboard>
                    </div>
                  }
                  content="URL copied!"
                  size="mini"
                  position="top center"
                  inverted={true}
                  on="click"
                  open={this.state.isOpen}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                />
                <ShareLinkContainer className={'link'}>{userProfile.referralUrl}</ShareLinkContainer>
                <SocialIcons>
                  <SocialButton onClick={() => window.open('https://www.facebook.com')}>
                    <SocialAction icon={['fab', 'facebook']} className="fa-sm" />
                  </SocialButton>
                  <SocialButton onClick={() => window.open('http://linkedin.in')}>
                    <SocialAction icon={['fab', 'linkedin']} className="fa-sm" />
                  </SocialButton>
                  <SocialButton onClick={() => window.open('https://twitter.com')}>
                    <SocialAction icon={['fab', 'twitter-square']} className="fa-sm" />
                  </SocialButton>
                  <SocialButton onClick={() => window.open('https://gmail.com')}>
                    <SocialAction icon={['fas', 'envelope-square']} className="fa-sm" />
                  </SocialButton>
                </SocialIcons>
              </ShareSection>
            </StatisticsContainer>
            <DetailContainer>
              <ReferralsList data={userProfile.referrals} />
            </DetailContainer>
          </SegmentGroup>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={800}
      />
    )
  }

  private handleOpen = () => {
    this.setState({ isOpen: true })

    setTimeout(() => {
      this.setState({ isOpen: false })
    }, 2000)
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose(false)
    }, 300)
  }
}
