// Import Packages
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const LeftPanel = styled.div`
  width: 100%;
  height: auto;

  h3.ui.header {
    padding-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    color: ${Colors.DarkBlue205};
    text-transform: uppercase;
    font-size: 16px;
  }

  .ui.dividing.header {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }
`

const RightPanel = styled.div`
  background: ${Colors.Grey110};
  width: 40%;
  height: auto;

  .ui.grid {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    overflow: auto;
    height: 500px;
    ${scrollbars};
  }

  .ui.grid > [class*='two column'].row > .column {
    font-size: 12px;
    color: ${Colors.Black400};
  }

  .title {
    margin-top: 10px;
  }

  img.ui.image {
    width: 170px;
    height: 110px;
  }

  h3.ui.header {
    padding-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    color: ${Colors.DarkBlue205};
    text-transform: uppercase;
    font-size: 16px;
  }

  .ui.dividing.header {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }
`

const Content = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
`

const Configure = styled.div`
  .ui.button {
    font-size: 0.7rem;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    white-space: nowrap;
    width: 100px;
    margin-top: 10px;
  }
`

const Icons = styled.div`
  height: 50px;
  width: 50px;
  min-width: 45px;
  border-radius: 50%;
  background-color: ${Colors.DarkBlue215};
  align-items: center;
  justify-content: center;
  display: flex;
  border: 2px solid ${Colors.White556};
  margin-right: 15px;

  .svg-inline--fa {
    width: 20px;
    height: 20px;
    color: ${Colors.White556};
  }
`

const Text = styled.div`
  font-size: 11px;
  margin-right: 15px;
  color: ${Colors.DarkBlue205};
  text-align: justify;
  flex: 1;

  div:nth-child(1) {
    font-size: 13px;
    font-weight: 600;
    color: ${Colors.Black400};
    margin-bottom: 4px;
  }
`

const Wrapper = styled.div`
  overflow: auto;
  height: 500px;
  ${scrollbars};
`

const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown {
    position: absolute;
    top: 26px;
    right: 25px;
  }
`

const Images = styled.div`
  background: url(${(props: any) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  border: 1px solid ${Colors.DarkBlue200};
  border-radius: 50%;
  min-width: 50px;
  margin-right: 15px;
`

export {
  Configure,
  Container,
  Content,
  Icons,
  Images,
  LeftPanel,
  RightPanel,
  Text,
  Wrapper,
  StyledDropdown
}
