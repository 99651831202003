import { UserProfile } from './Types'

export const addCreditDebit = (state: UserProfile, newData: any): UserProfile['creditDebit'] => {
  const { creditDebit } = state
  const creditDebitData = creditDebit.slice()
  creditDebitData.push(newData)
  return creditDebitData
}

export const deleteCreditDebit = (state: UserProfile, cardId: string): UserProfile['creditDebit'] => {
  const { creditDebit } = state
  const creditDebitData = creditDebit.slice()
  const index = creditDebitData.findIndex((data: any) => {
    return data._id === cardId
  })

  creditDebitData.splice(index, 1)
  return creditDebitData
}

export const updateCreditDebit = (state: UserProfile, newData: any): UserProfile['creditDebit'] => {
  const { creditDebit } = state
  let listRecords = creditDebit.slice()
  listRecords = listRecords.map((list: any) => {
    if (list._id === newData._id) {
      return newData
    } else {
      return list
    }
  })
  return listRecords
}

export const profilePercents = (state: UserProfile, userProfile: any): UserProfile['profileProgress'] => {
  const requiredFields = ['roasterName', 'bio', 'website', 'userName', 'mlsId', 'licenseNo', 'licensedExpiration']
  let count = 0
  Object.keys(userProfile).forEach((key: string) => {
    if (requiredFields.includes(key) && userProfile[key] !== null && userProfile[key] !== '') {
      count = count + 1
    } else if (
      key === 'addresses' &&
      userProfile[key] !== null &&
      userProfile[key] !== '' &&
      userProfile[key].length >= 2
    ) {
      count = count + 1
    } else if (
      key === 'phones' &&
      userProfile[key] !== null &&
      userProfile[key] !== '' &&
      userProfile[key].length >= 2
    ) {
      count = count + 1
    }
  })
  const userPercent = (count / 9) * 100
  return userPercent
}
