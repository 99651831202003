import styled from 'styled-components'

export const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  &:hover {
    .rp-button-bubble {
      opacity: 0.8;
    }
  }

  .rp-button-bubble {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
`

export const Image = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto;
`
