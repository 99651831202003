import gql from 'graphql-tag'

export const GET_CHECKLIST_CATEGORIES = gql`
  query getChecklistCategories($where: CheckListCategoryFilter) {
    getCheckListCategories(where: $where) {
      _id
      docCheckLists {
        _id
        checkListSubType
        docTemplates {
          _id
        }
        mls {
          _id
          shortName
        }
      }
      isActive
      isLocked
      mls
      name
    }
  }
`
