import gql from 'graphql-tag'

export const GET_EVENTS = gql`
  query getEvents {
    getEvents {
      _id
      type
      name
      description
      startDate
      endDate
      startTime
      endTime
      createdAt
      updatedAt
      createdBy {
        _id
        firstName
        lastName
        profileImage
        phones {
          type
          value
        }
      }
      type
      sharedWith {
        _id
        firstName
        lastName
        profileImage
      }
      lead {
        _id
        firstName
        lastName
        email
      }
      transaction {
        _id
        transactionId
        owner {
          _id
          firstName
          lastName
          userName
        }
      }
    }
  }
`
