import client from 'queries/apollo'

import { UPLOAD_PROPERTY_IMAGES_VIA_IDX } from 'queries/graphql/Transactions/Mutation'

export const uploadImagesViaIdx = async (input: any) => {
  const response = await client.mutate({
    mutation: UPLOAD_PROPERTY_IMAGES_VIA_IDX,
    variables: { input }
  })

  return response.data.images
}
