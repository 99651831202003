import gql from 'graphql-tag'

export const GET_ICONS = gql`
  query getIcons {
    getIcons {
      _id
      key
      imageUrl
    }
  }
`
