// Import Packages
import * as React from 'react'

// Import Store Types, Actions and Reducers
import { MyReportDetails } from 'store/Reports/Types'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  StyledContent
} from './Styled'

// Font Awesome Icons
import {
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faTrashAlt, faPencilAlt])

interface ReportData {
  _id: string
  module: string
  name: string
}

interface Props {
  monthlySummary: (id: string, data: string) => void
  deleteReport: (reportId: string) => void
  updateMyReport: (data: any) => void
  generateMyReport: (reportId: string, pickFilterData: object) => void
  moduleId: string
  report: MyReportDetails[]
  title: string
  setModule: string
  generateMyReportId: string
}

interface State {
  activeIndex: string
}

class AccordionContent extends React.Component<Props, State> {
  public state = {
    activeIndex: ''
  }

  public render() {
    const { report, title, generateMyReportId } = this.props
    const { activeIndex } = this.state
    const reports = report && report.map((items: any, index: any) => (
      <StyledContent
        key={index}
        onClick={() => { this.handleClick(items._id, items) }}
        background={((activeIndex === items._id) || (generateMyReportId === items._id)) ? Colors.White525 : Colors.White1000}>
        <div>{items.name}</div>
        {
          title === 'My Reports' &&
          <div>
            <span onClick={() => this.updateMyReport(items)}><FontAwesomeIcon icon={['fal', 'pencil-alt']} /></span>
            <span onClick={() => { this.deleteMyReport(items._id) }}><FontAwesomeIcon icon={['fal', 'trash-alt']} /></span>
          </div>
        }
      </StyledContent>
    ))
    return (
      <div>
        {reports}
      </div>
    )
  }

  private handleClick = (index: string, items: any) => {
    const { monthlySummary, setModule, generateMyReport } = this.props
    if (setModule !== 'MY REPORTS') {
      monthlySummary(index, items.name)
    } else {
      generateMyReport(index, items.pickFilterData)
    }
    this.setState({ activeIndex: index })
  }

  private updateMyReport = (reportName: ReportData) => {
    const { updateMyReport } = this.props
    updateMyReport(reportName)
  }

  private deleteMyReport = (reportId: string) => {
    const { deleteReport } = this.props
    deleteReport(reportId)
  }
}

export default AccordionContent
