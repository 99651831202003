import * as React from 'react'
import { Input } from 'semantic-ui-react'
import Strings from 'utils/Strings'
import {
  DeleteItemContainer,
  RangeItemContainer,
  TextBoxItem,
  TextBoxLabel
} from '../Styled'

const RadioItem = ({
  max,
  min,
  index,
  percent,
  deleteItem,
  onChangeMax,
  onChangeMin,
  onChangePercent
}: {
  max: number | null
  min: number | null
  index: number
  percent: number | null
  deleteItem: () => void
  onChangeMax: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeMin: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangePercent: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return <RangeItemContainer key={index}>
    <TextBoxItem>
      <TextBoxLabel>{Strings.transactionFeeSettings.minimum}</TextBoxLabel>
      <Input type='number' icon='dollar' iconPosition='left' value={min} onChange={onChangeMin} />
    </TextBoxItem>
    <TextBoxItem>
      <TextBoxLabel>{Strings.transactionFeeSettings.maximum}</TextBoxLabel>
      <Input type='number' icon='dollar' iconPosition='left' value={max} onChange={onChangeMax} />
    </TextBoxItem>
    <TextBoxItem>
      <TextBoxLabel>{Strings.transactionFeeSettings.percent}</TextBoxLabel>
      <Input type='number' icon='dollar' iconPosition='left' value={percent} onChange={onChangePercent} />
    </TextBoxItem>
    <DeleteItemContainer onClick={deleteItem}>{Strings.transactionFeeSettings.delete}</DeleteItemContainer>
  </RangeItemContainer>
}

export default RadioItem
