// Import Packages
import * as React from 'react'

// Import Images and Icons
import IconAscending from 'design/icons/styledGrid/icon_asc.png'
import IconDescending from 'design/icons/styledGrid/icon_desc.png'

// Import Styled Components
import {
  ImageWrapper
} from './Styled'

// Import Colors
import Colors from 'design/Colors'

export const styleConfig = {
  classNames: {
  },
  icons: {
    TableHeadingCell: {
      sortAscendingIcon: <ImageWrapper backgroundImage={IconAscending} />,
      sortDescendingIcon: <ImageWrapper backgroundImage={IconDescending} />,
    },
  },
  styles: {
    Cell: {
      color: Colors.Black300,
      fontSize: 13,
      paddingLeft: 30,
      paddingRight: 30,
    },
    Row: {
      backgroundColor: Colors.White800,
      borderBottomColor: Colors.Grey700,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      height: 45,
      width: '100%'
    },
    Table: {
      borderCollapse: 'collapse',
      fontFamily: 'Lato',
      width: '100%'
    },
    TableHeading: {
      backgroundColor: Colors.White900,
      borderBottomColor: Colors.Grey700,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      height: 40,
    },
    TableHeadingCell: {
      color: Colors.Black500,
      fontSize: 16.5,
      fontWeight: 400,
      height: 50,
      paddingLeft: 40,
      paddingRight: 40,
      textAlign: 'left',
    },
  }
}


export const NewLayout = ({ Table, Pagination }: any) => (
  <div>
    <Table />
  </div>
)


