// Import Packages
import * as React from 'react'

// Import Components
import Modal from 'shared/Modal'
import Invitee from './Invitee'

import { ShareWith, TransactionList } from 'store/Transactions/Types'

// Font Awesome Icons
import {
  faEnvelope,
  faUserEdit,
} from '@fortawesome/pro-light-svg-icons'
import { editInvitee } from 'app/Transactions/Dashboard/TransactionMutations'
import { getTransactionDetails } from 'app/Transactions/Dashboard/TransactionQueries'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faUserEdit, faEnvelope])

interface Props {
  closeModal: () => void
  getShareWithUser: (data: ShareWith) => void
  getTransactionDetail: (data: TransactionList) => void
  shareUser: ShareWith[]
  transactionId: string
}

interface State {
  activeIndex: number
  animate: boolean
}

class InviteeModal extends React.Component<Props, State> {

  public state = {
    activeIndex: 0,
    animate: true
  }

  public render() {
    const { animate } = this.state
    return (
      <Modal content={
        <Invitee editUser={this.props.shareUser} editInvitee={this.editInvitee} closeModal={this.closeModal} />
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={650} />
    )
  }

  private editInvitee = async (user: any) => {
    const { getShareWithUser, getTransactionDetail, transactionId } = this.props
    await editInvitee(user)
    const details = await getTransactionDetails(transactionId)
    getTransactionDetail(details[0])
    getShareWithUser(details[0].shareWith)
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default InviteeModal
