// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'

// Import Images And Icons
import { cancel, check } from 'design/icons/dashboard'

// Import Utils
import { Strings } from 'utils'

// Import Queries And Mutation
import { deleteFeedNotification, updateFeedNotification } from '../NotificationsMutations'
import { getFeedNotifications } from '../NotificationsQueries'
import { FeedNotifications } from './Types'

// Import Styled Components
import {
  Container,
  Date,
  Description,
  Discard,
  Icons,
  NoAlerts,
  Profile,
  StyledNotes,
  StyledPopup,
  Title
} from './Styled'

interface Props {
  showTeamDetail: (id: string) => void
  unReadNotificationCounterUpdate: () => void
  closeModal: () => void
}

interface State {
  id: string
  note: string
  showEditButton: boolean
  showConfirmationModal: boolean
  feedNotifications: FeedNotifications[]
}

class Feed extends React.Component<Props, State> {
  public state = {
    feedNotifications: [],
    id: '',
    note: '',
    showConfirmationModal: false,
    showEditButton: false
  }

  public async componentDidMount() {
    const response = await getFeedNotifications()
    this.setState({ feedNotifications: response })
  }

  public render() {
    const { closeModal, showTeamDetail } = this.props
    const { feedNotifications } = this.state
    const notifications = feedNotifications.map((items: any, index: any) => {
      return (
        <React.Fragment key={index}>
          <Container>
            <div className="title">
              <Profile background={items.senderId.profileImage} />
              <div className='icon'>
                <StyledPopup
                  trigger={
                    items.isViewed ? (
                      <Icons className="feed-check" src={check} />
                    ) : (
                        <Icons className="feed-check" src={cancel} onClick={() => this.messageRead(items._id)} />
                      )
                  }
                  content={items.isViewed ? 'Message read' : `Mark as read`}
                  position="top center"
                />
              </div>
            </div>
            <div className='description'>
              <div className='date'>
                {items.category === 'Team' ? (
                  <Title style={{ cursor: 'pointer' }}>
                    {items.pageRoute !== null ? (
                      <Link
                        to={items.pageRoute}
                        onClick={() => {
                          closeModal()
                          showTeamDetail(items.refData._id)
                          this.messageRead(items._id)
                        }}
                        style={{ color: '#222222' }}
                      >
                        {items.title}
                      </Link>
                    ) : (
                        items.title
                      )}
                  </Title>
                ) : (
                    <Title style={{ cursor: 'pointer' }}>
                      {items.pageRoute !== null ? (
                        <Link
                          to={items.pageRoute}
                          onClick={() => {
                            closeModal()
                            this.messageRead(items._id)
                          }}
                          style={{ color: '#222222' }}
                        >
                          {items.title}
                        </Link>
                      ) : (
                          items.title
                        )}
                    </Title>
                  )}
                <Date>{moment(items.createdAt).fromNow()}</Date>
              </div>
              <Description
                onClick={() => {
                  this.toggleShowHide(`my${index}`, items._id, items.isViewed)
                }}
                space={!items.readMessage ? 'nowrap' : 'normal'}
              >
                <div id={`my${index}`}>{items.content}</div>
                <Icon name="caret down" />
              </Description>
            </div>
          </Container>
        </React.Fragment>
      )
    })
    return (
      <div>
        <StyledNotes>
          {notifications.length !== 0 ? (
            notifications
          ) : (
              <NoAlerts>
                <div>{Strings.settings.notification.all}</div>
              </NoAlerts>
            )}
        </StyledNotes>
        {notifications.length !== 0 && (
          <Discard>
            <Button onClick={this.discardFeedMessage}>
              <Icon name="check" /> {Strings.settings.notification.discard}
            </Button>
          </Discard>
        )}
      </div>
    )
  }

  private toggleShowHide = (index: string, id: string, isViewed: boolean) => {
    const x = document.getElementById(index)
    if (x) {
      if (x.style.whiteSpace === 'normal') {
        x.style.whiteSpace = 'nowrap'
      } else {
        x.style.whiteSpace = 'normal'
      }
    }
    if (!isViewed) {
      this.readFeedMessage(id)
    }
  }

  private messageRead = (id: string) => {
    this.readFeedMessage(id)
  }

  private discardFeedMessage = async () => {
    const { unReadNotificationCounterUpdate } = this.props
    const response = await deleteFeedNotification()
    unReadNotificationCounterUpdate()
    this.setState({ feedNotifications: response })
  }

  private readFeedMessage = async (id: string) => {
    const { unReadNotificationCounterUpdate } = this.props
    const newData = {
      id: id,
      isViewed: true
    }
    const response = await updateFeedNotification(newData)
    this.setState({ feedNotifications: response })
    unReadNotificationCounterUpdate()
  }
}

export default Feed
