import { Button, Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { SecondaryFontName } from 'design/Fonts'
import { children, text, x, y } from 'design/Styled'

export const Container = styled.div`
  ${x};
  ${text};
  ${children};

  .rp-confirmation-method-toggle {
    margin-bottom: 0.5em;
  }
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Col = styled.section`
  ${y};
  height: 100%;
`

export const StyledForm = styled(Form)`
  ${y};
  &.ui.form {
    max-width: 700px;
    margin: 0 auto;
  }
  ${Row} {
    margin-bottom: 0.8em;
  }
`

export const StyledInput = styled(Input)`
  ${x};
`

export const StyledButton = styled(Button)``

export const Image = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

export const Header = styled.div`
  color: ${Colors.TextPrimary};
  font-size: 28px;
  font-weight: 300;
  margin: 40px 0 15px;
  line-height: 32px;
  text-align: center;
  font-family: ${SecondaryFontName};
`

export const Verification = styled.div`
  color: ${Colors.TextPrimary};
  font-weight: 300;
  margin: 10px 0 15px;
  font-family: ${SecondaryFontName};
  display: flex;
  justify-content: center;
`

export const HeaderLarge = styled.div`
  color: ${Colors.TextPrimary};
  font-size: 43px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 15px 0 15px;
  font-family: ${SecondaryFontName};
`

export const HeaderGray = styled.div`
  color: ${Colors.TextSecondary};
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
  font-family: ${SecondaryFontName};
`

export const HeaderTop = styled.div`
  color: ${Colors.TextPrimary};
  font-size: 27px;
  font-weight: 400;
  font-family: ${SecondaryFontName};
`

export const HeaderBold = styled.span`
  font-weight: 600;
`

export const Feature = styled.div`
  font-size: 17px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  color: ${Colors.TextPrimary};
  &:before {
    content: '+';
    color: ${Colors.TextHighlight};
    margin-right: 7px;
  }
  font-family: ${SecondaryFontName};
`

export const Return = styled.a`
  padding-left: 5px;
  cursor: pointer;
`
