import * as React from 'react'

import { Routes } from 'utils'
import ClickToUpload from 'design/images/dashboard/clickToUpload.jpg'

import {
  Avatar,
  Container,
  Span,
  StyledLink,
} from './Styled'
import { RowType } from '../Types'

interface Props {
  row: RowType
}

class Address extends React.Component<Props, {}> {
  public render() {
    const { row: { rowData: {
      _id,
      propertyId: {
        address: {
          city,
          state,
          streetName,
          streetNumber,
          zipCode,
        },
        images,
      }
    }}} = this.props

    return (
      <Container>
        <Avatar background={images.length > 0 ? images[0].url : ClickToUpload} />
        <StyledLink to={Routes.transactions.root + `/${_id}` + Routes.transactions.documents.path}>
          <Span>{streetNumber || ''} {streetName || ''}</Span>
          <Span>{city || ''}, {state || ''} {zipCode || ''}</Span>
        </StyledLink>
      </Container>
    )
  }
}

export default Address
