// Import Packages
import {
  Button,
  Checkbox,
  Form,
  Input,
  Search,
} from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import {
  children,
  primaryStyledButton,
  styledToggle,
  x,
  y,
} from 'design/Styled'

interface Props {
  isToggled: boolean
}

export const Container = styled.div`
  ${x};
  height: 100%;
`

export const LeftPanel = styled.div`
  ${y};
  width: 30%;
  height: 100%;
  background-color: ${Colors.EmphasisContainer};
`

export const MiddlePanel = styled.div`
  ${y};
  height: 100%;
  width: 60%;
  border-top: 0;
  border-bottom: 0;
`

export const RightPanel = styled.div`
  width: 30%;
`

export const Header = styled.div`
  ${x};
  ${children};
  font-size: 12px;
  font-weight: bold;
  padding: 12px;
  padding-left: 30px;
  color: ${Colors.Text};
  background-color: ${Colors.EmphasisContainer};
`

export const Image = styled.img`
  display: block;
  margin: 1em auto;
  width: 100%;
  max-width: 200px;
`

export const ToggleItem = styled.div`
  color: ${(props: Props) => props.isToggled ? Colors.Grey50 : Colors.Black550};
`

export const Toggle = styled(Checkbox)`
  ${styledToggle};
`

export const StyledForm = styled(Form)`
  ${y};

  &.ui.form {
    font-size: 0.875rem;
  }

  .ap-input-icon {
    display: none;
  }

  .ui.search,
  .ui.input,
  .ui.input>input,
  .ap-input {
    width: 100%;
    max-height: 30px;
    font-size: 12px;
  }
`

export const Col = styled.div`
  ${y};
  margin-bottom: 1em;
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Label = styled.label`
  margin-bottom: 0.5em;
  color: ${Colors.Text};
  font-weight: bold;
`

export const StyledInput = styled(Input)``

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
`

export const TimeLineContainer = styled.div`
  height: 400px;
  overflow: auto;
  color: ${Colors.Black550};
  padding-left: 18px;

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
      background: ${Colors.transparent};
  }

  &.notes-styling section > div:nth-child(1) {
    background: ${Colors.Grey100} !important;
    left: 8px !important;
    width: 1px !important;
  }

  &.notes-styling section > div:nth-child(2) {
    padding-left: 20px !important;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) {
    width: 15px !important;
    height: 16px !important;
    background: ${Colors.White1000} !important;
    border: 1px solid ${Colors.Grey100} !important;
    justify-content: center !important;
    margin-top: 5px;
  }

  &.notes-styling section > div:nth-child(2) > div:nth-child(1) > span > i{
    font-size: 8px;
    margin-top: -1px;
    margin-left: 3px;
    color: ${Colors.White420};
  }
`

export const StyledSearch = styled(Search)`
  .ui.icon.input>i.icon {
    display: none;
  }
`

export const Optional = styled.em`
  color: ${Colors.TextLight};
  font-size: 10px;
  font-style: italic;
  margin-left: 5px;
`
