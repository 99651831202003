// Import Graphql Queries
import {
  EXPORT_LEADS,
  GET_LEAD_DETAILS,
  GET_LEAD_NOTE,
  GET_LEADS,
  GET_LEADS_ON_STATE
} from 'queries/graphql/Lead/Queries'
import { GET_ICONS } from 'queries/graphql/Queries'
import { GET_TASK, GET_TASK_ACTIVITY } from 'queries/graphql/Tasks/Queries'
import { GET_REASSIGN_OFFICE_USERS } from 'queries/graphql/Users/Queries'
import { GET_LEAD_BOARD_LEADS, GET_LEAD_BOARDS, GET_SORTED_LEAD_BOARDS } from 'queries/graphql/LeadBoard/Queries'
import { GET_STATES } from 'queries/graphql/States/Queries'

// Import Components
import client from 'queries/apollo'
import { Checked } from './CustomComponents'
import { toggle } from './Leads'

import { FiltersData, LeadPassFilter, UserType } from 'store/Leads/Types'

// Import Utils
import { GET_TRANSACTION_WORKFLOW } from 'queries/graphql/Transactions/Queries'
import { getLoggedInUser } from 'utils'

export const getLeadBoards = async (
  filter: FiltersData | undefined,
  userId?: string,
  userPassFilter?: LeadPassFilter,
  id?: string,
  singleSkip?: number
) => {
  const leadBoards = await client.query({
    fetchPolicy: 'network-only',
    query: GET_LEAD_BOARDS,
    variables: {
      filter,
      id,
      orderField: 'boardOrder',
      singleSkip,
      sortOrder: 1,
      userId,
      userPassFilter
    }
  })

  return leadBoards.data.getLeadBoards
}

export const getStateLeadBoards = async (
  id: string,
  filter: FiltersData,
  userId?: string,
  userPassFilter?: LeadPassFilter,
  boardId?: string,
  singleSkip?: number
) => {
  const leadBoards = await client.query({
    fetchPolicy: 'network-only',
    query: GET_LEAD_BOARD_LEADS,
    variables: {
      boardId,
      filter,
      id,
      orderField: 'boardOrder',
      singleSkip,
      sortOrder: 1,
      userId,
      userPassFilter
    }
  })

  return leadBoards.data.getLeadBoards
}

export const getLead = async (filter: FiltersData, userId?: string) => {
  const lead = await client.query({
    fetchPolicy: 'network-only',
    query: GET_LEADS,
    variables: {
      filter,
      userId
    }
  })

  return lead.data.getLeads
}

export const getStateLead = async (state: string, filter: FiltersData, userId?: string) => {
  const lead = await client.query({
    fetchPolicy: 'network-only',
    query: GET_LEADS_ON_STATE,
    variables: {
      filter,
      isActive: true,
      state,
      userId
    }
  })

  return lead.data.getLeads
}

export const reset = async (loggedInUser: UserType) => {
  if (toggle) {
    await client.query({
      query: GET_LEAD_BOARDS,
      variables: {
        orderField: 'boardOrder',
        sortOrder: 1
      }
    })
    await client.query({
      query: GET_LEADS
    })
  } else {
    await client.query({
      query: GET_LEAD_BOARDS,
      variables: {
        orderField: 'boardOrder',
        sortOrder: 1,
        userId: loggedInUser._id
      }
    })
    await client.query({
      query: GET_LEADS,
      variables: { userId: loggedInUser._id }
    })
  }
}

export const getOptions = async () => {
  const states = await client.query({
    query: GET_STATES
  })
  const options = states.data.getStates.reduce((accumulator: any, currentValue: any) => {
    currentValue.name && accumulator.push({
      key: currentValue._id,
      text: currentValue.name,
      value: `${currentValue._id}, ${currentValue.name}`
    })
    return accumulator
  }, [])
  options.splice(0, 0, {
    key: '0',
    text: 'Select State',
    value: 'Choose'
  })
  if (options.length > 2) {
    options.push({
      key: 'All',
      text: 'All',
      value: 'All'
    })
  }
  return options
}

export const getStates = async () => {
  const states = await client.query({
    query: GET_STATES
  })
  return states.data.getStates
}

export const ChangeToggleCheckBox = async (checked: boolean) => {
  const user: UserType = await getLoggedInUser({ fromCache: true })
  if (toggle) {
    const data: any = client.readQuery({
      query: GET_LEADS
    })
    const newData = data.getLeads.map((list: any) => {
      return {
        ...list,
        checked: !checked
      }
    })
    client.writeQuery({
      data: { getLeads: newData },
      query: GET_LEADS,
      variables: { userId: user._id }
    })
    Checked(checked)
  } else {
    const data: any = client.readQuery({
      query: GET_LEADS,
      variables: { userId: user._id }
    })
    const newData = data.getLeads.map((list: any) => {
      return {
        ...list,
        checked: !checked
      }
    })
    client.writeQuery({
      data: { getLeads: newData },
      query: GET_LEADS,
      variables: { userId: user._id }
    })
    Checked(checked)
  }
}

export const ToggleSingleCheckBox = async (id: string) => {
  const user: UserType = await getLoggedInUser({ fromCache: true })
  if (toggle) {
    const data: any = client.readQuery({
      query: GET_LEADS
    })
    const newData = data.getLeads.map((list: any) => {
      return {
        ...list,
        checked: list._id === id ? !list.checked : list.checked
      }
    })
    client.writeQuery({
      data: { getLeads: newData },
      query: GET_LEADS,
      variables: { userId: user._id }
    })
  } else {
    const data: any = client.readQuery({
      query: GET_LEADS,
      variables: { userId: user._id }
    })
    const newData = data.getLeads.map((list: any) => {
      return {
        ...list,
        checked: list._id === id ? !list.checked : list.checked
      }
    })
    client.writeQuery({
      data: { getLeads: newData },
      query: GET_LEADS,
      variables: { userId: user._id }
    })
  }
}

export const getReassignUsers = async () => {
  const loggedInUser = await getLoggedInUser()
  const data = await client.query({
    query: GET_REASSIGN_OFFICE_USERS
  })

  if (loggedInUser.role === 'ADMIN' || loggedInUser.role === 'MANAGER') {
    return data.data.getOfficeUsers
  }

  return data.data.getOfficeUsers.filter((user: any) => {
    return user._id !== loggedInUser._id
  })
}

export const exportUsers = async (groupid: string, userFilterState: string) => {
  const user: any = await getLoggedInUser({ fromCache: true })
  let url: any = {}
  if (toggle) {
    url = await client.query({
      query: EXPORT_LEADS,
      variables: {
        stateBoard:
          (userFilterState !== 'All' && userFilterState !== '') || user.role === 'MANAGER' ? groupid : undefined,
        tenantBoard:
          (userFilterState === 'All' || userFilterState === '') && user.role === 'ADMIN' ? groupid : undefined
      }
    })
  } else {
    url = await client.query({
      query: EXPORT_LEADS,
      variables: {
        board: groupid
      }
    })
  }
  if (url && url.data) {
    setTimeout(() => {
      const response = {
        file: url.data.exportLeads
      }
      return (window.location.href = response.file)
    }, 100)
  }
}

export const sortLead = async (boardId: string, sortOrder: number, loggedInUser: UserType, toggleStatus: boolean) => {
  let result
  if (toggleStatus === true) {
    result = await client.query({
      fetchPolicy: 'network-only',
      query: GET_SORTED_LEAD_BOARDS,
      variables: {
        id: boardId,
        leadOrderField: 'firstName',
        orderField: 'boardOrder',
        sortLeadOrder: sortOrder,
        sortOrder: 1
      }
    })
  } else {
    result = await client.query({
      fetchPolicy: 'network-only',
      query: GET_SORTED_LEAD_BOARDS,
      variables: {
        id: boardId,
        leadOrderField: 'firstName',
        orderField: 'boardOrder',
        sortLeadOrder: sortOrder,
        sortOrder: 1,
        userId: loggedInUser._id
      }
    })
  }
  return result
}

export const getLeadDetails = async (leadId: string) => {
  const result = await client.query({
    query: GET_LEAD_DETAILS,
    variables: { leadId }
  })
  return result.data.getLeads[0]
}

export const getIcons = async () => {
  const data = await client.query({
    query: GET_ICONS
  })

  const icons = data.data.getIcons.filter((icon: any) => {
    return icon.key !== 'APPERANCE10'
  })

  return icons
}

export const getLeadTask = async (leadId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK,
    variables: { leadId }
  })

  return response.data.getTasks
}

export const getLeadNotes = async (leadId: string) => {
  const response = await client.query({
    query: GET_LEAD_NOTE,
    variables: { leadId }
  })

  return response.data.getNotes
}

export const getLeadsWorkflow = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_WORKFLOW,
    variables: { categoryName: 'Leads' }
  })

  return response.data.getWorkflowCategories[0]
}

export const getLeadActivities = async (leadId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_ACTIVITY,
    variables: { leadId }
  })

  return response.data.getActivities
}
