import gql from 'graphql-tag'

export const officeFragment = gql`
  fragment OfficeFragment on Office {
    _id
    branchName
    city
    country
    createdAt
    email
    mlsId
    officeOrder
    phone
    state
    status
    street
    zipCode
  }
`

export const officeMlsFragment = gql`
  fragment OfficeMlsFragment on Mls {
    _id
  }
`

export const officeUserFragment = gql`
  fragment OfficeUserFragment on User {
    _id
    firstName
    hasTeam
    isPrimaryManager
    lastName
    profileImage
    role
    status
    userName
  }
`

export const officeUserAddressFragment = gql`
  fragment OfficeUserAddressFragment on Address {
    city
    country
    state
    streetName
    streetNumber
    zipCode
  }
`
