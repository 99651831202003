import client from 'queries/apollo'

import { GET_USER_PROFILE } from 'queries/graphql/UserProfile/Queries'
import { GENERIC_GET_USERS } from 'queries/graphql/Users/Queries'

export const genericGetUsers = async (
  where: any = {},
  sorting: any = {},
  skip: number = 0,
  limit: number = 0,
  filter: any = {}
) => {
  const query = await client.query({
    query: GENERIC_GET_USERS,
    variables: {
      filter,
      limit,
      skip,
      sorting,
      where
    }
  })

  let result: any = null
  if (query.data && query.data.genericGetUsers) {
    result = query.data.genericGetUsers
  }
  return result
}

export const getUserById = async (id: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_PROFILE,
    variables: { id, isActive: true }
  })

  return response.data.getUsers[0]
}
