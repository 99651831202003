// Import Utils
import { generateID } from 'utils'

export const modulesMockData = [
  {
    _id: generateID(),
    icon: 'ballot-check',
    title: 'MY REPORTS',
  },
  {
    _id: generateID(),
    icon: 'folders',
    title: 'TRANSACTIONS',
  },
  {
    _id: generateID(),
    icon: 'handshake',
    title: 'LEADS',
  },
  {
    _id: generateID(),
    icon: 'whatsapp',
    title: 'CONTACTS',
  },
  {
    _id: generateID(),
    icon: 'clone',
    title: 'TASKS',
  },
  {
    _id: generateID(),
    icon: 'newspaper',
    title: '1099 REPORT',
  },
  {
    _id: generateID(),
    icon: 'user-circle',
    title: 'USERS',
  },
  {
    _id: generateID(),
    icon: 'users',
    title: 'TEAMS',
  },
  {
    _id: generateID(),
    icon: 'building',
    title: 'OFFICES',
  }
]

export const modulesMockDataAgent = [
  {
    _id: generateID(),
    icon: 'ballot-check',
    title: 'MY REPORTS',
  },
  {
    _id: generateID(),
    icon: 'folders',
    title: 'TRANSACTIONS',
  },
  {
    _id: generateID(),
    icon: 'handshake',
    title: 'LEADS',
  },
  {
    _id: generateID(),
    icon: 'whatsapp',
    title: 'CONTACTS',
  },
  {
    _id: generateID(),
    icon: 'clone',
    title: 'TASKS',
  },
  {
    _id: generateID(),
    icon: 'newspaper',
    title: '1099 REPORT',
  },
]
