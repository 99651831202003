import client from 'queries/apollo'

import { GET_OR_CREATE_CITY } from 'queries/graphql/City/Mutation'
import { GET_OR_CREATE_STATE } from 'queries/graphql/States/Mutation'

import { CityStateParamType } from './Types'

export const getOrCreateCityState = async (params: CityStateParamType) => {
  const state = await client.mutate({
    mutation: GET_OR_CREATE_STATE,
    variables: { input: { name: params.state } }
  })

  const stateId = state.data.createState._id

  const city = await client.mutate({
    mutation: GET_OR_CREATE_CITY,
    variables: {
      input: {
        name: params.city,
        state: stateId
      }
    }
  })

  return {
    city: city.data.createCity,
    state: state.data.createState
  }
}
