export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`firstName`]) {
    formIsValid = false
    errors[`firstName`] = true
  }

  if (!fields[`lastName`]) {
    formIsValid = false
    errors[`lastName`] = true
  }

  if (!fields[`userName`]) {
    formIsValid = false
    errors[`userName`] = true
  }

  return { errors, formIsValid }
}
