import { ActionCreator } from 'redux'
import Actions from 'store/Tasks/ActionsTypes'
import {
  Filter,
  GetTaskActivities,
  GetTaskDetails,
  TaskAddNewBoard,
  TaskAddNewCard,
  TaskAddNewNotes,
  TaskAllTaskStatus,
  TaskChangeStatus,
  TaskDeleteCard,
  TaskDeleteNotes,
  TaskDeleteRecord,
  TaskDetails,
  TaskEditNotes,
  TaskEditRecord,
  TaskFilter,
  TaskGetBoardData,
  TaskGetListData,
  TaskGetNotesData,
  TaskImportData,
  TaskItemsDetails,
  TaskNotes,
  TaskPassFilter,
  TaskResetCardData,
  TaskRestoreCard,
  TaskSearchData,
  TaskSerachLoader,
  TaskSetNewBoardName,
  TaskSetNewDescription,
  TaskSetNewIcon,
  TaskUpdateCard,
  UserPassTaskFilter
} from './Types'

export const addNewBoard: ActionCreator<TaskAddNewBoard> = (
  index: string,
  newData: TaskDetails
) => ({
  payload: {
    index,
    newData
  },
  type: Actions.TaskAddNewBoard
})

export const getBoardData: ActionCreator<TaskGetBoardData> = (
  data: TaskDetails[]
) => ({
  payload: {
    data
  },
  type: Actions.TaskGetBoardData
})

export const getListData: ActionCreator<TaskGetListData> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.TaskGetListData
})

export const addNewCard: ActionCreator<TaskAddNewCard> = (
  id: string,
  index: string,
  newData: TaskItemsDetails
) => {
  return {
    payload: {
      id,
      index,
      newData
    },
    type: Actions.TaskAddNewCard
  }
}

export const setNewIcon: ActionCreator<TaskSetNewIcon> = (
  id: string,
  icon: string
) => ({
  payload: {
    icon,
    id
  },
  type: Actions.TaskSetNewIcon
})

export const setNewDescription: ActionCreator<TaskSetNewDescription> = (
  id: string,
  description: string
) => ({
  payload: {
    description,
    id
  },
  type: Actions.TaskSetNewDescription
})

export const setNewBoardName: ActionCreator<TaskSetNewBoardName> = (
  id: string,
  boardName: string
) => ({
  payload: {
    boardName,
    id
  },
  type: Actions.TaskSetNewBoardName
})

export const deleteCard: ActionCreator<TaskDeleteCard> = (
  boardId: string,
  cardId: string
) => ({
  payload: {
    boardId,
    cardId
  },
  type: Actions.TaskDeleteCard
})

export const deleteRecord: ActionCreator<TaskDeleteRecord> = (id: string) => ({
  payload: {
    id
  },
  type: Actions.TaskDeleteRecord
})

export const restoreCard: ActionCreator<TaskRestoreCard> = () => ({
  type: Actions.TaskRestoreCard
})

export const resetCardData: ActionCreator<TaskResetCardData> = () => ({
  type: Actions.TaskResetCardData
})

export const changeStatus: ActionCreator<TaskChangeStatus> = (
  boardId: string,
  cardId: string,
  status: string
) => ({
  payload: {
    boardId,
    cardId,
    status
  },
  type: Actions.TaskChangestatus
})

export const addNewNotes: ActionCreator<TaskAddNewNotes> = (
  newData: TaskNotes,
  cardId: string = ''
) => ({
  payload: {
    cardId,
    newData
  },
  type: Actions.TaskAddNewNotes
})

export const getNotesData: ActionCreator<TaskGetNotesData> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.TaskGetNotesData
})

export const editNotes: ActionCreator<TaskEditNotes> = (
  id: string,
  newData: TaskNotes[]
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.TaskEditNotes
})

export const deleteNotes: ActionCreator<TaskDeleteNotes> = (id: string) => ({
  payload: {
    id
  },
  type: Actions.TaskDeleteNotes
})

export const getTaskDetails: ActionCreator<GetTaskDetails> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.GetTaskDetails
})

export const getTaskActivities: ActionCreator<GetTaskActivities> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.GetTaskActivities
})

export const editRecord: ActionCreator<TaskEditRecord> = (newData: any) => ({
  payload: {
    newData
  },
  type: Actions.TaskEditRecord
})

export const importData: ActionCreator<TaskImportData> = (
  boardId: string,
  importedData: any
) => ({
  payload: {
    boardId,
    importedData
  },
  type: Actions.TaskImportData
})

export const filter: ActionCreator<TaskFilter> = (data: Filter) => ({
  payload: {
    data
  },
  type: Actions.TaskFilter
})

export const searchLoader: ActionCreator<TaskSerachLoader> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.TaskSearchLoader
})

export const toggleStatus: ActionCreator<TaskAllTaskStatus> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.TaskToggleStatus
})

export const searchDataFilter: ActionCreator<TaskSearchData> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.TaskSearchFilter
})

export const updateCard: ActionCreator<TaskUpdateCard> = (
  task: any,
  boardId: string = ''
) => ({
  payload: {
    boardId,
    task
  },
  type: Actions.TaskUpdateCard
})

export const showUserPassTaskFilterData: ActionCreator<UserPassTaskFilter> = (
  filters: TaskPassFilter
) => ({
  payload: {
    filters
  },
  type: Actions.userPassTaskFilter
})
