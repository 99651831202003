import * as React from 'react'
import InputMask from 'react-input-mask'
import { Form, Input } from 'semantic-ui-react'

import AddressInput from 'shared/AddressInput'
import StateDropdown from 'shared/StateDropdown'
import SensitiveInput from 'shared/SensitiveInput'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'
import { FormType } from '../Types'

interface Props {
  form: FormType
  onChange: (e:any, { name: string, value: any }:any) => void
}
class ExternalForm extends React.Component<Props, {}> {
  public render() {
    const {
      form,
      form: { errors },
      onChange,
    } = this.props

    return (
      <div>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            name='brokerageName'
            value={form.brokerageName}
            error={!!errors.brokerageName}
            label='Brokerage Name'
            placeholder='Brokerage Name'
            onChange={onChange}
          />
          <div className='field'>
            <label>Brokerage Tax ID</label>
            <SensitiveInput
              name='brokerageTaxID'
              placeholder='Brokerage Tax ID'
              onChange={onChange}
              value={form.brokerageTaxID}
              error={!!errors.brokerageTaxID}
              maxLength={9}
            />
          </div>
        </Form.Group>
        <Form.Field
          className='amount'
          control={Input}
          error={!!errors.phone}
          label='Phone'
        >
          <InputMask
            mask='(999) 999-9999'
            name='phone'
            placeholder='(xxx) xxx-xxxx'
            onChange={this.onChangePhone}
            value={form.phone}
          />
        </Form.Field>
        <Form.Field
          address={form.address}
          control={AddressInput}
          error={!!errors.street}
          label='Street Address'
          name='streetAddress'
          onChange={this.handleAddressChange}
          placeholder='Street Address'
          size='mini'
        />
        <Form.Group widths='equal'>
          <Form.Field
            address={form.address}
            control={AddressInput}
            error={!!errors.city}
            label='City'
            mode={AddressModeEnum.City}
            name='city'
            onChange={this.handleAddressChange}
            placeholder='City'
            size='mini'
          />
          <Form.Field
            control={StateDropdown}
            error={!!errors.state}
            label='State'
            name='state'
            onChange={(e:any, { value }:any) => this.handleAddressChange({ state: value })}
            placeholder='State'
            value={form.address.state}
          />
          <Form.Field
            control={Input}
            error={!!errors.zipCode}
            label='Zipcode'
            name='zipCode'
            onChange={(e:any, { value }:any) => this.handleAddressChange({ zipCode: value })}
            placeholder='Zipcode'
            type='number'
            value={form.address.zipCode}
          />
        </Form.Group>
        <Form.Field
          control={Input}
          name='name'
          value={form.name}
          label='Referral Name'
          placeholder='Referral Name'
          className='amount'
          onChange={onChange}
          error={!!errors.user}
        />
      </div>
    )
  }

  private handleAddressChange = (address: Partial<AddressType>) => {
    const { form, onChange } = this.props
    const value = { ...form.address, ...address }
    onChange(null, { name: 'address', value })
  }

  private onChangePhone = (event: any) => {
    const { onChange } = this.props
    onChange(event, { name: 'phone', value: event.target.value })
  }
}

export default ExternalForm
