/**
 * Disabled linter for sorting object literal keys since
 * it is easier to understand object without alphabetical
 * sorting with numbers.
 */

/* tslint:disable:object-literal-sort-keys */
const Colors = {
  Border: '#e0e0e0',
  BorderBottom: '#a9a9a94f',
  ButtonDanger: '#ef705f',
  ButtonDangerHover: '#d85e4d',
  ButtonDisable: '#dfdfdf',
  ButtonHighlight: '#8ac440',
  ButtonHighlightHover: '#79ad36',
  ButtonPrimary: '#4e5f80',
  ButtonPrimaryHover: '#36435d',
  CardShadow: '#d4d4d5',
  Container: '#fff',
  ContainerHighlight: '#e5ecfe',
  ContainerShadow: '#444',
  ContainerSidebar: '#fbfcff',
  DropZoneContainer: 'rgba(255, 255, 255, 0.8)',
  DropZoneHighlight: '#8ac440',
  EmphasisContainer: '#f2f7f8',
  EmphasisContainerDark: '#e2e7e8',
  EmphasisLine: '#bdbdbd',
  HeaderHighlight: '#f9f9f9',
  ImagePanelContainer: '#f2f7f8',
  Kanban: '#fafcff',
  KanbanShadow: 'rgba(0, 0, 0, 0.4)',
  ListEven: '#f1f4fe',
  ListOdd: '#fbfcff',
  LoadingStripeEven: '#f2f7f8',
  LoadingStripeOdd: '#8ac440',
  MarkupLabelFill: '#246efe',
  MarkupLabelText: '#FFF',
  MarkupOverlay: 'rgba(36, 110, 254, 0.3)',
  ModalShadow: 'rgba(0,0,0,0.75)',
  PlaceholderEven: '#f2f7f8',
  PlaceholderOdd: '#e2e7e8',
  StatusActive: '#8ac440',
  StatusClosed: '#ED6052',
  StatusFailed: '#bdbdbd',
  StatusInactive: '#dfdfdf',
  StatusNew: '#ffc848',
  StatusPayable: '#c67eb9',
  StatusPending: '#ec9b36',
  StatusReceived: '#4680fe',
  TableLine: '#eef6fe',
  TableHeadLine: '#dbe1e7',
  Text: '#646464',
  TextDanger: '#ef705f',
  TextDark: '#323232',
  TextDisable: '#c8c8c8',
  TextError: '#ef705f',
  TextHighlight: '#8ac440',
  TextHighlightHover: '#79ad36',
  TextLight: '#fff',
  TextLink: '#246efe',
  TextLinkHover: '#2a61cc',
  TextMedium: '#999999',
  TextNegative: '#dc3912',
  TextNeutral: '#ff9801',
  TextPositive: '#129518',
  TextPrimary: '#4e5f80',
  TextPrimaryHover: '#36435d',
  TextSecondary: '#a6a8ab',
  TextSecondaryHover: '#909294',
  TextSuccess: '#8ac440',
  TextUrl: '#0068fff5',
  ToggleOff: '#9a9a9a',
  ToggleOn: '#4e5f80',
  ToolTip: '#1b1c1d',
  TransactionBoth: '#8b7fc7',
  TransactionBuyer: '#8ac74d',
  TransactionSeller: '#77a7b8',
  TransparentBlack: (value: number) => `rgba(0,0,0,${value})`,
  TransparentWhite: (value: number) => `rgba(255,255,255,${value})`,

  /* Social */
  Facebook: '#3C5A99',
  LinkedIn: '#0077B5',
  Twitter: '#1DA1F2',

  Black50: '#000000',
  Black60: '#46586b',
  Black61: '#00000061',
  Black200: '#222222',
  Black300: '#323232',
  Black400: '#444444',
  Black450: '#636363',
  Black500: '#646464',
  Black505: '#747576',
  Black510: '#656565',
  Black515: '#adaeb2',
  Black520: '#696969',
  Black530: '#666666',
  Black535: '#8f8f8f',
  Black540: '#959595',
  Black545: '#aeadad',
  Black543: '#a3acb6',
  Black544: '#bfbfbf',
  Black550: '#969696',
  Black555: '#505051',
  Black560: '#808080',
  Black565: '#8e8e8e',
  Black570: '#979797',
  Black580: '#a8a8a8',
  Black590: '#5c5c5e',
  Black600: '#999999',
  Black610: '#9ba4b5',
  Black615: '#d9dde0',
  Black620: '#909090',
  Black625: '#E2E8E8',
  Black700: '#a7a9ac',
  Black750: '#575757',
  Black760: '#bdbec1',
  Black765: '#e0e0e0',
  Black770: '#f4f8ff',
  Black771: '#E5E5E5',
  Black800: '#eeeeee',
  Black805: '#d9d9d9',
  Black810: '#e4e4e4',
  Black840: '#cdcfd1',
  Black845: '#d7dae4',
  Black850: '#ebebeb',
  Black855: '#ececec',
  Black900: '#e3eafa',
  Black905: '#f3f3f3',
  Black910: '#f0f0f0',
  Black950: '#aeaeae',
  Black1000: '#cccccc',
  Blue00: '#246efe',
  Blue10: '#719dfe',
  Blue50: '#4680fe',
  Blue60: '#4580fe',
  Blue75: '#558afe',
  Blue80: '#5c71f8',
  Blue100: '#719dfe',
  Blue111: '#4761B1',
  Blue110: '#eef4fe',
  Blue150: '#bddbff',
  Blue180: '#9bc9ff',
  Blue200: '#1cccfe',
  Blue210: '#66f8f9',
  Blue300: '#298bff',
  Blue600: '#c3d6fe',
  Blue700: '#4498dc',
  Blue701: '#0068fff5',
  Blue800: '#e6f0ff',
  Blue900: '#d6f6ff',
  Blue910: '#e1ebf5',
  Blue915: '#ebf0fc',
  Blue950: '#e1ebff',
  Blue1000: '#f0f5ff',
  DarkBlue200: '#4e5f80',
  DarkBlue205: '#525a80',
  DarkBlue210: '#525c7e',
  DarkBlue215: '#e2ebf4',
  DarkBlue220: '#656d8b',
  DarkBlue225: '#dae4ee',
  DarkBlue230: '#525d7d',
  DarkBlue234: '#3366cc',
  DarkBlue235: '#9AA5B8',
  DarkBlue240: '#4e5b79',
  DarkBlue243: '#5b778c',
  DarkBlue245: '#f4f8ff',
  DarkBlue250: '#525d7d',
  DarkBlue255: '#ebeff5',
  DarkBlue256: '#ebeff5',
  DarkBlue260: '#6d7692',
  DarkBlue270: '#4c5874',
  DarkBlue275: '#e0ecf4',
  DarkBlue276: '#eef6fe',
  DarkBlue280: '#6b7892',
  DarkBlue285: '#3f4b61',
  DarkBlue290: '#f2f5ff',
  DarkBlue295: '#eef6ff',
  DarkBlue400: '#466e87',
  DarkBlue500: '#77a7b8',
  DarkBlue510: '#93BBC6',
  DarkBlue600: '#a7c7d4',
  LightBlue600: '#cbe5fe',
  Green50: '#a3da55',
  Green55: '#8fc766',
  Green56: '#9ac354',
  Green60: '#8ce44b',
  Green70: '#00821c',
  Green80: '#13c72d',
  Green90: '#8acc53',
  Green100: '#b3d7aa',
  Green101: '#1f9518',
  Green110: '#87c94c',
  Green120: '#72fab4',
  Green125: '#85c84c',
  Green130: '#71DC6E',
  Green140: '#89C84C',
  Green200: '#64e1a5',
  Green210: '#6BC360',
  Green215: '#8ac74d',
  Green300: '#72e2ad',
  Green310: '#86CA4C',
  Green350: '#7ed01e',
  Green400: '#9cd351',
  Green500: '#8dc63f',
  Green550: '#23d02f',
  Green560: '#4dc7c2',
  Green600: '#3de586',
  Green700: '#a3db57',
  Magenta50: '#c67eb9',
  Magenta60: '#9374bb',
  Magenta70: '#c867b3',
  Magenta80: '#a3218c',
  Magento100: '#eaf1f3',
  Magenta200: '#c7c6c7',
  Orange50: '#ffa13c',
  Orange100: '#ffba17',
  Orange110: '#F4B44C',
  Orange115: '#f3e2c9',
  Orange120: '#ebdbc6',
  Orange125: '#fbc55e',
  Orange150: '#ffb845',
  Orange160: '#FBB85A',
  Orange200: '#ffc05e',
  Orange250: '#ffc848',
  Orange300: '#ffab1a',
  Orange400: '#ffaf00',
  Orange410: '#ffa71e',
  Orange450: '#ffe07d',
  Orange480: '#fecf86',
  Orange490: '#fcad31',
  Orange500: '#f15928',
  Orange510: '#f26c4f',
  Orange515: '#f3e3cf',
  Orange520: '#e8993a',
  Orange600: '#c0c0c0',
  Pink50: '#ff7da5',
  Pink55: '#ede0f2',
  Pink60: '#eaafe9',
  Pick65: '#e4d9ed',
  Pink70: '#e395f9',
  Pink75: '#CE91D6',
  Pink80: '#ffacde',
  Pink100: '#ff86ac',
  Pink200: '#f346fe',
  Pink210: '#edf0f9',
  Pink250: '#ff5cee',
  Purple00: '#454B66',
  Purple50: '#8b7fc7',
  Red00: '#FA5A4C',
  Red10: '#f83131',
  Red15: '#eb3635',
  Red20: '#f26e5e',
  Red25: '#e0849b',
  Red30: '#f79495',
  Red35: '#fe6266',
  Red50: '#e26165',
  Red55: '#ED6052',
  Red100: '#fdb4c3',
  Red110: '#e1e1e1',
  Red120: '#f9fafa',
  Red130: '#ef7462',
  Red200: '#fb607f',
  Red400: '#ff5a7d',
  Red500: '#fe4653',
  Red600: '#dddddd',
  Red700: '#eb4159',
  Red800: '#f26c4f',
  Red810: '#ef705f',
  Red820: '#f5977c',
  Red825: '#FB6280',
  Red830: '#ff8C92',
  Red835: '#f68f92',
  Red840: '#f48873',
  Teal10: '#4cacc3',
  Teal15: '#d3e7f0',
  Teal20: '#dfe1fd',
  Teal50: '#7ab6c7',
  Teal55: '#20AAC4',
  Teal60: '#546485',
  Teal65: '#8BD2E6',
  transparent: 'transparent',
  White400: '#f5f5f5',
  White410: '#e9eaee',
  White420: '#f6f4f5',
  White450: '#f2f6fe',
  White460: '#f9f9fc',
  White470: '#f5faff',
  White480: '#f5f5f7',
  White490: '#f1f4fe',
  White500: '#f5f7fa',
  White510: '#fbfcff',
  White515: '#fafbfd',
  White520: '#f1f1f1',
  White525: '#f6f6fb',
  White530: '#f9fafc',
  White535: '#f9f9f9',
  White540: '#eaeaea',
  White545: '#fbfbfb',
  White550: '#FAFAFA',
  White555: '#e4edf6',
  White556: '#9FA2AE',
  White560: '#e5ecfe',
  White565: '#dcdffd8f',
  White600: '#f2f2f2',
  white605: '#f5f9fe',
  White700: '#f8f8f8',
  White800: '#fafcff',
  White850: '#f0f4fe',
  White900: '#f9fafb',
  White910: '#f2f8f8',
  White1000: '#ffffff',
  Grey00: '#f6f7fb',
  Grey50: '#c8c8c8',
  Grey100: '#dfdfdf',
  Grey110: '#f2f7f8',
  Grey120: '#b6b6b6',
  Grey125: '#E7EDED',
  Grey200: '#fafbff',
  Grey300: '#e1e2e4',
  Grey335: '#dcdde2',
  Grey400: '#ebebeb',
  Grey500: '#f0f3f8',
  Grey510: '#b1b7c6',
  Grey520: '#e1e2e6',
  Grey550: '#e9e9e9',
  Grey555: '#eff0f4',
  Grey560: '#E9F0F3',
  Grey565: '#B3B8C4',
  Grey570: '#e0e1e2',
  Grey575: '#d0d1d3',
  Grey580: '#dcdcdc',
  Grey600: '#abaeb3',
  Grey605: '#f6f6f6',
  Grey610: '#eae8eb',
  Grey615: '#ededf1',
  Grey700: '#e4e5e5',
  Grey800: '#bdbdbd',
  Grey850: '#9b9b9b',
  Grey900: '#d7d7d7',
  Grey950: '#a3acbd',
  Grey1000: '#b7b7b7',
  Yellow50: '#ffff00',
  Yellow60: '#fde784',
  Yellow70: '#f5eb59',
  Yellow80: '#f3e47e',
  Yellow90: '#f8f373'
}
/* tslint:enable:object-literal-sort-keys */

export default Colors
