// Import Packages
import * as React from 'react'
import { Button, Form, Input } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Header,
  StyledForm
} from './Styled'

interface Props {
  updatePassName: (newName: string) => void
  handleClose: () => void
  name: string
}

interface State {
  rename: string
  animate: boolean
}

class RenameUserPassModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    rename: '',
  }

  public componentDidMount() {
    const { name } = this.props
    this.setState({ rename: name })
  }

  public render() {
    const { rename, animate } = this.state
    return (
      <Modal content={
        <div>
          <Header>
            {Strings.generalText.renamePass}
          </Header>
          <StyledForm size={'mini'}>
            <Form.Field
              control={Input}
              name="name"
              label='New Name'
              value={rename}
              placeholder='New Name'
              onChange={this.handleInputChange} />
            <Form.Field
              control={Button}
              className='save-button'
              onClick={this.submitResult}
              content={'SAVE'} />
          </StyledForm>
        </div>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={350} />
    )
  }

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rename: e.target.value })
  }

  private submitResult = () => {
    const { handleClose, updatePassName } = this.props
    const { rename } = this.state
    updatePassName(rename)
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose()
    }, 300)
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose()
    }, 300)
  }
}

export default RenameUserPassModal
