import { ActionCreator } from 'redux'
import Actions from 'store/Actions'
import { ToggleNavigation } from './Types'

export const toggleNavigation: ActionCreator<ToggleNavigation> = (
  toggle: boolean
) => ({
  payload: { toggle },
  type: Actions.toggleNavigation
})
