import client from 'queries/apollo'
import { GET_EVENTS } from 'queries/graphql/Events/Queries'
import { GET_USERS } from 'queries/graphql/Users/Queries'

export const getEvents = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_EVENTS
  })

  return response.data.getEvents
}

export const getUsers = async () => {
  const response = await client.query({
    query: GET_USERS,
    variables: { isActive: true }
  })

  return response.data.getUsers
}
