import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, Label } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { primaryStyledButton, text, x, y } from 'design/Styled'

interface Props {
  max?: number
}

export const Container = styled.div`
  ${y};
  height: 100%;
  font-size: 15px;

  .rp-scrollable-child {
    min-height: 100%;
    display: flex;
    align-items: center;
  }
`

export const Text = styled.p`
  ${text};
  display: block;
  width: 100%;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 0.5em;
  strong {
    display: inline;
  }
`

export const Form = styled.section`
  ${y};
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`

export const Col = styled.div`
  ${y};
`

export const InlineButton = styled(Label)`
  &.ui.label {
    min-width: 36px;
    min-height: 34px;
    text-align: center;
    border: 1px solid ${Colors.EmphasisLine};
  }
  cursor: pointer;
`

export const Fa = styled(FontAwesomeIcon)``

export const StyledInput = styled(Input)`
  ${x};
  margin-bottom: 0.5em;
  align-items: stretch;
`

export const Image = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto 1em auto;
  ${(props: Props) =>
    props.max
      ? css`
          max-width: ${props.max}px;
        `
      : ``};
`

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
`

export const PaidIcon = styled.img`
  display: block;
  height: 30px;
  margin-left: 0.5em;
`
