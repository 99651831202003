export const handleTeamValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`team`] || fields[`team`].trim() === '') {
    formIsValid = false
    errors[`team`] = true
  }

  return { errors, formIsValid }
}
