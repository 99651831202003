import * as algoliasearch from 'algoliasearch'
import Toast from 'shared/Toast'
// import { debounce } from 'lodash'
import * as React from 'react'

import { getAlgoliaPlacesCredentials } from './Queries'

import {
  AddressModeEnum,
  // AddressResultEnum,
  AddressType,
  AddressValueEnum,
  // AlgoliaSuggestionType,
  ResultType,
  SearchParamsType,
} from './Types'

import {
  Container,
  Result,
  StyledPopup,
  StyledSearch,
  Subtitle,
  Title,
} from './Styled'

interface Props {
  address: AddressType
  error: boolean
  mode: AddressModeEnum
  name: string
  onChange: (address: AddressType) => void
  placeholder: string
  showNumberPopup: boolean
  size: string | undefined
}

interface State {
  geolocation: string
  loading: boolean
  options: ResultType[]
  popup: string
}

class AddressInput extends React.Component<Props, State> {
  public static defaultProps = {
    error: false,
    mode: AddressModeEnum.Address,
    name: '',
    placeholder: '',
    showNumberPopup: true,
    size: undefined,
  }

  public state = {
    geolocation: '',
    loading: false,
    options: [] as ResultType[],
    popup: '',
  }

  public algolia: any = null
  public wait: number = 500

  // private getAddressOptions = debounce(async (params: SearchParamsType) => {
  //   const { mode, showNumberPopup } = this.props

  //   const loading = false
  //   let popup = ''
  //   let options: ResultType[] = []
  //   if (showNumberPopup && !/^($|\d+$|\d+\W)/.test(params.query)) {
  //     popup = 'Address must begin with a street number'
  //   }

  //   const street = this.renderStreet(params.query)
  //   if (!street.streetNumber || !street.streetName) {
  //     return this.setState({
  //       loading,
  //       options,
  //       popup,
  //     })
  //   }

  //   const hits: any = await this.getPlaces(params)
  //   if (!hits) {
  //     return this.setState({
  //       loading,
  //       options,
  //       popup,
  //     })
  //   }

  //   const isStreet: string = AddressResultEnum[mode]
  //   const filtered: AlgoliaSuggestionType[] = hits.filter(
  //     (item: AlgoliaSuggestionType) => {
  //       return (
  //         item[isStreet] &&
  //         this.hasCompleteData(item) &&
  //         this.hasStreetNumber(item.locale_names[0])
  //       )
  //     }
  //   )

  //   options = filtered.map((item: AlgoliaSuggestionType) => {
  //     const streetParts = this.renderStreet(item.locale_names[0] || '')
  //     const data: AddressType = {
  //       ...streetParts,
  //       city: item.city[0] || '',
  //       state: item.administrative[0] || '',
  //       zipCode: this.renderZipCode(item.postcode[0] || ''),
  //     }
  //     return {
  //       data,
  //       title: item.objectID,
  //     }
  //   })

  //   return this.setState({
  //     loading,
  //     options,
  //     popup,
  //   })
  // }, this.wait)

  // private getCityOptions = debounce(async (params: SearchParamsType) => {
  //   const { address, mode } = this.props

  //   const loading = false
  //   let options: ResultType[] = []
  //   const hits: any = await this.getPlaces(params)
  //   if (!hits) {
  //     return this.setState({
  //       loading,
  //       options,
  //     })
  //   }

  //   const isCity: string = AddressResultEnum[mode]
  //   const filtered: AlgoliaSuggestionType[] = hits.filter(
  //     (item: AlgoliaSuggestionType) => {
  //       return item[isCity] && this.hasCompleteData(item)
  //     }
  //   )

  //   options = filtered.map((item: AlgoliaSuggestionType) => {
  //     const data: AddressType = {
  //       ...address,
  //       city: item.locale_names[0] || '',
  //       state: item.administrative[0] || '',
  //       zipCode: this.renderZipCode(item.postcode[0] || ''),
  //     }
  //     return {
  //       data,
  //       title: item.objectID,
  //     }
  //   })

  //   return this.setState({
  //     loading,
  //     options,
  //   })
  // }, this.wait)

  public componentDidMount = async () => {
    try {
      const { appId, apiKey }: any = await getAlgoliaPlacesCredentials()
      this.algolia = algoliasearch.initPlaces(appId, apiKey)
    } catch {
      Toast({ message: 'Could not configure Algolia Places', type: 'error' })
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setGeolocation)
    }
  }

  public render() {
    const { address, error, mode, name, placeholder, size } = this.props
    const { options, popup } = this.state

    return (
      <Container
        className={
          'rp-address-input-container ui form' + (error ? ' error' : '')
        }
      >
        <StyledPopup
          content={popup}
          open={!!popup}
          size={'mini'}
          trigger={
            <StyledSearch
              className='rp-address-input'
              fluid={true}
              icon={''}
              // loading={loading}
              name={name}
              onResultSelect={this.handleResultSelect}
              onSearchChange={this.handleSearchChange}
              placeholder={placeholder}
              resultRenderer={this.renderResult}
              results={options}
              selectFirstResult={false}
              showNoResults={false}
              size={size}
              value={address[AddressValueEnum[mode]] || ''}
            />
          }
        />
      </Container>
    )
  }

  private handleResultSelect = (e: any, { result: { data } }: any) => {
    const { onChange } = this.props
    onChange(data)
  }

  private handleSearchChange = async (e: any, { value }: any) => {
    const { address, mode, onChange } = this.props
    const { geolocation } = this.state

    const params: SearchParamsType = {
      countries: 'us',
      hitsPerPage: 10,
      language: 'us',
      query: value,
      type: mode,
    }
    if (geolocation) {
      params.aroundLatLng = geolocation
      params.aroundRadius = 50000
    }

    this.setState({ loading: true })

    switch (mode) {
      case AddressModeEnum.City:
        onChange({
          ...address,
          city: value,
        })
        // this.getCityOptions(params)
        break
      case AddressModeEnum.Address:
      default:
        const street = this.renderStreet(value)
        onChange({
          ...address,
          ...street,
        })
      // this.getAddressOptions(params)
    }
  }

  // private getPlaces = async (params: SearchParamsType) => {
  //   let result: any = null

  //   try {
  //     let attempt: any = await this.algolia.search(params)
  //     if (attempt.nbHits === 0) {
  //       delete params.aroundLatLng
  //       delete params.aroundRadius
  //       attempt = await this.algolia.search(params)
  //     }
  //     result = attempt.hits
  //   } catch (error) {
  //     Toast({ message: error, type: 'error' })
  //   }

  //   this.setState({ loading: false })
  //   return result
  // }

  private renderResult = (item: ResultType, index: number) => {
    const { mode } = this.props
    const { city, streetAddress, state, zipCode } = item.data

    let title = ''
    let subtitle = ''

    switch (mode) {
      case AddressModeEnum.City:
        title = city
        subtitle = `${state} ${zipCode}`
        break

      case AddressModeEnum.Address:
      default:
        title = streetAddress
        subtitle = `${city}, ${state} ${zipCode}`
    }
    return (
      <Result>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Result>
    )
  }

  private renderStreet = (streetAddress: string) => {
    const streetNumber = streetAddress.match(/^\d+\s/)
    if (!streetNumber) {
      return {
        streetAddress,
        streetName: streetAddress,
        streetNumber: '',
      }
    }
    return {
      streetAddress,
      streetName: streetAddress.substring(streetNumber[0].length),
      streetNumber: streetNumber[0].trim(),
    }
  }

  // private renderZipCode = (zipCode: string) => {
  //   return zipCode.slice(0, 5)
  // }

  private setGeolocation = (position: Position) => {
    const { latitude, longitude } = position.coords
    const geolocation = `${latitude},${longitude}`
    this.setState({ geolocation })
  }

  // private hasStreetNumber = (input: string) => {
  //   return /^\d+\s/.test(input)
  // }

  // private hasCompleteData = (suggestion: AlgoliaSuggestionType) => {
  //   const { mode } = this.props
  //   const { locale_names, city, administrative, postcode } = suggestion

  //   try {
  //     switch (mode) {
  //       case AddressModeEnum.City:
  //         return locale_names[0] && administrative[0] && postcode[0]

  //       case AddressModeEnum.Address:
  //       default:
  //         return locale_names[0] && city[0] && administrative[0] && postcode[0]
  //     }
  //   } catch {
  //     return false
  //   }
  // }
}

export default AddressInput
