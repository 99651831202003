// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Menu } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import Feed from './Notifications/Feed'
import Updates from './Notifications/Updates'

// Import Queries And Mutation
import * as DashboardActions from 'store/Dashboard/Actions'
import * as Actions from 'store/Notifications/Actions'
import { getUnreadFeedNotificationCount, getUnreadFeedPublicNotificationCount, getUnreadPublicNotificationCount } from './NotificationsQueries'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import {
  Container,
  RightPanel,
  StyledLabel,
  StyledTab,
} from './Styled'

interface StoreProps {
  setShowTeamDetail: (id: string) => void
  setShowNotificationCount: (count: number) => void
}

interface OwnProps {
  closeModal: () => void
}

type Props = StoreProps & OwnProps

interface State {
  animate: boolean
  unReadFeedNotification: number
  unReadPublicNotification: number
  userID: string
  activeIndex: number
  stopTabClick: boolean
}

class NotificationsModal extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    animate: true,
    stopTabClick: false,
    unReadFeedNotification: 0,
    unReadPublicNotification: 0,
    userID: ''
  }

  public async componentDidMount() {
    const { setShowNotificationCount } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    const notificationCount = await getUnreadFeedPublicNotificationCount()
    setShowNotificationCount(notificationCount)
    const unReadPublicNotification = await getUnreadPublicNotificationCount(user._id)
    const unReadFeedNotification = await getUnreadFeedNotificationCount()
    this.setState({ unReadFeedNotification, unReadPublicNotification, userID: user._id })
  }

  public render() {
    const { animate, unReadFeedNotification, unReadPublicNotification, activeIndex } = this.state
    const panes = [
      {
        menuItem: (<Menu.Item key='FEED' className='feed'>{Strings.settings.notification.feed} <StyledLabel circular={true}>{unReadFeedNotification}</StyledLabel></Menu.Item>),
        render: () => <Feed showTeamDetail={this.showTeamDetail} closeModal={this.closeModal} unReadNotificationCounterUpdate={this.unReadNotificationCounterUpdate} />
      },
      {
        menuItem: (<Menu.Item key='UPDATES'>{Strings.settings.notification.updates} <StyledLabel circular={true}>{unReadPublicNotification}</StyledLabel></Menu.Item>),
        render: () => <Updates unReadNotificationCounterUpdate={this.unReadNotificationCounterUpdate} stopTabClick={this.stopTabClick} />
      },
    ]
    return (
      <Modal content={
        <Container>
          <RightPanel>
            <StyledTab panes={panes} activeIndex={activeIndex} onTabChange={this.handleTabChange} />
          </RightPanel>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={800} height={500} />
    )
  }

  private showTeamDetail = (id: string) => {
    const { setShowTeamDetail } = this.props
    setShowTeamDetail(id)
  }

  private handleTabChange = (e: React.ChangeEvent<HTMLInputElement>, { activeIndex }: any) => {
    const { stopTabClick } = this.state
    if (!stopTabClick) {
      this.setState({ activeIndex })
    }
  }

  private unReadNotificationCounterUpdate = async () => {
    const { setShowNotificationCount } = this.props
    const { userID } = this.state
    const notificationCount = await getUnreadFeedPublicNotificationCount()
    setShowNotificationCount(notificationCount)
    const unReadPublicNotification = await getUnreadPublicNotificationCount(userID)
    const unReadFeedNotification = await getUnreadFeedNotificationCount()
    this.setState({ unReadFeedNotification, unReadPublicNotification })
  }

  private stopTabClick = (data: boolean) => {
    this.setState({ stopTabClick: data })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default connect(
  null,
  {
    setShowNotificationCount: Actions.showNotificationCount,
    setShowTeamDetail: DashboardActions.showTeamDetail,
  }
)(NotificationsModal)
