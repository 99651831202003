import * as React from 'react'
import Clipboard from 'react-copy-to-clipboard'

import Button from 'shared/Button'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import Scrollable from 'shared/Scrollable'
import Toast from 'shared/Toast/Toast'
import Transition from 'shared/Transition'
import Templates from 'templates/Listing'

import { Routes } from 'utils'

import {
  Body,
  Col,
  Container,
  DIMENSIONS,
  Frame,
  Header,
  Link,
  Menu,
  MenuItem,
  Preview,
  Row,
  Title,
} from './Styled'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { TransactionType } from '../Types'
import { ViewType } from './Types'

import { faShareAlt, faDesktop, faTabletAndroidAlt, faMobileAlt } from '@fortawesome/pro-light-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([ faShareAlt, faDesktop, faTabletAndroidAlt, faMobileAlt ])

interface Props {
  onClose: () => void
  transaction: TransactionType
}

interface State {
  isReady: boolean
  list: string[]
  scale: number
  template: string
  transition: boolean
  url: string
  view: ViewType
}

class PreviewModal extends React.Component<Props, State> {
  public state = {
    isReady: false,
    list: [],
    scale: 1,
    template: '',
    transition: true,
    url: '',
    view: 'desktop' as ViewType,
  }

  private viewer: any = null
  private DURATION: number = 300

  public componentDidMount = () => {
    const list = Object.keys(Templates)
    this.setState({ list })

    window.requestAnimationFrame(() => {
      this.toggleView('desktop')
      this.toggleTemplate(list[0])
    })
  }

  public render = () => {
    const {
      isReady,
      list,
      scale,
      template,
      transition,
      url,
      view,
    } = this.state

    return (
      <Modal
        content={(
          <Container width={100}>
            <Menu>
              <Header>
                <Title>Virtual Showcase</Title>
              </Header>
              <Col
                primary={1}
                overflow='hidden'
              >
                <Scrollable>
                  {list.map((item: string, index: number) => (
                    <MenuItem
                      key={index}
                      onClick={() => this.toggleTemplate(item)}
                      isActive={template === item}
                      >
                        {Templates[item].name}
                      </MenuItem>
                    ))}
                  </Scrollable>
              </Col>
            </Menu>
            <Col
              height={100}
              overflow='hidden'
              padding={'0 1em 1em'}
            >
              <Header>
                <Row primary={true}>
                  <Clipboard text={url} onCopy={this.handleCopy}>
                    <Button.Icon
                      label='Copy and share public URL'
                      icon={faShareAlt}
                    />
                  </Clipboard>
                  <Link href={url} target='_blank'>{url}</Link>
                </Row>
                <Row width={-1}>
                  <Button.Icon
                    isActive={view === 'mobile'}
                    label='Mobile view'
                    icon={faMobileAlt}
                    onClick={() => this.toggleView('mobile')}
                  />
                  <Button.Icon
                    isActive={view === 'tablet'}
                    label='Tablet view'
                    icon={faTabletAndroidAlt}
                    onClick={() => this.toggleView('tablet')}
                  />
                  <Button.Icon
                    isActive={view === 'desktop'}
                    label='Desktop view'
                    icon={faDesktop}
                    onClick={() => this.toggleView('desktop')}
                  />
                </Row>
              </Header>
              <Transition on={isReady} type='fadeIn' duration={isReady ? this.DURATION : 20}>
                <Body innerRef={(el:any) => this.viewer = el}>
                    <Preview mode={view} scale={scale}>
                      <LoadingIndicator type={IndicatorTypeEnum.Spinner} />
                      <Frame src={url} scale={scale} />
                    </Preview>
                </Body>
              </Transition>
            </Col>
          </Container>
        )}
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        maximum={true}
      />
    )
  }

  private handleCopy = () => {
    Toast({
      message: 'Showcase URL has been copied to your clipboard!',
      type: 'success'
    })
  }

  private toggleView = (view: ViewType) => {
    if (!this.viewer) {
      return
    }

    let scale = 1
    const padding = 40
    const screen = {
      height: this.viewer.clientHeight,
      width: this.viewer.clientWidth,
    }
    const device = {
      height: DIMENSIONS[view].height,
      width: DIMENSIONS[view].width,
    }
    const ratio = {
      height: screen.height < device.height ? screen.height / (device.height + padding) : 1,
      width: screen.width < device.width ? screen.width / (device.width + padding) : 1
    }
    scale = ratio.width < ratio.height ? ratio.width : ratio.height

    this.setState({
      isReady: false,
      scale,
      view,
    }, () => {
      window.setTimeout(() => {
        this.setState({ isReady: true })
      }, this.DURATION)
    })
  }

  private toggleTemplate = (template: string) => {
    const { transaction } = this.props
    const url = `${process.env.REACT_APP_DOMAIN}${Routes.listing.path}/${template}/${transaction._id}`
    this.setState({ template, url })
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      onClose()
    }, 300)
  }
}

export default PreviewModal
