import { Reducer } from 'redux'
import Actions from 'store/Contacts/ActionsTypes'
import {
  addNewBoard,
  addNewCard,
  addNewNotes,
  addShareWith,
  AddTransaction,
  deleteBoard,
  deleteCard,
  deleteListRecord,
  deleteNotes,
  deleteShareWith,
  editNotes,
  editRecord,
  importData,
  restoreCard,
  setNewBoardName,
  setNewDescription,
  setNewIcon,
  sortBoardData
} from './ReduxUtil'
import { Contact, ContactItemsDetails, ContactPassFilter } from './Types'

const initialState: Contact = {
  activities: [],
  allContactStatus: false,
  boardId: '',
  boardIndex: '',
  cardIndex: '',
  contactDetail: {},
  currentlyDeleteBoard: null,
  currentlyDeleteCard: null,
  data: [],
  filter: {
    filter: false,
    searchText: false
  },
  listData: [],
  notes: [],
  searchData: {},
  searchLoader: false,
  shareWith: [],
  transactions: [],
  userPassContactFilter: {} as ContactPassFilter
}

const ContactReducer: Reducer<Contact> = (
  state: Contact = initialState,
  action
) => {
  switch (action.type) {
    case Actions.contactAddNewBoard:
      return {
        ...state,
        data: addNewBoard(state, action.payload.index, action.payload.newData)
      }
    case Actions.contactGetBoardData:
      return {
        ...state,
        data: action.payload.data
      }
    case Actions.contactGetListData:
      return {
        ...state,
        listData: action.payload.data
      }
    case Actions.contactAddNewCard:
      return {
        ...state,
        data: addNewCard(
          state,
          action.payload.id,
          action.payload.index,
          action.payload.newData
        )
      }
    case Actions.contactSetNewIcon:
      return {
        ...state,
        data: setNewIcon(state, action.payload.id, action.payload.icon)
      }
    case Actions.contactSetNewDescription:
      return {
        ...state,
        data: setNewDescription(
          state,
          action.payload.id,
          action.payload.description
        )
      }
    case Actions.contactSetNewBoardName:
      return {
        ...state,
        data: setNewBoardName(
          state,
          action.payload.id,
          action.payload.boardName
        )
      }
    case Actions.contactDeleteBoard:
      const data1 = state.data
      const boardData = data1.slice()
      let tempIndex: string
      let tempData: ContactItemsDetails
      const callbackdata = () => {
        return {
          ...state,
          boardId: action.payload.boardId,
          boardIndex: tempIndex,
          currentlyDeleteCard: tempData,
          data: deleteBoard(state, action.payload.boardId)
        }
      }
      boardData.forEach((list: any, boardIndex: number) => {
        if (list._id === action.payload.boardId) {
          tempIndex = boardIndex.toString()
          tempData = boardData[boardIndex.toString()]
          callbackdata()
        }
      })
      return callbackdata()
    case Actions.contactDeleteCard:
      const { data } = state
      const feeData = data.slice()
      let index: string
      let temp: ContactItemsDetails
      const callback = () => {
        return {
          ...state,
          boardId: action.payload.boardId,
          cardIndex: index,
          currentlyDeleteCard: temp,
          data: deleteCard(state, action.payload.boardId, action.payload.cardId)
        }
      }
      feeData.forEach((list: any) => {
        if (list._id === action.payload.boardId) {
          const newList = list.items.slice()
          const listIndex = newList.findIndex((laneItem: any) => {
            return laneItem._id === action.payload.cardId
          })
          index = listIndex
          temp = newList[listIndex]
          callback()
        }
      })
      return callback()
    case Actions.contactRestoreCard:
      return {
        ...state,
        data: restoreCard(
          state,
          state.boardId,
          state.cardIndex,
          state.currentlyDeleteCard
        )
      }
    case Actions.contactResetCardData:
      return {
        ...state,
        boardId: '',
        cardIndex: '',
        currentlyDeleteCard: null
      }
    case Actions.contactAddNewNotes:
      return {
        ...state,
        notes: addNewNotes(state, action.payload.newData)
      }
    case Actions.contactGetNotesData:
      return {
        ...state,
        notes: action.payload.data
      }
    case Actions.contactEditNotes:
      return {
        ...state,
        notes: editNotes(state, action.payload.id, action.payload.newData)
      }
    case Actions.contactDeleteNotes:
      return {
        ...state,
        notes: deleteNotes(state, action.payload.id)
      }
    case Actions.contactSortBoardData:
      return {
        ...state,
        data: sortBoardData(
          state,
          action.payload.boardId,
          action.payload.sortOrder
        )
      }
    case Actions.contactDeleteListRecord:
      return {
        ...state,
        listData: deleteListRecord(state, action.payload.cardId)
      }
    case Actions.contactEditRecord:
      return {
        ...state,
        listData: editRecord(state, action.payload.newData)
      }
    case Actions.contactAddShareWith:
      return {
        ...state,
        shareWith: addShareWith(state, action.payload.shareWithObj)
      }
    case Actions.contactGetShareWith:
      return {
        ...state,
        shareWith: action.payload.data
      }
    case Actions.contactDeleteShareWith:
      return {
        ...state,
        shareWith: deleteShareWith(state, action.payload.data)
      }
    case Actions.contactDetails:
      return {
        ...state,
        contactDetail: action.payload.data
      }
    case Actions.contactEditDetails: {
      return {
        ...state,
        contactDetail: {
          ...state.contactDetail,
          address: action.payload.data.address,
          anniversaryDate: action.payload.data.anniversaryDate,
          children: action.payload.data.children,
          dateOfBirth: action.payload.data.dateOfBirth,
          firstName: action.payload.data.firstName,
          genderPrefix: action.payload.data.genderPrefix,
          lastName: action.payload.data.lastName,
          married: action.payload.data.married,
          pets: action.payload.data.pets,
          phone: action.payload.data.phone,
          source: action.payload.data.source
        }
      }
    }
    case Actions.contactImportData: {
      return {
        ...state,
        data: importData(
          state,
          action.payload.boardId,
          action.payload.importedData
        )
      }
    }
    case Actions.contactActivities: {
      return {
        ...state,
        activities: action.payload.data
      }
    }
    case Actions.contactFilter:
      return {
        ...state,
        filter: action.payload.data
      }
    case Actions.contactSearchLoader:
      return {
        ...state,
        searchLoader: action.payload.open
      }
    case Actions.contactToggleStatus:
      return {
        ...state,
        allContactStatus: action.payload.open
      }
    case Actions.contactSearchFilter:
      return {
        ...state,
        searchData: action.payload.data
      }
    case Actions.getContactTransaction:
      return {
        ...state,
        transactions: action.payload.data
      }
    case Actions.addContactTransaction:
      return {
        ...state,
        transactions: AddTransaction(state, action.payload.data)
      }
    case Actions.userPassContactFilter:
      return {
        ...state,
        userPassContactFilter: action.payload.filters
      }
    default:
      return state
  }
}

export default ContactReducer
