// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'

// Import Components
import GridView from 'shared/WorkflowGrid'
import {
  CustomAddedDate,
  CustomListingId,
  CustomModifiedDate,
  CustomOwnerName,
  CustomPrice,
  CustomPropertyAddress,
  CustomPropertySide,
  CustomSharedWith,
  CustomStatus
} from './CustomComponents'

import { formatListData } from 'app/Transactions/Dashboard/Utils/FormattingData'
import { getContactTransactions } from 'app/Contacts/Dashboard/ContactQueries'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Contacts/Actions'

import { ListViewTransactionType } from 'app/Transactions/Dashboard/Types'
import { ContactListDetails } from 'store/Contacts/Types'

// Import Utils
import { getLoggedInUser } from 'utils'

// Import Styled Components
import {
  GridContainer
} from './Styled'

interface StoreProps {
  setContactTransactionList: (data: ListViewTransactionType[]) => void
  contactTransactions: ListViewTransactionType[]
}

interface OwnProps {
  contactData: ContactListDetails
}

type Props = OwnProps & StoreProps

interface State {
  checked: boolean
  listViewData: ListViewTransactionType[]
  gridKey: number
}

export let loggedUser: any

class Transactions extends React.Component<Props, State> {
  public state = {
    checked: false,
    gridKey: new Date().getTime(),
    listViewData: [],
  }

  public columnMetaData = [
    {
      customComponent: CustomPropertyAddress,
      enhanceWithRowData: true,
      id: 'propertyId.address.streetNumber',
      title: 'Property',
    },
    {
      customComponent: CustomPropertySide,
      enhanceWithRowData: true,
      id: 'transactionRole',
      title: 'Property Side',
    },
    {
      enhanceWithRowData: true,
      id: 'transactionId',
      title: 'Transaction Id',
    },
    {
      customComponent: CustomListingId,
      enhanceWithRowData: true,
      id: 'propertyId.mlsId',
      title: 'MLS ID',
    },
    {
      customComponent: CustomPrice,
      enhanceWithRowData: true,
      id: 'propertyId.price',
      title: 'Price',
    },
    {
      customComponent: CustomOwnerName,
      enhanceWithRowData: true,
      id: 'owner.firstName',
      title: 'Owner Name',
    },
    {
      customComponent: CustomSharedWith,
      enhanceWithRowData: true,
      id: 'sharedWith',
      title: 'Shared With',
    },
    {
      customComponent: CustomAddedDate,
      enhanceWithRowData: true,
      id: 'createdAt',
      title: 'Added Date',
    },
    {
      customComponent: CustomModifiedDate,
      enhanceWithRowData: true,
      id: 'updatedAt',
      title: 'Modified Date',
    },
    {
      customComponent: CustomStatus,
      enhanceWithRowData: true,
      id: 'status',
      title: 'Status',
    }
  ]

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = user
    const { contactData, setContactTransactionList } = this.props
    const transactions = await getContactTransactions(contactData._id)
    const transactionListData = await formatListData(transactions)
    setContactTransactionList(transactionListData)
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({ gridKey: new Date().getTime() })
  }

  public render() {
    const { gridKey } = this.state
    return (
      <GridContainer style={{ position: 'relative' }} key={gridKey}>
        <GridView
          data={this.props.contactTransactions}
          tableHeight={50}
          columnMetaData={this.columnMetaData}
        />
      </GridContainer>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  contactTransactions: state.contacts.transactions
})

export default connect(
  mapStateToProps,
  {
    setContactTransactionList: Actions.getContactTransaction,
  }
)(Transactions)
