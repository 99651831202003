import * as React from 'react'

import Transition from 'shared/Transition'

import { Strings } from 'utils'

import { Col, Description, StyledButton, StyledDropdown, StyledForm, Title } from '../../Styled'

import { ActionEnum, FormType, OptionType, TransitEnum } from '../../Types'

interface Props {
  form: FormType
  offices: OptionType[]
  onAction: (action: ActionEnum, data: any) => void
  transit: TransitEnum
}

const {
  transactionModal: {
    CONCIERGE: { INPUT, TITLE }
  }
} = Strings

class OfficeStep extends React.Component<Props, {}> {
  public render() {
    const {
      form,
      form: { errors },
      offices,
      transit
    } = this.props

    const transition = transit === TransitEnum.Enter

    return (
      <Col height={100}>
        <Transition on={transition}>
          <Title>{TITLE.office1}</Title>
          <StyledForm onSubmit={this.handleSubmit}>
            <Col>
              <Transition className="office-step" delay={50} on={transition} stagger={30}>
                <Description>{INPUT.officeLabel}</Description>
                <StyledDropdown
                  error={!!errors.office}
                  fluid={true}
                  name="office"
                  onChange={this.handleInput}
                  options={offices}
                  placeholder="Office"
                  search={true}
                  selection={true}
                  value={form.office}
                />
                <Col padding={'0 1em 1em'}>
                  <StyledButton type="submit" content={INPUT.officeSubmit} />
                </Col>
              </Transition>
            </Col>
          </StyledForm>
        </Transition>
      </Col>
    )
  }

  private handleSubmit = () => {
    const { onAction } = this.props
    onAction(ActionEnum.Step, null)
  }

  private handleInput = (e: React.SyntheticEvent, { name, value }: any) => {
    const { onAction } = this.props
    onAction(ActionEnum.Input, { name, value })
  }
}

export default OfficeStep
