// Import Packages
import styled from 'styled-components'

const Container = styled.div`
	position: relative;
	width: 320px;
	height: 200px;
`

const FloatingMenu = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
`


export {
  Container,
  FloatingMenu
}
