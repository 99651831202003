// Import Packages
import * as React from 'react'
import { List, Popup } from 'semantic-ui-react'

// Import Components
import { getPasswordConfirmPopup, getPasswordPopup } from './Validation'

// Import Styles
import { CustomInput, CustomPopupIcon } from './Styled'

interface Props {
  fluid: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: any) => void
  password: string
  passwordConfirm: string
  passwordConfirmError: boolean
  passwordError: boolean
}

interface State {
  popup: any
}

export default class PasswordInputs extends React.Component<Props, State> {
  public state = {
    popup: {
      password: {
        isValid: true,
        status: [{ message: '', valid: false }]
      },
      passwordConfirm: {
        isValid: true,
        status: [{ message: '', valid: false }]
      }
    }
  }

  public componentDidMount = async () => {
    const { popup } = this.state
    popup.password = getPasswordPopup('')
    popup.passwordConfirm = getPasswordConfirmPopup('', '')
    this.setState({ popup })
  }

  public render() {
    const { fluid, password, passwordConfirm, passwordConfirmError, passwordError } = this.props
    const { popup } = this.state
    return (
      <React.Fragment>
        <Popup
          on="focus"
          trigger={
            <CustomInput
              className={password === '' ? 'hidden-icon' : ''}
              type="password"
              placeholder="New Password"
              name="password"
              fluid={fluid}
              icon={popup.password.isValid ? 'check circle' : 'warning circle'}
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChange('password', event)}
              error={passwordError}
            />
          }
        >
          <List>
            {popup.password.status.map((status, index) => {
              return (
                <List.Item key={index}>
                  <CustomPopupIcon name={status.valid ? 'check circle' : 'warning circle'} />
                  <List.Content>{status.message}</List.Content>
                </List.Item>
              )
            })}
          </List>
        </Popup>
        <CustomInput
          className={passwordConfirm === '' ? 'hidden-icon' : ''}
          type="password"
          placeholder="Confirm New Password"
          name="passwordConfirm"
          fluid={fluid}
          icon={popup.passwordConfirm.isValid ? 'check circle' : 'warning circle'}
          value={passwordConfirm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChange('passwordConfirm', event)}
          error={passwordConfirmError}
        />
      </React.Fragment>
    )
  }

  private handleChange = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    const { popup } = this.state
    if (name === 'password') {
      popup.password = getPasswordPopup(event.target.value)
    }
    if (name === 'passwordConfirm') {
      popup.passwordConfirm = getPasswordConfirmPopup(this.props.password, event.target.value)
    }
    this.setState({ popup })
    onChange(event, { name, value: event.target.value })
  }
}
