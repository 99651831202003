import Colors from 'design/Colors'
import RPFlag from 'design/images/RPFlag.png'
import RPLine from 'design/images/RPLine.png'
import { x, y } from 'design/Styled'
import styled from 'styled-components'

export const Container = styled.section`
  ${y};
  position: relative;
  background: ${Colors.Container};
  width: 8.5in;
  height: 11in;
  margin: 0 auto;
  padding: 0;
`

export const Hero = styled.div`
  display: flex;
  width: 100%;
  height: 4in;
  flex: 1;
`

export const Details = styled.div`
  display: flex;
  width: 100%;
  height: 2.5in;
`

export const Row = styled.div`
  ${x};
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 1.9in;
  padding-top: 0.1in;
  background: #eee;
`

export const Image = styled.div`
  ${x};
  justify-content: center;
  width: 32%;
  height: 100%;
  background: #444;
  box-shadow: 0 0 0.4in -0.4in #333;
`

export const Info = styled.div`
  height: 1.5in;
  ${x};
  justify-content: center;
  width: 100%;
  padding: 0.3in;

  p {
    text-align: center;
  }
`

export const Description = styled.div`
  width: 50%;
  height: 100%;
  padding: 0.2in;
`

export const ImageCol = styled.div`
  width: 60%;
  height: 100%;
  background: #444;
  height: 3in;
`

export const OwnerImage = styled.div`
  width: 30%;
  height: 80%;
  background: #444;
  margin-top: 20px;
  margin-left: 20px;
`

export const OwnerDetail = styled.div`
  padding: 0.5in;

  p {
    text-align: left;
  }
`

export const Text = styled.div`
  margin-bottom: 1em;
`

export const CompanyLogo = styled.div`
  ${y} justify-content: center;
  align-items: center;
  height: 1in;
  background-color: #444;
`

export const Logo = styled.div`
  width: 10%;
  height: 55%;
  background: #444;
`

export const MLSId = styled.div`
  font-size: 13pt;
  color: white;
  font-weight: bold;
  padding-bottom: 0.2em;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1in;
`

export const Category = styled.div`
  font-size: 20pt;
  font-family: 'Fredericka the Great';
  text-transform: uppercase;
  color: ${Colors.Text};
  text-align: center;
`

export const LeftRP = styled.div`
  background: url(${RPFlag});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  width: 38px;
`

export const RightRP = styled.div`
  background: url(${RPFlag});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  width: 38px;
  transform: rotate(180deg);
`

export const Line = styled.div`
  background: url(${RPLine});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 6px;
  width: 38%;
`
