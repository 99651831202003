import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { x } from 'design/Styled'

interface Props {
  percent?: number
}

const bar = css`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease-in;
`

const text = css`
  content: '${(props: Props) => props.percent || 0}%';
  font: ${fonts.xSmall.medium};
  padding: 0.4em;
`

export const Container = styled.div`
  ${x};
  position: relative;
  width: 100%;
  min-height: 20px;
  background: ${Colors.LoadingStripeEven};

  @supports not (clip-path: inset(0 0 0 100%)) {
    .clip-path {
      display: none;
    }
  }
`

export const Clipping = styled.div`
  ${bar};
  clip-path: inset(
    0 ${(props: Props) => (props.percent ? 100 - (props.percent || 100) : 100)}%
      0 0
  );
  z-index: 1;

  &:before {
    ${text};
    color: ${Colors.TextLight};
  }
`

export const ProgressBar = styled.div`
  ${bar};
  background-color: ${Colors.LoadingStripeOdd};
  transform: translate3d(
    ${(props: Props) => (props.percent || 0) - 100}%,
    0,
    0
  );
`

export const ProgressText = styled.div`
  ${bar};

  &:before {
    ${text};
    color: ${Colors.TextDark};
  }
`
