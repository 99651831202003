import gql from 'graphql-tag'

export const documentMarkupFragment = gql`
  fragment DocumentMarkupFragment on DocumentMarkup {
    id
    label {
      text
      value
    }
    value
    page
    top
    left
    width
    height
    style {
      fontSize
      fontFamily
    }
  }
`

export const documentFragment = gql`
  fragment DocumentFragment on Document {
    _id
    createdAt
    fileName
    fileType
    isActive
    isLocked
    isViewed
    password
    rotation
    thumbnailUrl
    url
  }
`
