import { isEqual } from 'lodash'
import * as React from 'react'
import { Component } from 'react'
import client from 'queries/apollo'
import * as Selectors from 'store/Settings/TransactionFee/Selectors'
import Strings from 'utils/Strings'
import { GET_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Queries'
import { activeTransactionSettingIndex } from '../FeeSettings'
import GraphSection from './GraphSection'

import { Dividers, GraphColumn } from './Styled'

import TransactionSettingQuery from 'app/Settings/TransactionFee/TransactionFeeDetails/FeeSettings/TransactionSettingQuery'

interface FeeData {
  expectedCommission: number
  propertyFee: number
  commissionFee: number
  otherFee: number
  totalFee: number
}

interface State {
  residentialFeeData: FeeData
  commercialFeeData: FeeData
}

class GraphColumnComponent extends Component<{}, State> {
  public state = {
    commercialFeeData: {
      commissionFee: 0,
      expectedCommission: 0,
      otherFee: 0,
      propertyCost: 0,
      propertyFee: 0,
      totalFee: 0
    },
    residentialFeeData: {
      commissionFee: 0,
      expectedCommission: 0,
      otherFee: 0,
      propertyCost: 0,
      propertyFee: 0,
      totalFee: 0
    }
  }

  private intialData: any = {}

  public onChangePrincipalPropertyCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    client.writeData({ data: { propertyFee: parseInt(e.target.value, 10) } })
  }

  public onChangeExpectedCommission = (e: React.ChangeEvent<HTMLInputElement>) => {
    client.writeData({ data: { expectedCommission: parseInt(e.target.value, 10) } })
  }

  public componentDidMount() {
    this.getCommercialCalculatedData()
    this.getResidentialCalculatedData()
  }

  public getResidentialCalculatedData = async () => {
    const residentialFeeData = await Selectors.getResidentialCalculatedData()
    this.setState({ residentialFeeData })
  }

  public getCommercialCalculatedData = async () => {
    const commercialFeeData = await Selectors.getCommercialCalculatedData()
    this.setState({ commercialFeeData })
  }

  public render() {
    const { residentialFeeData, commercialFeeData } = this.state
    return (
      <GraphColumn>
        <TransactionSettingQuery query={GET_TRANSACTION_SETTING} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) {
              return 'Loading...'
            }
            if (error) {
              return `Error! ${error.message}`
            }
            if (data && data.getTransactionSettings && data.getTransactionSettings[activeTransactionSettingIndex]) {
              if (!isEqual(this.intialData, data.getTransactionSettings[activeTransactionSettingIndex])) {
                this.getCommercialCalculatedData()
                this.getResidentialCalculatedData()
                this.intialData = data.getTransactionSettings[activeTransactionSettingIndex]
              }

              return (
                <div>
                  <GraphSection title={Strings.transactionFeeSettings.types.residential.name} {...residentialFeeData} />
                  <Dividers />
                  <GraphSection title={Strings.transactionFeeSettings.types.commercial.name} {...commercialFeeData} />
                </div>
              )
            }
            return null
          }}
        </TransactionSettingQuery>
      </GraphColumn>
    )
  }
}

export default GraphColumnComponent
