import * as React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import LoadingIndicator from 'shared/LoadingIndicator'
import Sidebar from './Sidebar'
import SubNavigation from './SubNavigation'
import { Activity, Commission, Documents, Notes, Workflow } from './SubPages'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'

import {
  getTransactionCommission,
  getTransactionDetails,
  getTransactionDocChecklist as getChecklist,
  getTransactionDocument,
  getTransactionMessage
} from 'app/Transactions/Dashboard/TransactionQueries'

import {
  getLoggedInUser,
  Routes
} from 'utils'

import {
  Container,
  Return,
  Row,
  Section,
} from './Styled'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { ChecklistItemType } from 'app/Transactions/Details/SubPages/Documents/Checklist/Types'
import { Messages as MessageType } from 'store/Transactions/Types'
import { RouteParams, TransactionType, UserType } from './Types'

interface StoreProps {
  documentCount: number
  messageCount: any
  setChecklist: (data: ChecklistItemType) => void
  setCommission: (data: any) => void
  setDocumentCount: (count: number) => void
  setMessagesData: (messages: MessageType[]) => void
  setPaymentApprove: (approve: boolean) => void
  setPaymentReleased: (release: boolean) => void
  setShareUsers: (data: any) => void
  setTransaction: (transaction: TransactionType) => void
  setTransactionDocuments: (data: any) => void
  transaction: TransactionType
}

type Props = StoreProps & RouteComponentProps<RouteParams>

interface State {
  loading: boolean
  user: UserType
}

const {
  activity: { path: activityPath },
  commission: { path: commissionPath },
  documents: { path: documentsPath },
  messages: { path: messagesPath },
  root: rootPath,
  workflow: { path: workflowPath }
} = Routes.transactions

class TransactionDetails extends React.Component<Props, State> {
  public state = {
    loading: true,
    user: {} as UserType
  }

  public componentDidMount = async () => {
    const user: any = (await getLoggedInUser({ fromCache: true })) || ({} as UserType)
    this.setState({ user })

    await this.refreshTransactionDetails()
    this.setState({ loading: false })
  }

  public componentDidUpdate = async (prev: Props) => {
    const { match, transaction } = this.props

    if (transaction._id !== match.params.id) {
      await this.refreshTransactionDetails()
      this.setState({ loading: false })
    }
  }

  public render = () => {
    const {
      documentCount,
      match: { params },
      messageCount,
      transaction
    } = this.props

    const { loading, user } = this.state

    return (
      <Container>
        {loading && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
        {transaction.owner && (
          <React.Fragment>
            <Sidebar transaction={transaction} />
            <Section primary={true}>
              <Row>
                <SubNavigation
                  count={{
                    documents: documentCount,
                    notes: messageCount
                  }}
                  subpage={params.subpage}
                  onNavigate={this.handleSubNavigation}
                />
                <Return
                  content={'Back'}
                  onClick={this.handleReturnNavigation}
                />
              </Row>
              <Switch>
                <Route
                  path={rootPath + '/:id' + documentsPath}
                  render={() => (
                    <Documents
                      transaction={transaction}
                      onAction={() => { return }}
                    />
                  )}
                />
                {user.role !== 'GUEST' && (
                  <Route
                    path={rootPath + '/:id' + commissionPath}
                    render={() => (
                      <Commission
                        transaction={transaction}
                        user={user}
                      />
                    )}
                  />
                )}
                <Route
                  path={rootPath + '/:id' + messagesPath}
                  render={() => (
                    <Notes transactionId={transaction._id} />
                  )}
                />
                {user.role !== 'GUEST' && (
                  <Route
                    path={rootPath + '/:id' + workflowPath}
                    component={Workflow}
                  />
                )}
                {user.role !== 'GUEST' && (
                  <Route
                    path={rootPath + '/:id' + activityPath}
                    render={() => (
                      <Activity transactionId={transaction._id} />
                    )}
                  />
                )}
              </Switch>
            </Section>
          </React.Fragment>
        )}
      </Container>
    )
  }

  private refreshTransactionDetails = async () => {
    const {
      match: {
        params: { id }
      },
      setChecklist,
      setCommission,
      setDocumentCount,
      setMessagesData,
      setPaymentApprove,
      setPaymentReleased,
      setShareUsers,
      setTransaction,
      setTransactionDocuments
    } = this.props

    const details: any = await getTransactionDetails(id)
    setTransaction(details[0])
    setShareUsers(details[0].shareWith)

    const messages: any = await getTransactionMessage(id)
    setMessagesData(messages)

    const response: any = await getTransactionCommission(id)
    if (details && response) {
      setCommission(response)
      setPaymentApprove(response.isApproved)
      setPaymentReleased(response.isReleased)
    }

    const checklist = await getChecklist(id)
    setChecklist(checklist)

    const documents: any = await getTransactionDocument(id)
    setTransactionDocuments(documents)

    const count = documents.filter((element: any) => !element.isViewed)
    setDocumentCount(count.length)
  }

  private handleSubNavigation = (subpage: string) => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const details = Routes.transactions.root
    this.props.history.replace({
      pathname: [details, id, subpage].join('/')
    })
  }

  private handleReturnNavigation = () => {
    this.props.history.goBack()
  }
}

const mapStateToProps = (state: AppState) => ({
  documentCount: state.transactions.documentCount,
  messageCount: state.transactions.messageCount,
  transaction: state.transactions.transactionDetail
})

export default connect(
  mapStateToProps,
  {
    setChecklist: Actions.getTransactionDocumentChecklist,
    setCommission: Actions.getCommission,
    setDocumentCount: Actions.documentCount,
    setMessagesData: Actions.getMessagesData,
    setPaymentApprove: Actions.paymentApprove,
    setPaymentReleased: Actions.paymentReleased,
    setShareUsers: Actions.getShareWithUser,
    setTransaction: Actions.getTransactionDetail,
    setTransactionDocuments: Actions.getTransactionDocuments
  }
)(TransactionDetails)
