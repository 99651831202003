import * as React from 'react'
import { connect } from 'react-redux'

import Modal from 'shared/Modal'

// Tasks
import { createTaskNotes } from 'app/Tasks/TaskMutations'
import * as TaskActions from 'store/Tasks/Actions'
import { TaskCardDetails, TaskNotes } from 'store/Tasks/Types'

import { Strings } from 'utils'

import { Aside, Container, Content, CreateButton, Image, Notepad } from './Styled'

interface Props {
  addNewNotes: (note: TaskNotes, cardId: string) => void
  onClose: () => void
  taskData: TaskCardDetails
}

interface State {
  loading: boolean
  noteText: string
  transition: boolean
}

class AddNoteModal extends React.Component<Props, State> {
  public state = {
    loading: false,
    noteText: '',
    transition: true
  }

  public render() {
    const { loading, transition } = this.state
    return (
      <Modal
        content={
          <Container>
            <Aside>
              <Image src={Strings.tasks.noteAddImage} />
            </Aside>
            <Content>
              <Notepad autoFocus="true" onChange={this.handleNoteChange} placeholder="Add a Note" rows={5} />
              <CreateButton loading={loading} compact={true} size="small" onClick={this.addNewNote}>
                Submit
              </CreateButton>
            </Content>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        width={500}
      />
    )
  }

  private handleNoteChange = (e: React.SyntheticEvent, data: any) => {
    this.setState({ noteText: data.value })
  }

  private addNewNote = async () => {
    const { addNewNotes, taskData } = this.props
    const { noteText } = this.state
    this.setState({ loading: true })

    const response = await createTaskNotes(taskData._id, noteText)
    if (response) {
      addNewNotes(response, taskData._id)
      return this.closeSelf()
    }
    this.setState({ loading: false })
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({
        transition: true
      })
      onClose()
    }, 300)
  }
}

export default connect(
  null,
  {
    addNewNotes: TaskActions.addNewNotes
  }
)(AddNoteModal)
