// Import Packages
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface CategoryHeaderProps {
  totalindex: number
}

interface ActionsWrapperProps {
  ishidden: boolean
}

interface ImageWrapperProps {
  isMinus?: boolean
}

interface CategoryWrapperProps {
  propertynumber?: number
}

const ActionsWrapper = styled.div`
  display: ${(props: ActionsWrapperProps) => props.ishidden ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 11rem;
  width: 50px;

  .svg-inline--fa {
    color: ${Colors.Black500};
    cursor: pointer;
  }
`

const ActionImageWrapper = styled.div`
  img {
    width: 12px;
    height: 12px;
  }
`

const CategoryWrapper = styled(Segment)`
  &.ui.segment {
    display: block;
    height:  ${(props: CategoryWrapperProps) => props.propertynumber ? `calc(${props.propertynumber * 45}px + 40px)` : 'auto'};
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    background-color: ${Colors.transparent};
  }
`

const CategoryHeader = styled(Segment.Group)`
  &.ui.horizontal.segments {
    cursor: pointer;
    display: flex;
    height: 35px;
    border: ${(props: CategoryHeaderProps) => props.totalindex % 2 === 0 ? `dashed 1.5px ${Colors.Grey400}` : 'none'};
    border-radius: 4px;
    padding: 0 2rem;
    margin: 0;
    margin-bottom: .5rem;
    background: ${(props: CategoryHeaderProps) => props.totalindex % 2 === 0 ? Colors.White1000 : Colors.White500};
    position: relative;
    ${ActionsWrapper} {
      visibility: hidden;
    }
    &:hover {
      ${ActionsWrapper} {
        visibility: visible;
      }
    }
  }
`

const CategoryNameText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.Black500};
  text-transform: uppercase;
  line-height: 1rem;
  align-self: center;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: .7rem;
  img {
    width: 15px;
    height: ${(props: ImageWrapperProps) => props.isMinus ? 1 : 15}px;
  }

  .svg-inline--fa {
    color: ${Colors.DarkBlue200};
  }
`

const StyledItem = styled.div`
  &.demo8-item {
    transform-origin: 50% 50% 0px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 400;
    pointer-events: auto;
    height: 35px;
    width: 100%;
    line-height: 35px;
    position: absolute;
    overflow: visible;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
`

export {
  ActionImageWrapper,
  ActionsWrapper,
  CategoryHeader,
  CategoryNameText,
  CategoryWrapper,
  ImageWrapper,
  StyledItem
}
