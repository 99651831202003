import { Reducer } from 'redux'
import { merge as _merge } from 'lodash'

import {
  DispatchEnum,
  UserPassType,
  UserPassDictType,
  UserPassState,
} from './Types'

const initialState: UserPassState = {
  dict: {} as UserPassDictType,
  list: [] as string[],
  page: {} as UserPassType.Pagination
}

const UserPassReducer: Reducer<UserPassState> = (
  state: UserPassState = initialState,
  action
) => {
  switch (action.type) {
    case DispatchEnum.SetUserPass: {
      const { payload: { _id }} = action
      const current: UserPassType.Base = state.dict[_id] || {} as UserPassType.Base
      const update: UserPassType.Base = _merge(current, action.payload)

      state.dict[_id] = update

      return {
        ...state,
        dict: { ...state.dict },
      }
    }

    case DispatchEnum.SetUserPassDict: {
      const dict = action.payload.results.reduce((obj: UserPassDictType, item: UserPassType.Base) => {
        obj[item._id] = item
        return obj
      }, state.dict)

      return {
        ...state,
        dict: { ...dict },
      }
    }

    case DispatchEnum.SetUserPassList: {
      const dict = action.payload.results.reduce((obj: UserPassDictType, item: UserPassType.Base) => {
        obj[item._id] = item
        return obj
      }, state.dict)
      const list = action.payload.results.map((item: UserPassType.Base) => item._id)
      return {
        ...state,
        dict: { ...dict },
        list: [ ...list ],
        page: { ...action.payload.pagination }
      }
    }

    default:
      return state
  }
}

export default UserPassReducer
