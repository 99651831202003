export const handleCapSettingValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (
    !fields[`individualResidentialCap`] ||
    !fields[`individualResidentialCap`].match(/^\d*$/)
  ) {
    formIsValid = false
    errors[`individualResidentialCap`] = true
  }

  if (
    !fields[`individualCommercialCap`] ||
    !fields[`individualCommercialCap`].match(/^\d*$/)
  ) {
    formIsValid = false
    errors[`individualCommercialCap`] = true
  }

  return { errors, formIsValid }
}
