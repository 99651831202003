// Import Graphql Queries
import { GET_USER_WALLPAPER } from 'queries/graphql/Passes/Queries'

// Import Components
import client from 'queries/apollo'

export const getUserWallpaper = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_WALLPAPER
  })

  return response.data.getUserWallpaper
}
