// Import Packages
import * as React from 'react'
import { Icon } from 'semantic-ui-react'

// Import Components
import KanbanLaneHeaderPopup from './KanbanLaneHeaderPopup'

// Import Styled Components
import {
  Count,
  CounterIcon,
  CounterItems,
  DownButton,
  EditBoardName,
  Header,
  PopupWrapper,
  RecordCount,
  StyledIcon,
  StyledIconContainer,
  StyledInput,
  StyledPopup,
  Title
} from './Styled'

interface Props {
  setColor: (color: string) => void
  setNewIcon: (id: string, icon: string) => void
  setNewDescription: (id: string, description: string) => void
  setNewBoardName: (id: string, boardName: string) => void
  ActionComponent: React.ReactType
  color: string
  itemsLength: number
  title: string
  boardId: string
  icons: any[]
  icon: any
  description: string
  appearance?: boolean
  showAddButton: boolean
  showAppearance?: boolean
  counterIcon?: string
  counterItems?: number
}

interface State {
  boardName: string
  isOpen: boolean
  showInputBox: boolean
}

class KanbanLaneHeader extends React.Component<Props, State> {
  public state = {
    boardName: '',
    isOpen: false,
    showInputBox: false
  }

  public render() {
    const {
      ActionComponent,
      boardId,
      color,
      itemsLength,
      title,
      icons,
      icon,
      description,
      appearance,
      showAddButton,
      showAppearance,
      counterIcon,
      counterItems
    } = this.props
    const { boardName, showInputBox } = this.state
    const toggleFunction = () => {
      this.onToggle()
      this.setState({ boardName: title })
    }
    return (
      <Header>
        <Title color={color}>
          {icon && (
            <StyledIconContainer>
              {description ? (
                <StyledPopup
                  position="top left"
                  trigger={<StyledIcon background={icon.imageUrl} />}
                  content={description}
                />
              ) : (
                <StyledIcon background={icon.imageUrl} />
              )}
            </StyledIconContainer>
          )}
          {showInputBox ? (
            <EditBoardName>
              <StyledInput
                placeholder="Search..."
                value={boardName}
                onChange={this.onChangeBoardName}
                width={icon ? 80 : 90}
              />
              <Icon name="check" onClick={this.setNewBoardName} />
            </EditBoardName>
          ) : (
            <div
              onClick={toggleFunction}
              style={{ marginTop: '2px', wordBreak: 'break-word' }}
            >{`${title.toUpperCase()}`}</div>
          )}
          {counterItems && counterItems > 0 && title !== 'Completed' ? (
            <CounterItems>
              {counterIcon !== '' && <CounterIcon name={counterIcon} />}
              <Count>{counterItems}</Count>
            </CounterItems>
          ) : null}
        </Title>
        <RecordCount color={color}>{`${itemsLength} Records`}</RecordCount>
        <DownButton>
          <PopupWrapper
            trigger={
              <div>
                | <Icon name="caret down" />
              </div>
            }
            content={
              <KanbanLaneHeaderPopup
                closeActionPopup={this.closeActionPopup}
                title={title}
                description={description}
                color={color}
                setColor={this.setColor}
                boardId={boardId}
                ActionComponent={ActionComponent}
                icons={icons}
                setIcon={icon}
                setNewIcon={this.setNewIcon}
                setNewDescription={this.setNewDescription}
                appearance={appearance}
                showAddButton={showAddButton}
                showAppearance={showAppearance}
              />
            }
            open={this.state.isOpen}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            hoverable={true}
            position={'bottom left'}
          />
        </DownButton>
      </Header>
    )
  }

  private handleOpen = () => {
    this.setState({ isOpen: true })
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private closeActionPopup = () => {
    this.setState({ isOpen: false })
  }

  private onChangeBoardName = (e: any) => {
    this.setState({ boardName: e.target.value })
  }

  private setNewBoardName = () => {
    const { boardId, setNewBoardName } = this.props
    const { boardName } = this.state
    setNewBoardName(boardId, boardName)
    this.onToggle()
  }

  private onToggle = () => {
    const { showInputBox } = this.state
    const { showAppearance, appearance } = this.props
    if (showAppearance || !appearance) {
      this.setState({ showInputBox: !showInputBox })
    }
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    setNewDescription(id, description)
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    setNewIcon(id, icon)
  }

  private setColor = (color: string) => {
    const { setColor } = this.props
    setColor(color)
  }
}

export default KanbanLaneHeader
