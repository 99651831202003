import * as React from 'react'
import {
  SliderContainer,
  SliderGroupContent,
  SliderMaxRange,
  SliderMinRange,
  SliderRangeContainer,
  SliderTitle,
  StyledOutput,
  StyledSlider
} from './Styled'

const SliderGroup = ({
  max,
  min,
  title,
  titleInfo,
  value,
  width,
  onChange
}: {
  max: number
  min: number
  title: string
  titleInfo?: string
  value: number
  width?: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <SliderGroupContent width={width}>
      <SliderTitle>{`${title} `}</SliderTitle>
      <SliderContainer>
        <StyledSlider type="range" name={`slider${title}`} min={min} max={max} value={value} onChange={onChange} step={'10000'} />
        <StyledOutput value={value} min={min} max={max}>{value !== 1000000 ? `$${value / 1000}K` : `$${value / 1000000}M`}</StyledOutput>
      </SliderContainer>
      <SliderRangeContainer>
        <SliderMinRange>{`${min}`}</SliderMinRange>
        <SliderMaxRange>{`${max / 1000000} M`}</SliderMaxRange>
      </SliderRangeContainer>
    </SliderGroupContent>
  )
}

export default SliderGroup
