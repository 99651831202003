import gql from 'graphql-tag'

export const GET_ALGOLIA_PLACES_CREDENTIALS = gql`
  query getAlgoliaPlacesCredentials {
    getAlgoliaPlacesCredentials {
      appId
      apiKey
    }
  }
`
