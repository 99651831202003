export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`type`]) {
    formIsValid = false
    errors[`type`] = true
  }

  return { errors, formIsValid }
}
