import { css, keyframes } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

export interface Props {
  align?: string
  childWidths?: number | string
  custom?: any
  error?: boolean
  height?: number
  isActive?: boolean | number
  justify?: string
  monochrome?: boolean | number | string
  overflow?: string
  padding?: number | string
  primary?: boolean | number
  size?: number
  spacing?: number
  width?: number
}

export const scrollSize = 3
export const scrollPadding = 8

const isPrimary = ({ primary }: Props) => {
  if (typeof primary === 'boolean') {
    return 'flex: 1'
  }

  if (typeof primary === 'number') {
    return css`
      flex: ${primary};
    `
  }

  return ''
}

const getPadding = ({ padding }: Props) => {
  if (typeof padding === 'number') {
    return `${padding}em`
  }

  if (typeof padding === 'string') {
    return padding
  }

  return 0
}

const getChildWidths = (props: Props) => {
  if (typeof props.childWidths === 'number') {
    const widths = css`
      & > :nth-child(n) {
        width: ${props.childWidths}% !important;
      }
    `
    return widths
  }

  if (typeof props.childWidths === 'string') {
    const childArr = props.childWidths.split(' ').map((n: string, index: number) => {
      return `
        & > :nth-child(${index + 1}) {
          width: ${n}% !important;
        }
      `
    })
    return css`
      ${childArr.join(' ')};
    `
  }

  return ''
}

const getWidth = ({ width }: Props) => {
  if (!width) {
    return css`
      width: 100%;
    `
  }

  if (width && width > -1) {
    return css`
      width: ${width}%;
    `
  }

  return ``
}

const getHeight = ({ height }: Props) => {
  if (!height) {
    return ``
  }

  return css`
    height: ${height}%;
  `
}

const getOverflow = ({ overflow }: Props) => {
  if (!overflow) {
    return ``
  }

  return css`
    overflow: ${overflow};
  `
}

const getAlignment = ({ align }: Props) => {
  if (!align) {
    return ``
  }

  return css`
    align-items: ${align};
  `
}

export const x = css`
  ${isPrimary};
  ${getWidth};
  display: flex;
  flex-direction: row;
  align-items: ${(props: Props) => props.align || 'center'};
  justify-content: ${(props: Props) => props.justify || 'flex-start'};
  padding: ${getPadding};
`

export const y = css`
  ${isPrimary};
  ${getWidth};
  ${getHeight};
  ${getAlignment};
  ${getOverflow};
  display: flex;
  flex-direction: column;
  justify-content: ${(props: Props) => props.justify || 'flex-start'};
  padding: ${getPadding};
`

export const row = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const text = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Text};
  margin: 0;
  line-height: 1;
`

export const fa = css`
  font-family: 'Font Awesome 5 Free';
  font-size: 1.3em;
  font-weight: 900;
  color: ${Colors.TextDark};
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
`

export const headerMargin = css`
  margin-top: 0;
  margin-bottom: 0.5em;
`

export const children = css`
  & > * {
    margin-left: ${(props: Props) => props.spacing || 0.5}em;
    margin-right: ${(props: Props) => props.spacing || 0.5}em;
    &:empty {
      margin-left: 0;
      margin-right: 0;
    }
  }

  & > :first-child {
    margin-left: 0;
  }

  & > :last-child {
    margin-right: 0;
  }
`

export const icon = css`
  display: flex;
  padding: 0.25em 1em;
  transform: scale3d(0.9, 0.9, 0.9);
  transition: transform, color 200ms;
  cursor: pointer;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;

  .svg-inline--fa {
    height: 100%;
  }

  &:hover {
    transform: scale3d(1, 1, 1);
  }
`

export const modalBorderRadius = '4px'

export const primaryButton = css`
  margin: 0;
  min-width: 80px;
  text-transform: uppercase;
  color: ${Colors.White1000};
  background: ${Colors.ButtonPrimary};
  letter-spacing: 0.05em;
  border-radius: 0;

  &:hover {
    color: ${Colors.White1000};
    background: ${Colors.ButtonPrimaryHover};
  }
`

export const dangerButton = css`
  ${primaryButton};
  background: ${Colors.ButtonDanger};
  &:hover {
    background: ${Colors.ButtonDangerHover};
  }
`

export const primaryStyledButton = css`
  &.ui.button {
    ${primaryButton};
  }
`

export const dangerStyledButton = css`
  &.ui.button {
    ${dangerButton};
  }
`

const getToggleColor = (props: Props, defaultColor: string) => {
  if (props.monochrome && (typeof props.monochrome === 'boolean' || typeof props.monochrome === 'number')) {
    return Colors.ToggleOn
  } else if (props.monochrome && typeof props.monochrome === 'string') {
    return props.monochrome
  }
  return Colors[defaultColor]
}

export const styledToggle = css`
  &.ui.toggle.checkbox {
    height: ${(props: Props) => (props.size || 1) * 16}px;
    min-height: ${(props: Props) => (props.size || 1) * 16}px;
    &.fitted {
      width: ${(props: Props) => (props.size || 1) * 28}px;
    }
    label {
      height: ${(props: Props) => (props.size || 1) * 16}px;
      padding: 0;
      &:before {
        width: ${(props: Props) => (props.size || 1) * 28}px;
        height: ${(props: Props) => (props.size || 1) * 16}px;
        background-color: ${(props: Props) => getToggleColor(props, 'ToggleOff')} !important;
      }
      &:after {
        top: ${(props: Props) => (props.size || 1) * 2}px;
        left: ${(props: Props) => (props.size || 1) * 2}px;
        width: ${(props: Props) => (props.size || 1) * 12}px;
        height: ${(props: Props) => (props.size || 1) * 12}px;
      }
    }
    input {
      width: ${(props: Props) => (props.size || 1) * 28}px;
      height: ${(props: Props) => (props.size || 1) * 16}px;
    }
    input:focus:checked ~ .box,
    input:focus:checked ~ label,
    input:checked ~ .box,
    input:checked ~ label {
      &:before {
        background-color: ${(props: Props) => getToggleColor(props, 'ToggleOn')} !important;
      }
      &:after {
        left: ${(props: Props) => (props.size || 1) * 14}px;
      }
    }
  }
`

export const toggleItem = css`
  color: ${(props: Props) => (props.isActive ? getToggleColor(props, 'ToggleOn') : getToggleColor(props, 'ToggleOff'))};
  transition: color 300ms;
`

export const toolTip = css`
  background: ${Colors.ToolTip};
  border: 0;
  box-shadow: none !important;
  color: ${Colors.TextLight};
  font: ${fonts.xSmall.regular};
  opacity: 0.9;
  padding: 0.3em 0.5em;

  &:hover {
    opacity: 1;
  }

  & > div {
    z-index: 1;
  }

  &:before {
    background: ${Colors.ToolTip} !important;
    border: 0;
    box-shadow: none !important;
    z-index: 0;
  }
`

export const styledToolTip = css`
  &.ui.popup {
    ${toolTip};
  }
`

export const childWidths = css`
  ${getChildWidths};
`

export const scrollbars = css`
  ::-webkit-scrollbar {
    width: ${scrollSize}px;
    height: ${scrollSize}px;
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.transparent};
  }
`

export const scrollbarYPadding = css`
  padding-right: ${scrollPadding}px;
`

export const scrollYContainer = css`
  ${scrollbars};
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

export const scrollXContainer = css`
  ${scrollbars};
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
`

export const scrollbarXPadding = css`
  padding-bottom: ${scrollPadding}px;
`

export const link = css`
  text-decoration: underline;
  color: ${Colors.Green70};
  cursor: pointer;

  &:visited {
    color: ${Colors.Green70};
    text-decoration: underline;
  }

  &:hover,
  &:active,
    color: ${Colors.TextLinkHover};
    text-decoration: underline;
  }
`

export const linkExternal = css`
  text-decoration: underline;
  color: ${Colors.TextLink};
  cursor: pointer;

  &:visited {
    color: ${Colors.TextLink};
    text-decoration: underline;
  }

  &:hover,
  &:active,
    color: ${Colors.TextLinkHover};
    text-decoration: underline;
  }
`

export const inputError = css`
  color: ${Colors.ButtonDanger} !important;
  border-width: 1px;
  border-style: solid;
  border-color: ${Colors.ButtonDanger} !important;
  box-shadow: inset 0 0 4px -1px ${Colors.ButtonDanger} !important;

  &::placeholder {
    color: ${Colors.ButtonDanger} !important;
  }

  &:focus {
    border-color: ${Colors.ButtonDanger} !important;
    color: ${Colors.ButtonDanger} !important;
    box-shadow: inset 0 0 4px -1px ${Colors.ButtonDanger} !important;
  }
`

export const pinStripes = css`
  background-image: linear-gradient(
    165deg,
    ${Colors.TextDisable} 10%,
    ${Colors.Container} 10%,
    ${Colors.Container} 50%,
    ${Colors.TextDisable} 50%,
    ${Colors.TextDisable} 60%,
    ${Colors.Container} 60%,
    ${Colors.Container} 100%
  );
  background-size: 19.32px 5.18px;
  background-repeat: repeat;
`

const placeholderKeyframes = keyframes`
  0% {
    background: ${Colors.PlaceholderOdd};
  }

  50% {
    background: ${Colors.PlaceholderEven};
  }

  100% {
    background: ${Colors.PlaceholderOdd};
  }
`

export const placeholder = css`
  animation: ${placeholderKeyframes} ${2500 + Math.round(Math.random() * 1000)}ms ease-in-out infinite;
`
