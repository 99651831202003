// Import Packages
import Griddle from 'griddle-react'
import styled from 'styled-components'

const StyledGrid = styled(Griddle)`
  &.griddle-cell {
    height: 50px;
  }
`

interface ImageProps {
  backgroundImage: string
}

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin: 0px 5px;
  display: inline-block;
`

const Infograph = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`

export { ImageWrapper, Infograph, StyledGrid }
