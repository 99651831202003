// Import Packages
import { fonts } from 'design/Fonts'
import { Label } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface TextProps {
  imageUrl: string
}

const BtnText = styled.div`
  color: ${Colors.White400};
  font: ${fonts.medium.regular};
  font-weight: 500;
  display: flex;
  align-items: center;
  &:before {
    display: inline-block;
    content: '';
    width: 15px;
    height: 20px;
    margin-right: 8px;
    margin-top: -1px;
    background-image: url(${(props: TextProps) => props.imageUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  /* BELL */
  @-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  }

  @keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  }

  .faa-ring.animated,
  .faa-ring.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-ring {
    -webkit-animation: ring 3s ease infinite;
    animation: ring 3s ease infinite;
  }

  svg.fa-cog {
    transform: rotate(1deg);
    transition: transform 500ms ease-in-out;
    :hover {
      transform: rotate(179deg);
    }
  }

  .fa-bell {
    width: 25px;
    height: 25px;
    color: ${Colors.DarkBlue200};
    cursor: pointer;
  }

  .fa-calendar-alt {
    width: 25px;
    height: 25px;
    color: ${Colors.DarkBlue200};
    margin-left: 20px;
    cursor: pointer;
    margin-top: -4px;
  }

  .fa-cog {
    width: 25px;
    height: 25px;
    color: ${Colors.DarkBlue200};
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .fa-tools {
    width: 25px;
    height: 25px;
    color: ${Colors.DarkBlue200};
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .fa-plus-circle {
    width: 22px;
    height: 22px;
    margin: 0 15px;
    object-fit: contain;
    object-position: center;
    color: ${Colors.DarkBlue200};
    cursor: pointer;
  }

  .fa-search {
    width: 22px;
    height: 22px;
    margin: 0 15px;
    object-fit: contain;
    object-position: center;
    color: ${Colors.DarkBlue200};
    cursor: pointer;
  }
`

const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${Colors.DarkBlue400};
  margin: 0 20px;
`

const Labels = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
    position: absolute;
    top: -8px;
    left: 10px;
    z-index: 1;
  }
`

export {
  BtnText,
  Divider,
  FlexContainer,
  Labels
}
