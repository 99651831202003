const Actions = {
  officeAddNewBoard: 'ADD_OFFICE_NEW_BOARD',
  officeAddNewCard: 'ADD_OFFICE_NEW_CARD',
  officeChangestatus: 'CHANGE_OFFICE_STATUS',
  officeDeleteCard: 'DELETE_OFFICE_CARD',
  officeFilter: 'OFFICE_FILTER',
  officeGetBoardData: 'GET_OFFICE_BOARD_DATA',
  officeResetCardData: 'RESET_OFFICE_CARD_DATA',
  officeRestoreCard: 'RESTORE_OFFICE_CARD',
  officeSearchData: 'OFFICE_SEARCH_DATA',
  officeSearchLoader: 'OFFICE_SEARCH_LOADER',
  officeSetNewBoardName: 'SET_OFFICE_NEW_BOARD_NAME',
  officeSetNewDescription: 'SET_OFFICE_NEW_DESCRIPTION',
  officeSetNewIcon: 'SET_OFFICE_NEW_ICON'
}

export default Actions
