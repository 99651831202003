import gql from 'graphql-tag'

export const REGISTER_TENANT = gql`
  mutation registerUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $password: String!
    $facebook: FacebookInput
    $google: GoogleInput
    $referralId: ID
    $verificationType: VerificationType
  ) {
    registerNewTenant: createTenant(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        password: $password
        facebook: $facebook
        google: $google
        referralId: $referralId
        verificationType: $verificationType
      }
    ) {
      _id
      errors {
        code
        type
        message
      }
    }
  }
`

export const UPDATE_TENANT = gql`
  mutation updateTenant($input: TenantInput) {
    tenant: updateTenant(input: $input) {
      _id
    }
  }
`

export const ONBOARD_TENANT_DETAILS = gql`
  mutation onboardTenantDetails(
    $adminEmail: EmailInput
    $city: String
    $companyName: String
    $country: String
    $homeAddress: AddressInput
    $mls: ID
    $officeAddress: AddressInput
    $officeEmail: String
    $officePhone: String
    $realtyPassEmail: EmailInput
    $state: String
    $street: String
    $userId: ID
    $zipCode: String
  ) {
    homeAddress: updateUser(
      where: { _id: $userId }
      input: { addresses: $homeAddress }
    ) {
      _id
    }

    officeAddress: updateUser(
      where: { _id: $userId }
      input: { addresses: $officeAddress }
    ) {
      _id
    }

    adminEmail: updateUser(
      where: { _id: $userId }
      input: { email: $adminEmail }
    ) {
      _id
    }

    realtyPassEmail: updateUser(
      where: { _id: $userId }
      input: { email: $realtyPassEmail }
    ) {
      _id
    }

    office: createOffice(
      input: {
        branchName: $companyName
        city: $city
        country: $country
        email: $officeEmail
        isDefault: true
        mls: $mls
        phone: $officePhone
        state: $state
        street: $street
        zipCode: $zipCode
      }
    ) {
      _id
    }
  }
`

export const HANDLE_OTP_LOGIN = gql`
  mutation handleOtpLogin($otp: String!) {
    OTPLogin: handleOtpLogin(otp: $otp)
  }
`
