import { Popup } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import {
  children,
  childWidths,
  styledToolTip,
  fa,
  x,
  y,
} from 'design/Styled'

interface Props {
  isActive?: boolean
}

const getHoverEffect = ({ isActive }:Props) => {
  if (!isActive) {
    return css``
  }
  return css`
    &:hover {
      background: ${Colors.ListEven};
    }
    cursor: pointer;
  `
}

export const Line = styled.div`
  ${x};
  ${children};
  ${childWidths};
  width: 100%;
  padding: 1em;
  padding-left: 2.2em;
  border-bottom: 1px solid ${Colors.TableLine};

  &:hover {
    background: ${Colors.ListOdd};
  }
`

export const HeadLine = styled(Line)`
  position: relative;
  color: ${Colors.TextPrimary};
  font-weight: bold;
  background: ${Colors.ListOdd};
  border-bottom: 1px solid ${Colors.TableHeadLine};

  ${getHoverEffect};
`

export const ChildLines = styled.section`
  ${y};
`

export const Expander = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  height: 100%;
  width: 2.2em;
  cursor: pointer;

  &:hover {
    background: ${Colors.ListEven};
  }

  &:before {
    ${fa};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    content: '\f0da';
    color: ${Colors.Text};
    transition: all 200ms;
    transform: ${(props:Props) => props.isActive ? `rotate(90deg)` : `rotate(0)`};
  }
`

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`
