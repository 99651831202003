import { Popup, Search } from 'semantic-ui-react'
import styled from 'styled-components'

import { inputError, scrollYContainer, y } from 'design/Styled'

export const Container = styled.div`
  width: 100%;
  & .input,
  & input {
    width: 100% !important;
  }

  &.error input {
    ${inputError};
  }
`

export const StyledSearch = styled(Search)`
  &.ui.search > .results .result {
    margin: 0;
    padding: 0.4em 1em;
  }

  &.ui.fluid.search .results {
    ${scrollYContainer};
    margin: 2px 0 0;
    height: auto;
    max-height: 200px;
    border-top: 0;
  }

  &.ui.loading.search .input > i.icon {
    &:before,
    &:after {
      left: 100%;
      margin-left: -2em;
    }
  }
`

export const Result = styled.div`
  ${y};
`

export const Title = styled.strong`
  width: 100%;
  text-align: left;
`

export const Subtitle = styled.span`
  width: 100%;
  text-align: left;
`

export const StyledPopup = styled(Popup)``
