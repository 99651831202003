// Import Packages
import { Container, TextArea } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Icons {
  icon?: string
  background?: string
}

const AppearanceContainer = styled.div`
  margin-top: 25px;
  display: flex;
`

const TabContainer = styled.div`
  max-width: 320px;
`

const StyledContainer = styled(Container)`
  &.ui.container {
    display: flex;
  }
  .ui.grid > .row > .column {
    margin-bottom: 20px;
  }
  .ui[class*='five column'].grid > .column:not(.row),
  .ui[class*='five column'].grid > .row > .column {
    width: 19%;
  }
`

const IconContainer = styled.div`
  cursor: pointer;
  background-color: ${(props: Icons) => (props.background ? props.background : Colors.White1000)};
  height: 45px;
  width: 45px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 3px solid ${Colors.White480};
`

const AppearanceIcons = styled.div`
  cursor: pointer;
  background: url(${(props: Icons) => props.icon || props.icon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
`

const Divider = styled.div`
  border: 1px solid ${Colors.Black1000};
  margin-top: 15px;
  margin-bottom: 15px;
`

const ColorsSelections = styled.div`
  margin-bottom: 30px;

  .ui.grid > .row {
    width: 95% !important;
  }
`

const ColorSet = styled.div`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 3px;
  margin-left: 8px;
  background-color: ${props => props.color || props.color};
`

const DefaultColorSet = styled.div`
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${props => props.color || props.color};
`

const StyledTextArea = styled(TextArea)`
  width: 95%;
  margin-top: 20px;
  border: none;
  color: ${Colors.Teal60};
  margin-bottom: 10px;
  padding: 10px;
`

export {
  AppearanceContainer,
  AppearanceIcons,
  ColorSet,
  ColorsSelections,
  DefaultColorSet,
  Divider,
  IconContainer,
  StyledContainer,
  StyledTextArea,
  TabContainer
}
