import { sortBy, merge } from 'lodash'
import {
  ManagerNotes,
  Messages,
  PropertyImageType,
  Transaction,
  TransactionFee,
  TransactionList,
  TransactionReferralFee,
  TransactionState
  // TransactionWorkflow
} from './Types'

export const addNewBoard = (state: TransactionState, index: number, newData: Transaction): Transaction[] => {
  const { allTransactions } = state
  const feeData = allTransactions.slice()
  feeData.splice(index, 0, newData)
  return [...feeData]
}

export const addNewCard = (
  state: TransactionState,
  id: string,
  index: string,
  newData: TransactionList
): TransactionState['allTransactions'] => {
  const { allTransactions } = state
  let feeData = allTransactions.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      const listLength = list.items.length
      const newList = list.items.slice()
      newList.splice(index ? index : listLength, 0, newData)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return feeData
}

export const setNewIcon = (state: TransactionState, id: string, icon: string): Transaction[] => {
  const { allTransactions } = state
  let feeData = allTransactions.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      return {
        ...list,
        icon: icon
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewDescription = (state: TransactionState, id: string, description: string): Transaction[] => {
  const { allTransactions } = state
  let feeData = allTransactions.slice()
  feeData = feeData.map((list: any) => {
    if (list.id === id) {
      return {
        ...list,
        description: description
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewBoardName = (state: TransactionState, id: string, boardName: string): Transaction[] => {
  const { allTransactions } = state
  let feeData = allTransactions.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        name: boardName
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const deleteCard = (state: TransactionState, boardId: string, cardId: string): Transaction[] => {
  const { allTransactions } = state
  let feeData = allTransactions.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === boardId) {
      const newList = list.items.slice()
      const listIndex = newList.findIndex((laneItem: any) => {
        return laneItem._id === cardId
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        items: newList,
        transactions: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const deleteRecord = (state: TransactionState, cardId: string): Transaction[] => {
  const { listData } = state
  const feeData = listData.slice()

  const index = feeData.findIndex((list: any) => {
    return list._id === cardId
  })
  feeData.splice(index, 1)
  return [...feeData]
}

export const restoreCard = (
  state: TransactionState,
  id: string,
  index: string,
  newData: TransactionList | null
): TransactionState['allTransactions'] => {
  if (newData) {
    const { allTransactions } = state
    let feeData = allTransactions.slice()
    feeData = feeData.map((items: any) => {
      if (items.id === id) {
        const newList = items.list.slice()
        newList.splice(index, 0, newData)
        return {
          ...items,
          list: newList,
          transactions: newList
        }
      } else {
        return items
      }
    })
    return feeData
  } else {
    return state.allTransactions
  }
}

export const addNewMessage = (state: TransactionState, newData: Messages): Messages[] => {
  const { messages } = state
  const feeData = messages.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const editMessage = (state: TransactionState, id: string, newData: Messages): Messages[] => {
  const { messages } = state
  const feeData = messages.slice()
  const messageIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  let message = { ...feeData[messageIndex] }
  message = newData
  feeData[messageIndex] = message
  return [...feeData]
}

export const deleteMessage = (state: TransactionState, id: string): Messages[] => {
  const { messages } = state
  const feeData = messages.slice()
  const messageIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  feeData.splice(messageIndex, 1)
  return [...feeData]
}

export const moveTransaction = (state: TransactionState, id: string, status: string, newData: any): any => {
  const { allTransactions } = state
  const oldTransactions = allTransactions.slice()
  const transactionObj = oldTransactions.find(txnObj => txnObj.name === status)
  let transactionObj2: any = ''
  newData.forEach((board: any) => {
    board.items.forEach((item: any) => {
      if (item._id === id) {
        transactionObj2 = board
      }
    })
  })
  if (status !== transactionObj2.name) {
    const newTransactionObj2 = oldTransactions.find(txnObj => txnObj.name === transactionObj2.name)
    let removeItem
    if (transactionObj && transactionObj.items) {
      const indexOfItemToDelete = transactionObj.items.findIndex(data => data._id === id)

      if (indexOfItemToDelete !== -1) {
        removeItem = transactionObj.items.splice(indexOfItemToDelete, 1)[0]
      }
    }

    if (removeItem && newTransactionObj2 && newTransactionObj2.items) {
      newTransactionObj2.items.push(removeItem)
    }
    return [...oldTransactions]
  } else {
    return [...oldTransactions]
  }
}

export const addFee = (state: TransactionState, newData: TransactionFee): TransactionFee[] => {
  const { commission } = state
  const fees = commission.fees ? [ ...commission.fees ] : []
  fees.push(newData)
  return [ ...fees ]
}

export const editFeeData = (state: TransactionState, id: string, newData: TransactionFee): TransactionFee[] => {
  const { commission } = state
  const fees = [ ...commission.fees ]
  const index = fees.findIndex((item: any) => {
    return item._id === id
  })
  fees[index] = merge(fees[index], newData)
  return [ ...fees ]
}

export const deleteFeeData = (state: TransactionState, id: string): TransactionFee[] => {
  const { commission } = state
  const fees = [ ...commission.fees ]
  const index = fees.findIndex((item: any) => {
    return item._id === id
  })
  fees.splice(index, 1)
  return [ ...fees ]
}

export const addCredits = (state: TransactionState, newData: TransactionFee): TransactionFee[] => {
  const { credits } = state
  const creditsData = credits.slice()
  creditsData.push(newData)
  return [...creditsData]
}

export const editcreditData = (state: TransactionState, id: string, newData: TransactionFee): TransactionFee[] => {
  const { credits } = state
  const creditDatas = credits.slice()
  const creditsIndex = creditDatas.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  let creditsData = { ...creditDatas[creditsIndex] }
  creditsData = newData
  creditDatas[creditsIndex] = creditsData
  return [...creditDatas]
}

export const deletecreditData = (state: TransactionState, id: string): TransactionFee[] => {
  const { credits } = state
  const creditData = credits.slice()
  const messageIndex = creditData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  creditData.splice(messageIndex, 1)
  return [...creditData]
}

export const editRecord = (state: TransactionState, newData: any): TransactionState['listData'] => {
  const { listData } = state
  let listRecords = listData.slice()
  listRecords = listRecords.map((list: any) => {
    if (list._id === newData._id) {
      return newData
    } else {
      return list
    }
  })
  return listRecords
}

export const importData = (
  state: TransactionState,
  boardId: string,
  importedData: any
): TransactionState['allTransactions'] => {
  const { allTransactions } = state
  let boardData = allTransactions.slice()
  boardData = boardData.map((list: any) => {
    if (list._id === boardId) {
      let newList = list.items.slice()
      if (newList.length > 0) {
        newList.concat(importedData)
      } else {
        newList = importedData
      }
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return boardData
}

export const addReferralFee = (state: TransactionState, newData: TransactionReferralFee): TransactionReferralFee[] => {
  const { commission } = state
  let referrals = commission.referrals ? [ ...commission.referrals ] : []
  referrals.push(newData)
  return [ ...referrals ]
}

export const editReferralFee = (state: TransactionState, id: string, newData: TransactionReferralFee): TransactionReferralFee[] => {
  const { commission } = state
  const referrals = [ ...commission.referrals ]
  const index = referrals.findIndex((item: any) => {
    return item._id === id
  })
  referrals[index] = merge(referrals[index], newData)
  return [ ...referrals ]
}

export const deleteReferralFee = (state: TransactionState, id: string): TransactionReferralFee[] => {
  const { commission } = state
  const referrals = [ ...commission.referrals ]
  const index = referrals.findIndex((item: any) => {
    return item._id === id
  })
  referrals.splice(index, 1)
  return [ ...referrals ]
}

export const addNewManagerNote = (state: TransactionState, newData: ManagerNotes): ManagerNotes[] => {
  const { managerNotes } = state
  const feeData = managerNotes.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const editManagerNote = (state: TransactionState, id: string, newData: ManagerNotes): ManagerNotes[] => {
  const { managerNotes } = state
  const feeData = managerNotes.slice()
  const messageIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  let message = { ...feeData[messageIndex] }
  message = newData
  feeData[messageIndex] = message
  return [...feeData]
}

export const deleteManagerNote = (state: TransactionState, id: string): ManagerNotes[] => {
  const { managerNotes } = state
  const feeData = managerNotes.slice()
  const messageIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  feeData.splice(messageIndex, 1)
  return [...feeData]
}

export const addShareWith = (state: TransactionState, shareWithObj: any): TransactionState['shareWith'] => {
  const { shareWith } = state
  const shareWithData = shareWith.slice()
  const len = shareWithData.length
  const newSharedData = shareWithObj[len]
  shareWithData.push(newSharedData)
  return shareWithData
}

export const deleteShareWith = (state: TransactionState, data: any): TransactionState['shareWith'] => {
  const { shareWith } = state
  const shareWithData = shareWith.slice()
  const index = shareWithData.findIndex((shared: any) => {
    return shared._id === data
  })
  shareWithData.splice(index, 1)
  return shareWithData
}

export const createWorkflowTask = (state: TransactionState, newData: any): TransactionState['workflowTask'] => {
  const { workflowTask } = state
  const feeData = workflowTask.slice()
  feeData.push(newData)
  const newdata = sortBy(feeData, ['dueDate'])
  return newdata
}

export const deleteWorkflowTask = (state: TransactionState, data: any): TransactionState['workflowTask'] => {
  const { workflowTask } = state
  const workflowTaskData = workflowTask.slice()
  const index = workflowTaskData.findIndex((shared: any) => {
    return shared._id === data
  })
  workflowTaskData.splice(index, 1)
  const newdata = sortBy(workflowTaskData, ['dueDate'])
  return newdata
}

export const updateWorkflowTask = (state: TransactionState, data: any): TransactionState['workflowTask'] => {
  const { workflowTask } = state
  const workflowTaskData = workflowTask.slice()
  const index = workflowTaskData.findIndex((element: any) => {
    return element._id === data._id
  })
  workflowTaskData.splice(index, 1, data)
  const newdata = sortBy(workflowTaskData, ['dueDate'])
  return newdata
}

export const createTransactionDocuments = (state: TransactionState, data: any): TransactionState['documents'] => {
  const { documents } = state
  const documentsData = documents.slice()
  documentsData.splice(0, 0, ...data)
  return [...documentsData]
}

export const addWorkflow = (state: TransactionState, newData: any): TransactionState['workflowTask'] => {
  const { workflowTask } = state
  const feeData = workflowTask.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const deleteMultipleWorkflowTasks = (state: TransactionState, data: any): TransactionState['workflowTask'] => {
  const { workflowTask } = state
  const workflowTaskData = workflowTask.slice()
  const newData: any = []
  workflowTaskData.forEach((workflow: any) => {
    if (data.includes(workflow._id) !== true) {
      newData.push(workflow)
    }
  })
  return newData
}

export const updateMultipleWorkflowTasks = (
  state: TransactionState,
  data: any,
  status: string
): TransactionState['workflowTask'] => {
  const { workflowTask } = state
  let workflowTaskData = workflowTask.slice()
  workflowTaskData = workflowTaskData.map((workflow: any) => {
    if (data.includes(workflow._id) === true) {
      return {
        ...workflow,
        status
      }
    } else {
      return workflow
    }
  })
  return workflowTaskData
}

export const addPropertyImage = (state: TransactionState, image: PropertyImageType): PropertyImageType[] => {
  const {
    transactionDetail: {
      propertyId: { images }
    }
  } = state
  const update: PropertyImageType[] = images.slice()
  if (image.order <= images.length) {
    update.splice(image.order, 0, image)
  } else {
    update.push(image)
  }

  return update
}

export const deletePropertyImage = (state: TransactionState, id: string): PropertyImageType[] => {
  const {
    transactionDetail: {
      propertyId: { images }
    }
  } = state
  const update: PropertyImageType[] = images.slice()
  const index = update.findIndex((item: PropertyImageType) => item._id === id)
  if (index >= 0) {
    update.splice(index, 1)
  }

  return update
}

export const sortBoardData = (
  state: TransactionState,
  boardId: string,
  sortOrder: number
): TransactionState['allTransactions'] => {
  const { allTransactions } = state
  let boardData = allTransactions.slice()
  boardData = boardData.map((list: any) => {
    if (list._id === boardId) {
      const newList = list.items.slice()
      const sortedData = sortBy(newList, ['createdAt'])
      if (sortOrder === -1) {
        return {
          ...list,
          items: sortedData.reverse()
        }
      } else {
        return {
          ...list,
          items: sortedData
        }
      }
    } else {
      return list
    }
  })
  return boardData
}

export const changeStatus = (
  state: TransactionState,
  accountId: string,
  status: string
): TransactionState['creditDebit'] => {
  const { creditDebit } = state
  let creditDebitData = creditDebit.slice()
  creditDebitData = creditDebitData.map((data: any) => {
    if (data._id === accountId) {
      if (status === 'UP') {
        return {
          ...data,
          thumbsUp: true
        }
      } else {
        return {
          ...data,
          thumbsDown: true
        }
      }
    } else {
      return data
    }
  })

  return creditDebitData
}
export const transferTransaction = (
  state: TransactionState,
  cardId: string,
  destinationBoardId: string,
  index: number,
  sourceBoardId: string
): TransactionState['allTransactions'] => {
  const { allTransactions } = state
  let transactionsData = allTransactions.slice()
  let movedCard: any = {}
  transactionsData.forEach((data: any) => {
    if (data._id === sourceBoardId) {
      data.items.forEach((item: any) => {
        if (item._id === cardId) {
          movedCard = item
        }
      })
    }
  })

  transactionsData = transactionsData.map((data: any) => {
    if (data._id === destinationBoardId) {
      const listLength = data.items.length
      const newList = data.items.slice()
      newList.splice(index ? index : listLength, 0, movedCard)
      return {
        ...data,
        items: newList,
        transactions: newList
      }
    } else if (data._id === sourceBoardId) {
      const newList = data.items.slice()
      const movedCardIndex = newList.findIndex((newData: any) => newData._id === movedCard._id)
      newList.splice(movedCardIndex, 1)
      return {
        ...data,
        items: newList,
        transactions: newList
      }
    } else {
      return data
    }
  })
  return [...transactionsData]
}
