// Import Packages
import * as React from 'react'
import { Form, Input, Table } from 'semantic-ui-react'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { updateProfile } from 'app/Settings/Mutations'
import {
  Container,
  StyledPopup,
  StyledTable,
  Title
} from './Styled'

// Font Awesome Icons
import {
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faInfoCircle])

interface Props {
  profile: any
}

interface State {
  systemDefault: any
}

class GeneralConfiguration extends React.Component<Props, State> {
  public state = {
    systemDefault: {}
  }

  public componentDidMount() {
    const { profile } = this.props
    this.setState({ systemDefault: profile })
  }

  public render() {
    const { systemDefault } = this.state
    return (
      <Container>
        <Title>{Strings.settings.configuration.generalConfiguration}</Title>
        <StyledTable basic={true}>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {Strings.settings.configuration.duplicatePayment}
                <StyledPopup
                  position='bottom center'
                  trigger={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
                  content={Strings.settings.configuration.duplicatePaymentMessage} />
              </Table.Cell>
              <Table.Cell><Form.Field control={Input} type='number' placeholder='Payment Tolerance' name='paymentTolerance' value={systemDefault[`paymentTolerance`]} onChange={this.handleChange} /></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{Strings.settings.configuration.batchImport}</Table.Cell>
              <Table.Cell><Form.Field control={Input} type='number' placeholder='Maximum Imports' name='maxImportLimit' value={systemDefault[`maxImportLimit`]} onChange={this.handleChange} /></Table.Cell>
            </Table.Row>
          </Table.Body>
        </StyledTable>
      </Container>
    )
  }

  private handleChange = async (event: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { systemDefault } = this.state
    systemDefault[name] = parseInt(value)
    this.setState({ systemDefault })
    await updateProfile(systemDefault)
  }

}

export default GeneralConfiguration
