import gql from 'graphql-tag'

export const paginationFragment = gql`
  fragment PaginationFragment on Pagination {
    count
    filter
    limit
    pages {
      current
      last
      next
      previous
      total
    }
    skip
    sorting {
      field
      order
    }
  }
`
