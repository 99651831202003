import gql from 'graphql-tag'

export const GET_CITIES = gql`
  query getCities($state_id: ID) {
    getCities(where: { state: $state_id }) {
      _id
      name
    }
  }
`
