// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

// Import Images
import * as Icons from 'design/icons/main-header'

// Import Component
import ChangePasswordModal from '../ChangePasswordModal'
import NotificationsModal from './NotificationsModal'
import Profile from './Profile'
import UpgradeModal from './UpgradeModal'

// Import Queries And Mutation
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Notifications/Actions'
import { NotificationCountItems } from 'store/Notifications/Types'
import { getLoggedInUser, Routes, Strings } from 'utils'
import { getUnreadFeedPublicNotificationCount } from '../Links/NotificationsModal/NotificationsQueries'

import { BillingPlanEnum } from 'shared/Billing/Types'
import { UserType } from './Profile/Types'

// Import Colors
import Colors from 'design/Colors'

// Import Style Component
import { BtnText, Divider, FlexContainer, Labels } from './Styled'

// Import Font Awesome
import { faBell, faCalendarAlt, faCog, faTools } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle, faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faBell, faCog, faPlusCircle, faSearch, faTools, faCalendarAlt])

interface DispatchProps {
  plan: BillingPlanEnum
  setShowNotificationCount: (data: NotificationCountItems) => void
  notificationCount: NotificationCountItems
}

interface OwnProps {
  dashboard: boolean
}

type Props = OwnProps & {} & DispatchProps

interface State {
  user: UserType
  showUpgradeModal: boolean
  showChangePassword: boolean
  showNotificationModal: boolean
}

class Links extends React.Component<Props, State> {
  public setIntervalNotificationCount: any = null

  public state = {
    showChangePassword: false,
    showNotificationModal: false,
    showUpgradeModal: false,
    user: {} as UserType
  }

  public updateUnreadFeedPublicNotificationCount = async () => {
    const { setShowNotificationCount } = this.props
    const response = await getUnreadFeedPublicNotificationCount()
    setShowNotificationCount(response)
  }

  public async componentDidMount() {
    const { setShowNotificationCount } = this.props
    const user: any = (await getLoggedInUser()) || ({} as UserType)
    this.setState({ user })
    const response = await getUnreadFeedPublicNotificationCount()
    setShowNotificationCount(response)
    // this.setIntervalNotificationCount = window.setInterval(this.updateUnreadFeedPublicNotificationCount, 60000)
  }

  public componentWillReceiveProps(nextProps: any) {
    const { notificationCount } = this.props
    const audio = new Audio('https://onechimp.s3-us-west-2.amazonaws.com/deduction.mp3')
    if (
      nextProps.notificationCount.totalCount !== notificationCount.totalCount &&
      nextProps.notificationCount.totalCount > notificationCount.totalCount &&
      nextProps.notificationCount.sound
    ) {
      audio.play()
    }
  }

  public componentWillUnmount() {
    clearInterval(this.setIntervalNotificationCount)
  }

  public render() {
    const { notificationCount, plan } = this.props

    const { user, showUpgradeModal, showChangePassword, showNotificationModal } = this.state

    return (
      <FlexContainer>
        {showNotificationModal && <NotificationsModal closeModal={this.closeModal} />}
        {showUpgradeModal && <UpgradeModal closeModal={this.closeModal} />}
        {showChangePassword && <ChangePasswordModal handleClose={this.closeModal} />}
        {user.role === 'ADMIN' &&
          plan === BillingPlanEnum.Free && (
            <React.Fragment>
              <Button compact={true} size="small" style={styles.btn}>
                <BtnText imageUrl={Icons.UpgradeBtnIcon} onClick={this.showUpgradeModal}>
                  {Strings.buttonText.upgradeBtn}
                </BtnText>
              </Button>
              <Divider />
            </React.Fragment>
          )}
        <FlexContainer>
          {user.role !== 'GUEST' && (
            <React.Fragment>
              <div style={{ position: 'relative' }} onClick={this.showNotificationModal}>
                {notificationCount.totalCount !== 0 && <Labels circular={true}>{notificationCount.totalCount}</Labels>}
                <FontAwesomeIcon
                  className={notificationCount.totalCount !== 0 ? 'fa fa-bell faa-ring animated fa-4x' : ''}
                  icon={['fal', 'bell']}
                />
              </div>
              <Link to={Routes.primary.events.path}>
                <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
              </Link>
              <Link to={Routes.primary.settings.path}>
                <FontAwesomeIcon icon={['fal', 'cog']} />
              </Link>
            </React.Fragment>
          )}
        </FlexContainer>
        <Profile showChangePasswordModal={this.showChangePasswordModal} />
      </FlexContainer>
    )
  }

  private showNotificationModal = () => {
    this.setState({ showNotificationModal: true })
  }
  private showChangePasswordModal = () => {
    this.setState({ showChangePassword: true })
  }

  private showUpgradeModal = () => {
    this.setState({ showUpgradeModal: true })
  }

  private closeModal = () => {
    this.setState({ showUpgradeModal: false, showNotificationModal: false, showChangePassword: false })
  }
}

const styles = {
  btn: {
    background: `linear-gradient(to bottom,${Colors.DarkBlue280} 0%,${Colors.DarkBlue285} 100%)`,
    backgroundColor: Colors.DarkBlue200,
    borderRadius: '0.585714rem',
    marginLeft: 20,
    padding: '0.389286em 1.325em 0.389286em'
  },
  icon: {
    color: Colors.Black600
  }
}

const mapStateToProps = (state: AppState) => ({
  notificationCount: state.notification.notificationCount,
  plan: state.billing.cycle.plan
})

export default connect(
  mapStateToProps,
  {
    setShowNotificationCount: Actions.showNotificationCount
  }
)(Links)
