// Import Packages
import * as React from 'react'
import { Grid, Responsive } from 'semantic-ui-react'

// Import Components
import { colors } from './IconsMockData'

// Import Styled Components
import {
  AppearanceIcons,
  ColorSet,
  ColorsSelections,
  DefaultColorSet,
  Divider,
  IconContainer,
  StyledContainer,
  StyledTextArea,
  TabContainer
} from './Styled'

interface Props {
  onChange: (color: string) => void
  setNewIcon: (id: string, icon: string) => void
  setNewDescription: (id: string, description: string) => void
  boardId: string
  color: string
  icons: any[]
  setIcon: string
  description: string
}

interface State {
  setDefaultIcon: number
}

class Appearance extends React.Component<Props, State> {
  public state = {
    setDefaultIcon: 0.5
  }

  public render() {
    const { color, icons, boardId, description, setIcon } = this.props
    const { setDefaultIcon } = this.state
    const IconsElements = icons.map((items, index) => {
      const setBackgroundColorFunction = () => {
        this.setState({ setDefaultIcon: index })
        this.setBackgroundColor(index)
        this.setNewIcon(boardId, items)
      }
      return (
        <Grid.Column key={index}>
          <IconContainer
            background={index === setDefaultIcon ? '' : setIcon === items.imageUrl ? '' : ''}
            onClick={setBackgroundColorFunction}
          >
            <AppearanceIcons icon={items.imageUrl} onClick={setBackgroundColorFunction} />
          </IconContainer>
        </Grid.Column>
      )
    })
    const ColorsElements = colors.map((items: any, index: any) => {
      const changeColorFunction = () => {
        this.onChangeColor(items.color)
      }
      return (
        <Grid.Column key={index}>
          {color === items.color ? (
            <DefaultColorSet color={color} />
          ) : (
            <ColorSet color={items.color} onClick={changeColorFunction} />
          )}
        </Grid.Column>
      )
    })
    return (
      <TabContainer>
        <Responsive as={StyledContainer}>
          <Grid columns={5}>
            <Grid.Row>{IconsElements}</Grid.Row>
          </Grid>
        </Responsive>
        <StyledTextArea placeholder="Description" rows={6} onChange={this.setNewDescription} value={description} />
        <Divider />
        <ColorsSelections>
          <Grid columns={8}>
            <Grid.Row>{ColorsElements}</Grid.Row>
          </Grid>
        </ColorsSelections>
      </TabContainer>
    )
  }

  private setNewDescription = (e: any) => {
    const { boardId, setNewDescription } = this.props
    setNewDescription(boardId, e.target.value)
  }

  private setNewIcon = (id: string, icon: any) => {
    const { setNewIcon } = this.props
    setNewIcon(id, icon)
  }

  private setBackgroundColor = (index: number) => {
    this.setState({ setDefaultIcon: index })
  }

  private onChangeColor = (color: string) => {
    const { onChange } = this.props
    onChange(color)
  }
}

export default Appearance
