import moment from 'moment'
import * as React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import { default as MessageEmailCard } from 'shared/MessageEmail/KanbanCard'
import EmailActions from './Actions'
import KanbanLane from './KanbanLane'

import Colors from 'design/Colors'

import { Container, Lane, LeftArrow, RightArrow, Row, Scroll } from './Styled'

import { faChevronDoubleLeft, faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronDoubleLeft, faChevronDoubleRight])

interface Props {
  ActionComponent: React.ReactType | null
  addNewCard: (id: string, index: string) => void
  alterData?: (data: any) => void
  appearance?: boolean
  boardData: any
  boardType: string
  CardComponent: React.ReactType
  convertNewCard?: (boardId: string, listIndex: string, inputData: any) => void
  deleteCard: (boardId: string, cardId: string) => void
  deleteRecord?: (boardId: string, cardId: string) => void
  icons: any[]
  mailboxData: any
  moveCard: (cardId: string, destinationIndex: number, destinationBoardId: string, sourceBoardId: string) => void
  price?: number
  reorderCard: (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => void
  resetCardData: () => void
  restoreCard: (boardId: string, cardId: string) => void
  setNewBoardName?: (id: string, boardName: string) => void
  setNewDescription?: (id: string, description: string) => void
  setNewIcon?: (id: string, icon: string) => void
  updateMailbox?: (type: string, skip: number) => void
}

interface State {
  isDragging: boolean
}

// disable all react-beautiful-dnd development warnings
window['__react-beautiful-dnd-disable-dev-warnings'] = true

const BoardBorderColors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Grey800}`,
  `${Colors.Green560}`
]

class MailboxKanbanBoard extends React.Component<Props, State> {
  public leftScroll: any = null
  public rightScroll: any = null

  public state = {
    isDragging: false
  }

  private isScrolling: boolean = false
  private scrollRef: any = React.createRef<HTMLDivElement>()
  private scrollTimer: any = null

  public componentWillMount() {
    const { boardData, alterData } = this.props
    const newData = boardData.map((dataItem: any) => {
      return {
        ...dataItem,
        color: this.getRandomColors(),
        items: dataItem.items.map((items: any) => {
          return {
            ...items,
            color: this.getRandomColors()
          }
        })
      }
    })
    if (alterData) {
      alterData(newData)
    }
  }

  public componentWillUnmount = () => {
    this.scrollStop()
  }

  public render() {
    const { ActionComponent, appearance, boardData, CardComponent, icons, mailboxData, price } = this.props

    const { isDragging } = this.state

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <Container>
          <Row>
            <LeftArrow onMouseEnter={() => this.scrollStart(-1)} onMouseLeave={this.scrollStop} isActive={!isDragging}>
              <FontAwesomeIcon icon={['fas', 'chevron-double-left']} />
            </LeftArrow>

            <Scroll innerRef={this.scrollRef}>
              <Lane>
                <KanbanLane
                  ActionComponent={EmailActions}
                  addNewCard={this.addNewCard}
                  appearance={appearance}
                  CardComponent={MessageEmailCard}
                  color={'#F44'}
                  disableDrop={true}
                  icons={icons}
                  index={-1}
                  item={mailboxData}
                  navigatePage={this.navigateMailboxPages}
                  price={price}
                  setNewBoardName={this.setNewBoardName}
                  setNewDescription={this.setNewDescription}
                  setNewIcon={this.setNewIcon}
                />
              </Lane>
              {boardData.map((element: any, index: number) => (
                <Lane key={index}>
                  <KanbanLane
                    ActionComponent={ActionComponent}
                    addNewCard={this.addNewCard}
                    appearance={appearance}
                    CardComponent={CardComponent}
                    color={element.color}
                    counterIcon="clock outline"
                    counterItems={element.items.filter((item: any) => moment(item.dueDate).isBefore(new Date())).length}
                    icons={icons}
                    index={index}
                    item={element}
                    price={price}
                    setNewBoardName={this.setNewBoardName}
                    setNewDescription={this.setNewDescription}
                    setNewIcon={this.setNewIcon}
                  />
                </Lane>
              ))}
            </Scroll>

            <RightArrow onMouseEnter={() => this.scrollStart(1)} onMouseLeave={this.scrollStop} isActive={!isDragging}>
              <FontAwesomeIcon icon={['fas', 'chevron-double-right']} />
            </RightArrow>
          </Row>
        </Container>
      </DragDropContext>
    )
  }

  private scrollX = (direction: number) => {
    const container = this.scrollRef.current
    if (!container || !this.isScrolling) {
      return
    }

    this.scrollTimer = window.setTimeout(() => {
      container.scrollLeft += direction * 30
      this.scrollX(direction)
    }, 100)
  }

  private scrollStart = (direction: number) => {
    this.isScrolling = true
    this.scrollX(direction)
  }

  private scrollStop = () => {
    this.isScrolling = false
    window.clearTimeout(this.scrollTimer)
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    if (setNewBoardName) {
      setNewBoardName(id, boardName)
    }
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    if (setNewDescription) {
      setNewDescription(id, description)
    }
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    if (setNewIcon) {
      setNewIcon(id, icon)
    }
  }

  private getListData = (id: any) => {
    const { boardData, boardType, mailboxData } = this.props

    const data = boardData.slice()
    data.push(mailboxData)

    const index = data.findIndex((laneItem: any) => {
      return laneItem._id === id
    })

    if (index === -1) {
      return {
        board: null,
        boardIndex: index,
        boardType: 'NONE'
      }
    }

    return {
      board: data[index],
      boardIndex: index >= boardData.length ? -1 : index,
      boardType: index >= boardData.length ? 'MAIL' : boardType
    }
  }

  private reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = list.items.slice()
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    const board = {
      ...list,
      items: result
    }

    return board
  }

  private move = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
    const { alterData, boardType, convertNewCard, moveCard } = this.props

    if (!source.board && !destination.board) {
      return
    }

    if (source.boardType === boardType && destination.boardType === 'MAIL') {
      return
    }

    if (source.boardType === boardType && destination.boardType === boardType) {
      const sourceClone = source.board.items.slice()
      const destClone = destination.board.items.slice()
      const [removed] = sourceClone.splice(droppableSource.index, 1)

      destClone.splice(droppableDestination.index, 0, removed)

      const resultState = this.props.boardData.slice()

      resultState[source.boardIndex] = {
        ...resultState[source.boardIndex],
        items: sourceClone
      }

      resultState[destination.boardIndex] = {
        ...resultState[destination.boardIndex],
        items: destClone
      }

      if (alterData) {
        alterData(resultState)
      }

      if (moveCard) {
        const cardId = source.board.items[droppableSource.index]._id
        moveCard(cardId, droppableDestination.index + 1, droppableDestination.droppableId, droppableSource.droppableId)
      }
    }

    if (source.boardType === 'MAIL' && destination.boardType === boardType) {
      const mailCard = source.board.items[droppableSource.index]
      if (convertNewCard) {
        convertNewCard(droppableDestination.droppableId, droppableDestination.index, mailCard)
      }
    }
  }

  private onDragStart = (res: any) => {
    this.setState({ isDragging: true })
  }

  private onDragEnd = (res: any) => {
    this.setState({ isDragging: false })
    const { source, destination } = res
    const { alterData, reorderCard } = this.props

    // no destination
    if (!destination) {
      return
    }

    // no movement
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    // dropped on same lane
    if (source.droppableId === destination.droppableId) {
      const list = this.getListData(source.droppableId)
      const ordering = this.reorder(this.props.boardData[list.boardIndex], source.index, destination.index)

      const resultColumns: any = this.props.boardData.slice()
      resultColumns.splice(list.boardIndex, 1, ordering)
      if (alterData) {
        alterData([...resultColumns])
      }

      const boardId = destination.droppableId
      const cardId = list.board.items[source.index]._id
      reorderCard(boardId, cardId, source.index + 1, destination.index + 1)
    }

    // dropped on new lane
    if (source.droppableId !== destination.droppableId) {
      this.move(this.getListData(source.droppableId), this.getListData(destination.droppableId), source, destination)
    }
  }

  private addNewCard = (id: string, index: string) => {
    const { addNewCard } = this.props
    addNewCard(id, index)
  }

  private getRandomColors = () => {
    return BoardBorderColors[Math.floor(Math.random() * BoardBorderColors.length)]
  }

  private navigateMailboxPages = (skip: number) => {
    const {
      mailboxData: { _id },
      updateMailbox
    } = this.props
    if (updateMailbox) {
      updateMailbox(_id, skip)
    }
  }
}

export default MailboxKanbanBoard
