import * as React from 'react'

import FlyerModal from 'app/Transactions/Flyer'
import ListingModal from 'app/Transactions/Listing/PreviewModal'
import PropertyImageModal from 'app/Transactions/PropertyImageModal'
import Button from 'shared/Button'

import { getLoggedInUser } from 'utils'

import { TransactionType } from 'app/Transactions/Details/Types'
import { ModeEnum as ImageZoneModeEnum } from 'shared/ImageZone/Types'
import { ModalType } from './Types'

import { Container } from './Styled'

import { faPennant, faLaptopHouse, faImages } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([ faPennant, faLaptopHouse, faImages ])

interface Props {
  className: string
  transaction: TransactionType
}

interface State {
  buttons: ModalType
  modal: ModalType
}

class Tools extends React.Component<Props, State> {
  public static defaultProps = {
    className: ''
  }

  public state = {
    buttons: {} as ModalType,
    modal: {} as ModalType,
  }

  public componentDidMount = async () => {
    const { transaction } = this.props
    const user:any = await getLoggedInUser({ fromCache: true })

    const buttons: ModalType = {
      flyer: true,
      showcase: true,
    }
    if (user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === transaction.owner._id) {
      buttons.images = true
    }

    this.setState({ buttons })
  }

  public render = () => {
    const { transaction, className } = this.props
    const { buttons, modal } = this.state

    return (
      <React.Fragment>
        {modal.images && (
          <PropertyImageModal
            active={0}
            mode={ImageZoneModeEnum.Default}
            onClose={this.closeModal}
            transaction={transaction}
          />
        )}
        {modal.flyer && (
          <FlyerModal
            transaction={transaction}
            closeFlyerModal={this.closeModal}
          />
        )}
        {modal.showcase && (
          <ListingModal
            transaction={transaction}
            onClose={this.closeModal}
          />
        )}
        <Container
          className={`rp-transaction-sidebar-tools ${className}`}
          childWidths={100 / Object.keys(buttons).length}
        >
          {buttons.images && (
            <Button.Block
              icon={faImages}
              label='Photo Manager'
              onClick={() => this.showModal('images')}
              type='light'
            />
          )}
          {buttons.flyer && (
            <Button.Block
              icon={faPennant}
              label='Flyer Creator'
              onClick={() => this.showModal('flyer')}
              type='light'
            />
          )}
          {buttons.showcase && (
            <Button.Block
              icon={faLaptopHouse}
              label='Virtual Showcase'
              onClick={() => this.showModal('showcase')}
              type='light'
            />
          )}
        </Container>
      </React.Fragment>
    )
  }

  private showModal = (type: string) => {
    const modal:ModalType = { [type]: true }
    this.setState({ modal })
  }

  private closeModal = () => {
    this.setState({ modal: {} })
  }
}

export default Tools
