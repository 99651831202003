import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import { x, y } from 'design/Styled'

export const Container = styled.div`
  ${y};
  position: relative;
  width: 100%;
  height: 100%;
`

export const Row = styled.div`
  ${x};
`

export const StyledModal = styled(Modal)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0 !important;
  width: 90vw !important;
  height: 90vh;
  flex: 0;
  background: transparent !important;
  box-shadow: none !important;
`
