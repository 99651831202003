import * as React from 'react'

import { Circle } from './Styled'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  color?: string
  size?: number
  text?: string
  initials?: string
}

class Insignia extends React.Component<Props, {}> {
  public render() {
    return (
      <Circle background={this.getBackground()} size={this.getSize()}>
        {this.getInitials()}
      </Circle>
    )
  }

  private getInitials = () => {
    const { text, initials } = this.props

    if (initials) {
      return initials
    }

    let letters = '@'
    if (text) {
      const email = text.split(' ').find(x => x.includes('@')) || ''
      const name = text.replace(email, '').trim()
      letters = email.replace(/[<>]/gi, '')[0]
      if (name !== '') {
        letters = name
          .split(' ')
          .map(x => x.length && x[0])
          .join('')
          .toUpperCase()
      }
    }

    return letters
  }

  private getBackground = () => {
    const { color } = this.props

    if (color) {
      return color
    }

    const colors = [
      `${Colors.Blue10}`,
      `${Colors.Blue180}`,
      `${Colors.DarkBlue260}`,
      `${Colors.DarkBlue500}`,
      `${Colors.Green100}`,
      `${Colors.Green600}`,
      `${Colors.Magenta50}`,
      `${Colors.Orange125}`,
      `${Colors.Orange490}`,
      `${Colors.Pink50}`,
      `${Colors.Red35}`,
      `${Colors.Yellow60}`
    ]

    return colors[Math.floor(Math.random() * colors.length)]
  }

  private getSize = () => {
    const { size } = this.props
    if (size) {
      return size
    }
    return 1
  }
}

export default Insignia
