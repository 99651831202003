// Import Packages
import { debounce, escapeRegExp, filter } from 'lodash'
import * as React from 'react'
import { Button, Form, Image, Input } from 'semantic-ui-react'

// Import Components
import { getReassignUsers } from 'app/Leads/Dashboard/LeadQueries'
import Modal from 'shared/Modal'
import { SharedUser } from 'store/Tasks/Types'
import { handleValidation } from './Validation'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { Container, LeftPanel, RightPanel, StyledDirectForm, StyledLabel, StyledSearch } from './Styled'

// Font Awesome Icons
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone])

interface SearchOption {
  _id: string
  description: string
  email: string
  firstName: string
  lastName: string
  phone: string
  regex: string
  title: string
  key: string
}

interface Props {
  onClose: () => void
  onShare: (newUser: SharedUser) => void
  sharedUsers: SharedUser[]
}

interface State {
  errors: any
  form: SharedUser
  isLoading: boolean
  searchResults: any
  transition: boolean
  userOptions: SearchOption[]
}

class ShareWithModal extends React.Component<Props, State> {
  public state = {
    errors: {},
    form: {
      _id: '',
      firstName: '',
      lastName: '',
      userName: ''
    },
    isLoading: false,
    searchResults: [],
    transition: true,
    userOptions: []
  }

  public componentDidMount = async () => {
    const { sharedUsers } = this.props
    let users = await getReassignUsers()

    const sharedIds = sharedUsers.map((user: SharedUser) => user._id)
    users = users.filter((user: any) => {
      return !sharedIds.includes(user._id)
    })

    const userOptions: any = []
    users.forEach((user: any) => {
      const { _id, emails, firstName, lastName, phones } = user
      const email = emails && emails[0] ? emails[0].value : null
      const phone = phones && phones[0] ? phones[0].value : null
      const entry: SearchOption = {
        _id,
        description: email,
        email,
        firstName,
        key: _id,
        lastName,
        phone,
        regex: firstName + lastName + email.split('@')[0],
        title: `${user.firstName} ${user.lastName}`
      }
      userOptions.push(entry)
    })
    this.setState({ userOptions })
  }

  public render() {
    const { isLoading, searchResults, transition, form, errors } = this.state
    return (
      <Modal
        content={
          <Container>
            <LeftPanel>
              <Image src={Strings.leads.image} size="small" />
            </LeftPanel>
            <RightPanel>
              <StyledDirectForm size={'mini'}>
                <Form.Field error={errors[`firstName`]}>
                  <StyledLabel>Name</StyledLabel>
                  <StyledSearch
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
                    onChange={this.handleChange}
                    name="firstName"
                    placeholder="First Name"
                    results={searchResults}
                    value={form.firstName}
                    {...this.props}
                  />
                </Form.Field>
                <Form.Field
                  control={Input}
                  onChange={this.handleChange}
                  label="Last name"
                  placeholder="Last name"
                  name="lastName"
                  value={form.lastName}
                  error={errors[`lastName`]}
                  autoComplete="off"
                />
                <Form.Field
                  control={Input}
                  onChange={this.handleChange}
                  label="Email"
                  placeholder="Email"
                  name="userName"
                  value={form.userName}
                  error={errors[`userName`]}
                  autoComplete="off"
                />
                <Form.Field onClick={() => this.submit()} control={Button} content={'SUBMIT'} />
              </StyledDirectForm>
            </RightPanel>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        width={550}
      />
    )
  }

  private handleChange = (e: any, { value, name }: any) => {
    const { form } = this.state
    form._id = ''
    form[name] = value
    this.setState({ form })
  }

  private handleResultSelect = (e: any, { result }: any) => {
    const { form } = this.state
    const { _id, email, firstName, lastName } = result
    form._id = _id
    form.userName = email
    form.firstName = firstName
    form.lastName = lastName

    this.setState({ form })
  }

  private handleSearchChange = (e: any, { value, name }: any) => {
    const { form, userOptions } = this.state

    form._id = ''
    form[name] = value
    this.setState({
      form,
      isLoading: true
    })

    setTimeout(() => {
      if (value.length < 1) {
        return this.resetComponent()
      }

      const re = new RegExp(escapeRegExp(value), 'i')
      const isMatch = (item: SearchOption) => re.test(item.title)
      const searchResults = filter(userOptions, isMatch)
      if (searchResults.length > 0) {
        this.setState({
          isLoading: false,
          searchResults
        })
      }
    }, 100)
  }

  private resetComponent = () => {
    this.setState({
      isLoading: false,
      searchResults: []
    })
  }

  private submit = () => {
    const { form } = this.state
    const { onShare } = this.props
    const result = handleValidation(form)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      onShare(form)
      this.closeSelf()
    }
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      onClose()
    }, 300)
  }
}

export default ShareWithModal
