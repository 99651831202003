import * as React from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'

import Button from 'shared/Button'
import LoadingIndicator from 'shared/LoadingIndicator'
import { formToast, serverToast } from 'shared/Toast/Toast'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/Taxes/Actions'
import { getTaxes } from './Queries'
import { updateStateTax, updateCityTax } from './Mutations'
import { handleValidation } from './Validation'

import { getLoggedInUser } from 'utils'

import {
  TaxOfficeType,
  TaxDictType,
} from 'store/Settings/Taxes/Types'
import {
  FormType,
  FormErrorType,
  UserType,
} from './Types'

import {
  Amount,
  Col,
  Container,
  HeadLine,
  Line,
  Locale,
  Form,
  StyledInput,
  Submit,
  Tax,
} from './Styled'

import { faPencilAlt } from '@fortawesome/pro-light-svg-icons'
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([ faTimes, faPencilAlt, faCheck ])

interface StoreProps {
  list: string[]
  setTax: (payload: Partial<TaxOfficeType>) => void
  tax: TaxDictType
}

type Props = StoreProps & RouteComponentProps<{},{}>

interface State {
  activeItem: string
  form: FormType
  loading: string
  user: UserType
}

class Taxes extends React.Component<Props, State> {
  public state = {
    activeItem: '',
    form: {
      errors: {} as FormErrorType
    } as FormType,
    loading: '',
    user: {} as UserType
  }

  public componentDidMount = async () => {
    this.setState({ loading: 'gathering tax information...' })

    const user: UserType = await getLoggedInUser({ fromCache: true }) || {} as UserType
    await getTaxes()

    this.setState({
      loading: '',
      user,
    })
  }

  public render = () => {
    const {
      tax,
      list,
    } = this.props

    const {
      activeItem,
      form,
      form: { errors },
      loading,
      user,
    } = this.state

    const isAdmin = user.role === 'ADMIN'
    const widths = isAdmin ? '30 30 30 10' : '30 35 35'

    return (
      <Container>
        {loading && <LoadingIndicator message={loading} />}
        <HeadLine childWidths={widths}>
          <Col>Office</Col>
          <Col>State Tax</Col>
          <Col>Local Tax</Col>
          {isAdmin && <Col>&nbsp;</Col>}
        </HeadLine>
        <Form onSubmit={this.handleSubmit}>
          {list.map((id:string) => {
            const item: TaxOfficeType = tax[id]
            return (
              <Line
                childWidths={widths}
                key={id}
              >
                {(isAdmin && activeItem === id) ? (
                  <React.Fragment>
                    <Col>{item.branchName}</Col>
                    <Tax>
                      <Locale>{item.state.name}</Locale>
                      <StyledInput
                        autoFocus={true}
                        error={!!errors.state}
                        icon={'percent'}
                        max={100}
                        min={0}
                        name={'state'}
                        onChange={this.handleChange}
                        placeholder={`${item.state.name} Tax`}
                        size={'mini'}
                        step='0.01'
                        type='number'
                        value={form.state}
                      />
                    </Tax>
                    <Tax>
                      <Locale>{item.city.name}</Locale>
                      <StyledInput
                        error={!!errors.city}
                        icon={'percent'}
                        max={100}
                        min={0}
                        name={'city'}
                        onChange={this.handleChange}
                        placeholder={`${item.city.name} Tax`}
                        size={'mini'}
                        step='0.01'
                        type='number'
                        value={form.city}
                      />
                    </Tax>
                    <Col justify='flex-end'>
                      <Button.Bubble
                        icon={faCheck}
                        label='Submit'
                        onClick={() => this.handleSubmit(null)}
                      />
                      <Submit/>
                      <Button.Icon
                        icon={faTimes}
                        label='Cancel'
                        onClick={this.handleCancel}
                      />
                    </Col>
                  </React.Fragment>
                ): (
                  <React.Fragment>
                    <Col>{item.branchName}</Col>
                    <Tax>
                      <Locale>{item.state.name}</Locale>
                      <Amount>{item.state.tax}%</Amount>
                    </Tax>
                    <Tax>
                      <Locale>{item.city.name}</Locale>
                      <Amount>{item.city.tax}%</Amount>
                    </Tax>
                    {isAdmin && (
                      <Col justify='flex-end'>
                        <Button.Icon
                          icon={faPencilAlt}
                          label='Edit'
                          onClick={() => this.toggleEdit(id)}
                        />
                      </Col>
                    )}
                  </React.Fragment>
                )}
              </Line>
            )
          })}
        </Form>
      </Container>
    )
  }
  private toggleEdit = (activeItem: string) => {
    const { tax } = this.props
    const { form } = this.state
    if (activeItem) {
      form.state = tax[activeItem].state.tax
      form.city = tax[activeItem].city.tax
      form.errors = {} as FormErrorType
    }
    this.setState({
      activeItem,
      form,
    })
  }

  private handleChange = (e: React.SyntheticEvent | any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private handleSubmit = async (e: React.SyntheticEvent | any) => {
    if (e) {
      e.preventDefault()
    }
    const { tax, setTax } = this.props
    const { activeItem, form } = this.state
    const current = { ...tax[activeItem] }

    const isStateUpdate = form.state !== current.state.tax
    const isCityUpdate = form.city !== current.city.tax

    if (!isStateUpdate && !isCityUpdate) {
      this.toggleEdit('')
      return
    }

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      formToast(validation.errors)
      return
    }

    const update: TaxOfficeType = {
      ...current,
      city: {
        ...current.city,
        tax: form.city
      },
      state: {
        ...current.state,
        tax: form.state
      }
    }

    if (isStateUpdate || isCityUpdate) {
      setTax(update)
    }

    if (isStateUpdate) {
      try {
        this.setState({ loading: `updating ${current.branchName} state tax settings...` })
        await updateStateTax(current.state._id, form.state)
      } catch (error) {
        update.state = current.state
        setTax(update)
        serverToast(error)
      }
    }

    if (isCityUpdate) {
      try {
        this.setState({ loading: `updating ${current.branchName} local tax settings...` })
        await updateCityTax(current.city._id, form.city)
      } catch (error) {
        update.city = current.city
        setTax(current)
        serverToast(error)
      }
    }

    this.setState({ loading: '' })
    this.toggleEdit('')
  }

  private handleCancel = () => {
    this.toggleEdit('')
  }
}

const mapStateToProps = (state: AppState) => ({
  tax: state.settings.taxes.dict,
  list: state.settings.taxes.list
})

export default withRouter(connect(
  mapStateToProps,
  {
    setTax: Actions.setTax
  }
)(Taxes))
