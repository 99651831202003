// Import Packages
import * as React from 'react'

// Import Utils
import { Strings } from 'utils'

import { OptionsType, UserType } from 'store/Offices/Types'

// Import Styled Components
import {
  Action,
  AddButtonContainer,
  Align,
  AlignContainer,
  Calendar,
  CloseIcon,
  Container,
  CustomButton,
  CustomInput,
  Filter,
  FilterIcon,
  SearchIcon,
  SearchInput,
  StyledDropdown,
  Trello,
  TrelloContainer
} from 'shared/StyledBoard/Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus])

interface Props {
  showCalendarIcon?: boolean
  showTaskSearchModal?: boolean
  showDefaultView: (data: boolean) => void
  getStateOffices: (id: string, text: string) => void
  changeOfficesStatus: (status: string) => void
  addList: () => void
  officeOptions: OptionsType[]
  filterValue: string
  user: UserType
}

interface State {
  show: boolean
  searchText: string
  showSearchModal: boolean
  sortOrder: number
}

class StyledBoard extends React.Component<Props, State> {
  public state = {
    searchText: '',
    show: true,
    showSearchModal: false,
    sortOrder: 1
  }

  public options = [
    {
      key: '0',
      text: 'Choose an Action',
      value: 'Choose'
    },
    {
      key: '1',
      text: 'Inactive Office(s)',
      value: 'Inactive'
    },
    {
      key: '2',
      text: 'Active Office(s)',
      value: 'Active'
    }
  ]

  public render() {
    const { showTaskSearchModal, showCalendarIcon, officeOptions, filterValue, user } = this.props
    const { searchText, show } = this.state
    return (
      <div>
        <Container>
          <Filter>
            <FilterIcon />{' '}
            <StyledDropdown
              inline={true}
              options={officeOptions}
              placeholder="Select State"
              value={filterValue}
              onChange={this.onHandleChange}
            />
          </Filter>
          <SearchInput style={{ width: !show ? '40%' : '50%' }}>
            <CustomInput placeholder={Strings.kanbanView.search} style={{ display: 'none' }}>
              {showTaskSearchModal ? (
                <input
                  value={searchText}
                  onChange={this.onChangeHandle}
                  onFocus={this.showSearchModal}
                  onBlur={this.closeModal}
                />
              ) : (
                <input value={searchText} onChange={this.onChangeHandle} />
              )}
              <SearchIcon />
              <CloseIcon onClick={this.clearState} />
            </CustomInput>
          </SearchInput>
          <CustomButton style={{ marginRight: !show && user.role !== 'ADMIN' ? '15px' : '0px' }}>
            {showCalendarIcon && <Calendar />}
            <AlignContainer>
              <Align onClick={this.showListView} />
            </AlignContainer>
            <TrelloContainer>
              <Trello onClick={this.showKanbanView} />
            </TrelloContainer>
            {!show &&
              user.role === 'ADMIN' && (
                <Action>
                  {' '}
                  <StyledDropdown
                    inline={true}
                    options={this.options}
                    placeholder="Choose an Action"
                    onChange={this.handleChange}
                  />
                </Action>
              )}
            {!show &&
              user.role === 'ADMIN' && (
                <AddButtonContainer onClick={this.props.addList} style={{ marginLeft: 20 }}>
                  <FontAwesomeIcon icon={['fas', 'plus']} />
                </AddButtonContainer>
              )}
          </CustomButton>
        </Container>
      </div>
    )
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    if (value !== 'Choose') {
      const { changeOfficesStatus } = this.props
      changeOfficesStatus(value)
    }
  }

  private showListView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: false
      },
      () => showDefaultView(this.state.show)
    )
  }

  private showKanbanView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: true
      },
      () => showDefaultView(this.state.show)
    )
  }

  private showSearchModal = () => {
    this.setState({ showSearchModal: true })
  }

  private closeModal = () => {
    this.setState({ showSearchModal: false })
  }

  private onHandleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const result = value.split(', ')
    const { getStateOffices } = this.props
    getStateOffices(result[0], result[1])
  }

  private onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value })
  }

  private clearState = () => {
    this.setState({ searchText: '' })
  }
}

export default StyledBoard
