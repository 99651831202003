import { cloneDeep } from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ConfirmAlert from 'sweetalert2'

import { isPlanAllowed } from 'shared/Billing/Queries'
import LoadingIndicator from 'shared/LoadingIndicator'
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import StyledGrid from 'shared/StyledBoard/GridView'
import KanbanView from 'shared/StyledBoard/KanbanView'
import Toast, { serverToast } from 'shared/Toast/Toast'

import LeadAction from './Actions'
import AddLeadModal from './AddLeadModal'
import ArchiveLeadModal from './ArchiveLeadModal'
import {
  Checked,
  CustomActions,
  CustomActivities,
  CustomCheckBox,
  CustomCreatedDate,
  CustomHeaderCheckBox,
  CustomLastActionDate,
  CustomLeadName,
  CustomLeadPoolActions,
  CustomLocation,
  CustomOwner,
  CustomPoolType,
  CustomPrice,
  CustomReferral,
  CustomSchedule,
  CustomScore,
  CustomSource,
  CustomType
} from './CustomComponents'
import ImportLeadsPopup from './ImportLeadsPopup'
import LeadCard from './LeadCard'
import StyledHeader from './StyledHeader'
import UpdateScoreModal from './UpdateScoreModal'

import { getTransactionBoards } from 'app/Transactions/Dashboard/TransactionQueries'
import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'
import {
  addLead,
  deleteCard,
  deleteLeadBoard,
  deleteLeads,
  moveCard,
  reorderCard,
  reorderLane,
  restore,
  updateLeadBoard,
  updateLeadScore,
  updateLeadStatus
} from './LeadMutations'
import {
  exportUsers,
  getIcons,
  getLead,
  getLeadBoards,
  getOptions,
  getStateLead,
  getStateLeadBoards,
  reset,
  sortLead
} from './LeadQueries'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Leads/Actions'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import {
  Filter,
  FiltersData,
  IconsType,
  LeadDetails,
  LeadItemsDetails,
  LeadPassFilter,
  UserState,
  UserType
} from 'store/Leads/Types'

import { getLoggedInUser, Routes, Strings } from 'utils'
import { formatKanbanData, formatListData } from './Utils/FormatingData'

import { Container } from './Styled'

interface StoreProps {
  setNewBoard: (index: number, newData: LeadDetails) => void
  setNewCard: (id: string, index: string, newData: LeadItemsDetails) => void
  setBoardData: (data: LeadDetails[]) => void
  setListData: (data: LeadItemsDetails[]) => void
  setResetCardData: () => void
  setRestoreCard: () => void
  setNewIcon: (id: string, icon: string) => void
  setNewDescription: (id: string, description: string) => void
  setNewBoardName: (id: string, boardName: string) => void
  setShowNoteModal: (data: boolean) => void
  setArchivedLeads: (data: object) => void
  setSelectedDeleteCard: (boardId: string, cardId: string) => void
  setLeadPoolStatus: (open: boolean) => void
  setContactTransactionPanel: (data: number) => void
  setSearchDataFilter: (data: FiltersData) => void
  setShowUserPassLeadFilterData: (filter: LeadPassFilter) => void
  data: LeadDetails[]
  archivedLead: LeadItemsDetails | any
  leadPool: boolean
  listData: LeadItemsDetails[]
  searchLoader: boolean
  filter: Filter
  searchData: FiltersData
  userPassLeadFilter: LeadPassFilter
}

type Props = StoreProps & RouteComponentProps<{}>

interface State {
  addBoardId: string
  addLeadIndex: string
  listViewData: LeadItemsDetails[]
  checked: boolean
  showAddLeadModal: boolean
  showLeadDetails: boolean
  showTransactionModal: boolean
  price: number
  showNotesView: boolean
  show: boolean
  loader: boolean
  appearance: boolean
  user: UserType
  userStates: UserState[]
  userFilterState: string
  showDetail: boolean
  leadData: LeadItemsDetails | any
  showLeadImportModal: boolean
  importLeadBoardId: string
  edit: boolean
  editLeadDetails: LeadItemsDetails
  icons: IconsType[]
  showUpdateScoreModal: boolean
  showArchiveLeadModal: boolean
  archivedLeadData: LeadItemsDetails
  result: any
  showComposeMail: string
}

export let ShowAddTransactionModal: any
export let ShowLeadDetailView: any
export let ToggleCheckBox: any
export let SingleCheckBox: any
export let ShowNotesView: any
export let ToggleLeadStatus: any
export let importUser: any
export let importLeadSuccessfully: any
export let exportUser: any
export let deleteBoard: any
export let sortLeads: any
export let toggle: boolean = false
export let editLead: any
export let ShowUpdateScoreModal: any
export let AcceptLead: any
export let ArchiveLead: any
export let ShowComposeMail: any
export let loggedUser: any

class Leads extends React.Component<Props, State> {
  public state = {
    addBoardId: '',
    addLeadIndex: '',
    appearance: true,
    archivedLeadData: {} as LeadItemsDetails,
    checked: false,
    edit: false,
    editLeadDetails: {} as LeadItemsDetails,
    icons: [],
    importLeadBoardId: '',
    leadData: {} as LeadItemsDetails | any,
    listViewData: [],
    loader: true,
    price: 50000,
    result: {},
    show: true,
    showAddLeadModal: false,
    showAddTransactionModal: false,
    showArchiveLeadModal: false,
    showComposeMail: '',
    showDetail: false,
    showLeadDetails: false,
    showLeadImportModal: false,
    showNotesView: false,
    showTransactionModal: false,
    showUpdateScoreModal: false,
    user: {} as UserType,
    userFilterState: 'Choose',
    userStates: []
  }

  public columnMyLeadMetaData = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomLeadName,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.leadName,
      title: Strings.leads.columnMetaData.title.leadName
    },
    {
      customComponent: CustomScore,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.score,
      title: Strings.leads.columnMetaData.title.score
    },
    {
      customComponent: CustomType,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.type,
      title: Strings.leads.columnMetaData.title.type
    },
    {
      customComponent: CustomLastActionDate,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.lastActionDate,
      title: Strings.leads.columnMetaData.title.lastActionDate
    },
    {
      customComponent: CustomOwner,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.owner,
      title: Strings.leads.columnMetaData.title.owner
    },
    {
      customComponent: CustomSource,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.source,
      title: Strings.leads.columnMetaData.title.source
    },
    {
      customComponent: CustomPrice,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.price,
      title: Strings.leads.columnMetaData.title.status
    },
    {
      customComponent: CustomSchedule,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.schedule,
      title: Strings.leads.columnMetaData.title.schedule
    },
    {
      customComponent: CustomActivities,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.activities,
      title: Strings.leads.columnMetaData.title.activities
    },
    {
      customComponent: CustomCreatedDate,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.createdDate,
      title: Strings.leads.columnMetaData.title.createdDate
    },
    {
      customComponent: CustomActions,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.Action,
      sortable: false,
      title: Strings.leads.columnMetaData.title.Action
    }
  ]

  public columnLeadPoolMetaData = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomLeadName,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.leadName,
      title: Strings.leads.columnMetaData.title.leadName
    },
    {
      customComponent: CustomPoolType,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.type,
      title: Strings.leads.columnMetaData.title.type
    },
    {
      customComponent: CustomScore,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.score,
      title: Strings.leads.columnMetaData.title.score
    },
    {
      customComponent: CustomOwner,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.owner,
      title: Strings.leads.columnMetaData.title.owner
    },
    {
      customComponent: CustomLocation,
      enhanceWithRowData: true,
      id: 'location',
      title: 'Location'
    },
    {
      customComponent: CustomReferral,
      enhanceWithRowData: true,
      id: 'referralFee',
      title: '% Referral'
    },
    {
      customComponent: CustomCreatedDate,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.createdDate,
      title: Strings.leads.columnMetaData.title.createdDate
    },
    {
      customComponent: CustomLeadPoolActions,
      enhanceWithRowData: true,
      id: Strings.leads.columnMetaData.id.Action,
      sortable: false,
      title: Strings.leads.columnMetaData.title.Action
    }
  ]

  public async componentDidMount() {
    const {
      leadPool,
      setContactTransactionPanel,
      setSearchDataFilter,
      setShowUserPassLeadFilterData,
      location
    } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = user
    setSearchDataFilter({} as FiltersData)
    if (location && location.search) {
      // showTransaction('')
    } else {
      setShowUserPassLeadFilterData({} as LeadPassFilter)
    }
    const iconsList = await getIcons()
    if (user.role !== 'GUEST') {
      const options = await getOptions()
      this.setState({ userStates: options })
    } else {
      this.setState({ userStates: [] })
    }
    toggle = leadPool
    this.setState({ user, icons: iconsList })
    this.refreshGetLeads()
    const transactions = await getTransactionBoards({})
    const newBoardId = transactions.filter((board: any) => {
      return board.name === 'New'
    })
    this.setState({ addBoardId: newBoardId[0]._id })

    setContactTransactionPanel(0)
    this.assignShowAddTransactionModal()
    this.assignShowLeadDetailView()
    this.assignToggleCheckBox()
    this.assignSingleCheckBox()
    this.assignShowNotesView()
    this.assignLeadStatus()
    this.Import()
    this.ImportedSuccessfully()
    this.Export()
    this.deleteLane()
    this.sortBoardLeads()
    this.editLead()
    this.assignShowUpdateScoreModal()
    this.assignArchiveLead()
    this.assignShowComposeMail()
    this.acceptLead()
  }

  public render() {
    const { archivedLead, leadPool, searchLoader, data: kanbanData, listData } = this.props
    const {
      showUpdateScoreModal,
      showComposeMail,
      showAddLeadModal,
      showLeadDetails,
      showTransactionModal,
      show,
      appearance,
      addBoardId,
      userStates,
      showLeadImportModal,
      icons,
      importLeadBoardId,
      userFilterState,
      user,
      edit,
      editLeadDetails,
      leadData,
      loader
    }: any = this.state

    return (
      <React.Fragment>
        {showTransactionModal &&
          leadData && (
            <CreateTransactionModal
              mutationParams={{
                addBoardId,
                edit: false,
                index: '0',
                leadId: leadData._id,
                show: false,
                user
              }}
              onClose={this.addTransaction}
            />
          )}
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={showComposeMail} />}
        {Object.keys(archivedLead).length > 0 && <ArchiveLeadModal handleClose={this.closeModal} lead={archivedLead} />}
        {showUpdateScoreModal && (
          <UpdateScoreModal closeModal={this.closeModal} changeLeadScore={this.changeLeadScore} leadData={leadData} />
        )}
        {showAddLeadModal && (
          <AddLeadModal
            closeModal={this.closeModal}
            addLead={this.addLead}
            addBoardId={addBoardId}
            edit={edit}
            editDetails={editLeadDetails}
            leadPool={leadPool}
          />
        )}
        {showLeadImportModal && (
          <ImportLeadsPopup
            handleClose={this.hideImportLeadsPopup}
            boardId={importLeadBoardId}
            userFilterState={userFilterState}
            user={user}
            toggleStatus={leadPool}
          />
        )}
        <Container>
          {(loader || searchLoader) && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
          {!showLeadDetails && (
            <StyledHeader
              user={user}
              addList={this.addList}
              showDefaultView={this.showDefaultView}
              showToggleCheckBox={true}
              toggleStatus={leadPool}
              changeToggle={this.changeToggleStatus}
              options={userStates}
              getStateLeads={this.getStateLeads}
              deleteLeads={this.deleteLeads}
              loggedUser={user}
            />
          )}
          {!showLeadDetails &&
            show &&
            kanbanData.length > 0 && (
              <KanbanView
                data={kanbanData}
                CardComponent={LeadCard}
                ActionComponent={LeadAction}
                addNewCard={this.addNewCard}
                icons={icons}
                deleteCard={this.deleteCard}
                deleteRecord={this.deleteLeadCard}
                resetCardData={this.resetCardData}
                restoreCard={this.restoreCard}
                alterData={this.alterData}
                // setNewBoard={leadPool ? undefined : this.setNewBoard}
                reorderColumn={this.reorderColumn}
                reorderCard={this.reorderLeads}
                appearance={appearance}
                deleteTransfer={false}
                moveCard={this.moveLeads}
                setNewBoardName={this.setNewBoardName}
                setNewIcon={this.setNewIcon}
                setNewDescription={this.setNewDescription}
                updateBoard={this.updateBoard}
              />
            )}
          {!showLeadDetails &&
            !show && (
              <StyledGrid
                data={listData}
                tableHeight={50}
                columnMetaData={leadPool ? this.columnLeadPoolMetaData : this.columnMyLeadMetaData}
                addList={this.addList}
              />
            )}
        </Container>
      </React.Fragment>
    )
  }

  private refreshGetLeads = async (id?: string, singleSkip?: number) => {
    const { setBoardData, setListData, searchData, userPassLeadFilter, location } = this.props
    this.setState({ loader: true })
    const user: any = await getLoggedInUser({ fromCache: true })
    const { show, userFilterState } = this.state
    if (show) {
      let kanbanData
      if (toggle) {
        if (userFilterState === 'All' || userFilterState === 'Choose') {
          kanbanData = await getLeadBoards(
            searchData,
            undefined,
            Object.keys(userPassLeadFilter).length > 0 && location && location.search ? userPassLeadFilter : undefined,
            id,
            singleSkip
          )
        } else {
          kanbanData = await getStateLeadBoards(
            userFilterState,
            searchData,
            undefined,
            Object.keys(userPassLeadFilter).length > 0 && location && location.search ? userPassLeadFilter : undefined,
            id,
            singleSkip
          )
        }
      } else {
        if (userFilterState === 'All' || userFilterState === 'Choose') {
          kanbanData = await getLeadBoards(
            searchData,
            user[`_id`],
            Object.keys(userPassLeadFilter).length > 0 && location && location.search ? userPassLeadFilter : undefined,
            id,
            singleSkip
          )
        } else {
          kanbanData = await getStateLeadBoards(
            userFilterState,
            searchData,
            user[`_id`],
            Object.keys(userPassLeadFilter).length > 0 && location && location.search ? userPassLeadFilter : undefined,
            id,
            singleSkip
          )
        }
      }
      const formatedData = formatKanbanData(kanbanData, toggle)
      setBoardData(formatedData)
      this.setState({ loader: false })
    } else {
      let listData
      if (toggle) {
        if (userFilterState === 'All' || userFilterState === 'Choose') {
          listData = await getLead(searchData)
        } else {
          listData = await getStateLead(userFilterState, searchData)
        }
      } else {
        if (userFilterState === 'All' || userFilterState === 'Choose') {
          listData = await getLead(searchData, user[`_id`])
        } else {
          listData = await getStateLead(userFilterState, searchData, user[`_id`])
        }
      }
      const formatedListData = formatListData(listData, toggle)
      setListData(formatedListData)
      this.setState({ loader: false })
    }
  }

  private addTransaction = async (result: any) => {
    this.closeModal()
    const { setContactTransactionPanel } = this.props
    if (!result) {
      return
    }

    try {
      setContactTransactionPanel(1)
      this.props.history.push({
        pathname: `${Routes.contacts.root}${Routes.contacts.details.path}/${result.contact._id}?pool=0`
      })
      Toast({ message: 'Transaction added successfully', type: 'success' })
      this.closeModal()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }
  }

  private changeLeadScore = async (score: number) => {
    const { user, leadData } = this.state
    updateLeadScore(leadData[`_id`], score, user)
    this.refreshGetLeads()
  }

  private assignShowUpdateScoreModal = () => {
    ShowUpdateScoreModal = (leadData: any) => {
      const { leadPool } = this.props
      const { user }: any = this.state
      if (leadPool && (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER')) {
        this.setState({ showUpdateScoreModal: true, leadData })
      } else if (!leadPool) {
        this.setState({ showUpdateScoreModal: true, leadData })
      }
    }
  }

  private acceptLead = () => {
    AcceptLead = async () => {
      this.refreshGetLeads()
    }
  }

  private assignShowComposeMail = () => {
    ShowComposeMail = (email: string) => {
      this.setState({ showComposeMail: email })
    }
  }

  private alterData = (data: LeadDetails[]) => {
    const { setBoardData } = this.props
    setBoardData(cloneDeep(data))
  }

  private assignArchiveLead = () => {
    ArchiveLead = (leaddata: any) => {
      this.setState({ showArchiveLeadModal: true, archivedLeadData: leaddata })
    }
  }

  private changeToggleStatus = async () => {
    const { setLeadPoolStatus, leadPool, filter } = this.props
    toggle = !leadPool
    setLeadPoolStatus(!leadPool)
    if (filter.filter === false && filter.searchText === false) {
      this.refreshGetLeads()
    }
  }

  private hideImportLeadsPopup = () => {
    this.setState({ showLeadImportModal: !this.state.showLeadImportModal })
  }

  private Import = () => {
    importUser = (boardId: string) => {
      this.setState({ importLeadBoardId: boardId, showLeadImportModal: true })
    }
  }

  private ImportedSuccessfully = () => {
    importLeadSuccessfully = async () => {
      this.refreshGetLeads()
    }
  }

  private Export = () => {
    exportUser = (boardId: string) => {
      const { userFilterState } = this.state
      exportUsers(boardId, userFilterState)
    }
  }

  private assignLeadStatus = () => {
    ToggleLeadStatus = async (cardId: string, status: string) => {
      const { user } = this.state
      await updateLeadStatus(cardId, status, user)
      this.refreshGetLeads()
    }
  }

  private assignShowNotesView = () => {
    ShowNotesView = () => {
      const { setShowNoteModal } = this.props
      setShowNoteModal(true)
      this.setState({ showLeadDetails: true })
    }
  }

  private showDefaultView = async (data: boolean) => {
    const { filter } = this.props
    this.setState({ show: data })
    if (filter.filter === false && filter.searchText === false) {
      this.refreshGetLeads()
    }
  }

  private assignToggleCheckBox = () => {
    ToggleCheckBox = () => {
      const { setListData, listData } = this.props
      const { checked } = this.state
      this.setState({ checked: !this.state.checked }, () => {
        const newData = listData.map((dataItem: any) => {
          return {
            ...dataItem,
            checked: this.state.checked
          }
        })
        setListData(newData)
      })
      Checked(checked)
    }
  }

  private assignSingleCheckBox = () => {
    SingleCheckBox = (id: string) => {
      const { setListData, listData } = this.props
      const newData = listData.map((dataItem: any) => {
        return {
          ...dataItem,
          checked: dataItem._id === id ? !dataItem.checked : dataItem.checked
        }
      })
      setListData(newData)
    }
  }

  private addList = () => {
    this.setState({ showAddLeadModal: true, addBoardId: '', edit: false, editLeadDetails: {} as LeadItemsDetails })
  }

  private resetCardData = () => {
    const { user } = this.state
    reset(user)
  }

  private restoreCard = async (boardId: string, cardId: string) => {
    const { user } = this.state
    restore(cardId, user)
  }

  private deleteCard = async (boardId: string, cardId: string) => {
    const { leadPool } = this.props
    const { user }: any = this.state

    if (leadPool) {
      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
        await deleteCard(boardId, cardId, user, leadPool)
        this.refreshGetLeads()
      }
    }
  }

  private deleteLeadCard = async (boardId: string, cardId: string) => {
    const { leadPool, data: kanbanData } = this.props
    const { user }: any = this.state
    if (leadPool) {
      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
        ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
      } else {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotDeleteTransaction, 'error')
      }
    } else {
      const currentBoard: any = kanbanData.find(e => e._id === boardId)
      const allowDeletion = currentBoard.items.find((e: any) => e._id === cardId).owner._id === user._id

      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER' || allowDeletion) {
        await deleteCard(boardId, cardId, user, leadPool)
        this.refreshGetLeads()
        ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
      } else {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotDeleteTransaction, 'error')
      }
    }
  }

  private setNewBoardName = async (id: string, boardName: string) => {
    const { user } = this.state
    const { leadPool, setBoardData, data } = this.props
    const field = 'name'
    const updatedBoardName = await updateLeadBoard(id, user, boardName, field)
    const boardIndex = data.findIndex(e => e._id === id)
    const formatedData = await formatKanbanData(
      [
        ...data.slice(0, boardIndex),
        { ...data[boardIndex], name: updatedBoardName.data.updateLeadBoard.name },
        ...data.slice(boardIndex + 1)
      ],
      leadPool
    )
    setBoardData(formatedData)
  }

  private setNewDescription = async (id: string, description: string) => {
    const { user } = this.state
    const field = 'description'
    await updateLeadBoard(id, user, description, field)
  }

  private setNewIcon = async (id: string, icon: string) => {
    const { user } = this.state
    const field = 'icon'
    await updateLeadBoard(id, user, icon[`_id`], field)
    this.refreshGetLeads()
  }

  private addNewCard = (id: string, index: string) => {
    this.setState({ showAddLeadModal: true, addLeadIndex: index, addBoardId: id })
  }

  private addLead = async (newLeadData: any) => {
    const { leadPool } = this.props
    const { addLeadIndex, addBoardId, show, user, userFilterState, edit } = this.state
    const response = await addLead(newLeadData, addLeadIndex, addBoardId, show, leadPool, user, userFilterState, edit)
    this.refreshGetLeads()
    this.setState({ leadData: response })
    return response
  }

  private reorderColumn = (boardId: string, sourceIndex: number, destinationIndex: number) => {
    const { leadPool } = this.props
    const { user, userFilterState } = this.state
    if (leadPool) {
      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
        reorderLane(boardId, sourceIndex, destinationIndex, user, userFilterState, leadPool)
      }
    } else {
      reorderLane(boardId, sourceIndex, destinationIndex, user, userFilterState, leadPool)
    }
  }

  private reorderLeads = (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
    const { leadPool } = this.props
    const { user } = this.state
    if (leadPool) {
      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
        reorderCard(boardId, cardId, sourceIndex, destinationIndex, user)
      }
    } else {
      reorderCard(boardId, cardId, sourceIndex, destinationIndex, user)
    }
  }

  private moveLeads = async (
    cardId: string,
    destinationIndex: number,
    destinationBoardId: string,
    sourceBoardId: string
  ) => {
    const { leadPool } = this.props
    const { user, userFilterState } = this.state
    if (leadPool) {
      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
        try {
          await moveCard(cardId, destinationIndex, destinationBoardId, sourceBoardId, user, leadPool, userFilterState)
        } catch (error) {
          Toast({
            message:
              'You are not authorized to move card. Please contact your administrator if you need additional assistance',
            type: 'error'
          })
        }
      } else {
        ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotMoveLead, 'error')
      }
    } else {
      if (user[`role`] !== 'GUEST') {
        await moveCard(cardId, destinationIndex, destinationBoardId, sourceBoardId, user, leadPool, userFilterState)
      } else {
        Toast(
          'You are not authorized to move card. Please contact your administrator if you need additional assistance'
        )
      }
    }
  }

  private assignShowAddTransactionModal = () => {
    ShowAddTransactionModal = async (rowData: any) => {
      try {
        await isPlanAllowed('transaction')
      } catch (error) {
        return serverToast(error)
      }
      this.setState({ showTransactionModal: true, leadData: rowData })
    }
  }

  private assignShowLeadDetailView = () => {
    ShowLeadDetailView = (leadData: any) => {
      this.setState({ showLeadDetails: true, leadData })
    }
  }

  private closeModal = async () => {
    const { setArchivedLeads } = this.props
    setArchivedLeads({})
    this.refreshGetLeads()
    this.setState({
      showAddLeadModal: false,
      showTransactionModal: false,
      showUpdateScoreModal: false,
      showArchiveLeadModal: false,
      showComposeMail: ''
    })
  }

  private getStateLeads = async (id: string, state: string) => {
    this.setState({ userFilterState: id }, () => this.refreshGetLeads())
  }

  private deleteLeads = async () => {
    const { listData } = this.props
    const { user }: any = this.state
    const deleteIds: string[] = []
    listData.forEach((list: any) => {
      if (list.checked === true) {
        deleteIds.push(list._id)
      }
    })
    const result = await deleteLeads(user, deleteIds)
    if (result !== 0 && result !== undefined) {
      this.refreshGetLeads()
    }
  }

  private deleteLane = () => {
    deleteBoard = async (boardId: string) => {
      const { user } = this.state
      const response: any = await deleteLeadBoard(boardId, user)
      if (response) {
        this.refreshGetLeads()
      }
    }
  }

  private sortBoardLeads = () => {
    sortLeads = async (boardId: string, sortOrder: number) => {
      const { leadPool, setBoardData } = this.props
      const { user } = this.state
      const response = await sortLead(boardId, sortOrder, user, leadPool)
      const formatedData = formatKanbanData(response.data.getLeadBoards, leadPool)
      setBoardData(formatedData)
    }
  }

  private editLead = () => {
    editLead = (details: any) => {
      this.setState({
        addBoardId: '',
        edit: true,
        editLeadDetails: details,
        showAddLeadModal: true
      })
    }
  }

  private updateBoard = async (id: string, singleSkip: number) => {
    this.refreshGetLeads(id, singleSkip)
  }
}

const mapStateToProps = (state: AppState) => ({
  archivedLead: state.leads.archivedLead,
  data: state.leads.data,
  filter: state.leads.filter,
  leadPool: state.leads.leadPool,
  listData: state.leads.listData,
  searchData: state.leads.searchData,
  searchLoader: state.leads.searchLoader,
  userPassLeadFilter: state.leads.userPassLeadFilter
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setArchivedLeads: Actions.archivedLeads,
      setBoardData: Actions.getBoardData,
      setContactTransactionPanel: Actions.setContactTransactionPanel,
      setDeleteCard: Actions.deleteCard,
      setLeadPoolStatus: Actions.leadPoolStatus,
      setListData: Actions.getListData,
      setNewBoard: Actions.addNewBoard,
      setNewBoardName: Actions.setNewBoardName,
      setNewCard: Actions.addNewCard,
      setNewDescription: Actions.setNewDescription,
      setNewIcon: Actions.setNewIcon,
      setResetCardData: Actions.resetCardData,
      setRestoreCard: Actions.restoreCard,
      setSearchDataFilter: Actions.searchDataFilter,
      setShowNoteModal: Actions.showNoteModal,
      setShowUserPassLeadFilterData: Actions.showUserPassLeadFilterData
    }
  )(Leads)
)
