export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`oldPassword`]) {
    formIsValid = false
    errors[`oldPassword`] = 'Cannot be Empty'
  }

  return { errors, formIsValid }
}
