// Import Packages
import { Button, Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import { fonts } from 'design/Fonts'

import {
  children,
  childWidths,
  primaryStyledButton,
  text,
  x,
  y,
} from 'design/Styled'

export const Container = styled.div`
  ${y};
  ${text};
`

export const Title = styled.h1`
  font: ${fonts.medium.medium};
  text-align: center;
  margin-bottom: 2em;
`

export const Row = styled.div`
  ${x};
  ${children};
  ${childWidths};

  margin-bottom: 0.8em;
  &:last-child {
    margin-bottom: 0;
  }

  & .rp-algolia-places {
    width: 100%;
  }
`

export const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 30px;
  width: 70%;
`

export const StyledForm = styled(Form)`
  .rp-state-dropdown {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
  margin-top: 15px !important;
`

export const StyledInput = styled(Input)`
  width: 100%;
`
