// Import Packages
import { Checkbox, Dropdown, Input } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import CalendarIcon from 'design/icons/styledBoard/calendar-icon.png'
import Close from 'design/icons/styledBoard/icon-close.png'
import Filters from 'design/icons/styledBoard/icon-filter.png'
import MenuIcon from 'design/icons/styledBoard/icon-menu.png'
import Search from 'design/icons/styledBoard/icon-search.png'
import TrelloIcon from 'design/icons/styledBoard/icon-trello.png'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars, x } from 'design/Styled'

export const Container = styled.header`
  ${x};
  justify-content: space-between;
  padding: 1.6em 2em 0.8em;
  font-size: 14px;
`

export const Filter = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black550};
  align-items: center;

  .ui.inline.dropdown > .text {
    font-weight: 100;
  }

  .ui.inline.dropdown .dropdown.icon {
    margin-left: 4px;
    font-weight: bold;
    font-size: 12px;
    top: 2px;
  }
`

export const StyledDropdown = styled(Dropdown)`
  &.ui.inline.dropdown {
    margin-top: 6px;
  }

  &.ui.inline.dropdown .menu {
    overflow: auto;
    max-height: 200px;
    ${scrollbars};
  }
`

export const FilterIcon = styled.div`
  background: url(${Filters});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  align-self: center;
  margin-right: 6px;
`

export const SearchInput = styled.div`
  width: 40%;
  justify-content: center;
  display: flex;
`
export const CustomInput = styled(Input)`
  border: 1px solid ${Colors.Grey550};
  width: 720px;
  height: 40px;
  background: ${Colors.White1000};

  &.ui.input > input {
    border: none;
  }
`

export const SearchIcon = styled.div`
  background: url(${Search});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 18px;
  align-self: center;
  float: right;
  margin-right: 10px;
  padding: 6px 0px 20px 0px;
`

export const CloseIcon = styled.div`
  background: url(${Close});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 10px;
  height: 10px;
  align-self: center;
  float: right;
  margin-right: 10px;
  padding: 6px 0px 20px 0px;
`

export const CustomButton = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const AlignContainer = styled.div`
  height: 25px;
  width: 25px;
  background-color: ${Colors.White1000};
  justify-content: center;
  display: flex;
  border: 1px solid ${Colors.Grey550};
  cursor: pointer;
`

export const TrelloContainer = styled.div`
  height: 25px;
  width: 25px;
  background-color: ${Colors.White1000};
  justify-content: center;
  display: flex;
  border: 1px solid ${Colors.Grey550};
  cursor: pointer;
`

export const Calendar = styled.div`
  background: url(${CalendarIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 18px;
  align-self: center;
  margin-top: 3px;
  cursor: pointer;
`

export const Align = styled.div`
  background: url(${MenuIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  align-self: center;
`

export const Trello = styled.div`
  background: url(${TrelloIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  align-self: center;
`

export const StyledGridContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
  background-color: ${Colors.White800};
  width: 95%;
  margin-left: 40px;
  margin-bottom: 25px;

  .table-styled {
    max-height: 560px;
    min-height: 225px;
    width: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: ${Colors.transparent};
    }
  }

  .table-styled > table > tbody > p:nth-last-child(1) {
    display: none;
  }
`

export const Action = styled.div`
  display: flex;
  font-size: 12px;
  margin-left: 15px;
  color: ${Colors.Black550};
  margin-right: 30px;

  .ui.inline.dropdown {
    flex-shrink: 0;
  }

  .ui.inline.dropdown .dropdown.icon {
    margin-left: 4px;
    font-weight: bold;
    font-size: 10px;
    top: 1px;
  }
`

export const MyLeads = styled.div`
  display: flex;
  margin-left: 25px;
  margin-top: 6px;
`

export const MyTasks = styled.div`
  display: flex;
  margin-left: 25px;
  margin-top: 6px;
`

export const StyledCheckbox = styled(Checkbox)`
  margin-left: 10px;

  &.ui.toggle.tester input:checked ~ .box:before,
  &.ui.toggle.tester input:checked ~ label:before,
  &.ui.toggle.tester input:focus:checked ~ .box:before,
  &.ui.toggle.tester input:focus:checked ~ label:before {
    width: 24px;
    height: 15px;
    background-color: ${Colors.DarkBlue200} !important;
  }

  &.ui.toggle.checkbox label::before {
    width: 24px;
    height: 15px;
    background-color: ${Colors.Grey565} !important;
  }

  &.ui.toggle.checkbox input:checked ~ label:after {
    left: 0.55rem !important;
  }

  &.ui.toggle.checkbox label::after {
    width: 12px;
    height: 11px;
    margin-top: 2px;
    margin-left: 2px;
  }

  &.ui.fitted.toggle.checkbox {
    margin-left: 10px;
    margin-top: 1px;
  }

  &.ui.fitted.toggle.checkbox {
    width: 2.7rem;
  }
`

export const AddButtonContainer = styled.div`
  cursor: pointer;
  margin-left: 10px;

  .fa-plus {
    width: 35px;
    background-color: ${Colors.DarkBlue200};
    height: 35px;
    color: white;
    border-radius: 50%;
    padding: 10px;
  }
`

export const Col = styled.div`
  display: flex;
  margin-left: 25px;
  margin-top: 6px;
`
