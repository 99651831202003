import gql from 'graphql-tag'

export const GET_WORKFLOW_CATEGORY = gql`
  query getWorklfowCategory {
    getWorkflowCategories(where: { isActive: true }) {
      _id
      name
      isActive
      workflows {
        _id
        name
        visibility
        isActive
      }
    }
  }
`
