// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Grid } from 'semantic-ui-react'

// Import Components
import Segments from './Segments'

// Import Styled Components
import { StyledGrid, UserDuration, UserName, UserProfile } from './Styled'

interface Props {
  totalResult: number
  users: any
  dates: any
  module: string
  commissionReceived: number
  totalReferrals: number
  totalDue: number
  totalPrice: number
}

class Reports extends React.Component<Props, {}> {
  public state = {}

  public render() {
    const {
      totalResult,
      users,
      dates,
      commissionReceived,
      totalDue,
      totalPrice,
      module,
      totalReferrals
    } = this.props
    return (
      <StyledGrid>
        <Grid.Row>
          <Grid.Column width={6}>
            <UserProfile background={users.profileImage} />
            <div style={{ marginLeft: '20px' }}>
              <UserName>{`${users.firstName} ${users.lastName}`}</UserName>
              {
                <UserDuration>
                  {dates[`from`] &&
                    dates[`to`] && (
                      <span>
                        {moment(dates[`from`]).format('L')} -{' '}
                        {moment(dates[`to`])
                          .add(-2, 'days')
                          .format('L')}
                      </span>
                    )}
                </UserDuration>
              }
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            <Segments
              totalResult={totalResult}
              module={module}
              totalPrice={totalPrice}
              totalDue={totalDue}
              commissionReceived={commissionReceived}
              totalReferrals={totalReferrals}
            />
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    )
  }
}

export default Reports
