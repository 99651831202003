// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background: string
}

const StyledForm = styled(Form)`
  margin-left: 18px;
  margin-top: 15px;

  &.ui.form .field .ui.input input {
    border-radius: 2px;
  }

  &.ui.form .field .ui.input {
    width: 350px !important;
    font-size: 12px;
  }
  .ui.button {
    border-radius: 2px;
    font-size: 12px;
    background: ${Colors.DarkBlue200} !important;
    color: ${Colors.White1000} !important;
  }
`

const StyledNotes = styled.div`
  width: 485px;
  margin-left: 18px;
  overflow: auto;
  height: 325px;
  ${scrollbars};
`

const PropFile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 30px;
  align-self: flex-start;
  margin-right: 10px;
  border-radius: 50%;
`

const Container = styled.div`
  display: flex;
  color: ${Colors.Black550};
  margin-bottom: 10px;
`

const Description = styled.div`
  font-size: 11px;
  line-height: 12px;
  color: ${Colors.Black500};
`

const Date = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;

  i.icon {
    margin-right: 8px;
    cursor: pointer;
  }
`

export {
  Container,
  Date,
  Description,
  PropFile,
  StyledForm,
  StyledNotes,
}
