import * as React from 'react'
import { Component } from 'react'
import { Form, Select } from 'semantic-ui-react'
import client from 'queries/apollo'
import { GET_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Queries'
import { UPDATE_TRANSACTION_SETTING_LOCAL } from 'queries/graphql/Settings/TransactionFee/Local/FeeSettingLocalMutation'
import { getTransactionsSetting } from '../TransactionFeeQueries'
import CommissionFee from './CommissionFee'
import GraphColumn from './GraphColumn'
import OtherFee from './OtherFee'
import PropertyFee from './PropertyFee'
import {
  Container,
  Inclusion,
  SegmentGroup,
  SettingColumn,
  SettingRowSection,
  StyledDivider,
  StyledForm
} from './Styled'
import TransactionSettingQuery from './TransactionSettingQuery'

export let activeTransactionSettingIndex: number = 0

interface NewData {
  additional?: number
  additionalMillionCap?: number
  base?: number
  baseMillionCap?: number
}

interface Options {
  key: string
  value: string
  text: string
}

interface State {
  selectedOption: any
  activeIndex: number
  transactions: any
}

interface Props {
  transactionsOptions: Options[]
  deleteTransactionSettingStatus: boolean
  showDeletePlan: (status: boolean, id: string) => void
  planAdded: boolean
}
class FeeSettings extends Component<Props, State> {
  public state = {
    activeIndex: 0,
    selectedOption: {},
    transactions: []
  }

  private transactionFeeId = ''

  public async componentDidMount() {
    const res = await getTransactionsSetting()
    const defaultSettings = res.find((data: any) => {
      return data.isDefault === true
    })
    this.setState({
      selectedOption: {
        key: defaultSettings._id,
        text: defaultSettings.name,
        value: `${defaultSettings.name}, ${defaultSettings._id}`
      },
      transactions: res
    })
  }

  public async componentWillReceiveProps(prevProps: any) {
    const { transactions } = this.state
    const { deleteTransactionSettingStatus, transactionsOptions, showDeletePlan, planAdded } = this.props
    if (deleteTransactionSettingStatus !== prevProps.deleteTransactionSettingStatus) {
      const defaultData: any = transactions.find((ele: any) => {
        return ele.name === 'Default'
      })
      activeTransactionSettingIndex = 0
      this.setState({
        activeIndex: 0,
        selectedOption: {
          key: defaultData[`_id`],
          text: defaultData[`name`],
          value: `${defaultData[`name`]}, ${defaultData[`_id`]}`
        }
      })
    }
    if (
      transactionsOptions !== prevProps.transactionsOptions &&
      prevProps.transactionsOptions.length > 1 &&
      planAdded !== prevProps.planAdded
    ) {
      await getTransactionsSetting()
      const res = prevProps.transactionsOptions[prevProps.transactionsOptions.length - 1]
      showDeletePlan(true, res[`key`])
      activeTransactionSettingIndex = prevProps.transactionsOptions.length - 1
      this.setState({
        activeIndex: prevProps.transactionsOptions.length - 1,
        selectedOption: {
          key: res[`key`],
          text: res[`text`],
          value: res[`value`]
        }
      })
    }
  }

  public changeResidentialRemoteData(newData: NewData) {
    let newObj = {}
    const [newDataKey] = Object.keys(newData)
    switch (newDataKey) {
      case 'base':
        newObj = {
          residentialBaseFee: newData.base
        }
        break
      case 'additional':
        newObj = {
          residentialAdditional: newData.additional
        }
        break
      case 'baseMillionCap':
        newObj = {
          residentialFirstAmount: newData.baseMillionCap
        }
        break
      case 'additionalMillionCap':
        newObj = {
          residentialIncrement: newData.additionalMillionCap
        }
        break
    }
    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public changeCommercialRemoteData(newData: NewData) {
    let newObj = {}
    const [newDataKey] = Object.keys(newData)
    switch (newDataKey) {
      case 'base':
        newObj = {
          commercialBaseFee: newData.base
        }
        break
      case 'additional':
        newObj = {
          commercialAdditional: newData.additional
        }
        break
      case 'baseMillionCap':
        newObj = {
          commercialFirstAmount: newData.baseMillionCap
        }
        break
      case 'additionalMillionCap':
        newObj = {
          commercialIncrement: newData.additionalMillionCap
        }
        break
    }
    client.mutate({
      mutation: UPDATE_TRANSACTION_SETTING_LOCAL,
      variables: { _id: this.transactionFeeId, ...newObj }
    })
  }

  public onChangeResidentialData = (transactionFeeId: string, newData: NewData) => {
    this.transactionFeeId = transactionFeeId
    this.changeResidentialRemoteData(newData)
  }

  public onChangeCommercialData = (transactionFeeId: string, newData: NewData) => {
    this.transactionFeeId = transactionFeeId
    this.changeCommercialRemoteData(newData)
  }

  public render() {
    const { transactionsOptions } = this.props
    const { selectedOption, activeIndex } = this.state
    return (
      <Container fluid={true}>
        <SegmentGroup horizontal={true} basic={true}>
          <SettingColumn basic={true} compact={true}>
            {Object.keys(transactionsOptions).length > 0 &&
              selectedOption && (
                <StyledForm size="mini">
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Select}
                      options={transactionsOptions}
                      placeholder="Plan Name"
                      value={selectedOption[`value`]}
                      onChange={this.changePlan}
                    />
                  </Form.Group>
                </StyledForm>
              )}
            <Inclusion>CAP Inclusive*</Inclusion>
            <TransactionSettingQuery fetchPolicy="network-only" query={GET_TRANSACTION_SETTING}>
              {({ loading, error, data }) => {
                if (loading) {
                  return 'Loading...'
                }
                if (error) {
                  return `Error! ${error.message}`
                }
                return data && data.getTransactionSettings && data.getTransactionSettings[activeIndex] ? (
                  <SettingRowSection>
                    <PropertyFee
                      title="Residential Fee"
                      min={0}
                      max={1000000}
                      base={data.getTransactionSettings[activeIndex].residentialBaseFee}
                      baseMillionCap={data.getTransactionSettings[activeIndex].residentialFirstAmount}
                      additional={data.getTransactionSettings[activeIndex].residentialAdditional}
                      additionalMillionCap={data.getTransactionSettings[activeIndex].residentialIncrement}
                      onChangeData={newData =>
                        this.onChangeResidentialData(data.getTransactionSettings!![activeIndex]._id, newData)
                      }
                      width={50}
                    />
                    <PropertyFee
                      title="Commercial Fee"
                      min={0}
                      max={1000000}
                      base={data.getTransactionSettings[activeIndex].commercialBaseFee}
                      baseMillionCap={data.getTransactionSettings[activeIndex].commercialFirstAmount}
                      additional={data.getTransactionSettings[activeIndex].commercialAdditional}
                      additionalMillionCap={data.getTransactionSettings[activeIndex].commercialIncrement}
                      onChangeData={newData =>
                        this.onChangeCommercialData(data.getTransactionSettings!![activeIndex]._id, newData)
                      }
                      width={50}
                    />
                  </SettingRowSection>
                ) : null
              }}
            </TransactionSettingQuery>
            <StyledDivider />
            <Inclusion style={{ marginBottom: 18, marginTop: 0 }}>CAP Inclusive*</Inclusion>
            <CommissionFee />
            <StyledDivider />
            <OtherFee />
          </SettingColumn>
          <GraphColumn />
        </SegmentGroup>
      </Container>
    )
  }

  private changePlan = async (e: any, { value }: any) => {
    const result = await getTransactionsSetting()
    const { showDeletePlan } = this.props
    const res = value.split(', ')
    const index = result.findIndex((ele: any) => {
      return ele._id === res[1]
    })
    if (res[0] === 'Default') {
      showDeletePlan(false, res[1])
    } else {
      showDeletePlan(true, res[1])
    }
    activeTransactionSettingIndex = index
    this.setState({
      activeIndex: index,
      selectedOption: {
        key: res[1],
        text: res[0],
        value
      }
    })
  }
}

export default FeeSettings
