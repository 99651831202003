const Actions = {
  addUserCreditDebit: 'ADD_USER_CREDIT_DEBIT',
  deleteUserCreditDebit: 'DELETE_USER_CREDIT_DEBIT',
  getUserCreditDebit: 'GET_USER_CREDIT_DEBIT',
  profilePercents: 'PROFILE_PERCENT',
  updateUserCreditDebit: 'UPDATE_USER_CREDIT_DEBIT',
  userPassProfileFilter: 'USER_PASS_PROFILE_FILTER_DATA',
  userStorageUsed: 'USER_STORAGE_USED'
}

export default Actions
