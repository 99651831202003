// Import Packages
import { Accordion } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background: string
  color?: string
}

const Container = styled.div`
  margin-top: 50px;
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  color: ${Colors.Black555};
  font-weight: 500;
`

const Reports = styled.div`
  background-color: ${Colors.White1000};
  height: 470px;
  overflow: auto;
  ${scrollbars};

  .ui.inline.loader {
    top: 40%;
  }
`

const AccordionContainer = styled.div`
  padding-top: 25px;
`

const StyledAccordion = styled(Accordion)`
  &.ui.accordion .title {
    background-color: ${(props: Props) => props.background || props.background};
    color: ${(props: Props) => props.color || Colors.DarkBlue200} !important;
    margin-bottom: 5px;
    font-size: 14px !important;
  }

  &.ui.accordion:not(.styled) .title~.content:not(.ui) {
    padding: 0;
  }

  span {
    margin-left: 20px;
    user-select: none;
  }

  .fa-caret-down {
    float: right;
    margin-right: 20px;
    width: 12px;
    height: 12px;
    margin-top: 4px;
  }

`

const StyledContent = styled.div`
  font-size: 13px;
  border-bottom: 1px solid ${Colors.Grey555};
  padding: 10px;
  padding-left: 40px;
  display: flex;
  cursor: pointer;
  background-color: ${(props: Props) => props.background || props.background};

  div:nth-child(1) {
    flex-grow: 1;
    color: ${Colors.Black500};
    user-select: none;
  }

  div:nth-child(2) {
    flex-grow: 1;
    color: ${Colors.Black500};
    display: flex;
    justify-content: flex-end;
  }

  .fa-trash-alt {
    margin-right: 10px;
    color: ${Colors.Black500};
  }

  .fa-pencil-alt {
    color: ${Colors.Black500};
  }
`

const Infograph = styled.div`
  background: ${(props: Props) => `url(${props.background}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`

export {
  AccordionContainer,
  Container,
  Reports,
  StyledAccordion,
  StyledContent,
  Title,
  Infograph
}

