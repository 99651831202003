import { Button, Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { SecondaryFontName } from 'design/Fonts'
import { children, text, x, y } from 'design/Styled'

interface Props {
  image?: string
  max?: number
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-size: ${(props: Props) => props.max || 400}px;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 600px) {
    background-image: url(${(props: Props) => props.image || ''});
  }
`

export const Content = styled.div`
  ${y};
  ${text};
  max-width: 400px;
  z-index: 2;
`

export const StyledForm = styled(Form)`
  &.ui.form {
    ${x};
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
  }
  .input {
    width: 100%;
    margin-bottom: 4px;
  }
  .button {
    margin-top: 8px;
  }
`

export const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: ${(props: Props) => props.max || 400}px;
`

export const Title = styled.h1`
  color: ${Colors.TextPrimary};
  font-size: 36px;
  font-weight: 600;
  margin: 0 0 0.4em 0;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  font-family: ${SecondaryFontName};
`

export const Return = styled.a`
  padding-left: 5px;
  cursor: pointer;
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const StyledInput = styled(Input)``

export const StyledButton = styled(Button)``
