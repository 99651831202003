import * as React from 'react'
import { SortableHandle } from 'react-sortable-hoc'

import { Strings } from 'utils'

import { Caret, Container, Content, Dots, Option, PassImage, Settings, StyledPopup, Title, ToolTip } from './Styled'

import { UserPassType } from 'app/Dashboard/MainContent/Passes/Types'

import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCaretDown, faPencilAlt, faTrashAlt])

interface Props {
  deletePass: (id: string) => void
  renamePass: (id: string, value: string) => void
  showModal: (pass: UserPassType) => void
  pass: UserPassType
}

interface State {
  showPopup: boolean
}

const Handle = SortableHandle(() => <Dots />)

class Pass extends React.Component<Props, State> {
  public state = {
    showPopup: false
  }

  public render() {
    const { pass } = this.props
    const { showPopup } = this.state

    return (
      <Container>
        <Handle />
        <StyledPopup
          trigger={
            <Caret>
              <FontAwesomeIcon icon={faCaretDown} />
            </Caret>
          }
          content={
            <Settings>
              <Option onClick={this.handleRename}>
                <FontAwesomeIcon icon={faPencilAlt} />
                <span>{Strings.dashboard.rename}</span>
              </Option>
              <Option onClick={this.handleDelete}>
                <FontAwesomeIcon icon={faTrashAlt} />
                <span>{Strings.dashboard.delete}</span>
              </Option>
            </Settings>
          }
          position="bottom left"
          hoverable={true}
          open={showPopup}
          onClose={this.closePopup}
          onOpen={this.openPopup}
        />
        <Content onClick={this.handleModal}>
          <PassImage icon={pass.pass.icon} size={2.7} />
          <ToolTip content={pass.name} position={'top center'} trigger={<Title>{pass.name}</Title>} />
        </Content>
      </Container>
    )
  }

  private openPopup = () => {
    this.setState({ showPopup: true })
  }

  private closePopup = () => {
    this.setState({ showPopup: false })
  }

  private handleRename = (e: React.MouseEvent) => {
    const { renamePass, pass } = this.props
    e.stopPropagation()
    this.closePopup()
    renamePass(pass._id, pass.name)
  }

  private handleDelete = (e: React.MouseEvent) => {
    const { deletePass, pass } = this.props
    e.stopPropagation()
    this.closePopup()
    deletePass(pass._id)
  }

  private handleModal = (e: React.MouseEvent) => {
    const { showModal, pass } = this.props
    e.stopPropagation()
    this.closePopup()
    showModal(pass)
  }
}

export default Pass
