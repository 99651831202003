import * as React from 'react'

import Scrollable from 'shared/Scrollable'

import { Container, Image, NoResults, Row, SliderWrapper, StyledButton, Subtitle, Text, Title } from './Styled'

import { PassType } from './Types'

interface Props {
  isAdding: boolean
  onAdd: (pass: PassType) => void
  pass: PassType
}

class Slider extends React.Component<Props, {}> {
  public render() {
    const { isAdding, onAdd, pass } = this.props

    return (
      <SliderWrapper>
        {Object.keys(pass).length ? (
          <Scrollable>
            <Container padding={1}>
              <Title>PASS DESCRIPTION</Title>
              <Row>
                <Image icon={pass.icon} size={5} />
              </Row>
              <Subtitle>{pass.name}</Subtitle>
              <Row primary={true} align={'flex-start'}>
                <Text>{pass.summary && pass.summary}</Text>
              </Row>
              <Row justify={'center'} padding={1}>
                <StyledButton disabled={isAdding} content={isAdding ? 'ADDED' : 'ADD'} onClick={() => onAdd(pass)} />
              </Row>
            </Container>
          </Scrollable>
        ) : (
          <NoResults />
        )}
      </SliderWrapper>
    )
  }
}

export default Slider
