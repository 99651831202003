import { ActionCreator } from 'redux'
import {
  DispatchEnum,
  SetUserPassAction,
  UserPassType,
} from './Types'

export const setUserPass: ActionCreator<SetUserPassAction> = (
  payload: Partial<UserPassType.Base>,
  type: DispatchEnum,
) => ({
  payload,
  type: DispatchEnum.SetUserPass
})
