// Import Packages
import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'

// Import Components
import Document from './Document/Document'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/ChecklistManager/Actions'

// Import Utils
import { generateID, Strings } from 'utils'

// Import Styled Components
import {
  Cols,
  DocumentsContainer,
  EditorButton,
  Header as StyledHeader,
  StyledDropdown,
  Title,
  XTitleText
} from './Styled'

import { OptionType } from '../Types'

interface ReduxProps {
  documents: any
}

interface DispatchProps {
  setNewDocument: (data: any) => void
  setDeleteDocument: (index: number) => void
  setUpdateDocument: (newName: string, index: number) => void
}

interface OwnProps {
  activeMls: string
  handleEditClick: (toggle: boolean, name?: string, index?: number, id?: string) => void
  mlsOptions: OptionType[]
  onUpdateMls: (value: string) => void
  showChecklistEditor: () => void
}

type Props = OwnProps & ReduxProps & DispatchProps

const addDocumentObject = {
  _id: generateID(),
  description: null,
  isActive: true,
  name: '+ Add Document'
}

class GridHeader extends Component<Props> {
  public render() {
    const {
      activeMls,
      setNewDocument,
      setDeleteDocument,
      documents,
      setUpdateDocument,
      handleEditClick,
      mlsOptions,
      showChecklistEditor
    } = this.props

    return (
      <StyledHeader>
        <Cols>
          <Title>
            <XTitleText>
              <span>
                <StyledDropdown inline={true} options={mlsOptions} value={activeMls} onChange={this.handleMlsChange} />
                {Strings.checklistManager.title.main}
              </span>
              <EditorButton onClick={showChecklistEditor}>Edit Templates</EditorButton>
            </XTitleText>
          </Title>
          <DocumentsContainer>
            {documents.map((document: any, index: number) => {
              return (
                <Document
                  mlsId={activeMls}
                  addDocument={setNewDocument}
                  editDocument={setUpdateDocument}
                  handleEditClick={handleEditClick}
                  index={index}
                  key={index}
                  item={document}
                  removeDocument={setDeleteDocument}
                />
              )
            })}
            <Document
              mlsId={activeMls}
              addDocument={setNewDocument}
              editDocument={setUpdateDocument}
              handleEditClick={handleEditClick}
              index={documents.length}
              key={documents.length}
              item={addDocumentObject}
              removeDocument={setDeleteDocument}
            />
          </DocumentsContainer>
        </Cols>
      </StyledHeader>
    )
  }

  private handleMlsChange = (e: React.SyntheticEvent, data: any) => {
    const { onUpdateMls } = this.props
    onUpdateMls(data.value)
  }
}

const mapStateToProps = (state: AppState) => ({
  documents: state.settings.checklistManager.documents
})

export default connect(
  mapStateToProps,
  {
    setDeleteDocument: Actions.deleteDocument,
    setNewDocument: Actions.addDocument,
    setUpdateDocument: Actions.editDocument
  }
)(GridHeader)
