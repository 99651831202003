import gql from 'graphql-tag'

export const GET_TOKEN_USER = gql`
  query getTokenUser($email: String!, $recaptchaToken: String!) {
    getTokenUser(email: $email, recaptchaToken: $recaptchaToken)
  }
`

export const GET_RECAPTCHA_SITE_KEY = gql`
  query getRecaptchaSiteKey {
    getRecaptchaSiteKey
  }
`
