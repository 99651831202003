// Import Packages
import * as React from 'react'
import { Menu } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import { UserType } from 'store/Transactions/Types'
import Notes from './Notes'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  RightPanel,
  StyledTab,
} from './Styled'

interface Props {
  transactionId: string
  closeModal: () => void
  user: UserType
}

interface State {
  animate: boolean
}

class ViewDetailsModal extends React.Component<Props, State> {
  public state = {
    animate: true
  }

  public render() {
    const { transactionId, user } = this.props
    const { animate } = this.state
    const panes = [
      {
        menuItem: (<Menu.Item key='Notes'>{Strings.generalText.notes}</Menu.Item>),
        render: () => <Notes transactionId={transactionId} user={user} />
      },
    ]
    return (
      <Modal content={
        <Container>
          <RightPanel>
            <StyledTab menu={{ secondary: true, pointing: true }} panes={panes} />
          </RightPanel>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={630} />
    )
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default ViewDetailsModal
