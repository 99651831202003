// Import Packages
import * as React from 'react'

// Import Components
import List from './List'

// Import Store Types, Actions and Reducers
import { Messages } from 'store/Transactions/Types'

interface Props {
  addNewMessage: (note: string) => void
  editMessage: (id: string, note: string) => void
  deleteMessage: (id: string) => void
  messageData: Messages[]
  owner: string
  loader: boolean
}

class NotesList extends React.Component<Props> {
  public state = {}

  public render() {
    const { messageData, owner, loader } = this.props
    return (
      <List loader={loader} owner={owner} addNewMessage={this.addNewMessage} messageData={messageData} editMessage={this.editMessage} deleteMessage={this.deleteMessage} />
    )
  }

  private deleteMessage = (id: string) => {
    const { deleteMessage } = this.props
    deleteMessage(id)
  }

  private editMessage = (id: string, note: string) => {
    const { editMessage } = this.props
    editMessage(id, note)
  }

  private addNewMessage = (note: string) => {
    const { addNewMessage } = this.props
    addNewMessage(note)
  }
}

export default NotesList
