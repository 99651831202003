// Import Packages
import * as React from 'react'

// Import Images and Icons
import IconBackButton from 'design/icons/styledGrid/backbutton.png'

// Import Components
import { UserType } from 'store/UserProfile/Types'
import {
  NewLayout,
  styleConfig,
} from './CustomComponent'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  AddButtonContainer,
  Container,
  Header,
  ImageWrapper,
  Infograph,
  NavigationDetail,
  NoResultsView,
  PathItem,
  PathItemContainer,
  StyledGrid as Griddle,
  StyledTableHeaderCell,
  StyledTableRow,
  Toggle,
  ToggleItem,
} from './Styled'

// Font Awesome Icons
import {
  faCloudUploadAlt,
  faPlus
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus, faCloudUploadAlt])

const MyDocumentsStrings = Strings.userProfile.detailSection.myDocuments

interface Props {
  allData: any[]
  data: any[]
  columnMetaData: any[]
  path: string[]
  tableHeight: number
  onClickItem: (id: string) => void
  onClickBack: () => void
  jump: (index: number) => void
  toggleForm: (toggle: boolean) => void
  showAddFolder: () => void
  upload: () => void
  user: UserType
}

interface State {
  showMyDocumentForm: boolean
  gridKey: number
}

// Component
export default class NavigatorGrid extends React.Component<Props, State> {
  public state = {
    gridKey: new Date().getTime(),
    showMyDocumentForm: false
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({ gridKey: new Date().getTime() })
  }

  public render() {
    const { allData, data, columnMetaData, path, onClickItem, onClickBack, jump, showAddFolder, user, upload } = this.props
    const { showMyDocumentForm, gridKey } = this.state
    const NewRow = (props: any) => {
      const onClick = (props.rowData.type === MyDocumentsStrings.folderType || props.rowData.type === MyDocumentsStrings.sharedFolderType) ? onClickItem.bind(null, props.rowData.id) : undefined
      return (<StyledTableRow
        style={props.style}
        className="item"
        onDoubleClick={onClick}>
        {
          columnMetaData.map((item, index) => {
            const { customComponent: CustomComponent } = item
            return <td key={index} style={styleConfig.styles.Cell}>
              <CustomComponent rowData={props.rowData} {...item} />
            </td>
          })
        }
      </StyledTableRow>)
    }

    const NewTableHeading = (props: any) => {
      return (<thead style={props.style}>
        <StyledTableRow className="header">
          {
            columnMetaData.map((item, index) => {
              return <StyledTableHeaderCell key={index}>
                {item.title}
              </StyledTableHeaderCell>
            })
          }
        </StyledTableRow>
      </thead>)
    }

    const PathItems = path.map((pathItem, index) => {
      if (pathItem === MyDocumentsStrings.none) {
        return <PathItemContainer onClick={jump.bind(null, 0)}><PathItem>{MyDocumentsStrings.documents}</PathItem> > </PathItemContainer>
      }
      const itemData = allData.find(dataItem => dataItem.id === pathItem)
      if (itemData) {
        return <PathItemContainer onClick={jump.bind(null, index)}><PathItem>{`${itemData.fileName}`}</PathItem> > </PathItemContainer>
      }
      return null
    })

    return <div>
      <NavigationDetail>
        <ImageWrapper backgroundImage={IconBackButton} className='backbutton' onClick={path.length > 1 ? onClickBack : undefined} />
        {PathItems}
        <Header>
          <ToggleItem isToggled={showMyDocumentForm}>{MyDocumentsStrings.mine}</ToggleItem>
          <Toggle
            onChange={this.toggleForm}
            toggle={true}
          />
          <ToggleItem isToggled={!showMyDocumentForm}>{MyDocumentsStrings.company}</ToggleItem>
          {
            ((showMyDocumentForm && (user.role === 'ADMIN' || user.role === 'MANAGER')) || !showMyDocumentForm) &&
            <React.Fragment>
              <AddButtonContainer onClick={showAddFolder}>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </AddButtonContainer>
              <AddButtonContainer onClick={upload}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
              </AddButtonContainer>
            </React.Fragment>
          }
        </Header>
      </NavigationDetail>
      <Container>
        <Griddle
          data={data}
          components={{
            Layout: NewLayout,
            NoResults: () => (
              <NoResultsView><Infograph backgroundImage={Strings.generalText.nothing} /></NoResultsView>
            ),
            Row: NewRow,
            TableHeading: NewTableHeading,
          }}
          classNames={['griddle-table']}
          styleConfig={styleConfig}
          pageProperties={{
            currentPage: 1,
            pageSize: data.length
          }}
          key={gridKey}
        />
      </Container>
    </div>
  }

  private toggleForm = () => {
    const { toggleForm } = this.props
    this.setState((prev: State) => ({ showMyDocumentForm: !prev.showMyDocumentForm }), () => { toggleForm(this.state.showMyDocumentForm) })
  }
}
