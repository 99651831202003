import gql from 'graphql-tag'

import {
  officeFragment,
  officeMlsFragment,
  officeUserFragment,
} from './Fragments'

export const CREATE_OFFICE = gql`
  mutation createOffice(
    $branchName: String
    $city: String
    $email: String
    $mls: ID
    $mlsId: String
    $officeOrder: Int
    $phone: String
    $state: String
    $street: String
    $zipCode: String
  ) {
    createOffice(
      input: {
        branchName: $branchName
        city: $city
        email: $email
        mls: $mls
        mlsId: $mlsId
        officeOrder: $officeOrder
        phone: $phone
        state: $state
        street: $street
        zipCode: $zipCode
      }
    ) {
      ...OfficeFragment
      isActive
      users {
        ...OfficeUserFragment
        emails {
          value
        }
        phones {
          type
          value
        }
      }
    }
  }
  ${officeFragment}
  ${officeUserFragment}
`

export const UPDATE_OFFICE = gql`
  mutation updateOffice(
    $branchName: String
    $city: String
    $email: String
    $id: ID
    $mls: ID
    $mlsId: String
    $officeOrder: Int
    $phone: String
    $state: String
    $status: String
    $street: String
    $zipCode: String
  ) {
    updateOffice(
      where: { _id: $id }
      input: {
        branchName: $branchName
        city: $city
        email: $email
        mls: $mls
        mlsId: $mlsId
        officeOrder: $officeOrder
        phone: $phone
        state: $state
        status: $status
        street: $street
        zipCode: $zipCode
      }
    ) {
      ...OfficeFragment
      isActive
      mls {
        ...OfficeMlsFragment
      }
    }
  }
  ${officeFragment}
  ${officeMlsFragment}
`

export const UPDATE_OFFICE_ORDER = gql`
  mutation updateOfficeOrder(
    $board: String
    $cardId: ID
    $source: Int
    $destination: Int
  ) {
    updateOfficeOrder(
      where: { _id: $cardId, state: $board, officeOrder: $source }
      input: { officeOrder: $destination }
    ) {
      ...OfficeFragment
      isActive
    }
  }
  ${officeFragment}
`

export const UPDATE_OFFICE_MANAGER = gql`
  mutation changeOfficeManager($officeId: ID, $userId: String) {
    changeOfficeManager(input: { _id: $officeId, user: $userId }) {
      ...OfficeFragment
      isActive
    }
  }
  ${officeFragment}
`

export const DELETE_OFFICE = gql`
  mutation transferOfficeData($deleteOfficeId: String, $newOfficeId: String) {
    transferOfficeData(
      where: { officeId: $deleteOfficeId }
      input: { officeId: $newOfficeId }
    ) {
      ...OfficeFragment
      isActive
    }
  }
  ${officeFragment}
`

export const CHANGE_OFFICE_STATUS = gql`
  mutation changeOfficeStatus($officeIds: [String], $status: String) {
    changeOfficeStatus(_id: $officeIds, status: $status, isActive: true) {
      ...OfficeFragment
      isActive
    }
  }
  ${officeFragment}
`
