// Import Packages
import * as React from 'react'
import { Table } from 'semantic-ui-react'

// Import Images and Icons
import Colors from 'design/Colors'

// Import Utils
import { Strings } from 'utils'

import { Commission, TotalCommissions } from 'store/Transactions/Types'

// Import Styled Components
import {
  StyledTable,
} from './Styled'

interface Props {
  commission: Commission
  totalCommissions: TotalCommissions
}

const TotalIncome = ({ commission, totalCommissions }: Props) => (
  <StyledTable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>{Strings.dashboard.commission.totalIncome}</Table.HeaderCell>
        <Table.HeaderCell style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>{Strings.dashboard.commission.totalExpenses}</Table.HeaderCell>
        <Table.HeaderCell style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>{Strings.dashboard.commission.commDue}</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell style={{ fontWeight: 600, color: Colors.TextPositive }}>{totalCommissions.totalIncome || totalCommissions.totalIncome===0  ? `${Number(Number(totalCommissions.totalIncome)).toLocaleString()}` : '-'}</Table.Cell>
        <Table.Cell style={{ fontWeight: 600, color: Colors.TextNegative }}>{totalCommissions.totalExpenses || totalCommissions.totalExpenses===0  ? `${Number(Number(totalCommissions.totalExpenses)).toLocaleString()}` : '-'}</Table.Cell>
        <Table.Cell style={{ fontWeight: 600, color: Colors.DarkBlue234 }}><span style={{ padding: 3 }}>{totalCommissions.commissionDue || totalCommissions.commissionDue===0 ? `${Number(Number(totalCommissions.commissionDue)).toLocaleString()}` : '-'}</span></Table.Cell>
      </Table.Row>
    </Table.Body>
  </StyledTable>
)

export default TotalIncome
