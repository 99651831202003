import moment from 'moment'
import * as React from 'react'
import InputMask from 'react-input-mask'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Accordion, Button, Form, Icon, Input, Select } from 'semantic-ui-react'

import AddressInput from 'shared/AddressInput'
import DatePicker from 'shared/DatePicker'
import StateDropdown from 'shared/StateDropdown'
import { serverToast } from 'shared/Toast/Toast'

import { updateContactDetails } from 'app/Contacts/Dashboard/ContactMutations'
import { getContactActivities, getContactDetails } from 'app/Contacts/Dashboard/ContactQueries'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Contacts/Actions'
import { Activities, ContactListDetails } from 'store/Contacts/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

import { Container, StyledAccordion, StyledForm } from './Styled'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'
import { FormErrorType, FormType, OptionType } from './Types'

import { RouteParams } from 'app/Contacts/ContactDetail/Types'

interface StoreProps {
  contactData: ContactListDetails
  setActivities: (data: Activities) => void
  setContactDetail: (data: ContactListDetails) => void
}

type Props = StoreProps & RouteComponentProps<RouteParams, {}>

interface State {
  activeIndex: number
  altered: boolean
  disabled: boolean
  form: FormType
  openAnniversary: boolean
  openDateOfBirth: boolean
}

const booleanOptions: OptionType[] = [{ key: 1, text: 'Yes', value: true }, { key: 2, text: 'No', value: false }]

const genderOptions: OptionType[] = [{ key: 1, text: 'Mr', value: 'Mr' }, { key: 2, text: 'Ms', value: 'Ms' }]

const SourceOptions: OptionType[] = [
  { key: 1, value: 'Call', text: 'Call' },
  { key: 2, value: 'Email', text: 'Email' },
  { key: 3, value: 'Trade', text: 'Trade' },
  { key: 4, value: 'Referral', text: 'Referral' },
  { key: 5, value: 'Seminar', text: 'Seminar' },
  { key: 6, value: 'Website', text: 'Website' },
  { key: 7, value: 'Third_Party', text: 'Third Party' }
]

class Details extends React.Component<Props, State> {
  public state = {
    activeIndex: -1,
    altered: false,
    disabled: true,
    form: {
      address: {} as AddressType,
      errors: {} as FormErrorType
    } as FormType,
    openAnniversary: false,
    openDateOfBirth: false
  }

  public async componentDidMount() {
    this.initializeForm()
  }

  public render() {
    const { activeIndex, altered, disabled, form, openAnniversary, openDateOfBirth } = this.state

    return (
      <Container>
        <StyledForm size={'mini'}>
          <Form.Group widths="equal">
            <Form.Field
              className="mr"
              control={Select}
              disabled={disabled}
              label="Mr"
              name="genderPrefix"
              onChange={this.handleInputChange}
              options={genderOptions}
              placeholder="Mr"
              value={form.genderPrefix}
            />
            <Form.Field
              className="name"
              control={Input}
              disabled={disabled}
              label="First name"
              name="firstName"
              onChange={this.handleInputChange}
              placeholder="First name"
              value={form.firstName}
            />
            <Form.Field
              className="name"
              control={Input}
              disabled={disabled}
              label="Last name"
              name="lastName"
              onChange={this.handleInputChange}
              placeholder="Last name"
              value={form.lastName}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              className="name"
              control={Input}
              disabled={true}
              label="Email"
              name="email"
              placeholder="Email"
              value={form.email}
            />
            <Form.Field className="name" control={Input} disabled={disabled} label="Phone">
              <InputMask
                mask="(999) 999-9999"
                name="phone"
                onChange={this.handlePhoneChange}
                placeholder="(xxx) xxx-xxxx"
                value={form.phone}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <label>Street Address</label>
              <AddressInput
                address={form.address}
                onChange={this.handleAddressChange}
                placeholder="Street Address"
                size={'small'}
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <label>State</label>
              <StateDropdown
                placeholder="State"
                name="state"
                value={form.address.state}
                onChange={this.handleStateChange}
              />
            </div>
            <div className="field">
              <label>City</label>
              <AddressInput
                address={form.address}
                mode={AddressModeEnum.City}
                onChange={this.handleCityChange}
                placeholder="City"
                size={'small'}
              />
            </div>
            <Form.Field
              control={Input}
              disabled={disabled}
              label="Zipcode"
              name="zipCode"
              onChange={this.handleZipCodeChange}
              placeholder="xxxxxx"
              value={form.address.zipCode}
            />
          </Form.Group>
          <Form.Field
            className="source"
            control={Select}
            disabled={disabled}
            label="Source"
            name="source"
            onChange={this.handleInputChange}
            options={SourceOptions}
            placeholder="Source"
            value={form.source}
          />
        </StyledForm>

        <StyledAccordion>
          <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordion}>
            <Icon name="dropdown" />
            <span>
              {Strings.contact.personal}
              <i>{Strings.contact.optional}</i>
            </span>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <StyledForm size={'mini'}>
              <Form.Field
                className="pets"
                control={Select}
                disabled={disabled}
                label="Pets"
                name="pets"
                onChange={this.handleInputChange}
                options={booleanOptions}
                placeholder="Select"
                value={form.pets}
              />
              <Form.Field
                action={{
                  icon: (
                    <DatePicker
                      date={new Date()}
                      handleClose={this.handleCloseDatePicker}
                      handleOpen={this.handleOpenDatePicker}
                      maxDate={true}
                      onChangeDate={(value: Date) => this.handleDateChange('dateOfBirth', value)}
                      open={openDateOfBirth}
                    />
                  ),
                  onClick: this.handleOpenDatePicker
                }}
                autoComplete="off"
                className="birthdate"
                control={Input}
                disabled={disabled}
                label="Birthdate"
                name="dateOfBirth"
                onClick={this.handleOpenDatePicker}
                placeholder="Select Date"
                value={form.dateOfBirth ? moment(form.dateOfBirth).format('L') : ''}
              />
              <Form.Group widths="equal">
                <Form.Field
                  className="married"
                  control={Select}
                  disabled={disabled}
                  label="Married"
                  name="married"
                  onChange={this.handleInputChange}
                  options={booleanOptions}
                  placeholder="Married"
                  value={form.married}
                />
                <Form.Field
                  action={{
                    icon: (
                      <DatePicker
                        date={new Date()}
                        handleClose={this.handleCloseDatePicker}
                        handleOpen={() => this.handleOpenDatePicker('anniversaryDate')}
                        maxDate={true}
                        onChangeDate={(value: Date) => this.handleDateChange('anniversaryDate', value)}
                        open={openAnniversary}
                      />
                    ),
                    onClick: () => this.handleOpenDatePicker('anniversaryDate')
                  }}
                  autoComplete="off"
                  control={Input}
                  disabled={disabled}
                  label="Anniversary Date"
                  name="anniversaryDate"
                  onClick={() => this.handleOpenDatePicker('anniversaryDate')}
                  placeholder="Select Date"
                  value={form.anniversaryDate ? moment(form.anniversaryDate).format('L') : ''}
                />
                <Form.Field
                  control={Select}
                  disabled={disabled}
                  label="Children"
                  name="children"
                  onChange={this.handleInputChange}
                  options={booleanOptions}
                  placeholder="Select"
                  value={form.children}
                />
              </Form.Group>
            </StyledForm>
          </Accordion.Content>
        </StyledAccordion>
        <Form.Field control={Button} content={'SAVE'} disabled={!altered} onClick={this.handleSubmit} />
      </Container>
    )
  }

  private handleAccordion = (e: React.SyntheticEvent<EventTarget>, { index }: any) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  private handlePhoneChange = (e: any) => {
    this.handleInputChange(null, { name: 'phone', value: e.target.value })
  }

  private handleDateChange = (name: string, value: any) => {
    this.handleInputChange(null, { name, value })
    this.handleCloseDatePicker()
  }

  private handleAddressChange = (address: AddressType) => {
    this.handleInputChange(null, { name: 'address', value: address })
  }

  private handleCityChange = (address: AddressType) => {
    const { form } = this.state
    const value = {
      ...form.address,
      city: address.city,
      state: address.state
    }
    this.handleAddressChange(value)
  }

  private handleStateChange = (e: React.SyntheticEvent<EventTarget>, data: any) => {
    const { form } = this.state
    const value = {
      ...form.address,
      state: data.value
    }
    this.handleAddressChange(value)
  }

  private handleZipCodeChange = (e: React.SyntheticEvent<EventTarget>, data: any) => {
    const { form } = this.state
    const value = {
      ...form.address,
      zipCode: data.value
    }
    this.handleAddressChange(value)
  }

  private handleOpenDatePicker = (date?: string) => {
    if (date === 'anniversaryDate') {
      this.setState({ openAnniversary: true })
    } else {
      this.setState({ openDateOfBirth: true })
    }
  }

  private handleCloseDatePicker = () => {
    this.setState({
      openAnniversary: false,
      openDateOfBirth: false
    })
  }

  private handleInputChange = async (event: any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({
      altered: true,
      form
    })
  }

  private handleSubmit = async () => {
    const { contactData, setContactDetail, setActivities } = this.props

    const { form } = this.state
    const contact: any = {
      ...contactData,
      ...form
    }
    delete contact.errors

    try {
      const updated: any = await updateContactDetails(contact)
      setContactDetail(updated.data.updateContact)

      const contactactivites = await getContactActivities(contactData._id)
      setActivities(contactactivites)
    } catch (error) {
      serverToast(error)
    }
  }

  private initializeForm = async () => {
    const { contactData, match, setContactDetail } = this.props

    let contact: any = contactData
    if (match.params.id !== contact._id) {
      contact = (await getContactDetails(match.params.id)) || contactData
      setContactDetail(contact)
    }

    const address = (contact.address || {}) as AddressType
    address.streetAddress = `${address.streetNumber || ''} ${address.streetName || ''}`.trim()
    const form = {
      ...contact,
      address,
      errors: {} as FormErrorType
    } as FormType

    const user: any = await getLoggedInUser({ fromCache: true })
    const disabled: boolean = !((contact.owner && contact.owner._id) === user._id)

    this.setState({
      disabled,
      form
    })
  }
}

const mapStateToProps = (state: AppState) => ({
  contactData: state.contacts.contactDetail
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setActivities: Actions.getActivities,
      setContactDetail: Actions.editContactDetail
    }
  )(Details)
)
