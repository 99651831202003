import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts, SecondaryFontFamily } from 'design/Fonts'

import { loginBg } from 'design/images/onboarding'

interface Props {
  align?: string
  error?: boolean
  justify?: string
  width?: number
}

const x = css`
  display: flex;
  flex-direction: row;
  align-items: ${(props: Props) => props.align || 'center'};
  justify-content: ${(props: Props) => props.justify || 'flex-start'};
  width: ${(props: Props) => props.width || 100}%;
`

const y = css`
  display: flex;
  flex-direction: column;
  align-items: ${(props: Props) => props.align || 'flex-start'};
  justify-content: ${(props: Props) => props.justify || 'flex-start'};
`

const childMargin = '0.5em'
const children = css`
  & > * {
    margin-left: ${childMargin};
    margin-right: ${childMargin};
  }

  & > :first-child {
    margin-left: 0;
  }

  & > :last-child {
    margin-right: 0;
  }
`

const text = css`
  font: ${fonts.medium.bold};
  font-family: ${SecondaryFontFamily};
  color: ${Colors.Text};
  margin: 0;
  line-height: 1;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url(${loginBg});
  background-color: ${Colors.Container};
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`

export const Content = styled.main`
  ${text};
  width: 100%;
  max-width: 500px;
  padding: 1em;
`

export const Logo = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 2em;
`

export const Form = styled.form`
  ${y};
`

export const Input = styled.input`
  ${text};
  width: 100%;
  padding: 0.5em 0.9em;
  margin-bottom: 1.4em;
  font-weight: 400;
  color: ${(props: Props) =>
    props.error ? Colors.ButtonDanger : Colors.ButtonPrimary};
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: Props) =>
    props.error ? Colors.ButtonDanger : Colors.ButtonPrimary};
  box-shadow: ${(props: Props) =>
    props.error
      ? `inset 0 0 4px -1px ${Colors.ButtonDanger}`
      : `inset 0 0 0 0 ${Colors.transparent}`};
  outline: none;
  transition: all 200ms;

  &::placeholder {
    color: ${(props: Props) =>
      props.error ? Colors.ButtonDanger : Colors.TextSecondary};
  }

  &:focus {
    border-color: ${(props: Props) =>
      props.error ? Colors.ButtonDanger : Colors.ButtonPrimaryHover};
    color: ${(props: Props) =>
      props.error ? Colors.ButtonDanger : Colors.ButtonPrimaryHover};
    box-shadow: ${(props: Props) =>
      props.error
        ? `inset 0 0 4px -1px ${Colors.ButtonDanger}`
        : `inset 0 0 4px -1px ${Colors.ButtonPrimary}`};
  }
`

export const Register = styled.a`
  ${text};
  margin: 0.2em 0em;
  color: ${Colors.TextHighlight};
  cursor: pointer;
  transform: color 200ms;

  &:hover {
    color: ${Colors.TextHighlightHover};
  }
`
export const Forgot = styled.a`
  ${text};
  margin: 0.2em 0em;
  color: ${Colors.TextSecondary};
  cursor: pointer;
  transform: color 200ms;

  &:hover {
    color: ${Colors.TextSecondaryHover};
  }
`

export const Confirm = styled(Forgot)``

export const Social = styled.a`
  ${x};
  font: ${fonts.small.regular};
  text-transform: uppercase;
  color: ${Colors.ButtonPrimary};
  padding: 0.4em 0.75em;
  background: ${Colors.Container};
  border-radius: 0.5em;
  border: 1px solid ${Colors.ButtonPrimary};
  cursor: pointer;

  &:hover {
    color: ${Colors.ButtonPrimaryHover};
    border-color: ${Colors.ButtonPrimaryHover};
  }

  span {
    margin-left: 0.5em;
  }
`

export const Icon = styled.img`
  height: 2em;
`

export const Button = styled.button`
  ${text};
  position: relative;
  padding: 0.5em 0.9em;
  font-size: 1.2em;
  border-radius: 0;
  border: 1px solid ${Colors.ButtonHighlight};
  background: ${Colors.Container};
  color: ${Colors.ButtonPrimary};
  cursor: pointer;
  outline: none;
  transition: all 200ms;

  &:hover {
    color: ${Colors.ButtonPrimaryHover};
    border-color: ${Colors.ButtonHighlightHover};
  }
`

export const ConciergeIcon = styled.img`
  height: 4em;
`

export const ConciergeTitle = styled.h1`
  margin: 0;
  line-height: 1;
  font-family: ${SecondaryFontFamily};
  font-weight: 700;
  font-size: 1.8em;
  text-transform: uppercase;
  color: ${Colors.TextPrimary};
`

export const ConciergeSubtitle = styled.h2`
  margin: 0;
  line-height: 1;
  font: ${fonts.large.regular};
  text-transform: capitalize;
  color: ${Colors.TextHighlight};
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Col = styled.div`
  ${y};
`

export const Break = styled.div`
  ${x};
  ${text};
  position: relative;
  margin: 1.5em 0;

  span {
    font-size: 90%;
    padding: 0.25em 0.5em;
    color: ${Colors.TextSecondary};
    background: ${Colors.Container};
    text-transform: uppercase;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -1px;
    border-top: 1px dashed ${Colors.EmphasisLine};
    z-index: 0;
  }
`

export const Terms = styled.small`
  ${x};
  ${text};
  font-size: 0.7em;
  color: ${Colors.TextSecondary};
  align-items: end;

  a {
    color: ${Colors.TextSecondary};
    transform: color 200ms;

    &:hover {
      color: ${Colors.TextSecondaryHover};
    }
  }

  & > *:after {
    content: ' ';
    margin: 0 0.5em;
  }

  & > :last-child:after {
    content: '';
    margin: 0;
  }
`
