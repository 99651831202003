import * as React from 'react'

import { Container, Image, Zoom } from './Styled'

import { ImageType } from './Types'

interface Props {
  image: ImageType
  onChange: (image: ImageType) => void
  onClick: () => void
}

interface State {
  isDragging: boolean
  isMouseDown: boolean
  x: number
  y: number
}

class ImageEditor extends React.Component<Props, State> {
  public state = {
    isDragging: false,
    isMouseDown: false,
    x: 0,
    y: 0
  }

  public render() {
    const { image } = this.props

    return (
      <Container onMouseDown={this.handleMouseDown} onMouseMove={this.handleMouseMove} onMouseUp={this.handleMouseUp}>
        <Image size={image.size} url={image.url} x={image.x} y={image.y} />
        <Zoom onScroll={this.handleScroll} />
      </Container>
    )
  }

  private handleMouseDown = (e: any) => {
    this.setState({
      isDragging: false,
      isMouseDown: true,
      x: e.clientX,
      y: e.clientY
    })
  }

  private handleMouseMove = (e: any) => {
    const { image, onChange } = this.props

    const { isMouseDown, x: prevX, y: prevY } = this.state

    if (!isMouseDown || !image.url) {
      return
    }

    const update = {
      ...image,
      x: (image.x || 0) - (prevX - e.clientX),
      y: (image.y || 0) - (prevY - e.clientY)
    }

    this.setState({
      isDragging: true,
      x: e.clientX,
      y: e.clientY
    })
    onChange(update)
  }

  private handleMouseUp = (e: any) => {
    const { onClick } = this.props
    const { isDragging } = this.state
    if (!isDragging) {
      onClick()
    }
    this.setState({
      isMouseDown: false
    })
  }

  private handleScroll = (e: any) => {
    const { image, onChange } = this.props

    const size = 1 + e.target.scrollTop * 0.01
    const update = {
      ...image,
      size
    }
    onChange(update)
  }
}

export default ImageEditor
