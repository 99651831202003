// Import Packages
import { escapeRegExp, filter } from 'lodash'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Content from './Content'
import GridView from './GridView'
import Modules from './Modules'
import Reports1099 from './Reports1099'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'

import { Container, Row } from './Styled'

interface Props extends RouteComponentProps<{}, {}> {
  report: string
  setPerviewModule: (data: string) => void
  module: string
}

interface State {
  activeIndex: string
  module: string
  showGridView: boolean
  previousPage: string
  yearModal: boolean
  source: any
  isLoading: boolean
  animate: boolean
  results: any
  value: string
}

const getItems = (count: any) =>
  Array.from({ length: count }, (v, k) => k).map((v, k) => ({
    id: `item-${k}`,
    title: `${1993 + v}`
  }))

class Reports extends React.Component<Props, State> {
  public state = {
    activeIndex: '',
    animate: true,
    isLoading: false,
    module: '',
    previousPage: '',
    results: '',
    showGridView: false,
    source: getItems(50),
    value: '',
    yearModal: false
  }

  public componentDidMount() {
    const { setPerviewModule, location } = this.props
    if (location && location.search) {
      this.setState({ previousPage: location.search.split('=')[1].toLocaleUpperCase() })
    }
    this.resetComponent()
    const { report } = this.props
    let index
    switch (report) {
      case 'teams':
        index = 'TEAMS'
        break
      case 'users':
        index = 'USERS'
        break
      case 'contacts':
        index = 'CONTACTS'
        break
      case 'transactions':
        index = 'TRANSACTIONS'
        break
      case 'offices':
        index = 'OFFICES'
        break
      default:
        index = 'MY REPORTS'
        break
    }
    setPerviewModule(index)
    this.setState({ activeIndex: index })
  }

  public resetComponent = () => this.setState({ isLoading: false, results: [], value: '' })

  public handleResultSelect = (e: any, { result }: any) => this.setState({ value: result.title })

  public handleSearchChange = (e: any, { value }: any) => {
    this.setState({ isLoading: true, value })

    setTimeout(() => {
      if (this.state.value.length < 1) {
        return this.resetComponent()
      }

      const re = new RegExp(escapeRegExp(this.state.value), 'i')
      const isMatch = (result: any) => re.test(result.title)

      this.setState({
        isLoading: false,
        results: filter(this.state.source, isMatch)
      })
    }, 300)
  }

  public render() {
    const { showGridView, activeIndex, yearModal } = this.state
    return (
      <Container>
        {yearModal && <Reports1099 closeModal={this.closeModal} />}
        {!showGridView && (
          <Container>
            <Modules showReports={this.showReports} activeIndex={activeIndex} />
            <Row primary={true}>
              <Content preview={this.preview} showReports={this.showDuplicateReport} />
            </Row>
          </Container>
        )}
        {showGridView && <GridView closeGridView={this.closeGridView} />}
      </Container>
    )
  }

  private closeModal = () => {
    this.setState({ animate: false })
    window.setTimeout(() => {
      this.setState({ value: '', yearModal: false })
    }, 300)
  }

  private preview = (view: string) => {
    if (view === 'table') {
      this.setState({ showGridView: true })
    }
  }

  private closeGridView = () => {
    this.setState({ showGridView: false })
  }

  private showReports = (title: string) => {
    if (title === '1099 REPORT') {
      this.setState({ yearModal: true, animate: true })
    }
    this.setState({ module: title })
  }

  private showDuplicateReport = (title: string) => {
    this.setState({ module: title, activeIndex: 'MY REPORTS' })
  }
}

const mapStateToProps = (state: AppState) => ({
  module: state.reports.module,
  report: state.reports.report
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setPerviewModule: Actions.perviewModule
    }
  )(Reports)
)
