import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'
import { Divider, Form } from 'semantic-ui-react'
import SemanticContainer from 'semantic-ui-react/dist/commonjs/elements/Container'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

interface SettingSectionProps {
  width?: number
}

const SegmentGroup = styled(Segment.Group)`
  &.ui.segments {
    border: none;
    box-shadow: none;
  }

  &.ui.horizontal.segments {
    margin: 0;
    border: none;
  }
`
const Container = styled(SemanticContainer)`
  &.ui.container {
    border: none;
    box-shadow: none;
    overflow-y: auto;
    max-height: 90%;
  }
  ${scrollbars};
`

const SettingColumn = styled(Segment.Group)`
  &.ui.segments {
    border: none;
    box-shadow: none;
    width: 65%;
    margin: 0 !important;
  }
`

const SettingSection = styled(Segment.Group)`
  &&.ui.segments{
    width: ${(props: SettingSectionProps) => (props.width ? `${props.width}%` : '100%')};
    border: none;
    box-shadow: none;
  }

  &&.ui.segments>.horizontal.segments {
    border-top: none;
  }
`

const SettingRowSection = styled(Segment)`
  .ui.compact.segments && {
    display: inline-flex;
    border-top: none;
    padding-top: 15px;
  }
`

const VerticalText = styled(Header)`
  transform: rotate(-180deg);
  text-orientation: mixed;
  writing-mode: vertical-lr;
  text-align: center;

  &.ui.center.aligned.header, .ui.centered.header {
    font-size: 12px;
    font-weight: 600;
    color: ${Colors.Black500};
  }
`

const StyledForm = styled(Form)`
  &.ui.form [class*="equal width"].fields>.field, .ui[class*="equal width"].form .fields>.field {
    max-width: 165px;
  }

  &.ui.form .field>.selection.dropdown {
    width: 50%;
    border-radius: 0;
  }

  &.ui.mini.form {
    background: ${Colors.White530};
    padding-top: 10px;
    padding-bottom: 1px;
    padding-left: 25px;
  }

  .ui.button {
    font-size: 0.9rem;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    padding: 0.685714em 1.5em .78571429em;
  }
`

const BorderTree = styled.div`
  border: 3px solid ${Colors.White525};
  border-right: 0;
  padding: 2px;
  margin-left: 5px;
`

const StyledDivider = styled(Divider)`
  &.ui.divider {
    margin-left: 35px;
  }

  &.ui.divider:not(.vertical):not(.horizontal) {
    border-top: 0.5px solid ${Colors.Black905};;
    border-bottom: 0.5px solid ${Colors.Black905};;
  }
`

const Plus = styled.div`
  @media (min-width: 1350px) and (max-width: 1600px) {
    margin-right: 10px;
  }

  @media (min-width: 1600px) and (max-width: 1900px) {
    margin-right: 30px;
  }
`

const Inclusion = styled.div`
  color: ${Colors.Black550};
  font-size: 12px;
  text-align: end;
  margin-right: 20px;
  margin-top: 10px;
`

export {
  BorderTree,
  Inclusion,
  Container,
  SettingColumn,
  SegmentGroup,
  SettingRowSection,
  SettingSection,
  StyledDivider,
  StyledForm,
  VerticalText,
  Plus
}
