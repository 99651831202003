import * as Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

export const hasSessionCookie = () => {
  const userCookie = getCookie('connect.sid')
  if (userCookie) {
    return true
  }
  return false
}

export const getCookie = (cookieName: string) => {
  return Cookies.get(cookieName) || ''
}

export const getUserIdFromCookie = async (cookieName: string) => {
  try {
    const cookie: string = getCookie(cookieName)
    const decode: any = await jwt_decode(cookie, { header: true })
    return decode.passport.user
  } catch (error) {
    return null
  }
}
