// Import Colors
import Colors from 'design/Colors'

export const colors = [
  {
    color: Colors.Purple50
  },
  {
    color: Colors.Orange490
  },
  {
    color: Colors.Red10
  },
  {
    color: Colors.Blue50
  },
  {
    color: Colors.Green60
  },
  {
    color: Colors.Magenta80
  },
  {
    color: Colors.Grey800
  },
  {
    color: Colors.Green560
  }
]
