// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Timeline } from 'react-event-timeline'
import { Icon } from 'semantic-ui-react'

import { Activities } from 'store/Transactions/Types'

// Import Styled Components
import { Container, People, StyledPopup, StyledTimelineEvent, Time } from './Styled'

interface Props {
  content: Activities[]
  currentDate: string
}

class Activity extends React.Component<Props> {
  public state = {}

  public render() {
    const { content, currentDate } = this.props
    const activityMockDatas = content.map((items: any, index: any) => {
      let icon: any
      switch (items.type) {
        case 'CREATED':
          icon = 'add'
          break
        case 'UPDATED':
          icon = 'pencil'
          break
        case 'DELETED':
          icon = 'trash'
          break
        case 'SHARED':
          icon = 'add user'
          break
        case 'REMOVED':
          icon = 'remove user'
          break
        default:
          break
      }
      if (currentDate === moment(items.updatedAt).format('LL')) {
        return (
          <Timeline key={index}>
            <StyledTimelineEvent
              title={
                <Container>
                  <div>
                    <p>
                      <b>{items.description}</b>
                    </p>
                  </div>
                </Container>
              }
              createdAt={<Time>{moment(items.updatedAt).format('LT')}</Time>}
              icon={
                items.owner !== null ? (
                  <StyledPopup
                    trigger={<People background={items.owner[`profileImage`]} />}
                    content={`${items.owner[`firstName`]} ${items.owner[`lastName`]}`}
                  />
                ) : (
                  <Icon name={icon} />
                )
              }
              style={{ lineHeight: '12px', top: '5px' }}
            />
          </Timeline>
        )
      }
      return null
    })
    return <div>{activityMockDatas}</div>
  }
}

export default Activity
