// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Icon } from 'semantic-ui-react'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import ConfirmAlert from 'sweetalert2'
// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { DropDownPopup, FileNameContainer, StyledDropdown } from './Styled'

import { faFileCsv, faFilePdf, faFolder, faImage } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faFileCsv, faFilePdf, faFolder, faImage])

const MyDocumentsStrings = Strings.userProfile.detailSection.myDocuments

interface DataItem {
  fileName: string
  id: string
  modified: moment.Moment
  parent: string
  type: string
  Key: string
}

const CustomName = ({ rowData, customComponentMetadata }: { rowData: DataItem; customComponentMetadata: any }) => {
  const { download } = customComponentMetadata
  const extension = rowData.fileName.split(/[. ]+/).pop()
  let icon: any
  switch (extension) {
    case 'pdf':
      icon = 'file-pdf'
      break
    case 'png':
      icon = 'image'
      break
    case 'jpeg':
      icon = 'image'
      break
    case 'jpg':
      icon = 'image'
      break
    case 'csv':
      icon = 'file-csv'
      break
    default:
      icon = 'file-pdf'
      break
  }
  const downloadItem = () => {
    if (rowData.type === 'FILE') {
      download(rowData.Key)
    }
  }
  return (
    <FileNameContainer onClick={downloadItem}>
      {rowData.type !== 'FILE' ? (
        <FontAwesomeIcon icon={['far', 'folder']} />
      ) : (
        <FontAwesomeIcon icon={['far', icon]} />
      )}
      {rowData.fileName}
    </FileNameContainer>
  )
}

const CustomAmount = ({ rowData }: { rowData: DataItem }) => {
  return <span>{rowData.modified}</span>
}

const CustomActions = ({ rowData, customComponentMetadata }: { rowData: DataItem; customComponentMetadata: any }) => {
  const { user, toggle, delete: deleteFileFolder, download, rename } = customComponentMetadata
  const deleteItem = () => {
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: `You will not be able to recover this ${rowData.type}!`,
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        deleteFileFolder(rowData.Key)
        ConfirmAlert('Deleted!', `Your ${rowData.type} has been deleted.`, 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', `Your ${rowData.type} is safe`, 'error')
      }
    })
  }

  const downloadItem = () => {
    download(rowData.Key)
  }

  const editItem = () => {
    rename(rowData.Key, rowData.type, rowData.fileName)
  }

  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              {rowData.type === 'FILE' ? (
                <Dropdown.Item text={MyDocumentsStrings.actions.download} onClick={downloadItem} />
              ) : (
                !((toggle && (user.role === 'ADMIN' || user.role === 'MANAGER')) || !toggle) && (
                  <Dropdown.Item text={'No Options'} />
                )
              )}
              {((toggle && (user.role === 'ADMIN' || user.role === 'MANAGER')) || !toggle) && (
                <React.Fragment>
                  <Dropdown.Item text={MyDocumentsStrings.actions.rename} onClick={editItem} />
                  <Dropdown.Item text={MyDocumentsStrings.actions.delete} onClick={deleteItem} />
                </React.Fragment>
              )}
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  )
}

export { CustomActions, CustomAmount, CustomName }
