import client from 'queries/apollo'

import { UPDATE_USER_PASS } from 'queries/graphql/Passes/Mutation'

import { UserPassType } from 'store/Pass/Types'
import { ID } from './Types'

export const updateUserPass = async (id:ID, input: Partial<UserPassType.Base>) => {
  if (input._id) {
    delete input._id
  }
  const response = await client.mutate({
    mutation: UPDATE_USER_PASS,
    variables: { id, input }
  })

  return response.data.updateUserPass
}
