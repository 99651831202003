import { Button, Dropdown, Input, Popup } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import DefaultIcon from 'design/icons/dashboard/ActivityHotsheet.png'

import { Strings } from 'utils'

import Colors from 'design/Colors'
import { children, primaryStyledButton, scrollbars, styledToolTip, x, y } from 'design/Styled'

interface Props {
  icon?: string
  size?: number
  transition?: number
}

const getThumbnail = (props: Props) => {
  const icon = props.icon || DefaultIcon
  const size = props.size || 4
  return css`
    background-image: url('${icon}');
    background-size: ${size * 0.6}em;
    width: ${size}em;
    height: ${size}em;
  `
}

export const Container = styled.div`
  ${y};
  overflow: hidden;
  height: 100%;

  .rp-scrollable-scroller {
    padding: 1em;
  }
`

export const Header = styled.header`
  ${x};
  padding: 1em 2em;
  background: ${Colors.ContainerHighlight};
  z-index: 9;
`

export const Body = styled.div`
  ${x};
  ${children};
  overflow: hidden;
  height: 100%;
`

export const Section = styled.section`
  ${y};
  height: 100%;
  min-width: 300px;
`

export const Row = styled.section`
  ${x};
`

export const Pass = styled.figure`
  display: flex;
  flex-direction: column;
  position: relative;
  float: left;
  margin: 10px;
  width: 150px;
  opacity: 1;
  z-index: 2;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: none;

  &.hidden {
    width: 0;
    margin: 10px 0;
    opacity: 0;
    z-index: 1;
    transform: translate3d(-40%, 80%, 0) scale3d(0.4, 0.4, 0.4) rotate(-60deg);
    transition: all ${(props: Props) => props.transition || 300}ms ease-in-out, z-index 0ms;
  }
`

export const Categories = styled(Dropdown)`
  &.ui.selection.dropdown {
    height: 30px;
    font-size: 12px;
    box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.18);
    ${scrollbars};
  }
`

export const Search = styled(Input)`
  &.ui.input {
    font-size: 12px;
    margin-left: 15px;
    width: 40%;
    box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.18);
  }
`

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`

export const PassName = styled.figcaption`
  font-size: 0.8rem;
  text-align: center;
  color: ${Colors.TextPrimary};
  font-weight: 600;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: uppercase;
`

export const PassWrapper = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 150px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 26px 20px;
  box-shadow: inset 0 0 0 4px ${Colors.EmphasisLine};
  background: ${Colors.Container};
  z-index: 1;

  .ui.button.more-info {
    background: ${Colors.Container};
    border: 1px solid ${Colors.ButtonPrimary};
    width: 80px;
    height: 20px;
    font-size: 10px;
    padding: 0;
    color: ${Colors.TextPrimary};
    border-radius: 2px;
    margin-top: 5px;
  }

  .add > .ui.button {
    background: ${Colors.ButtonHighlight} !important;
    color: ${Colors.TextLight} !important;
    width: 80px;
    height: 20px;
    font-size: 14px;
    border-radius: 0;
    margin-top: 8px;
    padding: 0;
    margin-bottom: 15px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    border: 4px solid ${Colors.EmphasisLine};
    background-color: ${Colors.Container};
    z-index: 9;
  }

  &:before {
    top: -64px;
  }

  &:after {
    bottom: -64px;
  }
`

export const Image = styled.div`
  margin: 1em auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid ${Colors.ButtonPrimary};
  border-radius: 50%;

  ${getThumbnail};
`

export const StyledButton = styled(Button)`
  ${primaryStyledButton};
`

export const SliderWrapper = styled.aside`
  ${y};
  height: 100%;
  font-size: 14px;
  background: ${Colors.ContainerSidebar};
  box-shadow: -20px 0px 20px -10px rgba(0, 0, 0, 0.12);
`

export const Title = styled.h1`
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  color: ${Colors.TextPrimary};
`

export const Subtitle = styled.h2`
  font-size: 1.4em;
  font-weight: 600;
  text-align: center;
  margin: 0 0 1em 0;
  color: ${Colors.Text};
`

export const Text = styled.p`
  text-align: justify;
  font-size: 1em;
  color: ${Colors.Text};
`

export const NoResults = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Strings.generalText.nothing});
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: center;
`
