// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Label } from 'semantic-ui-react'

// Import Component
import ComposeModal from 'shared/MessageEmail/ComposeModal'

// Import Store Types, Actions and Reducers
import { ContactListDetails } from 'store/Contacts/Types'

// Import Utils
import { AppState } from 'store/CombineReducers'
import { getFirstCharacter, getLoggedInUser, Routes, Strings } from 'utils'

// Import Styled Components
import {
  Added,
  CardContent,
  CardWrapper,
  CircleLable,
  Contact,
  ContactEmail,
  ContactName,
  ContactSouse,
  Container,
  Content,
  Divider,
  EmailIcon,
  ExtraContent,
  Labels,
  Li,
  MemberProfile,
  PhoneIcon,
  Profile,
  // ProfileImage,
  StyledPopup
} from './Styled'

interface UserType {
  _id: string
  role: string
  profileImage: string
}

interface Props {
  CardData: ContactListDetails
  color: string
  allContactStatus: boolean
}

interface State {
  showComposeMail: boolean
  user: UserType
}

class ContactCard extends React.Component<Props, State> {
  public state = {
    showComposeMail: false,
    user: {} as UserType
  }

  public async componentDidMount() {
    const user: UserType = await getLoggedInUser({ fromCache: true })
    this.setState({ user })
  }

  public render() {
    const { CardData, color, allContactStatus } = this.props
    const { showComposeMail, user } = this.state
    const profile = CardData.shareWith.map((items: any, index: number) => {
      while (index < 3) {
        return (
          <Li key={index}>
            <StyledPopup
              trigger={<MemberProfile key={index} background={items.profileImage} />}
              content={`${items.firstName} ${items.lastName}`}
            />
          </Li>
        )
      }
      return null
    })
    const Count =
      CardData.shareWith.length > 3 ? (
        <Labels>
          <StyledPopup trigger={<div>...</div>} content={`+${CardData.shareWith.length - 3}`} />
        </Labels>
      ) : null

    return (
      <Container>
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={CardData.email} />}
        <CardWrapper>
          <CardContent>
            <CircleLable color={color}>
              <Label circular={true}>{getFirstCharacter(CardData.firstName.toUpperCase())}</Label>
            </CircleLable>
            <Content>
              <Link
                to={
                  allContactStatus
                    ? user.role === 'ADMIN' || user.role === 'MANAGER'
                      ? Routes.contacts.root + Routes.contacts.details.path + `/${CardData._id}?pool=1`
                      : {}
                    : Routes.contacts.root + Routes.contacts.details.path + `/${CardData._id}?pool=0`
                }
              >
                <ContactName>
                  {CardData.firstName} {CardData.lastName}
                </ContactName>
                <ContactSouse>
                  {Strings.contact.source}
                  {CardData.source}
                </ContactSouse>
                <Contact>
                  <PhoneIcon />
                  {CardData.phone}
                </Contact>
              </Link>
              <ContactEmail
                onClick={e => {
                  e.stopPropagation()
                  this.sendMail()
                }}
              >
                <EmailIcon />
                {CardData.email}
              </ContactEmail>
            </Content>
          </CardContent>
          <Divider />
          <ExtraContent>
            <Profile>
              {profile}
              {Count && Count}
            </Profile>
            <Added>
              {Strings.contact.addedOn}
              {moment(CardData.createdAt).format('L')}
            </Added>
          </ExtraContent>
        </CardWrapper>
      </Container>
    )
  }

  private sendMail = () => {
    this.setState({ showComposeMail: true })
  }

  private closeModal = () => {
    this.setState({ showComposeMail: false })
  }
}

const mapStateToProps = (state: AppState) => ({
  allContactStatus: state.contacts.allContactStatus
})

export default connect(
  mapStateToProps,
  {}
)(ContactCard)
