// Import Packages
import { Label } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background: string
}

const GraphWrapper = styled.div`
  border-right: 1px solid ${Colors.Black905};

  .field {
    margin: 0 auto;
    display: flex;
  }

  .ui.button {
    font-size: 0.8rem;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    line-height: 1.5;
    margin: 0 auto;
  }
`

const ChartTitle = styled.div`
  font-size: 14px;
  color: ${Colors.Black500};
  margin-left: 30px;
  margin-top: 10px;
  font-weight: 500;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Notes = styled.div`
  display: flex;
  margin-left: 30px;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  color: ${Colors.DarkBlue200};
  cursor: pointer;

  .fa-comment {
    color: ${Colors.DarkBlue200};
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`

const Labels = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .button.yes {
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #3085d6;
    color: #fff;
    font-size: 1.0625em;
    margin-bottom: 25px;
  }

  .button.no {
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #aaa;
    color: #fff;
    font-size: 1.0625em;
    margin-bottom: 25px;
    margin-left: 10px;
  }

  img.ui.image {
    width: 70%;
  }
`

const Text = styled.div`
  position: absolute;
  top: 196px;
  max-width: 100%;
  margin: 0 0 .4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
`

const Image = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 350px;
  align-self: center;
`

export {
  GraphWrapper,
  ChartTitle,
  ModalWrapper,
  Notes,
  Labels,
  Wrapper,
  Text,
  Image
}
