// Import Packages
import * as React from 'react'
import { Menu } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import Notes from './Notes'

// Import Store Types, Actions and Reducers
import { LeadItemsDetails, LeadNotes } from 'store/Leads/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  RightPanel,
  StyledTab,
} from './Styled'

interface Props {
  closeModal: () => void
  addNewNotes: (note: LeadNotes) => void
  editNotes: (note: LeadNotes) => void
  deleteNotes: (id: string) => void
  notesData: LeadNotes[]
  leadData: LeadItemsDetails
}

interface State {
  animate: boolean
}

class ViewDetailsModal extends React.Component<Props, State> {
  public state = {
    animate: true
  }

  public render() {
    const { notesData, leadData } = this.props
    const { animate } = this.state
    const panes = [
      {
        menuItem: (<Menu.Item key='Notes'>{Strings.leads.notes}</Menu.Item>),
        render: () => <Notes addNewNotes={this.addNewNotes} notesData={notesData} editNotes={this.editNotes} deleteNotes={this.deleteNotes} leadData={leadData} />
      },
    ]
    return (
      <Modal content={
        <Container>
          <RightPanel>
            <StyledTab menu={{ secondary: true, pointing: true }} panes={panes} />
          </RightPanel>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={630} />
    )
  }

  private deleteNotes = (id: string) => {
    const { deleteNotes } = this.props
    deleteNotes(id)
  }

  private editNotes = (note: LeadNotes) => {
    const { editNotes } = this.props
    editNotes(note)
  }

  private addNewNotes = (note: LeadNotes) => {
    const { addNewNotes } = this.props
    addNewNotes(note)
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default ViewDetailsModal
