import gql from 'graphql-tag'

export const GET_WORKFLOW_TASK = gql`
  query getWorkflowTasks($workflowId: String, $name: String) {
    getWorkflowTasks(
      where: { workflow: $workflowId, name: $name, isActive: true }
    ) {
      _id
      type
      name
      description
      dueDate
      isActive
    }
  }
`
