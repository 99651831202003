import * as React from 'react'

import { formToast } from 'shared/Toast/Toast'
import Transition from 'shared/Transition'

import { validateEmail } from '../../Validation'

import { Strings } from 'utils'

import { Col, Description, StyledButton, StyledForm, StyledInput, Title } from '../../Styled'

import { ActionEnum, FormType, TransitEnum } from '../../Types'

interface Props {
  form: FormType
  onAction: (action: ActionEnum, data: any) => void
  transit: TransitEnum
}

const {
  transactionModal: {
    CONCIERGE: { DESCRIPTION, INPUT, TITLE }
  }
} = Strings

class EmailStep extends React.Component<Props, {}> {
  public render() {
    const {
      form,
      form: { errors },
      transit
    } = this.props

    const transition = transit === TransitEnum.Enter

    return (
      <Col height={100}>
        <Transition on={transition}>
          <Title>{TITLE.intro1}</Title>
          <StyledForm onSubmit={this.handleSubmit}>
            <Col>
              <Transition delay={50} on={transition} stagger={20}>
                <Description>{DESCRIPTION.intro1}</Description>
                <Description>{INPUT.emailLabel}</Description>
                <Col>
                  <StyledInput
                    autoFocus={true}
                    action={<StyledButton content={INPUT.emailSubmit} />}
                    name="email"
                    onChange={this.handleInput}
                    placeholder={INPUT.emailPlaceholder}
                    value={form.email}
                    error={!!errors.email}
                  />
                </Col>
              </Transition>
            </Col>
          </StyledForm>
        </Transition>
      </Col>
    )
  }

  private handleSubmit = () => {
    const { form, onAction } = this.props

    const validation = validateEmail(form)
    if (!validation.formIsValid) {
      this.handleInput(null, { name: 'errors', value: validation.errors })
      formToast(validation.errors)
      return
    }

    onAction(ActionEnum.Step, null)
  }

  private handleInput = (e: React.SyntheticEvent | null, { name, value }: any) => {
    const { onAction } = this.props
    onAction(ActionEnum.Input, { name, value })
  }
}

export default EmailStep
