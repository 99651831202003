import Template1 from './Template1'
import Template2 from './Template2'
import Template3 from './Template3'
import Template4 from './Template4'

import { TemplateOptionType } from './Types'

const Templates: TemplateOptionType = {
  m1: { name: 'Minimal Light', component: Template1 },
  m2: { name: 'Minimal Dark', component: Template2 },
  s1: { name: 'Slideshow Light', component: Template3 },
  s2: { name: 'Slideshow Dark', component: Template4 },
}

export default Templates
