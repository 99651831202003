import * as React from 'react'
import Slider from 'react-slick'

import PropertyImageModal from 'app/Transactions/PropertyImageModal'
import ClickToUpload from 'design/images/dashboard/clickToUpload.jpg'

import { getLoggedInUser } from 'utils'

import {
  Container,
  FixedRow,
  ImageContainer,
  Role,
  Slide,
  StatusDropdown,
  Tag,
} from './Styled'

import {
  ImageType,
  OptionType,
  TransactionType,
  UserType,
} from 'app/Transactions/Details/Types'
import { ModeEnum as ImageZoneModeEnum } from 'shared/ImageZone/Types'
import { ModalType } from './Types'

import { TransactionList } from 'store/Transactions/Types'

interface Props {
  moveTransaction: (e: React.SyntheticEvent<EventTarget>, data: TransactionList) => void
  statusOptions: OptionType[]
  transaction: TransactionType
}

interface State {
  modal: ModalType
  user: UserType
}

const settings = {
  arrows: false,
  autoplay: true,
  dots: false,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  initialSlide: 0,
  speed: 500,
}

class Carousel extends React.Component<Props, State> {
  public state = {
    modal: {} as ModalType,
    user: {} as UserType,
  }

  public componentDidMount = async () => {
    const user: UserType = await getLoggedInUser({ fromCache: true }) || {} as UserType
    this.setState({ user })
  }

  public render = () => {
    const {
      moveTransaction,
      statusOptions,
      transaction,
      transaction: {
        propertyId: { images },
        status,
        tags,
        transactionRole,
      },
    } = this.props

    const {
      modal,
      user,
    } = this.state

    return (
      <Container>
        {modal.media && (
          <PropertyImageModal
            active={0}
            mode={ImageZoneModeEnum.Default}
            onClose={this.closeModal}
            transaction={transaction}
          />
        )}
        <ImageContainer onClick={this.showImageModal}>
          {(images.length > 0) ? (
            <Slider {...settings}>
              {images.map((item: ImageType) => (
                <Slide
                  key={item._id}
                  background={item.url}
                />
              ))}
            </Slider>
          ) : (
              <Slide background={ClickToUpload} />
            )}
        </ImageContainer>
        <FixedRow>
          <Role>{transactionRole}</Role>
          <StatusDropdown
            disabled={user.role !== 'ADMIN' && user.role !== 'MANAGER'}
            inline={true}
            onChange={moveTransaction}
            options={statusOptions}
            value={status}
            direction='left'
          />
          {tags && (
            <Tag>{tags}</Tag>
          )}
        </FixedRow>
      </Container>
    )
  }

  private showImageModal = () => {
    const {
      transaction: { owner }
    } = this.props

    const { user } = this.state

    if (user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === owner._id) {
      this.setState({ modal: { media: 1 } })
    }
  }

  private closeModal = () => {
    this.setState({ modal: {} })
  }

}

export default Carousel
