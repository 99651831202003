// Import Packages
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  background: ${Colors.White1000};
  margin-top: 25px;
  height: 430px;
`

const Title = styled.div`
  border-bottom: 2px solid ${Colors.Black765};
  color: ${Colors.DarkBlue200};
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  padding-left: 30px;
`

const StyledTable = styled(Table)`
  .ui.input>input {
    border-radius: 0;
    width: 300px;

    @media(max-width: 1250px) {
      width: 220px;
    }
  }

  .ui.icon.input>i.icon {
    color: ${Colors.Black760};
  }

  .amount {
    border-radius: 0;
    width: 150px;
  }

  &.ui.table {
    width: 70%;
    padding-left: 22px;
    padding-bottom: 20px;
    font-size: 12px;
    color: ${Colors.Black500};
  }

  &.ui.basic.table {
    border: none;
  }

  &.ui.table tr td {
    border: none;
    height: 60px;
  }

  .svg-inline--fa {
    width: 20px;
    height: 20px;
    color: ${Colors.Black615};
  }
`


export {
  Container,
  StyledTable,
  Title
}
