// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

// Import Images and Icons
import ExportIcon from 'design/icons/contacts/contact_icon_1.png'
import ImportIcon from 'design/icons/contacts/contact_icon_2.png'
import DeleteIcon from 'design/icons/contacts/contact_icon_5.png'

// Import Components
import { addActionTask, deleteTaskBoard, exportTask, ShowImportFileModal } from '../Tasks'
import ActionItem from './ActionItem'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Action from 'store/Reports/Actions'

// Import Utils
import { Strings } from 'utils'

interface Props {
  viewReport: (report: string) => void
  closeActionPopup: () => void
  boardId: string
  showAddButton?: boolean
  showAppearance?: boolean
}

interface State {
  sortOrder: number
}

class Actions extends React.Component<Props, State>{
  public state = {
    sortOrder: 1
  }

  public render() {
    const { showAddButton, showAppearance } = this.props
    return (
      <div>
        {showAddButton && <span onClick={this.addTask}><ActionItem title={Strings.kanbanView.addTask} text={Strings.kanbanView.actionText} icons={<Icon name='tasks' />} /></span>}
        {<span onClick={this.showExportFileModal}><ActionItem title={Strings.kanbanView.export} text={Strings.kanbanView.actionText} icon={ExportIcon} /></span>}
        {showAddButton && <span onClick={this.showImportFileModal}><ActionItem title={Strings.kanbanView.import} text={Strings.kanbanView.actionText} icon={ImportIcon} /></span>}
        {showAppearance && <span onClick={this.deleteBoard}><ActionItem title={Strings.kanbanView.delete} text={Strings.kanbanView.actionText} icon={DeleteIcon} /></span>}
      </div>
    )
  }

  private showImportFileModal = () => {
    const { closeActionPopup, boardId } = this.props
    ShowImportFileModal(boardId)
    closeActionPopup()
  }

  private showExportFileModal = () => {
    const { closeActionPopup, boardId } = this.props
    exportTask(boardId)
    closeActionPopup()
  }

  private deleteBoard = () => {
    const { boardId, closeActionPopup } = this.props
    deleteTaskBoard(boardId)
    closeActionPopup()
  }

  private addTask = () => {
    const { boardId } = this.props
    addActionTask(boardId)
  }
}

const mapStateToProps = (state: AppState) => ({})

export default connect(
  mapStateToProps,
  {
    viewReport: Action.viewReport,
  }
)(Actions)

