// Import Graphql Queries
import client from 'queries/apollo'
import { GET_MY_REPORT } from 'queries/graphql/Report/Queries'

export const getMyReport = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_MY_REPORT
  })

  return response.data.getMyReports
}
