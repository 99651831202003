import styled from 'styled-components'

import Colors from 'design/Colors'

interface Props {
  background?: string
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 60px;
  padding: 0.4em 0.5em;
  border-radius: 4px;
  background-color: ${(props: Props) => props.background || Colors.ButtonDisable};
  color: ${Colors.TextLight};
  line-height: 1;
`