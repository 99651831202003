// Import Packages
import * as React from 'react'
import { Component } from 'react'
import ConfirmAlert from 'sweetalert2'

// Import Images and Icons
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'

// Import Utils
import { Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  ActionsWrapper,
  DragHandle,
  ImageWrapper,
  PropertyHeader,
  PropertyNameText,
  PropertyValues,
  PropertyWrapper,
  ValueImageWrapper
} from './Styled'

// Font Awesome Icons
import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  addDocTemplate,
  deleteDocChecklist,
  removeDocTemplate
} from 'app/Settings/ChecklistManager/ChecklistManagerMutation'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPencilAlt, faTrashAlt])

interface Props {
  propertyId: string
  categoryIndex: number
  categoryId: string
  categoryName: string
  documents: Array<{
    id: string
    name: string
  }>
  index: number
  name: string
  changeChecklistValue: (categoryIndex: number, documentId: string, newValue: boolean, propertyIndex: number) => void
  handleEditClick: (
    toggle: boolean,
    categoryIndex?: number,
    propertyIndex?: number,
    name?: string,
    id?: string,
    categoryName?: string
  ) => void
  addProperty: () => void
  removeProperty: (categoryIndex: number, propertyIndex: number) => void
  totalindex: number
  values: object
  y: number
  mouseDownCallback: (pos: number, pressY: number, { pageY }: { pageY: number }) => void
  touchStartCallback: (key: number, pressLocation: number, e: any) => void
  handleAddPropertyClick: (toggle: boolean, id?: string, index?: number, name?: string) => void
}

class Property extends Component<Props> {
  public handleClick = () => {
    if (this.props.name === Strings.settings.addProperty) {
      const { categoryId, categoryIndex, categoryName } = this.props
      this.props.handleAddPropertyClick(true, categoryId, categoryIndex, categoryName)
    }
  }

  public changeValue = async (documentId: string, value: boolean) => {
    const { changeChecklistValue, categoryIndex, index, propertyId } = this.props
    changeChecklistValue(categoryIndex, documentId, value, index)
    if (value) {
      await addDocTemplate(propertyId, documentId)
    } else {
      await removeDocTemplate(propertyId, documentId)
    }
  }

  public handleRemoveProperty = () => {
    const { categoryIndex, index, removeProperty, propertyId } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.settings.taxSetup.recoverProperty,
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(result => {
      if (result.value) {
        removeProperty(categoryIndex, index)
        deleteDocChecklist(propertyId)
        ConfirmAlert(Strings.kanbanView.deleted, Strings.settings.taxSetup.propertyDeleted, 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, Strings.settings.taxSetup.propertySafed, 'error')
      }
    })
  }

  public render() {
    const { name, totalindex, documents, values, index: propertyIndex, y, propertyId, categoryName } = this.props
    return (
      <PropertyWrapper totalindex={totalindex}>
        <PropertyHeader onClick={this.handleClick.bind({})}>
          {name !== Strings.settings.addProperty ? (
            <DragHandle
              onMouseDown={(e: any) => this.props.mouseDownCallback(propertyIndex, y, e)}
              onTouchStart={(e: any) => this.props.touchStartCallback(propertyIndex, y, e)}
            />
          ) : null}
          <PropertyNameText>
            {name !== Strings.settings.addProperty ? (
              name
            ) : (
              <span style={{ textDecoration: 'underline', color: Colors.DarkBlue200 }}>
                {Strings.settings.addProperty}
              </span>
            )}
          </PropertyNameText>
          <ActionsWrapper ishidden={name === Strings.settings.addProperty}>
            <ImageWrapper
              onClick={() =>
                this.props.handleEditClick(
                  true,
                  this.props.categoryIndex,
                  this.props.index,
                  name,
                  propertyId,
                  categoryName
                )
              }
            >
              <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
            </ImageWrapper>
            <ImageWrapper onClick={this.handleRemoveProperty}>
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </ImageWrapper>
          </ActionsWrapper>
        </PropertyHeader>
        <PropertyValues>
          {documents.map((item, index) => {
            return name !== Strings.settings.addProperty &&
              (values[item[`_id`]] !== undefined || (item.name !== Strings.settings.addString && item.name !== '')) ? (
              /* tslint:disable-next-line jsx-no-lambda */
              values[item[`_id`]] ? (
                <ValueImageWrapper
                  key={index}
                  onClick={() => {
                    this.changeValue(item[`_id`], false)
                  }}
                >
                  <Image src={Strings.settings.checkedBtn} />
                </ValueImageWrapper>
              ) : (
                /* tslint:disable-next-line jsx-no-lambda */
                <ValueImageWrapper
                  key={index}
                  onClick={() => {
                    this.changeValue(item[`_id`], true)
                  }}
                >
                  <Image src={Strings.settings.uncheckedBtn} />
                </ValueImageWrapper>
              )
            ) : null
          })}
        </PropertyValues>
      </PropertyWrapper>
    )
  }
}

export default Property
