// Import Packages
import * as QueryString from 'query-string'
import * as React from 'react'
import SpinningBubbles from 'react-loading'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { Dimmer } from 'semantic-ui-react'

// Import Components
import Toast from 'shared/Toast'
import Scrollable from 'shared/Scrollable'
import AddLeadModal from '../Dashboard/AddLeadModal'
import UpdateScoreModal from '../Dashboard/UpdateScoreModal'
import LeftPanel from './LeftPanel'
import RightLanel from './RightPanel'
import { RouteParams } from './Types'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Store Types, Actions and Reducers
import { LeadItemsDetails, LeadShareWith, UserType } from 'store/Leads/Types'

// Import Styled Components
import { BackButton, StyledGrid } from './Styled'

// Font Awesome Icons
import { faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { addLead, deleteShareWithUser, shareWith, updateLeadScore } from '../Dashboard/LeadMutations'
import { getLeadDetails } from '../Dashboard/LeadQueries'
AddIconToLibrary([faArrowCircleLeft])

export let loggedUser: any
export let toggleStatus: boolean
export let ShowUpdateScoreModal: any
export let editLead: any

interface Props extends RouteComponentProps<RouteParams, {}> {
  onBack: () => void
  leadData: LeadItemsDetails
}

interface State {
  leadData: LeadItemsDetails
  leadDetails: LeadItemsDetails
  leadId: string
  showUpdateScoreModal: boolean
  user: UserType
  edit: boolean
  editLeadDetails: LeadItemsDetails
  showAddLeadModal: boolean
}

class LeadDetail extends React.Component<Props, State> {
  public state = {
    edit: false,
    editLeadDetails: {} as LeadItemsDetails,
    leadData: {} as LeadItemsDetails,
    leadDetails: {} as LeadItemsDetails,
    leadId: '',
    showAddLeadModal: false,
    showUpdateScoreModal: false,
    user: {} as UserType
  }

  public refreshUpdateLead = async () => {
    const { match, location } = this.props

    const leadId = match.params.id
    const data = await getLeadDetails(leadId)
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = user && user

    const query = QueryString.parse(location.search, { parseNumbers: true })
    toggleStatus = !!query.pool

    this.assignShowUpdateScoreModal()
    this.editLeadDetails()
    if (user) {
      this.setState({ leadDetails: data, leadId: leadId, user })
    }
  }

  public async componentDidMount() {
    this.refreshUpdateLead()
  }

  public componentDidUpdate() {
    const { match } = this.props
    const { leadId } = this.state
    const leadID = match.params.id
    if (leadID !== leadId) {
      this.refreshUpdateLead()
    }
  }

  public render() {
    const { leadDetails, showUpdateScoreModal, leadData, showAddLeadModal, edit, editLeadDetails } = this.state
    if (Object.keys(leadDetails).length > 0) {
      return (
        <Scrollable>
          <StyledGrid>
            {showUpdateScoreModal && (
              <UpdateScoreModal
                closeModal={this.closeModal}
                changeLeadScore={this.changeLeadScore}
                leadData={leadData}
              />
            )}
            {showAddLeadModal && (
              <AddLeadModal
                closeModal={this.closeModal}
                edit={edit}
                editDetails={editLeadDetails}
                leadPool={toggleStatus}
                addLead={this.updateLead}
              />
            )}
            <Grid.Row className="back-button-row">
              <Link to={Routes.primary.leads.path}>
                <BackButton>
                  <FontAwesomeIcon icon={['fas', 'arrow-circle-left']} />
                  <span>{Strings.generalText.back}</span>
                </BackButton>
              </Link>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <LeftPanel
                  leadData={leadDetails}
                  deleteShareUser={this.deleteShareWithUser}
                  shareUser={this.shareWithUser}
                  toggleStatus={toggleStatus}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <RightLanel leadData={leadDetails} toggleStatus={toggleStatus} />
              </Grid.Column>
            </Grid.Row>
          </StyledGrid>
        </Scrollable>
      )
    } else {
      return (
        <Dimmer active={true} inverted={true}>
          <SpinningBubbles type={'spinningBubbles'} color={Colors.DarkBlue200} />
        </Dimmer>
      )
    }
  }

  private deleteShareWithUser = async (userId: string) => {
    const { leadId } = this.state
    const data: any = await deleteShareWithUser(userId, leadId)
    this.setState({ leadDetails: data.data.getLeads[0] })
  }

  private shareWithUser = async (shareObj: LeadShareWith) => {
    const { leadId } = this.state
    try {
      const data: any = await shareWith(leadId, shareObj)
      this.setState({ leadDetails: data.data.getLeads[0] })
    } catch (error) {
      Toast({ message: 'Email already exists', type: 'error' })
    }
  }

  private closeModal = () => {
    this.setState({ showUpdateScoreModal: false, showAddLeadModal: false })
  }

  private changeLeadScore = async (score: number) => {
    const { user, leadData } = this.state
    const response = await updateLeadScore(leadData[`_id`], score, user)
    this.setState({ leadDetails: response.data.updateLead, leadData: response.data.updateLead })
  }

  private assignShowUpdateScoreModal = () => {
    ShowUpdateScoreModal = (leadData: any) => {
      this.setState({ showUpdateScoreModal: true, leadData })
    }
  }

  private editLeadDetails = () => {
    editLead = (details: any) => {
      this.setState({
        edit: true,
        editLeadDetails: details,
        showAddLeadModal: true
      })
    }
  }

  private updateLead = async (lead: LeadItemsDetails | any) => {
    const { user } = this.state
    const response = await addLead(lead, '0', '', false, toggleStatus, user, '', true)
    this.setState({ leadDetails: response.data.transferLead, leadData: response.data.transferLead })
    this.refreshUpdateLead()
  }
}

export default withRouter(LeadDetail)
