import * as React from 'react'

import { Child, Container, Scroller } from './Styled'

class Scrollable extends React.Component<{}, {}> {
  public render() {
    const { children } = this.props

    return (
      <Container className={'rp-scrollable-container'}>
        <Scroller className={'rp-scrollable-scroller'}>
          <Child className={'rp-scrollable-child'}>{children}</Child>
        </Scroller>
      </Container>
    )
  }
}

export default Scrollable
