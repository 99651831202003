import gql from 'graphql-tag'

export const GET_CONTACT_BOARDS = gql`
  query getContactBoards(
    $orderField: String
    $sortOrder: Int
    $userId: String
    $searchData: ContactSearch
    $userPassFilter: UserPassContactFilter
    $singleSkip: Int
    $id: String
  ) {
    getContactBoards(
      where: { isActive: true, user: $userId }
      sorting: { field: $orderField, order: $sortOrder }
      filter: $searchData
      userPassFilter: $userPassFilter
      _id: $id
      singleSkip: $singleSkip
    ) {
      _id
      name
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      isActive
      boardOrder
      contacts {
        _id
        firstName
        lastName
        phone
        email
        source
        isActive
        type
        teamContactOrder
        officeContactOrder
        tenantContactOrder
        createdAt
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
      }
    }
  }
`

export const GET_SORTED_CONTACT_BOARDS = gql`
  query getSortedContactsBoards(
    $orderField: String
    $sortOrder: Int
    $contactOrderField: String
    $sortContactOrder: Int
    $id: String
  ) {
    getContactBoards(
      where: { isActive: true }
      sorting: { field: $orderField, order: $sortOrder }
      _id: $id
      singleSorting: { field: $contactOrderField, order: $sortContactOrder }
    ) {
      _id
      name
      user {
        _id
        firstName
        lastName
        userName
      }
      icon {
        _id
        key
        imageUrl
      }
      description
      pagination {
        count
        limit
        pages {
          current
          last
          next
          previous
          total
        }
        skip
        sorting {
          field
          order
        }
      }
      isActive
      boardOrder
      contacts {
        _id
        firstName
        lastName
        phone
        email
        source
        isActive
        type
        teamContactOrder
        officeContactOrder
        tenantContactOrder
        createdAt
        shareWith {
          _id
          firstName
          lastName
          profileImage
          status
          type
        }
        owner {
          _id
          firstName
          lastName
          profileImage
        }
      }
    }
  }
`
