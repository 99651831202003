import client from 'queries/apollo'

import { GET_MLS_LIST, GET_MLS_TENANT_LIST } from 'queries/graphql/Mls/Queries'

import { MlsFilterType } from './Types'

export const getVerifiedMlsList = async (where: MlsFilterType) => {
  where = {
    ...where,
    isActive: true,
    isVerified: true
  }

  const query = await client.query({
    query: GET_MLS_LIST,
    variables: { where }
  })

  return query.data.getMlsList
}

export const getMlsTenantList = async (where: MlsFilterType) => {
  where = {
    ...where,
    isActive: true
  }
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_MLS_TENANT_LIST,
    variables: { where }
  })

  return response.data.getMlsTenantList
}
