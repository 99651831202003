import { Button, Form, TextArea } from 'semantic-ui-react'
import { css } from 'styled-components'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

const text = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Text};
  margin: 0;
  line-height: 1.5;
`

export const Aside = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 40%;
  background: ${Colors.ImagePanelContainer};
`

export const Container = styled.div`
  ${text};
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  width: 100%;

  .ui.form textarea {
    margin-bottom: 1em;
  }
`

export const Content = styled(Form)`
  padding: 2em;
`

export const CreateButton = styled(Button)`
  &.ui.button {
    background: ${Colors.ButtonPrimary};
    color: ${Colors.White1000};
    border-radius: 0;
    text-transform: uppercase;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const Notepad = styled(TextArea)``
