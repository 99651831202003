// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Button, Form, Icon, Input } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import Toast from 'shared/Toast'
import { GET_LEAD_NOTE } from 'queries/graphql/Lead/Queries'
import { createLeadNotes, deleteLeadNotes, editLeadNotes } from '../../../../Dashboard/LeadMutations'
import LeadNotesQuery from './LeadNotesQuery'
import { handleValidation } from './Validation'

// Import Store Types, Actions and Reducers
import { LeadItemsDetails, LeadNotes } from 'store/Leads/Types'
import { ErrorType } from './Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { Container, Date, Description, Propfile, StyledForm, StyledNotes } from './Styled'

interface Props {
  addNewNotes: (note: LeadNotes) => void
  editNotes: (note: LeadNotes) => void
  deleteNotes: (id: string) => void
  notesData: LeadNotes[]
  leadData: LeadItemsDetails
}

interface State {
  id: string
  note: string
  showEditButton: boolean
  showConfirmationModal: boolean
  errors: ErrorType
  loader: boolean
}

class Notes extends React.Component<Props, State> {
  public state = {
    errors: {} as ErrorType,
    id: '',
    loader: false,
    note: '',
    showConfirmationModal: false,
    showEditButton: false
  }

  public render() {
    const { leadData } = this.props
    const { note, showEditButton, errors, loader } = this.state
    return (
      <div style={{ paddingLeft: 30 }}>
        <StyledForm size={'small'}>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              name="description"
              placeholder="Add a Note"
              onChange={this.handleChange}
              value={note}
              error={errors[`note`]}
            />
            {loader ? (
              <Form.Field control={Button} loading={true} content="LOADING" />
            ) : showEditButton ? (
              <Form.Field control={Button} content="EDIT" onClick={this.editNotes} />
            ) : (
              <Form.Field control={Button} content="SUBMIT" onClick={this.saveNotes} />
            )}
          </Form.Group>
        </StyledForm>
        <StyledNotes>
          <LeadNotesQuery query={GET_LEAD_NOTE} variables={{ leadId: leadData._id }}>
            {({ loading, error, data }) => {
              if (loading) {
                return 'Loading...'
              }
              if (error) {
                return `Error! ${error.message}`
              }
              if (data && data.getNotes) {
                return (
                  <div>
                    {data.getNotes.map((items: any, index: any) => {
                      const editNotesFunction = () => {
                        this.setState({ id: items._id, note: items.description, showEditButton: true })
                      }
                      const deleteNotesFunction = () => {
                        this.deleteNotes(items._id)
                      }
                      return (
                        <Container key={index}>
                          <Propfile background={items.createdBy.profileImage} />
                          <div style={{ alignItems: 'center' }}>
                            <Description>{items.description}</Description>
                            <Date>
                              <div style={{ flexGrow: 1 }}>{moment(items.createdAt).format('ll')}</div>
                              <div style={{ marginRight: '10px' }}>
                                <Icon name="pencil" onClick={editNotesFunction} />
                                <Icon name="trash" onClick={deleteNotesFunction} />
                              </div>
                            </Date>
                          </div>
                        </Container>
                      )
                    })}
                  </div>
                )
              }
              return null
            }}
          </LeadNotesQuery>
        </StyledNotes>
      </div>
    )
  }

  private deleteNotes = (id: string) => {
    const { leadData, deleteNotes } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.leads.alert.noKeepIt,
      confirmButtonText: Strings.leads.alert.yesDeleteIt,
      showCancelButton: true,
      text: Strings.leads.alert.text,
      title: Strings.leads.alert.sure,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        try {
          await deleteLeadNotes(id, leadData._id)
          deleteNotes(id)
          ConfirmAlert(Strings.leads.alert.deleted, Strings.leads.alert.onDeleted, 'success')
        } catch (error) {
          Toast({ message: error.message, type: 'error' })
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.leads.alert.cancelled, Strings.leads.alert.safe, 'error')
      }
    })
  }

  private editNotes = async () => {
    const { leadData, editNotes } = this.props
    const { id, note } = this.state
    const result = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      this.setState({ loader: true })
      try {
        const response = await editLeadNotes(id, note, leadData._id)
        editNotes(response)
        if (response) {
          this.setState({ loader: false })
        }
      } catch (error) {
        this.setState({ loader: false })
        Toast({ message: error.message, type: 'error' })
      }
      this.setState({ id: '', note: '', showEditButton: false })
    }
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    this.setState({ note: value })
  }

  private saveNotes = async () => {
    const { leadData, addNewNotes } = this.props
    const { note } = this.state
    const result = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      this.setState({ loader: true })
      try {
        const response = await createLeadNotes(leadData._id, note)
        addNewNotes(response)
        if (response) {
          this.setState({ loader: false })
        }
      } catch (error) {
        this.setState({ loader: false })
        Toast({ message: error.message, type: 'error' })
      }
      this.setState({ id: '', note: '' })
    }
  }
}

export default Notes
