import client from 'queries/apollo'

import { GET_TEXTRACT_DOCUMENT } from 'queries/graphql/Document/Queries'

export const getTextractDocument = async (id: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TEXTRACT_DOCUMENT,
    variables: { id }
  })

  return response.data.getTextractDocument
}
