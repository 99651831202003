import { FormErrorType, FormType } from './Types'

export const handleValidation = (data: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!data.name) {
    formIsValid = false
    errors.name = 'Can Not be empty'
  }

  if (!data.url) {
    formIsValid = false
    errors.url = 'Can Not be empty'
  }

  return { errors, formIsValid }
}
