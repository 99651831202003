import axios from 'axios'
import { debounce, range } from 'lodash'
import * as React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { generateID } from 'utils'

// Markup
import MarkupTool from 'shared/MarkupTool'
import { MarkupType, MarkupModeEnum, MarkupSourceEnum } from 'shared/MarkupTool/Types'

// Textract
import { PDFDocumentType, PDFPageType, PDFOptionsType } from './Types'
import { getFullLineFromWords, getWordsCenteredInBounds } from './Utils'

// Document
import { getTextractDocument } from './Queries'

import { Container, OverflowContainer, PageContainer } from './Styled'

interface Props {
  doc: PDFDocumentType
  markupMode: MarkupModeEnum
  markupSource: MarkupSourceEnum
  onUpdate: (doc: PDFDocumentType) => void
  resize: boolean
  zoom: number
}

interface State {
  attempt: number
  isAllowed: boolean
  options: PDFOptionsType
  pages: PDFPageType[]
  pdf: any
  textractWords: any
  viewportWidth: number
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

class PDFViewer extends React.Component<Props, State> {
  public static defaultProps = {
    markupMode: MarkupModeEnum.None,
    markupSource: MarkupSourceEnum.None,
    onUpdate: (doc: PDFDocumentType) => { return },
    resize: false,
    zoom: 1,
  }

  public state = {
    attempt: 0,
    isAllowed: true,
    options: {} as PDFOptionsType,
    pages: [] as PDFPageType[],
    pdf: null,
    textractWords: null,
    viewportWidth: 1,
  }

  private container: any = React.createRef<HTMLDivElement>()

  public componentDidMount = async () => {
    this.getTextractWords()
    window.addEventListener('resize', debounce(this.updateDimensions, 100))
  }

  public componentWillUnmount = () => {
    window.removeEventListener('resize', debounce(this.updateDimensions, 100))
  }

  public componentDidUpdate = (prev: Props) => {
    if (prev.resize !== this.props.resize) {
      this.updateDimensions()
    }
  }

  public render() {
    const {
      doc,
      markupMode,
      markupSource,
      zoom,
    } = this.props

    const {
      isAllowed,
      options,
      pages,
      pdf,
      viewportWidth,
    } = this.state

    return (
      <React.Fragment>
        <Container>
          <OverflowContainer innerRef={this.container}>
            <Document
              file={doc && isAllowed ? doc.url : ''}
              onLoadSuccess={this.handleDocumentLoad}
              rotate={(doc && doc.rotation) || 0}
              options={options}
              onPassword={this.handlePassword}
              noData={'Failed to open document'}
            >
              {pdf && pages.map((page: PDFPageType, index: number) => {
                const pageMarkup = markupSource ? (doc[markupSource] || [] as MarkupType[]).filter((markup: MarkupType) => markup.page === index) : [] as MarkupType[]
                return (
                  <PageContainer key={index} width={viewportWidth}>
                    <Page
                      {...pdf}
                      onLoadSuccess={this.handlePageLoad}
                      pageIndex={index}
                      renderAnnotations={false}
                      renderInteractiveForms={false}
                      renderTextLayer={false}
                      scale={zoom}
                      width={viewportWidth * zoom}
                    />
                    {markupMode !== MarkupModeEnum.None && (
                      <MarkupTool
                        className='overlay'
                        markup={pageMarkup}
                        onMarkup={(markup: MarkupType[]) => this.updateMarkup(markup, index)}
                        mode={markupMode}
                        source={markupSource}
                      />
                    )}
                  </PageContainer>
                )
              })}
            </Document>
          </OverflowContainer>
        </Container>
      </React.Fragment>
    )
  }

  private handlePassword = async () => {
    const { attempt, options } = this.state
    this.setState({ isAllowed: false })

    const falsePassword = generateID()
    if (attempt < 1) {
      options.password = await window.prompt('This document is encrypted. Please provide a password.') || falsePassword
    }

    this.setState({
      isAllowed: options.password !== falsePassword && attempt < 1,
      attempt: attempt + 1,
      options,
    })
  }

  private updateMarkup = async (updatedMarkup: MarkupType[], page: number) => {
    const { doc, markupSource } = this.props

    updatedMarkup = updatedMarkup.map((markup: MarkupType) => {
      if (typeof markup.page !== 'number') {
        markup.page = page
      }
      return markup
    })

    const excludePage = markupSource ? (doc[markupSource] || [] as MarkupType[]).filter((markup: MarkupType) => markup.page !== page) : [] as MarkupType[]
    const combinedMarkup = excludePage.concat(updatedMarkup)

    await this.batchUpdateMarkup(combinedMarkup)
  }

  private batchUpdateMarkup = async (updatedMarkup: MarkupType[]) => {
    const {
      doc,
      markupSource,
      onUpdate,
    } = this.props
    const { pages } = this.state

    const updatedDocument = { ...doc, pages }

    if (markupSource === MarkupSourceEnum.Textract) {
      const getTextractValues = async (markup: MarkupType) => {
        if (typeof markup.page === 'number') {
          markup.value = await this.searchBounds(markup, markup.page)
        }
        return markup
      }

      const promisedMarkup = await updatedMarkup.map(getTextractValues)
      updatedMarkup = await Promise.all(promisedMarkup)
    }

    updatedDocument[markupSource] = updatedMarkup
    onUpdate(updatedDocument)
  }

  private searchBounds = async (markup: MarkupType, page: number) => {
    const words = await this.getTextractWords()
    if (!words || !words[page]) {
      return ''
    }

    const source = words[page]
    const selection = getWordsCenteredInBounds(source, markup)
    const value = getFullLineFromWords(selection)
    return value
  }

  private getTextractWords = async () => {
    const { markupSource } = this.props
    if (markupSource !== MarkupSourceEnum.Textract) {
      return null
    }

    const { textractWords } = this.state
    let words: any = textractWords
    if (textractWords) {
      return words
    }

    const {
      doc,
      doc: { _id, extractWordsUrl },
      onUpdate
    } = this.props
    let url = extractWordsUrl
    if (!url || url === '') {
      const pdf = await getTextractDocument(_id)
      if (!pdf || !pdf.extractWordsUrl || pdf.extractWordsUrl === '') {
        return null
      }
      url = pdf.extractWordsUrl
      const update = {
        ...doc,
        pdf
      }
      onUpdate(update)
    }

    const response = await axios({
      method: 'get',
      responseType: 'json',
      url
    })

    if (!response.data) {
      return null
    }

    words = response.data
    this.setState({ textractWords: words })

    window.requestAnimationFrame(() => {
      this.batchUpdateMarkup(doc.extractMarkup)
    })

    return words
  }

  private handleDocumentLoad = (pdf: any) => {
    const { doc, onUpdate } = this.props
    const { options } = this.state

    const pages:PDFPageType[] = range(pdf.numPages).map(() => ({
        width: 1,
        height: 1,
    }))

    if (options.password) {
      const update = {
        ...doc,
        password: options.password
      }
      onUpdate(update)
    }

    this.setState({
      pages,
      pdf,
    })
  }

  private handlePageLoad = (page: any) => {
    const { pages } = this.state
    const { pageIndex, originalWidth, originalHeight } = page
    pages[pageIndex] = {
      width: originalWidth,
      height: originalHeight,
    }
    this.setState({ pages })
    this.updateDimensions()
  }

  private updateDimensions = (page:any = null) => {
    const container = this.container.current
    if (!container) {
      return
    }

    this.setState({ viewportWidth: container.clientWidth })
  }
}

export default PDFViewer
