// Import Packages
import moment from 'moment'
import * as React from 'react'

import Modal from 'shared/Modal'
import AddDocumentModal from 'shared/Transaction/AddDocumentModal'

import ActionButtons from '../ActionButtons'
import Insignia from '../Insignia'
import { getMessageEmailById } from '../MailboxQueries'

import { genericGetUsers } from 'shared/Users/Queries'

// Import Store Types, Actions and Reducers
import { EmailAddress, InboxAttachment, MessageEmailCardData, MessageEmailViewData } from 'store/Mailbox/Types'

import {
  AttachmentLi,
  AttachmentUl,
  Container,
  Content,
  Envelope,
  EnvelopeCol,
  FileActions,
  FileButton,
  FileDetails,
  From,
  Header,
  HeaderRow,
  IFrame,
  Meta,
  MetaItem,
  Plaintext,
  Subject,
  Text
} from './Styled'

// Font Awesome Icons
import {
  faArrowToBottom,
  faCalendarAlt,
  faClock,
  faFileInvoiceDollar,
  faPaperclip,
  faReply,
  faReplyAll,
  faShare,
  faTrashAlt
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faArrowToBottom,
  faCalendarAlt,
  faClock,
  faFileInvoiceDollar,
  faPaperclip,
  faReply,
  faReplyAll,
  faShare,
  faTrashAlt
])

interface Props {
  cardData: MessageEmailViewData | MessageEmailCardData
  onClose: () => void
}

interface State {
  dateSent: string
  fromName: string
  iframeSrc: string
  messageData: MessageEmailViewData
  modal: any
  pdfs: InboxAttachment[]
  showModal: boolean
  timeSent: string
  transition: boolean
}

class ViewModal extends React.Component<Props, State> {
  public state = {
    dateSent: '',
    fromName: '',
    iframeSrc: '',
    messageData: {
      _id: '',
      attachments: [
        {
          _id: '',
          fileName: '',
          fileType: '',
          rotation: '',
          url: ''
        }
      ],
      bcc: [
        {
          _id: '',
          color: '',
          initials: '',
          name: '',
          value: ''
        }
      ],
      cc: [
        {
          _id: '',
          color: '',
          initials: '',
          name: '',
          value: ''
        }
      ],
      from: {
        _id: '',
        color: '',
        initials: '',
        name: '',
        value: ''
      },
      html: '',
      isRead: true,
      isReplied: false,
      preview: '',
      sentAt: new Date(),
      spamScore: '',
      subject: '',
      text: '',
      to: [
        {
          _id: '',
          color: '',
          initials: '',
          name: '',
          value: ''
        }
      ]
    },
    modal: null,
    pdfs: [],
    showModal: false,
    timeSent: '',
    transition: true
  }

  public componentWillMount = () => {
    const {
      cardData: { from, attachments }
    } = this.props

    let pdfs: InboxAttachment[] = []
    if (attachments && attachments.length > 0) {
      pdfs = attachments.filter((attachment: InboxAttachment) => attachment.fileType === 'application/pdf')
    }

    const timestamp = this.getTimestamp()
    this.setState({
      dateSent: timestamp.date,
      fromName: this.getFriendlyName(from),
      pdfs,
      timeSent: timestamp.time
    })

    this.getFullMessage()
  }

  public render = () => {
    const { dateSent, fromName, iframeSrc, messageData, pdfs, timeSent } = this.state
    const {
      messageData: { from, subject, text, attachments }
    } = this.state
    const { modal, showModal, transition } = this.state
    return (
      <Modal
        content={
          <Container>
            <Header>
              <Subject>{subject}</Subject>
              <HeaderRow>
                <Envelope>
                  <Insignia initials={from.initials} color={from.color} size={1.6} />
                  <EnvelopeCol>
                    <From>{fromName}</From>
                    <Meta>
                      {/* { data.company && <MetaItem>{data.company}</MetaItem> } */}
                      <MetaItem>
                        <Icon icon={['fal', 'calendar-alt']} />
                        <span>{dateSent}</span>
                      </MetaItem>
                      <MetaItem>
                        <Icon icon={['fal', 'clock']} />
                        <span>{timeSent}</span>
                      </MetaItem>
                    </Meta>
                  </EnvelopeCol>
                </Envelope>
                <ActionButtons
                  actions={['reply', 'replyAll', 'forward', 'trash', 'options']}
                  messageData={messageData}
                />
              </HeaderRow>
            </Header>
            <Content>{iframeSrc.length > 0 ? <IFrame src={iframeSrc} /> : <Plaintext>{text}</Plaintext>}</Content>
            {attachments.length > 0 && (
              <AttachmentUl>
                {attachments.map((attachment: InboxAttachment) => (
                  <AttachmentLi key={attachment._id}>
                    <FileDetails>
                      <Icon icon={['fal', 'paperclip']} />
                      <Text>{attachment.fileName}</Text>
                    </FileDetails>
                    <FileActions basic={true} compact={true} size="tiny">
                      {attachment.fileType === 'application/pdf' && (
                        <FileButton onClick={() => this.addToTransaction([attachment])}>
                          <Icon icon={['fal', 'file-invoice-dollar']} />
                          <Text>Add to Transaction</Text>
                        </FileButton>
                      )}
                      <FileButton as="a" download={true} href={attachment.url} target="_blank">
                        <Icon icon={['fal', 'arrow-to-bottom']} />
                        <Text>Download</Text>
                      </FileButton>
                    </FileActions>
                  </AttachmentLi>
                ))}
              </AttachmentUl>
            )}
            {pdfs.length > 1 && (
              <AttachmentUl>
                <AttachmentLi>
                  <div />
                  <FileActions basic={true} compact={true} size="tiny">
                    <FileButton onClick={() => this.addToTransaction(pdfs)}>
                      <Icon icon={['fal', 'file-invoice-dollar']} />
                      <Text>Add all PDFs to Transaction</Text>
                    </FileButton>
                  </FileActions>
                </AttachmentLi>
              </AttachmentUl>
            )}
            {showModal && modal}
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
      />
    )
  }

  private getFullMessage = async () => {
    const {
      cardData: { _id }
    } = this.props
    const query = await getMessageEmailById(_id)
    if (query) {
      const messageData = query
      const { html } = messageData
      let iframeSrc = ''
      if (html && html.length > 0) {
        iframeSrc = `${process.env.REACT_APP_SERVER_URL}/email/content/${_id}`
      }
      this.setState({ iframeSrc, messageData })
    }
  }

  private getTimestamp = () => {
    const {
      cardData: { sentAt }
    } = this.props

    return {
      date: moment(sentAt).format('MMMM Do, YYYY'),
      time: moment(sentAt).format('hh:mm a')
    }
  }

  private getFriendlyName = (data: EmailAddress) => {
    let friendlyName = data.value
    if (data.name && data.name.length > 0) {
      friendlyName = `${data.name} (${data.value})`
    }
    return friendlyName
  }

  private addToTransaction = async (attachments: InboxAttachment[]) => {
    const {
      messageData: { from }
    } = this.state
    let transactionOwner: any = null
    if (from.value) {
      transactionOwner = await genericGetUsers({ userName: from.value })
    }
    if (transactionOwner && transactionOwner.length === 1) {
      transactionOwner = transactionOwner[0]
    }
    const modal = <AddDocumentModal documents={attachments} onClose={this.closeModal} owner={transactionOwner} />
    this.setState({
      modal,
      showModal: true
    })
  }

  private closeModal = () => {
    this.setState({ showModal: false })
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({
        transition: true
      })
      onClose()
    }, 300)
  }
}

export default ViewModal
