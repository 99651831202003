// Import Packages
import { Form, Input, Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const CloseWrapper = styled.div`
  height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
`

const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  margin-top: 0px;
  z-index: 999;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.2);
`
const PopUpContent = styled.div`
  padding: 0 !important;
`

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const Filters = styled.div`
  width: 175px;
  position: relative;
  background: ${Colors.DarkBlue290};
`

const StyledMenu = styled(Menu)`
  &.ui.vertical.menu {
    background-color: ${Colors.transparent};
    box-shadow: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    width: 84%;
    width: 100%;
  }

  &.ui.vertical.menu > .active.item:first-child {
    border-radius: 0;
  }

  &.ui.vertical.menu > .active.item:last-child {
    border-radius: 0;
  }

  &.ui.vertical.menu .item:before {
    background: none;
  }

  &.ui.vertical.menu .item {
    color: ${Colors.Black450};
    font-size: 14px;
    font-weight: 600;
  }

  &.ui.vertical.menu .item:hover {
    background-color: ${Colors.Black850};
  }

  &.ui.vertical.menu .active.item {
    background: ${Colors.DarkBlue200};
    border-radius: 0;
    color: ${Colors.White1000};
    font-weight: bold;
  }
`

const StyledForm = styled(Form)`
  width: 70%;
  margin: 0 auto;
  padding-top: 20px;
`

const SearchContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  background: white;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 3px 5px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.06);
`

const SearchInput = styled(Input)`
  &.ui.input > input {
    max-width: 95%;
    font-size: 12px;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.06);
  }

  .fa-search {
    color: ${Colors.Black570};
    cursor: pointer;
    position: absolute;
    line-height: 1;
    text-align: center;
    top: 0;
    margin: 0;
    height: 100%;
    width: 1.071429em;
    opacity: 0.5;
  }

  .fa-times {
    color: ${Colors.Black570};
    cursor: pointer;
    position: absolute;
    line-height: 1;
    text-align: center;
    top: 0;
    right: 6px;
    margin: 0;
    height: 100%;
    width: 0.671429em;
    opacity: 0.5;
  }

  &.ui.input {
    width: 100%;
  }

  &.ui.icon.input > input {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`

const Divider = styled.div`
  border-bottom: 1px solid ${Colors.Grey580};
  margin-top: 15px;
  margin-bottom: 12px;
`

const Wrapper = styled.div`
  display: flex;
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  width: 20%;

  div:nth-child(1) {
    background: ${Colors.White1000};
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 3px solid ${Colors.Blue915};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  div:nth-child(2) {
    font-weight: 600;
    color: ${Colors.DarkBlue200};
  }

  .svg-inline--fa {
    width: 15px;
    height: 15px;
    color: ${Colors.DarkBlue200};
  }
`

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: baseline;
  line-height: 1.5;
  margin-left: 30px;
`

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
`

const Phone = styled.div`
  font-size: 10px;
  color: ${Colors.Black550};
  font-weight: 500;

  .fa-phone {
    transform: rotate(100deg);
    margin-right: 3px;
  }

  .fa-envelope {
    margin-right: 1px;
  }
`

const Type = styled.div`
  font-size: 10px;
  color: ${Colors.DarkBlue200};
  font-weight: 500;
  font-style: italic;
`

const Map = styled.div`
  font-size: 10px;
  color: ${Colors.Black550};
  font-weight: 500;

  .fa-map-marker-alt {
    margin-right: 5px;
  }
`

const Manager = styled.div`
  font-size: 10px;
  color: ${Colors.Black550};
  font-weight: 500;
  text-transform: capitalize;
`

const MLS = styled.div`
  font-size: 10px;
  color: ${Colors.Black550};
  font-weight: 500;
`

const Due = styled.div`
  font-size: 10px;
  color: ${Colors.Black550};
  font-weight: 500;
`

const RelatedTo = styled.div`
  font-size: 10px;
  color: ${Colors.Black550};
  font-weight: 500;
`

const Active = styled.span`
  font-style: italic;
  font-size: 10px;
  color: ${Colors.Black550};
  font-weight: 500;
  margin-left: 4px;
  font-weight: 500;
`

const LoadMore = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: ${Colors.DarkBlue200};
  margin-bottom: 25px;
  font-style: italic;
  cursor: pointer;
`

const SearchWrapper = styled.div`
  width: 40%;
  position: relative;
`

const FormContainer = styled.div`
  overflow: auto;
  max-height: 330px;
  min-height: 330px;
  height: 330px;
  ${scrollbars};
`

const Labels = styled(Label)`
  &.ui.circular.label,
  .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }
`

const Infograph = styled.div`
  background: ${(props: any) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
  align-self: center;
  margin-top: 30px;
`

export {
  Active,
  CloseWrapper,
  Container,
  ContainerWrapper,
  FormContainer,
  Divider,
  Filters,
  PopUpContent,
  SearchContainer,
  StyledContainer,
  StyledForm,
  StyledMenu,
  SearchWrapper,
  SearchInput,
  Wrapper,
  Icon,
  Name,
  Phone,
  Type,
  Map,
  Manager,
  Due,
  RelatedTo,
  MLS,
  LoadMore,
  Labels,
  Infograph
}
