import * as React from 'react'
import { Button, Form } from 'semantic-ui-react'

import { Strings } from 'utils'

import { Image, Pass, PassName, PassWrapper, ToolTip } from './Styled'

import { PassType } from './Types'

interface Props {
  added: string
  onAdd: (pass: PassType) => void
  onSelect: (data: PassType) => void
  passes: PassType[]
  transition: number
}

class Passes extends React.Component<Props, {}> {
  public render() {
    const { added, onAdd, onSelect, passes, transition } = this.props

    return (
      <React.Fragment>
        {passes.map((item: any, index: number) => (
          <Pass key={index} transition={transition} className={added === item._id ? 'hidden' : ''}>
            <PassWrapper>
              <Image icon={item.icon} size={4} />
              <ToolTip content={item.name} position={'top center'} trigger={<PassName>{item.name}</PassName>} />
              <Button className="more-info" onClick={() => onSelect(item)}>
                {Strings.dashboard.searchPassModal.moreInfo}
              </Button>
              <Form.Field
                className="add"
                control={Button}
                disabled={added === item._id}
                content={Strings.dashboard.searchPassModal.add}
                onClick={() => onAdd(item)}
              />
            </PassWrapper>
          </Pass>
        ))}
      </React.Fragment>
    )
  }
}

export default Passes
