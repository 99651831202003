import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Dashboard/Actions'
import { getLoggedInUser, Routes, Strings } from 'utils'

import { getBillingUsage } from 'shared/Billing/Queries'

import {
  Menu,
  MenuItem,
  PopupContainer,
  ProfileImage,
  Row,
  Section,
  StyledPopup,
  Tier,
  TierContainer,
  TierDetail,
  TierPopup,
  Usage
} from './Styled'

import { BillingPlanEnum, BillingProductUsageType } from 'shared/Billing/Types'
import { MenuActionEnum, MenuItemType, PrivilegeEnum, TierType, UserType } from './Types'

import { faSortDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faSortDown])

interface DispatchProps {
  setProfileImage: (profile: string) => void
  setShowPricingTabInSettings: (data: boolean) => void
  plan: BillingPlanEnum
  profileImage: string
  usage: BillingProductUsageType
}

interface OwnProps extends RouteComponentProps<{}> {
  showChangePasswordModal: () => void
}

type Props = OwnProps & {} & DispatchProps

interface State {
  isOpen: boolean
  privilege: PrivilegeEnum
  user: UserType
}

const MenuItems: MenuItemType[] = [
  { action: MenuActionEnum.Profile, privilege: PrivilegeEnum.GUEST, title: 'Profile' },
  { action: MenuActionEnum.Password, privilege: PrivilegeEnum.GUEST, title: 'Change Password' },
  { action: MenuActionEnum.Billing, privilege: PrivilegeEnum.ADMIN, title: 'Account And Billing' },
  { action: MenuActionEnum.Support, privilege: PrivilegeEnum.AGENT, title: 'Support' },
  {
    action: MenuActionEnum.Logout,
    privilege: PrivilegeEnum.GUEST,
    title: Strings.dashboard.header.signOut,
    divide: true
  }
]

const Tiers: TierType[] = [
  { title: 'Tier 1', cost: 5, min: 0, max: 50 },
  { title: 'Tier 2', cost: 4, min: 51, max: 100 },
  { title: 'Tier 3', cost: 3, min: 101, max: 200 },
  { title: 'Tier 4', cost: 2, min: 201, max: null }
]

class Profile extends React.Component<Props, State> {
  public state = {
    isOpen: false,
    privilege: PrivilegeEnum.GUEST,
    user: {} as UserType
  }

  public async componentDidMount() {
    this.refreshData()
  }

  public render() {
    const { plan, profileImage, usage } = this.props

    const { isOpen, privilege } = this.state

    return (
      <StyledPopup
        trigger={
          <PopupContainer>
            <ProfileImage imageUrl={profileImage} />
            <FontAwesomeIcon icon={['fas', 'sort-down']} />
          </PopupContainer>
        }
        content={
          <Menu>
            {privilege >= PrivilegeEnum.MANAGER && (
              <Section>
                <Row justify="space-between">
                  <Usage>
                    {Strings.dashboard.header.progress} (
                    {usage.transaction >= 5 && plan !== BillingPlanEnum.Paid ? 'MAX' : usage.transaction})
                  </Usage>
                  <TierContainer justify="flex-end">
                    {Tiers.map((item: TierType, index: number) => (
                      <TierPopup
                        key={index}
                        content={
                          <TierDetail>
                            <strong>{item.title}</strong>
                            <span>
                              {item.min === 0 ? 1 : item.min}
                              {item.max ? ` - ${item.max}` : `+`}
                            </span>
                            <span>${item.cost} per Transaction</span>
                            {usage.transaction >= 5 &&
                              plan !== BillingPlanEnum.Paid && (
                                <em>You've reached the limit of 5 free Transactions.</em>
                              )}
                          </TierDetail>
                        }
                        trigger={
                          <Tier
                            custom={{
                              activeTier: usage.transaction >= item.min,
                              freeLimit: usage.transaction >= 5 && plan !== BillingPlanEnum.Paid
                            }}
                          />
                        }
                        size="mini"
                        offset={'0, -50%'}
                        position={'top center'}
                      />
                    ))}
                  </TierContainer>
                </Row>
              </Section>
            )}
            <Section>
              {MenuItems.map(
                (item: MenuItemType, index: number) =>
                  privilege >= item.privilege && (
                    <MenuItem
                      key={index}
                      onClick={() => this.handleMenuItemClick(item)}
                      custom={{
                        addDivider: item.divide
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  )
              )}
            </Section>
          </Menu>
        }
        position={'bottom right'}
        hoverable={true}
        open={isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
      />
    )
  }

  private handleMenuItemClick = (item: MenuItemType) => {
    const { showChangePasswordModal, setShowPricingTabInSettings } = this.props
    const { user } = this.state

    switch (item.action) {
      case MenuActionEnum.Profile:
        this.props.history.push(Routes.primary.profile.path + `/${user._id}`)
        break

      case MenuActionEnum.Password:
        showChangePasswordModal()
        break

      case MenuActionEnum.Billing:
        setShowPricingTabInSettings(true)
        this.props.history.push(Routes.settings.root)
        break

      case MenuActionEnum.Support:
        window.open('https://realtypass.crisp.help/en-us/')
        break

      case MenuActionEnum.Logout:
        this.props.history.push(Routes.logout.path)
        break

      default:
        break
    }

    this.handleClose()
  }

  private handleOpen = async () => {
    this.setState({ isOpen: true })
    this.refreshData()
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private refreshData = async () => {
    const { profileImage, setProfileImage } = this.props
    await getBillingUsage('network-only')

    const user: UserType = (await getLoggedInUser({ fromCache: false })) || this.state.user
    if (user) {
      const privilege: PrivilegeEnum = PrivilegeEnum[user.role]
      this.setState({ privilege, user })
      if (profileImage !== user.profileImage) {
        setProfileImage(user.profileImage)
      }
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  plan: state.billing.cycle.plan,
  profileImage: state.dashboard.userProfile,
  usage: state.billing.usage
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setProfileImage: Actions.getUserProfile,
      setShowPricingTabInSettings: Actions.showPricingTabInSettings
    }
  )(Profile)
)
