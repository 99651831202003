import * as React from 'react'
import InputMask from 'react-input-mask'

import TokenMethodToggle from 'app/Onboarding/TokenMethodToggle'
import { formToast, serverToast } from 'shared/Toast/Toast'

import {
  sendAccountLink,
  sendAccountOtp,
} from 'app/Onboarding/Queries'
import {
  validateEmail,
  validatePhone,
} from 'app/Onboarding/Validation'

import {
  Container,
  Row,
  StyledForm,
  StyledInput,
  Text,
} from './Styled'

import { MethodEnum } from 'app/Onboarding/TokenMethodToggle/Types'
import { SubjectEnum } from 'app/Onboarding/Types'
import {
  FormErrorType,
  FormType,
} from './Types'

interface Props {
  form: FormType
  type: SubjectEnum
  onUpdate: (form: FormType) => void
  onSubmit: () => void
}

interface State {
  isEmailAttempt: boolean
}

class TokenRequestForm extends React.Component<Props, State> {
  public state = {
    isEmailAttempt: false
  }

  public render () {
    const {
      form,
      form: { errors },
    } = this.props
    const { isEmailAttempt } = this.state
    return (
      <Container spacing={0.25}>
        {isEmailAttempt ? (
          <Text>Sorry, we could not determine an account with that phone number. Please confirm your account email.</Text>
        ): (
          <Row>
            <TokenMethodToggle
              name={'method'}
              value={form.method}
              onChange={this.handleChange}
            />
          </Row>
        )}
        <StyledForm onSubmit={this.handleSubmit}>
          {form.method === MethodEnum.Sms && !isEmailAttempt ? (
            <InputMask
              mask='(999) 999-9999'
              value={form.phone}
              onChange={this.handlePhoneChange}
            >
              {() => (
                <StyledInput
                  action='Submit'
                  error={!!errors.phone}
                  fluid={true}
                  name='phone'
                  placeholder='Phone Number'
                />
              )}
            </InputMask>
          ) : (
            <StyledInput
              action='Submit'
              error={!!errors.email}
              fluid={true}
              name='email'
              onChange={this.handleChange}
              placeholder='Email address'
              type='email'
              value={form.email}
            />
          )}
        </StyledForm>
      </Container>
    )
  }

  private handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const { form, onSubmit, type } = this.props
    const { isEmailAttempt } = this.state
    const input:any = {
      phone: form.phone,
      type,
      userName: form.email,
    }

    if (form.method === MethodEnum.Email) {
      const validation = validateEmail(form)
      if (!validation.formIsValid) {
        this.handleFormError(validation.errors)
        return
      }

      try {
        delete input.phone
        await sendAccountLink(input)
      } catch (error) {
        serverToast(error)
        return
      }
    } else if (form.method === MethodEnum.Sms) {
      const validation = validatePhone(form.phone)
      if (!isEmailAttempt && !validation.formIsValid) {
        this.handleFormError(validation.errors)
        return
      }

      try {
        input.phone = isEmailAttempt ? '' : form.phone
        input.userName = isEmailAttempt ? form.email : ''
        const result = await sendAccountOtp(input)
        this.handleChange(null, { name: 'phone', value: result })
        if (!result) {
          this.setState({ isEmailAttempt: true })
          return
        }
      } catch (error) {
        serverToast(error)
        if (isEmailAttempt) {
          this.setState({ isEmailAttempt: false })
        }
        return
      }
    }

    onSubmit()
  }

  private handleFormError = (errors: FormErrorType) => {
    const { form, onUpdate } = this.props
    form.errors = errors
    formToast(errors)
    onUpdate(form)
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>|null, { name, value }: any) => {
    const { form, onUpdate } = this.props
    form[name] = value
    onUpdate(form)
  }

  private handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleChange(null, { name: 'phone', value: e.target.value })
  }
}

export default TokenRequestForm
