// Import Packages
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Select } from 'semantic-ui-react'

import DatePicker from 'shared/DatePicker'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'

import { getStates } from '../../../../Leads/Dashboard/LeadQueries'

import { StyledForm } from '../Styled'
interface Options {
  key: string
  text: string
  value: string
}

interface StoreProps {
  setFilter: (data: any) => void
  setChangeKey: (key: number) => void
  setResetFilterData: (reset: boolean) => void
  filter: any
  key: number
  resetData: boolean
}

interface OwnProps {
  categoryId: string
  report: string
}

type Props = StoreProps & OwnProps

interface State {
  activeIndex: string
  searchData: any
  cities: Options[]
  states: Options[]
  selectedCity: Options
  selectedState: Options
  openDueDate: boolean
}

const taskOptions = [
  { key: 'Email', text: 'Email', value: 'Email' },
  { key: 'Call', text: 'Call', value: 'Call' },
  { key: 'Meeting', text: 'Meeting', value: 'Meeting' },
  { key: 'Sms', text: 'Sms', value: 'Sms' },
  { key: 'Pass', text: 'Pass', value: 'Pass' }
]

class TaskContent extends React.Component<Props, State> {
  public state = {
    activeIndex: '',
    cities: [],
    openDueDate: false,
    searchData: {},
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: []
  }

  public async componentDidMount() {
    const { setChangeKey } = this.props
    const states = await getStates()
    if (states) {
      const statesData: Options[] = []
      states.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`
        }
        element.name && statesData.push(obj)
      })
      this.setState({ states: statesData })
    }
    setChangeKey(0)
  }

  public componentWillReceiveProps(nextProps: any) {
    const { setChangeKey, resetData } = this.props
    if (resetData ? resetData : nextProps.resetData) {
      this.setState({
        searchData: {},
        selectedCity: {
          key: '',
          text: '',
          value: ''
        },
        selectedState: {
          key: '',
          text: '',
          value: ''
        }
      })
      setChangeKey(1)
    }
  }

  public render() {
    const { report, key, filter } = this.props
    const { openDueDate } = this.state
    return (
      <div key={key}>
        <StyledForm size={'tiny'}>
          {report === 'Task Name' && (
            <div>
              <Form.Field
                control={Input}
                label="Task Name"
                name="taskName"
                value={filter[`taskName`]}
                placeholder="Task Name"
                onChange={this.handleChange}
              />
            </div>
          )}
          {report === 'Due Date' && (
            <Form.Field
              control={Input}
              label="Due Date"
              name="dueDate"
              value={filter[`dueDate`]}
              onClick={this.handleOpen}
              autoComplete="off"
              action={{
                icon: (
                  <DatePicker
                    onChangeDate={this.onDateRange}
                    open={openDueDate}
                    handleOpen={this.handleOpen}
                    handleClose={this.handleClose}
                  />
                ),
                onClick: this.handleOpen
              }}
              placeholder="Due Date"
            />
          )}
          {report === 'Type' && (
            <Form.Field
              control={Select}
              label="Type"
              name="type"
              placeholder="Type"
              value={filter[`type`]}
              options={taskOptions}
              onChange={this.handleChange}
            />
          )}
        </StyledForm>
      </div>
    )
  }

  private handleOpen = () => {
    this.setState({ openDueDate: true })
  }

  private handleClose = () => {
    this.setState({ openDueDate: false })
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { setFilter, filter } = this.props
    if (name === 'type') {
      this.setState(
        {
          searchData: {
            ...filter,
            type: value
          }
        },
        () => setFilter(this.state.searchData)
      )
    } else {
      this.setState(
        {
          searchData: {
            ...filter,
            name: value
          }
        },
        () => setFilter(this.state.searchData)
      )
    }
  }

  private onDateRange = (date: any) => {
    const { filter, setFilter } = this.props
    this.setState(
      {
        searchData: {
          ...filter,
          dueDate: moment(date).format('L')
        }
      },
      () => setFilter(this.state.searchData)
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.reports.filter,
  key: state.reports.key,
  resetData: state.reports.resetData
})

export default connect(
  mapStateToProps,
  {
    setChangeKey: Actions.changeKey,
    setFilter: Actions.getFilter,
    setResetFilterData: Actions.resetFilterData
  }
)(TaskContent)
