// Import Packages
import { fonts } from 'design/Fonts'
import styled from 'styled-components'

interface Props {
  background: string
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: end;
  position: fixed;
  bottom: 35px;
  right: 100px;
  text-align: right;
`

const DateContainer = styled.div`
  font: ${fonts.xxLarge.bold};
  font-size: 35px;
  color: ${(props: Props) => props.background || props.background};
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14);
`

const Header = styled.div`
  font: ${fonts.xLarge.bold};
  color: ${(props: Props) => props.background || props.background};
  margin: -5px 0 10px;
  font-size: 20px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.14);
  text-transform: capitalize;
`

const SubHeader = styled.div`
  color: ${(props: Props) => props.background || props.background};
  font-size: 16px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .fa-tools {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  strong {
    color: ${(props: Props) => props.background || props.background};
    font-size: 14px;
  }
`

export { ContentContainer, DateContainer, Header, SubHeader }
