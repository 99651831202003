import * as React from 'react'

import { UsaStates } from 'utils'

import { Container, StyledDropdown } from './Styled'

import { OptionType, StateType } from './Types'

interface Props {
  onChange: (event: any, data: any) => void
  value: string
  [inputProps: string]: any
}

interface State {
  options: OptionType[]
}

class StateDropdown extends React.Component<Props, State> {
  public state = {
    options: [] as OptionType[]
  }

  public componentDidMount = () => {
    const options = UsaStates.map((state: StateType) => {
      const option: OptionType = {
        abbr: state.abbreviation,
        key: state.name.toLowerCase(),
        text: state.name,
        value: state.name
      }
      return option
    })
    this.setState({ options })
  }

  public componentDidUpdate = (prev: Props) => {
    const { onChange, value, ...inputProps } = this.props
    const { options } = this.state
    if (value !== null && value !== prev.Props && value.length < 3 && options.length > 0) {
      const state = options.find((option: OptionType) => option.abbr.toLowerCase() === value.toLowerCase())
      if (state) {
        onChange(null, { ...inputProps, value: state.value })
      }
    }
  }

  public render = () => {
    const { value, onChange, ...inputProps } = this.props

    const { options } = this.state

    return (
      <Container className="rp-state-dropdown">
        <StyledDropdown
          fluid={true}
          onChange={this.handleDropdownChange}
          options={options}
          search={this.handleSearch}
          selection={true}
          value={value}
          {...inputProps}
        />
      </Container>
    )
  }

  private handleDropdownChange = (e: React.SyntheticEvent, data: any) => {
    const { onChange } = this.props
    onChange(e, data)
  }

  private handleSearch = (e: any, search: string) => {
    const { options } = this.state
    if (!search) {
      return options
    }

    const getResults = (regex: string) => {
      const re = new RegExp(regex, 'i')
      return options.filter((item: OptionType) => re.test(item.text))
    }

    let filteredOptions: any = getResults(`^${search}`)
    if (filteredOptions.length === 0) {
      filteredOptions = getResults(search)
    }

    return filteredOptions
  }
}

export default StateDropdown
