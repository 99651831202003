import styled from 'styled-components'

import Colors from 'design/Colors'
import { y } from 'design/Styled'

const KanbanWrapper = styled.div`
  ${y};
  width: 280px;
  height: 100%;
  box-shadow: 0 6px 24px -6px ${Colors.KanbanShadow};
  background-color: ${Colors.Kanban};
  border: 4px solid ${props => props.color || Colors.EmphasisLine};
  border-left: none;
  border-right: none;
  overflow: hidden;
`

const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.color || Colors.Purple50};
  font-size: 20px;
  margin-bottom: 15px;

  .fa-dollar-sign {
    margin-right: 5px;
  }
`

export { KanbanWrapper, Price }
