interface Props {
  delay?: number
  duration?: number
  style?: string
  height?: number
  width?: number
}

const getTransition = ({ delay, duration }: Props) => {
  return `${duration || 300}ms ${delay || 0}ms`
}

/* tslint:disable:object-literal-sort-keys */
export const styles = (props: Props) => {
  switch (props.style) {
    case 'fadeUp-exit':
      return {
        opacity: 0,
        WebkitTransform: 'translate3d(0, 10px, 0)',
        msTransform: 'translate3d(0, 10px, 0)',
        transform: 'translate3d(0, 10px, 0)',
        transition: `opacity ${getTransition(props)}, transform ${getTransition(props)}`
      }

    case 'fadeUp-enter':
      return {
        opacity: 1,
        WebkitTransform: 'translate3d(0, 0, 0)',
        msTransform: 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
        transition: `opacity ${getTransition(props)}, transform ${getTransition(props)}`
      }

    case 'fadeDown-exit':
      return {
        opacity: 0,
        WebkitTransform: 'translate3d(0, -10px, 0)',
        msTransform: 'translate3d(0, -10px, 0)',
        transform: 'translate3d(0, -10px, 0)',
        transition: `opacity ${getTransition(props)}, transform ${getTransition(props)}`
      }

    case 'fadeDown-enter':
      return {
        opacity: 1,
        WebkitTransform: 'translate3d(0, 0, 0)',
        msTransform: 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
        transition: `opacity ${getTransition(props)}, transform ${getTransition(props)}`
      }

    case 'fadeIn-exit':
      return {
        opacity: 0,
        WebkitTransform: 'scale3d(0.95, 0.95, 0.95)',
        msTransform: 'scale3d(0.95, 0.95, 0.95)',
        transform: 'scale3d(0.95, 0.95, 0.95)',
        transition: `opacity ${getTransition(props)}, transform ${getTransition(props)}`
      }

    case 'fadeIn-enter':
      return {
        opacity: 1,
        WebkitTransform: 'scale3d(1, 1, 1)',
        msTransform: 'scale3d(1, 1, 1)',
        transform: 'scale3d(1, 1, 1)',
        transition: `opacity ${getTransition(props)}, transform ${getTransition(props)}`
      }

    case 'fadeLeft-exit':
      return {
        opacity: 0,
        WebkitTransform: 'translate3d(10px, 0, 0)',
        msTransform: 'translate3d(10px, 0 , 0)',
        transform: 'translate3d(10px, 0 , 0)',
        transition: `opacity ${getTransition(props)}, transform ${getTransition(props)}`
      }

    case 'fadeLeft-enter':
      return {
        opacity: 1,
        WebkitTransform: 'translate3d(0, 0, 0)',
        msTransform: 'translate3d(0, 0 , 0)',
        transform: 'translate3d(0, 0 , 0)',
        transition: `opacity ${getTransition(props)}, transform ${getTransition(props)}`
      }

    case 'expandY-before':
      return {
        position: 'fixed',
        top: '-999999px',
        left: '-999999px',
      }

    case 'expandY-exit':
      return {
        height: 0,
        overflow: 'hidden',
        transition: `height ${getTransition(props)}`,
      }

    case 'expandY-enter':
      return {
        height: props.height || 'auto',
        overflow: 'hidden',
        transition: `height ${getTransition(props)}`,
      }

    default:
      return {}
  }
}
/* tslint:enable:object-literal-sort-keys */
