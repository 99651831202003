// Import Packages
import * as React from 'react'
import { Form, Input, Table } from 'semantic-ui-react'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  StyledPopup,
  StyledTable,
  Title
} from './Styled'

// Font Awesome Icons
import {
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { updateProfile } from 'app/Settings/Mutations'
AddIconToLibrary([faInfoCircle])

interface Props {
  profile: any
}

interface State {
  systemDefault: any
}

class UserConfiguration extends React.Component<Props, State> {
  public state = {
    systemDefault: {}
  }

  public componentDidMount() {
    const { profile } = this.props
    this.setState({ systemDefault: profile })
  }

  public render() {
    const { systemDefault } = this.state
    return (
      <Container>
        <Title>{Strings.settings.configuration.userConfiguration}</Title>
        <StyledTable basic={true}>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{Strings.settings.configuration.agentReferralBonus}</Table.Cell>
              <Table.Cell><Input className='amount' type='number' icon='dollar sign' placeholder='100 (Default)' name='referralBonus' onChange={this.handleChange} value={systemDefault[`referralBonus`]} /></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {Strings.settings.configuration.maxAgentReferrals}
                <StyledPopup
                  position='bottom center'
                  trigger={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
                  content={Strings.settings.configuration.numberOfAgent} />
              </Table.Cell>
              <Table.Cell><Form.Field control={Input} type='number' placeholder='50 (Default)' name='maxAgentReferrals' onChange={this.handleChange} value={systemDefault[`maxAgentReferrals`]} /></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{Strings.settings.configuration.permitted}</Table.Cell>
              <Table.Cell><Form.Field control={Input} type='number' placeholder='5 (Default)' name='maxTeamSize' onChange={this.handleChange} value={systemDefault[`maxTeamSize`]} /></Table.Cell>
            </Table.Row>
          </Table.Body>
        </StyledTable>
      </Container>
    )
  }

  private handleChange = async (event: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { systemDefault } = this.state
    systemDefault[name] = parseInt(value)
    this.setState({ systemDefault })
    await updateProfile(systemDefault)
  }

}

export default UserConfiguration
