import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { x, y, text, Props as StyledProps } from 'design/Styled'

interface Props extends StyledProps {
  isActive?: boolean
  src?: string
}

const getActive = ({ isActive }: Props) => {
  if (!isActive) {
    return css`
      &:hover {
        box-shadow: 0 0 0 1px ${Colors.StatusInactive};
      }
    `
  }

  return css`
    box-shadow: 0 0 0 2px ${Colors.StatusActive};
  `
}

export const Container = styled.section`
  ${x};
  flex-wrap: wrap;
`

export const Label = styled.p`
  padding: 1em 0;
  margin: 0;
`

export const Template = styled.figure`
  ${y};
  position: relative;
  width: 31%;
  padding: 0.5em;
  margin: 1%;
  cursor: pointer;
  box-shadow: 0 0 0 1px ${Colors.TransparentWhite(0)};
  transition: box-shadow 300ms;

  ${getActive};
`

export const Image = styled.div`
  display: block;
  position: relative;
  width: 100%;
  transition: transform 300ms;

  &:after {
    display: block;
    content: '';
    padding-bottom: 66.6%;
  }

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${({ src }:Props) => src || ''});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:hover {
    transform: scale3d(1.05,1.05,1.05);
  }
`

export const Caption = styled.figcaption`
  ${x};
  ${text};
  width: 100%;
  align-items: center;
  padding: 0.5em 0;
`
