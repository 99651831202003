// Import Packages
import { Card } from 'semantic-ui-react'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled(Segment)`
  &.ui.segment {
    background-color: ${Colors.transparent};
    border: none;
    box-shadow: none;
    display: flex;
  }

  .fa-percent {
    width: 12px;
    height: 20px;
    margin-left: 3px;
  }

  .fa-dollar-sign {
    width: 12px;
    height: 20px;
    margin-right: 6px;
  }

  .fa-bullhorn {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 3px;
    left: 95px;
    color: ${Colors.DarkBlue225};
  }

  .fa-home {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 3px;
    left: 95px;
    color: ${Colors.DarkBlue225};
  }

  .fa-building {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 8px;
    left: 95px;
    color: ${Colors.Pick65};
  }

  .fa-user {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 6px;
    left: 95px;
    color: ${Colors.Orange120};
  }

  .fa-users {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 6px;
    left: 95px;
    color: ${Colors.Orange120};
  }

  .fa-folders {
    position: absolute;
    width: 55px;
    height: 50px;
    top: 3px;
    left: 86px;
    color: ${Colors.Pick65};
  }

  .fa-arrow-up {
    margin-right: 2px;
  }

  .fa-arrow-down {
    margin-right: 2px;
  }

  .sales>span {
    font-size: 23px;
  }

  .sales {
    margin-top: 10px;
    cursor: pointer;
  }

  .sales>div:nth-child(1) {
    font-size: 20px;
  }

  .sales>div:nth-child(2) {
    font-size: 9px;
    color: ${Colors.Black500};
    font-weight: 500;
    margin-top: -2px;
  }

  .sales-desription {
    font-size: 12px;
    margin-top: 5px;
    color: ${Colors.Black500};
  }

  .sales-desription>span:nth-child(1) {
    color: ${Colors.Green80};
    font-weight: 600;
  }

  .sales-desription>span:nth-child(2) {
    margin-left: 2px;
    color: ${Colors.Black515};
    font-weight: 500;
  }

  .sales-header {
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.Black505};
    font-family: inherit;
    cursor: pointer;
  }

  .transaction>span {
    font-size: 23px;
  }

  .transaction {
    margin-top: 10px;
  }

  .transaction>div:nth-child(1) {
    margin-top: 26px;
    font-size: 23px;
  }

  .transaction>div:nth-child(2) {
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;
    margin-top: -2px;
  }

  .transaction-desription {
    font-size: 12px;
    color: ${Colors.Black500};
    margin-top: 8px;
  }

  .transaction-desription>span:nth-child(1) {
    color: ${Colors.Green80};
    font-weight: 600;
  }

  .transaction-desription>span:nth-child(2) {
    margin-left: 2px;
    color: ${Colors.Black515};
    font-weight: 500;
  }

  .transaction-header {
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.Black505};
    font-family: inherit;
  }

  .transaction-header {
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.Black505};
    font-family: inherit;
  }

  .transaction-header>span {
    position: absolute;
  }

  .sales-header>span {
    position: relative;
  }

  .transaction>span:nth-child(1) {
    font-size: 12px;
  }

  .lead>span {
    font-size: 23px;
  }

  .lead {
    margin-top: 10px;
  }

  .lead>div:nth-child(1) {
    font-size: 23px;
  }

  .lead>div:nth-child(2) {
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;
    margin-top: -2px;
  }

  .lead-desription {
    font-size: 12px;
    color: ${Colors.Black500};
    margin-top: 17px;
  }

  .lead-desription>span:nth-child(1) {
    color: ${Colors.Red15};
    font-weight: 600;
  }

  .lead-desription>span:nth-child(2) {
    margin-left: 2px;
    color: ${Colors.Black515};
    font-weight: 500;
  }

  .lead-header {
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.Black505};
    font-family: inherit;
  }

  .lead-header {
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.Black505};
    font-family: inherit;
  }

  .lead-header>span {
    position: relative;
  }

  .lead>span:nth-child(1) {
    font-size: 12px;
  }
`

const StyledCard = styled(Card.Group)`
  &.ui.cards>.card {
    width: 165px;
  }

  &.ui.cards {
    flex-wrap: nowrap;
  }

  &.ui.cards>.card.sales-card {
    background: ${Colors.DarkBlue215};
    border-radius: 2px;
    padding-top: 15px;
    height: 126px;
    box-shadow: none;
    margin-right: 20px;
  }

  &.ui.cards>.card.transaction-card {
    background: ${Colors.Pink55};
    border-radius: 2px;
    padding-top: 15px;
    height: 126px;
    box-shadow: none;
    margin-right: 20px;
  }

  &.ui.cards>.card.lead-card {
    background: ${Colors.Orange115};
    border-radius: 2px;
    padding-top: 15px;
    height: 126px;
    box-shadow: none;
    margin-right: 15px;
    cursor: pointer;
  }
`

export {
  Container,
  StyledCard
}
