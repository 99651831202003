export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`name`] || !fields[`name`].match(/^[a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/)) {
    formIsValid = false
    errors[`name`] = 'Can Not be empty'
  }

  if (!fields[`workflowCategory`]) {
    formIsValid = false
    errors[`workflowCategory`] = 'Can Not be empty'
  }

  if (!fields[`visibility`]) {
    formIsValid = false
    errors[`visibility`] = 'Can Not be empty'
  }

  return { errors, formIsValid }
}
