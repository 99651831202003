// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'

// Mailbox
import MailBoxBoard from 'shared/MailBoxBoard'
import { ConvertEmailToTask } from 'shared/MessageEmail/Conversions'

// Tasks
import { default as TaskCard } from 'shared/Tasks/KanbanCard'
import { createTask, deleteTasks, moveTask, reorderCard } from 'app/Tasks/TaskMutations'
import { getMyTaskBoards } from 'app/Tasks/TaskQueries'
import { formatKanbanData } from 'app/Tasks/Utils/FormattingData'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as TaskActions from 'store/Tasks/Actions'
import { TaskDetails, TaskItemsDetails, TaskNotes } from 'store/Tasks/Types'

// Import Utils
import { generateID, getLoggedInUser, Strings } from 'utils'
import { Icons } from './Icons'

interface Props {
  addNewCard: (id: string, index: string, newData: TaskItemsDetails) => void
  addNewNotes: (newData: TaskNotes) => void
  taskBoardData: TaskDetails[]
  changeStatus: (boardId: string, cardId: string, status: string) => void
  deleteCard: (boardId: string, cardId: string) => void
  deleteNotes: (id: string) => void
  editNotes: (id: string, newData: TaskNotes) => void
  getBoardData: (data: TaskDetails[]) => void
  getNotesData: (notes: TaskNotes[]) => void
  notes: TaskNotes[]
  resetCardData: () => void
  restoreCard: () => void
  setNewBoardName: (id: string, boardName: string) => void
  setNewDescription: (id: string, description: string) => void
  setNewIcon: (id: string, icon: string) => void
}

interface State {
  activeIndex: number
  appearance: boolean
  boardName?: string
  checked: boolean
  date: Date
  listViewData: any
  newBoard?: TaskDetails
  newCard?: TaskItemsDetails
  setIcon: any
  showAddTaskModal: boolean
  user: any
}

class MailBox extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    appearance: true,
    checked: false,
    date: new Date(),
    listViewData: [],
    setIcon: '',
    showAddTaskModal: true,
    user: {}
  }

  public componentDidMount = async () => {
    const { getBoardData, taskBoardData } = this.props

    const user = await getLoggedInUser()

    if (taskBoardData.length === 0) {
      const taskBoards = await getMyTaskBoards(user._id)
      const formattedData = await formatKanbanData(taskBoards, false)
      getBoardData(formattedData)
    }

    this.setState({ user })
  }

  public render() {
    const { taskBoardData } = this.props
    const { appearance } = this.state
    return (
      <MailBoxBoard
        ActionComponent={null}
        addNewCard={this.addNewCard}
        alterData={this.alterData}
        appearance={appearance}
        boardData={taskBoardData}
        CardComponent={TaskCard}
        deleteCard={this.deleteCard}
        icons={Icons}
        moveCard={this.moveTask}
        reorderCard={this.reorderTask}
        resetCardData={this.resetCardData}
        restoreCard={this.restoreCard}
        setNewBoardName={this.setNewBoardName}
        setNewDescription={this.setNewDescription}
        setNewIcon={this.setNewIcon}
        convertNewCard={this.convertNewCard}
      />
    )
  }

  private resetCardData = async () => {
    const { resetCardData, getBoardData } = this.props
    const { user } = this.state
    const kanbanData = await getMyTaskBoards(user[`_id`])
    const formatedData = await formatKanbanData(kanbanData, false)
    getBoardData(formatedData)
    resetCardData()
  }

  private restoreCard = () => {
    const { restoreCard, resetCardData } = this.props
    restoreCard()
    resetCardData()
  }

  private deleteCard = async (boardId: string, cardId: string) => {
    const { deleteCard } = this.props
    const result = await deleteTasks([cardId])
    if (result) {
      deleteCard(boardId, cardId)
    }
  }

  private moveTask = async (
    cardId: string,
    destinationIndex: number,
    destinationBoardId: string,
    sourceBoardId: string
  ) => {
    const { user } = this.state
    const { taskBoardData: data } = this.props
    let movedCard: any = {}
    data.forEach((row: any) => {
      if (row._id === sourceBoardId) {
        row.items.forEach((card: any) => {
          if (card._id === cardId) {
            movedCard = card
          }
        })
      }
    })
    if (movedCard.createdBy._id === user[`_id`] || user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
      await moveTask(cardId, destinationBoardId, destinationIndex, user, false)
      this.resetCardData()
    } else {
      this.resetCardData()
    }
  }

  private reorderTask = async (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
    const { user } = this.state
    reorderCard(boardId, cardId, sourceIndex, destinationIndex, user, false)
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    setNewBoardName(id, boardName)
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    setNewDescription(id, description)
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    setNewIcon(id, icon)
  }

  private addNewCard = (id: string, index: string) => {
    const { addNewCard } = this.props
    const newCard: any = {
      _id: generateID(),
      activity: '5',
      due: 'Deu Aug 12, 2018',
      name: 'SMS',
      notes: '2',
      relatedTo: 'Ryan Gosling Lead',
      sharedWith: [
        {
          url: `${Strings.office.avatar}`
        },
        {
          url: `${Strings.office.avatar1}`
        },
        {
          url: `${Strings.office.avatar2}`
        }
      ],
      task: 'Lorem Ipsum',
      taskDescription: 'Lorem Ipsum is simply dummay text of the printing'
    }
    addNewCard(id, index, newCard)
    this.setState({ showAddTaskModal: true })
  }

  private alterData = (data: any) => {
    const { getBoardData } = this.props
    getBoardData(data)
  }

  private convertNewCard = async (boardId: string, listIndex: string, inputData: any) => {
    const { addNewCard } = this.props
    const { user } = this.state
    const convertedCard = await ConvertEmailToTask(inputData)
    const newCard = await createTask(convertedCard, listIndex, boardId, 'listview', user, false, false)
    if (newCard) {
      addNewCard(boardId, listIndex, newCard)
    }
    return newCard
  }
}

const mapStateToProps = (state: AppState) => ({
  notes: state.tasks.notes,
  taskBoardData: state.tasks.data
})

export default connect(
  mapStateToProps,
  {
    addNewCard: TaskActions.addNewCard,
    addNewNotes: TaskActions.addNewNotes,
    changeStatus: TaskActions.changeStatus,
    deleteCard: TaskActions.deleteCard,
    deleteNotes: TaskActions.deleteNotes,
    editNotes: TaskActions.editNotes,
    getBoardData: TaskActions.getBoardData,
    getNotesData: TaskActions.getNotesData,
    resetCardData: TaskActions.resetCardData,
    restoreCard: TaskActions.restoreCard,
    setNewBoardName: TaskActions.setNewBoardName,
    setNewDescription: TaskActions.setNewDescription,
    setNewIcon: TaskActions.setNewIcon
  }
)(MailBox)
