import { Input } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import {
  children,
  childWidths,
  text,
  x,
  y,
} from 'design/Styled'


interface Props {
  amount?: number
}

const getDollar = ({ amount }: Props) => {
  if (!amount && amount !== 0) {
    return ``
  }
  return css`
    &:after {
      display: block;
      color: ${Colors.TextNegative};
      content: '$${Number(amount).toFixed(2).toLocaleString()}';
    }
  `
}

export const Col = styled.div`
  ${y};
`

export const Row = styled.div`
  ${x};
  ${children};
  ${childWidths};
  ${text};
  width: 100%;
`

export const AdminSettings = styled(Row)`
  ${y};
  & > :first-child {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    & > :last-child {
      padding-left: 0;
      font-weight: bold;
    }
  }
`

export const Title = styled.h2`
  color: ${Colors.TextDark};
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.8em;
`

export const Text = styled.p`
  ${text};
  line-height: 1.4;
  margin-bottom: 0.5em;
`

export const Line = styled(Row)`
  padding: 0.8em 1em;
  padding-left: 2.2em;
  border-bottom: 1px solid ${Colors.TableLine};

  &:hover {
    background: ${Colors.ListOdd};
  }
`

export const Label = styled.strong`
  ${x};
  ${children};
  ${text};
  font-weight: bold;
  line-height: 1.3;
`

export const Dollar = styled.span`
  ${getDollar};
  cursor: pointer;
`

export const StyledInput = styled(Input)`
  max-width: 60px;
`
