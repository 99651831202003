// Import Components
import client from 'queries/apollo'

// Import Graphql Mutations
import {
  ADD_USER_CREDIT_DEBIT,
  CREATE_FOLDER,
  CREATE_LINK,
  DELETE_FILE_OR_FOLDER,
  DELETE_LINK,
  DELETE_USER_CREDIT_DEBIT,
  DOWNLOAD_FILE_OR_FOLDER,
  SETUP_NEW_WEBSITE,
  UPDATE_FILE_FOLDER,
  UPDATE_LINK,
  UPDATE_USER_CREDIT_DEBIT,
  UPDATE_USER_PROFILE,
  UPLOAD_MY_DOCUMENT,
  UPLOAD_PROFILE,
} from 'queries/graphql/UserProfile/Mutation'

import { LinkData, UserCreditDebit, UserProfileType } from 'store/UserProfile/Types'

// Import Graphql Queries
import { GET_USER_PROFILE } from 'queries/graphql/UserProfile/Queries'

export const uploadProfilePic = (userId: string, file: any) => {
  return client.mutate({
    mutation: UPLOAD_PROFILE,
    refetchQueries: [
      {
        query: GET_USER_PROFILE,
        variables: { id: userId }
      }
    ],
    variables: { userId, file }
  })
}

export const updateProfile = (updatedObject: UserProfileType | any, basic: any) => {
  return client.mutate({
    mutation: UPDATE_USER_PROFILE,
    refetchQueries: [
      {
        query: GET_USER_PROFILE,
        variables: { id: updatedObject._id }
      }
    ],
    variables: {
      _id: basic._id,
      ...updatedObject
    }
  })
}

export const createDebitCredit = async (data: UserCreditDebit, userId: string) => {
  const response = await client.mutate({
    mutation: ADD_USER_CREDIT_DEBIT,
    variables: { ...data, amount: parseFloat(data.amount), userId }
  })

  return response.data.createUserCreditDebit
}

export const updateCreditDebit = async (data: UserCreditDebit) => {
  const response = await client.mutate({
    mutation: UPDATE_USER_CREDIT_DEBIT,
    variables: { ...data, amount: parseFloat(data.amount) }
  })

  return response.data.updateUserCreditDebit
}

export const deleteCreditDebit = async (accountId: string) => {
  const response = await client.mutate({
    mutation: DELETE_USER_CREDIT_DEBIT,
    variables: { accountId }
  })

  return response.data.deleteUserCreditDebit
}

export const uploadMyDocument = async (id: string | undefined, path: string, files: File) => {
  const response = await client.mutate({
    mutation: UPLOAD_MY_DOCUMENT,
    variables: { id, path, files }
  })

  return response.data.uploadMyDocument
}

export const createFolder = async (id: string | undefined, path: string, folderName: string) => {
  const response = await client.mutate({
    mutation: CREATE_FOLDER,
    variables: { id, path, folderName }
  })

  return response.data.createFolder
}

export const deleteFileFolder = async (key: string) => {
  const response = await client.mutate({
    mutation: DELETE_FILE_OR_FOLDER,
    variables: { key }
  })

  return response.data.deleteFileFolder
}

export const downloadFileFolder = async (key: string) => {
  const response = await client.mutate({
    mutation: DOWNLOAD_FILE_OR_FOLDER,
    variables: { key }
  })

  return response.data.downloadFileFolder
}

export const renameFileFolder = async (oldKey: string, newKey: string) => {
  const response = await client.mutate({
    mutation: UPDATE_FILE_FOLDER,
    variables: { oldKey, newKey }
  })

  return response.data.renameFileFolder
}

export const createLink = async (toggle: boolean, data: LinkData, userId: string) => {
  const { name, url } = data
  const response = await client.mutate({
    mutation: CREATE_LINK,
    variables: { name, url, userId: !toggle ? userId : undefined }
  })

  return response.data.createLink
}

export const deleteLinks = async (ids: string[]) => {
  const response = await client.mutate({
    mutation: DELETE_LINK,
    variables: { ids }
  })

  return response.data.deleteLinks
}

export const updateLink = async (id: string, data: LinkData) => {
  const { name, url } = data
  const response = await client.mutate({
    mutation: UPDATE_LINK,
    variables: { _id: id, name, url }
  })

  return response.data.updateLink
}

export const setupNewWebsite = async () => {
  const response = await client.mutate({
    mutation: SETUP_NEW_WEBSITE
  })

  return response
}
