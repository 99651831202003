// Import Packages
import * as React from 'react'
import { Grid } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import { SetCustomeColor } from 'app/Dashboard/Dashboard'

// Import Colors
import Colors from 'design/Colors'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  ColorImage,
  Customize,
  CustomizeTitle,
  PickColorImage,
  StyledButton,
  StyledChromePicker,
  StyledColor,
  StyledColorGrid,
  StyledFontAwesomeIcon,
  StyledPopup
} from './Styled'

import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faTimesCircle])

interface Props {
  defaultColor: (color: string) => void
  addNewColor: (color: string) => void
  deleteColor: (color: string) => void
  color: string[]
}

interface State {
  background: string
  showColorPickerModal: boolean
}

class PickColor extends React.Component<Props, State> {
  public state = {
    background: '',
    showColorPickerModal: false
  }

  public render() {
    const { color } = this.props
    const { showColorPickerModal, background } = this.state
    const colorArr = color.map((items: string, index: number) => {
      return (
        <Grid.Column key={index}>
          <span onClick={() => this.deleteColor(items)}>
            <StyledFontAwesomeIcon icon={['fas', 'times-circle']} />
          </span>
          {items === 'rgb(undefined, undefined, undefined, 0.5)' ? (
            <StyledColor color={Colors.Black50} onClick={() => this.setCustomeColor(items)} />
          ) : (
            <StyledColor color={items} onClick={() => this.setCustomeColor(items)} />
          )}
        </Grid.Column>
      )
    })
    return (
      <div>
        <CustomizeTitle>
          <ColorImage />
          <span>{Strings.dashboard.colors}</span>
        </CustomizeTitle>
        <Customize>
          <StyledColorGrid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <StyledPopup
                  trigger={<PickColorImage />}
                  hoverable={true}
                  open={showColorPickerModal}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  position="right center"
                >
                  <StyledChromePicker color={background} onChangeComplete={this.handleChangeComplete} />
                  <StyledButton onClick={this.setrgbColor}>{Strings.dashboard.ok}</StyledButton>
                </StyledPopup>
              </Grid.Column>
              {colorArr}
            </Grid.Row>
          </StyledColorGrid>
        </Customize>
      </div>
    )
  }

  private deleteColor = (color: string) => {
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this color!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        const { deleteColor } = this.props
        deleteColor(color)
        ConfirmAlert('Deleted!', 'Your color has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your color is safe', 'error')
      }
    })
  }

  private handleOpen = () => {
    this.setState({ showColorPickerModal: true })
  }

  private handleClose = () => {
    this.setState({ showColorPickerModal: false })
  }

  private setrgbColor = () => {
    const { addNewColor } = this.props
    const { background } = this.state
    const color = `rgb(${background['r']}, ${background['g']}, ${background['b']}, 0.5)`
    addNewColor(color)
    this.setState({ showColorPickerModal: false })
  }

  private setCustomeColor = (color: string) => {
    const { defaultColor } = this.props
    defaultColor(color)
    SetCustomeColor(color)
  }

  private handleChangeComplete = (color: any) => {
    this.setState({ background: color.rgb })
  }
}

export default PickColor
