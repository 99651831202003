import moment from 'moment'
import * as React from 'react'

import {
  Container,
  DateItem,
  Title,
} from './Styled'

import {
  TransactionType,
  CommissionType,
} from 'app/Transactions/Details/Types'

interface Props {
  commission: CommissionType
  transaction: TransactionType
}

class Dates extends React.Component<Props, {}> {
  public render = () => {
    const {
      commission,
      transaction,
    } = this.props

    return (
      <Container>
        <Title>Important Dates</Title>
        <DateItem>
          <span>Contract Date:</span>
          <strong>{moment(transaction.createdAt).utc().format('LL')}</strong>
        </DateItem>
        <DateItem>
          <span>Created Date:</span>
          <strong>{moment(transaction.createdAt).utc().format('LL')}</strong>
        </DateItem>
        {moment(commission.closingDate).utc().format('LL') !== 'Invalid date' && (
          <DateItem>
            <span>Closing Date:</span>
            <strong>{moment(commission.closingDate).format('LL')}</strong>
          </DateItem>
        )}
      </Container>
    )
  }
}

export default Dates
