export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`office`]) {
    formIsValid = false
    errors[`office`] = 'Can Not be empty'
  }

  if (!fields[`year`]) {
    formIsValid = false
    errors[`year`] = 'Can Not be empty'
  }

  return { errors, formIsValid }
}
