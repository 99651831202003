import Colors from 'design/Colors'
import { Form, Search } from 'semantic-ui-react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

const LeftPanel = styled.div`
  width: 35%;
  height: auto;
  background-color: ${Colors.Grey110};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${Colors.Black500};

  .ui.small.image {
    width: 150px;
  }
`

const StyledDirectForm = styled(Form)`
  &.ui.form .field > label {
    color: ${Colors.Black500};
  }

  &.ui.form {
    padding-left: 30px;
    padding-right: 70px;
    width: 100%;
  }

  &.ui.form .field {
    font-size: 12px;
  }

  .ui.input {
    width: 220px;
  }

  .ui.icon.input > i.icon {
    font-size: 8px;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 30px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

const RightPanel = styled.div`
  padding-top: 25px;
  padding-left: 25px;
`

const StyledSearch = styled(Search)`
  &.ui.search {
    width: 100%;
  }

  .ui.icon.input > i.icon {
    display: none;
  }

  .ui.input {
    height: 30px;
    margin-bottom: 10px;
  }
`

const StyledLabel = styled.label`
  font-weight: 700;
  margin: 0 0 0.28571429rem 0;
  font-size: 11px;
  color: darkslategrey;
`

const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 10px;
  color: ${Colors.Black500};
`

export { Container, LeftPanel, RightPanel, StyledDirectForm, StyledLabel, StyledSearch, Title }
