// Import Packages
import * as React from 'react'

// Import Images and Icons
import SortIcon from 'design/icons/offices/contact_icon_4.png'

// Import Components
import { sortOffices } from '../Offices'
import ActionItem from './ActionItem'

// Import Utils
import { Strings } from 'utils'

interface Props {
  boardId: string
}

class Actions extends React.Component<Props>{
  public state = {
    sortOrder: 1
  }

  public render() {
    return (
      <div>
        <span onClick={this.handleChange}>
          <ActionItem title={Strings.kanbanView.sort} text={Strings.kanbanView.sortAction} icon={SortIcon} />
        </span>
      </div>
    )
  }

  private handleChange = () => {
    const { sortOrder } = this.state
    const { boardId } = this.props
    if (sortOrder === 1) {
      this.setState({ sortOrder: -1 })
      sortOffices(boardId, this.state.sortOrder)
    } else {
      this.setState({ sortOrder: 1 })
      sortOffices(boardId, this.state.sortOrder)

    }
  }
}

export default Actions
