import Colors from 'design/Colors'
import * as React from 'react'
import { Grid } from 'semantic-ui-react'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import { VictoryPie, VictoryTheme } from 'victory'
import {
  Column,
  Content,
  Cost,
  FooterLabel,
  FooterValue,
  Graph,
  GraphSection,
  Section,
  StyledGrid,
  StyledTable,
  StyledTableFooter,
  TotalCommissionText,
  VictoryLegend,
} from './Styled'

// Font Awesome Icons
import {
  faRectangleLandscape
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faRectangleLandscape])


const GraphSectionComponent = ({
  commissionFee,
  expectedCommission,
  otherFee,
  propertyCost,
  propertyFee,
  title,
  totalFee
}: {
  commissionFee: number
  expectedCommission: number
  otherFee: number,
  propertyCost: number,
  propertyFee: number
  title: string
  totalFee: number
}) => {
  const data = [
    { x: 1, y: propertyFee, label: '' },
    { x: 2, y: commissionFee, label: '' },
    { x: 3, y: otherFee, label: '' },
    { x: 4, y: totalFee, label: '' }
  ]
  const legend = [
    { color: Colors.Orange125, name: 'Commercial Fee' },
    { color: Colors.Green600, name: 'Residential Fee' },
    { color: Colors.Red35, name: 'Commission Fee' },
    { color: Colors.Black60, name: 'Other Fee' },
  ]
  return <GraphSection>
    <Header as='h3' textAlign='center'>{title}</Header>
    <Content horizontal={'true'}>
      <Graph>
        <VictoryPie
          data={data}
          height={170}
          colorScale={[Colors.Green600, Colors.Orange125, Colors.Black60, Colors.Orange150]}
          innerRadius={40}
          theme={VictoryTheme.material}
          padding={{ top: 0, bottom: 10 }}
          style={{ labels: { fontSize: 20, justifyContent: 'center', display: 'none' } }} />
        <Section compact={true} size={'big'}>
          <TotalCommissionText>Total Commission</TotalCommissionText>
          <TotalCommissionText>{`$ ${expectedCommission.toLocaleString()}`}</TotalCommissionText>
        </Section>
      </Graph>
      <Column>
        <Cost>Hypothetical {`$${propertyCost.toLocaleString()}`} Price</Cost>
        <StyledTable basic={'very'}>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Transaction Fee:</Table.Cell>
              <Table.Cell style={{ fontWeight: 600 }}>{`-$${propertyFee.toLocaleString()}`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Commission Fee:</Table.Cell>
              <Table.Cell style={{ fontWeight: 600 }}>{`- $${commissionFee.toLocaleString()}`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Other Fees:</Table.Cell>
              <Table.Cell style={{ fontWeight: 600 }}>{`- $${otherFee.toLocaleString()}`}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </StyledTable>
        <StyledTableFooter className={'tfoot'}>
          <FooterLabel>Total : </FooterLabel>
          <FooterValue>{`$${totalFee.toLocaleString()}`}</FooterValue>
        </StyledTableFooter>
      </Column>
    </Content>
    <StyledGrid>
      <Grid.Row columns={2}>
        {
          title === 'Commercial Fee' &&
          legend.map((items: any, index: number) => (
            <Grid.Column key={index}>
              <VictoryLegend >
                <FontAwesomeIcon icon={['fas', 'rectangle-landscape']} style={{ color: items.color }} />
                <span>{items.name}</span>
              </VictoryLegend>
            </Grid.Column>
          ))
        }
      </Grid.Row>
    </StyledGrid>

  </GraphSection>
}

export default GraphSectionComponent
