// Import Graphql Queries
import {
  GET_FEED_NOTIFICATION,
  GET_PUBLIC_NOTIFICATION,
  GET_UNREAD_FEED_NOTIFICATION,
  GET_UNREAD_FEED_PUBLIC_NOTIFICATION,
  GET_UNREAD_PUBLIC_NOTIFICATION
} from 'queries/graphql/Notification/Queries'

// Import Components
import client from 'queries/apollo'

// Public Notification
export const getPublicNotifications = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_PUBLIC_NOTIFICATION
  })

  return response.data.getPublicNotifications
}

export const getUnreadPublicNotificationCount = async (id: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_UNREAD_PUBLIC_NOTIFICATION,
    variables: { id }
  })

  return response.data.getUnreadPublicNotificationCount
}

// Feed Notification
export const getFeedNotifications = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_FEED_NOTIFICATION
  })

  return response.data.getFeedNotifications
}

export const getUnreadFeedNotificationCount = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_UNREAD_FEED_NOTIFICATION
  })

  return response.data.getUnreadFeedNotificationCount
}

export const getUnreadFeedPublicNotificationCount = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_UNREAD_FEED_PUBLIC_NOTIFICATION
  })

  return response.data.getUnreadFeedPublicNotificationCount
}
