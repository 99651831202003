// Import Packages
import { debounce } from 'lodash'
import * as React from 'react'
import { Form, Icon, Input, Menu, Select } from 'semantic-ui-react'

// Import Components
import AddressInput from 'shared/AddressInput'
import StateDropdown from 'shared/StateDropdown'
import { FormErrorType, FormType } from './Types'

// Import Store Types, Actions and Reducers
import { FiltersData, FilterText, Options } from 'store/Users/Types'

// Import Utils
import { Strings } from 'utils'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'

// Import Styled Components
import {
  CloseWrapper,
  Container,
  Filter,
  Filters,
  FiltersTitle,
  PopUpContent,
  StyledContainer,
  StyledForm,
  StyledInput,
  StyledMenu
} from './Styled'

const filters = [
  { id: 'User Name', name: 'User Name', text: 'name' },
  { id: 'Office Name', name: 'Office Name', text: 'officeName' },
  { id: 'Status', name: 'Status', text: 'status' },
  { id: 'Email Contains', name: 'Email Contains', text: 'email' },
  { id: 'Address', name: 'Address', text: 'address' }
]

const statusOptions = [
  { key: 1, text: 'Active', value: 'Active' },
  { key: 2, text: 'Inactive', value: 'Inactive' },
  { key: 3, text: 'Pending', value: 'Pending' }
]

interface Props {
  filtersContent: (id: string, name: string, text: string, searchData: FiltersData) => void
  closeAdvanceSearchModal: () => void
  filter: (data: FiltersData, state: Options, city: Options) => void
  data: FiltersData
  filterText: FilterText[]
  selectedCity: Options
  selectedState: Options
}

interface State {
  form: FormType
  activeItem: string
  searchData: FiltersData
  cities: Options[]
  states: Options[]
  selectedCity: Options
  selectedState: Options
}

class UserSearchModal extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    activeItem: '',
    cities: [],
    form: {
      city: '',
      errors: {} as FormErrorType,
      state: '',
      streetAddress: '',
      type: '',
      zipCode: ''
    } as FormType,
    searchData: {
      city: '',
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      officeName: undefined,
      state: '',
      status: undefined,
      zipCode: ''
    },
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: []
  }

  public async componentDidMount() {
    const { data, selectedState, selectedCity } = this.props
    this.setState({ searchData: data, selectedState, selectedCity })
  }

  public componentWillReceiveProps(nextProps: any) {
    const { filterText } = this.props
    const lastElement = nextProps.filterText.slice(-1)[0]
    if (lastElement !== undefined) {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: lastElement.content })
      }
    } else {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: '' })
      }
    }
  }

  public render() {
    const { activeItem, searchData } = this.state
    return (
      <StyledContainer>
        <CloseWrapper onClick={this.closeAdvanceSearchModal} />
        <PopUpContent>
          <Container>
            <Filters>
              <FiltersTitle>{Strings.advanceSearchModal.filters}</FiltersTitle>
              <StyledMenu vertical={true}>
                {filters.map((items: any, index: number) => (
                  <Menu.Item
                    key={index}
                    name={items.name}
                    id={items.id}
                    text={items.text}
                    active={activeItem === items.name}
                    onClick={this.handleItemClick}
                  />
                ))}
              </StyledMenu>
            </Filters>
            <StyledForm size={'tiny'}>
              {activeItem === '' && (
                <Filter>
                  <Icon name="filter" />
                  <div>Select Filter</div>
                </Filter>
              )}
              {activeItem === 'User Name' && (
                <div>
                  <Form.Field
                    control={Input}
                    label="First Name"
                    name="firstName"
                    value={searchData.firstName}
                    placeholder="First Name"
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    control={Input}
                    label="Last Name"
                    name="lastName"
                    value={searchData.lastName}
                    placeholder="Last Name"
                    onChange={this.handleChange}
                  />
                </div>
              )}
              {activeItem === 'Office Name' && (
                <Form.Field
                  control={Input}
                  label="Office Name"
                  name="officeName"
                  value={searchData.officeName}
                  placeholder="Office Name"
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Status' && (
                <Form.Field
                  control={Select}
                  label="Status"
                  name="status"
                  placeholder="Status"
                  value={searchData.status}
                  options={statusOptions}
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Email Contains' && (
                <Form.Field
                  control={Input}
                  label="Email Contains"
                  name="email"
                  value={searchData.email}
                  placeholder="Email Contains"
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Address' && (
                <div>
                  <Form.Field childWidths={33.334} className="algolia">
                    <AddressInput
                      address={{
                        city: searchData.city,
                        state: searchData.state,
                        streetAddress: '',
                        streetName: '',
                        zipCode: searchData.zipCode
                      }}
                      mode={AddressModeEnum.City}
                      onChange={this.handleCityChange}
                      placeholder="City"
                    />
                    <StateDropdown
                      name="state"
                      onChange={this.handleInputChange}
                      placeholder="State"
                      value={searchData.state}
                    />
                    <StyledInput
                      className="zipcode"
                      type="text"
                      placeholder="Zip Code"
                      name="zipCode"
                      value={searchData.zipCode}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                </div>
              )}
            </StyledForm>
          </Container>
        </PopUpContent>
      </StyledContainer>
    )
  }

  private handleCityChange = (address: AddressType) => {
    const { form } = this.state
    form.city = address.city
    form.state = address.state
    form.zipCode = address.zipCode
    this.setState(
      {
        form,
        searchData: {
          ...this.state.searchData,
          city: address.city,
          state: address.state,
          zipCode: address.zipCode
        }
      },
      () => this.getUsersBoardsData()
    )
  }

  private handleInputChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    form.errors[name] = ''
    this.setState(
      {
        form,
        searchData: {
          ...this.state.searchData,
          city: form.city,
          state: form.state,
          zipCode: form.zipCode
        }
      },
      () => this.getUsersBoardsData()
    )
  }

  private closeAdvanceSearchModal = () => {
    const { closeAdvanceSearchModal } = this.props
    closeAdvanceSearchModal()
  }

  private handleItemClick = (e: React.SyntheticEvent<EventTarget>, { id, name, text }: any) => {
    const { filtersContent } = this.props
    const { searchData } = this.state
    this.setState({ activeItem: name })
    filtersContent(id, name, text, searchData)
  }

  private getUsersBoardsData = async () => {
    const { filter } = this.props
    const { searchData, selectedCity, selectedState } = this.state

    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(() => {
      filter(searchData, selectedState, selectedCity)
    }, 1000)

    this.debounceJob()
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { searchData } = this.state
    searchData[name] = value
    this.setState({ searchData }, () => this.getUsersBoardsData())
  }
}

export default UserSearchModal
