// Import Packages
import * as React from 'react'
import InputMask from 'react-input-mask'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'

// Import Components
import AddressInput from 'shared/AddressInput'
import LoadingIndicator from 'shared/LoadingIndicator'
import StateDropdown from 'shared/StateDropdown'
import Modal from 'shared/Modal'
import { getLeadBoards } from '../LeadQueries'
import { toggle } from '../Leads'
import { handleValidation } from './Validation'

import { LeadItemsDetails } from 'store/Leads/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import { getOrCreateCityState } from 'shared/AddressInput/Mutations'

import { AddForm, AddLeftPanel, Container, StyledForm, StyledGroup } from './Styled'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'

import { FormErrorType, FormType, OptionType } from './Types'

export interface Props {
  closeModal: () => void
  addLead: (newLeadData: FormType) => void
  addBoardId?: string
  edit: boolean
  editDetails: LeadItemsDetails | any
  leadPool?: boolean
}

interface State {
  boardOptions: OptionType[]
  cityOptions: OptionType[]
  disableTypeSelection: boolean
  form: FormType
  transition: boolean
  loading: string
}

const propertyTypeOptions = [
  { key: 'Residential', value: 'Residential', text: 'Residential' },
  { key: 'Commercial', value: 'Commercial', text: 'Commercial' }
]

const SourceOptions = [
  { key: 1, value: 'Call', text: 'Call' },
  { key: 2, value: 'Email', text: 'Email' },
  { key: 3, value: 'Trade', text: 'Trade' },
  { key: 4, value: 'Referral', text: 'Referral' },
  { key: 5, value: 'Seminar', text: 'Seminar' },
  { key: 6, value: 'Website', text: 'Website' },
  { key: 7, value: 'Third_Party', text: 'Third Party' }
]

class AddLeadModal extends React.Component<Props, State> {
  public state = {
    boardOptions: [] as OptionType[],
    cityOptions: [] as OptionType[],
    disableTypeSelection: false,
    form: {
      errors: {} as FormErrorType
    } as FormType,
    loading: '',
    transition: true
  }

  public async componentDidMount() {
    const { addBoardId, editDetails, edit } = this.props
    this.setState({ loading: edit ? 'Gathering data...' : '' })
    const boardOptions = [] as OptionType[]
    let disableTypeSelection = false
    const form = {
      ...editDetails,
      city: editDetails.city ? editDetails.city.name : undefined,
      errors: {} as FormErrorType,
      state: editDetails.state ? editDetails.state.name : undefined
    }
    const user: any = await getLoggedInUser({ fromCache: true })

    let leadBoards: any = null
    if (toggle) {
      leadBoards = await getLeadBoards(undefined)
    } else {
      leadBoards = await getLeadBoards(undefined, user[`_id`])
    }

    if (leadBoards) {
      leadBoards.forEach((board: any) => {
        const option: OptionType = {
          key: board._id,
          text: board.name,
          value: board._id
        }
        if (board._id === addBoardId) {
          disableTypeSelection = true
          form.tenantBoard = option.value
          form.type = option.text
        }
        if (Object.keys(editDetails).length > 0 && editDetails.type === board.name) {
          disableTypeSelection = false
          form.tenantBoard = option.value
          form.type = option.text
        }
        boardOptions.push(option)
      })
    }

    this.setState({
      boardOptions,
      disableTypeSelection,
      form,
      loading: ''
    })
  }

  public render() {
    const { edit, leadPool } = this.props
    const {
      disableTypeSelection,
      form,
      form: { errors },
      transition,
      boardOptions,
      loading
    } = this.state

    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loading} />}
            <AddLeftPanel>
              <Image src={edit ? Strings.leads.addLeadSrc1 : Strings.leads.addLeadSrc} size="small" />
            </AddLeftPanel>
            <AddForm>
              <StyledForm size={'mini'}>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                    onChange={this.handleInputChange}
                    value={form.firstName}
                    error={!!errors.firstName}
                  />
                  <Form.Field
                    control={Input}
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                    onChange={this.handleInputChange}
                    value={form.lastName}
                    error={!!errors.lastName}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="Email"
                    name="email"
                    disabled={edit ? true : false}
                    placeholder="Email"
                    onChange={this.handleInputChange}
                    value={form.email}
                    error={!!errors.email}
                  />
                  <Form.Field
                    control={Input}
                    label={
                      <div
                        style={{
                          color: Colors.Black500,
                          fontSize: '.92857143em',
                          fontWeight: 'bold',
                          marginBottom: '4px'
                        }}
                      >
                        {Strings.leads.phone}
                        <span
                          style={{
                            color: Colors.Black550,
                            fontSize: '10px',
                            fontStyle: 'italic',
                            marginLeft: '5px'
                          }}
                        >
                          {Strings.leads.optional}
                        </span>
                      </div>
                    }
                  >
                    <InputMask
                      mask="(999) 999-9999"
                      name="phone"
                      placeholder="(xxx) xxx-xxxx"
                      onChange={this.handlePhoneChange}
                      value={form.phone}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Select}
                    label="Property Type"
                    name="propertyType"
                    placeholder="Property Type"
                    options={propertyTypeOptions}
                    onChange={this.handleInputChange}
                    value={form.propertyType}
                    error={!!errors.propertyType}
                  />
                  <Form.Field
                    control={Select}
                    label="Type"
                    name="tenantBoard"
                    placeholder="Type"
                    disabled={disableTypeSelection}
                    value={form.tenantBoard}
                    options={boardOptions}
                    onChange={this.handleBoardChange}
                    error={!!errors.tenantBoard}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Select}
                    label="Source"
                    name="source"
                    placeholder="Source"
                    options={SourceOptions}
                    onChange={this.handleInputChange}
                    value={form.source}
                    error={!!errors.source}
                  />
                  {leadPool && (
                    <Form.Field
                      control={Input}
                      onChange={this.handleInputChange}
                      label="Referral Fee"
                      icon="percent"
                      name="referralFee"
                      iconPosition="right"
                      value={form.referralFee}
                      error={!!errors.referralFee}
                    />
                  )}
                </Form.Group>
                {leadPool && (
                  <StyledGroup widths="equal">
                    <div className="field">
                      <label>City</label>
                      <AddressInput
                        address={{
                          city: form.city,
                          state: form.state,
                          streetAddress: '',
                          streetName: '',
                          zipCode: form.zipCode
                        }}
                        mode={AddressModeEnum.City}
                        onChange={this.handleCityChange}
                        placeholder="City"
                        size="mini"
                      />
                    </div>
                    <div className="field">
                      <label>State</label>
                      <StateDropdown
                        placeholder="State"
                        name="state"
                        value={form.state}
                        onChange={this.handleInputChange}
                        error={!!errors.state}
                      />
                    </div>
                    <Form.Field
                      control={Input}
                      label="ZipCode"
                      name="zipCode"
                      placeholder="ZipCode"
                      onChange={this.handleInputChange}
                      value={form.zipCode}
                      error={!!errors.zipCode}
                    />
                  </StyledGroup>
                )}
                <div style={{ color: 'red' }}>{errors.email !== undefined ? `Email : ${errors.email}` : ''} </div>
                <Button type="button" onClick={this.handleSubmit} content={'SAVE'} />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={700}
      />
    )
  }

  private handlePhoneChange = (e: any) => {
    const { form } = this.state
    form.phone = e.target.value
    this.setState({ form })
  }

  private handleCityChange = (address: AddressType) => {
    const { form } = this.state
    form.city = address.city
    form.state = address.state
    form.zipCode = address.zipCode
    this.setState({ form })
  }

  private handleBoardChange = (e: React.SyntheticEvent<EventTarget>, { name, value, text }: any) => {
    const { form, disableTypeSelection } = this.state
    if (disableTypeSelection) {
      return
    }
    form.type = text
    form.tenantBoard = value
    this.setState({ form })
  }

  private handleInputChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private handleSubmit = async () => {
    const { addLead } = this.props
    const {
      form,
      form: { city, state }
    } = this.state
    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    const address = await getOrCreateCityState({ city, state })
    form.city = address.city._id
    form.state = address.state._id

    try {
      await addLead(form)
      this.closeModal()
    } catch (error) {
      form.errors.email = 'Email already exists'
      this.setState({ form })
    }
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default AddLeadModal
