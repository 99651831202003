import jwtDecode from 'jwt-decode'

import client from 'queries/apollo'

import { GET_MLS } from 'queries/graphql/Mls/Queries'
import { GET_CONCIERGE_SETTINGS } from 'queries/graphql/Concierge/Queries'
import {
  GET_RECAPTCHA_SITE_KEY,
  GET_TOKEN_USER
} from 'queries/graphql/Token/Queries'

import { PassStatusEnum, PassTypeEnum } from 'store/Pass/Types'
import {
  ConciergePassType,
  DocTemplateType,
  IdxByMlsType,
  IdxPassType,
  OfficeByIdType,
  OfficeType,
  SettingsType,
  TemplatesByMlsType
} from './Types'

export const getRecaptchaSiteKey = async () => {
  const response = await client.query({
    query: GET_RECAPTCHA_SITE_KEY
  })

  return response.data.getRecaptchaSiteKey
}

export const getTokenUser = async (email: string, recaptchaToken: string) => {
  const response = await client.query({
    query: GET_TOKEN_USER,
    variables: { email, recaptchaToken }
  })

  return response.data.getTokenUser
}

export const getConciergeSettings = async (token: string) => {
  const result: SettingsType = {
    conciergeOffices: [] as OfficeType[],
    conciergePass: {} as ConciergePassType,
    enabledIdx: {} as IdxByMlsType,
    enabledOffices: {} as OfficeByIdType,
    enabledTemplates: {} as TemplatesByMlsType,
    idxOffices: [] as OfficeType[],
    idxPasses: [] as IdxPassType[]
  }
  let decodedToken: any = null
  try {
    decodedToken = jwtDecode(token)
  } catch {
    return result
  }

  const response = await client.query({
    context: {
      headers: {
        authorization: token
      }
    },
    fetchPolicy: 'network-only',
    query: GET_CONCIERGE_SETTINGS,
    variables: {
      conciergePassWhere: {
        isActive: true,
        passType: PassTypeEnum.Concierge
      },
      docTemplateWhere: { isActive: true },
      idxPassWhere: {
        custom: {
          passSection: { $exists: false },
          'settings.mls': { $exists: true, $ne: '' },
          'settings.status': PassStatusEnum.Active
        },
        isActive: true,
        passType: PassTypeEnum.Idx
      },
      product: 'concierge',
      userWhere: { _id: decodedToken.uid }
    }
  })

  if (response.data.isAllowed) {
    result.isAllowed = true
  }

  let offices = [] as OfficeType[]
  if (response.data.user) {
    offices = response.data.user[0].office
  }

  if (response.data.conciergePasses.results) {
    result.conciergePass = response.data.conciergePasses.results[0]
  }

  if (response.data.idxPasses.results) {
    result.idxPasses = response.data.idxPasses.results

    result.enabledIdx = result.idxPasses.reduce(
      (obj: IdxByMlsType, current: IdxPassType) => {
        obj[current.settings.mls._id] = current
        return obj
      },
      {} as IdxByMlsType
    )

    result.idxOffices = offices.filter((item: OfficeType) => {
      const isEnabled = result.enabledIdx[item.mls._id]
      if (isEnabled) {
        result.enabledOffices[item._id] = {
          ...item,
          idxEnabled: true
        }
      }
      return isEnabled
    })
  }

  if (response.data.docTemplates) {
    const docTemplates: any = response.data.docTemplates.filter(
      (item: DocTemplateType) => item.template
    )

    result.enabledTemplates = docTemplates.reduce(
      (obj: TemplatesByMlsType, current: DocTemplateType) => {
        if (obj[current.mls._id]) {
          obj[current.mls._id].push(current)
        } else {
          obj[current.mls._id] = [current]
        }
        return obj
      },
      {} as TemplatesByMlsType
    )

    result.conciergeOffices = offices.filter((item: OfficeType) => {
      const isEnabled = result.enabledTemplates[item.mls._id]
      if (isEnabled) {
        if (result.enabledOffices[item._id]) {
          result.enabledOffices[item._id].conciergeEnabled = true
        } else {
          result.enabledOffices[item._id] = {
            ...item,
            conciergeEnabled: true
          }
        }
      }
      return isEnabled
    })
  }

  return result
}

export const getMls = async (id: string, token: string) => {
  const response = await client.query({
    context: {
      headers: {
        authorization: token
      }
    },
    query: GET_MLS,
    variables: { id }
  })

  return response.data.getMls
}
