// Import Utils
import { generateID } from 'utils'

export const menuMockData = [
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'tachometer-alt-fast',
        route: 'dashboard',
      },
      {
        _id: generateID(),
        icon: 'folders',
        route: 'transactions',
      },
      {
        _id: generateID(),
        icon: 'handshake',
        route: 'leads',
      },
      {
        _id: generateID(),
        icon: 'whatsapp',
        route: 'contacts',
      },
      {
        _id: generateID(),
        icon: 'clone',
        route: 'tasks',
      },
    ],
    menu: 'CRM',
  },
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'calendar-alt',
        route: 'events',
      },
      {
        _id: generateID(),
        icon: 'file-invoice',
        route: 'reports',
      },
      {
        _id: generateID(),
        icon: 'envelope',
        route: 'mailbox',
      },
      {
        _id: generateID(),
        icon: 'file-alt',
        route: 'Documents',
      },
      {
        _id: generateID(),
        icon: 'link',
        route: 'Links',
      }
    ],
    menu: 'Productivity',
  },
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'cog',
        route: 'settings',
      },
      {
        _id: generateID(),
        icon: 'user-circle',
        route: 'profile',
      },
      {
        _id: generateID(),
        icon: 'user',
        route: 'users',
      },
      {
        _id: generateID(),
        icon: 'users',
        route: 'teams',
      },
      {
        _id: generateID(),
        icon: 'building',
        route: 'offices',
      },
    ],
    menu: 'Management',
  }
]


export const menuMockDataForGuest = [
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'tachometer-alt-fast',
        route: 'dashboard',
      },
      {
        _id: generateID(),
        icon: 'folders',
        route: 'transactions',
      },
      {
        _id: generateID(),
        icon: 'handshake',
        route: 'leads',
      },
      {
        _id: generateID(),
        icon: 'whatsapp',
        route: 'contacts',
      },
      {
        _id: generateID(),
        icon: 'clone',
        route: 'tasks',
      },
    ],
    menu: 'CRM',
  }
]

export const menuMockDataForAgent = [
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'tachometer-alt-fast',
        route: 'dashboard',
      },
      {
        _id: generateID(),
        icon: 'folders',
        route: 'transactions',
      },
      {
        _id: generateID(),
        icon: 'handshake',
        route: 'leads',
      },
      {
        _id: generateID(),
        icon: 'whatsapp',
        route: 'contacts',
      },
      {
        _id: generateID(),
        icon: 'clone',
        route: 'tasks',
      },
    ],
    menu: 'CRM',
  },
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'calendar-alt',
        route: 'events',
      },
      {
        _id: generateID(),
        icon: 'file-invoice',
        route: 'reports',
      },
      {
        _id: generateID(),
        icon: 'envelope',
        route: 'mailbox',
      },
      {
        _id: generateID(),
        icon: 'envelope',
        route: 'Documents',
      },
      {
        _id: generateID(),
        icon: 'envelope',
        route: 'Links',
      }
    ],
    menu: 'Productivity',
  },
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'cog',
        route: 'settings',
      },
      {
        _id: generateID(),
        icon: 'user-circle',
        route: 'profile',
      },
    ],
    menu: 'Management',
  }
]

export const menuMockDataForAgentInvite = [
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'tachometer-alt-fast',
        route: 'dashboard',
      },
      {
        _id: generateID(),
        icon: 'folders',
        route: 'transactions',
      },
      {
        _id: generateID(),
        icon: 'handshake',
        route: 'leads',
      },
      {
        _id: generateID(),
        icon: 'whatsapp',
        route: 'contacts',
      },
      {
        _id: generateID(),
        icon: 'clone',
        route: 'tasks',
      },
    ],
    menu: 'CRM',
  },
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'calendar-alt',
        route: 'events',
      },
      {
        _id: generateID(),
        icon: 'file-invoice',
        route: 'reports',
      },
      {
        _id: generateID(),
        icon: 'envelope',
        route: 'mailbox',
      },
      {
        _id: generateID(),
        icon: 'envelope',
        route: 'Documents',
      },
      {
        _id: generateID(),
        icon: 'envelope',
        route: 'Links',
      }
    ],
    menu: 'Productivity',
  },
  {
    _id: generateID(),
    items: [
      {
        _id: generateID(),
        icon: 'cog',
        route: 'settings',
      },
      {
        _id: generateID(),
        icon: 'user-circle',
        route: 'profile',
      },
      {
        _id: generateID(),
        icon: 'user',
        route: 'users',
      },
    ],
    menu: 'Management',
  }
]

