// Import Packages
import { Button, Card, Popup, Progress } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background?: string
  left?: number
  color?: string
}

const Container = styled.div`
  min-height: 15px;

  .ui.divider {
    width: 90%;
    align-self: center;
    margin-bottom: 0;
    margin-top: 0;
  }

  .ui.divider:not(.vertical):not(.horizontal) {
    border-top: 2px solid rgba(34,36,38,.15);
  }
`

const CardWrapper = styled(Card)`
  &.ui.card {
    margin: 0 auto;
    border-radius: 0px;
    width: 95%;
    background: ${Colors.White1000};
    border: 1px solid ${Colors.Blue910};
  }
`

const CardContent = styled(Card.Content)`
  display: flex;
  padding-bottom: 6px !important;
  padding: 1em 1em;

  .ui.small.image {
    width: 60px;
    height: 65px;
    object-fit: cover;
  }
`

const ExtraContent = styled.div`
  font-size: 12px;
  display: flex;
  padding: 5px 20px 15px 20px;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
`

const Label = styled.div`
  width: 20px;
  height: 20px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
`

const StyledLabel = styled.div`
  ${y};
  height: 100%;
  color: ${Colors.DarkBlue220};
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`

const Content = styled.div`
  margin-left: 8px;
  color: ${Colors.Black550};

  .fa-map-marker-alt {
    margin-right: 6px;
    width: 9px;
    margin-top: 4px;
  }
`

const TransactionName = styled.span`
  font-size: 14px;
  color: ${Colors.Black500};
  font-weight: bold;
  line-height: 1.2;
  text-transform: capitalize;
`

const Address = styled.div`
  font-size: 12px;
  margin-top: 4px;
  line-height: 15px;
  text-transform: 'capitalize';
`

const MLSId = styled.div`
  font-size: 12px;
`

const CreatedDate = styled.div`
  font-size: 12px;
  font-style: italic;
  margin-top: 5px;
`

const Price = styled.div`
  color: ${Colors.Green60};
  font-size: 16px;
  font-weight: bold;
  flex-grow: 1;
  white-space: nowrap;
`

const StyledProgress = styled(Progress)`
  width: 90%;

  &.ui.progress {
    height: 2px;
    margin-bottom: 0;
    margin-top: 0;
    margin: 0 auto;
  }

  &.ui.progress .bar {
    height: 2px;
    color: ${Colors.Black50};
    background: ${(props: Props) => props.background || props.background}
  }

`

const Avatar = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
  margin-right: 8px;
`

const Tag = styled(Button)`
  position: absolute;
  top: 20px;
  left: 20px;

  &.ui.button {
    padding: 0px;
    width: 35px;
    height: 16px;
    font-size: 10px;
    border-radius: 10px;
    color: ${(props: Props) => props.background || props.background};
    background: ${Colors.White1000};
  }

  &.ui.button:hover {
    color: ${(props: Props) => props.background || props.background};
    background: ${Colors.White1000};
  }
`

const Profile = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black500};
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
`

const Ul = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  margin-right: 8px;
`

const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
`

export {
  Address,
  Avatar,
  CardContent,
  CardWrapper,
  Container,
  Content,
  CreatedDate,
  ExtraContent,
  Label,
  MLSId,
  Price,
  StyledLabel,
  StyledProgress,
  Tag,
  TransactionName,
  Profile,
  Li,
  StyledPopup,
  MemberProfile,
  Labels,
  Ul
}
