// Import Packages
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Fonts, Colors
import Colors from 'design/Colors'

interface Props {
  vertical?: boolean
  total: number
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: ${(props: Props) => (props.vertical ? 'column' : 'row')};
  flex-wrap: wrap;
  height: 100%;
  font-size: 120%;

  div {
    max-height: 2em;
    ${(props: Props) => {
      if (props.vertical) {
        return `height: ${100 / props.total}%;`
      }
      return ''
    }};
  }
`

export const Action = styled.div`
  display: flex;
  padding: 0.25em 0.5em;
  color: ${Colors.Black550};
  transform: scale3d(0.9, 0.9, 0.9);
  transition: transform, color 200ms;
  cursor: pointer;

  .svg-inline--fa {
    height: 100%;
  }

  &:hover {
    color: ${Colors.Black500};
    transform: scale3d(1, 1, 1);
  }
`

export const SubAction = styled(Action)`
  transform: none;
  align-items: center;
  justify-content: flex-start;
  span {
    display: flex;
    justify-content: center;
    width: 1em;
    margin-right: 0.5em;
  }
`

export const Options = styled(Popup)``
