// Import Packages
import { Card, Label, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Chat from 'design/icons/teams/chat.png'
import Envalope from 'design/icons/teams/icon-envalope.png'
import File from 'design/icons/teams/icon-file.png'
import Phone from 'design/icons/teams/icon-phone.png'

// Import Colors
import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background?: string
  left?: number
  flex?: number
}

const Container = styled.div`
  min-height: 15px;
`

const CardWrapper = styled(Card)`
  &.ui.card {
    margin: 0 auto;
    border-radius: 0px;
    width: 95%;
    background: ${Colors.White1000};
    border: 1px solid ${Colors.Blue910};
  }
`

const CardContent = styled(Card.Content)`
  padding: 20px 0px 6px 20px;
`

const ExtraContent = styled.div`
  font-size: 12px;
  display: flex;
  padding: 5px 20px 20px 20px;
  margin-top: 5px;
  margin-bottom: -12px;
  color: ${Colors.Black550};
  align-items: center;

  a {
    flex-grow: 1;
    color: ${Colors.Black550};
  }
`

const Divider = styled.div`
  border-bottom: 1px solid ${Colors.DarkBlue200};
  width: 90%;
  margin: 0 auto;
`

const TeamName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.Black500};
  line-height: 1.2;
  text-transform:capitalize;
`

const Profile = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 6px;
  color: ${Colors.Black500};
  align-items: center;
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border-radius: 50%;
`

const FullName = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  width: 120px;
  line-height: 1.2;
  text-transform:capitalize;
`

const PhoneIcon = styled.div`
  background: url(${Phone});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 10px;
  cursor: pointer;
`

const EmailIcon = styled.div`
  background: url(${Envalope});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 10px;
  cursor: pointer;
`
const ChatIcon = styled.div`
  background: url(${Chat});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 10px;
`

const Team = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 25px;
  color: ${Colors.Black550};
  margin-bottom: -8px;
  align-items: flex-end;
`

const TeamCAP = styled.div`
  width: 100%;
  display: flex;
  flex-grow: ${(props: Props) => props.flex || props.flex};
`

const CAP = styled.div`
  margin-right: 10px;
`

const Text = styled.div`
  font-size: 12px;
  white-space: nowrap;
`

const Member = styled.div`
  display: flex;
  position: relative;
  margin-left: 6px;
`

const Ul = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
`

const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
`

const StyledLabel = styled.div`
  ${y};
  height: 100%;
  color: ${Colors.DarkBlue220};
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`

const Report = styled.div`
  display: flex;
  flex-grow: 1;
`

const FileIcon = styled.div`
  background: url(${File});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 5px;
  margin-top: -3px;
`

const Pending = styled.div`
  display: flex;
  cursor: pointer;
`

const Invites = styled(Label)`
  &.ui.circular.label, .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: lowercase;
  }
`

export {
  CAP,
  CardContent,
  CardWrapper,
  ChatIcon,
  Container,
  Divider,
  EmailIcon,
  ExtraContent,
  FileIcon,
  FullName,
  Invites,
  Labels,
  Member,
  MemberProfile,
  Pending,
  PhoneIcon,
  Profile,
  ProfileImage,
  Report,
  StyledLabel,
  Team,
  TeamCAP,
  TeamName,
  Text,
  StyledPopup,
  Li,
  Ul
}
