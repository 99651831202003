import * as React from 'react'
import ConfirmAlert from 'sweetalert2'

import Modal from 'shared/Modal'
import LoadingIndicator from 'shared/LoadingIndicator'
import Scrollable from 'shared/Scrollable'
import Toast, { serverToast } from 'shared/Toast/Toast'
import Transition from 'shared/Transition'

import { isPlanAllowed } from 'shared/Billing/Queries'
import { updateUserPass } from './Mutations'
import { getIdxData } from './Queries'

import PngIdx from 'design/icons/transactions/IDX_Graphic.png'

import { Aside, Badge, Col, Container, Image, Label, Link, Row, Span, Text, Title, ToolTip } from './Styled'

import { PassStatusEnum } from 'store/Pass/Types'
import { SettingsType, TransitionType } from './Types'

interface Props {
  onClose: () => void
}

interface State {
  isAllowed: boolean
  loading: boolean
  settings: SettingsType[]
  transition: TransitionType
}

class IdxSettingsModal extends React.Component<Props, State> {
  public state = {
    isAllowed: false,
    loading: true,
    settings: [] as SettingsType[],
    transition: {
      modal: true,
      pass: false,
      paywall: false
    } as TransitionType
  }

  public componentDidMount = async () => {
    const { transition } = this.state
    let isAllowed = false

    try {
      await isPlanAllowed('idx')
      isAllowed = true
    } catch (e) {
      transition.paywall = true
      this.setState({
        isAllowed,
        loading: false
      })
      return
    }

    try {
      const settings: any = await getIdxData()
      this.setState({ settings })
      transition.pass = true
    } catch (e) {
      serverToast(e)
    }

    this.setState({
      isAllowed,
      loading: false,
      transition
    })
  }

  public render() {
    const { isAllowed, settings, loading, transition } = this.state

    return (
      <Modal
        content={
          <React.Fragment>
            {loading && <LoadingIndicator />}
            <Container>
              <Aside>
                <Image max={190} src={PngIdx} />
                <Title>Configure IDX</Title>
              </Aside>
              <Scrollable>
                <Col padding={1.8}>
                  {!isAllowed && (
                    <Transition on={transition.paywall}>
                      <Col justify="center">
                        <Text>This feature is only available with a paid subscription.</Text>
                        <Text>Please upgrade your account to continue.</Text>
                      </Col>
                    </Transition>
                  )}
                  {isAllowed && (
                    <Transition on={transition.pass}>
                      <Text>Request feed activation for each MLS you would like to use.</Text>
                      <Text>Each feed is billed at $75/month and includes a one-time activation fee.</Text>
                      <Text>
                        Additional information may be required to complete your request. You will receive a notification
                        when your feed has been activated.
                      </Text>
                      {settings.length &&
                        settings.map((item: SettingsType, index: number) => (
                          <Transition key={index} on={true} delay={50 * index}>
                            <Row padding={'1em 0 0'}>
                              <Col width={20}>
                                <ToolTip
                                  content={item.mls.fullName}
                                  position="top left"
                                  trigger={<Label>{item.mls.shortName}</Label>}
                                />
                              </Col>
                              <Col width={40} align="center">
                                <Badge status={item.status} />
                              </Col>
                              <Col width={40} align="flex-end">
                                {item.status === PassStatusEnum.Active && (
                                  <Link onClick={() => this.toggleActivation(index)}>Request deactivation?</Link>
                                )}
                                {item.status === PassStatusEnum.Inactive && (
                                  <Link onClick={() => this.toggleActivation(index)}>Request activation?</Link>
                                )}
                                {item.status.includes('Pending') && <Span>Change requested</Span>}
                              </Col>
                            </Row>
                          </Transition>
                        ))}
                    </Transition>
                  )}
                </Col>
              </Scrollable>
            </Container>
          </React.Fragment>
        }
        className={transition.modal ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
      />
    )
  }

  private toggleActivation = async (index: number) => {
    const { settings } = this.state

    if (settings[index].status.includes('Pending')) {
      return
    }

    this.setState({ loading: true })
    let errorType = 'error'
    try {
      const { mls, status } = settings[index]
      let statusUpdate = PassStatusEnum.PendingActivation
      let text = `This action will send a request to activate your IDX feed for ${
        mls.shortName
      }. This process may take a few days. Once complete, monthly billing for this feed will begin. Do you wish to continue?`
      let title = `Activate ${mls.shortName}?`
      if (status === PassStatusEnum.Active) {
        statusUpdate = PassStatusEnum.PendingDeactivation
        text = `This action will send a request to deactivate your IDX feed for ${
          mls.shortName
        }. This process may take a few days. Once complete, monthly billing for this feed will be paused and you will not be able to use IDX features. Do you wish to continue?`
        title = `Deactivate ${mls.shortName}?`
      }
      const confirm = await ConfirmAlert({
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
        showCancelButton: true,
        text,
        title,
        type: 'warning'
      })
      if (confirm.dismiss) {
        errorType = 'warning'
        throw new Error(`IDX feed for ${mls.shortName} remains ${status}`)
      } else {
        const id = settings[index]._id
        const input = { settings: { status: statusUpdate } }
        const result: any = await updateUserPass(id, input)
        if (result) {
          settings[index].status = result.settings.status
          this.setState({ settings })
        }
      }
    } catch (e) {
      Toast({
        message: e.message,
        type: errorType
      })
    }

    this.setState({ loading: false })
  }

  private closeSelf = () => {
    const { onClose } = this.props
    const { transition } = this.state
    transition.modal = false
    this.setState({ transition })
    window.setTimeout(() => {
      onClose()
    }, 300)
  }
}

export default IdxSettingsModal
