import * as React from 'react'
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'

import {
  getLoggedInUser,
  Routes,
} from 'utils'

import {
  Container,
  Icon,
  Image,
  Nav,
  Title,
} from './Styled'

import {
  RouteParams,
  UserType,
} from 'app/Transactions/Details/Types'
import {
  CountType,
  NavItemType,
} from './Types'

interface OwnProps {
  count: CountType
  onNavigate: (subpage:string) => void
  subpage: string
}

type Props = OwnProps & RouteComponentProps<RouteParams>

interface State {
  nav: NavItemType[]
}

class SubNavigation extends React.Component<Props, State> {
  public state = {
    nav: [] as NavItemType[]
  }

  public componentDidMount = async () => {
    let tabs = [
      'documents',
      'commission',
      'messages',
      'workflow',
      'activity',
    ]

    const guestTabs = [
      'documents',
      'messages',
    ]

    const user:UserType = await getLoggedInUser({ fromCache: true }) || {} as UserType
    if (user.role === 'GUEST') {
      tabs = guestTabs
    }

    const { transactions: route } = Routes
    const nav:NavItemType[] = tabs.map((item:string) => {
      return {
        icon: route[item].icon,
        path: route[item].path.replace('/',''),
        text: route[item].key,
      }
    })

    this.setState({ nav })
  }

  public render = () => {
    const {
      count,
      subpage,
    } = this.props

    const {
      nav,
    } = this.state

    return (
      <Container>
        {nav.map((item:NavItemType) => (
          <Nav
            key={item.path}
            isActive={subpage === item.path}
            onClick={() => this.handleNavigation(item.path)}
          >
            <Icon count={count[item.text] || 0}>
              <Image src={item.icon}/>
            </Icon>
            <Title>{item.text}</Title>
          </Nav>
        ))}
      </Container>
    )
  }

  private handleNavigation = (subpage:string) => {
    const { onNavigate } = this.props
    onNavigate(subpage)
  }
}

export default withRouter(SubNavigation)
