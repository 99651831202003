import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { x } from 'design/Styled'

interface Props {
  count?: number
  image?: string
  isActive?: boolean
}

const getCount = (props: Props) => {
  if (props.count) {
    return css`
      &:after {
        content: '${props.count}';
        position: absolute;
        top: -0.5em;
        right: -0.8em;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 1.5em;
        height: 1.5em;
        padding: 0 0.4em;
        font-size: 0.7em;
        line-height: 1;
        font-weight: 500;
        color: ${Colors.TextLight};
        background: ${Colors.ButtonDanger};
        border-radius: 0.75em;
      }
    `
  }
  return ``
}

export const Container = styled.div`
  ${x};
  padding-top: 0.5em;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.EmphasisLine};
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  img {
    opacity: 0.8;
  }

  span {
    box-shadow: 0 ${(props: Props) => (props.isActive ? 3 : 0)}px 0 0
      ${Colors.TextPrimary};
  }

  &:hover {
    img {
      opacity: 1;
    }
    span {
      box-shadow: 0 3px 0 0
        ${(props: Props) =>
          props.isActive ? Colors.TextPrimary : Colors.EmphasisLine};
    }
  }
`

export const Icon = styled.div`
  display: block;
  position: relative;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  font-size: 18px;

  ${getCount};
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  transition: opacity 200ms;
`

export const Title = styled.span`
  width: 100%;
  padding: 0.5em 0 0.8em;
  text-align: center;
  font: ${fonts.small.medium};
  color: ${(props: Props) =>
    props.isActive ? Colors.TextPrimary : Colors.TextMedium};
  text-transform: capitalize;
  transition: all 200ms;
`
