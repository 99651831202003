import gql from 'graphql-tag'

import { userPassFragment } from './Fragments'
import { paginationFragment } from 'queries/graphql/Pagination/Fragments'

export const GET_PASSES = gql`
  query getPasses($searchText: String, $passType: String, $usersCount: Boolean) {
    getPasses(where: { name: $searchText, passType: $passType, isActive: true }, usersCount: $usersCount) {
      _id
      addedCount
      createdAt
      icon
      isActive
      name
      passCategory
      passType
      price
      summary
    }
  }
`

export const GET_PASSES_SECTION = gql`
  query getPassSections {
    getPassSections {
      _id
      name
      user {
        _id
        firstName
      }
      userPasses {
        ...UserPassFragment
      }
    }
  }
  ${userPassFragment}
`

export const GET_USER_PASSES = gql`
  query getUserPasses($where: UserPassFilter, $sorting: SortFilter, $skip: Int, $limit: Int) {
    getUserPasses(where: $where, sorting: $sorting, skip: $skip, limit: $limit) {
      results {
        ...UserPassFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${userPassFragment}
  ${paginationFragment}
`

export const GET_IDX_PASSES = gql`
  query getIdxPasses {
    getUserPasses(where: { isActive: true, passType: "Idx" }) {
      results {
        ...UserPassFragment
      }
    }

    mlsList: getMlsTenantList(where: { isActive: true }) {
      _id
      shortName
      fullName
    }
  }
  ${userPassFragment}
`

export const HOT_SHEET_PASS = gql`
  query hotSheetPass($passId: String, $timePeriod: Int) {
    hotSheetPass(_id: $passId, timePeriod: $timePeriod) {
      taskDue
      taskUpcoming
      totalContacts
      receivedTransactions
      updatedTransactions
      payableTransactions
      newTransactions
      referrals
      totalLeads
    }
  }
`

export const GET_USER_WALLPAPER = gql`
  query getUserWallpaper {
    getUserWallpaper {
      _id
      defaultColor
      colors
      defaultUrl
      imageUrls
      user {
        _id
        firstName
        lastName
        userName
      }
    }
  }
`
