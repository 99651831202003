import * as React from 'react'
import { connect } from 'react-redux'

import Details from './Details'
import { serverToast } from 'shared/Toast/Toast'

import { updateMLSID } from 'app/Transactions/Dashboard/TransactionMutations'
import * as Actions from 'store/TransactionV2/Actions'


import { PropertyType, TransactionType, UserType } from 'app/Transactions/Details/Types'
import { EditFormType, FormType } from './Types'

import { getLoggedInUser, Strings } from 'utils'

import { Container, Icon, Image, Label, Owner, Row, StyledInput, Value } from './Styled'


import { faPencilAlt } from '@fortawesome/pro-light-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPencilAlt])

interface StoreProps {
  mergeTransactionProperty: (id: string, data: Partial<PropertyType>) => void
}

interface OwnProps {
  onChangeOwner: () => void
  showTransactionModal: () => void
  transaction: TransactionType
}

type Props = OwnProps & StoreProps

interface State {
  form: FormType
  isEditing: EditFormType
}

class Homeowner extends React.Component<Props, {}> {
  public state = {
    form: {} as FormType,
    isEditing: {} as EditFormType
  }

  public render = () => {
    const {
      showTransactionModal,
      transaction,
      transaction: { owner }
    } = this.props
    const { form, isEditing } = this.state

    return (
      <Container>
        <Row justify="space-between">
          <Row width={-1}>
            <Label>Transaction ID:</Label>
            <Value>{transaction.transactionId}</Value>
          </Row>
          <Row primary={1} justify="flex-end">
            <Label>MLS ID:</Label>
            {isEditing.mls ? (
              <StyledInput
                name="mls"
                placeholder="MLS Listing ID"
                value={form.mls || transaction.propertyId.mlsId || ''}
                autoFocus={true}
                onChange={this.handleInputChange}
                onBlur={this.handleUpdateMls}
              />
            ) : (
              <React.Fragment>
                <Value onClick={() => this.handleFormEdit('mls')}>
                  {form.mls || transaction.propertyId.mlsId || ''}
                  <Icon icon={['fal', 'pencil-alt']} />
                </Value>
              </React.Fragment>
            )}
          </Row>
        </Row>
        <Row justify="space-between">
          <Row width={-1} primary={1}>
            <Image src={Strings.generalText.src} />
            <Owner>
              {owner.firstName} {owner.lastName}
            </Owner>
          </Row>
          <Value onClick={this.handleChangeOwner}>
            <Icon icon={['fal', 'pencil-alt']} />
          </Value>
        </Row>
        <Row>
          <Details transaction={transaction} showTransactionModal={showTransactionModal} />
        </Row>
      </Container>
    )
  }

  private handleChangeOwner = async () => {
    const { onChangeOwner, transaction } = this.props

    const user: UserType | any = await getLoggedInUser({ fromCache: true })
    if (!user || (user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === transaction.owner._id)) {
      onChangeOwner()
    }
  }

  private handleInputChange = (e: any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private handleFormEdit = (name: string) => {
    this.setState((prev: State) => {
      const { isEditing } = prev
      isEditing[name] = !isEditing[name]
      return { isEditing }
    })
  }

  private handleUpdateMls = async () => {
    const { transaction, mergeTransactionProperty } = this.props
    const { form } = this.state
    try {
      const result = await updateMLSID(transaction._id, form.mls)
      const update = { mlsId: result.propertyId.mlsId }
      mergeTransactionProperty(transaction._id, update)
    } catch (error) {
      serverToast(error)
      form.mls = transaction.propertyId.mlsId || ''
      this.setState({ form })
    }
    this.handleFormEdit('mls')
  }
}

export default connect(
  null,
  {
    mergeTransactionProperty: Actions.mergeTransactionProperty
  }
)(Homeowner)
