import { Timeline, TimelineEvent } from 'react-event-timeline'
import { Icon, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'

export const Container = styled(Timeline)``

export const StyledTimelineEvent = styled(TimelineEvent)`
  background: ${Colors.Container};

  span {
    display: flex;
    font-weight: bold;
    color: ${Colors.Text};
  }
`

export const Date = styled.div`
  font-style: italic;
`

export const StyledIcon = styled(Icon)``

export const Title = styled.div`
  width: 70px;
  color: ${Colors.Black500};
  word-break: break-word;
  text-transform: capitalize;
`

export const Envelope = styled.div`
  width: 21px;
  height: 20px;
  background-color: ${Colors.White420};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
  margin-left: 10px;
  color: ${Colors.Black500};
  cursor: pointer;
`

export const Phone = styled.div`
  width: 21px;
  height: 20px;
  background-color: ${Colors.White420};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
  margin-left: 5px;
  transform: rotate(100deg);
  color: ${Colors.Black500};
  cursor: pointer;

  .fa-phone {
    width: 10px;
    height: 10px;
  }
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
  }
`
