import * as React from 'react'
import Slider from 'react-slick'

import Button from 'shared/Button'
import { ImageType } from 'templates/Listing/Types'
import Transition from 'shared/Transition'

import {
  Container,
  Dot,
  Footer,
  Nav,
  Slide,
} from './Styled'

import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([ faChevronRight, faChevronLeft ])

interface Props {
  images: ImageType[]
  settings: any
}

interface State {
  isReady: boolean
  active: number
}

class Carousel extends React.Component<Props, State> {
  public static defaultProps = {
    settings: {}
  }

  public state = {
    isReady: true,
    active: 0,
  }

  private slider:any = null
  private defaultSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    initialSlide: 0,
    afterChange: (index: number) => this.handleChange(index),
  }

  componentDidMount = () => {
    const { settings, images } = this.props

    const current = settings.initialSlide || this.defaultSettings.initialSlide
    const image = new Image()
    image.src = images[current].url
    image.onload = () => {
      this.setState({ isReady: true })
    }
  }

  public render = () => {
    const {
      images,
      settings,
    } = this.props

    const {
      active,
      isReady,
    } = this.state

    return (
      <Transition
        on={isReady}
        type='fadeIn'
        className='rp-listing-carousel'
      >
        <Container>
          <Slider
            ref={(el:any) => this.slider = el}
            {...{...this.defaultSettings, ...settings}}
          >
            {images.map((item: ImageType, index: number) => (
              <Slide
                key={index}
                src={item.url}
              />
            ))}
          </Slider>
          <Nav
            className='left'
            onClick={() => this.handleNavigation(active, -1)}
          >
            <Button.Bubble
              icon={faChevronLeft}
              onClick={() => this.handleNavigation(active, -1)}
              type='light'
              size={30}
            />
          </Nav>
          <Nav
            className='right'
            onClick={() => this.handleNavigation(active, 1)}
          >
            <Button.Bubble
              icon={faChevronRight}
              onClick={() => this.handleNavigation(active, 1)}
              type='light'
              size={30}
            />
          </Nav>
          <Footer>
            {images.map((item: ImageType, index: number) => (
              <Dot
                key={index}
                isActive={active === index}
                onClick={() => this.handleNavigation(index, 0)}
              />
            ))}
          </Footer>
        </Container>
      </Transition>
    )
  }

  private handleNavigation = (current: number, direction: number) => {
    const { images } = this.props
    const last = images.length - 1
    let active = current + direction
    if (active < 0) {
      active = last
    } else if (active > last) {
      active = 0
    }
    this.slider.slickGoTo(active)
    this.setState({ active })
  }

  private handleChange = (active: number) => {
    this.setState({ active })
  }
}

export default Carousel
