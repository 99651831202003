// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  background: ${Colors.White1000};
`

const Title = styled.div`
  border-bottom: 2px solid ${Colors.Black765};
  color: ${Colors.DarkBlue200};
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  padding-left: 30px;
`

const StyledForm = styled(Form)`
  padding-left: 30px;
  padding-top: 25px;
  padding-bottom: 50px;
  padding-right: 180px;

  &.ui.form .field>.ui.input>input {
    border-radius: 1px;
  }

  &.ui.form .field>label {
    font-weight: 400;
    color: ${Colors.Black500};
  }

  .ui.selection.dropdown {
    border-radius: 1px;
  }
`

const DateFormat = styled.div`
  color: ${Colors.Black550};
  margin-bottom: 8px;
  font-size: 10px;
`

export {
  Container,
  DateFormat,
  StyledForm,
  Title
}

