import BlockButton from './Block'
import BubbleButton from './Bubble'
import IconButton from './Icon'
import RadioButton from './Radio'
import ToggleButton from './Toggle'

export default {
  Block: BlockButton,
  Bubble: BubbleButton,
  Icon: IconButton,
  Radio: RadioButton,
  Toggle: ToggleButton,
}
