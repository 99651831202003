export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`name`] || fields[`name`].trim() === '') {
    formIsValid = false
    errors[`name`] = true
  }

  if (!fields[`type`]) {
    formIsValid = false
    errors[`type`] = true
  }

  if (!fields[`description`] || fields[`description`].trim() === '') {
    formIsValid = false
    errors[`description`] = true
  }

  if (!fields[`dueDate`]) {
    formIsValid = false
    errors[`dueDate`] = true
  }

  return { errors, formIsValid }
}
