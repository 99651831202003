import * as React from 'react'
import { connect } from 'react-redux'

import ImageZone from 'shared/ImageZone'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import MlsUpload from './MlsUpload'
import { serverToast } from 'shared/Toast/Toast'

import * as Actions from 'store/Transactions/Actions'

import {
  deleteTransactionPropertyImage,
  reorderTransactionPropertyImage,
  uploadTransactionPropertyImages,
} from 'app/Transactions/Dashboard/TransactionMutations'

import { getLoggedInUser, Strings } from 'utils'

import {
  Container,
  Menu,
  MenuItem,
  Section,
} from './Styled'

import {
  ImageType,
  TransactionType,
  UserType,
} from 'app/Transactions/Details/Types'
import { ActionEnum as MlsActionEnum } from 'app/Transactions/PropertyImageModal/MlsUpload/Types'
import { ActionEnum as ImageActionEnum } from 'shared/ImageZone/ActionButtons/Types'
import { ModeEnum as ImageZoneModeEnum, PermissionEnum } from 'shared/ImageZone/Types'

interface StoreProps {
  setPropertyImageAdd: (image: ImageType) => void
  setPropertyImageDelete: (imageId: string) => void
  setPropertyImages: (image: ImageType[]) => void
}

interface OwnProps {
  active: number
  mode: ImageZoneModeEnum
  onClose: (data:any) => void
  transaction: TransactionType
}

type Props = OwnProps & StoreProps

interface State {
  activeIndex: number
  batch: File[]
  loading: boolean
  show: boolean
  transition: boolean
  user: UserType
}

class PropertyImageModal extends React.Component<Props, State> {
  public state = {
    activeIndex: 1,
    batch: [],
    loading: false,
    show: false,
    transition: true,
    user: {} as UserType,
  }

  public menu = [
    {
      isPaid: false,
      render: () => (
        <ImageZone
          gridActions={['trash','sort']}
          images={this.props.transaction.propertyId.images}
          onAction={this.handleImageAction}
          onUploadEach={this.handleImageUpload}
          mode={this.props.mode}
          globalPermission={this.state.user._id === this.props.transaction.owner._id ? PermissionEnum.Owner : PermissionEnum.None}
        />
     ),
      title: Strings.transactionSidebar.photoUpload.tabTexts.uploadImage,
    },
    {
      isPaid: true,
      render: () => (
        <MlsUpload
          property={this.props.transaction.propertyId}
          onAction={this.handleMlsAction}
        />
      ),
      title: Strings.transactionSidebar.photoUpload.tabTexts.mlsUpload,
    },
  ]

  public mounted: boolean = true

  public componentDidMount = async () => {
    const { active } = this.props
    const user: UserType = await getLoggedInUser({ fromCache: true }) || {} as UserType
    this.setState({
      activeIndex: active,
      batch: [],
      user,
    })
  }

  public componentWillUnmount = () => {
    this.mounted = false
  }

  public render() {
    const {
      activeIndex,
      loading,
      transition,
    } = this.state

    return (
      <Modal
        content={
          <React.Fragment>
            {loading && <LoadingIndicator />}
            <Container>
              <Menu>
                {this.menu.map((item:any, index:number) => (
                  <MenuItem
                    isActive={index === activeIndex}
                    isPaid={item.isPaid}
                    key={index}
                    onClick={() => this.handleTabChange(index)}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
              <Section primary={true} padding={'3px'}>
                {this.menu[activeIndex].render()}
              </Section>
            </Container>
          </React.Fragment>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        maximum={true}
      />
    )
  }

  private handleImageAction = (action:ImageActionEnum, data:any) => {
    switch (action) {
      case ImageActionEnum.Select:
        this.closeSelf(data)
        break

      case ImageActionEnum.Trash:
        this.handleImageDelete(data)
        break

      case ImageActionEnum.Sort:
      case ImageActionEnum.SortEnd:
        this.handleImageSort(data)
        break
    }
  }

  private handleMlsAction = (action:MlsActionEnum, data:any) => {
    const { setPropertyImages, transaction: { propertyId: { images }}} = this.props

    switch (action) {
      case MlsActionEnum.Start:
        this.setState({ loading: true })
        break

      case MlsActionEnum.Complete:
        if (data) {
          setPropertyImages(data)
          if (images.length !== data.length) {
            this.handleTabChange(0)
          }
        }
        this.setState({ loading: false })
        break

      default:
        break
    }
  }

  private handleImageUpload = async (file:File, index: number, total: number) => {
    const {
      transaction: { propertyId: property },
      setPropertyImages,
    } = this.props

    const { batch } = this.state
    const updatedBatch = [ ...batch, file ]
    if (updatedBatch.length === total) {
      try {
        const result: any = await uploadTransactionPropertyImages(property._id, updatedBatch)
        if (result) {
          const images = [ ...property.images, ...result ]
          setPropertyImages(images)
        }
      } catch (e) {
        serverToast(e)
      }
      if (this.mounted) {
        this.setState({ batch: [] })
      }
    } else {
      if (this.mounted) {
        this.setState({ batch: updatedBatch })
      }
    }

    return
  }

  private handleImageDelete = async (image: ImageType) => {
    const {
      setPropertyImageAdd,
      setPropertyImageDelete,
    } = this.props
    setPropertyImageDelete(image._id)

    try {
      await deleteTransactionPropertyImage(image._id)
    } catch {
      setPropertyImageAdd(image)
    }
  }

  private handleImageSort = async ({oldIndex, newIndex}:any) => {
    const {
      transaction: { propertyId: { images }},
      setPropertyImages,
    } = this.props

    if (oldIndex === newIndex) {
      return
    }

    const update: ImageType[] = images.slice()
    update.splice(newIndex, 0, update.splice(oldIndex, 1)[0])
    update.forEach((item:ImageType, index:number) => {
      update[index].order = index
    })

    setPropertyImages(update)
    try {
      await reorderTransactionPropertyImage(update)
    } catch {
      setPropertyImages(images)
    }
  }

  private handleTabChange = (activeIndex: number) => {
    this.setState({ activeIndex })
  }

  private closeSelf = (data:any=null) => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      onClose(data)
    }, 300)
  }
}

export default connect(
  null,
  {
    setPropertyImageAdd: Actions.addPropertyImage,
    setPropertyImageDelete: Actions.deletePropertyImage,
    setPropertyImages: Actions.setPropertyImages,
  }
)(PropertyImageModal)
