import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { scrollbars, x } from 'design/Styled'

interface Props {
  background?: string
  zIndex?: number
  isActive?: boolean
}

const arrow = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  color: ${Colors.TextLight};
  border-radius: 50%;
  background: ${Colors.ButtonPrimary};
  opacity: 0.3;
  cursor: pointer;
  z-index: ${(props: Props) => (props.isActive ? 2 : 0)}

  &:hover {
    opacity: 1;
  }
`

export const Container = styled.section`
  ${x};
  flex: 1;
  position: relative;
  overflow: hidden;
`

export const Row = styled.div`
  display: flex;
  position: relative;
  justify-content: stretch;
  align-items: stretch;
  flex: 1;
  height: 100%;
  padding-bottom: 0.2em;
  overflow: hidden;
  transition: all 300ms;
`

export const Scroll = styled.div`
  ${x};
  ${scrollbars};
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`

export const Lane = styled.section`
  ${x};
  justify-content: center;
  padding: 1em;
  min-width: 320px;
  max-width: 320px;
  height: 100%;
  overflow: hidden;
`

export const LeftArrow = styled.nav`
  ${arrow};
  left: 1em;
`

export const RightArrow = styled.nav`
  ${arrow};
  right: 1em;
`
