import { cloneDeep, debounce } from 'lodash'
import { sortBy } from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Accordion, Dropdown, Icon, Input } from 'semantic-ui-react'
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/WorkflowManager/Actions'
import { WorkflowLabelManage, WorkflowTask } from 'store/Settings/WorkflowManager/Types'
import ConfirmAlert from 'sweetalert2'
import { Strings } from 'utils'
import AddWorkflowTaskModal from './AddTaskModal'
import Contents from './Content'

import { getWorkflowCategoryTasks } from '../WorkflowManagerQueries'

import {
  Action,
  ActionTitle,
  Content,
  ContentHeader,
  DropDownPopup,
  DueDaysTitle,
  DueIn,
  Header,
  Infograph,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledContent,
  StyledDropdown,
  TaskName,
  Title,
  WorkflowTaskTitle,
  Wrapper
} from './Styled'

// Font Awesome Icons
import { faComment, faEnvelope, faPhone, faSearch, faSms } from '@fortawesome/pro-light-svg-icons'
import { faBars, faMinus, faPlus, faSortDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import {
  createWorkflowTask,
  deleteWorkflowTask,
  reorderWorkflowTasks,
  updateWorkflowTask
} from '../../WorkflowManagerMutations'
AddIconToLibrary([faBars, faMinus, faPlus, faSms, faEnvelope, faPhone, faComment, faSearch, faSortDown])

interface StoreProps {
  setNewTask: (newData: WorkflowTask) => void
  setDeleteTask: (id: string) => void
  setTaskData: (data: WorkflowTask[]) => void
  setUpdateTask: (newData: WorkflowTask) => void
  labelManageData: WorkflowLabelManage[]
  taskData: WorkflowTask[]
}

interface OwnProps {
  workflowId: string
  workflowName: string
}

type Props = StoreProps & OwnProps

interface State {
  activeIndex?: number
  showAddTaskModal: boolean
  editTaskData: object
  searchText: string
  task: any
  direction: string
}

export let Task: any

// const DragHandle = SortableHandle(() => <FontAwesomeIcon icon={['fas', 'bars']} />)

const SortableItem = SortableElement(({ items, index, activeIndex, handleClick, editTask, deleteTask }: any) => {
  const onEditTask = () => {
    editTask(items)
  }
  const onDeleteTask = () => {
    deleteTask(items._id)
  }

  let taskIcons
  if (items.type === 'Email') {
    taskIcons = <FontAwesomeIcon icon={['far', 'envelope']} />
  }
  if (items.type === 'Call') {
    taskIcons = <FontAwesomeIcon icon={['fas', 'phone']} />
  }
  if (items.type === 'Meeting') {
    taskIcons = <FontAwesomeIcon icon={['far', 'handshake']} />
  }
  if (items.type === 'Sms') {
    taskIcons = <FontAwesomeIcon icon={['far', 'sms']} />
  }
  if (items.type === 'Pass') {
    taskIcons = <FontAwesomeIcon icon={['far', 'ticket']} />
  }

  return (
    <div className="styled-accordion">
      <StyledAccordionTitle active={activeIndex === items._id} index={items._id} onClick={handleClick}>
        <FontAwesomeIcon icon={activeIndex === items._id ? ['fas', 'minus'] : ['fas', 'plus']} />
        <TaskName>
          {items.name}
          {taskIcons}
        </TaskName>
        <DueIn>in {items.dueDate} days</DueIn>
        <Action>
          <StyledDropdown>
            <DropDownPopup
              content={
                <div className="action-drop-down">
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onEditTask}>{Strings.tasksSetting.edit}</Dropdown.Item>
                    <Dropdown.Item onClick={onDeleteTask}>{Strings.tasksSetting.deleteWorkflow}</Dropdown.Item>
                  </Dropdown.Menu>
                </div>
              }
              trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
              hoverable={true}
              position="bottom left"
            />
          </StyledDropdown>
          {/* <DragHandle /> */}
        </Action>
      </StyledAccordionTitle>
      <StyledAccordionContent active={activeIndex === items._id} style={{ paddingTop: '0px' }}>
        <Contents content={items} />
      </StyledAccordionContent>
    </div>
  )
})

const SortableList = SortableContainer(({ items, activeIndex, handleClick, editTask, deleteTask }: any) => {
  return (
    <Accordion className="accordionUI">
      {items.map((value: any, index: any) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          items={value}
          activeIndex={activeIndex}
          handleClick={handleClick}
          deleteTask={deleteTask}
          editTask={editTask}
        />
      ))}
    </Accordion>
  )
})

class RightPanel extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    activeIndex: 0.5,
    direction: 'descending',
    editTaskData: {},
    searchText: '',
    showAddTaskModal: false,
    showManageLabelModal: false,
    task: []
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({
      task: nextProps.taskData
    })
  }

  public render() {
    const { workflowName } = this.props
    const { activeIndex, showAddTaskModal, editTaskData, task } = this.state
    return (
      <Wrapper>
        <Header style={{ display: showAddTaskModal ? 'none' : 'flex' }}>
          <div style={{ flexGrow: 1, display: 'flex' }}>
            <Title>{workflowName}</Title>
            <Input icon="search" placeholder="Search By Task" onChange={this.handleChange} />
            <div className="workflow" onClick={this.showAddTaskModal}>
              {Strings.tasksSetting.ADDTASK}
            </div>
          </div>
        </Header>
        <Content>
          {!showAddTaskModal ? (
            <React.Fragment>
              {task.length === 0 ? (
                <Infograph backgroundImage={Strings.generalText.nothing} />
              ) : (
                <React.Fragment>
                  <ContentHeader>
                    <WorkflowTaskTitle>{Strings.tasksSetting.workflowTask}</WorkflowTaskTitle>
                    <DueDaysTitle onClick={this.handleSort}>{Strings.tasksSetting.dueDays}</DueDaysTitle>
                    <ActionTitle>{Strings.tasksSetting.action}</ActionTitle>
                  </ContentHeader>
                  <StyledContent>
                    <SortableList
                      items={task}
                      onSortEnd={this.onSortEnd}
                      useDragHandle={true}
                      activeIndex={activeIndex}
                      handleClick={this.handleClick}
                      deleteTask={this.deleteTask}
                      editTask={this.editTask}
                    />
                  </StyledContent>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <div>
              <AddWorkflowTaskModal
                closeAddTaskModal={this.closeAddTaskModal}
                addNewTask={this.addNewTask}
                editTaskData={cloneDeep(editTaskData)}
                updateTask={this.updateTask}
              />
            </div>
          )}
        </Content>
      </Wrapper>
    )
  }

  private handleSort = () => {
    const { task, direction } = this.state
    if (direction === 'descending') {
      const previewData = sortBy(task, 'dueDate')
      this.setState({
        direction: 'ascending',
        task: previewData.reverse()
      })
    } else {
      this.setState({
        direction: 'descending',
        task: this.state.task.reverse()
      })
    }
  }

  private getTasksSearchData = async () => {
    const { setTaskData, workflowId } = this.props
    const { searchText } = this.state

    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(async () => {
      const workflowTask = await getWorkflowCategoryTasks(workflowId, searchText)
      setTaskData(workflowTask)
    }, 1000)

    this.debounceJob()
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { value }: any) => {
    this.setState({ searchText: value }, () => this.getTasksSearchData())
  }

  private updateTask = async (data: WorkflowTask | any) => {
    const { setUpdateTask, workflowId } = this.props
    await updateWorkflowTask(data, data[`_id`], workflowId)
    setUpdateTask(data)
    this.setState({ editTaskData: {} })
  }

  private editTask = async (item: any) => {
    this.setState({ editTaskData: item, showAddTaskModal: true })
  }

  private deleteTask = (id: string) => {
    const { setDeleteTask } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.tasksSetting.noKeepIt,
      confirmButtonText: Strings.tasksSetting.yesDeleteIt,
      showCancelButton: true,
      text: Strings.tasksSetting.recoverTask,
      title: Strings.tasksSetting.sure,
      type: 'warning'
    }).then(result => {
      if (result.value) {
        setDeleteTask(id)
        deleteWorkflowTask(id)
        ConfirmAlert(Strings.tasksSetting.deleted, Strings.tasksSetting.deletedTask, 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.tasksSetting.cancelled, Strings.tasksSetting.safeTask, 'error')
      }
    })
  }

  private addNewTask = async (data: WorkflowTask | any) => {
    const { setNewTask, workflowId } = this.props
    const res = await createWorkflowTask(data, workflowId)
    setNewTask(res)
  }

  private closeAddTaskModal = () => {
    this.setState({ showAddTaskModal: false, editTaskData: {} })
  }

  private showAddTaskModal = () => {
    this.setState({ showAddTaskModal: true })
  }

  private onSortEnd = ({ oldIndex, newIndex }: any) => {
    const { setTaskData, taskData } = this.props
    const items = arrayMove(taskData, oldIndex, newIndex)
    setTaskData(items)
    const sortedData: any = []
    items.forEach((item: any, index: number) => {
      sortedData.push({
        _id: item._id,
        order: index + 1
      })
    })
    reorderWorkflowTasks(sortedData)
  }

  private handleClick = (e: React.SyntheticEvent<EventTarget>, titleProps: any) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
}

const mapStateToProps = (state: AppState) => ({
  labelManageData: state.settings.workflowManager.labelManageData,
  taskData: state.settings.workflowManager.taskData
})

export default connect(
  mapStateToProps,
  {
    setDeleteTask: Actions.deleteTask,
    setNewTask: Actions.addNewTask,
    setTaskData: Actions.getTaskData,
    setUpdateTask: Actions.updateTask
  }
)(RightPanel)
