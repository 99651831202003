export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`firstName`] || !fields[`firstName`].match(/^[A-Z,a-z]+$/)) {
    formIsValid = false
    errors[`firstName`] = 'Can Not be empty'
  }

  if (!fields[`lastName`] || !fields[`lastName`].match(/^[A-Z,a-z]+$/)) {
    formIsValid = false
    errors[`lastName`] = 'Can Not be empty'
  }

  if (
    !fields[`email`] ||
    !fields[`email`].match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    formIsValid = false
    errors[`email`] = 'Can Not be empty'
  }

  if (!fields[`source`]) {
    formIsValid = false
    errors[`source`] = 'Can Not be empty'
  }

  if (!fields[`propertyType`]) {
    formIsValid = false
    errors[`propertyType`] = 'Can Not be empty'
  }

  if (!fields[`tenantBoard`]) {
    formIsValid = false
    errors[`tenantBoard`] = 'Can Not be empty'
  }

  return { errors, formIsValid }
}
