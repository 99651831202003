import * as React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

import List from 'shared/List'
import Pagination from 'shared/Pagination'
import Button from 'shared/Button'
import { serverToast } from 'shared/Toast/Toast'
import Transition from 'shared/Transition'

import LineItem from './LineItem'
import TemplateSelector from '../TemplateSelector'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Pass/Actions'
import { getIdxPasses, getWebsitePassData } from '../Queries'
import { updateUserPass } from '../Mutations'

import {
  AdminSettings,
  Col,
  Dollar,
  Label,
  Row,
  StyledInput,
  Title,
  Text,
} from './Styled'
import { Badge, ToolTip } from '../Styled'

import {
  PaginatedUserPassType,
  PassTypeEnum,
  PassStatusEnum,
  UserPassType,
  UserPassDictType,
} from 'store/Pass/Types'
import { PaginationType } from 'store/Pagination/Types'
import { User } from 'store/Users/Types'
import {
  ActionEnum,
  AdminFeeEditorPerMlsType,
  AdminWebsitesPerMlsType,
  ID,
  TOGGLE_OPTIONS,
  WebsiteListType,
  WebsitesPerMlsType,
} from '../Types'

import { faPencilAlt, faCheck } from '@fortawesome/pro-light-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faPencilAlt,
  faCheck,
])

interface StoreProps {
  setUserPass: (pass: Partial<UserPassType.Base>) => void
  passes: UserPassDictType
}

interface OwnProps {
  onAction: (action: ActionEnum, data: any) => void
  user: User.LoggedInType
}

type Props = StoreProps & OwnProps

interface State {
  adminFeeEditorPerMls: AdminFeeEditorPerMlsType
  adminWebsitesPerMls: AdminWebsitesPerMlsType
  idxPasses: PaginatedUserPassType
  primaryId: string
  transition: boolean
  websitesPerMls: WebsitesPerMlsType
}

class AdminMode extends React.Component<Props, State> {
  public state = {
    adminFeeEditorPerMls: {} as AdminFeeEditorPerMlsType,
    adminWebsitesPerMls: {} as AdminWebsitesPerMlsType,
    idxPasses: {} as PaginatedUserPassType,
    primaryId: '',
    transition: false,
    websitesPerMls: {} as WebsitesPerMlsType,
  }

  public componentDidMount = async () => {
    const { onAction, user } = this.props
    onAction(ActionEnum.Loading, true)

    const idxPasses: PaginatedUserPassType = await getIdxPasses() || { results: [] }
    if (!idxPasses.results.length) {
      onAction(ActionEnum.Loading, false)
      return
    }

    const websitesPerMls: WebsitesPerMlsType = {}
    const sorting: PaginationType.Sort = { field: 'settings.isPending', order: -1 }
    const skip = 0
    const limit = 20

    const adminPasses: PaginatedUserPassType = await getWebsitePassData({
      passType: PassTypeEnum.WebsiteSolution,
      isPlaceholder: false,
      user: user._id
    }, sorting, skip, 0)
    if (!adminPasses.results.length) {
      onAction(ActionEnum.Loading, false)
      return
    }
    let primaryId: string = ''
    const adminWebsitesPerMls:AdminWebsitesPerMlsType = adminPasses.results.reduce((obj:AdminWebsitesPerMlsType, item:UserPassType.Base) => {
      if (item.settings.mls) {
        obj[item.settings.mls._id] = item._id
      } else if (item.settings.isPrimary) {
        primaryId = item._id
      }
      return obj
    }, {})

    for (const idx of idxPasses.results) {
      const where: Partial<UserPassType.Filter> = {
        passType: PassTypeEnum.WebsiteSolution,
        isPlaceholder: false,
        custom: { 'settings.mls': idx.settings.mls._id, user: { $ne: user._id }}
      }
      sorting.extraSort = {field: 'user', order: -1}
      const passes: PaginatedUserPassType = await getWebsitePassData(where, sorting, skip, limit) || {}
      websitesPerMls[idx.settings.mls._id] = {
        list: passes.results.map((item: UserPassType.Base) => item._id),
        pagination: passes.pagination,
      }
    }


    this.setState({
      adminWebsitesPerMls,
      primaryId,
      idxPasses,
      websitesPerMls,
      transition: true,
    })
    onAction(ActionEnum.Loading, false)
  }

  public render = () => {
    const { passes, user } = this.props
    const {
      adminFeeEditorPerMls,
      adminWebsitesPerMls,
      idxPasses,
      transition,
      websitesPerMls,
      primaryId,
    } = this.state

    return (
      <Transition on={transition}>
        {primaryId && (
          <Col padding='0 0 2em'>
            <Title>Your Primary Website</Title>
            <Text>Control the template and active status of your primary account website.</Text>
              <Transition on={true}>
                <AdminSettings>
                  <List.Grouped
                    defaultIsExpanded={false}
                    header={(
                      <LineItem
                        isAdmin={true}
                        onAction={this.handleAction}
                        pass={primaryId}
                      />
                    )}
                    items={(
                      <TemplateSelector
                        user={user}
                        pass={passes[primaryId]}
                        onSelect={(template: string) => this.handleTemplateSelection(primaryId, template)}
                      />
                    )}
                    requireExpanderClick={true}
                  />
                </AdminSettings>
              </Transition>
          </Col>
        )}
        <Col>
          <Title>Your Users' Websites</Title>
          <Text>Control the template and active status of each of your user's office-level websites.</Text>
          <Text>Each website in use by your brokerage will add $15/month toward your regular billing cycle. The optional "Admin Fee" allows you to invoice your own user's for their use of the Website Solution service. RealtyPass does not collect this fee.</Text>
          <Text>You can toggle the active state of your users' websites individually or globally per active MLS. Users may request activation or deactivation of their websites.</Text>
          {(idxPasses.results || []).map((idxPass: UserPassType.Base, index: number) => {
            const { mls, status } = idxPass.settings
            const websitePasses: WebsiteListType = websitesPerMls[mls._id] || { list: [] }
            const adminPass: UserPassType.Base = passes[adminWebsitesPerMls[mls._id]] || {}
            const isEditingFee = adminFeeEditorPerMls[mls._id]
            return (
              <Transition key={index} on={true} delay={(50 * index) + 50}>
                <List.Grouped
                  defaultIsExpanded={(idxPasses.results || []).length < 2}
                  header={(
                    <Row justify='space-between'>
                      <Col width={20}>
                        <ToolTip
                          content={mls.fullName}
                          position='top left'
                          trigger={<Label>{mls.shortName}</Label>}
                        />
                      </Col>
                      <Col width={45} align='center'>
                        <ToolTip
                          content='IDX integration status'
                          position='top center'
                          trigger={<Badge status={status} />}
                        />
                      </Col>
                      <Col>
                        <Label justify='center'>
                          <span>Admin Fee:</span>
                          {isEditingFee ? (
                            <React.Fragment>
                              <StyledInput
                                name='adminFee'
                                size='mini'
                                onChange={(e:any, { value }:any) => this.handleFeeChange(adminPass._id, value)}
                                value={adminPass.settings.adminFee}
                              />
                              <Button.Bubble
                                icon={faCheck}
                                label='Confirm Fee'
                                onClick={() => this.toggleFeeEdit(mls._id)}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Dollar amount={adminPass.settings.adminFee || 0} />
                              <Button.Icon
                                icon={faPencilAlt}
                                label='Edit Fee'
                                onClick={() => this.toggleFeeEdit(mls._id)}
                              />
                            </React.Fragment>
                          )}
                        </Label>
                      </Col>
                      <Col width={-1}>
                        <Button.Toggle
                          monochrome={0}
                          name={`toggle_${mls.shortName}`}
                          onChange={(e:any, { value }:any) => this.toggleAdminStatus(adminPass._id, value)}
                          options={TOGGLE_OPTIONS}
                          tooltip={`Toggle All User Websites for ${mls.shortName}`}
                          showLabels={false}
                          value={adminPass.settings.adminStatus === PassStatusEnum.Active ? adminPass.settings.adminStatus : PassStatusEnum.Inactive}
                        />
                      </Col>
                    </Row>
                  )}
                  items={(
                    <React.Fragment>
                      {websitePasses.list.map((id:ID) => {
                        return (
                          <LineItem
                            key={id}
                            isAdmin={false}
                            onAction={this.handleAction}
                            pass={id}
                          />
                        )
                      })}
                      <Row padding={1}>
                        <Pagination.Records
                          currentLength={websitePasses.list.length}
                          pagination={websitePasses.pagination}
                          onChange={(pagination: PaginationType.Base) => this.handlePageChange(mls._id, pagination)}
                        />
                      </Row>
                    </React.Fragment>
                  )}
                  hasItems={true}
                  requireExpanderClick={true}
                />
              </Transition>
            )
          })}
        </Col>
      </Transition>
    )
  }

  private handleAction = (action:ActionEnum, data: any) => {
    switch (action) {
      case ActionEnum.Update:
        this.updatePass(data._id, data)
    }
  }

  private handleTemplateSelection = (_id: ID, template: string) => {
    const update = { settings: { template }}
    this.updatePass(_id, update)
  }

  private handlePageChange = async (mls: ID, pagination: PaginationType.Base) => {
    const { websitesPerMls } = this.state
    const {
      filter,
      sorting,
      skip,
      limit
    } = pagination

    const where: any = {
      passType: filter.passType,
      isPlaceholder: filter.isPlaceholder,
      custom: {
        'settings.mls': filter['settings.mls'],
        user: filter.user
      }
    }

    const passes: PaginatedUserPassType = await getWebsitePassData(where, sorting, skip, limit) || {}
    websitesPerMls[mls] = {
      list: passes.results.map((item: UserPassType.Base) => item._id),
      pagination: passes.pagination,
    }
    this.setState({ websitesPerMls })
  }

  private handleFeeChange = (_id: ID, adminFee: number) => {
    const { setUserPass } = this.props
    setUserPass({ _id, settings: { adminFee } })
  }

  private toggleFeeEdit = (mls: ID) => {
    const { passes } = this.props
    const { adminWebsitesPerMls, adminFeeEditorPerMls } = this.state

    const pass = passes[adminWebsitesPerMls[mls]]
    const isEditing = adminFeeEditorPerMls[mls]
    if (isEditing) {
      const update: Partial<UserPassType.Base> = {
        settings: {
          adminFee: pass.settings.adminFee
        }
      }
      this.updatePass(pass._id, update)
    }

    adminFeeEditorPerMls[mls] = !isEditing
    this.setState({ adminFeeEditorPerMls })
  }

  private toggleAdminStatus = async (_id: ID, adminStatus: PassStatusEnum) => {
    const { passes, setUserPass } = this.props
    const { websitesPerMls } = this.state
    const { settings: { mls }} = passes[_id]
    const update: Partial<UserPassType.Base> = {
      settings: {
        adminStatus,
        status: adminStatus,
      }
    }
    const willEnable = adminStatus === PassStatusEnum.Active
    let text = `This action will ${willEnable ? 'enable' : 'disable'} all websites for ${mls.shortName}. This will affect your monthly subscription costs.`
    const confirm = await ConfirmAlert({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Proceed',
      showCancelButton: true,
      text,
      title: `${willEnable ? 'Enable' : 'Disable'} All Websites?`,
      type: 'warning'
    })
    if (!confirm.value) {
      return
    }

    const result = this.updatePass(_id, update)
    if (result) {
      websitesPerMls[mls._id].list.forEach((_id:string) => {
        setUserPass({ _id, ...update })
      })
    }
  }

  private updatePass = async (_id: ID, update:Partial<UserPassType.Base>) => {
    const {
      passes,
      setUserPass,
      onAction,
    } = this.props
    onAction(ActionEnum.Loading, true)

    let isSuccess = true
    setUserPass({ _id, ...update })
    try {
      await updateUserPass(_id, update)
    } catch (error) {
      isSuccess = false
      serverToast(error)
      setUserPass(passes[_id])
    }

    onAction(ActionEnum.Loading, false)
    return isSuccess
  }
}

const mapStateToProps = (state: AppState) => ({
  passes: state.userPass.dict
})

export default connect(
  mapStateToProps,
  {
    setUserPass: Actions.setUserPass
  }
)(AdminMode)
