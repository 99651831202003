import * as React from 'react'

import Modal from 'shared/Modal'

import { getLoggedInUser } from 'utils'

import { updateSignatureData } from './Mutations'
import { getSignatureData } from './Queries'

import { signatureTemplates } from 'templates/EmailSignature'

import { AddressType, OfficeType, OptionType, PhoneType, SocialType } from './Types'

import {
  Container,
  Fa,
  FontContainer,
  Footer,
  FormContainer,
  Header,
  Nav,
  PickColor,
  Preview,
  PreviewContainer,
  SocialContainer,
  StyledButton,
  StyledChromePicker,
  StyledDropdown,
  StyledIcon,
  StyledInput,
  StyledLabel,
  StyledPopup
} from './Styled'

import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/pro-light-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faArrowCircleLeft, faArrowCircleRight])

interface Props {
  onClose: () => void
}

interface State {
  form: any
  showColorPopup: any
  transition: boolean
  uid: string
}

const fontSizeOptions = [
  { key: 1, text: 'Small', value: 12 },
  { key: 2, text: 'Normal', value: 16 },
  { key: 3, text: 'Large', value: 18 }
]

const fontFamilyOptions = [
  { key: 1, text: 'Serif', value: 'serif' },
  { key: 2, text: 'Sans Serif', value: 'Helvetica,Verdana,sans-serif' },
  { key: 3, text: 'Monospace', value: 'monospace' },
  { key: 4, text: 'Fancy', value: 'cursive' }
]

const nameTitleOptions = [
  { key: 0, text: '', value: '' },
  { key: 1, text: 'Dr.', value: 'Dr.' },
  { key: 2, text: 'Ms.', value: 'Ms.' },
  { key: 3, text: 'Mrs.', value: 'Mrs.' },
  { key: 4, text: 'Mr.', value: 'Mr.' },
  { key: 5, text: 'Sr.', value: 'Sr.' },
  { key: 6, text: 'Jr.', value: 'Jr.' }
]

class SignatureModal extends React.Component<Props, State> {
  public state = {
    form: {
      address: '',
      colorLink: '#33F',
      colorText: '#333',
      company: '',
      companyPosition: '',
      errors: {},
      fontFamily: fontFamilyOptions[1].value,
      fontSize: fontSizeOptions[1].value,
      name: '',
      nameTitle: nameTitleOptions[0].value,
      phone: '',
      profileImage: '',
      templateId: 0,
      urlFacebook: '',
      urlLinkedin: '',
      urlTwitter: '',
      urlWebsite: ''
    },
    showColorPopup: {
      colorLink: false,
      colorText: false
    },
    transition: true,
    uid: ''
  }

  public componentDidMount = () => {
    this.initializeData()
  }

  public render() {
    const {
      colorLink,
      colorText,
      company,
      companyPosition,
      errors,
      urlFacebook,
      fontSize,
      fontFamily,
      urlLinkedin,
      name,
      nameTitle,
      phone,
      urlTwitter,
      urlWebsite
    } = this.state.form
    const { showColorPopup, transition } = this.state
    return (
      <Modal
        content={
          <Container>
            <Header>Create Signature</Header>
            <FormContainer>
              <div className="col">
                <StyledLabel>Title</StyledLabel>
                <StyledDropdown
                  name="nameTitle"
                  onChange={(event: any, data: any) => this.handleOptionsChange(event, data, 'nameTitle')}
                  value={nameTitle}
                  options={nameTitleOptions}
                  selection={true}
                  placeholder="Title"
                />
              </div>
              <div className="col">
                <StyledLabel>Full Name</StyledLabel>
                <StyledInput
                  name="name"
                  value={name}
                  onChange={(event: any) => this.handleInputChange(event, 'name')}
                  error={!!errors[`name`]}
                />
              </div>
              <div className="col">
                <StyledLabel>Company</StyledLabel>
                <StyledInput
                  name="company"
                  value={company}
                  onChange={(event: any) => this.handleInputChange(event, 'company')}
                  error={!!errors[`company`]}
                />
              </div>
              <div className="col">
                <StyledLabel>Phone</StyledLabel>
                <StyledInput
                  name="phone"
                  value={phone}
                  onChange={(event: any) => this.handleInputChange(event, 'phone')}
                  error={!!errors[`phone`]}
                />
              </div>
              <div className="col">
                <StyledLabel>Position</StyledLabel>
                <StyledInput
                  name="companyPosition"
                  value={companyPosition}
                  onChange={(event: any) => this.handleInputChange(event, 'companyPosition')}
                  error={!!errors[`companyPosition`]}
                />
              </div>
              <div className="col">
                <StyledLabel>Website</StyledLabel>
                <StyledInput
                  name="urlWebsite"
                  value={urlWebsite}
                  onChange={(event: any) => this.handleInputChange(event, 'urlWebsite')}
                  error={!!errors[`urlWebsite`]}
                />
              </div>
            </FormContainer>
            <SocialContainer>
              <StyledInput
                placeholder="https://facebook.com/me"
                name="urlFacebook"
                iconPosition="left"
                icon={true}
                value={urlFacebook}
                onChange={(event: any) => this.handleInputChange(event, 'urlFacebook')}
                error={!!errors[`urlFacebook`]}
              >
                <StyledIcon name="facebook" className="facebook" />
                <input />
              </StyledInput>
              <StyledInput
                placeholder="https://linkedin.com/me"
                name="urlLinkedin"
                iconPosition="left"
                value={urlLinkedin}
                onChange={(event: any) => this.handleInputChange(event, 'urlLinkedin')}
                error={!!errors[`urlLinkedin`]}
              >
                <StyledIcon name="linkedin" className="linkedin" />
                <input />
              </StyledInput>
              <StyledInput
                placeholder="https://twitter.com/me"
                name="urlTwitter"
                iconPosition="left"
                value={urlTwitter}
                onChange={(event: any) => this.handleInputChange(event, 'urlTwitter')}
                error={!!errors[`urlTwitter`]}
              >
                <StyledIcon name="twitter square" className="twitter" />
                <input />
              </StyledInput>
            </SocialContainer>
            <FontContainer>
              <div className="col">
                <StyledLabel>Font Size</StyledLabel>
                <StyledDropdown
                  name="fontSize"
                  onChange={(event: any, data: any) => this.handleOptionsChange(event, data, 'fontSize')}
                  value={fontSize}
                  options={fontSizeOptions}
                  selection={true}
                  placeholder="Medium"
                />
              </div>
              <div className="col">
                <StyledLabel>Font Type</StyledLabel>
                <StyledDropdown
                  name="fontFamily"
                  onChange={(event: any, data: any) => this.handleOptionsChange(event, data, 'fontFamily')}
                  value={fontFamily}
                  options={fontFamilyOptions}
                  selection={true}
                  placeholder="Sans Serif"
                />
              </div>
              <div className="col">
                <StyledLabel>Text Color</StyledLabel>
                <StyledPopup
                  id="colorText"
                  trigger={<PickColor style={{ backgroundColor: colorText }} />}
                  hoverable={true}
                  open={showColorPopup.colorText}
                  onClose={this.toggleColorModal}
                  onOpen={this.toggleColorModal}
                  position="right center"
                >
                  <StyledChromePicker
                    color={colorText}
                    disableAlpha={true}
                    onChangeComplete={(color: any) => this.handleColorChange(color, 'colorText')}
                  />
                </StyledPopup>
              </div>
              <div className="col">
                <StyledLabel>Link Color</StyledLabel>
                <StyledPopup
                  id="colorLink"
                  trigger={<PickColor style={{ backgroundColor: colorLink }} />}
                  hoverable={true}
                  open={showColorPopup.colorLink}
                  onClose={this.toggleColorModal}
                  onOpen={this.toggleColorModal}
                  position="right center"
                >
                  <StyledChromePicker
                    color={colorLink}
                    disableAlpha={true}
                    onChangeComplete={(color: any) => this.handleColorChange(color, 'colorLink')}
                  />
                </StyledPopup>
              </div>
            </FontContainer>
            <PreviewContainer>
              <Nav onClick={() => this.handleChangeSignature(-1)}>
                <Fa icon={['fal', 'arrow-circle-left']} />
              </Nav>
              <Preview dangerouslySetInnerHTML={this.handleCreateSignature()} />
              <Nav onClick={() => this.handleChangeSignature(1)}>
                <Fa icon={['fal', 'arrow-circle-right']} />
              </Nav>
            </PreviewContainer>
            <Footer>
              <StyledButton compact={true} size="small" onClick={this.handleSaveSignature}>
                Save
              </StyledButton>
              <StyledButton compact={true} size="small" onClick={this.handleCancelSignature}>
                Cancel
              </StyledButton>
            </Footer>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
      />
    )
  }

  private handleChangeSignature = (direction: number) => {
    this.setState(prev => {
      let index = prev.form.templateId + direction
      if (index < 0) {
        index = signatureTemplates.length - 1
      }
      if (index > signatureTemplates.length - 1) {
        index = 0
      }
      prev.form.templateId = index
      return { form: prev.form }
    })
  }

  private handleCreateSignature = () => {
    const {
      form,
      form: { templateId }
    } = this.state
    const html = signatureTemplates[templateId](form)
    return { __html: html }
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const { form } = this.state
    form[name] = event.target.value
    form.errors[name] = ''
    this.setState({ form })
  }

  private handleOptionsChange = (event: React.SyntheticEvent, data: any, name: string) => {
    const { form } = this.state
    form[name] = data.value
    this.setState({ form })
  }

  private handleColorChange = (color: any, name: string) => {
    const { form } = this.state
    form[name] = color.hex
    form.errors[name] = ''
    this.setState({ form })
  }

  private handleSaveSignature = async () => {
    const { form, uid } = this.state

    const mutate = await updateSignatureData(uid, form)
    if (mutate) {
      this.closeSelf()
    }
  }

  private handleCancelSignature = () => {
    this.closeSelf()
  }

  private closeSelf = () => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({
        transition: true
      })
      onClose()
    }, 300)
  }

  private toggleColorModal = (event: React.SyntheticEvent, data: any) => {
    const target = data.id
    this.setState(prev => {
      const { showColorPopup } = prev
      showColorPopup[target] = !prev.showColorPopup[target]
      return { showColorPopup }
    })
  }

  private initializeData = async () => {
    const { _id } = await getLoggedInUser()
    if (!_id) {
      return
    }

    const data = await getSignatureData(_id)

    const { form } = this.state
    const { signature } = data

    form.name = (signature && signature.name) || `${data.firstName} ${data.lastName}`
    form.nameTitle = (signature && signature.nameTitle) || ''
    form.companyPosition =
      (signature && signature.companyPosition) ||
      (data.role && data.role.slice(0, 1).toUpperCase() + data.role.slice(1).toLowerCase()) ||
      ''
    form.urlWebsite = (signature && signature.urlWebsite) || data.urlWebsite || ''
    form.profileImage = data.profileImage

    if (signature && signature.colorLink) {
      form.colorLink = signature.colorLink
    }

    if (signature && signature.colorText) {
      form.colorText = signature.colorText
    }

    if (signature && signature.fontSize) {
      const isOption: number = fontSizeOptions.findIndex((option: OptionType) => {
        return option.value === signature.fontSize
      })
      if (isOption > -1) {
        form.fontSize = signature.fontSize
      }
    }

    if (signature && signature.fontFamily) {
      const isOption: number = fontFamilyOptions.findIndex((option: OptionType) => {
        return option.value === signature.fontFamily
      })
      if (isOption > -1) {
        form.fontFamily = signature.fontFamily
      }
    }

    if (signature && signature.company) {
      form.company = signature.company
    } else if (data.office && data.office.length > 0) {
      const company: OfficeType | undefined = data.office.find((item: OfficeType) => {
        return item.isDefault
      })
      form.company = company ? company.branchName : data.office[0].branchName || ''
    }

    if (signature && signature.address) {
      form.address = signature.address
    } else if (data.addresses && data.addresses.length > 0) {
      let address: AddressType | undefined = data.addresses.find((item: AddressType) => {
        return item.type.toLowerCase() === 'office'
      })
      if (!address) {
        address = data.address[0]
      }
      form.address = address ? `${address.streetNumber} ${address.streetName}, ${address.city}` : ''
    } else if (data.office && data.office.length > 0) {
      let address: OfficeType | undefined = data.office.find((item: OfficeType) => {
        return item.isDefault
      })
      if (!address) {
        address = data.office[0]
      }
      form.address = address ? `${address.street}, ${address.city}` : ''
    }

    if (signature && signature.phone) {
      form.phone = signature.phone
    } else if (data.phones && data.phones.length > 0) {
      const phone: PhoneType | undefined = data.phones.find((item: PhoneType) => {
        return item.type === 'Work'
      })
      form.phone = phone ? phone.value : (data.phones[0] && data.phones[0].value) || ''
    }

    const findSocialUrl = (name: string) => {
      const lowerName = name.toLowerCase()
      const signatureName = `url${name}`
      if (signature && signature[signatureName]) {
        return signature[signatureName]
      } else if (data.social) {
        const social: SocialType | undefined = data.social.find((item: SocialType) => {
          return item.type.toLowerCase() === lowerName
        })
        return social ? social.url : ''
      }
      return ''
    }

    form.urlFacebook = findSocialUrl('Facebook')
    form.urlLinkedin = findSocialUrl('Linkedin')
    form.urlTwitter = findSocialUrl('Twitter')

    if (signature && signature.templateId) {
      const total = signatureTemplates.length
      const isValid = signature.templateId < total && signature.templateId > -1
      form.templateId = isValid ? signature.templateId : 0
    }

    this.setState({
      form,
      uid: _id
    })
  }
}

export default SignatureModal
