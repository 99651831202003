// Import Packages
import * as React from 'react'
import { Grid } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import { SetThemeImage } from 'app/Dashboard/Dashboard'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Customize,
  CustomizeTitle,
  StyledColorGrid,
  StyledFontAwesomeIcon,
  StyledImages,
  WallpaperImg,
  Wallpapers,
} from './Styled'

// Font Awesome Icons
import {
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faTimesCircle])

interface Props {
  defaultUrl: (image: string) => void
  addWallpaperImage: (image: string[]) => void
  deleteImage: (image: string) => void
  wallpaper: string[]
}

interface State {
  fileName: string[]
}

class ImageUpload extends React.Component<Props, State> {
  public textInput: any = React.createRef()

  public state = {
    fileName: []
  }

  public render() {
    const { wallpaper } = this.props
    const wallpaperArr = wallpaper.map((items: string, index: number) => (
      <Grid.Column key={index}>
        <span onClick={() => this.deleteImage(items)}><StyledFontAwesomeIcon icon={['fas', 'times-circle']} /></span>
        <StyledImages background={items} onClick={() => this.setThemeImage(items)} />
      </Grid.Column>
    ))
    return (
      <div>
        <CustomizeTitle>
          <WallpaperImg />
          <span>{Strings.dashboard.wallpaper}</span>
        </CustomizeTitle>
        <Customize>
          <StyledColorGrid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Wallpapers onClick={this.handleFile} />
              </Grid.Column>
              {wallpaperArr}
              <input
                type="file"
                ref={(node: any) => { this.textInput = node }}
                accept="image/*"
                id="hidden-new-file"
                style={{ display: 'none' }}
                name='fileName'
                onChange={this.handleChange}
              />
            </Grid.Row>
          </StyledColorGrid>
        </Customize>
      </div>
    )
  }

  private deleteImage = (image: string) => {
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this image!',
      title: 'Are you sure?',
      type: 'warning',
    }).then(async (result) => {
      if (result.value) {
        const { deleteImage } = this.props
        deleteImage(image)
        ConfirmAlert(
          'Deleted!',
          'Your image has been deleted.',
          'success'
        )
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(
          'Cancelled',
          'Your image is safe',
          'error'
        )
      }
    })
  }

  private handleFile = () => {
    this.textInput.click()
  }

  private uploadImage = () => {
    const { addWallpaperImage } = this.props
    const { fileName } = this.state
    addWallpaperImage(fileName)
  }

  private handleChange = (e: any) => {
    this.setState({ fileName: e.target.files[0] }, () => this.uploadImage())
  }

  private setThemeImage = (image: string) => {
    const { defaultUrl } = this.props
    defaultUrl(image)
    SetThemeImage(image)
  }
}

export default ImageUpload
