// Import Packages
import { debounce } from 'lodash'
import * as React from 'react'
import { Form, Icon, Input, Menu, Select } from 'semantic-ui-react'

// Import Components
import StateDropdown from 'shared/StateDropdown'
import { FiltersData, FilterText, FormErrorType, FormType, Options } from './Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  CloseWrapper,
  Container,
  Filter,
  Filters,
  FiltersTitle,
  PopUpContent,
  StyledContainer,
  StyledForm,
  StyledInput,
  StyledMenu
} from './Styled'

const optionsType = [
  { key: 1, text: 'Buyer', value: 'Buyer' },
  { key: 2, text: 'Seller', value: 'Seller' },
  { key: 3, text: 'Both', value: 'Both' },
  { key: 4, text: 'Renter', value: 'Renter' },
  { key: 5, text: 'Other', value: 'Other' }
]

const optionsSource = [
  { key: 1, text: 'Call', value: 'Call' },
  { key: 2, text: 'Email', value: 'Email' },
  { key: 3, text: 'Trade', value: 'Trade' },
  { key: 4, text: 'Referral', value: 'Referral' },
  { key: 5, text: 'Seminar', value: 'Seminar' },
  { key: 6, text: 'Website', value: 'Website' },
  { key: 7, text: 'Third Party', value: 'Third Party' }
]

const filters = [
  { id: 'Contact Name', name: 'Contact Name', text: 'name' },
  { id: 'Type', name: 'Type', text: 'type' },
  { id: 'Source', name: 'Source', text: 'source' },
  { id: 'Location', name: 'Location', text: 'address' }
]

interface Props {
  filtersContent: (id: string, name: string, text: string, searchData: FiltersData) => void
  closeAdvanceSearchModal: () => void
  filter: (data: FiltersData, state: Options) => void
  data: FiltersData
  filterText: FilterText[]
  selectedCity: Options
  selectedState: Options
}

interface State {
  form: FormType
  activeItem: string
  searchData: FiltersData
  states: Options[]
  selectedState: Options
}

class ContactSearchModal extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    activeItem: '',
    form: {
      city: '',
      errors: {} as FormErrorType,
      state: '',
      streetAddress: '',
      type: '',
      zipCode: ''
    } as FormType,
    searchData: {
      address: {
        state: '',
        zipCode: ''
      },
      firstName: undefined,
      lastName: undefined,
      source: undefined,
      type: undefined
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: []
  }

  public async componentDidMount() {
    const { data, selectedState } = this.props
    this.setState({ searchData: data, selectedState })
  }

  public componentWillReceiveProps(nextProps: any) {
    const { filterText } = this.props
    const lastElement = nextProps.filterText.slice(-1)[0]
    if (lastElement !== undefined) {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: lastElement.content })
      }
    } else {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: '' })
      }
    }
  }

  public render() {
    const { activeItem, searchData } = this.state
    return (
      <StyledContainer>
        <CloseWrapper onClick={this.closeAdvanceSearchModal} />
        <PopUpContent>
          <Container>
            <Filters>
              <FiltersTitle>{Strings.advanceSearchModal.filters}</FiltersTitle>
              <StyledMenu vertical={true}>
                {filters.map((items: any, index: number) => (
                  <Menu.Item
                    key={index}
                    name={items.name}
                    id={items.id}
                    text={items.text}
                    active={activeItem === items.name}
                    onClick={this.handleItemClick}
                  />
                ))}
              </StyledMenu>
            </Filters>
            <StyledForm size={'tiny'}>
              {activeItem === '' && (
                <Filter>
                  <Icon name="filter" />
                  <div>Select Filter</div>
                </Filter>
              )}
              {activeItem === 'Contact Name' && (
                <div>
                  <Form.Field
                    control={Input}
                    label="First Name"
                    name="firstName"
                    value={searchData.firstName}
                    placeholder="First Name"
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    control={Input}
                    label="Last Name"
                    name="lastName"
                    value={searchData.lastName}
                    placeholder="Last Name"
                    onChange={this.handleChange}
                  />
                </div>
              )}
              {activeItem === 'Type' && (
                <Form.Field
                  control={Select}
                  label="Type"
                  name="type"
                  placeholder="Type"
                  value={searchData.type}
                  options={optionsType}
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Location' && (
                <div>
                  <Form.Field childWidths={33.334} className="algolia">
                    <StateDropdown
                      name="state"
                      onChange={this.handleInputChange}
                      placeholder="State"
                      value={searchData.address.state}
                    />
                    <StyledInput
                      className="zipcode"
                      type="text"
                      placeholder="Zip Code"
                      name="zipCode"
                      value={searchData.address.zipCode}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                </div>
              )}
              {activeItem === 'Source' && (
                <Form.Field
                  control={Select}
                  label="Source"
                  name="source"
                  placeholder="Source"
                  value={searchData.source}
                  options={optionsSource}
                  onChange={this.handleChange}
                />
              )}
            </StyledForm>
          </Container>
        </PopUpContent>
      </StyledContainer>
    )
  }

  private handleInputChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    form.errors[name] = ''
    this.setState(
      {
        form,
        searchData: {
          ...this.state.searchData,
          address: {
            state: form.state,
            zipCode: form.zipCode ? form.zipCode : undefined
          }
        }
      },
      () => this.getContactBoardsData()
    )
  }

  private closeAdvanceSearchModal = () => {
    const { closeAdvanceSearchModal } = this.props
    closeAdvanceSearchModal()
  }

  private handleItemClick = (e: React.SyntheticEvent<EventTarget>, { id, name, text }: any) => {
    const { filtersContent } = this.props
    const { searchData } = this.state
    this.setState({ activeItem: name })
    filtersContent(id, name, text, searchData)
  }

  private getContactBoardsData = async () => {
    const { filter } = this.props
    const { searchData, selectedState } = this.state

    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(() => {
      filter(searchData, selectedState)
    }, 1000)

    this.debounceJob()
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { searchData } = this.state
    searchData[name] = value
    this.setState({ searchData }, () => this.getContactBoardsData())
  }
}

export default ContactSearchModal
