export enum ActionEnum {
  Complete = 'mlsUpload:complete',
  Start = 'mlsUpload:start'
}

export enum StatusEnum {
  Active =  'active',
  Inactive = 'inactive',
  Unpaid = 'unpaid',
}
