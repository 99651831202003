import gql from 'graphql-tag'

import {
  officeFragment,
  officeMlsFragment,
  officeUserFragment,
} from './Fragments'

export const GET_OFFICES = gql`
  query getOffices($filter: OfficeSearch) {
    getOffices(
      where: { isActive: true }
      sorting: { order: 1, field: "officeOrder" }
      filter: $filter
    ) {
      ...OfficeFragment
      users {
        ...OfficeUserFragment
        emails {
          value
        }
        phones {
          type
          value
        }
      }
      mls {
        ...OfficeMlsFragment
      }
      isChecked @client
    }
  }
  ${officeFragment}
  ${officeMlsFragment}
  ${officeUserFragment}
`

export const GET_OFFICES_ON_STATES = gql`
  query getOfficesOnStates($state: String, $filter: OfficeSearch) {
    getOffices(
      where: { isActive: true, state: $state }
      sorting: { field: "officeOrder", order: 1 }
      filter: $filter
    ) {
      ...OfficeFragment
      users {
        ...OfficeUserFragment
        emails {
          value
        }
        phones {
          type
          value
        }
      }
    }
  }
  ${officeFragment}
  ${officeUserFragment}
`

export const GET_OFFICES_DETAILS = gql`
  query getOfficeDetails($_id: ID) {
    getOffices(where: { _id: $_id }) {
      ...OfficeFragment
      noOfTransactions
      users {
        ...OfficeUserFragment
        ein
        paymentMethod
        taxReportName
        salesGoal
        contractDocument
        taxID
        phones {
          type
          value
        }
        office {
          _id
          branchName
        }
        dob
        sex
        capSetting {
          _id
          name
        }
        isLoggedIn @client
        userOrder
        group {
          _id
          name
        }
        emails {
          type
          value
        }
        teamFee
        teamCommissionEarn
        teamCommissionSplit
        userCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
          residentialCapStatus
          commercialCapStatus
        }
        transactionFeeSetting {
          _id
          name
        }
        transactionCounter
        leads {
          _id
        }
        createdAt
        bio
        languages
        mlsId
        licenseNo
        licensedExpiration
        contractDocument
        website
        addresses {
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        lastLogin
        roasterName
        teams {
          _id
          name
          leader {
            _id
            profileImage
            addresses {
              type
              streetNumber
              streetName
              city
              state
              country
              zipCode
            }
            phones {
              type
              value
            }
            firstName
            lastName
            userName
            teamFee
            teamCommissionEarn
            teamCommissionSplit
          }
          inviteMember {
            firstName
            lastName
            email
            fee
            commission
            status
            inviteDate
            teamAgreement
          }
          members {
            _id
            profileImage
            addresses {
              type
              streetNumber
              streetName
              city
              state
              country
              zipCode
            }
            phones {
              type
              value
            }
            firstName
            lastName
            userName
            teamFee
            teamCommissionEarn
            teamCommissionSplit
          }
          teamCap {
            residentialTargetCap
            commercialTargetCap
            residentialCompletedCap
            commercialCompletedCap
          }
          teamOrder
          isActive
        }
        social {
          type
          url
        }
      }
      teams {
        _id
        name
      }
    }
  }
  ${officeFragment}
  ${officeUserFragment}
`

export const GET_DEFAULT_OFFICE_DETAILS = gql`
  query getOfficeDetails {
    getOffices(where: { isActive: true, isDefault: true }) {
      ...OfficeFragment
    }
  }
  ${officeFragment}
`

export const GET_USER_OFFICES = gql`
  query getUserOffices($where: UserFilter) {
    getUsers(where: $where) {
      _id
      office {
        _id
        branchName
      }
    }
  }
`
