// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as TaskActions from 'store/Tasks/Actions'
import { TaskCardDetails, TaskDetails } from 'store/Tasks/Types'

// Tasks
import AddNoteModal from 'shared/Tasks/AddNoteModal'
import { shareTaskWith } from 'shared/Tasks/Mutations'
import SetDueDateModal from 'shared/Tasks/SetDueDateModal'
import ShareWithModal from 'shared/Tasks/ShareWithModal'
import { deleteTasks, moveTask } from 'app/Tasks/TaskMutations'
import { editTask } from 'app/Tasks/Tasks'
import { SharedUser } from 'store/Tasks/Types'

// Transactions
import AddDocumentModal from 'shared/Transaction/AddDocumentModal'
import CreateTransactionModal from 'shared/Transaction/CreateTransactionModal'

// Contacts
import AddContactModal from 'app/Contacts/Dashboard/AddContactModal'
import { addNewContact } from 'shared/Contact/Utils'

// Leads
import AddLeadModal from 'app/Leads/Dashboard/AddLeadModal'
import { addNewLead } from 'shared/Lead/Utils'

// Events
import AddEventModal from 'app/Events/AddEventModal'
import { createEvent } from 'app/Tasks/EventMutations'

// Email
import ComposeModal from 'shared/MessageEmail/ComposeModal'

// Users
import { genericGetUsers } from 'shared/Users/Queries'

import { isPlanAllowed } from 'shared/Billing/Queries'
import { serverToast } from 'shared/Toast/Toast'
import { getLoggedInUser, Strings } from 'utils'

import { Action, Container, Options, SubAction } from './Styled'

// Font Awesome Icons
import {
  faAddressBook,
  faCalendarAlt,
  faCheck,
  faEllipsisVAlt,
  faFileInvoiceDollar,
  faMale,
  faPencilAlt,
  faPlusCircle,
  faReply,
  faReplyAll,
  faShare,
  faStickyNote,
  faTrashAlt
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faAddressBook,
  faCalendarAlt,
  faCheck,
  faEllipsisVAlt,
  faFileInvoiceDollar,
  faMale,
  faPencilAlt,
  faPlusCircle,
  faReply,
  faReplyAll,
  faShare,
  faStickyNote,
  faTrashAlt
])

interface ButtonsType {
  complete: boolean
  create: boolean
  edit: boolean
  forward: boolean
  options: boolean
  reply: boolean
  replyAll: boolean
  trash: boolean
}

interface Props {
  actions: string[]
  boardData: TaskDetails[]
  boardId: string
  cardData: TaskCardDetails
  deleteCard: (boardId: string, cardId: string) => void
  getBoardData: (data: TaskDetails[]) => void
  updateTaskCard: (task: any) => void
  vertical?: boolean
}

interface State {
  buttons: ButtonsType
  modal: any
  showEllipsisOptions: boolean
  showModal: boolean
  showPlusOptions: boolean
}

class ActionButtons extends React.Component<Props, State> {
  public state = {
    buttons: {
      complete: false,
      create: false,
      delegateTo: false,
      edit: false,
      forward: false,
      options: false,
      reply: false,
      replyAll: false,
      setDueDate: false,
      trash: false
    },
    modal: null,
    showEllipsisOptions: false,
    showModal: false,
    showPlusOptions: false,
    transition: true
  }

  public componentDidMount = () => {
    const { buttons } = this.state
    this.props.actions.forEach(action => {
      buttons[action] = true
    })

    // TODO: track complete status
    // const { boardData, boardId } = this.props
    // const currentBoard = boardData.find((board:TaskDetails) => board._id === boardId)
    // if (currentBoard && this.isDoneBoard(currentBoard)) {
    //   buttons.complete = false
    // }
    this.setState({ buttons })
  }

  public render = () => {
    const {
      cardData: { messageEmail }
    } = this.props
    const { actions, vertical } = this.props
    const {
      buttons: { create, complete, edit, forward, options, reply, replyAll, trash }
    } = this.state
    const { modal, showModal, showEllipsisOptions, showPlusOptions } = this.state
    return (
      <Container vertical={vertical} total={actions.length}>
        {create && (
          <Options
            trigger={
              <Action>
                <Icon icon={['fal', 'plus-circle']} />
              </Action>
            }
            on="click"
            open={showPlusOptions}
            onOpen={this.openPlusOptions}
            onClose={this.closePlusOptions}
          >
            <SubAction onClick={this.createTransaction}>
              <span>
                <Icon icon={['fal', 'file-invoice-dollar']} />
              </span>
              Create Transaction
            </SubAction>
            <SubAction onClick={this.createContact}>
              <span>
                <Icon icon={['fal', 'address-book']} />
              </span>
              Create Contact
            </SubAction>
            <SubAction onClick={this.createLead}>
              <span>
                <Icon icon={['fal', 'male']} />
              </span>
              Create Lead
            </SubAction>
            <SubAction onClick={this.createEvent}>
              <span>
                <Icon icon={['fal', 'calendar-alt']} />
              </span>
              Create Event
            </SubAction>
          </Options>
        )}
        {edit && (
          <Action onClick={this.editTask}>
            <Icon icon={['fal', 'pencil-alt']} />
          </Action>
        )}
        {complete &&
          this.props.cardData[`status`] !== 'Completed' && (
            <Action onClick={this.completeTask}>
              <Icon icon={['fal', 'check']} />
            </Action>
          )}
        {messageEmail &&
          reply && (
            <Action onClick={this.replyMessage}>
              <Icon icon={['fal', 'reply']} />
            </Action>
          )}
        {messageEmail &&
          replyAll && (
            <Action onClick={this.replyAllMessage}>
              <Icon icon={['fal', 'reply-all']} />
            </Action>
          )}
        {messageEmail &&
          forward && (
            <Action onClick={this.forwardMessage}>
              <Icon icon={['fal', 'share']} />
            </Action>
          )}
        {options && (
          <Options
            trigger={
              <Action>
                <Icon icon={['fal', 'ellipsis-v-alt']} />
              </Action>
            }
            hoverable={true}
            open={showEllipsisOptions}
            onOpen={this.openEllipsisOptions}
            onClose={this.closeEllipsisOptions}
          >
            <SubAction onClick={this.setDueDate}>
              <span>
                <Icon icon={['fal', 'calendar-alt']} />
              </span>
              Set Due Date
            </SubAction>
            <SubAction onClick={this.addNote}>
              <span>
                <Icon icon={['fal', 'sticky-note']} />
              </span>
              Add Note
            </SubAction>
            <SubAction onClick={this.delegateTo}>
              <span>
                <Icon icon={['fal', 'male']} />
              </span>
              Delegate To
            </SubAction>
            {messageEmail &&
              messageEmail.attachments.length > 0 && (
                <SubAction onClick={this.addToTransaction}>
                  <span>
                    <Icon icon={['fal', 'file-invoice-dollar']} />
                  </span>
                  Add to Transaction
                </SubAction>
              )}
          </Options>
        )}
        {trash && (
          <Action onClick={this.deleteCard}>
            <Icon icon={['fal', 'trash-alt']} />
          </Action>
        )}
        {showModal && modal}
      </Container>
    )
  }

  private closeModal = () => {
    this.setState({ showModal: false })
  }

  private closePlusOptions = () => {
    this.setState({ showPlusOptions: false })
  }

  private openPlusOptions = () => {
    this.setState({ showPlusOptions: true })
  }

  private closeEllipsisOptions = () => {
    this.setState({ showEllipsisOptions: false })
  }

  private openEllipsisOptions = () => {
    this.setState({ showEllipsisOptions: true })
  }

  private isDoneBoard = (board: TaskDetails) => {
    return board.name.toLowerCase() === 'done' || board.name.toLowerCase() === 'completed'
  }

  private completeTask = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { boardData: boards, boardId, cardData, getBoardData } = this.props

    const sourceBoardIndex = boards.findIndex((board: TaskDetails) => board._id === boardId)
    const doneBoardIndex = boards.findIndex((board: TaskDetails) => this.isDoneBoard(board))
    if (sourceBoardIndex < 0 || doneBoardIndex < 0) {
      return
    }

    const sourceBoard = boards[sourceBoardIndex]
    const doneBoard = boards[doneBoardIndex]
    const sourceCardIndex = sourceBoard.items.findIndex((item: any) => item._id === cardData._id)
    if (sourceCardIndex < 0) {
      return
    }

    const sourceClone = sourceBoard.items.slice()
    const doneClone = doneBoard.items.slice()
    const [removed] = [{ ...sourceClone.splice(sourceCardIndex, 1)[0], status: 'Completed' }]

    doneClone.splice(0, 0, removed)

    const resultState = boards.slice()

    resultState[sourceBoardIndex] = {
      ...resultState[sourceBoardIndex],
      items: sourceClone
    }

    resultState[doneBoardIndex] = {
      ...resultState[doneBoardIndex],
      items: doneClone
    }

    const user = await getLoggedInUser()
    if (cardData.createdBy._id === user[`_id`] || user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
      await moveTask(cardData._id, doneBoard._id, 0, user, false)
      getBoardData(resultState)
    }
  }

  private editTask = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { cardData } = this.props
    editTask(cardData)
  }

  private replyMessage = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const {
      cardData: { messageEmail }
    } = this.props
    const modal = <ComposeModal messageData={messageEmail} onClose={this.closeModal} composerType="REPLY" />
    this.setState({
      modal,
      showModal: true
    })
  }

  private replyAllMessage = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const {
      cardData: { messageEmail }
    } = this.props
    const modal = <ComposeModal messageData={messageEmail} onClose={this.closeModal} composerType="REPLY-ALL" />
    this.setState({
      modal,
      showModal: true
    })
  }

  private forwardMessage = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const {
      cardData: { messageEmail }
    } = this.props
    const modal = <ComposeModal messageData={messageEmail} onClose={this.closeModal} composerType="FORWARD" />
    this.setState({
      modal,
      showModal: true
    })
  }

  private deleteCard = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    const { boardId, cardData, deleteCard } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.kanbanView.recoverCard,
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        const response = await deleteTasks([cardData._id])
        if (response) {
          deleteCard(boardId, cardData._id)
        }
        ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
      }
    })
  }

  private createTransaction = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    try {
      await isPlanAllowed('transaction')
    } catch (error) {
      return serverToast(error)
    }

    this.closePlusOptions()
    const modal = <CreateTransactionModal onClose={this.closeModal} />

    this.setState({
      modal,
      showModal: true
    })
  }

  private createContact = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closePlusOptions()

    const user = await getLoggedInUser()
    const {
      cardData: { messageEmail }
    } = this.props
    let edit: boolean = false
    let autoDetails: any = {}
    if (messageEmail) {
      const { from } = messageEmail
      const name = from.name && from.name.split(' ')
      const firstName = (name && name[0]) || ''
      const lastName = (name && name[1]) || ''
      autoDetails = {
        email: from.value || '',
        firstName,
        lastName,
        source: 'Email'
      }
      edit = true
    }

    const modal = (
      <AddContactModal
        addBoardId=""
        closeModal={this.closeModal}
        createContact={addNewContact}
        edit={edit}
        editContactDetails={autoDetails}
        filterOptions={false}
        user={user}
      />
    )

    this.setState({
      modal,
      showModal: true
    })
  }

  private createLead = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closePlusOptions()

    const {
      cardData: { messageEmail }
    } = this.props
    let edit: boolean = false
    let autoDetails: any = {}
    if (messageEmail) {
      const { from } = messageEmail
      const name = from.name && from.name.split(' ')
      const firstName = (name && name[0]) || ''
      const lastName = (name && name[1]) || ''
      autoDetails = {
        email: from.value || '',
        firstName,
        lastName,
        source: 'Email'
      }
      edit = true
    }

    const modal = (
      <AddLeadModal
        addBoardId=""
        leadPool={false}
        addLead={addNewLead}
        closeModal={this.closeModal}
        edit={edit}
        editDetails={autoDetails}
      />
    )
    this.setState({
      modal,
      showModal: true
    })
  }

  private createEvent = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closePlusOptions()

    const addNewEvent = async (event: any) => {
      await createEvent(event, false)
    }

    const modal = (
      <AddEventModal
        closeModal={this.closeModal}
        addEvent={addNewEvent}
        selectedData={{}}
        edit={false}
        editEventObj={{}}
      />
    )

    this.setState({
      modal,
      showModal: true
    })
  }

  private setDueDate = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closeEllipsisOptions()

    const { cardData } = this.props
    const modal = <SetDueDateModal onClose={this.closeModal} taskData={cardData} />

    this.setState({
      modal,
      showModal: true
    })
  }

  private addNote = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closeEllipsisOptions()

    const { cardData } = this.props
    const modal = <AddNoteModal onClose={this.closeModal} taskData={cardData} />

    this.setState({
      modal,
      showModal: true
    })
  }

  private delegateTo = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closeEllipsisOptions()

    const { cardData, updateTaskCard } = this.props

    const handleShare = async (newUser: SharedUser) => {
      const updatedTask = await shareTaskWith(cardData._id, newUser)
      if (updatedTask) {
        updateTaskCard(updatedTask)
      }
    }

    const modal = (
      <ShareWithModal
        onClose={this.closeModal}
        onShare={handleShare}
        sharedUsers={cardData.sharedWith as SharedUser[]}
      />
    )
    this.setState({
      modal,
      showModal: true
    })
  }

  private addToTransaction = async (e: React.MouseEvent<{}>) => {
    e.stopPropagation()
    this.closeEllipsisOptions()

    const {
      cardData: {
        messageEmail: { attachments, from }
      }
    } = this.props
    let transactionOwner: any = null
    if (from.value) {
      transactionOwner = await genericGetUsers({ userName: from.value })
    }
    if (transactionOwner && transactionOwner.length === 1) {
      transactionOwner = transactionOwner[0]
    }
    const modal = <AddDocumentModal documents={attachments} onClose={this.closeModal} owner={transactionOwner} />
    this.setState({
      modal,
      showModal: true
    })
  }
}

const mapStateToProps = (state: AppState) => ({
  boardData: state.tasks.data
})

export default connect(
  mapStateToProps,
  {
    deleteCard: TaskActions.deleteCard,
    getBoardData: TaskActions.getBoardData,
    updateTaskCard: TaskActions.updateCard
  }
)(ActionButtons)
