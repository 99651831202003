const Actions = {
  discardFeedMessage: 'DISCARD_FEED_MESSAGE',
  discardUpdateMessage: 'DISCARD_UPDATE_MESSAGE',
  getFeedNotification: 'GET_FEED_NOTIFICATION',
  getUpdateMessage: 'GET_UPDATE_NOTIFICATION',
  readFeedMessage: 'READ_FEED_MESSAGE',
  readUpdateMessage: 'READ_UPDATE_MESSAGE',
  showNotificationCount: 'SHOW_NOTIFICATION_COUNT'
}

export default Actions
