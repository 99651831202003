import gql from 'graphql-tag'

export const GET_USER_PROFILE = gql`
  query getUserProfile($id: ID) {
    getUsers(where: { _id: $id, isActive: true }) {
      _id
      accountNumber
      bankName
      routingNumber
      firstName
      lastName
      userName
      ein
      paymentMethod
      taxReportName
      salesGoal
      contractDocument
      taxID
      phones {
        type
        value
      }
      hasTeam
      office {
        _id
        branchName
      }
      notificationSetting {
        category
        key
        title
        inApp
        email
        inAppSound
      }
      isPrimaryManager
      dob
      role
      sex
      profileImage
      status
      capSetting {
        _id
        name
        capStatus
      }
      isLoggedIn @client
      userOrder
      group {
        _id
        name
      }
      emails {
        type
        value
      }
      tenant
      teamFee
      teamCommissionEarn
      teamCommissionSplit
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      referrals {
        _id
        firstName
        lastName
        userName
        profileImage
        createdAt
        isPaid
        phones {
          type
          value
        }
      }
      referralUrl
      transactionFeeSetting {
        _id
        name
      }
      transactionCounter
      leads {
        _id
      }
      joiningDate
      createdAt
      bio
      languages
      mlsId
      licenseNo
      licensedExpiration
      contractDocument
      website
      addresses {
        type
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      lastLogin
      roasterName
      teams {
        _id
        name
        leader {
          _id
          profileImage
          addresses {
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          phones {
            type
            value
          }
          firstName
          lastName
          userName
          teamFee
          teamCommissionEarn
          teamCommissionSplit
        }
        inviteMember {
          firstName
          lastName
          email
          fee
          commission
          status
          inviteDate
          teamAgreement
        }
        members {
          _id
          profileImage
          addresses {
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          phones {
            type
            value
          }
          firstName
          lastName
          userName
          teamFee
          teamCommissionEarn
          teamCommissionSplit
        }
        teamCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
        }
        teamOrder
        isActive
      }
      social {
        type
        url
      }
    }
  }
`

export const GET_USER_CREDIT_DEBIT = gql`
  query getUserCreditDebit($userId: String, $status: String) {
    getUserCreditDebits(where: { user: $userId, status: $status }) {
      _id
      feeName
      amount
      transactionType
      createdAt
      status
      user {
        _id
        firstName
        lastName
      }
      transactionId
    }
  }
`

export const GET_USER_SIGNATURE_DATA = gql`
  query getUserSignatureData($id: ID) {
    userSignature: getUsers(where: { _id: $id, isActive: true }) {
      addresses {
        city
        state
        streetName
        streetNumber
        type
      }
      firstName
      lastName
      office {
        branchName
        city
        isDefault
        state
        street
      }
      phones {
        type
        value
      }
      profileImage
      role
      signature {
        address
        colorLink
        colorText
        companyPosition
        fontFamily
        fontSize
        name
        nameTitle
        phone
        profileImage
        templateId
        urlFacebook
        urlLinkedin
        urlTwitter
        urlWebsite
        company
      }
      social {
        type
        url
      }
      website
    }
  }
`

export const USER_PROFILE_COUNTER = gql`
  query userProfileCounter($userId: String) {
    userProfileCounter(_id: $userId)
  }
`

export const GET_ALL_FOLDER_FILES = gql`
  query getAllFolderFiles($userId: String) {
    getAllFolderFiles(_id: $userId)
  }
`

export const GET_LINKS = gql`
  query getLinks($userId: ID) {
    getLinks(where: { user: $userId }) {
      _id
      name
      url
      user {
        _id
      }
      tenant {
        _id
      }
    }
  }
`
