import Colors from 'design/Colors'
import { Tab } from 'semantic-ui-react'
import styled from 'styled-components'

const TransactionFeeDetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  background-color: white;
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .add-plan {
    position: absolute;
    top: 14px;
    right: 30px;
    display: flex;
  }

  .ui.button {
    font-size: 0.8rem;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    margin-right: 15px;
  }
`

const StyledTab = styled(Tab)`
  height: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 15px;

  .ui.secondary.pointing.menu .active.item {
    font-size: 16px;
    color: ${Colors.DarkBlue200};
    border-bottom: 3px solid ${Colors.DarkBlue200};
    padding-left: 0;
    padding-right: 0;
    margin-right: 30px;
    margin-left: 16px;
  }

  .ui.secondary.pointing.menu .item {
    color: ${Colors.Black500};
    padding-left: 0;
    padding-right: 0;
    margin-right: 30px;
    margin-left: 16px;
    font-weight: 600;
  }

  .ui.secondary.pointing.menu {
    font-size: 16px;
    margin-bottom: 0;
  }
`

export {
  Container,
  Modal,
  StyledTab,
  TransactionFeeDetailsWrapper
}
