// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  .ui.secondary.pointing.menu {
    border-bottom: 1px solid ${Colors.DarkBlue200};
  }

  .ui.secondary.pointing.menu .item {
    color: ${Colors.Black550};
    border-bottom-style: none;
  }

  .ui.secondary.pointing.menu .active.item {
    color: ${Colors.Teal60};
  }
`

export { Container }
