// Import Packages
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const StyledGrid = styled(Grid)`
  &.ui.grid {
    margin-right: 2rem;
    margin-left: 1rem;
    margin-top: 0;
  }

  &.ui.grid>.row.buttons {
    text-align: end;
  }

  &.ui.grid>.row>.column>.ui.button {
    border-radius: 0;
    margin-right: 25px;
    font-size: 12px;
  }

  &.ui.grid>.row>.column>.ui.button.preview {
    background-color: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    width: 155px;
  }
`

export {
  StyledGrid
}

