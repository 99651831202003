import { ActionCreator } from 'redux'
import Actions from 'store/Reports/ActionsTypes'
import {
  ApplyFilter,
  MyReportDetails,
  MyReportModule,
  PickData,
  ReportAddNewMyReport,
  ReportAddNewTransaction,
  ReportApplyFilter,
  ReportChangeKey,
  ReportColumnMetaData,
  ReportDates,
  ReportDeleteMyReport,
  ReportDeleteTransaction,
  ReportFilter,
  ReportGeneratedID,
  ReportGetMyReport,
  ReportGetTransaction,
  ReportModule,
  ReportPickData,
  ReportResetFilterData,
  ReportShowCity,
  ReportShowState,
  ReportShowSystemReport,
  ReportToggle,
  TransactionDetails,
  TransactionModule,
  ViewReport
} from './Types'

// My Report Module
export const addNewMyReport: ActionCreator<ReportAddNewMyReport> = (
  module: string,
  newData: MyReportDetails
) => ({
  payload: {
    module,
    newData
  },
  type: Actions.reportAddNewMyReport
})

export const getReportData: ActionCreator<ReportGetMyReport> = (
  data: MyReportModule[]
) => ({
  payload: {
    data
  },
  type: Actions.reportGetReportData
})

export const deleteMyReport: ActionCreator<ReportDeleteMyReport> = (
  moduleId: string,
  reportId: string
) => ({
  payload: {
    moduleId,
    reportId
  },
  type: Actions.reportDeleteMyReport
})

// Transaction Module
export const addNewTransaction: ActionCreator<ReportAddNewTransaction> = (
  module: string,
  newData: TransactionDetails
) => ({
  payload: {
    module,
    newData
  },
  type: Actions.reportAddNewTransaction
})

export const getTransactionData: ActionCreator<ReportGetTransaction> = (
  data: TransactionModule[]
) => ({
  payload: {
    data
  },
  type: Actions.reportGetTransaction
})

export const deleteTransaction: ActionCreator<ReportDeleteTransaction> = (
  moduleId: string,
  reportId: string
) => ({
  payload: {
    moduleId,
    reportId
  },
  type: Actions.reportDeleteTransaction
})

export const viewReport: ActionCreator<ViewReport> = (report: string) => ({
  payload: {
    report
  },
  type: Actions.viewReport
})

export const getFilter: ActionCreator<ReportFilter> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.reportGetFilter
})

export const getPickData: ActionCreator<ReportPickData> = (
  data: PickData[]
) => ({
  payload: {
    data
  },
  type: Actions.reportGetPickData
})

export const getApplyFilter: ActionCreator<ReportApplyFilter> = (
  data: ApplyFilter[]
) => ({
  payload: {
    data
  },
  type: Actions.reportGetApplyFilter
})

export const getColumnData: ActionCreator<ReportColumnMetaData> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.reportColumnMetaData
})

export const perviewModule: ActionCreator<ReportModule> = (data: string) => ({
  payload: {
    data
  },
  type: Actions.reportModule
})

export const datesFilters: ActionCreator<ReportDates> = (data: object) => ({
  payload: {
    data
  },
  type: Actions.reportDatesFilters
})

export const resetFilterData: ActionCreator<ReportResetFilterData> = (
  reset: boolean
) => ({
  payload: {
    reset
  },
  type: Actions.reportResetDataFilter
})

export const changeKey: ActionCreator<ReportChangeKey> = (key: number) => ({
  payload: {
    key
  },
  type: Actions.reportChangeKey
})

export const changeToggle: ActionCreator<ReportToggle> = (toggle: object) => ({
  payload: {
    toggle
  },
  type: Actions.reportChangeToggle
})

export const showState: ActionCreator<ReportShowState> = (state: any) => ({
  payload: {
    state
  },
  type: Actions.reportState
})

export const showCity: ActionCreator<ReportShowCity> = (city: any) => ({
  payload: {
    city
  },
  type: Actions.reportCity
})

export const showSystemReport: ActionCreator<ReportShowSystemReport> = (
  module: any
) => ({
  payload: {
    module
  },
  type: Actions.systemReport
})

export const showGeneratedReportId: ActionCreator<ReportGeneratedID> = (
  id: string
) => ({
  payload: {
    id
  },
  type: Actions.reportGeneratedID
})
