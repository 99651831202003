import * as React from 'react'
import { Input } from 'semantic-ui-react'
import Responsive from 'semantic-ui-react/dist/commonjs/addons/Responsive'
import {
  ItemContainer,
  Label,
  StyledInput,
} from '../Styled'
import {
  TextBoxItem as TextBoxContainer,
} from './Styled'

const TextBoxItem = ({
  label,
  placeholder,
  value,
  width,
  onChange
}: {
  label: string
  placeholder: string
  value: number
  width?: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <Responsive as={ItemContainer}>
      <TextBoxContainer>
        <Label>{label}</Label>
        <StyledInput><Input type='number' icon='dollar' iconPosition='left' value={value} onChange={onChange} /></StyledInput>
      </TextBoxContainer>
    </Responsive>
  )
}

export default TextBoxItem
