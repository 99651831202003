import client from 'queries/apollo'

import { InviteUserParams, TenantUpdateParams } from './Types'

import { getOrCreateCityState } from 'shared/AddressInput/Mutations'

import { ACTIVATE_ACCOUNT } from 'queries/graphql/Account/Mutations'
import { ONBOARD_TENANT_DETAILS, UPDATE_TENANT } from 'queries/graphql/Onboarding/Mutation'
import { CREATE_USER, UPDATE_USER } from 'queries/graphql/Users/Mutation'

export const onboardTenantDetails = async (input: TenantUpdateParams) => {
  const {
    adminEmail,
    companyName,
    companyType,
    domainName,
    homeAddress,
    mls,
    officeAddress,
    officeAddress: { city, country, state, streetName, streetNumber, zipCode },
    officeEmail,
    officePhone,
    realtyPassEmail,
    userId
  } = input

  const street = `${streetNumber} ${streetName}`

  await client.mutate({
    mutation: UPDATE_TENANT,
    variables: {
      input: {
        billingAddress: officeAddress,
        companyName,
        companyType,
        domainName,
        shippingAddress: officeAddress
      }
    }
  })

  const tenant: any = await client.mutate({
    mutation: ONBOARD_TENANT_DETAILS,
    variables: {
      adminEmail,
      city,
      companyName,
      country,
      homeAddress,
      mls,
      officeAddress,
      officeEmail,
      officePhone,
      realtyPassEmail,
      state,
      street,
      userId,
      zipCode
    }
  })

  await getOrCreateCityState({
    city,
    state
  })

  const {
    office: { _id: officeId }
  } = tenant.data

  const response = await client.mutate({
    mutation: UPDATE_USER,
    variables: {
      _id: userId,
      isActive: true,
      officeId,
      status: 'Active'
    }
  })

  return response.data.updateUser
}

export const inviteNewUser = async (input: InviteUserParams) => {
  const response = await client.mutate({
    mutation: CREATE_USER,
    variables: { ...input }
  })

  return response.data.createUser
}

export const activateAccount = async () => {
  const response = await client.mutate({
    mutation: ACTIVATE_ACCOUNT
  })

  return response.data.activateAccount
}
