// Import Packages
import moment from 'moment'
import * as React from 'react'

// Import Utils
import Strings from 'utils/Strings'

// Import Styled Components
import { Infograph, ListItem, StyledHeader, StyledList, StyledListContent, StyledListHeader } from './Styled'

// Font Awesome Icons
import { faUsdCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faUsdCircle])

const ReferralPopupStrings = Strings.userProfile.referralPopup

interface ReferralItem {
  _id: string
  firstName: string
  lastName: string
  userName: string
  createdAt: string
  isPaid: boolean
  phones: {
    type: string
    value: string
  }
}

interface Props {
  data: ReferralItem[]
}

export default class ReferralsList extends React.Component<Props, {}> {
  public state = {}

  public render() {
    const { data } = this.props
    const listItems = data.map((dataItem: ReferralItem, index) => {
      return (
        <ListItem key={index}>
          <StyledListContent>
            <StyledListHeader isToggled={dataItem.isPaid}>
              {`${dataItem.firstName} ${dataItem.lastName}`}
              <FontAwesomeIcon icon={['fas', 'usd-circle']} />
            </StyledListHeader>
            {`${dataItem.userName}  | Joined Date : ${moment(new Date(dataItem.createdAt)).format('L')}`}
          </StyledListContent>
        </ListItem>
      )
    })
    return (
      <div>
        <StyledHeader>{ReferralPopupStrings.referralsList.title}</StyledHeader>
        <StyledList celled={true}>
          {listItems.length !== 0 ? listItems : <Infograph background={Strings.generalText.nothing} />}
        </StyledList>
      </div>
    )
  }
}
