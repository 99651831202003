import styled from 'styled-components'

import Colors from 'design/Colors'
import { defaultSize, fontStyle } from 'design/Fonts'
import { y } from 'design/Styled'

export const AppWrapper = styled.main`
  ${y};
  height: 100%;
  font-size: ${defaultSize};
  font-family: ${fontStyle};
  overflow: hidden;
`

export const Section = styled.section`
  ${y};
  flex: 1;
  overflow: hidden;
  background-color: ${Colors.EmphasisContainer};
`
