import { Dropdown, Input, TextArea } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

interface Props {
  justify?: string
  padding?: number
  width?: number
}

const scrollSize = 2
const scrollPadding = 8

const x = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: Props) => props.justify || 'flex-start'};
  width: ${(props: Props) => props.width || 100}%;
`

const y = css`
  display: flex;
  flex-direction: column;
  width: ${(props: Props) => props.width || 100}%;
  padding: ${(props: Props) => props.padding || 0}em;
`

const text = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Text};
  margin: 0;
  line-height: 1;
`

const children = css`
  & > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  & > :first-child {
    margin-left: 0;
  }

  & > :last-child {
    margin-right: 0;
  }
`

const icon = css`
  display: flex;
  padding: 0.25em 1em;
  transform: scale3d(0.9, 0.9, 0.9);
  transition: transform, color 200ms;
  cursor: pointer;

  .svg-inline--fa {
    height: 100%;
  }

  &:hover {
    transform: scale3d(1, 1, 1);
  }
`

const tableRow = css`
  ${x};
  ${children};
  padding: 1em 0.6em;
  margin-bottom: 0.25em;
  border: 1px solid ${Colors.transparent};
`

export const Container = styled.div`
  ${text};
  display: flex;
  height: 100%;
  overflow: hidden;
  background: ${Colors.Container};
`

export const ChecklistSection = styled.section`
  ${y};
  background: ${Colors.EmphasisContainer};
`

export const PDFSection = styled.div`
  ${y};
  .rp-dropzone-container {
    padding: 1em;
  }
`

export const PDFChildren = styled.div`
  ${y};
  height: 100%;
`

export const Title = styled.h1`
  ${x};
  ${text};
  font: ${fonts.xLarge.bold};
  text-align: left;
  border-bottom: 2px solid ${Colors.EmphasisLine}
  margin-bottom: 0.25em;
  padding: 0.6em;
`

export const Header = styled.header`
  ${tableRow};
  width: 100%;
  width: calc(100% - 8px);
  span {
    ${text};
    font: ${fonts.medium.medium};
  }
`

export const Checklist = styled.section`
  ${y};
  flex: 1;
  overflow: auto;
  margin-bottom: 1em;
  padding-right: ${scrollPadding}px;

  & > :nth-child(even) {
    background: ${Colors.ListEven};
  }

  ::-webkit-scrollbar {
    width: ${scrollSize}px;
    height: ${scrollSize}px;
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.transparent};
  }
`

export const Col = styled.div`
  ${x};
`

export const Item = styled.div`
  ${tableRow};
  position: relative;
  background: ${Colors.ListOdd};
  border: 1px dashed ${Colors.EmphasisLine};
  flex-wrap: wrap;
`

export const Row = styled.div`
  ${x};
`

export const Labels = styled.div`
  ${x};
  flex-wrap: wrap;
  margin-top: 1em;
  padding-top: 1em;
  font: ${fonts.small.regular};
  border-top: 1px solid ${Colors.EmphasisLine};

  & > div {
    margin: 0.25em 0;
  }

  strong {
    padding: 0.2em 0.4em;
    margin-right: 0.5em;
    font-weight: normal;
    color: ${Colors.MarkupLabelText};
    background: ${Colors.MarkupLabelFill};
  }
`

export const ItemTitle = styled.a`
  ${x};
  cursor: pointer;
  overflow: hidden;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ItemTitleInput = styled(Input)`
  &.ui.input > input {
    width: 100%;
  }
`

export const DescriptionCol = styled.div`
  ${x};
  overflow: hidden;
  white-space: pre;
`
export const Description = styled.p`
  ${text};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DescriptionInput = styled(TextArea)`
  ${x};
  ${text};
  outline: none;
  padding: 0.5em;
  border: 1px solid ${Colors.EmphasisLine}
  border-radius: 4px;
`

export const RoleSelector = styled(Dropdown)`
  flex: 1;
`

export const ActionCol = styled.span`
  ${x};
  width: auto;
`

export const Footer = styled.footer`
  ${x};
  padding-right: ${scrollPadding + scrollSize}px;
`

export const AddButton = styled(Item)`
  cursor: pointer;
`

export const Grip = styled.div`
  ${icon};
  cursor: grab;
`

export const Edit = styled.div`
  ${icon};
  padding: 0.25em 0.5em;
`

export const Trash = styled.div`
  ${icon};
  padding: 0.25em 0.5em;
`

export const Nav = styled.nav`
  ${icon};
  color: ${Colors.TextLink};
`

export const PDFHeader = styled.header`
  ${x};
  width: 100%;
  padding: 0 1em 1em;
  margin-bottom: 1em;
  border-bottom: 2px solid ${Colors.EmphasisLine}
  justify-content: center;
`

export const PDFTitle = styled.h2`
  ${text};
  font: ${fonts.large.regular};
  color: ${Colors.TextLink};
  text-align: center;
`

export const DropNotice = styled.p`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;

  span {
    ${text};
    text-align: center;
    padding: 1em 1em 0.5em;
  }
`
