import gql from 'graphql-tag'

export const GET_TAX_DATA = gql`
  query getTaxData {
    offices: getOffices(
      where: { isActive: true }
      sorting: { order: 1, field: "city" }
    ) {
      _id
      branchName
      city
      state
    }

    cities: getCities {
      _id
      name
      state {
        _id
        name
        tax
      }
      tax
    }
  }
`
