// Import Packages
import moment from 'moment'
import { Component } from 'react'
import * as React from 'react'
import ReactHtmlParser from 'react-html-parser'
import InputMask from 'react-input-mask'
import ReactQuill, { Quill } from 'react-quill'
import { Button, Form, Input } from 'semantic-ui-react'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'

// Import Images and Icons
import IconUserCircle from 'design/icons/userProfile/basicDetails/user_circle.png'

// Import Components
import Toast from 'shared/Toast'
import DatePicker from 'shared/DatePicker'
import { UpdateProgress } from '../../../UserProfileComponent'
import AddressFormModal from './AddressForm'
import DetailItem from './DetailItem'
import InputItem from './InputItem'

import { UserProfileType } from 'store/UserProfile/Types'

// Import Graphql Queries
import { AddressPropType } from './AddressForm/Types'

// Import Utils
import Strings from 'utils/Strings'

// Import Styled Components
import {
  AddressContainer,
  BioAddressContainer,
  // BioContainer,
  Content,
  ContentHeader,
  // ContentHeaderAction,
  DescriptionContainer,
  HeaderItem,
  ImageWrapper,
  LeftColumn,
  QuillWrapper,
  RightColumn,
  StyledContainer,
  StyledDivider,
  StyledGrid,
  StyledHeader,
  StyledIcon,
  StyledSegment,
  TextBox,
  TitleContainer
} from './Styled'

// Font Awesome Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faFacebookF, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import {
  faCheck,
  faEnvelope,
  faGlobe,
  faHome,
  faHotel,
  faMobileAlt,
  faPencilAlt,
  faPhone,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons'
import { updateProfile } from '../../../UserProfileMutations'
library.add(
  fab,
  faUserCircle,
  faPencilAlt,
  faHome,
  faHotel,
  faPhone,
  faMobileAlt,
  faEnvelope,
  faGlobe,
  faFacebookF,
  faLinkedin,
  faTwitter,
  faCheck
)

const BasicDetailsStrings = Strings.userProfile.detailSection.basicDetails

interface State {
  editingBio: boolean
  editedAddress: object
  editingHomeAddress: boolean
  editingOfficeAddress: boolean
  editingPhoneNo: boolean
  editingMobilePhoneNo: boolean
  editingRosterName: boolean
  editingLicenceExpiration: boolean
  editingLicenceNo: boolean
  editingMlsId: boolean
  editingWebsite: boolean
  basicDetails: object
  updatedData: object
  subRole: any[]
  isOpen: boolean
  editorHtml: string
  theme: string
}

interface Props {
  userProfile: UserProfileType
  showEditButton: string
}

const icons = Quill.import('ui/icons')
const bold = 'bold'
icons[bold] = ''
const italic = 'italic'
icons[italic] = ''
const list = 'list'
icons[list] = ''

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic'],
    [
      { align: ['', 'center', 'right', 'justify'] },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image']
  ]
}

const quillFormats = ['header', 'bold', 'italic', 'align', 'list', 'bullet', 'indent', 'link', 'image']

class BasicDetails extends Component<Props, State> {
  public state = {
    basicDetails: {},
    editedAddress: {},
    editingBio: false,
    editingHomeAddress: false,
    editingLicenceExpiration: false,
    editingLicenceNo: false,
    editingMlsId: false,
    editingMobilePhoneNo: false,
    editingOfficeAddress: false,
    editingPhoneNo: false,
    editingRosterName: false,
    editingWebsite: false,
    isOpen: false,
    subRole: ['ADMIN', 'MANAGER', 'USER'],
    updatedData: {},

    editorHtml: '',
    theme: 'snow'
  }

  public componentDidMount() {
    if (this.props.userProfile) {
      this.setState({ basicDetails: this.props.userProfile })
    }
  }

  public shouldComponentUpdate(nextProps: {}, nextState: State) {
    if (!Object.is(this.state, nextState) || !Object.is(this.props, nextProps)) {
      return true
    } else {
      return false
    }
  }

  public render() {
    const {
      subRole,
      basicDetails,
      editedAddress,
      editingBio,
      editingHomeAddress,
      editingLicenceExpiration,
      editingLicenceNo,
      editingMlsId,
      editingMobilePhoneNo,
      editingOfficeAddress,
      editingPhoneNo,
      editingRosterName,
      editingWebsite,
      theme,
      isOpen
    } = this.state
    const { showEditButton } = this.props
    const HomeAddress =
      basicDetails[`addresses`] &&
      basicDetails[`addresses`].length > 0 &&
      basicDetails[`addresses`].find((address: any) => {
        return address.type === 'Home'
      })
    const realtyPassEmail =
      basicDetails[`emails`] &&
      basicDetails[`emails`].length > 0 &&
      basicDetails[`emails`].find((data: any) => {
        return data.type === 'RealtyPass'
      })
    const HomeAddressString = HomeAddress
      ? `${HomeAddress.streetNumber} ${HomeAddress.streetName}, ${HomeAddress.city}, ${HomeAddress.state} ${
          HomeAddress.zipCode
        }`
      : 'No Home Address Yet'
    const WorkAddress =
      basicDetails[`addresses`] &&
      basicDetails[`addresses`].length > 0 &&
      basicDetails[`addresses`].find((address: any) => {
        return address.type === 'Office'
      })
    const WorkAddressString = WorkAddress
      ? `${WorkAddress.streetNumber} ${WorkAddress.streetName}, ${WorkAddress.city}, ${WorkAddress.state} ${
          WorkAddress.zipCode
        }`
      : 'No Office Address Yet'
    const Phone =
      basicDetails[`phones`] &&
      basicDetails[`phones`].length > 0 &&
      basicDetails[`phones`].find((phone: any) => {
        return phone.type === 'Work'
      })
    const Mobile =
      basicDetails[`phones`] &&
      basicDetails[`phones`].length > 0 &&
      basicDetails[`phones`].find((phone: any) => {
        return phone.type === 'Mobile'
      })
    const fbUrl =
      basicDetails['social'] &&
      basicDetails['social'].length > 0 &&
      basicDetails[`social`].find((socials: any) => {
        return socials.type === 'Facebook'
      })
    const twitterUrl =
      basicDetails['social'] &&
      basicDetails['social'].length > 0 &&
      basicDetails[`social`].find((socials: any) => {
        return socials.type === 'Twitter'
      })
    const linkedinUrl =
      basicDetails['social'] && basicDetails['social'].length > 0
        ? basicDetails[`social`].find((socials: any) => {
            return socials.type === 'LinkedIn'
          })
        : null
    return (
      <StyledContainer fluid={true}>
        {(editingHomeAddress || editingOfficeAddress) && (
          <AddressFormModal closeModal={this.closeModal} address={editedAddress as AddressPropType} edit={this.edit} />
        )}
        <StyledGrid columns={2}>
          {Object.keys(basicDetails).length > 0 && (
            <Grid.Row>
              <LeftColumn>
                <StyledHeader>
                  <HeaderItem>
                    <AddressContainer>
                      <TitleContainer edited={editingMlsId ? '95px' : '50px'}>
                        {BasicDetailsStrings.mlsId}:
                      </TitleContainer>{' '}
                      {!editingMlsId ? (
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickMlsIdAction
                              : undefined
                          }
                        >
                          {basicDetails[`mlsId`] ? basicDetails[`mlsId`] : 'Not Provided'}
                        </span>
                      ) : (
                        <TextBox
                          onKeyDown={this.onKeyDown}
                          onBlur={this.onBlur}
                          fluid={true}
                          value={basicDetails[`mlsId`]}
                          name="mlsId"
                          type="text"
                          onChange={this.handleChange}
                          placeholder={BasicDetailsStrings.editMlsId}
                        >
                          <input autoFocus={true} />
                        </TextBox>
                      )}
                    </AddressContainer>
                  </HeaderItem>
                  <HeaderItem>
                    <AddressContainer>
                      <TitleContainer edited={editingLicenceNo ? '140px' : '70px'} style={{ marginRight: 3 }}>
                        {' '}
                        {BasicDetailsStrings.licenceNo}:{' '}
                      </TitleContainer>{' '}
                      {!editingLicenceNo ? (
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickLicenceNoAction
                              : undefined
                          }
                        >
                          {basicDetails[`licenseNo`] ? basicDetails[`licenseNo`] : 'Not Provided'}{' '}
                        </span>
                      ) : (
                        <TextBox
                          onKeyDown={this.onKeyDown}
                          onBlur={this.onBlur}
                          fluid={true}
                          value={basicDetails[`licenseNo`]}
                          name="licenseNo"
                          type="text"
                          onChange={this.handleChange}
                          placeholder={BasicDetailsStrings.licenceNo}
                        >
                          <input autoFocus={true} />
                        </TextBox>
                      )}
                    </AddressContainer>
                  </HeaderItem>
                  <HeaderItem>
                    <AddressContainer>
                      {!editingLicenceExpiration ? (
                        <span
                          style={{ display: 'flex' }}
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickLicenceExpirationAction
                              : undefined
                          }
                        >
                          <TitleContainer style={{ marginRight: 3 }}>
                            {BasicDetailsStrings.licenceExpiration}:
                          </TitleContainer>
                          {basicDetails[`licensedExpiration`] === null
                            ? moment(new Date()).format('L')
                            : moment(basicDetails[`licensedExpiration`]).format('L')}
                        </span>
                      ) : (
                        <Form.Field
                          control={Input}
                          onKeyDown={this.onKeyDown}
                          onBlur={this.onBlur}
                          className="birthdate"
                          name="birthDate"
                          value={
                            basicDetails[`licensedExpiration`] === null
                              ? moment(new Date()).format('L')
                              : moment(basicDetails[`licensedExpiration`]).format('L')
                          }
                          label="License Expiration: "
                          action={{
                            icon: (
                              <DatePicker
                                onChangeDate={this.onChangeLicensedExpirationData}
                                open={isOpen}
                                handleOpen={this.handleOpen}
                                handleClose={this.handleClose}
                              />
                            ),
                            onClick: this.handleOpen
                          }}
                          placeholder="Select Date"
                        />
                      )}
                    </AddressContainer>
                  </HeaderItem>
                </StyledHeader>
                <StyledDivider />
                <Content>
                  <ContentHeader size="small">
                    <ImageWrapper backgroundImage={IconUserCircle} />
                    {!editingRosterName ? (
                      <span>
                        {BasicDetailsStrings.rosterName}:{' '}
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickRosterAction
                              : undefined
                          }
                        >
                          {basicDetails[`roasterName`]}
                        </span>
                      </span>
                    ) : (
                      <TextBox
                        onKeyDown={this.onKeyDown}
                        onBlur={this.onBlur}
                        fluid={true}
                        value={basicDetails[`roasterName`]}
                        name="roasterName"
                        type="text"
                        onChange={this.handleChange}
                        placeholder={BasicDetailsStrings.rosterNamePlaceholder}
                      >
                        <input autoFocus={true} />
                      </TextBox>
                    )}
                  </ContentHeader>
                  <DescriptionContainer>
                    <BioAddressContainer>
                      {!editingBio ? (
                        basicDetails[`bio`] &&
                        basicDetails[`bio`].replace(/ /g, '') !== '<p></p>' &&
                        basicDetails[`bio`] !== '<p><br></p>' ? (
                          <span
                            onClick={
                              subRole.find((items: any) => items === showEditButton) !== undefined
                                ? this.onClickBioAction
                                : undefined
                            }
                          >
                            {' '}
                            {ReactHtmlParser(basicDetails[`bio`])}{' '}
                          </span>
                        ) : (
                          <span
                            onClick={
                              subRole.find((items: any) => items === showEditButton) !== undefined
                                ? this.onClickBioAction
                                : undefined
                            }
                          >
                            No Bio added Yet
                          </span>
                        )
                      ) : (
                        <QuillWrapper>
                          <ReactQuill
                            theme={theme}
                            onChange={this.handleChangeBio}
                            value={basicDetails[`bio`]}
                            modules={quillModules}
                            formats={quillFormats}
                            placeholder="Start Bio..."
                          />
                          <Form.Field control={Button} content="SAVE" onClick={this.onBlur} />
                        </QuillWrapper>
                      )}
                    </BioAddressContainer>
                  </DescriptionContainer>
                </Content>
              </LeftColumn>
              <RightColumn>
                <StyledSegment>
                  <Header size="small">{BasicDetailsStrings.address}</Header>
                  <AddressContainer>
                    <StyledIcon icon={['fas', 'home']} className="fa-sm" />
                    <span onClick={() => this.onClickHomeAddressAction(HomeAddress)}>{HomeAddressString}</span>
                  </AddressContainer>
                  <AddressContainer>
                    <StyledIcon
                      icon={['fas', 'hotel']}
                      className="fa-sm"
                      style={{ width: 15, height: 15, marginRight: 14, marginLeft: 2 }}
                    />
                    <span onClick={() => this.onClickofficeAddressAction(WorkAddress)}>{WorkAddressString}</span>
                  </AddressContainer>
                </StyledSegment>
                <StyledSegment>
                  <Header size="small">{BasicDetailsStrings.contactInfo}</Header>
                  <AddressContainer style={{ margin: '0.5rem 0rem' }}>
                    <StyledIcon icon={['fas', 'phone']} className="fa-sm" style={{ width: 14, height: 14 }} />
                    {!editingPhoneNo ? (
                      Phone !== undefined && Phone != null ? (
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickPhoneAction
                              : undefined
                          }
                        >
                          {Phone.value ? Phone.value : 'No Phone Number Added'}
                        </span>
                      ) : (
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickPhoneAction
                              : undefined
                          }
                        >
                          No Phone Number Added
                        </span>
                      )
                    ) : (
                      <TextBox>
                        <InputMask
                          style={{ height: '25px', borderColor: 'grey', backgroundColor: 'transparent' }}
                          autoFocus={true}
                          mask="(999) 999-9999"
                          onKeyDown={this.onKeyDown}
                          onBlur={this.onBlur}
                          value={Phone !== null && Phone !== undefined ? Phone.value : undefined}
                          onChange={this.onChange}
                          type="text"
                          name="Work"
                          placeholder={BasicDetailsStrings.phonePlaceholder}
                        />
                      </TextBox>
                    )}
                  </AddressContainer>
                  <AddressContainer>
                    <StyledIcon
                      icon={['fas', 'mobile-alt']}
                      className="fa-sm"
                      style={{ marginRight: 14, marginLeft: 2 }}
                    />
                    {!editingMobilePhoneNo ? (
                      Mobile !== undefined && Mobile != null ? (
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickMobilePhoneAction
                              : undefined
                          }
                        >
                          {Mobile.value ? Mobile.value : 'No Mobile Number Added'}
                        </span>
                      ) : (
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickMobilePhoneAction
                              : undefined
                          }
                        >
                          No Mobile Number Added
                        </span>
                      )
                    ) : (
                      <TextBox>
                        <InputMask
                          style={{ height: '25px', borderColor: 'grey', backgroundColor: 'transparent' }}
                          autoFocus={true}
                          mask="(999) 999-9999"
                          onKeyDown={this.onKeyDown}
                          onBlur={this.onBlur}
                          value={Mobile !== null && Mobile !== undefined ? Mobile.value : undefined}
                          onChange={this.onChange}
                          type="text"
                          name="Mobile"
                          placeholder={BasicDetailsStrings.mobilePhonePlaceholder}
                        />
                      </TextBox>
                    )}
                  </AddressContainer>
                  <DetailItem icon={'envelope'} text={basicDetails[`userName`]} />
                  <DetailItem icon={'envelope'} text={realtyPassEmail.value} />
                  <AddressContainer>
                    <StyledIcon icon={['fas', 'globe']} className="fa-sm" style={{ marginRight: 11 }} />
                    {!editingWebsite ? (
                      basicDetails[`website`] ? (
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickWebsiteAction
                              : undefined
                          }
                        >
                          {basicDetails[`website`]}
                        </span>
                      ) : (
                        <span
                          onClick={
                            subRole.find((items: any) => items === showEditButton) !== undefined
                              ? this.onClickWebsiteAction
                              : undefined
                          }
                        >
                          No Website Added
                        </span>
                      )
                    ) : (
                      <TextBox
                        onKeyDown={this.onKeyDown}
                        onBlur={this.onBlur}
                        fluid={true}
                        value={basicDetails[`website`]}
                        type="text"
                        name="website"
                        onChange={this.handleChange}
                        placeholder={BasicDetailsStrings.editWebsite}
                      >
                        <input autoFocus={true} />
                      </TextBox>
                    )}
                  </AddressContainer>
                </StyledSegment>
                <StyledSegment>
                  <Header size="small">{BasicDetailsStrings.socials}</Header>
                  <InputItem
                    icon={'facebook-f'}
                    text={fbUrl ? fbUrl.url : ``}
                    name="Facebook"
                    onChange={this.onChangeUrl}
                    placeholder={BasicDetailsStrings.fbPlaceholder}
                  />
                  <InputItem
                    icon={'twitter'}
                    text={twitterUrl ? twitterUrl.url : ''}
                    name="Twitter"
                    onChange={this.onChangeUrl}
                    placeholder={BasicDetailsStrings.twitterPlaceholder}
                  />
                  <InputItem
                    icon={'linkedin'}
                    text={linkedinUrl ? linkedinUrl.url : ''}
                    name="LinkedIn"
                    onChange={this.onChangeUrl}
                    placeholder={BasicDetailsStrings.linkedinPlaceholder}
                  />
                </StyledSegment>
              </RightColumn>
            </Grid.Row>
          )}
        </StyledGrid>
      </StyledContainer>
    )
  }

  private handleChangeBio = (html: any) => {
    const newObj = {}
    const { basicDetails } = this.state
    basicDetails[`bio`] = html
    newObj[`bio`] = html
    this.setState({ basicDetails, updatedData: newObj })
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const newObj = {}
    const { basicDetails } = this.state
    if (name === 'licenseNo' || name === 'mlsId') {
      basicDetails[name] = value
      newObj[name] = parseInt(value)
      this.setState({ basicDetails, updatedData: newObj })
    } else {
      basicDetails[name] = value
      newObj[name] = value
      this.setState({ basicDetails, updatedData: newObj })
    }
  }

  private closeModal = () => {
    this.setState({ editingHomeAddress: false, editingOfficeAddress: false })
  }

  private onBlur = () => {
    this.setState(
      {
        editingMlsId: false,
        editingLicenceNo: false,
        editingLicenceExpiration: false,
        editingRosterName: false,
        editingBio: false,
        editingHomeAddress: false,
        editingOfficeAddress: false,
        editingMobilePhoneNo: false,
        editingPhoneNo: false,
        editingWebsite: false
      },
      () => {
        this.updateProfile()
      }
    )
  }

  private onKeyDown = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      this.setState(
        {
          editingMlsId: false,
          editingLicenceNo: false,
          editingLicenceExpiration: false,
          editingRosterName: false,
          editingBio: false,
          editingHomeAddress: false,
          editingOfficeAddress: false,
          editingMobilePhoneNo: false,
          editingPhoneNo: false,
          editingWebsite: false
        },
        () => {
          this.updateProfile()
        }
      )
    }
  }

  private onChange = (event: any) => {
    const newObj = {}
    let phone = {}
    let index
    const { basicDetails } = this.state
    if (event.target.name === 'Work') {
      index =
        basicDetails[`phones`] !== null
          ? basicDetails[`phones`].findIndex((element: any) => {
              return element.type === event.target.name
            })
          : -1
      phone = {
        type: 'Work',
        value: event.target.value
      }
    } else if (event.target.name === 'Mobile') {
      index =
        basicDetails[`phones`] !== null
          ? basicDetails[`phones`].findIndex((element: any) => {
              return element.type === event.target.name
            })
          : -1
      phone = {
        type: 'Mobile',
        value: event.target.value
      }
    }
    if (index !== -1) {
      basicDetails[`phones`][index] = phone
      newObj[`phones`] = phone
      this.setState({ basicDetails, updatedData: newObj })
    } else if (index === -1) {
      basicDetails[`phones`] = basicDetails[`phones`] !== null ? basicDetails[`phones`] : []
      basicDetails[`phones`].push(phone)
      newObj[`phones`] = phone
      this.setState({ basicDetails, updatedData: newObj })
    }
  }

  private edit = (newAddress: any) => {
    const newObj: any = {}
    newAddress.country = 'United States'
    const { basicDetails } = this.state
    if (basicDetails[`addresses`]) {
      const index =
        basicDetails[`addresses`] &&
        basicDetails[`addresses`].findIndex((element: any) => element.type === newAddress.type)
      if (typeof index === 'number' && index > -1) {
        basicDetails[`addresses`][index] = newAddress
      } else {
        basicDetails[`addresses`].push(newAddress)
      }
    } else {
      basicDetails[`addresses`] = []
      basicDetails[`addresses`].push(newAddress)
    }
    newObj[`addresses`] = newAddress
    this.setState({ basicDetails, updatedData: newObj }, () => this.updateProfile())
  }

  private onClickRosterAction = () => {
    this.setState({ editingRosterName: !this.state.editingRosterName })
  }

  private onClickBioAction = () => {
    this.setState({ editingBio: !this.state.editingBio })
  }

  private onClickHomeAddressAction = (address: any) => {
    const { showEditButton } = this.props
    const { subRole } = this.state
    if (!subRole.includes(showEditButton)) {
      return
    }

    if (!address) {
      address = {}
      address[`type`] = 'Home'
    }

    this.setState({ editingHomeAddress: !this.state.editingHomeAddress, editedAddress: address })
  }

  private onClickofficeAddressAction = (address: any) => {
    const { showEditButton } = this.props
    const { subRole } = this.state
    if (!subRole.includes(showEditButton)) {
      return
    }

    if (!address) {
      address = {}
      address[`type`] = 'Office'
    }
    this.setState({ editingOfficeAddress: !this.state.editingOfficeAddress, editedAddress: address })
  }

  private onClickPhoneAction = () => {
    this.setState({ editingPhoneNo: !this.state.editingPhoneNo })
  }

  private onClickMobilePhoneAction = () => {
    this.setState({ editingMobilePhoneNo: !this.state.editingMobilePhoneNo })
  }

  private onClickMlsIdAction = () => {
    this.setState({ editingMlsId: !this.state.editingMlsId, editingLicenceExpiration: false })
  }

  private onClickLicenceNoAction = () => {
    this.setState({ editingLicenceNo: !this.state.editingLicenceNo, editingLicenceExpiration: false })
  }

  private onClickLicenceExpirationAction = () => {
    this.setState({ editingLicenceExpiration: !this.state.editingLicenceExpiration })
  }

  private onClickWebsiteAction = () => {
    this.setState({ editingWebsite: !this.state.editingWebsite })
  }

  private onChangeLicensedExpirationData = async (date: Date) => {
    const { basicDetails } = this.state
    const newObj = {}
    basicDetails[`licensedExpiration`] = date
    newObj[`licensedExpiration`] = moment(date).format('L')
    UpdateProgress()
    try {
      await updateProfile(newObj, basicDetails)
      this.setState({ basicDetails, updatedData: newObj, editingLicenceExpiration: false })
      this.handleClose()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }
  }

  private updateProfile = async () => {
    const { updatedData, basicDetails } = this.state
    try {
      await updateProfile(updatedData, basicDetails)
      UpdateProgress()
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }
  }

  private handleOpen = () => {
    this.setState({ isOpen: true })
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private onChangeUrl = (e: any) => {
    const { target } = e
    const { name, value } = target
    const { basicDetails } = this.state
    let index
    const newObj = {}
    if (name === 'Facebook') {
      index =
        basicDetails[`social`] !== null
          ? basicDetails[`social`].findIndex((element: any) => {
              return element.type === name
            })
          : -1
    } else if (name === 'Twitter') {
      index =
        basicDetails[`social`] !== null
          ? basicDetails[`social`].findIndex((element: any) => {
              return element.type === name
            })
          : -1
    } else {
      index =
        basicDetails[`social`] !== null
          ? basicDetails[`social`].findIndex((element: any) => {
              return element.type === name
            })
          : -1
    }
    const social = {
      type: name,
      url: value
    }
    if (index !== -1) {
      basicDetails[`social`][index] = social
      newObj[`social`] = social
      this.setState({ basicDetails, updatedData: newObj }, () => this.updateProfile())
    } else {
      basicDetails[`social`] = basicDetails[`social`] !== null ? basicDetails[`social`] : []
      basicDetails[`social`].push(social)
      newObj[`social`] = social
      this.setState({ basicDetails, updatedData: newObj }, () => this.updateProfile())
    }
  }
}

export default BasicDetails
