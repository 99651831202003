// Import Packages
import * as React from 'react'

import { UserType } from 'store/Teams/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import {
  Action,
  AddButtonContainer,
  Align,
  AlignContainer,
  Calendar,
  CloseIcon,
  Container,
  CustomButton,
  CustomInput,
  Filter,
  FilterIcon,
  SearchIcon,
  SearchInput,
  StyledDropdown,
  Trello,
  TrelloContainer
} from 'shared/StyledBoard/Styled'

// Font Awesome Icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus])

interface Options {
  key: string
  value: string
  text: string
}

interface Props {
  showCalendarIcon?: boolean
  showTaskSearchModal?: boolean
  showDefaultView: (data: boolean) => void
  addList: () => void
  offices: Options[]
  getOfficeTeams: (id: string, office: string) => void
  deleteTeams: () => void
  filterValue: string
  user: UserType
}

interface State {
  show: boolean
  searchText: string
  showSearchModal: boolean
  sortOrder: number
  value: string
}

class StyledHeader extends React.Component<Props, State> {
  public user: any = []
  public state = {
    searchText: '',
    show: true,
    showSearchModal: false,
    sortOrder: 1,
    value: ''
  }

  public options = [
    {
      key: '0',
      text: 'Choose an Action',
      value: 'Choose'
    },
    {
      key: '1',
      text: 'Delete Team(s)',
      value: 'Delete'
    }
  ]

  public async componentDidMount() {
    this.user = await getLoggedInUser()
  }

  public render() {
    const { showTaskSearchModal, showCalendarIcon, offices, filterValue, user } = this.props
    const { searchText, show, value } = this.state
    return (
      <div>
        <Container>
          <Filter>
            <FilterIcon />{' '}
            <StyledDropdown
              inline={true}
              options={offices}
              placeholder="Select Office"
              value={filterValue}
              onChange={this.onHandleChange}
            />
          </Filter>
          <SearchInput style={{ width: !show ? '40%' : '50%' }}>
            <CustomInput placeholder={Strings.kanbanView.search} style={{ display: 'none' }}>
              {showTaskSearchModal ? (
                <input
                  value={searchText}
                  onChange={this.onChangeHandle}
                  onFocus={this.showSearchModal}
                  onBlur={this.closeModal}
                />
              ) : (
                <input value={searchText} onChange={this.onChangeHandle} />
              )}
              <SearchIcon />
              <CloseIcon onClick={this.clearState} />
            </CustomInput>
          </SearchInput>
          <CustomButton
            style={{
              marginRight: !show && this.user.role !== 'ADMIN' && this.user.role !== 'MANAGER' ? '15px' : '0px'
            }}
          >
            {showCalendarIcon && <Calendar />}
            <AlignContainer>
              <Align onClick={this.showListView} />
            </AlignContainer>
            <TrelloContainer>
              <Trello onClick={this.showKanbanView} />
            </TrelloContainer>
            {!show &&
              (user.role === 'ADMIN' || user.role === 'MANAGER') && (
                <Action>
                  {' '}
                  <StyledDropdown
                    inline={true}
                    options={this.options}
                    placeholder="Choose an Action"
                    onChange={this.handleChange}
                    value={value}
                  />
                </Action>
              )}
            {!show && (
              <AddButtonContainer onClick={this.props.addList} style={{ marginLeft: 20 }}>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </AddButtonContainer>
            )}
          </CustomButton>
        </Container>
      </div>
    )
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { value }: any) => {
    this.setState({ value })
    if (value !== 'Choose') {
      const { deleteTeams } = this.props
      deleteTeams()
    }
  }

  private showListView = () => {
    const { showDefaultView } = this.props
    this.setState(
      {
        show: false
      },
      () => showDefaultView(this.state.show)
    )
  }

  private showKanbanView = () =>
    this.setState(
      {
        show: true
      },
      () => this.props.showDefaultView(this.state.show)
    )

  private showSearchModal = () => {
    this.setState({ showSearchModal: true })
  }

  private closeModal = () => {
    this.setState({ showSearchModal: false })
  }

  private onHandleChange = (e: React.SyntheticEvent<EventTarget>, { value }: any) => {
    const result = value.split(', ')
    const { getOfficeTeams } = this.props
    getOfficeTeams(result[0], value)
  }

  private onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value })
  }

  private clearState = () => {
    this.setState({ searchText: '' })
  }
}

export default StyledHeader
