// Import Packages
import { get, sortBy } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import SpinningBubbles from 'react-loading'
import { connect } from 'react-redux'
import { Dimmer, Table } from 'semantic-ui-react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

// Import Components
import client from 'queries/apollo'
import HeaderOption from './HeaderOption'
import ReportsPreview from './ReportsPreview'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import * as DashboardActions from 'store/Dashboard/Actions'
import { ApplyFilter, Toggle, UserType } from 'store/Reports/Types'

// Graphql Queries
import { GET_LEADS } from 'queries/graphql/Lead/Queries'
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import { GET_TEAMS } from 'queries/graphql/Teams/Queries'
import { GET_USERS } from 'queries/graphql/Users/Queries'
import { getContactsSearch, getMyContactsSearch } from 'app/Contacts/Dashboard/ContactQueries'
import { getMyTasksSearch, getTasksSearch } from 'app/Tasks/TaskQueries'
import { getTransactions } from 'app/Transactions/Dashboard/TransactionQueries'

// Import Colors
import Colors from 'design/Colors'
import { getLoggedInUser, Routes } from 'utils'

// Import Styled Components
import { Container, StyledTable, Transaction, UserProfile } from './Styled'
// import StyledGrid from './StyledGrid'

interface DispatchProps {
  getColumnData: (date: ApplyFilter[]) => void
  getFilter: (data: any) => void
  setShowOfficeDetail: (id: string) => void
  setShowTeamDetail: (id: string) => void
  setShowTaskDetail: (id: string) => void
  module: string
  columnMetaData: any
  filter: any
  dates: any
  toggle: Toggle
  systemReport: string
}

interface OwnProps extends RouteComponentProps<{}> {
  closeGridView: () => void
}

type Props = OwnProps & {} & DispatchProps

interface State {
  loader: boolean
  users: UserType
  previewData: any
  column: any
  direction: any
  commissionReceived: number
  totalReferrals: number
  payViaEscrowAmount: number
  totalDue: number
  totalPrice: number
}

class GridView extends React.Component<Props, State> {
  public state = {
    loader: true,
    previewData: [],
    users: {} as UserType,

    commissionReceived: 0,
    totalReferrals: 0,
    totalDue: 0,
    totalPrice: 0,
    payViaEscrowAmount: 0,
    column: undefined,
    direction: undefined
  }

  public async componentDidMount() {
    const { filter, toggle, module, systemReport } = this.props
    const users: any = await getLoggedInUser({ fromCache: true })
    this.setState({ users })
    let data: any
    switch (module !== 'MY REPORTS' ? module : systemReport) {
      case 'TRANSACTIONS':
        const transaction = await getTransactions({ searchData: filter })
        data = transaction.length === 0 ? [] : transaction
        let totalPrice = 0
        let totalDue = 0
        let commissionReceived = 0
        let totalReferrals = 0
        data.forEach((element: any) => {
          totalPrice += element.propertyId.price
          totalDue += element.payment.commissionDue
          commissionReceived += element.payment.actualCommission
          totalReferrals += element.payment.totalReferrals
        })
        this.setState({ totalPrice, totalDue, commissionReceived, totalReferrals })
        break
      case 'LEADS':
        const lead = await client.query({
          fetchPolicy: 'network-only',
          query: GET_LEADS,
          variables: { userId: toggle.lead === false ? users._id : undefined, filter }
        })
        data = lead.data.getLeads.length === 0 ? [] : lead.data.getLeads
        break
      case 'CONTACTS':
        if (toggle.contact === false) {
          const contact = await getMyContactsSearch(users, filter)
          data = contact.length === 0 ? [] : contact
        } else {
          const contact = await getContactsSearch(filter)
          data = contact.length === 0 ? [] : contact
        }
        break
      case 'TASKS':
        if (toggle.task === false) {
          const task = await getMyTasksSearch(users._id, filter)
          data = task.length === 0 ? [] : task
        } else {
          const task = await getTasksSearch(filter)
          data = task.length === 0 ? [] : task
        }
        break
      case 'USERS':
        const user = await client.query({
          fetchPolicy: 'network-only',
          query: GET_USERS,
          variables: { isActive: true, filter }
        })
        data = user.data.getUsers.length === 0 ? [] : user.data.getUsers.flatMap((user: any) => {
        
          if(user.transactions.length>1){
            let updateData =[]
            for(let i=0;i<user.transactions.length;i++){
              updateData.push({
                ...user,
                homeAddress: user.addresses.find( (address: any) => address.type === 'Home' ) || { streetNumber: '', streetName: 'No home address found.', city: '', state: '', zipCode: '' },
                userTransaction: user.transactions[i]
              })
            }
            return updateData
          } 
            return{
              ...user,
              homeAddress: user.addresses.find( (address: any) => address.type === 'Home' ) || { streetNumber: '', streetName: 'No home address found.', city: '', state: '', zipCode: '' },
              userTransaction: user.transactions[0]
            }
      }
      )
        
        break
      case 'TEAMS':
        const team = await client.query({
          fetchPolicy: 'network-only',
          query: GET_TEAMS,
          variables: { isActive: true, filter }
        })
        data = team.data.getTeams.length === 0 ? [] : team.data.getTeams
        break
      case 'OFFICES':
        const office = await client.query({
          fetchPolicy: 'network-only',
          query: GET_OFFICES,
          variables: { filter }
        })
        data = office.data.getOffices.length === 0 ? [] : office.data.getOffices
        break
      default:
        data = []
        break
    }
    if (data) {
      this.setState({ loader: false })
    }
    this.setState({ previewData: data })
  }

  public render() {
    const { loader, previewData, users, totalPrice, totalDue, commissionReceived, totalReferrals } = this.state
    const { filter, module } = this.props

    return (
      <Container>
        {loader && (
          <Dimmer active={true} inverted={true}>
            <SpinningBubbles type={'spinningBubbles'} color={Colors.DarkBlue200} />
          </Dimmer>
        )}
        <Transaction>
          <HeaderOption
            closeGridView={this.closeGridView}
            exportCSV={this.exportCSV}
            resetData={this.resetData}
            print={this.print}
            module={module}
          />
        </Transaction>
        <UserProfile>
          <ReportsPreview
            totalResult={previewData.length}
            module={module}
            users={users}
            dates={filter}
            totalPrice={totalPrice}
            totalDue={totalDue}
            commissionReceived={commissionReceived}
            totalReferrals={totalReferrals}
          />
        </UserProfile>
        <div className="table">
          <StyledTable sortable={true} selectable={true} singleLine={true} id="preview-data">
            <Table.Header>
              <Table.Row>
                {this.props.columnMetaData.map((data: any) => (
                  <Table.HeaderCell
                    sorted={this.state.column === data.title ? this.state.direction : undefined}
                    onClick={this.handleSort(data.id)}
                  >
                    {data.title}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.previewData.length !== 0 ? (
                this.state.previewData.map((data: any, index: number) => (
                  <Table.Row key={index}>
                    {this.props.columnMetaData.map((item: any) =>
                        item.id === 'createdAt' ||
                          item.id === 'payment.receivedDate' ||
                          item.id === 'dueDate' ||
                          item.id === 'dateOfBirth' ||
                          item.id === 'anniversaryDate' ||
                          item.id === 'joiningDate' ||
                          item.id === 'userTransaction.createdAt' ? (
                            <Table.Cell onClick={() => this.onRowSelected(data)}>
                              {moment(get(data, item.id)).format('L') !== 'Invalid date'
                                ? moment(get(data, item.id)).format('L')
                                : '-'}
                            </Table.Cell>
                          ) : get(data, item.id) ? (
                            <Table.Cell onClick={() => this.onRowSelected(data)}>
                              {item.id === 'propertyId.price' ||
                                item.id === 'payment.commissionDue' ||
                                item.id === 'payment.expectedCommission' ||
                                item.id === 'payment.actualCommission' ||
                                item.id === 'payment.totalCredits' ||
                                item.id === 'payment.totalIncome' ||
                                item.id === 'payment.payViaEscrowAmount' ||
                                item.id === 'payment.totalDebits'
                                ? `$${Number(get(data, item.id).toFixed(2)).toLocaleString()}`
                                : item.id === 'languages' ? (
                                  get(data, item.id).join(', ')) :
                                item.id === 'sharedWith'
                                  ? this.getSharedWith(data)
                                  : item.id === 'leader.firstName'
                                    ? this.getLeaderName(data)
                                    : item.id === 'teamCap.residentialTargetCap' ||
                                      item.id === 'teamCap.commercialTargetCap'
                                      ? this.getResidentialCompletedCap(data, item.id)
                                      : item.id === 'payment.totalReferrals'
                                      ? this.getReferralWithoutEscrow(data, item.id)
                                      : get(data, item.id)}

                            </Table.Cell>
                          ) : item.id === 'revenue' ? (<Table.Cell>0</Table.Cell>):(
                              <Table.Cell>-</Table.Cell>
                            )
                    )}
                  </Table.Row>
                ))
              ) : (
                  <Table.Row>
                    <Table.Cell>Not Records Found</Table.Cell>
                  </Table.Row>
                )}
            </Table.Body>
          </StyledTable>
        </div>
      </Container>
    )
  }

  private onRowSelected = (data: any) => {
    const { module, setShowOfficeDetail, setShowTeamDetail, setShowTaskDetail } = this.props
    const { users } = this.state

    switch (module) {
      case 'TRANSACTIONS':
        window.open(Routes.transactions.root + `/${data._id}` + Routes.transactions.documents.path, '_blank')
        break
      case 'LEADS':
        this.props.history.push(
          data.tenantBoard !== undefined && data.tenantBoard !== null
            ? users.role === 'ADMIN' || users.role === 'MANAGER'
              ? Routes.leads.root + Routes.leads.details.path + `/${data._id}?pool=1`
              : ''
            : Routes.leads.root + Routes.leads.details.path + `/${data._id}?pool=0`
        )
        break
      case 'CONTACTS':
        this.props.history.push(Routes.contacts.root + Routes.contacts.details.path + `/${data._id}?pool=0`)
        break
      case 'USERS':
        this.props.history.push(Routes.primary.profile.path + `/${data._id}`)
        break
      case 'TEAMS':
        setShowTeamDetail(data._id)
        this.props.history.push(Routes.primary.teams.path)
        break
      case 'OFFICES':
        setShowOfficeDetail(data._id)
        this.props.history.push(Routes.primary.offices.path)
        break
      case 'TASKS':
        setShowTaskDetail(data._id)
        this.props.history.push(Routes.primary.tasks.path)
        break
      default:
        return
    }
  }

  private getSharedWith = (data: any) => {
    const capitalize = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
    const names = data.sharedWith.map((item: any) => {
      return `${capitalize(item.firstName)} ${capitalize(item.lastName)}`
    })
    return names.join(', ')
  }

  private getLeaderName = (data: any) => {
    return `${data.leader.firstName.charAt(0).toUpperCase() +
      data.leader.firstName.slice(1)} ${data.leader.lastName.charAt(0).toUpperCase() + data.leader.lastName.slice(1)}`
  }

  private getResidentialCompletedCap = (data: any, id: string) => {
    if (id === 'teamCap.residentialTargetCap') {
      return `${data.teamCap.residentialCompletedCap}/${data.teamCap.residentialTargetCap}`
    } else {
      return `${data.teamCap.commercialCompletedCap}/${data.teamCap.commercialTargetCap}`
    }
  }

  public getReferralWithoutEscrow =  (data:any, id: string) => {
    const {
      payment:{
        totalReferrals,
        payViaEscrowAmount
      }
    } = data
    return `$${totalReferrals.toFixed(2) - (payViaEscrowAmount || 0).toFixed(2)}`
  }

  private handleSort = (clickedColumn: any) => () => {
    const { column, previewData, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
        previewData: sortBy(previewData, [clickedColumn])
      })

      return
    }

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      previewData: previewData.reverse()
    })
  }

  private print = () => {
    const divToPrint: any = document.getElementById('preview-data')
    const newWin: any = window.open()
    newWin.document.write(divToPrint.outerHTML)
    newWin.print()
    newWin.close()
  }

  private resetData = () => {
    // const { getFilter, getColumnData } = this.props
    // getFilter({})
    // getColumnData([])
  }

  private exportCSV = () => {
    const { module } = this.props
    this.exportTableToCSV(`${module}_REPORT.csv`)
  }

  private exportTableToCSV = (filename: any) => {
    const csv = []
    const rows = Array.from(document.querySelectorAll('#preview-data tr'))

    for (const data of rows) {
      const row = []
      const cols = Array.from(data.querySelectorAll('td, th'))

      for (const col of cols) {
        row.push(col.textContent ? `"${col.textContent}"` : '')
      }

      csv.push(row.join(','))
    }

    this.downloadCSV(csv.join('\n'), filename)
  }

  private downloadCSV = (csv: any, filename: any) => {
    let csvFile
    let downloadLink

    // CSV file
    csvFile = new Blob([csv], { type: 'text/csv' })

    // Download link
    downloadLink = document.createElement('a')

    // File name
    downloadLink.download = filename

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile)

    // Hide download link
    downloadLink.style.display = 'none'

    // Add the link to DOM
    document.body.appendChild(downloadLink)

    // Click download link
    downloadLink.click()
  }

  private closeGridView = () => {
    const { closeGridView } = this.props
    closeGridView()
    // getFilter({})
    // getColumnData([])
  }
}

const mapStateToProps = (state: AppState) => ({
  columnMetaData: state.reports.columnMetaData,
  dates: state.reports.dates,
  filter: state.reports.filter,
  module: state.reports.module,
  systemReport: state.reports.systemReport,
  toggle: state.reports.toggle
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      getColumnData: Actions.getColumnData,
      getFilter: Actions.getFilter,
      setShowOfficeDetail: DashboardActions.showOfficeDetail,
      setShowTeamDetail: DashboardActions.showTeamDetail,
      setShowTaskDetail: DashboardActions.showTaskDetail
    }
  )(GridView)
)
