// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Import Components
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import { ShowInvited } from '../Teams'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import { TeamItemsDetails, UserType } from 'store/Teams/Types'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Styled Components
import {
  CAP,
  CardContent,
  CardWrapper,
  // ChatIcon,
  Container,
  Divider,
  EmailIcon,
  ExtraContent,
  FileIcon,
  FullName,
  Invites,
  Labels,
  Li,
  MemberProfile,
  Pending,
  PhoneIcon,
  Profile,
  ProfileImage,
  Report,
  StyledLabel,
  StyledPopup,
  Team,
  TeamCAP,
  TeamName,
  Text,
  Ul
} from './Styled'

interface StoreProps {
  setViewReport: (report: string) => void
}

interface OwnProps {
  CardData: TeamItemsDetails
  color: string
}

type Props = StoreProps & OwnProps

interface State {
  showComposeMail: boolean
  users: UserType
}

class KanbanCard extends React.Component<Props, State> {
  public state = {
    showComposeMail: false,
    users: {} as UserType
  }

  public async componentDidMount() {
    const users: UserType = await getLoggedInUser({ fromCache: true })
    this.setState({ users })
  }

  public render() {
    const { CardData } = this.props
    const { showComposeMail, users } = this.state
    let teamCap = 0
    if (CardData.teamCap) {
      teamCap = CardData.teamCap.commercialTargetCap + CardData.teamCap.residentialTargetCap
    }

    const membersFilters = CardData.members.filter((user: any) => {
      return user.status === 'Active' && user.role !== 'TEAM_LEADER'
    })

    const pendingMembersFilters = CardData.members.filter((user: any) => {
      return user.status === 'Pending' && user.role !== 'TEAM_LEADER'
    })

    const Card = membersFilters.map((items: any, index: any) => {
      while (index < 3) {
        return (
          <Li key={index}>
            <StyledPopup
              trigger={<MemberProfile key={index} background={items.profileImage} />}
              content={`${items.firstName} ${items.lastName}`}
            />
          </Li>
        )
      }
      return null
    })
    const Count =
      membersFilters.length > 3 ? (
        <Labels>
          <StyledPopup trigger={<StyledLabel>...</StyledLabel>} content={`+${membersFilters.length - 3}`} />
        </Labels>
      ) : null
    return (
      <Container>
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={CardData.email} />}
        <CardWrapper>
          <CardContent>
            <TeamName onClick={this.showViewDetails}>{CardData.teamName}</TeamName>
            <Profile>
              <ProfileImage background={CardData.profileImage} onClick={this.showViewDetails} />
              <FullName onClick={this.showViewDetails}>{`${CardData.firstName} ${CardData.lastName}`}</FullName>
              <StyledPopup
                trigger={
                  <EmailIcon
                    onClick={e => {
                      e.stopPropagation()
                      this.sendMail()
                    }}
                  />
                }
                content={CardData.leader.userName ? CardData.leader.userName : 'No Email Added '}
              />
              <StyledPopup
                trigger={<PhoneIcon />}
                content={
                  CardData.leader.phones && CardData.leader.phones[0]
                    ? CardData.leader.phones[0].value
                    : 'No Phone Number Added'
                }
              />
              {/* <ChatIcon /> */}
            </Profile>
            <Team onClick={this.showViewDetails}>
              <TeamCAP flex={CardData.members.length === 0 ? 1 : 1}>
                <CAP>{Strings.teams.teamCap}</CAP>
                {`$ ${teamCap}`}
              </TeamCAP>
              {CardData.members.length > 0 && (
                <Ul style={{ display: 'flex' }}>
                  {Card}
                  <Li>{Count}</Li>
                </Ul>
              )}
              {CardData.members.length === 0 && <Text>{Strings.teams.noMembers}</Text>}
            </Team>
          </CardContent>
          <Divider />
          <ExtraContent onClick={this.showViewDetails}>
            {(users.role === 'TEAM_LEADER' || users.role === 'ADMIN' || users.role === 'MANAGER') && (
              <Link
                to={Routes.primary.reports.path + `?previous-page=teams&team=${CardData.teamName}`}
                onClick={() => this.props.setViewReport('teams')}
              >
                <Report>
                  <FileIcon />
                  <div>{Strings.teams.viewReport}</div>
                </Report>
              </Link>
            )}
            <Pending
              onClick={e => {
                e.stopPropagation()
                this.showPendingTab()
              }}
            >
              <div>{Strings.teams.PandingInvites}</div>
              <Invites circular={true}>{pendingMembersFilters.length}</Invites>
            </Pending>
          </ExtraContent>
        </CardWrapper>
      </Container>
    )
  }

  private sendMail = () => {
    this.setState({ showComposeMail: true })
  }

  private closeModal = () => {
    this.setState({ showComposeMail: false })
  }

  private showPendingTab = () => {
    const { CardData } = this.props
    ShowInvited(CardData._id, 1)
  }

  private showViewDetails = () => {
    const { CardData } = this.props
    ShowInvited(CardData._id)
  }
}

const mapStateToProps = (state: AppState) => ({})

export default connect(
  mapStateToProps,
  {
    setViewReport: Actions.viewReport
  }
)(KanbanCard)
