import { Popup } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, text, x, y } from 'design/Styled'

interface Props {
  custom?: {
    activeTier?: boolean
    addDivider?: boolean
    freeLimit?: boolean
  }
  imageUrl?: string
}

const divider = css`
  border-top: 1px solid ${Colors.EmphasisLine};
`

const isDivided = ({ custom }: Props) => {
  if (custom && custom.addDivider) {
    return divider
  }
  return ''
}

const getTierColor = ({ custom: { activeTier, freeLimit } }: any) => {
  let color = Colors.ButtonDisable

  if (activeTier && freeLimit) {
    color = Colors.ButtonDanger
  } else if (activeTier) {
    color = Colors.ButtonPrimary
  }

  return color
}

export const Menu = styled.div`
  ${y};
  border-radius: 0.28571429rem;
  overflow: hidden;

  & > section {
    ${divider};
  }

  & > section:first-child {
    border-top: none;
  }
`

export const Section = styled.section`
  ${y};
  width: 100%;
`

export const Row = styled.div`
  ${x};
  padding: 0.8em 1em;
`

export const MenuItem = styled(Row)`
  ${text};
  ${isDivided};
  font: ${fonts.small.regular};
  width: 100%;
  cursor: pointer;

  :hover {
    background: ${Colors.EmphasisContainer};
  }
`

export const ProfileImage = styled.div`
  border-radius: 50%;
  width: 46px;
  height: 46px;
  background-image: url(${(props: Props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
`

export const StyledPopup = styled(Popup)`
  &.ui.bottom.right.popup {
    padding: 0;
    min-width: 200px;
  }
`

export const PopupContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;

  .fa-sort-down {
    margin-left: 6px;
    color: ${Colors.Black500};
    width: 12px;
    height: 12px;
  }
`

export const Usage = styled.h3`
  ${text};
  flex: 1;
  font: ${fonts.small.bold};
  color: ${Colors.TextPrimary};
  margin-bottom: 0;
  text-transform: uppercase;
  white-space: nowrap;
`

export const TierContainer = styled.div`
  ${x};
`

export const TierPopup = styled(Popup)``

export const TierDetail = styled.div`
  ${y};

  span,
  strong,
  em {
    width: 100%;
    text-align: center;
  }

  em {
    color: ${Colors.TextDanger};
  }
`

const tierHeight = 8

export const Tier = styled.div`
  ${x};
  ${children};
  position: relative;
  margin-left: 1px;
  margin-right: 1px;
  width: 18px;
  height: ${tierHeight}px;
  border-radius: 0;
  background: ${getTierColor};
  opacity: 0.8;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms;

  &:first-child {
    border-top-left-radius: ${tierHeight / 2}px;
    border-bottom-left-radius: ${tierHeight / 2}px;
  }

  &:last-child {
    border-top-right-radius: ${tierHeight / 2}px;
    border-bottom-right-radius: ${tierHeight / 2}px;
  }

  &:hover {
    opacity: 1;
  }
`
