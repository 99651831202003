// Import Packages
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import List from 'semantic-ui-react/dist/commonjs/elements/List'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background?: string
  isToggled?: boolean
}

const StyledHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.Black500};
`

const ListItem = styled(List.Item)`
  .ui.celled.list> &.item{
    padding: 20px 20px;
    border-style: solid;
    border-bottom-width: 0px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: rgb(34, 36, 38, 0.15);
    :first-child{
      padding: 20px 20px;
    }
    :last-child{
      padding: 20px 20px;
    }
  }
`

const StyledList = styled(List)`
  &.ui.list {
    height: 431px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 3px
    }
    ::-webkit-scrollbar-track {
      background: ${Colors.transparent};
    }
  }
`

const StyledListHeader = styled(List.Header)`
  .ui.list>.item &.header {
    color: ${Colors.Black500}
  }

  .fa-usd-circle {
    width: 18px;
    height: 18px;
    color: ${(props: Props) => props.isToggled ? Colors.Green70 : Colors.Orange410};
    border: 1px solid;
    border-radius: 50%;
    margin-left: 5px;
  }
`

const StyledListContent = styled(List.Content)`
  .ui.list > .item > &.content {
    color: ${Colors.Grey800};
    position: relative;
  }
`

const StyledButton = styled(Button)`
  &.ui.button {
    padding: 5px 15px;
    color: ${Colors.Black500};
    &.pendingButton {
      background-color: ${Colors.Red700};
      color: white;
    }
    &.joinedButton {
      background-color: ${Colors.Green500};
      color: white;
    }
  }

`

interface ColorProps {
  color: string
}

const ColorView = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props: ColorProps) => `${props.color}`};
  line-height: 100%;
  align-self: center;
  margin-right: 10px;
  margin-left: 5px;
  display: inline-block;
  position: absolute;
  right: 0px;
`

const LoadMore = styled.div`
  text-align: center;
  padding-top: 10px;
  font-weight: bold;
  font-size: 12px;
  color: ${Colors.Black550};
  cursor: pointer;
`

const Infograph = styled.div`
  background: ${(props: Props) => `url(${props.background}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`

export {
  ColorView,
  ListItem,
  LoadMore,
  StyledButton,
  StyledHeader,
  StyledList,
  StyledListContent,
  StyledListHeader,
  Infograph
}
