import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { row } from 'design/Styled'

interface Props {
  isVisible?: boolean
}

const getDisplay = (props: Props) => {
  if (props.isVisible) {
    return css`
      display: block;
    `
  }
  return css`
    display: none;
  `
}

const Footer = styled.footer`
  padding: 0.6em 0;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 0 10px -6px ${Colors.KanbanShadow};
  z-index: 2;
`

const Add = styled.div`
  ${getDisplay};
  font-size: 14px;
  color: ${Colors.DarkBlue200};
  cursor: pointer;
`

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.color || Colors.Purple50};
`

const Row = styled.div`
  ${row};
`

const PageNav = styled.div`
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }

  & svg {
    color: ${props => props.color || Colors.Purple50};
  }

  &.disabled {
    svg {
      color: #ddd;
    }

    &:hover {
      cursor: not-allowed;
    }
  }
`

export { Add, Footer, Label, Row, PageNav }
