// Import Packages
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const StyledGrid = styled(Grid)`
  &.ui.grid {
    padding-top: 40px;
    padding-left: 40px;
    width: 100%;
  }

  &.ui.grid>.row>[class*="four wide"].column {
    width: 22% !important;
  }

  &.ui.grid>.row.back-button-row {
    justify-content: flex-end;
    width: 95.7% !important;
  }
`

const BackButton = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.DarkBlue200};
  cursor: pointer;

  .fa-arrow-circle-left {
    margin-right: 5px;
  }

  span {
    text-decoration: underline;
  }
`

export {
  BackButton,
  StyledGrid
}
