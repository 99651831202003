import * as React from 'react'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot
} from 'react-beautiful-dnd'
import * as ReactDOM from 'react-dom'

import Scrollable from 'shared/Scrollable'

import { AddCard, AddCardContainer, Container, ContentWrapper } from './Styled'

interface Props {
  addNewCard: (id: string, index: string) => void
  boardId: string
  CardComponent: React.ReactType
  color: string
  content: any
  droppableId: string
  disableDrop?: boolean
  itemsLength: number
  showAddButton: boolean
}

interface State {
  disableDrag: boolean
}

class KanbanLaneContent extends React.Component<Props, State> {
  public state = {
    disableDrag: false
  }

  private portal: any

  public componentDidMount = async () => {
    this.portal = document.getElementById('__dnd-portal')
  }

  public render() {
    const { boardId, content, CardComponent, showAddButton, droppableId, disableDrop } = this.props
    const { disableDrag } = this.state
    const getBoardIdFunction = (cardIndex: string) => {
      this.addNewCard(boardId, cardIndex + 2)
    }
    return (
      <ContentWrapper>
        <Droppable droppableId={droppableId} isDropDisabled={disableDrop}>
          {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
            <div
              ref={dropProvided.innerRef}
              {...dropProvided.droppableProps}
              style={{
                height: '100%',
                width: '100%'
              }}
            >
              <Scrollable>
                {content.map((card: any, index: number) => (
                  <Draggable key={card._id} draggableId={card._id} index={index} isDragDisabled={disableDrag}>
                    {(cardDragProvided: DraggableProvided, cardDragSnapshot: DraggableStateSnapshot) => {
                      const child: any = (
                        <Container
                          size={showAddButton ? '0' : '25px'}
                          innerRef={cardDragProvided.innerRef}
                          {...cardDragProvided.draggableProps}
                          {...cardDragProvided.dragHandleProps}
                        >
                          <CardComponent
                            key={index}
                            CardData={card}
                            color={card.color}
                            boardId={boardId}
                            length={content.length}
                            order={index}
                            disableDrag={this.handleDisableDrag}
                          />
                          {showAddButton && (
                            <AddCardContainer>
                              <AddCard onClick={() => getBoardIdFunction(index.toString())} />
                            </AddCardContainer>
                          )}
                        </Container>
                      )
                      return cardDragSnapshot.isDragging ? ReactDOM.createPortal(child, this.portal) : child
                    }}
                  </Draggable>
                ))}
              </Scrollable>
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </ContentWrapper>
    )
  }

  private addNewCard = (id: string, index: string) => {
    const { addNewCard } = this.props
    addNewCard(id, index)
  }

  private handleDisableDrag = (disableDrag: boolean) => {
    this.setState({ disableDrag })
  }
}

export default KanbanLaneContent
