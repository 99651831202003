import client from 'queries/apollo'

import { GET_ALGOLIA_PLACES_CREDENTIALS } from 'queries/graphql/Address/Queries'

export const getAlgoliaPlacesCredentials = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_ALGOLIA_PLACES_CREDENTIALS
  })

  return response.data.getAlgoliaPlacesCredentials
}
