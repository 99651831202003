import gql from 'graphql-tag'

export const GET_PLAN_ALLOWANCE = gql`
  query getPlanAllowance($product: BillingProductEnum) {
    getPlanAllowance(product: $product)
  }
`

export const GET_CURRENT_BILLING_CYCLE = gql`
  query getBillingCycle($transactionWhere: TransactionFilter) {
    getBillingCycle {
      _id
      plan
      status
      endDate
      startDate
      subscriptionItems {
        product
        quantity
      }
    }

    getTransactionsCountTenant(where: $transactionWhere)
  }
`

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods {
    getPaymentMethods {
      cards {
        brand
        id
        isDefault
        last4
      }
      error {
        code
        message
        type
      }
    }
  }
`

export const GET_UPCOMING_INVOICE = gql`
  query getUpcomingInvoice($filter: UpcomingInvoiceFilter) {
    getUpcomingInvoice(filter: $filter) {
      cycle {
        _id
        plan
        status
        endDate
        startDate
      }
      lines {
        amount_decimal
        description
        endDate
        product
        quantity
        startDate
      }
      total_decimal
    }
  }
`

export const GET_PREVIOUS_INVOICES = gql`
  query getPreviousInvoices($filter: InvoiceListFilter) {
    getPreviousInvoices(filter: $filter) {
      amount_due
      amount_paid
      createdAt
      dueDate
      endDate
      hosted_invoice_url
      id
      paid
      receipt_number
      startDate
      status
    }
  }
`
