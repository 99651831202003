import { Checkbox, Dropdown, Label, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background: string
  left?: number
  color?: string
}

export const Container = styled.div`
  ${y};
  padding: 0;
  height: 100%;
  overflow: hidden;

  .fa-envelope {
    width: 20px;
    height: 20px;
  }

  .fa-ticket {
    width: 20px;
    height: 20px;
  }

  .fa-handshake {
    width: 20px;
    height: 20px;
  }

  .fa-sms {
    width: 20px;
    height: 20px;
  }

  .fa-phone {
    width: 20px;
    height: 20px;
    transform: rotate(100deg);
  }
`

export const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox {
    min-width: 0px;
  }
`

export const Due = styled.div`
  background-color: ${(props: Props) => props.background || props.background};
  font-style: italic;
  color: ${props => props.color || props.color};
  width: 85px;
  text-align: center;
`

export const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

export const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.95);
      z-index: 13;
    }
  }

  .action-drop-down > .menu > a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.95);
      z-index: 13;
    }
  }
`

export const Notes = styled(Label)`
  &.ui.circular.label,
  .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px !important;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
  }
`

export const StyledDropdownItem = styled(Dropdown.Item)`
  display: flex !important;
`

export const Task = styled.div`
  margin-left: 20px;
`

export const CheckboxWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`

export const Profile = styled.div`
  display: flex;
  font-size: 12px;
  color: ${Colors.Black500};
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
`

export const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

export const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

export const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
  font-style: normal;

  div {
    ${y};
    height: 100%;
    color: ${Colors.DarkBlue220};
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  height: 100%;
  background-color: ${Colors.White1000};
  width: 100%;
  padding: 20px 50px;

  .rbc-event {
    color: ${Colors.Text};
    font-size: 14px;
    padding: 10px;
    background: #f0f3f8 !important;
  }

  .rbc-day-slot .rbc-event {
    border: none;
  }

  .rbc-event.rbc-selected {
    background-color: #f0f3f8 !important;
  }
`

export const Status = styled.div`
  background-color: ${(props: Props) => props.background || props.background};
  min-width: 75px;
  max-width: 40px;
  justify-content: center;
  text-align: -webkit-center;
  color: ${Colors.White1000};
  font-size: 12px;
  font-weight: bold;
`

export const EventDescription = styled.div`
  text-align: justify;
  color: ${Colors.Black500};
  font-size: 12px;
  text-transform: capitalize;
`

export const Address = styled.div`
  font-size: 12px;
  text-transform: 'capitalize';
`
