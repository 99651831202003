import gql from 'graphql-tag'

export const userPassSettingsFragment = gql`
  fragment UserPassSettingsFragment on UserPass {
    settings {
      ... on PassSettingsConcierge {
        additionalFee
        automaticTransaction
      }
      ... on PassSettingsIdx {
        feedPassword
        feedUrl
        feedUsername
        mls {
          _id
          shortName
          fullName
        }
        status
      }
      ... on PassSettingsWebsiteSolution {
        adminFee
        adminStatus
        isPending
        isPrimary
        office {
          _id
          branchName
          mlsId
        }
        officeSubdomain
        mls {
          _id
          shortName
          fullName
        }
        mlsSubdomain
        requestedAt
        status
        template
        tenantSubdomain
      }
    }
  }
`

export const userPassFragment = gql`
  fragment UserPassFragment on UserPass {
    _id
    name
    passType
    price
    passCategory
    passStatus
    user {
      _id
      firstName
      lastName
    }
    pass {
      _id
      name
      icon
    }
    ...UserPassSettingsFragment
    order
    timePeriod
    isActive
  }
  ${userPassSettingsFragment}
`
