export const handleValidation = (data: string) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields || fields.trim() === '') {
    formIsValid = false
    errors[`note`] = true
  }

  return { errors, formIsValid }
}
