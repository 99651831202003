import { Action } from 'redux'

import { BillingCycleType, BillingPlanEnum, BillingProductUsageType } from 'shared/Billing/Types'

export enum Actions {
  BillingUpdatePlan = 'BILLING_UPDATE_PLAN',
  BillingUpdateState = 'BILLING_UPDATE_STATE'
}

export interface BillingState {
  cycle: BillingCycleType
  usage: BillingProductUsageType
}

export interface BillingUpdateStateAction extends Action {
  type: string
  payload: BillingState
}

export interface BillingUpdatePlanAction extends Action {
  type: string
  payload: BillingPlanEnum
}
