import gql from 'graphql-tag'

export const DELETE_MESSAGE_EMAIL = gql`
  mutation deleteMessageEmail($id: String) {
    deleteMessageEmail(_id: $id)
  }
`

export const CREATE_MESSAGE_EMAIL = gql`
  mutation createMessageEmail(
    $attachments: [ID]
    $html: String
    $subject: String
    $to: [String]
    $text: String
    $type: String
  ) {
    createMessageEmail(
      input: {
        attachments: $attachments
        html: $html
        subject: $subject
        to: $to
        text: $text
        type: $type
      }
    ) {
      _id
    }
  }
`

export const UPDATE_MESSAGE_EMAIL = gql`
  mutation updateMessageEmail(
    $_id: String
    $attachments: [ID]
    $html: String
    $subject: String
    $to: [String]
    $text: String
    $type: String
  ) {
    updateMessageEmail(
      _id: $_id
      input: {
        attachments: $attachments
        html: $html
        subject: $subject
        to: $to
        text: $text
        type: $type
      }
    ) {
      _id
    }
  }
`

export const CREATE_MESSAGE_EMAIL_ATTACHMENT = gql`
  mutation createMessageEmailAttachment($file: Upload!, $type: String) {
    createMessageEmailAttachment(input: {}, file: $file, type: $type) {
      _id
      url
      fileName
    }
  }
`

export const DELETE_MESSAGE_EMAIL_ATTACHMENT = gql`
  mutation deleteMessageEmailAttachment($id: String) {
    deleteMessageEmailAttachment(_id: $id)
  }
`
