import gql from 'graphql-tag'

export const GET_MY_REPORT = gql`
  query getPasses {
    getMyReports {
      _id
      module
      name
      pickFilterData
    }
  }
`
