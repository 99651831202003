import gql from 'graphql-tag'

export const GET_MLS = gql`
  query getMls($id: String) {
    getMls(_id: $id) {
      _id
      fullName
      shortName
      state
    }
  }
`

export const GET_MLS_LIST = gql`
  query getMlsList($where: MlsFilter) {
    getMlsList(where: $where) {
      _id
      fullName
      shortName
      state
    }
  }
`

export const GET_MLS_TENANT_LIST = gql`
  query getMlsTenantList($where: MlsFilter) {
    getMlsTenantList(where: $where) {
      _id
      fullName
      shortName
      state
    }
  }
`
