export interface ButtonsType {
  cancel?: boolean
  confirm?: boolean
  download?: boolean
  lock?: boolean
  mail?: boolean
  markup?: boolean
  options?: boolean
  rename?: boolean
  rotate?: boolean
  textract?: boolean
  trash?: boolean
  zoom?: boolean
}

export enum ActionEnum {
  Cancel = 'document:cancel',
  Close = 'document:close',
  Confirm = 'document:confirm',
  Lock = 'document:lock',
  Mail = 'document:mail',
  Markup = 'document:markup',
  Rename = 'document:rename',
  Rotate = 'document:rotate',
  SaveOne = 'document:save',
  SaveAll = 'document:saveAll',
  Select = 'document:select',
  Textract = 'document:textract',
  Trash = 'document:trash',
  Unlock = 'document:unlock',
  UpdateOne = 'document:update',
  UpdateAll = 'document:updateAll',
  View = 'document:view',
  ZoomIn = 'document:zoomIn',
  ZoomOut = 'document:zoomOut',
}
