export interface UserType {
  firstName: string
  lastName: string
}

export interface OptionType {
  text: string
  value: string
}

export interface PointType {
  x: number
  y: number
}

export interface DimensionsType {
  height: number
  left: number
  top: number
  width: number
}

export interface StageType {
  height: number
  width: number
  scale: PointType
}

export interface MarkupType {
  height: number
  id: string
  label: OptionType
  left: number
  page?: number
  style: any
  meta?: any
  top: number
  value?: string
  width: number
}

export enum MarkupSourceEnum {
  None = '',
  Textract = 'extractMarkup',
  User = 'viewerMarkup',
}

export enum MarkupModeEnum {
  SuperAdmin = 1000,
  Admin = 400,
  Owner = 300,
  Editor = 200,
  Viewer = 100,
  None = 0,
}

export enum MarkupLabelEnum {
  None = '',
  Signature = 'signature',
  Text = 'text',
  Note = 'note',
}
