import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'
import { Accordion, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

interface Props {
  background: string
}

const Wrapper = styled.div`
  background: ${Colors.White1000};
  height: calc(88vh - 70px);
  overflow: auto;
  ${scrollbars};

  .dev>div:last-child {
    border-bottom: none;
  }
`

const Header = styled.div`
  display: flex;
  padding: 20px;

  div:nth-child(2) {
    font-size: 12px;
    text-decoration: underline;
    color: ${Colors.DarkBlue200};
    font-weight: 500;
    cursor: pointer;
  }
`

const Title = styled.div`
  font-size: 16px;
  flex-grow: 1;
  color: ${Colors.DarkBlue200};
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
`

const Content = styled.div`
  padding-top: 0;
  background-color: ${Colors.White1000};
  margin-top: 0;

  .ui.accordion .title:not(.ui) {
    background: ${Colors.White535};
    margin-bottom: 4px;
    padding-left: 14px;
  }

  .ui.accordion {
    margin-bottom: 30px;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
      background: ${Colors.transparent};
  }
`

const StyledAccordionTitle = styled(Accordion.Title)`
  display: flex;
  font-weight: bold;
  padding-bottom: 0 !important;

  div {
    width: 100%;
    padding-bottom: 12px;
    color: ${Colors.Black500};
    padding-top: 4px;
    font-size: 12px;
  }

  .fa-minus {
    height: 10px;
    width: 10px;
    color: ${Colors.DarkBlue200};
    margin-right: 8px;
    margin-top: 8px;
  }

  .fa-plus {
    height: 10px;
    width: 10px;
    color: ${Colors.DarkBlue200};
    margin-right: 8px;
    margin-top: 8px;
  }
`

const Contanier = styled.div`
  padding-top: 0;
  cursor: pointer;

  i.icon {
    margin-left: 10px;
    margin-right: 15px;
    color: ${Colors.Blue60};
  }
`

const StyledContent = styled.div`
  border-bottom: 1px solid ${Colors.Grey100};
  padding: 14px;
  padding-left: 24px;
  margin-left: 14px;
  margin-right: 14px;
  display: flex;
  color: ${Colors.Black500};
  font-size: 12px;
  background-color: ${(props: Props) => props.background || props.background};

  .fa-bars {
    color: ${Colors.DarkBlue200};
    margin-top: 2px;
    cursor: pointer;
  }

  .fa-building {
    color: ${Colors.DarkBlue200};
    margin-right: 6px;
    width: 25px;
    height: 18px;
  }

  .fa-user {
    color: ${Colors.DarkBlue200};
    margin-right: 6px;
    width: 25px;
    height: 18px;
  }

  .fa-users {
    color: ${Colors.DarkBlue200};
    margin-right: 6px;
    width: 25px;
    height: 18px;
  }

  .fa-sort-down {
    color: ${Colors.DarkBlue200};
    margin-right: 25px;
  }
`

const Types = styled.span`
  flex-grow: 1;
  margin-left: 6px;
`


const StyledDropdown = styled.div`
  width: auto;
  min-width: 28px;
  font-size: 10px;
`

const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Black500};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Black500};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

export {
  Contanier,
  Content,
  DropDownPopup,
  Header,
  StyledAccordionTitle,
  StyledContent,
  StyledDropdown,
  Title,
  Types,
  Wrapper
}
