// Import Packages
import { Button, Dropdown, Icon, Input } from 'semantic-ui-react'
import { css } from 'styled-components'
import styled from 'styled-components'

// Import Fonts, Colors
import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'

const outerPadding = css`
  padding: 1em 2.5em;
`

const padding = css`
  padding: 1em 2em;
  padding-top: 0;
`

const text = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Black500};
  margin: 0;
  line-height: 1.5;
`

const label = css`
  font: ${fonts.medium.regular};
  color: ${Colors.Black400};
  margin: 0;
  line-height: 1.5;
`

const input = css`
  border: none;
  box-shadow: none;
  border-radius: 0;
`

const button = css`
  ${text}
  font: ${fonts.medium.bold};
  padding: 0.6em 1.8em;
  text-transform: uppercase;
  border-radius: 0px;
  background: ${Colors.ButtonPrimary};
  color: ${Colors.White1000};
`

const children = css`
  & > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  & > :first-child {
    margin-left: 0;
  }

  & > :last-child {
    margin-right: 0;
  }
`

const row = css`
  ${children};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`

export const AttachIcon = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
`

export const Clear = styled(Icon)``

export const Container = styled.div`
  ${text};
  ${outerPadding};
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  background: ${Colors.White1000};

  label {
    ${label};
  }
`

export const Label = styled.label`
  ${label};
`

export const EmailAddressDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    ${input};
    flex: 1;
    min-width: 20%;
  }
`

export const Footer = styled.footer`
  ${padding};
  margin: 0;
`

export const QuillContainer = styled.div`
  ${padding};
  display: flex;
  flex: 1;
`

export const Options = styled.div`
  ${padding};
`

export const InputRow = styled.div`
  ${row};
  border-bottom: 1px solid ${Colors.CardShadow};
`

export const Send = styled(Button)`
  &.ui.button {
    ${button};
  }
`

export const SubjectInput = styled(Input)`
  &.ui.input > input {
    ${input};
  }
  flex: 1;
`

export const TemplateDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    ${input};
  }
`

export const Title = styled.h1`
  ${row}
  ${text}
  font: ${fonts.xLarge.bold};
  text-transform: uppercase;
  text-align: left;
  border-bottom: 4px solid ${Colors.CardShadow};
  margin-bottom: 20px;
  padding: 10px 0;
`

export const ToRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`

export const ToButton = styled(Button)`
  &.ui.button {
    margin: 5px 5px 0 0;
  }
`

export const UnTabInput = styled(Input)`
  &.ui.input {
    position: fixed;
    left: -99999px;
    top: -99999px;
    width: 1px;
  }
`
