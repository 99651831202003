import * as React from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'

import ChangeOwnership from 'app/Transactions/Dashboard/ChangeOwnership'
import { changeTransactionOwnership } from 'app/Transactions/Dashboard/TransactionMutations'
import { ShareWith, TransactionList } from 'store/Transactions/Types'

import * as Actions from 'store/Transactions/Actions'

import Dates from './Dates'
import Email from './Email'
import Homeowner from './Homeowner'
import People from './People'

import { Routes } from 'utils'

import {
  Container,
  Section,
} from './Styled'

import {
  CommissionType,
  TransactionType,
} from 'app/Transactions/Details/Types'

interface StoreProps {
  refreshOwnership: (data: TransactionList) => void
  refreshShareWithUser: (data: ShareWith) => void
}

interface OwnProps {
  commission: CommissionType
  people: ShareWith[]
  showTransactionModal: () => void
  transaction: TransactionType
}

type Props = StoreProps & OwnProps & RouteComponentProps<{}, {}>

interface State {
  modal: any
}

class PropertyInformation extends React.Component<Props, State> {
  public state = {
    modal: null,
  }

  public render = () => {
    const {
      commission,
      people,
      showTransactionModal,
      transaction,
    } = this.props

    const { modal } = this.state

    return (
      <Container>
        {modal}
        <Section>
          <Dates
            commission={commission}
            transaction={transaction}
          />
        </Section>
        <Section>
          <Homeowner
            onChangeOwner={this.handleChangeOwner}
            showTransactionModal={showTransactionModal}
            transaction={transaction}
          />
        </Section>
        <Section primary={true}>
          <People
            people={people}
            transaction={transaction}
          />
        </Section>
        <Section>
          <Email transaction={transaction} />
        </Section>
      </Container>
    )
  }

  private handleChangeOwner = () => {
    const {
      refreshOwnership,
      refreshShareWithUser,
      transaction,
    } = this.props

    const changeOwner = async (data: any) => {
      const response = await changeTransactionOwnership(data, transaction._id)
      if (response) {
        this.props.history.push({
          pathname: `${Routes.primary.transactions.path}`
        })
      }
      refreshOwnership(response)
      refreshShareWithUser(response.shareWith)
    }

    const modal = (
      <ChangeOwnership
        transactionOwnerId={transaction.owner._id}
        closeModal={this.closeModal}
        changeOwnership={changeOwner}
      />
    )

    this.setState({ modal })
  }

  private closeModal = () => {
    this.setState({ modal: null })
  }
}

export default withRouter(connect(
  null,
  {
    refreshOwnership: Actions.editOwnership,
    refreshShareWithUser: Actions.getShareWithUser,
  }
)(PropertyInformation))
