import { FormErrorType, FormType } from './Types'
import { PaymentNumericEnum } from 'store/Transactions/Types'

export const handleValidation = (fields: FormType, isEditing: boolean) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!fields.office) {
    formIsValid = false
    errors.office = 'An office must be selected.'
  }

  if (!fields.type) {
    formIsValid = false
    errors.type = 'A category must be selected.'
  }

  if (!fields.subType) {
    formIsValid = false
    errors.subType = 'A subcategory must be selected.'
  }

  if (!fields.transactionRole) {
    formIsValid = false
    errors.transactionRole = 'A transaction role must be selected.'
  }

  if (!fields.price) {
    formIsValid = false
    errors.price = 'Price can not be blank.'
  }

  if (
    !fields.streetName ||
    !fields.streetName.match(/^[a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/) ||
    fields.streetName.trim() === ''
  ) {
    formIsValid = false
    errors.streetName = 'Street name is not valid.'
  }

  if (
    !fields.streetNumber ||
    !fields.streetNumber.match(/^[a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/) ||
    fields.streetNumber.trim() === ''
  ) {
    formIsValid = false
    errors.streetNumber = 'Street number is not valid.'
  }

  if (!fields.city) {
    formIsValid = false
    errors.city = 'City can not be blank.'
  }

  if (!fields.state) {
    formIsValid = false
    errors.state = 'State can not be blank.'
  }

  if (!fields.zipCode || !fields.zipCode.match(/^[0-9]+$/)) {
    formIsValid = false
    errors.zipCode = 'Zip code can not be blank.'
  }

  if (!fields.feeObj?.type && isEditing) {
    formIsValid = false
    errors.currency = 'Please select an amount type.'
  }

  if (!isEditing && (!fields.feeObj?.fee || Number(fields.feeObj.fee) < 0 || (fields.feeObj.type === PaymentNumericEnum.Percent && (Number(fields.feeObj.fee) > 100)))) {
    // TODO: set cap to fee percentage
    formIsValid = false
    errors.amount = 'Please enter a valid fee amount.'
  }

  return { errors, formIsValid }
}
