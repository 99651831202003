import * as React from 'react'

import DocumentZone from 'shared/DocumentZone'
import { serverToast } from 'shared/Toast/Toast'
import Transition from 'shared/Transition'

import { createTransactionDocument } from '../../Mutations'

import { Strings } from 'utils'

import { Col, Row, StyledButton, Title } from '../../Styled'
import { Section } from './Styled'

import { PDFDocumentType } from 'shared/PDFViewer/Types'
import { ActionEnum, TransitEnum } from '../../Types'

interface Props {
  onAction: (action: ActionEnum, data: any) => void
  token: string
  transaction: string
  transit: TransitEnum
}

interface State {
  docs: PDFDocumentType[]
}

const {
  transactionModal: {
    CONCIERGE: { INPUT, TITLE }
  }
} = Strings

class MultiDocumentStep extends React.Component<Props, State> {
  public state = {
    docs: [] as PDFDocumentType[]
  }

  public render() {
    const { transit } = this.props
    const { docs } = this.state

    const transition = transit === TransitEnum.Enter

    return (
      <Col height={100}>
        <Transition on={transition}>
          <Title>{TITLE.multiDocument1}</Title>
        </Transition>
        <Transition on={transition} type="fadeIn" delay={30}>
          <Section primary={true}>
            <DocumentZone allowMultiple={true} docs={docs} onUploadEach={this.handleUpload} />
          </Section>
        </Transition>
        <Transition on={transition} delay={60}>
          <Row justify="center" padding={'1em 0 0'}>
            <StyledButton
              onClick={this.handleStep}
              content={docs.length ? INPUT.multiDocumentSubmit : INPUT.multiDocumentSubmitNone}
            />
          </Row>
        </Transition>
      </Col>
    )
  }

  private handleUpload = async (file: File) => {
    const { token, transaction } = this.props
    const { docs } = this.state

    if (!transaction) {
      return
    }

    try {
      const doc = await createTransactionDocument([file], transaction, token)
      const update = [doc[0], ...docs]
      this.setState({ docs: update })
    } catch (error) {
      serverToast(error)
    }
  }

  private handleStep = () => {
    const { onAction } = this.props
    onAction(ActionEnum.Step, null)
  }
}

export default MultiDocumentStep
