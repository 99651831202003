import styled from 'styled-components'

import { fonts } from 'design/Fonts'
import Colors from 'design/Colors'

import {
  children,
  childWidths,
  link,
  x,
  y,
} from 'design/Styled'

export const Col = styled.div`
  ${y};
`

export const Row = styled.div`
  ${x};
  ${children};
  ${childWidths};
  font: ${fonts.medium.regular};
  color: ${Colors.Text};
`

export const Link = styled.span`
  ${link};
`
