export const fontStyle: string = 'Roboto, sans-serif'
export const defaultSize: string = '16px'
export const PrimaryFontName = 'Roboto'
export const PrimaryFontFamily = 'Roboto, sans-serif'
export const SecondaryFontName = 'Arvo'
export const SecondaryFontFamily = 'Arvo, serif'

export const handwriting = {
  dancingScript: {
    name: 'Dancing Script',
    fontFamily: 'Dancing Script, cursive',
  },
  sacramento: {
    name: 'Sacramento',
    fontFamily: 'Sacramento, cursive',
  },
  rockSalt: {
    name: 'Rock Salt',
    fontFamily: 'Rock Salt, cursive',
  },
  kristi: {
    name: 'Kristi',
    fontFamily: 'Kristi, cursive',
  },
  dawningNewDay: {
    name: 'Dawning of a New Day',
    fontFamily: 'Dawning of a New Day, cursive',
  },
}

export const fonts = {
  xxSmall: {
    size: `.688em`,
    regular: `400 .688em ${fontStyle}`,
    medium: `500 .688em ${fontStyle}`,
    bold: `700 .688em ${fontStyle}`
  },
  xSmall: {
    size: `.75em`,
    regular: `400 .75em ${fontStyle}`,
    medium: `500 .75em ${fontStyle}`,
    bold: `700 .75em ${fontStyle}`
  },
  small: {
    size: `.875em`,
    regular: `400 .875em ${fontStyle}`,
    medium: `500 .875em ${fontStyle}`,
    bold: `700 .875em ${fontStyle}`
  },
  medium: {
    size: `1em`,
    regular: `400 1em ${fontStyle}`,
    medium: `500 1em ${fontStyle}`,
    bold: `700 1em ${fontStyle}`
  },
  normal: {
    size: `1em`,
    regular: `400 1em ${fontStyle}`,
    medium: `500 1em ${fontStyle}`,
    bold: `700 1em ${fontStyle}`
  },
  large: {
    size: `1.125em`,
    regular: `400 1.125em ${fontStyle}`,
    medium: `500 1.125em ${fontStyle}`,
    bold: `700 1.125em ${fontStyle}`
  },
  xLarge: {
    size: `1.313em`,
    regular: `400 1.313em ${fontStyle}`,
    medium: `500 1.313em ${fontStyle}`,
    bold: `700 1.313em ${fontStyle}`
  },
  xxLarge: {
    size: `3em`,
    regular: `400 3em ${fontStyle}`,
    medium: `500 3em ${fontStyle}`,
    bold: `700 3em ${fontStyle}`
  }
}
