import * as React from 'react'
import { Form, Input } from 'semantic-ui-react'

import { FormType } from '../Types'

interface Props {
  form: FormType
  onChange: (e:any, { name: string, value: any }:any) => void
}

class ThirdPartyForm extends React.Component<Props, {}> {
  public render() {
    const {
      form,
      form: { errors },
      onChange,
    } = this.props

    return (
      <Form.Group widths='equal'>
        <Form.Field
          control={Input}
          name='description'
          value={form.description}
          label='Description'
          placeholder='Description'
          className='amount'
          onChange={onChange}
          error={!!errors.description}
        />
        <Form.Field
          control={Input}
          name='name'
          value={form.name}
          label='Referral Name'
          placeholder='Referral Name'
          className='amount'
          onChange={onChange}
          error={!!errors.user}
        />
      </Form.Group>
    )
  }
}

export default ThirdPartyForm
