// Import Packages
import * as React from 'react'
import { Button, Form, Select } from 'semantic-ui-react'

// Import Components
import { createDocChecklist, editDocChecklist } from 'app/Settings/ChecklistManager/ChecklistManagerMutation'
import Modal from 'shared/Modal'
import { getCategoryChecklist } from '../../../ChecklistManagerQueries'
import { handleValidation } from './validation'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Header,
  StyledForm
} from './Styled'

import { ErrorType, Options } from './Types'

interface Props {
  addSubtype: (id: string, result: any, index: number) => void
  categoryId: string
  categoryIndex: number
  categoryName: string
  edit: boolean
  editProperty: (result: string, categoryIndex: number, propertyIndex: number) => void
  handleClose: (toggle: boolean) => void
  mlsId: string
  propertyId: string
  propertyIndex: number
  propertyName: string
}



interface State {
  animate: boolean
  errors: ErrorType
  propertyTypes: Options[]
  result: object
  selectedPropertyType: any
  subPropertyOptions: Options[]
}

export default class AddSubtypeModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    errors: {},
    propertyTypes: [],
    result: {},
    selectedPropertyType: {
      key: '',
      text: '',
      value: ''
    },
    subPropertyOptions: []
  }

  public async componentDidMount() {
    const { edit, propertyName, categoryName } = this.props
    let res: any = []
    if (categoryName === 'Residential' || categoryName === 'Commercial') {
      res = await getCategoryChecklist(categoryName)
    } else {
      res = await getCategoryChecklist(undefined)
    }
    let propertyNameResult: any
    if (edit && propertyName) {
      propertyNameResult = propertyName.split(':')
    }

    if (res) {
      const subProperty: Options[] = []
      let selectedOption: Options = { key: '', text: '', value: '' }
      res.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`,
        }
        if (propertyNameResult && element.name === propertyNameResult[1]) {
          selectedOption = obj
        }
        subProperty.push(obj)
      })
      this.setState({ subPropertyOptions: subProperty, selectedPropertyType: selectedOption })
    }
  }

  public handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>, { name, value }: any) => {
    const { result } = this.state
    const res = value.split(', ')
    this.setState({
      selectedPropertyType: {
        key: res[1],
        text: res[0],
        value: `${res[0]}, ${res[1]}`,
      }
    })
    result[name] = res[0]
    this.setState({ result })
  }

  public submitResult = async () => {
    const {
      addSubtype,
      categoryId: checkListCategory,
      categoryIndex,
      edit,
      editProperty,
      mlsId: mls,
      propertyId,
      propertyIndex,
    } = this.props
    const { result } = this.state

    const validation = handleValidation(result)
    this.setState({ errors: validation.errors })
    if (validation.formIsValid) {
      const checkListSubType = result[`type`]
      if (edit) {
        editProperty(checkListSubType, categoryIndex, propertyIndex)
        editDocChecklist(propertyId, { checkListSubType })
      } else {
        const res = await createDocChecklist({
          checkListCategory,
          checkListSubType,
          mls,
        })
        addSubtype(res._id, checkListSubType, categoryIndex)
      }
      this.closeModal()
    }
  }

  public render() {
    const { animate, selectedPropertyType, subPropertyOptions, errors } = this.state
    return (
      <Modal content={
        <div>
          <Header>
            {Strings.generalText.addCategorySubtype}
          </Header>
          <StyledForm size={'mini'}>
            <Form.Field
              control={Select}
              placeholder='Property Type'
              name='type'
              label='Property Type'
              options={subPropertyOptions}
              value={selectedPropertyType.value}
              onChange={this.handleTypeChange}
              error={errors[`type`] ? true : false} />
            <Form.Field
              control={Button}
              className='save-button'
              onClick={this.submitResult}
              content={'SAVE'} />
          </StyledForm>
        </div>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={350} />
    )
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose(false)
    }, 300)
  }
}
