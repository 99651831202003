import Colors from 'design/Colors'
import RPFlag from 'design/images/RPFlag.png'
import RPLine from 'design/images/RPLine.png'
import { x, y } from 'design/Styled'
import styled from 'styled-components'

export const Container = styled.section`
  ${y};
  position: relative;
  background: ${Colors.Container};
  width: 8.5in;
  height: 11in;
  margin: 0 auto;
  padding: 0;
`

export const Hero = styled.div`
  display: flex;
  width: 100%;
  height: 3in;
`

export const Details = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 2in;
`

export const Row = styled.div`
  ${x};
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0.25in;
`

export const Image = styled.div`
  ${x};
  justify-content: center;
  width: 30%;
  height: 100%;
  background: #444;
  box-shadow: 0 0 0.4in -0.4in #333;
`

export const Info = styled.div`
  height: 1in;
  ${x} justify-content: space-between;
  padding-right: 10px;
  width: 100%;
`

export const Address = styled.div`
  margin-left: 10px;
`

export const Price = styled.div`
  font-weight: bold;
`

export const HeroImage = styled.div`
  width: 70%;
  height: 100%;
  background: #444;
`

export const Owner = styled.div`
  width: 30%;
  height: 100%;
  background: ${Colors.ButtonPrimary};
`

export const CallOut = styled.div`
  display: flex;
  height: 3in;
  width: 100%;
`

export const Description = styled.div`
  width: 33%;
  padding: 1em;
`

export const OwnerImage = styled.div`
  width: 80%;
  height: 40%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #444;
`

export const OwnerDetail = styled.div`
  margin-top: 20px;
  color: ${Colors.TextLight};
  width: 90%;
  margin: 0 auto;

  p {
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const Text = styled.div``

export const CompanyLogo = styled.div`
  ${y} justify-content: center;
  align-items: center;
  height: 1in;
  background-color: #444;
`

export const Logo = styled.div`
  width: 10%;
  height: 55%;
  background: #444;
`

export const MLSId = styled.div`
  font-size: 13pt;
  color: white;
  font-weight: bold;
  padding-bottom: 0.2em;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1in;
`

export const Category = styled.div`
  font-size: 20pt;
  font-family: 'Fredericka the Great';
  text-transform: uppercase;
  color: ${Colors.Text};
  text-align: center;
`

export const LeftRP = styled.div`
  background: url(${RPFlag});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  width: 38px;
`

export const RightRP = styled.div`
  background: url(${RPFlag});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  width: 38px;
  transform: rotate(180deg);
`

export const Line = styled.div`
  background: url(${RPLine});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 6px;
  width: 38%;
`
