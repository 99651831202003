import { Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import {
  children,
  x,
  y,
} from 'design/Styled'

interface Props {
  size?: number
}

export const Row = styled.div`
  ${x};
  ${children};
  margin-bottom: 0.5em;
`

export const Container = styled.section`
  ${x};
  ${children};
  position: relative;
  align-items: flex-start;
  width: 100%;
  background: ${Colors.White525};
  padding: 1em;
  margin-bottom: 1em;
  font-size: 12px;
  color: ${Colors.Text};

  ${Row}:last-child {
    margin-bottom: 0;
  }
`

export const Col = styled.div`
  ${y};
`

export const StyledLabel = styled.label`
  font-weight: bold;
  margin-bottom: 0.5em;
`

export const StyledInput = styled(Input)`
  margin-bottom: 0.5em;
`

export const Delete = styled.div`
  position: absolute;
  top: ${(props:Props) => (props.size || 22) * -0.4}px;
  right: ${(props:Props) => (props.size || 22) * -0.4}px;

  .rp-button-bubble {
    box-shadow: 0 0 5px -2px ${Colors.ContainerShadow};
  }
`
