// Import Packages
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import styled from 'styled-components'

const ProfileDetail = styled(Grid.Column)`
  padding-top: 10px;

  .ui[class*="equal width"].grid>&.wide.column {
    height: 100%;
    max-width: 75%;
    padding-top: 0;

    @media ( min-width: 991px ) and (max-width: 1200px) {
      max-width: 75%
    }
    @media ( min-width: 1200px ) {
      max-width: 80%
    }
  }

  .ui.stretched.grid>.column {
    padding-left: 0;
  }
`

export {
  ProfileDetail
}
