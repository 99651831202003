import styled from 'styled-components'

import Colors from 'design/Colors'
import {
  children,
  x,
  y
} from 'design/Styled'

export const Container = styled.div`
  ${x};
  ${children};
  height: 100%;
  overflow: hidden;
  padding: 2em 1.4em;
`

export const Sidebar = styled.nav`
  ${y};
  height: 100%;
  width: 240px;
  overflow: hidden;
  background: ${Colors.Container};
  box-shadow: 0 0 10px -10px ${Colors.ContainerShadow};
  font-size: 13px;
`

export const Main = styled.section`
  ${y};
  flex: 1;
  height: 100%;
  overflow: hidden;
`
