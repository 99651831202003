// Import Packages
import { groupBy } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { Card, Form, Grid, Image, Loader } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import New from './New'
import Payable from './Payable'
import Received from './Received'
import { cardMockData } from './TransactionSummaryMockDate'
import Updated from './Updated'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import { updateUserPass } from '../../Mutations'

import { UserPassType } from 'app/Dashboard/MainContent/Passes/Types'
import { Transaction as TransactionProp } from 'store/Transactions/Types'
import { getTransactions } from 'app/Transactions/Dashboard/TransactionQueries'

// Import Images and Icons
import Colors from 'design/Colors'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { Container, DescriptionWrapper, LeftPanel, RightPanel, StyledCard, StyledDropdown, Wrapper } from './Styled'
interface Props extends RouteComponentProps<{}, {}> {
  closeModal: () => void
  onUpdate: (data: any) => void
  pass: UserPassType
  transactions: TransactionProp[]
}

interface State {
  animate: boolean
  timePeriod: number
  payableTransaction: any[]
  newTransaction: any[]
  receivedTransaction: any[]
  updatedTransaction: any[]
  loader: boolean
}

const options = [{ key: 1, text: 30, value: 30 }, { key: 2, text: 60, value: 60 }, { key: 3, text: 90, value: 90 }]
class TransactionSummaryModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    loader: true,
    newTransaction: [],
    payableTransaction: [],
    receivedTransaction: [],
    timePeriod: 30,
    updatedTransaction: []
  }

  public dateFilters = (data: any) => {
    const { timePeriod } = this.state
    let beforeDate: any
    const currentDate = moment()
    beforeDate = moment().subtract(timePeriod, 'days')
    const items = data ? data[0].items : null
    const setTimePeriodForLeads = items.filter((list: any) => {
      return (
        moment(moment(list.createdAt).format('YYYY-MM-DD')).isSameOrAfter(moment(beforeDate).format('YYYY-MM-DD')) &&
        moment(moment(list.createdAt).format('YYYY-MM-DD')).isSameOrBefore(moment(currentDate).format('YYYY-MM-DD'))
      )
    })
    return setTimePeriodForLeads
  }

  public getTimePeriodData = async () => {
    let transactionListData: any = {}
    transactionListData = await getTransactions({})

    if (transactionListData) {
      this.setState({ loader: false })
    }

    const data = groupBy(this.props.transactions, 'name')
    if ('Payable' in data) {
      this.setState({ payableTransaction: this.dateFilters(data['Payable']) })
    }
    if ('New' in data) {
      this.setState({ newTransaction: this.dateFilters(data['New']) })
    }
    if ('$$ Received' in data) {
      this.setState({ receivedTransaction: this.dateFilters(data['$$ Received']) })
    }
    if ('Updated' in data) {
      this.setState({ updatedTransaction: this.dateFilters(data['Updated']) })
    }
  }

  public async componentDidMount() {
    const {
      pass: { timePeriod }
    } = this.props
    if (timePeriod !== null) {
      this.setState({ timePeriod }, () => this.getTimePeriodData())
    } else {
      this.setState({ timePeriod: 30 }, () => this.getTimePeriodData())
    }
  }

  public render() {
    const {
      animate,
      timePeriod,
      payableTransaction,
      newTransaction,
      receivedTransaction,
      updatedTransaction,
      loader
    } = this.state
    const card = cardMockData.map((data: any, index: number) => {
      let background
      let count
      switch (data.meta) {
        case 'New':
          background = Colors.Orange250
          count = newTransaction.length
          break
        case 'Payable':
          background = Colors.Magenta50
          count = payableTransaction.length
          break
        case 'Received':
          background = Colors.Blue111
          count = receivedTransaction.length
          break
        default:
          background = Colors.Magenta60
          count = updatedTransaction.length
          break
      }
      return (
        <Grid.Column key={index}>
          <StyledCard background={background}>
            <Card.Content>
              <Card.Header content={count} />
              <Card.Meta content={`(${data.meta})`} />
              <Card.Description
                content={
                  <DescriptionWrapper>
                    <Image src={data.chart} size="small" />
                    <div className="trend">{Strings.dashboard.passes.trend}</div>
                    <div className="month">{`Last ${timePeriod} Days`}</div>
                  </DescriptionWrapper>
                }
              />
            </Card.Content>
          </StyledCard>
        </Grid.Column>
      )
    })
    return (
      <Modal
        content={
          <Container>
            <LeftPanel>
              <Grid divided="vertically">
                <Grid.Row columns={2}>{card}</Grid.Row>
              </Grid>
            </LeftPanel>
            <RightPanel>
              <h3 className="ui dividing header">{`Last ${timePeriod} Days Transaction Summary`}</h3>
              <Form size="mini" className="days-dropdown">
                <span>{Strings.dashboard.passes.time}</span>
                <StyledDropdown
                  name="timePeriod"
                  onChange={this.changeTimePeriod}
                  selection={true}
                  value={timePeriod}
                  options={options}
                />
              </Form>
              {loader ? (
                <Loader active={true} size="large" />
              ) : (
                  <Wrapper>
                    {payableTransaction &&
                      payableTransaction.length > 0 && <Payable payableTransaction={payableTransaction} />}
                    {newTransaction && newTransaction.length > 0 && <New newTransaction={newTransaction} />}
                    {receivedTransaction &&
                      receivedTransaction.length > 0 && <Received receivedTransaction={receivedTransaction} />}
                    {updatedTransaction &&
                      updatedTransaction.length > 0 && <Updated updatedTransaction={updatedTransaction} />}
                  </Wrapper>
                )}
            </RightPanel>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={1000}
      />
    )
  }

  private changeTimePeriod = async (e: any, { value }: any) => {
    const { onUpdate, pass } = this.props
    this.setState({ timePeriod: value }, () => this.getTimePeriodData())

    const input = { timePeriod: value }
    try {
      const response = await updateUserPass(pass._id, input)
      onUpdate(response)
    } catch {
      // userPass not updated
    }
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

const mapStateToProps = (state: AppState) => ({
  transactions: state.transactions.allTransactions
})

export default withRouter(connect(
  mapStateToProps,
  {}
)(TransactionSummaryModal))
