import client from 'queries/apollo'

import { GET_USER_SIGNATURE_DATA } from 'queries/graphql/UserProfile/Queries'

export const getSignatureData = async (id: string) => {
  const query = await client.query({
    query: GET_USER_SIGNATURE_DATA,
    variables: { id }
  })

  const { data } = query
  if (data && data.userSignature) {
    return data.userSignature[0]
  }

  return false
}
