import { MessageEmailCardData } from 'store/Mailbox/Types'
import { getMessageEmailById } from './MailboxQueries'

export const ConvertEmailToTask = async (data: MessageEmailCardData) => {
  const { _id, subject, preview } = data

  const fullMessage = await getMessageEmailById(_id)

  const task = {
    description: preview,
    messageEmail: fullMessage,
    name: subject,
    sharedWith: [],
    type: 'Email'
  }
  return task
}
