// Import Packages
import { Checkbox, Popup, Table } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  background: ${Colors.White1000};
`

const Title = styled.div`
  border-bottom: 2px solid ${Colors.Black765};
  color: ${Colors.DarkBlue200};
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  padding-left: 30px;
`

const StyledTable = styled(Table)`
  .ui.selection.dropdown {
    border-radius: 0;
    width: 300px;
  }

  &.ui.table {
    width: 70%;
    padding-left: 22px;
    padding-bottom: 50px;
    font-size: 12px;
    color: ${Colors.Black500};
  }

  &.ui.basic.table {
    border: none;
  }

  &.ui.table tr td {
    border: none;
    height: 60px;
  }

  .fa-info-circle {
    margin-left: 6px;
    cursor: pointer;
  }
`

const StyledCheckbox = styled(Checkbox)`
  &.ui.toggle.checkbox label::before {
    width: 30px;
    height: 18px;
    background-color: ${Colors.Grey565} !important;
  }

  &.ui.toggle.checkbox.test input:checked~.box:before,
  &.ui.toggle.checkbox.test input:checked~label:before,
  &.ui.toggle.checkbox.test input:focus:checked~.box:before,
  &.ui.toggle.checkbox.test input:focus:checked~label:before {
    width: 30px;
    height: 18px;
    background-color: ${Colors.DarkBlue200} !important;
  }

  &.ui.toggle.checkbox input:checked~label:after {
    left: 0.85rem !important;
  }

  &.ui.toggle.checkbox label::after {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-left: 2px;
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    font-size: 0.8rem;
    width: 225px;
    color: ${Colors.Black500};
  }
`

export {
  Container,
  StyledCheckbox,
  StyledTable,
  Title,
  StyledPopup
}

