// Import Packages
import * as React from 'react'
import { Button } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import { LeadItemsDetails } from 'store/Leads/Types'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Container,
  Slider,
  SliderContainer,
  SliderGroupContent,
  StyledOutput,
  StyledSlider,
  Title,
} from './Styled'

interface Props {
  closeModal: () => void
  changeLeadScore: (score: number) => void
  leadData: LeadItemsDetails
}

interface State {
  animate: boolean
  value: number
}


class UpdateScoreModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    value: 0
  }

  public componentDidMount() {
    const { leadData } = this.props
    this.setState({ value: leadData.leadScore })
  }

  public render() {
    const { animate, value } = this.state
    let color = ''
    if (value >= 0 && value < 25) {
      color = Colors.DarkBlue205
    } else if (value >= 25 && value < 50) {
      color = Colors.Grey850
    } else if (value >= 50 && value < 75) {
      color = Colors.Green80
    } else {
      color = Colors.Red10
    }
    return (
      <Modal
        content={
          <Container>
            <Title>
              Update Lead Score
            </Title>
            <Slider>
              <SliderGroupContent width={100}>
                <SliderContainer>
                  <StyledSlider type="range" min={0} max={100} value={value} onChange={this.onChange} step={5} />
                  <StyledOutput value={value} min={0} max={100} color={color}>{value}</StyledOutput>
                </SliderContainer>
              </SliderGroupContent>
            </Slider>
            <Button content={'SAVE'} onClick={this.updateScore} />
          </Container>
        } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={300}
      />
    )
  }

  private onChange = (e: any) => {
    this.setState({ value: parseInt(e.target.value) })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private updateScore = () => {
    const { changeLeadScore, closeModal } = this.props
    const { value } = this.state
    changeLeadScore(value)
    closeModal()
  }
}

export default UpdateScoreModal
