import * as React from 'react'

import Scrollable from 'shared/Scrollable'
import CalendarPanel from './CalendarPanel'

import { Event } from 'store/Events/Types'

import {
  Container
} from './Styled'

class Events extends React.Component<Event> {
  public render() {
    return (
      <Scrollable>
        <Container>
          <CalendarPanel />
        </Container>
      </Scrollable>
    )
  }
}

export default Events
