// Import Packages
import { Checkbox, Form, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Colors from 'design/Colors'

interface Props {
  background: string
}

const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

const AddContactLeftPanel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40%;
  height: auto;
  background: ${Colors.Grey110};

  .ui.small.image {
    width: 100px;
  }
`

const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 15px;
  color: ${Colors.Black500};
`

const Text = styled.div`
  width: 200px;
  text-align: center;
  margin-top: 5px;
  font-size: 18px;
  color: ${Colors.Black500};
  font-weight: 600;
`

const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 55%;
  text-align: right;

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    font-weight: bold;
    width: 100px;
  }

  h2 {
    font-size: 1.014286rem;
    text-align: center;
    margin-bottom: 40px;
    color: ${Colors.DarkBlue205};
  }

  .ui.input {
    width: 100px;
    border-radius: 0px;
  }

  .fa-info-circle {
    width: 16px;
    height: 16px;
    margin-left: 6px;
    cursor: pointer;
  }

  .ui.basic.table {
    color: ${Colors.DarkBlue205};
  }
`

const StyledForm = styled(Form)`
  &.ui.form .field > label {
    color: ${Colors.Black500};
  }

  &.ui.form {
    max-width: 60%;
    margin: 0 auto;
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

const Icons = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  align-self: center;
  margin-top: 18px;
  border: 1px solid ${Colors.DarkBlue200};
  background-size: cover;
  border-radius: 50%;
`

const StyledCheckbox = styled(Checkbox)`
  &.ui.toggle.checkbox label::before {
    width: 30px;
    height: 18px;
    background-color: ${Colors.Grey565} !important;
  }

  &.ui.toggle.checkbox.test input:checked ~ .box:before,
  &.ui.toggle.checkbox.test input:checked ~ label:before,
  &.ui.toggle.checkbox.test input:focus:checked ~ .box:before,
  &.ui.toggle.checkbox.test input:focus:checked ~ label:before {
    width: 30px;
    height: 18px;
    background-color: ${Colors.DarkBlue200} !important;
  }

  &.ui.toggle.checkbox input:checked ~ label:after {
    left: 0.85rem !important;
  }

  &.ui.toggle.checkbox label::after {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-left: 2px;
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
  }
`

export {
  AddContactLeftPanel,
  StyledCheckbox,
  AddForm,
  Container,
  Text,
  StyledForm,
  Icons,
  Title,
  StyledPopup
}
