import { debounce } from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'

import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import Scrollable from 'shared/Scrollable'
import Passes from './Passes'
import Slider from './Slider'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Dashboard/Actions'

import { createUserPass } from '../Mutations'
import { getPasses } from '../Queries'

import { Body, Categories, Container, Header, NoResults, Search, Section } from './Styled'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { FormType, PassType } from './Types'

interface Props {
  setFilteredPasses: (passes: PassType[]) => void
  getPassesSection: (data: any) => void
  closeModal: () => void
  sectionId: string
  passesSection: any[]
  passes: PassType[]
}

interface State {
  activePass: PassType
  addedPass: string
  filteredPasses: PassType[]
  form: FormType
  loading: boolean
  transition: boolean
}

const options = [
  { key: 1, text: 'All', value: '' },
  { key: 2, text: 'Productivity', value: 'Productivity' },
  { key: 3, text: 'Summary', value: 'Summary' },
  { key: 4, text: 'Configuration', value: 'Configuration' },
  { key: 5, text: 'Transactions', value: 'Transactions' },
  { key: 6, text: 'Leads', value: 'Leads' }
]

class BrowseAllModal extends React.Component<Props, State> {
  public state = {
    activePass: {} as PassType,
    addedPass: '',
    filteredPasses: [] as PassType[],
    form: {
      category: '',
      search: ''
    },
    loading: true,
    transition: true
  }

  private debounceJob: any = null
  private transition: number = 400

  public componentDidMount = async () => {
    const { setFilteredPasses } = this.props
    const filteredPasses: any = (await getPasses()) || ([] as PassType[])
    setFilteredPasses(filteredPasses)
    this.setState({
      filteredPasses,
      loading: false
    })
  }

  public componentWillUnmount = () => {
    this.debounceJob = null
  }

  public render() {
    const { activePass, addedPass, filteredPasses, form, loading, transition } = this.state

    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
            <Header>
              <Categories
                name="category"
                onChange={this.handleInputChange}
                options={options}
                placeholder="Select Pass Type"
                search={true}
                selection={true}
                value={form.category}
              />
              <Search
                name="search"
                icon="search"
                placeholder="Search Pass"
                onChange={this.handleInputChange}
                value={form.search}
              />
            </Header>
            <Body primary={true}>
              {filteredPasses.length ? (
                <React.Fragment>
                  <Section>
                    <Scrollable>
                      <Passes
                        added={addedPass}
                        onSelect={this.moreInfo}
                        onAdd={this.addPass}
                        passes={filteredPasses}
                        transition={this.transition}
                      />
                    </Scrollable>
                  </Section>
                  <Section width={25}>
                    <Slider isAdding={!!addedPass} onAdd={this.addPass} pass={activePass} />
                  </Section>
                </React.Fragment>
              ) : (
                <NoResults />
              )}
            </Body>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        maximum={true}
      />
    )
  }

  private getFilteredPasses = () => {
    const { passes } = this.props

    const {
      form: { category, search }
    } = this.state

    let filteredPasses = [...passes]
    if (!category && !search) {
      this.setState({ filteredPasses })
      return
    }

    let passTypes: any = ['Activity_HotSheet', 'Concierge', 'General', 'Idx', 'Leads', 'Transactions']
    switch (category) {
      case 'Productivity':
        passTypes = ['Concierge', 'Idx']
        break

      case 'Configuration':
        passTypes = ['General']
        break

      case 'Transactions':
        passTypes = ['Transactions', 'Idx']
        break

      case 'Leads':
        passTypes = ['Leads']
        break

      case 'Summary':
        passTypes = ['Activity_HotSheet']
        break

      default:
        break
    }

    filteredPasses = passes.filter((item: PassType) => {
      const re = new RegExp(search || '', 'gi')
      return passTypes.includes(item.passType) && (re.test(item.name) || re.test(item.summary))
    })

    this.setState({ filteredPasses })
  }

  private handleInputChange = (e: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })

    this.debounceJob = debounce(this.getFilteredPasses, 500)
    this.debounceJob()
  }

  private addPass = async (pass: PassType) => {
    const { getPassesSection, passes, sectionId, setFilteredPasses } = this.props
    const { activePass } = this.state

    const index = passes.findIndex((item: PassType) => item._id === pass._id)
    if (index < 0) {
      return
    }
    this.setState({ addedPass: passes[index]._id })

    window.setTimeout(async () => {
      const updated = [...passes]

      updated.splice(index, 1)
      setFilteredPasses(updated)
      this.setState({
        activePass: activePass._id === pass._id ? ({} as PassType) : activePass,
        addedPass: '',
        filteredPasses: updated
      })

      try {
        const response = await createUserPass(pass._id, sectionId)
        getPassesSection(response)
      } catch (e) {
        setFilteredPasses(passes)
        this.setState({ filteredPasses: passes })
      }
    }, this.transition + 100)
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private moreInfo = (data: any) => {
    this.setState({ activePass: data })
  }
}

const mapStateToProps = (state: AppState) => ({
  passes: state.dashboard.categories
})

export default connect(
  mapStateToProps,
  {
    setFilteredPasses: Actions.categoriesFilter
  }
)(BrowseAllModal)
