import styled from 'styled-components'

import Colors from 'design/Colors'

export const Container = styled.figure`
  display: block;
  margin: 1em;
  padding: 0;
  position: relative;
  float: left;
  cursor: pointer;

  &.active-sort-item {
    z-index: 99999;
  }

  &.sorting > div {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-2deg);
    box-shadow: 0 0 10px 1px ${Colors.CardShadow};
    transition: all 100ms ease-out;
  }
`

export const Content = styled.div`
  display: block;
  position: relative;
  height: 140px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 13px 5px ${Colors.CardShadow};
  transform: scale3d(1, 1, 1) rotate(0deg);
  transition: all 100ms ease-out;

  &:hover .sidebar {
    opacity: 0.8;
    transform: translate3d(0, 0, 0);
    &:hover {
      opacity: 1;
    }
  }
`

export const Thumbnail = styled.img`
  display: block;
  position: relative;
  height: 100%;
`

export const Sidebar = styled.aside`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  height: 100%;
  width: 30px;
  background: ${Colors.EmphasisContainer};
  transition: all 200ms;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  box-shadow: 0 0 16px 5px ${Colors.transparent};
  overflow: hidden;
`
