import * as React from 'react'
import { Form, Input } from 'semantic-ui-react'

import AddressInput from 'shared/AddressInput'
import Button from 'shared/Button'
import SensitiveInput from 'shared/SensitiveInput'
import StateDropdown from 'shared/StateDropdown'

import { getUserProfile } from '../Queries'

import { FormType, PaymentMethodEnum, EscrowRecipientType } from '../Types'
import { CommissionType, TransactionType, } from 'app/Transactions/Details/Types'
import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'
import { PaymentNumericEnum } from 'store/Transactions/Types'

interface Props {
  commission: CommissionType
  form: FormType
  onUpdate: (update: FormType) => void
  transaction: TransactionType
}

class EscrowForm extends React.Component<Props, {}> {
  public componentDidMount = async () => {
    const {
      commission,
      form,
      onUpdate,
      transaction: { owner },
    } = this.props

    if (!form.paymentMethod) {
      form.paymentMethod = PaymentMethodEnum.Ach
    }

    // TODO: temporary patch. This problem should be solved server-side
    form.feeObj.fee = Math.round(commission.commissionDue * 100) / 100
    form.feeObj.type = PaymentNumericEnum.Fixed
    form.brokerageName = `${owner.firstName} ${owner.lastName}`

    const recipient:EscrowRecipientType = await getUserProfile(owner._id) || {} as EscrowRecipientType
    const address:any = recipient.addresses.find((item:any) => item.type === 'Home') || recipient.addresses[0]
    form.address = {
      city: address.city,
      state: address.state,
      streetAddress: `${address.streetNumber} ${address.streetName}`,
      streetName: address.streetName,
      streetNumber: address.streetNumber,
      zipCode: address.zipCode,
    }
    form.brokerageTaxID = (recipient.taxID || '').replace(/[^0-9]/gi,'') || ''
    form.bankName = recipient.bankName || form.bankName || ''
    form.accountNumber = recipient.accountNumber || form.accountNumber || ''
    form.routingNumber = recipient.routingNumber || form.routingNumber || ''

    onUpdate(form)
  }

  public render() {
    const {
      form,
      form: { errors },
    } = this.props

    return (
      <div>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            name='brokerageName'
            value={form.brokerageName}
            error={!!errors.brokerageName}
            label='Agent Name'
            placeholder='Agent Name'
            onChange={this.handleChange}
            disabled={true}
          />
          <div className='field'>
            <label>Agent Tax ID</label>
            <SensitiveInput
              name='brokerageTaxID'
              placeholder='Agent Tax ID'
              onChange={this.handleNumberChange}
              value={form.brokerageTaxID}
              error={!!errors.brokerageTaxID}
              maxLength={9}
            />
          </div>
        </Form.Group>
        <div className='field'>
          <label>Select a Payment Method</label>
          <Button.Toggle
            monochrome={1}
            name='paymentMethod'
            onChange={this.handleChange}
            options={[
              { label: 'ACH', value: PaymentMethodEnum.Ach },
              { label: 'Check', value: PaymentMethodEnum.Check },
            ]}
            showLabels={true}
            value={form.paymentMethod}
          />
        </div>
        {form.paymentMethod === PaymentMethodEnum.Check && (
          <React.Fragment>
            <Form.Field
              address={form.address}
              control={AddressInput}
              error={!!errors.street}
              label='Street Address'
              name='streetAddress'
              onChange={this.handleAddressChange}
              placeholder='Street Address'
              size='mini'
            />
            <Form.Group widths='equal'>
              <Form.Field
                address={form.address}
                control={AddressInput}
                error={!!errors.city}
                label='City'
                mode={AddressModeEnum.City}
                name='city'
                onChange={this.handleAddressChange}
                placeholder='City'
                size='mini'
              />
              <Form.Field
                control={StateDropdown}
                error={!!errors.state}
                label='State'
                name='state'
                onChange={(e:any, { value }:any) => this.handleAddressChange({ state: value })}
                placeholder='State'
                value={form.address.state}
              />
              <Form.Field
                control={Input}
                error={!!errors.zipCode}
                label='Zipcode'
                name='zipCode'
                onChange={(e:any, { value }:any) => this.handleAddressChange({ zipCode: value })}
                placeholder='Zipcode'
                type='number'
                value={form.address.zipCode}
              />
            </Form.Group>
          </React.Fragment>
        )}
        {form.paymentMethod === PaymentMethodEnum.Ach && (
          <React.Fragment>
            <Form.Field
              control={Input}
              name='bankName'
              value={form.bankName}
              error={!!errors.bankName}
              label='Bank Name'
              placeholder='Bank Name'
              onChange={this.handleChange}
            />
            <div className='field'>
              <label>Routing Number</label>
              <SensitiveInput
                error={!!errors.routingNumber}
                name='routingNumber'
                placeholder='Routing Number'
                onChange={this.handleNumberChange}
                value={form.routingNumber}
              />
            </div>
            <div className='field'>
              <label>Account Number</label>
              <SensitiveInput
                error={!!errors.accountNumber}
                name='accountNumber'
                placeholder='Account Number'
                onChange={this.handleNumberChange}
                value={form.accountNumber}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }

  private handleNumberChange = (e:any, { name, value }: any) => {
    const { form, onUpdate } = this.props
    if (value !== '' && /[^0-9]/.test(value)) {
      value = form[name]
    }
    form[name] = value
    onUpdate(form)
  }

  private handleAddressChange = (address: Partial<AddressType>) => {
    const { form, onUpdate } = this.props
    const value = { ...form.address, ...address }
    form.address = value
    onUpdate(form)
  }

  private handleChange = (e:any, { name, value }: any) => {
    const { form, onUpdate } = this.props
    form[name] = value
    onUpdate(form)
  }
}

export default EscrowForm
