// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'

// Import Store Types, Actions and Reducers
import { EventDetails, UserType } from 'store/Events/Types'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Address,
  Clock,
  ClockTitle,
  Close,
  Description,
  EventDelete,
  EventDescription,
  EventEdit,
  EventProperty,
  EventShared,
  EventTitle,
  Labels,
  MemberProfile,
  Phone,
  PhoneIcon,
  PhoneTitle,
  PopupWrapper,
  SharedWithUsers,
  StyledLabel,
  StyledPopup,
  TimeIcon
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faPhone, faSms, faTimes, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone, faSms, faTimes, faPencilAlt, faCalendarAlt, faTrashAlt])

interface Props {
  data: EventDetails
  user: UserType
  editEvent: (data: EventDetails) => void
  deleteEvent: (data: EventDetails) => void
}

interface State {
  isOpen: boolean
}

class CalendarPopup extends React.Component<Props, State> {
  public state = {
    isOpen: false
  }

  public render() {
    const { data, user } = this.props
    let toggle = true
    const Avatars =
      data.sharedWith &&
      data.sharedWith.map((value: any, index: number) => {
        while (index < 3) {
          return (
            <div className="images" key={index}>
              <StyledPopup
                trigger={<MemberProfile background={value.profileImage} />}
                content={`${value.firstName} ${value.lastName}`}
              />
            </div>
          )
        }
        return null
      })

    const Count =
      data.sharedWith && data.sharedWith.length > 3 ? (
        <div className="images">
          <Labels>
            <StyledPopup trigger={<StyledLabel>...</StyledLabel>} content={`+${data.sharedWith.length - 3}`} />
          </Labels>
        </div>
      ) : null

    let backgroundIcon: any
    let backgroundColor = ''
    switch (data.type) {
      case 'Email':
        backgroundIcon = 'envelope'
        backgroundColor = Colors.Red25
        break
      case 'Call':
        backgroundIcon = 'phone'
        backgroundColor = Colors.Blue50
        break
      case 'Meeting':
        backgroundIcon = 'handshake'
        backgroundColor = Colors.Purple50
        break
      case 'Sms':
        backgroundIcon = 'sms'
        backgroundColor = Colors.Pink70
        break
      case 'Pass':
        backgroundIcon = 'calendar-alt'
        backgroundColor = Colors.Orange110
        break
    }

    if (data.lead) {
      data.lead.shareWith.forEach((item: any) => {
        if (item._id === user._id) {
          toggle = false
        }
      })
    }
    return (
      <div>
        <PopupWrapper
          trigger={
            <div style={{ display: 'flex' }}>
              {data.category === 'Task' ? (
                <FontAwesomeIcon icon={['fal', backgroundIcon]} style={{ color: backgroundColor }} />
              ) : (
                <FontAwesomeIcon icon={['far', 'calendar-alt']} style={{ color: Colors.Orange110 }} />
              )}
              <div className="title">{data.title}</div>
            </div>
          }
          content={
            <div style={{ paddingBottom: '20px' }}>
              <Close onClick={this.handleClose}>
                <FontAwesomeIcon icon={['fal', 'times']} />
              </Close>
              <EventTitle>
                {data.title}
                {user.role !== 'GUEST' &&
                  (user.role === 'ADMIN' || user.role === 'MANAGER' || data.createdBy._id === user._id) && (
                    <>
                      <EventEdit onClick={this.editEvent}>
                        <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
                      </EventEdit>
                      <EventDelete onClick={this.deleteEvent}>
                        <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                      </EventDelete>
                    </>
                  )}
              </EventTitle>
              <EventProperty>{data.description}</EventProperty>
              {data.lead && (
                <>
                  <EventShared>Task Related To</EventShared>
                  <Link
                    to={
                      toggle
                        ? user.role === 'ADMIN' || user.role === 'MANAGER'
                          ? Routes.leads.root + Routes.leads.details.path + `/${data.lead._id}?pool=1`
                          : ''
                        : Routes.leads.root + Routes.leads.details.path + `/${data.lead._id}?pool=0`
                    }
                  >
                    <Description>{data.lead && `${data.lead.firstName} ${data.lead.lastName}`}</Description>
                  </Link>
                </>
              )}
              {data.transaction && (
                <>
                  <EventShared>Task Related To</EventShared>
                  <Link to={Routes.transactions.root + `/${data.transaction._id}` + Routes.transactions.documents.path}>
                    <EventDescription>
                      {data.transaction &&
                        data.transaction.owner &&
                        `${data.transaction.owner.firstName} ${data.transaction.owner.lastName}`}
                    </EventDescription>
                    <div style={{ textTransform: 'capitalize' }}>
                      <Address>
                        {data.transaction.propertyId && data.transaction.propertyId.address
                          ? `${data.transaction.propertyId.address.streetNumber} ${
                              data.transaction.propertyId.address.streetName
                            }`
                          : 'No Address Found'}
                      </Address>
                      <Address>
                        {data.transaction.propertyId && data.transaction.propertyId.address
                          ? `${data.transaction.propertyId.address.city}, ${
                              data.transaction.propertyId.address.state
                            } ${data.transaction.propertyId.address.zipCode}`
                          : 'No Address Found'}
                      </Address>
                    </div>
                  </Link>
                </>
              )}
              <TimeIcon>
                <Clock />
                {data.category === 'Event' ? (
                  <ClockTitle>
                    <span>
                      <b>
                        {moment(data.start).format('L')}-{moment(data.end).format('L')}
                      </b>
                    </span>
                    <span>
                      {moment(data.start).format('LT')}-{moment(data.end).format('LT')}
                    </span>
                  </ClockTitle>
                ) : (
                  <ClockTitle>
                    <span>
                      <b>{moment(data.end).format('L')}</b>
                    </span>
                    <span>
                      {moment(data[`taskTime`]).format('LT') !== 'Invalid date'
                        ? moment(data[`taskTime`]).format('LT')
                        : 'No Task time found'}
                    </span>
                  </ClockTitle>
                )}
              </TimeIcon>
              <PhoneIcon>
                <Phone />
                <PhoneTitle>
                  <b>{data.phone}</b>
                </PhoneTitle>
              </PhoneIcon>
              {data.sharedWith.length > 0 && (
                <EventShared>
                  {data.category === 'Event'
                    ? Strings.eventsPopup.calendarPanel.eventShared
                    : Strings.eventsPopup.calendarPanel.taskShared}
                  <SharedWithUsers>
                    {Avatars}
                    {Count}
                  </SharedWithUsers>
                </EventShared>
              )}
            </div>
          }
          on="click"
          open={this.state.isOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
        />
      </div>
    )
  }

  private handleOpen = () => {
    this.setState({ isOpen: true })
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private editEvent = () => {
    const { data, editEvent } = this.props
    this.setState({ isOpen: false })
    editEvent(data)
  }

  private deleteEvent = () => {
    const { data, deleteEvent } = this.props
    this.setState({ isOpen: false })
    deleteEvent(data)
  }
}

export default CalendarPopup
