// Import Packages
import * as React from 'react'
import { Button, Form, Image, Select } from 'semantic-ui-react'

// Import Images and Icons
import Modal from 'shared/Modal'

// Import Graphql Queries
import { getDocChecklist } from '../../../../../Dashboard/TransactionQueries'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  AddForm,
  Container,
  LeftPanel,
  StyledForm,
  StyledText,
} from './Styled'

interface Props {
  closeModal: () => void
  createChecklist: (id:string) => void
}

interface OptionType {
  key: string
  value: string
  text: string
}

interface State {
  animate: boolean
  checklistId: string
  options: OptionType[]
}

class AddChecklist extends React.Component<Props, State> {
  public state = {
    animate: true,
    checklistId: '',
    options: [] as OptionType[],
  }

  public async componentDidMount() {
    const checklistData = await getDocChecklist()
    if (!checklistData) {
      return
    }

    const options:OptionType[] = []
    checklistData.forEach((element: any) => {
      options.push({
        key: element._id,
        text: `${element.mls.shortName}:${element.checkListSubType}`,
        value: element._id,
      })
    })
    this.setState({ options })
  }

  public render() {
    const { options, animate } = this.state
    return (
      <Modal
        content={
          <Container>
            <LeftPanel>
              <Image src={Strings.generalText.changeOwnership} size='small' />
            </LeftPanel>
            <AddForm>
              <StyledForm>
                <StyledText>{Strings.transactionCommissionTab.checklist}</StyledText>
                <Form.Field
                  control={Select}
                  name='checklist'
                  placeholder='Select Checklist Type'
                  options={options}
                  onChange={this.handleChange}
                />
                <Button
                  style={{ width: '170px', marginTop: '10px' }}
                  type="button"
                  onClick={this.createChecklist}
                  content="Apply Checklist"
                />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        width={500}
      />
    )
  }

  private handleChange = async (event: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    this.setState({ checklistId: value })
  }

  private closeSelf = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private createChecklist = () => {
    const { createChecklist } = this.props
    const { checklistId } = this.state
    createChecklist(checklistId)
    this.closeSelf()
  }
}

export default AddChecklist
