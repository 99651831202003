// Import Packages
import * as React from 'react'
import NumberFormat from 'react-number-format'
import { Form, Input, Select } from 'semantic-ui-react'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'

// Import Components
import Modal from 'shared/Modal'
import { UserCreditDebit } from 'store/UserProfile/Types'
import { handleValidation } from './validation'

// Import Utils
import { Strings } from 'utils'
import { FormErrorType, FormType } from './Types'

// Import Styled Components
import {
  DetailContainer,
  ImageContainer,
  SegmentGroup,
  StyledButton,
  StyledForm,
} from './Styled'

const AddAccTransactionStrings = Strings.userProfile.addAccTransaction

interface Props {
  handleClose: (toggle: boolean) => void
  addCreditDebit: (data: UserCreditDebit | any) => void
  editCreditDebitObj: UserCreditDebit | any
  edit: boolean
}

interface State {
  animate: boolean
  form: FormType
}

export default class AddAccTransaction extends React.Component<Props, State> {
  public state = {
    animate: true,
    form: {
      errors: {} as FormErrorType,
    } as FormType,
  }

  public componentDidMount() {
    const { editCreditDebitObj } = this.props
    if (Object.keys(editCreditDebitObj).length > 0) {
      this.setState({
        form: {
          ...editCreditDebitObj,
          amount: editCreditDebitObj.amount.toString(),
          errors: {} as FormErrorType,
        }
      })
    }
  }

  public render() {
    const { form, form: { errors }, animate } = this.state
    const transactionTypeOptions = [
      {
        key: AddAccTransactionStrings.transactionTypeOptions.credit.key,
        text: AddAccTransactionStrings.transactionTypeOptions.credit.label,
        value: AddAccTransactionStrings.transactionTypeOptions.credit.key
      },
      {
        key: AddAccTransactionStrings.transactionTypeOptions.debit.key,
        text: AddAccTransactionStrings.transactionTypeOptions.debit.label,
        value: AddAccTransactionStrings.transactionTypeOptions.debit.key
      }
    ]

    return (
      <Modal
        content={
          <SegmentGroup horizontal={true}>
            <ImageContainer>
              <Image src={AddAccTransactionStrings.src} size='small' />
            </ImageContainer>
            <DetailContainer>
              <StyledForm size={'small'}>
                <Form.Group widths='equal'>
                  <Form.Field
                    control={Input}
                    label={AddAccTransactionStrings.feeName}
                    name='feeName'
                    placeholder={AddAccTransactionStrings.feeName}
                    value={form.feeName}
                    onChange={this.handleChange}
                    error={!!errors.feeName} />
                  <Form.Field
                    control={Input}
                    label={AddAccTransactionStrings.amount}
                    name='amount'
                    error={!!errors.amount} >
                    <NumberFormat
                      icon='dollar'
                      iconPosition='left'
                      placeholder={AddAccTransactionStrings.amount}
                      onValueChange={this.handlePropertyPrice}
                      value={form.amount}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field
                  control={Select}
                  label={AddAccTransactionStrings.transactionType}
                  name='transactionType'
                  value={form.transactionType}
                  placeholder={AddAccTransactionStrings.transactionType}
                  options={transactionTypeOptions}
                  onChange={this.handleChange}
                  error={!!errors.transactionType} />
                <StyledButton onClick={this.addCreditDebit}>{AddAccTransactionStrings.save}</StyledButton>
              </StyledForm>
            </DetailContainer>
          </SegmentGroup>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal} width={700}
      />
    )
  }

  private handlePropertyPrice = (values: any) => {
    const { floatValue } = values
    const { form } = this.state
    form[`amount`] = floatValue
    this.setState({ form })
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private addCreditDebit = async () => {
    const { addCreditDebit } = this.props
    const { form } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    addCreditDebit(form)
    this.closeModal()
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose(false)
    }, 300)
  }
}
