import { Popup } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { scrollbars, styledToolTip } from 'design/Styled'

interface Props {
  vertical?: boolean
  total: number
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
  max-height: ${(props: Props) => props.total * 40}px;

  .fa-ellipsis-v-alt {
    width: 0.375em;
  }

  .rp-button-bubble {
    margin: 0.25em 0.5em;
  }

  .rp-button-icon {
    padding: 0.25em 0.5em;
    font-size: 120%;
    color: ${Colors.TextMedium};
    &:hover {
      color: ${Colors.Text};
    }
  }

  ${(props: Props) => {
    if (props.vertical) {
      return css`
        flex-direction: column;
        overflow-y: auto;
        ${scrollbars};
      `
    }
    return css`
      flex-direction: row;
    `
  }};
`

export const Action = styled.a`
  display: flex;
  padding: 0.25em 0.5em;
  color: ${Colors.TextMedium};
  transform: scale3d(0.9, 0.9, 0.9);
  transition: transform, color 200ms;
  cursor: pointer;

  .svg-inline--fa {
    height: 100%;
  }

  &:hover {
    color: ${Colors.Text};
    transform: scale3d(1, 1, 1);
  }
`

export const SubAction = styled(Action)`
  transform: none;
  align-items: center;
  justify-content: flex-start;
  span {
    display: flex;
    justify-content: center;
    width: 1em;
    margin-right: 0.5em;
  }
`

export const Options = styled(Popup)``

export const Info = styled(Popup)`
  ${styledToolTip};
`
