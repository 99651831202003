import styled from 'styled-components'

import { children, scrollbars, x, y } from 'design/Styled'

export const Container = styled.section`
  ${x};
  ${children};
  flex: 1;
  align-items: stretch;
  overflow: hidden;
`

export const Section = styled.section`
  ${y};
  ${scrollbars};
  overflow-y: auto;
  overflow-x: hidden;

  .rp-document-zone {
    padding: 3px;
  }
`
