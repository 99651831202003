import * as React from 'react'
import { InputProps } from 'semantic-ui-react'
import InputMask from 'react-input-mask'

import {
  StyledInput,
  InlineButton,
  Fa
} from './Styled'

import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEye, faEyeSlash])

interface Props extends InputProps {
  mask?: string
}

interface State {
  isVisible: boolean
}

class SensitiveInput extends React.Component<Props, State> {

  public state = {
    isVisible: false
  }

  public render() {
    const {
      className,
      error,
      mask='',
      name,
      onChange,
      placeholder,
      value,
      ...props
    } = this.props

    const {
      isVisible
    } = this.state

    return (
      <StyledInput
        className={`rp-sensitive-input ${className || ''}`}
        error={!!error}
        labelPosition='right'
        {...props}
      >
        <InputMask
          mask={mask}
          maskChar=''
          name={name}
          onChange={this.handleChange}
          placeholder={placeholder}
          type={isVisible ? 'text' : 'password'}
          value={value}
        />
        <InlineButton onClick={this.toggleVisibility}>
          <Fa icon={isVisible ? faEye : faEyeSlash} />
        </InlineButton>
      </StyledInput>
    )
  }

  private handleChange = (event:any) => {
    const { onChange, name } = this.props
    if (!onChange) {
      return
    }
    onChange(event, { name, value: event.target.value })
  }

  private toggleVisibility = () => {
    this.setState((prev:State) => ({
      isVisible: !prev.isVisible
    }))
  }
}

export default SensitiveInput
