import { debounce, escapeRegExp } from 'lodash'
import * as React from 'react'
import InputMask from 'react-input-mask'
import { RouteComponentProps, withRouter } from 'react-router-dom'

// Import Components
import AddressInput from 'shared/AddressInput'
import Modal from 'shared/Modal'
import StateDropdown from 'shared/StateDropdown'
import { formToast, serverToast } from 'shared/Toast/Toast'

// Import Store Types, Actions and Reducers
import { LeadItemsDetails } from 'store/Leads/Types'

import { reassignLead } from 'app/Leads/Dashboard/LeadMutations'
import { getReassignUsers } from 'app/Leads/Dashboard/LeadQueries'
import { getOrCreateCityState } from 'shared/AddressInput/Mutations'

import { handleDirectValidation, handlePoolValidation } from './Validation'

import TimelineEvent from './TimelineEvent'

import { Routes, Strings } from 'utils'

import {
  Col,
  Container,
  Header,
  Image,
  Label,
  LeftPanel,
  MiddlePanel,
  Optional,
  RightPanel,
  Row,
  StyledButton,
  StyledForm,
  StyledInput,
  StyledSearch,
  TimeLineContainer,
  Toggle,
  ToggleItem
} from './Styled'

import { AddressModeEnum, AddressType } from 'shared/AddressInput/Types'

import { FormErrorType, FormType, ReassignUserType, SearchOptionType } from './Types'

interface Props extends RouteComponentProps<{}> {
  closeModal: () => void
  leadData: LeadItemsDetails
}

interface State {
  filteredUserOptions: SearchOptionType[]
  form: FormType
  isLoading: boolean
  showLeadForm: boolean
  transition: boolean
  userOptions: SearchOptionType[]
}

class ReassignLeadModal extends React.Component<Props, State> {
  public state = {
    filteredUserOptions: [] as SearchOptionType[],
    form: { errors: {} as FormErrorType } as FormType,
    isLoading: false,
    showLeadForm: false,
    transition: true,
    userOptions: [] as SearchOptionType[]
  }

  public async componentDidMount() {
    const users = await getReassignUsers()
    const userOptions: SearchOptionType[] = users.map((user: ReassignUserType) => {
      return {
        _id: user._id,
        description: `${user.firstName} ${user.lastName}`,
        phone: (user.phones[0] && user.phones[0].value) || '',
        title: (user.emails[0] && user.emails[0].value) || ''
      }
    })
    this.setState({ userOptions })
  }

  public render() {
    const { leadData } = this.props
    const {
      filteredUserOptions,
      form,
      form: { errors },
      isLoading,
      showLeadForm,
      transition
    } = this.state

    return (
      <Modal
        content={
          <Container>
            <LeftPanel justify="center">
              <Image src={showLeadForm ? Strings.leads.src1 : Strings.leads.src} />
            </LeftPanel>
            <MiddlePanel>
              <Header>
                <ToggleItem isToggled={showLeadForm}>{Strings.leads.DIRECT}</ToggleItem>
                <Toggle onChange={this.toggleForm} toggle={true} />
                <ToggleItem isToggled={!showLeadForm}>{Strings.leads.LEADPOOL}</ToggleItem>
              </Header>
              <StyledForm primary={true} padding={1} onSubmit={this.handleSubmit}>
                {showLeadForm ? (
                  <Col primary={true}>
                    <Col>
                      <Label>City</Label>
                      <AddressInput
                        address={{
                          city: form.city,
                          state: form.state,
                          streetAddress: '',
                          streetName: '',
                          zipCode: form.zipCode
                        }}
                        mode={AddressModeEnum.City}
                        onChange={this.handleCityChange}
                        placeholder="City"
                      />
                    </Col>
                    <Col>
                      <Label>State</Label>
                      <StateDropdown
                        error={!!errors.state}
                        name="state"
                        onChange={this.handleInputChange}
                        placeholder="State"
                        value={form.state}
                      />
                    </Col>
                    <Col>
                      <Label>Zip Code</Label>
                      <StyledInput
                        error={!!errors.zipCode}
                        name="zipCode"
                        onChange={this.handleInputChange}
                        value={form.zipCode}
                      />
                    </Col>
                    <Col>
                      <Label>{Strings.leads.referralFeeRequested}</Label>
                      <StyledInput
                        error={!!errors.referralFee}
                        icon="percent"
                        iconPosition="right"
                        name="referralFee"
                        onChange={this.handleInputChange}
                        value={form.referralFee}
                      />
                    </Col>
                  </Col>
                ) : (
                  <Col primary={true}>
                    <Col>
                      <Label>{Strings.leads.emailId}</Label>
                      <StyledSearch
                        loading={isLoading}
                        name="email"
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
                        placeholder="Email ID"
                        results={filteredUserOptions}
                        value={form.email}
                        error={!!errors.email}
                      />
                    </Col>
                    <Row childWidths={50}>
                      <Col>
                        <Label>First Name</Label>
                        <StyledInput
                          error={!!errors.firstName}
                          name="firstName"
                          placeholder="First name"
                          value={form.firstName}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                      <Col>
                        <Label>Last Name</Label>
                        <StyledInput
                          error={!!errors.lastName}
                          name="lastName"
                          placeholder="Last name"
                          value={form.lastName}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Row childWidths={50}>
                      <Col>
                        <Label>
                          {Strings.leads.phone}
                          <Optional>{Strings.leads.optional}</Optional>
                        </Label>
                        <StyledInput error={!!errors.phone} name="phone" value={form.phone}>
                          <InputMask
                            mask="(999) 999-9999"
                            name="phone"
                            onChange={this.handlePhoneChange}
                            placeholder="(xxx) xxx-xxxx"
                            value={form.phone}
                          />
                        </StyledInput>
                      </Col>
                      <Col>
                        <Label>{Strings.leads.referralFeeRequested}</Label>
                        <StyledInput
                          error={!!errors.referralFee}
                          icon="percent"
                          name="referralFee"
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                <Row>
                  <StyledButton type="submit" content={'Submit'} />
                </Row>
              </StyledForm>
            </MiddlePanel>
            <RightPanel>
              <Header>{Strings.leads.history}</Header>
              {leadData &&
                leadData.reassignHistory &&
                leadData.reassignHistory.length > 0 && (
                  <TimeLineContainer className="notes-styling">
                    {leadData.reassignHistory.map((event: any, index: any) => (
                      <TimelineEvent key={index} event={event} />
                    ))}
                  </TimeLineContainer>
                )}
            </RightPanel>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        minWidth={900}
        width={980}
      />
    )
  }

  private handleResultSelect = (e: React.SyntheticEvent<EventTarget>, { result }: any) => {
    const { form } = this.state
    const res = result.description.split(' ')
    form.firstName = res[0]
    form.lastName = res[1]
    form.phone = result.phone
    form.userId = result._id
    form.email = result.title
    this.setState({ form })
  }

  private handleSearchChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { userOptions } = this.state

    this.setState({ isLoading: true })
    this.handleInputChange(e, { name, value })

    if (value.length < 1) {
      return this.resetSearch()
    }

    const re = new RegExp(escapeRegExp(value), 'i')
    const filteredUserOptions = userOptions.filter((item: SearchOptionType) => re.test(item.title))
    this.setState({
      filteredUserOptions,
      isLoading: false
    })
  }

  private resetSearch = () => {
    this.setState({
      filteredUserOptions: [] as SearchOptionType[],
      isLoading: false
    })
  }

  private handleCityChange = (address: AddressType) => {
    const { form } = this.state
    form.city = address.city
    form.state = address.state
    form.zipCode = address.zipCode
    this.setState({ form })
  }

  private handlePhoneChange = (e: any) => {
    this.handleInputChange(e, { name: 'phone', value: e.target.value })
  }

  private handleInputChange = async (e: any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private handleSubmit = async () => {
    const { leadData } = this.props
    const { form, showLeadForm } = this.state

    let result
    if (showLeadForm) {
      result = handlePoolValidation(form)
    } else {
      result = handleDirectValidation(form)
    }

    if (!result.formIsValid) {
      form.errors = result.errors
      formToast(result.errors)
      this.setState({ form })
      return
    }

    const { city, state } = form
    const location = await getOrCreateCityState({ city, state })
    if (!location) {
      return
    }

    const update: any = {
      ...leadData,
      ...form,
      city: location.city._id,
      state: location.state._id
    }

    try {
      await reassignLead(leadData._id, update)
      this.props.history.push({
        pathname: Routes.primary.leads.path
      })
      this.closeSelf()
    } catch (error) {
      serverToast(error)
    }
  }

  private toggleForm = () => {
    this.setState((prev: State) => ({ showLeadForm: !prev.showLeadForm }))
  }

  private closeSelf = () => {
    const { closeModal } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default withRouter(ReassignLeadModal)
