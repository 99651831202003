// Import Packages
import styled from 'styled-components'

// Import Images and Icons
import { gradientBG } from 'design/images/onboarding'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { SecondaryFontName } from 'design/Fonts'

const PassContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  position: relative;
`

const Header = styled.div`
  color: ${Colors.DarkBlue200};
  font-size: 48px;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
  font-family: ${SecondaryFontName};
`

const HeaderBold = styled.span`
  font-weight: 600;
`

const FlexWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 50px auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const Divider = styled.div`
  width: 2px;
  height: 480px;
  margin: 0 40px 0 20px;
  background-color: ${Colors.Black1000};
`

const Pass = styled.div`
  width: calc(50% - 20px);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const PassContent = styled.div`
  position: relative;
  background-image: url(${(props: { img: string }) => props.img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 480px;
  margin-bottom: 30px;
`

const PassTitle = styled.div`
  color: ${Colors.White1000};
  text-align: center;
  margin-top: 35px;
  font-size: 36px;
  font-weight: 300;
  font-family: ${SecondaryFontName};
  text-transform: uppercase;
`

const SubTitle = styled.div`
  margin: 35px auto 5px;
  text-align: center;
  font-size: 16px;
  color: ${Colors.Black700};
  font-weight: 300;
  max-width: 180px;
  text-transform: uppercase;
`

const ColorDivider = styled.div`
  width: 150px;
  margin: 4px auto 20px;
  height: 1px;
  background-color: ${(props: { color: string }) => props.color};
`

const Feature = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${SecondaryFontName};
  margin: 4px 0;
  color: ${Colors.Black500};
`

const ColoredTitle = styled.div`
  color: ${(props: { color: string }) => props.color};
  text-align: center;
  margin-top: 30px;
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: ${SecondaryFontName};
`

const PassTitleBold = styled.span`
  font-weight: 600;
`

const BGGradient = styled.div`
  width: 100%;
  height: 50vh;
  background-image: url(${gradientBG});
  background-size: cover;
  position: fixed;
  bottom: 0;
  left: 0;
`

const SkipBtn = styled.div`
  cursor: pointer;
  color: ${Colors.Black700};
  font-size: 17px;
  font-weight: 600;
  margin-right: 25px;
  display: inline-block;
  transition: all 0.2s;
  font-family: ${SecondaryFontName};
  position: absolute;
  right: 50px;
  bottom: 10px;
  transform: 1;
  opacity: 1;
  &:hover {
    transform: 0.6;
    opacity: 0.6;
  }
`

export {
  BGGradient,
  ColorDivider,
  ColoredTitle,
  Divider,
  Feature,
  FlexWrapper,
  Header,
  HeaderBold,
  Pass,
  PassContainer,
  PassContent,
  PassTitle,
  PassTitleBold,
  SkipBtn,
  SubTitle
}
