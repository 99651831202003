// Import Packages
import * as React from 'react'
import { Component, createRef } from 'react'
import { connect } from 'react-redux'

// Import Components
import Category from './Category'
import VerticalLines from './VerticalLines'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/ChecklistManager/Actions'

// Import Utils
import { generateID } from 'utils'

// Import Styled Components
import {
  AbsoluteGrid,
  AbsoluteGridColumn,
  AbsoluteGridRow,
  ChecklistGridContainer,
  ChecklistValueColumn,
  StyledContent,
  StyledGrid,
  StyledGridContainer,
  TitleColumn
} from './Styled'

// export interface DocumentItem {
//   id: string
//   name: string
//   description: string | null
//   isActive: boolean
// }

// interface CategoryType {
//   id: string
//   name: string
//   isActive: boolean
//   property: Array<{
//     name: string
//     value: object
//   }>
// }

interface ReduxProps {
  categories: any
  documents: any
}

interface DispatchProps {
  addCategory: (newCategory: any) => void
  addProperty: (categoryIndex: number) => void
  changeChecklistValue: (categoryIndex: number, documentId: string, newValue: boolean, propertyIndex: number) => void
  deleteCategory: (index: number) => void
  deleteProperty: (categoryIndex: number, propertyIndex: number) => void
  reinsertChecklistProperty: (categoryIndex: number, fromIndex: number, toIndex: number) => void
}

interface OwnProps {
  activeMls: string
  handleAddPropertyClick: (toggle: boolean, id?: string, index?: number, name?: string) => void
  handleEditPropertyClick: (
    toggle: boolean,
    categoryIndex?: number,
    propertyIndex?: number,
    name?: string,
    id?: string,
    categoryName?: string
  ) => void
  handleEditCategoryClick: (toggle: boolean, categoryIndex?: number, name?: string, id?: string) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

interface State {
  lineHeight: number
}

const addCategoryObject = {
  _id: generateID(),
  isLocked: true,
  name: '+ Add New Category',
  property: []
}

class GridContent extends Component<Props, State> {
  public state = {
    lineHeight: 0
  }

  private containerHeight: any = createRef()

  public setHeight = () => {
    const height = this.containerHeight.clientHeight
    this.setState({ lineHeight: height })
  }

  public componentDidMount() {
    this.setHeight()
  }

  public render() {
    const {
      activeMls,
      addCategory,
      addProperty,
      categories,
      changeChecklistValue,
      deleteCategory,
      deleteProperty,
      documents,
      handleAddPropertyClick,
      handleEditCategoryClick,
      handleEditPropertyClick,
      reinsertChecklistProperty
    } = this.props
    const { lineHeight } = this.state
    return (
      <StyledContent>
        <StyledGridContainer>
          <StyledGrid container={true}>
            <ChecklistGridContainer>
              <TitleColumn />
              <ChecklistValueColumn />
              <AbsoluteGrid>
                {/* tslint:disable-next-line jsx-no-lambda */}
                <AbsoluteGridRow columns={1} innerRef={(elem: HTMLDivElement) => (this.containerHeight = elem)}>
                  <AbsoluteGridColumn>
                    {categories.map((category: any, index: number) => {
                      return (
                        <Category
                          addProperty={addProperty}
                          categories={categories}
                          category={category}
                          changeChecklistValue={changeChecklistValue}
                          documents={documents}
                          handleAddClick={addCategory}
                          handleAddPropertyClick={handleAddPropertyClick}
                          handleEditClick={handleEditCategoryClick}
                          handleEditPropertyClick={handleEditPropertyClick}
                          handleRemoveClick={deleteCategory}
                          index={index}
                          key={category._id}
                          mls={activeMls}
                          onUpdateHeight={this.setHeight}
                          reinsertChecklistProperty={reinsertChecklistProperty}
                          removeProperty={deleteProperty}
                        />
                      )
                    })}
                    <Category
                      addProperty={addProperty}
                      categories={categories}
                      category={addCategoryObject}
                      changeChecklistValue={changeChecklistValue}
                      documents={documents}
                      handleAddClick={addCategory}
                      handleAddPropertyClick={handleAddPropertyClick}
                      handleEditClick={handleEditCategoryClick}
                      handleEditPropertyClick={handleEditPropertyClick}
                      handleRemoveClick={deleteCategory}
                      index={categories.length}
                      key={addCategoryObject._id}
                      mls={activeMls}
                      onUpdateHeight={this.setHeight}
                      reinsertChecklistProperty={reinsertChecklistProperty}
                      removeProperty={deleteProperty}
                    />
                  </AbsoluteGridColumn>
                </AbsoluteGridRow>
              </AbsoluteGrid>
              <VerticalLines documents={documents} height={lineHeight} />
            </ChecklistGridContainer>
          </StyledGrid>
        </StyledGridContainer>
      </StyledContent>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  categories: state.settings.checklistManager.categories,
  documents: state.settings.checklistManager.documents
})

export default connect(
  mapStateToProps,
  {
    addCategory: Actions.addCategory,
    addProperty: Actions.addProperty,
    changeChecklistValue: Actions.changeChecklistValue,
    deleteCategory: Actions.deleteCategory,
    deleteProperty: Actions.deleteProperty,
    reinsertChecklistProperty: Actions.reinsertChecklistProperty
  }
)(GridContent)
