// Import Packages
import styled from 'styled-components'

interface Props {
  background: string
}

const Container = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  margin-right: 10px;
  align-self: center;
  border-radius: 50%;
`

export { Container, ProfileImage }
