import styled from 'styled-components'

import Colors from 'design/Colors'
import { children, pinStripes, x, y } from 'design/Styled'

export const Container = styled.section`
  ${x};
  ${children};
  height: 100%;
  flex: 1;
  align-items: stretch;
  padding: 1em;
  background: ${Colors.EmphasisContainer};
  overflow: hidden;

  .loading {
    opacity: 0.1;
    transform: translate3d(0, 5px, 0);
  }

  .delay-1 {
    transition-delay: 200ms;
  }

  .delay-2 {
    transition-delay: 400ms;
  }

  .expander {
    position: absolute;
    top: 198px;
    right: -12px;
  }
`

export const Section = styled.section`
  ${y};
  transform: translate3d(0, 0, 0);
  transition: all 300ms;
  overflow: hidden;
`

export const Expander = styled.div`
  ${y};
  position: relative;
  width: auto;
  transition: all 300ms;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 12px -8px ${Colors.ContainerShadow};

  & > section {
    ${pinStripes};
    overflow: hidden;
    position: relative;
    width: 280px;
    height: 100%;

    .rp-transaction-sidebar {
      position: absolute;
      top: 0;
      left: 0;
      width: 280px;
      height: 100%;
      opacity: 1;
      transition: opacity 600ms;
    }
  }

  &.hidden {
    box-shadow: 0 0 8px -5px ${Colors.ContainerShadow};

    & > section {
      width: 12px;
      .rp-transaction-sidebar {
        opacity: 0;
      }
    }
    .expander {
      right: -6px;
      svg {
        transform: scale3d(-1, 1, 1);
      }
    }
  }
`

export const Subpage = styled.section`
  ${y};
  flex: 1;
  opacity: 1;
  padding-top: 0.8em;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 300ms;
`
