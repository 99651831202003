import gql from 'graphql-tag'

export const ADD_DOC_TEMPLATE = gql`
  mutation addDocTemplate($docChecklistId: String, $docTemplateId: String) {
    addDocTemplate(
      where: { _id: $docChecklistId }
      input: { docTemplates: $docTemplateId }
    ) {
      _id
      docTemplates {
        _id
        name
      }
    }
  }
`

export const REMOVE_DOC_TEMPLATE = gql`
  mutation removeDocTemplate($docChecklistId: String, $docTemplateId: String) {
    removeDocTemplate(
      where: { _id: $docChecklistId }
      input: { docTemplates: $docTemplateId }
    ) {
      _id
      docTemplates {
        _id
        name
      }
    }
  }
`

export const EDIT_DOC_CHECKLIST = gql`
  mutation editDocChecklist($id: String, $input: DocCheckListInput) {
    updateDocCheckList(where: { _id: $id }, input: $input) {
      _id
      checkListSubType
    }
  }
`

export const CREATE_DOC_CHECKLIST = gql`
  mutation createDocChecklist($input: DocCheckListInput) {
    createDocCheckList(input: $input) {
      _id
      checkListSubType
    }
  }
`

export const DELETE_DOC_CHECKLIST = gql`
  mutation deleteDocChecklist($docChecklistId: String) {
    deleteDocCheckList(_id: $docChecklistId)
  }
`

export const REORDER_DOCCHECKLIST = gql`
  mutation reorderingDocChecklist($docChecklist: [DocCheckListFilter]) {
    reorderingDocChecklist(input: $docChecklist) {
      _id
      order
    }
  }
`
