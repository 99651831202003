import * as React from 'react'
import { connect } from 'react-redux'
import { Accordion } from 'semantic-ui-react'
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/WorkflowManager/Actions'
import { WorkflowCategories, WorkflowCategoriesItemsDetails, WorkflowTask } from 'store/Settings/WorkflowManager/Types'
import ConfirmAlert from 'sweetalert2'
import { getLoggedInUser, Strings } from 'utils'
import AddWorkflowModal from './AddWorkflowModal'
import Contents from './Content'
import {
  Contanier,
  Content,
  Header,
  StyledAccordionTitle,
  Title,
  Wrapper
} from './Styled'

import { UserType } from 'store/Settings/WorkflowManager/Types'

// Font Awesome Icons
import {
  faMinus,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { createWorkflow, deleteWorkFlow, updateWorkflow } from '../../WorkflowManagerMutations'
import { getWorkflowCategory, getWorkflowCategoryTasks } from '../WorkflowManagerQueries'
AddIconToLibrary([faPlus, faMinus])

interface StoreProps {
  setNewWorkflow: (category: string, newData: WorkflowCategoriesItemsDetails) => void
  setDeleteWorkflow: (category: string, id: string) => void
  setCategoriesData: (data: WorkflowCategories[]) => void
  setTaskData: (data: WorkflowTask[]) => void
  getWorkflowId: (id: string, name: string) => void
  categoriesData: WorkflowCategories[]
}

interface OwnProps {
  getWorkflowId: (id: string, name: string) => void
}

type Props = StoreProps & OwnProps

interface State {
  showAddWorkflowModal: boolean
  activeIndex: number
  activeId: string
  loggedUser: UserType
  editCategoriesData: any
}

interface AddWorkflowData {
  name: string
  workflowCategory: string
  visibility: string
}

class LeftPanel extends React.Component<Props, State>{
  public state = {
    activeId: '',
    activeIndex: 0,
    editCategoriesData: {},
    loggedUser: {} as UserType,
    showAddWorkflowModal: false,
  }

  public async componentDidMount() {
    const { setCategoriesData, setTaskData, getWorkflowId } = this.props
    const user = await getLoggedInUser()
    const workflowCategories = await getWorkflowCategory()
    let workflowTask
    if (workflowCategories && workflowCategories[0].workflows && workflowCategories[0].workflows[0]) {
      workflowTask = await getWorkflowCategoryTasks(workflowCategories[0].workflows[0]._id)
      getWorkflowId(workflowCategories[0].workflows[0]._id, workflowCategories[0].workflows[0].name)
      setTaskData(workflowTask)
      this.setState({ activeId: workflowCategories[0].workflows[0]._id, loggedUser: user })
    }
    setCategoriesData(workflowCategories)
    this.setState({ loggedUser: user })
  }

  public render() {
    const { categoriesData, setTaskData, getWorkflowId } = this.props
    const { activeIndex, showAddWorkflowModal, loggedUser, editCategoriesData } = this.state
    const typesElements = categoriesData.map((items: any, index: any) => {
      return (
        <div key={index}>
          <StyledAccordionTitle
            active={activeIndex === index}
            index={index}
            id={(items.workflows && items.workflows[0]) ? items.workflows[0]._id : ''}
            onClick={this.handleClick}>
            <FontAwesomeIcon icon={activeIndex === index ? ['fas', 'minus'] : ['fas', 'plus']} />
            <div>{items.name}</div>
          </StyledAccordionTitle>
          <Accordion.Content active={activeIndex === index} style={{ paddingTop: '0px' }}>
            <Contanier>
              <Contents
                categories={items}
                category={items.name}
                deleteWorkflow={this.deleteWorkflow}
                alterData={this.alterData}
                editWorkflow={this.editWorkflow}
                getTaskData={setTaskData}
                loggedUser={loggedUser}
                getWorkflow={getWorkflowId} />
            </Contanier>
          </Accordion.Content>
        </div >
      )
    })
    return (
      <Wrapper>
        {
          showAddWorkflowModal &&
          <AddWorkflowModal
            closeModal={this.closeModal}
            editCategoriesData={editCategoriesData}
            editCategoryWorkflow={this.editCategoryWorkflow}
            addCategoryWorkflow={this.addWorkflow}
            loggedUser={loggedUser} />
        }
        <Header>
          <Title>{Strings.tasksSetting.workflowManager}</Title>
          <div onClick={this.showAddWorkflowModal}>{Strings.tasksSetting.ADDWORKFLOW}</div>
        </Header>
        <Content>
          <Accordion>
            {typesElements}
          </Accordion>
        </Content>
      </Wrapper>
    )
  }

  private editWorkflow = (categoryId: string, data: any) => {
    const newData = {
      ...data,
      categoryId
    }
    this.setState({ editCategoriesData: newData, showAddWorkflowModal: true })
  }

  private deleteWorkflow = (category: string, id: string) => {
    const { setDeleteWorkflow, setCategoriesData } = this.props
    ConfirmAlert({
      cancelButtonText: Strings.tasksSetting.noKeepIt,
      confirmButtonText: Strings.tasksSetting.yesDeleteIt,
      showCancelButton: true,
      text: Strings.tasksSetting.recoverWorkflow,
      title: Strings.tasksSetting.sure,
      type: 'warning',
    }).then(async (result) => {
      if (result.value) {
        setDeleteWorkflow(category, id)
        deleteWorkFlow(id)
        const workflowCategories = await getWorkflowCategory()
        setCategoriesData(workflowCategories)
        ConfirmAlert(
          Strings.tasksSetting.deleted,
          Strings.tasksSetting.deletedWorkflow,
          'success'
        )
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(
          Strings.tasksSetting.cancelled,
          Strings.tasksSetting.safeWorkflow,
          'error'
        )
      }
    })
  }

  private showAddWorkflowModal = () => {
    this.setState({ showAddWorkflowModal: true })
  }

  private addWorkflow = async (data: AddWorkflowData) => {
    const { setNewWorkflow } = this.props
    const res = await createWorkflow(data)
    setNewWorkflow(data.workflowCategory, res)
  }

  private editCategoryWorkflow = async (data: AddWorkflowData) => {
    const { setCategoriesData } = this.props
    await updateWorkflow(data)
    const workflowCategories = await getWorkflowCategory()
    setCategoriesData(workflowCategories)
  }

  private closeModal = () => {
    this.setState({ showAddWorkflowModal: false, editCategoriesData: {} })
  }

  private handleClick = async (e: any, titleProps: any) => {
    const { index, id } = titleProps
    const { activeIndex } = this.state
    const { setTaskData } = this.props
    const newIndex = activeIndex === index ? -1 : index
    const workflowTask = await getWorkflowCategoryTasks(id)
    setTaskData(workflowTask)
    this.setState({ activeIndex: newIndex, activeId: id })
  }

  private alterData = (data: any, id: string) => {
    const { categoriesData, setCategoriesData } = this.props
    const newData = categoriesData.map(dataItem => {
      if (dataItem[`_id`] === id) {
        return {
          ...dataItem,
          workflows: data
        }
      }
      return dataItem
    })
    setCategoriesData(newData)
  }
}

const mapStateToProps = (state: AppState) => ({
  categoriesData: state.settings.workflowManager.categoriesData
})

export default connect(
  mapStateToProps,
  {
    setCategoriesData: Actions.getCategoriesData,
    setDeleteWorkflow: Actions.deleteWorkflow,
    setNewWorkflow: Actions.addNewWorkflow,
    setTaskData: Actions.getTaskData,
  }
)(LeftPanel)
