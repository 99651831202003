// Import Packages
import { Tab } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Container = styled.div`
  display: flex;
`

const RightPanel = styled.div`
  padding: 10px;
  padding-top: 0;
`

const StyledTab = styled(Tab)`
  .ui.secondary.pointing.menu .active.item {
    font-size: 16px;
    color: ${Colors.Black500};
    border-bottom: 0px;
  }

  .ui.secondary.pointing.menu .active.item:hover {
    color: ${Colors.Black500};
  }

  .ui.secondary.pointing.menu .item {
    color: ${Colors.Black500};
  }

  .ui.secondary.pointing.menu {
    border-bottom: 1px solid rgba(34,36,38,.15);
  }

  .ui.secondary.pointing.menu {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
`

export {
  Container,
  RightPanel,
  StyledTab,
}
