// Import Packages
import moment from 'moment'
import * as React from 'react'
import Ref from 'semantic-ui-react/dist/commonjs/addons/Ref'

// Import Images and Icons
import IconAdd from 'design/icons/userProfile/accounts/icon_plus.png'

// Import Components
import StyledGrid from 'shared/StyledGrid'
import { UserCreditDebit, UserProfileType, UserType } from 'store/UserProfile/Types'
import FloatingAction from '../FloatingAction'
import { CustomActions, CustomAmount, CustomPaid, CustomTransactionId } from './CustomComponents'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import { getCreditDebit } from 'app/UserProfile/UserProfileQueries'
import { FloatingMenuContainer, StyledContainer } from './Styled'

const {
  action: ActionStrings,
  amount: AmountStrings,
  date: DateStrings,
  feeName: FeeNameStrings,
  paid: PaidStrings,
  transactionId: TransactionStrings
} = Strings.userProfile.detailSection.accounts.columns

interface DataItem {
  amount: number
  currency: string
  currencySymbol: string
  createdAt: moment.Moment
  feeName: string
  paid: boolean
  transactionId: string
  type: string
}

interface State {
  accountsData: DataItem[]
  containerHeight: number
  containerWidth: number
  user: UserType
}

interface Props {
  openAccTransaction: () => void
  deleteUserCreditDebit: (data: string) => void
  getUserCreditDebit: (data: UserCreditDebit[]) => void
  updateUserCreditDebit: (data: UserCreditDebit) => void
  creditDebit: UserCreditDebit[]
  userProfile: UserProfileType
}

export let loggedUser: any

export default class Accounts extends React.Component<Props, State> {
  public container: HTMLElement | any = null
  public state = {
    accountsData: [],
    containerHeight: 0,
    containerWidth: 0,
    user: {} as UserType
  }

  public async componentDidMount() {
    const { userProfile, getUserCreditDebit } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = userProfile
    const response = await getCreditDebit(userProfile._id)
    getUserCreditDebit(response)
    this.setState({ containerHeight: this.container.offsetHeight, containerWidth: this.container.offsetWidth, user })
  }

  public render() {
    const { containerHeight, user } = this.state
    const { openAccTransaction, creditDebit } = this.props
    const finalData = creditDebit.map((item: any) => {
      return {
        ...item,
        createdAt: moment(item.createdAt).format('MM/DD/YYYY')
      }
    })

    const columnMetaDataForAdmin = [
      {
        enhanceWithRowData: false,
        id: FeeNameStrings.id,
        title: FeeNameStrings.name
      },
      {
        customComponent: CustomAmount,
        enhanceWithRowData: true,
        id: AmountStrings.id,
        title: AmountStrings.name
      },
      {
        enhanceWithRowData: false,
        id: DateStrings.id,
        title: DateStrings.name
      },
      {
        customComponent: CustomTransactionId,
        enhanceWithRowData: true,
        id: TransactionStrings.id,
        title: TransactionStrings.name
      },
      {
        customComponent: CustomPaid,
        enhanceWithRowData: true,
        id: PaidStrings.id,
        title: PaidStrings.name
      },
      {
        customComponent: CustomActions,
        enhanceWithRowData: true,
        id: ActionStrings.id,
        sortable: false,
        title: ActionStrings.name
      }
    ]

    const columnMetaData = [
      {
        enhanceWithRowData: false,
        id: FeeNameStrings.id,
        title: FeeNameStrings.name
      },
      {
        customComponent: CustomAmount,
        enhanceWithRowData: true,
        id: AmountStrings.id,
        title: AmountStrings.name
      },
      {
        enhanceWithRowData: false,
        id: DateStrings.id,
        title: DateStrings.name
      },
      {
        customComponent: CustomTransactionId,
        enhanceWithRowData: true,
        id: TransactionStrings.id,
        title: TransactionStrings.name
      },
      {
        customComponent: CustomPaid,
        enhanceWithRowData: true,
        id: PaidStrings.id,
        title: PaidStrings.name
      }
    ]
    return (
      /* tslint:disable-next-line jsx-no-lambda */
      <Ref innerRef={(ref: HTMLElement) => (this.container = ref)}>
        <StyledContainer fluid={true}>
          {user.role === 'ADMIN' || user.role === 'MANAGER' ? (
            <React.Fragment>
              <FloatingMenuContainer>
                <FloatingAction mainButtonIcon={IconAdd} isMenu={false} mainButtonAction={openAccTransaction} />
              </FloatingMenuContainer>
              <div className="table-body">
                <StyledGrid data={finalData} columnMetaData={columnMetaDataForAdmin} tableHeight={containerHeight} />
              </div>
            </React.Fragment>
          ) : (
            <div className="table-body">
              <StyledGrid data={finalData} columnMetaData={columnMetaData} tableHeight={containerHeight} />3
            </div>
          )}
        </StyledContainer>
      </Ref>
    )
  }
}
