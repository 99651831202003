// Import Packages
import * as React from 'react'
import { Table } from 'semantic-ui-react'

// Import Images and Icons
import RealtyPass from 'design/icons/settings/logo.png'

// Import Components
import NotificationRoles from '../AllowRoles'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Description,
  Logo,
  StyledCheckbox,
  StyledTable,
  Title
} from './Styled'

// Font Awesome Icons
import {
  faEnvelope
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope])

interface Notification {
  category: string
  key: string
  title: string
  inApp: boolean
  email: boolean
  inAppSound: boolean
}

interface Props {
  updateInApp: (key: string) => void
  updateEmail: (key: string) => void
  updateInAppSound: (key: string) => void
  system: Notification[]
  userRole: string
}

interface State {
  systemNotification: Notification[]
}

class SystemNotification extends React.Component<Props, State> {
  public state = {
    systemNotification: [],
  }

  public componentDidMount() {
    const { system } = this.props
    this.setState({ systemNotification: system })
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({ systemNotification: nextProps.system })
  }

  public render() {
    const { userRole } = this.props
    const { systemNotification } = this.state
    const systemNotifications = systemNotification.map((items: any, index: number) => {
      return (
        <Table.Row key={index}>
          {
            NotificationRoles.System[items.key].includes(userRole) && items.key !== 'SystemWideMaintenance' && <>
              <Table.Cell style={{ whiteSpace: 'normal' }}>{items.title}</Table.Cell>
              <Table.Cell>
                <StyledCheckbox
                  className="test"
                  toggle={true}
                  checked={items.inApp}
                  onChange={this.switchRealtyPassHandleChange}
                  id={items.key + 'realty'}
                  notificationKey={items.key}
                />
              </Table.Cell>
              <Table.Cell>
                <StyledCheckbox
                  className="test"
                  toggle={true}
                  checked={items.email}
                  onChange={this.switchAsEmailHandleChange}
                  id={items.key + 'email'}
                  notificationKey={items.key}
                />
              </Table.Cell>
              <Table.Cell>
                <StyledCheckbox
                  className="test"
                  toggle={true}
                  checked={items.inAppSound}
                  onChange={this.switchAsSoundHandleChange}
                  id={items.key + 'sound'}
                  notificationKey={items.key}
                />
              </Table.Cell>
            </>
          }
        </Table.Row>
      )
    })
    return (
      <div>
        <Title>{Strings.settings.notification.systemNotification}</Title>
        <Description>{Strings.settings.notification.systemNotificationDescription}</Description>
        <StyledTable singleLine={true}>
          <Table.Header>
            <Table.Row style={{ width: '100%' }}>
              <Table.HeaderCell style={{ width: '70%' }}>{Strings.settings.notification.notification}</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '10%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Logo background={RealtyPass} />{Strings.settings.notification.realtyPass}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: '8%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon icon={['fal', 'envelope']} />{Strings.settings.notification.email}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: '10%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon icon={['fal', 'volume']} />{Strings.settings.notification.sound}
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {systemNotifications}
          </Table.Body>
        </StyledTable>
      </div>
    )
  }

  private switchRealtyPassHandleChange = (e: React.SyntheticEvent<EventTarget>, { id, notificationKey }: any) => {
    const { updateInApp } = this.props
    const { systemNotification } = this.state
    const newData = systemNotification.map((dataItem: any) => {
      return {
        ...dataItem,
        inApp: dataItem.key + 'realty' === id ? !dataItem.inApp : dataItem.inApp,
      }
    })
    this.setState({ systemNotification: newData })
    updateInApp(notificationKey)
  }

  private switchAsEmailHandleChange = (e: React.SyntheticEvent<EventTarget>, { id, notificationKey }: any) => {
    const { updateEmail } = this.props
    const { systemNotification } = this.state
    const newData = systemNotification.map((dataItem: any) => {
      return {
        ...dataItem,
        email: dataItem.key + 'email' === id ? !dataItem.email : dataItem.email,
      }
    })
    this.setState({ systemNotification: newData })
    updateEmail(notificationKey)
  }

  private switchAsSoundHandleChange = (e: React.SyntheticEvent<EventTarget>, { id, notificationKey }: any) => {
    const { updateInAppSound } = this.props
    const { systemNotification } = this.state
    const newData = systemNotification.map((dataItem: any) => {
      return {
        ...dataItem,
        inAppSound: dataItem.key + 'sound' === id ? !dataItem.inAppSound : dataItem.inAppSound,
      }
    })
    this.setState({ systemNotification: newData })
    updateInAppSound(notificationKey)
  }
}

export default SystemNotification
