const Actions = {
  createLeadWorkflowTask: 'CREATE_LEAD_WORKFLOW_TASK',
  deleteLeadWorkflowTask: 'DELETE_LEAD_WORKFLOW_TASK',
  deleteMultipleLeadWorkflowTask: 'DELETE_MULTIPLE_LEAD_WORKFLOW_TASK',
  getLeadWorkflowTask: 'GET_LEAD_WORKFLOW_TASK',
  leadActivities: 'GET_LEAD_ACTIVITIES',
  leadAddNewBoard: 'ADD_LEAD_NEW_BOARD',
  leadAddNewCard: 'ADD_LEAD_NEW_CARD',
  leadAddNewNotes: 'ADD_LEAD_NEW_NOTES',
  leadAddWorkflow: 'ADD_LEAD_WORKFLOW',
  leadDeleteCard: 'DELETE_LEAD_CARD',
  leadDeleteNotes: 'DELETE_LEAD_NOTES',
  leadEditNotes: 'EDIT_LEAD_NOTES',
  leadFilter: 'LEAD_FILTER',
  leadGetBoardData: 'GET_LEAD_BOARD_DATA',
  leadGetListData: 'GET_LEAD_LIST_DATA',
  leadGetNotesData: 'GET_LEAD_NOTES_DATA',
  leadGetWorkflow: 'GET_LEAD_WORKFLOW',
  leadPool: 'LEAD_POOL',
  leadResetCardData: 'RESET_LEAD_CARD_DATA',
  leadRestoreCard: 'RESTORE_LEAD_CARD',
  leadSearchFilter: 'LEAD_SEARCH_FILTER',
  leadSearchLoader: 'LEAD_SEARCH_LOADER',
  leadSetNewBoardName: 'SET_LEAD_NEW_BOARD_NAME',
  leadSetNewDescription: 'SET_LEAD_NEW_DESCRIPTION',
  leadSetNewIcon: 'SET_LEAD_NEW_ICON',
  leadShowNoteModal: 'SHOW _LEAD_NOTE_MODAL',
  leadarchive: 'ARCHIVE_LEAD',
  setContactTransactionPanel: 'SET_CONTACT_TRANSACTION_PANEL',
  updateLeadWorkflowTask: 'UPDATE_LEAD_WORKFLOW_TASK',
  updateMutlipleLeadWorkflowTask: 'UPDATE_MULTIPLE_LEAD_WORKFLOW_TASK',
  userPassLeadFilter: 'USER_PASS_LEAD_FILTER'
}

export default Actions
