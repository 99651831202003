const Actions = {
  workflowAddNewLabel: 'ADD_WORKFLOW_MANAGER_NEW_LABEL',
  workflowAddNewTask: 'ADD_WORKFLOW_MANAGER_NEW_TASK',
  workflowAddNewWorkflow: 'ADD_WORKFLOW_MANAGER_NEW_WORKFLOW',
  workflowDeleteTask: 'DELETE_WORKFLOW_MANAGER_TASK',
  workflowDeleteWorkflow: 'DELETE_WORKFLOW_MANAGER_WORKFLOW',
  workflowGetCategoriesData: 'GET_WORKFLOW_MANAGER_CATEGORIES_DATA',
  workflowGetLabelManageData: 'GET_WORKFLOW_MANAGER_LABEL_MANAGE_DATA',
  workflowGetTaskData: 'GET_WORKFLOW_MANAGER_TASK_DATA',
  workflowUpdateTask: 'UPDATE_WORKFLOW_MANAGER_TASK'
}

export default Actions
