import { escapeRegExp } from 'lodash'

import { FormType, FormErrorType, SearchOptionType, PaymentMethodEnum } from './Types'
import { PaymentNumericEnum } from 'store/Transactions/Types'

export const handleValidationForExternal = (form: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true
  if (!form.type) {
    formIsValid = false
    errors.type = 'Please select a referral type.'
  }

  if (!form.feeObj.type) {
    formIsValid = false
    errors.currency = 'Please select an amount type.'
  }

  if (!form.feeObj.fee || Number(form.feeObj.fee) < 0 || (form.feeObj.type === PaymentNumericEnum.Percent && (Number(form.feeObj.fee) > 100))) {
    formIsValid = false
    errors.amount = 'Please enter a valid fee amount.'
  }

  if (!form.brokerageName || !form.brokerageName.trim()) {
    formIsValid = false
    errors.brokerageName = 'Please enter a brokerage name.'
  }

  if (!form.brokerageTaxID) {
    formIsValid = false
    errors.brokerageTaxID = 'Please enter a brokerage tax ID.'
  }

  if (!form.phone) {
    formIsValid = false
    errors.phone = 'Please enter a phone number.'
  }

  if (!form.address.streetName || !form.address.streetName.trim()) {
    formIsValid = false
    errors.street = 'Please enter a street address.'
  } else if (!form.address.streetNumber || !form.address.streetNumber.trim()) {
    formIsValid = false
    errors.street = 'Street address should begin with a street number.'
  }

  if (!form.address.state) {
    formIsValid = false
    errors.state = 'Please select a state.'
  }

  if (!form.address.city) {
    formIsValid = false
    errors.city = 'Please enter a city.'
  }

  if (!form.address.zipCode || !form.address.zipCode.match(/^[0-9]+$/)) {
    formIsValid = false
    errors.zipCode = 'Please enter a zip code.'
  }

  if (!form.name || !form.name.trim()) {
    formIsValid = false
    errors.user = 'Please add a referral name.'
  }

  return { errors, formIsValid }
}

export const handleValidationForEscrow = (form: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!form.type) {
    formIsValid = false
    errors.type = 'Please select a referral type.'
  }

  if (!form.feeObj.type) {
    formIsValid = false
    errors.currency = 'Please select an amount type.'
  }

  if (!form.feeObj.fee || Number(form.feeObj.fee) < 0 || (form.feeObj.type === PaymentNumericEnum.Percent && (Number(form.feeObj.fee) > 100))) {
    formIsValid = false
    errors.amount = 'Please enter a valid fee amount.'
  }

  if (!form.brokerageName || !form.brokerageName.trim()) {
    formIsValid = false
    errors.brokerageName = 'Please include the agent.'
  }

  if (!form.brokerageTaxID) {
    formIsValid = false
    errors.brokerageTaxID = `Please enter the agent's tax ID.`
  }

  if (form.paymentMethod === PaymentMethodEnum.Check) {
    if (!form.address.streetName || !form.address.streetName.trim()) {
      formIsValid = false
      errors.street = 'Please enter a mailing address.'
    } else if (!form.address.streetNumber || !form.address.streetNumber.trim()) {
      formIsValid = false
      errors.street = 'Street address should begin with a street number.'
    }

    if (!form.address.state) {
      formIsValid = false
      errors.state = 'Please select a state.'
    }

    if (!form.address.city) {
      formIsValid = false
      errors.city = 'Please enter a city.'
    }

    if (!form.address.zipCode || !form.address.zipCode.match(/^[0-9]+$/)) {
      formIsValid = false
      errors.zipCode = 'Please enter a zip code.'
    }
  } else if (form.paymentMethod === PaymentMethodEnum.Ach) {
    if (!form.accountNumber) {
      formIsValid = false
      errors.accountNumber = 'Please include a bank account number.'
    }

    if (!form.routingNumber || form.routingNumber.toString().length !== 9) {
      formIsValid = false
      errors.routingNumber = 'Please include a 9-digit bank routing number.'
    }

    if (!form.bankName) {
      formIsValid = false
      errors.bankName = 'Please include a bank name.'
    }
  }

  return { errors, formIsValid }
}

export const handleValidationForInternal = (form: FormType, users: SearchOptionType[]) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!form.type) {
    formIsValid = false
    errors.type = 'Please select a referral type.'
  }

  if (!form.feeObj.type) {
    formIsValid = false
    errors.currency = 'Please select an amount type.'
  }

  if (!form.feeObj.fee || Number(form.feeObj.fee) < 0 || (form.feeObj.type === PaymentNumericEnum.Percent && (Number(form.feeObj.fee) > 100))) {
    formIsValid = false
    errors.amount = 'Please enter a valid fee amount.'
  }

  if (!form.office) {
    formIsValid = false
    errors.office = 'Please select an office.'
  }

  if (!form.user || !form.name.trim()) {
    formIsValid = false
    errors.user = 'Please choose a referral member.'
  } else {
    const inputName = new RegExp(escapeRegExp(form.name), 'i')
    const user:any = users.find((item:SearchOptionType) => {
      return item._id === form.user && inputName.test(item.title)
    })
    if (!user) {
      formIsValid = false
      errors.user = 'Please choose a referral member.'
    }
  }

  return { errors, formIsValid }
}

export const handleValidationForThirdParty = (form: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!form.type) {
    formIsValid = false
    errors.type = 'Please select a referral type.'
  }

  if (!form.feeObj.type) {
    formIsValid = false
    errors.currency = 'Please select an amount type.'
  }

  if (!form.feeObj.fee || (form.feeObj.type === PaymentNumericEnum.Percent && (Math.abs(Number(form.feeObj.fee)) > 100))) {
    formIsValid = false
    errors.amount = 'Please enter a valid fee amount.'
  }

  if (!form.description || !form.description.trim()) {
    formIsValid = false
    errors.description = 'Please add a description.'
  }

  if (!form.name || !form.name.trim()) {
    formIsValid = false
    errors.user = 'Please add a referral name.'
  }

  return { errors, formIsValid }
}
