const notificationRoles = {
  Goal: {
    ProductionGoalsAchieved: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ]
  },
  Lead: {
    LeadAwardedAssigned: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ]
  },
  System: {
    CalendarEvent: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'TEAM_LEADER', 'AGENT'],
    InvoiceBilling: ['ADMIN'],
    PublicNotification: ['ADMIN'],
    LicenceExpiration: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ],
    ProfileIncomplete: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ],
    SystemWideMaintenance: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ]
  },
  Team: {
    PendingTeamAcceptance: ['TEAM_LEADER'],
    TeamMemberAcceptance: ['TEAM_LEADER']
  },
  Transaction: {
    CAPAmountModified: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ],
    PendingTransactionTaskDueToday: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ],
    TransactionNotesOnOff: ['ADMIN', 'MANAGER'],
    TransactionOutgoingIncoming: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ],
    TransactionStatus: [
      'ADMIN',
      'SUPER_ADMIN',
      'MANAGER',
      'TEAM_LEADER',
      'AGENT'
    ]
  }
}

export default notificationRoles
