import { cloneDeep } from 'lodash'
import { Reducer } from 'redux'
import Actions from 'store/Users/ActionsTypes'
import {
  addNewBoard,
  addNewCard,
  deleteCard,
  restoreCard,
  setNewBoardName,
  setNewDescription,
  setNewIcon
} from './ReduxUtil'
import { Users, UsersItem } from './Types'

const initialState: Users = {
  boardId: '',
  cardIndex: '',
  currentlyDeleteCard: null,
  data: [],
  filter: {
    filter: false,
    searchText: false
  },
  searchData: {},
  searchLoader: false,
  showAddUserPopup: false,
  showImportUsersPopup: true
}

const ContactReducer: Reducer<Users> = (
  state: Users = initialState,
  action
) => {
  switch (action.type) {
    case Actions.addNewBoard:
      return {
        ...state,
        data: addNewBoard(state, action.payload.index, action.payload.newData)
      }
    case Actions.getBoardData:
      return {
        ...state,
        data: cloneDeep(action.payload.data)
      }
    case Actions.addNewCard:
      return {
        ...state,
        data: addNewCard(
          state,
          action.payload.id,
          action.payload.index,
          action.payload.newData
        )
      }
    case Actions.showAddUserPopup:
      return {
        ...state,
        showAddUserPopup: true
      }
    case Actions.showImportUsersPopup:
      return {
        ...state,
        showImportUsersPopup: true
      }
    case Actions.hideAddUserPopup:
      return {
        ...state,
        showAddUserPopup: false
      }
    case Actions.hideImportUsersPopup:
      return {
        ...state,
        showImportUsersPopup: false
      }
    case Actions.setNewIcon:
      return {
        ...state,
        data: setNewIcon(state, action.payload.id, action.payload.icon)
      }
    case Actions.setNewDescription:
      return {
        ...state,
        data: setNewDescription(
          state,
          action.payload.id,
          action.payload.description
        )
      }
    case Actions.setNewBoardName:
      return {
        ...state,
        data: setNewBoardName(
          state,
          action.payload.id,
          action.payload.boardName
        )
      }
    case Actions.deleteCard:
      const { data } = state
      const feeData = data.slice()
      let index: string
      let temp: UsersItem
      const callback = () => {
        return {
          ...state,
          boardId: action.payload.boardId,
          cardIndex: index,
          currentlyDeleteCard: temp,
          data: deleteCard(state, action.payload.boardId, action.payload.cardId)
        }
      }
      feeData.forEach((list: any) => {
        if (list.id === action.payload.boardId) {
          const newList = list.items.slice()
          const listIndex = newList.findIndex((laneItem: any) => {
            return laneItem.id === action.payload.cardId
          })
          index = listIndex
          temp = newList[listIndex]
          callback()
        }
      })
      return callback()
    case Actions.restoreCard:
      return {
        ...state,
        data: restoreCard(
          state,
          state.boardId,
          state.cardIndex,
          state.currentlyDeleteCard
        )
      }
    case Actions.resetCardData:
      return {
        ...state,
        boardId: '',
        cardIndex: '',
        currentlyDeleteCard: null
      }
    case Actions.userFilter:
      return {
        ...state,
        filter: action.payload.data
      }
    case Actions.userSearchLoader:
      return {
        ...state,
        searchLoader: action.payload.open
      }
    case Actions.userSearchData:
      return {
        ...state,
        searchData: action.payload.data
      }
    default:
      return state
  }
}

export default ContactReducer
