// Import Packages
import { debounce } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { Form, Icon, Input, Menu, Select } from 'semantic-ui-react'

import { FiltersData, FilterText, Options } from 'store/Tasks/Types'
import DatePicker from 'shared/DatePicker'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  CloseWrapper,
  Container,
  Filter,
  Filters,
  FiltersTitle,
  PopUpContent,
  StyledContainer,
  StyledForm,
  StyledMenu
} from './Styled'

const filters = [
  { id: 'Task Name', name: 'Task Name', text: 'name' },
  { id: 'Due Date', name: 'Due Date', text: 'dueDate' },
  { id: 'Type', name: 'Type', text: 'type' }
]

const taskOptions = [
  { key: 'Email', text: 'Email', value: 'Email' },
  { key: 'Call', text: 'Call', value: 'Call' },
  { key: 'Meeting', text: 'Meeting', value: 'Meeting' },
  { key: 'Sms', text: 'Sms', value: 'Sms' },
  { key: 'Pass', text: 'Pass', value: 'Pass' }
]

interface Props {
  filtersContent: (id: string, name: string, text: string, searchData: FiltersData) => void
  closeAdvanceSearchModal: () => void
  filter: (data: object, state: Options, city: Options) => void
  data: FiltersData
  filterText: FilterText[]
  selectedCity: Options
  selectedState: Options
}

interface State {
  activeItem: string
  searchData: FiltersData
  cities: Options[]
  states: Options[]
  selectedCity: Options
  selectedState: Options
  openDueDate: boolean
}

class TaskSearchModal extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    activeItem: '',
    cities: [],
    openDueDate: false,
    searchData: {
      dueDate: undefined,
      name: undefined,
      type: undefined
    },
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: []
  }

  public async componentDidMount() {
    const { data, selectedState, selectedCity } = this.props
    this.setState({ searchData: data, selectedState, selectedCity })
  }

  public componentWillReceiveProps(nextProps: any) {
    const { filterText } = this.props
    const lastElement = nextProps.filterText.slice(-1)[0]
    if (lastElement !== undefined) {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: lastElement.content })
      }
    } else {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: '' })
      }
    }
  }

  public render() {
    const { activeItem, searchData, openDueDate } = this.state
    return (
      <StyledContainer>
        <CloseWrapper onClick={this.closeAdvanceSearchModal} />
        <PopUpContent>
          <Container>
            <Filters>
              <FiltersTitle>{Strings.advanceSearchModal.filters}</FiltersTitle>
              <StyledMenu vertical={true}>
                {filters.map((items: any, index: number) => (
                  <Menu.Item
                    key={index}
                    name={items.name}
                    id={items.id}
                    text={items.text}
                    active={activeItem === items.name}
                    onClick={this.handleItemClick}
                  />
                ))}
              </StyledMenu>
            </Filters>
            <StyledForm size={'tiny'}>
              {activeItem === '' && (
                <Filter>
                  <Icon name="filter" />
                  <div>Select Filter</div>
                </Filter>
              )}
              {activeItem === 'Task Name' && (
                <Form.Field
                  control={Input}
                  label="Task Name"
                  name="name"
                  value={searchData.name}
                  placeholder="Task Name"
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Due Date' && (
                <Form.Field
                  control={Input}
                  label="Due Date"
                  name="dueDate"
                  value={searchData.dueDate}
                  onClick={this.handleOpen}
                  autoComplete="off"
                  action={{
                    icon: (
                      <DatePicker
                        onChangeDate={this.onDateRange}
                        open={openDueDate}
                        handleOpen={this.handleOpen}
                        handleClose={this.handleClose}
                      />
                    ),
                    onClick: this.handleOpen
                  }}
                  placeholder="Due Date"
                />
              )}
              {activeItem === 'Type' && (
                <Form.Field
                  control={Select}
                  label="Type"
                  name="type"
                  placeholder="Type"
                  value={searchData.type}
                  options={taskOptions}
                  onChange={this.handleChange}
                />
              )}
            </StyledForm>
          </Container>
        </PopUpContent>
      </StyledContainer>
    )
  }

  private handleOpen = () => {
    this.setState({ openDueDate: true })
  }

  private handleClose = () => {
    this.setState({ openDueDate: false })
  }

  private closeAdvanceSearchModal = () => {
    const { closeAdvanceSearchModal } = this.props
    closeAdvanceSearchModal()
  }

  private handleItemClick = (e: React.SyntheticEvent<EventTarget>, { id, name, text }: any) => {
    const { filtersContent } = this.props
    const { searchData } = this.state
    this.setState({ activeItem: name })
    filtersContent(id, name, text, searchData)
  }

  private getTasksBoardsData = async () => {
    const { filter } = this.props
    const { searchData, selectedCity, selectedState } = this.state

    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(() => {
      filter(searchData, selectedState, selectedCity)
    }, 1000)

    this.debounceJob()
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { searchData } = this.state
    searchData[name] = value
    this.setState({ searchData }, () => this.getTasksBoardsData())
  }

  private onDateRange = (date: any) => {
    this.setState(
      {
        searchData: {
          ...this.state.searchData,
          dueDate: moment(date).format('L')
        }
      },
      () => this.getTasksBoardsData()
    )
    this.handleClose()
  }
}

export default TaskSearchModal
