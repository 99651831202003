// Import Packages
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import CallIocn from 'design/icons/Events/icon_call.png'
import ClockIocn from 'design/icons/Events/icon_clock.png'

// Import Colors
import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background: string
}

const PopupWrapper = styled(Popup)`
  height: auto;
  width: 30%;

  &.ui.popup {
    font-size: 12px;
    padding: 0px;
    max-width: 350px;
    color: ${Colors.Black500};
  }

  &.ui.bottom.right.popup:before {
    top: -0.507143em;
    right: 16px;
    width: 16px;
    background: ${Colors.DarkBlue200};
  }

  &.ui.popup:before {
    height: 1.314286em;
  }

  &.ui.top.left.popup:before {
    bottom: -0.607143em;
    left: 1.4em;
  }

  &.ui.right.center.popup:before {
    left: -0.707143em;
  }

  &.ui.left.center.popup:before {
    right: -0.707143em;
  }

  &.ui.popup:before {
    width: 1.414286em;
  }

  &.ui.top.right.popup:before {
    bottom: -0.607143em;
  }

  &.ui.bottom.left.popup:before {
    top: -0.507143em;
    background: ${Colors.DarkBlue200};
  }
`

const EventTitle = styled.div`
  position: relative;
  padding: 0px 20px 50px 20px;
  color:${Colors.White1000};
  background: ${Colors.DarkBlue200};
  font-weight: 600;

  .ui.small.image {
    width: 30px;
    position: absolute;
  }
`

const EventDescription = styled.div`
  padding: 20px 20px 0px 20px;
  text-align: justify;
  color: ${Colors.Black500};
  padding-top: 0px;
  text-transform: capitalize;
`

const EventProperty = styled.div`
  padding: 20px 20px 0px 20px;
  text-align: justify;
  color: ${Colors.Black500};
`

const Description = styled.div`
  padding: 0px 20px 0px 20px;
  text-align: justify;
  color: ${Colors.Black500};
  text-transform: capitalize;
`

const TimeIcon = styled.div`
  padding: 20px;
  text-align: justify;
  display: flex;
  color: ${Colors.Black550};

  span {
    margin-left: 25px;
  }
`

const Clock = styled.div`
  background: url(${ClockIocn});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
`

const ClockTitle = styled.div`
  margin-left: -12px;
  display: grid;

  span:last-child {
    font-size: 10px;
  }
`

const PhoneIcon = styled.div`
  display: flex;
  padding: 0px 0px 0px 20px;
  text-align: justify;
  color: ${Colors.Black550};
`

const Phone = styled.div`
  background: url(${CallIocn});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  align-self: center;
`

const PhoneTitle = styled.div`
  margin-left: 10px;
`

const EventShared = styled.div`
  padding: 20px 0px 0px 20px;
  text-align: justify;
  font-weight: bold;
  color: ${Colors.DarkBlue200};

  img {
    width: 30px;
    border-radius: 50%;
    margin-top: 10px;
  }
`

const SharedWithUsers = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;

  .images {
    margin-right: 6px;
  }
`

const EventTag = styled.div`
  padding: 10px 0px 30px 20px;
  text-align: justify;
  font-weight: bold;
  color: ${Colors.DarkBlue200};

  div {
    margin-bottom: 8px;
  }

  a.ui.label {
    position: relative;
    background-color: ${Colors.White490};
    font-size: 10px;
    min-width: 38px;
    width: auto;
  }

  .fa-times {
    position: absolute;
    top: -4px;
    right: 2px;
    height: 10px;
    width: 8px;
    color: ${Colors.Black530};
  }
`

const EventEdit = styled.div`
  position: absolute;
  bottom: -15px;
  right: 55px;
  padding: 10px;
  background: ${Colors.DarkBlue200};
  border-radius: 100%;
  display: flex;
  font-size: 16px;
  -webkit-box-shadow: 0px -1px 5px 0px ${Colors.Black50};
  -moz-box-shadow: 0px -1px 5px 0px ${Colors.Black50};
  box-shadow: 0px 0px 11px 1px ${Colors.Black50};
  cursor: pointer;

  .fa-pencil-alt {
    margin: 0 auto;
  }
`

const EventDelete = styled.div`
  position: absolute;
  bottom: -15px;
  right: 10px;
  padding: 10px;
  background: ${Colors.DarkBlue200};
  border-radius: 100%;
  display: flex;
  font-size: 16px;
  -webkit-box-shadow: 0px -1px 5px 0px ${Colors.Black50};
  -moz-box-shadow: 0px -1px 5px 0px ${Colors.Black50};
  box-shadow: 0px 0px 11px 1px ${Colors.Black50};
  cursor: pointer;

  .fa-trash-alt {
    margin: 0 auto;
  }
`

const Close = styled.div`
  text-align: -webkit-right;
  background: ${Colors.DarkBlue200};
  font-size: 18px;
  color: ${Colors.White400};
  cursor: pointer;

  .fa-times {
    margin-top: 5px;
    margin-right: 10px;
  }
`

const ClearFloat = styled.div`
  clear: both;
`
const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

const StyledLabel = styled.div`
  ${y};
  height: 100%;
  color: ${Colors.DarkBlue220};
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Labels = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
`

const Address = styled.div`
  padding: 0px 20px 0px 20px;
  font-size: 12px;
  text-transform: 'capitalize';
`

export {
  Address,
  ClearFloat,
  Clock,
  ClockTitle,
  Close,
  EventDescription,
  MemberProfile,
  EventDelete,
  EventEdit,
  EventShared,
  EventTag,
  EventTitle,
  Phone,
  PhoneIcon,
  PhoneTitle,
  PopupWrapper,
  EventProperty,
  TimeIcon,
  StyledPopup,
  StyledLabel,
  SharedWithUsers,
  Description,
  Labels,
}
