// Import Packages
import * as React from 'react'
import Dropzone from 'react-dropzone'

// Import Images and Icons
import IconUpload from 'design/icons/users/upload_file.png'

// Import Components
import Modal from 'shared/Modal'
import { importUsers } from '../../UserMutation'
import { importUserSuccessfully } from '../../Users'

// Import Utils
import Strings from 'utils/Strings'

// Import Styled Components
import {
  FileDropSection,
  ImageContainer,
  ImageWrapper,
  ModalWrapper,
  SampleFileSection,
  SegmentGroup,
  StyledButton,
  StyledDivider,
  StyledProgress,
  UploadSection
} from './Styled'

const ImportUsersStrings = Strings.users.importUsers

interface Props {
  handleClose: () => void
  boardId: string
  officeID: string
}

interface State {
  fileName: File
  percent: number,
  dropzoneProgress: boolean
  manualUploadProgress: boolean
  animate: boolean
}

let setStateInterval = 0
export default class ImportUsersPopup extends React.Component<Props, State> {
  public textInput: any = React.createRef()

  public state = {
    animate: true,
    dropzoneProgress: false,
    fileName: {} as File,
    manualUploadProgress: false,
    percent: 0,
  }

  public dropzoneStartCount = (file: any) => {
    let percent = 0
    this.setState({ dropzoneProgress: true, fileName: file })
    setStateInterval = window.setInterval(async () => {
      percent += (Math.random() * 70)
      percent = (percent >= 100) ? 100 : percent
      await this.importUsers()
      if (percent === 100) {
        window.clearInterval(setStateInterval)
        this.closeModal()
        await importUserSuccessfully()
        this.setState({ fileName: file, dropzoneProgress: false })
      }
      this.setState({
        percent
      })
    }, 2000)
  }

  public manualUploadStartCount = (file: File) => {
    let percent = 0
    this.setState({ manualUploadProgress: true, fileName: file })
    setStateInterval = window.setInterval(async () => {
      percent += (Math.random() * 25)
      percent = (percent >= 100) ? 100 : percent
      if (percent === 100) {
        await this.importUsers()
        window.clearInterval(setStateInterval)
        importUserSuccessfully()
        this.closeModal()
        this.setState({ fileName: file, manualUploadProgress: false })
      }
      this.setState({
        percent
      })
    }, 500)
  }

  public componentWillUnmount() {
    window.clearInterval(setStateInterval)
  }

  public render() {
    const { dropzoneProgress, manualUploadProgress, percent, animate } = this.state
    let fileName = ''
    if (this.state.fileName && this.state.fileName[0]) {
      fileName = this.state.fileName[0].name
    }
    return (
      <Modal
        content={
          <ModalWrapper>
            <div className="header">{ImportUsersStrings.title}</div>
            <SegmentGroup horizontal={true}>
              <FileDropSection>
                <div className="dropSectionText">{ImportUsersStrings.dragAndDrop}</div>
                <Dropzone className="test" onDrop={acceptedFiles => this.dropzoneStartCount(acceptedFiles)}>
                  <ImageContainer className="largeContainer">
                    {
                      dropzoneProgress ?
                        <div className='progress-container' style={{ textAlign: 'center' }}>
                          <StyledProgress percent={percent} indicating={true} />
                        </div> :
                        <ImageWrapper backgroundImage={IconUpload} className="large" />
                    }
                  </ImageContainer>
                </Dropzone>
              </FileDropSection>
              <UploadSection>
                <ImageContainer>
                  <ImageWrapper backgroundImage={IconUpload} />
                </ImageContainer>
                <StyledDivider horizontal={true}>{ImportUsersStrings.selectFile}</StyledDivider>
                {Object.keys(fileName).length > 0 &&
                  <span style={{ textAlign: 'center' }}>{fileName}</span>
                }
                <input
                  type="file"
                  ref={(node: any) => { this.textInput = node }}
                  id="hidden-new-file"
                  style={{ display: 'none' }}
                  name='fileName'
                  onChange={(e: any) => this.manualUploadStartCount(e.target.files)}
                />
                {
                  manualUploadProgress ?
                    <div className='progress-container' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <StyledProgress percent={percent} indicating={true} />
                    </div> :
                    <StyledButton onClick={this.handleFile}>{ImportUsersStrings.uploadFile}</StyledButton>
                }
              </UploadSection>
            </SegmentGroup>
            <SampleFileSection>
              <div className="textContainer">
                <div className="downloadText">{ImportUsersStrings.downloadSample}</div>
              </div>
              <StyledButton onClick={this.downloadSampleFile}>{ImportUsersStrings.download}</StyledButton>
            </SampleFileSection>
          </ModalWrapper>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal} width={800}
      />
    )
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose()
    }, 300)
  }

  private handleFile = () => {
    this.textInput.click()
  }

  private downloadSampleFile = () => {
    window.location.href = 'https://s3-us-west-2.amazonaws.com/onechimp/sample/csv/sample_user.csv'
  }

  private importUsers = async () => {
    const { boardId, officeID } = this.props
    const { fileName } = this.state
    await importUsers(boardId, fileName[0], officeID)
  }
}
