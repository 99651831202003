import gql from 'graphql-tag'

export const UPLOAD_PROFILE = gql`
  mutation uploadProfilePic($userId: String, $file: Upload) {
    uploadProfilePic(_id: $userId, file: $file)
  }
`

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile(
    $_id: ID
    $accountNumber: String
    $bankName: String
    $routingNumber: String
    $mlsId: Int
    $licenseNo: Int
    $licensedExpiration: Date
    $roasterName: String
    $bio: String
    $addresses: AddressInput
    $notificationSetting: [UserNotificationSettingInput]
    $social: SocialInput
    $languages: [String]
    $website: String
    $taxReportName: String
    $ein: String
    $taxID: String
    $paymentMethod: String
    $salesGoal: Float
    $contractDocument: Upload
    $firstName: String
    $lastName: String
    $userName: String
    $phones: PhoneInput
    $teamCommissionSplit: Float
    $teamFee: Int
    $transactionFeeSetting: String
    $joiningDate: Date
  ) {
    updateUser(
      where: { _id: $_id, isActive: true }
      input: {
        accountNumber: $accountNumber
        bankName: $bankName
        routingNumber: $routingNumber 
        mlsId: $mlsId
        licenseNo: $licenseNo
        licensedExpiration: $licensedExpiration
        roasterName: $roasterName
        bio: $bio
        addresses: $addresses
        social: $social
        languages: $languages
        website: $website
        taxReportName: $taxReportName
        ein: $ein
        taxID: $taxID
        paymentMethod: $paymentMethod
        salesGoal: $salesGoal
        firstName: $firstName
        lastName: $lastName
        userName: $userName
        phones: $phones
        teamCommissionSplit: $teamCommissionSplit
        teamFee: $teamFee
        transactionFeeSetting: $transactionFeeSetting
        notificationSetting: $notificationSetting
        joiningDate: $joiningDate
      }
      file: $contractDocument
    ) {
      _id
      firstName
      lastName
      userName
      ein
      paymentMethod
      taxReportName
      salesGoal
      contractDocument
      taxID
      phones {
        type
        value
      }
      hasTeam
      office {
        _id
        branchName
      }
      isPrimaryManager
      dob
      role
      sex
      profileImage
      status
      capSetting {
        _id
        name
      }
      userOrder
      group {
        _id
        name
      }
      emails {
        type
        value
      }
      notificationSetting {
        category
        key
        title
        inApp
        email
        inAppSound
      }
      teamFee
      teamCommissionEarn
      teamCommissionSplit
      userCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
        residentialCapStatus
        commercialCapStatus
      }
      transactionFeeSetting {
        _id
        name
      }
      createdAt
      joiningDate
      bio
      languages
      mlsId
      licenseNo
      licensedExpiration
      contractDocument
      website
      taxReportName
      ein
      taxID
      paymentMethod
      addresses {
        type
        streetNumber
        streetName
        city
        state
        country
        zipCode
      }
      lastLogin
      roasterName
      teams {
        _id
        name
        leader {
          _id
          firstName
          lastName
          userName
          phones {
            type
            value
          }
          profileImage
          teamFee
          teamCommissionEarn
          teamCommissionSplit
        }
        inviteMember {
          firstName
          lastName
          email
          fee
          commission
          status
          inviteDate
          teamAgreement
        }
        members {
          _id
          profileImage
          firstName
          lastName
          userName
          teamFee
          teamCommissionEarn
          teamCommissionSplit
        }
        teamCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
        }
        teamOrder
        isActive
      }
      social {
        type
        url
      }
    }
  }
`

export const UPDATE_USER_SIGNATURE_DATA = gql`
  mutation updateUserSignatureData(
    $_id: ID
    $address: String
    $colorLink: String
    $colorText: String
    $companyPosition: String
    $fontFamily: String
    $fontSize: Int
    $name: String
    $nameTitle: String
    $phone: String
    $profileImage: String
    $templateId: Int
    $urlFacebook: String
    $urlLinkedin: String
    $urlTwitter: String
    $urlWebsite: String
    $company: String
  ) {
    updateUserSignature: updateUser(
      where: { _id: $_id, isActive: true }
      input: {
        signature: {
          address: $address
          colorLink: $colorLink
          colorText: $colorText
          companyPosition: $companyPosition
          fontFamily: $fontFamily
          fontSize: $fontSize
          name: $name
          nameTitle: $nameTitle
          phone: $phone
          profileImage: $profileImage
          templateId: $templateId
          urlFacebook: $urlFacebook
          urlLinkedin: $urlLinkedin
          urlTwitter: $urlTwitter
          urlWebsite: $urlWebsite
          company: $company
        }
      }
    ) {
      _id
    }
  }
`

export const ADD_USER_CREDIT_DEBIT = gql`
  mutation addUserCreditDebit(
    $feeName: String!
    $amount: Float!
    $transactionType: String!
    $userId: String
  ) {
    createUserCreditDebit(
      input: {
        feeName: $feeName
        amount: $amount
        transactionType: $transactionType
        user: $userId
      }
    ) {
      _id
      feeName
      amount
      transactionType
      status
      createdAt
      user {
        _id
        firstName
        lastName
      }
      transactionId
    }
  }
`

export const UPDATE_USER_CREDIT_DEBIT = gql`
  mutation updateUserCreditDebit(
    $_id: String
    $feeName: String
    $amount: Float
    $transactionType: String
  ) {
    updateUserCreditDebit(
      where: { _id: $_id }
      input: {
        feeName: $feeName
        amount: $amount
        transactionType: $transactionType
      }
    ) {
      _id
      feeName
      amount
      transactionType
      status
      createdAt
      user {
        _id
        firstName
        lastName
      }
      transactionId
    }
  }
`

export const DELETE_USER_CREDIT_DEBIT = gql`
  mutation deleteUserCreditDebit($accountId: String!) {
    deleteUserCreditDebit(_id: $accountId)
  }
`

export const SETUP_NEW_WEBSITE = gql`
  mutation setupNewWebsite {
    setupNewWebsite
  }
`

export const UPLOAD_MY_DOCUMENT = gql`
  mutation uploadMyDocument($id: ID, $path: String, $files: [Upload!]) {
    uploadMyDocument(_id: $id, files: $files, path: $path)
  }
`

export const CREATE_FOLDER = gql`
  mutation createFolder($id: ID, $path: String, $folderName: String!) {
    createFolder(_id: $id, folderName: $folderName, path: $path)
  }
`

export const DELETE_LINK = gql`
  mutation deleteLinks($ids: [ID!]) {
    deleteLinks(_ids: $ids)
  }
`

export const CREATE_LINK = gql`
  mutation createLink($name: String, $url: String, $userId: ID) {
    createLink(input: { name: $name, url: $url, user: $userId }) {
      _id
      name
      url
      user {
        _id
      }
      tenant {
        _id
      }
    }
  }
`

export const UPDATE_LINK = gql`
  mutation updateLink($_id: ID!, $name: String, $url: String) {
    updateLink(_id: $_id, input: { name: $name, url: $url }) {
      _id
      name
      url
      user {
        _id
      }
      tenant {
        _id
      }
    }
  }
`

export const DELETE_FILE_OR_FOLDER = gql`
  mutation deleteFileFolder($key: String) {
    deleteFileFolder(key: $key)
  }
`

export const DOWNLOAD_FILE_OR_FOLDER = gql`
  mutation downloadFileFolder($key: String) {
    downloadFileFolder(key: $key)
  }
`

export const UPDATE_FILE_FOLDER = gql`
  mutation renameFileFolder($oldKey: String, $newKey: String) {
    renameFileFolder(oldKey: $oldKey, newKey: $newKey)
  }
`
