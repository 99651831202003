import styled from 'styled-components'

import { scrollbars, x } from 'design/Styled'

export const Container = styled.div`
  ${x};
  overflow: auto;
  margin-right: 1em;
  ${scrollbars};

  .sort-start > div:last-child {
    visibility: hidden;
  }

  .sort-end > div:last-child {
    visibility: visible;
  }
`
