import React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

import List from 'shared/List'
import Toast, { serverToast } from 'shared/Toast/Toast'
import TemplateSelector from '../TemplateSelector'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Pass/Actions'
import { getWebsitePassData } from '../Queries'
import { updateUserPass } from '../Mutations'

import { Row, Col, Link } from './Styled'
import { Bubble, ToolTip, Copy } from '../Styled'

import {
  PaginatedUserPassType,
  PassStatusEnum,
  PassTypeEnum,
  UserPassDictType,
  UserPassType,
} from 'store/Pass/Types'
import { User } from 'store/Users/Types'
import {
  ActionEnum,
  ID,
} from '../Types'

interface StoreProps {
  setUserPass: (pass: Partial<UserPassType.Base>) => void
  passes: UserPassDictType
}

interface OwnProps {
  onAction: (action: ActionEnum, data: any) => void
  user: User.LoggedInType
}

type Props = StoreProps & OwnProps

interface State {
  ids: ID[]
}

class AgentMode extends React.Component<Props, State> {
  public state = {
    ids: [] as ID[]
  }

  public componentDidMount = async () => {
    const { onAction, user } = this.props
    onAction(ActionEnum.Loading, true)

    const sorting = { field: 'settings.isPending', order: -1 }
    const skip = 0
    const limit = 100

    const passes: PaginatedUserPassType = await getWebsitePassData({
      passType: PassTypeEnum.WebsiteSolution,
      isPlaceholder: false,
      user: user._id
    }, sorting, skip, limit)

    const ids = passes.results.map((item: UserPassType.Base) => item._id)
    this.setState({ ids })
    onAction(ActionEnum.Loading, false)
  }

  public render() {
    const { passes, user } = this.props
    const { ids } = this.state
    return (
      <Col>
        {ids.map((id: ID, index: number) => {
          const { settings }:UserPassType.Base = passes[id] || {}
          const url = `${settings.officeSubdomain}.idxpass.com`
          const isPending = settings.status.includes('Pending')
          const status = isPending ? settings.status.includes('Deactivation') ? 'Pending Deactivation' : 'Pending Activation' : settings.status

          return (
            <List.Grouped
              key={id}
              defaultIsExpanded={index === 0}
              header={(
                <Row>
                  <ToolTip
                    content={settings.mls.fullName}
                    position='top left'
                    trigger={<Col width={8}>{settings.mls.shortName}</Col>}
                  />
                  <Row width={-1}>{settings.office.branchName}</Row>
                  <ToolTip
                    content='Website URL (Click to Copy)'
                    position='top center'
                    trigger={(
                      <Col primary={1}>
                        <Copy
                          justify='center'
                          text={url}
                          onCopy={() => this.handleCopy(url)}
                        >
                          <span>{url}</span>
                        </Copy>
                      </Col>
                    )}
                  />
                  {!isPending && (
                    <Row align='flex-end' width={-1}>
                      {settings.status === PassStatusEnum.Active && (
                        <Link onClick={() => this.toggleActivation(id)}>Request deactivation?</Link>
                      )}
                      {settings.status === PassStatusEnum.Inactive && (
                        <Link onClick={() => this.toggleActivation(id)}>Request activation?</Link>
                      )}
                    </Row>
                  )}
                  <ToolTip
                    content={`Current status: ${status}`}
                    position='top center'
                    trigger={<Row width={-1}><Bubble status={settings.status} /></Row>}
                  />
                </Row>
              )}
              items={(
                <TemplateSelector
                  user={user}
                  pass={passes[id]}
                  onSelect={(template: string) => this.handleTemplateSelection(id, template)}
                />
              )}
              hasItems={true}
              requireExpanderClick={true}
            />
          )
        })}
      </Col>
    )
  }

  private handleTemplateSelection = (_id: ID, template: string) => {
    const update = { settings: { template }}
    this.updatePass(_id, update)
  }

  private handleCopy = (url:string) => {
    Toast({
      message: `Copied ${url} to clipboard`,
      type: 'success'
    })
  }

  private toggleActivation = async (_id: ID) => {
    const { passes } = this.props
    const { settings }:UserPassType.Base = passes[_id] || {}

    if (settings.status.includes('Pending')) {
      return
    }

    let errorType = 'error'
    try {
      const { office, status } = settings
      const isActive = status === PassStatusEnum.Active
      const statusAction = isActive ? 'Deactivate' : 'Activate'

      const title = `${statusAction} ${office.branchName}?`
      const text = [
        `This action will send a request to your Administrator to ${statusAction.toLowerCase()} your Website for ${office.branchName}.`,
        (settings.adminFee && !isActive) ? `Your admin charges a monthly fee of $${Number(settings.adminFee).toFixed(2).toLocaleString()} for this service.` : ``,
        `Do you wish to continue?`,
      ].filter(Boolean).join(' ')

      const confirm = await ConfirmAlert({
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
        showCancelButton: true,
        text,
        title,
        type: 'warning'
      })

      if (confirm.dismiss) {
        errorType = 'warning'
        throw new Error(`Website for ${office.branchName} remains ${settings.status}`)
      } else {
        const status = isActive ? PassStatusEnum.PendingDeactivation : PassStatusEnum.PendingActivation
        const input = { settings: { status, isPending: !isActive } }
        this.updatePass(_id, input)
      }
    } catch (e) {
      Toast({
        message: e.message,
        type: errorType
      })
    }
  }

  private updatePass = async (_id: ID, update:Partial<UserPassType.Base>) => {
    const {
      passes,
      setUserPass,
      onAction,
    } = this.props
    onAction(ActionEnum.Loading, true)

    let isSuccess = true
    setUserPass({ _id, ...update })
    try {
      await updateUserPass(_id, update)
    } catch (error) {
      isSuccess = false
      serverToast(error)
      setUserPass(passes[_id])
    }

    onAction(ActionEnum.Loading, false)
    return isSuccess
  }
}

const mapStateToProps = (state: AppState) => ({
  passes: state.userPass.dict
})

export default connect(
  mapStateToProps,
  {
    setUserPass: Actions.setUserPass
  }
)(AgentMode)
