// Import Packages
import * as React from 'react'

// Import Components
import { ProfileType } from '../Types'
import BrandingLogo from './BrandingLogo'
import Localization from './Localization'
import Social from './Social'

// Import Styling
import { Container } from './Styled'

interface Props {
  profile: ProfileType
}

class Contact extends React.Component<Props, {}> {
  public state = {}

  public render() {
    const { profile } = this.props
    return (
      <Container>
        <BrandingLogo profile={profile} />
        <Localization profile={profile} />
        <Social profile={profile} />
      </Container>
    )
  }

}

export default Contact
