import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import {
  children,
  x,
} from 'design/Styled'

import { fonts } from 'design/Fonts'

export const Container = styled.div`
  ${x};
  ${children};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.8em;
  background: ${Colors.White800};
  border-bottom: 1px solid ${Colors.EmphasisLine};
  cursor: pointer;

  &:hover {
    background: ${Colors.Container};
  }
`

export const Title = styled.div`
  flex: 1;
  font: ${fonts.xSmall.regular};
  color: ${Colors.Text};
  word-break: break-word;

  .fa-info-circle {
    margin-left: 3px;
  }
`

export const Image = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 0 0 5px;
  transition: transform 200ms, opacity 100ms;
  cursor: pointer;
`

export const Actions = styled.div`
  ${x};
  position: relative;
  width: 25px;
  z-index: 9;

  &:hover {
    .hover {
      opacity: 1;
      z-index: 999;
      img {
        transform: translate3d(0,0,0);
      }
    }
    .active {
      opacity: 0;
      z-index: -999;
    }
  }
`

export const Hover = styled.div`
  ${x};
  width: auto;
  position: absolute;
  right: 0;
  opacity: 0;
  z-index: -999;

  img {
    transform: translate3d(5px,0,0);
  }

  img:last-child {
    transform: translate3d(0,0,0);
  }
`



export const HoverComponentContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  right: 7px;
`

export const HoverIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  background: ${Colors.White800};
`



export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  img:first-child {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
  ${HoverComponentContainer} {
    display: none !important;
  }
  &:hover {
    ${HoverComponentContainer} {
      display: flex !important;
    }
  }
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    font-size: 0.8rem;
    width: 225px;
    color: ${Colors.Black500};
  }
`
