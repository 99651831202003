import { ActionCreator } from 'redux'
import Actions from 'store/Leads/ActionsTypes'
import {
  CreateLeadWorkflowTask,
  DeleteLeadWorkflowTask,
  DeleteMultipleLeadWorkflowTasks,
  Filter,
  GetLeadWorkflowTask,
  LeadActivities,
  LeadAddNewBoard,
  LeadAddNewCard,
  LeadAddNewNotes,
  LeadAddWorkflow,
  LeadArchivedLead,
  LeadDeleteCard,
  LeadDeleteNotes,
  LeadDetails,
  LeadEditNotes,
  LeadFilter,
  LeadGetBoardData,
  LeadGetListData,
  LeadGetNotesData,
  LeadGetWorkflow,
  LeadItemsDetails,
  LeadPassFilter,
  LeadPool,
  LeadResetCardData,
  LeadRestoreCard,
  LeadSearchData,
  LeadSerachLoader,
  LeadSetNewBoardName,
  LeadSetNewDescription,
  LeadSetNewIcon,
  LeadShowNoteModal,
  SetContactTransactionPanel,
  UpdateLeadWorkflowTask,
  UpdateMultipleLeadWorkflowTasks,
  UserPassLeadFilter
} from './Types'

export const addNewBoard: ActionCreator<LeadAddNewBoard> = (
  index: number,
  newData: LeadDetails
) => ({
  payload: {
    index,
    newData
  },
  type: Actions.leadAddNewBoard
})

export const getListData: ActionCreator<LeadGetListData> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.leadGetListData
})

export const getBoardData: ActionCreator<LeadGetBoardData> = (
  data: LeadDetails[]
) => ({
  payload: {
    data
  },
  type: Actions.leadGetBoardData
})

export const addNewCard: ActionCreator<LeadAddNewCard> = (
  id: string,
  index: string,
  newData: LeadItemsDetails
) => ({
  payload: {
    id,
    index,
    newData
  },
  type: Actions.leadAddNewCard
})

export const setNewIcon: ActionCreator<LeadSetNewIcon> = (
  id: string,
  icon: string
) => ({
  payload: {
    icon,
    id
  },
  type: Actions.leadSetNewIcon
})

export const setNewDescription: ActionCreator<LeadSetNewDescription> = (
  id: string,
  description: string
) => ({
  payload: {
    description,
    id
  },
  type: Actions.leadSetNewDescription
})

export const setNewBoardName: ActionCreator<LeadSetNewBoardName> = (
  id: string,
  boardName: string
) => ({
  payload: {
    boardName,
    id
  },
  type: Actions.leadSetNewBoardName
})

export const deleteCard: ActionCreator<LeadDeleteCard> = (
  boardId: string,
  cardId: string
) => ({
  payload: {
    boardId,
    cardId
  },
  type: Actions.leadDeleteCard
})

export const restoreCard: ActionCreator<LeadRestoreCard> = () => ({
  type: Actions.leadRestoreCard
})

export const resetCardData: ActionCreator<LeadResetCardData> = () => ({
  type: Actions.leadResetCardData
})

export const addNewNotes: ActionCreator<LeadAddNewNotes> = (newData: any) => ({
  payload: {
    newData
  },
  type: Actions.leadAddNewNotes
})

export const getNotesData: ActionCreator<LeadGetNotesData> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.leadGetNotesData
})

export const editNotes: ActionCreator<LeadEditNotes> = (newData: any) => ({
  payload: {
    newData
  },
  type: Actions.leadEditNotes
})

export const deleteNotes: ActionCreator<LeadDeleteNotes> = (id: string) => ({
  payload: {
    id
  },
  type: Actions.leadDeleteNotes
})

export const showNoteModal: ActionCreator<LeadShowNoteModal> = (
  data: boolean
) => ({
  payload: {
    data
  },
  type: Actions.leadShowNoteModal
})

export const filter: ActionCreator<LeadFilter> = (data: Filter) => ({
  payload: {
    data
  },
  type: Actions.leadFilter
})

export const searchLoader: ActionCreator<LeadSerachLoader> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.leadSearchLoader
})

export const archivedLeads: ActionCreator<LeadArchivedLead> = (
  data: object
) => ({
  payload: {
    data
  },
  type: Actions.leadarchive
})

export const leadPoolStatus: ActionCreator<LeadPool> = (open: boolean) => ({
  payload: {
    open
  },
  type: Actions.leadPool
})

export const searchDataFilter: ActionCreator<LeadSearchData> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.leadSearchFilter
})

export const addWorkflow: ActionCreator<LeadAddWorkflow> = (newData: any) => ({
  payload: {
    newData
  },
  type: Actions.leadAddWorkflow
})

export const getWorkflow: ActionCreator<LeadGetWorkflow> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.leadGetWorkflow
})

export const getLeadWorkflowTask: ActionCreator<GetLeadWorkflowTask> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.getLeadWorkflowTask
})

export const createLeadWorkflowTask: ActionCreator<CreateLeadWorkflowTask> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.createLeadWorkflowTask
})

export const deleteLeadWorkflowTask: ActionCreator<DeleteLeadWorkflowTask> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.deleteLeadWorkflowTask
})

export const updateLeadWorkflowTask: ActionCreator<UpdateLeadWorkflowTask> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.updateLeadWorkflowTask
})

export const deleteMultipleLeadWorkflowTask: ActionCreator<
  DeleteMultipleLeadWorkflowTasks
> = (ids: any) => ({
  payload: {
    ids
  },
  type: Actions.deleteMultipleLeadWorkflowTask
})

export const updateMultipleLeadWorkflowTask: ActionCreator<
  UpdateMultipleLeadWorkflowTasks
> = (ids: any, status: string) => ({
  payload: {
    ids,
    status
  },
  type: Actions.updateMutlipleLeadWorkflowTask
})

export const setContactTransactionPanel: ActionCreator<
  SetContactTransactionPanel
> = (data: number) => ({
  payload: {
    data
  },
  type: Actions.setContactTransactionPanel
})

export const getActivities: ActionCreator<LeadActivities> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.leadActivities
})

export const showUserPassLeadFilterData: ActionCreator<UserPassLeadFilter> = (
  filters: LeadPassFilter
) => ({
  payload: {
    filters
  },
  type: Actions.userPassLeadFilter
})
