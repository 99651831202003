// Import Packages
import moment from 'moment'
import * as React from 'react'
import DatePickers from 'react-datepicker'
import { Button, Dropdown, Form, Image, Input, Label, TextArea } from 'semantic-ui-react'

import 'react-datepicker/dist/react-datepicker.css'

// Import Components
import DatePicker from 'shared/DatePicker'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import Toast from 'shared/Toast'
import { handleEventValidation } from './Validation'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'
import { FormErrorType, FormType } from './Types'

import { EventDetails, Options, UserType } from 'store/Events/Types'

// Import Styled Components
import {
  AddForm,
  AddLeftPanel,
  Container,
  Header,
  SharedWithTitle,
  StyledForm,
  StyledLabel,
  Toggle,
  ToggleItem
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faHandshake, faPhone, faSms, faTicket } from '@fortawesome/pro-regular-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { getUsers } from '../EventQueries'
AddIconToLibrary([faEnvelope, faPhone, faSms, faHandshake, faTicket])

export interface Props {
  addEvent: (event: FormType) => void
  closeModal: () => void
  selectedData: EventDetails | any
  edit?: boolean
  editEventObj?: EventDetails | any
  calendarSwitch?: boolean
}

interface State {
  form: FormType
  userMultiOptions: Options[]
  sharedWith: any
  animate: boolean
  openStartDatePicker: boolean
  openEndDatePicker: boolean
  startTime: Date
  endTime: Date | null
  type: boolean
  user: UserType
  loading: string
}

class AddEventModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    endTime: null,
    form: {
      errors: {} as FormErrorType
    } as FormType,
    loading: '',
    openEndDatePicker: false,
    openStartDatePicker: false,
    sharedWith: [],
    startTime: new Date(),
    type: false,
    user: {} as UserType,
    userMultiOptions: []
  }

  public async componentDidMount() {
    const { edit, editEventObj, selectedData, calendarSwitch } = this.props
    this.setState({ loading: edit ? 'Gathering data...' : '' })
    const usersOpt: any = []
    const loggedUser: any = await getLoggedInUser({ fromCache: true })
    const users = await getUsers()
    users.forEach((user: any) => {
      const multiObj: any = {
        content: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Image src={user.profileImage} avatar={true} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {user.firstName} {user.lastName}
              </div>
              <div>{user.emails && user.emails[0] ? user.emails[0].value : ''}</div>
            </div>
          </div>
        ),
        key: user._id,
        text: (
          <Label
            content={user.firstName}
            onRemove={(e: any, lp: any) => {
              e.stopPropagation()
              this.removeCard(lp.value)
            }}
          />
        ),
        value: user._id
      }
      usersOpt.push(multiObj)
    })
    if (!edit) {
      this.setState({ sharedWith: [] })
    }
    this.setState({ userMultiOptions: usersOpt, user: loggedUser })

    if (selectedData && calendarSwitch) {
      this.setState({
        endTime: selectedData.end,
        form: {
          ...editEventObj,
          endDate: selectedData.end,
          endTime: moment(selectedData.end).format('h:mm A'),
          errors: {} as FormErrorType,
          startDate: selectedData.start,
          startTime: moment(selectedData.start).format('h:mm A')
        },
        startTime: selectedData.start
      })
    } else {
      this.setState({
        form: {
          ...editEventObj,
          endDate: selectedData.end,
          endTime: moment(new Date())
            .add(1, 'hour')
            .format('LT'),
          errors: {} as FormErrorType,
          startDate: selectedData.start,
          startTime: moment(new Date()).format('LT')
        },
        startTime: new Date()
      })
    }

    if (edit) {
      const editSharedWith: any = []
      editEventObj.sharedWith.forEach((obj: any) => {
        editSharedWith.push(obj._id)
      })

      this.setState({
        endTime: editEventObj.end,
        form: {
          ...editEventObj,
          endDate: editEventObj.end,
          endTime: moment(editEventObj.end).format('LT'),
          errors: {} as FormErrorType,
          sharedWith: editSharedWith,
          startDate: editEventObj.start,
          startTime: moment(editEventObj.start).format('LT')
        },
        loading: '',
        sharedWith: editSharedWith,
        startTime: editEventObj.start
      })
      if (editEventObj.type === 'Public') {
        this.setState({ type: true })
      } else {
        this.setState({ type: false })
      }
    }
  }

  public render() {
    const {
      form,
      form: { errors },
      loading,
      userMultiOptions,
      sharedWith,
      type,
      animate,
      openEndDatePicker,
      openStartDatePicker,
      endTime,
      startTime
    } = this.state
    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loading} />}
            <AddLeftPanel>
              <Image src={Strings.eventsPopup.src} size="small" />
            </AddLeftPanel>
            <AddForm>
              <StyledForm size={'mini'}>
                <Form.Field
                  error={!!errors.name}
                  control={Input}
                  label="Event Name"
                  name="name"
                  value={form.name}
                  placeholder="Event Name"
                  onChange={this.handleChange}
                />
                <Form.Field
                  error={!!errors.description}
                  control={TextArea}
                  label="Event Description"
                  name="description"
                  value={form.description}
                  placeholder="Description"
                  onChange={this.handleChange}
                />
                <Form.Group widths="equal">
                  <Form.Field
                    error={!!errors.startDate}
                    control={Input}
                    className="duedate"
                    name="startDate"
                    value={form.startDate ? moment(form.startDate).format('L') : ''}
                    autoComplete="off"
                    label="Start Date"
                    onClick={this.handleStartDateOpen}
                    action={{
                      icon: (
                        <DatePicker
                          minDate={true}
                          date={new Date()}
                          onChangeDate={this.onChangeStartDate}
                          open={openStartDatePicker}
                          handleOpen={this.handleStartDateOpen}
                          handleClose={this.handleClose}
                        />
                      ),
                      onClick: this.handleStartDateOpen
                    }}
                    placeholder="Select Start Date"
                  />
                  <Form.Field>
                    <label>Start Time</label>
                    <DatePickers
                      selected={startTime}
                      onChange={this.startTime}
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      timeIntervals={15}
                      dateFormat="h:mm aa"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    error={!!errors.endDate}
                    control={Input}
                    className="duedate"
                    name="endDate"
                    value={form.endDate ? moment(form.endDate).format('L') : ''}
                    autoComplete="off"
                    label="End Date"
                    onClick={this.handleEndDateOpen}
                    action={{
                      icon: (
                        <DatePicker
                          minDate={true}
                          date={new Date()}
                          onChangeDate={this.onChangeEndDate}
                          open={openEndDatePicker}
                          handleOpen={this.handleEndDateOpen}
                          handleClose={this.handleClose}
                        />
                      ),
                      onClick: this.handleEndDateOpen
                    }}
                    placeholder="Select End Date"
                  />
                  <Form.Field>
                    <label>End Time</label>
                    <DatePickers
                      selected={endTime}
                      onChange={this.endTime}
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      timeIntervals={15}
                      dateFormat="h:mm aa"
                    />
                  </Form.Field>
                </Form.Group>
                <SharedWithTitle>Shared With</SharedWithTitle>
                <Header>
                  <ToggleItem isToggled={type}>Private</ToggleItem>
                  <Toggle checked={type} onChange={this.toggleType} toggle={true} />
                  <ToggleItem isToggled={!type}>Public</ToggleItem>
                </Header>
                {!type && (
                  <Form.Field>
                    <StyledLabel>{Strings.tasks.sharedWith}</StyledLabel>
                    <Dropdown
                      error={errors[`sharedWith`] ? true : false}
                      name="sharedWith"
                      placeholder="Invite People"
                      multiple={true}
                      value={sharedWith}
                      fluid={true}
                      selection={true}
                      clearable={true}
                      options={userMultiOptions}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                )}
                <Button className="save-button" onClick={this.addNewEvent} content="SAVE EVENT" />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={700}
      />
    )
  }

  private toggleType = () => {
    const { edit } = this.props
    const { form, user } = this.state
    if ((user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') && !edit) {
      if (!this.state.type) {
        form[`type`] = 'Public'
      } else {
        form[`type`] = 'Private'
      }
      this.setState({ form, type: !this.state.type })
    } else {
      Toast({ message: 'You are not authorized to change type to private/public', type: 'error' })
    }
  }

  private startTime = (time: Date) => {
    const { form }: any = this.state
    form[`startTime`] = moment(time).format('LT')
    this.setState({ form, startTime: time })
  }

  private endTime = (time: Date) => {
    const { form }: any = this.state
    form[`endTime`] = moment(time).format('LT')
    this.setState({ form, endTime: time })
  }

  private onChangeStartDate = (date: string) => {
    const { form } = this.state
    if (moment(moment(date).format('YYYY-MM-DD')).isSameOrBefore(moment(form[`endDate`]).format('YYYY-MM-DD'))) {
      form[`startDate`] = date
      this.setState({ form })
      this.handleClose()
    } else {
      Toast({ message: 'Invalid Start date', type: 'error' })
      this.handleClose()
    }
  }

  private onChangeEndDate = (date: string) => {
    const { form } = this.state
    if (moment(moment(form[`startDate`]).format('YYYY-MM-DD')).isSameOrBefore(moment(date).format('YYYY-MM-DD'))) {
      form[`endDate`] = date
      this.setState({ form })
      this.handleClose()
    } else {
      Toast({ message: 'Invalid end date', type: 'error' })
      this.handleClose()
    }
  }

  private handleStartDateOpen = () => {
    this.setState({ openStartDatePicker: true })
  }

  private handleEndDateOpen = () => {
    this.setState({ openEndDatePicker: true })
  }

  private handleClose = () => {
    this.setState({ openStartDatePicker: false, openEndDatePicker: false })
  }

  private removeCard = (data: string) => {
    const { sharedWith, form }: any = this.state
    const newSharedWith = sharedWith.filter((lang: string) => lang !== data)
    form[`sharedWith`] = newSharedWith
    this.setState({ sharedWith: newSharedWith, form })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    if (name === 'sharedWith') {
      let { sharedWith }: any = this.state
      sharedWith = value
      form[name] = value
      this.setState({ form, sharedWith })
    } else {
      form[name] = value
      this.setState({ form })
    }
  }

  private addNewEvent = () => {
    const { form, startTime, endTime } = this.state
    const { addEvent } = this.props

    const validation = handleEventValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    if (endTime !== null) {
      if (moment(endTime || '').isSameOrAfter(startTime) && moment(startTime).isSameOrBefore(endTime || '')) {
        if (addEvent) {
          addEvent(form)
          this.closeModal()
        }
        this.closeModal()
      } else {
        Toast({ message: 'Invalid event time', type: 'error' })
      }
    } else {
      Toast({ message: 'Invalid end time', type: 'error' })
    }
  }
}

export default AddEventModal
