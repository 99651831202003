// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { primaryButton } from 'design/Styled'

export const Header = styled.div`
  background: ${Colors.HeaderHighlight};
  color: ${Colors.TextPrimary};
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
  text-align: center;
`

export const StyledForm = styled(Form)`
  &.ui.form {
    padding: 40px;
  }

  &.ui.form .field > label {
    color: ${Colors.Text};
  }

  .ui.button {
    ${primaryButton};
  }

  .save-button {
    text-align: center;
  }
`
