import * as React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import ActionButtons from 'shared/ImageZone/ActionButtons'
import Transition from 'shared/Transition'

import { Container, Content, Sidebar, Thumbnail } from './Styled'

import { ImageType, PermissionEnum } from 'shared/ImageZone/Types'

import { ActionEnum } from 'shared/ImageZone/ActionButtons/Types'

interface Props {
  actions: string[]
  disableSort: boolean
  getPermission: (image: ImageType) => Promise<PermissionEnum>
  image: ImageType
  index: number
  onAction: (action: ActionEnum, data: any) => void
}

interface State {
  isReady: boolean
  permission: PermissionEnum
}

const Item = SortableElement(({ actions, handleAction, handleSelect, image, isReady, permission }: any) => (
  <Transition delay={10 * image.order} on={isReady} type="fadeIn">
    <Container onClick={(e: any) => e.stopPropagation()}>
      <Content>
        <Thumbnail onClick={handleSelect} src={image.url} />
        {permission !== PermissionEnum.None && (
          <Sidebar className="sidebar">
            <ActionButtons
              actions={actions}
              image={image}
              onAction={handleAction}
              permission={permission}
              vertical={true}
            />
          </Sidebar>
        )}
      </Content>
    </Container>
  </Transition>
))

class GridItem extends React.Component<Props, State> {
  public state = {
    isReady: false,
    permission: PermissionEnum.None
  }

  public componentDidMount = () => {
    const {
      image: { url }
    } = this.props
    const image = new Image()
    image.onload = () => {
      this.setState({ isReady: true })
    }
    image.src = url
    this.getPermission()
  }

  public render() {
    const { actions, disableSort, image, index } = this.props

    const { isReady, permission } = this.state

    return (
      <Item
        actions={actions}
        disabled={disableSort}
        handleAction={this.handleAction}
        handleSelect={this.handleSelect}
        image={image}
        index={index}
        isReady={isReady}
        permission={permission}
      />
    )
  }

  private getPermission = async () => {
    const { image, getPermission } = this.props
    const permission = await getPermission(image)
    this.setState({ permission })
  }

  private handleSelect = (e: any) => {
    e.stopPropagation()

    const { image, onAction } = this.props
    const { permission } = this.state
    if (permission === PermissionEnum.Owner || permission === PermissionEnum.Viewer) {
      onAction(ActionEnum.View, image)
    }
  }

  private handleAction = (action: ActionEnum, data: any) => {
    const { onAction } = this.props
    onAction(action, data)
  }
}

export default GridItem
