import client from 'queries/apollo'

import { REGISTER_TENANT } from 'queries/graphql/Onboarding/Mutation'

export const registerTenant = async (data: any) => {
  const { email, facebook, firstName, google, lastName, password, phone, referralId, method } = data
  const result = await client.mutate({
    mutation: REGISTER_TENANT,
    variables: {
      email,
      facebook,
      firstName,
      google,
      lastName,
      password,
      phone,
      referralId,
      verificationType: method
    }
  })
  return result.data.registerNewTenant
}
