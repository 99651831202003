// Import Packages
import * as React from 'react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import { editOffice, ShowViewDetails, ToggleStatus } from '../Offices'

// Import Store Types, Actions and Reducers
import { OfficeItemsDetails, UserType } from 'store/Offices/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Address,
  AddressDetails,
  Branch,
  BranchName,
  CardContent,
  CardWrapper,
  Container,
  Divider,
  EmailIcon,
  ExtraContent,
  FullName,
  Label,
  Li,
  Map,
  MemberProfile,
  PhoneIcon,
  Profile,
  ProfileImage,
  StyledDropdown,
  StyledLabel,
  StyledPopup,
  Ul
} from './Styled'

// Font Awesome Icons
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPencilAlt])

interface Props {
  changeStatus: (status: string) => void
  CardData: OfficeItemsDetails
  boardId: string
  length: number
  order: number
}

interface State {
  showDetailsView: boolean
  profileImage: string
  showComposeMail: boolean
  user: UserType
}

const options = [{ key: 1, text: 'Active', value: 'Active' }, { key: 2, text: 'Inactive', value: 'Inactive' }]

class OfficeCard extends React.Component<Props, State> {
  public state = {
    profileImage: '',
    showComposeMail: false,
    showDetailsView: false,
    user: {} as UserType
  }

  public async componentDidMount() {
    const user: UserType = await getLoggedInUser({ fromCache: true })
    this.setState({ user })
  }

  public render() {
    const { CardData, length, order } = this.props
    const { showComposeMail, user } = this.state
    const Card = CardData.teamMembers.map((items: any, index: number) => {
      while (index < 3) {
        return (
          <Li key={index}>
            <StyledPopup
              trigger={<MemberProfile key={index} background={items.profileImage} />}
              content={`${items.firstName} ${items.lastName}`}
            />
          </Li>
        )
      }
      return null
    })
    const Count =
      CardData.teamMembers.length > 3 ? (
        <Label>
          <StyledPopup trigger={<StyledLabel>...</StyledLabel>} content={`+${CardData.teamMembers.length - 3}`} />
        </Label>
      ) : null
    let email: any
    let phone: any
    CardData[`users`].forEach((items: any) => {
      if (items.isPrimaryManager) {
        email = items.userName
        phone = phone === undefined ? 'No Phone Number Added' : items.phones[0] && items.phones[0].value
      }
    })
    return (
      <Container>
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={email} />}
        <CardWrapper>
          <CardContent>
            <Branch>
              <BranchName onClick={this.showViewDetails}>
                {Strings.office.branchName}
                {CardData.branchName}
              </BranchName>
              <StyledPopup
                style={{ textTransform: 'lowercase' }}
                trigger={<PhoneIcon />}
                content={CardData.phone ? CardData.phone : 'No Phone Number Added'}
              />
            </Branch>
            <Address>
              <Map />
              <AddressDetails onClick={this.showViewDetails}>
                {[CardData.street, CardData.city, CardData.state, CardData.zipCode].join(', ')}
              </AddressDetails>
              <StyledPopup
                style={{ textTransform: 'lowercase' }}
                trigger={
                  <EmailIcon
                    onClick={e => {
                      e.stopPropagation()
                      this.mailTo()
                    }}
                  />
                }
                content={CardData.email ? CardData.email : 'No Email Added'}
              />
            </Address>
            {CardData.firstName !== undefined &&
              CardData.lastName !== undefined && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Profile onClick={this.showViewDetails}>
                    <ProfileImage background={CardData.profileImage} />
                    <FullName>{`${CardData.firstName} ${CardData.lastName} (Manager)`}</FullName>
                  </Profile>
                  {user.role === 'ADMIN' && (
                    <div style={{ marginTop: 14, marginRight: 8 }} onClick={() => editOffice(CardData)}>
                      <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
                    </div>
                  )}
                </div>
              )}
          </CardContent>
          <Divider />
          <ExtraContent>
            <div style={{ width: 60 }}>
              {' '}
              <StyledDropdown
                className={length - 1 === order ? 'upward' : ''}
                inline={true}
                options={options}
                onChange={this.handleChangeStatus}
                value={CardData.status === 'Inactive' ? options[1].value : options[0].value}
                background={CardData.status === 'Inactive' ? Colors.Black810 : Colors.Green500}
                color={CardData.status === 'Inactive' ? Colors.Black500 : Colors.White1000}
              />
            </div>
            <div>
              <Ul style={{ display: 'flex' }}>
                {Card}
                <Li>{Count}</Li>
              </Ul>
            </div>
          </ExtraContent>
        </CardWrapper>
      </Container>
    )
  }

  private showViewDetails = () => {
    const { CardData } = this.props
    this.setState({ showDetailsView: true }, () => {
      if (this.state.showDetailsView) {
        ShowViewDetails(CardData._id)
      }
    })
  }

  private mailTo = () => {
    this.setState({ showComposeMail: true })
  }

  private closeModal = () => {
    this.setState({ showComposeMail: false })
  }

  private handleChangeStatus = (event: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const { boardId, CardData } = this.props
    if (CardData.status !== value) {
      if (value === 'Inactive') {
        ConfirmAlert({
          cancelButtonText: Strings.kanbanView.noKeepItActive,
          confirmButtonText: Strings.kanbanView.yesMakeItInactive,
          showCancelButton: true,
          text: Strings.kanbanView.recoverOffices,
          title: Strings.kanbanView.sure,
          type: 'warning'
        }).then(result => {
          if (result.value) {
            ToggleStatus(boardId, CardData._id, value)
          }
        })
      } else {
        ToggleStatus(boardId, CardData._id, value)
      }
    }
  }
}

export default OfficeCard
