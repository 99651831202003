// Import Packages
import styled from 'styled-components'

import { Popup } from 'semantic-ui-react'

// Import Colors
import Colors from 'design/Colors'
import { scrollYContainer } from 'design/Styled'

// Import Fonts
import { Form } from 'semantic-ui-react'

interface Props {
  background: string
}

interface ImageProps {
  backgroundImage: string
}

const Wrapper = styled.div`
  height: 100%;
  padding: 40px;
  overflow: auto;
  overflow-y: hidden;
  flex: 1;
`

const StyledForm = styled(Form)`
  margin-left: 18px;

  &.ui.form .field .ui.input input {
    border-radius: 2px;
    width: 500px !important;
  }

  .ui.button {
    border-radius: 2px;
    background: ${Colors.DarkBlue200} !important;
    color: ${Colors.White1000} !important;
    font-size: 12px;
  }
`

const StyledNotes = styled.div`
  margin-left: 18px;
  width: 100%;
  padding-right: 25px;
  flex: 1;
  overflow: auto;
  ${scrollYContainer};
`

const Profile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: flex-start;
  margin-right: 10px;
  flex-shrink: 0;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
`

const Container = styled.div`
  display: flex;
  color: ${Colors.Black550};
  margin-bottom: 15px;
`

const Description = styled.div`
  font-size: 11px;
  line-height: 12px;
  text-align: justify;
`

const Date = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 5px;

  i.icon {
    margin-right: 8px;
    cursor: pointer;
  }
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

const Infograph = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 480px;
  align-self: center;
`

export {
  Container,
  Date,
  Description,
  Infograph,
  Profile,
  StyledForm,
  StyledNotes,
  StyledPopup,
  Wrapper
}
