import client from 'queries/apollo'

import { GET_DOCUMENTS_WITH_IDS } from 'queries/graphql/Document/Queries'
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import { GET_IDX_PASSES } from 'queries/graphql/Passes/Queries'
import {
  GET_TRANSACTION_PROPERTY_CATEGORY,
  GET_TRANSACTION_PROPERTY_SUB_CATEGORIES
} from 'queries/graphql/TransactionBoard/Queries'
import {
  GENERIC_GET_TRANSACTIONS,
  GET_TRANSACTION_DATA_VIA_IDX,
  GET_TRANSACTIONS_DOCUMENTS
} from 'queries/graphql/Transactions/Queries'

export const genericGetTransactions = async (where: any = {}, sort: any = {}, filter: any = {}) => {
  const query = await client.query({
    query: GENERIC_GET_TRANSACTIONS,
    variables: {
      filter,
      sort,
      where
    }
  })

  let result: any = null
  if (query.data && query.data.genericGetTransactions) {
    result = query.data.genericGetTransactions
  }
  return result
}

export const getTransactionDocuments = async (transactionId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTIONS_DOCUMENTS,
    variables: { transactionId }
  })

  return response.data.getDocuments
}

export const getDocumentsWithIds = async (ids: string[]) => {
  const response = await client.query({
    query: GET_DOCUMENTS_WITH_IDS,
    variables: { ids }
  })

  return response.data.getDocumentsWithIds
}

export const getTransactionCategories = async (token: string = '') => {
  const response = await client.query({
    context: {
      headers: {
        authorization: token
      }
    },
    query: GET_TRANSACTION_PROPERTY_CATEGORY
  })

  return response.data.getPropertyCategories
}

export const getTransactionSubcategories = async (propertyCategoryId: string, token: string = '') => {
  const response = await client.query({
    context: {
      headers: {
        authorization: token
      }
    },
    query: GET_TRANSACTION_PROPERTY_SUB_CATEGORIES,
    variables: { propertyCategoryId }
  })

  return response.data.getPropertySubCategories
}

export const getOffices = async (token: string = '') => {
  const response = await client.query({
    context: {
      headers: {
        authorization: token
      }
    },
    query: GET_OFFICES,
    variables: { filter: {} }
  })

  return response.data.getOffices
}

export const getIdxData = async (listingId: string, officeId: string, token: string = '') => {
  const response = await client.query({
    context: {
      headers: {
        authorization: token
      }
    },
    query: GET_TRANSACTION_DATA_VIA_IDX,
    variables: {
      input: {
        listingId,
        office: officeId
      }
    }
  })

  return response.data.getTransactionDataViaIdx
}

export const getIdxPasses = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_IDX_PASSES
  })

  return response.data.getUserPasses.results
}
