import client from 'queries/apollo'

import { GET_USER_PROFILE } from 'queries/graphql/UserProfile/Queries'

export const getUserProfile = async (id:string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_PROFILE,
    variables: { id }
  })

  return response.data.getUsers[0]
}
