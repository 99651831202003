// Import Packages
import * as React from 'react'
import { Menu } from 'semantic-ui-react'

// Import Components
import Activity from './Activity'
import EmailDrip from './EmailDrip'
import Workflow from './Workflow'

// Import Store Types, Actions and Reducers
import { LeadItemsDetails } from 'store/Leads/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { Container, ProImage, StyledActivityTab, StyledLabel, StyledTab, WorkflowImage } from './Styled'

// Font Awesome Icons
import { faEnvelopeOpenText, faHomeHeart } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import client from 'queries/apollo'
import { GET_TASK_ACTIVITY } from 'queries/graphql/Tasks/Queries'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelopeOpenText, faHomeHeart])

interface Props {
  leadData: LeadItemsDetails
  toggleStatus: boolean
}

interface State {
  activeIndex: number
  activitiesCount: number
}

class RightPanel extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    activitiesCount: 0
  }

  public async componentDidMount() {
    const { leadData } = this.props
    const activities = await client.query({
      fetchPolicy: 'network-only',
      query: GET_TASK_ACTIVITY,
      variables: { leadId: leadData._id }
    })

    this.setState({ activitiesCount: activities.data.getActivities.length })
  }

  public async componentWillReceiveProps() {
    const { leadData } = this.props
    const activities = await client.query({
      fetchPolicy: 'no-cache',
      query: GET_TASK_ACTIVITY,
      variables: { leadId: leadData._id }
    })

    this.setState({ activitiesCount: activities.data.getActivities.length })
  }

  public render() {
    const { activeIndex, activitiesCount } = this.state
    const { leadData, toggleStatus } = this.props
    const panes = [
      {
        menuItem: {
          content: <span>{Strings.leads.workflow}</span>,
          icon: <WorkflowImage />,
          key: 'Workflow'
        },
        render: () => <Workflow leadData={leadData} getActivityCount={this.getCount} toggleStatus={toggleStatus} />
      },
      {
        menuItem: {
          content: <span>{Strings.leads.emailDrip}</span>,
          icon: (
            <span style={{ display: 'contents' }}>
              <ProImage />
              <FontAwesomeIcon icon={['fal', 'envelope-open-text']} />
            </span>
          ),
          key: 'Email Drip'
        },
        render: () => <EmailDrip />
      }
    ]
    const activityPanes = [
      {
        menuItem: (
          <Menu.Item key="Activity">
            {Strings.leads.Activity}
            <StyledLabel circular={true}>{activitiesCount}</StyledLabel>
          </Menu.Item>
        ),
        render: () => <Activity leadData={leadData} />
      }
    ]
    return (
      <Container>
        <StyledTab
          className="styledTab"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          onTabChange={this.handleTabChange}
        />
        {activeIndex === 0 && <StyledActivityTab menu={{ secondary: true, pointing: true }} panes={activityPanes} />}
      </Container>
    )
  }

  public handleTabChange = (e: React.SyntheticEvent<EventTarget>, { activeIndex }: any) =>
    this.setState({ activeIndex })

  public getCount = (count: number) => this.setState({ activitiesCount: count })
}

export default RightPanel
