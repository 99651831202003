export const formatData = (data: any, userID: string) => {
  const folderFilesData = Object.assign(data)
  const newData = folderFilesData.data.Contents.map((list: any) => {
    const fileName = list.Key.split('/')
    const length = fileName.length
    return {
      ...list,
      fileName:
        list.Key.charAt(list.Key.length - 1) === '/'
          ? fileName[length - 2]
          : fileName[length - 1],
      id:
        list.Key.charAt(list.Key.length - 1) === '/'
          ? fileName[length - 2]
          : fileName[length - 1],
      modified: list.LastModified,
      parent:
        list.Key.charAt(list.Key.length - 1) === '/'
          ? userID !== fileName[length - 3]
            ? userID !== fileName[length - 3]
              ? fileName[length - 3]
              : 'NONE'
            : 'NONE'
          : userID !== fileName[length - 2]
            ? fileName[length - 2]
            : 'NONE',
      type: list.Key.charAt(list.Key.length - 1) === '/' ? 'FOLDER' : 'FILE'
    }
  })
  return newData
}
