// Import Packages
import { Form, Input, Search } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const StyledForm = styled(Form)`
  &.ui.form {
    font-size: 12px;
    width: 90%;
    padding: 40px 10px 100px 45px;
    padding-bottom: 0;
  }

  &.ui.form>.field>input {
    border-radius: 4%;
    color: ${Colors.Black500};
  }

  &.ui.form .field .ui.input {
    width: 240px;
  }

  &.ui.form .field .ui.input>i.icon {
    font-size: 8px;
  }

  &.ui.form .field>label {
    font-weight: 100;
  }

  &.ui.form>.ui.input {
    width: 175px;
  }

  &.ui.form>.ui.button {
    width: 135px;
    margin-top: 25px;
    border-radius: 4%;
    background: ${Colors.DarkBlue200} !important;
    color: ${Colors.White1000} !important;
    font-weight: 600;
  }

  .save-button>.ui.button {
    width: 135px;
    margin-top: 25px;
    border-radius: 4%;
    background: ${Colors.DarkBlue200} !important;
    color: ${Colors.White1000} !important;
    font-weight: 600;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ui.search>.results {
    max-height: 180px;
    overflow: auto;
    ${scrollbars};
  }
`

const StyledInput = styled(Input)`
  width: 180px;

  button {
    background-color: ${Colors.DarkBlue200} !important;
    width: 65px;
    justify-content: space-around;
  }

  .fa-cloud-upload-alt {
    width: 20px;
    height: 15px;
    color: ${Colors.White1000};
  }
`

const StyledSearch = styled(Search)`
  &.ui.search {
    width: 100%;
  }

  .ui.icon.input>i.icon {
    display: none;
  }

  .ui.input {
    height: 30px;
  }
`

const StyledLabel = styled.label`
  font-weight: 700;
  margin: 0 0 .28571429rem 0;
  font-size: 11px;
  color: darkslategrey;
`

export {
  StyledForm,
  StyledInput,
  StyledSearch,
  StyledLabel
}
