// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  background: string
}

const Container = styled.div`
  background-color: ${Colors.White1000};
  overflow: auto;
  height: 550px;
`

const Infograph = styled.div`
  background: ${(props: Props) => `url(${props.background}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`

export {
  Container,
  Infograph
}
