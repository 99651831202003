// Import Packages
import Griddle from 'griddle-react'
import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { fontStyle } from 'design/Fonts'
import { children, scrollbars, scrollXContainer, styledToggle, x } from 'design/Styled'

export const StyledGrid = styled(Griddle)`
  &.griddle-cell{
    height: 50px;
  }
`

export const Container = styled.div`
  overflow: auto;
  height: 400px;
  ${scrollbars};
`

interface ImageProps {
  backgroundImage?: string
  isToggled?: boolean
}

export const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin: 0px 5px;
  display: inline-block;
  &.backbutton {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`

export const StyledTableHeaderCell = styled.th`
  color: ${Colors.Black500};
  font-size: 16.5px;
  font-weight: 400;
  height: 50px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
`

export const StyledTableRow = styled.tr`
  border-bottom-color: ${Colors.Grey700};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: 40px;
  width:  100%;
  cursor: pointer;
  user-select: none;
  &.header {
    background-color: ${Colors.transparent};
  }
  &.item {
    background-color: ${Colors.White800};
    &:hover {
      background-color: ${Colors.White900} !important;
    }
  }
`

export const NoResultsView = styled.div`
  height: 400px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: ${fontStyle};
`

export const NavigationDetail = styled.div`
  ${scrollXContainer};
  border-bottom-color: ${Colors.Grey700};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: 50px;
  width:  100%;
  background-color: ${Colors.transparent};
  display: flex;
  align-items: center;
  padding: 5px 32px;
`

export const Header = styled.div`
  ${x};
  ${children};
  justify-content: flex-end;
  font-size: 12px;
  font-weight: bold;
  padding: 12px;
  padding-left: 30px;
  padding-right: 0px;
  color: ${Colors.Text};
`

export const ToggleItem = styled.div`
  color: ${(props: ImageProps) => props.isToggled ? Colors.Grey50 : Colors.Black550};
`

export const Toggle = styled(Checkbox)`
  ${styledToggle};
`

export const PathItem = styled.div`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px;
  margin: 0px 10px;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(128,128,128,0.3);
  border-radius: 5px;
  cursor: pointer;
`

export const PathItemContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`

export const Infograph = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`

export const AddButtonContainer = styled.div`
  cursor: pointer;
  margin-left: 10px;

  .fa-plus {
    width: 30px;
    height: 30px;
    background-color: ${Colors.DarkBlue200};
    color: white;
    border-radius: 50%;
    padding: 10px;
  }

  .fa-cloud-upload-alt{
    width: 35px;
    height: 35px;
    color: ${Colors.DarkBlue200};
  }
`
