import styled from 'styled-components'

import PlusCircle from 'design/icons/styledBoard/icon-plus-circle.png'

import { y } from 'design/Styled'

interface Props {
  size?: any
  display?: string
  height?: any
}

export const AddCardContainer = styled.div`
  text-align: -webkit-center;
  width: ${(props: Props) => props.size || props.size};
  height: ${(props: Props) => props.size || props.size};
`

export const AddCard = styled.div`
  background: url(${PlusCircle});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  padding: 10px;
  align-self: center;
  visibility: hidden;
  cursor: pointer;
  display: ${(props: Props) => props.display || props.display};
  margin-top: 5px;
  margin-bottom: 5px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.4em;
  margin-bottom: ${(props: Props) => props.size || props.size};
  outline: none;

  &:hover {
    ${AddCard} {
      visibility: visible;
    }
  }
`

export const ContentWrapper = styled.div`
  ${y};
  flex: 1;
  overflow: hidden;
  z-index: 1;
`
