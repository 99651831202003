import { Reducer } from 'redux'
import Actions from 'store/Tasks/ActionsTypes'
import {
  addNewBoard,
  addNewCard,
  addNewNotes,
  changeStatus,
  deleteCard,
  deleteListRecord,
  deleteNotes,
  editNotes,
  editRecord,
  importData,
  restoreCard,
  setNewBoardName,
  setNewDescription,
  setNewIcon,
  updateCard
} from './ReduxUtil'
import { Task, TaskItemsDetails, TaskPassFilter } from './Types'

const initialState: Task = {
  activities: [],
  allTaskStatus: false,
  boardId: '',
  cardIndex: '',
  currentlyDeleteCard: null,
  data: [],
  filter: {
    filter: false,
    searchText: false
  },
  listData: [],
  notes: [],
  searchData: {},
  searchLoader: false,
  taskDetail: {},
  userPassTaskFilter: {} as TaskPassFilter
}

const TaskReducer: Reducer<Task> = (state: Task = initialState, action) => {
  switch (action.type) {
    case Actions.TaskUpdateCard:
      const {
        payload: { boardId, task }
      } = action
      return {
        ...state,
        data: updateCard(state, task, boardId)
      }
    case Actions.TaskAddNewBoard:
      return {
        ...state,
        data: addNewBoard(state, action.payload.index, action.payload.newData)
      }
    case Actions.TaskGetBoardData:
      return {
        ...state,
        data: action.payload.data
      }
    case Actions.TaskGetListData:
      return {
        ...state,
        listData: action.payload.data
      }
    case Actions.TaskAddNewCard:
      return {
        ...state,
        data: addNewCard(
          state,
          action.payload.id,
          action.payload.index,
          action.payload.newData
        )
      }
    case Actions.TaskSetNewIcon:
      return {
        ...state,
        data: setNewIcon(state, action.payload.id, action.payload.icon)
      }
    case Actions.TaskSetNewDescription:
      return {
        ...state,
        data: setNewDescription(
          state,
          action.payload.id,
          action.payload.description
        )
      }
    case Actions.TaskSetNewBoardName:
      return {
        ...state,
        data: setNewBoardName(
          state,
          action.payload.id,
          action.payload.boardName
        )
      }
    case Actions.TaskDeleteRecord:
      return {
        ...state,
        listData: deleteListRecord(state, action.payload.id)
      }
    case Actions.TaskDeleteCard:
      const { data } = state
      const feeData = data.slice()
      let index: string
      let temp: TaskItemsDetails
      const callback = () => {
        return {
          ...state,
          boardId: action.payload.boardId,
          cardIndex: index,
          currentlyDeleteCard: temp,
          data: deleteCard(state, action.payload.boardId, action.payload.cardId)
        }
      }
      feeData.forEach((list: any) => {
        if (list._id === action.payload.boardId) {
          const newList = list.items.slice()
          const listIndex = newList.findIndex((laneItem: any) => {
            return laneItem._id === action.payload.cardId
          })
          index = listIndex
          temp = newList[listIndex]
          callback()
        }
      })
      return callback()
    case Actions.TaskRestoreCard:
      return {
        ...state,
        data: restoreCard(
          state,
          state.boardId,
          state.cardIndex,
          state.currentlyDeleteCard
        )
      }
    case Actions.TaskResetCardData:
      return {
        ...state,
        boardId: '',
        cardIndex: '',
        currentlyDeleteCard: null
      }
    case Actions.TaskChangestatus:
      return {
        ...state,
        data: changeStatus(
          state,
          action.payload.boardId,
          action.payload.cardId,
          action.payload.status
        )
      }
    case Actions.TaskAddNewNotes:
      const {
        payload: { cardId, newData }
      } = action
      const result = addNewNotes(state, newData, cardId)
      return {
        ...state,
        data: result.data,
        notes: result.notes
      }
    case Actions.TaskGetNotesData:
      return {
        ...state,
        notes: action.payload.data
      }
    case Actions.TaskEditNotes:
      return {
        ...state,
        notes: editNotes(state, action.payload.id, action.payload.newData)
      }
    case Actions.TaskDeleteNotes:
      return {
        ...state,
        notes: deleteNotes(state, action.payload.id)
      }
    case Actions.GetTaskDetails:
      return {
        ...state,
        taskDetail: action.payload.data
      }
    case Actions.GetTaskActivities:
      return {
        ...state,
        activities: action.payload.data
      }
    case Actions.TaskEditRecord:
      return {
        ...state,
        listData: editRecord(state, action.payload.newData)
      }
    case Actions.TaskImportData: {
      return {
        ...state,
        data: importData(
          state,
          action.payload.boardId,
          action.payload.importedData
        )
      }
    }
    case Actions.TaskFilter:
      return {
        ...state,
        filter: action.payload.data
      }
    case Actions.TaskSearchLoader:
      return {
        ...state,
        searchLoader: action.payload.open
      }
    case Actions.TaskToggleStatus:
      return {
        ...state,
        allTaskStatus: action.payload.open
      }
    case Actions.TaskSearchFilter:
      return {
        ...state,
        searchData: action.payload.data
      }
    case Actions.userPassTaskFilter:
      return {
        ...state,
        userPassTaskFilter: action.payload.filters
      }
    default:
      return state
  }
}

export default TaskReducer
