import {
  CustomeColor,
  DashboardState,
  Passes,
  PaymentNotes,
  WallpaperImage,
  WidgetSection
} from './Types'

export const addWidgetSection = (
  state: DashboardState,
  newData: WidgetSection
): WidgetSection[] => {
  const { widgetSection } = state
  const feeData = widgetSection.slice()
  feeData.push(newData)
  return [...feeData]
}

export const deleteWidgetSection = (
  state: DashboardState,
  id: string
): WidgetSection[] => {
  const { widgetSection } = state
  const feeData = widgetSection.slice()
  const noteIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  feeData.splice(noteIndex, 1)
  return [...feeData]
}

export const renameWidgetSection = (
  state: DashboardState,
  id: string,
  newName: string
): WidgetSection[] => {
  const { widgetSection } = state
  const feeData = widgetSection.slice()
  const noteIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  const index = { ...feeData[noteIndex] }
  index.name = newName
  feeData[noteIndex] = index
  return [...feeData]
}

export const addPass = (
  state: DashboardState,
  id: string,
  newData: Passes
): WidgetSection[] => {
  const { widgetSection } = state
  let feeData = widgetSection.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      const newList = list.items.slice()
      newList.splice(0, 0, newData)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return feeData
}

export const deletePass = (
  state: DashboardState,
  sectionId: string,
  passId: string
): WidgetSection[] => {
  const { widgetSection } = state
  let feeData = widgetSection.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === sectionId) {
      const newList = list.items.slice()
      const listIndex = newList.findIndex((laneItem: any) => {
        return laneItem._id === passId
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const renamePass = (
  state: DashboardState,
  section: string,
  passId: string,
  newName: string
): WidgetSection[] => {
  const { widgetSection } = state
  let feeData = widgetSection.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === section) {
      const newList = list.items.slice()
      const listIndex = newList.findIndex((laneItem: any) => {
        return laneItem._id === passId
      })
      const index = { ...newList[listIndex] }
      index.title = newName
      newList[listIndex] = index
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const addCustomeColor = (
  state: DashboardState,
  newData: CustomeColor
): CustomeColor[] => {
  const { colors } = state
  const feeData = colors.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const addWallpaperImage = (
  state: DashboardState,
  newData: WallpaperImage
): WallpaperImage[] => {
  const { wallpaper } = state
  const feeData = wallpaper.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const addNewNotes = (
  state: DashboardState,
  newData: PaymentNotes
): PaymentNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}
