import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import styled from 'styled-components'

const TextBoxLabel = styled(Label)`
  &&.label {
    border: none;
    width: inherit;
    background-color: transparent;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 10px;
  }
`

const TextBoxItem = styled.div`
  display: contents;
  padding: 0em 0em;
  font-size: 10px;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
`

export {
  TextBoxItem,
  TextBoxLabel
}
