// Import Packages
import styled from 'styled-components'

// Import Images and Icons
import { SecondaryFontName } from 'design/Fonts'
import { rocket, welcomeBG } from 'design/images/onboarding'

// Import Colors
import Colors from 'design/Colors'

const WelcomeContainer = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
`

const TextContainer = styled.div`
  width: 55%;
  margin-bottom: 50px;
`

const Rocket = styled.div`
  width: 55%;
  height: 100%;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${rocket});
`

const Header = styled.div`
  color: ${Colors.DarkBlue200};
  font-size: 30px;
  font-weight: 300;
  font-family: ${SecondaryFontName};
  line-height: 32px;
  margin: 8px 0 40px;
`

const HeaderGray = styled.div`
  color: ${Colors.Black600};
  font-size: 30px;
  font-weight: 600;
  font-family: ${SecondaryFontName};
`

const Text = styled.div`
  color: ${Colors.Black600};
  font-size: 16px;
  margin-bottom: 30px;
  font-family: ${SecondaryFontName};
`

const TextColored = styled.div`
  color: ${Colors.DarkBlue200};
  font-size: 18px;
  margin: 70px 0 20px;
  font-family: ${SecondaryFontName};
`

const TextBold = styled.span`
  font-weight: 600;
`

const BGImage = styled.div`
  width: 100%;
  height: 50vh;
  background-size: contain;
  background-position: top;
  background-image: url(${welcomeBG});
  position: absolute;
  top: 0;
  right: 0;
`

export {
  BGImage,
  Header,
  HeaderGray,
  Rocket,
  Text,
  TextBold,
  TextColored,
  TextContainer,
  WelcomeContainer
}
