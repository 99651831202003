import styled from 'styled-components'

import Colors from 'design/Colors'
import { childWidths, x } from 'design/Styled'

export const Container = styled.section`
  ${x};
  ${childWidths};
  .rp-button-block {
    padding: 0.4em 0.6em;
    span {
      transform: scale3d(1,1,1);
    }
    &:hover span {
      color: ${Colors.TextPrimary};
    }
  }
`
