import { Button, Form, Input } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import PlusCircle from 'design/icons/styledBoard/icon-plus-circle.png'

import Colors from 'design/Colors'
import { children, link, primaryButton, scrollbars, x, y } from 'design/Styled'

interface Props {
  isActive?: boolean
}

const arrow = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  color: ${Colors.TextLight};
  border-radius: 50%;
  background: ${Colors.ButtonPrimary};
  opacity: 0.3;
  cursor: pointer;
  z-index: ${(props: Props) => (props.isActive ? 2 : 0)};

  &:hover {
    opacity: 1;
  }

  svg {
    position: relative;
  }
`

export const KanbanBoardContainer = styled.section`
  ${x};
  flex: 1;
  position: relative;
  overflow: hidden;
`

export const KanbanLaneContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: stretch;
  align-items: stretch;
  flex: 1;
  height: 100%;
  padding-bottom: 0.2em;
  overflow: hidden;
  z-index: ${(props: Props) => (props.isActive ? 1 : 2)};
  transition: all 300ms;
`

export const KanbanContainer = styled.div`
  ${x};
  width: auto;
  height: 100%;
  overflow: hidden;
`

export const KanbanScroller = styled.div`
  ${x};
  ${scrollbars};
  height: 100%;
  padding: 1em;
  overflow-x: auto;
  overflow-y: hidden;
`

export const Col = styled.div`
  ${y};
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Aside = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props: Props) => (props.isActive ? 280 : 150)}px;
  height: 100%;
  padding: 1em;
  background: ${Colors.Container};
  box-shadow: 0 0 9px -3px ${Colors.CardShadow};
  overflow: hidden;
  z-index: ${(props: Props) => (props.isActive ? 1 : 2)};
  transition: all 300ms;

  @media (min-width: 1500px) {
    width: ${(props: Props) => (props.isActive ? 340 : 160)}px;
  }
`

export const DropAside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  border: 4px dotted ${Colors.DropZoneHighlight};
  opacity: ${(props: Props) => (props.isActive ? 0.8 : 0)};
  z-index: ${(props: Props) => (props.isActive ? 1 : -9)};
  visibility: ${(props: Props) => (props.isActive ? 'visible' : 'none')};
  transition: all 300ms;

  & > * {
    opacity: 0.4;
  }

  &:hover {
    opacity: ${(props: Props) => (props.isActive ? 1 : 0)};
  }
`

export const Delete = styled.div`
  ${y};
  height: 100%;
`

export const AddBoard = styled.div`
  background: url(${PlusCircle});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  padding: 10px;
  align-self: center;
  margin-top: 20px;
  visibility: hidden;
  cursor: pointer;
  margin-left: 5px;
`

export const KanbanHoverContainer = styled.div`
  ${x};
  height: 100%;

  &:hover {
    ${AddBoard} {
      visibility: visible;
    }
  }
`

export const LeftArrow = styled.nav`
  ${arrow};
  left: 1em;
  svg {
    left: -1px;
  }
`

export const RightArrow = styled.nav`
  ${arrow};
  right: 1em;
  svg {
    left: 1px;
  }
`

export const AddBoardContainer = styled.div`
  height: 100%;
  width: 40px;
`

export const AddLink = styled.div`
  ${link};
  width: 100%;
  text-align: center;
  margin-bottom: 0.5em;
`

export const Image = styled.img`
  display: block;
  margin: 0.5em auto;
`

export const DeleteText = styled.span`
  width: 100%;
  padding: 1em;
  text-align: center;
`

export const StyledForm = styled(Form)``

export const StyledInput = styled(Input)`
  ${x};
  margin-bottom: 0.5em;
`

export const StyledButton = styled(Button)`
  &.ui.button {
    ${primaryButton};
    display: flex;
    flex: 1;
    justify-content: center;
    min-width: 0;
    max-width: 48%;
    font-size: 11px;
  }
`
