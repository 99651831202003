import * as React from 'react'
import Modal from 'shared/Modal'
import { FormType } from '../InviteModal/InviteMember/Types'
import InviteMember from './InviteMember'
import PendingMember from './PendingInvites'
import {
  Container,
  InviteSection,
  StyledTab
} from './Styled'
import Team from './Team'

interface Props {
  closeModal: () => void
  cardId: string
  activeIndex: number
}

interface State {
  activeIndex: number
  activeIndexNone: boolean
  editInviteData: FormType
  animate: boolean
}

class InviteModal extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    activeIndexNone: false,
    animate: true,
    editInviteData: {} as FormType
  }

  public render() {
    const { activeIndex } = this.props
    const { activeIndexNone, editInviteData, animate } = this.state
    const { cardId } = this.props
    const panes = [
      { menuItem: 'Invite Member', render: () => <InviteMember closeModal={this.closeModal} cardId={cardId} editInvite={editInviteData} editedInvite={this.editedInvite} /> },
      { menuItem: 'Pending Invites', render: () => <PendingMember cardId={cardId} editInvite={this.editInvite} /> },
    ]
    return (
      <div>
        <Modal
          content={
            <Container>
              <InviteSection>
                <StyledTab
                  menu={{ secondary: true, pointing: true }}
                  activeIndex={activeIndexNone ? this.state.activeIndex : activeIndex}
                  panes={panes}
                  onTabChange={this.handleTabChange} />
              </InviteSection>
              <div>
                <Team cardId={cardId} />
              </div>
            </Container>
          } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} minWidth={1000} />
      </div>
    )
  }

  private handleTabChange = (e: React.SyntheticEvent<HTMLDivElement>, { activeIndex }: any) => {
    this.setState({ activeIndex, activeIndexNone: true })
  }

  private editInvite = (data: FormType) => {
    this.setState({ activeIndex: 0, activeIndexNone: true, editInviteData: data })
  }

  private editedInvite = () => {
    this.setState({ activeIndex: 1, activeIndexNone: true, editInviteData: {} as FormType })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default InviteModal

