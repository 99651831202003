// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Accordion } from 'semantic-ui-react'

// Import Components
import AccordionContent from './AccordionContent'
import { contactMockData, leadMockData, officeMockData, taskMockData, teamMockData, teamMockDataForCapOff, transactionMockData, userMockData } from './PickMockData'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import { PickData as PickDataType } from 'store/Reports/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  AccordionContainer,
  Container,
  Infograph,
  Reports,
  StyledAccordion,
  StyledCheckbox,
  Text,
  Title,
  Triangle,
} from './Styled'

// Font Awesome Icons
import {
  faCaretDown,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCaretDown])

interface StoreProps {
  setPickData: (data: PickDataType[]) => void
}

interface OwnProps {
  activeTab: (tabNumber: number) => void
  capStatus: string
  systemReport: string
  tabActive: string
  module: string
  resetData: boolean
  pickData: PickDataType[]
  monthlySummary: any
}

type Props = StoreProps & OwnProps

interface State {
  activeIndex: number
  pickData: PickDataType[]
  checkBox: boolean
}

class PickData extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    checkBox: false,
    pickData: []
  }

  public componentDidMount() {
    const { module, pickData, systemReport, capStatus } = this.props
    let data: any
    switch (module === 'MY REPORTS' ? systemReport : module) {
      case 'TRANSACTIONS':
        data = transactionMockData
        break
      case 'LEADS':
        data = leadMockData
        break
      case 'CONTACTS':
        data = contactMockData
        break
      case 'USERS':
        data = userMockData
        break
      case 'TEAMS':
        data = capStatus === 'ON' ? teamMockData : teamMockDataForCapOff
        break
      case 'OFFICES':
        data = officeMockData
        break
      case 'TASKS':
        data = taskMockData
        break
      default:
        data = []
        break
    }
    if (pickData.length === 0) {
      this.setState({ pickData: data })
    } else {
      this.setState({ pickData })
    }
  }

  public componentWillReceiveProps(nextProps: any) {
    const { module, setPickData, capStatus } = this.props
    if (module !== nextProps.module) {
      let data: any
      switch (nextProps.module ? nextProps.module : module) {
        case 'TRANSACTIONS':
          data = transactionMockData
          break
        case 'LEADS':
          data = leadMockData
          break
        case 'CONTACTS':
          data = contactMockData
          break
        case 'USERS':
          data = userMockData
          break
        case 'TEAMS':
          data = capStatus === 'ON' ? teamMockData : teamMockDataForCapOff
          break
        case 'OFFICES':
          data = officeMockData
          break
        case 'TASKS':
          data = taskMockData
          break
        default:
          data = []
          break
      }
      this.setState({ pickData: data, }, () => {
        setPickData(this.state.pickData)
      })
    } else {
      if (nextProps.pickData.length !== 0) {
        this.setState({ pickData: nextProps.pickData })
      } else {
        let data: any
        switch (nextProps.systemReport) {
          case 'TRANSACTIONS':
            data = transactionMockData
            break
          case 'LEADS':
            data = leadMockData
            break
          case 'CONTACTS':
            data = contactMockData
            break
          case 'USERS':
            data = userMockData
            break
          case 'TEAMS':
            data = capStatus === 'ON' ? teamMockData : teamMockDataForCapOff
            break
          case 'OFFICES':
            data = officeMockData
            break
          case 'TASKS':
            data = taskMockData
            break
          default:
            return
        }
        this.setState({ pickData: data, }, () => {
          setPickData(this.state.pickData)
        })
      }
    }
  }

  public render() {
    const { tabActive } = this.props
    const { activeIndex, pickData } = this.state
    const pick = pickData.map((items: any, index: any) => {
      const {
        isChecked,
        isDisabled,
        _id: itemId,
        fieldName = []
      } = items
      return (<StyledAccordion key={index}>
        <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
          <span>{fieldName.length === 0 && <StyledCheckbox checked={isChecked} disabled={isDisabled}  onChange={() => this.handleChange(items._id)} />}{items.fieldCategory}</span>
          {fieldName.length !== 0 ? activeIndex === index ? <FontAwesomeIcon icon={['fas', 'caret-down']} /> : <FontAwesomeIcon icon={['fas', 'caret-right']} /> : null}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index}>
          <AccordionContent categoryId={itemId} report={fieldName} handleSingleChange={this.handleSingleChange} />
        </Accordion.Content>
      </StyledAccordion>
    )
  })
    return (
      <Container>
        <Title onClick={this.activeTab} border={tabActive}>
          <Text><i>{Strings.reports.number1}</i>{Strings.reports.pickYourData}</Text>
          <Triangle />
        </Title>
        <Reports>
          {
            pickData.length === 0 ? <Infograph background={Strings.generalText.nothing} /> :
              <AccordionContainer>
                {pick}
              </AccordionContainer>
          }
        </Reports>
      </Container>
    )
  }

  private handleChange = (categoryId: string) => {
    const { setPickData } = this.props
    const { pickData } = this.state
    const newData = pickData.map((dataItem: any) => {
      if (dataItem._id === categoryId && dataItem.fieldId !== 'firstName' && dataItem.fieldId !== 'lastName') {
        return {
          ...dataItem,
          fieldName: dataItem.fieldName.map((items: any) => {
            return {
              ...items,
              isChecked: dataItem.isChecked ? false : !dataItem.isChecked ? true : '',
            }
          }),
          isChecked: !dataItem.isChecked
        }
      }
      return { ...dataItem }
    })
    this.setState({ pickData: newData, checkBox: true }, () => {
      setPickData(this.state.pickData)
    })
  }

  private handleSingleChange = (categoryId: string, fieldId: string) => {
    const { setPickData } = this.props
    const { pickData } = this.state
    const newData = pickData.map((dataItem: any) => {
      if (dataItem._id === categoryId) {
        return {
          ...dataItem,
          fieldName: dataItem.fieldName.map((items: any) => {
            if (items._id === fieldId) {
              return {
                ...items,
                isChecked: !items.isChecked,
              }
            }
            return { ...items }
          }),
        }
      }
      return { ...dataItem }
    })
    this.setState({ pickData: newData }, () => {
      setPickData(this.state.pickData)
    })
  }

  private activeTab = () => {
    const { activeTab } = this.props
    activeTab(1)
  }

  private handleClick = (e: React.SyntheticEvent<EventTarget>, titleProps: any) => {
    const { checkBox } = this.state
    if (!checkBox) {
      const { index } = titleProps
      const { activeIndex } = this.state
      const newIndex = activeIndex === index ? -1 : index
      this.setState({ activeIndex: newIndex })
    }
    this.setState({ checkBox: false })
  }
}

const mapStateToProps = (state: AppState) => ({
  module: state.reports.module,
  pickData: state.reports.pickData,
  resetData: state.reports.resetData,
  systemReport: state.reports.systemReport,
})

export default connect(
  mapStateToProps,
  {
    setPickData: Actions.getPickData,
  }
)(PickData)
