import { Reducer } from 'redux'
import Actions from 'store/Offices/ActionsTypes'
import {
  addNewBoard,
  addNewCard,
  changeStatus,
  deleteCard,
  restoreCard,
  setNewBoardName,
  setNewDescription,
  setNewIcon
} from './ReduxUtil'
import { Office, OfficeItemsDetails } from './Types'

const initialState: Office = {
  boardId: '',
  cardIndex: '',
  currentlyDeleteCard: null,
  data: [],
  filter: {
    filter: false,
    searchText: false
  },
  searchData: {},
  searchLoader: false
}

const OfficeReducer: Reducer<Office> = (
  state: Office = initialState,
  action
) => {
  switch (action.type) {
    case Actions.officeAddNewBoard:
      return {
        ...state,
        data: addNewBoard(state, action.payload.index, action.payload.newData)
      }
    case Actions.officeGetBoardData:
      return {
        ...state,
        data: action.payload.data
      }
    case Actions.officeAddNewCard:
      return {
        ...state,
        data: addNewCard(
          state,
          action.payload.id,
          action.payload.index,
          action.payload.newData
        )
      }
    case Actions.officeSetNewIcon:
      return {
        ...state,
        data: setNewIcon(state, action.payload.id, action.payload.icon)
      }
    case Actions.officeSetNewDescription:
      return {
        ...state,
        data: setNewDescription(
          state,
          action.payload.id,
          action.payload.description
        )
      }
    case Actions.officeSetNewBoardName:
      return {
        ...state,
        data: setNewBoardName(
          state,
          action.payload.id,
          action.payload.boardName
        )
      }
    case Actions.officeDeleteCard:
      const { data } = state
      const feeData = data.slice()
      let index: string
      let temp: OfficeItemsDetails
      const callback = () => {
        return {
          ...state,
          boardId: action.payload.boardId,
          cardIndex: index,
          currentlyDeleteCard: temp,
          data: deleteCard(state, action.payload.boardId, action.payload.cardId)
        }
      }
      feeData.forEach((list: any) => {
        if (list.id === action.payload.boardId) {
          const newList = list.items.slice()
          const listIndex = newList.findIndex((laneItem: any) => {
            return laneItem.id === action.payload.cardId
          })
          index = listIndex
          temp = newList[listIndex]
          callback()
        }
      })
      return callback()
    case Actions.officeRestoreCard:
      return {
        ...state,
        data: restoreCard(
          state,
          state.boardId,
          state.cardIndex,
          state.currentlyDeleteCard
        )
      }
    case Actions.officeResetCardData:
      return {
        ...state,
        boardId: '',
        cardIndex: '',
        currentlyDeleteCard: null
      }
    case Actions.officeChangestatus:
      return {
        ...state,
        data: changeStatus(
          state,
          action.payload.boardId,
          action.payload.cardId,
          action.payload.status
        )
      }
    case Actions.officeFilter:
      return {
        ...state,
        filter: action.payload.data
      }
    case Actions.officeSearchLoader:
      return {
        ...state,
        searchLoader: action.payload.open
      }
    case Actions.officeSearchData:
      return {
        ...state,
        searchData: action.payload.data
      }
    default:
      return state
  }
}

export default OfficeReducer
