// Import Packages
import { History } from 'history'
import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, TransitionablePortal } from 'semantic-ui-react'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import { getGroups } from '../../Users/UserQueries'

import Colors from 'design/Colors'

// Import Components
import Customize from './Customize'
import { menuMockData, menuMockDataForAgent, menuMockDataForAgentInvite, menuMockDataForGuest } from './MenuMockData'
import StyledRoute from './Routes'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Dashboard/Actions'
import { CustomeColor, WallpaperImage } from 'store/Dashboard/Types'

// Import Utils
import { getLoggedInUser, Routes } from 'utils'

// Import Styled Components
import { ActiveRoute, Container, Dropdown, Logo, MenuContainer, Menus, StyledGrid } from './Styled'

import { faChevronDown, faFileAlt, faTachometerAltFast } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronDown, faTachometerAltFast, faFileAlt])

export let ShowCustomizeModal: any

interface Props extends RouteComponentProps<{}> {
  history: History
  setNewCustomColor: (newData: CustomeColor) => void
  setNewWallpaperImage: (newData: WallpaperImage) => void
}

interface State {
  showColorPickerModal: boolean
  showDropdown: boolean
  menus: any[]
  user: any
}

class Menu extends Component<Props, State> {
  public state = {
    menus: [],
    showColorPickerModal: false,
    showDropdown: false,
    user: {}
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    if (!user) {
      return
    }
    this.setState({ user })
    if (user.role === 'GUEST') {
      this.setState({ menus: menuMockDataForGuest })
    } else if (user.role === 'AGENT') {
      const { data }: any = await getGroups()
      if (data.getGroups[0].user) {
        this.setState({ menus: menuMockDataForAgentInvite })
      } else {
        this.setState({ menus: menuMockDataForAgent })
      }
    } else {
      this.setState({ menus: menuMockData })
    }
    this.assignCustomizeModal()
  }

  public render() {
    const { history } = this.props
    const { showColorPickerModal, showDropdown, menus, user } = this.state
    return (
      <MenuContainer>
        {showColorPickerModal && (
          <Customize
            closeModal={this.closeModal}
            addCustomeColor={this.addCustomeColor}
            addWallpaperImage={this.addWallpaperImage}
          />
        )}
        <Logo onClick={() => this.onChangeRoute('dashboard')} />
        <Container>
          <Dropdown onClick={this.handleClick}>
            <ActiveRoute>{history.location.pathname.replace('/', '').split('/')[0]}</ActiveRoute>
            <FontAwesomeIcon icon={['fal', 'chevron-down']} />
          </Dropdown>
          <TransitionablePortal onClose={this.handleClose} open={showDropdown}>
            {user[`role`] !== 'GUEST' ? (
              <StyledGrid style={{ width: 400 }} columns={3}>
                {menus.map((items: any, index: number) => (
                  <Grid.Column style={{ borderRight: `1px solid ${Colors.Black805}` }} textAlign="left" key={index}>
                    <Menus>{items.menu}</Menus>
                    <StyledRoute route={items.items} onChangeRoute={this.onChangeRoute} />
                  </Grid.Column>
                ))}
              </StyledGrid>
            ) : (
              <StyledGrid columns={3} style={{ width: 130 }}>
                {menus.map((items: any, index: number) => (
                  <Grid.Column textAlign="left" key={index}>
                    <Menus>{items.menu}</Menus>
                    <StyledRoute route={items.items} onChangeRoute={this.onChangeRoute} />
                  </Grid.Column>
                ))}
              </StyledGrid>
            )}
          </TransitionablePortal>
        </Container>
      </MenuContainer>
    )
  }

  private assignCustomizeModal = () => {
    ShowCustomizeModal = () => {
      this.setState({ showColorPickerModal: true })
    }
  }

  private addCustomeColor = (newData: any) => {
    const { setNewCustomColor } = this.props
    setNewCustomColor(newData)
  }

  private addWallpaperImage = (newData: any) => {
    const { setNewWallpaperImage } = this.props
    setNewWallpaperImage(newData)
  }

  private handleClose = () => {
    this.setState({ showDropdown: false })
  }

  private handleClick = () => {
    const { showDropdown } = this.state
    this.setState({ showDropdown: !showDropdown })
  }

  private closeModal = () => {
    this.setState({ showColorPickerModal: false })
  }

  private onChangeRoute = async (route: string) => {
    const { user } = this.state
    if (route === 'profile') {
      this.props.history.push(Routes.primary[route].path + `/${user[`_id`]}`)
    } else if (route === 'Documents' || route === 'Links') {
      this.props.history.push(Routes.primary['profile'].path + `/${user[`_id`]}?tab=${route}`)
    } else {
      this.props.history.push(Routes.primary[route].path)
    }
    this.setState({ showDropdown: false })
  }
}

const mapStateToProps = (state: AppState) => ({
  userProfile: state.dashboard.userProfile
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setNewCustomColor: Actions.addCustomeColor,
      setNewWallpaperImage: Actions.addWallpaperImage
    }
  )(Menu)
)
