import * as React from 'react'
import { Icon } from 'semantic-ui-react'

import { Strings } from 'utils'

import { Col, Container, PerPage, StyledDropdown, StyledPagination, TotalPage } from './Styled'

interface Props {
  onChange: (data: number) => void
  handlePagination: (data: any) => void
  addList: () => void
  rowData: any
  setPageNumber: (activePage: any) => void
  page: number
  perPage: number
}

interface State {
  limit: number
  pageNumber: number
  totalPages: number
}

const options = [
  { key: 1, text: '10', value: 10 },
  { key: 2, text: '25', value: 25 },
  { key: 3, text: '50', value: 50 },
  { key: 4, text: '100', value: 100 }
]

class CustomPaging extends React.Component<Props, State> {
  public state = {
    limit: 10,
    pageNumber: 1,
    totalPages: 0
  }

  public componentDidMount() {
    this.setState({ pageNumber: this.props.page, limit: this.props.perPage }, () => {
      this.handlePaginationChange()
    })
  }

  public render() {
    const { rowData } = this.props
    const { limit } = this.state
    const totalPages = Math.ceil(rowData.length / this.state.limit)
    return (
      <React.Fragment>
        {rowData &&
          rowData.length > 0 && (
            <Container>
              <Col primary={true}>
                <StyledDropdown onChange={this.OnChangePrePage} options={options} selection={true} value={limit} />
                <PerPage>{Strings.kanbanView.recordsPerPage}</PerPage>
                <TotalPage>
                  {`${Strings.kanbanView.show}
                ${this.state.pageNumber === 1 ? this.state.pageNumber : this.state.pageNumber * limit - (limit - 1)} to
                ${this.state.pageNumber === totalPages ? rowData.length : this.state.pageNumber * limit}
                ${Strings.kanbanView.of}
                ${rowData.length} ${Strings.kanbanView.entries}`}
                </TotalPage>
              </Col>
              <Col>
                <StyledPagination
                  activePage={this.state.pageNumber}
                  onPageChange={this.handlePaginationChangess}
                  size="mini"
                  totalPages={totalPages}
                  firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                  lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true }}
                  nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
              </Col>
            </Container>
          )}
      </React.Fragment>
    )
  }

  private handlePaginationChange = () => {
    const { rowData, handlePagination } = this.props
    const last = this.state.pageNumber * this.state.limit
    const start = last - this.state.limit
    const currentPage = rowData.slice(start, last)
    const totalPage = Math.ceil(rowData.length / this.state.limit)
    this.setState({ totalPages: totalPage }, () => handlePagination(currentPage))
  }

  private OnChangePrePage = (event: React.SyntheticEvent<HTMLDivElement>, data: any) => {
    const { onChange } = this.props
    this.setState({ pageNumber: 1, limit: data.value }, () => this.handlePaginationChange())
    onChange(data.value)
  }

  private handlePaginationChangess = (e: any, { activePage }: any) => {
    const { setPageNumber } = this.props
    this.setState({ pageNumber: activePage }, () => {
      setPageNumber(activePage)
      this.handlePaginationChange()
    })
  }
}

export default CustomPaging
