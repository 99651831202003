import gql from 'graphql-tag'

export const CREATE_LEAD = gql`
  mutation createLead(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $propertyType: String
    $source: String
    $stateBoard: String
    $stateBoardOrder: Int
    $tenantBoard: String
    $tenantBoardOrder: Int
    $board: String
    $boardOrder: Int
    $type: String
    $state: String
    $city: String
    $zipCode: String
    $referralFee: Float
  ) {
    createLead(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        propertyType: $propertyType
        source: $source
        stateBoard: $stateBoard
        stateBoardOrder: $stateBoardOrder
        tenantBoard: $tenantBoard
        tenantBoardOrder: $tenantBoardOrder
        board: $board
        boardOrder: $boardOrder
        type: $type
        state: $state
        city: $city
        zipCode: $zipCode
        referralFee: $referralFee
      }
    ) {
      _id
      firstName
      lastName
      email
      phone
      propertyType
      createdAt
      lastAction
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      zipCode
      owner {
        _id
        firstName
        lastName
      }
      reassignHistory {
        userId {
          _id
          firstName
          lastName
          emails {
            value
          }
          phones {
            value
          }
        }
        date
      }
      leadScore
      createdAt
      referralFee
      zipCode
      tag
      isActive
      source
      status
      type
    }
  }
`

export const UPDATE_LEAD = gql`
  mutation updateLead(
    $leadId: String
    $status: String
    $isActive: Boolean
    $score: Int
  ) {
    updateLead(
      where: { _id: $leadId }
      input: { status: $status, isActive: $isActive, leadScore: $score }
    ) {
      _id
      firstName
      lastName
      email
      phone
      propertyType
      createdAt
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      owner {
        _id
        firstName
        lastName
      }
      reassignHistory {
        userId {
          _id
          firstName
          lastName
          emails {
            value
          }
          phones {
            value
          }
        }
        date
      }
      notes {
        description
      }
      leadScore
      createdAt
      lastAction
      referralFee
      zipCode
      tag
      isActive
      source
      status
      type
    }
  }
`

export const DELETE_LEAD = gql`
  mutation deleteLead($leadId: [String]) {
    deleteLead(_id: $leadId)
  }
`

export const UPDATE_LEAD_ORDER = gql`
  mutation updateLeadOrder(
    $board: String
    $cardId: String
    $myBoardSourceOrder: Int
    $myBoardDestinationOrder: Int
  ) {
    updateLeadOrder(
      where: { _id: $cardId, board: $board, boardOrder: $myBoardSourceOrder }
      input: { boardOrder: $myBoardDestinationOrder }
    ) {
      _id
      firstName
      lastName
      email
      phone
      propertyType
      createdAt
      lastAction
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      reassignHistory {
        userId {
          _id
          firstName
          lastName
          emails {
            value
          }
          phones {
            value
          }
        }
        date
      }
      leadScore
      createdAt
      referralFee
      zipCode
      tag
      isActive
      source
      status
      type
    }
  }
`

export const TRANSFER_LEAD = gql`
  mutation transferLead($where: LeadFilter, $input: LeadInput) {
    transferLead(where: $where, input: $input) {
      _id
      firstName
      lastName
      email
      phone
      propertyType
      createdAt
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      owner {
        _id
        firstName
        lastName
      }
      reassignHistory {
        userId {
          _id
          firstName
          lastName
          emails {
            value
          }
          phones {
            value
          }
        }
        date
      }
      notes {
        description
      }
      leadScore
      createdAt
      lastAction
      referralFee
      zipCode
      tag
      isActive
      source
      status
      type
    }
  }
`

export const REASSIGN_LEAD = gql`
  mutation reassignLead(
    $where: LeadFilter
    $input: LeadInput
    $userParams: UserFilter
  ) {
    reassignLead(where: $where, input: $input, userParams: $userParams) {
      _id
      firstName
      lastName
      email
      phone
      propertyType
      createdAt
      lastAction
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      owner {
        _id
        firstName
        lastName
      }
      reassignHistory {
        userId {
          _id
          firstName
          lastName
          emails {
            value
          }
          phones {
            value
          }
        }
        date
      }
      leadScore
      createdAt
      referralFee
      zipCode
      tag
      isActive
      source
      status
      type
    }
  }
`

export const IMPORT_LEADS = gql`
  mutation importLeads(
    $board: String
    $stateBoard: String
    $tenantBoard: String
    $file: Upload
  ) {
    importLeads(
      input: {
        board: $board
        stateBoard: $stateBoard
        tenantBoard: $tenantBoard
      }
      file: $file
    )
  }
`

export const SHARE_WITH = gql`
  mutation shareWith(
    $leadId: String
    $userId: ID
    $firstName: String
    $lastName: String
    $email: String
    $type: String
  ) {
    sharedWith(
      where: { _id: $leadId }
      userParams: {
        _id: $userId
        firstName: $firstName
        lastName: $lastName
        userName: $email
        type: $type
      }
    ) {
      _id
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
        status
      }
    }
  }
`

export const DELETE_SHARE_WITH = gql`
  mutation deleteShareWith($leadId: String, $userId: ID) {
    deleteSharedWith(where: { _id: $leadId }, userParams: { _id: $userId }) {
      _id
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
        status
      }
    }
  }
`

export const CREATE_LEAD_NOTES = gql`
  mutation createLeadNote($leadId: String, $description: String) {
    createNote(input: { lead: $leadId, description: $description }) {
      _id
      description
      lead {
        firstName
        lastName
      }
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const EDIT_LEAD_NOTES = gql`
  mutation editLeadNote($leadNoteId: String, $description: String) {
    updateNote(
      where: { _id: $leadNoteId }
      input: { description: $description }
    ) {
      _id
      description
      lead {
        firstName
        lastName
      }
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const DELETE_LEAD_NOTES = gql`
  mutation deleteLeadNote($leadNoteId: String) {
    deleteNote(_id: $leadNoteId)
  }
`
