import * as React from 'react'

import KanbanLaneContent from './KanbanLaneContent'
import KanbanLaneFooter from './KanbanLaneFooter'
import KanbanLaneHeader from './KanbanLaneHeader'

import { Container, Price } from './Styled'

import { faDollarSign } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faDollarSign])

interface Props {
  ActionComponent: React.ReactType | null
  addNewCard: (id: string, index: string) => void
  appearance?: boolean
  CardComponent: React.ReactType
  color: string
  counterIcon?: string
  counterItems?: number
  disableDrop?: boolean
  icons: any[]
  index: number
  item?: any
  navigatePage?: (skip: number) => void
  price?: number
  setNewBoardName?: (id: string, boardName: string) => void
  setNewDescription?: (id: string, description: string) => void
  setNewIcon?: (id: string, icon: string) => void
}

interface State {
  colorSet: string
}

export default class Lane extends React.Component<Props, State> {
  public state = {
    colorSet: ''
  }

  public componentDidMount() {
    const { color } = this.props
    this.setState({ colorSet: color })
  }

  public render() {
    const {
      ActionComponent,
      appearance,
      CardComponent,
      color,
      counterIcon,
      counterItems,
      disableDrop,
      icons,
      item,
      price
    } = this.props
    const {
      item: { pagination },
      navigatePage
    } = this.props
    const { colorSet } = this.state
    return (
      <Container color={colorSet ? colorSet : color}>
        <KanbanLaneHeader
          ActionComponent={ActionComponent}
          appearance={appearance}
          boardId={item._id}
          color={colorSet ? colorSet : color}
          description={item.description}
          icon={item.icon}
          icons={icons}
          counterIcon={counterIcon}
          counterItems={counterItems}
          itemsLength={(pagination && pagination.count) || item.items.length}
          setColor={this.setColor}
          setNewBoardName={this.setNewBoardName}
          setNewDescription={this.setNewDescription}
          setNewIcon={this.setNewIcon}
          showAddButton={false}
          showAppearance={item.showAppearance}
          title={item.name}
        />
        {price && (
          <Price color={colorSet ? colorSet : color}>
            <FontAwesomeIcon icon={['far', 'dollar-sign']} />
            {Number(price).toLocaleString()}
          </Price>
        )}
        <KanbanLaneContent
          addNewCard={this.addNewCard}
          boardId={item._id}
          CardComponent={CardComponent}
          color={colorSet ? colorSet : color}
          content={item.items}
          droppableId={item._id}
          disableDrop={disableDrop}
          itemsLength={item.items.length}
          showAddButton={false}
        />
        <KanbanLaneFooter
          color={colorSet ? colorSet : color}
          itemsLength={item.items.length}
          boardId={item._id}
          addNewCard={this.addNewCard}
          showAddButton={false}
          pagination={pagination}
          navigatePage={navigatePage}
        />
      </Container>
    )
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    if (setNewBoardName) {
      setNewBoardName(id, boardName)
    }
  }

  private setColor = (color: string) => {
    this.setState({ colorSet: color })
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    if (setNewDescription) {
      setNewDescription(id, description)
    }
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    if (setNewIcon) {
      setNewIcon(id, icon)
    }
  }

  private addNewCard = (id: string, index: string) => {
    const { addNewCard } = this.props
    addNewCard(id, index)
  }
}
