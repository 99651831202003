import Colors from 'design/Colors'
import FreeIcon from 'design/icons/settings/img4.png'
import * as React from 'react'
import { Button } from 'semantic-ui-react'
import { passesMockData } from './PassesMockData'
import {
  ActivatedBy,
  Container,
  Description,
  Free,
  Image,
  Info,
  PassName,
  ScrollY,
  StyledCard,
  StyledGrid,
} from './Styled'

class Integration extends React.Component {
  public state = {}

  public render() {

    return (
      <Container>
        <ScrollY>
          <StyledGrid>
            {passesMockData.map((items: any, index: number) => (
              <StyledCard key={index}>
                <Free background={FreeIcon} />
                <Image
                  background={items.passImage}
                  style={{ backgroundSize: items.passName === 'IDX MLS' ? 'cover' : 'auto' }}
                />
                <Info>
                  <PassName>{items.passName}</PassName>
                  <Description>{items.description}</Description>
                  <div className='key'>
                    <Button style={{
                      background: items.status === 'Activated' ? Colors.Green215 : Colors.Grey50,
                      color: Colors.White1000
                    }}>
                      {items.status}
                    </Button>
                    {items.status === 'Activated' && (
                      <Button className='key-button'>Key</Button>
                    )}
                  </div>
                  <ActivatedBy>{items.activatedBy}</ActivatedBy>
                </Info>
              </StyledCard>
            ))}
          </StyledGrid>
        </ScrollY>
      </Container>
    )
  }

}

export default Integration
