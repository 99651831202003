// Import Packages
import { Label, Popup } from 'semantic-ui-react'
import { css } from 'styled-components'
import styled from 'styled-components'

// Import Fonts, Colors
import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { y } from 'design/Styled'

interface Props {
  background: string
  progress?: string
  left?: number
  color?: string
  important?: boolean
}

const textStyle = css`
  color: ${Colors.Black500};
  margin: 0;
  line-height: 1.5;
`

const text = css`
  font: ${fonts.small.regular};
  ${textStyle};
`

const mediumText = css`
  font: ${fonts.xSmall.regular};
  ${textStyle};
`

const smallText = css`
  font: ${fonts.xxSmall.regular};
  ${textStyle};
`

const nowrapText = css`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const padX = css`
  padding-right: 1em;
  padding-left: 1em;
`

const padY = css`
  padding-top: 0.8em;
  padding-bottom: 0.8em;
`

const row = css`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;

  & > :first-child {
    margin-left: 0;
  }
  & > :last-child {
    margin-right: 0;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  padding: 0;
  border-radius: 0px;
  width: 95%;
  background: ${Colors.White1000};
  border: 1px solid ${Colors.Blue910};
  box-shadow: 0 1px 3px 0 ${Colors.CardShadow}, 0 0 0 1px ${Colors.CardShadow};
`

export const Card = styled.div`
  ${row} &:hover .sidebar {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    box-shadow: 16px 0 16px 5px ${Colors.Black50};
  }
`

export const Col = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 0.8em;
  margin-right: 0.8em;
`

export const Envelope = styled(Col)`
  overflow: hidden;
`

export const From = styled.h1`
  ${text} ${nowrapText}
  font-weight: bold;
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

export const Subject = styled.h2`
  ${mediumText} ${nowrapText}
  font-style: italic;
`

export const Preview = styled.p`
  ${smallText};
`

export const Timestamp = styled.span`
  ${mediumText} width: 100%;
  padding-top: 5px;
  text-align: center;
`

export const Footer = styled.footer`
  ${row}
  ${text}
  ${padX}
  width: 98%;
  padding-bottom: 1em;
  justify-content: space-between;
  flex: 1;

  :before {
    content: '';
    display: block;
    width: 100%;
    margin: 0 auto;
    background: ${Colors.Black905};
    height: 1px;
    margin-bottom: 1em;
  }
`

export const Ul = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
`

export const Li = styled.li`
  width: 12px;
  list-style: none;
  display: inline-block;
  padding: '0';
`

export const Notes = styled(Label)`
  &.ui.circular.label,
  .ui.circular.labels .label {
    min-width: 1em;
    min-height: 1em;
    line-height: 0.5em;
    margin-left: 5px;
    height: 18px;
  }

  &.ui.label {
    background: ${Colors.Red810};
    color: ${Colors.White1000};
    font-style: normal;
  }
`

export const StyledNotes = styled.div`
  display: flex;
  font-style: italic;
  font-size: 11px;
  cursor: pointer;
  margin-top: 4px;
`

export const ContentRow = styled.div`
  ${row}
  ${padX}
  ${padY}
  flex-direction: column;
`

export const Sidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  width: 90px;
  height: 100%;
  background: ${Colors.White800};
  transition: all 200ms;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  box-shadow: 0 0 16px 5px ${Colors.transparent};
`

export const Member = styled.div`
  display: flex;
  position: relative;
  margin-left: 6px;
`

export const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  position: absolute;
  left: ${(props: Props) => props.left || props.left}px;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

export const CircleIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${Colors.White470};
  border-radius: 50%;
  text-align: -webkit-center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .fa-envelope {
    color: ${Colors.Red20};
  }

  .fa-sms {
    color: ${Colors.Pink70};
  }

  .fa-phone {
    color: ${Colors.Blue50};
    transform: rotate(100deg);
  }
`

export const StyledProgress = styled.div`
  margin-top: -16px;
  .fa-minus {
    color: ${(props: Props) => props.background || props.background};
  }
`

export const RelatedTo = styled.div`
  font-size: 12px;
  font-style: italic;
  text-transform: capitalize;
  display: flex;
`

export const TaskDecription = styled.div`
  font-size: 11px;
`

export const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 1px;
  text-transform: capitalize;
`

export const Labels = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
  position: absolute;
`

export const StyledLabel = styled.div`
  ${y};
  height: 100%;
  color: ${Colors.DarkBlue220};
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`

export const StyledIcon = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  align-self: center;
  margin-top: 6px;
`

export const DueRow = styled.div`
  ${row} justify-content: space-between;
  align-items: flex-start;
`

export const DueDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 600;

  span {
    font-size: 10px;
    font-style: italic;
    line-height: 1;
    padding: ${props => (props.important ? `5px 8px` : `5px 0`)};
    color: ${props => props.color || props.color};
    background: ${(props: Props) => props.background || props.background};
  }

  &:after {
    content: '';
    display: block;
    position: relative;
    margin: 7px 0;
    width: 16px;
    height: 3px;
    background: ${props => props.progress || props.progress};
  }
`
