// Import Packages
import * as React from 'react'
import { Button, Grid } from 'semantic-ui-react'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Option,
  StyledGrid,
} from './Styled'

// Font Awesome Icons
import {
  faFileExport,
  faPrint
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPrint, faFileExport])

interface Props {
  closeGridView: () => void
  exportCSV: () => void
  resetData: () => void
  print: () => void
  module: string
}

// const exportOptions = [
//   { key: 1, text: 'Choice 1', value: 1 },
//   { key: 2, text: 'Choice 2', value: 2 }
// ]

class HeaderOption extends React.Component<Props> {
  public state = {}

  public render() {
    const { exportCSV, module } = this.props
    return (
      <StyledGrid>
        <Grid.Row>
          <Grid.Column width={10}>
            {`${module} ${Strings.reports.transaction}`}
          </Grid.Column>
          <Grid.Column width={6}>
            <Option onClick={this.props.print}>
              <FontAwesomeIcon icon={['fal', 'print']} />
              {Strings.reports.print}
            </Option>
            <Option className='export' onClick={exportCSV}>
              <FontAwesomeIcon icon={['fal', 'file-export']} />
              <div>Export CSV File</div>
              {/* <Dropdown text='Export' options={exportOptions} /> */}
            </Option>
            <Option>
              <Button onClick={this.closeGridView}>{Strings.reports.back}</Button>
            </Option>
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    )
  }

  private closeGridView = () => {
    const { closeGridView, resetData } = this.props
    closeGridView()
    resetData()
  }
}

export default HeaderOption
