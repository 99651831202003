// Import Packages
import * as React from 'react'
import { Button, Form, Input } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import { EditFileFolder } from './Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Header,
  StyledForm
} from './Styled'

interface Props {
  handleClose: (toggle: boolean) => void
  addFolder: (name: string) => void
  updateFileFolder: (newKey: string) => void
  editFileFolder: EditFileFolder
  edit: boolean
}

interface State {
  result: string
  animate: boolean
  error: boolean
  extension: string | undefined
}

export default class AddFolderModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    error: false,
    extension: '',
    result: ''
  }

  public componentDidMount() {
    const { edit, editFileFolder } = this.props
    if (edit) {
      if (editFileFolder.type === 'FILE') {
        const fileNameLength = editFileFolder.fileName.lastIndexOf('.')
        const fileName = editFileFolder.fileName.slice(0, fileNameLength)
        const extension = editFileFolder.fileName.split(/[. ]+/).pop()
        this.setState({ result: fileName, extension })
      } else {
        this.setState({ result: editFileFolder.fileName })
      }
    }
  }

  public handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ result: e.target.value, error: false })
  }

  public saveFolder = () => {
    const { addFolder } = this.props
    const { result } = this.state
    if (result) {
      addFolder(result)
      this.closeModal()
    } else {
      this.setState({ error: true })
    }
  }

  public updateFileFolder = () => {
    const { editFileFolder, updateFileFolder } = this.props
    const { result, extension } = this.state
    if (result) {
      let newKey
      if (editFileFolder.type === 'FILE') {
        newKey = editFileFolder.key.replace(editFileFolder.fileName, `${result}.${extension}`)
      } else {
        newKey = editFileFolder.key.replace(editFileFolder.fileName, result)
      }
      updateFileFolder(newKey)
      this.closeModal()
    } else {
      this.setState({ error: true })
    }
  }

  public render() {
    const { editFileFolder, edit } = this.props
    const { animate, error, result } = this.state
    return (
      <Modal content={
        <div>
          <Header>
            {edit ? editFileFolder.type === 'FOLDER' ? Strings.generalText.addFolderName : Strings.generalText.addFolderFile : Strings.generalText.addFolder}
          </Header>
          <StyledForm size={'mini'}>
            <Form.Field
              control={Input}
              name="folder"
              value={result}
              label={edit ? editFileFolder.type === 'FOLDER' ? 'Folder Name' : 'File Name' : 'Folder Name'}
              placeholder={edit ? editFileFolder.type === 'FOLDER' ? 'Folder Name' : 'File Name' : 'Folder Name'}
              onChange={this.handleInputChange}
              error={error} />
            {
              edit ? <Form.Field
                control={Button}
                className='save-button'
                onClick={this.updateFileFolder}
                content={'UPDATE'} /> :
                <Form.Field
                  control={Button}
                  className='save-button'
                  onClick={this.saveFolder}
                  content={'SAVE'} />
            }

          </StyledForm>
        </div>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={350} />
    )
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose(false)
    }, 300)
  }
}
