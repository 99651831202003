import { FormErrorType, FormType } from './Types'

const validName = /^[a-z]([ '-]?[a-z]){1,24}$/i
const validEmail = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.?)+\.[a-zA-Z]{2,}$/

export const handlePoolValidation = (form: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!form.state) {
    formIsValid = false
    errors.state = 'Please select a state.'
  }

  if (!form.referralFee) {
    formIsValid = false
    errors.referralFee = 'Please add a referral fee amount.'
  }

  return { errors, formIsValid }
}

export const handleDirectValidation = (form: FormType) => {
  const errors = {} as FormErrorType
  let formIsValid = true

  if (!form.firstName || !form.firstName.match(validName)) {
    formIsValid = false
    errors.firstName = 'Please enter a valid first name.'
  }

  if (!form.lastName || !form.lastName.match(validName)) {
    formIsValid = false
    errors.lastName = 'Please enter a valid last name.'
  }

  if (!form.email || !form.email.match(validEmail)) {
    formIsValid = false
    errors.email = 'Please enter a valid email address.'
  }

  if (!form.referralFee) {
    formIsValid = false
    errors.referralFee = 'Please add a referral fee amount.'
  }

  return { errors, formIsValid }
}
