// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Button, Form, Icon, Input } from 'semantic-ui-react'

// Import Store Types and Actions
import { Owner, UserType } from 'store/Contacts/Types'
import { LeadNotes } from 'store/Leads/Types'
import { handleValidation } from './Validation'

import { ErrorType } from './Types'

// Import Styled Components
import { Container, Date, Description, Profile, StyledForm, StyledNotes } from './Styled'

interface Props {
  addNewNotes: (note: string) => void
  editNotes: (id: string, note: string) => void
  deleteNotes: (id: string) => void
  notesData: LeadNotes[]
  contactOwner: Owner
  loader: boolean
  user: UserType
}

interface State {
  id: string
  note: string
  showEditButton: boolean
  showConfirmationModal: boolean
  errors: ErrorType
}

class Notes extends React.Component<Props, State> {
  public state = {
    errors: {} as ErrorType,
    id: '',
    note: '',
    showConfirmationModal: false,
    showEditButton: false
  }

  public render() {
    const { notesData, contactOwner, loader, user } = this.props
    const { note, showEditButton, errors } = this.state
    const notes = notesData.map((items: any, index: any) => {
      const editNotesFunction = () => {
        this.setState({ id: items._id, note: items.description, showEditButton: true })
      }
      const deleteNotesFunction = () => {
        this.deleteNotes(items._id)
      }
      return (
        <Container key={index}>
          <Profile background={items.createdBy.profileImage} />
          <div>
            <Description>{items.description}</Description>
            <Date>
              <div style={{ flexGrow: 1 }}>{moment(items.createdAt).format('ll')}</div>
              <div
                style={{
                  marginRight: '10px',
                  display: user._id === items.createdBy._id || user._id === contactOwner._id ? 'block' : 'none'
                }}
              >
                <Icon name="pencil" onClick={editNotesFunction} />
                <Icon name="trash" onClick={deleteNotesFunction} />
              </div>
            </Date>
          </div>
        </Container>
      )
    })
    return (
      <div style={{ paddingLeft: 30 }}>
        <StyledForm size={'small'}>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              name="note"
              placeholder="Add a Note"
              onChange={this.handleChange}
              value={note}
              error={errors[`note`]}
            />
            {loader ? (
              <Form.Field control={Button} loading={true} content="LOADING" />
            ) : showEditButton ? (
              <Form.Field control={Button} content="EDIT" onClick={this.editNotes} />
            ) : (
              <Form.Field control={Button} content="SUBMIT" onClick={this.saveNotes} />
            )}
          </Form.Group>
        </StyledForm>
        <StyledNotes>{notes}</StyledNotes>
      </div>
    )
  }

  private deleteNotes = (id: string) => {
    const { deleteNotes } = this.props
    deleteNotes(id)
  }

  private editNotes = () => {
    const { editNotes } = this.props
    const { id, note } = this.state
    const result = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      editNotes(id, note)
      this.setState({ id: '', note: '', showEditButton: false })
    }
  }

  private handleChange = (e: any, { name, value }: any) => {
    this.setState({ note: value })
  }

  private saveNotes = () => {
    const { addNewNotes } = this.props
    const { note } = this.state
    const result = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      addNewNotes(note)
      this.setState({ id: '', note: '' })
    }
  }
}

export default Notes
