import client from 'queries/apollo'

import { CREATE_USER_PASS, UPDATE_USER_PASS } from 'queries/graphql/Passes/Mutation'

export const createUserPass = async (input: any) => {
  const response = await client.mutate({
    mutation: CREATE_USER_PASS,
    variables: { input }
  })

  return response.data.createUserPass
}

export const updateUserPass = async (id: string, input: any) => {
  const response = await client.mutate({
    mutation: UPDATE_USER_PASS,
    variables: { id, input }
  })

  return response.data.updateUserPass
}
