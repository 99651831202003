import * as React from 'react'
import { SortableContainer } from 'react-sortable-hoc'

import GridItem from './GridItem'

import { Container } from './Styled'

import { ActionEnum } from 'shared/ImageZone/ActionButtons/Types'
import { ImageType, PermissionEnum } from 'shared/ImageZone/Types'

interface Props {
  images: ImageType[]
  getPermission: (image: ImageType) => Promise<PermissionEnum>
  gridActions: string[]
  onAction: (action: ActionEnum, data: any) => void
}

interface State {
  disableSort: boolean
}

const GridArea = SortableContainer(({ disableSort, getPermission, gridActions, items, onAction }: any) => {
  return (
    <Container onClick={(e: any) => e.stopPropagation()}>
      {items.map((item: ImageType, index: number) => (
        <GridItem
          key={item._id}
          index={index}
          image={item}
          disableSort={disableSort}
          actions={gridActions}
          onAction={onAction}
          getPermission={getPermission}
        />
      ))}
    </Container>
  )
})

class Images extends React.Component<Props, State> {
  public state = {
    disableSort: false
  }

  public componentDidMount = () => {
    const { gridActions } = this.props
    this.setState({ disableSort: !gridActions.includes('sort') })
  }

  public render = () => {
    const { getPermission, gridActions, images, onAction } = this.props

    const { disableSort } = this.state

    return (
      <GridArea
        axis={'xy'}
        disableSort={disableSort}
        getPermission={getPermission}
        gridActions={gridActions}
        helperClass={'active-sort-item'}
        items={images}
        lockToContainerEdges={true}
        onAction={onAction}
        onSortEnd={this.handleSortEnd}
        onSortStart={this.handleSortStart}
        pressDelay={100}
      />
    )
  }

  private handleSortEnd = (data: any) => {
    const { onAction } = this.props
    onAction(ActionEnum.SortEnd, data)
  }

  private handleSortStart = (data: any, e: any) => {
    const { onAction } = this.props
    onAction(ActionEnum.SortStart, null)

    window.requestAnimationFrame(() => {
      const element = document.getElementsByClassName('active-sort-item').item(0)
      if (element) {
        element.className += ' sorting'
      }
    })
  }
}

export default Images
