import { Dropdown } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, x } from 'design/Styled'

interface Props {
  background?: string
  type?: string
}

const pill = css`
  ${x};
  ${children};
  width: auto;
  margin: 0;
  padding: 5px 8px;
  color: ${Colors.Blue50};
  background: ${Colors.Container};
  border-radius: 15px;
  font: ${fonts.xxSmall.medium};
  text-transform: uppercase;
`

export const Container = styled.header`
  width: 100%;
  position: relative;

  .slick-slider,
  .slick-list {
    width: 100%;
    height: 100%;
    outline: none;
  }
  .slick-track,
  .slick-slide div {
    height: 100%;
    outline: none;
  }
`

export const ImageContainer = styled.div`
  display: block;
  width: 100%;
`

export const Slide = styled.div`
  display: block !important;
  width: 100%;
  background-image: url(${(props: Props) => props.background || ''});
  background-size: cover;
  background-position: center;

  &:after {
    content: '';
    display: block;
    padding-bottom: 75%;
  }
`

export const FixedRow = styled.div`
  ${x};
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.8em;
  margin: 0;
`

export const Role = styled.div`
  ${pill};
`

export const Tag = styled.div`
  ${pill};
  background: ${Colors.ButtonPrimary};
  color: ${Colors.TextLight};
`

export const StatusDropdown = styled(Dropdown)`
  ${pill};
  background: ${Colors.Red825};
  &.ui.inline.dropdown {
    color: ${Colors.TextLight};
  }
`

// export const ToolGroup = styled(Button.Group)`
//   &.ui.buttons {
//     width: 100%;
//     border-radius: 0;
//     border: 0;
//     position: absolute;
//     bottom: 0;
//   }
// `
//
// export const Tool = styled(Button)`
//   &.ui.button {
//     font-size: 14px !important;
//     padding: 0.6em !important;
//     background: rgba(255,255,255,0.7) !important;
//     border-radius: 0 !important;
//
//     &:hover {
//       background: rgba(255,255,255,0.9) !important;
//     }
//   }
// `
//
// export const ToolTip = styled(Popup)`
//   &.ui.popup {
//     ${toolTip};
//     font-size: 0.9em;
//     opacity: 1;
//   }
// `
