import styled from 'styled-components'

import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${Colors.Container};
  box-shadow: 0 0 9px -3px ${Colors.CardShadow};
  padding: 20px 50px;

  .rbc-overlay {
    width: 400px;
    min-width: 400px !important;
    overflow: auto;
    height: 30%;
    ${scrollbars};
  }

  .rbc-event {
    color: ${Colors.Text};
    font-size: 14px;
    padding: 10px;
    background: #f0f3f8 !important;
  }

  .rbc-day-slot .rbc-event {
    border: none;
  }

  .rbc-event.rbc-selected {
    background-color: #f0f3f8 !important;
    outline: none;
  }

  .rbc-month-view {
    height: auto;
  }

  .rbc-day-slot .rbc-event{
    display: inline-block;
    padding: 0px;
    padding-left: 10px;
    min-height: 2%;
  }

  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 400px;
    margin-left: 5px;
    padding-bottom: 2px;
  }
`

export {
  Wrapper
}
