import Colors from 'design/Colors'
import * as React from 'react'
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { Dropdown, Icon } from 'semantic-ui-react'
import { Strings } from 'utils'
import {
  DropDownPopup,
  StyledContent,
  StyledDropdown,
  Types,
} from './Styled'

import { UserType } from 'store/Settings/WorkflowManager/Types'

// Font Awesome Icons
import {
  faUser,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'
import {
  faBuilding,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBars,
  faEllipsisV,
  faSortDown
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { reorderWorkflow } from '../../WorkflowManagerMutations'
import { getWorkflowCategoryTasks } from '../WorkflowManagerQueries'
AddIconToLibrary([faBars, faBuilding, faEllipsisV, faUser, faUsers, faSortDown])

interface Props {
  categories: any
  category: string
  loggedUser: UserType
  alterData: (data: any, id: string) => void
  deleteWorkflow: (category: string, id: string) => void
  editWorkflow: (categoryId: string, data: any) => void
  getTaskData: (data: any) => void
  getWorkflow: (id: string, name: string) => void
}

const DragHandle = SortableHandle(() => <FontAwesomeIcon icon={['fas', 'bars']} />)

const SortableItem = SortableElement(({ items, category, editWorkflow, deleteWorkflow, onActive, activeItem, viewWorkflow, loggedUser, categoryId }: any) => {
  let visibility
  switch (items.visibility) {
    case 'Team':
      visibility = <FontAwesomeIcon icon={['fal', 'users']} />
      break
    case 'Office':
      visibility = <FontAwesomeIcon icon={['far', 'building']} />
      break
    case 'Individual':
      visibility = <FontAwesomeIcon icon={['fal', 'user']} />
      break
    default:
      visibility = <FontAwesomeIcon icon={['fal', 'users']} />
      break
  }

  const onViewWorkflow = () => {
    viewWorkflow(items._id)
  }

  const onDeleteWorkflow = () => {
    deleteWorkflow(category, items._id)
  }

  const onEditWorkflow = () => {
    editWorkflow(categoryId, items)
  }

  return (
    <StyledContent onClick={() => onActive(items._id, items.name)} background={activeItem === items._id ? Colors.White535 : Colors.White1000}>
      {loggedUser[`role`] === 'ADMIN' && <DragHandle />}
      <Types>{items.name}</Types>
      {visibility}
      <StyledDropdown>
        <DropDownPopup
          content={
            <div className='action-drop-down'>
              <Dropdown.Menu>
                <Dropdown.Item onClick={onViewWorkflow}>
                  {Strings.tasksSetting.viewWorkflow}
                </Dropdown.Item>
                <Dropdown.Item onClick={onEditWorkflow}>
                  {Strings.tasksSetting.edit}
                </Dropdown.Item>
                <Dropdown.Item onClick={onDeleteWorkflow}>
                  {Strings.tasksSetting.deleteWorkflow}
                </Dropdown.Item>
              </Dropdown.Menu>
            </div>
          }
          trigger={
            <Icon style={{ cursor: 'pointer', color: 'black' }} name='caret down' />
          }
          hoverable={true}
          position='bottom left' />
      </StyledDropdown>
    </StyledContent>
  )
})

const SortableList = SortableContainer(({ items, category, editWorkflow, deleteWorkflow, onActive, activeItem, viewWorkflow, loggedUser }: any) => {
  return (
    <div className='dev'>
      {items.workflows.map((value: any, index: any) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          items={value}
          categoryId={items._id}
          category={category}
          editWorkflow={editWorkflow}
          deleteWorkflow={deleteWorkflow}
          onActive={onActive}
          activeItem={activeItem}
          viewWorkflow={viewWorkflow}
          loggedUser={loggedUser} />
      ))}
    </div>
  )
})

class Contents extends React.Component<Props> {
  public state = {
    activeItem: ''
  }

  public render() {
    const { categories, category, deleteWorkflow, loggedUser, editWorkflow } = this.props
    const { activeItem } = this.state
    return (
      <SortableList
        items={categories}
        category={category}
        editWorkflow={editWorkflow}
        deleteWorkflow={deleteWorkflow}
        onSortEnd={this.onSortEnd}
        useDragHandle={true}
        onActive={this.onActive}
        activeItem={activeItem}
        viewWorkflow={this.viewWorkflow}
        loggedUser={loggedUser} />
    )
  }

  private onActive = async (id: string, name: string) => {
    const { getTaskData, getWorkflow } = this.props
    getWorkflow(id, name)
    const workflowTask = await getWorkflowCategoryTasks(id)
    getTaskData(workflowTask)
    this.setState({ activeItem: id })
  }

  private onSortEnd = ({ oldIndex, newIndex }: any) => {
    const { alterData, categories } = this.props
    const items = arrayMove(categories.workflows, oldIndex, newIndex)
    alterData(items, categories._id)
    const sortedData: any = []
    items.forEach((item: any, index: number) => {
      sortedData.push({
        _id: item._id,
        order: index + 1
      })
    })
    if (oldIndex !== newIndex) {
      reorderWorkflow(sortedData)
    }
  }

  private viewWorkflow = async (id: string) => {
    const { getTaskData } = this.props
    const workflowTask = await getWorkflowCategoryTasks(id)
    getTaskData(workflowTask)
    this.setState({ activeItem: id })
  }
}

export default Contents
