// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Button, Form, Icon, Input } from 'semantic-ui-react'

// Import Components
import { handleValidation } from './Validation'

// Import Store Types, Actions and Reducers
import { Messages } from 'store/Transactions/Types'
import { ErrorType } from './Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  Date,
  Description,
  Infograph,
  Profile,
  StyledForm,
  StyledNotes,
  StyledPopup,
  Wrapper
} from './Styled'

interface Props {
  addNewMessage: (note: string) => void
  editMessage: (id: string, note: string) => void
  deleteMessage: (id: string) => void
  messageData: Messages[]
  owner: string
  loader: boolean
}

interface State {
  id: string
  note: string
  showEditButton: boolean
  showConfirmationModal: boolean
  errors: ErrorType
}

class List extends React.Component<Props, State> {
  public state = {
    errors: {} as ErrorType,
    id: '',
    note: '',
    showConfirmationModal: false,
    showEditButton: false
  }

  public render() {
    const { messageData, owner, loader } = this.props
    const { note, showEditButton, errors } = this.state
    const message = messageData.map((items: any, index: any) => {
      const editMessageFunction = () => {
        this.setState({ id: items._id, note: items.message, showEditButton: true })
      }
      const deleteMessageFunction = () => {
        this.deleteMessage(items._id)
      }
      return (
        <Container key={index}>
          <StyledPopup
            trigger={<Profile background={items.createdBy.profileImage} />}
            content={`${items.createdBy.firstName} ${items.createdBy.lastName}`}
          />
          <div style={{ width: '100%' }}>
            <Description>{items.message}</Description>
            <Date>
              {this.dateMatch(moment(items.createdAt).format('ll')) === 0 && <div style={{ flexGrow: 1 }}>Today</div>}
              {this.dateMatch(moment(items.createdAt).format('ll')) === -1 && (
                <div style={{ flexGrow: 1 }}>Yesterday</div>
              )}
              {this.dateMatch(moment(items.createdAt).format('ll')) !== 0 &&
                this.dateMatch(moment(items.createdAt).format('ll')) !== -1 && (
                  <div style={{ flexGrow: 1 }}>{moment(items.createdAt).format('ll')}</div>
                )}
              {owner === items.createdBy._id && (
                <div style={{ marginRight: '40px' }}>
                  <Icon name="pencil" onClick={editMessageFunction} />
                  <Icon name="trash" onClick={deleteMessageFunction} />
                </div>
              )}
            </Date>
          </div>
        </Container>
      )
    })
    return (
      <Wrapper style={{ width: '100%' }}>
        <StyledForm size="tiny">
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              name="note"
              placeholder="Add a Note"
              onChange={this.handleChange}
              value={note}
              error={errors[`note`]}
            />
            {loader ? (
              <Form.Field control={Button} loading={true} content="LOADING" />
            ) : showEditButton ? (
              <Form.Field control={Button} content="EDIT" onClick={this.editMessage} />
            ) : (
              <Form.Field control={Button} content="SUBMIT" onClick={this.saveMessage} />
            )}
          </Form.Group>
        </StyledForm>
        <StyledNotes>
          {message.length === 0 ? <Infograph backgroundImage={Strings.generalText.nothing} /> : message}
        </StyledNotes>
      </Wrapper>
    )
  }

  private deleteMessage = (id: string) => {
    const { deleteMessage } = this.props
    deleteMessage(id)
  }

  private dateMatch = (date: string) => {
    const todayDate = moment()
    const a = moment(date)
    const b = moment(todayDate)
    return a.diff(b, 'days')
  }

  private editMessage = () => {
    const { editMessage } = this.props
    const { id, note } = this.state
    const result = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      editMessage(id, note.trim())
      this.setState({ id: '', note: '', showEditButton: false })
    }
  }

  private handleChange = (e: any, { name, value }: any) => {
    this.setState({ note: value })
  }

  private saveMessage = () => {
    const { addNewMessage } = this.props
    const { note } = this.state
    const result = handleValidation(note)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      addNewMessage(note.trim())
      this.setState({ id: '', note: '' })
    }
  }
}

export default List
