// Import Packages
import { Form, Search } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

export const Container = styled.div`
  display: flex;
  color: ${Colors.Black500};
`

export const AddLeftPanel = styled.div`
  width: 35%;
  height: auto;
  background: ${Colors.White510};

  .ui.small.image {
    width: 250px;
  }
`

export const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 15px;
  color: ${Colors.Black500};
`

export const Text = styled.div`
  width: 220px;
  text-align: center;
  margin-top: 15px;
  font-size: 10px;
  color: ${Colors.Black500};
  font-weight: 500;
`

export const Disclaimer = styled.div`
  font-size: 10px;
  margin-bottom: 10px;
  padding: 25px;
  color: ${Colors.Black500};
  text-align: justify;

  span {
    color: ${Colors.Red10}
  }
`

export const AddForm = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 58%;

  .rp-algolia-places {
    margin-bottom: 9px;
    .ap-input {
      max-height: 33px;
    }
  }
`

export const StyledForm = styled(Form)`
  &.ui.form .field>label {
    color: ${Colors.Black500};
  }

  .ui.button {
    font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 50px;
    border-radius: 0px;
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
    font-weight: bold;
  }
`

export const StyledGroup = styled(Form.Group)`
  .ui.selection.dropdown {
    min-width: 140px;
    max-height: 29.91px;
  }
`

export const StyledSearch = styled(Search)`
  .ui.icon.input>i.icon {
    display: none;
  }
`
