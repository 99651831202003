// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import { GET_CAP_SETTING } from 'queries/graphql/Settings/TransactionFee/CapSettings/Queries'
import { GET_TRANSACTION_SETTING } from 'queries/graphql/Settings/TransactionFee/FeeSettings/Queries'

export const getTransactionsSetting = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TRANSACTION_SETTING
  })

  return response.data.getTransactionSettings
}

export const getCapSettings = async () => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CAP_SETTING
  })

  return response.data.getCapSettings[0].capStatus
}
