// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Timeline } from 'react-event-timeline'
import { Icon } from 'semantic-ui-react'
import { Activities } from 'store/Tasks/Types'

// Import Styled Components
import { Container, People, StyledPopup, StyledTimelineEvent, TimeLineContainer } from './Styled'
interface Props {
  activities: Activities[]
}

class Activity extends React.Component<Props, {}> {
  public state = {}

  public render() {
    const { activities } = this.props
    let timeLine
    if (activities) {
      timeLine = activities.map((items: any, index: number) => {
        let icon: any
        switch (items.type) {
          case 'CREATED':
            icon = 'add'
            break
          case 'UPDATED':
            icon = 'pencil'
            break
          case 'DELETED':
            icon = 'trash'
            break
          case 'SHARED':
            icon = 'add user'
            break
          case 'REMOVED':
            icon = 'remove user'
            break
          default:
            break
        }
        return (
          <Timeline key={index}>
            <StyledTimelineEvent
              title={
                <Container>
                  <div>
                    <p>
                      <b style={{ textTransform: 'capitalize' }}>{items.type}</b> task name {items.task.name}
                    </p>
                  </div>
                </Container>
              }
              createdAt={moment(items.updatedAt).format('LL')}
              icon={
                items.owner !== null ? (
                  <StyledPopup
                    trigger={<People background={items.owner[`profileImage`]} />}
                    content={`${items.owner[`firstName`]} ${items.owner[`lastName`]}`}
                  />
                ) : (
                  <Icon name={icon} />
                )
              }
              style={{ lineHeight: '12px', marginLeft: '10px', top: '5px' }}
            />
          </Timeline>
        )
      })
    }
    return <TimeLineContainer className="notes-styling">{timeLine}</TimeLineContainer>
  }
}

export default Activity
