import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Colors from 'design/Colors'
import { row, text, y } from 'design/Styled'

interface Props {
  background?: string
}

export const Container = styled.div`
  ${row};
  padding: 0.5em 0;
`

export const Span = styled.span`
  ${row};
  ${text};
  line-height: 1.3;
  text-transform: capitalize;
`

export const StyledLink = styled(Link)`
  ${y};
  width: 100%;
  margin-left: 1em;
`

export const Avatar = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 25px;
  min-height: 25px;
  align-self: center;
  border: 1px solid ${Colors.ButtonPrimary};
  border-radius: 50%;
`

