export enum BillingPlanEnum {
  Free = 'FREE',
  None = 'NONE',
  Paid = 'PAID'
}

export enum BillingProductEnum {
  Base = 'base',
  Concierge = 'concierge',
  LeadPool = 'leadPool',
  None = 'none',
  Office = 'office',
  Transaction = 'transaction'
}

export interface CheckoutSessionInput {
  plan: BillingPlanEnum
  stripePaymentMethodId?: string
}

export interface BillingProductUsageType {
  base: number
  concierge: number
  office: number
  transaction: number
}

export interface InvoiceType {
  cycle: BillingCycleType
  lines: LineItemType[]
  total_decimal: string
}

export interface InvoiceListType {
  amount_due: number
  amount_paid: number
  createdAt: Date
  dueDate: Date
  endDate: Date
  hosted_invoice_url: string
  id: string
  paid: boolean
  receipt_number: string
  startDate: Date
  status: string
}

export interface LineItemType {
  amount_decimal: string
  description: string
  endDate: Date
  startDate: Date
}

export interface BillingCycleType {
  plan: BillingPlanEnum
  status: string
  endDate: Date
  startDate: Date
}

export interface PaymentMethodType {
  id: string
  brand: string
  isDefault: boolean
  last4: string
}
