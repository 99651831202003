// Import Packages
import * as React from 'react'
import { Grid } from 'semantic-ui-react'
import { VictoryPie, VictoryTooltip } from 'victory'

import { RouteComponentProps, withRouter } from 'react-router-dom'

// Import Components
import Modal from 'shared/Modal'
import client from 'queries/apollo'
import { ShowComposeMail } from '../Offices'

// Import Graphql Queries
import { GET_OFFICES_DETAILS } from 'queries/graphql/Offices/Queries'
import { AddressType, OfficeItemsDetails, UserDetails } from 'store/Offices/Types'

// Import Utils
import { Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  Branch,
  BranchName,
  Content,
  EmailIcon,
  FullName,
  Header,
  Icons,
  Level,
  Location,
  Manager,
  Map,
  MemberImage,
  MemberName,
  Numbers,
  PhoneIcon,
  PopupContainer,
  PopupEmail,
  PopupLocation,
  PopupPhone,
  Profile,
  ProfileImage,
  ProfileInfo,
  ProfileProgressContainer,
  Progress,
  StyledColumn,
  StyledGrid,
  StyledPopup,
  Text,
  Triangle
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faMapMarkerAlt, faPhone, faCircle])

interface Props extends RouteComponentProps<{}> {
  closeModal: () => void
  officeId: string
}

interface State {
  showProfileProgress: string
  officeDetails: OfficeItemsDetails
  animate: boolean
  activeUsers: number
}

const requiredFields = ['roasterName', 'bio', 'website', 'userName', 'mlsId', 'licenseNo', 'licensedExpiration']

class ViewDetailsModal extends React.Component<Props, State> {
  public state = {
    activeUsers: 0,
    animate: true,
    officeDetails: {} as OfficeItemsDetails,
    showProfileProgress: ''
  }

  public async componentDidMount() {
    const officeDetails = await client.query({ query: GET_OFFICES_DETAILS, variables: { _id: this.props.officeId } })
    const activeUsers = officeDetails.data.getOffices[0].users.filter((items: any) => {
      return items.status === 'Active'
    })
    this.setState({ officeDetails: officeDetails.data.getOffices[0], activeUsers: activeUsers.length })
  }

  public getDataResidential = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public getDataCommercial = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public render() {
    const { officeDetails, animate, activeUsers }: any = this.state
    if (Object.keys(officeDetails).length > 0) {
      const officeDetail = officeDetails.users.map((items: UserDetails, index: number) => {
        const HomeAddress =
          items.addresses &&
          items.addresses.length > 0 &&
          items.addresses.find((list: AddressType) => {
            return list.type === 'Home'
          })
        const HomeAddressString = HomeAddress
          ? `${HomeAddress.streetNumber} ${HomeAddress.streetName}, ${HomeAddress.city}, ${HomeAddress.state} ${
              HomeAddress.zipCode
            }`
          : 'No Home Address Yet'
        let count = 0
        Object.keys(items).forEach((key: string) => {
          if (requiredFields.includes(key) && items[key] !== null) {
            count = count + 1
          } else if (key === 'addresses' && items[key] !== null && items[key].length >= 2) {
            count = count + 1
          } else if (key === 'phones' && items[key] !== null && items[key].length >= 2) {
            count = count + 1
          }
        })
        const userPercent = (count / 9) * 100

        return (
          <StyledColumn width={2} key={index}>
            <ProfileProgressContainer>
              <div>
                <svg viewBox="0 0 400 400">
                  <VictoryPie
                    standalone={false}
                    width={400}
                    height={400}
                    data={this.getDataCommercial(userPercent)}
                    innerRadius={150}
                    labelRadius={50}
                    padding={25}
                    startAngle={0}
                    endAngle={360}
                    labels={['R : 50%']}
                    labelComponent={
                      <VictoryTooltip
                        dx={100}
                        style={{
                          fontSize: '30px',
                          height: '50px',
                          position: 'absolute',
                          width: '100px'
                        }}
                      />
                    }
                    colorScale={[Colors.Green500, Colors.White900]}
                  />
                </svg>
              </div>
            </ProfileProgressContainer>
            <StyledPopup
              trigger={
                <MemberImage
                  floated="right"
                  circular={true}
                  size="tiny"
                  src={items.profileImage}
                  onClick={() => this.redirectOnProfile(items._id)}
                />
              }
              content={
                <div>
                  <PopupContainer>
                    <PopupLocation>
                      <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
                      {HomeAddressString}
                    </PopupLocation>
                    <PopupPhone>
                      <FontAwesomeIcon icon={['fal', 'phone']} />
                      {items.phones && items.phones[0] ? items.phones[0].value : 'No Phone Added'}
                    </PopupPhone>
                    <PopupEmail>
                      <FontAwesomeIcon icon={['fal', 'envelope']} />
                      {items.userName}
                    </PopupEmail>
                    <Progress color={Colors.Green500} className="profile">
                      <FontAwesomeIcon icon={['fas', 'circle']} /> {userPercent.toFixed(2)}% Profile Complete
                    </Progress>
                  </PopupContainer>
                  <Triangle />
                </div>
              }
              hoverable={true}
            />
            <MemberName>{[items.firstName, items.lastName].join(' ')}</MemberName>
          </StyledColumn>
        )
      })
      if (Object.keys(officeDetails).length > 0) {
        let ManagerDetails = officeDetails.users.find((user: any) => {
          return user.isPrimaryManager === true
        })
        if (!ManagerDetails) {
          ManagerDetails = officeDetails.users.find((user: any) => {
            return user.role === 'ADMIN'
          })
        }
        return (
          <Modal
            content={
              <div>
                <Header>
                  <Branch>
                    <BranchName>{officeDetails.branchName}</BranchName>
                    <Location>
                      <div>
                        <Map />
                      </div>
                      <div>{[officeDetails.street, officeDetails.city, officeDetails.state].join(', ')}</div>
                    </Location>
                  </Branch>
                  <Level>
                    <Numbers color={Colors.Green110}>{activeUsers}</Numbers>
                    <Text>{Strings.office.activeUsers}</Text>
                  </Level>
                  <Level>
                    <Numbers color={Colors.Orange100}>
                      {officeDetails.teams === null ? 0 : officeDetails.teams.length}
                    </Numbers>
                    <Text>{Strings.office.noOf}</Text>
                  </Level>
                  <Level>
                    <Numbers color={Colors.Magenta60}>
                      {officeDetails.noOfTransactions ? officeDetails.noOfTransactions : 0}
                    </Numbers>
                    <Text>{Strings.office.transactions}</Text>
                  </Level>
                  {ManagerDetails !== undefined ? (
                    <Profile>
                      <ProfileInfo>
                        <FullName>{[ManagerDetails.firstName, ManagerDetails.lastName].join(' ')}</FullName>
                        <Manager>{Strings.office.managers}</Manager>
                        <Icons>
                          <span onClick={() => ShowComposeMail(ManagerDetails.emails[0].value)}>
                            <EmailIcon />
                          </span>
                          <PhoneIcon onClick={() => this.callTo(ManagerDetails)} />
                        </Icons>
                      </ProfileInfo>
                      <ProfileImage background={ManagerDetails.profileImage} />
                    </Profile>
                  ) : (
                    <Profile>
                      <ProfileInfo>
                        <FullName>{Strings.office.noManager}</FullName>
                      </ProfileInfo>
                    </Profile>
                  )}
                </Header>
                <Content>
                  <StyledGrid>
                    <Grid.Row>{officeDetail}</Grid.Row>
                  </StyledGrid>
                </Content>
              </div>
            }
            className={animate ? 'zoomIn' : 'zoomOut'}
            closeModal={this.closeModal}
            minWidth={900}
          />
        )
      }
    }
    return null
  }

  private redirectOnProfile = (id: string) => {
    this.props.history.push(Routes.primary.profile.path + `/${id}`)
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private callTo = (data: any) => {
    return (window.location.href = `tel:${data.phone}`)
  }
}

export default withRouter(ViewDetailsModal)
