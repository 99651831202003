import { Notifications, NotificationsItems } from './Types'

export const readFeedMessage = (
  state: Notifications,
  id: string
): NotificationsItems[] => {
  const { feedNotification } = state
  let feeData = feedNotification.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        readMessage: true
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const readUpdateMessage = (
  state: Notifications,
  id: string
): NotificationsItems[] => {
  const { updateNotification } = state
  let feeData = updateNotification.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        readMessage: true
      }
    } else {
      return list
    }
  })
  return [...feeData]
}
