import { Popup } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import {
  children,
  link,
  modalBorderRadius as r,
  styledToolTip,
  text,
  x,
  y
} from 'design/Styled'

import { PassStatusEnum } from 'store/Pass/Types'

interface Props {
  align?: string
  max?: number
  status?: PassStatusEnum
}

const StatusColor = {
  [PassStatusEnum.Active]: Colors.StatusActive,
  [PassStatusEnum.Inactive]: Colors.StatusInactive,
  [PassStatusEnum.PendingActivation]: Colors.StatusPending,
  [PassStatusEnum.PendingDeactivation]: Colors.StatusPending
}

const getStatus = ({ status }: Props) => {
  if (!status) {
    return ``
  }

  const color = StatusColor[status]
  return css`
    background: ${color};
    &:after {
      display: block;
      content: '${status}';
      color: ${
        status === PassStatusEnum.Inactive ? Colors.Text : Colors.TextLight
      };
    }
  `
}

export const Container = styled.div`
  ${x};
  height: 100%;

  .rp-scrollable-child {
    min-height: 100%;
  }
`

export const Aside = styled.aside`
  ${y};
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 300px;
  max-width: 300px;
  padding: 1em;
  background-color: ${Colors.ImagePanelContainer};
  border-radius: ${r} 0 0 ${r};
  overflow: hidden;
`

export const Image = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto 1em;
  ${(props: Props) => props.max && `max-width: ${props.max}px`};
`

export const Title = styled.h1`
  color: ${Colors.Text};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
`

export const Text = styled.p`
  ${x};
  ${text};
  margin-bottom: 0.5em;
  line-height: 1.3;
`

export const ToolTip = styled(Popup)`
  ${styledToolTip};
`

export const Label = styled.strong`
  ${text};
  font-weight: bold;
  line-height: 1.3;
  cursor: pointer;
`

export const Link = styled.span`
  ${link};
`

export const Span = styled.span`
  ${text};
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Col = styled.div`
  ${y};
  min-height: 100%;

  & > .rp-transition-wrapper {
    height: 100%;
  }
`

export const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 160px;
  padding: 0.4em 0.5em;
  border-radius: 4px;
  line-height: 1;
  ${getStatus};
`
