import Image1 from 'design/icons/settings/img1.png'
import Image2 from 'design/icons/settings/img2.png'

export const passesMockData = [
  {
    activatedBy: '',
    description: '',
    passImage: Image2,
    passName: 'Zillow',
    status: 'Coming soon',
  },
  {
    activatedBy: '',
    description: '',
    passImage: Image1,
    passName: 'SendGrid Email',
    status: 'Coming soon',
  },
]
