import gql from 'graphql-tag'

export const CREATE_TASK = gql`
  mutation createTask(
    $type: String
    $name: String
    $description: String
    $dueDate: Date
    $taskTime: String
    $sharedWith: [String]
    $leadId: String
    $messageEmail: MessageEmailCopy
    $transactionId: String
    $createdBy: String
    $taskBoard: String
    $taskOrder: Int
    $teamTaskBoard: String
    $teamTaskOrder: Int
    $officeTaskBoard: String
    $officeTaskOrder: Int
    $tenantTaskBoard: String
    $tenantTaskOrder: Int
  ) {
    createTask(
      input: {
        createdBy: $createdBy
        type: $type
        name: $name
        description: $description
        dueDate: $dueDate
        taskTime: $taskTime
        sharedWith: $sharedWith
        lead: $leadId
        messageEmail: $messageEmail
        transaction: $transactionId
        taskBoard: $taskBoard
        taskOrder: $taskOrder
        teamTaskBoard: $teamTaskBoard
        teamTaskOrder: $teamTaskOrder
        officeTaskBoard: $officeTaskBoard
        officeTaskOrder: $officeTaskOrder
        tenantTaskBoard: $tenantTaskBoard
        tenantTaskOrder: $tenantTaskOrder
      }
    ) {
      _id
      type
      name
      description
      createdAt
      dueDate
      taskTime
      status
      notes {
        _id
        description
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        createdAt
      }
      activities {
        _id
        type
        task {
          name
        }
        description
        updatedAt
      }
      sharedWith {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      lead {
        _id
        firstName
        lastName
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
      messageEmail {
        attachments {
          fileName
          fileType
          url
        }
        from {
          initials
          name
          value
        }
        preview
        subject
        text
        to {
          initials
          name
          value
        }
      }
    }
  }
`

export const DELETE_TASK = gql`
  mutation deleteTask($taskId: [String]) {
    deleteTask(_id: $taskId)
  }
`

export const UPDATE_TASK = gql`
  mutation updateTask(
    $taskId: String
    $status: String
    $type: String
    $name: String
    $description: String
    $dueDate: Date
    $taskTime: String
    $createdById: String
    $sharedWith: [String]
  ) {
    updateTask(
      where: { _id: $taskId }
      input: {
        status: $status
        type: $type
        name: $name
        description: $description
        dueDate: $dueDate
        taskTime: $taskTime
        sharedWith: $sharedWith
        createdBy: $createdById
      }
    ) {
      _id
      type
      name
      description
      createdAt
      dueDate
      taskTime
      status
      notes {
        _id
        description
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        createdAt
      }
      activities {
        _id
        type
        task {
          name
        }
        description
        updatedAt
      }
      sharedWith {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      lead {
        _id
        firstName
        lastName
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
      transaction {
        _id
        propertyId {
          _id
          type {
            _id
            name
          }
          subType {
            _id
            name
          }
          address {
            _id
            type
            streetNumber
            streetName
            city
            state
            country
            zipCode
          }
          images {
            _id
            name
            url
            order
          }
          price
          mlsId
        }
        transactionId
        transactionRole
        status
        tags
        progress
        createdAt
        updatedAt
        owner {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
    }
  }
`

export const UPDATE_BULK_TASKS = gql`
  mutation updateBulkTasks($taskIds: [String], $status: String) {
    updateBulkTasks(_id: $taskIds, input: { status: $status }) {
      _id
      type
      name
      description
      createdAt
      dueDate
      taskTime
      status
      notes {
        _id
        description
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        createdAt
      }
      activities {
        _id
        type
        task {
          name
        }
        description
        updatedAt
      }
      sharedWith {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      lead {
        _id
        firstName
        lastName
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
    }
  }
`

export const CREATE_TASK_NOTES = gql`
  mutation createTaskNote($taskId: String, $description: String) {
    createNote(input: { task: $taskId, description: $description }) {
      _id
      description
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdAt
    }
  }
`

export const EDIT_TASK_NOTES = gql`
  mutation editTaskNote($taskNoteId: String, $description: String) {
    updateNote(
      where: { _id: $taskNoteId }
      input: { description: $description }
    ) {
      _id
      description
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdAt
    }
  }
`

export const DELETE_TASK_NOTES = gql`
  mutation deleteTaskNote($taskNoteId: String) {
    deleteNote(_id: $taskNoteId)
  }
`

export const MOVE_TASK = gql`
  mutation transferTask(
    $taskId: String
    $type: String
    $name: String
    $description: String
    $dueDate: Date
    $taskTime: String
    $sharedWith: [String]
    $createdBy: String
    $taskBoard: String
    $taskOrder: Int
    $teamTaskBoard: String
    $teamTaskOrder: Int
    $officeTaskBoard: String
    $officeTaskOrder: Int
    $tenantTaskBoard: String
    $tenantTaskOrder: Int
  ) {
    transferTask(
      where: { _id: $taskId }
      input: {
        createdBy: $createdBy
        type: $type
        name: $name
        description: $description
        dueDate: $dueDate
        taskTime: $taskTime
        sharedWith: $sharedWith
        taskBoard: $taskBoard
        taskOrder: $taskOrder
        teamTaskBoard: $teamTaskBoard
        teamTaskOrder: $teamTaskOrder
        officeTaskBoard: $officeTaskBoard
        officeTaskOrder: $officeTaskOrder
        tenantTaskBoard: $tenantTaskBoard
        tenantTaskOrder: $tenantTaskOrder
      }
    ) {
      _id
      type
      name
      createdAt
      description
      dueDate
      taskTime
      status
      notes {
        _id
        description
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        createdAt
      }
      activities {
        _id
        type
        task {
          name
        }
        description
        updatedAt
      }
      sharedWith {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      lead {
        _id
        firstName
        lastName
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
    }
  }
`

export const UPDATE_TASK_ORDER = gql`
  mutation updateTaskOrder(
    $taskBoard: String
    $teamTaskBoard: String
    $officeTaskBoard: String
    $tenantTaskBoard: String
    $cardId: String
    $taskSourceBoardOrder: Int
    $teamTaskSourceBoardOrder: Int
    $officeTaskSourceBoardOrder: Int
    $tenantTaskSourceBoardOrder: Int
    $taskDestinationBoardOrder: Int
    $teamTaskDestinationBoardOrder: Int
    $officeTaskDestinationBoardOrder: Int
    $tenantTaskDestinationBoardOrder: Int
  ) {
    updateTaskOrder(
      where: {
        _id: $cardId
        taskBoard: $taskBoard
        teamTaskBoard: $teamTaskBoard
        officeTaskBoard: $officeTaskBoard
        tenantTaskBoard: $tenantTaskBoard
        taskOrder: $taskSourceBoardOrder
        teamTaskOrder: $teamTaskSourceBoardOrder
        officeTaskOrder: $officeTaskSourceBoardOrder
        tenantTaskOrder: $tenantTaskSourceBoardOrder
      }
      input: {
        taskOrder: $taskDestinationBoardOrder
        teamTaskOrder: $teamTaskDestinationBoardOrder
        officeTaskOrder: $officeTaskDestinationBoardOrder
        tenantTaskOrder: $tenantTaskDestinationBoardOrder
      }
    ) {
      _id
      type
      name
      createdAt
      description
      dueDate
      taskTime
      status
      notes {
        _id
        description
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        createdAt
      }
      activities {
        _id
        type
        task {
          name
        }
        description
        updatedAt
      }
      sharedWith {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      lead {
        _id
        firstName
        lastName
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
    }
  }
`

export const IMPORT_TASKS = gql`
  mutation importTasks(
    $file: Upload!
    $taskBoard: String
    $teamTaskBoard: String
    $officeTaskBoard: String
    $tenantTaskBoard: String
  ) {
    importTasks(
      input: {
        taskBoard: $taskBoard
        teamTaskBoard: $teamTaskBoard
        officeTaskBoard: $officeTaskBoard
        tenantTaskBoard: $tenantTaskBoard
      }
      file: $file
    ) {
      _id
      type
      name
      createdAt
      description
      dueDate
      taskTime
      status
      notes {
        _id
        description
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
        createdAt
      }
      activities {
        _id
        type
        task {
          name
        }
        description
        updatedAt
      }
      sharedWith {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      createdBy {
        _id
        firstName
        lastName
        userName
        profileImage
        phones {
          type
          value
        }
      }
      lead {
        _id
        firstName
        lastName
        shareWith {
          _id
          firstName
          lastName
          userName
          profileImage
          phones {
            type
            value
          }
        }
      }
    }
  }
`

export const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: String!) {
    deleteEvent(_id: $eventId)
  }
`
