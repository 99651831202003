import gql from 'graphql-tag'

export const GET_TRANSACTION_SETTING = gql`
  query getTransactionSettings {
    getTransactionSettings(where: { isActive: true }) {
      _id
      name
      isDefault
      residentialBaseFee
      residentialFirstAmount
      residentialAdditional
      residentialIncrement
      commercialBaseFee
      commercialFirstAmount
      commercialAdditional
      commercialIncrement
      commissionFeeType
      flatFeeAmount
      flatFeePercentage
      progressiveFee {
        minimum
        maximum
        percentage
      }
      otherFees {
        name
        fee
        type
      }
    }
    expectedCommission @client
    propertyFee @client
  }
`
