import { sortBy } from 'lodash'
import {
  Contact,
  ContactDetails,
  ContactItemsDetails,
  ContactNotes
} from './Types'

export const addNewBoard = (
  state: Contact,
  index: number,
  newData: ContactDetails
): ContactDetails[] => {
  const { data } = state
  const feeData = data.slice()
  feeData.splice(index, 0, newData)
  return [...feeData]
}

export const addNewCard = (
  state: Contact,
  id: string,
  index: string,
  newData: ContactItemsDetails
): Contact['data'] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      const listLength = list.items.length
      const newList = list.items.slice()
      newList.splice(index ? index : listLength, 0, newData)
      return {
        ...list,
        contacts: newList,
        items: newList
      }
    } else {
      return list
    }
  })
  return feeData
}

export const setNewIcon = (
  state: Contact,
  id: string,
  icon: string
): ContactDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        icon: icon
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewDescription = (
  state: Contact,
  id: string,
  description: string
): ContactDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        description: description
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const setNewBoardName = (
  state: Contact,
  id: string,
  boardName: string
): ContactDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === id) {
      return {
        ...list,
        name: boardName
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const deleteBoard = (
  state: Contact,
  boardId: string
): ContactDetails[] => {
  const { data } = state
  const boardData = data.slice()
  const boardIndex = boardData.findIndex((lane: any) => {
    return lane._id === boardId
  })
  boardData.splice(boardIndex, 1)
  return [...boardData]
}

export const deleteCard = (
  state: Contact,
  boardId: string,
  cardId: string
): ContactDetails[] => {
  const { data } = state
  let feeData = data.slice()
  feeData = feeData.map((list: any) => {
    if (list._id === boardId) {
      const newList = list.items.slice()
      const listIndex = newList.findIndex((laneItem: any) => {
        return laneItem._id === cardId
      })
      newList.splice(listIndex, 1)
      return {
        ...list,
        contacts: newList,
        items: newList
      }
    } else {
      return list
    }
  })
  return [...feeData]
}

export const restoreCard = (
  state: Contact,
  id: string,
  index: string,
  newData: ContactItemsDetails | null
): Contact['data'] => {
  if (newData) {
    const { data } = state
    let feeData = data.slice()
    feeData = feeData.map((list: any) => {
      if (list._id === id) {
        const newList = list.items.slice()
        newList.splice(index, 0, newData)
        return {
          ...list,
          items: newList
        }
      } else {
        return list
      }
    })
    return feeData
  } else {
    return state.data
  }
}

export const addNewNotes = (
  state: Contact,
  newData: ContactNotes
): ContactNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}

export const editNotes = (
  state: Contact,
  id: string,
  newData: ContactNotes
): ContactNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  const noteIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  let note = { ...feeData[noteIndex] }
  note = newData
  feeData[noteIndex] = note
  return [...feeData]
}

export const sortBoardData = (
  state: Contact,
  boardId: string,
  sortOrder: number
): Contact['data'] => {
  const { data } = state
  let boardData = data.slice()
  boardData = boardData.map((list: any) => {
    if (list._id === boardId) {
      const newList = list.items.slice()
      const sortedData = sortBy(newList, ['firstName'])
      if (sortOrder === -1) {
        return {
          ...list,
          items: sortedData.reverse()
        }
      } else {
        return {
          ...list,
          items: sortedData
        }
      }
    } else {
      return list
    }
  })
  return boardData
}

export const deleteNotes = (state: Contact, id: string): ContactNotes[] => {
  const { notes } = state
  const feeData = notes.slice()
  const noteIndex = feeData.findIndex((laneItem: any) => {
    return laneItem._id === id
  })
  feeData.splice(noteIndex, 1)
  return [...feeData]
}

export const deleteListRecord = (
  state: Contact,
  cardId: string
): Contact['listData'] => {
  const { listData } = state
  const listRecords = listData.slice()
  const index = listRecords.findIndex((data: any) => {
    return data._id === cardId
  })
  listRecords.splice(index, 1)
  return listRecords
}

export const editRecord = (
  state: Contact,
  newData: any
): Contact['listData'] => {
  const { listData } = state
  let listRecords = listData.slice()
  listRecords = listRecords.map((list: any) => {
    if (list._id === newData._id) {
      return newData
    } else {
      return list
    }
  })
  return listRecords
}

export const addShareWith = (
  state: Contact,
  shareWithObj: any
): Contact['shareWith'] => {
  const { shareWith } = state
  const shareWithData = shareWith.slice()
  const len = shareWithData.length
  const newSharedData = shareWithObj[len]
  shareWithData.push(newSharedData)
  return shareWithData
}

export const deleteShareWith = (
  state: Contact,
  data: any
): Contact['shareWith'] => {
  const { shareWith } = state
  const shareWithData = shareWith.slice()
  const index = shareWithData.findIndex((shared: any) => {
    return shared._id === data
  })
  shareWithData.splice(index, 1)
  return shareWithData
}

export const importData = (
  state: Contact,
  boardId: string,
  importedData: any
): Contact['data'] => {
  const { data } = state
  let boardData = data.slice()
  boardData = boardData.map((list: any) => {
    if (list._id === boardId) {
      let newList = list.items.slice()
      if (newList.length > 0) {
        newList.concat(importedData)
      } else {
        newList = importedData
      }
      return {
        ...list,
        items: newList
      }
    } else {
      return list
    }
  })
  return boardData
}

export const AddTransaction = (
  state: Contact,
  newData: any
): Contact['transactions'] => {
  const { transactions } = state
  const feeData = transactions.slice()
  feeData.splice(0, 0, newData)
  return [...feeData]
}
