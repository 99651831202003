// Import Packages
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

const MessagesWrapper = styled.section`
  width: 100%;
  background-color: ${Colors.White1000};
  flex: 1;
  overflow: auto;
  ${scrollbars};
`

export { MessagesWrapper }
