import gql from 'graphql-tag'

export const CREATE_TEAM = gql`
  mutation createTeam(
    $name: String!
    $office: ID!
    $leader: ID!
    $teamOrder: Int
  ) {
    createTeam(
      input: {
        name: $name
        office: $office
        leader: $leader
        teamOrder: $teamOrder
      }
    ) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        emails {
          value
        }
        profileImage
        teamFee
        status
        teamCommissionEarn
        teamCommissionSplit
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
      }
      teamCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
      }
      teamOrder
      isActive
      createdAt
    }
  }
`

export const UPDATE_TEAM = gql`
  mutation updateTeam($teamId: String, $name: String, $residentialTargetCap: Float, $commercialTargetCap: Float) {
    updateTeam(
      where: $teamId,
      input: {
        name: $name,
        updateTeamCap: {
          residentialTargetCap: $residentialTargetCap,
          commercialTargetCap: $commercialTargetCap
        }
      }
    ) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        emails {
          value
        }
        profileImage
        teamFee
        status
        teamCommissionEarn
        teamCommissionSplit
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
      }
      office {
        _id
        branchName
      }
      teamOrder
      isActive
      createdAt
    }
  }
`

export const DELETE_TEAM = gql`
  mutation deleteTeam($teamId: [String]) {
    deleteTeam(_id: $teamId)
  }
`

export const UPDATE_TEAM_ORDER = gql`
  mutation updateTeamOrder(
    $board: ID
    $cardId: ID
    $source: Int
    $destination: Int
  ) {
    updateTeamOrder(
      where: { _id: $cardId, office: $board, teamOrder: $source }
      input: { teamOrder: $destination }
    ) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        emails {
          value
        }
        profileImage
        teamFee
        status
        teamCommissionEarn
        teamCommissionSplit
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
      }
      teamCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
      }
      office {
        _id
        branchName
      }
      teamOrder
      isActive
      createdAt
    }
  }
`

export const UPDATE_TEAM_OFFICE_ORDER = gql`
  mutation updateTeamOfficeOrder($board: ID, $source: Int, $destination: Int) {
    updateTeamOfficeOrder(
      where: { _id: $board, teamOfficeOrder: $source }
      input: { teamOfficeOrder: $destination }
    ) {
      _id
      branchName
      status
      state
      zipCode
      street
      city
      country
      teamOfficeOrder
    }
  }
`

export const MOVE_TEAM = gql`
  mutation transferTeam($teamId: ID, $destinationOrder: Int, $office: ID) {
    transferTeam(
      input: { _id: $teamId, teamOrder: $destinationOrder, office: $office }
    ) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        emails {
          value
        }
        profileImage
        teamFee
        status
        teamCommissionEarn
        teamCommissionSplit
      }
      teamCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
      }
      teamOrder
      isActive
      createdAt
    }
  }
`

export const UPDATE_TEAM_LEADER = gql`
  mutation changeTeamLeader($teamId: String, $leaderId: String) {
    changeTeamLeader(input: { teamId: $teamId, leaderId: $leaderId }) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        emails {
          value
        }
        profileImage
      }
    }
  }
`

export const DELETE_INVITE_MEMBER = gql`
  mutation deleteMember($teamId: String, $userId: String) {
    deleteMember(teamId: $teamId, userId: $userId) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        emails {
          value
        }
        profileImage
        teamFee
        status
        teamCommissionEarn
        teamCommissionSplit
      }
      teamCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
      }
      teamOrder
      isActive
      createdAt
    }
  }
`

export const INVITE_MEMBER = gql`
  mutation inviteMember(
    $teamId: String
    $userId: ID
    $firstName: String
    $lastName: String
    $userName: String
    $phones: PhoneInput
    $teamCommissionSplit: Float
    $teamFee: Int
    $file: Upload
  ) {
    inviteMember(
      _id: $teamId
      userParams: {
        _id: $userId
        firstName: $firstName
        lastName: $lastName
        userName: $userName
        phones: $phones
        teamCommissionSplit: $teamCommissionSplit
        teamFee: $teamFee
      }
      file: $file
    ) {
      _id
      name
      leader {
        _id
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        emails {
          value
        }
        profileImage
        teamFee
        status
        teamCommissionEarn
        teamCommissionSplit
      }
      members {
        _id
        profileImage
        firstName
        lastName
        userName
        phones {
          type
          value
        }
        teamFee
        teamCommissionSplit
        teamAgreement
        status
        teamInvitedDate
      }
      teamCap {
        residentialTargetCap
        commercialTargetCap
        residentialCompletedCap
        commercialCompletedCap
      }
      office {
        _id
        branchName
      }
      teamOrder
      isActive
      createdAt
    }
  }
`
