// import { generateID } from 'utils'
import { ChecklistManagerState } from './Types'

// Reducer Util Functions

// Document Util
export const addDocument = (
  state: ChecklistManagerState,
  data: any
): ChecklistManagerState['documents'] => {
  const { documents } = state
  documents.splice(documents.length, 0, {
    _id: data._id,
    name: 'NEW DOCUMENT'
  })
  return [...documents]
}

export const deleteDocument = (
  state: ChecklistManagerState,
  index: number
): ChecklistManagerState['documents'] => {
  const { documents } = state
  documents.splice(index, 1)
  return [...documents]
}

export const editDocument = (
  state: ChecklistManagerState,
  newName: string,
  index: number
): ChecklistManagerState['documents'] => {
  const { documents } = state
  const newDocuments: Array<{
    id: string
    name: string
  }> = Object.assign([], documents)
  newDocuments[index] = {
    ...newDocuments[index],
    name: newName
  }
  return [...newDocuments]
}

// Category Util
export const addCategory = (
  state: ChecklistManagerState,
  newCategory: any
): ChecklistManagerState['categories'] => {
  const { categories } = state
  categories.splice(categories.length, 0, {
    _id: newCategory._id,
    name: 'New Category',
    property: [
      {
        name: '+ Add Property Type',
        value: {}
      }
    ]
  })
  return [...categories]
}

export const deleteCategory = (
  state: ChecklistManagerState,
  index: number
): ChecklistManagerState['categories'] => {
  const { categories } = state
  categories.splice(index, 1)
  return [...categories]
}

export const editCategory = (
  state: ChecklistManagerState,
  newName: string,
  index: number
): ChecklistManagerState['categories'] => {
  const { categories } = state
  const newCategories: Array<{
    id: string
    name: string
    property: Array<{
      name: string
      value: object
    }>
  }> = Object.assign([], categories)
  newCategories[index] = {
    ...newCategories[index],
    name: newName
  }
  return [...newCategories]
}

// Property Util
export const addProperty = (
  state: ChecklistManagerState,
  categoryIndex: number,
  propertyId: string,
  propertyName: any
): ChecklistManagerState['categories'] => {
  const { categories } = state
  categories[categoryIndex].property.splice(
    categories[categoryIndex].property.length - 1,
    0,
    {
      _id: propertyId,
      name: propertyName,
      value: {}
    }
  )
  return [...categories]
}

export const deleteProperty = (
  state: ChecklistManagerState,
  categoryIndex: number,
  propertyIndex: number
): ChecklistManagerState['categories'] => {
  const { categories } = state
  categories[categoryIndex].property.splice(propertyIndex, 1)
  return [...categories]
}

export const editProperty = (
  state: ChecklistManagerState,
  newName: string,
  categoryIndex: number,
  propertyIndex: number
): ChecklistManagerState['categories'] => {
  const { categories } = state
  const newCategories: Array<{
    id: string
    name: string
    property: Array<{
      name: string
      value: object
    }>
  }> = Object.assign([], categories)
  newCategories[categoryIndex].property[propertyIndex] = {
    ...newCategories[categoryIndex].property[propertyIndex],
    name: newName
  }
  return [...newCategories]
}

export const changeChecklistValue = (
  state: ChecklistManagerState,
  categoryIndex: number,
  documentId: number,
  newValue: boolean,
  propertyIndex: number
): ChecklistManagerState['categories'] => {
  const { categories } = state
  const newCategories: Array<{
    id: string
    name: string
    property: Array<{
      name: string
      value: object
    }>
  }> = Object.assign([], categories)
  newCategories[categoryIndex].property[propertyIndex].value[
    documentId
  ] = newValue
  return [...newCategories]
}

export const reinsertChecklistProperty = (
  state: ChecklistManagerState,
  categoryIndex: number,
  fromIndex: number,
  toIndex: number
): ChecklistManagerState['categories'] => {
  const { categories } = state
  const newCategories: Array<{
    id: string
    name: string
    property: Array<{
      name: string
      value: object
    }>
    docCheckLists: Array<{
      name: string
      value: object
    }>
  }> = Object.assign([], categories)
  const array = newCategories[categoryIndex].property.slice(0)
  const val = array[fromIndex]
  array.splice(fromIndex, 1)
  array.splice(toIndex, 0, val)
  newCategories[categoryIndex].property = array
  return newCategories
}
