// Import Packages
import { debounce, escapeRegExp, filter } from 'lodash'
import * as React from 'react'
import InputMask from 'react-input-mask'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import { getReassignUsers } from '../../../../Leads/Dashboard/LeadQueries'
import { handleValidation } from './Validation'

// Import Store Types, Actions and Reducers
import { ContactListDetails, ContactShareWith } from 'store/Contacts/Types'

// Import Utils
import { Strings } from 'utils'

import { FormErrorType, FormType, SearchOptions } from './Types'

// Import Styled Components
import { Container, LeftPanel, RightPanel, StyledDirectForm, StyledLabel, StyledSearch } from './Styled'

// Font Awesome Icons
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faEnvelope, faPhone])

interface Props {
  closeModal: () => void
  showUser: (userObj: ContactShareWith | any) => void
  contactData: ContactListDetails
  shareWith: ContactShareWith[]
}

interface State {
  form: FormType
  userOptions: SearchOptions[]
  isLoading: boolean
  results: ContactShareWith[]
  value: string
  animate: boolean
  type: string
  firstname: string
}

const typeOptions = [
  { key: 1, text: 'Buyer', value: 'Buyer' },
  { key: 2, text: 'Seller', value: 'Seller' },
  { key: 3, text: 'Listing Agent', value: 'Listing Agent' },
  { key: 4, text: 'Selling Agent', value: 'Selling Agent' },
  { key: 5, text: 'Escrow', value: 'Escrow' },
  { key: 6, text: 'Title', value: 'Title' },
  { key: 7, text: 'Lender', value: 'Lender' },
  { key: 8, text: 'Attorney', value: 'Attorney' },
  { key: 9, text: 'Home Inspector', value: 'Home Inspector' },
  { key: 10, text: 'Other', value: 'Other' }
]

class ShareWithModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    firstname: '',
    form: {
      errors: {} as FormErrorType
    } as FormType,
    isLoading: false,
    results: [],
    shareWithObj: {} as ContactShareWith | any,
    type: '',
    userOptions: [],
    value: ''
  }

  public async componentDidMount() {
    const { shareWith } = this.props
    const usersOpt: any = []
    const users = await getReassignUsers()
    const usersList: any = users.filter((user: any) => {
      let found = false
      shareWith.forEach((data: any) => {
        if (data._id === user._id) {
          found = true
        }
      })
      return !found
    })
    usersList.forEach((user: any) => {
      const obj: any = {
        _id: user._id,
        description: user.emails && user.emails[0] ? user.emails[0].value : null,
        phone: user.phones && user.phones[0] ? user.phones[0].value : null,
        title: `${user.firstName} ${user.lastName}`,
        type: user.type
      }
      usersOpt.push(obj)
    })
    this.setState({ userOptions: usersOpt })
  }

  public render() {
    const {
      isLoading,
      results,
      value,
      animate,
      form,
      form: { errors }
    } = this.state
    return (
      <Modal
        content={
          <Container>
            <LeftPanel>
              <Image src={Strings.leads.image} size="small" />
            </LeftPanel>
            <RightPanel>
              <StyledDirectForm size={'mini'}>
                <Form.Group widths="equal">
                  <Form.Field error={!!errors.firstName}>
                    <StyledLabel>First Name</StyledLabel>
                    <StyledSearch
                      loading={isLoading}
                      onResultSelect={this.handleResultSelect}
                      onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
                      onChange={this.handleChange}
                      name="firstName"
                      placeholder="First Name"
                      results={results}
                      value={value}
                      {...this.props}
                    />
                  </Form.Field>
                  <Form.Field
                    control={Input}
                    onChange={this.handleChange}
                    label="Last Name"
                    placeholder="Last Name"
                    name="lastName"
                    value={form.lastName}
                    error={!!errors.lastName}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    onChange={this.handleChange}
                    label="Email"
                    placeholder="Email"
                    name="email"
                    value={form.email}
                    error={!!errors.email}
                  />
                  <Form.Field control={Input} label="Phone" className="amount" error={!!errors.phone}>
                    <InputMask
                      mask="(999) 999-9999"
                      name="phone"
                      placeholder="(xxx) xxx-xxxx"
                      onChange={this.onChangePhone}
                      value={form.phone}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field
                  control={Select}
                  onChange={this.handleChange}
                  placeholder="Collaborator Role"
                  name="type"
                  value={form.type}
                  label="Collaborator Role"
                  options={typeOptions}
                  error={!!errors.type}
                />
                <Form.Field onClick={() => this.submit()} control={Button} content={'SUBMIT'} />
              </StyledDirectForm>
            </RightPanel>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={650}
      />
    )
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { value, name }: any) => {
    const { form, firstname } = this.state
    form[name] = value
    form[`firstName`] = firstname
    this.setState({ form })
  }

  private onChangePhone = (event: any) => {
    const { form } = this.state
    form[`phones`] = event.target.value
    this.setState({ form })
  }

  private handleResultSelect = (e: React.SyntheticEvent<EventTarget>, { result }: any) => {
    const { form } = this.state
    const res = result.title.split(' ')
    form[`firstName`] = res[0]
    form[`lastName`] = res[1]
    form[`userId`] = result._id
    form[`email`] = result.description
    form[`phones`] = result.phone
    form[`type`] = result.type
    this.setState({ value: res[0], form })
  }

  private handleSearchChange = (e: React.SyntheticEvent<EventTarget>, { value, name }: any) => {
    const { form } = this.state
    this.setState({ isLoading: true, value, firstname: value })
    setTimeout(() => {
      if (this.state.value.length < 1) {
        return this.resetComponent()
      }
      const re = new RegExp(escapeRegExp(this.state.value), 'i')
      const isMatch = (result: any) => re.test(result.title)
      const results = filter(this.state.userOptions, isMatch)
      if (results.length > 0) {
        this.setState({
          isLoading: false,
          results
        })
      } else {
        form[name] = value
        this.setState({ form })
      }
    }, 300)
  }

  private resetComponent = () => this.setState({ isLoading: false, results: [], value: '' })

  private submit = () => {
    const { form } = this.state
    const { showUser } = this.props

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    form[`phones`] = {
      type: 'Mobile',
      value: form[`phones`]
    }
    showUser(form)
    this.closeModal()
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default ShareWithModal
