// Import Packages
import { Checkbox } from 'semantic-ui-react'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { children, scrollbars, styledToggle, x } from 'design/Styled'

interface ImageProps {
  backgroundImage?: string
  isToggled?: boolean
}

export const StyledContainer = styled(Container)`
  &.ui.fluid.container {
    border: none;
    box-shadow: none;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 90%;
    max-height: 91%;
  }

  .table-body {
    overflow: auto;
    height: 400px;
    ${scrollbars};
  }
`

export const StyledDropdown = styled.div`
  width: auto;
  min-width: 70px;
  font-size: 10px;
`

export const DropDownPopup = styled(Popup)`
  &.ui.bottom.left.popup {
    margin-left: -14px;
    margin-top: 14px;
  }

  &.ui.top.left.popup {
    margin-left: -14px;
    margin-bottom: 6px;
  }

  &.ui.popup {
    padding: 8px;
  }

  .item {
    cursor: pointer;
    padding: 5.5px;
    color: ${Colors.Text};
    font-size: 10px;
    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }

  .action-drop-down>.menu>a {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1.5em;
    color: rgba(0,0,0,.87);
    text-transform: none;
    font-size: 10px;
    color: ${Colors.Text};
    font-weight: 500;

    :hover {
      background: rgba(0,0,0,.05);
      color: rgba(0,0,0,.95);
      z-index: 13;
    }
  }
`

export const FloatingMenuContainer = styled.div`
  position: absolute;
  right: -22px;
  top: 4px;
`

export const Header = styled.div`
  ${x};
  ${children};
  justify-content: flex-end;
  font-size: 12px;
  font-weight: bold;
  padding: 12px;
  padding-left: 30px;
  padding-right: 0px;
  color: ${Colors.Text};
  border-bottom: 1px solid ${Colors.BorderBottom};
`

export const ToggleItem = styled.div`
  color: ${(props: ImageProps) => props.isToggled ? Colors.TextDisable : Colors.Text};
`

export const Toggle = styled(Checkbox)`
  ${styledToggle};
`

export const AddButtonContainer = styled.div`
  cursor: pointer;
  margin-left: 10px;

  .fa-plus {
    width: 30px;
    height: 30px;
    background-color: ${Colors.ButtonPrimary};
    color: white;
    border-radius: 50%;
    padding: 10px;
  }
`

export const Link = styled.a`
  text-decoration: underline;
  color: ${Colors.TextUrl};
  cursor: pointer;
`
