import * as React from 'react'

import Button from 'shared/Button'
import LightBox from 'templates/Listing/LightBox'

import { ImageType } from 'templates/Listing/Types'
import { ModalType } from './Types'

import {
  Container,
  Image,
} from './Styled'

import { faExpandAlt } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faExpandAlt])

interface Props {
  images: ImageType[]
}

interface State {
  active: number
  modal: ModalType
}

class Images extends React.Component<Props, State> {
  public state = {
    active: 0,
    modal: {} as ModalType,
  }

  public render = () => {
    const { images } = this.props

    const {
      active,
      modal,
    } = this.state

    return (
      <React.Fragment>
        {modal.lightbox && (
          <LightBox
            images={images}
            active={active}
            onClose={this.closeModal}
          />
        )}
        {images.map((item:ImageType, index:number) => (
          <Container key={index} className='rp-listing-image'>
            <Image src={item.url} />
            <Button.Bubble
              onClick={() => this.openLightBox(index)}
              size={30}
              icon={faExpandAlt}
              type='light'
            />
          </Container>
        ))}
      </React.Fragment>
    )
  }

  private openLightBox = (index: number) => {
    this.setState({
      active: index,
      modal: { lightbox: true },
    })
  }

  private closeModal = (data:any=null) => {
    this.setState({ modal: {} })
  }
}

export default Images
