import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ConfirmAlert from 'sweetalert2'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Dashboard/Actions'
import * as TransactionActions from 'store/Transactions/Actions'

import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Toast, { serverToast } from 'shared/Toast/Toast'
import InviteeModal from '../../InviteeModal'
import ShareWithModal from './ShareWith'

import { sendAccountLink } from 'app/Onboarding/Queries'
import {
  deleteShareTransactionWithUser,
  shareTransactionWithUser
} from 'app/Transactions/Dashboard/TransactionMutations'

import { getLoggedInUser, Routes, Strings } from 'utils'

import {
  Contact,
  Container,
  Header,
  InfoPopup,
  InviteLink,
  List,
  ListItem,
  ProfileImage,
  ProfileInfo,
  ProfilePopup,
  Title
} from './Styled'

import { SubjectEnum } from 'app/Onboarding/Types'
import { TransactionType, UserType } from 'app/Transactions/Details/Types'
import { ShareWith as ShareUserType, TransactionList } from 'store/Transactions/Types'

import { faEnvelope, faEye, faPhone, faRedo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPhone, faEnvelope, faEye, faRedo])

export let ChangeOwner: any

interface StoreProps {
  setNewShareWithUser: (data: ShareUserType) => void
  setDeleteShareWithUser: (userId: string) => void
  setToggleComposeMail: (open: string) => void
  setToggleDetailsSharedWithPopup: () => void
  setShareWithUser: (data: ShareUserType) => void
  setTransactionDetail: (data: TransactionList) => void
  toggleSharedWith: boolean
  toggleCompose: string
}

interface OwnProps {
  people: ShareUserType[]
  transaction: TransactionType
}

type Props = StoreProps & OwnProps & RouteComponentProps<{}, {}>

interface State {
  activeIndex: number
  editSharedUser: ShareUserType
  emailID: string
  isOpen: boolean
  modal: any
  showComposeMail: boolean
  showPeopleDetails: boolean
  showShareWith: boolean
  transactionId: string
  user: UserType
}

class People extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    editSharedUser: {} as ShareUserType,
    emailID: '',
    isOpen: false,
    modal: null,
    showComposeMail: false,
    showPeopleDetails: false,
    showShareWith: false,
    transactionId: '',
    user: {} as UserType,
    userEmail: ''
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    if (!user) {
      return
    }
    this.setState({ user })
  }

  public render() {
    const { emailID, modal, showComposeMail, showShareWith, user } = this.state

    const {
      people,
      toggleSharedWith,
      toggleCompose,
      transaction: { owner }
    } = this.props

    return (
      <Container>
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={emailID} />}
        {toggleCompose && <ComposeModal onClose={this.closeModal} defaultEmail={toggleCompose} />}
        {showShareWith && <ShareWithModal closeModal={this.closeModal} showUser={this.shareWith} shareWith={people} />}
        {toggleSharedWith && (
          <ShareWithModal closeModal={this.closeModal} showUser={this.shareWith} shareWith={people} />
        )}
        {modal}
        {(user.role === 'ADMIN' || user.role === 'MANAGER' || user._id === owner._id) && (
          <Header>
            <Title>
              <span>{Strings.generalText.invite}</span>
              <InfoPopup
                size={'mini'}
                position="bottom center"
                trigger={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
                content={
                  'Collaborator email addresses are the only authorized emails for emailing documents into a transaction'
                }
              />
            </Title>
            <InviteLink onClick={this.showShareWith}>{Strings.generalText.addInvite}</InviteLink>
          </Header>
        )}
        <List>
          {people.map((item: any, index: number) => (
            <ProfilePopup
              key={index}
              position="bottom center"
              hoverable={true}
              trigger={
                <ListItem>
                  <ProfileImage
                    status={item.status}
                    background={item.profileImage}
                    onClick={() => owner !== item._id && this.showInviteeModal(item)}
                  />
                </ListItem>
              }
              content={
                <ProfileInfo>
                  <strong>
                    {item.firstName} {item.lastName}
                  </strong>
                  {item.type !== null && <span>{item.type}</span>}
                  <Contact>
                    {item.status !== 'Pending' && (
                      <React.Fragment>
                        <span onClick={e => this.mailTo(e, item)}>
                          <FontAwesomeIcon icon={['fal', 'envelope']} />
                        </span>
                        <span onClick={e => this.callTo(e, item)}>
                          <FontAwesomeIcon icon={['fal', 'phone']} />
                        </span>
                        {((user.role !== 'GUEST' && user.role !== 'AGENT') || user._id === item._id) && (
                          <span onClick={e => this.viewTo(e, item)}>
                            <FontAwesomeIcon icon={['fal', 'eye']} />
                          </span>
                        )}
                      </React.Fragment>
                    )}
                    {(user.role === 'ADMIN' || user.role === 'MANAGER') &&
                      owner !== item._id && (
                        <span onClick={e => this.deleteTo(e, item)}>
                          <FontAwesomeIcon icon={['fal', 'trash']} />
                        </span>
                      )}
                    {item.status === 'Pending' &&
                      user.role !== 'GUEST' && (
                        <span onClick={e => this.resendMail(e, item)}>
                          <FontAwesomeIcon icon={['fal', 'redo']} />
                        </span>
                      )}
                  </Contact>
                </ProfileInfo>
              }
            />
          ))}
        </List>
      </Container>
    )
  }

  private showInviteeModal = (item: any) => {
    const { transaction, setShareWithUser, setTransactionDetail } = this.props
    const { user } = this.state

    if (user.role === 'GUEST' || user.role === 'AGENT') {
      return
    }

    const modal = (
      <InviteeModal
        transactionId={transaction._id}
        closeModal={this.closeModal}
        shareUser={item}
        getShareWithUser={setShareWithUser}
        getTransactionDetail={setTransactionDetail}
      />
    )
    this.setState({ modal })
  }

  private resendMail = async (e: React.SyntheticEvent<EventTarget>, user: any) => {
    e.stopPropagation()
    try {
      await sendAccountLink({
        type: SubjectEnum.Invite,
        userName: user.userName
      })
      Toast({ message: 'Mail Sent successfully', type: 'success' })
    } catch (error) {
      serverToast(error)
    }
  }

  private shareWith = async (item: any) => {
    const {
      transaction: { _id: transactionId },
      setNewShareWithUser
    } = this.props
    try {
      const res = await shareTransactionWithUser(transactionId, item)
      setNewShareWithUser(res)
    } catch (error) {
      Toast({ message: 'Email already exists', type: 'error' })
    }
  }

  private showShareWith = () => {
    this.setState({ showShareWith: true })
  }

  private mailTo = (e: React.SyntheticEvent<EventTarget>, item: any) => {
    e.stopPropagation()
    this.setState({ showComposeMail: true, emailID: item.userName })
  }

  private callTo = (e: React.SyntheticEvent<EventTarget>, item: any) => {
    e.stopPropagation()
    window.location.href = `tel:${item.phones[0].value}`
  }

  private viewTo = (e: React.SyntheticEvent<EventTarget>, item: any) => {
    e.stopPropagation()
    this.props.history.push({
      pathname: `${Routes.primary.profile.path}/${item._id}`
    })
  }

  private closeModal = () => {
    const { setToggleDetailsSharedWithPopup, setToggleComposeMail } = this.props
    setToggleDetailsSharedWithPopup()
    setToggleComposeMail('')
    this.setState({
      modal: null,
      showComposeMail: false,
      showShareWith: false
    })
  }

  private deleteTo = async (e: React.SyntheticEvent<EventTarget>, item: any) => {
    e.stopPropagation()
    const {
      setDeleteShareWithUser,
      transaction: { _id: transactionId }
    } = this.props
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this user!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        setDeleteShareWithUser(item._id)
        deleteShareTransactionWithUser(item._id, transactionId)
        ConfirmAlert('Deleted!', 'Your user has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your user is safe', 'error')
      }
    })
  }
}

const mapStateToProps = (state: AppState) => ({
  toggleCompose: state.dashboard.toggleComposeMail,
  toggleSharedWith: state.dashboard.toggleSharedWithPopup
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setDeleteShareWithUser: TransactionActions.deleteTransactionShareWithUser,
      setNewShareWithUser: TransactionActions.addShareWithUser,
      setShareWithUser: TransactionActions.getShareWithUser,
      setToggleComposeMail: Actions.toggleComposeMail,
      setToggleDetailsSharedWithPopup: Actions.toggleDetailsSharedWithPopup,
      setTransactionDetail: TransactionActions.getTransactionDetail
    }
  )(People)
)
