// Import Packages
import * as React from 'react'
import { Accordion, Icon } from 'semantic-ui-react'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  StyledAccordion,
  Title,
  Wrapper,
  WrapperSubMenu,
} from './Styled'

interface Props {
  onActive: (contentId: string, contentName: string) => void
  contentData: any
  activeContent: string
  showWorkflowTab: boolean
  showChecklistTab: boolean
  showTexTab: boolean
  showFeeTab: boolean
  user: any
  setLocalizationDefault: boolean
}

interface State {
  activeIndex: number
  defaultShow: boolean
  localization: string
}

const BoardingSetups = ({ content, onActive, activeContent }: any) => (
  <div>
    {
      content.map((items: any, index: number) => {
        return (
          <WrapperSubMenu
            key={index}
            onClick={() => onActive(items._id, items.name)}
            background={activeContent === items._id ? Colors.White525 : Colors.White1000} >
            {items.name}
          </WrapperSubMenu>
        )
      })
    }
  </div>
)

class Content extends React.Component<Props, State> {
  public state = {
    activeIndex: 0.5,
    defaultShow: true,
    localization: 'Localization',
  }

  public async componentDidMount() {
    const { showWorkflowTab, showChecklistTab, showTexTab, showFeeTab } = this.props
    if (showWorkflowTab) {
      this.setState({ defaultShow: false })
    }
    if (showChecklistTab) {
      this.setState({ defaultShow: false })
      this.setState({ activeIndex: 3 })
    }
    if (showTexTab) {
      this.setState({ defaultShow: false })
      this.setState({ activeIndex: 3 })
    }
    if (showFeeTab) {
      this.setState({ defaultShow: false })
      this.setState({ activeIndex: 3 })
    }
  }

  public render() {
    const { activeContent, contentData, user, setLocalizationDefault } = this.props
    const { activeIndex, defaultShow } = this.state
    const contents = contentData.map((items: any, index: number) => {
      return items.name === 'On Boarding Setup' ?
        <StyledAccordion key={index}>
          <div>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={this.handleClick} >
              <Title style={{ color: Colors.Black500 }}>{items.name}</Title>
              <Icon name='dropdown' />
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === index}
              content={<BoardingSetups content={items.content} onActive={this.onActive} activeContent={activeContent} />} />
          </div>
        </StyledAccordion> :
        user[`role`] === 'TEAM_LEADER' || user[`role`] === 'AGENT' || user[`role`] === 'MANAGER' ?
          <Wrapper
            key={index}
            onClick={() => this.onActive(items._id, items.name)}
            background={activeContent === items._id || (setLocalizationDefault === true && defaultShow === true && items.name === 'Workflow Manager') ? Colors.White525 : Colors.White1000} >
            {items.name}
          </Wrapper> : <Wrapper
            key={index}
            onClick={() => this.onActive(items._id, items.name)}
            background={activeContent === items._id || (setLocalizationDefault === true && defaultShow === true && items.name === 'Localization') ? Colors.White525 : Colors.White1000} >
            {items.name}
          </Wrapper>
    })
    return (
      <div>
        {contents}
      </div>
    )
  }

  private handleClick = (e: React.SyntheticEvent<HTMLDivElement>, titleProps: any) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  private onActive = (id: string, name: string) => {
    const { onActive } = this.props
    onActive(id, name)
    this.setState({ defaultShow: false })
  }

}

export default Content
