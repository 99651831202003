// Import Packages
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

const Header = styled.div`
  background: ${Colors.White535};
  color: ${Colors.DarkBlue200};
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
  text-align: center;
`

const StyledForm = styled(Form)`
  &.ui.form {
    padding: 40px;
  }

  &.ui.form .field > label {
    color: ${Colors.Black500};
  }

  .ui.button {
    background-color: ${Colors.DarkBlue200};
    border-radius: 2px;
    font-weight: bold;
    color: ${Colors.White1000};
    font-size: 12px;
  }

  .save-button {
    text-align: center;
  }
`

export { Header, StyledForm }
