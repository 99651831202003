import client from 'queries/apollo'

import { GET_USER_PASSES } from 'queries/graphql/Passes/Queries'
import Store from 'store/Store'

import { DispatchEnum, UserPassType, PassTypeEnum } from 'store/Pass/Types'
import { PaginationType } from 'store/Pagination/Types'

export const getIdxPasses = async () => {
  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_PASSES,
    variables: {
      limit: 100,
      skip: 0,
      sorting: { field: 'settings.mls.shortName', order: 1 },
      where: {
        isActive: true,
        passSection: null,
        passType: PassTypeEnum.Idx,
      }
    }
  })

  Store.dispatch({
    payload: response.data.getUserPasses,
    type: DispatchEnum.SetUserPassDict,
  })

  return response.data.getUserPasses
}

export const getWebsitePassData = async (
  where: Partial<UserPassType.Filter>,
  sorting: Partial<PaginationType.Sort> = {},
  skip: number = 0,
  limit: number = 20
) => {
  const response: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_PASSES,
    variables: {
      limit,
      skip,
      sorting,
      where,
    }
  })

  Store.dispatch({
    payload: response.data.getUserPasses,
    type: DispatchEnum.SetUserPassDict,
  })

  return response.data.getUserPasses
}
