// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import {
  CREATE_MESSAGE_EMAIL,
  DELETE_MESSAGE_EMAIL,
  UPDATE_MESSAGE_EMAIL
} from 'queries/graphql/MessageEmail/Mutations'

export const deleteMessageEmail = async (id: string) => {
  const result = await client.mutate({
    mutation: DELETE_MESSAGE_EMAIL,
    variables: {
      id
    }
  })

  return result
}

export const sendMessageEmail = async (input: any) => {
  const { _id, attachments, html, subject, text, to } = input
  let result: any
  if (_id) {
    result = await client.mutate({
      mutation: UPDATE_MESSAGE_EMAIL,
      variables: {
        _id,
        attachments,
        html,
        subject,
        text,
        to,
        type: 'OUTBOUND'
      }
    })
  } else {
    result = await client.mutate({
      mutation: CREATE_MESSAGE_EMAIL,
      variables: {
        attachments,
        html,
        subject,
        text,
        to,
        type: 'OUTBOUND'
      }
    })
  }

  return result
}
