import gql from 'graphql-tag'

export const CREATE_MY_REPORT = gql`
  mutation createMyReport($module: String, $reportName: String) {
    createMyReport(input: { module: $module, name: $reportName }) {
      _id
      module
      name
    }
  }
`

export const UPDATE_MY_REPORT = gql`
  mutation updateMyReport(
    $id: String
    $reportName: String
    $pickFilterData: JSON
  ) {
    updateMyReport(
      where: { _id: $id }
      input: { name: $reportName, pickFilterData: $pickFilterData }
    ) {
      _id
      module
      name
    }
  }
`

export const DELETE_MY_REPORT = gql`
  mutation deleteMyReport($id: String) {
    deleteMyReport(_id: $id)
  }
`
