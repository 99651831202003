import gql from 'graphql-tag'

export const GET_CONCIERGE_SETTINGS = gql`
  query getConciergeSettings(
    $conciergePassWhere: UserPassFilter
    $docTemplateWhere: DocTemplateFilter
    $idxPassWhere: UserPassFilter
    $product: BillingProductEnum
    $userWhere: UserFilter
  ) {
    isAllowed: getPlanAllowance(product: $product)

    user: getUsers(where: $userWhere) {
      _id
      office {
        _id
        branchName
        mls {
          _id
        }
      }
    }

    conciergePasses: getUserPasses(where: $conciergePassWhere) {
      results {
        _id
        passStatus
      }
    }

    idxPasses: getUserPasses(where: $idxPassWhere) {
      results {
        _id
        settings {
          ... on PassSettingsIdx {
            mls {
              _id
            }
          }
        }
      }
    }

    docTemplates: getDocTemplate(where: $docTemplateWhere) {
      _id
      name
      mls {
        _id
      }
      template {
        _id
        thumbnailUrl
        extractMarkup {
          value
        }
      }
      textRact
    }
  }
`
