import gql from 'graphql-tag'

export const CREATE_MLS = gql`
  mutation createMls($input: MlsInput) {
    createMls(input: $input) {
      _id
      fullName
      shortName
      state
    }
  }
`
