import * as React from 'react'

import { getPaymentMethods } from 'shared/Billing/Queries'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'

import { PaymentMethodType } from 'shared/Billing/Types'
import { OptionType } from './Types'

import { Col, Container, Row, StyledButton, StyledRadio, Title } from './Styled'

interface Props {
  methods: PaymentMethodType[]
  onClose: (paymentMethod: PaymentMethodType | null) => void
}

interface State {
  loading: boolean
  options: OptionType[]
  paymentMethods: PaymentMethodType[]
  selected: string
  transition: boolean
}

class PaymentMethodModal extends React.Component<Props, State> {
  public static defaultProps = {
    methods: [] as PaymentMethodType[]
  }

  public state = {
    loading: true,
    options: [] as OptionType[],
    paymentMethods: [] as PaymentMethodType[],
    selected: '',
    transition: true
  }

  public componentDidMount = async () => {
    let methods = this.props.methods
    if (methods.length === 0) {
      this.setState({ loading: true })
      const result: any = await getPaymentMethods()
      if (!result.error && result.cards && result.cards.length > 0) {
        methods = result.cards
      } else {
        return this.closeSelf({} as PaymentMethodType)
      }
    }

    let selected = 'add_new_method'
    const options: any = methods.map((item: PaymentMethodType) => {
      if (item.isDefault) {
        selected = item.id
      }
      return {
        key: item.id,
        text: `${item.brand.toUpperCase()}: ***${item.last4}`,
        value: item.id
      }
    })
    options.push({
      key: 'add_new_method',
      text: `Add a new payment method`,
      value: 'add_new_method'
    })

    this.setState({
      loading: false,
      options,
      paymentMethods: methods,
      selected
    })
  }

  public render() {
    const { loading, options, selected, transition } = this.state

    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator />}
            <Title>Please select a payment method:</Title>
            <Col primary={true}>
              {options.map((option: OptionType, index: number) => (
                <Row key={index}>
                  <StyledRadio
                    label={option.text}
                    name="paymentMethod"
                    value={option.value}
                    checked={option.value === selected}
                    onChange={this.handleSelection}
                  />
                </Row>
              ))}
            </Col>
            <StyledButton content="Continue" onClick={this.handleSubmit} />
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={() => this.closeSelf(null)}
        width={400}
      />
    )
  }

  private handleSelection = (e: any, { value }: any) => {
    this.setState({ selected: value })
  }

  private handleSubmit = () => {
    const { paymentMethods, selected } = this.state

    const paymentMethod: any =
      paymentMethods.find((item: PaymentMethodType) => selected === item.id) || ({} as PaymentMethodType)

    this.closeSelf(paymentMethod)
  }

  private closeSelf = (paymentMethod: PaymentMethodType | null) => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({ transition: true })
      onClose(paymentMethod)
    }, 300)
  }
}

export default PaymentMethodModal
