import * as React from 'react'
import { connect } from 'react-redux'

import Scrollable from 'shared/Scrollable'
import AddChecklist from './AddChecklist'
import ChecklistItem from './ChecklistItem'

import {
  createChecklist,
  updateTransactionAllDocChecklist as updateAllChecklistItems,
  updateTransactionDocumentChecklists as updateOneChecklistItem,
} from 'app/Transactions/Dashboard/TransactionMutations'
import { getTransactionDocChecklist as getChecklist } from 'app/Transactions/Dashboard/TransactionQueries'

import { AppState } from 'store/CombineReducers'
import * as StoreActions from 'store/Transactions/Actions'

import {
  getLoggedInUser,
  Strings,
} from 'utils'

import {
  Actions,
  Container,
  Header,
  Image,
  StyledButton,
  Title,
} from './Styled'

import { UserType } from 'app/Transactions/Details/Types'
import {
  ActionType,
  ChecklistItemType,
} from './Types'

interface StoreProps {
  checklist: ChecklistItemType[]
  isApproved: boolean
  isReleased: boolean
  setAllChecklistItems: (data: ChecklistItemType[]) => void
  setChecklist: (data: ChecklistItemType) => void
  setOneChecklistItem: (data: ChecklistItemType[]) => void
}

interface OwnProps {
  transactionId: string
}

type Props = OwnProps & StoreProps

interface State {
  allowActions: boolean
  modal: any
}

const actions:ActionType[] = [
  { value: 'Not Applicable', src: Strings.transactionDocumentTab.documentList.document.banIcon },
  { value: 'Not Approved', src: Strings.transactionDocumentTab.documentList.document.removeIcon },
  { value: 'Approved', src: Strings.transactionDocumentTab.documentList.document.completedIcon },
]

class Checklist extends React.Component<Props, State> {
  public state = {
    allowActions: false,
    modal: null,
  }

  public componentDidMount = async () => {
    const {
      isApproved,
      isReleased,
      setChecklist,
      transactionId,
    } = this.props

    const user:UserType = await getLoggedInUser({ fromCache: true }) || {} as UserType
    const allowActions = (user.role === 'ADMIN' || user.role === 'MANAGER') && (!isApproved || !isReleased)

    this.setState({ allowActions })

    const checklist = await getChecklist(transactionId)
    setChecklist(checklist)
  }

  public render() {
    const { checklist } = this.props

    const {
      allowActions,
      modal,
    } = this.state

    return (
      <React.Fragment>
        { modal }
        <Container justify={checklist.length <= 0 ? 'center' : 'flex-start'}>
          {checklist.length > 0 && (
            <React.Fragment>
              <Header>
                <Title>Checklist</Title>
                {allowActions && (
                  <Actions>
                    {actions.map((action:ActionType, index: number) => (
                      <Image
                        key={index}
                        src={action.src}
                        onClick={() => this.assignAll(action)}
                        title={action.value}
                      />
                    ))}
                  </Actions>
                )}
              </Header>
              <Scrollable>
                {checklist.map((item:ChecklistItemType) => (
                  <ChecklistItem
                    key={item._id}
                    item={item}
                    actions={actions}
                    allowActions={allowActions}
                    onAction={this.assignOne}
                  />
                ))}
              </Scrollable>
            </React.Fragment>
          )}
          {(checklist.length === 0 && allowActions) && (
            <StyledButton
              onClick={this.showCreateChecklist}
              content={'Import Checklist'}
            />
          )}
        </Container>
      </React.Fragment>
    )
  }

  private showCreateChecklist = () => {
    const modal = (
      <AddChecklist
        closeModal={this.closeModal}
        createChecklist={this.createChecklist}
      />
    )
    this.setState({ modal })
  }

  private closeModal = () => {
    this.setState({ modal: null })
  }

  private createChecklist = async (checklistId: string) => {
    const {
      setChecklist,
      transactionId,
    } = this.props

    const response = await createChecklist(checklistId, transactionId)
    setChecklist(response)
  }

  private assignAll = async (action: ActionType) => {
    const {
      setAllChecklistItems,
      setChecklist,
      transactionId,
    } = this.props

    const response = await updateAllChecklistItems(transactionId, action.value)
    setAllChecklistItems(response)
    setChecklist(response)
  }

  private assignOne = async (id: string, action: ActionType) => {
    const {
      setChecklist,
      setOneChecklistItem,
      transactionId,
    } = this.props

    const item = await updateOneChecklistItem(id, action.value)
    setOneChecklistItem(item)

    const update = await getChecklist(transactionId)
    setChecklist(update)
  }
}

const mapStateToProps = (state: AppState) => ({
  checklist: state.transactions.documentChecklist,
  isApproved: state.transactions.isApproved,
  isReleased: state.transactions.isReleased,
})

export default connect(
  mapStateToProps,
  {
    setAllChecklistItems: StoreActions.updateTransactionAllDocumentChecklist,
    setChecklist: StoreActions.getTransactionDocumentChecklist,
    setOneChecklistItem: StoreActions.updateTransactionDocumentChecklist,
  }
)(Checklist)
