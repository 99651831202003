// Import Packages
import * as React from 'react'

// Import Styled Components
import {
  StyledSegment,
  Title
} from './Styled'

// Font Awesome Icons
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
  faBallotCheck,
  faBuilding,
  faClone,
  faFolders,
  faHandshake,
  faNewspaper,
  faPlus,
  faUserCircle,
  faUsers
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary(
  [
    faPlus,
    faBallotCheck,
    faFolders,
    faHandshake,
    faWhatsapp,
    faClone,
    faNewspaper,
    faUserCircle,
    faUsers,
    faBuilding
  ]
)

interface Props {
  icon: any
  index: number
  title: string
  color?: string
  className?: string
  activeItem: (index: number, title: string) => void
}

class Segment extends React.Component<Props> {
  public state = {}

  public render() {
    const { color, icon, title, className } = this.props
    let iconTyle: any = ''
    switch (icon) {
      case 'plus':
        iconTyle = 'fas'
        break
      case 'whatsapp':
        iconTyle = 'fab'
        break
      default:
        iconTyle = 'fal'
        break
    }
    return (
      <StyledSegment compact={true} onClick={this.activeItem} className={className}>
        <div style={{ width: '35px', height: '35px' }}><FontAwesomeIcon icon={[iconTyle, icon]} /></div>
        <Title as='h6' background={color}>{title}</Title>
      </StyledSegment>
    )
  }

  private activeItem = () => {
    const { activeItem, index, title } = this.props
    activeItem(index, title)
  }
}

export default Segment
