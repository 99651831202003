// Import Packages
import { Accordion } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background?: string
}

const Container = styled.div`
  height: calc(88vh - 70px);
  overflow: auto;
  background: ${Colors.White1000};
  ${scrollbars};

  .ui.accordion, .ui.accordion .accordion {
    background: ${Colors.White1000};
  }

  .ui.accordion .title .dropdown.icon {
    color: ${Colors.Black565};
  }

  .ui.accordion .title:not(.ui) {
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 5px;
    font-weight: 500;
  }

  span {
    margin-right: 8px;
  }

  .svg-inline--fa {
    width: 18px;
    color: ${Colors.Black500};
  }
`

const Title = styled.div`
  flex-grow: 1;
  color: ${props => props.color || props.color};
`

const Wrapper = styled.div`
  border-bottom: 1px solid ${Colors.Black800};
`

const AccordionTitle = styled(Accordion.Title)`
  background : ${(props: Props) => props.background || props.background};
`

export {
  AccordionTitle,
  Container,
  Title,
  Wrapper
}

