// Import Packages
import styled, { css } from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

interface Props {
  display?: string
}

const row = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Row = styled.div`
  ${row} padding: 10px;
`

const Footer = styled.div`
  font-size: 16px;
  text-align: center;
  margin: 0;
`

const Add = styled.div`
  font-size: 14px;
  color: ${Colors.DarkBlue200};
  cursor: pointer;
  display: ${(props: Props) => props.display || props.display};
`

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.color || Colors.Purple50};
  line-height: 1;
  flex: 1;
`

const PageNav = styled.div`
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }

  & svg {
    color: ${props => props.color || Colors.Purple50};
  }

  &.disabled {
    svg {
      color: #ddd;
    }

    &:hover {
      cursor: not-allowed;
    }
  }
`

export { Add, Footer, Label, PageNav, Row }
