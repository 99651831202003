import styled from 'styled-components'

import {
  x,
  y,
} from 'design/Styled'

export const Container = styled.div`
  ${y};
  height: 100%;
  overflow: hidden;
  align-items: center;
`

export const Row = styled.div`
  ${x};
  overflow: hidden;
`
