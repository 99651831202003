// Import Packages
import * as React from 'react'

// Import Components
import GridView from 'shared/WorkflowGrid'
import {
  CustomAction,
  CustomActivatedOn,
  CustomAddedCount,
  CustomAmount,
  CustomPassName,
  CustomType,
} from './CustomComponents'

import { getPassesForSettings } from 'app/Dashboard/MainContent/Passes/Queries'

// Import Styled Components
import {
  Container,
  ScrollY,
} from './Styled'

interface State {
  checked?: false,
  listViewData?: any
}

export const OfficeOptions = [
  { key: 1, text: 'NEWEST', value: 'NEWEST' },
  { key: 2, text: 'Item1', value: 'Item1' },
  { key: 3, text: 'Item2', value: 'Item2' },
  { key: 4, text: 'Item3', value: 'Item3' },
]

class Passes extends React.Component<State> {
  public state = {
    checked: false,
    listViewData: []
  }

  public columnMetaData = [
    {
      customComponent: CustomPassName,
      enhanceWithRowData: true,
      id: 'name',
      title: 'Pass Name',
    },
    {
      customComponent: CustomActivatedOn,
      enhanceWithRowData: true,
      id: 'createdAt',
      title: 'Activated On',
    },
    {
      customComponent: CustomType,
      enhanceWithRowData: true,
      id: 'passCategory',
      title: 'Type',
    },
    {
      customComponent: CustomAmount,
      enhanceWithRowData: true,
      id: 'price',
      title: 'Amount',
    },
    {
      customComponent: CustomAddedCount,
      enhanceWithRowData: true,
      id: 'addedCount',
      title: 'Usage',
    },
    {
      customComponent: CustomAction,
      enhanceWithRowData: true,
      id: 'action',
      sortable: false,
      title: 'Action',
    }
  ]

  public async componentDidMount() {
    const response = await getPassesForSettings()
    this.setState({ listViewData: response })
  }

  public render() {
    const { listViewData } = this.state
    return (
      <Container>
        <ScrollY>
          <GridView
            data={listViewData}
            tableHeight={50}
            columnMetaData={this.columnMetaData}
          />
        </ScrollY>
      </Container>
    )
  }
}

export default Passes
