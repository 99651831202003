// Import Packages
import { groupBy } from 'lodash'
import * as React from 'react'

// Import Components
import client from 'queries/apollo'
import GoalsNotification from './GoalsNotification'
import LeadNotification from './LeadNotification'
import SystemNotification from './SystemNotification'
import TeamNotification from './TeamNotification'
import TransactionNotification from './TransactionNotification'

// Import Graphql Queries
import { GET_USER_PROFILE } from 'queries/graphql/UserProfile/Queries'
import { updateProfile } from 'app/UserProfile/UserProfileMutations'

import { getLoggedInUser } from 'utils'

// Import Styled Components
import { Container } from './Styled'

interface Notification {
  category: string
  key: string
  title: string
  inApp: boolean
  email: boolean
  inAppSound: boolean
}

interface State {
  goal: Notification[]
  lead: Notification[]
  system: Notification[]
  team: Notification[]
  transaction: Notification[]
  notificationData: any
  userID: string
  userRole: string
}
class Notifications extends React.Component<{}, State> {
  public state = {
    goal: [],
    lead: [],
    notificationData: [],
    system: [],
    team: [],
    transaction: [],
    userID: '',
    userRole: ''
  }

  public componentDidMount() {
    this.getNotification()
  }

  public getNotification = async () => {
    const loggedUser: any = await getLoggedInUser({ fromCache: true })
    const userID = loggedUser._id
    const user = await client.query({
      fetchPolicy: 'network-only',
      query: GET_USER_PROFILE,
      variables: { id: userID }
    })

    this.setState({ userID, userRole: loggedUser.role, notificationData: user.data.getUsers[0].notificationSetting })
    const notificationData = groupBy(user.data.getUsers[0].notificationSetting, 'category')

    if ('Goal' in notificationData) {
      this.setState({ goal: notificationData['Goal'] })
    }
    if ('Lead' in notificationData) {
      this.setState({ lead: notificationData['Lead'] })
    }
    if ('System' in notificationData) {
      this.setState({ system: notificationData['System'] })
    }
    if ('Team' in notificationData) {
      this.setState({ team: notificationData['Team'] })
    }
    if ('Transaction' in notificationData) {
      this.setState({ transaction: notificationData['Transaction'] })
    }
  }

  public render() {
    const { system, team, lead, transaction, goal, userRole } = this.state
    return (
      <Container>
        <SystemNotification
          userRole={userRole}
          system={system}
          updateInApp={this.updateInApp}
          updateEmail={this.updateEmail}
          updateInAppSound={this.updateInAppSound}
        />
        <TeamNotification
          userRole={userRole}
          team={team}
          updateInApp={this.updateInApp}
          updateEmail={this.updateEmail}
          updateInAppSound={this.updateInAppSound}
        />
        <LeadNotification
          userRole={userRole}
          lead={lead}
          updateInApp={this.updateInApp}
          updateEmail={this.updateEmail}
          updateInAppSound={this.updateInAppSound}
        />
        <TransactionNotification
          userRole={userRole}
          transaction={transaction}
          updateInApp={this.updateInApp}
          updateEmail={this.updateEmail}
          updateInAppSound={this.updateInAppSound}
        />
        <GoalsNotification
          userRole={userRole}
          goal={goal}
          updateInApp={this.updateInApp}
          updateEmail={this.updateEmail}
          updateInAppSound={this.updateInAppSound}
        />
      </Container>
    )
  }

  private updateInApp = async (key: string) => {
    const { notificationData, userID } = this.state
    const feeData = notificationData.slice()
    const newData = feeData.map((dataItem: any) => {
      return {
        ...dataItem,
        inApp: dataItem.key === key ? !dataItem.inApp : dataItem.inApp
      }
    })
    const basicDetails = {
      _id: userID
    }
    const newObj = {
      notificationSetting: newData
    }
    await updateProfile(newObj, basicDetails)
    this.getNotification()
  }

  private updateEmail = async (key: string) => {
    const { notificationData, userID } = this.state
    const newData = notificationData.map((dataItem: any) => {
      return {
        ...dataItem,
        email: dataItem.key === key ? !dataItem.email : dataItem.email
      }
    })
    const basicDetails = {
      _id: userID
    }
    const newObj = {
      notificationSetting: newData
    }
    await updateProfile(newObj, basicDetails)
    this.getNotification()
  }

  private updateInAppSound = async (key: string) => {
    const { notificationData, userID } = this.state
    const feeData = notificationData.slice()
    const newData = feeData.map((dataItem: any) => {
      return {
        ...dataItem,
        inAppSound: dataItem.key === key ? !dataItem.inAppSound : dataItem.inAppSound
      }
    })
    const basicDetails = {
      _id: userID
    }
    const newObj = {
      notificationSetting: newData
    }
    await updateProfile(newObj, basicDetails)
    this.getNotification()
  }
}

export default Notifications
