import gql from 'graphql-tag'

import { userPassFragment } from './Fragments'

export const CREATE_PASS_SECTION = gql`
  mutation createPassSection($name: String) {
    createPassSection(input: { name: $name }) {
      _id
      name
    }
  }
`

export const UPDATE_PASS_SECTION = gql`
  mutation updatePassSection($id: String!, $name: String) {
    updatePassSection(_id: $id, input: { name: $name }) {
      _id
      name
    }
  }
`

export const DELETE_PASS_SECTION = gql`
  mutation deletePassSection($id: String!) {
    deletePassSection(_id: $id)
  }
`

export const CREATE_PASS = gql`
  mutation createPass($name: String, $passType: String, $passCategory: String) {
    createPass(
      input: { name: $name, passType: $passType, passCategory: $passCategory }
    ) {
      _id
      name
      passType
      price
      passCategory
      isActive
    }
  }
`

export const CREATE_USER_PASS = gql`
  mutation createUserPass($input: UserPassInput) {
    createUserPass(input: $input) {
      ...UserPassFragment
    }
  }
  ${userPassFragment}
`

export const DELETE_USER_PASS = gql`
  mutation deleteUserPass($id: String!) {
    deleteUserPass(_id: $id)
  }
`

export const UPDATE_USER_PASS = gql`
  mutation updateUserPass($id: String!, $input: UserPassInput) {
    updateUserPass(_id: $id, input: $input) {
      ...UserPassFragment
    }
  }
  ${userPassFragment}
`

export const REORDER_USER_PASS = gql`
  mutation reorderingUserPass($pass: [UserPassFilter]) {
    reorderingUserPass(input: $pass) {
      _id
      order
    }
  }
`

export const UPDATE_USER_WALLPAPER = gql`
  mutation updateUserWallpaper(
    $id: String
    $defaultUrl: String
    $defaultColor: String
    $colors: String
    $file: Upload
  ) {
    updateUserWallpaper(
      where: { _id: $id }
      input: {
        defaultUrl: $defaultUrl
        colors: $colors
        defaultColor: $defaultColor
      }
      file: $file
    ) {
      _id
      defaultColor
      colors
      defaultUrl
      imageUrls
      user {
        _id
        firstName
      }
    }
  }
`

export const DELETE_USER_COLOR_WALLPAPER = gql`
  mutation deleteUserColorWallpaper(
    $id: String!
    $colors: [String]
    $imageUrls: [String]
  ) {
    deleteUserColorWallpaper(
      _id: $id
      input: { colors: $colors, imageUrls: $imageUrls }
    ) {
      _id
      defaultColor
      colors
      defaultUrl
      imageUrls
      user {
        _id
        firstName
      }
    }
  }
`
