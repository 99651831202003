import { Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import {
  children,
  childWidths,
  x,
  y
} from 'design/Styled'

export const Container = styled.div`
  ${y};
  width: 100%;
  height: 100%;
  font: ${fonts.small.regular};
  color: ${Colors.Text};
  background: ${Colors.Container};
`

export const Line = styled.div`
  ${x};
  ${children};
  ${childWidths};
  width: 100%;
  border-bottom: 1px solid ${Colors.TableLine};

  &:hover {
    background: ${Colors.ListOdd};
  }

  & > :first-child {
    padding-left: 1.8em;
  }
  & > :last-child {
    padding-right: 1.8em;
  }
`

export const HeadLine = styled(Line)`
  color: ${Colors.TextPrimary};
  font-weight: bold;
  background: ${Colors.ListEven};
  border-bottom: 1px solid ${Colors.TableHeadLine};
  padding: 1em 0;
`

export const Col = styled.div`
  ${x};
  ${children};
  padding: 0.4em 1em;
`

export const Tax = styled(Col)`
  justify-content: space-between;
  &:hover {
    background: ${Colors.ListEven};
  }
`


export const Span = styled.span`
  ${x};
  ${children};
  padding: 0.6em 0;
`

export const Locale = styled(Span)`
  flex: 1
  width: auto;
`

export const Amount = styled(Span)`
  width: 40%;
  font-weight: bold;
  text-align: left;
`

export const StyledInput = styled(Input)`
  width: 40%;
`

export const Form = styled.form`
  ${y};
`

export const Submit = styled.button`
  position: fixed;
  top: -999px;
  left: -999px;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
`
