import { Action } from 'redux'

export interface TaxType {
  _id: string
  name: string
  tax: number
}

export interface TaxOfficeType {
  _id: string
  branchName: string
  city: TaxType
  state: TaxType
}

export interface TaxDictType {
  [officeId:string]: TaxOfficeType
}

export enum DispatchEnum {
  SetTax = '@@SET_TAX',
  SetTaxDict = '@@SET_TAX_DICT',
  SetTaxList = '@@SET_TAX_LIST',
}

export interface TaxState {
  dict: TaxDictType
  list: string[]
}

export interface SetTaxAction extends Action {
  payload: Partial<TaxOfficeType>
  type: DispatchEnum
}

export interface SetTaxDictAction extends Action {
  payload: TaxDictType
  type: DispatchEnum
}

export interface SetTaxListAction extends Action {
  payload: string[]
  type: DispatchEnum
}
