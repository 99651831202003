import { Component } from 'react'
import * as React from 'react'
import { match as MatchType } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { Routes } from 'utils'
import ContactDetail from './ContactDetail'

const {
  details
} = Routes.contacts

interface Props {
  match: MatchType<{}>
}
export default class ContactDetails extends Component<Props> {
  public render() {
    const { match } = this.props
    return (
      <Switch>
        <Route path={match.url + details.path + '/:id'} component={ContactDetail} />
      </Switch>
    )
  }
}
