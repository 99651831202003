// Import Packages
import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { Grid, Popup } from 'semantic-ui-react'
import { VictoryPie } from 'victory'

// Import Images and Icons
import IconCamera from 'design/icons/userProfile/userInfo/camera-icon.png'
import IconCall from 'design/icons/userProfile/userInfo/icon-call.png'
import IconEnvelope from 'design/icons/userProfile/userInfo/icon-envelope.png'

// Import Components
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import { uploadProfilePic } from '../../UserProfileMutations'

// Import Store Types, Actions and Reducers
import * as Actions from 'store/Dashboard/Actions'
import { UserProfileType } from 'store/UserProfile/Types'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  ContactView,
  Container,
  ImageWrapper,
  Label,
  Li,
  MemberProfile,
  Name,
  ProfileImageContainer,
  ReferralView,
  SocialAction,
  SocialButton,
  StyledLabel,
  StyledPopup,
  TopSegment,
  Ul
} from './Styled'

// Font Awesome Icons
import { faCopy, faEye, faShareAlt } from '@fortawesome/pro-light-svg-icons'
import { faUsdCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCopy, faShareAlt, faEye, faUsdCircle])

const UserInfoStrings = Strings.userProfile.userInfo

interface StoreProps {
  setUserProfile: (profile: string) => void
}

interface OwnProps {
  email: string
  firstName: string
  lastName: string
  mobileNo: string
  rank: number
  referrals: {
    joined: number
    pending: number
  }
  totalEmployees: number
  openReferralPopup: () => void
  userProfile: UserProfileType
  showEditButton: string
  profileProgress: number
}

type Props = StoreProps & OwnProps

interface State {
  fileName: string
  profilePercent: number
  file: File
  userId: string
  showComposeMail: string
  isOpen: boolean
}

class PersonalInfo extends React.Component<Props, State> {
  public state = {
    file: {} as File,
    fileName: '',
    isOpen: false,
    profilePercent: 0,
    showComposeMail: '',
    userId: ''
  }

  public textInput: any = React.createRef()

  public async componentDidMount() {
    const { profileProgress, userProfile } = this.props
    this.setState({})
    const user: any = await getLoggedInUser({ fromCache: true })
    this.setState({ userId: user._id, profilePercent: profileProgress, fileName: userProfile.profileImage })
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({ profilePercent: nextProps.profileProgress })
  }

  public getData = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public render() {
    const { userProfile, showEditButton } = this.props
    const { fileName, profilePercent, userId, showComposeMail } = this.state
    const Card = userProfile.referrals.map((items: any, index: number) => {
      while (index < 2) {
        return (
          <Li key={index}>
            <StyledPopup
              trigger={<MemberProfile key={index} background={items.profileImage} />}
              content={`${items.firstName} ${items.lastName}`}
            />
          </Li>
        )
      }
      return null
    })
    const Count =
      userProfile.referrals.length > 2 ? (
        <Label>
          <StyledLabel>+</StyledLabel>
          <FontAwesomeIcon icon={['fas', 'usd-circle']} />
        </Label>
      ) : null
    return (
      <TopSegment>
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={showComposeMail} />}
        <Container>
          <ProfileImageContainer>
            <ReactResizeDetector handleWidth={true} handleHeight={true}>
              {(width: number = 200, height: number = 200) => {
                let finalHeight
                let finalWidth
                if (height === width) {
                  finalHeight = height
                  finalWidth = width
                } else if (height > width) {
                  finalHeight = width
                  finalWidth = width
                } else {
                  finalWidth = height
                  finalHeight = height
                }
                return (
                  <VictoryPie
                    standalone={true}
                    padding={0}
                    startAngle={0}
                    endAngle={360}
                    data={this.getData(profilePercent)}
                    innerRadius={180}
                    cornerRadius={0}
                    labels={['', '']}
                    colorScale={[Colors.Green500, Colors.Black950]}
                    style={{
                      parent: {
                        backgroundImage: `url(${fileName})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '50%',
                        height: finalHeight - 20,
                        position: 'absolute',
                        width: finalWidth - 20
                      }
                    }}
                  />
                )
              }}
            </ReactResizeDetector>
            {(showEditButton === 'ADMIN' ||
              showEditButton === 'MANAGER' ||
              showEditButton === 'USER' ||
              userId === userProfile._id) && (
              <ImageWrapper backgroundImage={IconCamera} className="changeImageIcon" onClick={this.handleFile} />
            )}
            <input
              type="file"
              ref={(node: any) => {
                this.textInput = node
              }}
              accept="image"
              id="hidden-new-file"
              style={{ display: 'none' }}
              name="fileName"
              onChange={(e: any) => this.setFiles(e)}
            />
          </ProfileImageContainer>
          <Name>{`${userProfile.firstName} ${userProfile.lastName}`}</Name>
          <ContactView>
            <StyledPopup
              trigger={
                <ImageWrapper
                  backgroundImage={IconEnvelope}
                  onClick={() => this.showComposeMail(userProfile.userName)}
                />
              }
              content={userProfile.userName ? userProfile.userName : 'No Added Email'}
            />
            <StyledPopup
              trigger={<ImageWrapper backgroundImage={IconCall} />}
              content={userProfile.phones.length !== 0 ? userProfile.phones[0].value : ''}
            />
          </ContactView>
          <ReferralView onClick={this.props.openReferralPopup}>
            <div className="referral">
              <div>{UserInfoStrings.referrals}</div>
              <div style={{ display: 'flex' }}>
                <Popup
                  trigger={
                    <span>
                      <CopyToClipboard text={userProfile.referralUrl}>
                        <FontAwesomeIcon icon={['fal', 'copy']} />
                      </CopyToClipboard>
                    </span>
                  }
                  content="URL copied!"
                  size="mini"
                  inverted={true}
                  position="top center"
                  on="click"
                  open={this.state.isOpen}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                />
                <Popup
                  trigger={
                    <span>
                      <FontAwesomeIcon icon={['fal', 'share-alt']} />
                    </span>
                  }
                  flowing={true}
                  hoverable={true}
                >
                  <Grid centered={true} divided={true} columns={4}>
                    <Grid.Column
                      textAlign="center"
                      onClick={(e: React.SyntheticEvent<EventTarget>) => {
                        e.stopPropagation()
                        window.open('https://www.facebook.com')
                      }}
                    >
                      <SocialButton>
                        <SocialAction icon={['fab', 'facebook']} className="fa-sm" />
                      </SocialButton>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="center"
                      onClick={(e: React.SyntheticEvent<EventTarget>) => {
                        e.stopPropagation()
                        window.open('https://linkedin.in')
                      }}
                    >
                      <SocialButton>
                        <SocialAction icon={['fab', 'linkedin']} className="fa-sm" />
                      </SocialButton>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="center"
                      onClick={(e: React.SyntheticEvent<EventTarget>) => {
                        e.stopPropagation()
                        window.open('https://twitter.com')
                      }}
                    >
                      <SocialButton>
                        <SocialAction icon={['fab', 'twitter-square']} className="fa-sm" />
                      </SocialButton>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="center"
                      onClick={(e: React.SyntheticEvent<EventTarget>) => {
                        e.stopPropagation()
                        window.open('https://gmail.com')
                      }}
                    >
                      <SocialButton>
                        <SocialAction icon={['fas', 'envelope-square']} className="fa-sm" />
                      </SocialButton>
                    </Grid.Column>
                  </Grid>
                </Popup>
                <span onClick={this.props.openReferralPopup}>
                  <FontAwesomeIcon icon={['fal', 'eye']} />
                </span>
              </div>
            </div>
            <div className="share">
              <Ul style={{ display: 'flex' }}>
                {Card}
                <Li>{Count}</Li>
              </Ul>
            </div>
          </ReferralView>
        </Container>
      </TopSegment>
    )
  }

  private handleOpen = (e: React.SyntheticEvent<EventTarget>) => {
    e.stopPropagation()
    this.setState({ isOpen: true })

    setTimeout(() => {
      this.setState({ isOpen: false })
    }, 2000)
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
  }

  private showComposeMail = (email: string) => {
    this.setState({ showComposeMail: email })
  }

  private closeModal = () => {
    this.setState({ showComposeMail: '' })
  }

  private handleFile = () => {
    this.textInput.click()
  }

  private setFiles = (e: any) => {
    const { userProfile, setUserProfile } = this.props
    const { userId } = this.state
    const file = e.target.files[0]
    this.setState({
      file: e.target.files[0],
      fileName: URL.createObjectURL(e.target.files[0])
    })
    if (userId === userProfile._id) {
      setUserProfile(URL.createObjectURL(e.target.files[0]))
    }
    uploadProfilePic(userProfile._id, file)
  }
}

export default connect(
  null,
  {
    setUserProfile: Actions.getUserProfile
  }
)(PersonalInfo)
