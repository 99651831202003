// Import Packages
import { Accordion, Checkbox, Form } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background?: string
  border?: string
}

const Container = styled.div`
  margin-top: 20px;

  h2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${Colors.DarkBlue205};
  }
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
  border-top: 3px solid ${(props: Props) => props.border || Colors.White1000};
  white-space: nowrap;
  cursor: pointer;
`

const TriangleLeft = styled.div`
  position: absolute;
  border-top: 23px solid ${Colors.transparent};
  border-bottom: 23px solid ${Colors.transparent};
  border-left: 23px solid ${Colors.White500};
  top: 0;
`

const TriangleRight = styled.div`
  position: absolute;
  border-top: 23px solid ${Colors.transparent};
  border-bottom: 23px solid ${Colors.transparent};
  border-left: 23px solid ${Colors.White1000};
  right: -23px;
  top: 0;
`

const Text = styled.div`
  background-color: ${Colors.White1000};
  height: 45px;
  padding: 12px;
  padding-left: 20px;
  color: ${Colors.DarkBlue200};
  font-weight: 500;

  i {
    margin-right: 8px;
    font-family: Georgia, serif;
    font-size: 24px;
    font-weight: bold;
    margin-left: 14px;
  }
`

const Reports = styled.div`
  background-color: ${Colors.White1000};
  height: 470px;
  overflow: auto;
  ${scrollbars};
`

const AccordionContainer = styled.div`
  padding-top: 25px;

  .ui.form .field > label {
    font-size: 12px;
    color: ${Colors.Black550};
  }

  .ui.action.input>.button {
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

const StyledAccordion = styled(Accordion)`
  &.ui.accordion .title {
    background-color: ${Colors.White525};
    color: ${Colors.Black555} !important;
    margin-bottom: 5px;
    font-size: 14px !important;
    user-select: none;
  }

  &.ui.accordion:not(.styled) .title~.content:not(.ui) {
    padding: 0;
  }

  &.ui.accordion .accordion {
    margin-left: 20px;
  }

  .fa-caret-down {
    float: right;
    margin-right: 20px;
    width: 12px;
    height: 12px;
    margin-top: 4px;
    color: ${Colors.Black555};
  }

  .fa-caret-right {
    float: right;
    margin-right: 20px;
    width: 12px;
    height: 12px;
    margin-top: 4px;
    color: ${Colors.Black555};
  }

`

const StyledContent = styled.div`
  font-size: 13px;
  border-bottom: 1px solid ${Colors.Grey555};
  padding: 10px;
  padding-left: 20px;
  display: flex;
  cursor: pointer;
  background-color: ${(props: Props) => props.background || props.background};

  span {
    margin-left: 14px;
    color: ${Colors.Black555};
  }
`

const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox {
    min-height: 14px;
  }

  &.ui.fitted.checkbox label {
    padding-left: 1.85714em !important;
  }

  &.ui.checkbox label:before {
    width: 14px;
    height: 14px;
    top: 2px;
    border: 1.5px solid ${Colors.Black555};
    border-radius: 2px;
  }
`

const StyledForm = styled(Form)`
  width: 100%;
  padding: 22px;
  padding-top: 5px;
  padding-bottom: 15px;

  .ui.fluid.dropdown {
    margin-bottom: 20px;
    margin-top: 6px;
  }

  &.ui.form .field > label {
    font-size: 12px;
    color: ${Colors.Black550};
  }

  .ui.button {
    background: ${Colors.DarkBlue200};
    border-radius: 3px;
    color: ${Colors.White1000};
  }

  a.ui.label {
    color: ${Colors.Blue60};
    position: relative;
    background-color: ${Colors.White490};
    font-size: 10px;
    min-width: 38px;
    width: auto;
    margin-bottom: 5px;
  }

  .ui.action.input>.button {
    background: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }

  .fa-times {
    position: absolute;
    top: -4px;
    right: 2px;
    height: 10px;
    width: 8px;
    color: ${Colors.Black530};
  }
`

const MyLeads = styled.div`
  display: flex;
  margin-left: 25px;
  margin-top: 6px;
  font-size: 14px;
  margin-top: 10px;
  color: ${Colors.Black550};
`

const StyledCheck = styled(Checkbox)`
  margin-left: 10px;

  &.ui.toggle.tester input:checked~.box:before,
  &.ui.toggle.tester input:checked~label:before,
  &.ui.toggle.tester input:focus:checked~.box:before,
  &.ui.toggle.tester input:focus:checked~label:before {
    width: 24px;
    height: 15px;
    background-color: ${Colors.DarkBlue200} !important;
  }

  &.ui.toggle.checkbox label::before {
    width: 24px;
    height: 15px;
    background-color: ${Colors.Grey565} !important;
  }

  &.ui.toggle.checkbox input:checked~label:after {
    left: 0.55rem !important;
  }

  &.ui.toggle.checkbox label::after {
    width: 12px;
    height: 11px;
    margin-top: 2px;
    margin-left: 2px;
  }

  &.ui.fitted.toggle.checkbox {
    margin-left: 10px;
    margin-top: 1px;
  }

  &.ui.fitted.toggle.checkbox {
    width: 2.7rem;
  }
`

const Infograph = styled.div`
  background: ${(props: Props) => `url(${props.background}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
  align-self: center;
`

export {
  AccordionContainer,
  Container,
  Reports,
  StyledAccordion,
  StyledCheck,
  StyledCheckbox,
  StyledContent,
  Title,
  TriangleLeft,
  TriangleRight,
  Text,
  StyledForm,
  MyLeads,
  Infograph,
}
