import client from 'queries/apollo'

import { GET_DEFAULT_OFFICE_DETAILS } from 'queries/graphql/Offices/Queries'
import { GET_TENANT } from 'queries/graphql/Tenant/Queries'

export const getTenantDetails = async (id: string) => {
  const response = await client.query({
    query: GET_TENANT,
    variables: { id }
  })

  return response.data.getTenants[0]
}

export const getDefaultOfficeDetails = async () => {
  const response = await client.query({
    query: GET_DEFAULT_OFFICE_DETAILS
  })

  return response.data.getOffices[0]
}
