// Import Packages
import * as React from 'react'
import { Button, Form, Image, Select } from 'semantic-ui-react'

// Import Images and Icons
import Modal from 'shared/Modal'

// Import Graphql Queries
import { Options } from 'store/Transactions/Types'
import { getReassignUsers } from '../../../Leads/Dashboard/LeadQueries'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  AddForm,
  Container,
  LeftPanel,
  StyledForm,
} from './Styled'

interface Props {
  closeModal: () => void
  changeOwnership: (data: string) => void
  transactionOwnerId: string
}

interface State {
  animate: boolean
  users: Options[]
  selectedUser: Options,
}

class ChangeOwnership extends React.Component<Props, State> {
  public state = {
    animate: true,
    selectedUser: {
      key: '',
      text: '',
      value: ''
    },
    users: [],
  }

  public async componentDidMount() {
    const { transactionOwnerId } = this.props
    const users = await getReassignUsers()
    if (users) {
      const userList: any = []
      users.forEach((element: any) => {
        if (element.role !== 'GUEST' && element.status !== 'Pending' && element.status !== 'Inactive' && element._id !== transactionOwnerId) {
          userList.push({
            key: element._id,
            text: `${element.firstName} ${element.lastName}`,
            value: `${element.firstName} ${element.lastName}, ${element._id}`
          })
        }
      })
      this.setState({ users: userList })
    }
  }

  public render() {
    const { animate, users } = this.state
    return (
      <Modal content={
        <Container>
          <LeftPanel>
            <Image src={Strings.generalText.src} size='small' />
          </LeftPanel>
          <AddForm>
            <StyledForm>
              <Form.Field
                control={Select}
                label='Please type in new owner'
                name='changeOwnership'
                placeholder='Please type in new owner'
                options={users}
                openOnFocus={true}
                search={true}
                selection={true}
                onChange={this.handleChange} />
              <Button
                style={{ width: '170px' }}
                type="button"
                onClick={this.changeOwnership}
                content="Change" />
            </StyledForm>
          </AddForm>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={600} />
    )
  }

  private handleChange = async (event: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const res = value.split(', ')
    this.setState({
      selectedUser: {
        key: res[1],
        text: res[0],
        value: `${res[0]}, ${res[1]}`
      }
    })
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private changeOwnership = () => {
    const { closeModal, changeOwnership } = this.props
    const { selectedUser } = this.state
    changeOwnership(selectedUser.key)
    closeModal()
  }
}

export default ChangeOwnership
