// Import Packages
import { Grid, Table } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars, y } from 'design/Styled'

const Container = styled.div`
  ${y};
  width: 98%;
  height: 100%;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: ${Colors.White1000};

  .table{
    height: auto;
    overflow: auto;
    ${scrollbars};
  }
`

const Transaction = styled.div`
  padding: 20px;
  padding-left: 40px;
`

const StyledGrid = styled(Grid)`
  &.ui.grid>.row>[class*="six wide"].column {
    display:flex;
    justify-content: flex-end;
    padding-right: 0;
    align-items: center;

    @media(max-width: 1250px) {
      width: 50%!important;
    }
  }

  &.ui.grid>.row>[class*="ten wide"].column {
    font-size: 14px;
    font-weight: 500;
    color: ${Colors.Black500};
    align-self: center;

    @media(max-width: 1250px) {
      width: 50%!important;
    }
  }

  .more {
    margin-top: 4px;
  }
`

const Option = styled.div`
  font-size: 14px;
  margin-right: 20px;
  color: ${Colors.DarkBlue200};
  font-weight: 500;
  cursor: pointer;
  display: flex;

  .fa-print {
    width: 30px;
    height: 20px;
    margin-right: 5px;
    color: ${Colors.DarkBlue200};
  }

  .fa-file-export {
    width: 30px;
    height: 20px;
    margin-right: 5px;
    color: ${Colors.DarkBlue200};
  }

  .ui.button {
    border-radius: 0;
    width: 100px;
    font-size: 12px;
    background-color: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }

  &.export {
    /* border-right: 2px solid ${Colors.DarkBlue200}; */
    padding-right: 20px;
  }
`

const UserProfile = styled.div`
  padding: 20px;
  padding-left: 40px;
  padding-top: 1px;
  background-color: ${Colors.White535};
  padding-bottom: 3px;
`

const StyledGridView = styled.div`
  background: ${Colors.White1000};
  padding-top: 15px;
  ${scrollbars};

`

const StyledTable = styled(Table)`
  &.ui.table {
    font-size: 0.8em;
    border-radius: 0;
    border: none;
    width: 100%;
    border-bottom: 1px solid ${Colors.Grey125};
  }

  &.ui.sortable.table thead th {
    color: ${Colors.DarkBlue205};
    border: none;
  }
`

export {
  Container,
  Option,
  StyledGrid,
  StyledGridView,
  Transaction,
  UserProfile,
  StyledTable,
}

