import moment from 'moment'
import * as React from 'react'

import ActionButtons from '../ActionButtons'

import { Container, Filename, Owner, Row, StyledForm, StyledInput, Thumbnail, Title } from './Styled'

import { PermissionEnum } from 'shared/DocumentZone/Types'

import { PDFDocumentType } from 'shared/PDFViewer/Types'

import { ActionEnum } from '../ActionButtons/Types'

import { FormErrorType, FormType } from '../Types'

interface Props {
  actions: string[]
  doc: PDFDocumentType
  getPermission: (doc: PDFDocumentType) => Promise<PermissionEnum>
  onAction: (action: ActionEnum, data: any) => void
  user: any
}

interface State {
  form: FormType
  image: string
  permission: PermissionEnum
  showRenameInput: boolean
}

class ListItem extends React.Component<Props, State> {
  public state = {
    form: {
      errors: {} as FormErrorType,
      fileName: ''
    },
    image: '',
    permission: PermissionEnum.None,
    showRenameInput: false
  }

  public componentDidMount = () => {
    this.initForm()
    this.getPermission()
    this.renderImage()
  }

  public componentDidUpdate = (prev: Props) => {
    const {
      doc: { fileName },
      user
    } = this.props

    if (fileName !== prev.doc.fileName) {
      this.initForm()
    }
    if (user !== prev.user) {
      this.getPermission()
    }
  }

  public render() {
    const { doc, actions } = this.props

    const {
      form,
      form: { errors },
      image,
      permission,
      showRenameInput
    } = this.state

    return (
      <Container onClick={(e: any) => e.stopPropagation()}>
        <Row primary={3} onClick={this.handleSelect}>
          <Thumbnail isLocked={doc.isLocked} image={image} />
          <Title isViewed={doc.isViewed}>
            {permission === PermissionEnum.Owner && showRenameInput ? (
              <StyledForm onSubmit={this.toggleRename}>
                <StyledInput
                  className="rp-inline-input"
                  autoFocus={true}
                  error={!!errors.fileName}
                  name="fileName"
                  onChange={this.handleInputChange}
                  onBlur={this.toggleRename}
                  placeholder="Rename Document"
                  value={form.fileName}
                />
              </StyledForm>
            ) : (
              <Filename>{form.fileName}</Filename>
            )}
          </Title>
        </Row>
        <Row primary={1} justify={'center'}>
          <Owner>
            ({doc.owner.firstName} {doc.owner.lastName})
          </Owner>
        </Row>
        <Row primary={1} justify={'center'}>
          {moment(doc.createdAt).format('L')}
        </Row>
        <Row primary={1} justify={'flex-end'}>
          <ActionButtons
            actions={actions}
            doc={doc}
            onAction={this.handleAction}
            permission={permission}
            vertical={false}
          />
        </Row>
      </Container>
    )
  }

  private initForm = () => {
    const {
      doc: { fileName }
    } = this.props
    const { form } = this.state
    form.fileName = fileName

    this.setState({ form })
  }

  private getPermission = async () => {
    const { doc, getPermission } = this.props
    const permission = await getPermission(doc)
    this.setState({ permission })
  }

  private renderImage = async () => {
    const {
      doc: { thumbnailUrl }
    } = this.props
    this.setState({ image: thumbnailUrl })

    const img = new Image()
    img.src = thumbnailUrl
    img.onerror = () => {
      this.setState({ image: '' })
    }
  }

  private handleSelect = (e: any) => {
    e.stopPropagation()

    const { doc, onAction } = this.props
    const { permission } = this.state
    if (permission === PermissionEnum.Owner) {
      onAction(ActionEnum.View, doc)
    }
  }

  private handleAction = (action: ActionEnum, data: any) => {
    const { onAction } = this.props
    switch (action) {
      case ActionEnum.Rename:
        this.toggleRename(null)
        return
    }
    onAction(action, data)
  }

  private toggleRename = (e: any) => {
    if (e) {
      e.stopPropagation()
    }
    const { doc, onAction } = this.props
    const { permission } = this.state
    if (permission !== PermissionEnum.Owner) {
      return
    }

    this.setState((prev: State) => {
      const form = prev.form
      const errors = {} as FormErrorType
      let showRenameInput = prev.showRenameInput

      if (prev.showRenameInput) {
        if (form.fileName.length === 0 || form.fileName.trim() === '') {
          errors.fileName = 'Filename can not be blank'
          form.errors = errors
        } else {
          form.fileName = `${form.fileName.trim()}.pdf`
          showRenameInput = false

          const update = { ...doc }
          update.fileName = form.fileName
          onAction(ActionEnum.Rename, update)
        }
      } else {
        form.fileName = form.fileName.split('.')[0]
        showRenameInput = true
      }

      return {
        form,
        showRenameInput
      }
    })
  }

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { form } = this.state
    form[e.target.name] = e.target.value
    this.setState({ form })
  }
}

export default ListItem
