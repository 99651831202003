import * as React from 'react'
import SpinningBubbles from 'react-loading'

import Colors from 'design/Colors'

import { Container, Message, SpinnerMessage, Stripes, StyledDimmer } from './Styled'

import { IndicatorTypeEnum } from './Types'

interface Props {
  height: number
  message: string
  type: IndicatorTypeEnum
}

interface State {
  stagger: number
}

class LoadingIndicator extends React.Component<Props, State> {
  public static defaultProps = {
    height: 2,
    message: 'loading...',
    type: IndicatorTypeEnum.Banner
  }

  public state = {
    stagger: 0
  }

  public componentDidMount = () => {
    const stagger = Math.ceil(Math.random() * 200)
    this.setState({ stagger })
  }

  public render = () => {
    const { height, message, type } = this.props
    const { stagger } = this.state

    if (type === IndicatorTypeEnum.Spinner) {
      return (
        <StyledDimmer active={true} inverted={true} className="rp-loading-indicator">
          <SpinningBubbles type={'spinningBubbles'} color={Colors.DarkBlue200} />
          <SpinnerMessage>{message}</SpinnerMessage>
        </StyledDimmer>
      )
    } else {
      return (
        <Container className="rp-loading-indicator">
          <Stripes className="rp-loading-stripes" stagger={stagger} height={height}>
            <Message className="rp-loading-message">{message}</Message>
          </Stripes>
        </Container>
      )
    }
  }
}

export default LoadingIndicator
