// Import Packages
import * as React from 'react'
import { Component } from 'react'

// Import Styled Components
import { Line, VerticalLinesWrapper } from './Styled'

export interface DocumentItem {
  id: string
  name: string
}

interface Props {
  documents: DocumentItem[]
  height: number
}

class VerticalLines extends Component<Props> {
  public render() {
    const { documents, height } = this.props
    return (
      <VerticalLinesWrapper>
        {
          documents.map((data, i) => (i < documents.length - 1) && <Line key={i} height={height} />)
        }
        <Line height={height} />
      </VerticalLinesWrapper>
    )
  }
}

export default VerticalLines
