import styled from 'styled-components'

import Colors from 'design/Colors'
import { y } from 'design/Styled'

export const Container = styled.section`
  ${y};
  padding: 0 0.8em;
  font-size: 15px;

  & > section {
    border-bottom: 1px solid ${Colors.EmphasisLine};
  }

  section:last-child {
    border: none;
  }
`

export const Section = styled.section`
  ${y};
  padding: 0.8em 0;
`
