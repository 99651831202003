// Import Packages
import * as React from 'react'

// Import Images and Icons
import ExportIcon from 'design/icons/leads/contact_icon_1.png'
import ImportIcon from 'design/icons/leads/contact_icon_2.png'
import SortIcon from 'design/icons/leads/contact_icon_4.png'
import DeleteIcon from 'design/icons/leads/contact_icon_5.png'

// Import Components
import { deleteBoard, exportUser, importUser, sortLeads } from '../Leads'
import ActionItem from './ActionItem'

// Import Utils
import { Strings } from 'utils'

interface Props {
  boardId: string
  closeActionPopup: () => void
  showAddUserPopup: () => void
  showImportUsersPopup: () => void
  showAddButton?: boolean
  showAppearance?: boolean
}

interface State {
  sortOrder: number
}

class Actions extends React.Component<Props, State>{
  public state = {
    sortOrder: 1
  }

  public render() {
    const { showAddButton, showAppearance } = this.props
    return (
      <div>
        {showAddButton && <span onClick={this.showExportFileModal}><ActionItem title={Strings.users.kanbanView.export} text={Strings.users.kanbanView.actionText} icon={ExportIcon} /></span>}
        {showAddButton && <span onClick={this.showImportFileModal}><ActionItem title={Strings.users.kanbanView.import} text={Strings.users.kanbanView.actionText} icon={ImportIcon} /></span>}
        <span onClick={this.handleChange}><ActionItem title={Strings.kanbanView.sort} text={Strings.kanbanView.actionText} icon={SortIcon} /></span>
        {showAppearance && <span onClick={this.deleteBoard}><ActionItem title={Strings.kanbanView.delete} text={Strings.kanbanView.actionText} icon={DeleteIcon} /></span>}
      </div>
    )
  }

  private showImportFileModal = () => {
    const { closeActionPopup, boardId } = this.props
    importUser(boardId)
    closeActionPopup()
  }

  private showExportFileModal = () => {
    const { closeActionPopup, boardId } = this.props
    exportUser(boardId)
    closeActionPopup()
  }

  private deleteBoard = () => {
    const { boardId, closeActionPopup } = this.props
    deleteBoard(boardId)
    closeActionPopup()
  }

  private handleChange = () => {
    const { sortOrder } = this.state
    const { boardId } = this.props
    if (sortOrder === 1) {
      this.setState({ sortOrder: -1 })
      sortLeads(boardId, this.state.sortOrder)
    } else {
      this.setState({ sortOrder: 1 })
      sortLeads(boardId, this.state.sortOrder)
    }
  }
}

export default Actions
