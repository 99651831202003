// Import Packages
import * as React from 'react'
import { Image } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'

// Import Store Types, Actions and Reducers
import { LeadItemsDetails } from 'store/Leads/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  Container,
  Text
} from './Styled'

interface Props {
  handleClose: () => void
  lead: LeadItemsDetails
}

interface State {
  animate: boolean
}

export default class ImportLeadsPopup extends React.Component<Props, State> {
  public state = {
    animate: true
  }

  public componentDidMount() {
    window.setTimeout(() => {
      this.closeModal()
    }, 5000)
  }

  public render() {
    const { animate } = this.state
    const { lead } = this.props
    return (
      <Modal
        content={
          <Container>
            <Image src={Strings.leads.leadArchieve} />
            <Text>Your Lead {lead.firstName} {lead.lastName} has been successfully archived.</Text>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal} width={400}
      />
    )
  }

  private closeModal = () => {
    const { handleClose } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose()
    }, 300)
  }
}
