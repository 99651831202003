import { Button, Dropdown } from 'semantic-ui-react'
import styled, { keyframes } from 'styled-components'

import Colors from 'design/Colors'

const appear = keyframes`
  from {
    opacity: 0;
    transform: scale(1, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const CurrencyDropdown = styled(Dropdown)`
  &.ui.compact.selection.dropdown {
    border-left: none;
    height: 28px;
    margin: 0 0 0 -10px;
    min-width: 5em;
  }
`

export const SaveButton = styled(Button)`


    &.ui.icon.left.labeled.button, &.ui.loading.disabled.left.labeled.button {
    background-color: white;
    border: solid 1px ${Colors.Grey700};
    border-radius: 0;
    cursor: default;
    font-size: 12px;
    height: 28px;
    width: 64px;
    margin: 0 24px 0 10px;
    opacity: 0;
    padding: 0 8px 0 24px !important;
  
    .save.outline.icon{
      background-color: transparent;
      height: 28px;
    }

    &.dirty{
      animation: ${appear} 0.2s ease-in-out 1 forwards;
      cursor: pointer;
      opacity: 1;
    }
  }
`