// Import Packages
import * as React from 'react'

// Import Store Types, Actions and Reducers
import { MyReportDetails } from 'store/Reports/Types'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  StyledCheckbox,
  StyledContent
} from './Styled'

interface Props {
  handleSingleChange: (categoryId: string, fieldId: string) => void
  categoryId: string
  report: MyReportDetails[]
}

interface State {
  activeIndex: string
}

class AccordionContent extends React.Component<Props, State> {
  public state = {
    activeIndex: ''
  }

  public render() {
    const { report } = this.props
    const { activeIndex } = this.state
    return (
      <div>
        {
          report.map((items: any, index: any) => (
            <StyledContent
              key={index}
              onClick={() => { this.handleClick(items._id) }}
              background={activeIndex === items._id ? Colors.White490 : Colors.White1000}>
              <div><StyledCheckbox checked={items.isChecked} onChange={() => this.handleSingleChange(items._id)} /><span>{items.field}</span></div>
            </StyledContent>
          ))
        }
      </div>
    )
  }

  private handleSingleChange = (fieldId: string) => {
    const { handleSingleChange, categoryId } = this.props
    handleSingleChange(categoryId, fieldId)
  }

  private handleClick = (index: string) => {
    this.setState({ activeIndex: index })
  }
}

export default AccordionContent
