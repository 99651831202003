import gql from 'graphql-tag'

export const UPDATE_TRANSACTION_SETTING = gql`
  mutation updateTransactionSetting(
    $_id: String
    $residentialBaseFee: Int
    $residentialFirstAmount: Int
    $residentialAdditional: Int
    $residentialIncrement: Int
    $commercialBaseFee: Int
    $commercialFirstAmount: Int
    $commercialAdditional: Int
    $commercialIncrement: Int
    $commissionFeeType: String
    $flatFeeAmount: Int
    $flatFeePercentage: Int
    $progressiveFee: [ProgressiveFeeInput]
    $otherFees: [OtherFeeInput]
  ) {
    updateTransactionSetting(
      _id: $_id
      input: {
        residentialBaseFee: $residentialBaseFee
        residentialFirstAmount: $residentialFirstAmount
        residentialAdditional: $residentialAdditional
        residentialIncrement: $residentialIncrement
        commercialBaseFee: $commercialBaseFee
        commercialFirstAmount: $commercialFirstAmount
        commercialAdditional: $commercialAdditional
        commercialIncrement: $commercialIncrement
        commissionFeeType: $commissionFeeType
        flatFeeAmount: $flatFeeAmount
        flatFeePercentage: $flatFeePercentage
        progressiveFee: $progressiveFee
        otherFees: $otherFees
      }
    ) {
      _id
      residentialBaseFee
      residentialFirstAmount
      residentialAdditional
      residentialIncrement
      commercialBaseFee
      commercialFirstAmount
      commercialAdditional
      commercialIncrement
      commissionFeeType
      flatFeeAmount
      flatFeePercentage
      progressiveFee {
        minimum
        maximum
        percentage
      }
      otherFees {
        name
        fee
        type
      }
    }
  }
`

export const CREATE_TRANSACTION_SETTING = gql`
  mutation createTransactionSetting(
    $name: String
    $isDefault: Boolean
    $residentialBaseFee: Int
    $residentialFirstAmount: Int
    $residentialAdditional: Int
    $residentialIncrement: Int
    $commercialBaseFee: Int
    $commercialFirstAmount: Int
    $commercialAdditional: Int
    $commercialIncrement: Int
    $commissionFeeType: String
    $flatFeeAmount: Int
    $flatFeePercentage: Int
    $progressiveFee: [ProgressiveFeeInput]
    $otherFees: [OtherFeeInput]
  ) {
    createTransactionSetting(
      input: {
        name: $name
        isDefault: $isDefault
        residentialBaseFee: $residentialBaseFee
        residentialFirstAmount: $residentialFirstAmount
        residentialAdditional: $residentialAdditional
        residentialIncrement: $residentialIncrement
        commercialBaseFee: $commercialBaseFee
        commercialFirstAmount: $commercialFirstAmount
        commercialAdditional: $commercialAdditional
        commercialIncrement: $commercialIncrement
        commissionFeeType: $commissionFeeType
        flatFeeAmount: $flatFeeAmount
        flatFeePercentage: $flatFeePercentage
        progressiveFee: $progressiveFee
        otherFees: $otherFees
      }
    ) {
      _id
      name
      isDefault
      residentialBaseFee
      residentialFirstAmount
      residentialAdditional
      residentialIncrement
      commercialBaseFee
      commercialFirstAmount
      commercialAdditional
      commercialIncrement
      commissionFeeType
      flatFeeAmount
      flatFeePercentage
      progressiveFee {
        minimum
        maximum
        percentage
      }
      otherFees {
        name
        fee
        type
      }
    }
  }
`

export const DELETE_TRANSACTION_SETTING = gql`
  mutation deleteTransactionSetting($id: String) {
    deleteTransactionSetting(_id: $id)
  }
`
