import client from 'queries/apollo'

import { GET_CITIES } from 'queries/graphql/City/Queries'
import { GET_OFFICE_USERS, GET_USERS } from 'queries/graphql/Users/Queries'

export const getCities = async (stateId: string) => {
  const result: any = await client.query({
    query: GET_CITIES,
    variables: { state_id: stateId }
  })

  return result.data.getCities
}

export const getUsers = async () => {
  const result: any = await client.query({
    query: GET_USERS
  })

  return result.data.getUsers
}

export const getOfficeUsers = async () => {
  const result: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_OFFICE_USERS
  })

  return result.data.getUsers
}
