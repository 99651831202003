// Import Packages
import { Card, Dropdown, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Images and Icons
import Envalope from 'design/icons/offices/icon-envalope.png'
import Phone from 'design/icons/offices/icon-phone.png'
import MapIcon from 'design/icons/offices/map.png'

// Import Colors
import Colors from 'design/Colors'
import { y } from 'design/Styled'

interface Props {
  background?: string
  left?: number
  color?: string
  leftValue?: number
}

const Container = styled.div`
  min-height: 15px;
`

const CardWrapper = styled(Card)`
  &.ui.card {
    margin: 0 auto;
    border-radius: 0px;
    width: 95%;
    background: ${Colors.White1000};
    border: 1px solid ${Colors.Blue910};
  }
`

const CardContent = styled(Card.Content)`
  padding: 20px 0px 6px 20px;

  .fa-pencil-alt {
    color: ${Colors.Black500};
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`

const Divider = styled.div`
  border-bottom: 1px solid ${Colors.DarkBlue200};
  width: 90%;
  margin: 0 auto;
`

const PhoneIcon = styled.div`
  background: url(${Phone});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 10px;
  flex-shrink: 0;
  align-self: start;
  cursor: pointer;
`

const EmailIcon = styled.div`
  background: url(${Envalope});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 10px;
  flex-shrink: 0;
  align-self: start;
  cursor: pointer;
`

const Branch = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  color: ${Colors.Black500};
  align-items: center;
  margin-bottom: 5px;
`

const BranchName = styled.div`
  display: flex;
  flex-grow: 1;
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  margin-right: 10px;
  line-height: 1.2;
  text-transform: capitalize;
`

const Address = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  color: ${Colors.Black500};
  align-items: center;
`

const Map = styled.div`
  background: url(${MapIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  align-self: center;
  margin-right: 3px;
  flex-shrink: 0;
  align-self: start;
`

const AddressDetails = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  margin-right: 10px;
  line-height: 1.2;
`

const Profile = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 15px;
  color: ${Colors.Black500};
  align-items: center;
  flex: 1;
`

const ProfileImage = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: center;
  margin-right: 6px;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const FullName = styled.div`
  line-height: 1.2;
  text-transform: capitalize;
`

const ExtraContent = styled.div`
  font-size: 12px;
  display: flex;
  padding: 5px 20px 20px 20px;
  padding-bottom: 6px;
  margin-top: 5px;
  padding-top: 0;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const StyledDropdown = styled(Dropdown)`
  background: ${(props: Props) => props.background || props.background};
  text-align: -webkit-center !important;
  width: auto;
  min-width: 70px;

  &.ui.inline.dropdown {
    display: flex;
  }

  &.ui.inline.dropdown .dropdown.icon {
    color: ${(props: Props) => props.color || props.color};
    flex-grow: 1;
    height: auto;
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }

  &.ui.inline.dropdown>.text {
    color: ${(props: Props) => props.color || props.color};
    margin-left: 5px;
    flex-grow: 1;
    font-weight: 500;
  }

  &.actionDropdown {
    text-align: left !important;
  }
`

const Ul = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
`

const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Label = styled.div`
  width: 25px;
  height: 25px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
`

const StyledLabel = styled.div`
  ${y};
  height: 100%;
  color: ${Colors.DarkBlue220};
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
  }
`

export {
  Address,
  AddressDetails,
  Branch,
  BranchName,
  CardContent,
  CardWrapper,
  Container,
  Divider,
  EmailIcon,
  ExtraContent,
  FullName,
  Label,
  Map,
  MemberProfile,
  PhoneIcon,
  Profile,
  ProfileImage,
  StyledDropdown,
  StyledLabel,
  Li,
  Ul,
  StyledPopup,
}
