import { notNumbers, validEmail, validPhone } from 'utils/Validation'

const message = {
  email: 'You must include a valid email address',
  phone: 'You must include a 10-digit phone number'
}

export const validateEmail = (fields: object) => {
  const errors = {}
  let formIsValid = true

  if (!fields[`email`] || !fields[`email`].match(validEmail)) {
    formIsValid = false
    errors[`email`] = message.email
  }

  return { errors, formIsValid }
}

export const validatePhone = (phone: string) => {
  const errors: any = {}
  let formIsValid = true

  if (!phone || !phone.replace(notNumbers, '').match(validPhone)) {
    formIsValid = false
    errors.phone = message.phone
  }
  return { errors, formIsValid }
}
