// Import Packages
import moment from 'moment'

// Import Images and Icons
import IconBadge from 'design/icons/userProfile/userInfo/badge.png'
import IconFlag1 from 'design/icons/userProfile/userInfo/flag_1.png'
import IconFlag2 from 'design/icons/userProfile/userInfo/flag_2.png'
import IconFlag3 from 'design/icons/userProfile/userInfo/flag_3.png'
import IconFlag4 from 'design/icons/userProfile/userInfo/flag_4.png'
import IconFlag5 from 'design/icons/userProfile/userInfo/flag_5.png'

const data = {
  badges: [
    {
      icon: IconBadge,
      name: 'xyz'
    },
    {
      icon: IconBadge,
      name: 'xyz'
    },
    {
      icon: IconBadge,
      name: 'xyz'
    },
    {
      icon: IconBadge,
      name: 'xyz'
    },
    {
      icon: IconBadge,
      name: 'xyz'
    },
    {
      icon: IconBadge,
      name: 'xyz'
    }
  ],
  email: 'martinl@gmail.com',
  firstName: 'Martin',
  joiningDate: moment('10/26/2017'),
  languages: [
    {
      code: 'FR',
      icon: IconFlag5,
      name: 'French'
    },
    {
      code: 'EN(US)',
      icon: IconFlag4,
      name: 'English(US)'
    },
    {
      code: 'HIN',
      icon: IconFlag2,
      name: 'Hindi'
    },
    {
      code: 'CHI',
      icon: IconFlag3,
      name: 'Chinese'
    },
    {
      code: 'EN(UK)',
      icon: IconFlag1,
      name: 'English(UK)'
    }
  ],
  lastName: 'Luther',
  lastlogin: undefined,
  mobileNo: '+1 222 222 2222',
  office: 'Agencyone HQ',
  rank: 5,
  referrals: {
    joined: 6,
    pending: 4
  },
  storageLimit: 2048,
  storageUnit: 'MB',
  storageUsed: 100,
  totalEmployees: 70
}

export default data
