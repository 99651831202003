import gql from 'graphql-tag'

// Public Notification
export const CREATE_PUBLIC_NOTIFICATION = gql`
  mutation createPublicNotification($title: String, $content: String) {
    createPublicNotification(input: { title: $title, content: $content })
  }
`

export const UPDATE_PUBLIC_NOTIFICATION = gql`
  mutation updatePublicNotification($id: String, $isViewed: Boolean, $title: String, $content: String) {
    updatePublicNotification(_id: $id, input: { isViewed: $isViewed, title: $title, content: $content }) {
      _id
      title
      content
      isViewed
    }
  }
`

export const DELETE_PUBLIC_NOTIFICATION = gql`
  mutation deletePublicNotification {
    deletePublicNotification
  }
`

export const DELETE_CREATED_BY_PUBLIC_NOTIFICATION = gql`
  mutation deleteCreatedByPublicNotification($id: String) {
    deleteCreatedByPublicNotification(_id: $id)
  }
`

// Public Notification
export const UPDATE_FEED_NOTIFICATION = gql`
  mutation updateFeedNotification($id: String, $isViewed: Boolean) {
    updateFeedNotification(_id: $id, input: { isViewed: $isViewed }) {
      _id
      title
      content
      isViewed
    }
  }
`

export const DELETE_FEED_NOTIFICATION = gql`
  mutation deleteFeedNotification {
    deleteFeedNotification
  }
`
