import * as React from 'react'

import {
  Container,
  StyledToggle,
  Title,
  ToggleItem,
} from './Styled'

import {
  MethodEnum
} from './Types'

interface Props {
  name: string
  value: MethodEnum
  onChange: (e:any, data:any) => void
}

class TokenMethodToggle extends React.Component<Props,{}> {
  public render () {
    const {
      name,
      value,
    } = this.props
    return (
      <Container
        className={'rp-confirmation-method-toggle'}
        spacing={0.25}
      >
        <Title>Verification Method:</Title>
        <ToggleItem isActive={value === MethodEnum.Email}>Email</ToggleItem>
        <StyledToggle
          defaultChecked={value === MethodEnum.Sms}
          name={name}
          onChange={this.handleChange}
          toggle={true}
          value={value}
          monochrome={1}
        />
        <ToggleItem isActive={value === MethodEnum.Sms}>Text</ToggleItem>
      </Container>
    )
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
    const { onChange } = this.props
    data.value = data.value !== MethodEnum.Email ? MethodEnum.Email : MethodEnum.Sms
    onChange(e, data)
  }
}

export default TokenMethodToggle
