import { Modal } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { fa, modalBorderRadius as r, Props, x } from 'design/Styled'

export const Container = styled(Modal)`
  position: relative;
  box-shadow: 0 7px 36px -9px ${Colors.ModalShadow};
  margin-left: auto;
  margin-right: auto;
  margin-top: 0 !important;
  max-height: 90vh;
  border-radius: ${r} !important;
  flex: 0;
`

export const Content = styled(Modal.Content)`
  padding: 0 !important;
  height: 100%;
  border-radius: ${r} !important;
`

const getActiveState = ({ isActive }: Props) => {
  if (!isActive) {
    return ``
  }

  return css`
    top: -5em;
    padding: 5em 0;
    cursor: grabbing;
  `
}

export const Grip = styled.div`
  ${x};
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 4px 0;
  font-size: 12px;
  opacity: 0.2;
  z-index: 99999;
  overflow: hidden;
  cursor: move;
  cursor: grab;
  transition: opacity 200ms;

  &:before {
    ${x};
    ${fa};
    justify-content: center;
    content: '\f7a4';
    background: ${Colors.transparent};
    border-radius: ${r} ${r} 0 0 !important;
    box-shadow: 0 1px 5px -4px ${Colors.ModalShadow};
    transition: all 300ms;
  }

  &:hover {
    opacity: 1;
    ${getActiveState};

    &:before {
      background: ${Colors.ButtonPrimary};
      box-shadow: 0 1px 7px -4px ${Colors.ModalShadow};
      color: ${Colors.TextLight};
    }
  }
`
