import client from 'queries/apollo'

import {
  CREATE_PASS,
  CREATE_PASS_SECTION,
  CREATE_USER_PASS,
  DELETE_PASS_SECTION,
  DELETE_USER_PASS,
  REORDER_USER_PASS,
  UPDATE_PASS_SECTION,
  UPDATE_USER_PASS
} from 'queries/graphql/Passes/Mutation'

import { getPassesSection } from './Queries'

// For super admin current not use
export const createPass = async (name: string, passType: string, passCategory: string) => {
  const response = await client.mutate({
    mutation: CREATE_PASS,
    variables: { name, passType, passCategory }
  })

  return response.data.createPass
}

export const createPassSection = async (name: string) => {
  await client.mutate({
    mutation: CREATE_PASS_SECTION,
    variables: { name }
  })

  const passSections = await getPassesSection()
  return passSections
}

export const updatePassSection = async (id: string, name: string) => {
  await client.mutate({
    mutation: UPDATE_PASS_SECTION,
    variables: { id, name }
  })

  const passSections = await getPassesSection()
  return passSections
}

export const deletePassSection = async (id: string) => {
  await client.mutate({
    mutation: DELETE_PASS_SECTION,
    variables: { id }
  })

  const passSections = await getPassesSection()
  return passSections
}

export const createUserPass = async (pass: string, passSection: string) => {
  await client.mutate({
    mutation: CREATE_USER_PASS,
    variables: {
      input: { pass, passSection }
    }
  })

  const passSections = await getPassesSection()
  return passSections
}

export const updateUserPass = async (id: string, input: any) => {
  await client.mutate({
    mutation: UPDATE_USER_PASS,
    variables: {
      id,
      input
    }
  })

  const passSections = await getPassesSection()
  return passSections
}

export const deleteUserPass = async (id: string) => {
  await client.mutate({
    mutation: DELETE_USER_PASS,
    variables: { id }
  })

  const passSections = await getPassesSection()
  return passSections
}

export const reorderingUserPass = async (data: any) => {
  await client.mutate({
    mutation: REORDER_USER_PASS,
    variables: { pass: data }
  })

  const passSections = await getPassesSection()
  return passSections
}
