import * as React from 'react'

import Modal from 'shared/Modal'
import StateDropdown from 'shared/StateDropdown'
import { serverToast } from 'shared/Toast/Toast'

import { createUnverifiedMls } from '../Mutations'

import { Cancel, Container, Create, Footer, Label, Row, StyledInput, Title } from './Styled'

import { FormErrorType, FormType, MlsInputType, MlsType } from '../Types'

interface Props {
  mlsInput: MlsInputType
  onClose: (mls: MlsType | null) => void
}

interface State {
  form: FormType
  loading: boolean
  transition: boolean
}

class AddMlsModal extends React.Component<Props, State> {
  public state = {
    form: {
      errors: {} as FormErrorType,
      fullName: '',
      shortName: '',
      state: ''
    },
    loading: false,
    transition: true
  }

  public componentDidMount = () => {
    const {
      mlsInput,
      mlsInput: { shortName }
    } = this.props
    if (shortName) {
      mlsInput.shortName = shortName.toUpperCase()
    }
    const { form } = this.state
    const initForm: FormType = {
      ...form,
      ...mlsInput
    }

    this.setState({ form: initForm })
  }

  public render = () => {
    const {
      form,
      form: { errors },
      loading,
      transition
    } = this.state

    return (
      <Modal
        content={
          <Container>
            <Title>Add a new MLS</Title>
            <Row>
              <Label>Short Name</Label>
              <StyledInput
                placeholder="Enter the short or abbreviated name"
                name="shortName"
                fluid={true}
                value={form.shortName}
                onChange={this.handleInputChange}
                error={!!errors.shortName}
              />
            </Row>
            <Row>
              <Label>Full Name</Label>
              <StyledInput
                autoFocus={true}
                placeholder="Enter the full MLS name"
                name="fullName"
                fluid={true}
                value={form.fullName}
                onChange={this.handleInputChange}
                error={!!errors.fullName}
              />
            </Row>
            <Row>
              <Label>State</Label>
              <StateDropdown
                placeholder="Select the operating locale"
                value={form.state}
                onChange={this.handleStateSelection}
                error={!!errors.state}
              />
            </Row>
            <Footer>
              <Create loading={loading} onClick={this.handleCreateMls} compact={true} size="small">
                Save
              </Create>
              <Cancel compact={true} size="small" onClick={() => this.closeSelf(null)}>
                Cancel
              </Cancel>
            </Footer>
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={() => this.closeSelf(null)}
        width={400}
      />
    )
  }

  private handleInputChange = (e: any, { value, name }: any) => {
    const { form } = this.state
    if (name === 'shortName') {
      value = value.toUpperCase()
    }
    form[name] = value
    this.setState({ form })
  }

  private handleStateSelection = (e: any, data: any) => {
    const { form } = this.state
    form.state = data.value
    form.errors.state = ''
    this.setState({ form })
  }

  private handleCreateMls = async () => {
    const {
      form: { fullName, shortName, state }
    } = this.state
    this.setState({ loading: true })
    const input: MlsInputType = {
      fullName,
      shortName,
      state
    }
    try {
      const mls = await createUnverifiedMls(input)
      this.closeSelf(mls)
    } catch (error) {
      serverToast(error)
    }
    this.setState({ loading: false })
  }

  private closeSelf = (createdMls: MlsType | null) => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({ transition: true })
      onClose(createdMls)
    }, 300)
  }
}
export default AddMlsModal
