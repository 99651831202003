import AddCustomPlan from 'design/icons/dashboard/AddCustomPlan.png'
import CheckList from 'design/icons/dashboard/checkList.png'
import Notifications from 'design/icons/dashboard/Notifications.png'
import Profile from 'design/icons/dashboard/Profile.png'
import Signature from 'design/icons/dashboard/Signature.png'
import TaxMascotGraphic from 'design/icons/dashboard/TaxMascotGraphic.png'
import TransactionAgent from 'design/icons/dashboard/TransactionAgent.png'
import Workflow from 'design/icons/dashboard/Workflow.png'

export const assistantAdminMockData = [
  {
    button: 'CONFIGURE',
    icon: CheckList,
    text:
      'The Checklist is the core of a RealtyPass transaction and is used to ensure compliance by making sure agents complete and submit all necessary documentation.  Our innovative Checklist Manager may be pre-loaded with your MLS-Specific documents or you can modify a generic version instead.  If your MLS is not already in our master template, please contact us for a complimentary setup to also ensure data mappings are accurate for auto-transaction creation and other scheduled enhancements coming your way.  Of course, you can  always modify it any way you wish to best suit your specific needs.',
    title: 'Checklist Manager'
  },
  {
    button: 'CONFIGURE',
    icon: TaxMascotGraphic,
    text:
      'Finn is here to remind you to set up any and all State or Local taxes that are required by your local jurisdiction. These taxes are automatically calculated from any earned commission for collection by the taxing authorities.',
    title: 'Tax Setup'
  },
  {
    button: 'CONFIGURE',
    icon: AddCustomPlan,
    text:
      'Set up your default business fee structures with a few simple clicks. This process can be completed before Finn finishes his treat! Create unlimited custom commission plans for individuals, teams, maximum CAP thresholds, exclusions and more.',
    title: 'Fee Settings'
  },
  {
    button: 'LET’S GO!',
    icon: TransactionAgent,
    text: '',
    title: 'Let’s get started by creating a transaction!'
  }
]

export const assistantUsersMockData = [
  {
    button: 'CONFIGURE',
    icon: Profile,
    text:
      'Manage your goals, account settings, bio and more. Keep your profile settings up to date to ensure proper communication with your clients and office staff.',
    title: 'Complete Profile'
  },
  {
    button: 'CONFIGURE',
    icon: Workflow,
    text:
      'Use our pre made library of templates or create your very own. This is a great way to track and manage your business activities.',
    title: 'Setup Workflows'
  },
  {
    button: 'CONFIGURE',
    icon: Notifications,
    text:
      'Determine exactly how you wish to be notified of various system wide triggers and event-based activities that require your attention.',
    title: 'Configure Notifications'
  },
  {
    button: 'CONFIGURE',
    icon: Signature,
    text:
      'Customize your outgoing email messages with our editor that adds a professional flare and credibility your customers will love.',
    title: 'Setup Email Signature'
  },
  {
    button: 'LET’S GO!',
    icon: TransactionAgent,
    text: '',
    title: 'Let’s get started by creating a transaction!'
  }
]

export const videosMockData = [
  {
    button: 'CONFIGURE',
    icon: 'check-square',
    text:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    title: 'Checklist Manager'
  },
  {
    button: 'ALL SET TO GO',
    icon: 'envelope-open-text',
    text:
      'Lorem Ipsum is simply dumy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    title: 'Tax Setup'
  },
  {
    button: 'CONFIGURE',
    icon: 'cog',
    text:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    title: 'Fee Settings'
  },
  {
    button: 'CONFIGURE',
    icon: 'check-square',
    text:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    title: 'Checklist Manager'
  },
  {
    button: 'ALL SET TO GO',
    icon: 'envelope-open-text',
    text:
      'Lorem Ipsum is simply dumy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    title: 'Tax Setup'
  },
  {
    button: 'CONFIGURE',
    icon: 'cog',
    text:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    title: 'Fee Settings'
  }
]
