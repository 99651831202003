import styled, { css } from 'styled-components'

import Colors from 'design/Colors'
import { modalBorderRadius as r, children, text, linkExternal, x, y } from 'design/Styled'

import { ViewType } from './Types'

interface Props {
  mode?: ViewType
  scale?: number
  isActive?: number | boolean
}

export const DIMENSIONS: any = {
  mobile: {
    width: 414,
    height: 736,
  },
  tablet: {
    width: 776,
    height: 1024,
  },
  desktop: {
    width: 1440,
    height: 900,
  }
}

const getInvertedScale = ({ scale }:Props) => {
  scale = scale || 1
  return css`
    transform: scale(${1 / scale});
  `
}

const getRadius = ({ scale }:Props) => {
  scale = scale || 1
  return css`
    border-radius: ${Math.round(4 * scale)}px;
  `
}

const getDimensions = ({ mode, scale }:Props) => {
  if (!mode) {
    return ``
  }

  scale = scale || 1

  return css`
    width: ${DIMENSIONS[mode].width}px;
    min-width: ${DIMENSIONS[mode].width}px;
    height: ${DIMENSIONS[mode].height}px;
    min-height: ${DIMENSIONS[mode].height}px;
    border-radius: ${Math.round(8 * scale)}px;
    border: ${Math.round(5 * scale)}px solid ${Colors.TextPrimary};
    box-shadow: ${Math.round(14 * scale)}px ${Math.round(14 * scale)}px 0 0 ${Colors.TextPrimaryHover};
    transform: scale(${scale});
  `
}

export const Container = styled.div`
  ${x};
  height: 100%;
  overflow: hidden;
  font-size: 14px;

  .rp-button-icon-active {
    color: ${Colors.TextLink};
  }
`

export const Row = styled.div`
  ${x};
  ${children};
`

export const Header = styled.header`
  ${x};
  ${children};
  ${text};
  justify-content: space-between;
  padding: 2em 1em 1em;
  min-height: 50px;
  border-bottom: 1px solid ${Colors.EmphasisLine};

  svg {
    font-size: 20px;
  }
`

export const Title = styled.h1`
  ${text};
  font-size: 20px;
  color: ${Colors.TextPrimary};
  margin: 0;
  padding: 0 0.3em;
  font-weight: bold;
`

export const Col = styled.div`
  ${y};
`

export const Menu = styled.nav`
  ${y};
  height: 100%;
  max-width: 240px;
  background: ${Colors.ListOdd};
  box-shadow: -2px 0 16px -4px ${Colors.TransparentBlack(0.2)};
  border-radius: ${r} 0 0 ${r};
  overflow: hidden;

  ${Header} {
    background: ${Colors.ListEven};
    border-bottom: 1px solid ${Colors.TableHeadLine};
  }
`

export const MenuItem = styled.span`
  ${x};
  ${text};
  padding: 0.7em 1.5em;
  cursor: pointer;
  color: ${Colors.TextPrimary};
  background: ${({ isActive }:Props) => isActive ? Colors.ListEven : 'transparent'};

  &:hover {
    color: ${Colors.TextPrimaryHover};
    background: ${Colors.ListEven};
  }
`

export const Body = styled.section`
  ${y};
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`

export const Preview = styled.div`
  display: block;
  transform-origin: center;
  transition: all 300ms;
  background: ${Colors.EmphasisContainer};

  ${getDimensions};

  .rp-loading-indicator {
    z-index: 1 !important;
    background-color: ${Colors.TransparentWhite(0)} !important;
    ${getInvertedScale};
  }
`

export const Frame = styled.iframe`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  background: ${Colors.TransparentWhite(0)};
  z-index: 2;

  ${getRadius};
`

export const Link = styled.a`
  ${linkExternal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: all;
`
