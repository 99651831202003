export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`firstName`]) {
    formIsValid = false
    errors[`firstName`] = true
  }

  if (!fields[`lastName`]) {
    formIsValid = false
    errors[`lastName`] = true
  }

  if (!fields[`email`]) {
    formIsValid = false
    errors[`email`] = true
  }

  if (!fields[`phones`]) {
    formIsValid = false
    errors[`phones`] = true
  }

  if (!fields[`type`]) {
    formIsValid = false
    errors[`type`] = true
  }

  return { errors, formIsValid }
}
