import { Reducer } from 'redux'
import Actions from 'store/Notifications/ActionsTypes'
import { readFeedMessage, readUpdateMessage } from './ReduxUtil'
import { NotificationCountItems, Notifications } from './Types'

const initialState: Notifications = {
  feedNotification: [],
  notificationCount: {} as NotificationCountItems,
  updateNotification: []
}

const NotificationsReducer: Reducer<Notifications> = (
  state: Notifications = initialState,
  action
) => {
  switch (action.type) {
    case Actions.getFeedNotification:
      return {
        ...state,
        feedNotification: action.payload.data
      }
    case Actions.getUpdateMessage:
      return {
        ...state,
        updateNotification: action.payload.data
      }
    case Actions.discardFeedMessage:
      return {
        ...state,
        feedNotification: []
      }
    case Actions.discardUpdateMessage:
      return {
        ...state,
        updateNotification: []
      }
    case Actions.readFeedMessage:
      return {
        ...state,
        feedNotification: readFeedMessage(state, action.payload.id)
      }
    case Actions.readUpdateMessage:
      return {
        ...state,
        updateNotification: readUpdateMessage(state, action.payload.id)
      }
    case Actions.showNotificationCount:
      return {
        ...state,
        notificationCount: action.payload.data
      }
    default:
      return state
  }
}

export default NotificationsReducer
