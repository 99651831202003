import { History } from 'history'
import * as React from 'react'
import { getLoggedInUser, Routes } from 'utils'

interface Props {
  allowedRoles: any
  history: History
}

class RestrictAccess extends React.Component<Props> {
  public state = {
    status: null
  }

  public async componentDidMount() {
    const { allowedRoles }: any = this.props
    const authenticationDetails: any = await getLoggedInUser({
      fromCache: true
    })
    if (!authenticationDetails || !Object.keys(authenticationDetails).length) {
      this.setState({ status: 'NOT_AUTHENTICATED' })
    } else if (
      !authenticationDetails.role ||
      !allowedRoles.includes(authenticationDetails.role)
    ) {
      this.setState({ status: 'NOT_ALLOWED' })
    } else {
      this.setState({ status: 'ALLOWED' })
    }
  }

  public render() {
    const { status } = this.state
    const { children }: any = this.props
    if (!status) {
      return null
    }

    if (status === 'NOT_AUTHENTICATED') {
      this.props.history.push(Routes.onboarding.login.path)
    }

    if (status === 'NOT_ALLOWED') {
      this.props.history.push(Routes.primary.dashboard.path)
    }

    return children
  }
}

export default RestrictAccess
