// Import Packages
import * as React from 'react'
import { Form, Icon, Input, Menu, Select } from 'semantic-ui-react'

// Import Components
import AddressInput from 'shared/AddressInput'
import StateDropdown from 'shared/StateDropdown'
import { FormErrorType, FormType, OptionType, FilterOptionEnum } from './Types'

// Import Store Types, Actions and Reducers
import { FiltersData, FilterText, TransactionRoleEnum, TransactionStatusEnum } from 'store/Transactions/Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  CloseWrapper,
  Container,
  Filter,
  Filters,
  FiltersTitle,
  PopUpContent,
  StyledContainer,
  StyledForm,
  StyledInput,
  StyledMenu
} from './Styled'

const ROLE_OPTIONS:OptionType[] = [
  { text: 'Buyer', value: TransactionRoleEnum.Buyer },
  { text: 'Seller', value: TransactionRoleEnum.Seller },
  { text: 'Both', value: TransactionRoleEnum.Both }
]

const STATUS_OPTIONS:OptionType[] = [
  { text: 'New', value: TransactionStatusEnum.New },
  { text: 'Active', value: TransactionStatusEnum.Active },
  { text: 'Received', value: 'Received' },
  { text: 'Payable', value: TransactionStatusEnum.Payable },
  { text: 'Closed', value: TransactionStatusEnum.Closed },
  { text: 'Failed', value: TransactionStatusEnum.Failed }
]

const FILTER_OPTIONS:OptionType[] = [
  { text: 'MLS Number', value: FilterOptionEnum.Mls },
  { text: 'Transaction Id', value: FilterOptionEnum.Transaction },
  { text: 'Total Debits', value: FilterOptionEnum.Debit },
  { text: 'Expected Commission', value: FilterOptionEnum.Commission },
  { text: 'Status', value: FilterOptionEnum.Status },
  { text: 'Type', value: FilterOptionEnum.Role },
  { text: 'Transaction Owner Name', value: FilterOptionEnum.Owner },
  { text: 'Address', value: FilterOptionEnum.Address },
]

export interface AddressType {
  city: string
  country?: string
  streetAddress: string
  streetName: string
  streetNumber?: string
  state: string
  zipCode: string
}

interface Props {
  filtersContent: (id: string, name: string, text: string, searchData: FiltersData) => void
  closeAdvanceSearchModal: () => void
  filter: (data: FiltersData, state: OptionType, city: OptionType) => void
  data: FiltersData
  filterText: FilterText[]
  selectedCity: OptionType
  selectedState: OptionType
}

interface State {
  form: FormType
  activeItem: string
  searchData: FiltersData
  cities: OptionType[]
  states: OptionType[]
  selectedCity: OptionType
  selectedState: OptionType
}

class TransactionSearchModal extends React.Component<Props, State> {
  public state = {
    activeItem: '',
    cities: [],
    form: {
      city: '',
      errors: {} as FormErrorType,
      state: '',
      streetAddress: '',
      type: '',
      zipCode: ''
    } as FormType,
    searchData: {
      address: {
        city: '',
        state: '',
        streetName: '',
        streetNumber: '',
        zipCode: ''
      },
      mlsId: undefined,
      owner: {
        firstName: undefined,
        lastName: undefined
      },
      transactionId: undefined,
      totalDebits: undefined,
      expectedCommission: undefined,
      status: undefined,
      transactionRole: undefined
    },
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
    states: []
  }

  public async componentDidMount() {
    const { data, selectedState, selectedCity } = this.props
    this.setState({ searchData: data, selectedState, selectedCity })
  }

  public componentWillReceiveProps(nextProps: any) {
    const { filterText } = this.props
    const lastElement = nextProps.filterText.slice(-1)[0]
    if (lastElement !== undefined) {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: lastElement.content })
      }
    } else {
      if (filterText.length !== nextProps.filterText.length) {
        this.setState({ activeItem: '' })
      }
    }
  }

  public render() {
    const { activeItem, searchData, form } = this.state
    return (
      <StyledContainer>
        <CloseWrapper onClick={this.closeAdvanceSearchModal} />
        <PopUpContent>
          <Container>
            <Filters>
              <FiltersTitle>{Strings.advanceSearchModal.filters}</FiltersTitle>
              <StyledMenu vertical={true}>
                {FILTER_OPTIONS.map((item: OptionType, index: number) => (
                  <Menu.Item
                    key={index}
                    name={item.text}
                    id={item.text}
                    text={item.value}
                    active={activeItem === item.text}
                    onClick={() => this.handleItemClick(item)}
                  />
                ))}
              </StyledMenu>
            </Filters>
            <StyledForm size={'tiny'}>
              {activeItem === '' && (
                <Filter>
                  <Icon name="filter" />
                  <div>Select Filter</div>
                </Filter>
              )}
              {activeItem === 'MLS Number' && (
                <Form.Field
                  control={Input}
                  label="MLS Number"
                  type="number"
                  name="mlsId"
                  value={searchData.mlsId}
                  placeholder="MLS Number"
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Total Debits' && (
                <Form.Field
                  control={Input}
                  label="Total Debits"
                  type="number"
                  step="any"
                  name="totalDebits"
                  value={searchData.totalDebits}
                  placeholder="Total Debits"
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Expected Commission' && (
                <Form.Field
                  control={Input}
                  label="Expected Commission"
                  type="number"
                  step="any"
                  name="expectedCommission"
                  value={searchData.expectedCommission}
                  placeholder="Expected Commission"
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Transaction Id' && (
                <Form.Field
                  control={Input}
                  label="Transaction Id"
                  type="number"
                  name="transactionId"
                  value={searchData.transactionId}
                  placeholder="Transaction Id"
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Status' && (
                <Form.Field
                  control={Select}
                  label="Status"
                  name="status"
                  placeholder="Status"
                  value={searchData.status}
                  options={STATUS_OPTIONS}
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Type' && (
                <Form.Field
                  control={Select}
                  label="Type"
                  name="transactionRole"
                  placeholder="Type"
                  value={searchData.transactionRole}
                  options={ROLE_OPTIONS}
                  onChange={this.handleChange}
                />
              )}
              {activeItem === 'Transaction Owner Name' && (
                <div>
                  <Form.Field
                    control={Input}
                    label="First Name"
                    name="firstName"
                    value={searchData.owner.firstName}
                    placeholder="First Name"
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    control={Input}
                    label="Last Name"
                    name="lastName"
                    value={searchData.owner.lastName}
                    placeholder="Last Name"
                    onChange={this.handleChange}
                  />
                </div>
              )}
              {activeItem === 'Address' && (
                <div>
                  <Form.Field>
                    <AddressInput
                      name="streetAddress"
                      placeholder="Street Address"
                      onChange={this.handleAddressChange}
                      address={form as AddressType}
                    />
                  </Form.Field>
                  <Form.Field childWidths={33.334} className="algolia">
                    <StyledInput
                      name="city"
                      onChange={this.handleInputChange}
                      placeholder="City"
                      value={searchData.address.city}
                    />
                    <StateDropdown
                      name="state"
                      onChange={this.handleInputChange}
                      placeholder="State"
                      value={searchData.address.state}
                    />
                    <StyledInput
                      className="zipcode"
                      type="text"
                      placeholder="Zip Code"
                      name="zipCode"
                      value={searchData.address.zipCode}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                </div>
              )}
            </StyledForm>
          </Container>
        </PopUpContent>
      </StyledContainer>
    )
  }

  private handleAddressChange = (address: AddressType) => {
    const { form } = this.state
    const updatedForm = {
      ...form,
      ...address
    }
    this.setState(
      {
        form: updatedForm,
        searchData: {
          ...this.state.searchData,
          address: {
            city: updatedForm.city,
            state: updatedForm.state,
            streetName: updatedForm.streetName,
            streetNumber: updatedForm.streetNumber,
            zipCode: updatedForm.zipCode
          }
        }
      },
      () => this.getTransactionBoardsData()
    )
  }

  private handleInputChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    form.errors[name] = ''
    this.setState(
      {
        form,
        searchData: {
          ...this.state.searchData,
          address: {
            city: form.city,
            state: form.state,
            streetName: form.streetName,
            streetNumber: form.streetNumber,
            zipCode: form.zipCode
          }
        }
      },
      () => this.getTransactionBoardsData()
    )
  }

  private closeAdvanceSearchModal = () => {
    const { closeAdvanceSearchModal } = this.props
    closeAdvanceSearchModal()
  }

  private handleItemClick = ({ text, value }: OptionType) => {
    const { filtersContent } = this.props
    const { searchData } = this.state
    this.setState({ activeItem: text })
    filtersContent(text, text, value, searchData)
  }

  private getTransactionBoardsData = async () => {
    const { filter } = this.props
    const { searchData, selectedCity, selectedState } = this.state
    filter(searchData, selectedState, selectedCity)
  }

  private handleChange = async (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { searchData } = this.state
    if (name === 'firstName') {
      this.setState(
        {
          searchData: {
            ...this.state.searchData,
            owner: {
              ...this.state.searchData.owner,
              firstName: value
            }
          }
        },
        () => this.getTransactionBoardsData()
      )
    } else if (name === 'lastName') {
      this.setState(
        {
          searchData: {
            ...this.state.searchData,
            owner: {
              ...this.state.searchData.owner,
              lastName: value
            }
          }
        },
        () => this.getTransactionBoardsData()
      )
    } else if (name === 'mlsId' || name === 'transactionId') {
      searchData[name] = parseInt(value)
      this.setState({ searchData }, () => this.getTransactionBoardsData())
    } else if (name === 'expectedCommission' || name === 'totalDebits') {
      searchData[name] = parseFloat(parseFloat(value).toFixed(2))
      this.setState({ searchData }, () => this.getTransactionBoardsData())
    }  else {
      searchData[name] = value
      this.setState({ searchData }, () => this.getTransactionBoardsData())
    }
  }
}

export default TransactionSearchModal
