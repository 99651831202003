import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { SecondaryFontName } from 'design/Fonts'

import { children, x, y } from 'design/Styled'

const screen = (size: string, pixels: number = 0) => {
  const sizes = {
    phablet: 414,
    tablet: 600
  }
  return sizes[size] + pixels + 'px'
}

export const TabContainer = styled.div`
  width: 100%;

  img {
    display: none;
  }

  @media (min-width: ${screen('phablet', 1)}) {
    width: calc(30% - 15px);

    img {
      display: block;
    }
  }
`

export const TabsContainer = styled.div`
  width: 45%;
  padding: 10px 0;
  margin: 0 auto;
  max-width: 820px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  @media (min-width: ${screen('phablet', 1)}) {
    width: 100%;
  }
`

export const TabImage = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: bottom;
  margin-bottom: 10px;
  height: ${(props: { type: number }) => {
    switch (props.type) {
      case 1:
        return `100px`
      case 2:
        return `100px`
      case 3:
        return `120px`
      default:
        return 0
    }
  }};
`

export const MobileTabImage = styled(TabImage)`
  width: 50%;
  padding: 10px;
  margin: 0;

  @media (min-width: ${screen('phablet', 1)}) {
    display: none;
  }
`

export const TabLabel = styled.div`
  font-size: 18px;
  font-family: ${SecondaryFontName};
  color: ${Colors.DarkBlue200};
`

export const MemberLabel = styled(TabLabel)`
  margin-bottom: 10px;
`

export const Toggle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.DarkBlue200};
  margin-right: 5px;
  border-radius: 50px;
`

export const ActiveToggle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50px;
  background-color: ${Colors.Green100};
`

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px auto;
  cursor: pointer;

  @media (min-width: ${screen('phablet', 1)}) {
    justify-content: center;
  }
`

export const Container = styled.section`
  ${y};
  padding: 2em 1em;
  overflow-x: hidden;
`

export const Header = styled.div`
  color: ${Colors.DarkBlue200};
  font-size: 28px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  font-family: ${SecondaryFontName};
  padding: 16px 0;

  @media (min-width: ${screen('phablet', 1)}) {
    font-size: 48px;
    padding: 20px 0;
  }
`

export const HeaderBold = styled.span`
  font-weight: 600;
`

export const FormContainer = styled.div`
  ${y};
  align-items: center;

  .team-member-row:after {
    content: '';
    position: relative;
    display: block;
    margin: 10px auto;
    height: 1px;
    width: 30%;
    background: ${Colors.Green100};
  }

  @media (min-width: 800px) {
    ${x};
    ${children};
  }

  @media (min-width: ${screen('phablet', 1)}) {
    .team-member-row:after {
      display: none;
    }
  }
`

export const Row = styled.div`
  ${x};
  ${children};

  & .rp-state-dropdown {
    width: 100%;
  }
`

export const Footer = styled.footer`
  ${x};
  margin: 1em 0;
`

export const Submit = styled(Button)``
