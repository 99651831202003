// Import Packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup } from 'semantic-ui-react'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import List from 'semantic-ui-react/dist/commonjs/elements/List'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'
import { StyledSegment } from '../Styled'

// Import Colors
import Colors from 'design/Colors'

// Import Fonts
import { fontStyle } from 'design/Fonts'

interface Props {
  background: string
}

const TopSegment = styled(Segment)`
  &.ui.segment {
    align-items: center;
    display: flex;
    flex-direction: column;
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin-bottom: 0px;
    margin-top: 0px;
    justify-content: flex-end;
    font-family: ${fontStyle};
    max-height: 40%;
    padding: 0px 2px;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      margin-bottom: 10px;
    }
    @media ( min-width: 1200 ) and (max-width: 1400px) {
      margin-bottom: 20px;
    }
  }
`

const Container = styled.div`
  height: 335px;
  min-height: 335px;
  max-height: 335px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ( min-width: 991px ) and (max-width: 1200px) {
    height: 240px;
    min-height: 240px;
    max-height: 250px;
  }
  @media ( min-width: 1200px ) and (max-width: 1400px) {
    height: 270px;
    min-height: 270px;
    max-height: 270px;
  }
  @media ( min-width: 1400px ) {
    height: 335px;
    min-height: 335px;
    max-height: 335px;
  }
`

const ProfileImageContainer = styled(Image)`
  &.ui.image {
    position: relative;
    width: 100%;
    min-height: 40%;
    height: inherit;
    max-height: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .changeImageIcon {
      position: absolute;
      bottom: 15px;
      right: 10px;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: white;
      background-size: 21px;
      box-shadow: 0px 0px 5px 2px ${Colors.Black1000};
      @media ( min-width: 991px ) and (max-width: 1200px) {
        right: 20%;
        height: 30px;
        width: 30px;
        background-size: 18px;
      }
      @media ( min-width: 1200px ) {
        right: 18%;
        height: 35px;
        width: 35px;
        background-size: 21px;
      }
    }
  }
`

const Name = styled(StyledSegment)`
  &.ui.segment {
    width: 100%;
    text-transform: capitalize;
    background-color: transparent;
    text-align: center;
    font-size: 14.5px;
    font-weight: bold;
    padding: 9px 16px 0px 16px;
    color: ${Colors.Black500};
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 13px;
    }
    @media ( min-width: 1200px ) {
      font-size: 14.5px;
    }
  }
`

const ContactView = styled(StyledSegment)`
  &.ui.segment {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 4px;
  }
`

const RankView = styled(StyledSegment)`
  &.ui.segment {
    width: 100%;
    background-color: transparent;
    text-align: center;
    font-size: 14.5px;
    padding: 0px 16px 9px 16px;
    color: ${Colors.DarkBlue200};
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 13px;
    }
    @media ( min-width: 1200px ) {
      font-size: 14.5px;
    }
  }
`

const ReferralView = styled(StyledSegment)`
  .referral {
    flex-grow: 1;
    cursor: pointer;
  }

  .referral>div {
    font-style: italic;
  }

  .svg-inline--fa {
    margin-right: 10px;
    width: 10px;
    height: 10px;
  }

  &.ui.segment {
    background: ${Colors.DarkBlue215};
    margin-bottom: 10px !important;
    width: 100%;
    margin-top: 15px;
    display: flex;
    padding: 12px;
    color: ${Colors.Black500};
    font-size: 13px;
    align-items: center;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 12px;
    }
    @media ( min-width: 1200px ) {
      font-size: 13px;
    }
  }
`

const ReferralValue = styled.div`
  display: inline;
  font-style: italic;
  text-decoration: underline;
`

interface ColorProps {
  color: string
}

const ColorView = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props: ColorProps) => `${props.color}`};
  line-height: 100%;
  align-self: center;
  margin-right: 10px;
  margin-left: 5px;
  display: inline-block;
`

const ListItemContent = styled(List.Content)`
  .ui.list > .item > &.content {
    display: flex;
  }
`

interface ImageProps {
  backgroundImage: string
}

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 17px;
  height: 17px;
  align-self: center;
  margin: 0px 5px;
  display: inline-block;
  cursor: pointer;
`

const Ul = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  margin-right: 25px;
`

const Li = styled.li`
  width: 12px;
  display: inline-block;
  padding: '0';
`

const MemberProfile = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: center;
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
`

const Label = styled.div`
  width: 12px;
  height: 12px;
  background: ${Colors.Red120};
  border: 1px solid ${Colors.DarkBlue220};
  border-radius: 50%;
  color: white;
  margin-top: 10px;
  margin-left: 11px;
  display: flex;

  .fa-usd-circle {
    width: 18px;
    height: 18px;
    color: ${Colors.Orange410};
    border: 1px solid;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: 3px;
  }
`

const StyledLabel = styled.div`
  text-align: center;
  margin-top: -4px;
  color: ${Colors.DarkBlue220};
  font-weight: bold;
  font-size: 8px;
  margin-left: 2px;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: ${Colors.Black500};
  }
`

const SocialButton = styled.div`
  height: 25px;
  cursor: pointer;
`

const SocialAction = styled(FontAwesomeIcon)`
  font-size: 28px;
  color: ${Colors.DarkBlue200};
`

export {
  MemberProfile,
  Label,
  StyledLabel,
  StyledPopup,
  ColorView,
  ContactView,
  Container,
  ImageWrapper,
  ListItemContent,
  Name,
  ProfileImageContainer,
  RankView,
  ReferralValue,
  ReferralView,
  TopSegment,
  Ul,
  Li,
  SocialButton,
  SocialAction
}
