// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Button, Form, Icon, Input, TextArea } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Images And Icons
import { cancel, check } from 'design/icons/dashboard'

// Import Component
import { handleValidation } from './Validation'

// Import Queries And Mutation
import {
  createPublicNotification,
  deleteCreatedByPublicNotification,
  deletePublicNotification,
  updatePublicNotification
} from '../NotificationsMutations'
import { getPublicNotifications } from '../NotificationsQueries'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'
import { FormErrorType, FormType, PublicNotifications } from './Types'

// Import Styled Components
import {
  Container,
  Date,
  Description,
  Discard,
  Icons,
  NoAlerts,
  Profile,
  StyledForm,
  StyledNotes,
  StyledPopup,
  Title
} from './Styled'

interface Props {
  unReadNotificationCounterUpdate: () => void
  stopTabClick: (data: boolean) => void
}

interface State {
  form: FormType
  id: string
  note: string
  showEditButton: boolean
  showConfirmationModal: boolean
  readMessage: boolean
  showAddPublicNotification: boolean
  userRole: string
  userID: string
  publicNotifications: PublicNotifications[]
  edit: boolean
}

class Updates extends React.Component<Props, State> {
  public state = {
    form: {
      errors: {} as FormErrorType
    } as FormType,
    edit: false,
    id: '',
    note: '',
    publicNotifications: [],
    readMessage: false,
    showAddPublicNotification: false,
    showConfirmationModal: false,
    showEditButton: false,
    userID: '',
    userRole: ''
  }

  public async componentDidMount() {
    const user: any = await getLoggedInUser({ fromCache: true })
    const response = await getPublicNotifications()
    this.setState({ userRole: user[`role`], userID: user[`_id`], publicNotifications: response })
  }

  public render() {
    const {
      edit,
      showAddPublicNotification,
      userRole,
      publicNotifications,
      userID,
      form,
      form: { errors },
    } = this.state
    const notifications = publicNotifications.map((items: any, index: any) => {
      return (
        <React.Fragment key={index}>
          <Container>
            <div className="title">
              <Profile background={items.senderId.profileImage} />
              <div className='icon'>
                {userRole === 'ADMIN' && (
                  <Icon
                    className="edit"
                    name="edit outline"
                    onClick={() => this.editNotification(items)}
                  />
                )}
                {userID === items.senderId._id && (
                  <Icon
                    className="trash"
                    name="trash alternate outline"
                    onClick={() => this.deleteCreatedByPublicNotification(items._id)}
                  />
                )}
                <StyledPopup
                  trigger={
                    items.isViewed ? (
                      <Icons className="check" src={check} />
                    ) : (
                        <Icons className="check" src={cancel} onClick={() => this.messageRead(items._id)} />
                      )
                  }
                  content={items.isViewed ? 'Message read' : `Mark as read`}
                  position="top center"
                />
              </div>
            </div>
            <div className='description'>
              <div className='date'>
                <Title>{items.title}</Title>
                <Date>{moment(items.createdAt).fromNow()}</Date>
              </div>
              <Description
                onClick={() => {
                  this.toggleShowHide(`my${index}`, items._id, items.isViewed)
                }}
                space={!items.readMessage ? 'nowrap' : 'normal'}
              >
                <div id={`my${index}`}>{items.content}</div>
                <Icon name="caret down" />
              </Description>
            </div>
          </Container>
        </React.Fragment>
      )
    })
    return (
      <div>
        <StyledNotes>
          {showAddPublicNotification || edit ? (
            <div className="add-notification">
              <StyledForm size={'small'}>
                <Form.Field
                  control={Input}
                  label="Title"
                  name="title"
                  placeholder="Title"
                  onChange={this.handleChange}
                  error={!!errors.title}
                  value={form.title}
                />
                <Form.Field
                  control={TextArea}
                  row={5}
                  label="Message"
                  name="content"
                  placeholder="Message"
                  onChange={this.handleChange}
                  error={!!errors.content}
                  value={form.content}
                />
                <Form.Group>
                  {
                    edit ? <Button content={'UPDATE'} onClick={this.updatePublicNotification} /> :
                      <Button content={'SAVE'} onClick={this.savePublicNotification} />
                  }
                  <Button content={'CANCEL'} onClick={this.closeAddPublicNotification} />
                </Form.Group>
              </StyledForm>
            </div>
          ) : notifications.length !== 0 ? (
            notifications
          ) : (
                <NoAlerts>
                  <div>{Strings.settings.notification.all}</div>
                </NoAlerts>
              )}
        </StyledNotes>
        {(!showAddPublicNotification && !edit) && (
          <Discard>
            {(userRole === 'ADMIN' || userRole === 'MANAGER') && (
              <Button onClick={this.showAddPublicNotification}>
                <Icon name="plus" /> {Strings.settings.notification.createNotification}
              </Button>
            )}
            {notifications.length !== 0 &&
              !showAddPublicNotification && (
                <Button onClick={this.discardUpdateMessage}>
                  <Icon name="check" /> {Strings.settings.notification.discard}
                </Button>
              )}
          </Discard>
        )}
      </div>
    )
  }

  private editNotification = (data: PublicNotifications) => {
    this.setState({
      edit: true,
      form: {
        ...data,
        errors: {} as FormErrorType
      },
    })
  }

  private toggleShowHide = (index: string, id: string, isViewed: boolean) => {
    const x = document.getElementById(index)
    if (x) {
      if (x.style.whiteSpace === 'normal') {
        x.style.whiteSpace = 'nowrap'
      } else {
        x.style.whiteSpace = 'normal'
      }
    }
    if (!isViewed) {
      this.readUpdateMessage(id)
    }
  }

  private messageRead = (id: string) => {
    this.readUpdateMessage(id)
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private savePublicNotification = async () => {
    const { unReadNotificationCounterUpdate } = this.props
    const { form } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    const response = await createPublicNotification(form)
    this.setState({ publicNotifications: response })
    this.closeAddPublicNotification()
    unReadNotificationCounterUpdate()
  }

  private updatePublicNotification = async () => {
    const { unReadNotificationCounterUpdate } = this.props
    const { form } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    const newData = {
      id: form._id,
      ...form
    }
    const response = await updatePublicNotification(newData)
    this.setState({
      publicNotifications: response, edit: false, form: {
        errors: {} as FormErrorType
      } as FormType,
    })
    this.closeAddPublicNotification()
    unReadNotificationCounterUpdate()
  }

  private discardUpdateMessage = async () => {
    const { unReadNotificationCounterUpdate } = this.props
    const response = await deletePublicNotification()
    unReadNotificationCounterUpdate()
    this.setState({ publicNotifications: response })
  }

  private deleteCreatedByPublicNotification = async (id: string) => {
    const { unReadNotificationCounterUpdate } = this.props
    ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this notification!',
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        const response = await deleteCreatedByPublicNotification(id)
        unReadNotificationCounterUpdate()
        this.setState({ publicNotifications: response })
        ConfirmAlert('Deleted!', 'Your notification has been deleted.', 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'Your notification is safe', 'error')
      }
    })
  }

  private showAddPublicNotification = () => {
    const { stopTabClick } = this.props
    this.setState({
      showAddPublicNotification: true
    })
    stopTabClick(true)
  }

  private closeAddPublicNotification = () => {
    const { stopTabClick } = this.props
    this.setState({
      form: {
        errors: {} as FormErrorType
      } as FormType,
      showAddPublicNotification: false,
      edit: false
    })
    stopTabClick(false)
  }

  private readUpdateMessage = async (id: string) => {
    const { unReadNotificationCounterUpdate } = this.props
    const newData = {
      id: id,
      isViewed: true
    }
    const response = await updatePublicNotification(newData)
    this.setState({ publicNotifications: response })
    unReadNotificationCounterUpdate()
  }
}

export default Updates
