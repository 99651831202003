// Import Graphql Mutations

import client from 'queries/apollo'
import { CREATE_EVENT, DELETE_EVENT, UPDATE_EVENT } from 'queries/graphql/Events/Mutations'

import { EventType } from 'store/Tasks/Types'

export const createEvent = async (newEvent: EventType, edit: boolean) => {
  let result: any = null
  if (edit) {
    result = await client.mutate({
      mutation: UPDATE_EVENT,
      variables: {
        eventId: newEvent._id,
        ...newEvent
      }
    })
  } else {
    result = await client.mutate({
      mutation: CREATE_EVENT,
      variables: { ...newEvent }
    })
  }

  return result
}

export const deleteEvent = async (eventId: string) => {
  const response = await client.mutate({
    mutation: DELETE_EVENT,
    variables: { eventId }
  })

  return response.data.deleteEvent
}
