import gql from 'graphql-tag'

export const CREATE_TASK_BOARD = gql`
  mutation createTaskBoard($name: String, $user: String, $order: Int) {
    createTaskBoard(input: { name: $name, user: $user, boardOrder: $order }) {
      _id
      name
      boardOrder
      icon {
        key
        imageUrl
      }
      description
      tasks {
        _id
        type
        name
        description
        taskTime
        notes {
          _id
          description
          createdBy {
            _id
            firstName
            profileImage
          }
          createdAt
        }
        activities {
          _id
          type
          task {
            name
          }
          description
          updatedAt
        }
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
        }
        lead {
          _id
          firstName
          lastName
        }
        sharedWith {
          _id
          firstName
          lastName
          userName
          profileImage
        }
        dueDate
        completionDate
        label {
          _id
          name
          icon
          colour
        }
        isActive
        status
      }
    }
  }
`

export const UPDATE_TASK_BOARD_ORDER = gql`
  mutation updateTaskBoardOrder(
    $boardId: ID
    $sourceIndex: Int
    $destinationIndex: Int
    $userId: String
  ) {
    updateTaskBoardOrder(
      where: { _id: $boardId, boardOrder: $sourceIndex, user: $userId }
      input: { boardOrder: $destinationIndex }
    ) {
      _id
      name
      boardOrder
      icon {
        key
        imageUrl
      }
      description
      tasks {
        _id
        type
        name
        description
        taskTime
        notes {
          _id
          description
          createdBy {
            _id
            firstName
            profileImage
          }
          createdAt
        }
        activities {
          _id
          type
          task {
            name
          }
          description
          updatedAt
        }
        createdBy {
          _id
          firstName
          lastName
          userName
          profileImage
        }
        lead {
          _id
          firstName
          lastName
        }
        sharedWith {
          _id
          firstName
          lastName
          userName
          profileImage
        }
        dueDate
        completionDate
        label {
          _id
          name
          icon
          colour
        }
        isActive
        status
      }
    }
  }
`

export const DELETE_TASK_BOARD = gql`
  mutation deleteTaskBoard($boardId: String) {
    deleteTaskBoard(_id: $boardId)
  }
`
