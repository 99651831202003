import gql from 'graphql-tag'

export const UPDATE_STATE = gql`
  mutation updateState($id: ID, $input: StateInput) {
    updateState(_id: $id, input: $input) {
      _id
    }
  }
`

export const UPDATE_STATE_ORDER = gql`
  mutation updateStateOrder($board: ID, $source: Int, $destination: Int) {
    updateStateOrder(
      where: { _id: $board, stateOrder: $source }
      input: { stateOrder: $destination }
    ) {
      _id
      name
      isActive
      stateOrder
    }
  }
`

export const GET_OR_CREATE_STATE = gql`
  mutation createState($input: StateInput) {
    createState(input: $input) {
      _id
      description
      name
      stateOrder
    }
  }
`
