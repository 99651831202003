// Import Packages
import { debounce, set, unset } from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'

// Import Components
import { formatKanbanData, formatListData } from '../Utils/FormattingData'
import TaskSearchModal from './TaskSearchModal'

// Import Graphql Queries
import { getMyTaskBoardsSearch, getMyTasksSearch, getTaskBoardsSearch, getTasksSearch } from '../TaskQueries'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Tasks/Actions'
import { Filter, FiltersData, FilterText, Options, TaskDetails, TaskItemsDetails, UserType } from 'store/Tasks/Types'

import { getLoggedInUser } from 'utils'

// Import Styled Components
import {
  Plus,
  SearchContainer,
  SearchInput,
  SearchWrapper,
  Tag,
  TagContainer,
} from './Styled'

// Font Awesome Icons
import {
  faSearch,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faSearch, faTimes])

interface StoreProps {
  setFilter: (data: Filter) => void
  setSearchLoader: (open: boolean) => void
  setSearchDataFilter: (data: FiltersData) => void
  setBoardData: (data: TaskDetails[]) => void
  setListData: (data: TaskItemsDetails[]) => void
  allTaskStatus: boolean
  filters: Filter
}

type Props = StoreProps

interface State {
  open: boolean
  searchText: string
  filterText: FilterText[]
  filter: FiltersData
  data: FiltersData
  selectedCity: Options
  selectedState: Options
}

class TaskSearchBar extends React.Component<Props, State> {
  public debounceJob: any = null

  public state = {
    data: {
      dueDate: undefined,
      name: undefined,
      type: undefined,
    },
    filter: {},
    filterText: [],
    open: false,
    searchText: '',
    selectedCity: {
      key: '',
      text: '',
      value: ''
    },
    selectedState: {
      key: '',
      text: '',
      value: ''
    },
  }

  public async componentWillReceiveProps(nextProps: any) {
    const { filters } = this.props
    if (this.props.allTaskStatus !== nextProps.allTaskStatus) {
      if (filters.filter === true || filters.searchText === true) {
        const { setSearchLoader, allTaskStatus, setSearchDataFilter } = this.props
        const { filter } = this.state
        setSearchDataFilter(filter)
        setSearchLoader(true)
        const { setBoardData, setListData } = this.props
        const user: UserType = await getLoggedInUser({ fromCache: true })
        let res: TaskDetails[] = []
        let listData: TaskItemsDetails[] = []
        if (allTaskStatus === true) {
          res = await getTaskBoardsSearch(filter)
          listData = await getTasksSearch(filter)
        } else {
          res = await getMyTaskBoardsSearch(user[`_id`], filter)
          listData = await getMyTasksSearch(user[`_id`], filter)
        }
        const formatedData = await formatKanbanData(res, allTaskStatus)
        const formatedListData = await formatListData(listData, allTaskStatus)
        setBoardData(formatedData)
        setListData(formatedListData)
        if (res && listData) {
          setSearchLoader(false)
        }
      }
    }
  }

  public render() {
    const { filterText, open, searchText, data, selectedState, selectedCity } = this.state
    return (
      <SearchWrapper>
        <SearchContainer>
          <div style={{ display: filterText.length === 0 ? 'none' : 'flex' }}>
            <TagContainer>
              {
                filterText.map((items: any, index: number) => (
                  <Tag key={index}>
                    {items.content}
                    <span className='times' onClick={() => this.deleteFilterText(items.id, items.text)}><FontAwesomeIcon icon={['far', 'times']} /></span>
                  </Tag>
                ))
              }
            </TagContainer>
            <Plus>+</Plus>
          </div>
          <SearchInput
            icon={
              searchText ?
                <span>
                  <span onClick={this.closeSearcModal}>
                    <FontAwesomeIcon icon={['far', 'search']} style={{ right: '25px' }} />
                  </span>
                  <span onClick={this.clearState}><FontAwesomeIcon icon={['far', 'times']} /></span>
                </span> :
                <span onClick={this.closeSearcModal}>
                  <FontAwesomeIcon icon={['far', 'search']} style={{ right: '6px' }} />
                </span>
            }
            placeholder='Search'
            onClick={this.showAdvanceSearcModal}
            onChange={this.handleChange}
            value={searchText} />
        </SearchContainer>
        {
          open &&
          <TaskSearchModal
            data={data}
            filterText={filterText}
            selectedCity={selectedCity}
            selectedState={selectedState}
            filter={this.filter}
            filtersContent={this.filtersContent}
            closeAdvanceSearchModal={this.showAdvanceSearcModal} />
        }
      </SearchWrapper>
    )
  }

  private filter = (data: FiltersData, state: Options, city: Options) => {
    const newList = this.state.filterText.slice()
    this.setState({ data, selectedState: state, selectedCity: city })
    const searchObj = {}
    newList.forEach((element: any) => {
      if (this.state.searchText !== undefined && this.state.searchText !== '') {
        searchObj['searchText'] = this.state.searchText
      }
      if (element.text in data) {
        searchObj[element.text] = data[element.text]
      }
    })
    this.setState({
      filter: searchObj
    }, () => this.searchingForBothView())
  }

  private searchingForBothView = async () => {
    const { setSearchLoader, allTaskStatus, setSearchDataFilter } = this.props
    const { filter } = this.state
    setSearchDataFilter(filter)
    setSearchLoader(true)
    const { setBoardData, setListData } = this.props
    const user: UserType = await getLoggedInUser({ fromCache: true })
    let res: TaskDetails[] = []
    let listData: TaskItemsDetails[] = []
    if (allTaskStatus === true) {
      res = await getTaskBoardsSearch(filter)
      listData = await getTasksSearch(filter)
    } else {
      res = await getMyTaskBoardsSearch(user[`_id`], filter)
      listData = await getMyTasksSearch(user[`_id`], filter)
    }
    const formatedData = await formatKanbanData(res, allTaskStatus)
    const formatedListData = await formatListData(listData, allTaskStatus)
    setBoardData(formatedData)
    setListData(formatedListData)
    if (res && listData) {
      setSearchLoader(false)
    }
  }

  private deleteFilterText = (id: string, text: string) => {
    const { setFilter } = this.props
    const { filterText, searchText } = this.state
    const newList = filterText.slice()
    if (this.state.searchText === '') {
      unset(this.state.filter, 'searchText')
    }
    unset(this.state.filter, text)
    const listIndex = newList.findIndex((item: any) => {
      return item.id === id
    })
    newList.splice(listIndex, 1)
    const newData = {
      filter: newList.length !== 0 ? true : false,
      searchText: searchText ? true : false
    }
    setFilter(newData)
    this.setState({ filterText: newList })
    this.searchingForBothView()
  }

  private defaultFilter = (data: object) => {
    const newList = this.state.filterText.slice()
    this.setState({ data })
    const searchObj = {}
    newList.forEach((element: any) => {
      if (this.state.searchText !== undefined && this.state.searchText !== '') {
        searchObj['searchText'] = this.state.searchText
      }
      if (element.text in data && data[element.text] !== undefined) {
        searchObj[element.text] = data[element.text]
      }
    })
    this.setState({
      filter: searchObj
    }, () => {
      this.searchingForBothView()
    })
  }

  private filtersContent = (id: string, content: string, text: string, searchData: FiltersData) => {
    const { filterText, searchText } = this.state
    const { setFilter } = this.props
    if (filterText.length !== 0) {
      const strIndex = filterText.findIndex((item: any) => {
        return item.id === id
      })
      if (strIndex !== -1) {
        return
      }
      const newContent = [...filterText, { id: id, content: content, text: text }]
      this.setState({ filterText: newContent }, () => this.defaultFilter(searchData))
      const newData = {
        filter: true,
        searchText: searchText ? true : false
      }
      setFilter(newData)
    } else {
      const newContent = [...filterText, { id: id, content: content, text: text }]
      this.setState({ filterText: newContent }, () => this.defaultFilter(searchData))
      const newData = {
        filter: true,
        searchText: searchText ? true : false
      }
      setFilter(newData)
    }
  }

  private clearState = () => {
    this.setState({
      data: {
        dueDate: undefined,
        name: undefined,
        type: undefined,
      },
      filter: {},
      filterText: [],
      searchText: '',
      selectedCity: {
        key: '',
        text: '',
        value: ''
      },
      selectedState: {
        key: '',
        text: '',
        value: ''
      },
    }, () => this.searchingForBothView())
    unset(this.state.filter, 'searchText')
  }

  private searchTextDebounce = async () => {

    if (this.debounceJob) {
      this.debounceJob.cancel()
    }

    this.debounceJob = debounce(() => {
      this.searchingForBothView()
    }, 1000)

    this.debounceJob()

  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const { filterText } = this.state
    const { setFilter } = this.props
    this.setState({ searchText: value, open: false }, () => {
      const newData = {
        filter: filterText.length !== 0 ? true : false,
        searchText: value ? true : false
      }
      setFilter(newData)
    })
    set(this.state.filter, 'searchText', value)
    if (this.state.filter['searchText'] === '') {
      unset(this.state.filter, 'searchText')
    }
    this.searchTextDebounce()
  }

  private closeSearcModal = () => {
    this.setState({ open: false })
  }

  private showAdvanceSearcModal = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }
}

const mapStateToProps = (state: AppState) => ({
  allTaskStatus: state.tasks.allTaskStatus,
  data: state.tasks.data,
  filters: state.tasks.filter,
  listData: state.tasks.listData,
})

export default connect(
  mapStateToProps,
  {
    setBoardData: Actions.getBoardData,
    setFilter: Actions.filter,
    setListData: Actions.getListData,
    setSearchDataFilter: Actions.searchDataFilter,
    setSearchLoader: Actions.searchLoader,
  }
)(TaskSearchBar)
