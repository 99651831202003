export const handleValidation = (data: object) => {
  const fields = data
  const errors = {}
  let formIsValid = true

  if (!fields[`firstName`] || fields[`firstName`].trim() === '') {
    formIsValid = false
    errors[`firstName`] = true
  }

  if (!fields[`lastName`] || fields[`lastName`].trim() === '') {
    formIsValid = false
    errors[`lastName`] = true
  }

  if (!fields[`role`]) {
    formIsValid = false
    errors[`role`] = true
  }

  if (!fields[`phone`]) {
    formIsValid = false
    errors[`phone`] = true
  }

  if (
    !fields[`userName`] ||
    fields[`userName`].trim() === '' ||
    !fields[`userName`].match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    formIsValid = false
    errors[`userName`] = true
  }

  // if (!fields[`brokerageName`] || fields[`brokerageName`].trim() === '') {
  //   formIsValid = false
  //   errors[`brokerageName`] = true
  // }

  return { errors, formIsValid }
}
