// Import Components
import client from 'queries/apollo'

// Import Graphql
import {
  CREATE_MESSAGE_EMAIL_ATTACHMENT,
  DELETE_MESSAGE_EMAIL_ATTACHMENT
} from 'queries/graphql/MessageEmail/Mutations'

export const createAttachment = async (file: any, type: string) => {
  try {
    const mutation = await client.mutate({
      mutation: CREATE_MESSAGE_EMAIL_ATTACHMENT,
      variables: {
        file,
        type
      }
    })

    return mutation
  } catch {
    return null
  }
}

export const deleteAttachment = async (id: string) => {
  try {
    const mutation = await client.mutate({
      mutation: DELETE_MESSAGE_EMAIL_ATTACHMENT,
      variables: { id }
    })

    return mutation
  } catch {
    return null
  }
}
