import { Grid, Header, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { x } from 'design/Styled'

interface Props {
  background?: string
}

export const Container = styled.div`
  ${x};
  padding: 1em;
`

export const StyledGrid = styled(Grid)`
  &.ui.grid {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  &.ui.grid>.row {
    overflow: auto;
    overflow-y: hidden;
    display: inline-block;
    white-space: nowrap;
  }

  &.ui.grid>[class*="ten column"].row>.column {
    padding: 0;
  }

  &.ui.grid>.row::-webkit-scrollbar-thumb {
    background-color: ${Colors.White1000};
  }

  &.ui.grid>.row::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${Colors.Grey120};
  }

  @media (max-width: 1200px) {
    &.ui.grid>[class*="ten column"].row>.column {
      width: 12% !important;
    }
  }
`

export const StyledSegment = styled(Segment)`
  &.ui.segment {
    margin: 0;
  }

  &.ui.compact.segment {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    flex: 1 !important;
    margin: 0 10px;
    text-align: center;
    padding: 8px 4px;
    padding-top: 20px;
    cursor: pointer;
    color: ${(props) => props.color || Colors.Black500};
  }

  .fa-plus {
    width: 20px !important;
  }

  .svg-inline--fa {
    width: 100%;
    height: 100%;
  }

  &.ui.compact.segment.active-segment {
    transform: scale(1.1);
    margin-left: 5px;
    margin-right: 15px;
    background-color: ${Colors.DarkBlue200};
    color: ${Colors.White1000};
  }
`

export const Title = styled(Header)`
  &.ui.header {
    font-size: 12px;
    margin-top: 12px;
    color: ${(props: Props) => props.background || Colors.Black500};
  }
`
