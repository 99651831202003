// Import Utils
import { generateID } from 'utils'

export const transactionMockData = [
  {
    _id: generateID(),
    filterCategory: 'MLS Number',
    filterId: 'mlsId',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Type',
    filterId: 'transactionRole',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Status',
    filterId: 'status',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Transaction Owner Name',
    filterId: 'owner',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Address',
    filterId: 'address',
    isChecked: false,
  },
]

export const leadMockData = [
  {
    _id: generateID(),
    filterCategory: 'Lead Name',
    filterId: 'name',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Type',
    filterId: 'type',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Location',
    filterId: 'address',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Source',
    filterId: 'source',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Status',
    filterId: 'status',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Property Type',
    filterId: 'propertyType',
    isChecked: false,
  },
]

export const contactMockData = [
  {
    _id: generateID(),
    filterCategory: 'Contact Name',
    filterId: 'name',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Type',
    filterId: 'type',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Location',
    filterId: 'address',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Source',
    filterId: 'source',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Birth/Anniversary Date',
    filterId: 'date',
    isChecked: false,
  },
]

export const userMockData = [
  {
    _id: generateID(),
    filterCategory: 'User Name',
    filterId: 'name',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Status',
    filterId: 'status',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Office Name',
    filterId: 'branchName',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Address',
    filterId: 'address',
    isChecked: false,
  },
]

export const teamMockData = [
  {
    _id: generateID(),
    filterCategory: 'Team Name',
    filterId: 'name',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Office Name',
    filterId: 'officeName',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Leader Name',
    filterId: 'leaderName',
    isChecked: false,
  },
]

export const officeMockData = [
  {
    _id: generateID(),
    filterCategory: 'Office Name',
    filterId: 'branchName',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Manager Name',
    filterId: 'managerName',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Address',
    filterId: 'address',
    isChecked: false,
  },
]

export const taskMockData = [
  {
    _id: generateID(),
    filterCategory: 'Task Name',
    filterId: 'name',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Due Date',
    filterId: 'dueDate',
    isChecked: false,
  },
  {
    _id: generateID(),
    filterCategory: 'Type',
    filterId: 'type',
    isChecked: false,
  },
]
