import * as React from 'react'

import Toast from 'shared/Toast'
import ImageEditor from 'shared/ImageEditor'
import QuillInline from 'shared/QuillInline'

import {
  Address,
  Category,
  CompanyLogo,
  Container,
  Description,
  Details,
  Footer,
  Header,
  Hero,
  Image,
  Info,
  Logo,
  MLSId,
  Price,
  Row,
  Text
} from './Styled'

import { ImageType as PropertyImageType } from 'app/Transactions/Details/Types'
import { CategoryEnum } from 'app/Transactions/Flyer/Types'
import { ImageType as EditorImageType } from 'shared/ImageEditor/Types'
import { DataType, InitType, TemplateEnumType } from './Types'

import { Strings } from 'utils'

interface Props {
  data: Partial<DataType>
  init: Partial<InitType>
  isPaid: boolean
  subCategoryName: string
  templateType: TemplateEnumType
  onUpdate: (data: Partial<DataType>) => void
  onReplaceImage: (item: string) => void
}

interface State {
  address: string
  companyLogo: EditorImageType
  price: string
}

const DEFAULT_IMAGE = { url: '' } as EditorImageType
const DEFAULT_TEXT = ''

class Template5 extends React.Component<Props, State> {
  public state = {
    address: '',
    companyLogo: {} as EditorImageType,
    price: ''
  }

  public componentDidMount = () => {
    const { init, onUpdate } = this.props

    const data = {
      image1: DEFAULT_IMAGE,
      image2: DEFAULT_IMAGE,
      image3: DEFAULT_IMAGE,
      image4: DEFAULT_IMAGE,
      text1: DEFAULT_TEXT
    } as DataType

    if (init.address) {
      data.text1 = `
        <p><span style="font-size: 16pt;">${init.description ||
          'Insert text here...'}</span></p>
      `
      this.setState({
        address: `
          <p>
            <strong style="font-size: 15pt; color: #fff; text-transform: capitalize;">${
              init.address.streetNumber
            } ${init.address.streetName}</strong>
          </p>
          <p>
            <strong style="font-size: 15pt; color: #fff; text-transform: capitalize;">${
              init.address.city
            }, ${init.address.state} ${init.address.zipCode}</strong>
          </p>
        `,
        price: `
          <span style="font-size: 28pt; color: #fff;">$${init.price &&
            init.price.toLocaleString()}</span>
        `
      })
    }
    if (init.images) {
      init.images.forEach((image: PropertyImageType, index: number) => {
        if (index < 4) {
          data[`image${index + 1}`] = { url: image.url }
        }
      })
    }
    onUpdate(data)

    this.setState({
      companyLogo: { url: init.companyLogo && init.companyLogo }
    })
  }

  public render() {
    const { data, init, isPaid, templateType, subCategoryName } = this.props

    const { address, companyLogo, price } = this.state

    return (
      <Container>
        <Header>
          <Category>
            {subCategoryName === 'All' ? CategoryEnum.Sold : subCategoryName}
          </Category>
        </Header>
        <Hero>
          <ImageEditor
            image={data.image1 || DEFAULT_IMAGE}
            onChange={(image: EditorImageType) =>
              this.handleImageChange('image1', image)
            }
            onClick={() => this.handleImageClick('image1')}
          />
        </Hero>
        <Details>
          <Row>
            <Image>
              <ImageEditor
                image={data.image2 || DEFAULT_IMAGE}
                onChange={(image: EditorImageType) =>
                  this.handleImageChange('image2', image)
                }
                onClick={() => this.handleImageClick('image2')}
              />
            </Image>
            <Image>
              <ImageEditor
                image={data.image3 || DEFAULT_IMAGE}
                onChange={(image: EditorImageType) =>
                  this.handleImageChange('image3', image)
                }
                onClick={() => this.handleImageClick('image3')}
              />
            </Image>
            <Image>
              <ImageEditor
                image={data.image4 || DEFAULT_IMAGE}
                onChange={(image: EditorImageType) =>
                  this.handleImageChange('image4', image)
                }
                onClick={() => this.handleImageClick('image4')}
              />
            </Image>
          </Row>
          <Description>
            <Info>
              <Address>
                <Text
                  dangerouslySetInnerHTML={{ __html: address || DEFAULT_TEXT }}
                />
              </Address>
              <Price>
                <Text
                  dangerouslySetInnerHTML={{ __html: price || DEFAULT_TEXT }}
                />
              </Price>
            </Info>
            <Footer>
              <QuillInline
                isPaid={isPaid}
                templateType={templateType}
                html={data.text1 || DEFAULT_TEXT}
                onChange={(
                  content: any,
                  delta: any,
                  source: any,
                  editor: any
                ) => this.handleTextChange('text1', content)}
              />
            </Footer>
          </Description>
        </Details>
        <CompanyLogo>
          <MLSId>
            {Strings.flyer.listing} {init.mlsId ? init.mlsId : '-'}{' '}
            {Strings.flyer.courtesy}
          </MLSId>
          <Logo>
            <ImageEditor
              image={companyLogo || DEFAULT_IMAGE}
              onChange={(image: EditorImageType) =>
                this.handleImageChange('companyLogo', image)
              }
              onClick={() => ''}
            />
          </Logo>
        </CompanyLogo>
      </Container>
    )
  }

  private handleTextChange = (item: string, content: string) => {
    const { data, onUpdate } = this.props

    const update = { ...data }
    update[item] = content
    onUpdate(update)
  }

  private handleImageChange = (item: string, image: EditorImageType) => {
    const { data, onUpdate } = this.props

    const update = { ...data }
    update[item] = image
    onUpdate(update)

    if (item === 'companyLogo') {
      this.setState({
        companyLogo: image
      })
    }
  }

  private handleImageClick = async (item: string) => {
    const { onReplaceImage, isPaid } = this.props
    if (isPaid) {
      onReplaceImage(item)
    } else {
      Toast({ message: Strings.flyer.paidMessage, type: 'error' })
    }
  }
}

export default Template5
