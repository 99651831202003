import { UserPassType, PassStatusEnum } from 'store/Pass/Types'

export type ID = string

export enum ModeEnum {
  None,
  Agent,
  Admin,
}

export enum ActionEnum {
  Loading,
  Update,
}

export interface TransitionType {
  modal?: boolean
  pass?: boolean
  paywall?: boolean
}

export interface AdminWebsitesPerMlsType {
  [mlsId: string]: ID
}

export interface WebsitesPerMlsType {
  [mlsId: string]: WebsiteListType
}

export interface AdminFeeEditorPerMlsType {
  [mlsId: string]: boolean
}

export interface WebsiteListType {
  list: ID[]
  pagination: UserPassType.Pagination
}

export const TOGGLE_OPTIONS = [
  {label: 'Inactive', value: PassStatusEnum.Inactive },
  {label: 'Active', value: PassStatusEnum.Active },
]
