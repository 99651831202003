import { CompanyFormErrorType, CompanyFormType, TeamFormErrorType, TeamFormType } from './Types'

import { validateAddress } from 'shared/AddressInput/Validation'
import { validDomainName, validEmail, validName } from 'utils/Validation'

export const validateCompany = (form: CompanyFormType, companyName: string) => {
  let errors = {} as CompanyFormErrorType
  let formIsValid = true

  if (!form.name) {
    formIsValid = false
    errors.name = `${companyName} can not be blank`
  } else if (form.name.length > 200) {
    formIsValid = false
    errors.name = `${companyName} can not be longer than 200 characters`
  }

  if (!form.domain) {
    formIsValid = false
    errors.domain = `Domain name can not be blank`
  } else if (!form.domain.match(validDomainName)) {
    formIsValid = false
    errors.domain = `Domain name can not include special characters, maximum length is 20`
  }

  if (!form.mls) {
    formIsValid = false
    errors.mls = `Please select an MLS`
  }

  const address = validateAddress(form)
  if (!address.formIsValid) {
    formIsValid = false
    errors = {
      ...errors,
      ...address.errors
    }
  }

  return { errors, formIsValid }
}

export const validateTeamMember = (form: TeamFormType, index: number) => {
  const errors = {} as TeamFormErrorType

  let formIsValid = true

  if (!form.email || !form.email.match(validEmail)) {
    formIsValid = false
    errors.email = `Team member ${index} must have a valid email address`
  }

  if (!form.firstName) {
    formIsValid = false
    errors.firstName = `Team member ${index}'s first name can not be blank`
  } else if (!form.firstName.match(validName)) {
    formIsValid = false
    errors.firstName = `Team member ${index}'s first name can only include letters, dashes and apostrophes`
  }

  if (!form.lastName) {
    formIsValid = false
    errors.lastName = `Team member ${index}'s last name can not be blank`
  } else if (!form.lastName.match(validName)) {
    formIsValid = false
    errors.lastName = `Team member ${index}'s last name can only include letters, dashes and apostrophes`
  }

  return { errors, formIsValid }
}
