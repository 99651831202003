import client from 'queries/apollo'

import { RESET_PASSWORD } from 'queries/graphql/Users/Mutation'

export const resetPassword = (data: any) => {
  const { newPassword, token } = data
  return client.mutate({
    mutation: RESET_PASSWORD,
    variables: {
      newPassword,
      token
    }
  })
}
