import * as React from 'react'
import SliderGroup from './SliderGroup'
import {
  ContentSegment,
  LabelSegment,
  StyledWrapper
} from './Styled'

const SettingItem = ({
  disabled,
  index,
  info,
  max,
  min,
  name,
  onChange,
  value
}: {
  disabled: boolean
  index: number
  info?: string
  max: number
  min: number
  name: string
  value: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <StyledWrapper horizontal={true}  >
      <LabelSegment size={'tiny'} basic={true}>{name}
      </LabelSegment>
      <ContentSegment basic={true}>
        <SliderGroup title={''} min={min} max={max} value={value} onChange={onChange} index={index} disabled={disabled} />
      </ContentSegment>
    </StyledWrapper>
  )
}

export default SettingItem
