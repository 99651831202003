import { TextArea } from 'semantic-ui-react'
import styled, { css, keyframes } from 'styled-components'

import Colors from 'design/Colors'
import { fontStyle } from 'design/Fonts'
import { scrollbars } from 'design/Styled'

import { MarkupLabelEnum, MarkupModeEnum } from '../Types'

interface Props {
  type?: string
  mode?: MarkupModeEnum
  dimensions?: {
    height: number
    left: number
    top: number
    width: number
    offset: number
  }
}

const dot = 20
const pulseAnimation = keyframes`
  0 {
    transform: scale3d(0,0,0);
    opacity: 0;
  }
  30% {
    opacity: 0.6;
  }
  100% {
    transform: scale3d(2,2,2);
    opacity: 0;
  }
`
const bounceAnimation = keyframes`
  0 {
    transform: scale3d(1.15,1.15,1.15);
  }
  40% {
    transform: scale3d(1,1,1);
  }
  100% {
    transform: scale3d(1.15,1.15,1.15);
  }
`
const noteStyle = css`
  background: ${Colors.TransparentWhite(0)};
  border-radius: 2px;
  & > * {
    opacity: 0;
  }
  &:hover {
    background: ${Colors.TransparentWhite(0.95)};
    box-shadow: 0 0 12px 0 ${Colors.TransparentBlack(0.1)};
    & > * {
      opacity: 1;
    }
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: ${dot * -0.5}px;
    left: ${dot * -0.5}px;
    width: ${dot * 0.75}px;
    height: ${dot * 0.75}px;
    border-radius: 50%;
    animation-duration: 1600ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  &:before {
    z-index: 0;
    box-shadow: 0 0 0 2px ${Colors.TextLink};
    transform: scale3d(0.1,0.1,0.1);
    opacity: 0;
    animation-name: ${pulseAnimation};
  }

  &:after {
    z-index: 1;
    border: ${dot * 0.25}px solid ${Colors.TextLink};
    background: ${Colors.Container};
    box-shadow: 0 0 19px -5px ${Colors.TransparentBlack(0.4)};
    transform: scale3d(1.15,1.15,1.15);
    animation-name: ${bounceAnimation};
  }
`

const getDimensions = ({ dimensions, mode }:Props) => {
  if (!dimensions) {
    dimensions = {
      height: 0,
      left: 0,
      top: 0,
      width: 0,
      offset: 4,
    }
  }

  const isEditor = (mode || 0) >= MarkupModeEnum.Editor
  const offset = isEditor ? dimensions.offset : 0
  const padding = isEditor ? 0 : dimensions.offset

  return css`
    top: ${dimensions.top + offset}px;
    left: ${dimensions.left + offset}px;
    width: ${dimensions.width - (offset * 2)}px;
    height: ${dimensions.height - (offset * 2)}px;
    padding: ${padding}px;
  `
}

const editingStyle = css`
  & > * {
    opacity: 1;
  }
  &:hover {
    background: ${Colors.TransparentWhite(0)};
    box-shadow: none;
  }
  &:before,
  &:after {
    display: none;
  }
`

const getStyle = ({ type, mode }: Props) => {
  const isEditing = (mode || 0) >= MarkupModeEnum.Editor

  switch (type || '') {
    case MarkupLabelEnum.Note:
      return css`
        ${noteStyle};
        ${isEditing ? editingStyle : ``};
      `

    default:
      return ``
  }
}

export const Container = styled.div`
  font-family: ${fontStyle};
  position: absolute;
  z-index: 99;
  background: ${Colors.TransparentWhite(0)};
  transition: background 200ms;
  ${getDimensions};
  ${getStyle};
`

export const StyledTextArea = styled(TextArea)`
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  color: ${Colors.TextDark};
  background: ${Colors.TransparentWhite(0)};
  border: none;
  outline: none;
  resize: none;
  cursor: text;
  transition: opacity 200ms;
  ${scrollbars};

  &:disabled {
    color: ${Colors.TextDark};
  }

  &:focus {
    background: ${Colors.TransparentWhite(0.95)};
    border: none;
    outline: none;
  }
`


export const TimeStamp = styled.span`
  position: absolute;
  white-space: nowrap;
  left: 100%;
  bottom: 0;
  margin-left: 4px;
  font-size: 12px;
`
