// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import Toast from 'shared/Toast'
import { CreateNewMyReport } from '../Content/Content'
import { handleValidation } from './Validation'

import { contactMockData, leadMockData, officeMockData, taskMockData, teamMockData, transactionMockData, userMockData } from '../Content/PickData/PickMockData'

// Import Store Types, Actions and Reducers
import * as Actions from 'store/Reports/Actions'
import { OptionsType, PickData, Toggle } from 'store/Reports/Types'
import { FormErrorType, FormType } from './Types'

// Import Store Types, Actions and Reducers
import { createMyReport, updateMyReport } from '../ReportMutations'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Styled Components
import {
  AddForm,
  AddLeftPanel,
  Container,
  StyledForm,
} from './Styled'

interface StoreProps {
  setPickData: (data: PickData[]) => void
  setShowSystemReport: (module: string) => void
  setChangeToggle: (data: Toggle) => void
}

interface OwnProps {
  closeModal: () => void
  edit?: boolean
  updateReport?: any
}


type Props = StoreProps & OwnProps

interface State {
  form: FormType
  animate: boolean
  loader: boolean
  id: string
  moduleOptions: OptionsType[]
}

const moduleOptionsForAgent = [
  { key: 1, value: 'Transaction', text: 'Transaction' },
  { key: 2, value: 'Lead', text: 'Lead' },
  { key: 3, value: 'Contact', text: 'Contact' },
  { key: 4, value: 'Task', text: 'Task' }
]

const moduleOptionsForAdmin = [
  { key: 1, value: 'Transaction', text: 'Transaction' },
  { key: 2, value: 'Lead', text: 'Lead' },
  { key: 3, value: 'Contact', text: 'Contact' },
  { key: 4, value: 'Task', text: 'Task' },
  { key: 5, value: 'User', text: 'User' },
  { key: 6, value: 'Team', text: 'Team' },
  { key: 7, value: 'Office', text: 'Office' },
]

class AddReportModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    form: {
      errors: {} as FormErrorType,
      module: '',
      reportName: '',
    },
    id: '',
    loader: false,
    moduleOptions: [],
  }

  public async componentDidMount() {
    const { edit, updateReport } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    if (user.role !== 'AGENT') {
      this.setState({ moduleOptions: moduleOptionsForAdmin })
    } else {
      this.setState({ moduleOptions: moduleOptionsForAgent })
    }

    if (edit) {
      this.setState({
        form: {
          errors: {} as FormErrorType,
          module: updateReport.module,
          reportName: updateReport.name,
        },
        id: updateReport._id
      })
    }
  }

  public render() {
    const { edit } = this.props
    const { animate, loader, form, form: { errors }, moduleOptions } = this.state
    return (
      <Modal content={
        <Container>
          <AddLeftPanel>
            <Image src={Strings.reports.src} size='small' />
          </AddLeftPanel>
          <AddForm>
            <StyledForm size={'mini'}>
              <Form.Field
                control={Input}
                label='Report Name'
                name='reportName'
                error={!!errors.reportName}
                value={form.reportName}
                placeholder='Report Name'
                onChange={this.handleChange} />
              <Form.Field
                control={Select}
                label='Module'
                name='module'
                error={!!errors.module}
                value={form.module}
                options={moduleOptions}
                placeholder='Module'
                disabled={edit}
                onChange={this.handleChange} />
              <Form.Field
                control={Select}
                className='shared-drop-down'
                label='Share Report With'
                placeholder='Me Only'
                onChange={this.handleChange}
                disabled={true} />
              {
                loader ?
                  <Form.Field
                    control={Button}
                    loading={true}
                    content='LOADING'
                  /> : <Form.Field
                    control={Button}
                    content={'SAVE'}
                    onClick={this.addNewReport}
                  />
              }
            </StyledForm>
          </AddForm>
        </Container>
      } className={animate ? 'zoomIn' : 'zoomOut'} closeModal={this.closeModal} width={700} />
    )
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private handleChange = (e: React.SyntheticEvent<EventTarget>, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private addNewReport = async () => {
    const { edit, setShowSystemReport } = this.props
    const { form, id } = this.state

    const validation = handleValidation(form)
    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      return
    }

    this.setState({ loader: true })
    try {
      if (edit) {
        const newData = {
          id: id,
          reportName: form.reportName
        }
        await updateMyReport(newData)
        Toast({ message: 'Report updated successfully ', type: 'success' })
      } else {
        await createMyReport(form.module, form.reportName)
        Toast({ message: 'Report added successfully ', type: 'success' })
      }
      setShowSystemReport(`${form.module.toUpperCase()}S`)
      this.setPickData(`${form.module.toUpperCase()}S`)
      CreateNewMyReport()
      this.closeModal()
      this.setState({ loader: false })
    } catch (error) {
      this.setState({ loader: false })
      Toast({ message: error.message, type: 'error' })
    }
  }

  private setPickData = (module: string) => {
    const { setPickData, setChangeToggle } = this.props
    let data: any
    switch (module) {
      case 'TRANSACTIONS':
        data = transactionMockData
        break
      case 'LEADS':
        data = leadMockData
        break
      case 'CONTACTS':
        data = contactMockData
        break
      case 'USERS':
        data = userMockData
        break
      case 'TEAMS':
        data = teamMockData
        break
      case 'OFFICES':
        data = officeMockData
        break
      case 'TASKS':
        data = taskMockData
        break
      default:
        return
    }
    const newData = { contact: false, lead: false, task: false }
    setPickData(data)
    setChangeToggle(newData)
  }
}

export default connect(
  null,
  {
    setChangeToggle: Actions.changeToggle,
    setPickData: Actions.getPickData,
    setShowSystemReport: Actions.showSystemReport,
  }
)(AddReportModal)
