// Import Packages
import { groupBy } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import SpinningBubbles from 'react-loading'
import { connect } from 'react-redux'
import { Dimmer } from 'semantic-ui-react'
import { Button, Grid } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

// Import Components
import { getCapSettings } from 'app/Settings/TransactionFee/TransactionFeeDetails/TransactionFeeQueries'
import Scrollable from 'shared/Scrollable'
import Toast from 'shared/Toast'
import AddReportModal from '../AddReportModal'
import ApplyFilters from './ApplyFilters'
import MyReports from './MyReports'
import PickData from './PickData'
import {
  reportMockData,
  reportMockDataAgent,
  systemGeneratedMyReportMockData,
  systemGeneratedMyReportMockDataAgent
} from './ReportsMockData'
import ReportType from './ReportType'

// Import Store Types, Actions and Reducers
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Reports/Actions'
import {
  ApplyFilter,
  MyReportModule,
  Options,
  PickData as PickDataType,
  Toggle,
  TransactionModule
} from 'store/Reports/Types'
import { deleteMyReport, updateMyReport } from '../ReportMutations'
import { getMyReport } from '../ReportQueries'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import { StyledGrid } from './Styled'

interface ReportData {
  _id: string
  module: string
  name: string
}

interface StoreProps {
  setDeleteMyReport: (module: string, reportId: string) => void
  setDeleteTransaction: (module: string, reportId: string) => void
  setReportData: (data: MyReportModule[]) => void
  setTransactionData: (data: TransactionModule[]) => void
  setFilter: (data: any) => void
  setApplyFilter: (data: any) => void
  setPickData: (data: PickDataType) => void
  setColumnData: (data: ApplyFilter) => void
  setResetFilterData: (reset: boolean) => void
  setShowState: (data: Options) => void
  setShowCity: (data: Options) => void
  setShowGeneratedReportId: (id: string) => void
  setChangeToggle: (data: Toggle) => void
  systemReport: string
  reportData: MyReportModule[]
  transactionData: TransactionModule[]
  module: string
  filter: any
  applyFilter: any
  pickData: PickDataType[]
  setModule: string
  columnMetaData: ApplyFilter[]
  toggle: Toggle
  resetData: boolean
  generatedReportID: string
}

interface OwnProps {
  preview: (view: string) => void
  showReports: (title: string) => void
}

type Props = StoreProps & OwnProps

interface State {
  updateReport: ReportData
  showAddReportModal: boolean
  tabActive: number
  view: string
  previewFilter: object
  columnMetaData: ApplyFilter[]
  monthlySummary: string
  myReport: MyReportModule[]
  generateMyReportId: string
  loader: boolean
  capStatus: string
}

export let CreateNewMyReport: any

class Content extends React.Component<Props, State> {
  public state = {
    capStatus: '',
    columnMetaData: [],
    generateMyReportId: '',
    loader: false,
    monthlySummary: '',
    myReport: [],
    previewFilter: {},
    showAddReportModal: false,
    tabActive: 1,
    updateReport: {} as ReportData,
    view: 'table'
  }

  public getMyReport = async () => {
    const user: any = await getLoggedInUser({ fromCache: true })
    const response = await getMyReport()
    const capStatus = await getCapSettings()
    const leadData = groupBy(response, 'module')
    let feeData: any
    if (user.role !== 'AGENT') {
      feeData = systemGeneratedMyReportMockData.slice()
    } else {
      feeData = systemGeneratedMyReportMockDataAgent.slice()
    }
    feeData = feeData.map((list: any) => {
      switch (list.module) {
        case 'Transactions':
          return {
            ...list,
            reportName: leadData['Transaction']
          }
        case 'Leads':
          return {
            ...list,
            reportName: leadData['Lead']
          }
        case 'Contacts':
          return {
            ...list,
            reportName: leadData['Contact']
          }
        case 'Tasks':
          return {
            ...list,
            reportName: leadData['Task']
          }
        case 'Users':
          return {
            ...list,
            reportName: leadData['User']
          }
        case 'Teams':
          return {
            ...list,
            reportName: leadData['Team']
          }
        case 'Offices':
          return {
            ...list,
            reportName: leadData['Office']
          }
      }
      return list
    })
    this.setState({ myReport: feeData, capStatus })
  }

  public async componentDidMount() {
    const { generatedReportID } = this.props
    const user: any = await getLoggedInUser({ fromCache: true })
    this.getMyReport()
    const { setReportData, setTransactionData } = this.props
    if (user.role !== 'AGENT') {
      setReportData(reportMockData)
      setTransactionData(systemGeneratedMyReportMockData)
    } else {
      setReportData(reportMockDataAgent)
      setTransactionData(systemGeneratedMyReportMockData)
    }
    this.assignCreateNewMyReport()
    this.setState({ generateMyReportId: generatedReportID })
  }

  public render() {
    const { reportData, module, setModule, resetData, systemReport } = this.props
    const {
      updateReport,
      showAddReportModal,
      tabActive,
      monthlySummary,
      myReport,
      generateMyReportId,
      loader,
      capStatus
    } = this.state
    let data
    let title
    let background
    let textColor
    let index
    switch (module) {
      case 'TRANSACTIONS':
        title = 'Transaction'
        data = reportData
        data = reportData
        background = Colors.DarkBlue200
        textColor = Colors.White1000
        index = 0
        break
      case 'LEADS':
        title = 'Leads'
        data = reportData
        background = Colors.DarkBlue200
        textColor = Colors.White1000
        index = 1
        break
      case 'CONTACTS':
        title = 'Contacts'
        data = reportData
        background = Colors.DarkBlue200
        textColor = Colors.White1000
        index = 2
        break
      case 'TASKS':
        title = 'Tasks'
        data = reportData
        background = Colors.DarkBlue200
        textColor = Colors.White1000
        index = 3
        break
      case 'USERS':
        title = 'Users'
        data = reportData
        background = Colors.DarkBlue200
        textColor = Colors.White1000
        index = 4
        break
      case 'TEAMS':
        title = 'Teams'
        data = reportData
        background = Colors.DarkBlue200
        textColor = Colors.White1000
        index = 5
        break
      case 'OFFICES':
        title = 'Offices'
        data = reportData
        background = Colors.DarkBlue200
        textColor = Colors.White1000
        index = 6
        break
      default:
        title = 'My Reports'
        data = myReport
        background = Colors.DarkBlue200
        textColor = Colors.White1000
        index = module === 'My Reports' ? 0 : 0.5
        break
    }
    return (
      <Scrollable>
        {loader && (
          <Dimmer active={true} inverted={true}>
            <SpinningBubbles type={'spinningBubbles'} color={Colors.DarkBlue200} />
          </Dimmer>
        )}
        {showAddReportModal && <AddReportModal closeModal={this.closeModal} edit={true} updateReport={updateReport} />}
        <StyledGrid>
          <Grid.Row columns={4}>
            <Grid.Column>
              <MyReports
                generateMyReportId={generateMyReportId}
                systemReport={systemReport}
                showReports={this.props.showReports}
                resetData={resetData}
                setModule={setModule}
                background={background}
                title={title}
                textColor={textColor}
                reportData={data}
                deleteReport={this.deleteReport}
                index={index}
                monthlySummary={this.monthlySummary}
                updateMyReport={this.updateMyReport}
                generateMyReport={this.generateMyReport}
              />
            </Grid.Column>
            <Grid.Column>
              <PickData
                activeTab={this.activeTab}
                tabActive={tabActive === 1 ? Colors.DarkBlue200 : ''}
                monthlySummary={monthlySummary}
                capStatus={capStatus}
              />
            </Grid.Column>
            <Grid.Column>
              <ApplyFilters
                activeTab={this.activeTab}
                tabActive={tabActive === 2 ? Colors.DarkBlue200 : ''}
                monthlySummary={monthlySummary}
              />
            </Grid.Column>
            <Grid.Column>
              <ReportType
                showGridView={this.showGridView}
                activeTab={this.activeTab}
                tabActive={tabActive === 3 ? Colors.DarkBlue200 : ''}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} className="buttons">
            <Grid.Column>
              <Button onClick={this.resetData}>{Strings.reports.cancel}</Button>
              <Button className="preview" onClick={() => this.pickData()}>
                {Strings.reports.preview}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </StyledGrid>
      </Scrollable>
    )
  }

  private assignCreateNewMyReport = () => {
    CreateNewMyReport = () => {
      this.getMyReport()
    }
  }

  private generateMyReport = (generateMyReportId: string, pickFilterData: object) => {
    const { setPickData, setFilter, setShowGeneratedReportId, setChangeToggle } = this.props
    this.setState({ generateMyReportId })
    setShowGeneratedReportId(generateMyReportId)
    this.resetData()
    if (pickFilterData) {
      setPickData(pickFilterData[`pickData`])
      setFilter(pickFilterData[`filter`])
      setChangeToggle(
        pickFilterData[`toggle`] ? pickFilterData[`toggle`] : { toggle: { contact: false, lead: false, task: false } }
      )
    } else {
      this.resetData()
    }
  }

  private monthlySummary = (reportId: string, monthlySummary: string) => {
    const { setFilter, setPickData, pickData, setShowGeneratedReportId } = this.props
    this.setState({ monthlySummary })
    setShowGeneratedReportId(reportId)
    let newFilter = {}
    if (
      monthlySummary === 'Monthly Production (past 30 days)' ||
      monthlySummary === 'Monthly Summary (past 30 days)' ||
      monthlySummary === 'New Users (past 30 days)'
    ) {
      newFilter = {
        from: moment()
          .subtract(30, 'days')
          .format('L'),
        to: moment(new Date())
          .add(2, 'days')
          .format('L')
      }
      setFilter(newFilter)
    } else if (
      monthlySummary === 'Quarterly Production (past 90 days)' ||
      monthlySummary === 'Quarterly Summary (past 90 days)'
    ) {
      newFilter = {
        from: moment()
          .subtract(90, 'days')
          .format('L'),
        to: moment(new Date())
          .add(2, 'days')
          .format('L')
      }
      setFilter(newFilter)
    } else if (
      monthlySummary === 'YTD Production (Jan 1, YYYY - Today)' ||
      monthlySummary === 'YTD Summary (Jan 1, YYYY - Today)'
    ) {
      newFilter = {
        from: moment()
          .startOf('year')
          .format('L'),
        to: moment(new Date())
          .add(2, 'days')
          .format('L')
      }
      setFilter(newFilter)
    }
    const newData: any = pickData.map((dataItem: any) => {
      return {
        ...dataItem,
        fieldName: (dataItem.fieldName || []).map((items: any) => {
          return {
            ...items,
            isChecked: true
          }
        }),
        isChecked: true
      }
    })
    setPickData(newData)
  }

  private resetData = () => {
    const {
      setFilter,
      setApplyFilter,
      setPickData,
      setResetFilterData,
      setShowCity,
      setShowState,
      pickData,
      setChangeToggle
    } = this.props
    this.setState({ previewFilter: {} })
    setResetFilterData(true)
    setTimeout(() => {
      setResetFilterData(false)
    }, 1000)
    const newData: any = pickData.map((dataItem: any) => {
      if (dataItem.fieldId !== 'firstName' && dataItem.fieldId !== 'lastName') {
        return {
          ...dataItem,
          fieldName: dataItem.fieldName.map((items: any) => {
            return {
              ...items,
              isChecked: false
            }
          }),
          isChecked: false
        }
      }
      return { ...dataItem }
    })
    setPickData(newData)
    setApplyFilter([])
    setFilter({})
    setShowCity({} as Options)
    setShowState({} as Options)
    setChangeToggle({ contact: false, lead: false, task: false })
  }

  private activeTab = (tabActive: number) => {
    this.setState({ tabActive })
  }

  private updateMyReport = (data: ReportData) => {
    this.setState({ showAddReportModal: true, updateReport: data })
  }

  private closeModal = () => {
    this.setState({ showAddReportModal: false })
  }

  private showGridView = (view: string) => {
    this.setState({ view })
  }

  private pickData = async () => {
    const { pickData, setColumnData, setModule, filter, toggle } = this.props
    const { generateMyReportId } = this.state
    const column: any = []
    pickData.forEach((dataItem: any) => {
      if (dataItem.isChecked === true) {
        dataItem.fieldName.forEach((items: any) => {
          if (items.isChecked === true) {
            column.push({
              enhanceWithRowData: false,
              id: items.fieldId ? items.fieldId : '-',
              title: items.field
            })
          }
        })
        if (dataItem.fieldName.length === 0) {
          column.push({
            enhanceWithRowData: false,
            id: dataItem.fieldId,
            title: dataItem.fieldCategory
          })
        }
      } else {
        dataItem.fieldName.forEach((items: any) => {
          if (items.isChecked === true) {
            column.push({
              enhanceWithRowData: false,
              id: items.fieldId ? items.fieldId : '-',
              title: items.field
            })
          }
        })
      }
    })
    setColumnData(column)
    if (column.length === 0) {
      Toast({ message: 'Please select your data item(s)', type: 'error' })
    } else {
      if (setModule !== 'MY REPORTS') {
        this.props.preview(this.state.view)
      } else {
        this.setState({ loader: true })
        const newData = {
          id: generateMyReportId,
          pickFilterData: {
            filter: filter,
            pickData: pickData,
            toggle: toggle
          }
        }
        try {
          const response = await updateMyReport(newData)
          if (response) {
            this.setState({ loader: false })
            this.props.preview(this.state.view)
          }
        } catch (error) {
          Toast({ message: 'Please select your report', type: 'error' })
          this.setState({ loader: false })
        }
      }
    }
    this.setState({ columnMetaData: column })
  }

  private deleteReport = async (reportId: string) => {
    const { generateMyReportId } = this.state
    ConfirmAlert({
      cancelButtonText: Strings.reports.noKeepIt,
      confirmButtonText: Strings.reports.yesDeleteIt,
      showCancelButton: true,
      text: 'You will not be able to recover this report!',
      title: Strings.reports.sure,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        await deleteMyReport(reportId)
        if (generateMyReportId === reportId) {
          this.resetData()
        }
        this.getMyReport()
        ConfirmAlert(Strings.reports.deleted, Strings.reports.deletedReport, 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.reports.cancelled, Strings.reports.safeReport, 'error')
      }
    })
  }
}

const mapStateToProps = (state: AppState) => ({
  applyFilter: state.reports.applyFilter,
  columnMetaData: state.reports.columnMetaData,
  filter: state.reports.filter,
  generatedReportID: state.reports.generatedReportID,
  module: state.reports.module,
  pickData: state.reports.pickData,
  reportData: state.reports.reportData,
  resetData: state.reports.resetData,
  setModule: state.reports.module,
  systemReport: state.reports.systemReport,
  toggle: state.reports.toggle,
  transactionData: state.reports.transactionData
})

export default connect(
  mapStateToProps,
  {
    setApplyFilter: Actions.getApplyFilter,
    setChangeToggle: Actions.changeToggle,
    setColumnData: Actions.getColumnData,
    setDeleteMyReport: Actions.deleteMyReport,
    setDeleteTransaction: Actions.deleteTransaction,
    setFilter: Actions.getFilter,
    setPickData: Actions.getPickData,
    setReportData: Actions.getReportData,
    setResetFilterData: Actions.resetFilterData,
    setShowCity: Actions.showCity,
    setShowGeneratedReportId: Actions.showGeneratedReportId,
    setShowState: Actions.showState,
    setTransactionData: Actions.getTransactionData
  }
)(Content)
