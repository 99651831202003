// Import Packages
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Progress from 'semantic-ui-react/dist/commonjs/modules/Progress'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'

export const StyledSegment = styled(Segment)`
  &.ui.segment {
    border: none;
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    width: 100%;
  }
`

const UserInfo = styled(Grid.Column)`
  .ui[class*="equal width"].grid>&.column:not(.row) {
    height: 100%;
    padding: 14px 26px;
  }
`

const BottomSegment = styled(Segment)`
  &.ui.segment {
      align-items: center;
      display: flex;
      flex-direction: column;
      border: none;
      padding: 0px;
      margin-top: 0px;
      flex-grow: 0 !important;
    }
`

const ReferSegment = styled(StyledSegment)`
  display: flex;
  align-items: center;

  &.ui.segment {
    background-color: ${Colors.DarkBlue200};
    height: 50px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    padding: 0px 14px;
    color: ${Colors.TextLight};
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 13px;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 12px;
      height: 40px;
    }
    @media ( min-width: 1200px ) {
      font-size: 13px;
      height: 50px;
    }
  }
`

const Office = styled(StyledSegment)`
  &.ui.segment {
    padding: 0px 15px;
    font-size: 12px;
    color: ${Colors.Text};
    display: flex;
    min-height: 20px;
    margin: 6px 0px 2px;
    align-items: center;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 11px;
    }
    @media ( min-width: 1200px ) {
      font-size: 12px;
    }
    .icon {
      width: 14px;
      height: 17px;
      margin: 0px 5px 0px 0px;
      color: ${Colors.TextPrimary};
    }
  }

  .ui.selection.dropdown {
    line-height: 0.5em;
    min-width: 10em;
    border-radius: 0;
    min-height: 1.714286em;
  }

  .ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon{
    top: 0.585714em;
  }
`

const CommissionPlan = styled(StyledSegment)`
  &.ui.segment {
    padding: 0px 15px;
    font-size: 12px;
    color: ${Colors.Text};
    display: flex;
    min-height: 20px;
    margin: 6px 0px 2px;
    align-items: center;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 11px;
    }
    @media ( min-width: 1200px ) {
      font-size: 12px;
    }
    .icon {
      width: 17px;
      height: 17px;
      margin: 0px 5px 0px 0px;
      color: ${Colors.TextPrimary};
    }
  }

  .ui.selection.dropdown {
    line-height: 0.5em;
    min-width: 8em;
    border-radius: 0;
    min-height: 1.714286em;
  }

  .ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon{
    top: 0.585714em;
  }
`

const ContractDocument = styled(StyledSegment)`
  &.ui.segment {
    cursor: pointer;
    padding: 0px 15px;
    font-size: 12px;
    color: ${Colors.Text};
    display: flex;
    min-height: 20px;
    margin: 6px 0px 6px;
    align-items: center;
    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 11px;
    }
    @media ( min-width: 1200px ) {
      font-size: 12px;
    }
    .icon {
      width: 14px;
      height: 14px;
      margin: 0px 5px 0px 0px;
      color: ${Colors.TextPrimary};
    }
  }
`

const DataItem = styled(StyledSegment)`
  &.ui.segment {
    padding: 0px 15px;
    margin: 7px 0px;
    font-size: 12px;
    color: ${Colors.TextPrimary};

    @media ( min-width: 991px ) and (max-width: 1200px) {
      font-size: 11px;
      margin: 4px 0px;
      line-height: 1.4em;
    }
    @media ( min-width: 1200px ) {
      font-size: 12px;
      margin: 7px 0px;
    }

    .ui.progress {
      margin: 0.5em 0 0.5em;
    }

    .languageIcon {
      margin: 0px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      @media ( min-width: 991px ) and (max-width: 1200px) {
        width: 18px;
        height: 18px;
      }
      @media ( min-width: 1200px ) {
        width: 20px;
        height: 20px;
      }
    }

    .badgeIcon {
      margin: 0px;
      margin-right: 7px;
      width: 17px;
      height: 17px;
      @media ( min-width: 991px ) and (max-width: 1200px) {
        width: 15px;
        height: 15px;
      }
      @media ( min-width: 1200px ) {
        width: 17px;
        height: 17px;
      }
    }
  }
`

interface ImageProps {
  backgroundImage: string
}

const ImageWrapper = styled.div`
  background: ${(props: ImageProps) => `url(${props.backgroundImage}) no-repeat`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 21px;
  height: 21px;
  align-self: center;
  margin: 0px 10px;
  display: inline-block;
`

const StyledButton = styled(Button)`
  &.ui.basic.button{
    border: none;
    box-shadow: none;
    padding: 0px;
    :hover{
      background-color: ${Colors.transparent} !important;
      box-shadow: none;
    }
    :focus{
      background-color: ${Colors.transparent} !important;
      box-shadow: none;
    }
    .buttonIcon{
      width: 16px;
      height: 16px;
    }
  }
`

const StyledProgress = styled(Progress)`
  &.ui.tiny.progress {
    background: ${Colors.EmphasisLine};
  }
  &.ui.tiny.progress .bar {
    background: ${Colors.ButtonPrimary};
  }
`

export {
  BottomSegment,
  ContractDocument,
  DataItem,
  ImageWrapper,
  Office,
  ReferSegment,
  StyledButton,
  StyledProgress,
  UserInfo,
  CommissionPlan
}
