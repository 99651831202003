import * as React from 'react'

import Scrollable from 'shared/Scrollable'
import { formToast, serverToast } from 'shared/Toast/Toast'
import TransactionForm from 'shared/Transaction/TransactionForm'
import Transition from 'shared/Transition'

import { getIdxData } from 'shared/Transaction/Queries'
import { createTransactionViaConciergeIdx } from '../../Mutations'
import { getMls } from '../../Queries'
import { validateListingId } from '../../Validation'

import { Strings } from 'utils'

import {
  Col,
  Description,
  StyledButton,
  StyledInput,
  Title
} from '../../Styled'
import { IdxForm, Row, Section } from './Styled'

import {
  ActionEnum as TransactionActionEnum,
  FormErrorType as TransactionFormErrorType,
  FormType as TransactionFormType,
  ModeEnum
} from 'shared/Transaction/TransactionForm/Types'
import {
  ActionEnum,
  ConciergeIdxParamsType,
  FormType,
  MlsType,
  OfficeType,
  TransitEnum,
} from '../../Types'

import { PaymentNumericEnum } from 'store/Transactions/Types'

interface Props {
  form: FormType
  office: OfficeType
  onAction: (action: ActionEnum, data: any) => void
  token: string
  transit: TransitEnum
}

interface State {
  attempt: number
  mls: MlsType
  showTransaction: boolean
  transaction: TransactionFormType
}

const {
  transactionModal: {
    CONCIERGE: { DESCRIPTION, INPUT, TITLE }
  }
} = Strings
const DURATION = 300

class IdxStep extends React.Component<Props, State> {
  public state = {
    attempt: 0,
    mls: {} as MlsType,
    showTransaction: false,
    transaction: {
      errors: {} as TransactionFormErrorType
    } as TransactionFormType
  }

  public componentDidMount = () => {
    this.getMls()
  }

  public componentDidUpdate = (prev: Props) => {
    if (prev.office.mls._id !== this.props.office.mls._id) {
      this.getMls()
    }
  }

  public render() {
    const {
      form,
      form: { errors },
      token,
      transit
    } = this.props
    const { attempt, mls, showTransaction, transaction } = this.state

    const transition = transit === TransitEnum.Enter

    return (
      <Col height={100}>
        {!showTransaction ? (
          <Transition on={transition && !transaction.office}>
            <Title>Your office has {mls.shortName} IDX enabled!</Title>
          </Transition>
        ) : (
          <Transition on={true} delay={50}>
            <Title>{TITLE.idx2}</Title>
          </Transition>
        )}
        <Row justify="center">
          <IdxForm
            onSubmit={this.handleConciergeSubmit}
            width={transaction.office ? 40 : 100}
            custom={{ duration: DURATION }}
          >
            <Transition delay={50} on={transition}>
              <Description>{DESCRIPTION.idx1}</Description>
            </Transition>
            <Transition delay={80} on={transition}>
              <Row justify="center" padding={'0 0 1em 0'}>
                <StyledInput
                  autoFocus={true}
                  action={<StyledButton content={INPUT.idxSubmit} />}
                  fluid={true}
                  name="listingId"
                  onChange={this.handleConciergeInput}
                  placeholder={INPUT.idxPlaceholder}
                  value={form.listingId}
                  error={!!errors.listingId}
                />
              </Row>
            </Transition>
            {attempt > 0 && (
              <Transition on={transition}>
                <Description>{DESCRIPTION.idx2}</Description>
              </Transition>
            )}
          </IdxForm>
          <Section width={transaction.office ? 60 : -1} height={100}>
            <Scrollable>
              {transaction.office &&
                showTransaction && (
                  <TransactionForm
                    disableOfficeSelection={true}
                    editing={true}
                    form={transaction}
                    mode={ModeEnum.Concierge}
                    onAction={this.handleAction}
                    token={token}
                  />
                )}
            </Scrollable>
          </Section>
        </Row>
      </Col>
    )
  }

  private handleAction = (action: TransactionActionEnum, data: any) => {
    const { onAction } = this.props
    switch (action) {
      case TransactionActionEnum.Loading:
        onAction(ActionEnum.Loading, !!data)
        break

      case TransactionActionEnum.UpdateOne:
        const { name, value } = data
        this.handleTransactionInput(name, value)
        break

      case TransactionActionEnum.Submit:
        this.handleTransactionSubmit()
        break

      default:
        return
    }
  }

  private handleConciergeInput = (
    e: React.SyntheticEvent | null,
    { name, value }: any
  ) => {
    const { onAction } = this.props
    onAction(ActionEnum.Input, { name, value })
  }

  private handleTransactionInput = (name: string, value: any) => {
    const { transaction } = this.state
    transaction[name] = value
    transaction.errors[name] = ''
    this.setState({ transaction })
  }

  private handleConciergeSubmit = async () => {
    const { form, onAction, token } = this.props
    const { attempt } = this.state
    onAction(ActionEnum.Loading, true)

    const validation = validateListingId(form)
    if (!validation.formIsValid) {
      this.handleConciergeInput(null, {
        name: 'errors',
        value: validation.errors
      })
      formToast(validation.errors)
      return
    }

    let listing: any = {}
    try {
      listing = await getIdxData(form.listingId, form.office, token)
    } catch (e) {
      serverToast(e)
      this.setState({ attempt: attempt + 1 })
      onAction(ActionEnum.Loading, false)
      return
    }

    const {
      propertyId: {
        address,
        bathrooms,
        bedrooms,
        description,
        mlsId,
        price,
        squareFt,
        subType,
        type
      },
      transactionRole
    } = listing

    const transaction: TransactionFormType = {
      bathrooms,
      bedrooms,
      city: address.city,
      description,
      errors: {} as TransactionFormErrorType,
      feeObj: {
        type: PaymentNumericEnum.Fixed,
        fee: 0,
      },
      mlsId,
      office: form.office,
      price,
      state: address.state,
      streetAddress: `${address.streetNumber} ${address.streetName}`,
      streetName: address.streetName,
      streetNumber: address.streetNumber,
      squareFt,
      subType: subType._id,
      transactionRole,
      type: type._id,
      zipCode: address.zipCode,
    }
    this.setState({ transaction }, () => {
      window.setTimeout(() => {
        this.setState({ showTransaction: true })
      }, DURATION + 10)
    })

    onAction(ActionEnum.Loading, false)
  }

  private handleTransactionSubmit = async () => {
    const {
      form: { office },
      onAction,
      token
    } = this.props

    const {
      transaction: {
        bathrooms,
        bedrooms,
        city,
        description,
        mlsId,
        price,
        squareFt,
        state,
        streetName,
        streetNumber,
        subType,
        transactionRole,
        type,
        zipCode
      }
    } = this.state

    onAction(ActionEnum.Loading, true)

    const input: ConciergeIdxParamsType = {
      office,
      propertyInput: {
        address: {
          city,
          state,
          streetName,
          streetNumber,
          zipCode
        },
        bathrooms,
        bedrooms,
        description,
        mlsId,
        price,
        squareFt,
        subType,
        type
      },
      transactionRole
    }

    try {
      const result = await createTransactionViaConciergeIdx(token, input)
      this.handleConciergeInput(null, {
        name: 'transaction',
        value: result._id
      })
      onAction(ActionEnum.Step, null)
    } catch (error) {
      serverToast(error)
    }

    onAction(ActionEnum.Loading, false)
  }

  private getMls = async () => {
    const { office, token } = this.props
    const mls: MlsType =
      (await getMls(office.mls._id, token)) || ({} as MlsType)
    this.setState({ mls })
  }
}

export default IdxStep
