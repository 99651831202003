import * as React from 'react'
import { Checkbox as Box } from 'semantic-ui-react'

import { CheckboxWrapper } from './Styled'

interface Props {
  isChecked?: boolean
  onChange: () => void
}

class Checkbox extends React.Component<Props, {}> {
  public render() {
    const {
      isChecked,
      onChange
    } = this.props

    return (
      <CheckboxWrapper>
        <Box
          checked={isChecked}
          onChange={onChange}
        />
      </CheckboxWrapper>
    )
  }
}

export default Checkbox