const Actions = {
  GetTaskActivities: 'GET_TASK_ACTIVITIES',
  GetTaskDetails: 'GET_TASK_DETAILS',
  TaskAddNewBoard: 'ADD_TASK_NEW_BOARD',
  TaskAddNewCard: 'ADD_TASK_NEW_CARD',
  TaskAddNewNotes: 'ADD_TASK_NEW_NOTES',
  TaskChangestatus: 'CHANGE_TASK_STATUS',
  TaskDeleteCard: 'DELETE_TASK_CARD',
  TaskDeleteNotes: 'DELETE_TASK_NOTES',
  TaskDeleteRecord: 'DELETE_TASK_RECORD',
  TaskEditNotes: 'EDIT_TASK_NOTES',
  TaskEditRecord: 'TASK_EDIT_RECORD',
  TaskFilter: 'TASK_FILTER',
  TaskGetBoardData: 'GET_TASK_BOARD_DATA',
  TaskGetListData: 'GET_TASK_LIST_DATA',
  TaskGetNotesData: 'GET_TASK_NOTES_DATA',
  TaskImportData: 'TASK_iMPORT_DATA',
  TaskResetCardData: 'RESET_TASK_CARD_DATA',
  TaskRestoreCard: 'RESTORE_TASK_CARD',
  TaskSearchFilter: 'TASK_SERACH_FILTER',
  TaskSearchLoader: 'TASK_SEARCH_LOADER',
  TaskSetNewBoardName: 'SET_TASK_NEW_BOARD_NAME',
  TaskSetNewDescription: 'SET_TASK_NEW_DESCRIPTION',
  TaskSetNewIcon: 'SET_TASK_NEW_ICON',
  TaskToggleStatus: 'TASK_TOGGLE_STATUS',
  TaskUpdateCard: 'UPDATE_TASK_CARD',
  userPassTaskFilter: 'USER_PASS_TASK_FILTER'
}

export default Actions
