const Actions = {
  addContactTransaction: 'ADD_CONTACT_TRANSACTION',
  contactActivities: 'GET_CONTACT_ACTIVITIES',
  contactAddNewBoard: 'ADD_CONTACT_NEW_BOARD',
  contactAddNewCard: 'ADD_CONTACT_NEW_CARD',
  contactAddNewNotes: 'ADD_CONTACT_NEW_NOTES',
  contactAddShareWith: 'CONTACT_ADD_SHARE_WITH',
  contactDeleteBoard: 'DELETE_CONTACT_BOARD',
  contactDeleteCard: 'DELETE_CONTACT_CARD',
  contactDeleteListRecord: 'DELETE_RECORD',
  contactDeleteNotes: 'DELETE_CONTACT_NOTES',
  contactDeleteShareWith: 'DELETE_CONTACT_SHARE_WITH',
  contactDetails: 'GET_CONTACT_DETAILS',
  contactEditDetails: 'EDIT_CONTACT_DETAILS',
  contactEditNotes: 'EDIT_CONTACT_NOTES',
  contactEditRecord: 'EDIT_CONTACT_RECORD',
  contactFilter: 'CONTACT_FILTER',
  contactGetBoardData: 'GET_CONTACT_BOARD_DATA',
  contactGetListData: 'GET_CONTACT_LIST_DATA',
  contactGetNotesData: 'GET_CONTACT_NOTES_DATA',
  contactGetShareWith: 'GET_CONTACT_SHARE_WITH',
  contactImportData: 'IMPORT_CONTACTS',
  contactResetCardData: 'RESET_CONTACT_CARD_DATA',
  contactRestoreCard: 'RESTORE_CONTACT_CARD',
  contactSearchFilter: 'CONTACT_SEARCH_FILTER',
  contactSearchLoader: 'CONTACT_SEARCH_LOADER',
  contactSetNewBoardName: 'SET_CONTACT_NEW_BOARD_NAME',
  contactSetNewDescription: 'SET_CONTACT_NEW_DESCRIPTION',
  contactSetNewIcon: 'SET_CONTACT_NEW_ICON',
  contactSortBoardData: 'SORT_CONTACT_BOARD_DATA',
  contactToggleStatus: 'CONTACT_TOGGLE_STATUS',
  getContactTransaction: 'GET_CONTACT_TRANSACTION',
  userPassContactFilter: 'USER_PASS_CONTACT_FILTER'
}

export default Actions
