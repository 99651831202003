// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import ConfirmAlert from 'sweetalert2'
import { VictoryPie } from 'victory'

// Import Components
import { isPlanAllowed } from 'shared/Billing/Queries'
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import { serverToast } from 'shared/Toast/Toast'
import { AcceptLead, toggle, ToggleLeadStatus } from '../Leads'
import { ShowUpdateScoreModal } from '../Leads'

// Import Store Types, Actions and Reducers
import { LeadItemsDetails } from 'store/Leads/Types'

// Import Utils
import { getLoggedInUser, Routes, Strings } from 'utils'

// Import Colors
import Colors from 'design/Colors'

// Import Styled Components
import {
  AcceptButton,
  Added,
  Address,
  CardContent,
  CardWrapper,
  Circle,
  Container,
  Content,
  CreatedDate,
  Divider,
  ExtraContent,
  Labels,
  LeadName,
  Li,
  MemberProfile,
  Owner,
  Profile,
  ProfileImage,
  ProfileProgressContainer,
  Schedule,
  Score,
  Status,
  StyledLabel,
  StyledPopup,
  StyledStatusDropdown,
  Type
} from './Styled'

// Font Awesome Icons
import { faEnvelope, faMoneyBillAlt, faPhone, faSms } from '@fortawesome/pro-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { reassignLead } from '../LeadMutations'
AddIconToLibrary([faMapMarkerAlt, faMoneyBillAlt, faSms, faPhone, faEnvelope])

interface UserType {
  _id: string
  role: string
  profileImage: string
}

interface Props {
  CardData: LeadItemsDetails
  color: string
  length: number
  order: number
}

interface State {
  showNote: boolean
  showTask: boolean
  showComposeMail: boolean
  user: UserType
}

const options = [
  { key: 1, text: 'New', value: 'New' },
  { key: 2, text: 'In Process', value: 'In Process' },
  { key: 3, text: 'Failed', value: 'Failed' },
  { key: 4, text: 'Closed', value: 'Closed' }
]

class LeadCard extends React.Component<Props, State> {
  public state = {
    showComposeMail: false,
    showNote: false,
    showTask: false,
    user: {} as UserType
  }

  public getDataResidential = (percent: number) => {
    return [{ x: 1, y: percent, label: '' }, { x: 2, y: 100 - percent, label: '' }]
  }

  public async componentDidMount() {
    const user: UserType = await getLoggedInUser({ fromCache: true })
    this.setState({ user })
  }

  public render() {
    const { CardData, length, order } = this.props
    const { showComposeMail, user } = this.state
    let profile
    let Count
    if (toggle) {
      profile = (
        <Profile>
          <ProfileImage background={CardData.owner.profileImage} />
        </Profile>
      )
    } else {
      profile = CardData.shareWith.map((items: any, index: number) => {
        while (index < 3) {
          return (
            <Li key={index}>
              <StyledPopup
                trigger={<MemberProfile key={index} background={items.profileImage} />}
                content={`${items.firstName} ${items.lastName}`}
              />
            </Li>
          )
        }
        return null
      })
      Count =
        CardData.shareWith.length > 3 ? (
          <Labels>
            <StyledPopup trigger={<div>...</div>} content={`+${CardData.shareWith.length - 3}`} />
          </Labels>
        ) : null
    }

    let color = ''
    let background = ''
    let defaultValue = ''
    if (CardData.leadScore >= 0 && CardData.leadScore < 25) {
      color = Colors.DarkBlue205
    } else if (CardData.leadScore >= 25 && CardData.leadScore < 50) {
      color = Colors.Grey950
    } else if (CardData.leadScore >= 50 && CardData.leadScore < 75) {
      color = Colors.Green80
    } else {
      color = Colors.Red10
    }

    switch (CardData.status) {
      case 'New':
        background = Colors.Orange250
        defaultValue = 'New'
        break
      case 'In Process':
        background = Colors.Magenta60
        defaultValue = 'In Process'
        break
      case 'Failed':
        background = Colors.Red200
        defaultValue = 'Failed'
        break
      default:
        background = Colors.Grey850
        defaultValue = 'Closed'
        break
    }
    // CardData.score has to be passed to graph and in chart
    return (
      <div>
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={CardData.email} />}
        <Container>
          <CardWrapper>
            <CardContent>
              <Score>
                <ProfileProgressContainer
                  onClick={e => {
                    e.stopPropagation()
                    ShowUpdateScoreModal(CardData)
                  }}
                >
                  <div>
                    <svg viewBox="0 0 400 400">
                      <VictoryPie
                        standalone={false}
                        animate={{ duration: 5000 }}
                        width={400}
                        height={400}
                        data={this.getDataResidential(CardData.leadScore)}
                        innerRadius={175}
                        labelRadius={120}
                        padding={0}
                        startAngle={0}
                        endAngle={360}
                        labels={['R : ', '30%']}
                        colorScale={[color, Colors.White900]}
                      />
                    </svg>
                  </div>
                  <Circle color={color}>
                    <div>{CardData.leadScore}</div>
                  </Circle>
                </ProfileProgressContainer>
              </Score>
              <Content>
                <Link
                  to={
                    toggle
                      ? user.role === 'ADMIN' || user.role === 'MANAGER'
                        ? Routes.leads.root + Routes.leads.details.path + `/${CardData._id}?pool=1`
                        : {}
                      : Routes.leads.root + Routes.leads.details.path + `/${CardData._id}?pool=0`
                  }
                  id="lead"
                >
                  <LeadName>
                    {CardData.firstName} {CardData.lastName}
                  </LeadName>
                  <Type>
                    {Strings.leads.type} {CardData.propertyType}
                  </Type>
                  <Address>
                    <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                    {CardData.state && CardData.city && CardData.zipCode
                      ? `${CardData.state.name}, ${CardData.city.name} ${CardData.zipCode}`
                      : CardData.state && CardData.city
                        ? `${CardData.state.name}, ${CardData.city.name}`
                        : CardData.state
                          ? `${CardData.state.name}`
                          : `No Address`}
                  </Address>
                  {/* <Price><FontAwesomeIcon icon={['far', 'money-bill-alt']} />{CardData.price}</Price> */}
                  <CreatedDate>{moment(CardData.createdAt).format('MMMM Do YYYY')}</CreatedDate>
                </Link>
              </Content>
              {toggle === false && (
                <Schedule>
                  <StyledPopup
                    trigger={<FontAwesomeIcon icon={['far', 'phone']} />}
                    content={CardData.phone ? CardData.phone : 'No Phone Number Added'}
                  />
                  <StyledPopup
                    trigger={
                      <span
                        onClick={e => {
                          e.stopPropagation()
                          this.sendMail()
                        }}
                      >
                        <FontAwesomeIcon icon={['far', 'envelope']} />
                      </span>
                    }
                    content={CardData.email ? CardData.email : 'No Email Added'}
                  />
                  {/* <FontAwesomeIcon icon={['far', 'sms']} /> */}
                </Schedule>
              )}
            </CardContent>
            <Divider />
            <ExtraContent>
              <Owner>
                {profile}
                {Count && Count}
              </Owner>
              <Status>
                {' '}
                {toggle === false ? (
                  <StyledStatusDropdown
                    className={length - 1 === order ? 'upward' : ''}
                    inline={true}
                    onChange={this.handleChange}
                    options={options}
                    defaultValue={defaultValue}
                    background={background}
                    disabled={toggle ? (user.role === 'ADMIN' || user.role === 'MANAGER' ? false : true) : false}
                  />
                ) : (
                  <AcceptButton onClick={this.acceptLead}>Accept</AcceptButton>
                )}
              </Status>
              <Added>
                <div>{Strings.leads.Activities}</div>
                <StyledLabel circular={true}>{CardData.activities.length}</StyledLabel>
              </Added>
            </ExtraContent>
          </CardWrapper>
        </Container>
      </div>
    )
  }

  private sendMail = () => {
    this.setState({ showComposeMail: true })
  }

  private closeModal = () => {
    this.setState({ showComposeMail: false })
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const { CardData } = this.props
    const { user } = this.state
    if (toggle) {
      if (user.role === 'ADMIN' || user.role === 'MANAGER') {
        ToggleLeadStatus(CardData._id, value)
      }
    } else {
      ToggleLeadStatus(CardData._id, value)
    }
  }

  private acceptLead = async () => {
    try {
      await isPlanAllowed('leadPool')
    } catch (error) {
      return serverToast(error)
    }
    const { CardData } = this.props
    const { user } = this.state
    const reassignObj = {
      referralFee: 0,
      userId: user._id
    }
    ConfirmAlert({
      cancelButtonText: 'I Decline!',
      confirmButtonText: 'I Accept!',
      showCancelButton: true,
      text:
        'By accepting this lead, you agree to uphold the policy and procedures required by this lead exchange system including regular follow up and payment of any referral fees generated as a result.',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        const id = document.getElementById('lead')
        await reassignLead(CardData._id, reassignObj)
        await AcceptLead()
        if (id) {
          id.click()
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert('Cancelled', 'This lead declined.', 'error')
      }
    })
  }
}

export default LeadCard
