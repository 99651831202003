// Import Packages
import * as React from 'react'
import { connect } from 'react-redux'
import { Accordion, Loader } from 'semantic-ui-react'

// Import Components
import AccordionContent from './AccordionContent'

// Import Store Types, Actions and Reducers
import { ApplyFilter, MyReportModule, Options, PickData, Toggle } from 'store/Reports/Types'

// Import Store Types, Actions and Reducers
import * as Actions from 'store/Reports/Actions'

// Import Styled Components
import {
  AccordionContainer,
  Container,
  Reports,
  StyledAccordion,
  Title
} from './Styled'

// Font Awesome Icons
import {
  faCaretDown,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faCaretDown])

interface ReportData {
  _id: string
  module: string
  name: string
}

interface StoreProps {
  setPerviewModule: (data: string) => void
  setShowSystemReport: (module: string) => void
  setFilter: (data: any) => void
  setApplyFilter: (data: any) => void
  setPickData: (data: PickData) => void
  setColumnData: (date: ApplyFilter) => void
  setDatesFilters: (data: object) => void
  setResetFilterData: (reset: boolean) => void
  setShowState: (data: Options) => void
  setShowCity: (data: Options) => void
  setChangeToggle: (data: Toggle) => void
}

interface OwnProps {
  deleteReport: (reportId: string) => void
  updateMyReport: (data: ReportData) => void
  monthlySummary: (id: string, data: string) => void
  generateMyReport: (reportId: string, pickFilterData: object) => void
  showReports: (title: string) => void
  reportData: MyReportModule[]
  background: string
  textColor: string
  title: string
  index?: number
  setModule: string
  resetData: boolean
  systemReport: string
  generateMyReportId: string
}

type Props = StoreProps & OwnProps

interface State {
  activeIndex: any
}

class MyReports extends React.Component<Props, State> {
  public state = {
    activeIndex: ''
  }

  public componentDidMount() {
    const { setModule, systemReport } = this.props
    this.setState({ activeIndex: setModule === 'MY REPORTS' ? systemReport : setModule })
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({ activeIndex: nextProps.setModule === 'MY REPORTS' ? nextProps.systemReport : nextProps.setModule })
  }

  public render() {
    const { background, reportData, title, textColor, monthlySummary, deleteReport, updateMyReport, setModule, generateMyReport, generateMyReportId } = this.props
    const { activeIndex } = this.state
    const report = reportData.map((items: any, index: any) => (
      <StyledAccordion key={index} background={background} color={textColor}>
        <Accordion.Title active={activeIndex === index} index={index} title={items.module.toUpperCase()} onClick={this.handleClick} >
          <span>{items.module}</span>
          <FontAwesomeIcon icon={['fas', 'caret-down']} />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === items.module.toUpperCase()}>
          <AccordionContent
            generateMyReportId={generateMyReportId}
            title={title}
            setModule={setModule}
            moduleId={items._id}
            report={items.reportName}
            deleteReport={deleteReport}
            updateMyReport={updateMyReport}
            monthlySummary={monthlySummary}
            generateMyReport={generateMyReport} />
        </Accordion.Content>
      </StyledAccordion>
    ))
    return (
      <Container>
        <Title>{title}</Title>
        <Reports>
          {
            report.length === 0 ? <Loader active={true} inline='centered' size='large' /> :
              <AccordionContainer>
                {report}
              </AccordionContainer>
          }
        </Reports>
      </Container>
    )
  }

  private handleClick = (e: React.SyntheticEvent<EventTarget>, titleProps: any) => {
    const { title } = titleProps
    const { setPerviewModule, showReports, setChangeToggle, setShowSystemReport, setPickData, setFilter, setApplyFilter, setColumnData, setDatesFilters, setResetFilterData, setShowCity, setShowState, setModule } = this.props
    const { activeIndex } = this.state
    if (setModule !== 'MY REPORTS') {
      setShowSystemReport('')
      if (activeIndex === title) {
        setPerviewModule(title)
        showReports(title)
        this.setState({ activeIndex: '' })
      } else {
        setPerviewModule(title)
        showReports(title)
        this.setState({ activeIndex: title })
      }
    } else {
      if (activeIndex === title) {
        showReports('MY REPORTS')
        setShowSystemReport(title)
        this.setState({ activeIndex: '' })
      } else {
        showReports('MY REPORTS')
        setShowSystemReport(title)
        this.setState({ activeIndex: title })
      }
    }

    setPickData([] as PickData | any)
    setApplyFilter([])
    setFilter({})
    setColumnData([] as ApplyFilter | any)
    setDatesFilters({})
    setResetFilterData(false)
    setShowCity({} as Options)
    setShowState({} as Options)
    setChangeToggle({ contact: false, lead: false, task: false })
  }
}

export default connect(
  null,
  {
    setApplyFilter: Actions.getApplyFilter,
    setChangeToggle: Actions.changeToggle,
    setColumnData: Actions.getColumnData,
    setDatesFilters: Actions.datesFilters,
    setFilter: Actions.getFilter,
    setPerviewModule: Actions.perviewModule,
    setPickData: Actions.getPickData,
    setResetFilterData: Actions.resetFilterData,
    setShowCity: Actions.showCity,
    setShowState: Actions.showState,
    setShowSystemReport: Actions.showSystemReport
  }
)(MyReports)
