import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { x, y } from 'design/Styled'

import BorderGreenTop from 'design/images/borderGreen.png'
import BorderGreenBottom from 'design/images/borderGreen1.png'

import BorderBlueTop from 'design/images/borderBlue.png'
import BorderBlueBottom from 'design/images/borderBlue1.png'

import DogTail from 'design/images/dog-icon-full.png'
import DogTailWagging from 'design/images/dog-tail-wagging.gif'

interface Props {
  background?: string
  color?: string
  height?: number
}

const borderWidth = 1
const frameHeight = 15

export const Container = styled.section`
  ${y};
  width: 230px;
  transition: all 300ms;

  &:hover {
    transform: scale3d(1.08, 1.08, 1.08);
    .wag {
      background-image: url(${DogTailWagging});
    }
    .frame {
      box-shadow: 0 0 15px -6px ${Colors.CardShadow};
      &:before {
        background-image: url(${BorderBlueTop});
      }
      &:after {
        background-image: url(${BorderBlueBottom});
      }
    }
  }
`

export const Content = styled.div`
  ${y};
  min-height: 300px;
  border: ${borderWidth}px solid ${Colors.CardShadow};
  box-shadow: 0 5px 10px -3px ${Colors.CardShadow};
  transition: all 300ms;

  &:before,
  &:after {
    display: block;
    content: '';
    position: relative;
    width: calc(100% + ${2 * borderWidth}px);
    height: ${frameHeight}px;
    background-repeat: repeat-x;
  }

  &:before {
    top: ${frameHeight / -2}px;
    left: ${borderWidth * -1}px;
    background-position: center top;
    background-image: url(${BorderGreenTop});
  }

  &:after {
    top: ${frameHeight / 2}px;
    left: ${borderWidth * -1}px;
    background-position: center bottom;
    background-image: url(${BorderGreenBottom});
  }
`

export const Header = styled.header`
  ${y};
  padding: 1em;
  align-items: center;
`

export const DogImage = styled.div`
  display: flex;
  width: 100%;
  height: ${(props: Props) => props.height || 100}px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${DogTail});
`

export const Title = styled.h1`
  font: ${fonts.medium.bold};
  color: ${Colors.DarkBlue200};
  margin: 0.8em 0;
  text-transform: uppercase;
`

export const Price = styled.h2`
  display: flex;
  position: relative;
  margin: 0;
  align-items: baseline;
  font: ${fonts.medium.regular};
  color: ${(props: Props) => props.color || Colors.DarkBlue200};

  strong {
    font-size: 24px;
  }

  span {
    font-size: 10px;
  }

  .fa-dollar-sign {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    left: -10px;
  }
`

export const Features = styled.ul`
  ${y};
  margin: 0;
  padding: 0 1em 1em;
  list-style: none;
`

export const Item = styled.li`
  ${x};
  font: ${fonts.small.regular};
  margin-bottom: 0.35em;
  align-items: flex-start;

  .fa-check-circle {
    margin-right: 0.5em;
    color: ${Colors.Green80};
  }
`

export const Footer = styled.div`
  ${y};
  padding: 0 1em 1em;
`
