// Import Components
import client from 'queries/apollo'

// Import Graphql Queries
import {
  GET_ALL_FOLDER_FILES,
  GET_LINKS,
  GET_USER_CREDIT_DEBIT,
  USER_PROFILE_COUNTER
} from 'queries/graphql/UserProfile/Queries'

export const getCreditDebit = async (userId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_CREDIT_DEBIT,
    variables: { userId }
  })

  return response.data.getUserCreditDebits
}

export const userProfileCounter = async (userId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: USER_PROFILE_COUNTER,
    variables: { userId }
  })

  return response.data.userProfileCounter
}

export const getAllFolderFiles = async (userId: string | undefined) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_ALL_FOLDER_FILES,
    variables: { userId }
  })

  return response.data.getAllFolderFiles
}

export const getLinks = async (toggle: boolean, userId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_LINKS,
    variables: { userId: !toggle ? userId : undefined }
  })

  return response.data.getLinks
}
