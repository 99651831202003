// Import Packages
import * as React from 'react'

// Import Utils
import { getLoggedInUser, Strings } from 'utils'

import { Options, UserType } from 'store/Transactions/Types'

// Import Styled Components
import {
  Action,
  AddButtonContainer,
  Align,
  AlignContainer,
  Calendar,
  CloseIcon,
  Col,
  Container,
  CustomButton,
  CustomInput,
  Filter,
  FilterIcon,
  SearchIcon,
  SearchInput,
  StyledCheckbox,
  StyledDropdown,
  Trello,
  TrelloContainer,
} from 'shared/StyledBoard/Styled'

// Font Awesome Icons
import {
  faPlus
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faPlus])

interface Props {
  showCalendarIcon?: boolean
  showTaskSearchModal?: boolean
  showDefaultView: (isKanbanView: boolean) => void
  filterState: string
  addList: () => void
  getOfficeTransactions: (id: string, value: string) => void
  filterOptions: Options[]
  defaultView: boolean
  deleteTransactions: () => void
  transactions: any[]
  user: UserType
  toggleStatus: boolean
  changeToggle: () => void
}

interface State {
  searchText: string
  showSearchModal: boolean
}

class StyledBoard extends React.Component<Props, State> {
  public user: any = []
  public state = {
    searchText: '',
    showSearchModal: false,
  }

  public options = [{
    key: '0',
    text: 'Choose an Action',
    value: 'Choose',
  }, {
    key: '1',
    text: 'Delete Transaction(s)',
    value: 'Delete',
  }]

  public async componentDidMount() {
    this.user = await getLoggedInUser()
  }

  public render() {
    const { showTaskSearchModal, showCalendarIcon, filterOptions, defaultView, transactions, user, toggleStatus } = this.props
    const { searchText } = this.state
    return (
      <Container>
        <Filter>
          {(user.role === 'ADMIN') && (
            <Filter>
              <FilterIcon />
              <StyledDropdown inline={true} placeholder="Select Office" options={filterOptions} onChange={this.onHandleChange} />
            </Filter>
          )}
          {(user.role === 'TEAM_LEADER') && (
            <Col>
              <span style={{ fontWeight: !toggleStatus ? 600 : 400, }}>Mine</span>
              <StyledCheckbox className='tester' checked={toggleStatus} toggle={true} onChange={this.toggleStatus} />
              <span style={{ fontWeight: toggleStatus ? 600 : 400 }}>Team</span>
            </Col>
          )}
        </Filter>
        <SearchInput style={{ width: !defaultView ? '40%' : '50%' }}>
          <CustomInput placeholder={Strings.kanbanView.search} style={{ display: 'none' }}>
            {
              showTaskSearchModal ?
                <input value={searchText} onChange={this.onChangeHandle} onFocus={this.showSearchModal} onBlur={this.closeModal} /> :
                <input value={searchText} onChange={this.onChangeHandle} />
            }
            <SearchIcon />
            <CloseIcon onClick={this.clearState} />
          </CustomInput>
        </SearchInput>
        <CustomButton style={{ marginRight: (!defaultView && this.user.role !== 'ADMIN') ? '15px' : '0px' }}>
          {
            showCalendarIcon && <Calendar />
          }
          <AlignContainer><Align onClick={this.showListView} /></AlignContainer>
          <TrelloContainer><Trello onClick={this.showKanbanView} /></TrelloContainer>
          {
            (!defaultView && (this.user.role === 'ADMIN' || this.user.role === 'MANAGER')) && <Action>
              {' '}
              <StyledDropdown
                inline={true}
                options={this.options}
                placeholder='Choose an Action'
                onChange={this.handleChange} />
            </Action>
          }
          {
            (user.role === 'TEAM_LEADER' && !toggleStatus) ? null : (!defaultView && (this.user.role !== 'GUEST') && transactions.length !== 0) && <AddButtonContainer onClick={this.props.addList} style={{ marginLeft: (!defaultView && (this.user.role === 'ADMIN' || this.user.role === 'MANAGER')) ? '' : 20 }}>
              <FontAwesomeIcon icon={['fas', 'plus']} />
            </AddButtonContainer>
          }
        </CustomButton>
      </Container>
    )
  }

  private toggleStatus = () => {
    const { changeToggle } = this.props
    changeToggle()
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    if (value !== 'Choose') {
      const { deleteTransactions } = this.props
      deleteTransactions()
    }
  }

  private showListView = () => {
    const { showDefaultView } = this.props
    showDefaultView(false)
  }

  private showKanbanView = () => {
    const { showDefaultView } = this.props
    showDefaultView(true)
  }

  private showSearchModal = () => {
    this.setState({ showSearchModal: true })
  }

  private closeModal = () => {
    this.setState({ showSearchModal: false })
  }

  private onHandleChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    const result = value.split(', ')
    const { getOfficeTransactions } = this.props
    getOfficeTransactions(result[0], value)
  }

  private onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value })
  }

  private clearState = () => {
    this.setState({ searchText: '' })
  }
}

export default StyledBoard
