import * as React from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ConfirmAlert from 'sweetalert2'

import LoadingIndicator from 'shared/LoadingIndicator'
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import StyledGrid from 'shared/StyledBoard/GridView'
import KanbanView from 'shared/StyledBoard/KanbanView'
import Toast from 'shared/Toast'

import { getIcons } from '../Leads/Dashboard/LeadQueries'
import TaskAction from './Actions'
import AddTasksModal from './AddTasksModal'
import {
  Checked,
  CustomActions,
  CustomCheckBox,
  CustomDue,
  CustomHeaderCheckBox,
  CustomRelatedTo,
  CustomSharedWith,
  CustomStatus,
  CustomTask
} from './CustomComponents'
import ImportTasksPopup from './ImportTasksPopup'
import StyledHeader from './StyledHeader'
import TaskCard from './TasksCard'
import { formatKanbanData, formatListData } from './Utils/FormattingData'
import ViewDetailsModal from './ViewDetailsModal'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Tasks/Actions'
import * as DashboardActions from 'store/Dashboard/Actions'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { OfficeItemsDetails } from 'store/Offices/Types'
import {
  Filter,
  FiltersData,
  IconsType,
  TaskDetails,
  TaskItemsDetails,
  TaskNotes,
  TaskPassFilter,
  UserType
} from 'store/Tasks/Types'

import { getLoggedInUser, Strings } from 'utils'

import { Container } from './Styled'

import { createEvent } from './EventMutations'
import { createTask, deleteTasks, moveTask, reorderCard, reorderLane, updateShareWithTasks } from './TaskMutations'
import { exportTasks, getMyTaskBoards, getMyTasks, getOptions, getTaskBoards, getTasks } from './TaskQueries'

interface StoreProps {
  setNewBoard: (index: number, newData: TaskDetails) => void
  setNewCard: (id: string, index: string, newData: TaskItemsDetails) => void
  setBoardData: (data: TaskDetails[]) => void
  setListData: (data: TaskItemsDetails[]) => void
  setResetCardData: () => void
  setRestoreCard: () => void
  setNewIcon: (id: string, icon: string) => void
  setNewDescription: (id: string, description: string) => void
  setNewBoardName: (id: string, boardName: string) => void
  setDeleteCard: (boardId: string, cardId: string) => void
  setDeleteRecord: (id: string) => void
  setChangeStatus: (boardId: string, cardId: string, status: string) => void
  setUpdateRecord: (newData: TaskItemsDetails) => void
  setImportData: (id: string, data: TaskItemsDetails) => void
  setToggleStatus: (oepn: boolean) => void
  setSearchDataFilter: (data: FiltersData) => void
  setShowUserPassTaskFilterData: (filter: TaskPassFilter) => void
  setShowTaskDetail: (id: string) => void

  data: TaskDetails[]
  listData: TaskItemsDetails[]
  notes: TaskNotes[]
  searchLoader: boolean
  allTaskStatus: boolean
  filter: Filter
  searchData: FiltersData
  userPassTaskFilter: TaskPassFilter
  taskID: string
}

type Props = StoreProps & RouteComponentProps<{}, {}>

interface State {
  appearance: boolean
  activeIndex: number
  newBoard?: TaskDetails
  newCard?: TaskItemsDetails
  boardName?: string
  showAddTaskModal: boolean
  showComposeMail: string
  showViewDetailsModal: boolean
  showTaskSearchModal: boolean
  showCalendarIcon: boolean
  date: Date
  checked: boolean
  show: string
  icons: IconsType[]
  user: UserType
  addTaskIndex: string
  addBoardId: string
  edit: boolean
  listViewData: TaskItemsDetails[]
  toggleStatus: boolean
  officeFilter: string
  offices: OfficeItemsDetails[]
  editTaskDetails: TaskItemsDetails
  showImportFileModal: boolean
  importTaskBoardId: string
  loader: boolean
  taskCalenderLogs: TaskItemsDetails[]
}

export let ToggleStatus: any
export let ShowViewDetails: any
export let ToggleCheckBox: any
export let SingleCheckBox: any
export let DeleteListTask: any
export let toggle: boolean
export let taskNotes: any
export let editTask: any
export let ShowImportFileModal: any
export let ImportTaskSuccessfully: any
export let ShowComposeMail: any
export let UpdateShareWith: any
export let exportTask: any
export let deleteTaskBoard: any
export let addActionTask: any
export let GetTasks: any
export let loggedUser: any

class Tasks extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    addBoardId: '',
    addTaskIndex: '',
    appearance: true,
    checked: false,
    date: new Date(),
    edit: false,
    editTaskDetails: {} as TaskItemsDetails,
    icons: [],
    importTaskBoardId: '',
    listViewData: [],
    loader: true,
    officeFilter: '',
    offices: [],
    setIcon: '',
    setIconColor: '',
    show: 'kanban',
    showAddTaskModal: false,
    showCalendarIcon: true,
    showComposeMail: '',
    showImportFileModal: false,
    showTaskSearchModal: true,
    showViewDetailsModal: false,
    taskCalenderLogs: [],
    toggleStatus: false,
    user: {} as UserType
  }

  public columnMetaData = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.office.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomTask,
      enhanceWithRowData: true,
      id: 'task',
      title: 'Task'
    },
    {
      enhanceWithRowData: true,
      id: 'description',
      title: 'Task Description'
    },
    {
      customComponent: CustomRelatedTo,
      enhanceWithRowData: true,
      id: 'relatedTo',
      title: 'Related To'
    },
    {
      customComponent: CustomSharedWith,
      enhanceWithRowData: true,
      id: 'sharedWith',
      title: 'Shared with'
    },
    {
      customComponent: CustomDue,
      enhanceWithRowData: true,
      id: 'dueDate',
      title: 'Due'
    },
    {
      customComponent: CustomStatus,
      enhanceWithRowData: true,
      id: 'status',
      title: 'Status'
    },
    {
      customComponent: CustomActions,
      enhanceWithRowData: true,
      id: 'action',
      title: 'Action'
    }
  ]

  public async componentDidMount() {
    const { notes, setSearchDataFilter, setShowUserPassTaskFilterData, location } = this.props
    const { toggleStatus } = this.state
    const user: any = await getLoggedInUser({ fromCache: true })
    loggedUser = user
    setSearchDataFilter({})
    if (location && location.search) {
      // showTransaction('')
    } else {
      setShowUserPassTaskFilterData({} as TaskPassFilter)
    }
    if (user.role !== 'GUEST') {
      const options = await getOptions()
      this.setState({ offices: options })
    } else {
      this.setState({ offices: [] })
    }
    toggle = toggleStatus
    taskNotes = notes
    this.refreshGetTasks()

    const iconsList = await getIcons()
    this.setState({ icons: iconsList, user })
    this.assignToggleStatusMethod()
    this.assignShowViewDetailsModal()
    this.assignToggleCheckBox()
    this.assignSingleCheckBox()
    this.assignDeleteTask()
    this.editTaskRecord()
    this.assignShowImportFileModal()
    this.importedSuccessfully()
    this.assignExportTasks()
    this.assignDeleteLane()
    this.addTaskAction()
    this.assignShowComposeMail()
    this.assignGetTasks()
    this.updateTaskShareWith()
  }

  public render() {
    const { data, listData, setImportData, searchLoader, taskID } = this.props
    const {
      activeIndex,
      loader,
      appearance,
      show,
      showAddTaskModal,
      showCalendarIcon,
      icons,
      toggleStatus,
      user,
      offices,
      edit,
      editTaskDetails,
      importTaskBoardId,
      showImportFileModal,
      showComposeMail
    } = this.state
    return (
      <React.Fragment>
        {showComposeMail && <ComposeModal onClose={this.closeComposeMailModal} defaultEmail={showComposeMail} />}
        {showAddTaskModal && (
          <AddTasksModal
            closeModal={this.closeModal}
            addWorkflowTask={this.addTask}
            edit={edit}
            editDetailObj={editTaskDetails}
            addEvent={this.addEvent}
          />
        )}
        {showImportFileModal && (
          <ImportTasksPopup
            closeModal={this.closeModal}
            boardId={importTaskBoardId}
            filterOptions={toggleStatus}
            user={user}
            importData={setImportData}
          />
        )}
        {taskID && (
          <ViewDetailsModal
            activeIndex={activeIndex}
            closeModal={this.closeModal}
            refreshTasks={this.refreshGetTasks}
            taskID={taskID}
            user={user}
          />
        )}
        <Container>
          {(loader || searchLoader) && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
          <StyledHeader
            addList={this.addList}
            changeToggle={this.changeToggleStatus}
            deleteTasks={this.deleteTasks}
            getOfficeTasks={this.getOfficeTasks}
            offices={offices}
            showCalendarIcon={showCalendarIcon}
            showDefaultView={this.showDefaultView}
            showToggleCheckBox={true}
            toggleStatus={toggleStatus}
            user={user}
          />
          {show === 'kanban' &&
            data.length > 0 && (
              <KanbanView
                ActionComponent={TaskAction}
                addNewCard={this.addNewCard}
                alterData={this.alterData}
                appearance={appearance}
                CardComponent={TaskCard}
                data={data}
                deleteCard={this.deleteCard}
                deleteRecord={this.deleteTaskCard}
                deleteTransfer={false}
                icons={icons}
                moveCard={this.moveTask}
                reorderCard={this.reorderTask}
                reorderColumn={this.reorderColumn}
                resetCardData={this.resetCardData}
                restoreCard={this.restoreCard}
                setNewBoardName={this.setNewBoardName}
                setNewDescription={this.setNewDescription}
                setNewIcon={this.setNewIcon}
                updateBoard={this.updateBoard}
              />
            )}
          {show === 'listview' &&
            listData && (
              <StyledGrid
                addList={this.addList}
                columnMetaData={this.columnMetaData}
                data={listData}
                tableHeight={50}
              />
            )}
        </Container>
      </React.Fragment>
    )
  }

  private refreshGetTasks = async (id?: string, singleSkip?: number) => {
    const { setBoardData, setListData, userPassTaskFilter, searchData, location } = this.props
    const { toggleStatus, officeFilter } = this.state
    this.setState({ loader: true })
    const user: any = await getLoggedInUser({ fromCache: true })
    if (officeFilter) {
      let res: TaskDetails[] = []
      let listData: TaskItemsDetails[] = []
      if (toggleStatus === true) {
        res = await getTaskBoards(searchData, officeFilter, undefined, id, singleSkip)
        listData = await getTasks(searchData, officeFilter)
      } else {
        res = await getMyTaskBoards(user[`_id`], undefined, id, singleSkip)
        listData = await getMyTasks(user[`_id`])
      }
      const formatedData = await formatKanbanData(res, toggleStatus)
      const formatedListData = await formatListData(listData, toggleStatus)
      setBoardData(formatedData)
      setListData(formatedListData)
      this.setState({ loader: false })
    } else if (Object.keys(userPassTaskFilter).length > 0 && location && location.search) {
      let res: TaskDetails[] = []
      let listData: TaskItemsDetails[] = []
      if (toggleStatus === true) {
        res = await getTaskBoards(searchData, undefined, userPassTaskFilter, id, singleSkip)
        listData = await getTasks(searchData, undefined, userPassTaskFilter)
      } else {
        res = await getMyTaskBoards(user[`_id`], userPassTaskFilter, id, singleSkip)
        listData = await getMyTasks(user[`_id`], userPassTaskFilter)
      }
      const formatedData = await formatKanbanData(res, toggleStatus)
      const formatedListData = await formatListData(listData, toggleStatus)
      setBoardData(formatedData)
      setListData(formatedListData)
      this.setState({ loader: false })
    } else {
      let res: TaskDetails[] = []
      let listData: TaskItemsDetails[] = []
      if (toggleStatus === true) {
        res = await getTaskBoards(searchData, undefined, undefined, id, singleSkip)
        listData = await getTasks(searchData)
      } else {
        res = await getMyTaskBoards(user[`_id`], undefined, id, singleSkip)
        listData = await getMyTasks(user[`_id`])
      }
      const formatedData = await formatKanbanData(res, toggleStatus)
      const formatedListData = await formatListData(listData, toggleStatus)
      setBoardData(formatedData)
      setListData(formatedListData)
      this.setState({ loader: false })
    }
  }

  private assignGetTasks = () => {
    GetTasks = () => {
      this.refreshGetTasks()
    }
  }

  private showDefaultView = async (status: boolean) => {
    const { data, filter } = this.props
    if (status === true) {
      this.setState({ show: 'kanban' })
    } else {
      this.setState({ show: 'listview' })
    }
    if (filter.filter === false && filter.searchText === false) {
      this.refreshGetTasks()
    }
    if (status === false) {
      const newBoardId = data.filter((board: any) => {
        return board.name === 'New Task'
      })
      this.setState({ addBoardId: newBoardId[0]._id })
    }
  }

  private changeToggleStatus = async () => {
    const { user, toggleStatus }: any = this.state
    const { setBoardData, setListData, filter } = this.props
    this.setState({ toggleStatus: !this.state.toggleStatus })
    toggle = !this.state.toggleStatus
    this.props.setToggleStatus(!this.props.allTaskStatus)
    if (filter.filter === false && filter.searchText === false) {
      let data: TaskDetails[] = []
      let listData: TaskItemsDetails[] = []
      if (!this.state.toggleStatus === true) {
        data = await getTaskBoards()
        listData = await getTasks()
      } else {
        data = await getMyTaskBoards(user._id)
        listData = await getMyTasks(user._id)
      }
      const formatedData = await formatKanbanData(data, toggleStatus)
      const formatedListData = await formatListData(listData, toggleStatus)
      setBoardData(formatedData)
      setListData(formatedListData)
    }
  }

  private assignToggleCheckBox = () => {
    ToggleCheckBox = () => {
      const { setListData, listData } = this.props
      const { checked } = this.state
      this.setState({ checked: !this.state.checked }, () => {
        // const { listViewData } = this.state
        const newData = listData.map((dataItem: any) => {
          return {
            ...dataItem,
            checked: this.state.checked
          }
        })
        setListData(newData)
      })
      Checked(checked)
    }
  }

  private assignSingleCheckBox = () => {
    SingleCheckBox = (id: string) => {
      const { setListData, listData } = this.props
      const newData = listData.map((dataItem: any) => {
        return {
          ...dataItem,
          checked: dataItem._id === id ? !dataItem.checked : dataItem.checked
        }
      })
      setListData(newData)
    }
  }

  private assignShowImportFileModal = () => {
    ShowImportFileModal = (boardId: string) => {
      this.setState({ showImportFileModal: true, importTaskBoardId: boardId })
    }
  }

  private importedSuccessfully = () => {
    ImportTaskSuccessfully = async () => {
      const { user, toggleStatus } = this.state
      const { setBoardData, setListData } = this.props
      let res: any = []
      let listdata: any = []

      if (toggleStatus === true) {
        res = await getTaskBoards()
        listdata = await getTasks()
      } else {
        res = await getMyTaskBoards(user[`_id`])
        listdata = await getMyTasks(user[`_id`])
      }
      const formatedData = await formatKanbanData(res, toggleStatus)
      const formatedListData = await formatListData(listdata, toggleStatus)
      setBoardData(formatedData)
      setListData(formatedListData)
    }
  }

  private assignExportTasks = () => {
    exportTask = (boardId: string) => {
      const { user, toggleStatus } = this.state
      exportTasks(boardId, toggleStatus, user)
    }
  }

  private assignDeleteLane = () => {
    deleteTaskBoard = async (boardId: string) => {
      // const { deleteBoard } = this.props
      const { user } = this.state
      ConfirmAlert({
        cancelButtonText: Strings.kanbanView.noKeepIt,
        confirmButtonText: Strings.kanbanView.yesDeleteIt,
        showCancelButton: true,
        text: Strings.kanbanView.recoverBoard,
        title: Strings.kanbanView.sure,
        type: 'warning'
      }).then(result => {
        if (result.value) {
          // deleteBoard(boardId)
          deleteTaskBoard(boardId, user)
          ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedBoard, 'success')
        } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
          ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeCard, 'error')
        }
      })
    }
  }

  private assignShowComposeMail = () => {
    ShowComposeMail = (showComposeMail: string) => {
      this.setState({ showComposeMail })
    }
  }

  private addList = () => {
    this.setState({ showAddTaskModal: true, edit: false, editTaskDetails: {} as TaskItemsDetails })
  }

  private assignToggleStatusMethod = () => {
    ToggleStatus = (boardId: string, cardId: string, status: string) => {
      const { setChangeStatus } = this.props
      setChangeStatus(boardId, cardId, status)
    }
  }

  private assignShowViewDetailsModal = () => {
    ShowViewDetails = (taskId: string, activeindex?: number) => {
      const { setShowTaskDetail } = this.props
      setShowTaskDetail(taskId)
      this.setState({
        showViewDetailsModal: true,
        activeIndex: activeindex ? activeindex : 0
      })
    }
  }

  private resetCardData = () => {
    const { setResetCardData } = this.props
    setResetCardData()
  }

  private restoreCard = () => {
    const { setRestoreCard, setResetCardData } = this.props
    setRestoreCard()
    setResetCardData()
  }

  private deleteCard = async (boardId: string, cardId: string) => {
    const { setDeleteCard } = this.props
    const { user } = this.state
    const ids: any = []
    ids.push(cardId)
    if (user.role === 'MANAGER' || user.role === 'ADMIN') {
      try {
        await deleteTasks(ids)
      } catch (error) {
        Toast({ message: error.message, type: 'error' })
      }
      setDeleteCard(boardId, cardId)
    }
  }

  private deleteTaskCard = async (boardId: string, cardId: string) => {
    const { user } = this.state
    if (user.role === 'MANAGER' || user.role === 'ADMIN') {
      ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
    } else {
      ConfirmAlert(Strings.kanbanView.error, Strings.kanbanView.cannotDeleteTransaction, 'error')
    }
  }

  private setNewBoardName = (id: string, boardName: string) => {
    const { setNewBoardName } = this.props
    setNewBoardName(id, boardName)
  }

  private setNewDescription = (id: string, description: string) => {
    const { setNewDescription } = this.props
    setNewDescription(id, description)
  }

  private setNewIcon = (id: string, icon: string) => {
    const { setNewIcon } = this.props
    setNewIcon(id, icon)
  }

  private closeComposeMailModal = () => {
    this.setState({ showComposeMail: '' })
  }

  private closeModal = () => {
    const { setShowTaskDetail } = this.props
    setShowTaskDetail('')
    this.setState({ showAddTaskModal: false, showViewDetailsModal: false, showImportFileModal: false })
  }

  private addNewCard = (id: string, index: string) => {
    this.setState({
      showAddTaskModal: true,
      addTaskIndex: index,
      addBoardId: id,
      edit: false,
      editTaskDetails: {} as TaskItemsDetails
    })
  }

  private updateTaskShareWith = () => {
    UpdateShareWith = async (newTask: TaskItemsDetails) => {
      await updateShareWithTasks(newTask)
      this.refreshGetTasks()
    }
  }

  private addTask = async (newTask: any) => {
    const { setNewCard, setUpdateRecord } = this.props
    const { addTaskIndex, addBoardId, show, user, edit, toggleStatus } = this.state
    const response: any = await createTask(newTask, addTaskIndex, addBoardId, show, user, edit, toggleStatus)
    if (edit) {
      setUpdateRecord(newTask)
    } else {
      setNewCard(addBoardId, addTaskIndex, response)
    }
    this.refreshGetTasks()
  }

  private addEvent = async (newEvent: any) => {
    await createEvent(newEvent, false)
  }

  private alterData = (data: TaskDetails[]) => {
    const { setBoardData } = this.props
    setBoardData(data)
  }

  private getOfficeTasks = async (id: string, office: string) => {
    this.setState({ officeFilter: id }, () => this.refreshGetTasks())
  }

  private assignDeleteTask = () => {
    DeleteListTask = async (taskId: string) => {
      const { setDeleteRecord } = this.props
      const ids: any = []
      ids.push(taskId)
      await deleteTasks(ids)
      setDeleteRecord(taskId)
    }
  }

  private addTaskAction = () => {
    addActionTask = (boardId: string) => {
      this.setState({
        showAddTaskModal: true,
        addBoardId: boardId,
        edit: false,
        editTaskDetails: {} as TaskItemsDetails
      })
    }
  }

  private moveTask = async (
    cardId: string,
    destinationIndex: number,
    destinationBoardId: string,
    sourceBoardId: string
  ) => {
    const { user, toggleStatus } = this.state
    const { data, setBoardData } = this.props
    let movedCard: any = {}
    data.forEach((row: any) => {
      if (row._id === sourceBoardId) {
        row.items.forEach((card: any) => {
          if (card._id === cardId) {
            movedCard = card
          }
        })
      }
    })
    if (
      (movedCard.createdBy && movedCard.createdBy._id === user[`_id`]) ||
      user[`role`] === 'ADMIN' ||
      user[`role`] === 'MANAGER'
    ) {
      this.setState({ loader: true })
      await moveTask(cardId, destinationBoardId, destinationIndex, user, toggleStatus)
      let kanbanData: any = []
      if (!toggleStatus) {
        kanbanData = await getMyTaskBoards(user[`_id`])
      } else {
        kanbanData = await getTaskBoards()
      }
      const formatedData = await formatKanbanData(kanbanData, toggleStatus)
      if (kanbanData) {
        this.setState({ loader: false })
      }
      setBoardData(formatedData)
    } else {
      let kanbanData: any = []
      if (!toggleStatus) {
        kanbanData = await getMyTaskBoards(user[`_id`])
      } else {
        kanbanData = await getTaskBoards()
      }
      const formatedData = await formatKanbanData(kanbanData, toggleStatus)
      setBoardData(formatedData)
      Toast({
        message:
          'You are not authorized to move task. Please contact your administrator if you need additional assistance',
        type: 'error'
      })
    }
  }

  private reorderColumn = (boardId: string, sourceIndex: number, destinationIndex: number) => {
    const { user, toggleStatus } = this.state

    if (toggleStatus === true) {
      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
        reorderLane(boardId, sourceIndex, destinationIndex, user, toggleStatus)
      }
    } else {
      reorderLane(boardId, sourceIndex, destinationIndex, user, toggleStatus)
    }
  }

  private reorderTask = async (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
    const { user, toggleStatus } = this.state
    if (toggleStatus === true) {
      if (user[`role`] === 'ADMIN' || user[`role`] === 'MANAGER') {
        reorderCard(boardId, cardId, sourceIndex, destinationIndex, user, toggleStatus)
      }
    } else {
      reorderCard(boardId, cardId, sourceIndex, destinationIndex, user, toggleStatus)
    }
  }

  private editTaskRecord = () => {
    editTask = (details: TaskItemsDetails) => {
      this.setState({
        addBoardId: '',
        edit: true,
        editTaskDetails: details,
        showAddTaskModal: true
      })
    }
  }

  private deleteTasks = () => {
    const { listData } = this.props
    const deleteIds: string[] = []
    listData.forEach((list: any) => {
      if (list.checked === true) {
        deleteIds.push(list._id)
      }
    })
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: 'Remove selected task(s)',
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        this.setState({ loader: true })
        const response = await deleteTasks(deleteIds)
        if (response) {
          this.refreshGetTasks()
          ConfirmAlert(Strings.kanbanView.deleted, 'Your task(s) has been deleted', 'success')
        }
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, 'Your task(s) is safe', 'error')
      }
    })
  }

  private updateBoard = async (id: string, singleSkip: number) => {
    this.refreshGetTasks(id, singleSkip)
  }
}

const mapStateToProps = (state: AppState) => ({
  allTaskStatus: state.tasks.allTaskStatus,
  data: state.tasks.data,
  filter: state.tasks.filter,
  listData: state.tasks.listData,
  notes: state.tasks.notes,
  searchData: state.tasks.searchData,
  searchLoader: state.tasks.searchLoader,
  userPassTaskFilter: state.tasks.userPassTaskFilter,
  taskID: state.dashboard.taskID,
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      setBoardData: Actions.getBoardData,
      setChangeStatus: Actions.changeStatus,
      setDeleteCard: Actions.deleteCard,
      setDeleteRecord: Actions.deleteRecord,
      setImportData: Actions.importData,
      setListData: Actions.getListData,
      setNewBoard: Actions.addNewBoard,
      setNewBoardName: Actions.setNewBoardName,
      setNewCard: Actions.addNewCard,
      setNewDescription: Actions.setNewDescription,
      setNewIcon: Actions.setNewIcon,
      setResetCardData: Actions.resetCardData,
      setRestoreCard: Actions.restoreCard,
      setSearchDataFilter: Actions.searchDataFilter,
      setShowUserPassTaskFilterData: Actions.showUserPassTaskFilterData,
      setToggleStatus: Actions.toggleStatus,
      setUpdateRecord: Actions.editRecord,
      setShowTaskDetail: DashboardActions.showTaskDetail
    }
  )(Tasks)
)
