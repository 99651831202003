import { Reducer } from 'redux'
import Actions from 'store/Actions'
// import { generateID } from 'utils'
import {
  addCategory,
  addDocument,
  addProperty,
  changeChecklistValue,
  deleteCategory,
  deleteDocument,
  deleteProperty,
  editCategory,
  editDocument,
  editProperty,
  reinsertChecklistProperty
} from './ReduxUtil'
import { ChecklistManagerState } from './Types'

const initialState: ChecklistManagerState = {
  categories: [],
  documents: []
}

const checklistManagerReducer: Reducer<ChecklistManagerState> = (
  state: ChecklistManagerState = initialState,
  action
) => {
  switch (action.type) {
    case Actions.updateChecklistData:
      return {
        ...state,
        categories: action.payload.categories || state.categories,
        documents: action.payload.documents || state.documents
      }
    case Actions.addDocument:
      return {
        ...state,
        documents: addDocument(state, action.payload.data)
      }
    case Actions.deleteDocument:
      return {
        ...state,
        documents: deleteDocument(state, action.payload.index)
      }
    case Actions.editDocument:
      return {
        ...state,
        documents: editDocument(
          state,
          action.payload.newName,
          action.payload.index
        )
      }
    case Actions.addCategory:
      return {
        ...state,
        categories: addCategory(state, action.payload.data)
      }
    case Actions.deleteCategory:
      return {
        ...state,
        categories: deleteCategory(state, action.payload.index)
      }
    case Actions.editCategory:
      return {
        ...state,
        categories: editCategory(
          state,
          action.payload.newName,
          action.payload.index
        )
      }
    case Actions.addProperty:
      return {
        ...state,
        categories: addProperty(
          state,
          action.payload.categoryIndex,
          action.payload.id,
          action.payload.name
        )
      }
    case Actions.deleteProperty:
      return {
        ...state,
        categories: deleteProperty(
          state,
          action.payload.categoryIndex,
          action.payload.propertyIndex
        )
      }
    case Actions.editProperty:
      return {
        ...state,
        categories: editProperty(
          state,
          action.payload.newName,
          action.payload.categoryIndex,
          action.payload.propertyIndex
        )
      }
    case Actions.changeChecklistValue:
      return {
        ...state,
        categories: changeChecklistValue(
          state,
          action.payload.categoryIndex,
          action.payload.documentId,
          action.payload.newValue,
          action.payload.propertyIndex
        )
      }
    case Actions.reinsertChecklistProperty:
      return {
        ...state,
        categories: reinsertChecklistProperty(
          state,
          action.payload.categoryIndex,
          action.payload.fromIndex,
          action.payload.toIndex
        )
      }
    default:
      return state
  }
}

export default checklistManagerReducer
