// Import Components
import client from 'queries/apollo'

import { ContactPassFilter, FiltersData, UserType } from 'store/Contacts/Types'

// Import Graphql Queries
import { GET_TRANSACTIONS } from 'queries/graphql/Transactions/Queries'
import { GET_TASK_ACTIVITY } from 'queries/graphql/Tasks/Queries'
import { GET_CITIES } from 'queries/graphql/City/Queries'
import { EXPORT_CONTACTS, GET_CONTACT_DETAILS, GET_CONTACT_NOTE, GET_CONTACTS } from 'queries/graphql/Contact/Queries'
import { GET_CONTACT_BOARDS } from 'queries/graphql/ContactBoard/Queries'
import { GET_STATES } from 'queries/graphql/States/Queries'

export const getContactBoards = async (userPassFilter?: ContactPassFilter, id?: string, singleSkip?: number) => {
  const contactBoards = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CONTACT_BOARDS,
    variables: {
      id,
      orderField: 'boardOrder',
      singleSkip,
      sortOrder: 1,
      userPassFilter
    }
  })

  return contactBoards.data.getContactBoards
}

export const getContactBoardsSearch = async (searchData: FiltersData) => {
  const contactBoards = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_CONTACT_BOARDS,
    variables: { orderField: 'boardOrder', sortOrder: 1, searchData }
  })

  return contactBoards.data.getContactBoards
}

export const getMyContactBoards = async (
  user: UserType,
  userPassFilter?: ContactPassFilter,
  id?: string,
  limit?: number
) => {
  const contactBoards = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CONTACT_BOARDS,
    variables: {
      id,
      limit,
      orderField: 'boardOrder',
      sortOrder: 1,
      userId: user._id,
      userPassFilter
    }
  })

  return contactBoards.data.getContactBoards
}

export const getMyContactBoardsSearch = async (user: UserType, searchData: FiltersData) => {
  const transactionBoards = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_CONTACT_BOARDS,
    variables: {
      orderField: 'boardOrder',
      searchData,
      sortOrder: 1,
      userId: user._id
    }
  })
  return transactionBoards.data.getContactBoards
}

export const getContacts = async (userPassFilter?: ContactPassFilter) => {
  const contacts = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CONTACTS,
    variables: { userPassFilter }
  })

  return contacts.data.getContacts
}

export const getContactsSearch = async (searchData: FiltersData) => {
  const contacts = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_CONTACTS,
    variables: { searchData }
  })

  return contacts.data.getContacts
}

export const getMyContacts = async (user: UserType, userPassFilter?: ContactPassFilter) => {
  const contacts = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CONTACTS,
    variables: { userId: user._id, userPassFilter }
  })

  return contacts.data.getContacts
}

export const getMyContactsSearch = async (user: UserType, searchData: FiltersData) => {
  const contacts = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_CONTACTS,
    variables: { userId: user._id, searchData }
  })

  return contacts.data.getContacts
}

export const reset = async () => {
  await client.query({
    query: GET_CONTACT_BOARDS,
    variables: {
      orderField: 'boardOrder',
      sortOrder: 1
    }
  })
}

export const getStates = async () => {
  const states = await client.query({
    query: GET_STATES
  })

  return states.data.getStates
}

export const getCities = async (stateId: string) => {
  const cities = await client.query({
    query: GET_CITIES,
    variables: { state_id: stateId }
  })

  return cities.data.getCities
}

export const getContactDetails = async (contactId: string) => {
  const data = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CONTACT_DETAILS,
    variables: { contactId }
  })
  return data.data.getContacts[0]
}

export const getContactNotes = async (contactId: string) => {
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: GET_CONTACT_NOTE,
    variables: { contactId }
  })

  return response.data.getNotes
}

export const getContactActivities = async (contactId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TASK_ACTIVITY,
    variables: { contactId }
  })

  return response.data.getActivities
}

export const exportUsers = async (groupid: string, filterOptions: boolean, user: UserType) => {
  const url = await client.query({
    query: EXPORT_CONTACTS,
    variables: {
      contactBoard: filterOptions === false || user.role === 'AGENT' ? groupid : undefined,
      officeContactBoard: filterOptions !== false && user.role === 'MANAGER' ? groupid : undefined,
      teamContactBoard: filterOptions !== false && user.role === 'TEAM_LEADER' ? groupid : undefined,
      tenantContactBoard: filterOptions !== false && user.role === 'ADMIN' ? groupid : undefined
    }
  })
  if (url && url.data) {
    setTimeout(() => {
      const response = {
        file: url.data.exportContacts
      }
      return (window.location.href = response.file)
    }, 100)
  }
}

export const getContactTransactions = async (contactId: string) => {
  const response = await client.query({
    fetchPolicy: 'no-cache',
    query: GET_TRANSACTIONS,
    variables: { contact: contactId }
  })

  return response.data.getTransactions
}
