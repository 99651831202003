// Import Packages
import * as React from 'react'
import NumberFormat from 'react-number-format'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button, Form, Input } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import client from 'queries/apollo'
import { UserProfileType, UserType } from 'store/UserProfile/Types'

// Import Graphql Queries
import { GET_USER_PROFILE } from 'queries/graphql/UserProfile/Queries'
import { updateProfile } from '../../UserProfileMutations'

// Import Styled Components
import { AddContactLeftPanel, AddForm, Container, Icons, StyledForm, Text } from './Styled'

// // Import Utils
import { getLoggedInUser } from 'utils'

// Font Awesome Icons
import { faBullseye } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faBullseye])

interface Props extends RouteComponentProps<{}> {
  handleClose: (salesGoal: number) => void
}

interface State {
  animate: boolean
  salesGoal: number
  data: UserProfileType
  user: UserType
  errors: boolean
}

class SalesGoalsModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    data: {} as UserProfileType,
    errors: false,
    salesGoal: 0,
    user: {} as UserType
  }

  public async componentDidMount() {
    const { location } = this.props
    let id: string = ''
    const loggedUser: any = await getLoggedInUser({ fromCache: true })
    this.setState({ user: loggedUser })
    let data: any = {}
    if (location && location.search) {
      const Id = location.search.split('=')[1]
      id = Id
    } else {
      id = loggedUser._id
    }
    data = await client.query({
      fetchPolicy: 'network-only',
      query: GET_USER_PROFILE,
      variables: { id: id }
    })

    this.setState({
      data: data.data.getUsers[0],
      salesGoal: data.data.getUsers[0].salesGoal
    })
  }

  public render() {
    const { animate, salesGoal, errors } = this.state
    return (
      <Modal
        content={
          <Container>
            <AddContactLeftPanel>
              <Icons>
                <FontAwesomeIcon icon={['fal', 'bullseye']} />
              </Icons>
              <Text>Sales Goal</Text>
            </AddContactLeftPanel>
            <AddForm>
              <h2>Set Your 2019 Sales Goals</h2>
              <StyledForm size={'mini'}>
                <Form.Field control={Input} label="Set Target Sales Volume Amount" name="salesGoal" error={errors}>
                  <NumberFormat
                    icon="dollar"
                    iconPosition="left"
                    placeholder="Enter Your Goal"
                    onValueChange={this.handlePropertyPrice}
                    value={salesGoal !== 0 ? salesGoal : ''}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </Form.Field>
                <Button content={'Save'} onClick={this.save} />
              </StyledForm>
            </AddForm>
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={500}
      />
    )
  }

  private save = async () => {
    const { data, salesGoal } = this.state
    const newObj = {
      salesGoal: salesGoal ? salesGoal : 0
    }
    await updateProfile(newObj, data)
    this.closeModal()
  }

  private handlePropertyPrice = (values: any) => {
    const { floatValue } = values
    this.setState({ salesGoal: floatValue })
  }

  private closeModal = async () => {
    const { handleClose } = this.props
    const { salesGoal } = this.state
    this.setState({ animate: false })
    window.setTimeout(() => {
      handleClose(salesGoal ? salesGoal : 0)
    }, 300)
  }
}

export default withRouter(SalesGoalsModal)
