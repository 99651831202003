import { Reducer } from 'redux'

import { Actions, BillingState } from './Types'

import { BillingCycleType, BillingProductUsageType } from 'shared/Billing/Types'

const initialState: BillingState = {
  cycle: {} as BillingCycleType,
  usage: {} as BillingProductUsageType
}

const BillingReducer: Reducer<BillingState> = (state: BillingState = initialState, action) => {
  switch (action.type) {
    case Actions.BillingUpdateState:
      return {
        ...state,
        ...action.payload
      }

    case Actions.BillingUpdatePlan:
      return {
        ...state,
        cycle: {
          ...state.cycle,
          plan: action.payload
        }
      }

    default:
      return state
  }
}

export default BillingReducer
