// Import Packages
import { cloneDeep, sortBy } from 'lodash'

// Import Utils
import { getLoggedInUser } from 'utils'

// Import Colors
import Colors from 'design/Colors'

const boardBorderColors = [
  `${Colors.Purple50}`,
  `${Colors.Orange490}`,
  `${Colors.Red10}`,
  `${Colors.Blue50}`,
  `${Colors.Green60}`,
  `${Colors.Grey800}`,
  `${Colors.Green560}`
]

const defaultLeadBoard = [
  'Buyer',
  'Seller',
  'Both',
  'Renter',
  'Closed',
  'Other'
]

export const formatKanbanData = (leadBoardData: any, toggleStatus: boolean) => {
  getLoggedInUser().then((response: any) => {
    // loggedInUser = response
  })

  let user: any = localStorage.getItem('user')
    ? localStorage.getItem('user')
    : '{}'
  user = JSON.parse(user)
  const loggedInUser = user

  const leadBoard = cloneDeep(leadBoardData)
  for (const lead of leadBoard) {
    lead.color = getRandomColors()
    if (!lead.leads) {
      lead.items = Object.assign([])
    } else {
      lead.items = Object.assign(lead.leads)
    }
    if (toggleStatus) {
      if (loggedInUser.role === 'ADMIN' || loggedInUser.role === 'MANAGER') {
        lead.showAddButton = true
      } else {
        lead.showAddButton = false
      }
    } else if (!toggleStatus) {
      if (loggedInUser.role !== 'GUEST') {
        lead.showAddButton = true
      }
    }
    if (defaultLeadBoard.includes(lead.name)) {
      lead.showAppearance = false
    } else {
      lead.showAppearance = true
    }
  }
  return sortBy(leadBoard, ['boardOrder'])
}

export const formatListData = (leadsData: any, toggleStatus: boolean) => {
  let user: any = localStorage.getItem('user')
    ? localStorage.getItem('user')
    : '{}'
  user = JSON.parse(user)
  const loggedInUser = user
  const leadData: any = []
  const leadListData = Object.assign(leadsData)
  // tslint:disable-next-line:forin
  for (const index in leadListData) {
    if (toggleStatus) {
      if (loggedInUser.role === 'MANAGER' || loggedInUser.role === 'ADMIN') {
        leadListData[index].showAddButton = true
      } else {
        leadListData[index].showAddButton = false
      }
    } else {
      leadListData[index].showAddButton = true
    }
    if (loggedInUser.role === 'ADMIN' || loggedInUser.role === 'MANAGER') {
      leadListData[index].showEditButton = true
    } else {
      leadListData[index].showEditButton = false
    }
    leadData.push(leadListData[index])
  }
  return leadData
}

const getRandomColors = () => {
  return boardBorderColors[Math.floor(Math.random() * boardBorderColors.length)]
}
