import gql from 'graphql-tag'

export const EDIT_CHECKLIST_CATEGORY = gql`
  mutation editChecklistCategory($checklistId: String, $name: String) {
    updateCheckListCategory(
      where: { _id: $checklistId }
      input: { name: $name }
    ) {
      _id
      name
    }
  }
`

export const CREATE_CHECKLIST_CATEGORY = gql`
  mutation createChecklistCategory($categoryName: String, $mls: String) {
    createCheckListCategory(input: { name: $categoryName, mls: $mls }) {
      _id
      name
    }
  }
`

export const DELETE_CHECKLIST_CATEGORY = gql`
  mutation deleteChecklistCategory($checklistCategoryId: String) {
    deleteCheckListCategory(_id: $checklistCategoryId)
  }
`
