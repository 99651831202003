import * as React from 'react'

import { Strings } from 'utils'

import { Container, Content, DogImage, Features, Footer, Header, Item, Price, Title } from './Styled'

import { faCheckCircle, faDollarSign } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faDollarSign, faCheckCircle])

interface Props {
  details: string[]
  dogHeight: number
  price: number
  priceColor: string
  title: string
}

class PlanCard extends React.Component<Props, {}> {
  public render() {
    const { children, details, dogHeight, price, priceColor, title } = this.props
    return (
      <Container padding={1}>
        <DogImage className="wag" height={dogHeight} />
        <Content className="frame">
          <Header>
            <Title>{title}</Title>
            <Price color={priceColor}>
              <FontAwesomeIcon icon={['fas', 'dollar-sign']} />
              <strong>{price}/</strong>
              <span>{Strings.settings.plans.months}</span>
            </Price>
          </Header>
          <Features primary={true}>
            {details.map((item: any, index: number) => (
              <Item key={index}>
                <FontAwesomeIcon icon={['fas', 'check-circle']} />
                {item}
              </Item>
            ))}
          </Features>
          <Footer>{children}</Footer>
        </Content>
      </Container>
    )
  }
}

export default PlanCard
